import { globalErrorsActions } from '../actions/error';
import { setGlobalLoader } from '../actions/loading';

export interface ApplicationState {
    error: Array<Object>,
    pending: boolean,
    isLoading: boolean,
    isLoadingLevel: number,
}

const initialState: ApplicationState = {
    error: [],
    pending: false,
    isLoading: false,
    isLoadingLevel: 0,
};


export const applicationReducer = (state: ApplicationState = initialState, action: any) => {
    if (action.fail === true) {
        return {
            ...state,
            error: [
                ...state.error,
                {
                    errorMessage: (action.error && typeof action.error === 'object' && 'message' in action.error) ? action.error.message : action.error
                }
            ]
        };
    }
    if ('pending' in action) {
        return {
            ...state,
            pending: action.pending
        };
    }
    if (action.type === globalErrorsActions.set.type) {
        return {
            ...state,
            error: [
                ...state.error,
                {
                    errorMessage: (action.error && typeof action.error === 'object' && 'message' in action.error) ? action.error.message : action.error
                }
            ]
        };
    }
    if (action.type === globalErrorsActions.destroy.type) {
        return {
            ...state,
            error: []
        };
    }
    if (action.type === setGlobalLoader.type) {
        let isLoadingLevel, isLoading;
        if (typeof action.isLoading === 'boolean') {
            isLoadingLevel = action.isLoading
                ? state.isLoadingLevel + 1
                : Math.max(state.isLoadingLevel - 1, 0);
        } else if (typeof action.isLoading === 'number') {
            isLoadingLevel = action.isLoading;
        } else {
            return state;
        }
        isLoading = isLoadingLevel > 0;
        return {
            ...state,
            isLoading: isLoading,
            isLoadingLevel: isLoadingLevel,
        };
    }
    return state;
};