// @flow

import React, { useEffect, useState } from 'react';
import { connect, createDispatchHook } from 'react-redux';
import {
  change,
  getFormValues,
} from 'redux-form';
import {
  FormFieldLayout,
} from '../../../../shared/components';

import { userCRUDPermissionsSelector } from '../../../../account/reducers/users-modules-reducer';
import { FileUploader } from '../../../../attachments/components/FileUploader';
import { AGENT_EDIT_FORM_NAME } from '../EditForm';

const MSD_TAG = 'MSD';

let MSDFile = ({
  agent,
  userPermissions,
  newFiles,
  changeAgentForm,
}) => {

  const _watchNewFiles = () => {
    if(!newFiles.length || newFiles.some(f => f.Tag !== MSD_TAG))
      return;
    const newFile = newFiles[0];
    changeAgentForm('MSDAttachment', newFile.Attachment);
    changeAgentForm('MSDAttachmentId', newFile.Attachment.Id);

  };
  useEffect(_watchNewFiles, [newFiles]);

  const deleteFile = () => {
    changeAgentForm('MSDAttachment', null);
    changeAgentForm('MSDAttachmentId',  null);
  };

  const getMsdFiles = () => {
    if(!agent?.MSDAttachment) {
      return [];
    }
    return [{ 
              AttachmentId: agent.MSDAttachment.Id,
              Attachment: { 
                Id: agent.MSDAttachment.Id, 
                OriginalName: agent.MSDAttachment.OriginalName }
              }];
  };

  return (
    <FormFieldLayout label={'MSD:'}>
      <FileUploader
          files={getMsdFiles()}
          accept={'.pdf'}
          multiple={false}
          oneFile={true}
          tag={MSD_TAG}
          deleteHandler={deleteFile}
          canDeleteFiles={(userPermissions.agent.UPDATE || userPermissions.agent.CREATE)}
          canUploadFiles={(userPermissions.agent.UPDATE || userPermissions.agent.CREATE)}
      />
    </FormFieldLayout>
  );
};


MSDFile = connect(
  (state) => ({
    agent: getFormValues(AGENT_EDIT_FORM_NAME)(state),
    newFiles: state.global.attachments.uploadedFiles,
    userPermissions: userCRUDPermissionsSelector(state),
  }),
  (dispatch) => ({
    changeAgentForm: (fieldName, value) => dispatch(change(AGENT_EDIT_FORM_NAME, fieldName, value, false)),
  })
)(MSDFile);

export {
  MSDFile,
};
