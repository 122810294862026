import {getEnum} from "../../../../../shared/utils";

export const processScaleEnum = getEnum(
    {
        laboratory:1,
        engineering:2,
        pilotIndustrial:3,
        industrial:4,
    }
);

export const processScaleTitlesEnum = getEnum(
    {
        'лабораторный':1,
        'инженерные':2,
        'опытно-промышленный':3,
        'промышленный':4,
    }
);
