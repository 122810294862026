// @flow

import { REACTION_REACTANTS_ARRAY_FIELD_NAME } from '../components/ReactionEdit/ReagentsTable';
import { ReagentTypeEnum } from '../../reagents/model/reagent-type-enum';
import { createSelector } from 'reselect';
import { getFormValues, getFormSyncErrors, isValid, isPristine } from 'redux-form';
import type { IReagent, IReagentJar } from '../../reagents/model';
import type { IProduct } from '../../products/model';
import { reactionModuleActions } from '../actions';
import type { RecalcFunctionArgs } from '../helpers/equation.helper';
import {
    pipeRecalcFunctions,
    recalcProduct,
    recalcProductList,
    recalcReactionYield,
    recalcReagent,
    recalcReagentList,
    recalcReferencedReagent,
} from '../helpers/equation.helper';
import { REACTION_EDIT_FORM_NAME } from '../components/ReactionEdit';
import { isProduct, isReagent } from '../helpers/common';
import { Reagent, ReagentJar } from '../../reagents/model';
import type { IJarStock } from '../../agents/models';
import { Agent, JarStock_Model } from '../../agents/models';
import type { IReactionName, IReactionParameter, IReactionYield, IRepkaTask } from '../models';
import type { TReagentAddFromDialogAction, TReagentEditFromDialogAction } from '../actions';
import { ReactionParameter, ReactionYield } from '../models';
import type { IRootStore } from '../../app/reducers';
import { uuidv4 } from '../../shared/utils/common';
import type { IArchivedReagent } from '../../archivedReagents/model';
import { REACTION_TYPE_PURIFICATION_ID } from '../../shared/globals/constants';
import { REACTION_IND_PARAMETERS_ARRAY_FIELD_NAME } from '../components/ReactionEdit/ReactionIndicators';
import { ReactionIndicatorParameter } from '../../reaction-indicator-parameters/models';


const SORT_REACTION_SUBSTANCES_ORDER = [
    ReagentTypeEnum.Original,
    ReagentTypeEnum.Reactant,
    ReagentTypeEnum.Catalyst,
    ReagentTypeEnum.Solvent,
    ReagentTypeEnum.Auxiliary,
];

export const
    sortReactantsByType = (a: (IReagent | IProduct), b: (IReagent | IProduct)) => {
        if ('ReagentType' in a && 'ReagentType' in b && typeof a.ReagentType === 'number' && typeof b.ReagentType === 'number') {
            return SORT_REACTION_SUBSTANCES_ORDER.indexOf(a.ReagentType) - SORT_REACTION_SUBSTANCES_ORDER.indexOf(b.ReagentType);
        }
        if (!('ReagentType' in a)) {
            return 1;
        }
        if (!('ReagentType' in b)) {
            return -1;
        }
        return 0;
    },
    searchExistingJar = (reagent: IReagent, availabilityId: number) => {
        if (!reagent.Jars || !reagent.Jars.length) {
            return null;
        }
        return reagent.Jars.find((jar: IReagentJar) => (
            jar.AgentAvailabilityId === availabilityId &&
            jar.ReagentId === reagent.Id &&
            !jar.IsDeleted
        ));
    },
    assignJars = (reagent: IReagent, jars: IJarStock[]) => {
        const reagentJars: IReagentJar[] = [];
        for (const jar of jars) {
            let _jar: IJarStock = searchExistingJar(reagent, jar.Id);
            if (!_jar) {
                _jar = ReagentJar({
                    ReagentId: reagent.Id,
                    AgentAvailabilityId: jar.Id,
                    ProviderName: jar.Provider
                });
            }
            if (_jar) reagentJars.push(ReagentJar(_jar));
        }
        return Reagent({
            ...reagent,
            Jars: reagentJars.map(j => ReagentJar(j))
        });
    }
    ;

export const reactionEditFormPlugin = (state, action) => {
    if (!state?.values) return;
    if ('debounce' in action && action.debounce === true) return state;
    const
        { [REACTION_REACTANTS_ARRAY_FIELD_NAME]: [], ..._reaction } = state?.values,
        _substancesList = state?.values[REACTION_REACTANTS_ARRAY_FIELD_NAME],
        _reagentList = _substancesList.filter(isReagent),
        _productList = _substancesList.filter(isProduct),
        _recalcEntry: RecalcFunctionArgs = {
            reagentList: _reagentList,
            productList: _productList,
            reaction: _reaction
        }
        ;
    const reducerFn = {
        [reactionModuleActions.recalc.reagent]: (action: { reagentIdx: IReagent }) => {
            const entry: RecalcFunctionArgs = {
                ..._recalcEntry,
                selectedReagent: _substancesList.find((_, idx) => idx === action.reagentIdx)
            };
            const {
                reagentList,
                productList
            }: RecalcFunctionArgs = pipeRecalcFunctions(entry, recalcReagent);
            return {
                ...state,
                values: {
                    ...state.values,
                    [REACTION_REACTANTS_ARRAY_FIELD_NAME]: [...reagentList, ...productList].sort(sortReactantsByType)
                }
            };
        },
        [reactionModuleActions.recalc.product]: (action: { productIdx: IProduct }) => {
            const entry: RecalcFunctionArgs = {
                ..._recalcEntry,
                selectedProduct: _substancesList.find((_, idx) => idx === action.productIdx)
            };
            const {
                reagentList,
                productList,
                reaction
            }: RecalcFunctionArgs = pipeRecalcFunctions(entry, recalcProduct, recalcReactionYield);
            const { ExperimentalPercentYield, TheoreticalYield, ExperimentalYield } = reaction;
            return {
                ...state,
                values: {
                    ...state.values,
                    ExperimentalPercentYield,
                    TheoreticalYield,
                    ExperimentalYield,
                    [REACTION_REACTANTS_ARRAY_FIELD_NAME]: [...reagentList, ...productList].sort(sortReactantsByType)
                }
            };
        },
        [reactionModuleActions.recalc.all]: () => {
            const {
                reagentList,
                productList,
                reaction
            }: RecalcFunctionArgs = pipeRecalcFunctions(_recalcEntry, recalcReferencedReagent, recalcReagentList, recalcProductList, recalcReactionYield);
            const { ExperimentalPercentYield, TheoreticalYield, ExperimentalYield } = reaction;
            return {
                ...state,
                values: {
                    ...state.values,
                    ExperimentalPercentYield,
                    TheoreticalYield,
                    ExperimentalYield,
                    [REACTION_REACTANTS_ARRAY_FIELD_NAME]: [...reagentList, ...productList].sort(sortReactantsByType)
                }
            };
        },
        [reactionModuleActions.recalc.reaction]: () => {
            const {
                reaction
            }: RecalcFunctionArgs = pipeRecalcFunctions(_recalcEntry, recalcReactionYield);
            const { ExperimentalPercentYield, TheoreticalYield, ExperimentalYield } = reaction;
            return {
                ...state,
                values: {
                    ...state.values,
                    ExperimentalPercentYield,
                    TheoreticalYield,
                    ExperimentalYield,
                }
            };
        },
        [reactionModuleActions.reactionEdit.setReferencedReagent]: (action: { reagent: IReagent }) => {
            _reagentList.forEach((reagent: IReagent) => {
                reagent.IsRefCompound = reagent === action.reagent;
            });
            action.reagent.IsRequired = true;
            return {
                ...state,
                values: {
                    ...state.values,
                }
            };
        },
        [reactionModuleActions.reactionEdit.addSubItem]: (action: { reagent: IReagent }) => {
            const reagent: IReagent = _substancesList.find((r, idx) => ((r.localUuid && r.localUuid === action.reagent.localUuid) || (r.Id && r.Id === action.reagent.Id)));
            const hasSubReagents = _substancesList.some((r, idx) => r.ParentReagentId == reagent.Id);
            try {
                let newReagent: IReagent = {
                    ...reagent,
                    Eq: 1,
                    Id: undefined,
                    localUuid: uuidv4(),
                    ParentReagentId: reagent.Id,
                    ParentReagentLocalId: reagent.localUuid,
                    IsRefCompound: false,
                    FactualConsumption: hasSubReagents ? 0 : reagent.FactualConsumption
                };
                newReagent.Jars = [...reagent.Jars];
                //newReagent.Jars.forEach(j=>j.ReagentId=-1);
                console.log('new', newReagent);
                const entry: RecalcFunctionArgs = {
                    ..._recalcEntry,
                    selectedReagent: newReagent
                };
                newReagent = recalcReagent(entry).selectedReagent;
                console.log('res', [..._substancesList, newReagent]);
                return {
                    ...state,
                    values: {
                        ...state.values,
                        [REACTION_REACTANTS_ARRAY_FIELD_NAME]: [..._substancesList, newReagent].sort(sortReactantsByType)
                    }
                };
            } catch (e) {
                throw e;
            }
            return {
                ...state
            };
        },
        [reactionModuleActions.reactionEdit.removeReagent]: (action: { reagentIdx: number }) => {
            const reagent: IReagent = _substancesList.find((_, idx) => idx === action.reagentIdx);
            if ('Id' in reagent) {
                return {
                    ...state,
                    values: {
                        ...state.values,
                        [REACTION_REACTANTS_ARRAY_FIELD_NAME]: _substancesList.map((r) => r === reagent || (r.ParentReagentId && r.ParentReagentId == reagent.Id) ? Reagent({
                            ...reagent,
                            IsDeleted: true
                        }) : r)
                    }
                };
            } else {
                return {
                    ...state,
                    values: {
                        ...state.values,
                        [REACTION_REACTANTS_ARRAY_FIELD_NAME]: _substancesList.filter((r) => r !== reagent)
                    }
                };
            }
        },
        [reactionModuleActions.reactionEdit.addArchivedReagent]: (action) => {
            try {
                const reagent = {
                    Agent: null,
                    AgentId: null,
                    Amount: null,
                    Compound: action.compound,
                    CompoundId: null,
                    Concentration: null,
                    Density: null,
                    Eq: null,
                    FactualConsumption: null,
                    Id: 0,
                    IsDeleted: false,
                    IsRequired: false,
                    MainAgentContent: null,
                    Mass: null,
                    ReactionId: null,
                    ReagentType: 4,
                    Volume: null
                };

                return {
                    ...state,
                    values: {
                        ...state.values,
                        [REACTION_REACTANTS_ARRAY_FIELD_NAME]: [..._substancesList, reagent].sort(sortReactantsByType)
                    }
                };

            } catch (e) {
                throw e;
            }
        },
        [reactionModuleActions.reactionEdit.addReagent]: (action: TReagentAddFromDialogAction) => {
            try {
                const reagent: IReagent = assignJars({
                    ReagentType: action.reagentType,
                    Eq: 1,
                    AgentAvailabilityId: action.availability?.Id,
                    Agent: Agent(action.agent),
                    AgentAvailability: JarStock_Model(action.availability),
                    MainAgentContent: action.availability?.MainAgentContent,
                    ReactionId: state.values?.Id,
                    IsRequired: action.reagentType === ReagentTypeEnum.Original || action.reagentType === ReagentTypeEnum.Reactant,
                    localUuid: uuidv4(),
                }, action.jars);

                return {
                    ...state,
                    values: {
                        ...state.values,
                        [REACTION_REACTANTS_ARRAY_FIELD_NAME]: [..._substancesList, reagent].sort(sortReactantsByType)
                    }
                };
            } catch (e) {
                throw e;
            }
        },
        [reactionModuleActions.reactionEdit.addMultipleReagents]: (action: { results: TReagentAddFromDialogAction[] }) => {
            try {
                const reagents = action.results.map((res: TReagentAddFromDialogAction) => (
                    assignJars({
                        ReagentType: res.reagentType,
                        Eq: 1,
                        AgentAvailabilityId: res.availability?.Id,
                        Agent: res.agent,
                        AgentAvailability: res.availability,
                        MainAgentContent: res.availability?.MainAgentContent,
                        ReactionId: state.values?.Id,
                        IsRequired: res.reagentType === ReagentTypeEnum.Original || res.reagentType === ReagentTypeEnum.Reactant,
                        localUuid: uuidv4(),
                    }, res.jars)
                ));

                return {
                    ...state,
                    values: {
                        ...state.values,
                        [REACTION_REACTANTS_ARRAY_FIELD_NAME]: [..._substancesList, ...reagents].sort(sortReactantsByType)
                    }
                };
            } catch (e) {
                throw e;
            }
        },
        [reactionModuleActions.reactionEdit.editSingleReagentJars]: (action: { results: TReagentEditFromDialogAction }) => {
            let editedReagent: IReagent = _reagentList.find((r: IReagent) => r.Id === action.results?.reagentId && r.localUuid === action.results?.reagentLocalUuid);
            console.log("update reagents");
            if (!editedReagent) {
                editedReagent = _reagentList.find((r: IReagent) => r.localUuid === action.results?.reagentLocalUuid);
                if (!editedReagent)
                    return state;
            }
            editedReagent = {
                ...assignJars(editedReagent, action.results?.jars),
                AgentAvailabilityId: action.results?.availability?.Id,
                AgentAvailability: action.results?.availability,
                MainAgentContent: action.results?.availability?.MainAgentContent,
            };
            let st = {
                ...state,
                values: {
                    ...state.values,
                    [REACTION_REACTANTS_ARRAY_FIELD_NAME]: [
                        ..._reagentList.filter((r: (IProduct | IReagent)) => (r.Id && r.Id > 0) ? r.Id !== editedReagent.Id : r.localUuid !== editedReagent.localUuid),
                        ..._productList,
                        editedReagent
                    ].sort(sortReactantsByType)
                }
            };
            return st;
        },
        [reactionModuleActions.reactionEdit.addProduct]: (action: { product: IProduct }) => ({
            ...state,
            values: {
                ...state.values,
                [REACTION_REACTANTS_ARRAY_FIELD_NAME]: [..._substancesList, action.product].sort(sortReactantsByType)
            }
        }),
        [reactionModuleActions.reactionEdit.setReactionParentRepkaTaskId]: (action: { task: IRepkaTask }) => ({
            ...state,
            values: {
                ...state.values,
                ParentRepkaTaskId: action.task?.RepkaTaskId
            }
        }),
        [reactionModuleActions.reactionEdit.setCurrentReactionName]: (action: { result: IReactionName }) => ({
            ...state,
            values: {
                ...state.values,
                Name: action.result?.Name,
                Number: action.result?.Number
            }
        }),
        [reactionModuleActions.reactionEdit.setNewReactionId]: (action: { reactionId: number }) => {
            return {
                ...state,
                values: {
                    ...state.values,
                    [REACTION_REACTANTS_ARRAY_FIELD_NAME]: _substancesList.map((substance: IProduct | IReagent | IArchivedReagent) => ({
                        ...substance,
                        ReactionId: action.reactionId
                    })),
                    ReactionParameters: state.values.ReactionParameters?.map((parameter: IReactionParameter) => ReactionParameter({
                        ...parameter,
                        ReactionId: action.reactionId
                    })),
                    ReactionYields: state.values.ReactionYields?.map((y: IReactionYield) => ReactionYield({
                        ...y,
                        ReactionId: action.reactionId
                    })),
                    [REACTION_IND_PARAMETERS_ARRAY_FIELD_NAME]: state.values[REACTION_IND_PARAMETERS_ARRAY_FIELD_NAME]?.map((par: ReactionIndicatorParameter) => ReactionIndicatorParameter({
                        ...par,
                        ReactionId: action.reactionId
                    })),
                }
            };
        },
        [reactionModuleActions.reactionEdit.resetClone]: (action) => {
            const res = _substancesList
                .filter((substance: IProduct | IReagent) => (isProduct(substance) && substance.IsMainProduct) || isReagent(substance))
                .map((substance: IProduct | IReagent) => {
                    if (isProduct(substance)) return ({
                        ...substance,
                        ReactionId: 0,
                        Id: 0,
                        MainAgentContent: 100,
                    });
                    else return {
                        ...substance,
                        AgentAvailability: null,
                        Jars: [],
                        ReactionId: 0,
                        //Id: 0,
                        FactualConsumption: null,
                        localUuid: uuidv4()
                    };
                });

            const childReagents = res.filter(r => r.ParentReagentId != null);
            const filledChildren = [];
            for (let child of childReagents) {
                const parent = res.find(r => r.Id == child.ParentReagentId)
                if (parent) {
                    child.ParentReagentLocalId = parent.localUuid;
                    child.ParentReagentId = null;
                    filledChildren.push(child);
                }
            }
            const rfinal = res
                .filter((substance: IProduct | IReagent) => (isProduct(substance) && substance.IsMainProduct) || isReagent(substance))
                .map((substance: IProduct | IReagent) => {
                    if (isProduct(substance)) return substance;
                    else {
                        const c = filledChildren.find(r => r.Id == substance.Id)
                        if (c != null) {
                            return {
                                ...substance,
                                ParentReagentLocalId: c.ParentReagentLocalId,
                                ParentReagentId: 0,
                                Id: 0,
                            };
                        }
                        else
                            return {
                                ...substance,
                                Id: 0,
                            };
                    }
                });

            return {
                ...state,
                values: {
                    ...state.values,
                    Id: 0,
                    OriginalId: action.originalId,
                    AuthorUserName: action.authorUserName,
                    IsCompleted: false,
                    InitialStageId: action.stageId,
                    MSText: '',
                    NMRText: '',
                    TLCText: '',
                    HPLCMSText: '',
                    AttachmentsText: '',
                    ExperimentalYield: null,
                    ExperimentalPercentYield: null,
                    Comment: '',
                    IsArchived: false,
                    CreationDate: undefined,
                    ChemistAbbreviation: undefined,
                    LaboratoryId: undefined,
                    ArchiveProjectName: undefined,
                    CompleteDate: undefined,
                    EndDate: undefined,
                    AdditionalName: '',

                    ReactionParameters: state.values.ReactionParameters?.map((parameter: IReactionParameter) => ReactionParameter({
                        ...parameter,
                        ReactionId: 0,
                        Id: 0
                    })),
                    ReactionYields: [],
                    HasMultipleYields: false,
                    RepkaTaskId: null,
                    QuickComment: '',
                    [REACTION_IND_PARAMETERS_ARRAY_FIELD_NAME]: state.values[REACTION_IND_PARAMETERS_ARRAY_FIELD_NAME].map(indParam => ({
                        ...indParam,
                        ReactionId: 0,
                        Id: 0
                    })),
                    [REACTION_REACTANTS_ARRAY_FIELD_NAME]: rfinal
                }
            };
        },
        [reactionModuleActions.reactionEdit.updateReagentsConsumption]: (action: { reagentConsumptions: { reagentId: number, consumption: number }[] }) => {
            return {
                ...state,
                values: {
                    ...state.values,
                    [REACTION_REACTANTS_ARRAY_FIELD_NAME]: [..._substancesList.map((substance: (IProduct | IReagent)) => {
                        if (!isReagent(substance)) return substance;
                        const consumption = action.reagentConsumptions.find((i) => i.reagentId === substance.Id)?.consumption;
                        if (consumption && !isNaN(Number(consumption))) {
                            substance.FactualConsumption += consumption;
                        }
                        return substance;
                    })]
                }
            };
        }
    }[action.type];
    return reducerFn ? reducerFn(action) : state;
};

export const
    getReactionFromForm = createSelector(
        (state) => getFormValues(REACTION_EDIT_FORM_NAME)(state),
        (data) => {
            if (data && data[REACTION_REACTANTS_ARRAY_FIELD_NAME]) {
                const { [REACTION_REACTANTS_ARRAY_FIELD_NAME]: [], ...rest } = data;
                return { ...rest };
            }
            return data;
        }
    ),
    getReactantsFromForm = createSelector(
        (state) => getFormValues(REACTION_EDIT_FORM_NAME)(state),
        (data) => (data && data[REACTION_REACTANTS_ARRAY_FIELD_NAME] && Array.isArray(data[REACTION_REACTANTS_ARRAY_FIELD_NAME]))
            ? data[REACTION_REACTANTS_ARRAY_FIELD_NAME]
            : []
    ),
    getReagentsFromForm = createSelector(
        [getReactantsFromForm],
        (data) => data.filter((item: IReagent | IProduct | IArchivedReagent) => isReagent(item))
    ),
    getIndicatorParametersFromForm = createSelector(
        (state) => getFormValues(REACTION_EDIT_FORM_NAME)(state),
        (data) => (data && data[REACTION_IND_PARAMETERS_ARRAY_FIELD_NAME] && Array.isArray(data[REACTION_IND_PARAMETERS_ARRAY_FIELD_NAME]))
            ? data[REACTION_IND_PARAMETERS_ARRAY_FIELD_NAME]
            : []
    ),
    getDeletedReagentsFromForm = createSelector(
        [getReagentsFromForm],
        (data) => data.filter((item: IReagent | IArchivedReagent) => item.IsDeleted)
    ),
    getProductsFromForm = createSelector(
        [getReactantsFromForm],
        (data) => data.filter((item: IReagent | IProduct | IArchivedReagent) => isProduct(item))
    ),
    getReactionReagentsFromSyncErrors = createSelector(
        (state) => getFormSyncErrors(REACTION_EDIT_FORM_NAME)(state),
        (data) => (data && data[REACTION_REACTANTS_ARRAY_FIELD_NAME])
            ? data[REACTION_REACTANTS_ARRAY_FIELD_NAME]
            : null
    ),
    getReagentsErroredFieldNames = createSelector(
        [getReactionReagentsFromSyncErrors],
        (data) => (
            (data && Array.isArray(data))
                ? data
                    .filter(i => i)
                    .map((i, idx) => Object.keys(i).map(j => `${REACTION_REACTANTS_ARRAY_FIELD_NAME}[${idx}].${j}`))
                    .reduce((acc, cur) => [...acc, ...cur], [])
                : []
        )
    ),
    isReactionFormIsValid = (state: IRootStore) => isValid(REACTION_EDIT_FORM_NAME)(state),
    isReactionFormIsPristine = (state: IRootStore) => isPristine(REACTION_EDIT_FORM_NAME)(state)
    ;
