import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import tableCss from '../table.m.less';
import style from './molecule.m.less';
import { LayoutBlock, TitleWithCounter, BcdTooltipWrapper } from '../../../shared/components';
import { AnalyticsSmallMoleculeTitles, AnalyticsSmallMoleculeKeys } from '../../models';
import { IconButtonExpand } from '../../../shared/components/IconButtonExpand';
import { IconButton } from '../../../shared/components/IconButton';
import classNames from 'classnames';
import { AnalyticTestsTable } from '../AnalyticTestsTable';
import { itemsToArraySelector } from '../../../shared/utils/selectors/resource-selectors';
import { loadBimsDictionaries } from '../../../dictionary/components/bimsLoaderHOC';
import SmallMoleculeEditPopup from '../SmallMoleculeEditPopup';
import { ButtonsContainer, PageHeaderWrap } from '../../../projects/components/ProjectsTable/style';
import Button from '@biocad/bcd-front-ui/controls/Button';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { AnalyticsModuleActions, analyticsQuickCommentActions } from '../../actions';
import CommentWrapper from '../../../shared/components/CommentWrapper';
import {_noop} from "../../../shared/utils/common";

const tableCols = [
  {
    title: AnalyticsSmallMoleculeTitles.SampleName,
    mappingProp: AnalyticsSmallMoleculeKeys.SampleName,
  },
  {
    title: AnalyticsSmallMoleculeTitles.SampleType,
    mappingProp: AnalyticsSmallMoleculeKeys.SampleType,
  },
  {
    title: AnalyticsSmallMoleculeTitles.Amount,
    mappingProp: AnalyticsSmallMoleculeKeys.Amount,
    alignRight: true,
  },
  {
    title: AnalyticsSmallMoleculeTitles.AnalyticTests,
    mappingProp: AnalyticsSmallMoleculeKeys.AnalyticTests,
    alignRight: true,
  },
];

let SmallMoleculeTable = ({
  noHeader,
  molecules,
  smTypes,
  createCurrentMolecule,
  deleteMolecule,
  setCurrentMolecule,
  clearCurrentMolecule,
  currentMolecule,
  readonly = false,
}) => {

  const editMolecule = (event, molecule, index) => {
    event.stopPropagation();
    setCurrentMolecule(molecule, index);
  };

  const closeModal = () => clearCurrentMolecule();

  const deleteMoleculeHandler = (event, molecule, index) => {
    event.stopPropagation();
    deleteMolecule({molecule, index});
  };

  const getIsExpandable = (tests) => {
    return tests.length && tests.filter(x => !x.IsDeleted).length;
  };

  const renderTableHeader = ({count}) => (
    <PageHeaderWrap>
        {
          noHeader 
          ? <div className={'flex-expander'} />
          : <TitleWithCounter title={'Образцы'} count={count}/>
        }
        <div className={noHeader ? style['reaction-analytics-button-container'] : ''}>
          <ButtonsContainer>
            {
              readonly ? '' :
                <Button
                    text={'Добавить образец'}
                    onAction={createCurrentMolecule}
                    size={'L'}
                    icon={faPlus}
                />
            }
          </ButtonsContainer>
        </div>
      </PageHeaderWrap>
  );

  return (
    <>
    <LayoutBlock header={renderTableHeader({count: molecules?.filter(x => !x.IsDeleted).length})}>
      <div className={classNames(tableCss.grid, style.template)}>
        <div className={tableCss.grid__header}>
          <HeaderRow />
        </div>
        <div className={tableCss.grid__body}>
          {
            molecules && molecules.map((molecule, index) => 
              !molecule.IsDeleted && 
                <MoleculeRow
                  key={molecule.Id || index}
                  molecule={molecule}
                  editHandler={readonly ? _noop : (event) => editMolecule(event, molecule, index)}
                  deleteHandler={readonly ? _noop : (event) => deleteMoleculeHandler(event, molecule, index)}
                  cellsComponent={<MoleculeRowCells molecule={molecule} smTypes={smTypes} />}
                  testsComponent={getIsExpandable(molecule.AnalyticTests)
                    ? <AnalyticTestsTable tests={molecule.AnalyticTests}/> 
                    : null
                  }
                  isExpandable={getIsExpandable(molecule.AnalyticTests)}
                />
            )
          }
        </div>
      </div>
      </LayoutBlock>
      {
        currentMolecule && 
          <SmallMoleculeEditPopup closeModal={closeModal} />
      }
    </>
  );
};

const HeaderRow = () => (
  <div className={tableCss.grid__row}>
    <div className={tableCss.grid__cell} />
    {
      tableCols.map((col, index) => 
        <div key={index} className={classNames(tableCss.grid__cell, col.alignRight && tableCss.alignRight)}>
          {col.title}
        </div>
      )
    }
    <div className={tableCss.grid__cell} />
  </div>
);

const MoleculeRow = ({molecule, cellsComponent, testsComponent, isExpandable, editHandler, deleteHandler}) => {
 
  const [expanded, setExpanded] = useState(false);
  const [tooltipExpanded, setTooltipExpanded] = useState(false);
  
  const toggleExpand = () => {
    isExpandable && setExpanded(prev => !prev);
  };

  useEffect(() => {
    !testsComponent && setExpanded(false);
  }, [testsComponent]);

  const stopPropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return(
    <>
      <div className={tableCss.grid__container}>
        <div className={tableCss.grid__row} onClick={toggleExpand}>
          <div className={tableCss.grid__cell}>
            <IconButtonExpand expanded={expanded} expandHandler={toggleExpand} disabled={!isExpandable}/>
          </div>
          {
            cellsComponent
          }
          <div className={classNames(tableCss.grid__cell, tableCss.alignRight)}>
            <IconButton
              iconClassName={'fo-comments'}
              active={!!molecule.Comment}
              onMouseEnter={() => setTooltipExpanded(true)}
              onClick={e => stopPropagation(e)}
            >
                {
                  tooltipExpanded &&
                  <BcdTooltipWrapper
                    align={'right'}
                    header='Комментарий'
                    body={molecule.Comment}
                    type='hover'
                    mousehold
                  />
                }
            </IconButton>
            {
              editHandler === _noop ? '' : <IconButton iconClassName={'fo-edit'} onClick={editHandler}/>
            }
            {
              deleteHandler === _noop ? '' : <IconButton iconClassName={'fo-trash'} onClick={deleteHandler}/>
            }
          </div>
        </div>
        {
          expanded && testsComponent
        }
      </div>
    </>
  );
};

const MoleculeRowCells = ({molecule, smTypes}) => {

  const cellContent = (field) => {
    switch (field) {

      case AnalyticsSmallMoleculeKeys.AnalyticTests: {
        return getTestsLength(molecule[AnalyticsSmallMoleculeKeys.AnalyticTests]);
      }
      case AnalyticsSmallMoleculeKeys.SampleType: {
        return getSampleTypeTitle(molecule[AnalyticsSmallMoleculeKeys.SampleType], smTypes);
      }
      default: {
        return molecule[field];
      }
    }
  };

  return tableCols.map(col =>
    <div 
      key={`${molecule.Id} ${col.mappingProp}`}
      className={classNames(tableCss.grid__cell, col.alignRight && tableCss.alignRight)}
    >
      {cellContent(col.mappingProp)}
    </div>
  );
};

const getSampleTypeTitle = (id, smTypes) => {
  return smTypes?.find(x => x.id === id)?.title;
};

const getTestsLength = (array) => {
  return array ? array.length : 0;
};

const mapStateToProps = ({resource, modules}) => ({
  molecules: modules.analytics.molecules,
  currentMolecule: modules.analytics.currentMolecule,
  smTypes: itemsToArraySelector(resource.dictionary.smTypes),
});

const mapDispatchToProps = dispatch => ({
  createCurrentMolecule: () => dispatch(AnalyticsModuleActions.createCurrentMolecule()),
  deleteMolecule: ({molecule, index}) => dispatch(AnalyticsModuleActions.deleteMolecule({molecule, index})),
  setCurrentMolecule: (molecule, index) => dispatch(AnalyticsModuleActions.setCurrentMolecule({molecule, index})),
  clearCurrentMolecule: () => dispatch(AnalyticsModuleActions.clearCurrentMolecule()),
});

SmallMoleculeTable = loadBimsDictionaries(SmallMoleculeTable);
SmallMoleculeTable = connect(mapStateToProps, mapDispatchToProps)(SmallMoleculeTable);

export {
  SmallMoleculeTable
};