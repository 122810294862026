import React from 'react';
import { projectTypesTranslate } from '../../../../projects/localization';
import { projectTypeEnum } from '../../../../projects/types/enum';
import { getClassNameByProjectType } from '../../../../projects/types/utils';
import dashboardStyles from '../../dashboard.m.less';
import { ProjectReactionsKeysEnum } from '../../../models';

export const ProjectNameWithType = React.memo(({item}) => 
  <>
    <i
      title={projectTypesTranslate[projectTypeEnum[item.Type]]} 
      className={`
        ${dashboardStyles.projectIcon} 
        ${getClassNameByProjectType(item)}
        ${item.Type === projectTypeEnum.generic && !item.IsScaling && dashboardStyles.generic}
        ${item.Type === projectTypeEnum.generic && item.IsScaling && dashboardStyles.scaling}
        ${item.Type === projectTypeEnum.innovative && dashboardStyles.innovative}`}/>
    <span>{item[ProjectReactionsKeysEnum.Name]}</span>
  </>
);