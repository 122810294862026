import { getEnum } from '../../shared/utils';

export const ReactionEventTypesEnum = getEnum({
  Unknown: 0,
  ReactionCreated: 1,
  ReactionUpdated: 2,
  ReactionCompleted: 3,
  ReagentAdded: 4,
  ReagentUpdated: 5,
  ReagentRemoved: 6,
  ProductAdded: 7,
  ProductUpdated: 8,
  JarAdded: 9,
  SpendingAdded: 10,
  YieldAdded: 11,
  YieldUpdated: 12,
  YieldRemoved: 13,
  ParametersAdded: 14,
  ParametersUpdated: 15,
  ParametersRemoved: 16,
  AttachmentAdded: 17,
  AttachmentRemoved: 18,
  ReagentJarSet: 19,
  ReagentJarReleased: 20,
  ReactionCloned: 21,
  TemplateUsed: 22,
});

export const ReactionEventBlockEnum = getEnum({
  Common: 0,
  Substances: 1,
  ReactionConditions: 2,
  Archive: 3,
  Yields: 4,
  Methodology: 5,
  Comment: 6,
  MS: 7,
  NMR: 8,
  TLC: 9,
  HPLCMS: 10,
  Attachments: 11,
});

export const ReactionsEventBlockNamesEnum = getEnum({
  [ReactionEventBlockEnum.Common]: 'Общий',
  [ReactionEventBlockEnum.Substances]: 'Св-ва вещества',
  [ReactionEventBlockEnum.ReactionConditions]: 'Условия реакции',
  [ReactionEventBlockEnum.Archive]: 'Архивные данные',
  [ReactionEventBlockEnum.Yields]: 'Выход реакции',
  [ReactionEventBlockEnum.Methodology]: 'Методика',
  [ReactionEventBlockEnum.Comment]: 'Комментарий',
  [ReactionEventBlockEnum.MS]: 'ВЭЖХ',
  [ReactionEventBlockEnum.NMR]: 'ЯМР',
  [ReactionEventBlockEnum.TLC]: 'ТСХ',
  [ReactionEventBlockEnum.HPLCMS]: 'ВЭЖХ-МС',
  [ReactionEventBlockEnum.Attachments]: 'Вложения',
});

export const ReactionsEventPropNamesEnum = getEnum({
  Name: 'Наименование',
  Density: 'Плотность',
  MainAgentContent: 'Основное вещество',
  Concentration: 'Концентрация',
  Eq: 'Eq',
  Mass: 'Масса',
  Amount: 'Кол-во',
  Volume: 'Объем',
  FactualConsumption: 'Факт. расход',
  IsRefCompound: 'Референсное соединение',
  IndexNumber: 'Индекс',
  Duration: 'Продолжительность',
  TemperatureInterval: 'Температура',
  TheoreticalYield: 'Теор. выход',
  ExperimentalYield: 'Эксп. выход',
  IsTrial: 'Пробная реакция',
  IsSuccessful: 'Реакция успешна',
  IsCompleted: 'Реакция завершена',
  VolumeBasedSolvents: 'Расчет растворителей от объема',
  ReactionType: 'Тип реакции',
  ReactionExtraConditions: 'Дополнительные условия',
  ChemistAbbreviation: 'ФИО автора',
  LaboratoryId: 'Лаборатория',
  ArchiveProjectName: 'Проект',
  StartDate: 'Дата начала',
  EndDate: 'Дата окончания',
  CompleteDate: 'Дата завершения',
  Comment: 'Комментарий',
});

export const genReactionsEventDescriptionEnum = ({ Name, AgentName, Amount, AgentAvailabilityNumber, AgentAvailabilityId, IsMainProduct, Block, OriginalId, TemplateId }) => getEnum({
  [ReactionEventTypesEnum.ReactionCreated]: 'Лист открыт',
  [ReactionEventTypesEnum.ReactionUpdated]: ReactionsEventUpdateDescriptionEnum(Block)[Block],
  [ReactionEventTypesEnum.ReactionCompleted]: 'Лист закрыт',
  [ReactionEventTypesEnum.ReagentAdded]: `Добавлен реагент ${AgentName ? `: ${AgentName}` : ''}`,
  [ReactionEventTypesEnum.ReagentUpdated]: `Реагент обновлен ${AgentName ? `: ${AgentName}` : ''}`,
  [ReactionEventTypesEnum.ReagentRemoved]: `Реагент удален ${AgentName ? `: ${AgentName}` : ''}`,
  [ReactionEventTypesEnum.ProductAdded]: 'Продукт добавлен',
  [ReactionEventTypesEnum.ProductUpdated]: 'Продукт обновлен',
  [ReactionEventTypesEnum.JarAdded]:
    `Добавлена новая банка на склад: ${Amount} г. ${(IsMainProduct === 'True' ? 'основного' : 'побочного')} продукта`,
  [ReactionEventTypesEnum.SpendingAdded]: `Внесен расход вещества ${Name}: ${Amount}г`,
  [ReactionEventTypesEnum.YieldAdded]: 'Выход реакции добавлен',
  [ReactionEventTypesEnum.YieldUpdated]: 'Выход реакции обновлен',
  [ReactionEventTypesEnum.YieldRemoved]: 'Выход реакции удален',
  [ReactionEventTypesEnum.ParametersAdded]: 'Внесено новое условие реакции',
  [ReactionEventTypesEnum.ParametersUpdated]: 'Условия реакции обновлены',
  [ReactionEventTypesEnum.ParametersRemoved]: 'Условия реакции удалены',
  [ReactionEventTypesEnum.AttachmentAdded]: `Добавлен новый файл "${Name}" в блок ${ReactionsEventBlockNamesEnum[Block]}`,
  [ReactionEventTypesEnum.AttachmentRemoved]: `Файл "${Name}" удален из блока ${ReactionsEventBlockNamesEnum[Block]}`,
  [ReactionEventTypesEnum.ReagentJarSet]:
    `Выбрана банка "${AgentAvailabilityNumber ? `Номер (склад): ${AgentAvailabilityNumber} , ` : ''}Id: ${AgentAvailabilityId}" для реагента ${AgentName}`,
  [ReactionEventTypesEnum.ReagentJarReleased]:
    `Банка "${AgentAvailabilityNumber ? `Номер (склад): ${AgentAvailabilityNumber}, ` : ''}Id: ${AgentAvailabilityId}" реагента ${AgentName} возвращена на склад`,
  [ReactionEventTypesEnum.ReactionCloned]: `Лист скопирован, оригинальный лист: ${OriginalId}`,
  [ReactionEventTypesEnum.TemplateUsed]: `Применен шаблон, Id реакции-шаблона: ${TemplateId}`

});

export const ReactionsEventUpdateDescriptionEnum = (block) =>
{
  const _default = `Текст блока ${ReactionsEventBlockNamesEnum[block]} обновлен`;
  return getEnum({
    default: _default,
    [ReactionEventBlockEnum.Common]:'Данные о реакции обновлены',
    [ReactionEventBlockEnum.Substances]:'Св-ва вещества обновлены',
    [ReactionEventBlockEnum.ReactionConditions]:'Условия реакции обновлены',
    [ReactionEventBlockEnum.Archive]:'Архивные данные обновлены',
    [ReactionEventBlockEnum.Yields]:'Данные о выходе реакции обновлены',
    [ReactionEventBlockEnum.Methodology]: _default,
    [ReactionEventBlockEnum.Comment]: _default,
    [ReactionEventBlockEnum.MS]: _default,
    [ReactionEventBlockEnum.NMR]: _default,
    [ReactionEventBlockEnum.TLC]: _default,
    [ReactionEventBlockEnum.HPLCMS]: _default,
    [ReactionEventBlockEnum.Attachments]: _default,
  });
};

export const ExtraConditionSystemName = getEnum({
  Autoclave:'Автоклав',
  Darkness:'Темнота',
  DeanStark:'Насадка Дина-Старка',
  InertAtmosphere:'Инертная атмосфера',
  MicrowaveIrradiation:'Микроволновое облучение',
  MolecularSieve:'Молекулярные сита',
  Reflux:'Кипячение',
  SchlenkTechnique:'Колба Шленка',
  SealedTube:'Закрытый сосуд',
  Sonication:'Ультразвуковое облучение',
  UVIrradiation:'Ультрафиолетовое облучение',
  FlowReactor: 'Проточный реактор',
  Photoreactor: 'Фотореактор',
});
