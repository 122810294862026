// @flow

import {resourceReducer, setResourceFailState, setResourceRequestState, setResourceSuccess} from '../../shared/utils';
import { reactionsResourceActions, reactionEventsHistoryResourceActions, reactionAttachmentsActions } from '../actions';
import {createSelector} from 'reselect';
import type {IRootStore} from '../../app/reducers';

export const reactionsResourceReducer = resourceReducer(reactionsResourceActions);
export const reactionEventsHistoryReducer = resourceReducer(reactionEventsHistoryResourceActions);
export const reactionAttachmentsReducer = resourceReducer(reactionAttachmentsActions);

export const getReactionEventsHistoryItems = createSelector(
    (state: IRootStore) => state.resource.eventsHistory.data,
    (data) => Object.values(data)
);