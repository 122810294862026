import Button from '@biocad/bcd-front-ui/controls/Button';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AnalyticsModuleActions } from '../../../../analytics/actions';
import Analytics from '../../../../analytics/components';
import { smEntityTypes } from '../../../../analytics/models';
import { StyledLayoutHeader } from '../../../../shared/components';
import { IconButtonBackwards } from '../../../../shared/components/IconButtonBackwards';
import { SanitizeHTML } from '../../../../shared/utils/sanitizeHTML';
import css from './style.m.less';
import { useQueryParams } from '../../../../shared/react-hooks/useQueryParams';
import { ChangesWatcher } from '../../../../shared/ChangesWatcher';
import { analyticsHasChangesSelector } from '../../../../analytics/reducers/analytics-module';

let AvailabilityAnalytics = ({ match, history, location, saveAnalyticsApi, cancelChanges }) => {

    const [id, setId] = useState(null);    
    const queryParams = useQueryParams(location.search);
    
    useEffect(() => {
      setId(match?.params?.id);
    }, [match?.params?.id]);

    const parseFormula = (s) => {
      if (!s || typeof s !== 'string') { return; }
      const result = [];

      for(let i = 0; i < s.length; i++) {
        result.push(/\d/i.test(s.charAt(i)) ? `<sub>${s.charAt(i)}</sub>` : s.charAt(i));
      }
      return result.join('');
    };

    const onSaveClick = () => {
      saveAnalyticsApi();
    };

    const onCancelClick = () => {
      cancelChanges();
      history.goBack();
    };
    

    return (
      <>
        <StyledLayoutHeader>
          <IconButtonBackwards />
          <h2>
            <span>Аналитика </span>
            <SanitizeHTML className={css.formula} html={parseFormula(queryParams?.agent)} />
            <span className={css.jarNumber}>Банка № {queryParams?.jar ? queryParams.jar : '–'}</span>
          </h2>
          <div className={'flex-expander'} />
          <div className={'buttons-holder'}>
            <Button text={'Отмена'}
                    size={'L'}
                    onAction={onCancelClick}
            />
            <Button text={'Сохранить'}
                    size={'L'}
                    onAction={onSaveClick}
                    view={'primary'}
            />
          </div>
        </StyledLayoutHeader>
        {
          id && <Analytics entity={{id, type: smEntityTypes.AgentAvailability}} noHeader={false}/>
        }
      </>
    );
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = dispatch => ({
  saveAnalyticsApi: () => dispatch(AnalyticsModuleActions.saveAnalyticsApi.request()),
  cancelChanges: () => dispatch(AnalyticsModuleActions.completeChanges()),
});


AvailabilityAnalytics = withRouter(connect(mapStateToProps, mapDispatchToProps)(AvailabilityAnalytics));

AvailabilityAnalytics = ChangesWatcher(
  { hasChangesSelector: analyticsHasChangesSelector, },
  AvailabilityAnalytics,
);

export default AvailabilityAnalytics;
