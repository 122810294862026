export class AnalyticsTest {
  AnalyticSmallMoleculeId: number | null;
  BimsId                 : string       ;
  Comment                : string       ;
  DeadlineDate           : string       ;
  Id                     : number | null;
  Link                   : string       ;
  Result                 : any          ;
  IsDeleted              : boolean      ;
  ShouldDelete           : boolean      ;
  StatusCode             : string       ;
  TestClassBimsId        : string       ;

  constructor ({test}) {
    this.AnalyticSmallMoleculeId = test?.AnalyticSmallMoleculeId || null      ;
    this.BimsId                  = test?.BimsId                  || ''        ;
    this.Comment                 = test?.Comment                 || ''        ;
    this.DeadlineDate            = test?.DeadlineDate            || undefined ;
    this.Id                      = test?.Id                      || 0         ;
    this.IsDeleted               = test?.IsDeleted               || false     ;
    this.Link                    = test?.Link                    || ''        ;
    this.Result                  = test?.Result                  || ''        ;
    this.ShouldDelete            = test?.ShouldDelete            || false     ;
    this.StatusCode              = test?.StatusCode              || ''        ;
    this.TestClassBimsId         = test?.TestClassBimsId         || ''        ;
  }

  deleteSelf() {
    this.IsDeleted = true;
    this.ShouldDelete = true;
  }
}

export const AnalyticsTestKeys = {
  AnalyticSmallMoleculeId: 'AnalyticSmallMoleculeId',
  BimsId                 : 'BimsId'                 ,
  Comment                : 'Comment'                ,
  DeadlineDate           : 'DeadlineDate'           ,
  Id                     : 'Id'                     ,
  Link                   : 'Link'                   ,
  Result                 : 'Result'                 ,
  IsDeleted              : 'IsDeleted'              ,
  ShouldDelete           : 'ShouldDelete'           ,
  StatusCode             : 'StatusCode'             ,
  TestClassBimsId        : 'TestClassBimsId'        ,
};

export const AnalyticsTestTitles = {
  Comment: 'Комментарий',
  DeadlineDate: 'Срок сдачи',
  Result: 'Результат',
  StatusCode: 'Статус',
  TestClassBimsId: 'Анализ',
};
