import styled from 'styled-components';

export const JarStockHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const JarSrockControlsSpanStyled = styled.span`
  display: flex;
  align-items: center;
  button {
    margin-left: 16px;
  }
`;