// @flow
import {moduleAction, resourceActions} from '../../shared/utils';

export const DESCENDANTS = 'DESCENDANTS';

export const descendantsResourceActions = {
    ...resourceActions(DESCENDANTS)
};

const CURRENT_ITEM = 'CURRENT_ITEM';
const LIST = 'LIST';

const SET = 'SET';
const DELETE = 'DELETE';

export const descendantsModuleActions = {
    setCurrentDescendant: moduleAction(
        DESCENDANTS,
        [CURRENT_ITEM, SET],
        ({id, name}) => ({id, name})
    ),
    deleteCurrentDescendant: moduleAction(
        DESCENDANTS,
        [CURRENT_ITEM, DELETE],
        () => null
    ),
    setDescendantsList: moduleAction(
        DESCENDANTS,
        [LIST, SET],
        ({list}) => ({list})
    ),
    clearDescendantsList: moduleAction(
        DESCENDANTS,
        [LIST, DELETE],
        () => null
    )
};