// @flow

import { resourceActions, CRUDActionCreate } from '../../shared/utils/action-creators';
import { STAGE_INDICATORS_MODULE } from './module';

export const stageIndicatorResourceActions = {
    ...resourceActions(STAGE_INDICATORS_MODULE),
    batchAllActions: CRUDActionCreate(
        STAGE_INDICATORS_MODULE,
        ['BATCH_ALL_ACTIONS'],
        ({ indicators = [], stageId }) => ({ indicators, stageId })
    ),
    explicitlySetRequestState: CRUDActionCreate(
        STAGE_INDICATORS_MODULE,
        ['EXPLICITLY_SET_REQUEST_STATE'],
        ({pending, requestSucceed}) => ({pending, requestSucceed})
    )
};
