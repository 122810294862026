// @flow

import { FieldValidators } from '../../../shared/validators/field-validators';
import { ProductKeyNames } from '../../model';

export const ProductFieldValidators: { [key: string]: Array<(value: any)=>?Error> } = {
	[ProductKeyNames.LaboratoryId]                : [FieldValidators.required],
	[ProductKeyNames.Location]                    : [FieldValidators.required],
	[ProductKeyNames.ShortName]                   : [FieldValidators.pattern('^[a-zA-Zа-яА-ЯёЁ0-9\\s\\-\\+\\]\\[\\}\\{\\)\\(\\,]*$')],
	[ProductKeyNames.Yield]                       : [FieldValidators.minNum(0), FieldValidators.required],
	[ProductKeyNames.Concentration]               : [FieldValidators.minNum(0)],
	[ProductKeyNames.ExperimentalMainAgentContent]: [FieldValidators.required, FieldValidators.minNum(0.1), FieldValidators.maxNum(100)],
	[ProductKeyNames.MolarWeight]                 : [FieldValidators.required],
	[ProductKeyNames.Compound]                    : [FieldValidators.required, ((value: {}) => Object.keys(value).length ? undefined : new Error('Необходимо ввести структурную формулу побочного продукта'))],
	[ProductKeyNames.Name]                        : [FieldValidators.required]
};
