// @flow
import type { IMergedStage, IObtainmentStage, IStageSubstance } from '../models';
import { MergedStage, ObtainmentStage, StageSubstance } from '../models';
import { stageModuleActions, stageResourceActions } from '../actions';
import { createSelector } from 'reselect';
import { STAGE_MERGE_OPTION_ENUM } from '../constants';
import type { IAgent, ICompound } from '../../agents/models/i-agent';
import { schemesResourceActions } from '../../schemes/actions';
import type { IObtainmentScheme } from '../../schemes/models';
import type {IFinaleCandidate, IProject} from '../../projects/models';
import { CompoundFormula } from '../../shared/models';
import { ObtainmentScheme } from '../../schemes/models';
import type { IRootStore } from '../../app/reducers';
import type { ICanonicalizerResponse } from '../../shared/services/compound/canonicalizer-service';
import { CanonicalizerResponse } from '../../shared/services/compound/canonicalizer-service';
import { getAllStageIndicators } from "../../stage-indicators/reducers";
import type { IStageIndicator } from '../../stage-indicators/models';
import {
  STAGE_INDICATORS_ARRAY_NAME,
  STAGE_INDICATORS_FORM_NAME
} from "../../stage-indicators/components/StageIndicatorsList/StageIndicatorsForm";
import { getFormValues } from "redux-form";
import {getProjectsSelector} from '../../projects/reducers';

export interface IStageModuleState {
  editStage: {
    substances: Map<{ [key: number]: IStageSubstance }>,
    deletedSubstances: IStageSubstance[],
    mergeOption: string,
    current: IObtainmentStage,
    currentScheme: IObtainmentScheme,
    mergeableSchemesIds: number[],
    isFirstStageInScheme: boolean,
    preSaveReady: boolean,
    preSaveSchemeReady: boolean,
    saveOrDeleteSuccess: boolean,
    schemeUpdateSuccess: boolean,
    marvinPending: boolean,
    mergeableSchemesLastStages: [],
    projectId: number,
  }
}

const defaultState: IStageModuleState = {
  editStage: {
    substances: new Map(Array(3)
      .fill(null)
      .map((el, idx, arr) => [
        idx + 1,
        StageSubstance({
          position: idx + 1,
          title: (idx + 1 === arr.length) ? 'Продукт' : `Реактант ${idx + 1}`,
          isSelected: idx === 0,
        })]
      )),
    deletedSubstances: [],
    mergeOption: STAGE_MERGE_OPTION_ENUM.DEFAULT,
    current: {},
    currentScheme: {},
    isFirstStageInScheme: null,
    preSaveReady: null,
    preSaveSchemeReady: null,
    saveOrDeleteSuccess: null,
    schemeUpdateSuccess: null,
    marvinPending: false,
    mergeableSchemesLastStages: [],
    projectId: null,
  }
};

export const stageModuleReducer = (state: IStageModuleState = defaultState, action: any = {}) => {
  const reducedFn = {

    [stageModuleActions.setSubstance]: (action: { payload: IAgent | ICompound | IObtainmentStage | IFinaleCandidate, substancePosition: number }): IStageModuleState => {
      const substances = new Map<{ [p: number]: IStageSubstance }>(state.editStage.substances);
      let { IsFinalCandidate } = state.editStage.current;
      try {
        if (!substances.has(action.substancePosition)) { // если у субстанции нет позиции, передано не правильно.
          console.warn('Substance lacks position field');
          return state;
        }
        let substance: IStageSubstance;
        const selectedSubstance: IStageSubstance = substances.get(action.substancePosition);

        // Если у реактанта не было картинки, значит это был пустой реактант, значит надо добавить новый пустой реактант
        const selectedSubstanceWasEmpty = !('Thumbnail' in selectedSubstance && selectedSubstance.Thumbnail);

        switch (state.editStage.mergeOption) { // в зависимости от выбранного способа получения субстанции разный payload
          case STAGE_MERGE_OPTION_ENUM.AGENTS: { // если агент, то от него надо выдернуть compound и его айдишник и передать уго в AgentId
            const agent: IAgent = action.payload;
            const AgentId = agent?.Id;
            const { Thumbnail, Formula, SmilesFormula, MolecularFormula, IsAntibody, IsPolymer } = agent?.Compound;
            const formulaId = agent?.Compound?.Id;
            const { Id, position, title } = selectedSubstance;
            substance = StageSubstance({
              AgentId,
              Thumbnail, Formula, MolecularFormula, SmilesFormula, IsAntibody, IsPolymer,
              Id, position, title,
              formulaId,
              CustomStageCompoundId: formulaId,
              Name: agent?.Name,
              MolarWeight: agent?.Compound?.MolarWeight,
              isSelected: true,
            });
            IsFinalCandidate = false;
            break;
          }
          case STAGE_MERGE_OPTION_ENUM.MARVIN: { // если из марвина пришло, то дергаем поля из компаунда, и что очень важно сохраняем поле CustomStageCompoundId и Id
            const compound: ICompound = action.payload;
            const
              { Thumbnail, Formula, SmilesFormula, MolecularFormula, Name, MolarWeight, IsAntibody, IsPolymer } = compound,
              { position, Id, title, CustomStageCompoundId, formulaId, canonicalized } = selectedSubstance;
            substance = StageSubstance({
              Thumbnail, Formula, SmilesFormula, MolecularFormula, Name, MolarWeight, IsAntibody, IsPolymer,
              position, Id, title, CustomStageCompoundId, formulaId, canonicalized,
              isSelected: true
            });
            IsFinalCandidate = false;
            break;
          }
          case STAGE_MERGE_OPTION_ENUM.SCHEME: { // при загрузке из схемы главное передать поле SourceSchemeId
            const stage: IObtainmentStage = action.payload;
            const
              SourceSchemeId = stage?.ObtainmentSchemeId,
              { Thumbnail, Formula, SmilesFormula, MolecularFormula, Name, MolarWeight, IsAntibody, IsPolymer } = stage?.Compound,
              { position, Id, title } = selectedSubstance;
            substance = StageSubstance({
              SourceSchemeId,
              Thumbnail, Formula, SmilesFormula, MolecularFormula, Name, MolarWeight, IsAntibody, IsPolymer,
              position, Id, title,
              isSelected: true,
            });
            break;
          }
          case STAGE_MERGE_OPTION_ENUM.UNITE_SCHEME: {
            const stage: IObtainmentStage = action.payload;
            const
              uniteSchemeId = stage?.ObtainmentSchemeId,
              { Thumbnail, Formula, SmilesFormula, MolecularFormula, Name, MolarWeight, IsAntibody, IsPolymer } = stage?.Compound,
              { position, Id, title } = selectedSubstance;
            substance = StageSubstance({
              uniteSchemeId,
              Thumbnail, Formula, SmilesFormula, MolecularFormula, Name, MolarWeight, IsAntibody, IsPolymer,
              position, Id, title,
              isSelected: true,
            });
            break;
          }
          case STAGE_MERGE_OPTION_ENUM.FINAL_CANDIDATE: { // из целевой молекулы надо взять компаунд и айдишник оригинального компаунда
            const candidate: IFinaleCandidate = action.payload;
            const
              SourceSchemeId = state.editStage.current?.ObtainmentSchemeId,
              { Thumbnail, Formula, SmilesFormula, MolecularFormula, Name, MolarWeight, IsAntibody, IsPolymer } = candidate?.Compound,
              { position, title, Id, formulaId } = selectedSubstance;
            substance = StageSubstance({
              SourceSchemeId,
              CustomStageCompoundId: formulaId,
              Thumbnail, Formula, SmilesFormula, MolecularFormula, Name, MolarWeight, IsAntibody, IsPolymer,
              position, title, Id,
              formulaId,
              isSelected: true,
            });
            IsFinalCandidate = true;
            break;
          }
          default: {
            substance = selectedSubstance;
            break;
          }
        }
        if (substance.position === substances.size) { // значит продукт
          substances.set(substances.size, substance);
        }
        else { // иначе реактант
          substances.set(substance.position, StageSubstance({
            ...substance,
            title: substance.title || `Реактант ${substance.position}`
          }));
          if (selectedSubstanceWasEmpty && substances.get(substances.size - 1).Thumbnail) {
            const product: IStageSubstance = substances.get(substances.size);
            substances.set(product.position + 1, StageSubstance({ ...product, position: product.position + 1 })); // сдвинуть продукт на одну позицию
            substances.set(product.position, StageSubstance({ position: product.position })); // вместо продукта пустой реактант
          }
        }
      } catch (e) {
        console.error(e);
      }
      return {
        ...state,
        editStage: {
          ...state.editStage,
          substances,
          current: {
            ...state.editStage.current,
            IsFinalCandidate,
          }
        }
      };
    },

    [stageModuleActions.selectSubstance]: (action: { position: number }): IStageModuleState => {
      const substances = new Map<{ [p: number]: IStageSubstance }>(state.editStage.substances);
      try {
        const
          prevSelected: IStageSubstance = [...state.editStage.substances.values()].find((substance: IStageSubstance) => substance.isSelected),
          toBeSelected: IStageSubstance = substances.get(action.position);
        if (prevSelected) {
          substances.set(prevSelected.position, StageSubstance({ ...prevSelected, isSelected: false }));
        }
        if (toBeSelected) {
          substances.set(action.position, StageSubstance({ ...toBeSelected, isSelected: true }));
        }
      } catch (e) {
        console.error(e);
      }
      return {
        ...state,
        editStage: {
          ...state.editStage,
          substances,
          mergeOption: STAGE_MERGE_OPTION_ENUM.DEFAULT,
        }
      };
    },

    [stageModuleActions.deleteSubstance]: (action: { position: number }): IStageModuleState => {
      const
        substances = new Map<{ [p: number]: IStageSubstance }>(state.editStage.substances),
        deletedSubstances = [...state.editStage.deletedSubstances];
      let { mergeOption } = state.editStage;
      try {
        const
          substanceToDelete: IStageSubstance = substances.get(action.position),
          prevSubstance: IStageSubstance = substances.get(substanceToDelete.position - 1);
        if (substanceToDelete) {
          if ('Id' in substanceToDelete) {
            deletedSubstances.push(
              StageSubstance({
                ...substanceToDelete,
                IsDeleted: true,
              }));
          }
          substances.set(substanceToDelete.position, StageSubstance({ position: substanceToDelete.position }));
          if (prevSubstance && substanceToDelete.isSelected) {
            substances.set(prevSubstance.position, StageSubstance({ ...prevSubstance, isSelected: true }));
          }
          if (substances.size > 3) {
            substances.forEach((value: IStageSubstance, key: number, map: Map<{ [number]: IStageSubstance }>) => {
              if (key > substanceToDelete.position) {
                map.delete(key);
                map.set(key - 1, StageSubstance({ ...value, position: key - 1 }));
              }
            });
            const product: IStageSubstance = substances.get(substances.size);
            substances.set(product.position + 1, StageSubstance({ ...product, position: product.position + 1 }));
            substances.set(product.position, StageSubstance({ position: product.position }));
          }
          if (substanceToDelete.isSelected) {
            mergeOption = STAGE_MERGE_OPTION_ENUM.DEFAULT;
          }
        }
      } catch (e) {
        console.error(e);
      }
      return {
        ...state,
        editStage: {
          ...state.editStage,
          substances,
          deletedSubstances,
          mergeOption,
        }
      };
    },

    [stageModuleActions.setMarvinPending]: (action: { pending: boolean }): IStageModuleState => {
      const
        marvinPending = action.pending,
        substancesEntries = [...state.editStage.substances.entries()];
      const updatedSubstances = substancesEntries
        .map(([key: number, substance: IStageSubstance]) => ([key, { ...substance, isDisabled: marvinPending && !substance.isSelected }]));
      return {
        ...state,
        editStage: {
          ...state.editStage,
          substances: new Map(updatedSubstances),
          marvinPending
        }
      };
    },

    [stageResourceActions.get.success]: (action: { result: IObtainmentStage }): IStageModuleState => {
      const substances = new Map<{ [p: number]: IStageSubstance }>();
      const { IsFinalCandidate, Number } = action.result;
      try {
        const firstSubstance: IStageSubstance = action.result.PreviousStageCompound
          ? StageSubstance({
            ...action.result.PreviousStageCompound,
            formulaId: action.result.PreviousStageCompound?.Id,
            Id: action.result.PreviousStageId,
            title: 'Реактант 1',
            position: 1,
            isSelected: true
          })
          : StageSubstance({
            title: 'Реактант 1',
            position: 1,
            isSelected: true
          });
        substances.set(firstSubstance.position, firstSubstance);
        if (action.result.MergedStages
          && Array.isArray(action.result.MergedStages)
          && action.result.MergedStages.length) {
          for (let mergedStage: IMergedStage of action.result.MergedStages) {
            substances.set(substances.size + 1, StageSubstance({
              ...mergedStage,
              ...mergedStage.Formula,
              Id: mergedStage.Id || 0,
              AgentId: mergedStage.AgentId,
              position: substances.size + 1,
              title: `Реактант ${substances.size + 1}`,
              formulaId: mergedStage.Formula?.Id,
            }));
          }
        }
        if (action.result.CanBeModifiedOrDeleted) {
          const emptyReactant: IStageSubstance = StageSubstance({ position: substances.size + 1 });
          substances.set(emptyReactant.position, emptyReactant);
        }
        const product: IStageSubstance = action.result.Compound
          ? StageSubstance({
            ...action.result.Compound,
            Id: null,
            formulaId: action.result?.Compound?.Id,
            title: 'Продукт',
            position: substances.size + 1,
          })
          : StageSubstance({ position: substances.size + 1 });
        substances.set(product.position, product);
      } catch (e) {
        console.error(e);
      }
      return {
        ...state,
        editStage: {
          ...state.editStage,
          substances,
          current: {
            ...state.editStage.current,
            IsFinalCandidate,
          },
          isFirstStageInScheme: Number === 1 // значит стадия первая в схеме, не путать с TreeNumber
        }
      };
    },

    [stageModuleActions.setMergeOption]: (action: { option: string }): IStageModuleState => {
      let mergeOption;
      if (!(Object.values(STAGE_MERGE_OPTION_ENUM).includes(action.option))) {
        mergeOption = STAGE_MERGE_OPTION_ENUM.default;
        console.error('Merge option must be included in STAGE_MERGE_OPTION_ENUM');
      } else {
        mergeOption = action.option;
      }
      return {
        ...state,
        editStage: { ...state.editStage, mergeOption }
      };
    },

    [stageModuleActions.setCurrentStageId]: (action: { id: number }): IStageModuleState => ({
      ...state,
      editStage: {
        ...state.editStage,
        current: {
          Id: !isNaN(Number(action.id, 10)) ? Number(action.id) : null
        }
      }
    }),

    [stageModuleActions.setCurrentSchemeId]: (action: { schemeId: number }): IStageModuleState => ({
      ...state,
      editStage: {
        ...state.editStage,
        current: {
          ...state.editStage.current,
          ObtainmentSchemeId: !isNaN(Number(action.schemeId, 10)) ? Number(action.schemeId) : null
        }
      }
    }),

    [stageModuleActions.setCurrentCanBeModifiedOrDeleted]: (action: { flag: boolean }): IStageModuleState => ({
      ...state,
      editStage: {
        ...state.editStage,
        current: {
          ...state.editStage.current,
          CanBeModifiedOrDeleted: action.flag,
        }
      }
    }),

    [stageModuleActions.setCurrentNumber]: (action: { number: number }): IStageModuleState => ({
      ...state,
      editStage: {
        ...state.editStage,
        current: {
          ...state.editStage.current,
          TreeNumber: action.number,
        }
      }
    }),

    [stageModuleActions.setCurrentReqiredPurificationsCount]: (action: { count: number }): IStageModuleState => ({
      ...state,
      editStage: {
        ...state.editStage,
        current: {
          ...state.editStage.current,
          RequiredPurificationsCount: action.count,
        }
      }
    }),  
    
    [stageModuleActions.setPurificationBundleType]: (action: { value: number }): IStageModuleState => ({
      ...state,
      editStage: {
        ...state.editStage,
        current: {
          ...state.editStage.current,
          PurificationBundleType: action.value,
        }
      }
    }),   

    [schemesResourceActions.get.success]: (action: { result: IObtainmentScheme }): IStageModuleState => ({
      ...state,
      editStage: {
        ...state.editStage,
        currentScheme: action.result,
      }
    }),

    [schemesResourceActions.update.success]: (): IStageModuleState => ({
      ...state,
      editStage: {
        ...state.editStage,
        schemeUpdateSuccess: true,
      }
    }),

    [schemesResourceActions.update.request]: (): IStageModuleState => ({
      ...state,
      editStage: {
        ...state.editStage,
        schemeUpdateSuccess: null,
        preSaveSchemeReady: null,
      }
    }),

    [schemesResourceActions.update.failure]: (): IStageModuleState => ({
      ...state,
      editStage: {
        ...state.editStage,
        schemeUpdateSuccess: false,
      }
    }),

    [schemesResourceActions.getMergeableSchemes.success]: (action: { result: IObtainmentScheme[] }): IStageModuleState => ({
      ...state,
      editStage: {
        ...state.editStage,
        mergeableSchemesIds: action.result.map((scheme: IObtainmentScheme) => scheme.Id),
      }
    }),

    [stageModuleActions.initEntrySubstance]: (action: { result: IObtainmentStage }): IStageModuleState => {
      let entrySubstance: IStageSubstance;
      const substances = new Map<{ [p: number]: IStageSubstance }>(state.editStage.substances);
      const isFirstStageInScheme = (state.editStage.isFirstStageInScheme !== null)
        ? state.editStage.isFirstStageInScheme
        : !action.result?.Id;
      if (action.result?.ObtainmentSchemeId === state.editStage.current?.ObtainmentSchemeId) { // обработка случая когда стадия первая в схеме, должно запрашиваться один раз при загрузке страницы.
        if (!isFirstStageInScheme) {
          const
            SourceSchemeId = action.result.ObtainmentSchemeId,
            { Id } = action.result,
            { MolecularFormula, Thumbnail, Formula, SmilesFormula, IsAntibody, IsPolymer, Name } = action.result.Compound;
          entrySubstance = StageSubstance({
            SourceSchemeId,
            MolecularFormula, Thumbnail, Formula, SmilesFormula, IsAntibody, IsPolymer,
            Id,
            position: 1,
            title: 'Реактант 1',
            Name: Name
          });
          substances.set(1, entrySubstance);
        } else {
          substances.set(substances.size - 1, StageSubstance({ ...substances.get(substances.size), position: substances.size - 1 }));
          substances.delete(substances.size);
        }
      }
      return {
        ...state,
        editStage: {
          ...state.editStage,
          substances,
          isFirstStageInScheme,
        }
      };
    },

    [schemesResourceActions.getLastStage.success]: (action: { result: IObtainmentStage }): IStageModuleState => ({
      ...state,
      editStage: {
        ...state.editStage,
        lastStageOfScheme: action.result,
      }
    }),

    [stageResourceActions.update.success]: (): IStageModuleState => ({
      ...state,
      editStage: {
        ...state.editStage,
        saveOrDeleteSuccess: true,
      }
    }),

    [stageResourceActions.update.failure]: (): IStageModuleState => ({
      ...state,
      editStage: {
        ...state.editStage,
        saveOrDeleteSuccess: false,
      }
    }),

    [stageResourceActions.update.request]: (): IStageModuleState => ({
      ...state,
      editStage: {
        ...state.editStage,
        saveOrDeleteSuccess: null,
        preSaveReady: false,
      }
    }),

    [stageResourceActions.create.success]: (): IStageModuleState => ({
      ...state,
      editStage: {
        ...state.editStage,
        saveOrDeleteSuccess: true,
      }
    }),

    [stageResourceActions.create.failure]: (): IStageModuleState => ({
      ...state,
      editStage: {
        ...state.editStage,
        saveOrDeleteSuccess: false,
      }
    }),

    [stageResourceActions.create.request]: (): IStageModuleState => ({
      ...state,
      editStage: {
        ...state.editStage,
        saveOrDeleteSuccess: null,
        preSaveReady: false,
      }
    }),

    [stageResourceActions.delete.success]: (): IStageModuleState => ({
      ...state,
      editStage: {
        ...state.editStage,
        saveOrDeleteSuccess: true,
      }
    }),

    [stageResourceActions.delete.failure]: (): IStageModuleState => ({
      ...state,
      editStage: {
        ...state.editStage,
        saveOrDeleteSuccess: false,
      }
    }),

    [stageResourceActions.delete.request]: (): IStageModuleState => ({
      ...state,
      editStage: {
        ...state.editStage,
        saveOrDeleteSuccess: null,
        preSaveReady: false,
      }
    }),

    [stageModuleActions.preSaveStage]: (action: { stage: IObtainmentStage }): IStageModuleState => {

      let
        current: IObtainmentStage = { ...state.editStage.current },
        preSaveReady = state.editStage.preSaveReady,
        uniteSchemeId = null;
      const currentBeforePreSave = { ...state.editStage.current };
      try {
        const
          { substances } = state.editStage,
          deletedSubstances = state.editStage.deletedSubstances
            .filter(s => s.Id)
            .map((substance: IStageSubstance): IMergedStage => ({
              ...substance,
              Formula: {
                Thumbnail: substance.Thumbnail,
                SmilesFormula: substance.SmilesFormula,
                MolecularFormula: substance.MolecularFormula,
                Formula: substance.Formula,
                Id: substance.formulaId,
                IsAntibody: substance.IsAntibody, 
                IsPolymer: substance.IsPolymer,
                Name: substance.Name,
              }
            })),
          { size } = substances;
        const
          product: IStageSubstance = substances.get(size),
          entryReactant: IStageSubstance = substances.get(1),
          reactants: IStageSubstance[] = [...substances.values()]
            .filter((substance: IStageSubstance) => substance.position !== 1 && substance.position !== size && (substance.SmilesFormula || substance.MolarWeight || substance.IsAntibody || substance.IsPolymer));
        const MergedStages: IMergedStage[] = reactants
          .map((substance: IStageSubstance) => {
            const
              { Id, AgentId, SourceSchemeId, Name, MolarWeight, IsDeleted, CustomStageCompoundId } = substance,
              { Formula, Thumbnail, SmilesFormula, MolecularFormula, formulaId, IsAntibody, IsPolymer } = substance;
            return MergedStage({
              CustomStageCompoundId,
              Id, Name, IsDeleted, AgentId, SourceSchemeId,
              Formula: CompoundFormula({
                ...(formulaId ? { Id: formulaId } : null),
                Formula, Thumbnail, SmilesFormula, MolecularFormula, Name, IsAntibody, IsPolymer,
              }),
              TargetStageId: action.stage?.Id,
            });
          })
          .concat(deletedSubstances);
        const Compound: ICompound = {
          Id: product.formulaId || product.CustomStageCompoundId || 0,
          SmilesFormula: product.SmilesFormula,
          Formula: product.Formula,
          Thumbnail: product.Thumbnail,
          MolecularFormula: product.MolecularFormula,
          Name: product.Name,
          MolarWeight: product.MolarWeight,
          IsAntibody: product.IsAntibody,
          IsPolymer: product.IsPolymer,
        };
        current = {
          ...action.stage,
          ...state.editStage.current,
          MergedStages,
          Compound,
          ...(Compound?.Id ? { CompoundId: Compound?.Id } : null),
          PreviousStageCompound: {
            Id: entryReactant.formulaId || 0,
            Formula: entryReactant.Formula,
            MolecularFormula: entryReactant.MolecularFormula,
            SmilesFormula: entryReactant.SmilesFormula,
            Thumbnail: entryReactant.Thumbnail,
            Name: entryReactant.Name,
            MolarWeight: entryReactant.MolarWeight,
            IsAntibody: entryReactant.IsAntibody,
            IsPolymer: entryReactant.IsPolymer,
          },
          PreviousStageId: entryReactant.Id,
        };
        preSaveReady = true;
        uniteSchemeId = entryReactant.uniteSchemeId;
      } catch (e) {
        console.error(e);
        current = { ...currentBeforePreSave };
        preSaveReady = false;
        throw e;
      }

      return {
        ...state,
        editStage: {
          ...state.editStage,
          current,
          preSaveReady,
          uniteSchemeId,
        }
      };
    },

    [stageModuleActions.preSaveScheme]: (): IStageModuleState => {
      const entrySubstance: IStageSubstance = state.editStage.substances.get(1);
      let currentScheme: IObtainmentScheme = state.editStage.currentScheme;
      let preSaveSchemeReady;
      const
        { Formula, Thumbnail, SmilesFormula, MolecularFormula, AgentId, IsAntibody, IsPolymer, Name } = entrySubstance;
      try {
        currentScheme = ObtainmentScheme({
          ...currentScheme,
          CustomStageCompound: {
            Formula, Thumbnail, SmilesFormula, MolecularFormula,
            Id: currentScheme.CustomStageCompoundId || 0,
            IsAntibody, IsPolymer, Name,
          },
          InitialAgentId: AgentId,
        });
        preSaveSchemeReady = true;
      } catch (e) {
        preSaveSchemeReady = false;
        throw e;
      }
      return {
        ...state,
        editStage: {
          ...state.editStage,
          currentScheme,
          preSaveSchemeReady
        }
      };
    },

    [stageModuleActions.destroyModule]: () => (
      defaultState
    ),

    [stageModuleActions.updateCanonicalizeStatus]: (action: { position: number, status: ICanonicalizerResponse }): IStageModuleState => {
      const substances = new Map<{ [p: number]: IStageSubstance }>(state.editStage.substances);
      substances.set(action.position, StageSubstance({ ...substances.get(action.position), canonicalized: CanonicalizerResponse(action.status) }));
      return {
        ...state,
        editStage: {
          ...state.editStage,
          substances
        }
      };
    },

    [stageModuleActions.setMergeableSchemesLastStages]: (action: { stages: [] }): IStageModuleState => {
      return {
        ...state,
        editStage: {
          ...state.editStage,
          mergeableSchemesLastStages: [...action.stages],
        }
      };
    },

    [stageModuleActions.setProjectId]: (action: { projectId: number }): IStageModuleState => ({
      ...state,
      editStage: {
        ...state.editStage,
        projectId: action.projectId,
      }
    }),

  }[action.type];
  return reducedFn ? reducedFn(action) : state;
};


// Selectors
export const
  listStageSubstancesSelector: (any) => IStageSubstance[] = createSelector(
    (state) => state.modules.stage.editStage?.substances,
    (data) => [...data.values()]
  ),
  getStageEntrySelector: (any) => IStageSubstance[] = createSelector(
    (state) => state.modules.stage.editStage?.substances,
    (data: Map) => data.get(1)
  ),
  getStageProductSelector: (any) => IStageSubstance = createSelector(
    (state) => state.modules.stage.editStage?.substances,
    (data: Map) => data.get(data.size)
  ),
  getSelectedSubstanceSelector: (any) => IStageSubstance = createSelector(
    (state) => state.modules.stage.editStage?.substances,
    (data) => [...data.values()].find((substance: IStageSubstance) => substance.isSelected)
  ),
  productIsSelected: (any) => boolean = createSelector(
    (state) => state.modules.stage.editStage?.substances,
    (data) => {
      const product: IStageSubstance = data.get(data.size);
      if (product && 'isSelected' in product) {
        return product.isSelected;
      }
      return null;
    },
  ),
  entryIsSelected: (any) => boolean = createSelector(
    (state) => state.modules.stage.editStage?.substances,
    (data) => {
      const entry: IStageSubstance = data.get(1);
      if (entry && 'isSelected' in entry) {
        return entry.isSelected;
      }
      return null;
    },
  ),
  getProductPosition: (any) => number = createSelector(
    (state) => state.modules.stage.editStage?.substances,
    (data) => data.size,
  ),
  getRemovableReactantPosition: (any) => number = createSelector(
    (state) => state.modules.stage.editStage?.substances,
    (data) => data.size > 3 ? data.size - 2 : 0,
  ),
  getMergeOption = (state: IRootStore): string => state.modules.stage.editStage?.mergeOption,
  getCurrentStageId = (state: IRootStore): number => state.modules.stage.editStage?.current?.Id,
  getBestReactionId = createSelector(
    (state: IRootStore) => ({
      currentId: state.modules.stage.editStage.current.Id,
      stages: state.resource.stages.data,
    }),
    ({ currentId, stages }): number => stages[currentId]?.BestReactionId,
  ),
  getInValidSubstances = createSelector(
    [
      listStageSubstancesSelector
    ],
    (substances: IStageSubstance[]) => substances.filter((substance: IStageSubstance) => !substance.canonicalized.IsValid)
  ),
  currentStageIndicatorsInitialFormValues = createSelector(
    [getCurrentStageId, getAllStageIndicators, getFormValues(STAGE_INDICATORS_FORM_NAME)],
    (stageId: ?number, allStageIndicators: IStageIndicator[], formValues: {}) => {
      if (stageId) {
        return {
          [STAGE_INDICATORS_ARRAY_NAME]: allStageIndicators?.filter((i: IStageIndicator) => i.StageId === stageId) || []
        };
      }
      else {
        return {
          [STAGE_INDICATORS_ARRAY_NAME]: (formValues && Array.isArray(formValues[STAGE_INDICATORS_ARRAY_NAME]))
            ? formValues[STAGE_INDICATORS_ARRAY_NAME]
            : []
        };
      }
    }
  ),
  stageProject = createSelector(
      [
          (state: IRootStore) => state.resource.projects.data,
          (state: IRootStore) => state.modules.stage.editStage,
      ],
      (projects, editStage) => {
        return editStage.projectId ? projects[editStage.projectId] || {} : {};
      }
  )
;
