import {
    put,
    takeEvery,
    takeLeading,
    fork,
    select,
} from 'redux-saga/effects';
import {ProjectService, CandidatesService} from '../services';
import {folderResourceActions, projectActions} from '../actions';
import {getToken} from '../../account/reducers';
import type {IRequestResourceAction} from '../../shared/utils/action-creators';

export function* getProjectsList(data) {
    const token = yield select(getToken);
    const service = new ProjectService(token);

    try {
        const result = yield service.getList(data);
        yield put(projectActions.list.success(result));
    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(projectActions.list.failure({error: e.response.data['ErrorMessage']}));
        }
    }
}

export function* getProject(uriParams) {
    const token = yield select(getToken);
    const service = new ProjectService(token);

    try {
        const result = yield service.get(uriParams.id);
        yield put(projectActions.get.success(result));
    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(projectActions.get.failure({error: e.response.data['ErrorMessage']}));
        }
    }
}


export function* getAllProjects() {
    const token = yield select(getToken);
    const service = new ProjectService(token);

    try {
        const result = yield service.getAll();
        yield put(projectActions.getAll.success(result));
    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(projectActions.getAll.failure({ error: e.response.data['ErrorMessage'] }));
        }
    }
}

export function* createProject(data) {
    const token = yield select(getToken);
    const service = new ProjectService(token);

    try {
        const result = yield service.create(data);
        yield put(projectActions.create.success(result));
    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(projectActions.create.failure({error: e.response.data['ErrorMessage']}));
        }
    }

}

export function* updateProject(params) {
    const {id, folderId, leader, name, status, type, OrderPriority, isScaling} = params;

    const token = yield select(getToken);
    const service = new ProjectService(token);

    try {
        const {result} = yield service.update(id, folderId, leader, name, status, type, OrderPriority, isScaling);
        yield put(projectActions.update.success(result));
    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(projectActions.update.failure({error: e.response.data['ErrorMessage']}));
        }
    }
}

export function* deleteProject(params) {
    const {projectId} = params;

    const token = yield select(getToken);
    const service = new ProjectService(token);

    try {
        const {result} = yield service.delete(projectId);
        if (!result.IsSuccess) {
            yield put(projectActions.delete.failure({
                error: 'Невозможно удалить проект, так как он содержит финальные кандидаты'
            }));
        } else {yield put(projectActions.delete.success());}

    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(projectActions.delete.failure({error: e.response.data['ErrorMessage']}));
        }
    }
}

export function* getMaxOrder(params) {
    const token = yield select(getToken);
    const service = new ProjectService(token);

    try {
        const {result} = yield service.getMaxOrder(params);
        yield put(projectActions.getMaxOrder.success({result}));
    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(projectActions.getMaxOrder.failure({error: e.response.data['ErrorMessage']}));
        }
    }
}

function* getProjectByCandidateId(action: IRequestResourceAction) {
    const token = yield select(getToken);
    const candidateService = new CandidatesService(token);
    const projectService = new ProjectService(token);
    
    try {
      const fcandidateId = action.params.candidateId;
      const candidate = yield candidateService.get(fcandidateId);
      const projectId = candidate.result.ProjectId;
      const project = yield projectService.get(projectId);
      yield put(projectActions.getProjectByCandidateId.success(project));
      yield put(projectActions.get.success(project));
   } catch (error) {
     yield put(projectActions.getProjectByCandidateId.failure({ error }));
   }
}


export const projectWatchers = [
    function* watchGetProjectByCandidateId() {
        yield takeEvery(projectActions.getProjectByCandidateId.request.type, function* (action) {
          yield fork(getProjectByCandidateId, action);
        });
      },

    function* watchProjectList() {
        yield takeLeading(projectActions.list.request.type, function* (action) {
            const {params} = action;
            yield fork(getProjectsList, params);
        });
    },

  function* watchGetAllProjects() {
      yield takeEvery(projectActions.getAll.request.type, function* () {
          yield fork(getAllProjects);
      });
  },

    function* watchProjectCreate() {
        yield takeEvery(projectActions.create.request.type, function* (action) {
            const {data} = action;
            yield fork(createProject, data);
        });
    },

    function* watchProjectUpdate() {
        yield takeEvery(projectActions.update.request.type, function* (action) {
            const {data} = action;
            yield fork(updateProject, data);
        });
    },

    function* watchProjectDelete() {
        yield takeEvery(projectActions.delete.request.type, function* (action) {
            const {data} = action;
            yield fork(deleteProject, data);
        });
    },

    function* watchGetMaxOrder() {
        yield takeEvery(projectActions.getMaxOrder.request.type, function* (action) {
            const {data} = action;
            yield fork(getMaxOrder, data);
        });
    },

    function* () {
        yield takeEvery(projectActions.get.request.type, function* (action) {
            const {uriParams} = action;
            yield fork(getProject, uriParams);
        });
    }

];
