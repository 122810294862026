// @flow
import {getEnum} from '../../shared/utils';

export const RolesMap = {
	User          : 'User',
	AgentsAdmin   : 'AgentsAdmin',
	ProjectLead   : 'ProjectLead',
	SuperUser     : 'SuperUser',
	ArchiveViewer : 'ArchiveViewer',
	OnlyReader	  : 'OnlyReader',
	Technologist  : 'Technologist',
};

export const RolesMapTranslate = getEnum({
	User          : 'Пользователь',
	AgentsAdmin   : 'Администратор справочника',
	ProjectLead   : 'Руководитель проекта',
	SuperUser     : 'Администратор системы',
	ArchiveViewer : 'Просмотр архивов',
	OnlyReader	  : 'Наблюдатель',
	Technologist   : 'Технолог',
});
