import bnc                  from 'bnc';
import propTypes            from 'prop-types';
import { Component }        from 'react';
import Button               from '../Button';
import Tooltip              from '../../layout/Tooltip';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import                           './index.less';

export default class ActionList extends Component {

    state = {
        opened: false,
        hover:  false
    };

    static propTypes = {
        icon:     propTypes.object,
        header:   propTypes.string,
        disabled: propTypes.bool
    };

    static Button = $ => <Button {...$}/>;

    static Group = ({ children, header }) => {
        return <li className={ActionList.block.el('group')}>
            { header && <h5>{ header }</h5> }
            <ul className={ ActionList.block.el('options') + ActionList.block.el('group-options') }>
                { children }
            </ul>
        </li>;
    };

    static Action = ({children, action, icon, disabled, closeTooltip}) =>
        <li
            className={
                ActionList.block.el('action') +
                ( disabled ? ActionList.block.el('action').mod('disabled') : '' )
            }
            onClick={
                e => {
                    if (disabled) {
                        e.stopPropagation();
                    } else {
                        closeTooltip && closeTooltip();
                        action();
                    }
                }
            }
        >
            { icon && <FontAwesomeIcon icon={icon}/> }
            <div className={ ActionList.block.el('action-label') }>{ children }</div>
        </li>;

    static Tooltip = $ => <Tooltip    listen='hover' open={false} {...$}/>;

    static Hover   = $ => <ActionList listen='hover' {...$}/>;

    static Click   = $ => <ActionList listen='click' {...$}/>;

    static block = new bnc.default('action-list')

    tooltip   = React.createRef()

    container = React.createRef()

    closeTooltip = () => this.tooltip.current.close()

    parametrizeActions = (actions) =>
        React.Children.map(
            actions,
            ({type, props:{children, ...rest} }) =>
                type === ActionList.Action
                    ? <ActionList.Action closeTooltip={this.closeTooltip} {...rest}>{children}</ActionList.Action>
                    : type === ActionList.Group
                        ? <ActionList.Group {...rest}>{ this.parametrizeActions(children) }</ActionList.Group>
                        : null
        )

    toggle = force => () => {
        this.setState({
            opened:  force !== void(0) ? force : !this.state.opened
        });
    }

    toggleHover = hover => () => {
        this.setState({ hover });
    }

    onKeyDown = (e) => {
        var { key } = e,
            { opened } = this.state;

        if (opened) {

            var layout        = this.tooltip.current.layout.current,

                pendingClass  = `${ActionList.block.el('action').mod('pending')}`.trim(),

                disabledClass = `${ActionList.block.el('action').mod('disabled')}`.trim(),

                actionNodes   = Array.from(
                                    layout.querySelectorAll(
                                        `.${ActionList.block.el('action')}`
                                    )
                                ),

                enabledNodes  = actionNodes
                                    .filter(
                                        ({classList}) => !classList.contains( disabledClass )
                                    ),

                pendingNode   = actionNodes
                                    .find(
                                        ({classList}) => classList.contains( pendingClass )
                                    ),

                pendingIndex  = enabledNodes.indexOf(
                                    pendingNode
                                ),

                pendingNext,

                pendingNextIndex;


            if (key === 'ArrowUp' || key === 'ArrowDown') {
                e.preventDefault();
                pendingNextIndex =
                    pendingIndex > -1
                        ? key === 'ArrowUp'
                            ? pendingIndex === 0
                                ? enabledNodes.length - 1
                                : pendingIndex - 1
                            : pendingIndex < enabledNodes.length - 1
                                ? pendingIndex + 1
                                : 0
                        : key === 'ArrowUp'
                            ? enabledNodes.length - 1
                            : 0
                ;

                pendingNext =  enabledNodes[pendingNextIndex];
                pendingNode && pendingNode.classList.remove(pendingClass);
                pendingNext && pendingNext.classList.add(pendingClass);
            } else if (key === 'Enter' && pendingNode) {
                e.preventDefault();
                pendingNode.click();
            } else if (key === 'Escape') {
                e.preventDefault();
                this.toggle(false)();
            }
        }
    }

    handlers = listen => (
        listen === 'click'
            ? {
                onAction:     this.toggle(),
                onFocus:      this.toggleHover(true),
                onBlur:       this.toggleHover(false),
                onMouseEnter: this.toggleHover(true),
                onMouseLeave: this.toggleHover(false)
            }
            : {
                onFocus:      this.toggle(true),
                onBlur:       this.toggle(false),
                onMouseEnter: this.toggle(true),
                onMouseLeave: this.toggle(false)
            }
    )

    renderActionList = ({ opened, hover }, { children, listen, align = 'left', className = '' }) => {
        const Children = React.Children.toArray(children);
        const TooltipEl= Children.find(   ({type}) => type === ActionList.Tooltip );
        const Target   = Children.find(   ({type}) => type === ActionList.Button  );
        const Actions  = Children.filter( ({type}) => type === ActionList.Action || type === ActionList.Group  );

        return <div className={ ActionList.block + className } ref={this.container}>
                <Button
                    mods = { opened ? ['active'] : [] }
                    { ...Target.props }
                    { ...this.handlers(listen) }
                    onKeyDown = { this.onKeyDown }
                >
                    { Target.props.children }
                    {
                        opened
                            ? <Tooltip
                                notail
                                mousehold = { listen === 'hover' ? true : false }
                                ref       = { this.tooltip }
                                className = {
                                    ActionList.block.el('list') +
                                    (className ? `${className}__tooltip` : '')
                                }
                                align     = { align }
                                onClose   = { this.toggle(false) }
                            >
                                <ul
                                    className = {
                                        ActionList.block.el('options') +
                                        (className ? `${className}__options` : '')
                                    }
                                    onClick   = { e => e.stopPropagation() }
                                >
                                    { this.parametrizeActions(Actions) }
                                </ul>
                            </Tooltip>
                            : (
                                (TooltipEl && hover)
                                    ? <Tooltip { ...TooltipEl.props}/>
                                    : null
                            )
                    }
                </Button>
            </div>;
    }

    render = () => this.renderActionList(this.state, this.props)
}

ActionList.Group   .displayName = 'ActionList.Group';
ActionList.Button  .displayName = 'ActionList.Button';
ActionList.Tooltip .displayName = 'ActionList.Tooltip';
ActionList.Click   .displayName = 'ActionList.Click';
ActionList.Hover   .displayName = 'ActionList.Hover';
ActionList.Action  .displayName = 'ActionList.Action';

ActionList.Action.propTypes = {
    action:         propTypes.func.isRequired,
    closeTooltip:   propTypes.func,
    disabled:       propTypes.bool
};

ActionList.Group.propTypes = {
    header: propTypes.string
};

