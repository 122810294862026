// @flow

import { ObjectModel } from 'objectmodel';

export const Compound = ObjectModel({
  Id: [Number, null, undefined],
  Formula: [String, null, undefined],
  MolarWeight: [Number, null, undefined],
  MolecularFormula: [String, null, undefined],
  Thumbnail: [String, null, undefined],
  Name: [String, null, undefined],
  SmilesFormula: [String, null, undefined],
  IsPolymer: [Boolean],
  IsAntibody: [Boolean],
  IsComplexCompound: [Boolean],
}).defaultTo({
  Id: 0,
});
