// @flow

import {getEnum} from '../../shared/utils';

export const StageIndicatorCPPEnum = getEnum({
    Reaction    : 1,
    Extraction  : 2,
    Purification: 3,
    ByWorks     : 4,
});

export const StageIndicatorCPPMap = {
    [StageIndicatorCPPEnum.Reaction]    : 'PP процесса реакции',
    [StageIndicatorCPPEnum.Extraction]  : 'PP процесса выделения',
    [StageIndicatorCPPEnum.Purification]: 'PP процесса очистки',
    [StageIndicatorCPPEnum.ByWorks]     : 'PP вспомогательных работ',
};
