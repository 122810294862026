import {
    apiRequestList,
    apiRequestSingle
} from '../../shared/services/api/common';

export class FolderService {

    constructor(token) {
        this._token = token;
        const headers = token ? {Authorization: token} : {};
        this._getList = apiRequestList('GET', '/folders', headers);
        this._create = apiRequestSingle('POST', '/folders', headers);
        this._update = apiRequestSingle('PUT', '/folders', headers);
        this._delete = (folderId) => apiRequestSingle('DELETE', `/folders/${folderId}`, headers);
    }

    getList(params) {
        return this._getList({params});
    }

    create(data) {
        return this._create({}, data);
    }

    update(data) {
        return this._update({}, data);
    }

    delete(folderId, params) {
        return this._delete(folderId)(params);
    }

}