// @flow

import React from 'react';
import { ReactionSideBar } from '../../../../reactions/components/ReactionsSidebar';
import { connect } from 'react-redux';
import { getCurrentStageId, getBestReactionId } from '../../../reducers';
import type { IRootStore } from '../../../../app/reducers';


interface IStageReactionListProps {
  className: string,
  stageId?: number,
  stageBestReactionId?: number,
}

const defaultProps: IStageReactionListProps = {
  className: '',
};

let StageReactionList = (props: IStageReactionListProps = defaultProps) => (
  props.stageId
    ? <ReactionSideBar
        stageId={props.stageId}
        className={props.className}
        bestReactionId={props.stageBestReactionId}
      />
    : ''
);

StageReactionList = connect(
  (state: IRootStore) => ({
    stageId: getCurrentStageId(state),
    stageBestReactionId: getBestReactionId(state),
  }),
  null
)(StageReactionList);

export {
  StageReactionList,
};
