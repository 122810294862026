// @flow

import React from 'react';
import styled from 'styled-components';
import Button from '@biocad/bcd-front-ui/controls/Button';
import Dropdown from '@biocad/bcd-front-ui/controls/Dropdown';

export const StyledReagentsTableBtnsContainer = styled('div')`
	padding: var(--grid-spacer) calc(var(--grid-spacer) * 1.5);
	@media screen {
	display: flex;
	}
	@media print {
	display: none;
	}
	align-items: center;
	
	*:first-child {
		margin-right: auto;
	}
	
	.FactualConsumption-check {
		border: 1px solid var(--red);
	}
	
	.${Dropdown.block} {
	  height: 24px;
	  max-width: 230px;
	  margin-left: 12px;
	  button {
        font-size: 14px;
	  }
	}
	
  .${Button.block} {
    padding: 0 20px;
    min-height: 24px;
    height: 24px;
    font-size: 14px;
    
    &:not(:last-of-type) {
      margin-right: 20px;
    }
  }   
`;

export const StyledRedCompoundBtn = styled('i')`
  color: var(--grey-500);
  font-size: 16px;
  margin-right: 10px;
  
  &:hover {
    color: var(--grey-700);
    cursor: pointer;
  }
`;

export const StyledListArrow = styled('i')`
    transition: 150ms linear;
    color: var(--grey-400);
    font-size: 9px;

    &.rotated {
      transform: rotate3d(180, 0, 0, -180deg);
    }
`;

export const ReagentsTableStyled = styled.table`
grid-template-rows: [header-start] var(--table-row-head-height) [header-end] repeat(1000, min-content);
`;
