// @flow

import React, {useState} from 'react';
import Dialog from '@biocad/bcd-front-ui/layout/Dialog';
import {MarvinSketch} from '../../../shared/components/MarvinSketch';
import Button from '@biocad/bcd-front-ui/controls/Button';
import {connect} from 'react-redux';
import {
	StyledMarvinModalContent,
	StyledMarvinModalFooter
} from './style';
import {_noop} from '../../../shared/utils/common';
import {withRouter} from 'react-router';
import type {IReactionEditDialogWrapComponentProps} from '../ReactionEdit/ReactionEditDialogWrap';
import {Field, reduxForm, Form, getFormValues, reset, change} from 'redux-form';
import {REACTION_EDIT_FORM_NAME} from '../ReactionEdit/ReactionAsideForm';
import {reactionModuleActions} from '../../actions';
import type {IAgentAlias, IProduct} from '../../../products/model';
import type {ICompound} from '../../../agents/models';

interface ICreateArchivedReactionModal extends IReactionEditDialogWrapComponentProps {
	onCancel: Function,
	history?: {
		push: (string) => void,
	},
	addArchivedReagent: Function,
  addProduct: Function
}

const initialProps = {
	onCancel: _noop,
	history: {
		push: _noop,
	},
	addArchivedReagent: _noop,
  addProduct: _noop
};

let CreateArchivedReactionModal = (props: ICreateArchivedReactionModal = initialProps) => {

	// States
	const
		[firstReactant, setFirstReactant] = useState(null),
		[secondReactant, setSecondReactant] = useState(null),
		[thirdReactant, setThirdReactant] = useState(null)
	;

	// Methods
	const
		onCancel = () => {
			// props.onCancel();
			props.closeDialog();
		},
		firstReactantChanged = (result) => {
			result.result && setFirstReactant(result.result);
		},
		secondReactantChanged = (result) => {
			result.result && setSecondReactant(result.result);
		},
		thirdReactantChanged = (result) => {
			result.result && setThirdReactant(result.result);
		},
		createReaction = () => {
			props.addArchivedReagent(firstReactant);
			secondReactant && props.addArchivedReagent(secondReactant);

			const product = {
        Amount: null,
        Compound: thirdReactant,
        CompoundId: null,
        Concentration: null,
        Density: null,
        Eq: null,
        FactualConsumption: null,
        Id: 0,
        IsDeleted: false,
        IsInWork: false,
        IsMainProduct: true,
        LaboratoryId: null,
        Location: null,
        MainAgentContent: 0,
        Mass: null,
        MolarWeight: null,
        Name: null,
        NameAliases: null,
        ReactionId: null,
        ShortName: null,
        Volume: null,
        ExperimentalMainAgentContent: null,
        Yield: null,
        IgnoreJarCreation: false
      };

      props.addProduct(product);

      props.closeDialog();
		}
	;

	return (
		<Dialog
			paranjaClose={false}
			close={onCancel}
		>
			<header className={'dialog-header'}>
				<h3>Введите исходные вещества</h3>
			</header>

			<StyledMarvinModalContent className={'dialog-contents'}>
				<label>
					Входное вещество:
					<MarvinSketch
						handleChange={(result) => firstReactantChanged({result})}
					/>
				</label>

				<label>
					Входное вещество:
					<MarvinSketch
						handleChange={(result) => secondReactantChanged({result})}
					/>
				</label>

				<label>
					Продукт:
					<MarvinSketch
						handleChange={(result) => thirdReactantChanged({result})}
					/>
				</label>
			</StyledMarvinModalContent>

			<StyledMarvinModalFooter className={'dialog-footer'}>
				<Button
					text={'Продолжить'}
					onAction={createReaction}
					size={'L'}
					view={'primary'}
					disabled={!firstReactant || !thirdReactant}
				/>

				<Button
					text={'Отмена'}
					onAction={onCancel}
					size={'L'}
				/>
			</StyledMarvinModalFooter>
		</Dialog>
	);
};

CreateArchivedReactionModal = connect(
	null,
	(dispatch) => ({
		addArchivedReagent: (compound) => dispatch(reactionModuleActions.reactionEdit.addArchivedReagent({compound})),
		addProduct: (product: IProduct) => dispatch(reactionModuleActions.reactionEdit.addProduct({product}))
	})
)(CreateArchivedReactionModal);

CreateArchivedReactionModal = withRouter(CreateArchivedReactionModal);

export {
	CreateArchivedReactionModal
};
