// @flow
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {accountActions} from '../../actions';
import {_noop} from '../../../shared/utils/common';
import classNames from 'classnames';
import {RolesMap} from '../../models/roles-map';

const StyledDropdown = styled('ul')`
  position: absolute;
  top: 0;
  right: 24px;
  height: auto;
  border-radius: 4px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  background: var(--white);
  &.opened {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  li {
    padding: 7px 12px;
    display: flex;
    align-items: center;
    color: var(--grey-500);
    &:hover {
      cursor: pointer;
      color: var(--black);
    }
    &:first-child {
      height: 40px;
      color: var(--black);
    }       
  }
`;

interface IUserRolesButtonProps {
  logout: Function,
  account: Object,
  history?: {
    push?: typeof Function
  }
}

const initialProps: IUserRolesButtonProps = {
  logout: _noop,
  account: null,
  history: null
};

const UserRolesButton = (props: IUserRolesButtonProps = initialProps) => {
  //States
  const [isDropdownShown, setIsDropdownShown] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  //Effects
  useEffect(() => {
    return document.removeEventListener('click', clickOutside, false);
  }, []);

  useEffect(() => {
    if (props.account?.roles)
      setIsAdmin(props.account.roles.indexOf(RolesMap.SuperUser) !== -1);
  }, [props.account]);

  //Methods
  const dropdownTrigger = () => {
    setIsDropdownShown(prev => !prev);
    if (isDropdownShown) {
      document.removeEventListener('click', clickOutside, false);
    } else {
      document.addEventListener('click', clickOutside, false);
    }
  };

  const clickOutside = () => {
    setIsDropdownShown(false);
    document.removeEventListener('click', clickOutside, false);
  };

  const userLogout = () => {
    props.logout();
  };

  const viewUserRoles = () => {
    props.history.push('/user-roles');
  };

  return (
    <StyledDropdown className={classNames('user-roles-dropdown', {
      'opened': isDropdownShown
    })}>

      <li onClick={dropdownTrigger}>
        {props.account?.data?.Name}
      </li>

      {isDropdownShown &&
      <>

        {isAdmin &&
        <li onClick={viewUserRoles}>Пользователи</li>
        }

        <li onClick={userLogout}>Выйти</li>

      </>
      }

    </StyledDropdown>
  );
};

const mapStateToProps = (state) => {
  const {global} = state;
  return {
    account: global.account
  };
};

export default withRouter(connect(
  mapStateToProps,
  (dispatch) => ({
    logout: () => dispatch(accountActions.logout.request())
  })
)(UserRolesButton));
