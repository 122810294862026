import './index.less';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Dialog, { DialogLayout } from '@biocad/bcd-front-ui/layout/Dialog';
import Button from '@biocad/bcd-front-ui/controls/Button';
import { schemesResourceActions, schemeModuleActions } from '../../actions';
import { GridTable } from '../../../shared/components/GridTable';
import { GridTableCol } from '../../../shared/components/GridTable/GridTableCol';
//import { setGlobalLoader } from '../../../../../../../shared/actions/loading';
import { InlineEdit, inputTypesEnum } from '../../../shared/components/InlineEdit';
import { FieldValidators } from '../../../shared/validators/field-validators';
import { reduxForm, isValid, getFormValues } from 'redux-form';

const SCHEME_COPY_FORM_NAME = "SchemeCopy";
const SCHEMES = "Schemes";

let SchemeCopyDialog = (props) => {
  function initDialog() {
    if(!props.scheme) {
      return;
    }
    props.getMergedSubschemes(props.scheme.Id);
  }
  useEffect(initDialog, [props.scheme]);

  function initSubschemes() {
    if(!props.subschemes) {
      return;
    }

    const schemeInfo = 
      [props.scheme, ...props.subschemes].map(s => {
        return {
          Id: s.Id,
          OldCode: s.Code,
          Code: `${s.Code}_copy`,
        };
      });

    props.change(SCHEMES, schemeInfo);
  }
  useEffect(initSubschemes, [props.subschemes]);

  function goToClonedScheme() {
    if(!props.clonedScheme) {
      return;
    }

    props.history.push(`/schemes/${props.clonedScheme.Id}`);
    props.onClose();
  }
  useEffect(goToClonedScheme, [props.clonedScheme]);

  function copyScheme() {
    if(!props.formValid) {
      return;
    }

    const data = { Codes: props.formValues.Schemes };
    props.cloneScheme(props.scheme.Id, props.finalCandidateId, data);
    
  }
  
  return(
    <>
      <Dialog paranjaClose={false} close={props.onClose}>
        <div className={'select-candidate-dialog'}>
          <header className={'dialog-header'}>
            <h3>Копирование схемы</h3>
          </header>
          <div className={'dialog-contents'}>
            <div className={'info'}>Укажите коды для схем, которые будут скопированы</div>
            <GridTable 
                hover={true}
                fieldArrayName={SCHEMES}
                rowHeight={'54px'}>
              <GridTableCol
                title={'Код схемы источника'}
                render={({ item }) => <span>{item?.OldCode}</span>}
              />
              <GridTableCol
                title={'Код новой схемы'}
                fieldName={'Code'}
                render={({input, meta}) => (
                  <InlineEdit
                    inputType={inputTypesEnum.InputText}
                    {...{input, meta}}
                  />
                )}
                align={'right'}
                validate={FieldValidators.required}
              />
            </GridTable>
          </div>
          <footer className={'dialog-footer'}>
            <Button
              text={'Копировать'}
              view='primary'
              size={'L'}
              onAction={copyScheme}
              disabled={!props.formValid}
            />
            <Button
              text='Отмена'
              size={'L'}
              onAction={props.onClose}
            />
          </footer>
        </div>
      </Dialog>
    </>
  );

};

SchemeCopyDialog = reduxForm({
  form: SCHEME_COPY_FORM_NAME,
  destroyOnUnmount: true,
})(SchemeCopyDialog);

SchemeCopyDialog = withRouter(SchemeCopyDialog);
SchemeCopyDialog = connect(
  (state) => ({ 
    subschemes: state?.resource?.schemes?.custom?.mergedSubschemes,
    formValid: isValid(SCHEME_COPY_FORM_NAME)(state),
    formValues: getFormValues(SCHEME_COPY_FORM_NAME)(state),
    clonedScheme: state?.resource?.schemes?.custom?.clonedScheme,
  }),
  (dispatch) => ({
    getMergedSubschemes: (id) => dispatch(schemesResourceActions.getMergedSubschemes.request({ uriParams: { id } })),
    cloneScheme: (schemeId, finalCandidateId, data) => dispatch(schemesResourceActions.cloneScheme.request({ uriParams: { schemeId, finalCandidateId }, data })),
  })
)(SchemeCopyDialog);

export {
  SchemeCopyDialog
};