import React, {useEffect, useState, useRef} from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import type {IReaction} from '../../../models';
import {GridTable, GridTableCol} from '../../../../shared/components/GridTable';
import type {IProduct} from '../../../../products/model';
import {ProductKeyNames} from '../../../../products/model';
import type {IRootStore} from '../../../../app/reducers';
import {ReagentKeyNames, ReagentTypeEnum} from '../../../../reagents/model';
import {InlineEdit, inputTypesEnum} from '../../../../shared/components/InlineEdit';
import {FieldValidators} from '../../../../shared/validators/field-validators';
import {isProduct, isReagent, isRefCompound, isSolvent} from '../../../helpers/common';
import {
	getProductsFromForm,
	getReactantsFromForm,
	getReactionFromForm, getReagentsErroredFieldNames,
	getReagentsFromForm
} from '../../../reducers/reaction-edit-form-plugin';
import {
	getCurrentReactionStates,
	getHighlightedSubstance,
	getHoveredImage,
	ReactionEditDialogsMap
} from '../../../reducers';
import {reagentsResourceActions} from '../../../../reagents/actions';
import {productsResourceActions} from '../../../../products/actions';
import {reactionModuleActions} from '../../../actions';
import {_noop} from '../../../../shared/utils/common';
import styled from 'styled-components';
import {EditReactantModal} from './EditArchivedReactant';
import {REACTION_REACTANTS_ARRAY_FIELD_NAME} from '../ReagentsTable';
import type {IArchivedReagent} from '../../../../archivedReagents/model';
import {archivedReagentsResourceActions} from '../../../../archivedReagents/actions';
import {Compound} from '../../../../agents/models';
import type {IReagent} from '../../../../reagents/model';
import type {TCurrentReactionStates} from '../../../reducers';
import Dropdown from '@biocad/bcd-front-ui/controls/Dropdown';
import type {OptionType} from '../../../../shared/models';
import Button from '@biocad/bcd-front-ui/controls/Button';
import type {TReactionEditDialogRefType} from '../ReactionEditDialogWrap';
import {ReactionEditDialogWrap} from '../ReactionEditDialogWrap';
import {ReactionPurificationModal} from '../../ReactionPurification/ReactionPurificationModal';

export const StyledDropdownContainer = styled('div')`
	padding: var(--grid-spacer) calc(var(--grid-spacer) * 1.5);
	display: flex;
	align-items: center;
	justify-content: flex-end;	
	
	*:first-child {
		margin-right: auto;
	}
	
	.FactualConsumption-check {
		border: 1px solid var(--red);
	}
	
	.${Dropdown.block} {
	  height: 24px;
	  max-width: 230px;
	  margin: 0;
	  button {
        font-size: 14px;
	  }
	}
  }  
`;

interface IArchivedReagentsTableProps {
	reaction?: IReaction,
	className?: string,
	reactantsList?: (IArchivedReagent | IProduct)[],
	getReagentsList?: Function,
	getProductsList?: Function,
	hoveredImage?: ?number,
	setHoveredImage?: Function,
	recalcReagent?: (number)=>void,
	recalcProduct?: (number)=>void,
	recalcAll?: ()=>void,
	reactionStates?: TCurrentReactionStates,
	addArchivedReagent: Function
}

const initialProps = {
	reaction: null,
	className: '',
	reactantsList: [],
	getReagentsList: _noop,
	getProductsList: _noop,
	setHoveredImage: _noop,
	addArchivedReagent: _noop
};

const fieldValidators = {
	eq: [FieldValidators.required, FieldValidators.stepNum(0.0001)],
	mainAgent: [FieldValidators.required, FieldValidators.maxNum(100), FieldValidators.minNum(0.01)]
};

const chooseAddedReactantDropdownOptions: OptionType[] = [
	{
		value: ReagentTypeEnum.Catalyst,
		label: 'Реагент/катализатор'
	},
	{
		value: ReagentTypeEnum.Solvent,
		label: 'Растворитель'
	},
	{
		value: ReagentTypeEnum.Auxiliary,
		label: 'Вспом. реагент'
	},
	{
		value: ReagentTypeEnum.Catalyst,
		label: 'Реагент/Катализатор из справочника'
	},
	{
		value: ReagentTypeEnum.Solvent,
		label: 'Растворитель из справочника'
	},
	{
		value: ReagentTypeEnum.Auxiliary,
		label: 'Вспом. реагент из справочника'
	}
];


let ArchivedReagentsTable = (props: IArchivedReagentsTableProps = initialProps) => {

	// Refs
	const
		addSubstanceDialogRef = useRef(null),
		reactionSpendingsRef  = useRef(null)
	;

	// State
	const
		[selectedSubstanceType, setSelectedSubstanceType] = useState(null),
		[reagentDialogCompound, setReagentDialogCompound] = useState(null),
		[reagentDialogAgentId, setReagentDialogAgentId]   = useState(null)
	;

	// Methods
	const
		chooseAddedReactant = (value) => {
			setSelectedSubstanceType(value);
			if (addSubstanceDialogRef?.current && 'open' in addSubstanceDialogRef?.current) {
				addSubstanceDialogRef?.current.open();
			}
		},

		addReagent = (reagent) => {
			props.addArchivedReagent(reagent);
			setSelectedSubstanceType(null);
		}
	;

	return (
		<div className={props.className}>
			<StyledDropdownContainer>
				<Dropdown
					onChange={chooseAddedReactant}
					value={'Добавить компонент'}
					options={Object.values(chooseAddedReactantDropdownOptions)}
				/>
			</StyledDropdownContainer>

			<GridTable
				items={props.reactantsList}
				hover={true}
				fieldArrayName={REACTION_REACTANTS_ARRAY_FIELD_NAME}
				colsTemplate={'50px repeat(9, minmax(min-content, 1fr)) min-content'}
				rowHeight={'54px'}
				excludeRows={(item: IProduct | IArchivedReagent) => ProductKeyNames.IsMainProduct in item ? !item.IsMainProduct : item.IsDeleted}
				onRowClicked={(_, rowidx, event, renderIdx) => renderIdx !== props.hoveredImage ? props.setHoveredImage(renderIdx) : props.setHoveredImage(null)}
				setRowClassNames={(_, rowidx, renderIdx) => ({
					'is-selected': renderIdx === props.hoveredImage
				})}
			>
				<GridTableCol
					title={'№'}
					render={({renderIdx}) => (
						<span>{renderIdx}</span>
					)}
				/>

				<GridTableCol
					title={'Масса, г'}
					fieldName={ReagentKeyNames.Mass}
					render={({input, meta, item}) => (
						<InlineEdit
							inputType={inputTypesEnum.InputNum}
							{...{input, meta}}
						/>
					)}
				/>

				<GridTableCol
					title={'Vol, мл'}
					fieldName={ReagentKeyNames.Volume}
					render={({input, meta}) => (
						<InlineEdit
							inputType={inputTypesEnum.InputNum}
							{...{input, meta}}
						/>
					)}
				/>

				<GridTableCol
					title={'Кол-во'}
					fieldName={ReagentKeyNames.Amount}
					render={({input, meta}) => (
						<InlineEdit
							inputType={inputTypesEnum.InputNum}
							{...{input, meta}}
						/>
					)}
				/>

				<GridTableCol
					title={'Eq'}
					fieldName={ReagentKeyNames.Eq}
					render={({input, meta}) => (
						<InlineEdit
							inputType={inputTypesEnum.InputNum}
							{...{input, meta}}
						/>
					)}
					align={'right'}
				/>

				<GridTableCol
					title={'MW'}
					fieldName={'Compound.MolarWeight'}
					render={({input, meta}) => (
						<InlineEdit
							inputType={inputTypesEnum.InputNum}
							{...{input, meta}}
						/>
					)}
					align={'right'}
				/>

				<GridTableCol
					title={'Плот, г/мл'}
					fieldName={ProductKeyNames.Density}
					render={({input, meta}) => (
						<InlineEdit
							inputType={inputTypesEnum.InputNum}
							{...{input, meta}}
						/>
					)}
					align={'right'}
				/>

				<GridTableCol
					title={'M, моль/л'}
					fieldName={ProductKeyNames.Concentration}
					render={({input, meta}) => (
						<InlineEdit
							inputType={inputTypesEnum.InputNum}
							{...{input, meta}}
						/>
					)}
					align={'right'}
				/>

				<GridTableCol
					title={'Осн. вещ-во, %'}
					fieldName={ReagentKeyNames.MainAgentContent}
					render={({input, meta}) => (
						<InlineEdit
							inputType={inputTypesEnum.InputNum}
							{...{input, meta}}
						/>
					)}
					align={'right'}
				/>

				<GridTableCol
					title={'Факт. расход, г'}
					fieldName={ReagentKeyNames.FactualConsumption}
					render={({input, meta}) => (
						<InlineEdit
							inputType={inputTypesEnum.InputNum}
							{...{input, meta}}
						/>
					)}
					align={'right'}
				/>

				<GridTableCol
					render={
						({item, input, meta}) => (
							<>
								<EditReactantModal item={item}/>
								{
									(item.Compound) &&
									<Button
										className={'fo-eye'}
										view={'icon'}
										size={'L'}
										onAction={() => {
											setReagentDialogCompound(item.Compound);
											setReagentDialogAgentId(null);
										}}
									/>
								}
							</>
						)
					}
				/>

			</GridTable>

			{
				(reagentDialogCompound && typeof reagentDialogCompound === 'object') &&
				<ReactionPurificationModal
					onCancel={() => {
						setReagentDialogCompound(null);
						setReagentDialogAgentId(null);
					}}
					compound={reagentDialogCompound}
					agentId={reagentDialogAgentId}
				/>
			}

		</div>
	);
};

ArchivedReagentsTable = connect(
	(state: IRootStore) => ({
		reaction: getReactionFromForm(state),
		reactantsList: getReactantsFromForm(state),
		reagentsList: getReagentsFromForm(state),
		productsList: getProductsFromForm(state),
		reactionStates: getCurrentReactionStates(state),
		formErrors: getReagentsErroredFieldNames(state),
		hoveredImage: getHighlightedSubstance(state)
	}),
	(dispatch) => ({
		getReagentsList: (id) => dispatch(archivedReagentsResourceActions.list.request({uriParams: {id}})),
		getProductsList: (id) => dispatch(productsResourceActions.list.request({uriParams: {id}})),
		editReagentComment: (id, data) => dispatch(archivedReagentsResourceActions.editReagentComment.request({
			uriParams: {id},
			data
		})),
		setReferencedReagent: (reagent: IArchivedReagent) => dispatch(reactionModuleActions.reactionEdit.setReferencedReagent({reagent})),
		setHoveredImage: (idx: number) => dispatch(reactionModuleActions.reactionEdit.setHoveredImage({idx})),
		recalcReagent: (reagentIdx: number) => dispatch(reactionModuleActions.recalc.reagent({ reagentIdx, debounce: true })),
		recalcProduct: (productIdx: number) => dispatch(reactionModuleActions.recalc.product({ productIdx, debounce: true })),
		recalcAll: () => dispatch(reactionModuleActions.recalc.all({ debounce: true })),
		addArchivedReagent: (compound) => dispatch(reactionModuleActions.reactionEdit.addArchivedReagent({compound}))
	})
)(ArchivedReagentsTable);

ArchivedReagentsTable = styled(ArchivedReagentsTable)`
  .b-InlineEdit-mod-Readonly.b-InlineEdit:not(.b-InlineEdit-mod-Edit)::before {
    content: none;
  }
  .buttons-wrap {
    display: flex;
    align-items: center;
  }
  .grid-table-row {
    .fo-link-solid {
      opacity: 0;
      pointer-events: none;
    }
      
    .withRefCompoundIcon {
      .fo-link-solid {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
`;

export {
	ArchivedReagentsTable,
	REACTION_REACTANTS_ARRAY_FIELD_NAME
};
