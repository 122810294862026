import React from 'react';
import bnc   from 'bnc';
import './Logotype.less';

const b = new bnc.default('b-logotype');

const Logotype = ({className}) => (
    <svg className={b + className} version="1" width="32" height="20" fill="#30B460">
        <path d="M27 2.8c1.5.8 2.6 2 3.3 3.4L32 4.7c-2.1-3-4.9-3.6-7.1-3.7-1.8 0-3.6.7-5.1 1.6 2.7-1.1 5.7-.7 7.2.2M31.6 15.8l-1.3-2.1S28.2 16 25 16c-1 0-1.8-.1-2.6-.4 1 .8 2.2 1.5 3.7 1.7 2.5.2 4.1-.5 5.5-1.5"/>
        <path d="M19.8 7c.1-.2.2-.3.2-.4l.1-.1c.6-.9 1.4-1.6 2.4-2 .1 0 1.7-.8 3.8-.5.7 0 1.6.2 2.4.8-.5-.6-1.2-1.1-1.9-1.5-2-1.1-6.9-1.4-9.3 2 0 0-1 1.2-1.6 2.7-.3.7-.5 1.4-.7 2.1-.2.6-.3 1.2-.5 1.8-.1.5-.3 1-.6 1.4-1 1.8-2.9 2.9-5 2.9-.7 0-1.4-.1-2.1-.3.8.5 1.8.9 2.9 1.1 2.4.5 4.8-.1 6.3-1.6 1-.9 1.9-2.9 2.6-5.7.2-1.2.7-2.2 1-2.7"/>
        <path d="M26.9 17.8h-.7c-3.9-.3-6-4-6.3-4.6l-.1-.1c-.5-.8-.7-1.7-.8-2.6-.6 2-1.2 3.5-1.9 4.5 1 1.6 3 3.2 5.2 3.7 3.9 1 7.1-.6 8.7-1.9-1.2.6-2.5 1-4.1 1M7 2.2c2.7 0 4.6.7 6.2 2.4 1.1 1.2 1.8 2.6 1.9 4.1.1-.3.2-.7.3-1 .4-1.1 1-2 1.4-2.4-1.6-2.5-4.5-4-7.7-4-1.9-.1-3.6.5-5.1 1.4.9-.3 1.9-.5 3-.5"/>
        <path d="M11.5 17.4c-.6 0-1.1-.1-1.7-.2-2.6-.5-4.7-2-5.6-4 0-.1-.1-.1-.1-.2-.1 0-.1 0-.1-.1-.2-.4-.3-.7-.4-1.1-.1-.4-.2-.8-.2-1.2v-.5c0-.6.1-1.1.2-1.6.5-1.9 1.6-3.2 3.4-4 .9-.4 3.3-.8 5.3.5 1.1.7 1.8 1.7 2.2 2.5-.3-.9-.8-1.8-1.5-2.5-1.6-1.7-3.4-2.4-6-2.3-2.2 0-4 .5-5.5 2.4v.1c-.4.4-.6.9-.8 1.2-.4.9-1 2.5-.7 4.7 0 .2.1.4.1.6v.1c0 .2.1.3.1.5 0 .1.1.2.1.3 0 .2.1.3.2.5 0 .1 0 .1.1.2.8 2.2 2.6 4.3 6 5.3 4.3 1.2 7.6-.8 9.3-2.5-1.2.9-2.7 1.3-4.4 1.3"/>
    </svg>
);

export default Logotype;
