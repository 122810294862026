// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import css from './jarStock.m.less';
import { AgentTitlesEnum } from '../../../../agents/models';
import { LabeledText } from '../../../../shared/components/LabeledText/index.js';
import JarStockSelectTable from '../JarStockSelectTable/index.js';
import { jarStockResourceActions, agentResourceActions } from '../../../../agents/actions/index.js';
import Loader from '@biocad/bcd-front-ui/controls/Loader';
import { ItemPages } from '../../../../shared/components/ItemPages';
import { itemsToArraySelector } from '../../../../shared/utils/selectors/resource-selectors.js';
import { reactionModuleActions } from '../../../actions';
import { labsResourceActions } from '../../../../dictionary/actions';
import Dropdown from '@biocad/bcd-front-ui/controls/Dropdown';

let JarSelection = ({
  resourceAgents,
  smilesFormulas,
  getAgentsBySmiles,
  loadJarStock,
  invalidAgents,
  destroyAgents,
  destroyJarStock,
  destroyJarStockModule,
  setAgentsData,
  agents,
  reagents,
  complexCompoundNames,
  primaryReactionId,
  labsList,
  getLabs,
  data, 
  userId
}) => {

  const [agentIndex, setAgentIndex] = useState(0);
  const labOptions = [{ value: null, label: 'Все лаборатории' }].concat(labsList);
  const formatLabOptions = (value) => labOptions.find(i => i.value === value);
  const user = data[userId];
  const [laboratoryId, setLaboratoryId] = useState(user.LaboratoryId);
  const currentLab = labOptions.find(i => i.value === laboratoryId);
  useEffect(() => {
    getLabs();
    getAgentsBySmiles(smilesFormulas, complexCompoundNames);
    return () => {
      destroyAgents();
      destroyJarStock();
      destroyJarStockModule();
    };
  }, []);

  useEffect(() => {
    if(agents && agents.length) {
      loadJarStock(agents.map(x => x.Id));
    }    
  }, [agents]);

  useEffect(() => {
    const agentsArray = itemsToArraySelector(resourceAgents);
    if(!agentsArray.length) {
      return;
    }
    const sorted = smilesFormulas
      .filter(s => s !== null)
      .map(s => ({ ...agentsArray.find(a => a.OriginalSmilesFormula && a.OriginalSmilesFormula === s) }));
      if (complexCompoundNames && complexCompoundNames.length) {
      //to-fo: transform to map?
      for(let compoundName of complexCompoundNames) {
        const complexAgent = agentsArray.find(a => a.Name === compoundName && !a.OriginalSmilesFormula);
        if(complexAgent) {
          sorted.push({...complexAgent});
        }
      }
    }
    setAgentsData(sorted);
  }, [resourceAgents]);

  const getJars = (index) => {
    if (!reagents || !reagents[agentIndex]) {
      return null;
    }

    return reagents[agentIndex].Jars
  };

  const onLabChange = (labId) => {
    setLaboratoryId(labId);
  };
  return (
    <>
    {
      (agents && agents.length)
        ? <>
            {
              agents?.length > 1 &&
                <ItemPages label='Вещество:' items={agents} itemIndex={agentIndex} itemClickHandler={setAgentIndex} invalidItems={invalidAgents} />
            }
            <div className={css.grid}>
              <div className={css.thumbnail}>
                <img
                  src={agents[agentIndex]?.Compound?.Thumbnail}
                  alt={agents[agentIndex]?.ShortName}
                  title={agents[agentIndex]?.ShortName}
                />
              </div>
              <div className={css.info}>
              <span className={css.info__field}>{agents[agentIndex]?.Name}</span>
                <LabeledText className={css.info__field} label={AgentTitlesEnum.ShortName} text={agents[agentIndex]?.ShortName} />
                <LabeledText className={css.info__field} label={AgentTitlesEnum.Cas} text={agents[agentIndex]?.Cas} />
                <LabeledText className={css.info__field} label={AgentTitlesEnum.MolarWeight} text={agents[agentIndex]?.MolarWeight} />
              </div>
              <div className={css.jarStock} >
              <span className={css.jarStockLabLabel}>Лаборатория</span>
                <Dropdown
                  defaultValue={currentLab}
                  options={labOptions}
                  className={css.labDropdown}
                  format={formatLabOptions}
                  onChange={onLabChange}
                /> 
                <JarStockSelectTable agentId={agents[agentIndex]?.Id} reagent={reagents[agentIndex]} reagentJars={getJars(agentIndex)} primaryReactionId={primaryReactionId} labId={laboratoryId}/>
              </div>
            </div>
          </>
        : <Loader />
    }
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    agents: state.modules.reaction.jarStock.agentsData,
    resourceAgents: state.resource.agents,
    selectedJars: state.modules.reaction.jarStock.selectedJars,
    invalidAgents: state.modules.reaction.jarStock.invalidAgentsIndexList,
    labsList: itemsToArraySelector(state.resource.dictionary.labs).map(lab => ({ value: lab.Id, label: lab.Name })),
    userId: state.modules.users.selfUserId,
    data: state.resource.users.data
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAgentsBySmiles: (smilesFormulas, compoundNames) => dispatch(agentResourceActions.getBySmiles.request({params: { smilesFormulas, compoundNames }})),
  destroyAgents: () => dispatch(agentResourceActions.destroy()),
  destroyJarStock: () => dispatch(jarStockResourceActions.destroy()),
  destroyJarStockModule: () => dispatch(reactionModuleActions.jarStock.destroy()),
  loadJarStock: (ids) => dispatch(jarStockResourceActions.list.request({ params: { Ids: ids }})),
  setAgentsData: (agents) => dispatch(reactionModuleActions.jarStock.setAgentsData(agents)),
  getLabs: () => dispatch(labsResourceActions.list.request({}))
});

JarSelection = withRouter(
  connect(
      mapStateToProps,
      mapDispatchToProps
  )(JarSelection)
);

export {
  JarSelection
};
