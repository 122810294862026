import bnc                  from 'bnc';
import {Component}          from 'react';
import { NavLink }          from 'react-router-dom';
import propTypes            from 'prop-types';
import Tooltip              from '../Tooltip';
import Button               from '../../controls/Button';
import BodyTheme            from '../../subjects/BodyTheme';
import { faLightbulb }      from '@fortawesome/free-solid-svg-icons/faLightbulb';
import                           '../../subjects/Outline';
import CrossNav             from './CrossNav';
import Logotype             from './Logotype';
import Sidebar              from './Sidebar';
import                           './index.less';



export default class Screen extends Component {

    static LogoControl = () => (
        <NavLink className={`${Screen.block.el('logo-link')}`} to="/">
            <Logotype />
        </NavLink>
    );

    static Logo = ({title, version, versionlink, Logo, crossnav=false}) => (
        <div className={Screen.block.el('logo') + Screen.block.el('logo').mod('crossnav', crossnav ? 'yes' : 'no') }>
            { crossnav && <CrossNav /> }
            { Logo ? <Logo /> : Screen.LogoControl() }
            { title   && <span className={Screen.block.el('logo-title')}>{title}</span> }
            { version &&
              <span className={Screen.block.el('logo-version')}>{
                versionlink
                    ? <NavLink to={versionlink}>
                        {version}
                        <Tooltip.Hover>Информация о версии</Tooltip.Hover>
                      </NavLink>
                    : version
              }</span>
            }
        </div>
    );

    static Error = ({ children, code }) => (
        <div className={Screen.block.el('error')}>
            { code && <div className={Screen.block.el('error-code')}>{code}</div>}
            <div className={Screen.block.el('error-wrapper')}>{children}</div>
        </div>
    );

    static TopMenu = ({ children }) => (
        <div className={Screen.block.el('top-menu')}>{children}</div>
    );

    static TopNavLink = ({ to, exact, children }) => (
        <NavLink
            className       = {Screen.block.el('top-menu-navlink') + ''}
            activeClassName = {Screen.block.el('top-menu-navlink').mod('active') + ''}
            to              = {to}
            exact           = {exact}
        >{ children }</NavLink>
    );

    static UserSide = ({ScreenUser, themeButton}) =>
        <div className={Screen.block.el('userside')}>
            {
                ScreenUser &&
                <ScreenUser/>
            }
            {
                themeButton &&
                <Button
                    className = {Screen.block.el('theme-bulb')}
                    icon      = {faLightbulb}
                    onAction  = {BodyTheme.toggle}
                    size      = "L"
                />
            }
        </div>;

    static Header = ({children, ScreenUser, themeButton = true, className = '', ...$}) =>
        <section className={Screen.block.el('header') + className}>
            <Screen.Logo {...$} />
            {children}
            <Screen.UserSide ScreenUser={ScreenUser} themeButton={themeButton}/>
        </section>
    ;

    static Content = ({children, className = ''}) =>
        <section className={Screen.block.el('content') + className}>
            {children}
        </section>
    ;

    static SidebarLayout = ({children, className = ''}) =>
        <section className={Screen.block.el('sidebarlayout') + className}>
            {children}
        </section>
    ;

    static Sidebar = ({children, collapsible}) =>
        <Sidebar collapsible={collapsible} className={Screen.block.el('sidebar')}>
            {children}
        </Sidebar>
    ;

    static Panel = ({children, className = ''}) =>
        <section className={Screen.block.el('panel') + className}>
            {children}
        </section>
    ;

    static SidebarNavLink = ({children, to, lvl, disabled = false, exact = true}) =>(
        disabled
            ?   <span
                    className = {
                        Screen.block.el('sidebar-nolink') +
                        Screen.block.el('sidebar-nolink').mod('lvl', lvl)
                    }
                >{children}</span>
            : <NavLink
                {...{exact,to}}
                className = {
                    Screen.block.el('sidebar-navlink') +
                    Screen.block.el('sidebar-navlink').mod('lvl', lvl)
                }
                activeClassName = {
                    Screen.block.el('sidebar-navlink').mod('active') + ''
                }
            >{children}</NavLink>
    );

    static block = new bnc.default('b-screen')

    render = () => <section className={Screen.block}>{this.props.children}</section>
}

Screen                .propTypes   = { children: propTypes.node.isRequired };
Screen.Content        .propTypes   = { children: propTypes.node.isRequired };
Screen.Error          .propTypes   = { children: propTypes.node.isRequired };
Screen.TopNavLink     .propTypes   = {
    to: propTypes.string.isRequired,
    exact: propTypes.bool,
    children: propTypes.node.isRequired,
};
Screen.Content        .displayName = 'Screen.Content';
Screen.Error          .displayName = 'Screen.Error';
Screen.Header         .displayName = 'Screen.Header';
Screen.Logo           .displayName = 'Screen.Logo';
Screen.UserSide       .displayName = 'Screen.UserSide';
Screen.TopMenu        .displayName = 'Screen.TopMenu';
Screen.TopNavLink     .displayName = 'Screen.TopNavLink';
Screen.SidebarNavLink .displayName = 'Screen.SidebarNavLink';
Screen.SidebarLayout  .displayName = 'Screen.SidebarLayout';
Screen.Sidebar        .displayName = 'Screen.Sidebar';
Screen.Panel          .displayName = 'Screen.Panel';
