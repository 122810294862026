// @flow

import {
  put,
  debounce,
  takeEvery,
  select,
  all,
  call
} from 'redux-saga/effects';
import {
  reactionEventsHistoryResourceActions,
  reactionModuleActions,
  reactionsResourceActions,
  reactionAttachmentsActions,
} from '../actions';
import { AnalyticsModuleActions } from '../../analytics/actions';
import type { IReaction } from '../models';
import { getCurrentReaction, getCurrentReactionStates } from '../reducers';
import type { TReactionEditCurrent } from '../reducers';
import {
  getProductsFromForm,
  getReactionFromForm,
  getReagentsFromForm,
  getIndicatorParametersFromForm,
} from '../reducers/reaction-edit-form-plugin';
import { getToken, getUserName } from '../../account/reducers';
import { ReactionsResourceService } from '../services';
import { IReagent, Reagent, ReagentKeyNames, ReagentTypeEnum } from '../../reagents/model';
import type { IProduct } from '../../products/model';
import { ReagentsResourceService } from '../../reagents/services';
import { ProductsResourceService } from '../../products/services';
import { ReactionsAttachmentService } from '../services';
import { setGlobalLoader } from '../../shared/actions/loading';
import { push } from 'connected-react-router';
import { globalErrorsActions } from '../../shared/actions';
import { getEventsHistory, getNewName, getNewPurificationName, getNewNestedPurificationName, getReaction, getReactionAttachments } from './resource';
import { listReagents } from '../../reagents/sagas/resource';
import { listProducts } from '../../products/sagas';
import { getSmallMoleculesReaction, saveAnalyticsApi } from '../../analytics/sagas/analytics';
import { productsResourceActions } from '../../products/actions';
import { arrayPush, autofill } from 'redux-form';
import { REACTION_EDIT_FORM_NAME } from '../components/ReactionEdit';
import { reagentsResourceActions } from '../../reagents/actions';
import { getAddedAttachments, getDeletedAttachments } from '../reducers';
import { Reaction, ReactionAttachment } from '../models';
import { ArchivedReagentsResourceService } from '../../archivedReagents/services';
import { ReactionIndicatorParametersResourceService } from '../../reaction-indicator-parameters/services';
import { listReactionIndicatorParameters } from '../../reaction-indicator-parameters/sagas';
import { reactionIndicatorsResourceActions } from '../../reaction-indicator-parameters/actions';
import { REACTION_TYPE_PURIFICATION_ID } from '../../shared/globals/constants';
import { ReactionKeyNames, REACTANTS_KEY_NAME } from '../models';
import { uuidv4 } from '../../shared/utils/common';
import { getStage } from '../../stages/sagas';
import type { IRootStore } from '../../app/reducers';
import { listArchivedReagents } from '../../archivedReagents/sagas';
import { listReactionTypes } from '../../reactionTypes/sagas';
import { getDescendantsList } from '../../projects/sagas';
import { descendantsResourceActions } from '../../projects/actions';
import { folderParentType } from '../../projects/types/enum';
import { toast } from 'react-toastify';
import { ReactionAttachmentType } from '../models/reaction-attachment'
import {JarStockService} from "../../agents/services/jar-stock";

const toastSaveId = 'synth-list-save-check-id';
function* checkSaveTime(action) {
  const text = 'Синтетический лист не сохранялся более 15 минут. Чтобы не потерять результат работы, пожалуйста, нажмите кнопку «Сохранить»';
  if (action.check) {
    toast.dark(text, {
      autoClose: false,
      position: toast.POSITION.BOTTOM_RIGHT,
      style: { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
      toastId: toastSaveId,
    });
    yield put(reactionModuleActions.reactionEdit.checkSave({ check: true }));
  }
}

function* saveReaction() {
  // start loader  
  yield put(setGlobalLoader({ isLoading: true }));
  yield put(reactionModuleActions.reactionEdit.setCurrentSaveStatus({ status: null }));

  // remove toast
  toast.dismiss(toastSaveId);

  // if user doesn't have permissons for saving (e.g. reaction is finished, and user isn't superuser)
  // use partialSave() to continue saving 
  const states = yield select(getCurrentReactionStates);
  if (states.isReadonly) {
    return yield partialSave();
  }

  // otherwise, permissions is ok and use fullSaveReaction()
  return yield fullSaveReaction();
}

function* fullSaveReaction() {

  // get form data
  const
    data: IReaction = yield select(getReactionFromForm),
    token: string = yield select(getToken);
  //pristine: boolean = yield select(isReactionFormIsPristine);

  // // prevent saving not changed data
  // if (pristine) {
  //   yield put(setGlobalLoader({ isLoading: false }));
  //   return;
  // }

  try {
    // save reaction
    //PP: do not use the prop "current.reactionId" in this method. Use "data.Id".
    const savedReaction: { result: IReaction } = data.Id
      ? yield ReactionsResourceService.update({ data }, token)
      : yield ReactionsResourceService.create({ data }, token);

    const savedReactionId = savedReaction?.result?.Id;

    // update form substances 'ReactionId' field
    if (savedReactionId) {
      yield put(reactionModuleActions.reactionEdit.setNewReactionId({ reactionId: savedReactionId }));
    }

    // get substances from form
    const
      reagents: IReagent[] = yield select(getReagentsFromForm),
      products: IProduct[] = yield select(getProductsFromForm);

    const parentReagents = reagents.filter(r => !r.ParentReagentId && !r.ParentReagentLocalId);
    const subReagents = reagents.filter(r => r.ParentReagentId || r.ParentReagentLocalId);

    // save reagents one by one
    for (let reagent of parentReagents) {
      if (reagent.Id && !reagent.IsDeleted) // update reagent if it's not deleted
        data.IsArchived ?
          yield ArchivedReagentsResourceService.update({ data: reagent }, token) :
          yield ReagentsResourceService.update({ data: reagent }, token);
      if (reagent.Id && reagent.IsDeleted) // delete existing reagents
        data.IsArchived ?
          yield ArchivedReagentsResourceService.delete({ data: reagent }, token) :
          yield ReagentsResourceService.delete({ uriParams: { id: reagent.Id } }, token);
      if (!reagent.Id) // create new reagents
        data.IsArchived ?
          yield ArchivedReagentsResourceService.create({ data: reagent }, token) :
          yield ReagentsResourceService.create({ data: reagent }, token);
    }

    for (let reagent of subReagents) {
      if (reagent.Id && !reagent.IsDeleted) // update reagent if it's not deleted
        data.IsArchived ?
          yield ArchivedReagentsResourceService.update({ data: reagent }, token) :
          yield ReagentsResourceService.update({ data: reagent }, token);
      if (reagent.Id && reagent.IsDeleted) // delete existing reagents
        data.IsArchived ?
          yield ArchivedReagentsResourceService.delete({ data: reagent }, token) :
          yield ReagentsResourceService.delete({ uriParams: { id: reagent.Id } }, token);
      if (!reagent.Id) // create new reagents
        data.IsArchived ?
          yield ArchivedReagentsResourceService.create({ data: reagent }, token) :
          yield ReagentsResourceService.create({ data: reagent }, token);
    }

    const savedProducts = [];
    // save products one by one
    for (let product of products) {
      const savedPrd = (product.Id
        ? yield ProductsResourceService.update({ data: product }, token)
        : yield ProductsResourceService.create({ data: product }, token));
      savedProducts.push(savedPrd.result);
    }

    // save analytics
    const mainProductSavedId = savedProducts.find(p => p.IsMainProduct).Id;
    yield put(AnalyticsModuleActions.actualizeReactionInfo({
      reactionId: savedReactionId,
      productId: mainProductSavedId
    }));
    yield saveAnalyticsApi();

    // save indicator parameters
    const indicatorParameters = yield select(getIndicatorParametersFromForm);
    const activeIndicatorParameters = indicatorParameters.filter(i => !i.IsDeleted);
    yield ReactionIndicatorParametersResourceService.batch({ data: activeIndicatorParameters }, token);
    const deletedIndicatorIds = indicatorParameters.filter(i => i.IsDeleted).map(i => i.Id);
    yield ReactionIndicatorParametersResourceService.delete({ data: deletedIndicatorIds }, token);


    // save attachments
    yield saveAttachments(savedReactionId);

    // reload data
    //
    //
    yield reloadAfterSave(savedReactionId);
  }
  catch (error) {
    yield put(globalErrorsActions.set({ error }));
    yield put(reactionModuleActions.reactionEdit.setCurrentSaveStatus({ status: false }));
    yield put(push('/reactions/catalog'));
  }
  finally {
    yield put(setGlobalLoader({ isLoading: false }));
    yield put(reactionModuleActions.reactionEdit.checkSave({ check: true }));
  }
}

function* saveAttachments(savedReactionId) {
  const token = yield select(getToken);
  const addedAttachments = yield select(getAddedAttachments);
  const deletedAttachments = yield select(getDeletedAttachments);
  for (let attachment of addedAttachments) {
    // undeleted attachments only
    if (deletedAttachments.indexOf(attachment) < 0) {
      const item = ReactionAttachment({
        ReactionAttachmentType: attachment.ReactionAttachmentType,
        ReactionId: savedReactionId,
        IsDeleted: false,
        AttachmentId: attachment.AttachmentId,
      });
      yield ReactionsAttachmentService.create({ data: item }, token);
    }
  }
  for (let attachment of deletedAttachments) {
    // exiting attachments only
    if (attachment.Id) {
      yield ReactionsAttachmentService.delete({ uriParams: { id: attachment.Id } }, token);
    }
  }
}

function* partialSave() {
  // get form data
  try {
    const data = yield select(getReactionFromForm);
    const token = yield select(getToken);
  
    if (!data.Id) {
      throw new Error("Невозможно сохранить вложения: реакция еще не создана");
    }

    yield ReactionsResourceService.setNowToModificationDate({
      uriParams: { id: data.Id }    
    }, token);
    yield saveAttachments(data.Id);
    yield reloadAfterSave(data.Id);
  }
  catch (error) {
    yield put(globalErrorsActions.set({ error }));
    yield put(reactionModuleActions.reactionEdit.setCurrentSaveStatus({ status: false }));
    yield put(push('/reactions/catalog'));
  }
  finally {
    yield put(setGlobalLoader({ isLoading: false }));
    yield put(reactionModuleActions.reactionEdit.checkSave({ check: true }));
  }
}

function* reloadAfterSave(savedReactionId) {
  yield put(reactionModuleActions.reactionEdit.setCurrentReactionId({ reactionId: savedReactionId }));
  yield put(reactionsResourceActions.destroy());
  yield put(productsResourceActions.destroy());
  yield put(reagentsResourceActions.destroy());
  yield put(reactionEventsHistoryResourceActions.destroy());
  yield put(reactionAttachmentsActions.destroy());
  yield put(reactionModuleActions.reactionEdit.clearReactionAttachments());
  yield put(reactionIndicatorsResourceActions.destroy());
  yield put(push(`/reactions/${savedReactionId}`));

  yield loadInitialReactionEditData({ isNew: false });
  yield put(reactionModuleActions.reactionEdit.setCurrentSaveStatus({ status: true }));
  //yield put(push(`/reload?url=/reactions/${savedReactionId}`));
}

function* loadInitialReactionEditData(action: { isNew: boolean, isCloning: boolean }) {
  yield put(setGlobalLoader({ isLoading: true }));
  let current: TReactionEditCurrent = yield select(getCurrentReaction);
  yield listReactionTypes({});
  if (action.isNew) {
    if (current.isNestedPurification) {
      yield getNewNestedPurificationName({ params: {}, reactionName: current.primaryReactionName });
    } else if (current.isPurification && (current.smilesFormula || current.compoundName)) {
      yield getNewPurificationName({ params: {} });
    } else {
      yield getNewName({ params: { initialStageId: current.stageId } });
    }

    if (current.stageId) {
      yield getStage({ uriParams: { id: current.stageId } });
    }
  }
  else {
    const reqParams = { uriParams: { id: current?.reactionId } };
    yield put(reactionEventsHistoryResourceActions.destroy());
    yield all([
      call(getReaction, reqParams),
      call(getReactionAttachments, reqParams),
      call(listProducts, reqParams),
      call(listReactionIndicatorParameters, {
        params: {
          reactionId: current.reactionId
        }
      }),
      call(getEventsHistory, {
        params: {
          skip: 0,
          limit: 20,
          Order: '-CreationDate',
        },
        ...reqParams
      }),
      call(getSmallMoleculesReaction, { id: current.reactionId }),
    ]);
    const reaction: IReaction = yield select(
      (state: IRootStore) => Reaction(state.resource.reactions.data[current.reactionId] || {})
    );
    yield put(
      reactionModuleActions.reactionEdit.setIsPurification({
        isPurification: reaction.ReactionType === REACTION_TYPE_PURIFICATION_ID
      })
    );
    yield put(
      reactionModuleActions.reactionEdit.setIsArchived({
        isArchived: reaction.IsArchived
      })
    );
    if (!action.isCloning) {
      yield put(
        reactionModuleActions.reactionEdit.setCurrentStageId({
          stageId: reaction.InitialStageId
        })
      );
    }
    if (Number.isInteger(reaction.FolderId)) {
      yield put(
        reactionModuleActions.reactionEdit.setCurrentFolderId({
          folderId: reaction.FolderId
        })
      );
    }
    if (reaction.InitialStageId) {
      yield put(descendantsResourceActions.destroy());
      yield all([
        call(getDescendantsList, {
          parentId: reaction.InitialStageId,
          parentType: folderParentType.stage,
        }),
        call(getStage, { uriParams: { id: reaction?.InitialStageId } }),
      ]);
    }

    if (reaction.IsArchived) {
      yield listArchivedReagents({ uriParams: { id: reaction.Id } });
    }
    else {
      yield listReagents({ uriParams: { id: reaction.Id } });
    }
  }
  yield put(setGlobalLoader({ isLoading: false }));
  yield put(reactionModuleActions.reactionEdit.checkSave({ check: true }));
}

function* cloneReaction() {
  //yield saveReaction();
  const current: TReactionEditCurrent = yield select(getCurrentReaction);
  const authorUserName: string = yield select(getUserName);
  const stageId: number = yield select(state => state.modules?.reaction?.editReaction?.current?.stageId);
  yield put(reactionModuleActions.reactionEdit.resetClone({ originalId: current.reactionId, authorUserName: authorUserName, stageId: stageId }));
  yield put(reactionAttachmentsActions.destroy());
  yield put(AnalyticsModuleActions.destroyAnalytics());
  if (current.isPurification) {
    yield put(reactionsResourceActions.getNewPurificationName.request({ params: {} }));
  }
  else {
    yield put(reactionsResourceActions.getNewName.request({ params: { initialStageId: current?.stageId } }));
  }
}

function* getHistoricalReactionsCount(action) {
  const token = yield select(getToken);
  yield put(reactionModuleActions.sidebar.setHistoricalCount({ count: null }));
  let answer = yield ReactionsResourceService.countHistorical({ uriParams: { stageId: action.stageId } }, token);
  yield put(reactionModuleActions.sidebar.setHistoricalCount({ count: answer.result.Count }));
}

function* searchReactionTemplateById(action) {
  try {
    yield put(reactionModuleActions.template.setIsPending({ value: true }));

    const token: string = yield select(getToken);
    const result = (yield ReactionsResourceService.get({ uriParams: { id: action.reactionId } }, token)).result;

    yield put(reactionModuleActions.template.setSearchedReaction({ result }));

    const reagnetsResult = (yield ReactionsResourceService.getReagents({ uriParams: { id: action.reactionId } }, token)).result;

    const oldReagents = reagnetsResult.filter(i => !i.IsRequired && i.ReagentType !== ReagentTypeEnum.Reactant);

    oldReagents.sort((a, b) => a.Id - b.Id);

    const reagentsToClone = oldReagents.map(r => ({
      ...r,
      Id: 0,
      FactualConsumption: null,
      ReactionId: 0, //PP: возможно, здесь лучше явно проставлять Id реакции
      Jars: r.Jars.map(j => ({ ...j, Id: 0, ReagentId: 0, IsReturned: false })),
      localUuid: uuidv4(),
      _oldId: r.Id
    }));

    //Восстанавливаем родительские реагенты для подстрок
    for(let rg of reagentsToClone) {
      if(rg.ParentReagentId) {
        rg.ParentReagentLocalId = reagentsToClone.find(i => i._oldId == rg.ParentReagentId)?.localUuid;
      } else {
        rg.ParentReagentLocalId = null;
      }
      rg.ParentReagentId = null;
    }

    const jarsToAgents = oldReagents
        .map((reagent) => ({
          reagentId: reagent.Id,
          agentId: reagent.Agent.Id,
          smiles: reagent.Agent.Compound.SmilesFormula,
          complexCompoundName: !reagent.Agent.Compound.SmilesFormula ? reagent.Agent.Compound.Name : null,
          jars: reagent.Jars.map(j => j.AgentAvailabilityId),
          isEmptyJars: null,
          amount: reagent.Amount
        })
    );

    const jarStockService = new JarStockService(token);
    
    for (let item of jarsToAgents) {
      const r = yield jarStockService.getJar({Ids: item.jars});
      const res = r?.result ?? [];
      item.isEmptyJars = res.reduce((acc, cur) => acc + cur.Amount, 0) <= 0;
    }
    /*for (let item of jarsToAgents) {
      item.isEmptyJars = true;
    }*/
    const emptyJarsInfo = jarsToAgents.filter((item) => item.isEmptyJars);
    const hasEmpty = (emptyJarsInfo.length > 0);
    if(action.checkEmpty) {
    if (emptyJarsInfo.length) {      
        yield put(reactionModuleActions.template.setEmptyJarsInfo({emptyJarsInfo}));
        yield put(reactionModuleActions.template.setHasEmptyJars({hasEmpty}));      
    }
    else {
      yield put(reactionModuleActions.template.setEmptyJarsInfo([]));
      yield put(reactionModuleActions.template.setHasEmptyJars({hasEmpty}));
    }
  }
    const reactants = reagnetsResult.filter(i => i.IsRequired);

    yield put(reactionModuleActions.template.setReagents({ reagents: reagentsToClone }));
    yield put(reactionModuleActions.template.setReactantsInfo({ reactants }));
  }
  catch (ex) {
    yield put(reactionModuleActions.template.setSearchedReaction({ result: null }));
    yield put(reactionModuleActions.template.setReagents({ reagents: [] }));
    yield put(reactionModuleActions.template.setReactantsInfo({ reactants: [] }));

  }
  finally {
    yield put(reactionModuleActions.template.setIsPending({ value: false }));
  }

}

function* useTemplate() {
  const token: string = yield select(getToken);
  const reaction = yield select(getReactionFromForm);
  const templateReaction = yield select(state => state.modules.reaction.template.searchedReaction);
  const reagents = yield select(state => state.modules.reaction.template.reagents);
  const reactionReagents = yield select(getReagentsFromForm);
  const templateReactants = yield select(state => state.modules.reaction.template.reactantsInfo);

  yield put(autofill(REACTION_EDIT_FORM_NAME, ReactionKeyNames.ReactionTemplateId, templateReaction.Id));

  yield put(autofill(REACTION_EDIT_FORM_NAME, ReactionKeyNames.AdditionalName, templateReaction.AdditionalName));
  yield put(autofill(REACTION_EDIT_FORM_NAME, ReactionKeyNames.ReactionExtraConditions, templateReaction.ReactionExtraConditions));
  yield put(autofill(REACTION_EDIT_FORM_NAME, ReactionKeyNames.Methodology, reaction.Methodology + '<div>Методика шаблона: </div>' + (templateReaction.Methodology || '')));
  yield put(autofill(REACTION_EDIT_FORM_NAME, ReactionKeyNames.TLCText, reaction.TLCText + '<div>ТСХ шаблона: </div>' + (templateReaction.TLCText || '')));


  if (templateReaction.ReactionType !== REACTION_TYPE_PURIFICATION_ID &&
    reaction.ReactionType !== REACTION_TYPE_PURIFICATION_ID) {
    yield put(autofill(REACTION_EDIT_FORM_NAME, ReactionKeyNames.ReactionType, templateReaction.ReactionType));
  }

  //Копирование файлов (ТСХ)
  const templateFiles = (yield ReactionsResourceService.getAttachedFiles({uriParams: { id: templateReaction.Id}}, token)).result;
  const attachmentsToCopy = templateFiles.filter(a => a.ReactionAttachmentType === ReactionAttachmentType.TLC)
                                         .map(a => ({...a, Id: 0}));
  yield put(reactionModuleActions.reactionEdit.addReactionAttachments({ attachments: attachmentsToCopy }));

  //Копирование реагентов
  for (const r of reagents) {
    yield put(arrayPush(REACTION_EDIT_FORM_NAME, REACTANTS_KEY_NAME, Reagent(r)));
  }

  //Проставление реактантам эквивалентов из реакции-шаблона
  const reactionReactants = reactionReagents.filter(i => i.IsRequired);
  templateReactants.sort((a, b) => a.Id - b.Id);
  for (let i = 0; i < reactionReactants.length; i++) {
    let eq = 1.0;
    if (i < templateReactants.length) {
      eq = templateReactants[i].Eq;
    }
    yield put(autofill(REACTION_EDIT_FORM_NAME, `${REACTANTS_KEY_NAME}[${i}].${ReagentKeyNames.Eq}`, eq));
  }

  yield put(reactionModuleActions.recalc.all({ debounce: false }));
}

function* useSpecificTemplateReaction(action) {
  yield put(reactionModuleActions.template.specTemplateApplied({ value: false }));
  yield searchReactionTemplateById(action.reactionId);
  yield useTemplate();
  yield put(reactionModuleActions.template.specTemplateApplied({ value: true }));
}

const recalcDebounce = 300;

export const reactionModuleWatchers = [
  function* () {
    yield debounce(recalcDebounce, reactionModuleActions.recalc.reagent.type, function* (action) {
      if (action.debounce) {
        yield put(reactionModuleActions.recalc.reagent({ reagentIdx: action.reagentIdx }));
      }
    });
  },
  function* () {
    yield debounce(recalcDebounce, reactionModuleActions.recalc.product.type, function* (action) {
      if (action.debounce) {
        yield put(reactionModuleActions.recalc.product({ productIdx: action.productIdx }));
      }
    });
  },
  function* () {
    yield debounce(recalcDebounce, reactionModuleActions.recalc.all.type, function* (action) {
      if (action.debounce) {
        yield put(reactionModuleActions.recalc.all({}));
      }
    });
  },
  function* () {
    yield debounce(recalcDebounce, reactionModuleActions.recalc.reaction.type, function* (action) {
      if (action.debounce) {
        yield put(reactionModuleActions.recalc.reaction({}));
      }
    });
  },
  function* () {
    yield takeEvery(reactionModuleActions.reactionEdit.saveReaction.type, saveReaction);
  },
  function* () {
    yield takeEvery(reactionModuleActions.reactionEdit.openSpendingsDialog.type, function* ({ id, name }) {
      yield saveReaction();
      yield put(reactionModuleActions.reactionEdit.openDialog({ id, name }));
    });
  },
  function* () {
    yield takeEvery(reactionModuleActions.reactionEdit.closeSpendingsDialog.type, function* ({ reagentConsumptions }) {
      try {
        yield put(reactionModuleActions.reactionEdit.updateReagentsConsumption({ reagentConsumptions }));
        yield put(reactionModuleActions.recalc.all({ debounce: false }));
        yield saveReaction();
      } finally {
        yield put(reactionModuleActions.reactionEdit.closeLastDialog());
      }
    });
  },
  function* () {
    yield takeEvery(reactionModuleActions.reactionEdit.cloneReaction.type, cloneReaction);
  },
  function* () {
    yield takeEvery(reactionModuleActions.sidebar.requestHistoricalCount, getHistoricalReactionsCount);
  },
  function* () {
    yield takeEvery(reactionModuleActions.template.requestSearchById, searchReactionTemplateById);
  },
  function* () {
    yield takeEvery(reactionModuleActions.template.useTemplate, useTemplate);
  },
  function* () {
    yield takeEvery(reactionModuleActions.template.useSpecificTemplateReaction, useSpecificTemplateReaction);
  },
  function* () {
    yield takeEvery(reactionModuleActions.reactionEdit.loadInitialData.type, loadInitialReactionEditData);
  },
  function* () {
    const delay = 15 * 60 * 1000; // 15 mins
    yield debounce(delay, reactionModuleActions.reactionEdit.checkSave.type, checkSaveTime);
  }
];
