import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ItemPages } from '../../../../shared/components/ItemPages';
import css from './spendings.m.less';
import SpendingsForm from '../SpendingsForm';
import { jarStockResourceActions } from '../../../../agents/actions';
import classNames from 'classnames';

const ReactionSpendings = ({ reagents, getJars }) => {
  useEffect(() => {
    reagents && reagents.length && getJars(reagents);
  }, []);

  const [reagentsIndex, setReagentsIndex] = useState(0);

  const onItemPagesClick = (val) => {
    setReagentsIndex(val);
  };

  const ReagentItem = ({ reagent, className }) => {
    return (
      <div className={classNames(css.grid, className)}>
        <div className={css.thumbnail}>
          <img
            src={reagent?.Agent?.Compound?.Thumbnail}
            alt={reagent?.Agent?.ShortName}
            title={reagent?.Agent?.ShortName}
          />
        </div>
        <div className={css.info}>
          <span className={css.info__field}>{reagent?.Agent?.Name}</span>
          <span className={css.info__field}>{reagent?.FactualConsumption}</span>
        </div>
      </div>
    );
  };

  const childReagents = reagents.filter(item=>item.ParentReagentId || item.ParentReagentLocalId);  
  let parentReagents = reagents.filter(item=> (!item.ParentReagentId && !item.ParentReagentLocalId));  
  parentReagents = parentReagents.filter(item => !childReagents.some(i => i.ParentReagentId && item.Id == i.ParentReagentId));  
  parentReagents = parentReagents.filter(item => !childReagents.some(i => i.ParentReagentLocalId && item.localUuid == i.ParentReagentLocalId));
  const resultReagents = [...childReagents,...parentReagents];
  const reagents2show = reagents.filter(i=> (i.Id && resultReagents.some(r=>r.Id == i.Id)) || i.localUuid && resultReagents.some(r=>r.localUuid == i.localUuid));
  console.log('regs', reagents2show);
  return (
    <>
      {
        reagents2show?.length > 1 &&
        <ItemPages label='Вещество:' items={reagents2show} itemIndex={reagentsIndex} itemClickHandler={onItemPagesClick} />
      }
      {
        reagents2show.map((reagent, index) =>
          index === reagentsIndex && <ReagentItem key={`${reagent.Id}__${index}`} reagent={reagents2show[index]} />
        )
      }
      <div className={css.jarStock}>
        <SpendingsForm agentId={reagents2show[reagentsIndex]?.Agent?.Id} reagent={reagents2show[reagentsIndex]} />
      </div>
    </>
  );
};

const mapStateToProps = () => ({

});

const mapDispatchToProps = dispatch => ({
  getJars: (reagents) => dispatch(jarStockResourceActions.getJar.request({data: reagents})),
});

export default
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ReactionSpendings);
