import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)`
  color: inherit;
`;

export const AgentsTableStyled = styled.table`
  margin-bottom: var(--LayoutBlock-padding-v);
  .Thumbnail {
    width: 144px;
    height: 144px;
  }
  ${StyledLink} {
    color: var(--grey-400);
  }
  .button-holder {
    display: flex;
    align-items: center;
  }
  .grid-table-row-wrap {
    cursor: pointer;
    &.is-expanded > .grid-table-row {
      border-bottom: none;
    }
    &.is-expanded:not(.highlight-error) > .grid-table-row, 
    .grid-table-row-after {
      background-color: inherit !important;
    }
    &.is-expanded.highlight-error > .grid-table-row {
      background-color: var(--red-o-15) !important;
    }
    &.is-selected > * {
      background-color: var(--white);
    }
  } 
`;
