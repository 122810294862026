import { BehaviorSubject, fromEvent }   from 'rxjs';

var ms = 'mouse',
    kb = 'keyboard',
    Outline = new BehaviorSubject( ms ),
    OnInteractionChanged = value =>
        document.body.className = Array
            .from   ( document.body.classList                          )
            .filter ( classes => !~classes.indexOf('last-interaction') )
            .concat ( [`last-interaction-${ value }`]                  )
            .join   ( ' ' )
    ;

fromEvent(document, 'keyup'    ).subscribe( ({keyCode})=> (keyCode === 9) && Outline.next(kb) );
fromEvent(document, 'mousemove').subscribe( ()=>Outline.next(ms) );

Outline.subscribe( OnInteractionChanged );

export default Outline;