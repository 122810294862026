const   weekdaysShort = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],

        months = [
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Май',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
            'Октябрь',
            'Ноябрь',
            'Декабрь',
        ],

        weekdaysLong = [
            'Воскресенье',
            'Понедельник',
            'Вторник',
            'Среда',
            'Четверг',
            'Пятница',
            'Суббота',
        ],

        firstDayOfWeek = 1,

        labels = {
            nextMonth:     'следующий месяц',
            previousMonth: 'предыдущий месяц'
        },

        regexp = new RegExp( /^(0[1-9]|1\d|2\d|30|31)\.(1[0-2]|0[1-9])\.(\d{4})$/ ),

        localeRu = {
            locale: 'ru',
            placeholder: 'дд.мм.гггг',
            regexp,
            months,
            weekdaysLong,
            weekdaysShort,
            firstDayOfWeek,
            labels
        };

export default localeRu;