import React from 'react';
import Dialog from '@biocad/bcd-front-ui/layout/Dialog';
import { IWorkProject } from '../../../models';
import { Link } from 'react-router-dom';
import { ReactionNameWithType } from '../../ReactionsList/ReactionNameWithType';
import css from './popup.m.less';
import dashboardStyles from '../../dashboard.m.less';

export const ProjectMoreInfoPopup = ({project, toggleModal}: {project: IWorkProject, toggleModal: () => null}) => {
  
  const renderCandidates = (candidates, reactions) => {
    return candidates.map(candidate => 
      <div key={candidate.Id} className={css.candidate}>
        <Link to={'#'}>
          <img className={css.thumbnail} src={candidate.Thumbnail} />
        </Link>
        <div className={css.reactions}>
          {
            reactions
                .filter(reaction => reaction.CandidateId === candidate.Id)
                .map((reaction) =>
                  <Link to={`/reactions/${reaction.Id}`} key={reaction.Id} className={dashboardStyles.textLink}>
                    <ReactionNameWithType item={reaction}/>
                  </Link>
                )
          }
        </div>
      </div>);
  };

  return (
    <Dialog paranjaClose={false} close={() => toggleModal(null)}>
      <h3>{`${project.Name}: целевые молекулы и реакции`}</h3>
      <hr className={css.hr}/>
      <div className={css.candidatesList}>
        {
          project && renderCandidates(project.Candidates, project.Reactions)
        }
      </div>
    </Dialog>
  );
};
