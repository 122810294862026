// @flow

import { jarSpendingResourceActions } from '../actions';
import { mergeStateDataWithActionResult, resourceReducer } from '../../shared/utils/reducer-creators';

export const jarSpendingResourceReducer = resourceReducer(jarSpendingResourceActions, {
  [jarSpendingResourceActions.create.success]: (state, action) => ({
    pending: action.pending,
    fail: action.fail,
    data: { ...state.data, ...{ [action.result.Id]: action.result } },
    params: action.params,
    error: null,
  }),
});
