import { environment } from './../../../environment'
import React, { useState } from 'react';

const JChem = () => {
    const [address, setAddress] = useState('');
    const [request, setRequest] = useState('NC1=C(C=NN1)C#N');
    const [service, setService] = useState('JChem');
    const [isStrictSearch, setIsStrictSearch] = useState(false);
    const [result, setResult] = useState(null);
    
    const onSubmit = (event: any) => {
        event.preventDefault();/*
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                address: address,
                request: request,
                service: service,
                isStrictSearch: isStrictSearch
            })
        };
        fetch(environment.apiUri + "/tests/check", requestOptions)
            .then(response => response.json())
            .then(data => setResult(data.Result));
*/
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    request: request,
                    address: address
                })
            };
            fetch(environment.apiUri + "/tests/tags", requestOptions)
                .then(response => response.json())
                .then(data => setResult(data.Result));
    };

    const setupJChemService = () => {
        setService('JChem');
        setRequest('NC1=C(C=NN1)C#N');
    }

    const setupStructureService = () => {
        setService('Structure');
        setRequest('C1=CC=CC=C1 |c:0,2,4|');
    }

    return (
        <>
            <form onSubmit={onSubmit} style={{"padding": "10px"}}>
                <div className={'searchFields'}>
                    <div style={{display: "flex", alignItems: "center"}}>Сервис:                         
                        <input 
                            type="checkbox" 
                            checked={service === 'JChem'}                            
                            style={{marginLeft: "10px", marginRight: "5px"}} 
                            onChange={(e) => { e.target.checked?setupJChemService():setupStructureService()} }/>
                            JChem
                        <input 
                            type="checkbox" 
                            checked={service === 'Structure'}
                            style={{marginLeft: "10px", marginRight: "5px"}} 
                            onChange={(e) => { e.target.checked?setupStructureService():setupJChemService()} }/>
                            Structure
                    </div>
                    <label>Адрес сервиса: 
                        <input 
                            type="text" 
                            value={address}
                            placeholder='http://address.com/webservices/rest-v0/'
                            style={{marginLeft: "10px", "width": "600px"}} 
                            onChange={(e)=>setAddress(e.target.value)}/>
                    </label>
                    <br/>                
                    <label>Запрос: 
                        <input 
                            type="text" 
                            value={request} 
                            style={{marginLeft: "10px", width: "600px"}} 
                            onChange={(e)=>setRequest(e.target.value)}/>
                    </label>
                    <br/>
                    { (service === 'Structure') ?
                    <div style={{display: "flex", alignItems: "center"}}>
                        <input 
                            type="checkbox" 
                            checked={isStrictSearch === true}
                            style={{marginRight: "5px"}} 
                            onChange={(e)=>  { setIsStrictSearch(e.target.checked) } }/>Точное совпадение
                    </div> : <></>}
                    <br/>
                    <input type="submit" value="Выполнить" />                                        
                    <div style={{border: "1px solid black", padding: "15px", marginTop: "20px"}} >{result}</div>
                </div>
            </form>
        </>
    );
};

export default JChem;

