// @flow

import React, { Fragment, useEffect, useState } from 'react';
import type { StatelessFunctionalComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isDirty, isSubmitting } from 'redux-form';
import { ConfirmDialog } from './components/ConfirmDialog/ConfirmDialog';
import { CustomPrompt } from './CustomPrompt';
import { analyticsHasChanges } from '../analytics/reducers/analytics-module';


export interface IWarnUnsavedFormProps {
    isDirtyForm: boolean;
    isSubmittingForm: boolean;
    hasChanges: boolean;
    handleClose: typeof Function;
}

export function ChangesWatcher({ formName, hasChanges, hasChangesSelector = () => {} }: { formName: string, hasChanges: boolean, hasChangesSelector: () => {} }, WrappedComponent: StatelessFunctionalComponent): StatelessFunctionalComponent {
    
    const WarnUnsavedForm = (props: IWarnUnsavedFormProps) => {
        let directChanges, formChanges;

        directChanges = hasChanges || props.hasChanges;
        formChanges = formName !== undefined 
            ? props.isDirtyForm || props.isSubmittingForm
            : null;

        const isUnsaved = formChanges !== null ? formChanges : directChanges;

        const [confirmVisible, setConfirmVisible] = useState(false);
        const switchConfirmVisibility = () => {
            setConfirmVisible(prev => !prev);
        };
        const handleCloseDialog = () => {
            setConfirmVisible(false);
        };

        useEffect(() => {
            isUnsaved
                ? window.addEventListener('beforeunload', _promptUnsavedChange)
                : window.removeEventListener(
                      'beforeunload',
                      _promptUnsavedChange
                  );

            return () => {
                window.removeEventListener(
                    'beforeunload',
                    _promptUnsavedChange
                );
            };
        }, [isUnsaved]);

        const _promptUnsavedChange = e => {
            e.preventDefault();
            e.returnValue = '';
        };

        const handleCloseDecorated = () => {
            isUnsaved
                ? switchConfirmVisibility()
                : props.handleClose();
        };

        return (
            <Fragment>
                <WrappedComponent
                    {...props}
                    handleClose={handleCloseDecorated}
                />
                <CustomPrompt
                    when={isUnsaved}
                    showHandler={switchConfirmVisibility}
                    declineLabel={'Ок'}
                    declineAction={handleCloseDialog}
                />
                {confirmVisible && (
                    <ConfirmDialog
                        confirmLabel={'Всё равно закрыть'}
                        declineLabel={'Продолжить редактирование'}
                        confirmAction={props.handleClose}
                        declineAction={handleCloseDialog}
                    />
                )}
            </Fragment>
        );
    };

    WarnUnsavedForm.propTypes = {
        isDirtyForm: PropTypes.bool,
        isSubmittingForm: PropTypes.bool,
    };

    const mapStateToProps = state => {
        return {
            isDirtyForm: isDirty(formName)(state),
            isSubmittingForm: isSubmitting(formName)(state),
            hasChanges: hasChangesSelector(state),
        };
    };

    return withRouter(
        connect(
            mapStateToProps,
            null
        )(WarnUnsavedForm)
    );
}
