import {
    put,
    select,
    takeLeading,
    fork,
} from 'redux-saga/effects';
import { testStatusesResourceActions, smTypesResourceActions, testClassesResourceActions } from '../actions';
import { getToken } from '../../account/reducers';
import { BimsService } from '../services';
import { ISmType, ITestClassed, ITestStatus} from '../models';
import {bimsDictionaryModuleActions} from '../reducers';

export function* getTestStatuses() {
  const token = yield select(getToken);
  const service = new BimsService(token);
  try {
      const { result }: {result: ITestStatus[] }  = yield service.getTestStatuses();      
      yield put(testStatusesResourceActions.list.success({result}));
      yield put(bimsDictionaryModuleActions.setTestStatusesRequested());
  } catch (e) {
      if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
          yield put(testStatusesResourceActions.list.failure({error: e.response.data['ErrorMessage']}));
      }
  }
}

export function* getSmTypes() {
  const token = yield select(getToken);
  const service = new BimsService(token);
  try {
      const { result }: {result: ISmType[] } = yield service.getSmTypes();
      yield put(smTypesResourceActions.list.success({result}));
      yield put(bimsDictionaryModuleActions.setSmTypesRequested());
  } catch (e) {
      if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
          yield put(smTypesResourceActions.list.failure({error: e.response.data['ErrorMessage']}));
      }
  }
}

export function* getTestClasses() {
  const token = yield select(getToken);
  const service = new BimsService(token);
  try {
      const { result }: {result: ITestClassed[] }  = yield service.getTestClasses();
      yield put(testClassesResourceActions.list.success({result}));
      yield put(bimsDictionaryModuleActions.setTestClassesRequested());
  } catch (e) {
      if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
          yield put(testClassesResourceActions.list.failure({error: e.response.data['ErrorMessage']}));
      }
  }
}

export const bimsWatchers = [
  function* () {
    yield takeLeading(testStatusesResourceActions.list.request.type, function* (action) {
        yield fork(getTestStatuses, action);
    });
  },
  function* () {
    yield takeLeading(smTypesResourceActions.list.request.type, function* (action) {
        yield fork(getSmTypes, action);
    });
  },
  function* () {
    yield takeLeading(testClassesResourceActions.list.request.type, function* (action) {
        yield fork(getTestClasses, action);
    });
  },
];