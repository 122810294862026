// @flow

import { globalAction } from '../utils/action-creators';

export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export interface SetErrorAction {
    type: typeof SET_ERROR;
    payload: {
        errorMessage: string
    }
}

export interface ClearErrorsAction {
    type: typeof CLEAR_ERRORS;
}

export type ErrorAction = SetErrorAction | ClearErrorsAction;

export function clearErrors(): ClearErrorsAction {
    return {
        type: CLEAR_ERRORS
    };
}

export const globalErrorsActions = {
    set: globalAction('APPLICATION', ['ERROR', 'SET'], ({ error }) => ({ error })),
    destroy: globalAction('APPLICATION', ['ERROR', 'DESTROY'], () => null),
};
