// @flow
import {resourceActions, moduleAction} from '../../shared/utils';
import {CRUDStatusActions} from '../../shared/utils/action-creators';

export const PRODUCTION_SCHEME_MODULE_NAME = 'PRODUCTION_SCHEME';

export const productionSchemeResourceActions = {
    ...resourceActions(PRODUCTION_SCHEME_MODULE_NAME),
    editSchemeComment: CRUDStatusActions(PRODUCTION_SCHEME_MODULE_NAME, 'EDIT_SCHEME_COMMENT'),
    editSchemeIsFavorite: CRUDStatusActions(PRODUCTION_SCHEME_MODULE_NAME, 'EDIT_SCHEME_FAVORITE')
};