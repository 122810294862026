import {
    put,
    takeEvery,
    fork,
    select,
} from 'redux-saga/effects';
import {ProductionSchemeService} from '../services';
import {productionSchemeResourceActions} from '../actions';
import {getToken} from '../../account/reducers';

export function* getProductionSchemeList(params) {
    const token = yield select(getToken);
    const service = new ProductionSchemeService(token);
    try {
        const result = yield service.getList(params);
        yield put(productionSchemeResourceActions.list.success(result));
    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(productionSchemeResourceActions.list.failure({error: e.response.data['ErrorMessage']}));
        }
    }
}

export function* editSchemeComment(data) {
    const {schemeId, quickComment, finalCandidateId, folderId} = data;
    const token = yield select(getToken);
    const service = new ProductionSchemeService(token);
    try {
        const result = yield service.editComment(schemeId, data);
        yield put(productionSchemeResourceActions.editSchemeComment.success(result));
        yield fork(getProductionSchemeList, {
            finalCandidateId: finalCandidateId,
            showOnlyTopLevelSchemes: true,
            folderId: folderId
        });
    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(productionSchemeResourceActions.editSchemeComment.request({error: e.response.data['ErrorMessage']}));
        }
    }
}

export function* editSchemeIsFavorite(data) {
    const {schemeId, isFavorite} = data;
    const token = yield select(getToken);
    const service = new ProductionSchemeService(token);
    try {
        const result = yield service.editIsFavorite(schemeId, data);
        yield put(productionSchemeResourceActions.editSchemeIsFavorite.success(result));
        yield fork(getProductionSchemeList, {
            finalCandidateId: finalCandidateId,
            showOnlyTopLevelSchemes: true,
            folderId: folderId
        });
    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(productionSchemeResourceActions.editSchemeIsFavorite.request({error: e.response.data['ErrorMessage']}));
        }
    }
}


export const productionSchemeWatchers = [

    function* watchProductionSchemeList() {
        yield takeEvery(productionSchemeResourceActions.list.request.type, function* (action) {
            const {params} = action;
            yield fork(getProductionSchemeList, params);
        });
    },

    function* watchEditComment() {
        yield takeEvery(productionSchemeResourceActions.editSchemeComment.request.type, function* (action) {
            const {data} = action;
            yield fork(editSchemeComment, data);
        });
    },

    function* watchEditFavorite() {
        yield takeEvery(productionSchemeResourceActions.editSchemeIsFavorite.request.type, function* (action) {
            const {data} = action;
            yield fork(editSchemeIsFavorite, data);
        });
    }

];
