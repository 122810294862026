export interface IDashboardTab {
  value: string;
  label: string;
}

export const DashboardTabsValues = {
  reactions: 'reactions',
  workProjects: 'work-projects',
  ownedProjects: 'owned-projects',
};

export const DashboardTabsLabels = {
  reactions: 'Реакции',
  workProjects: 'Проекты',
  ownedProjects: 'Курируемые проекты',
};