// @flow

import React, {
  useEffect,
  useState
} from 'react';
import { connect } from 'react-redux';
import Dialog from '@biocad/bcd-front-ui/layout/Dialog';
import Button from '@biocad/bcd-front-ui/controls/Button';
import Loader from '@biocad/bcd-front-ui/controls/Loader';
import { _noop } from '../../../../../shared/utils/common';
import { stageModuleActions, stageResourceActions } from '../../../../actions';
import { schemesResourceActions } from '../../../../../schemes/actions';
import { STAGE_MERGE_OPTION_ENUM } from '../../../../constants';
import { getSelectedSubstanceSelector, getMergeableStages } from '../../../../reducers';
import type { IMergeableStage, IStageSubstance } from '../../../../models';
import { DialogLayout, MergeableStageItemStyled } from './style';
import type { ICompound } from '../../../../../agents/models/i-agent';

interface IMergeableSchemesDialogProps {
  stageId?: number,
  schemeId?: number,
  selectedSubstance?: IStageSubstance,
  pending?: boolean,
  mergeableSchemeIds?: number[],
  mergeableStages?: IMergeableStage[],
  setMergeOption?: (string)=>void,
  loadMergeableSchemes?: (number) => void,
  loadLastStagesByIds?: (number[]) => void,
  clearLoadedStages?: (number)=>void,
  clearSchemes?: () => void,
  setSubstance?: (ICompound, number)=>void,
}

const defaultProps: IMergeableSchemesDialogProps = {
  stageId: null,
  schemeId: null,
  selectedSubstance: null,
  pending: false,
  mergeableStages: [],
  setMergeOption: _noop,
};

let MergeableSchemesDialog = (props: IMergeableSchemesDialogProps = defaultProps) => {

  // State
  const [selectedStage: IMergeableStage, setSelectedStage] = useState(null);

  // Effects
  const _loadSchemesOnMount = () => {
    if (props.schemeId) {
      props.loadMergeableSchemes(props.schemeId);
    }
  };
  useEffect(_loadSchemesOnMount, [props.schemeId]);

  const _loadStagesOnMergeableSchemesLoad = () => {
    if (props.mergeableSchemeIds && props.mergeableSchemeIds.length) {
      props.loadLastStagesByIds(props.mergeableSchemeIds);
    }
  };
  useEffect(_loadStagesOnMergeableSchemesLoad, [props.mergeableSchemeIds]);

  // Handlers
  const
    onCancel = () => {
      props.clearLoadedStages(props.stageId);
      props.clearSchemes();
      props.setMergeOption(STAGE_MERGE_OPTION_ENUM.DEFAULT);
    },
    onCandidateSelect = () => {
      if (!selectedStage) {
        return;
      }
      props.setSubstance(selectedStage, props.selectedSubstance.position);
      props.clearLoadedStages(props.stageId);
      props.clearSchemes();
      props.setMergeOption(STAGE_MERGE_OPTION_ENUM.DEFAULT);
    },
    onStageClick = (stage: IMergeableStage) => {
      if (stage && stage.Id) {
        setSelectedStage(stage);
      }
    };

  return (
    <Dialog
      paranjaClose={false}
      close={onCancel}
    >
      <DialogLayout className={'dialog-layout'}>
        <header className={'dialog-header'}>
          <h3>Выбор схемы получения</h3>
        </header>
        <div className={'dialog-contents'}>
          {
            props.pending ?
              <Loader /> :
              props.mergeableStages.map(( stage: IMergeableStage ) => (
                <MergeableStageItemStyled
                  onClick={() => onStageClick(stage)}
                  key={stage.Id}
                  className={stage?.Id === selectedStage?.Id ? 'is-selected' : ''}
                >
                  <div className={'stage-number'}>{ stage.schemeCode }</div>
                  <i className={'fo-arrow-right'} />
                  <img className={'stage-thumbnail'} src={stage.Compound?.Thumbnail} alt=""/>
                </MergeableStageItemStyled>
              ))
          }
        </div>
        <footer className={'dialog-footer'}>
          <Button
            onAction={onCandidateSelect}
            text={'Выбрать'}
            size={'L'}
            view={'primary'}
            disabled={!selectedStage}
          />
          <Button
            onAction={onCancel}
            text={'Отменить'}
            size={'L'}
          />
        </footer>
      </DialogLayout>
    </Dialog>
  );
};

MergeableSchemesDialog = connect(
  (state): IMergeableSchemesDialogProps => ({
    stageId: state.modules.stage.editStage?.current?.Id,
    schemeId: state.modules.stage.editStage?.current?.ObtainmentSchemeId,
    selectedSubstance: getSelectedSubstanceSelector(state),
    pending: state.resource.schemes.pending || state.resource.stages.pending,
    mergeableSchemeIds: state.modules.stage.editStage.mergeableSchemesIds,
    mergeableStages: getMergeableStages(state),
  }),
  (dispatch): IMergeableSchemesDialogProps => ({
    loadMergeableSchemes: (id) => dispatch(schemesResourceActions.getMergeableSchemes.request({ uriParams: { id }})),
    setMergeOption: (option: string) => dispatch(stageModuleActions.setMergeOption({ option })),
    loadLastStagesByIds: (ids: number[]) => dispatch(stageModuleActions.loadLastStagesByIds({ ids })),
    clearLoadedStages: (id: number) => dispatch(stageResourceActions.clearAllNotCurrent({ id })),
    clearSchemes: () => dispatch(schemesResourceActions.destroy()),
    setSubstance: (payload: IMergeableStage, substancePosition: number) => dispatch(stageModuleActions.setSubstance({ payload, substancePosition })),
  }),
)(MergeableSchemesDialog);

export {
  MergeableSchemesDialog,
};
