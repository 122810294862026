// @flow

export const ReagentKeyNames = {
	Agent: 'Agent',
	AgentAvailability: 'AgentAvailability',
	AgentAvailabilityId: 'AgentAvailabilityId',
	Amount: 'Amount',
	Eq: 'Eq',
	FactualConsumption: 'FactualConsumption',
	Id: 'Id',
	IsDeleted: 'IsDeleted',
	IsRequired: 'IsRequired',
	Jars: 'Jars',
	Mass: 'Mass',
	ReactionId: 'ReactionId',
	ReagentType: 'ReagentType',
	Volume: 'Volume',
	IsRefCompound: 'IsRefCompound',
	MainAgentContent: 'MainAgentContent',
};
