import { BehaviorSubject }      from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import LocalSettings            from './LocalSettings';

var Ligth = 'light',
    Dark  = 'dark',

    BodyTheme = new BehaviorSubject(
        LocalSettings.getValue().theme || Ligth
    ),

    ChangeBodyThemeClass = theme => {
        document.body.className = Array
            .from   ( document.body.classList               )
            .filter ( classes => !~classes.indexOf('theme') )
            .concat ( [`theme-${ theme }`]                  )
            .join   ( ' ' );
    },

    OnThemeChanged = theme => {
        ChangeBodyThemeClass(theme);
        LocalSettings.mix({theme});
    };

BodyTheme
    .pipe( distinctUntilChanged() )
    .subscribe( OnThemeChanged );

BodyTheme.toggle = () =>
    BodyTheme.next(
        BodyTheme.getValue() === Ligth ? Dark : Ligth
    );


export default BodyTheme;