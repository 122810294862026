import {
    put,
    takeEvery,
    fork,
    select,
} from 'redux-saga/effects';
import {candidatesResourceActions, candidateModuleActions} from '../../projects/actions';
import {getToken} from '../../account/reducers';
import {CandidatesService} from '../../projects/services';

export function* getCandidatesList(data) {
    const token = yield select(getToken);
    const service = new CandidatesService(token);
    try {
        const result = yield service.getList(data);
        yield put(candidatesResourceActions.list.success(result));
    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(candidatesResourceActions.list.failure({error: e.response.data['ErrorMessage']}));
        }
    }
}

function* getCandidate(uriParams) {
    const token = yield select(getToken);
    const service = new CandidatesService(token);
    const { id } = uriParams;
    try {
        const result = yield service.get(id);
        yield put(candidatesResourceActions.get.success(result));
    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(candidatesResourceActions.get.failure({error: e.response.data['ErrorMessage']}));
        }
    }
}

export function* createCandidate(data) {
    const token = yield select(getToken);
    const service = new CandidatesService(token);
    try {
        const result = yield service.create(data);
        yield put(candidatesResourceActions.create.success(result));
    }
    catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data)
        {
            yield put(candidatesResourceActions.create.failure({error: e.response.data['ErrorMessage']}));
        }
        else if (e.response?.data?.errors?.title)
        {
            yield put(candidatesResourceActions.create.failure({ error: e.response?.data?.errors?.title }))
        }
        else {
            throw e;
        }
    }
}

export function* checkParentsTask(repkaTaskId) {
    const token = yield select(getToken);
    const service = new CandidatesService(token);
    try {
        const taskMaxId = 9223372036854775295;

        repkaTaskId = Number(repkaTaskId);
        if (!Number.isInteger(repkaTaskId) || repkaTaskId < 0 
                                           || repkaTaskId > taskMaxId) {
            yield put(candidatesResourceActions.checkRepkaTaskExists.failure({
                error: 'Некорректный идентификатор задачи'
            }));
            return;
        }

        const result = yield service.checkTask(repkaTaskId);

        if (!result.result) {
            yield put(candidatesResourceActions.checkRepkaTaskExists.failure({
                error: 'Такой задачи не существует'
            }));
        }
        yield put(candidatesResourceActions.checkRepkaTaskExists.success(result));
    } catch (e) {
        if (e.response && e.response.data && 'ExceptionMessage' in e.response.data) {
            yield put(candidatesResourceActions.checkRepkaTaskExists.failure({error: e.response.data['ExceptionMessage']}));
        }
    }
}

export function* updateCandidate(data) {
    const {code, projectId, folderId, repkaTaskLinks, compound, name, compoundId, id, currentFolderId, mnn, afs} = data;
    const token = yield select(getToken);
    const service = new CandidatesService(token);
    try {
        const {result} = yield service.update(
            {code, projectId, folderId, repkaTaskLinks, compound, name, compoundId, id, mnn, afs}
            );
        yield put(candidatesResourceActions.update.success(result));

        yield fork(getCandidatesList, {
            projectId: projectId,
            folderId: currentFolderId
        });
    } catch (e) {
        yield fork(getCandidatesList, {
            projectId: projectId,
            folderId: currentFolderId
        });

        if (e.response && e.response.data && 'ErrorMessage' in e.response.data)
        {
            yield put(candidatesResourceActions.update.failure({error: e.response.data['ErrorMessage']}));
        }
        else if (e.response?.data?.title)
        {
            yield put(candidatesResourceActions.update.failure({ error: e.response?.data?.title }))
        }
        else {
            throw e;
        }
    }
}

export function* deleteCandidate(params) {
    const token = yield select(getToken);
    const service = new CandidatesService(token);
    const {id, projectId, folderId} = params;
    try {
        const result = yield service.delete(id);

        if(!result.result.IsSuccess) {
            yield put(candidatesResourceActions.delete.failure({
                error: 'Невозможно удалить. Молекула содержит схемы получения.'
            }));
        }

        yield put(candidateModuleActions.removeCurrentCandidate());
        yield fork(getCandidatesList, {projectId, folderId});
    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(candidatesResourceActions.delete.failure({error: e.response.data['ErrorMessage']}));
        }
    }
}

export function* editCandidateComment(data) {
    const {candidateId, quickComment, projectId, folderId} = data;
    const token = yield select(getToken);
    const service = new CandidatesService(token);
    try {
        const result = yield service.editComment(candidateId, data);
        yield put(candidatesResourceActions.editCandidateComment.success(result));
        yield fork(getCandidatesList, {
            projectId: projectId,
            folderId: folderId
        });
    } catch (e) {
        yield fork(getCandidatesList, {
            projectId: projectId,
            folderId: folderId
        });
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(candidatesResourceActions.editCandidateComment.failure({error: e.response.data['ErrorMessage']}));
        }
    }
}

function* getFromAllFolders(data) {
    const token = yield select(getToken);
    const service = new CandidatesService(token);
    try {
        const result = yield service.getFromAllFolders(data.uriParams);
        yield put(candidatesResourceActions.getFromAllFolders.success(result));
    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(candidatesResourceActions.getFromAllFolders.failure({error: e.response.data['ErrorMessage']}));
        }
    }
}

export const candidatesWatchers = [
    function* watchCandidatesList() {
        yield takeEvery(candidatesResourceActions.list.request.type, function* (action) {
            const {params} = action;
            yield fork(getCandidatesList, params);
        });
    },

    function* watchGetCandidate () {
        yield takeEvery(candidatesResourceActions.get.request.type, function* (action) {
            const { uriParams } = action;
            yield fork(getCandidate, uriParams);
        });
    },

    function* checkParentsTaskWatcher() {
        yield takeEvery(candidatesResourceActions.checkRepkaTaskExists.request.type, function* (action) {
            const {params} = action;
            yield fork(checkParentsTask, params.repkaTaskId);
        });
    },

    function* watchCreateCandidate() {
        yield takeEvery(candidatesResourceActions.create.request.type, function* (action) {
            const {data} = action;
            yield fork(createCandidate, data);
        });
    },

    function* watchUpdateCandidate() {
        yield takeEvery(candidatesResourceActions.update.request.type, function* (action) {
            const {data} = action;
            yield fork(updateCandidate, data);
        });
    },

    function* watchDeleteCandidate() {
        yield takeEvery(candidatesResourceActions.delete.request.type, function* (action) {
            const {data} = action;
            yield fork(deleteCandidate, data);
        });
    },

    function* watchEditComment() {
        yield takeEvery(candidatesResourceActions.editCandidateComment.request.type, function* (action) {
            const {data} = action;
            yield fork(editCandidateComment, data);
        });
    },

    function* watchGetFromAllFolders() {
        yield takeEvery(candidatesResourceActions.getFromAllFolders.request.type, function* (action) {
            yield fork(getFromAllFolders, action);
        });
    },
];
