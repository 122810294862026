// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { StageEdit } from './StageEdit';

const Stage = ({ match }) => {
  return (
    <Switch>
      <Route
        path={`${match.path}/:id`}
        render={(props)=><StageEdit {...props} />}
      />
    </Switch>
  );
};

export default withRouter(connect(null, null)(Stage));
