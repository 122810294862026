// @flow
import { apiRequestSingle } from '../../shared/services/api/common';

export class TagsService {
    
    _getTagsList: () => {};

    constructor(token) {
        const headers = token ? {Authorization: token} : {};

        this._getTagsList = apiRequestSingle('GET', '/tags', headers);
    }

    getTagsList() {
        return this._getTagsList({}, {});
    }
}
