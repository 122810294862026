// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".list-m__table___gKAsv {\n  --grid-template: 200px 1fr 300px;\n}\n.list-m__reactionList___jOOMK {\n  display: inline-flex;\n  flex-flow: column nowrap;\n  justify-content: flex-end;\n}\n.list-m__moreLinkBtn___yxOfe {\n  display: inline-flex;\n  justify-content: flex-start;\n  padding-left: 4px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "list-m__table___gKAsv",
	"reactionList": "list-m__reactionList___jOOMK",
	"moreLinkBtn": "list-m__moreLinkBtn___yxOfe"
};
export default ___CSS_LOADER_EXPORT___;
