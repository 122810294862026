import React, { useState, useEffect } from 'react';
import bnc                     from 'bnc';
import { FontAwesomeIcon }     from '@fortawesome/react-fontawesome';
import { faSpinner }           from '@fortawesome/free-solid-svg-icons/faSpinner';
import                              './index.less';

const block = new bnc.default('b-loader');

const getTS = (date = new Date()) => date.getTime();

const TimeDiff = () => {
    const [{init, diff}, setDate] = useState({init: getTS(), diff: 0});
    const tick = () => setDate({init, diff: Math.round((getTS() - init) / 1000) });

    useEffect(
        () => {
            const timerID = setInterval( tick, 1000 );
            return () => clearInterval( timerID );
        }
    );

    return  (
        <div className={block.el('diff')}>
            {
                diff > 4 ?
                    diff > 100
                        ? `${Math.round(diff/60)} мин ${diff % 60} c`
                        : `${diff} с`
                    : ''
            }
        </div>
    );
};

const Loader = ({ text, size = 'block' }) => (
    <div className={
        block +
        (text ? block.mod('hastext')    : '') +
        (size ? block.mod('size', size) : '')
    }>
        <div className={block.el('icon')}>
            <FontAwesomeIcon icon={faSpinner} />
        </div>
        { text && <div className={block.el('label')}>{text}</div> }
        { (size === 'page') && <TimeDiff /> }
    </div>
);

export const LoaderWrapper = ({isLoading, text, size, children}) => (
    isLoading ? <Loader text={text} size={size}/> : children
);

export default Loader;

