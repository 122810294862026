// @flow

import { createSelector } from 'reselect';
import { resourceReducer } from '../../shared/utils/reducer-creators';
import { reagentsResourceActions } from '../actions';
import type { IRootStore } from '../../app/reducers';

export const reagentsResourceReducer = resourceReducer(reagentsResourceActions);

// Selectors
export const
  getReagents = createSelector(
    (state: IRootStore) => state.resource.reagents.data,
    (data) => Object.values(data)
  )
;
