import {getEnum} from "../../../../../shared/utils";

export const aftAimEnum = getEnum(
    {
        technologyDevelopment:1,
        dki:2,
        kiI:3,
        kiII:4,
        kiIII:5,
        commercialProduction:6
    }
);

export const aftAimTitlesEnum = getEnum(
    {
        'разработка технологии':1,
        'ДКИ':2,
        'КИ I':3,
        'КИ II':4,
        'КИ III':5,
        'коммерческое производство':6
    }
);
