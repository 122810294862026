/* eslint-disable quotes */
// @flow
import React, {
  useEffect,
  useState,
} from 'react';
import { withRouter } from 'react-router-dom';
import RootRouter from '../routes';
import MainHeader from './MainHeader';
import Screen from '@biocad/bcd-front-ui/layout/Screen';
import '../../assets/style/index.less';
import AlertMessage from '../../shared/components/AlertMessage';
import LoaderControl from '../../shared/components/LoaderControl';
import { connect } from 'react-redux';
import {getToken, isAuthorized} from '../../account/reducers';
import { setGlobalLoader } from '../../shared/actions/loading';
import { uuidv4 } from "../../shared/utils/common";
import { environment } from "../../environment";
import {accountActions} from "../../account/actions";
import {ErrorBoundary} from '../../shared/components/ErrorBoundary';
import {loadBimsDictionaries} from '../../dictionary/components/bimsLoaderHOC';
import { ToastContainer } from "react-toastify";
import {usersResourceActions} from '../../account/actions/users';

const RootRouterLinks = [
  { title: 'Справочник веществ', path: '/agents/list', },
  { title: 'Список проектов', path: '/projects/list', },
  { title: 'Кабинет химика', path: '/user-dashboard', },
  { title: 'Справочник реакций', path: '/reactions/catalog', },
  { title: 'Справочник оборудования', path: '/equipment', },
];

let App = (props) => {

  // States
  const [waitingForAccount, setWaitingForAccount] = useState(!props.isAuthorized);

  // Effects
  useEffect(() => {
    if (props.isAuthorized) {
      setWaitingForAccount(false);
    }
  }, [props.isAuthorized]);

  useEffect(() => {
     if(!environment.useLocalLogin) {
       props.setLoader(waitingForAccount);
     }
  }, [waitingForAccount]);

  useEffect(() => {
      if (environment.useLocalLogin && props.token && props.token !== ' null') {
        localStorage.setItem('localLoginToken', props.token);
      }
      if (props.token && props.token !== ' null')
      {
          props.getSelfUser();
      }
  }, [props.token]);

  useEffect(() => {
      if (environment.useLocalLogin) {
          const localLoginToken = localStorage.getItem('localLoginToken');
          if (localLoginToken) {
            props.setToken(localLoginToken);
            props.getAccount();
          }
      } else {
          localStorage.removeItem('localLoginToken');
      }
  }, []);

  useEffect(() => {
    if (props?.location?.pathname === '/login' || props?.location?.pathname === '/reload') return;
    if (props?.location?.pathname === '/') {
      props.history?.push('/user-dashboard/reactions');
      return;
    }
    if (props?.location?.pathname?.startsWith('/auth-no-match')) {
      return;
    }

    if (props?.location?.pathname?.length) {
      let lastVisitedPage = {};
      try {
        lastVisitedPage = JSON.parse(localStorage.getItem('lastVisitedPage')) || {};
      } catch (error) {
        lastVisitedPage = {};
      }
      if (!window.name) {
        window.name = uuidv4();
      }
      lastVisitedPage[window.name] = props.location;
      try {
        localStorage.setItem('lastVisitedPage', JSON.stringify(lastVisitedPage));
      } catch (error) {
        if (['QUOTA_EXCEEDED_ERR', 'NS_ERROR_DOM_QUOTA_REACHED', 'QuotaExceededError', 'W3CException_DOM_QUOTA_EXCEEDED_ERR'].includes(error.name)) {
          localStorage.removeItem('lastVisitedPage');
          localStorage.setItem('lastVisitedPage', JSON.stringify(lastVisitedPage));
        }
      }
    }
  }, [props?.location?.pathname]);

  return (
    <Screen>
      <ErrorBoundary>
          <MainHeader items={RootRouterLinks} />
          <Screen.Content>
              {
                  (!waitingForAccount || environment.useLocalLogin) ? <RootRouter /> : ''
              }
          </Screen.Content>
          <LoaderControl />
          <AlertMessage />
          <ToastContainer />
      </ErrorBoundary>
    </Screen>
  );
};

App = connect(
  (state) => ({
    isAuthorized: isAuthorized(state),
    token: getToken(state),
  }),
  (dispatch) => ({
    setLoader: (isLoading) => dispatch(setGlobalLoader({ isLoading })),
    setToken: (token) => dispatch(accountActions.setToken({ token })),
    getAccount: () => dispatch(accountActions.get.request()),
    getSelfUser: () => dispatch(usersResourceActions.getSelf.request()),
  })
)(App);

export default withRouter(App);
