// @flow
import { Model } from 'objectmodel';

export const ReactionAttachmentType = {
    MS: 1,
    NMR: 2,
    TLC: 3,
    HPLCMS: 4,
    Other: 5,
};

export interface IReactionAttachment {
    Id: number;
    ReactionAttachmentType: number;
    ReactionId: number;
    IsDeleted: boolean;
    AttachmentId: number;
    Attachment: { OriginalName: string },
}

export const ReactionAttachment: (attachment: IReactionAttachment) => IReactionAttachment = Model({
    Id: [Number],
    ReactionAttachmentType: [Number],
    ReactionId: [Number],
    IsDeleted: [Boolean],
    AttachmentId: [Number],
    Attachment: [{ OriginalName: [String] }],
});