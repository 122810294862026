// @flow

import { createSelector } from 'reselect';
import { reactionIndicatorsResourceActions } from '../actions';
import { resourceReducer } from '../../shared/utils/reducer-creators';
import type { IRootStore } from '../../app/reducers';

export const reactionIndicatorsResourceReducer = resourceReducer(reactionIndicatorsResourceActions, {
    //Внимание! Здесь list - это массив, а не объект
    [reactionIndicatorsResourceActions.list.success]: (state, action) => ({
      pending: action.pending,
      fail: action.fail,
      data: action.result,
      params: action.params,
      error: null,
    })
  });
export const
    getReactionIndicators = createSelector(
        (state: IRootStore) => state.resource.reactionIndicators.data,
        (data) => Object.values(data),
    )
;