// @flow

import {BasicModel, Model, ArrayModel} from 'objectmodel';

export const User = Model({
  userName: String,
  abbreviation: String,
  id: Number,
  laboratoryId: [Number, null, undefined],
  roles: [ArrayModel(String), null, undefined]
})
  .defaultTo({
    userName: '',
    abbreviation: '',
    id: 0
  });
