// @flow

import {stageIndicatorsModuleActions} from '../actions';
import {STAGE_INDICATORS_ARRAY_NAME} from '../components/StageIndicatorsList/StageIndicatorsForm';
import type {IStageIndicator} from '../models';
import {StageIndicator} from '../models';

const sortByOrderPriority = (a: IStageIndicator, b: IStageIndicator) =>
(
    a.IsDeleted
        ? -1
        : a.OrderPriority - b.OrderPriority
);

export const stageIndicatorsFormPluginReducer = (state, action) =>
{
    const reducerFn = {
        [stageIndicatorsModuleActions.sortByOrderPriority]: (action) =>
        {
            const stageIndicators: IStageIndicator[] = state.values && state.values[STAGE_INDICATORS_ARRAY_NAME];
            if (!Array.isArray(stageIndicators)) return state;
            return {
                ...state,
                value: {
                    ...state.value,
                    [STAGE_INDICATORS_ARRAY_NAME]: stageIndicators.sort(sortByOrderPriority)
                }
            }
        }
    }[action.type];
    return reducerFn ? reducerFn(action) : state;
}