// @flow
import { IReaction } from './i-reaction';

export interface IWorkProject {
  Candidates: any[];
  Id: Number;
  Leader: String;
  Name: String;
  Reactions: IReaction[];
  Status: Number;
  Type: Number;
}

export const ProjectReactionsKeysEnum = {
  Candidates: 'Candidates',
  Id: 'Id',
  Leader: 'Leader',
  Name: 'Name',
  Reactions: 'Reactions',
  Status: 'Status',
  Type: 'Type',
};

export const ProjectReactionsTitlesEnum = {
  Candidates: 'Целевые молекулы',
  Id: 'Id',
  Leader: 'Руководитель',
  Name: 'Наименование проекта',
  Reactions: 'Реакции',
  Status: 'Статус',
  Type: 'Тип',
};