// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".edit-m__template___Fv2Sj {\n  --template: 1fr 1fr 1fr;\n}\n.edit-m__form___HY7O0 {\n  padding-top: 8px;\n}\n.edit-m__testTemplate___K_fFy {\n  --template: 1fr 1fr 2fr 50px;\n  --table-border-color: none;\n  margin: 16px auto;\n  border-bottom: 1px solid var(--border-color);\n}\n.edit-m__headerRow___hgd3s {\n  align-items: end;\n  border-top: 1px solid var(--border-color) !important;\n}\n.edit-m__testsSection___B4AYC {\n  margin: 40px auto 20px;\n  min-height: 200px;\n}\n.edit-m__testsSection__body___HnwGY {\n  max-height: 300px;\n  overflow: auto;\n}\n.edit-m__borderBottom___RwU81 {\n  border-bottom: 1px solid var(--border-color);\n}\n.edit-m__formRow___J11m5 {\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr;\n  grid-column-gap: var(--grid-spacer);\n  grid-row-gap: var(--grid-spacer);\n  align-items: start;\n}\n.edit-m__formRow__reaction___ZxJsR {\n  grid-template-rows: 80px 80px 40px;\n}\n.edit-m__formRow__agent___MUygj {\n  grid-template-rows: 80px 80px;\n}\n.edit-m__formRow__comment___fQvCh {\n  grid-column-start: 1;\n  grid-column-end: 4;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"template": "edit-m__template___Fv2Sj",
	"form": "edit-m__form___HY7O0",
	"testTemplate": "edit-m__testTemplate___K_fFy",
	"headerRow": "edit-m__headerRow___hgd3s",
	"testsSection": "edit-m__testsSection___B4AYC",
	"testsSection__body": "edit-m__testsSection__body___HnwGY",
	"borderBottom": "edit-m__borderBottom___RwU81",
	"formRow": "edit-m__formRow___J11m5",
	"formRow__reaction": "edit-m__formRow__reaction___ZxJsR",
	"formRow__agent": "edit-m__formRow__agent___MUygj",
	"formRow__comment": "edit-m__formRow__comment___fQvCh"
};
export default ___CSS_LOADER_EXPORT___;
