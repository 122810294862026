import {
    apiRequestList, apiRequestSingle
} from '../../shared/services/api/common';

export class ProjectService {

    constructor(token) {
        const headers = token ? {Authorization: token} : {};

        this._getList = apiRequestList('GET', '/projects', headers);
        this._get = (projectId) => apiRequestSingle('GET', `/projects/${projectId}`, headers);
        this._getAll = apiRequestList('GET', '/projects/all', headers);
        this._create = apiRequestSingle('POST', '/projects', headers);
        this._update = apiRequestSingle('PUT', '/projects', headers);
        this._delete = (projectId) => apiRequestSingle('DELETE', `/projects/${projectId}`, headers);
        this._get = (projectId) => apiRequestList('GET', `/projects/${projectId}`, headers);
        this._getMaxOrder = apiRequestSingle('GET', '/projects/max_order', headers);
    }

    getList(params) {
        return this._getList({ params });
    }

    getAll(params) {
        return this._getAll({ params });
    }

    create(data) {
        return this._create({}, data);
    }

    update(id, folderId, leader, name, status, type, OrderPriority, isScaling) {
        return this._update({}, {id, folderId, leader, name, status, type, OrderPriority, isScaling});
    }

    delete(projectId, params) {
        return this._delete(projectId)(params);
    }

    getMaxOrder(params) {
        return this._getMaxOrder(params);
    }

    get(id) {
        return this._get(id)({});
    }

}
