import {
  put,
  takeLeading,
  fork,
  select,
} from 'redux-saga/effects';
import { LabsService } from '../services/labs';
import {getToken} from '../../account/reducers';
import { labsResourceActions } from '../actions';

export function* getLabsList(data: { id: number }) {
  const token = yield select(getToken);
  const service = new LabsService(token);

  try {
    const result = yield service.getLabsList(data);

    yield put(labsResourceActions.list.success(result));
  } catch (e) {
    if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
      yield put(labsResourceActions.list.failure({error: e.response.data['ErrorMessage']}));
    }
  }
}


export const labsWatchers = [

  function* watchGetLabsList() {
    yield takeLeading(labsResourceActions.list.request.type, function*(action) {
      const { data } = action;
      yield fork(getLabsList, data);
    });
  },

];
