import {connect} from "react-redux";
import React, {useRef} from "react";
import './index.less';

const ItemsSearchForm = ({onUpdate, itemsList}
  : {onUpdate: Array<string> => void, itemsList: Array<string>}) => {
  const inputRef = useRef(null);

  const deleteItem = (e: Event) => {
    let index = e.target.dataset.index;
    onUpdate(itemsList.filter((s, idx) => idx != index));
  };
  const addItem = (__: Event) => {
    let val = inputRef.current.value;
    if (val.length > 0 && itemsList.indexOf(val) === -1) {
      onUpdate([...itemsList, val]);
      inputRef.current.value = "";
    }
  };

  return (
    <div className={'search-items'}>
    <span>Код: &nbsp;</span>
      <div className={'search-items-list'}>
        <div className={'search-items-input'}>
          <input
            ref={inputRef}
            placeholder={'Введите код ЦМ'}/>
          <div onClick={addItem} className={'add-item-btn'}>
            <i className={'fo-plus'}/>
          </div>
        </div>
        {itemsList?.length > 0 &&
          <ul>
            {itemsList?.map((item, index) => {
              return (
                <li key={index}>
                  <div className={'item-title'}>
                    {item}
                  </div>
                  <i className={'fo-plus'} data-index={index} onClick={deleteItem}/>
                </li>
              );
            })}
          </ul>
        }
      </div>
    </div>
  );
};

export default connect(
  null,
  null
)(ItemsSearchForm);