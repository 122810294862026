// @flow

import React, {
	useState,
	useEffect
} from 'react';
import bnc from 'bnc';
import { _noop } from '../../utils/common';
import classNames from 'classnames';
import { uniqueId } from 'lodash';

import './index.less';


interface ITabListProps {
	children?: React$Element[],
	className?: string,
	onTabTitleClick?: (event: SyntheticEvent, idx: number)=>void,
	tabListClassName?: string,
	componentWrapperClassName?: string,
	selectedTab?: number
}

interface ITabProps {
	title: string | React$ComponentType,
	children?: React$ComponentType,
	isActive?: boolean,
	titleClassName?: string,
	componentClassName?: string,
}

const tabListDefaultProps: ITabListProps = {
	className: '',
	componentWrapperClassName: '',
	tabListClassName: '',
	onTabTitleClick: _noop,
	selectedTab: 0
};

const tabDefaultProps: ITabProps = {
	title: '',
	component: _noop,
	isActive: false,
	titleClassName: '',
	componentClassName: ''
};

// BNC
const
	BNCBlock = new bnc('b-TabList'),
	BNCTabTitle = BNCBlock.el('title'),
	BNCTabTitleActive = BNCTabTitle.mod('active'),
	BNCTabTitleList = BNCBlock.el('titleList'),
	BNCTabComponent = BNCBlock.el('component'),
	BNCTabComponentActive = BNCTabComponent.mod('active'),
	BNCTabComponentsWrapper = BNCBlock.el('componentsWrapper')
;

// Util functions
const
	filterTabComponents = (child: React$Element) => child.type === Tab
;

// Components

const Tab = (props: ITabProps = tabDefaultProps) => (
	<div
		className={classNames(BNCTabComponent.toString(), props.componentClassName, {
			[BNCTabComponentActive]: props.isActive
		})}>
		{
			props.children || <span />
		}
	</div>
);

const TabList = (props: ITabListProps = tabListDefaultProps) => {

	// state
	let tabs: React$Element[] = React.Children.toArray(props.children).filter(filterTabComponents);
	const
		[activeTabIdx, setActiveTabIdx] = useState<number>(props.selectedTab?props.selectedTab:0)
	;

	useEffect(() => {
		if (props.selectedTab!==undefined && props.selectedTab!==null) {
			setActiveTabIdx(props.selectedTab);
		}
	}, [props.selectedTab])

	// handlers
	const
		onTitleClick = (event: SyntheticEvent, idx: number) => {
			setActiveTabIdx(idx);
			if (props.onTabTitleClick && typeof props.onTabTitleClick === 'function' && props.tabListClassName !== _noop) {
				props.onTabTitleClick(event, idx);
			}
		}
	;

	return (
		<div className={classNames(BNCBlock.toString(), props.className)}>
			<div className={BNCTabTitleList + props.tabListClassName}>
				{
					tabs.map((tab: React$Element, idx: number) => (
						<div
							key={uniqueId(`${idx}`)}
							className={classNames(BNCTabTitle.toString(), tab.props.titleClassName ,{
								[BNCTabTitleActive]: idx === activeTabIdx,
							})}
							onClick={(event) => onTitleClick(event, idx)}
						>
							{
								tab.props.title && (typeof tab.props.title === 'function')
									? tab.props.title()
									: <span>{ tab.props.title }</span>
							}
						</div>
					))
				}
			</div>
			<div className={BNCTabComponentsWrapper}>
				{
					tabs.map((tab: React$Element, idx: number) => (
						<Tab
							key={idx}
							title={tab.props.title}
							isActive={idx === activeTabIdx}
							componentClassName={tab.props.componentClassName}
						>
							{ tab.props.children }
						</Tab>
					))
				}
			</div>
		</div>
	)
};

export {
	TabList,
	Tab
}
