// @flow

import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import {ReactionKeyNames} from '../../../../models';
import {BcdInputWrapper} from '../../../../../shared/components/BcdInputWrapper';
import {DatePickerInput} from '../../../../../shared/components/DatePickerInput';
import {Dispatch} from 'redux';
import {_noop} from '../../../../../shared/utils/common';
import {labsResourceActions} from '../../../../../dictionary/actions';
import type {IRootStore} from '../../../../../app/reducers';
import {BcdDropdownWrapper} from '../../../../../shared/components/BcdDropdownWrapper';
import {itemsToArraySelector} from '../../../../../shared/utils/selectors/resource-selectors';
import {FieldValidators} from '../../../../../shared/validators/field-validators';

interface IArchivedReactionInfo {
  getLaboratories?: Function,
  labs?: Object[]
}

const initialProps = {
  getLaboratories: _noop,
  labs: []
};

let ArchivedReactionInfo = (props: IArchivedReactionInfo = initialProps) => {

  //States
  const [labs, setLabs] = useState([]);

  // Effects
  useEffect(() => {
    props.getLaboratories();
  }, []);

  useEffect(() => {
    if(props.labs){
      let tempArr = [];
      let labsList = itemsToArraySelector(props.labs);

      labsList.map(item => {
        tempArr.push(item.Name)
      });

      setLabs(tempArr);
    }
  }, [props.labs]);

  return (
    <div className={'form-group archive-info'}>

      <h4>Информация об архивной реакции</h4>
      <Field
        name={ReactionKeyNames.Name}
        label={'Наименование реакции'}
        large={true}
        useFormFieldLayout={true}
        component={BcdInputWrapper}
        validate={[FieldValidators.required]}
        markPristineValidity
      />

      <div className={'form-group'}>
        <Field
          name={ReactionKeyNames.AdditionalName}
          component={BcdInputWrapper}
          label={'Дополнительное наименование'}
          large
          useFormFieldLayout
        />
      </div>

      <div className={'fields-row'}>
        <Field
          name={ReactionKeyNames.AuthorUserName}
          label={'ФИО автора'}
          useFormFieldLayout={true}
          component={BcdInputWrapper}
        />

        <Field
          name={ReactionKeyNames.ArchiveProjectName}
          label={'Проект'}
          useFormFieldLayout={true}
          component={BcdInputWrapper}
        />
      </div>

      <div className={'fields-row'}>
        <label>
          Дата начала
          <Field
            name={ReactionKeyNames.StartDate}
            component={DatePickerInput}
            useFormFieldLayout={true}
          />
        </label>

        <label>
          Дата окончания
          <Field
            name={ReactionKeyNames.EndDate}
            component={DatePickerInput}
            useFormFieldLayout={true}
          />
        </label>
      </div>

      <div className={'fields-row large'}>
        <Field
          name={ReactionKeyNames.LaboratoryId}
          large={true}
          useFormFieldLayout={true}
          label={'Лаборатория'}
          component={BcdDropdownWrapper}
          options={labs}
          placeholder={'Выберите лабораторию'}
        />
      </div>

    </div>
  );
};

ArchivedReactionInfo = connect(
  (state: IRootStore) => ({
    labs: state.resource.dictionary.labs
  }),
  (dispatch: Dispatch) => ({
    getLaboratories: () => dispatch(labsResourceActions.list.request({}))
  })
)(ArchivedReactionInfo);

export {
  ArchivedReactionInfo
};
