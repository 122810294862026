// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".events-m__valuesList___PvqEN {\n  display: flex;\n  flex-flow: column nowrap;\n  font-size: 12px;\n}\n.events-m__moreInfo___Hpg5W {\n  display: inline-block;\n  width: 20px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"valuesList": "events-m__valuesList___PvqEN",
	"moreInfo": "events-m__moreInfo___Hpg5W"
};
export default ___CSS_LOADER_EXPORT___;
