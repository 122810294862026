// @flow
import { Model } from 'objectmodel';

export const RepkaTaskLink = Model({
  FinalCandidateId: [Number],
  Id: Number,
  IsDeleted: Boolean,
  ObtainmentSchemeId: Number,
  RepkaTaskId: Number,
});
