// @flow
import { Model } from 'objectmodel';

export const ReactionYield = Model({
  Id: [Number, null, undefined],
  Comment: [String, null, undefined],
  ExperimentalPercentYield: [Number, null, undefined],
  ExperimentalYield: [Number, null, undefined],
  IsDeleted: Boolean,
  ReactionId: Number,
  TheoreticalYield: [Number, null, undefined],
  IndexNumber: Number,
  ShouldCreateJar: Boolean,
}).defaultTo({
  IsDeleted: false,
  ShouldCreateJar: true,
  ExperimentalYield: 0,
  ExperimentalPercentYield: 0,
  TheoreticalYield: 0,
});
