// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form-m__filter___OHVH5 {\n  --FormFieldLayout-margin-b: 0px;\n  --FormFieldLayout-margin-r: 12px;\n  padding-left: var(--LayoutBlock__header-padding-h);\n  padding-right: var(--LayoutBlock__header-padding-h);\n  padding-top: var(--LayoutBlock-padding-v);\n  padding-bottom: calc(var(--LayoutBlock-padding-v) / 2);\n  border-top: 1px solid var(--border-color);\n}\n.form-m__filter__form___nYgFb {\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: flex-end;\n  align-items: center;\n}\n.form-m__filter__form__field___vZPfh {\n  height: 66px;\n  display: flex;\n  justify-content: center;\n  flex: 1;\n}\n.form-m__filter__form__field___vZPfh:not(:first-child) {\n  margin-left: var(--FormFieldLayout-margin-r);\n}\n.form-m__filter__form__checkbox___bEQIl {\n  flex: 2;\n  margin-left: var(--FormFieldLayout-margin-r);\n}\n.form-m__filter__form__buttonWrapper___UvWNE {\n  flex: 2;\n  display: flex;\n  justify-content: flex-end;\n}\n.form-m__filter__form__button___vCgDC:not(:last-child) {\n  margin-right: var(--FormFieldLayout-margin-r);\n}\n.form-m__filter__form__dash___dYxV8 {\n  margin-left: var(--FormFieldLayout-margin-r);\n}\n.form-m__filter__form__expander___S0HJt {\n  flex: 2;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter": "form-m__filter___OHVH5",
	"filter__form": "form-m__filter__form___nYgFb",
	"filter__form__field": "form-m__filter__form__field___vZPfh",
	"filter__form__checkbox": "form-m__filter__form__checkbox___bEQIl",
	"filter__form__buttonWrapper": "form-m__filter__form__buttonWrapper___UvWNE",
	"filter__form__button": "form-m__filter__form__button___vCgDC",
	"filter__form__dash": "form-m__filter__form__dash___dYxV8",
	"filter__form__expander": "form-m__filter__form__expander___S0HJt"
};
export default ___CSS_LOADER_EXPORT___;
