import {
    apiRequestList, apiRequestSingle
} from '../../shared/services/api/common';

export class CandidatesService {

    constructor(token) {
        const headers = token ? {Authorization: token} : {};

        this._getList = apiRequestList('GET', '/final_candidates', headers);
        this._get = (id) => apiRequestSingle('GET', `/final_candidates/${id}`, headers);
        this._create = apiRequestSingle('POST', '/final_candidates', headers);
        this._update = apiRequestSingle('PUT', '/final_candidates', headers);
        this._delete = (candidateId) => apiRequestSingle('DELETE', `/final_candidates/${candidateId}`, headers);
        this._checkTask = (repkaTaskId) => apiRequestSingle('GET', `/repka/${repkaTaskId}`, headers);
        this._editComment = (candidateId) => apiRequestList('PUT', `/final_candidates/${candidateId}/quick_comment`, headers);
        this._getFromAllFolders = apiRequestList('GET', '/final_candidates/all_folders', headers);
    }

    getFromAllFolders(params) {
        return this._getFromAllFolders({params});
    }

    getList(params) {
        return this._getList({params});
    }

    get(id) {
        return this._get(id)();
    }

    create(data) {
        return this._create({}, data);
    }

    update(data) {
        return this._update({}, data);
    }

    delete(candidateId, params) {
        return this._delete(candidateId)(params);
    }

    checkTask(repkaTaskId) {
        return this._checkTask(repkaTaskId)();
    }

    editComment(candidateId, data) {
        return this._editComment(candidateId)({data});
    }

}
