// @flow

import {getEnum} from '../shared/utils';
import type {IJarStock} from './models';
import type {IReagentJar} from '../reagents/model';

const FRACTION_DIGITS = 5;

const _enum = {
    Full    : 1,
    Medium  : 2,
    Low     : 3,
    Empty   : 4,
};

const parseFractionToFloat = (a: number = 1, b: number) =>
{
    if (typeof a !== 'number' || typeof b !== 'number') return NaN;
    return Number.parseFloat((a/b).toFixed(FRACTION_DIGITS));
}

export const AmountRemainsIndicatorEnum: typeof _enum = getEnum(_enum);

export type TAmountRemainsIndicator =
{
    value?: typeof AmountRemainsIndicatorEnum,
    limits?: { min: number, max: number },
    className: string,
    searchValue?: string,
}

export const amountColorIndicators: { [typeof AmountRemainsIndicatorEnum]: TAmountRemainsIndicator } =
{
    [AmountRemainsIndicatorEnum.Empty]:
    {
        value: AmountRemainsIndicatorEnum.Empty,
        limits: { min: 0, max: parseFractionToFloat(1, 20) },
        className: 'jar-amount-remains--empty',
    },
    [AmountRemainsIndicatorEnum.Low]:
    {
        value: AmountRemainsIndicatorEnum.Low,
        limits: { min: parseFractionToFloat(1, 20), max: parseFractionToFloat(1, 3) },
        className: 'jar-amount-remains--low',
        searchValue: 'isSmallAmount',
    },
    [AmountRemainsIndicatorEnum.Medium]:
    {
        value: AmountRemainsIndicatorEnum.Medium,
        limits: { min: parseFractionToFloat(1, 3), max: parseFractionToFloat(2, 3) },
        className: 'jar-amount-remains--medium',
        searchValue: 'isSufficientAmount',
    },
    [AmountRemainsIndicatorEnum.Full]:
    {
        value: AmountRemainsIndicatorEnum.Full,
        limits: { min: parseFractionToFloat(2, 3), max: 1 },
        className: 'jar-amount-remains--full',
        searchValue: 'isLargeAmount',
    }
};

export const getAmountRemainsIndicator = (jar: IJarStock): TAmountRemainsIndicator =>
{
    if (jar.IsIgnoringSpending)
    {
        return {
            className: 'jar-amount-remains--ignore-spending'
        };
    }
    if (jar.Amount === null || jar.PackageWeight === null || jar.PackageWeight <= 0)
    {
        return;
    }
    if (jar.Amount >= jar.PackageWeight)
    {
        return amountColorIndicators[AmountRemainsIndicatorEnum.Full];
    }
    if (jar.Amount <= 0)
    {
        return amountColorIndicators[AmountRemainsIndicatorEnum.Empty];
    }
    const remainsFraction = parseFractionToFloat(jar.Amount, jar.PackageWeight);
    return Object.values(amountColorIndicators).find(i => remainsFraction >= i.limits.min && remainsFraction <= i.limits.max);
};