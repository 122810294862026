// @flow
import Input from '@biocad/bcd-front-ui/controls/Input';
import React, {useState, useEffect} from 'react';
import {
    FormFieldLayout
} from '../FormFieldLayout';
import { parseInputValueToNumberOnBlur, parseInputValueToNumberOnChange } from '../../utils/common';

type BcdInputWrapperProps = {
    placeholder?        : string,
    disabled?           : boolean,
    autoComplete?       : boolean,
    className?          : string,
    icon?               : any,
    type?               : string,
    step?               : number,
    min?                : number,
    max?                : number,
    label?              : string,
    wrapperStyle?       : {},
    wrapperClassName?   : string,
    hint?               : string,
    meta?               : any,
    input?              : any,
    useFormFieldLayout  : boolean,
    markPristineValidity?: boolean,
}

// Wrapper for use BCD Input component with Redux Forms - passing field.input object,
// that contains handlers is necessary.
export const BcdInputWrapper = ({
    placeholder, disabled, autoComplete, className,
    icon, type, step, min, max, label, wrapperStyle, wrapperClassName,
    hint, meta, input, useFormFieldLayout = false,
    required, markPristineValidity = false
  }: BcdInputWrapperProps) => {
    const { touched, error, valid, warning } = meta;
    let _valid: boolean;
    if (!markPristineValidity) {
      _valid = (meta !== undefined && (valid === false || !!warning)) && touched === true ? false : undefined;
    }
    else {
      _valid = (valid && !warning) ? undefined : false
    }

    const bcdInputProps = {
        valid: _valid,
        placeholder,
        disabled,
        autoComplete,
        className,
        icon,
        step,
        min,
        max,
        type,
    };

    const formatNumber = {
      onChange: (event: Event) => {
        type==='number' ? parseInputValueToNumberOnChange(event, input.onChange) : input.onChange(event);
      },
      onBlur: (event) => {
        type==='number' ? parseInputValueToNumberOnBlur(event, input.onChange) : input.onBlur(event);
      },
    };

    const formFieldLayoutProps = {
        style: wrapperStyle,
        className: wrapperClassName,
        invalidMessage: error || warning,
        valid: _valid,
        hint,
        label,
        required,
    };

    const renderBcdInput = () => (
      <React.Fragment>
          {
              icon ? (
                <Input.Icon
                  {...bcdInputProps}
                  {...input}
                  {...formatNumber}
                  type={type==='number' ? 'text' : type}
                />
              ) : (
                <Input
                  {...bcdInputProps}
                  {...input}
                  {...formatNumber}
                  type={type==='number' ? 'text' : type}
                />
              )
          }
      </React.Fragment>
    );
    return useFormFieldLayout
      ? (<FormFieldLayout {...formFieldLayoutProps}>{renderBcdInput()}</FormFieldLayout>)
      : renderBcdInput();
};
