import { resourceReducer, moduleAction } from '../../shared/utils';
import { testStatusesResourceActions, smTypesResourceActions, testClassesResourceActions } from '../actions';
import { createSelector } from 'reselect';
import type {IRootStore} from '../../app/reducers';

export const testStatusesResourceReducer = resourceReducer(testStatusesResourceActions);

export const smTypesResourceReducer = resourceReducer(smTypesResourceActions);

export const testClassesResourceReducer = resourceReducer(testClassesResourceActions);

export const bimsDictionaryModuleActions = {
    setSmTypesRequested: moduleAction(
        'BIMS_DICTIONARY_MODULE',
        ['SM_TYPES', 'SET_REQUESTED'],
        () => ({})
    ),
    setTestClassesRequested: moduleAction(
        'BIMS_DICTIONARY_MODULE',
        ['TEST_CLASSES', 'SET_REQUESTED'],
        () => ({})
    ),
    setTestStatusesRequested: moduleAction(
        'BIMS_DICTIONARY_MODULE',
        ['TEST_STATUSES', 'SET_REQUESTED'],
        () => ({})
    ),
};

export type TBimsDictionaryModuleStore = {
    smTypesRequested: boolean,
    testClassesRequested: boolean,
    testStatusesRequested: boolean,
}

const defaultStore: TBimsDictionaryModuleStore = {
    smTypesRequested: false,
    testClassesRequested: false,
    testStatusesRequested: false,
}

export const bimsDictionaryModuleReducer = (state: TBimsDictionaryModuleStore = defaultStore, action: any): TBimsDictionaryModuleStore =>
{
    const reducedFn = {
        [bimsDictionaryModuleActions.setSmTypesRequested]: (state, action) => ({
            ...state,
            smTypesRequested: true
        }),
        [bimsDictionaryModuleActions.setTestClassesRequested]: (state, action) => ({
            ...state,
            testClassesRequested: true
        }),
        [bimsDictionaryModuleActions.setTestStatusesRequested]: (state, action) => ({
            ...state,
            testStatusesRequested: true
        }),
    }[action.type];
    return reducedFn ? reducedFn(state, action) : state;
}

export const
    smTypesSelector = createSelector(
        (store: IRootStore) => store.resource.dictionary.smTypes.data,
        (data) => Object.values(data)
    ),
    testClassesSelector = createSelector(
        (store: IRootStore) => store.resource.dictionary.testClasses.data,
        (data) => Object.values(data)
    ),
    testStatusesSelector = createSelector(
        (store: IRootStore) => store.resource.dictionary.testStatuses.data,
        (data) => Object.values(data)
    )
;