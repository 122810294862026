// @flow
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import { LayoutBlock } from '../../../shared/components/LayoutBlock';

export const layoutBlockStyles = {
    minHeight: '92%',
};

export const LayoutBlockStyled = styled(LayoutBlock)`
  min-height: 92%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
`;

export const PageHeaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PageHeaderWrapExpander = styled.div`
  flex-grow: 1;
`;

export const ButtonsContainer = styled.div`
  Button:first-child:not(:last-child) {
    margin-right: 12px;
  }
`;

export const StyledLink = styled(Link)`
  color: var(--grey-400);
`;

export const ProjectsTableStyled = styled.table`
  margin-bottom: var(--grid-spacer);
  tbody tr:hover {
      background-color: var(--hover-bg-color);
  }
  tr {
    cursor: pointer;
    transition: 0.1s background-color linear;
    height: 40px;
  }
  td, th {
    padding: 0 16px;
    height: 40px;
  }
  td {
      font-size: 14px;
      color: var(--black);
  }
  ${StyledLink},
  i{
    color: var(--grey-400);
  }
  i.fo-innovative-project {
    color: var(--orange);
  }
  i.fo-generic-project {
    color: var(--green);
  }
  i.fo-scaling-project {
    color: var(--red);
  }
  .fo-folder:before,
  .fo-scaling-project:before,
  .fo-innovative-project:before,
  .fo-generic-project:before{
    margin-right: .5em;
    font-size: 135%;
    position: relative;
    top: 1px;
  }
  .fo-edit:hover,
  .fo-trash:hover{
    color: #54575E;
  }
  .fo-edit:before {
    font-size: 92%;
  }
  .button-holder {
    display: flex;
    align-items: center;
  }
  .cell-with-only-icon {
    pointer-events: none;
    width: 68px;
    i {
      pointer-events: auto;
    }
  }
`;
