// @flow

import styled from 'styled-components';

export const CatalogReactionListStyled = styled.div`
  .title-col {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: inherit;
    &:hover {
      text-decoration: underline;
      color: var(--link-color);
    }
    .typeIcon {
      margin-right: calc(var(--grid-spacer) / 2);
    }
    .additional-name {
      font-size: smaller;
    }
  }
  
  .reagent-thumbnail-holder {
    display: flex;
  }
  
  .reagent-thumbnail {
    display: block;
    width: 90px; height: 90px;
    &:not(:last-of-type) {
      margin-right: 4px;
    }  
  }
`;

export const ListHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LoaderWrap = styled.div`
  & .b-loader {
    position:absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
  }
`;
