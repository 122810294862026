// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uploader-m__main_wrap___opD_K {\n  display: contents;\n}\n.uploader-m__popup___S2Xdz {\n  width: 360px;\n}\n.uploader-m__inputBtn___OF510 {\n  display: none;\n}\n.uploader-m__list___fiKNg {\n  max-height: 200px;\n  overflow-y: scroll;\n  padding-left: 0;\n  margin: 0;\n}\n.uploader-m__list__row___TvxHf {\n  display: inline-flex;\n  justify-content: flex-start;\n  align-items: center;\n  margin-bottom: var(--minor-margin);\n  margin-right: var(--minor-margin);\n}\n.uploader-m__list__row__name___olFrG {\n  font-size: 14px;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n.uploader-m__list__row__expander___M7LoD {\n  flex-grow: 1;\n}\n.uploader-m__trashIcon___n0R2a {\n  color: var(--grey-400);\n  margin-right: 2px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main_wrap": "uploader-m__main_wrap___opD_K",
	"popup": "uploader-m__popup___S2Xdz",
	"inputBtn": "uploader-m__inputBtn___OF510",
	"list": "uploader-m__list___fiKNg",
	"list__row": "uploader-m__list__row___TvxHf",
	"list__row__name": "uploader-m__list__row__name___olFrG",
	"list__row__expander": "uploader-m__list__row__expander___M7LoD",
	"trashIcon": "uploader-m__trashIcon___n0R2a"
};
export default ___CSS_LOADER_EXPORT___;
