// @flow
import { Model } from 'objectmodel';
import { IndicatorTypeEnum } from '../../indicators/models'
import {formInputValueType} from '../../shared/utils/common';
import type {IStageIndicator} from '../../stage-indicators/models';
import {StageIndicator} from '../../stage-indicators/models';

export interface IReactionIndicatorParameter
{
    Id: Number;
    ReactionId: number;
    StageIndicatorId?: number;
    StageIndicator?: IStageIndicator;
    Comment?: string;
    Value?: number;
    TextValue?: string;
    MinRangeValue?: number;
    MaxRangeValue?: number;
    BooleanValue?: boolean;
    IsDeleted: boolean;
}

export const ReactionIndicatorParameterFieldNamesMap = {
    Id: 'Id',
    ReactionId: 'ReactionId',
    StageIndicatorId: 'StageIndicatorId',
    StageIndicator: 'StageIndicator',
    Comment: 'Comment',
    Value: 'Value',
    TextValue: 'TextValue',
    MinRangeValue: 'MinRangeValue',
    MaxRangeValue: 'MaxRangeValue',
    BooleanValue: 'BooleanValue',
    IsDeleted: 'IsDeleted',
};

export const ReactionIndicatorParameter = Model({   
    [ReactionIndicatorParameterFieldNamesMap.Id]                : [Number],
    [ReactionIndicatorParameterFieldNamesMap.ReactionId]        : Number,
    [ReactionIndicatorParameterFieldNamesMap.StageIndicatorId]  : [Number],
    [ReactionIndicatorParameterFieldNamesMap.StageIndicator]    : [StageIndicator],
    [ReactionIndicatorParameterFieldNamesMap.Comment]           : formInputValueType(String),
    [ReactionIndicatorParameterFieldNamesMap.Value]             : formInputValueType(Number),
    [ReactionIndicatorParameterFieldNamesMap.TextValue]         : formInputValueType(String),
    [ReactionIndicatorParameterFieldNamesMap.MinRangeValue]     : formInputValueType(Number),
    [ReactionIndicatorParameterFieldNamesMap.MaxRangeValue]     : formInputValueType(Number),
    [ReactionIndicatorParameterFieldNamesMap.BooleanValue]      : formInputValueType(Boolean),
    [ReactionIndicatorParameterFieldNamesMap.IsDeleted]         : [Boolean]
})
.defaultTo({
    IsDeleted: false
});

export const areReactionIndicatorParametersFilled = (params) => {
    const indType = (p) => p?.StageIndicator?.Indicator?.IndicatorType;
    if (!params || !Array.isArray(params) || !Array.prototype.every)
        return false;

    return params.every((p) => ({
        [IndicatorTypeEnum.Number]      : typeof p.Value === 'number',
        [IndicatorTypeEnum.Boolean]     : typeof p.BooleanValue === 'boolean',
        [IndicatorTypeEnum.Text]        : !!p.TextValue,
        [IndicatorTypeEnum.NumericRange]: !![p.MinRangeValue, p.MaxRangeValue].every((val) => typeof val === 'number')
    }[indType(p)]) || false);
};