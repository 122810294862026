import './index.less';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Dialog, { DialogLayout } from '@biocad/bcd-front-ui/layout/Dialog';
import Button from '@biocad/bcd-front-ui/controls/Button';
import { schemesResourceActions, schemeModuleActions } from '../../actions';
import {SchemeTree} from '../SchemeTree';
import { schemeSelector } from '../../reducers';
import { comparingSchemesSelector, selectedStageIdSelector } from '../../reducers/selectors';
import {
  PageLayout,
  PageLayoutSlot,
  PageLayoutHeader,
  PageLayoutBody,
  pageLayoutAreas,
} from '../../../shared/components/PageLayout';
import { SchemeCompareSelectDialog } from '../SchemeCompareSelectDialog';
import { setGlobalLoader } from '../../../shared/actions/loading';


interface ISchemeCompareProps {
  id: Number;
  selectedComparingIds: [],
  getComparingSchemeViews: (schemeIds: []) => null,
  getScheme: (schemeId: number) => null,
  setCurrentSchemeId: (schemeId: number) => null,
  scheme: {},
  history: {},
  setLoader: (isLoading: boolean) => null,
}

let SchemeCompare = (props : ISchemeCompareProps) => {
  const id = Number(props.match.params.id);
  const [isCompareSelectModalShown, setIsCompareSelectModalShown] = useState(false);
  const [collapseOptions, setCollapseOptions] = useState({});

  function init() {
    props.getScheme(id);
    props.setCurrentSchemeId(id);
  }
  useEffect(init, [id]);

  function openSelectModal() {
    if(!props.scheme) {
      return;
    }

    setIsCompareSelectModalShown(true);
  }
  useEffect(openSelectModal, [props.scheme]);

  function loadSchemes() {
    props.getComparingSchemeViews([id, ...props.selectedComparingIds]);
  }
  useEffect(loadSchemes, [props.selectedComparingIds]);

  function loaderHandler() {
    props.setLoader(props.pending);
  }
  useEffect(loaderHandler, [props.pending]);

  function toggleCompareSelectModalShown() {
    setIsCompareSelectModalShown(!isCompareSelectModalShown);
  }

  function goBack() {
    props.history.push(`/schemes/${id}`);
  }

  function toggleScheme(schemeId) {
    collapseOptions[schemeId] = !collapseOptions[schemeId];
    setCollapseOptions({...collapseOptions});
  }

  function selectStage(stageId: number) {
    props.history.push(`/stages/${stageId}`);
  }

  return(
    <>
      <div className={'scheme-compare-container'}>
        {props.viewSchemes && props.viewSchemes.map((sch, idx) => (
          <div key={sch.SchemeId}>
            <div className={'scheme-header'}>
              {idx === 0 &&
              <Button
                className={'fo-folder-back'}
                onAction={goBack}
                view={'icon'}
                size={'L'}
              />}
              {idx > 0 &&
              <Button
                className={`${collapseOptions[sch.SchemeId] ? 'fo-down-open' : 'fo-up-open'}`}
                view={'icon'}
                onAction={() => toggleScheme(sch.SchemeId)}
                size={'L'}
              />}
              <h2>{sch.Code}</h2>
              {idx === 0 &&
              <Button
                className={'link-btn fo-compare'}
                view={'icon'}
                size={'L'}
                onAction={toggleCompareSelectModalShown}
              />}
            </div>
            <div className={'scheme-tree'} style={{'display': collapseOptions[sch.SchemeId] ? 'none' : 'block'}}>
              <SchemeTree
                schemeView={sch}
                onSelectStage={selectStage} />
            </div>
          </div>
        ))}
      </div>
      
      {isCompareSelectModalShown &&
          <SchemeCompareSelectDialog 
            scheme={props.scheme}
            onClose={toggleCompareSelectModalShown}
            onSelect={toggleCompareSelectModalShown}
          />
      }
    </>
  );
};

SchemeCompare = withRouter(connect(
  (state) => ({
    viewSchemes: state?.modules?.scheme?.compareScheme?.comparingSchemeViews,
    selectedComparingIds: state?.modules?.scheme?.compareScheme?.comparingSchemeIds,
    pending: state?.resource?.schemes?.pending,
    scheme: schemeSelector(state),
  }),
  (dispatch) => ({
    getScheme: (id: number) => dispatch(schemesResourceActions.get.request({ uriParams: { id } })),
    getComparingSchemeViews: (schemeIds) => dispatch(schemesResourceActions.getComparingSchemeViews.request({ params: { schemeIds }})),
    setCurrentSchemeId: (schemeId) => dispatch(schemeModuleActions.setCurrentSchemeId({ schemeId })),
    setLoader: (isLoading) => dispatch(setGlobalLoader({ isLoading })),
  })
)(SchemeCompare));

export {
  SchemeCompare
};