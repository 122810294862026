// @flow

import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {StyledLayoutHeader} from '../../../reactions/components/ReactionCatalog/style';
import {LayoutBlock} from '../../../shared/components/LayoutBlock';
import SearchForm, {USERS_SEARCH_FORM_NAME} from './SearchForm';
import styled from 'styled-components';
import Button from '@biocad/bcd-front-ui/controls/Button';
import {usersResourceActions} from '../../actions/users';
import {_noop} from '../../../shared/utils/common';
import {RolesMap, RolesMapTranslate} from '../../models/roles-map';
import {itemsToArraySelector} from '../../../shared/utils/selectors/resource-selectors';
import {labsResourceActions} from '../../../dictionary/actions';
import Loader from '@biocad/bcd-front-ui/controls/Loader';
import classNames from 'classnames';
import EditUser from './EditUser';
import {getFormValues} from 'redux-form';
import {PaginationStyled} from '../../../shared/components/Paginator/style';

const StyledLayoutBlock = styled(LayoutBlock)`
  padding: 0 !important;
`;

const StyledUsersList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    height: 54px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--table-border-color);
    transition: 100ms linear;
    overflow: hidden;
    &.opened {
      height: 96px;
    }
    div {
      padding: 0 16px;
      &.user-name {
        flex: 6;
      }
      &.user-login {
        flex: 3;
      }
      &.user-lab {
        flex: 4;
      }
    }
    button {
      flex: 1;
      margin-right: 8px;
    } 
    ul {
      flex: 24;
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      li {
        justify-content: center;
        margin: 0 8px;
        text-align: center;
        border-bottom: none;
        color: #fff;
        background-color: var(--green);
        padding: 8px;
        max-height: 35px;
        text-overflow: ellipsis;
        border-radius: calc(var(--border-radius) * 2);
        height: auto;
        width: auto;
        word-wrap: normal;
      }
    }
  }
`;

interface IUserRolesProps {
  account: Object,
  users: Object[],
  getUsersList: Function,
  getLabs: Function,
  labs: Object[],
  history?: {
    push?: typeof Function,
    goBack?: typeof Function
  },
  loading: boolean,
  searchFormValues: Object,
  listPagination: Object,
  clearUsersList: Function,
  getUser: Function
}

const initialProps = {
  account: null,
  users: null,
  getUsersList: _noop,
  getLabs: _noop,
  labs: null,
  history: null,
  loading: true,
  searchFormValues: null,
  listPagination: null,
  clearUsersList: _noop,
  getUser: _noop
};

const UserRoles = (props: IUserRolesProps = initialProps) => {
  //States
  const [openedLi, setOpenedLi] = useState(null);

  //Effects
  useEffect(() => {
    if (props.account.roles.indexOf(RolesMap.SuperUser) !== -1) {
      props.getLabs();
      props.getUsersList();
    } else {
      props.history.goBack();
    }
  }, []);

  //Methods
  const handleSearchFormSubmit = (event: any) => {
    event.preventDefault();
    props.clearUsersList();
    props.getUser(props.searchFormValues.search);
  };

  const editUser = (index) => {
    setOpenedLi(index);
  };

  const cancelEdit = () => {
    setOpenedLi(null);
  };

  const handlePaginationClick = listParams => {
    props.clearUsersList();
    props.getUsersList({
      ...listParams,
      ...props.searchFormValues
    });
  };

  return (
    <>
      <StyledLayoutHeader>
        <h2>Роли пользователей</h2>

        <SearchForm onSubmit={handleSearchFormSubmit}/>
      </StyledLayoutHeader>

      <StyledLayoutBlock>

        {props.loading ?
          <Loader/> :

          <StyledUsersList>

            {props.users?.map((user, index) => (
              <li key={index} className={classNames({'opened': openedLi === index})}>

                {index !== openedLi &&
                <>
                  <div className={'user-name'}>{user.UserName}</div>

                  <div className={'user-login'}>{user.Abbreviation}</div>

                  <div className={'user-lab'}>{props.labs?.find(item => item.Id === user.LaboratoryId)?.Name}</div>

                  <ul>
                    {Object.values(user.Roles).map((role, index) => (
                      <li key={index}>
                        {RolesMapTranslate[role]}
                      </li>
                    ))}
                  </ul>

                  <Button
                    className={'fo-edit'}
                    onAction={() => editUser(index)}
                    view={'icon'}
                    size={'L'}
                  />
                </>
                }

                {index === openedLi &&
                <EditUser user={user} labs={props.labs} onCancel={cancelEdit}/>
                }

              </li>
            ))}


          </StyledUsersList>
        }

        <PaginationStyled
          {...props.listPagination}
          handlePagingChange={handlePaginationClick}
        />

      </StyledLayoutBlock>
    </>
  );
};

const mapStateToProps = (state) => {
  const {global, resource, modules} = state;
  return {
    account: global.account,
    users: itemsToArraySelector(resource.users),
    labs: itemsToArraySelector(resource.dictionary.labs),
    loading: resource.users.pending && resource.dictionary.labs.pending,
    searchFormValues: getFormValues(USERS_SEARCH_FORM_NAME)(state),
    listPagination:
      modules.users &&
      modules.users.list &&
      modules.users.list.pagination
  };
};

export default withRouter(connect(
  mapStateToProps,
  (dispatch) => ({
    getUsersList: (params) => dispatch(usersResourceActions.list.request({params})),
    clearUsersList: () => dispatch(usersResourceActions.destroy()),
    getLabs: () => dispatch(labsResourceActions.list.request({})),
    getUser: (id: String) => dispatch(usersResourceActions.get.request({uriParams: {id}}))
  })
)(UserRoles));
