import {BcdDatePickerWrapper, InlineEdit, inputTypesEnum} from '../../../../shared/components';
import { FieldValidators } from '../../../../shared/validators/field-validators';
import { JarStockKeysEnum } from '../../../models';
import type { TableCol } from '../../../../shared/models/table-col-type';
import {Link} from 'react-router-dom';
import {AnalyticsTestKeys} from "../../../../analytics/models";
import {Field} from "redux-form";
import { BcdCheckboxWrapper } from '../../../../shared/components/BcdCheckboxWrapper';

export const jarStockTableCols: TableCol[] = [
  {
    title: '№ банки',
    mappingProp: JarStockKeysEnum.Number,
    validate: [FieldValidators.minNum(0), FieldValidators.stepNum(1)],
    className: 'w115',
    renderChild: ({ ...rest }) => (
      <InlineEdit {...rest} inputType={inputTypesEnum.InputNum} />
    )
  },
  {
    title: 'Производитель',
    mappingProp: JarStockKeysEnum.Provider,
    validate: [FieldValidators.required],
    className: 'w150',
    renderChild: ({ item, ...rest }) => (
        item.ProviderReactionId
            ? <div className={'b-InlineEdit'}>
                <div className={'b-InlineEdit__valueHolder'}>
                  <Link className={'b-InlineEdit__value on-top'} to={`/reactions/${item.ProviderReactionId}`}>{ item.Provider }</Link>
                </div>
              </div>
            : <InlineEdit {...rest} inputType={inputTypesEnum.InputText} />
    )
  },
  {
    title: 'Квалиф.',
    mappingProp: JarStockKeysEnum.Qualification,
    validate: [],
    className: 'w115',
    renderChild: ({ ...rest }) => (
      <InlineEdit {...rest} inputType={inputTypesEnum.InputText} />
    )
  },
  {
    title: 'Осн. вещ-во, %',
    mappingProp: JarStockKeysEnum.MainAgentContent,
    className: 'align-right w150',
    validate: [
      FieldValidators.minNum(0.01),
      FieldValidators.maxNum(100),
      FieldValidators.stepNum(0.01),
      FieldValidators.required
    ],
    renderChild: ({ ...rest }) => (
      <InlineEdit {...rest} inputType={inputTypesEnum.InputNum} />
    )
  },
  {
    title: 'М, моль/л',
    mappingProp: JarStockKeysEnum.Concentration,
    className: 'align-right w115',
    validate: [FieldValidators.minNum(0), FieldValidators.stepNum(0.01)],
    renderChild: ({ ...rest }) => (
      <InlineEdit {...rest} inputType={inputTypesEnum.InputNum} />
    )
  },
  {
    title: 'Плотность, г/мл',
    mappingProp: JarStockKeysEnum.Density,
    className: 'align-right w150',
    validate: [FieldValidators.minNum(0), FieldValidators.stepNum(0.001)],
    renderChild: ({ ...rest }) => (
      <InlineEdit {...rest} inputType={inputTypesEnum.InputNum} />
    )
  },
  {
    title: 'Фасовка, г',
    mappingProp: JarStockKeysEnum.PackageWeight,
    className: 'align-right w115',
    validate: [FieldValidators.minNum(0), FieldValidators.stepNum(0.01)],
    renderChild: ({ ...rest }) => (
      <InlineEdit {...rest} inputType={inputTypesEnum.InputNum} />
    )
  },
  {
    title: 'Доступно, г',
    mappingProp: JarStockKeysEnum.Amount,
    validate: [FieldValidators.minNum(0), FieldValidators.stepNum(0.001), FieldValidators.required],
    className: 'w115',
    renderChild: ({ ...rest }) => (
      <InlineEdit {...rest} inputType={inputTypesEnum.InputNum} />
    )
  },
  {
    title: 'В заказе',
    mappingProp: JarStockKeysEnum.IsOrdered,
    className: '',
    renderChild: ({ item, ...rest }) => (
      <BcdCheckboxWrapper {...rest} />
    )
  },
  {
    title: 'Расположение',
    mappingProp: JarStockKeysEnum.LaboratoryId,
    validate: [FieldValidators.required],
    className: 'w150',
    getLabelById: (id: number, list?: [], field?: string) => {
      try {        
        let el = list.find(x => x.Id === id);
        if(el == undefined) return null;
        return el[field];
      } catch (e) {
        return null;
      }
    },
    renderChild: ({ ...rest }) => (
      <InlineEdit {...rest} inputType={inputTypesEnum.Select} />
    )
  },
  {
    title: 'Место',
    mappingProp: JarStockKeysEnum.Location,
    validate: [FieldValidators.required],
    className: 'w150',
    renderChild: ({ ...rest }) => (
        <InlineEdit {...rest} inputType={inputTypesEnum.InputText} />
    )
  },
  {
    title: 'Серия',
    mappingProp: JarStockKeysEnum.Series,
    className: 'w150',
    renderChild: ({ ...rest }) => (
        <InlineEdit {...rest} inputType={inputTypesEnum.InputText} />
    )
  },
  {
    title: 'Срок годности ',
    mappingProp: JarStockKeysEnum.ExpirationDate,
    className: 'date-col',
    renderChild: ({ item, ...rest }) => (
        <InlineEdit {...rest} inputType={inputTypesEnum.DateTime} />
    )
  }
];
