// @flow

import React from 'react';
import { ReagentTypeEnum } from '../../../../reagents/model';
import styled from 'styled-components';


const TemplateReactionDiv = styled.div`
  cursor: pointer;
  border: 5px solid var(--grey-500); 
  margin: 8px;
  padding: 8px;
  &.is-selected {
    border-color: var(--green);
  }
`;

const getReagentsByType = (reaction, rType) => reaction.Reagents?.filter(r => r.ReagentType === rType);
const renderReagent = (r, index) => (<li key={index}>{r.ShortName || r.Name}</li>);

let TemplateReaction = (props) => {
    const catalysts = getReagentsByType(props.templateReaction, ReagentTypeEnum.Catalyst);
    const solvents = getReagentsByType(props.templateReaction, ReagentTypeEnum.Solvent);
    const auxs = getReagentsByType(props.templateReaction, ReagentTypeEnum.Auxiliary);

    return (
      <TemplateReactionDiv 
        className={props.isSelected && 'is-selected'}
        onClick={() => props.onSelect(props.templateReaction.Id)}>
        <h3>{props.templateReaction.Name} {props.templateReaction.AdditionalName && `(${props.templateReaction.AdditionalName})`}</h3>
            {catalysts.length > 0 &&
                <>
                    <span>Катализаторы</span>
                    <ul>
                        {catalysts?.map((r, index) => renderReagent(r, index))}
                    </ul>
                </>
            }
            {solvents.length > 0 &&
                <>
                    <span>Растворители</span>
                    <ul>
                        {solvents?.map((r, index) => renderReagent(r, index))}
                    </ul>
                </>
            }
            {auxs.length > 0 &&
                <>
                    <span>Вспомогательные реагенты</span>
                    <ul>
                        {auxs?.map((r, index) => renderReagent(r, index))}
                    </ul>
                </>
            }
      </TemplateReactionDiv>
    );
};

export {
    TemplateReaction
};

