// @flow

import { resourceActions, CRUDStatusActions } from '../../shared/utils/action-creators';
import { FOLDER_MODULE_NAME } from './module';

export const _temp_folderResourceAction = {
  ...resourceActions(FOLDER_MODULE_NAME),
  getDescendants: CRUDStatusActions(
    FOLDER_MODULE_NAME,
    'DESCENDANTS',
  ),
  getFolderByCandidateId: CRUDStatusActions(FOLDER_MODULE_NAME, 'GET_FOLDER_BY_CANDIDATE'),
  select: CRUDStatusActions(FOLDER_MODULE_NAME, 'SELCT_FOLDER_FOR_REACTION'),
  selectFolder: CRUDStatusActions(FOLDER_MODULE_NAME, 'SELCT_FOLDER_FOR_SCHEME'),
};
