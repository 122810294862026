import { fork, put, select, takeEvery } from '@redux-saga/core/effects';
import { getToken} from '../../account/reducers';
import { RepkaResourceService } from '../services';
import { reactionRepkaTaskListActions, reactionModuleActions } from '../actions';

export function* getRepkaTaskList({id}) {
    const token = yield select(getToken);
    try {
        const result = yield RepkaResourceService.getList({uriParams: {id}}, token);
        yield put(reactionRepkaTaskListActions.success(result));
    } catch (error) {
        yield put(reactionRepkaTaskListActions.failure({error}));
    }
}

export function* initRepkaRedirectTaskUrl() {
    const token = yield select(getToken);
    const redirectUrl = yield select(state => state.modules.reaction.settings.repkaRedirectTaskUrl);
    if(redirectUrl) {
        return;
    }

    const result = yield RepkaResourceService.getRedirectUrl(token);
    yield put(reactionModuleActions.settings.setRepkaRedirectTaskUrl({ url: result.result }));
}

export const repkaTaskListWatchers = [
    function* () {
        yield takeEvery(reactionRepkaTaskListActions.request.type, function* (action) {
            const { data } = action;
            yield fork(getRepkaTaskList, data);
        });
    },
    function* () {
        yield takeEvery(reactionModuleActions.settings.initRepkaRedirectTaskUrl.type, function* () {
            yield fork(initRepkaRedirectTaskUrl);
        });
    },
];