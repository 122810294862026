// @flow

import React from 'react';
import type { Element } from 'react';
import styled from 'styled-components';
import { LayoutBlock } from '../LayoutBlock';

const hasChildWithAreaName = (children, areaName) => !!React.Children.toArray(children).filter(child => child.props['gridAreaName']===areaName).length;

export const pageLayoutAreas = {
  leftSide: 'PageLayoutLeftSide',
  header: 'PageLayoutHeader',
  body: 'PageLayoutBody',
  breadcrumbs: 'PageLayoutBreadcrumbs',
  headerLeftSide: 'PageLayoutHeaderLeftSide',
  headerCenter: 'PageLayoutHeaderCenter',
  headerRightSide: 'PageLayoutHeaderRightSide',
  headerBackBtn: 'PageLayoutHeaderBackBtn',
  bodyTopLeft: 'PageLayoutBodyTopLeft',
  bodyBottomLeft: 'PageLayoutBodyBottomLeft',
  bodyRight: 'PageLayoutBodyRight',
};

export const PageLayoutSlot = styled('div')`
  grid-area: ${(props => props['gridAreaName'] || '')};
  overflow: auto;
  position: relative;
`;

export const PageLayout = styled('div')`
  display: grid;
  grid-template-areas: "${pageLayoutAreas.leftSide} ${pageLayoutAreas.header} ."
                       "${pageLayoutAreas.leftSide} ${pageLayoutAreas.body}   ."
                       "${pageLayoutAreas.leftSide} .                         .";
  grid-template-columns: ${({children}) => hasChildWithAreaName(children, pageLayoutAreas.leftSide) ? '48px' : 0 } auto 0;
  grid-template-rows: 96px minmax(620px, 1fr) var(--grid-spacer);
  grid-column-gap: var(--grid-spacer);
  height: 100%;
`;

export const PageLayoutHeader = styled('header')`
  display: grid;
  grid-template-areas: ".                                ${pageLayoutAreas.breadcrumbs}       ${pageLayoutAreas.breadcrumbs}  .                                 "
                       "${pageLayoutAreas.headerBackBtn} ${pageLayoutAreas.headerLeftSide}    ${pageLayoutAreas.headerCenter} ${pageLayoutAreas.headerRightSide}";
  grid-template-rows: ${({ children }) => hasChildWithAreaName(children, pageLayoutAreas.breadcrumbs) ? '16px' : 0} auto;
  grid-template-columns: ${({ children }) => hasChildWithAreaName(children, pageLayoutAreas.headerBackBtn) ? '60px' : 0} auto 1fr auto;
  justify-content: start;
  align-items: center;
`;

export const PageLayoutBody = styled(LayoutBlock)`
  display: grid;
  grid-template-areas: "${pageLayoutAreas.bodyTopLeft}    ${pageLayoutAreas.bodyRight}"
                       "${pageLayoutAreas.bodyBottomLeft} ${pageLayoutAreas.bodyRight}";
  
  grid-template-columns: 1fr ${({ children }) => hasChildWithAreaName(children, pageLayoutAreas.bodyRight) ? 'minmax(326px, 0.32fr)' : 0};
  grid-template-rows: minmax(200px, 0.25fr) auto;
  & > * {
    overflow: auto;
  }
`;
