import {candidateModuleActions, candidatesResourceActions} from '../actions/candidates';
import { STATUS_CODES } from 'http';

const initialState = {
    candidate: null,
    compoundFromMarvin: null,
    checkParentsTask: false,
    isEditMode: false,
    isViewMode: false,
    candidateSelect: {},
    order: null,
    filterCodes: []
};

export const candidatesModuleReducer = (state = initialState, action) => {
  const reduceFn = {
      [candidateModuleActions.setCurrentCandidate]: () => ({
          ...state,
          candidate: action.candidate
      }),
      [candidateModuleActions.removeCurrentCandidate]: () => ({
          ...state,
          candidate: null
      }),
      [candidateModuleActions.compoundFromMarvin]: () => ({
          ...state,
          compoundFromMarvin: action.result
      }),
      [candidatesResourceActions.checkRepkaTaskExists.request]: () => ({
          ...state,
          checkParentsTask: {
              response: false,
              pending: action.pending,
          }
      }),
      [candidatesResourceActions.checkRepkaTaskExists.success]: () => ({
          ...state,
          checkParentsTask: {
              response: action.result,
              pending: action.pending,
          }
      }),
      [candidatesResourceActions.get.success]: () => ({
          ...state,
          candidate: action.result
      }),
      [candidatesResourceActions.checkRepkaTaskExists.failed]: () => ({
          ...state,
          checkParentsTask: {
              response: false,
              pending: action.pending,
          }
      }),
      [candidateModuleActions.setEditMode]: () => ({
          ...state,
          isEditMode: action.isEditMode
      }),
      [candidateModuleActions.setViewMode]: () => ({
          ...state,
          isViewMode: action.isViewMode
      }),
      [candidateModuleActions.setOrder]: () => ({
        ...state,
        order: action.order
    }),
    [candidateModuleActions.setFilterCodes]: () => ({
      ...state,
      filterCodes: action.filterCodes
    }),
  }[action.type];
  return reduceFn ? reduceFn() : state;
};