import { JarStockKeysEnum } from '../../../../agents/models';

export const tableCols = [
  {
    title: 'Номер',
    mappingProp: JarStockKeysEnum.Number,
  },
  {
    title: 'Производитель',
    mappingProp: JarStockKeysEnum.Provider,
    // validate: [FieldValidators.required],
  },
  {
    title: 'Квалификация',
    mappingProp: JarStockKeysEnum.Qualification,
    // validate: [],
  },
  {
    title: 'Осн. вещ-во, %',
    mappingProp: JarStockKeysEnum.MainAgentContent,
    className: 'align-right',
      // validate: [FieldValidators.minNum(0.1), FieldValidators.maxNum(100), FieldValidators.stepNum(0.1), FieldValidators.required],
  },
  {
    title: 'М, моль/л',
    mappingProp: JarStockKeysEnum.Concentration,
    className: 'align-right',
      // validate: [FieldValidators.minNum(0), FieldValidators.stepNum(0.01)],
  },
  {
    title: 'Доступно',
    mappingProp: JarStockKeysEnum.Amount,
  },
  {
    title: 'Расположение',
    mappingProp: JarStockKeysEnum.LaboratoryId,
    // validate: [FieldValidators.required],
  },
  {
    title: 'Место',
    mappingProp: JarStockKeysEnum.Location,
    // validate: [FieldValidators.required],
  },
  {
    title: 'Химик',
    mappingProp: JarStockKeysEnum.JarUsers,
    // validate: [FieldValidators.required],
  },
];