const getColor = (val) => getComputedStyle(document.documentElement).getPropertyValue(val).trim();

export interface IStyleColors {
  trialColor: string,
  hasYieldColor: string,
  noYieldColor: string,
  uncompletedColor: string
}

export function getStyleColors(): IStyleColors {
  return {
    trialColor: getColor('--trialColor'),
    hasYieldColor: getColor('--hasYieldColor'),
    noYieldColor: getColor('--noYieldColor'),
    uncompletedColor: getColor('--uncompletedColor'),
  };
}