// @flow

import React, { useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { getLabsAsOptions, getLabsPendingSelector, getLabsSelector } from '../reducers';
import { labsResourceActions } from '../actions';
import type { IRootStore } from '../../app/reducers';
import { Dispatch } from 'redux';
import type { ILab } from '../models';
import type { OptionType } from '../../shared/models';

type TLabsLoaderHOCProps = {
	labs?: ILab[],
	labsPending?: boolean,
	labsOptions?: OptionType[],
	loadLabs?: ()=>void,
	clearLabs?: ()=>void
}

export interface ILabsLoaderWrappedComponentProps {
	labs?: ILab[],
	labsPending?: boolean,
	labsOptions?: OptionType[]
}

function labsLoaderHOC(WrappedComponent: React$Component) {

	function LabsLoader ({ labs, labsPending, labsOptions, loadLabs, clearLabs, ...props }: TLabsLoaderHOCProps & {}) {

		// effects
		useEffect(() => {
			if (!labs || !labs.length) {
				loadLabs();
			}
			return () => {

			}
		}, []);

		return (
			<WrappedComponent
				labs={labs}
				labsPending={labsPending}
				labsOptions={labsOptions}
				{...props}
			/>
		)

	}

	return connect(
		(state: IRootStore): TLabsLoaderHOCProps => ({
			labs: getLabsSelector(state),
			labsPending: getLabsPendingSelector(state),
			labsOptions: getLabsAsOptions(state),
		}),
		(dispatch: Dispatch): TLabsLoaderHOCProps => ({
			loadLabs: () => dispatch(labsResourceActions.list.request()),
			clearLabs: () => dispatch(labsResourceActions.destroy())
		})
	)(LabsLoader)

}


export {
	labsLoaderHOC
}
