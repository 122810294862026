import {
    put,
    takeEvery,
    fork,
    select,
} from 'redux-saga/effects';
import {getToken} from '../../account/reducers';
import {descendantsResourceActions} from '../actions';
import {DescendantsService} from '../services';

export function* getDescendantsList(params) {
    const token = yield select(getToken);
    const service = new DescendantsService(token);
    try {
        const result = yield service.getList(params);
        yield put(descendantsResourceActions.list.success(result));
    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(descendantsResourceActions.list.failure({error: e.response.data['ErrorMessage']}));
        }
    }
}

export const descendantsWatchers = [

  function* watchDescendantsList() {
    yield takeEvery(descendantsResourceActions.list.request.type, function* (action) {
        const {params} = action;
        yield fork(getDescendantsList, params);
    });
  }

];