// @flow

import React, {
  useState,
  useEffect,
  useRef
} from 'react';
import bnc from 'bnc';
import propTypes from 'prop-types';
import Tooltip from '@biocad/bcd-front-ui/layout/Tooltip';

import './index.less';
import Button from '@biocad/bcd-front-ui/controls/Button/index';
import { _noop } from '../../utils/common';


interface ICustomizedTooltipProps {
  className?: string;
  tooltipClassName?: string;
  buttonClassName?: string;
  contentsClassName?: string;
  onTooltipOpen?: (SyntheticEvent) => void,
  onTooltipClose?: (SyntheticEvent) => void,
  children?: typeof React.Children;
  title?: string;
  disabled?: boolean
}

const defaultProps: ICustomizedTooltipProps = {
  className: '',
  tooltipClassName: '',
  buttonClassName: '',
  contentsClassName: '',
  children: <div />,
  title: ' ',
  onTooltipClose: _noop,
  onTooltipOpen: _noop,
  disabled: false
};

const
  BNCBlock = new bnc('b-CustomizedTooltip'),
  BNCTrigger = BNCBlock.el('trigger'),
  BNCTriggerToggled = BNCTrigger.mod('toggled'),
  BNCTooltip = BNCBlock.el('tooltip'),
  BNCHeader = BNCBlock.el('header'),
  BNCTooltipContents = BNCBlock.el('tooltipContents'),
  BNCTooltipWrapper = BNCBlock.el('tooltipWrapper')
;

const CustomizedTooltip = (props: ICustomizedTooltipProps = defaultProps) => {
  // Refs
  const tooltipContentsWrapperRef = useRef();

  // State
  const
    [opened, setOpened] = useState(false)
  ;

  // Handlers
  const
    toggleTooltip = (event) => {
      if (opened) {
        typeof props.onTooltipClose === 'function' && props.onTooltipClose(event);
      } else {
        typeof props.onTooltipOpen === 'function' && props.onTooltipOpen(event);
      }
      if (opened && tooltipContentsWrapperRef.current?.contains(event.target)) {
        event.stopPropagation();
        return;
      }
      setOpened(prev => !prev);
    }
  ;

  return (
    <div className={BNCBlock + props.className}>
      <Button className={`${BNCTrigger} ${opened ? BNCTriggerToggled : ''} ${props.buttonClassName}`}
              view={'icon'}
              size={'L'}
              onAction={toggleTooltip}
              disabled={props.disabled}
      >
        <span className={BNCTooltipWrapper}>
          {
            opened &&
            <Tooltip notail
                     transparent
                     align={'right'}
                     className={BNCTooltip + props.tooltipClassName}
                     onClose={toggleTooltip}
            >
              <div className={BNCTooltipContents + props.contentsClassName} ref={tooltipContentsWrapperRef}>
                {
                  props.title &&
                  <div className={BNCHeader}>
                    { props.title }
                  </div>
                }
                { props.children }
              </div>
            </Tooltip>
          }
        </span>
      </Button>
    </div>
  );
};

CustomizedTooltip.propTypes = {
  className: propTypes.string,
  tooltipClassName: propTypes.string,
  buttonClassName: propTypes.string,
  title: propTypes.string,
};

export {
  CustomizedTooltip,
};
