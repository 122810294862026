// @flow

import { Model } from 'objectmodel';

export const JarUser = Model({
  Abbreviation: [String],
  AgentAvailabilityId: [Number],
  ReactionCompleted: [Boolean],
  UserName: [String],
  ReactionId: [Number],
});
