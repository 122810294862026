import { initial } from 'lodash';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import { schemeModuleActions } from '../../actions';
import './index.less';

const initialProps = {
    schemeId: null,
    showScheme: false,
};

let SchemeBreadcrumbs = (props = initialProps) => {
    function initialSchemeLoad() {
        if(!props.schemeId) {
            return;
        }
        props.loadBreadcrumbs(props.schemeId);
    }
    useEffect(initialSchemeLoad, [props.schemeId]);
    
    function getCandidateUrl() {
        const folderParam = props.breadcrumbs?.scheme?.folderId ? `&schemeFolderId=${props.breadcrumbs?.scheme?.folderId}` : '';
        const candidateUrl = `/projects/${props.breadcrumbs?.project?.id}?`
          + (props.breadcrumbs?.candidate?.folderId ? `folderId=${props.breadcrumbs?.candidate?.folderId}&` : ``) +
          `selectedId=${props.breadcrumbs?.candidate?.id}${folderParam}`;
        return candidateUrl;
    }

    return (
        props.breadcrumbs &&
        <>
            <Link to={getCandidateUrl()}>
                <span>{props.breadcrumbs.project.title}</span>
            </Link>
            &nbsp;—&nbsp;
            <Link to={getCandidateUrl()}>
                <span>{props.breadcrumbs.candidate.title}</span>
            </Link>
            { props.showScheme && 
            <>
                &nbsp;—&nbsp;
                <Link to={`/schemes/${props.breadcrumbs.scheme.id}`}>
                    <span>{props.breadcrumbs.scheme.title}</span>
                </Link> 
            </>

            }
        </>
    );
};


SchemeBreadcrumbs = connect(
    (state) => ({
        breadcrumbs: state?.modules?.scheme?.breadcrumbs,
    }),
    (dispatch) => ({
        loadBreadcrumbs: (schemeId: number) => dispatch(schemeModuleActions.loadBreadcrumbs({schemeId})),
    })
)(SchemeBreadcrumbs);

export { SchemeBreadcrumbs };
