// @flow
import React from 'react';
import type { Children } from 'react';
import styled from 'styled-components';
import './index.less';
import bnc from 'bnc';

type LayoutHeaderProps = {
  children?: Children,
  className?: string,
}

const
    block = new bnc('b-LayoutHeader'),
    blockName = block.toString();

export const LayoutHeader = (props: LayoutHeaderProps) => {
    return (
        <header className={blockName + props.className}>
            {props.children}
        </header>
    );
};

export const StyledLayoutHeader = styled(LayoutHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .buttons-holder {
    *:not(:last-of-type) {
      margin-right: var(--grid-spacer);
    }
  }
  .flex-expander {
    flex-grow: 1;
  }
`;
