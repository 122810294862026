// @flow
import React from 'react';
import {Field, reduxForm, Form} from 'redux-form';
import Button from '@biocad/bcd-front-ui/controls/Button';
import {faSearch} from '@fortawesome/free-solid-svg-icons/faSearch';
import {
    BcdInputWrapper
} from '../../../shared/components';
import {EquipmentSearchFormLayout} from './style';

const initialValues: {
    search: string
} = {
    search: ''
};

export let EquipmentSearchForm = ({onSubmit}) => {
    return (
        <Form onSubmit={onSubmit}>
            <EquipmentSearchFormLayout>
                <div className={'searchFields'}>
                    <Field
                        name={'search'}
                        component={BcdInputWrapper}
                        props={{
                            placeholder: 'Наименование оборудоваания',
                            icon: faSearch,
                            className: 'searchString',
                        }}
                        type="text"
                    />
                    <Button
                        text="Найти"
                        view="primary"
                        size="L"
                        type={'submit'}
                    />
                </div>
            </EquipmentSearchFormLayout>
        </Form>
    );
};

export const EQUIPMENT_SEARCH_FORM_NAME = 'equipmentSearchForm';

export default reduxForm({
    form: EQUIPMENT_SEARCH_FORM_NAME,
    initialValues
})(EquipmentSearchForm);
