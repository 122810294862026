// @flow

import {
  resourceActions,
  moduleAction,
  CRUDStatusActions,
} from '../../shared/utils';
import { SCHEMES_MODULE_NAME } from './module';

export const schemesResourceActions = {
  ...resourceActions(SCHEMES_MODULE_NAME),
  getMergeableSchemes: CRUDStatusActions(SCHEMES_MODULE_NAME, 'GET_MERGEABLE_SCHEMES'),
  getMergedSubschemes: CRUDStatusActions(SCHEMES_MODULE_NAME, 'GET_MERGED_SUBSCHEMES'),
  getLastStage: CRUDStatusActions(SCHEMES_MODULE_NAME, 'GET_LAST_STAGE'),
  getSchemeView: CRUDStatusActions(SCHEMES_MODULE_NAME, 'GET_SCHEME_VIEW'),
  getComparingSchemeViews: CRUDStatusActions(SCHEMES_MODULE_NAME, 'GET_COMPARING_SCHEME_VIEWS'),
  cloneScheme: CRUDStatusActions(SCHEMES_MODULE_NAME, 'CLONE_SCHEME'),
};
