// @flow

import axios, { AxiosPromise } from 'axios';
import type {
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';
import { ObjectModel } from 'objectmodel';
import { HTTPMethodEnum, CRUDRequestsMap } from './constants';
import { environment } from '../../../environment';
import { ResourceRequestMethod } from './resource-request-method';
import type {
  IServiceRequestConfig, ResourceRequestMethodType,
} from './resource-request-method';
import { SingleRequestURIParams } from '../../models';


export interface ICreateServiceConfig {
  baseURL: string, // базовый урл от которого будут начинаться урлы методов запросов
  requests?: { [string]: (IServiceRequestConfig | (AxiosInstance)=>AxiosPromise) }, // запросы, могут быть либо ввиде объекта конфига, либо ввиде функции принимающей базовый сервис
  useDefaultCRUDRequests?: Symbol[], // список дефолтных CRUD методов перечисленных в CRUDRequestsMap
  defaultModel?: typeof Function, // дефолтная модель данных получаемая от апи
  defaultQuery?: typeof Function, // дефолтная модель query запроса
  baseConfig?: AxiosRequestConfig, // перезапись конфига базового сервиса
}

export type ResourceRequestFunction = ResourceRequestMethodType;

export type IRESTService = { [key]: ResourceRequestMethodType }

const defaultConfig: ICreateServiceConfig = {
  baseURL: '/',
  requests: null,
  useDefaultCRUDRequests: [],
  defaultModel: null,
  defaultQuery: null,
  baseConfig: {}
};

export function ResourceService (
  config: ICreateServiceConfig = defaultConfig,
): IRESTService
{
  // Базовый сервис, использует интерфейс AxiosInstance
  const baseService: AxiosInstance = axios.create({
    baseURL: `${environment.apiUri}${config.baseURL || ''}`,
    validateStatus: (status) => status >= 200 && status < 300,
    ...config.baseConfig,
  });

  // CRUD запросы перечисленные в CRUDRequestsMap
  let CRUDRequests = {
    get: CRUDRequestsMap.get,
    list: CRUDRequestsMap.list,
    create: CRUDRequestsMap.create,
    update: CRUDRequestsMap.update,
    delete: CRUDRequestsMap.delete,
  };

  // Дефолтные конфиги для CRUD методов, используются если метод есть в списке
  // config.useDefaultCRUDRequests и не метод не переопределен в config.requests
  const DefaultCRUDRequestConfigMap: any = {
    [CRUDRequestsMap.get]: {
      url: '/{id}',
      method: HTTPMethodEnum.GET,
      model: config.defaultModel,
      queryParamsModel: config.defaultQuery,
      uriParamsModel: SingleRequestURIParams,
      bodyModel: null,
    },
    [CRUDRequestsMap.list]: {
      url: '',
      method: HTTPMethodEnum.GET,
      model: config.defaultModel,
      queryParamsModel: config.defaultQuery && 'extend' in config.defaultQuery && config.defaultQuery.extend(ObjectModel({ Limit: [Number], Skip: [Number] })),
      expectArray: true,
      uriParamsModel: null,
      bodyModel: null,
    },
    [CRUDRequestsMap.create]: {
      url: '',
      method: HTTPMethodEnum.POST,
      model: config.defaultModel,
      queryParamsModel: null,
      uriParamsModel: null,
      bodyModel: config.defaultModel,
    },
    [CRUDRequestsMap.update]: {
      url: '/{id}',
      method: HTTPMethodEnum.PUT,
      model: config.defaultModel,
      queryParamsModel: null,
      uriParamsModel: SingleRequestURIParams,
      bodyModel: config.defaultModel,
    },
    [CRUDRequestsMap.delete]: {
      url: '/{id}',
      method: HTTPMethodEnum.DELETE,
      model: null,
      queryParamsModel: null,
      uriParamsModel: SingleRequestURIParams,
      bodyModel: config.defaultModel,
    },
  };

  // Заменить символы на методы запросов
  CRUDRequests = Object.entries(CRUDRequests)
    .map(([requestName, requestSymbol]) => {
      // Если символ использован как название метода в config.requests, то используется либо объект конфига,
      // либо функция в которую передается базовый сервис.
      if (config.requests && CRUDRequestsMap[requestName] in config.requests) {
        return {
          [requestName]: typeof config.requests[requestSymbol] === 'object' ?
            ResourceRequestMethod(config.requests[requestSymbol], baseService) :
              (typeof config.requests[requestSymbol] === 'function' && config.requests[requestSymbol].length >= 1) ?
                config.requests[requestSymbol](baseService) :
                null
        };
      }
      // Иначе используется дефолтные настройки из DefaultCRUDRequestConfigMap, в config.useDefaultCRUDRequests
      // должны быть только символы из CRUDRequestsMap
      if (config.useDefaultCRUDRequests
        && Array.isArray(config.useDefaultCRUDRequests)
        && config.useDefaultCRUDRequests.includes(requestSymbol)) {
        return { [requestName]: ResourceRequestMethod(DefaultCRUDRequestConfigMap[requestSymbol], baseService) };
      }
      return null;
    })
    .filter(request => request && Object.values(request).find(value => value)) // убрать null элементы списка
    .reduce((cur, acc) => ({ ...acc, ...cur }), {}); // переделать список в объект

  // Остальные методы запросов из config.requests
  const otherRequests = config.requests && Object.entries(config.requests)
    .filter(([requestName, _]) => !(requestName in DefaultCRUDRequestConfigMap)) // убрать методы имена, которых являются символами из DefaultCRUDRequestConfigMap
    .map(([requestName, requestMethod]) => {
      if (typeof requestMethod === 'object') { // если метод запросы передан ввиде конфига то создать его используя стандартную функцию createServiceRequest
        return { [requestName]: ResourceRequestMethod(requestMethod, baseService) };
      }
      if (typeof requestMethod === 'function' && requestMethod.length >= 1) { // если метод запроса передан ввиде функции, которая принимает хотя бы один аргумент,
                                                                              // то выполнить эту функцию передав ей базовый сервис
        return { [requestName]: requestMethod(baseService) };
      }
      return null;
    })
    .filter(i => i) // убрать пустые значения из списка
    .reduce((cur, acc) => ({ ...acc, ...cur }), {}); // переделать список в объект

  return {
    ...CRUDRequests,
    ...otherRequests,
  };
}
