// @flow
import { AnalyticsTest } from './analytics-test.model';

export class AnalyticsSmallMolecule {
  AgentAvailabilityId : number | null  ;
  Amount              : number | null  ;
  AnalyticTests       : AnalyticsTest[];
  BimsId              : string         ;
  Comment             : string         ;
  Id                  : number | null  ;
  IsDeleted           : boolean        ;
  ProductId           : number | null  ;
  ReactionId          : number | null  ;
  SampleName          : string         ;
  SampleType          : string         ;
  SendAsCandidate     : boolean        ;

  constructor ({molecule = null, ReactionId = null, AgentAvailabilityId = null, SampleName = ''}) {
    this.Amount              = molecule?.Amount              || null                        ;
    this.BimsId              = molecule?.BimsId              || ''                          ;
    this.Comment             = molecule?.Comment             || ''                          ;
    this.Id                  = molecule?.Id                  || 0                           ;
    this.IsDeleted           = molecule?.IsDeleted           || false                       ;
    this.ProductId           = molecule?.ProductId           || null                        ;
    this.ReactionId          = molecule?.ReactionId          || ReactionId                  ;
    this.AgentAvailabilityId = molecule?.AgentAvailabilityId || AgentAvailabilityId         ;
    this.SampleName          = molecule?.SampleName          || SampleName                          ;
    this.SampleType          = molecule?.SampleType          || ''                          ;
    this.SendAsCandidate     = molecule?.SendAsCandidate     || false                       ;

    this.AnalyticTests = molecule?.AnalyticTests?.length 
      ? molecule.AnalyticTests.map(test => new AnalyticsTest({test}))
      : [];
  }

  deleteSelf() {
    this.IsDeleted = true;
  }

  newTest() {
    const newTest = new AnalyticsTest({});
    this.AnalyticTests = [
      ...this.AnalyticTests,
      newTest,
    ];
  }

  deleteTest(index: number) {
    const test = this.AnalyticTests[index];
    if (test.Id) {
      test.deleteSelf();
      this.AnalyticTests = [
        ...this.AnalyticTests
      ];
    } else {
      this.AnalyticTests = [
        ...this.AnalyticTests.filter((x, i) => index !== i)
      ];
    }
  }
}

export const AnalyticsSmallMoleculeKeys = {
  AgentAvailabilityId: 'AgentAvailabilityId',
  Amount             : 'Amount'             ,
  AnalyticTests      : 'AnalyticTests'      ,
  BimsId             : 'BimsId'             ,
  Comment            : 'Comment'            ,
  Id                 : 'Id'                 ,
  IsDeleted          : 'IsDeleted'          ,
  ProductId          : 'ProductId'          ,
  ReactionId         : 'ReactionId'         ,
  SampleName         : 'SampleName'         ,
  SampleType         : 'SampleType'         ,
  SendAsCandidate    : 'SendAsCandidate'    ,
};

export const AnalyticsSmallMoleculeTitles = {
  SampleName   : 'Название',
  SampleType   : 'Тип образца',
  Amount       : 'Кол-во, г' ,
  AnalyticTests: 'Анализы, шт',
  Comment      : 'Комментарий',
};