// @flow
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IPaginationParams } from '../../models';
import bnc from 'bnc';
import Input from '@biocad/bcd-front-ui/controls/Input';
import { useDidMount } from '../../react-hooks/did-mount';
import classNames from 'classnames';

import './index.less';
import {_noop} from '../../utils/common';

interface IPaginatorProps {
  handlePagingChange: (params: { skip: number, limit: number }) => void,
  page?: number,
  pageCount?: number,
  pageSize?: number,
  total?: number,
  pagingOptions?: number[],
  className?: string,
  hidePageInput?: boolean,
  hideLimit?: boolean,
}

const
  block = new bnc('b-Paginator'),
  pageNumHolder = block.el('pageNumHolder'),
  pagingItem = block.el('item'),
  pageNumItem = pagingItem.mod('pageNumItem'),
  interSignNumItem = pagingItem.mod('interSign'),
  itemIsActive = pagingItem.mod('isActive'),
  goToBtn = pagingItem.mod('goToBtn'),
  goToBtnPrev = pagingItem.mod('prev'),
  goToBtnNext = pagingItem.mod('next'),
  goToBtnFirst = pagingItem.mod('first'),
  goToBtnLast = pagingItem.mod('last'),
  changePageInput = block.el('changePageInput'),
  changeLimitHolder = block.el('changeLimitHolder'),
  limitOptionItem = pagingItem.mod('limitOptionItem'),
  limitOptionItemIsActive = pagingItem.mod('limitOptionItemIsActive'),
  goToBtnCssClass = pagingItem.toString() + goToBtn.toString();

export const pagingLimitOptions = [10, 20, 30, 40];
export const initialPagingParams = {
  limit: 20,
  skip: 0,
};

const
    MAX_PAGINATOR_ITEMS_LENGTH = 5,
    PAGINATOR_ITEMS_INTER_SIGN = '...'
;


const renderPagingItems = (
  params: { page: number, pageCount: number },
  itemClickHandler: (page: number) => void
): any => {

  const
      pageCountIsLessOrEqualThenMaxCount    = params.pageCount <= MAX_PAGINATOR_ITEMS_LENGTH,
      pageIsAtEdge                          = (params.page <= 2 || params.page >= (params.pageCount - 1)),
      isFirstPage                           = pageIsAtEdge && params.page === 1,
      isLastPage                            = pageIsAtEdge && params.page === params.pageCount,
      isSecondPage                          = pageIsAtEdge && params.page === 2,
      isNextToLast                          = pageIsAtEdge && params.page === params.pageCount - 1
  ;

  const itemsLength = pageCountIsLessOrEqualThenMaxCount
      ? params.pageCount
      : pageIsAtEdge
          ? (isSecondPage || isNextToLast)
              ? MAX_PAGINATOR_ITEMS_LENGTH
              : MAX_PAGINATOR_ITEMS_LENGTH - 1
          : MAX_PAGINATOR_ITEMS_LENGTH + 2
  ;

  return new Array(itemsLength)
      .fill(_noop)
      .map((_, idx) =>
      {
          const item = idx+1;
          if (pageCountIsLessOrEqualThenMaxCount)
          {
              return idx+1;
          }
          if (pageIsAtEdge && !pageCountIsLessOrEqualThenMaxCount)
          {
              if (isFirstPage)
              {
                  switch (item)
                  {
                      case 1: return item;
                      case 2: return item;
                      case 3: return PAGINATOR_ITEMS_INTER_SIGN;
                      case 4: return params.pageCount;
                  }
              }
              else if (isSecondPage)
              {
                  switch (item)
                  {
                      case 1: return item;
                      case 2: return item;
                      case 3: return item;
                      case 4: return PAGINATOR_ITEMS_INTER_SIGN;
                      case 5: return params.pageCount;
                  }
              }
              else if (isNextToLast)
              {
                  switch (item)
                  {
                      case 1: return item;
                      case 2: return PAGINATOR_ITEMS_INTER_SIGN;
                      case 3: return params.pageCount - 2;
                      case 4: return params.pageCount - 1;
                      case 5: return params.pageCount;
                  }
              }
              else if (isLastPage)
              {
                  switch (item)
                  {
                      case 1: return item;
                      case 2: return PAGINATOR_ITEMS_INTER_SIGN;
                      case 3: return params.pageCount - 1;
                      case 4: return params.pageCount;
                  }
              }
          }
          else {
              switch (item)
              {
                  case 1: return item;
                  case 2: return PAGINATOR_ITEMS_INTER_SIGN;
                  case 3: return params.page - 1;
                  case 4: return params.page;
                  case 5: return params.page + 1;
                  case 6: return PAGINATOR_ITEMS_INTER_SIGN;
                  case 7: return params.pageCount;
              }
          }
      })
      .map((item, idx, arr) =>
      (
          <li
              className={
                  classNames({
                      [pagingItem]      : true,
                      [pageNumItem]     : true,
                      [itemIsActive]    : item === params.page,
                      [interSignNumItem]: item === PAGINATOR_ITEMS_INTER_SIGN,
                  })
              }
              key={idx}
              onClick={ () => (isNaN(item) || item === params.page) ? _noop : itemClickHandler(item) }
          >
              { item }
          </li>
      ));
};

export const Paginator = ({
    handlePagingChange,
    page,
    pageCount,
    pageSize,
    total,
    pagingOptions = pagingLimitOptions,
    className,
    hideLimit=false,
    hidePageInput=false,
}: IPaginatorProps): any => {

  const [pageInput, setPageInput]: [number, Function] = useState(page || 1);
  const [limit, setLimit]: [number, Function] = useState(pageSize || initialPagingParams.limit);
  const [skip, setSkip]: [number, Function] = useState((page > 1 && pageSize > 0) ? (page-1) * pageSize : initialPagingParams.skip);

  useEffect(() => {
    setPageInput(page || 1);
    setSkip((page - 1) * limit);
  }, [page]);

  const changePageOnSubmit = (event: any) => {
    event.preventDefault();
    if (pageInput <= 0)
    {
      setPageInput(1);
      setSkip(0);
      handlePagingChange({skip: 0, limit});
      return;
    }
    if (pageInput > pageCount)
    {
      setPageInput(pageCount);
      setSkip(skip * (pageCount-1));
      handlePagingChange({skip: skip * (pageCount-1), limit});
      return;
    }
    setSkip((pageInput-1) * limit);
    handlePagingChange({skip: (pageInput-1) * limit, limit});
  };

  const handleInputPageChange = (event: any) => {
    const value = event.target.value;
    if (event.target.value !== '')
    {
      setPageInput(Number.parseInt(value, 10));
    }
    else {
      setPageInput('');
    }
  };

  const handleLimitClick = (limitOption: number) => {
      setLimit(limitOption);
      setSkip(0);
      handlePagingChange({skip: 0, limit: limitOption});
  };

  const handleNextPageClick = () => {
      setSkip(prev => (page+1 <= pageCount) ? prev + limit : 0);
      handlePagingChange({
          skip: (page+1 <= pageCount) ? skip + limit : 0,
          limit
      });
  };

  const handlePrevPageClick = () => {
      setSkip(prev => (page-1 > 0) ? prev - limit : 0);
      handlePagingChange({
          skip: (page-1 > 0) ? skip - limit : 0,
          limit
      });
  };

  const handleToFirstPageClick = () => {
      setSkip(0);
      handlePagingChange({ skip: 0, limit });
  };

  const handleToLastPageClick = () => {
      setSkip((pageCount - 1) * limit);
      handlePagingChange({
          skip: (pageCount - 1) * limit,
          limit
      });
  };

  const handlePagingItemClick = (pagingItem: number) => {
      setSkip((pagingItem-1) * limit);
      handlePagingChange({
          skip: (pagingItem-1) * limit,
          limit
      });
  };

  return (
      <div className={block.toString() + className}>
        {
          page > 1 ?
            (
              <React.Fragment>
                <span
                  onClick={handleToFirstPageClick}
                  className={goToBtnCssClass + goToBtnFirst.toString()}
                />
                <span
                  onClick={handlePrevPageClick}
                  className={goToBtnCssClass + goToBtnPrev.toString()}
                />
              </React.Fragment>
            ) : ''
        }
        {
          page < pageCount ?
            (
              <React.Fragment>
                <span
                  onClick={handleNextPageClick}
                  className={goToBtnCssClass + goToBtnNext.toString()}
                />
                <span
                  onClick={handleToLastPageClick}
                  className={goToBtnCssClass + goToBtnLast.toString()}
                />
              </React.Fragment>
            ) : ''
        }
          <ul className={`${pageNumHolder.toString()}`}>
              { renderPagingItems({ page, pageCount }, handlePagingItemClick) }
          </ul>
        {
          !hidePageInput &&
          <form onSubmit={changePageOnSubmit}
                className={`${changePageInput.toString()}`}>
            <Input
              type="text"
              pattern="[0-9]*"
              value={pageInput}
              onChange={handleInputPageChange}
            />
          </form>
        }
        {
          !hideLimit &&
          <div className={`${changeLimitHolder.toString()}`}>
            <span>Показывать по:</span>
            {pagingOptions.map(option => (
              <span
                className={
                  pagingItem.toString() +
                  limitOptionItem.toString() +
                  (option === limit
                    ? limitOptionItemIsActive.toString()
                    : undefined)
                }
                onClick={() => handleLimitClick(option)}
                key={option}
              >
                      {option}
                  </span>
            ))}
          </div>
        }
      </div>
  );
};

Paginator.propTypes = {
  handlePagingChange: PropTypes.func.isRequired,
  page: PropTypes.number,
  pageCount: PropTypes.number,
  pageSize: PropTypes.number,
  total: PropTypes.number,
  pagingOptions: PropTypes.arrayOf((propValue, key, componentName, location, propFullName) =>
    Number.isNaN(Number(propValue)) && new Error(`Property ${propFullName} is expected to contain only numeric values`)),
  className: PropTypes.string,
};
