import {
    put,
    takeEvery,
    fork,
    select,
} from 'redux-saga/effects';
import {FolderService} from '../services';
import {folderResourceActions} from '../actions';
import {getToken} from '../../account/reducers';

export function* getFoldersList(params) {
    const token = yield select(getToken);
    const service = new FolderService(token);
    
    try {
        const result = yield service.getList(params);

        yield put(folderResourceActions.list.success(result));
    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(folderResourceActions.list.failure({error: e.response.data['ErrorMessage']}));
        }
    }
}

export function* createFolder(data) {
    const token = yield select(getToken);
    const service = new FolderService(token);
    
    try {
        const result = yield service.create(data);

        yield put(folderResourceActions.create.success(result));
    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(folderResourceActions.create.failure({error: e.response.data['ErrorMessage']}));
        }
    }
}

export function* updateFolder(data) {
    const token = yield select(getToken);
    const service = new FolderService(token);
    
    try {
        const result = yield service.update(data);

        yield put(folderResourceActions.update.success(result));
    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(folderResourceActions.update.failure({error: e.response.data['ErrorMessage']}));
        }
    }
}

export function* deleteFolder(params) {
    const token = yield select(getToken);
    const service = new FolderService(token);

    const {id, parentId, parentType} = params;

    try {
        const {result} = yield service.delete(id);

        if(!result.IsSuccess) {
            yield put(folderResourceActions.create.failure({
                error: 'Невозможно удалить папку. Папка не является пустой'
            }));
        }

        yield fork(getFoldersList, {parentId, parentType});

    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(folderResourceActions.delete.failure({error: e.response.data['ErrorMessage']}));
        }
    }
}

export const folderWatchers = [

    function* watchFolderList() {
        yield takeEvery(folderResourceActions.list.request.type, function* (action) {
            const {params} = action;

            yield fork(getFoldersList, params);
        });
    },

    function* watchCreateFolder() {
        yield takeEvery(folderResourceActions.create.request.type, function* (action) {
            const {data} = action;

            yield fork(createFolder, data);
        });
    },

    function* watchUpdateFolder() {
        yield takeEvery(folderResourceActions.update.request.type, function* (action) {
            const {data} = action;

            yield fork(updateFolder, data);
        });
    },

    function* watchDeleteFolder() {
        yield takeEvery(folderResourceActions.delete.request.type, function* (action) {
            const {data} = action;

            yield fork(deleteFolder, data);
        });
    }

];
