// @flow

import {
    ResourceService,
    ResourceRequestMethodType,
    CRUDRequestsMap,
    HTTPMethodEnum,
} from '../../shared/utils/service-creator';
import { ArrayModel } from 'objectmodel';
import { ReactionIndicatorParameter } from '../models';
import { Model } from 'objectmodel';

interface IReactionIndicatorParametersResourceService {
    list: ResourceRequestMethodType,
    delete: ResourceRequestMethodType,
    batch: ResourceRequestMethodType,
}

export const ReactionIndicatorParametersResourceService: IReactionIndicatorParametersResourceService = ResourceService({
    baseURL: '/reaction_parameters',
    defaultModel: ReactionIndicatorParameter,
    useDefaultCRUDRequests: [
        CRUDRequestsMap.list,
        CRUDRequestsMap.delete,
    ],
    requests: {
        batch: {
            url: '/batch',
            method: HTTPMethodEnum.POST,
        },
        delete: {
            url: '/batch',
            method: HTTPMethodEnum.DELETE,
            model: ArrayModel([Number]),
        },
        list: {
            method: HTTPMethodEnum.GET,
            paramsModel: Model({ reactionId: [Number] }),
          },
        getForNewReaction: {
            url: '/new_reaction',
            method: HTTPMethodEnum.GET,
            paramsModel: Model({ stageId: [Number] }),
        },
    },
});
