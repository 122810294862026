import {
    put,
    takeLeading,
    fork,
    select,
  } from 'redux-saga/effects';
  import { TagsService } from '../services/tags';
  import {getToken} from '../../account/reducers';
  import { tagsResourceActions } from '../../agents/actions';
    
  export const tagsWatchers = [
  
    function* watchGetTagsList() {
      yield takeLeading(tagsResourceActions.list.request.type, function*(action) {
        const { data } = action;
        yield fork(getTagsList, data);
      });
    },
  
  ];
  
  export function* getTagsList() {
    const token = yield select(getToken);
    const service = new TagsService(token);
  
    try {
      const result = yield service.getTagsList();
  
      yield put(tagsResourceActions.list.success(result));
    } catch (e) {
      if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
        yield put(tagsResourceActions.list.failure({error: e.response.data['ErrorMessage']}));
      }
    }
  }
  