// @flow

import {
  resourceActions,
} from '../../shared/utils';


export const ALIASES_ACTION_ROOT = 'ALIASES';
export const aliasesResourceActions = {
  ...resourceActions(ALIASES_ACTION_ROOT),
};
