import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Attachment } from '../../../../../attachments/models';
import { attachmentsActions } from '../../../../../attachments/actions';
import { FileUploaderDialog } from '../../../../../attachments/components/FileUploader/dialog';
import { FileUploader } from '../../../../../attachments/components/FileUploader';

const JarStockFileUploader = ({
  item,
  name,
  viewMode = false,
  index,
  uploadFiles,
  newFiles,
  saveFilesHandler,
  destroyFiles,
  handleClose,
  deleteHandler,
  canDeleteFiles = true,
  canUploadFiles = true,
}) => {
  const uploadHandler = ({ files }) => {  
    uploadFiles({ files, index });
  };

  useEffect(() => {
    newFiles.length &&
      saveFilesHandler({
        Attachments:[
          ...newFiles.map(x => (new Attachment({
              AttachmentId: x.Id,
              AgentAvailabilityId: item.Id,
              Id: 0,
              Attachment: x.Attachment
            })))
        ],
      });
      destroyFiles();
  }, [newFiles[0]]);

  return (
    <>
        <FileUploaderDialog handleClose={handleClose}
                            header={(viewMode || (canDeleteFiles || canUploadFiles)) && `Файлы ${name ? name : ''}`}
        >
          <FileUploader
            files={item?.Attachments}
            uploadHandler={uploadHandler}
            multiple={true}
            deleteHandler={deleteHandler}
            canDeleteFiles={canDeleteFiles}
            canUploadFiles={canUploadFiles}
            viewMode={viewMode}
          />
        </FileUploaderDialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  newFiles: state.global.attachments.uploadedFiles,
});

const mapDispatchToProps = dispatch => ({
  destroyFiles: () => dispatch(attachmentsActions.upload.destroy())
});


export default connect(mapStateToProps, mapDispatchToProps)(JarStockFileUploader);
