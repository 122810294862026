// @flow

import { IResourceState } from '../reducer-creators';
import { createSelector } from 'reselect';

export const itemByIdSelector = (state: IResourceState, itemId: number) => {
  const { data } = state;
  if (!data) {
    return console.warn('resource state is expected to have data field');
  }
  if (!Object.keys(data).length) return null;
  return data[itemId];
};

export const itemsToArraySelector = createSelector(
  (state: IResourceState) => state.data,
  (data) => !Object.keys(data).length ? [] : Object.values(data),
);

export const firstItemSelector = createSelector(
  (state: IResourceState) => state.data,
  (data) => !Object.keys(data).length ? [] : Object.values(data)[0],
);

export const needToLoadSelector = createSelector(
  (state: IResourceState) => state,
  (state) => state && !state.pending && !state.fail && !Object.keys(state.data).length,
);

export const resourcePaginationSelector = (state: IResourceState) => {
  const { pagination } = state;
  if (!pagination) {
    console.warn('no pagination in resource');
    return null;
  }
  return pagination;
};

export const anyResourceError = () => (resource) => {
  if (!resource) return;
  const failedResource = Object.values(resource).find((r: IResourceState) => r.fail);
  return failedResource ? failedResource.error : null;
};

export const anyResourcePending = () => (resource) => {
  if (!resource) return;
    const pendingResource = Object.values(resource).find((r: IResourceState) => r.pending);
    return pendingResource ? pendingResource.pending : null;
};
