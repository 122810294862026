import React, {useState, useEffect} from 'react';
import Dialog from '@biocad/bcd-front-ui/layout/Dialog';
import {MarvinSketch} from '../../../../../shared/components/MarvinSketch';
import {StyledMarvinModalContent, StyledMarvinModalFooter} from '../../../CreateArchivedReactionModal/style';
import styled from 'styled-components';
import Button from '@biocad/bcd-front-ui/controls/Button';
import type {IProduct} from '../../../../../products/model';
import type {IArchivedReagent} from '../../../../../archivedReagents/model';

const StyledEditIcon = styled('i')`
  color: var(--grey-300);
  
  &:hover {
    color: var(--grey-700);
    cursor: pointer;
  }
`;

interface IEditReactantModal {
    item: IProduct | IArchivedReagent
}

const initialProps = {
    item: null
};

let EditReactantModal = (props: IEditReactantModal = initialProps) => {

    // State
    const
        [isEditModalVisible, setIsEditModalVisible] = useState(false),
        [result, setResult] = useState(null)
    ;

    // Effects
    useEffect(() => {
        props.item && setResult(props.item);
    },[props.item]);

    // Methods
    const
        marvinEditModalTrigger = () => {
            setIsEditModalVisible(prev => !prev);
            setResult(null);
        },
        reactantChanged = (result) => {
            result.result && setResult(result.result);
        },
        onSave = () => {
            marvinEditModalTrigger();
        }
    ;

    return (
        <>
            <StyledEditIcon className={'fo-edit'} onClick={marvinEditModalTrigger}/>

            {isEditModalVisible &&
            <Dialog
                paranjaClose={false}
                close={marvinEditModalTrigger}
            >
                <header className={'dialog-header'}>
                    <h3>Редактирование вещества</h3>
                </header>

                <StyledMarvinModalContent className={'dialog-contents last'}>
                    <label>
                        {result?.Name || '-'}
                        <MarvinSketch
                            initialMrv={props.item?.Compound && props.item.Compound.Formula}
                            handleChange={(result) => reactantChanged({result})}
                        />
                    </label>
                </StyledMarvinModalContent>

                <StyledMarvinModalFooter className={'dialog-footer'}>
                    <Button
                        text={'Сохранить'}
                        onAction={onSave}
                        size={'L'}
                        view={'primary'}
                        disabled={!result}
                    />
                    <Button
                        text={'Отмена'}
                        onAction={marvinEditModalTrigger}
                        size={'L'}
                    />
                </StyledMarvinModalFooter>
            </Dialog>}
        </>
    );
};

export {
    EditReactantModal
};
