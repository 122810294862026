import styled from 'styled-components';

export const StyledMarvinModalContent = styled('div')`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  
  label {
    font-size: 20px;
    
    .b-MarvinSketch {
        width: 600px;
        height: 400px;
        margin-right: 20px;
    }
    
    &:last-child {
        position: relative;
        left: 100%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
  }
`;

export const StyledMarvinModalFooter = styled('footer')`
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 20px;
    background: var(--white);
`;
