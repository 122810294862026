// @flow

import {
  moduleAction,
  CRUDStatusActions,
} from '../../shared/utils';
export const SCHEMES_MODULE_NAME = 'SCHEMES';

export const schemeModuleActions = {
  destroyModule: moduleAction(SCHEMES_MODULE_NAME, ['DESTROY'], () => ({})),
  setSelectedStageId: moduleAction(
    SCHEMES_MODULE_NAME,
    ['EDIT_SCHEME', 'SET_SELECTED_STAGE_ID'],
    ({ stageId }: {stageId: number}) => ({ stageId })
  ),
  setCurrentSchemeId: moduleAction(
    SCHEMES_MODULE_NAME,
    ['EDIT_SCHEME', 'SET_CURRENT_SCHEME_ID'],
    ({ schemeId }: {schemeId: number}) => ({ schemeId })
  ),
  clearSchemeSavedId: moduleAction(
    SCHEMES_MODULE_NAME,
    ['EDIT_SCHEME', 'CLEAR_SCHEME_SAVED_ID'],
    () => null
  ),
  setComparingSchemeIds: moduleAction(
    SCHEMES_MODULE_NAME,
    ['COMPARE_SCHEME', 'SET_SCHEME_IDS'],
    schemeIds => schemeIds
  ),
  setComparingSchemeViews: moduleAction(
    SCHEMES_MODULE_NAME,
    ['COMPARE_SCHEME', 'SET_SCHEME_VIEWS'],
    schemeViews => schemeViews
  ),
  loadAgentBySmiles: CRUDStatusActions(
      SCHEMES_MODULE_NAME,
      'GET_AGENT_BY_SMILES'
  ),
  clearAgentForCurrentCompound: moduleAction(
      SCHEMES_MODULE_NAME,
      ['CLEAR_AGENT_FOR_CURRENT_COMPOUND'],
      () => ({})
  ),
  addJarAmountBtn: moduleAction(
      SCHEMES_MODULE_NAME,
      ['JAR_AMOUNTS', 'ADD'],
      ({ uuid, SmilesFormula, CompoundName }) => ({ uuid, SmilesFormula, CompoundName })
  ),
  loadSingleAgentJarAmount: moduleAction(
      SCHEMES_MODULE_NAME,
      ['JAR_AMOUNTS', 'LOAD', 'SINGLE'],
      ({ uuid }) => ({ uuid })
  ),
  loadAllAgentJarAmount: moduleAction(
      SCHEMES_MODULE_NAME,
      ['JAR_AMOUNTS', 'LOAD', 'ALL'],
      ({}) => ({})
  ),
  setAgentIdForLoadedJarAmounts: moduleAction(
      SCHEMES_MODULE_NAME,
      ['JAR_AMOUNTS', 'SET_AGENT_ID'],
      ({ uuid, agentId }) => ({ uuid, agentId })
  ),
  setPendingForLoadedJarAmount: moduleAction(
      SCHEMES_MODULE_NAME,
      ['JAR_AMOUNTS', 'SET_PENDING'],
      ({ uuid, pending }) => ({ uuid, pending })
  ),
  setShowResultForLoadedJarAmount: moduleAction(
      SCHEMES_MODULE_NAME,
      ['JAR_AMOUNTS', 'SET_SHOW'],
      ({ uuid, show }) => ({ uuid, show })
  ),
  setAllLoadedJarAmountsHidden: moduleAction(
      SCHEMES_MODULE_NAME,
      ['JAR_AMOUNTS', 'SET_ALL_HIDDEN'],
      () => ({})
  ),
  getPrecalcXlsx: moduleAction(
    SCHEMES_MODULE_NAME,
    ['EDIT_SCHEME', 'GET_PRECALC_XLSX'],
    ({ schemeId, xlsxName, schemeImage }: {schemeId: number, xlsxName: string, schemeImage: string}) => ({ schemeId, xlsxName, schemeImage })
  ),
  loadBreadcrumbs: moduleAction(
    SCHEMES_MODULE_NAME,
    ['EDIT_SCHEME', 'LOAD_BREADCRUMBS'],
    ({ schemeId }: {schemeId: number}) => ({ schemeId })
  ),
  setBreadcrumbs: moduleAction(
    SCHEMES_MODULE_NAME,
    ['EDIT_SCHEME', 'SET_BREADCRUMBS'],
    ({ breadcrumbs }) => ({ breadcrumbs })
  ),
};