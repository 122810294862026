import React from 'react';
import { TitleWithCounter } from '../../../../shared/components/TitleWithCounter';
import Button from '@biocad/bcd-front-ui/controls/Button';
import Checkbox from '@biocad/bcd-front-ui/controls/Checkbox';
import { JarStockHeaderStyled, JarSrockControlsSpanStyled } from './style';
import {
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import {userCRUDPermissionsSelector} from '../../../../account/reducers/users-modules-reducer';

let JarStockHeader = ({
  hideEmptyJars = true,
  setHideEmptyJars = () => {},
  count = 0,
  handleAddJarClick = () => {},
  userPermissions = {}
}) => {
  return (
    <JarStockHeaderStyled>
      <TitleWithCounter
        count={count}
        title={'Склад'}
      />
      <JarSrockControlsSpanStyled>
        <Checkbox checked={hideEmptyJars} large={true} onChange={() => setHideEmptyJars(!hideEmptyJars)}>Скрыть пустые банки</Checkbox>
          {
              userPermissions.jarStock.CREATE &&
              <Button
                  text={'Добавить'}
                  onAction={handleAddJarClick}
                  size={'L'}
                  icon={faPlus}
              />
          }
      </JarSrockControlsSpanStyled>
    </JarStockHeaderStyled>
  );
};

JarStockHeader = connect(
    (state) => ({
        userPermissions: userCRUDPermissionsSelector(state),
    }),
    null
)(JarStockHeader);

export default JarStockHeader;
