// @flow

import React from 'react';
import styled from 'styled-components';
import { LayoutHeader } from '../../../shared/components/LayoutHeader';
import { pageLayoutAreas } from '../../../shared/components/PageLayout';

export const StyledPageLayout = styled('div')`
  --LayoutHeader-margin-v: 0;
  --LayoutHeader-margin-h: 0;
  --LayoutBlock-margin-h: 0;
  --LayoutBlock-margin-v: 0;
  --LayoutBlock-padding-h: 0;
  --LayoutBlock-padding-v: 0;
  
  grid-template-rows: 80px minmax(620px,1fr) var(--grid-spacer);
  
  .${pageLayoutAreas.header} {
    padding: var(--grid-spacer) 0;
  }
  
  .${pageLayoutAreas.headerRightSide} {
    display: grid;
    grid-template: "linkButtons saveBtn";
    grid-column-gap: var(--grid-spacer);
    align-items: center;
    justify-self: end;
    .link-buttons {
      grid-area: linkButtons;
      .link-btn {
        font-size: 16px;
      }
      .separator {
        display: inline-block;
        height: 34px;
        width: 1px;
        background: var(--grey-400);
        vertical-align: middle;
      }
    }
    .save-btn {
      grid-area: saveBtn;
    }
    .cancel-btn {
      grid-area: cancelBtn;
    }
  }
  
  .${pageLayoutAreas.headerLeftSide} {
    display: flex;
    align-items: center;
    .h2 { 
      margin-bottom: 0;
      display: flex;
      align-items: center;
      > span { margin-bottom: 4px }
    }
  }
  
  .${pageLayoutAreas.headerBackBtn} {
    display: flex;
    .fo-folder-back {
      margin: auto 0 auto auto;
    }
  }
  
  .${pageLayoutAreas.body} {
    grid-template-rows: 1fr 0;
  }
  
  .${pageLayoutAreas.bodyRight} {
    border-left: 1px solid var(--grey-300);
  }
  
  .${pageLayoutAreas.bodyTopLeft} {
    border-bottom: 1px solid var(--grey-300);
  }
  
  .no-overflow {
    overflow: initial;
  }

`;
