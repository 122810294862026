// @flow


import React from 'react';
import bnc from 'bnc';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.less';


export interface IFormFieldLayoutProps {
  label?: string;
  style?: any;
  className?: string;
  childrenWrapClassName?: string;
  hint?: string;
  validMessage?: string;
  invalidMessage?: string | Error;
  valid?: boolean;
  required?: boolean;
  children?: typeof React.Children;
}

export const
  block = new bnc('b-FormFieldLayout'),
  blockHasHint = block.mod('hasHint'),
  bLabel = block.el('label').toString(),
  bHint = block.el('hint'),
  bHintValid = bHint.mod('valid').toString(),
  bHintInvalid = bHint.mod('invalid').toString(),
  bChildrenWrap = block.el('childrenWrap').toString();


const FormFieldLayout = ({
                           label,
                           style,
                           className = '',
                           childrenWrapClassName = '',
                           hint,
                           validMessage,
                           invalidMessage,
                           valid,
                           children,
                           required = false,
}: IFormFieldLayoutProps) => {
  const hasHint = hint !== undefined || validMessage !== undefined || invalidMessage !== undefined;
  
  const classes = classNames(
    block.toString(),
    hasHint ? blockHasHint.toString() : null,
    className ? className : null
  );

  return (
    <div className={classes} style={style}>
      {
        label 
          ? <span className={bLabel}>
              {label}
              {
                required && <i className='required-red' />
              }
            </span>
          : null
      }
      <span className={bChildrenWrap + childrenWrapClassName}>
        { children }
      </span>
      {
        ((valid === null || valid === undefined) && hint !== undefined && <span className={bHint.toString()}>{hint}</span>)
        ||
        (valid === true && validMessage &&
          <span className={bHint.toString() + bHintValid}>
            {validMessage}
          </span>)
        ||
        (valid === false && invalidMessage &&
          <span className={bHint.toString() + bHintInvalid}>
            {invalidMessage instanceof Error ? invalidMessage.message : invalidMessage}
          </span>)
      }
    </div>
  );
};

FormFieldLayout.propTypes = {
  label: PropTypes.string,
  hint: PropTypes.string,
  validMessage: PropTypes.string,
  invalidMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Error)]),
  valid: PropTypes.bool,
};

export {
  FormFieldLayout,
};
