// @flow
import { IResourceActions } from '../action-creators';
import { resourceAction } from '../action-creators';


export interface IResourceState {
  pending: boolean;
  fail: boolean;
  data: { [Id: string]: any };
  error: any;
  params: any;
  uriParams: any;
  requestSucceed: boolean;
}

export const initialResourceState: IResourceState = {
  pending: false,
  fail: false,
  data: {},
  error: null,
  params: {},
  requestSucceed: null,
};

export type reducerFn = (state: IResourceState, action: resourceAction) => IResourceState;
export type resourceRequestReducers = {[actionType: string]: reducerFn};

export const setResourceRequestState = (state: IResourceState, action: resourceAction): IResourceState => ({
  ...state,
  pending: action.pending,
  fail: action.fail,
  data: state.data,
  error: null,
  params: action.params || {},
  uriParams: action.uriParams || {},
  requestSucceed: null,
});

export const setResourceFailState = (state: IResourceState, action: resourceAction): IResourceState => ({
  ...state,
  pending: action.pending,
  fail: action.fail,
  data: state.data,
  error: action.error,
  requestSucceed: false,
});

export const setResourceSuccess = (state: IResourceState, action: resourceAction): IResourceState => ({
  ...state,
  pending: action.pending,
  fail: action.fail,
  data: mergeStateDataWithActionResult(state, action),
  params: action.params,
  error: null,
  requestSucceed: true,
});

function mergeStateDataWithActionResult(state: IResourceState, action: resourceAction) {
  let st = {
  ...state.data,
  ...(
    checkResult(action)
    /*Array.isArray(action.result)
      ? action.result.reduce((acc, cur) => ({...acc, [cur.Id]: cur}), {})
      : (action.result && action.result.Id)
      ? { [action.result.Id]: action.result }
      : {}*/)
  };
  return st;
}

function checkResult(action: resourceAction) {
  if(Array.isArray(action.result)) {
    console.log('array');
    return action.result.reduce((acc, cur) => ({...acc, [cur.Id]: cur}), {});
  } else {
    if(action.result != null && action.result!=undefined && action.result.Id != null && action.result.Id != undefined) {
      return { [action.result.Id]: action.result };
    } else {
      return {};
    }
  }
}

export const resourceReducer = (actionTypes: IResourceActions, additionalReducerFns?: resourceRequestReducers = {}): reducerFn => {

  return (state: IResourceState = initialResourceState, action: any): IResourceState => {
    const reducerFn = (action && state) && {

      
      // Get actions
      [actionTypes.get.request]: () => setResourceRequestState(state, action),
      [actionTypes.get.success]: () => ({
        ...state,
        pending: action.pending,
        fail: action.fail,
        data: { ...state.data, [action.result ? action.result.Id : 0]: action.result },
        params: action.params,
        error: null,
        requestSucceed: true,
      }),
      [actionTypes.get.failure]: () => setResourceFailState(state, action),


      // List Actions
      [actionTypes.list.request]: () => setResourceRequestState(state, action),
      [actionTypes.list.success]: () => ({
        ...state,
        pending: action.pending,
        fail: action.fail,
        data: {
          ...state.data,
          ...(action.result && Array.isArray(action.result) && action.result.reduce((acc, cur, index) => 
              ({...acc, [cur.Id || cur.id || index]: cur}), {}))
        },
        params: action.params,
        error: null,
        requestSucceed: true,
      }),
      [actionTypes.list.failure]: () => setResourceFailState(state, action),


      // Create Actions
      [actionTypes.create.request]: () => setResourceRequestState(state, action),
      [actionTypes.create.success]: () => ({
        ...state,
        pending: action.pending,
        fail: action.fail,
        data: mergeStateDataWithActionResult(state, action),
        params: action.params,
        error: null,
        requestSucceed: true,
      }),
      [actionTypes.create.failure]: () => setResourceFailState(state, action),


      // Update Actions
      [actionTypes.update.request]: () => setResourceRequestState(state, action),
      [actionTypes.update.success]: () => ({
        ...state,
        pending: action.pending,
        fail: action.fail,
        data: mergeStateDataWithActionResult(state, action),
        params: action.params,
        error: null,
        requestSucceed: true,
      }),
      [actionTypes.update.failure]: () => setResourceFailState(state, action),


      // Delete Actions
      [actionTypes.delete.request]: () => setResourceRequestState(state, action),
      [actionTypes.delete.success]: () => ({
        ...state,
        pending: action.pending,
        fail: action.fail,
        data: mergeStateDataWithActionResult(state, action),
        params: action.params,
        error: null,
        requestSucceed: true,
      }),
      [actionTypes.delete.failure]: () => setResourceFailState(state, action),


      // Destroy Actions
      [actionTypes.destroy]: () => initialResourceState,


      // Additional resource reducers - map with action types as keys and either functions that return state objects or objects
      ...Object.entries(additionalReducerFns).reduce((acc, cur) => ({
        ...acc,
        [cur[0]]: cur[1],
      }), {})
    }[action.type];
    return reducerFn ? reducerFn(state, action) : state;
  };
};
