// @flow

import React, {
  useState,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import {
  entryIsSelected,
  getCurrentFinalCandidate,
  getCurrentScheme,
  getCurrentStage,
  getMergeOption,
  getSelectedSubstanceSelector,
  productIsSelected,
} from '../../../reducers';
import type { IObtainmentStage, IStageSubstance } from '../../../models';
import { StageSubstanceEditLayout } from './style';
import Dropdown from '@biocad/bcd-front-ui/controls/Dropdown';
import type { OptionType } from '../../../../shared/models';
import { STAGE_MERGE_OPTION_ENUM } from '../../../constants';
import { _noop } from '../../../../shared/utils/common';
import { stageModuleActions } from '../../../actions';
import { AgentListDialog } from './AgentsListDialog';
import type { IAgent, ICompound } from '../../../../agents/models/i-agent';
import { MarvinSketch, MolecularFormulaFormatter } from '../../../../shared/components';
import { MergeableSchemesDialog } from './MergeableSchemesDialog';
import { candidatesResourceActions } from '../../../../projects/actions';
import type { IObtainmentScheme } from '../../../../schemes/models';
import Loader from '@biocad/bcd-front-ui/controls/Loader';
import { schemesResourceActions } from '../../../../schemes/actions';
import type { MarvinJsSketch } from '../../../../shared/services/compound/marvinjs-util';
import {userCRUDPermissionsSelector} from "../../../../account/reducers/users-modules-reducer";


interface IStageSubstanceEditProps {
  className: string;
  selectedSubstance?: IStageSubstance;
  productIsSelected?: boolean;
  entryIsSelected?: boolean;
  mergeOption?: string;
  currentStage?: IObtainmentStage,
  currentScheme?: IObtainmentScheme,
  currentCandidate?: any,
  currentSchemeId?: number,
  currentStageIsFinalCandidate?: boolean,
  currentCanBeModifiedOrDeleted?: boolean,
  isFirstStageInScheme?: boolean,
  lastStageOfScheme?: {},
  pending?: boolean,
  marvinPending?: boolean,
  setMergeOption?: (string)=>void,
  setSubstance?: (ICompound, number)=>void,
  getFinalCandidate?: (number)=>void,
  getCurrentScheme?: (number)=>void,
  setMarvinPending?: (boolean)=>void,
  userPermissions?: {},
}

const defaultProps: IStageSubstanceEditProps = {
  className: '',
  selectedSubstance: null,
  mergeOption: STAGE_MERGE_OPTION_ENUM.DEFAULT,
  currentStage: null,
  currentScheme: null,
  currentCandidate: null,
  currentSchemeId: null,
  currentStageIsFinalCandidate: null,
  currentCanBeModifiedOrDeleted: false,
  isFirstStageInScheme: null,
  lastStageOfScheme: null,
  pending: false,
  marvinPending: false,
  setMergeOption: _noop,
  setSubstance: _noop,
  getFinalCandidate: _noop,
  getCurrentScheme: _noop,
  setMarvinPending: _noop,
};

let StageSubstanceEdit = (props: IStageSubstanceEditProps = defaultProps) => {
  // constants
  const availableDropdownOptions: { condition: boolean, option: OptionType }[] = [
    {
      option: {
        label: (props.entryIsSelected && props.isFirstStageInScheme) || props.productIsSelected ? 'Выбор из справочника' : 'Слияние из справочника',
        value: STAGE_MERGE_OPTION_ENUM.AGENTS
      },
      condition: props.currentCanBeModifiedOrDeleted && (props.entryIsSelected ? props.isFirstStageInScheme : true),
    },
    {
      option: {
        label: 'Слияние из схемы',
        value: STAGE_MERGE_OPTION_ENUM.SCHEME
      },
      condition: props.currentCanBeModifiedOrDeleted && !props.productIsSelected && !props.entryIsSelected,
    },
    {
      option: {
        label: 'Объединение со схемой',
        value: STAGE_MERGE_OPTION_ENUM.UNITE_SCHEME
      },
      condition: props.currentCanBeModifiedOrDeleted && !props.productIsSelected && (props.entryIsSelected && props.isFirstStageInScheme),
    },
    {
      option: {
        label: 'Ручной ввод',
        value: STAGE_MERGE_OPTION_ENUM.MARVIN
      },
      condition: props.currentCanBeModifiedOrDeleted && (props.entryIsSelected ? props.isFirstStageInScheme : true),
    },
    {
      option: {
        label: 'Подставить целевую молекулу',
        value: STAGE_MERGE_OPTION_ENUM.FINAL_CANDIDATE
      },
      condition: props.currentCanBeModifiedOrDeleted && props.productIsSelected && !props.currentStageIsFinalCandidate
      && (!props.lastStageOfScheme || !props.currentStage?.Id || props.lastStageOfScheme?.Id === props.currentStage?.Id),
    },
  ];

  // State
  const
    [dropdownValue: OptionType, setDropdownValue]       = useState(STAGE_MERGE_OPTION_ENUM.DEFAULT),
    [dropdownOptions: OptionType[], setDropdownOptions] = useState([]),
    [marvinSketch: MarvinJsSketch, setMarvinSketch]     = useState(null)
  ;

  // Effects
  const _updateDropdownValueOnOptionChange = () => {
    setDropdownValue({
      value: props.mergeOption === STAGE_MERGE_OPTION_ENUM.DEFAULT ? undefined : props.mergeOption,
      label: props.mergeOption === STAGE_MERGE_OPTION_ENUM.DEFAULT ? undefined : dropdownOptions.find(opt => opt.value === props.mergeOption)['label'],
    });
  };
  useEffect(_updateDropdownValueOnOptionChange, [props.mergeOption]);

  const _updateOptionsOnSelectedProductChange = () => {
    const options: OptionType[] = availableDropdownOptions
      .filter(opt => !!opt.condition)
      .map(opt => opt.option);
    setDropdownOptions(options);
  };
  useEffect(_updateOptionsOnSelectedProductChange, [
    props.selectedSubstance,
    props.mergeOption,
    props.currentCanBeModifiedOrDeleted,
    props.productIsSelected,
    props.currentStageIsFinalCandidate,
    props.entryIsSelected,
    props.isFirstStageInScheme,
  ]);

  const _watchMergeOption = () => {
    if (props.currentStageIsFinalCandidate) {
      return;
    }
    if (props.mergeOption === STAGE_MERGE_OPTION_ENUM.FINAL_CANDIDATE) {
      if (!props.currentScheme && !props.pending) {
        props.getCurrentScheme(props.currentSchemeId);
      }
      else if (props.currentScheme && !props.currentCandidate) {
        props.getFinalCandidate(props.currentScheme?.FinalCandidateId);
      }
    }
  };
  useEffect(_watchMergeOption, [props.mergeOption, props.pending, props.currentScheme]);

  const _watchFinaleCandidate = () => {
    if (props.currentStageIsFinalCandidate) {
      return;
    }
    if (props.currentCandidate && props.mergeOption === STAGE_MERGE_OPTION_ENUM.FINAL_CANDIDATE) {
      props.setSubstance(props.currentCandidate, props.selectedSubstance?.position);
      props.setMergeOption(STAGE_MERGE_OPTION_ENUM.DEFAULT);
    }
  };
  useEffect(_watchFinaleCandidate, [props.currentCandidate, props.mergeOption]);

  const _debounceMarvinPending = () => {
    if (props.marvinPending) {
      setTimeout(() => props.setMarvinPending(false), 1000);
    }
  };
  useEffect(_debounceMarvinPending, [props.marvinPending]);

  const _updateMarvinDisplaySettingsOnCanonicalized = () => {
    if (!props.selectedSubstance?.canonicalized?.IsValid) {
      if (marvinSketch && 'setDisplaySettings' in marvinSketch) {
        marvinSketch.setDisplaySettings({ atomIndicesVisible: true })
      }
    }
  };
  useEffect(_updateMarvinDisplaySettingsOnCanonicalized, [props.selectedSubstance?.canonicalized?.IsValid]);

  // Handlers
  const
    onMarvinSketchChange = (compound: ICompound, { pending }) => {
      if (!props.marvinPending && pending) props.setMarvinPending(pending);
      if (compound && compound.SmilesFormula) {
        props.setSubstance(compound, props.selectedSubstance?.position);
      }
    },
    onMarvinLoad = (sketch: MarvinJsSketch) => {
      setMarvinSketch(sketch);
      if (!props.selectedSubstance?.canonicalized?.IsValid) {
        sketch.setDisplaySettings({ atomIndicesVisible: true });
      }
      sketch.on('molchange', () => {
        if (sketch.isEmpty() && props.mergeOption === STAGE_MERGE_OPTION_ENUM.MARVIN) {
          props.setSubstance({
            Thumbnail: null,
            Formula: null,
            MolecularFormula: null,
            SmilesFormula: null,
          }, props.selectedSubstance.position);
        }
      });
    };

  return (
    <div className={props.className}>
      <StageSubstanceEditLayout>
        <div className={'header'}>
          {
            props.selectedSubstance &&
            <>
              {
                props.selectedSubstance?.title ?
                  <div>
                    { dropdownOptions.length ? 'Редактирование' : 'Просмотр' }: { props.selectedSubstance.title }
                    {
                      props.selectedSubstance?.MolecularFormula &&
                        <MolecularFormulaFormatter
                          formulaStr={props.selectedSubstance?.MolecularFormula}
                          className={'selected-substance-formula'}
                        />
                    }
                  </div> :
                  <div>Добавить вещество</div>
              }
              {
                <div>
                  { props.selectedSubstance?.Name }
                </div>
              }
              {
                (dropdownOptions.length && (props.userPermissions.stage.CREATE || props.userPermissions.stage.UPDATE)) ?
                <Dropdown
                  value={dropdownValue}
                  options={dropdownOptions}
                  onChange={(option) => props.setMergeOption(option)}
                  placeholder={'Выбрать молекулу'}
                  defaultValue={undefined}
                /> : 
                <div></div>
              }
            </>
          }
        </div>
        <div className={'edit-content'}>
          {
            props.pending && <Loader />
          }
          {
            props.mergeOption !== STAGE_MERGE_OPTION_ENUM.MARVIN &&
            <img
              src={props.selectedSubstance?.Thumbnail || require('../../../../assets/images/empty-formula.png')}
              alt=""
              className={`thumbnail ${!props.selectedSubstance?.Thumbnail ? 'thumbnail-empty' : ''}`}
            />
          }
          {
            props.mergeOption === STAGE_MERGE_OPTION_ENUM.MARVIN &&
            <MarvinSketch
              initialMrv={props.selectedSubstance?.Formula}
              handleChange={onMarvinSketchChange}
              onSketchInit={onMarvinLoad}
              message={props.selectedSubstance.canonicalized.Message}
            />
          }
          {
            props.mergeOption === STAGE_MERGE_OPTION_ENUM.AGENTS &&
            <AgentListDialog isInStockOnly={true}/>
          }
          {
            (props.mergeOption === STAGE_MERGE_OPTION_ENUM.SCHEME ||
            props.mergeOption === STAGE_MERGE_OPTION_ENUM.UNITE_SCHEME) &&
            <MergeableSchemesDialog />
          }
        </div>
      </StageSubstanceEditLayout>
    </div>
  );
};

StageSubstanceEdit = connect(
  (state): IStageSubstanceEditProps => ({
    selectedSubstance: getSelectedSubstanceSelector(state),
    productIsSelected: productIsSelected(state),
    entryIsSelected: entryIsSelected(state),
    mergeOption: getMergeOption(state),
    currentStage: getCurrentStage(state),
    currentScheme: getCurrentScheme(state),
    currentSchemeId: state.modules.stage.editStage?.current?.ObtainmentSchemeId,
    currentCandidate: getCurrentFinalCandidate(state),
    pending: state.resource.schemes?.pending,
    marvinPending: state.modules.stage.editStage?.marvinPending,
    currentStageIsFinalCandidate: state.modules.stage.editStage.current?.IsFinalCandidate,
    currentCanBeModifiedOrDeleted: state.modules.stage.editStage?.current?.CanBeModifiedOrDeleted,
    lastStageOfScheme: state.modules.stage.editStage?.lastStageOfScheme,
    isFirstStageInScheme: state.modules.stage.editStage?.isFirstStageInScheme,
    userPermissions: userCRUDPermissionsSelector(state),
  }),
  (dispatch): IStageSubstanceEditProps => ({
    setMergeOption: (option: string) => dispatch(stageModuleActions.setMergeOption({ option })),
    setSubstance: (payload: ICompound, substancePosition: number) => dispatch(stageModuleActions.setSubstance({ payload, substancePosition })),
    getFinalCandidate: (id: number) => dispatch(candidatesResourceActions.get.request({ uriParams: { id } })),
    getCurrentScheme: (id: number) => dispatch(schemesResourceActions.get.request({ uriParams: { id } })),
    setMarvinPending: (pending: boolean) => dispatch(stageModuleActions.setMarvinPending({ pending })),
  }),
)(StageSubstanceEdit);

export {
  StageSubstanceEdit,
};
