// @flow
import * as React from 'react';
import Checkbox from '@biocad/bcd-front-ui/controls/Checkbox';
import { OptionType } from '../../models/option-type';
import {
    FormFieldLayout
} from '../FormFieldLayout';

type IBcdCheckboxWrapperProps = {
    name?       : string,
    disabled?   : boolean,
    checked?    : boolean,
    large?      : boolean,
    option      : OptionType,
    button?     : boolean,
    className?  : string,
    label       : string,
    useFormFieldLayout  : boolean,
    wrapperStyle?       : {},
    wrapperClassName?   : string,
    hint?               : string,
};

const defaultBcdCheckboxWrapperProps = {
    useFormFieldLayout: false,
    label: ''
};

// Wrapper for use BCD Checkbox component with Redux Forms.
export const BcdCheckboxWrapper = (props: (IBcdCheckboxWrapperProps & any) = defaultBcdCheckboxWrapperProps) => {
    const formFieldLayoutProps = {
        style: props.wrapperStyle,
        className: props.wrapperClassName,
        invalidMessage: props.input?.error,
        valid: props.input?.valid,
        hint: props.hint,
        required: props.required,
    };
    const renderBcdCheckBox = () => (
      <Checkbox
        name        = {props.name}
        disabled    = {props.disabled}
        checked     = {!!props.input?.value}
        large       = {props.large}
        option      = {props.option}
        button      = {props.button}
        className   = {props.className}
        {...props.input}
      >{props.label}</Checkbox>
    );

    return props.useFormFieldLayout ? (
      <FormFieldLayout {...formFieldLayoutProps} >
          { renderBcdCheckBox() }
      </FormFieldLayout>
    ) : renderBcdCheckBox();
};
