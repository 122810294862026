
export const useLastVisitedPage = () =>
{
    let lastVisitedPage = {};
    try {
        lastVisitedPage = JSON.parse(localStorage.getItem('lastVisitedPage')) || {};
    } catch (error) {
        lastVisitedPage = {};
    }
    return lastVisitedPage[window.name]?.pathname || '/user-dashboard';
}