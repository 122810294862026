// @flow

import { resourceReducer, setResourceFailState, setResourceRequestState } from '../../shared/utils';
import { stageResourceActions } from '../actions';
import { schemesResourceActions } from '../../schemes/actions';
import { createSelector } from 'reselect';
import type { IMergeableStage, IObtainmentStage, IStageSubstance } from '../models';
import type { IRootStore } from '../../app/reducers';
import type { IObtainmentScheme } from '../../schemes/models';
import { MergeableStage } from '../models';

export const stageResourceReducer = resourceReducer(stageResourceActions, {
  [schemesResourceActions.getLastStage.request]: setResourceRequestState,
  [schemesResourceActions.getLastStage.success]: (state, action) => ({
    pending: action.pending,
    fail: action.fail,
    data: { ...state.data },
    params: action.params,
    error: null,
  }),
  [schemesResourceActions.getLastStage.failure]: setResourceFailState,
  [stageResourceActions.clearAllNotCurrent]: (state, action: { id: number }) => ({
    ...state,
    data: {
      ...Object.entries(state.data)
        .filter(([id, _]) => Number(id, 10) === action.id)
        .reduce((acc, [id, stage]) => ({ ...acc, [id]: stage }), {})
    }
  })
});

// selectors
export const
  getMergeableStages = createSelector(
    [
      (state: IRootStore) => state.modules.stage.editStage?.mergeableSchemesLastStages,
      (state: IRootStore) => state.modules.stage.editStage?.current?.Id,
      (state: IRootStore) => state.modules.stage.editStage?.substances,
      (state: IRootStore) => state.resource.schemes.data
    ],
    (
      stages: { [key: number]: IObtainmentStage },
      currentId: number,
      substances: Map<{ [key: number]: IStageSubstance }>,
      schemes: { [key: number]: IObtainmentScheme }
    ) =>
    (
      Object.values(stages)
        .filter((stage: IObtainmentStage) => (
          stage?.Id !== currentId &&
          ![ ...substances.values() ]
            .map((substance: IStageSubstance) => substance.SourceSchemeId)
            .includes(stage?.ObtainmentSchemeId)
        ))
        .map((stage: IObtainmentStage): IMergeableStage => (
          MergeableStage({
            ...stage,
            schemeCode: schemes[stage.ObtainmentSchemeId]?.Code
          })
        ))
    )
  ),
  getCurrentStage = createSelector(
    [
      (state: IRootStore) => state.resource.stages?.data,
      (state: IRootStore) => state.modules.stage.editStage.current?.Id
    ],
    (stages: { [key: number]: IObtainmentStage }, currentId: number) => currentId
      ? stages[currentId]
      : null,
  ),
  getCurrentScheme = createSelector(
    [
      (state: IRootStore) => state.resource.schemes?.data,
      (state: IRootStore) => state.modules.stage.editStage.current?.ObtainmentSchemeId
    ],
    (schemes: { [key: number]: IObtainmentScheme }, schemeId: number) => schemeId ? schemes[schemeId] : null
  ),
  getCurrentFinalCandidate = createSelector(
    [
      (state: IRootStore) => state.resource.candidates?.data,
      (state: IRootStore) => state.resource.schemes?.data,
      (state: IRootStore) => state.modules.stage.editStage.current?.ObtainmentSchemeId,
    ],
    (candidates: { [key: number]: {}} , schemes: { [key:number]: IObtainmentScheme }, schemeId: number) => {
      const candidateId = schemeId && schemes[schemeId]?.FinalCandidateId;
      return candidates[candidateId];
    }
  );
