// @flow

import { jarStockResourceActions } from '../actions';
import { resourceReducer } from '../../shared/utils/reducer-creators';
import { createSelector } from 'reselect';
import type { IRootStore } from '../../app/reducers';


export const jarStockResourceReducer = resourceReducer(jarStockResourceActions);

export const getJarStock = createSelector(
	(state: IRootStore) => state.resource.jarStock.data,
	(data: {}) => Object.values(data)
);
