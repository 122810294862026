import React from 'react';
import './index.less';

const initialProps: {
    menuItems: Array<{
        title: string,
        action: Function
    }>,
    currentId: string
} = {
    menuItems: [],
    currentId: null
};

const DropdownMenu = (props = initialProps) => {

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    return (
        <React.Fragment>
            {props.menuItems.length &&
            <i className={'fo-ellipsis'} onClick={stopPropagation}/>}

            {props.menuItems.length &&
            <ul className={'dropdown-menu-list'} data-id={props.currentId}>
                {props.menuItems.map((item, index) => {
                    return (
                        <li key={index} onClick={item.action}>
                            {item.title}
                        </li>
                    );
                })}
            </ul>}

        </React.Fragment>
    );
};

export default DropdownMenu;
