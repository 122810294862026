import { apiRequestSingleAppendFile, apiRequestSingleBlob } from '../../shared/services/api';

export const ATTACHMENTS_ROOT = '/attachments';

export class AttachmentsService {
  constructor(token) {
    const headers = token
      ? {
          Authorization: token,
        } 
      : {};

    this._uploadFile = apiRequestSingleAppendFile('POST', `${ATTACHMENTS_ROOT}`, headers);    
    this._downloadFile = (id) => apiRequestSingleBlob('GET', `${ATTACHMENTS_ROOT}/${id}/content`, headers);    
  }


  uploadFile(data) {
    return this._uploadFile({}, data);
  }
  downloadFile(id) {
    return this._downloadFile(id)({});
  }
}