import React from 'react';
import PropTypes from 'prop-types';
import bnc from 'bnc';

import './index.less';


const
  block = new bnc('b-MolecularFormulaFormatter'),
  bSymbol = block.el('symbol'),
  bSymbolDigit = bSymbol.mod('digit').toString();

const MolecularFormulaFormatter = ({ formulaStr='-', className }) => {
  const formulaStrArr = formulaStr.trim().split('');
  return (
    <div className={block.toString() + className}>
      {
        formulaStrArr.map((s, i) => (
          /\d/i.test(s) ?
            <sub key={i} className={bSymbol.toString() + bSymbolDigit}>{s}</sub>
            :
            <span key={i} className={bSymbol.toString()}>{s}</span>
        ))
      }
    </div>
  );
};

MolecularFormulaFormatter.propTypes = {
  formulaStr: PropTypes.string.isRequired,
};

export {
  MolecularFormulaFormatter,
};
