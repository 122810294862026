// @flow

import {
    resourceActions,
} from '../../shared/utils';


export const WORK_PROJECTS_ROOT_NAME = 'WORK_PROJECTS';
export const workProjectsResourceActions = {
    ...resourceActions(WORK_PROJECTS_ROOT_NAME),
};