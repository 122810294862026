// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jarStock-m__grid___qsj96 {\n  display: grid;\n  grid-template-columns: 5fr 4fr;\n  grid-template-rows: 240px 1fr;\n  grid-gap: var(--grid-spacer);\n}\n.jarStock-m__thumbnail___u5MbS {\n  justify-self: center;\n  align-self: start;\n  padding-top: var(--grid-spacer);\n}\n.jarStock-m__thumbnail___u5MbS img {\n  max-height: 200px;\n}\n.jarStock-m__info___gkWvh {\n  padding-top: var(--grid-spacer);\n  display: flex;\n  flex-flow: column nowrap;\n  align-self: start;\n}\n.jarStock-m__info__field___H9RnU {\n  margin-bottom: 20px;\n}\n.jarStock-m__jarStock___HH_4w {\n  grid-column-start: 1;\n  grid-column-end: 3;\n  margin-bottom: 8px;\n}\n.jarStock-m__jarStockLabLabel___hmwO_ {\n  margin-right: 5px;\n}\n.jarStock-m__labDropdown___szTBq {\n  margin-bottom: 2px;\n  width: 300px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": "jarStock-m__grid___qsj96",
	"thumbnail": "jarStock-m__thumbnail___u5MbS",
	"info": "jarStock-m__info___gkWvh",
	"info__field": "jarStock-m__info__field___H9RnU",
	"jarStock": "jarStock-m__jarStock___HH_4w",
	"jarStockLabLabel": "jarStock-m__jarStockLabLabel___hmwO_",
	"labDropdown": "jarStock-m__labDropdown___szTBq"
};
export default ___CSS_LOADER_EXPORT___;
