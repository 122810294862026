// @flow

import {
    put,
    debounce,
} from 'redux-saga/effects';
import {
    reduxFormAutosaveDebounceAction,
    REDUX_FORM_AUTOSAVE_DELAY,
} from '../hocs/ReduxFormAutosave';

export const reduxFormAutosaveWatchers = [
    function* () {
        yield debounce(
            REDUX_FORM_AUTOSAVE_DELAY,
            reduxFormAutosaveDebounceAction.type,
            function* (action) {
                if ('submitActionType' in action && action.submitActionType !== null) { // submitActionType равен null то отменять сохранение
                    // SUBMIT FORM
                    yield put({ type: action.submitActionType });
                }
            });
    }
]