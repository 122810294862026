export interface IRepkaTask{
  Name: string;
  RepkaTaskId: number;
  Deadline: string;
}

export const RepkaTaskTitles = {
  Name: 'Описание:',
  RepkaTaskId: 'Номер задачи:',
  Deadline: 'Дедлайн:',
};