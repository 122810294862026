// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tests-m__template___BpSxB {\n  --template: 3fr 3fr 1fr 1fr 1fr;\n  margin: 0 auto;\n  border: 1px solid var(--border-color);\n  margin: 16px 32px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"template": "tests-m__template___BpSxB"
};
export default ___CSS_LOADER_EXPORT___;
