import React, {useState, useEffect} from 'react';
import Button from '@biocad/bcd-front-ui/controls/Button';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {candidateModuleActions, candidatesResourceActions} from '../../../actions';
import {CANDIDATE_EDIT_FORM_NAME} from '../RightPanel';
import {getFormValues, isInvalid} from 'redux-form';
import {getRepkaTaskLinks} from '../../../../shared/components/ParentsTasks/utils';
import queryString from 'query-string';
import { _noop } from '../../../../shared/utils/common';
import {userCRUDPermissionsSelector} from "../../../../account/reducers/users-modules-reducer";

import './index.less';

const initialProps = {
    setCurrentCandidate: () => null,
    formValues: null,
    createCandidate: () => null,
    folderPath: [],
    editCandidate: () => null,
    currentCandidate: null,
    clearCandidatesList: () => null,
    currentProjectType: null,
    onBeforeChange: _noop,
    saveAvailable: false,
    userPermissions: {},
    className: '',
    formInvalid: null
};

let Header = (props = initialProps) => {

    // States
    const
        [blockSaveBtn, setBlockSaveBtn] = useState(true),
        [currentProjectId, setCurrentProjectId] = useState(null),
        [isEditMode, setIsEditMode] = useState(false),
        [currentFolderId, setCurrentFolderId] = useState(null),
        [isSaving, setIsSaving] = useState(false)
    ;

    // Effects
    useEffect(() => {
        if (props.location.pathname.split('/')[2] !== 'add') {      // Проверка, создаем ли мы новую молекулу
            setIsEditMode(true);
        }
        else {
            let urlParams = queryString.parse(props.location.search);
            urlParams.projectId && setCurrentProjectId(urlParams.projectId);
        }
    }, []);

    useEffect(() => {
        if (props.currentCandidate) {
            setCurrentProjectId(props.currentCandidate.ProjectId);
            setCurrentFolderId(props.currentCandidate.FolderId);
        }
    }, [props.currentCandidate]);

    useEffect(() => {
        if (typeof props.formInvalid === 'boolean')
        {
            if (props.formInvalid)
            {
                return setBlockSaveBtn(props.formInvalid);
            }
            setBlockSaveBtn(props.formInvalid);
        }
        if (props.formValues && props.formValues.code &&
            props.formValues.mw && props.formValues.marvinCompound) {
            if (Number(props.currentProjectType) !== 2) {
                (props.formValues.parentsTasks && props.formValues.parentsTasks.length) ?
                    setBlockSaveBtn(false) : setBlockSaveBtn(true);
            } else {
                setBlockSaveBtn(false);
            }
        } else {
            setBlockSaveBtn(true);
        }
    }, [props.formValues, props.formInvalid]);

    // Methods
    const goBack = () => {
        const folderId = props.currentCandidate && props.currentCandidate.FolderId || props.formValues.folderId;
        const url = `/projects/${currentProjectId}?`
        + (folderId ? `folderId=${folderId}&` : ``) +
        `selectedId=${props.currentCandidate?.Id}`;
        props.setCurrentCandidate({
            candidate: null
        });
        setIsEditMode(false);

        props.history.push(url);
    };

    const saveChanges = () => {
        props.clearCandidatesList();

        if (isEditMode) {
            const marvinCompound = {
                ...props.formValues.marvinCompound,
                Id: props.currentCandidate.CompoundId,
            };

            if (props.formValues?.marvinCompound?.IsAntibody || props.formValues?.marvinCompound?.IsPolymer)
            {
                marvinCompound.MolarWeight = Number.parseFloat(props.formValues?.mw);
            }

            props.editCandidate({
                code: props.formValues.code,
                projectId: currentProjectId,
                name: (props.formValues?.marvinCompound?.IsAntibody || props.formValues?.marvinCompound?.IsPolymer)
                    ? props.formValues.name
                    : props.formValues.marvinCompound.Name,
                folderId: props.formValues.folderId,
                compoundId: props.currentCandidate.CompoundId,
                compound: marvinCompound,
                repkaTaskLinks: Number(props.currentProjectType) !== 2 ? getRepkaTaskLinks(props.formValues.parentsTasks, props.currentCandidate) : null,
                id: props.currentCandidate.Id,
                mnn: props.formValues.mnn,
                afs: props.formValues.afs,
                currentFolderId: props.folderPath.length ?
                    props.folderPath[props.folderPath.length - 1].id : null
            });

        }
        else {
            props.createCandidate({
                code: props.formValues.code,
                projectId: currentProjectId,
                folderId: props.formValues.folderId,
                repkaTaskLinks: Number(props.currentProjectType) !== 2 ? getRepkaTaskLinks(props.formValues.parentsTasks, props.currentCandidate) : null,
                compound: {
                    ...props.formValues.marvinCompound,
                    ...(
                        (props.formValues?.marvinCompound?.IsAntibody || props.formValues?.marvinCompound?.IsPolymer)
                        ? { MolarWeight: props.formValues.mw }
                        : null
                    )
                },
                name: (props.formValues?.marvinCompound?.IsAntibody || props.formValues?.marvinCompound?.IsPolymer)
                    ? props.formValues.name
                    : props.formValues.marvinCompound.Name,
                mnn: props.formValues.mnn,
                afs: props.formValues.afs,
                compoundId: 0
            });
        }

        setIsSaving(true);
    };

    useEffect(() => {
        if (props.saveAvailable) {
            saveChanges();
        }
    }, [props.saveAvailable]);

    useEffect(() => {
        if (isSaving && !props.pending) {
            goBack();
        }
    }, [isSaving, props.pending])

    return (
        <div className={`candidate-header ${props.className}`}>

            <h2>
                {isEditMode ? 'Редактирование ' : 'Создание '} целевой молекулы
            </h2>
            <div className={'buttons-panel'}>
                <Button
                    text={'Отмена'}
                    size={'L'}
                    onAction={goBack}
                />
                {
                    (props.userPermissions.candidate.CREATE || props.userPermissions.candidate.UPDATE) &&
                    <Button
                        text={'Сохранить'}
                        size={'L'}
                        view="primary"
                        onAction={props.onBeforeChange}
                        disabled={blockSaveBtn}
                    />
                }
            </div>

        </div>
    );

};

const mapStateToProps = (state) => {
    const {modules} = state;
    return {
        formValues: getFormValues(CANDIDATE_EDIT_FORM_NAME)(state),
        folderPath: modules.folder.candidatesPath,
        currentCandidate: modules.candidate.candidate,
        currentProjectType: modules.project?.currentProject?.Type,
        pending: state.resource?.candidates?.pending,
        userPermissions: userCRUDPermissionsSelector(state),
        formInvalid: isInvalid(CANDIDATE_EDIT_FORM_NAME)(state)
    };
};

Header = withRouter(Header);

Header = connect(
  mapStateToProps,
  (dispatch) => ({
      setCurrentCandidate: (candidate) => dispatch(candidateModuleActions.setCurrentCandidate(candidate)),
      createCandidate: (data) => dispatch(candidatesResourceActions.create.request({data})),
      editCandidate: (data) => dispatch(candidatesResourceActions.update.request({data})),
      clearCandidatesList: () => dispatch(candidatesResourceActions.destroy()),
      removeCurrentCandidate: () => dispatch(candidateModuleActions.removeCurrentCandidate())
  })
)(Header);

export default Header;
