// @flow

import { CRUDRequestsMap, HTTPMethodEnum, ResourceService } from '../../shared/utils/service-creator';
import { ReactionType } from '../model';
import type { ResourceRequestMethodType } from '../../shared/utils/service-creator';

interface IReactionTypesResourceService {
	get: ResourceRequestMethodType,
	update: ResourceRequestMethodType,
	create: ResourceRequestMethodType,
	delete: ResourceRequestMethodType,
	list: ResourceRequestMethodType,
}

export const ReactionTypesResourceService: IReactionTypesResourceService =
	ResourceService({
		baseURL: '/reaction_types',
		defaultModel: ReactionType,
		useDefaultCRUDRequests: [
			CRUDRequestsMap.get,
			CRUDRequestsMap.update,
			CRUDRequestsMap.create,
			CRUDRequestsMap.delete,
			CRUDRequestsMap.list,
		],
		requests: {
			[CRUDRequestsMap.create]: {
				url: '',
				method: HTTPMethodEnum.POST,
				model: ReactionType,
				dataModel: ReactionType,
			},
			[CRUDRequestsMap.update]: {
				url: '',
				method: HTTPMethodEnum.PUT,
				model: ReactionType,
				dataModel: ReactionType,
			},
			getTemplateReactionTypes: {
				url: '/template',
				method: HTTPMethodEnum.GET,
				model: ReactionType,
				dataModel: ReactionType,
			},
		}
	});
