// @flow

import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, Form, getFormValues, isValid, isDirty, change} from 'redux-form';
import {BcdDropdownWrapper} from '../../../shared/components/BcdDropdownWrapper';
import {BcdInputWrapper} from '../../../shared/components/BcdInputWrapper';
import {FieldValidators} from '../../../shared/validators/field-validators';
import {FormFieldLayout} from '../../../shared/components/FormFieldLayout';
import styled from 'styled-components';
import {RolesMap, RolesMapTranslate} from '../../models/roles-map';
import {StyledButton} from '../../../shared/components';
import {_noop} from '../../../shared/utils/common';
import {usersResourceActions} from '../../actions/users';

const StyledForm = styled.form`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  .form-user-name {
    width: 15%;
  }
  .b-FormFieldLayout {
    position: relative;
    width: 20%;
    padding: 0 16px 0 0;
    &:not(:last-child) {
      margin-bottom: 0;    
    }
    .b-FormFieldLayout__label {
      top: -20px;
    }
    .b-dropdown {
      padding: 6px 0 0 0;
    }
  }
  .form-user-roles {
    width: 25%;
    div {
      margin: 4px;
      height: 24px;
      font-size: 12px;
      padding: 0 8px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      transition-property: border;
      transition-duration: 0.4s;
      border-radius: 8px;
      background-color: var(--green);
      color: var(--white);
    }
  }
  .btns-container {
    display: flex;
  }
`;

export const USER_EDIT_FORM_NAME = 'userEditFormName';

const formInitialValues: {
  abbreviation: string,
  lab: string
} = {
  abbreviation: '',
  lab: ''
};

interface IEditUserProps {
  user: Object,
  labs: Object[],
  formValues: any,
  formDirty: boolean,
  formValid: boolean,
  onCancel: Function,
  updateAbbreviation: Function,
  updateLab: Function,
  updateUser: Function
}

const initialProps = {
  user: null,
  labs: null,
  formValues: null,
  formDirty: false,
  formValid: false,
  onCancel: _noop,
  updateAbbreviation: _noop,
  updateLab: _noop,
  updateUser: _noop
};

let EditUser = (props: IEditUserProps = initialProps) => {

  //Effects
  useEffect(() => {
    props.updateAbbreviation(props.user.Abbreviation);
    props.user?.LaboratoryId && props.updateLab(props.labs?.find(item => item.Id === props.user.LaboratoryId)?.Name);
  }, []);

  //Methods
  const handleSubmit = () => {
    let data = {
      userName: props.user?.UserName,
      abbreviation: props.formValues?.abbreviation,
      id: props.user?.Id,
      laboratoryId: props.formValues.lab ? props.labs?.find(lab => lab.Name === props.formValues.lab)?.Id : null,
      roles: props.user?.Roles
    };

    props.updateUser(data);
    props.onCancel();
  };

  const getLabsOptions = () => {
    let tempArr = [{
      value: '-',
      label: '-'
    }];

    props.labs.map(lab => {
      tempArr.push({
        value: lab.Name,
        label: lab.Name
      });
    });

    return tempArr;
  };

  return (
    <StyledForm onSubmit={handleSubmit}>

      <div className={'form-user-name'}>{props.user.UserName}</div>

      <FormFieldLayout label='Аббревиатура' required>
        <Field
          type='text'
          name={'abbreviation'}
          component={BcdInputWrapper}
          validate={[FieldValidators.required]}
          placeholder={'Введите аббревиатуру'}
        />
      </FormFieldLayout>

      {props.labs &&
      <Field
        name={'lab'}
        large={true}
        useFormFieldLayout={true}
        label={'Лаборатория'}
        component={BcdDropdownWrapper}
        options={getLabsOptions()}
        placeholder={'Выберите лабораторию'}
      />
      }

      <div className={'form-user-roles'}>
        {Object.values(props.user?.Roles).map((role, index) => (
          <div key={index}>
            {RolesMapTranslate[role]}
          </div>
        ))}
      </div>

      <div className={'btns-container'}>
        <StyledButton
          size="L"
          text={'Изменить'}
          type={'submit'}
          view="primary"
          disabled={!props.formValid || !props.formDirty}
        />

        <StyledButton
          size="L"
          text='Отмена'
          onAction={props.onCancel}
        />
      </div>

    </StyledForm>
  );
};

const mapStateToProps = (state) => {
  return {
    formValues: getFormValues(USER_EDIT_FORM_NAME)(state),
    formValid: isValid(USER_EDIT_FORM_NAME)(state),
    formDirty: isDirty(USER_EDIT_FORM_NAME)(state)
  };
};

EditUser = reduxForm({
  form: USER_EDIT_FORM_NAME,
  formInitialValues,
  destroyOnUnmount: true
})(EditUser);

EditUser = connect(
  mapStateToProps,
  (dispatch) => ({
    updateAbbreviation: (value) => dispatch(change(USER_EDIT_FORM_NAME, 'abbreviation', value, false)),
    updateLab: (value) => dispatch(change(USER_EDIT_FORM_NAME, 'lab', value, false)),
    updateUser: (data) => dispatch(usersResourceActions.update.request({data}))
  })
)(EditUser);

export default EditUser;
