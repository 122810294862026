import './index.less';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Dialog, { DialogLayout } from '@biocad/bcd-front-ui/layout/Dialog';
import Button from '@biocad/bcd-front-ui/controls/Button';
//import { setGlobalLoader } from '../../../../../../../shared/actions/loading';
import { candidatesResourceActions } from '../../../actions';
import type { IFinaleCandidate } from '../../../models';

interface ISelectCandidateProps{
  onClose: () => void,
  onSelect: (number) => void,
}

let SelectCandidateDialog = (props: ISelectCandidateProps) => {
  const [selectedCandidateId, setSelectedCandidateId] = useState(null);

  function initDialog() {
    if (props.project?.Id)
    {
      props.getCandidatesFromAllFolders(props.project?.Id);
    }
  }
  useEffect(initDialog, [props.project]);

  function assignInitialCandidateId() {
    setSelectedCandidateId(props.initialCandidateId);
  }
  useEffect(assignInitialCandidateId, [props.initialCandidateId])

  function selectCandidate(id) {
    setSelectedCandidateId(id);
  }

  function isCandidateSelected(id) {
    return selectedCandidateId === id;
  }

  return(
    <>
      <Dialog paranjaClose={false} close={props.onClose}>
        <div className={'select-candidate-dialog'}>
          <header className={'dialog-header'}>
            <h3>Выбор целевой молекулы</h3>
          </header>
          <div className={'dialog-contents'}>
          {props.candidates && props.candidates.sort((a, b) => b.Id - a.Id).map((candidate, index) => {
              let imgStyle = {
                  background: 'url(' + candidate.Compound.Thumbnail + ') center center no-repeat',
                  backgroundSize: 'contain'
              };

              return(
                <div
                      key={candidate?.Id}
                      className={`cell ${isCandidateSelected(candidate?.Id) && 'active'}`}
                      data-id={candidate?.Id}
                      onClick={() => selectCandidate(candidate?.Id)}
                      >

                  <div className={'img'}
                      style={imgStyle}
                      data-id={candidate?.Id}/>

                  <div className={'panel'} data-id={candidate?.Id}>
                      <h4>{candidate.Code}</h4>
                  </div>

                </div>
              );
            })
          }

          </div>
          <footer className={'dialog-footer'}>
            <Button
              text={'Выбрать'}
              view='primary'
              size={'L'}
              onAction={() => props.onSelect(selectedCandidateId)}
            />
            <Button
              text='Отмена'
              size={'L'}
              onAction={props.onClose}
            />
          </footer>
        </div>
      </Dialog>
    </>
  );

};

SelectCandidateDialog = withRouter(SelectCandidateDialog);
SelectCandidateDialog = connect(
  (state) => ({ 
    candidates: state?.resource?.candidates?.data?.fromAllFolders
  }),
  (dispatch) => ({
    getCandidatesFromAllFolders: (projectId) => dispatch(candidatesResourceActions.getFromAllFolders.request({uriParams: {projectId: projectId}})),
  })
)(SelectCandidateDialog);

export {
  SelectCandidateDialog
};