// @flow

import Button from '@biocad/bcd-front-ui/controls/Button';
import Loader from '@biocad/bcd-front-ui/controls/Loader';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { arrayPush, arrayRemove, change, getFormSyncErrors, getFormValues, initialize, isInvalid } from 'redux-form';
import {globalErrorsActions, setGlobalLoader} from '../../../shared/actions';
import { LayoutBlock, LayoutHeader } from '../../../shared/components';
import { ConfirmDialog } from '../../../shared/components/ConfirmDialog/ConfirmDialog';
import { useDidMount } from '../../../shared/react-hooks/did-mount';
import { itemsToArraySelector } from '../../../shared/utils/selectors/resource-selectors';
import { agentModuleActions, agentResourceActions, aliasesResourceActions, jarStockResourceActions } from '../../actions';
import { JarStock, copyJar } from '../../models';
import { AGENT_EDIT_FORM_NAME, EditForm } from './EditForm';
import JarStockForm, { AGENT_JAR_STOCK_ARRAY_FIELD_NAME, AGENT_JAR_STOCK_EDIT_FORM_NAME } from './JarStockForm';
import JarStockHeader from './JarStockHeader';
import JarStockSpendingsDialog from './JarStockSpendingsDialog';
import { StyledLayoutHeader } from './style';
import type {IAgent} from '../../models/i-agent';
import {userCRUDPermissionsSelector} from "../../../account/reducers/users-modules-reducer";


const getCurrentAgentJarStock = (jarStockData, agentId) => {
  return jarStockData.find(x => x[0] && x[0].AgentId === parseInt(agentId, 10));
};

const initialEditFormValues: IAgent = {
  MolarWeight: '',
  Cas: '',
  Name: '',
  IsIgnoringSpending: '',
  Aliases: '',
  ShortName: '',
  MolecularFormula: '',
  Compound: { },
  Tags: []
};

const Edit = ({
                history,
                currentAgent = { details: {}, jarStock: [] },
                newAgentId = null,
                agentCreated = null,
                aliasesCreated = null,
                jarStockCreated = null,
                labsList = [],
                pending = true,
                failure,
                agentEditFormValues = {},
                agentJarStockFormValues = {},
                match               = {},
                editFormIsInvalid,
                jarStockFormErrors,
                jarSpent = [],
                loadAgent = () => null,
                loadAliases = () => null,
                loadJarStock = () => null,
                destroyData = () => null,
                destroyCurrent = () => null,
                dispatchMarvinChange = () => null,
                addNewJarStockRow = () => null,
                copyJarStockRow = () => null,
                removeJarStockRow = () => null,
                initJarStockForm = () => null,
                updateAgent = () => null,
                createAgent = () => null,
                deleteAgent = () => null,
                updateJarStock = () => null,
                addAgentAliases = () => null,
                deleteAgentAliases = () => null,
                throwError = () => null,
                userPermissions = {},
                setLoader = () => null,
              }) => {
  const agentId = Number(match.params.agentId) || null;
  const agentIsNew = !agentId;

  const [spendingsDialogOpened, setSpendingsDialogOpened] = useState(false);
  const [confirmDeleteAgentDialogOpened, setConfirmDeleteAgentDialogOpened] = useState(false);
  const [confirmDeleteJarDialogOpened, setConfirmDeleteJarDialogOpened] = useState(false);
  const [jarStockIdxToRemove, setJarStockIdxToRemove] = useState(null);
  const [agentAvailabilityId, setAgentAvailabilityId] = useState(null);
  const [redirectToList, setRedirectToList] = useState(false);
  const [jarStockRowsHaveErrors, setJarStockRowsHaveErrors] = useState(false);
  const [hideEmptyJars, setHideEmptyJars] = useState(true);
  const [details, setDetails] = useState({});
  const [jarStock, setJarStock] = useState([]);
  const [needReload, setNeedReload] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const marvinCanonicalize = useRef();

  const keepFormValues = (currentAgentDetails) => {
    if(pending || !agentEditFormValues.Aliases || (currentAgentDetails.Id && !agentEditFormValues.Id))
      return {...currentAgentDetails};
    return {
      ...currentAgentDetails,
      Aliases: agentEditFormValues.Aliases,
      QuickComment: agentEditFormValues.QuickComment,
      Cas: agentEditFormValues.Cas,
      PhysicalState: agentEditFormValues.PhysicalState,
      ShortName: agentEditFormValues.ShortName,
      IsPrecursor: agentEditFormValues.IsPrecursor,
      IsIgnoringSpending: agentEditFormValues.IsIgnoringSpending,
      MSDAttachment: agentEditFormValues.MSDAttachment,
      MSDAttachmentId: agentEditFormValues.MSDAttachmentId,
    };
  };

  const _watchCurrentAgent = () => {
    if (currentAgent) {
      currentAgent.details && Object.keys(currentAgent.details).length
      && setDetails(keepFormValues(currentAgent.details));
      currentAgent.jarStock && currentAgent.jarStock.length
      && setJarStock(currentAgent.jarStock.map(jar => new JarStock({...jar})));
    }
  };
  useEffect(_watchCurrentAgent, [currentAgent]);

  // !!! Это глупо, но очень важно чтобы хук _loadInitialData стоял ПОСЛЕ хука _watchCurrentAgent - иначе локальный
  // стейт jarStock получает неактуальный currentAgent.jarStock и происходит рассинхрон.
  const _loadInitialData = () => {
    destroyCurrent();
    setJarStock([]);
    if (!agentIsNew) {
      loadAgent(agentId, {});
      loadJarStock(agentId);
      loadAliases(agentId, {});
    } else {
      details.Compound = {
        ...details.Compound,
        IsPolymer: match.params.agentId === 'addPolymer',
        IsAntibody: match.params.agentId === 'addAntibody',
      };
      setDetails(details);
    }
  };
  useEffect(_loadInitialData, []);

  const _clearDataOnUnmount = () => () => {
    destroyData();
    destroyCurrent();
    setLoader(0);
  };
  useEffect(_clearDataOnUnmount, []);

  const _watchJarStockErrors = () => {
    const stock = jarStockFormErrors[AGENT_JAR_STOCK_ARRAY_FIELD_NAME];
    if (stock) {
      const hasErr = stock.filter(row => row && Object.values(row).find(v => v !== undefined)).length > 0;
      setJarStockRowsHaveErrors(hasErr);
    }
  };
  useEffect(_watchJarStockErrors, [jarStockFormErrors]);

  const _initializeJarStockForm = () => {
    if (jarSpent.length && !isInitialized) {
      setIsInitialized(true);
      setJarStock((prev: JarStock[]) => {
        return prev.map((item: JarStock) => ({...item, CanBeDeleted: !jarSpent.includes(item.Id) }));
      });
      initJarStockForm({ [AGENT_JAR_STOCK_EDIT_FORM_NAME]: jarStock, agentId });
    }
  };
  useEffect(_initializeJarStockForm, [jarSpent]);

  const _updateJarStockAndAliasesAfterNewAgentCreated = () => {
    if (agentCreated && newAgentId) {
      requestBatchAliases();
      requestBatchJarStock();
      loadAgent(newAgentId);
      history.push(`/agents/${newAgentId}`);
    }
  };
  useEffect(_updateJarStockAndAliasesAfterNewAgentCreated, [newAgentId, agentCreated]);
  useEffect(() => {
    if (jarStockCreated && !agentIsNew) {
      loadJarStock(agentId);
    }
  }, [jarStockCreated]);
  useEffect(() => {
    if (aliasesCreated && !agentIsNew) {
      loadAliases(agentId, {});
    }
  }, [aliasesCreated]);

  const _redirectToList = () => {
    if (redirectToList) history.push('/agents/list');
  };
  useEffect(_redirectToList, [redirectToList]);

  useEffect(() => {
    setLoader(pending);
  }, [pending]);

  const jarStockHeaderCount = () => {
    const stock = agentJarStockFormValues[AGENT_JAR_STOCK_ARRAY_FIELD_NAME];
    if (stock && Array.isArray(stock)) {
      return stock.length;
    } else if (jarStock && Array.isArray(jarStock)) {
      return jarStock.length;
    }
    return 0;
  };

  const isSaveDisabled = useCallback(() => {
    return editFormIsInvalid || jarStockRowsHaveErrors || !details?.Compound?.Thumbnail;
  }, [editFormIsInvalid, jarStockRowsHaveErrors, details?.Compound?.Thumbnail]);

  const cancelHandler = () => {
    setRedirectToList(true);
  };

  const deleteAgentHandler = () => {
    if (!agentIsNew) {
      deleteAgent(agentId);
      toggleDeleteConfirmDialog();
      setRedirectToList(true);
    }
  };

  const requestBatchJarStock = () => {
    const stock = agentJarStockFormValues[AGENT_JAR_STOCK_ARRAY_FIELD_NAME];
    if (!stock) return;
    const stockData = stock.map(formRow => {
      const filteredFormRow = Object.entries(formRow)
        .filter(entry => (entry[1] !== null && entry[1] !== undefined && entry[1] !== '') ? entry : false)
        .reduce((acc, [key, val]) => Object.assign(acc, { [key]: val }), {});
      return { ...filteredFormRow, AgentId: !agentIsNew ? agentId : newAgentId };
    });
    const deletedStockRows = jarStock
      .filter((jar) => !stockData.find(row => {
        return Number.isInteger(row.Id) && row.Id === jar.Id;
      }))
      .map(row => ({ ...row, IsDeleted: true }));
    const _requestedStockData = [...stockData, ...deletedStockRows];
    if (_requestedStockData.length) {
      updateJarStock(_requestedStockData);
    }
  };

  const requestBatchAliases = () => {
    const { Aliases } = agentEditFormValues;
    if (Aliases) {
      const deletedAliases = Aliases.deletedValues;
      const newAliases = Aliases.newValues && Aliases.newValues.map(alias => ({
        Name: alias.label,
        ...( !agentIsNew ? { AgentId: agentId } : { AgentId: newAgentId } ),
      }));
      if (deletedAliases && Array.isArray(deletedAliases) && deletedAliases.length) {
        deleteAgentAliases(deletedAliases);
      }
      if (newAliases && Array.isArray(newAliases) && newAliases.length) {
        addAgentAliases(newAliases);
      }
    }
  };

  const saveHandler = () => {    
    const agentData: IAgent = { ...details.Compound, Compound: details.Compound, ...agentEditFormValues };
    if ('Aliases' in agentData) {
      delete agentData.Aliases;
    }
    if ('Tags' in agentData) {
      delete agentData.Tags.deletedValues;
      delete agentData.Tags.newValues;  
      let tags = Object.values(agentData.Tags);      
      agentData.Tags = tags && tags.map(tag => ({
        Name: tag.label,
        ...( !agentIsNew ? { AgentId: agentId } : { AgentId: null } ),
      }));
    }
    
    if (!agentIsNew) {
      updateAgent(agentData);
      requestBatchJarStock();
      requestBatchAliases();      
      setNeedReload(true);
    } else {
      createAgent(agentData);
    }
  };

  const _reloadItems = () => {
    if(needReload && !pending) {
      setNeedReload(false);
      loadJarStock(agentId);
      loadAliases(agentId, {});
    }
  };
  useEffect(_reloadItems, [pending, needReload]);

  const navigateAnalytics = (e, jarStockId) => {
    if (isSaveDisabled()) {
      if (e) e.preventDefault();
      throwError('Вещество невозможно сохранить. Проверьте обязательные поля.');
    } else {
      const agent = currentAgent?.details?.Compound?.MolecularFormula;
      const jar = jarStock.find(x => x.Id === jarStockId)?.Number;

      saveHandler();
      history.push({
        pathname: `/agents/analytics/${jarStockId}`,
        search: `?agent=${agent}&jar=${jar}`,
        state: {agent, jar},
      });
    }
  };

  const toggleSpendingsDialog = (id) => {
    setAgentAvailabilityId(id || null);
    setSpendingsDialogOpened(prev => !prev);
  };

  const toggleDeleteConfirmDialog = () => {
    setConfirmDeleteAgentDialogOpened(prev => !prev);
  };

  const toggleRemoveStockJarDialog = (rowIdx) => {
    setConfirmDeleteJarDialogOpened(prev => !prev);
    if (rowIdx !== null) {
      setJarStockIdxToRemove(rowIdx);
    }
  };

  const removeJarStockRowAfterConfirmation = () => {
    if (jarStockIdxToRemove !== null) {
      removeJarStockRow(jarStockIdxToRemove);
      setJarStockIdxToRemove(null);
    }
    toggleRemoveStockJarDialog();
  };

  const renderJarStockHeader = () =>
    <JarStockHeader
      count={jarStockHeaderCount()}
      handleAddJarClick={addNewJarStockRow}
      hideEmptyJars={hideEmptyJars}
      setHideEmptyJars={setHideEmptyJars}
    />;

  const renderDeleteConfirmDialogMessage = () => (
    <span style={{lineHeight: 1.5}}>Вы уверены, что хотите удалить вещество<br /><strong>{details.Name}</strong>?</span>
  );

  return (
          <>
            <StyledLayoutHeader as={LayoutHeader}>
              <h2>{agentIsNew ? 'Создание вещества' : 'Редактирование вещества'}</h2>
              <div className={'buttons-holder'}>
                {
                  (!agentIsNew && agentEditFormValues.CanBeDeleted && userPermissions?.agent.DELETE) &&
                  <Button text={'Удалить'}
                          size={'L'}
                          onAction={toggleDeleteConfirmDialog}
                  />
                }
                <Button text={'Отмена'}
                        size={'L'}
                        onAction={cancelHandler}
                />
                {
                  (userPermissions?.agent.CREATE || userPermissions?.agent.UPDATE) &&
                  <Button text={'Сохранить'}
                          size={'L'}
                          onAction={() => {
                            if (details?.Compound?.IsAntibody || details?.Compound?.IsPolymer) {
                              saveHandler();
                            } else if (marvinCanonicalize?.current && typeof marvinCanonicalize?.current === 'function') {
                              marvinCanonicalize.current(details?.Compound?.SmilesFormula);
                            }
                          }}
                          view={'primary'}
                          disabled={isSaveDisabled()}
                  />
                }
              </div>
            </StyledLayoutHeader>

            <LayoutBlock style={{'--LayoutBlock-padding-v': 0}}>
              <EditForm
                agent={details || initialEditFormValues}
                initialValues={details || initialEditFormValues}
                onMarvinSketchChange={(result) => dispatchMarvinChange({result})}
                onMarvinSketchInit={(_, canonicalize) => {
                  marvinCanonicalize.current = canonicalize;
                }}
                onCanonicalizerResponse={saveHandler}
              />
            </LayoutBlock>

            <LayoutBlock header={renderJarStockHeader()}>
              <JarStockForm
                labsList={labsList}
                initialValues={{ [AGENT_JAR_STOCK_ARRAY_FIELD_NAME]: jarStock, agentId }}
                openSpendingsDialog={toggleSpendingsDialog}
                openRemoveJarStockRowDialog={toggleRemoveStockJarDialog}
                onAnalyticsClick={navigateAnalytics}
                hideEmptyJars={hideEmptyJars}
                onCopyClick={copyJarStockRow}
              />
            </LayoutBlock>

            {
              spendingsDialogOpened &&
              <JarStockSpendingsDialog
                Id={agentAvailabilityId}
                handleClose={toggleSpendingsDialog}
              />
            }
            {
              confirmDeleteAgentDialogOpened &&
                <ConfirmDialog
                  title={'Удалить вещество'}
                  message={renderDeleteConfirmDialogMessage()}
                  confirmLabel={'Удалить'}
                  declineLabel={'Отмена'}
                  confirmAction={deleteAgentHandler}
                  declineAction={toggleDeleteConfirmDialog}
                />
            }
            {
              confirmDeleteJarDialogOpened &&
                <ConfirmDialog
                  title={'Удалить банку'}
                  message={'Вы уверены, что хотите удалить со склада банку?'}
                  confirmLabel={'Удалить'}
                  declineLabel={'Отмена'}
                  confirmAction={removeJarStockRowAfterConfirmation}
                  declineAction={toggleRemoveStockJarDialog}
                />
            }
          </>
  );
};

const mapStateToProps = (state) => {
  const { agents, jarStock, aliases } = state.resource;
  const { labs } = state.resource.dictionary;
  const agent = state && state.modules && state.modules.agent;
  return {
    currentAgent            : agent?.currentAgent,
    jarSpent                : agent?.currentAgent.jarSpent,
    jarStock,
    labsList                : itemsToArraySelector(labs),
    pending                 : agents.pending || jarStock.pending || aliases.pending,
    failure                 : agents.fail || jarStock.fail || aliases.fail,
    agentEditFormValues     : getFormValues(AGENT_EDIT_FORM_NAME)(state),
    agentJarStockFormValues : getFormValues(AGENT_JAR_STOCK_EDIT_FORM_NAME)(state),
    editFormIsInvalid       : isInvalid(AGENT_EDIT_FORM_NAME)(state),
    jarStockFormErrors      : getFormSyncErrors(AGENT_JAR_STOCK_EDIT_FORM_NAME)(state),
    newAgentId              : agent?.currentAgent.newAgentResponse.newAgentId,
    agentCreated            : agent?.currentAgent.newAgentResponse.agentCreated,
    aliasesCreated          : agent?.currentAgent.newAgentResponse.aliasesCreated,
    jarStockCreated         : agent?.currentAgent.newAgentResponse.jarStockCreated,
    userPermissions         : userCRUDPermissionsSelector(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadAgent           : (agentId, params) => dispatch(agentResourceActions.get.request({params: { agentId, ...params } })),
    loadAliases         : (agentId, params) => dispatch(aliasesResourceActions.get.request({params: {agentId, ...params}})),
    loadJarStock        : (Ids = null) => dispatch(jarStockResourceActions.list.request({params: {Ids}})),
    destroyCurrent      : () => dispatch(agentModuleActions.destroyCurrent()),
    destroyData         : () => [jarStockResourceActions, agentResourceActions, aliasesResourceActions].forEach(i => dispatch(i.destroy())),
    dispatchMarvinChange: (compound) => dispatch(agentModuleActions.compoundFromMarvin(compound)),
    addNewJarStockRow   : () => dispatch(arrayPush(AGENT_JAR_STOCK_EDIT_FORM_NAME, AGENT_JAR_STOCK_ARRAY_FIELD_NAME, new JarStock)),
    copyJarStockRow     : (jar) => dispatch(arrayPush(AGENT_JAR_STOCK_EDIT_FORM_NAME, AGENT_JAR_STOCK_ARRAY_FIELD_NAME, copyJar(jar))),
    removeJarStockRow   : (idx) => dispatch(arrayRemove(AGENT_JAR_STOCK_EDIT_FORM_NAME, AGENT_JAR_STOCK_ARRAY_FIELD_NAME, idx)),
    updateJarComment    : (field, value) => dispatch(change(AGENT_JAR_STOCK_EDIT_FORM_NAME, AGENT_JAR_STOCK_ARRAY_FIELD_NAME, value)),
    initJarStockForm    : (data) => dispatch(initialize(AGENT_JAR_STOCK_EDIT_FORM_NAME, data, true)),
    updateAgent         : (data) => dispatch(agentResourceActions.update.request({data})),
    createAgent         : (data) => dispatch(agentResourceActions.create.request({data})),
    deleteAgent         : (agentId, params) => dispatch(agentResourceActions.delete.request({ params: { agentId, ...params } })),
    updateJarStock      : (data) => dispatch(jarStockResourceActions.update.request({data})),
    addAgentAliases     : (data) => dispatch(aliasesResourceActions.create.request({data})),
    deleteAgentAliases  : (data) => dispatch(aliasesResourceActions.delete.request({data})),
    setLoader: (isLoading) => dispatch(setGlobalLoader({ isLoading })),
    throwError: (error) => dispatch(globalErrorsActions.set({error})),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
