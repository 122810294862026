// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dialog-m__dialogLayout___xp0y3 {\n  width: 800px;\n  height: 520px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogLayout": "dialog-m__dialogLayout___xp0y3"
};
export default ___CSS_LOADER_EXPORT___;
