// @flow

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Field,
  reduxForm,
} from 'redux-form';
import {
  MultiValueInput,
  BcdInputWrapper,
  FormFieldLayout,
  BcdCheckboxWrapper,
  MolecularFormulaFormatter,
  MarvinSketch, BcdDropdownWrapper, 
} from '../../../../shared/components';
import { EditFormLayout } from './style';
import { RegRulesEnum } from '../../../../shared/globals/regrules.enum';
import { FieldValidators } from '../../../../shared/validators/field-validators';
import { ResizableAreaFieldWrapper } from '../../../../shared/components/ResizableArea/ResizableAreaFieldWrapper';
import moment from 'moment';
import { userCRUDPermissionsSelector } from '../../../../account/reducers/users-modules-reducer';
import type { MarvinJsSketch } from '../../../../shared/services/compound/marvinjs-util';
import { physicalStatEnum, physicalStatTitlesEnum } from '../../../enums/physical-state.enum';
import Button from '@biocad/bcd-front-ui/controls/Button';
import {
  faFileUpload,
} from '@fortawesome/free-solid-svg-icons';
import { MSDFile } from '../MSDFile/MSDFile'

export const AGENT_EDIT_FORM_NAME = 'agentEditForm';

let EditForm = ({
  agent,
  onMarvinSketchChange,
  onMarvinSketchInit,
  initialValues,
  initialize,
  onCanonicalizerResponse,
  userPermissions,
}) => {
  const [sketchInstance, setSketchInstance] = useState(null);
  const [tags, setTags] = useState(null);
  const isComplexMolecule = agent?.Compound?.IsPolymer || agent?.Compound?.IsAntibody;

  useEffect(() => {
    initialize({ ...agent });
  }, [agent]);

  useEffect(() => {
    if (sketchInstance && sketchInstance.isEmpty() && !isComplexMolecule) {
      sketchInstance.importStructure('mrv', initialValues.Compound?.Formula);
    }
  }, [initialValues.Compound?.Formula]);

  const onMarvinInit = (sketch: MarvinJsSketch, canonicalize: typeof Function) => {
    if (!sketchInstance) setSketchInstance(sketch);
    onMarvinSketchInit(null, canonicalize);
  };

  const physicalStatesOptions = [
    {
      label: physicalStatTitlesEnum[physicalStatEnum.substancePowder],
      value: physicalStatEnum.substancePowder,
    },
    {
      label: physicalStatTitlesEnum[physicalStatEnum.substanceSolution],
      value: physicalStatEnum.substanceSolution,
    },
    {
      label: physicalStatTitlesEnum[physicalStatEnum.powder],
      value: physicalStatEnum.powder,
    }
  ];

  const hiddenFileInput = React.useRef(null);

  const handleUpload = (event) => {
    const input = event.target;

    if (input.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(input.files[0]);
      reader.onload = () => {
        // agent.Compound.Thumbnail = reader.result;
        onMarvinSketchChange({ Thumbnail: reader.result, IsAntibody: agent.Compound.IsAntibody, IsPolymer: agent.Compound.IsPolymer });
      };
    }

    event.preventDefault();
  };

  const catalizatorChanged = (e) => {
    if(e.target.checked) {
      let el = document.getElementsByName("Cas")[0];
      if(el.value == '') {
        el.focus();                  
        el.blur();
      }
    }
  }

  return (
    <EditFormLayout onSubmit={(event) => event.preventDefault()}>
      {
        !isComplexMolecule &&
        <div className={'agent-marvin'}>
          <MarvinSketch
            handleChange={onMarvinSketchChange}
            initialMrv={initialValues.Compound && initialValues.Compound.Formula}
            onSketchInit={onMarvinInit}
            useCanonicalizer={onCanonicalizerResponse}
          />
        </div>
      }
      {
        isComplexMolecule &&
        <div className={'agent-image-upload'}>
          <div className={'button'}>
            <Button
              text={'Загрузить изображение молекулы'}
              onAction={() => hiddenFileInput.current.click()}
              size={'L'}
              icon={faFileUpload}
              className={agent?.Compound?.Thumbnail ? '' : 'invalid'}
            />
            <input type='file'
              onChange={(event) => handleUpload(event)}
              accept="image/png,image/jpeg,image/svg+xml,image/gif"
              ref={hiddenFileInput}
            />
          </div>
          <div className={'image'}>
            {
              agent?.Compound?.Thumbnail && <img src={agent.Compound.Thumbnail} />
            }
          </div>
        </div>
      }
      <aside className={'agent-aside'}>
        {
          !isComplexMolecule &&
          <FormFieldLayout>
            <MolecularFormulaFormatter
              className={'h2'}
              formulaStr={agent && agent.Compound && agent.Compound.MolecularFormula || '-'}
            />
            <div className={'agent-Name'}>{agent.Name}</div>
          </FormFieldLayout>
        }
        {
          isComplexMolecule && <>
            <div className={'compound-type'}>
              {agent.Compound.IsPolymer && <>Полимер</>}
              {agent.Compound.IsAntibody && <>Антитело</>}
            </div>
            <Field
              name={'Name'}
              component={BcdInputWrapper}
              placeholder={'Введите наименование молекулы'}
              label={'Наименование'}
              useFormFieldLayout={true}
              markPristineValidity={true}
              validate={[FieldValidators.required]}
              disabled={!(userPermissions.agent.UPDATE || userPermissions.agent.CREATE)}
            />
          </>
        }
        <FormFieldLayout label={'Доп. наименования'}>
          <Field
            name={'Aliases'}
            component={MultiValueInput}
            placeholder={'Введите доп. наимен.'}
            disabled={!(userPermissions.agentAlias.UPDATE || userPermissions.agentAlias.CREATE)}
          />
        </FormFieldLayout>
        <Field
          name={'ShortName'}
          component={BcdInputWrapper}
          useFormFieldLayout={true}
          placeholder={'Введите сокр. обозначение'}
          label={'Сокращенное обозначение'}
          disabled={!(userPermissions.agent.UPDATE || userPermissions.agent.CREATE)}
        />
        <Field
          name={'PhysicalState'}
          component={BcdDropdownWrapper}
          options={physicalStatesOptions}
          label={'Физическое состояние'}
          format={(value) => physicalStatTitlesEnum[value]}
          placeholder={<span style={{ color: 'var(--grey-300)' }}>Выберите физическое состояние</span>}
          useFormFieldLayout={true}
          disabled={!(userPermissions.agent.UPDATE || userPermissions.agent.CREATE)}
        />
        <FormFieldLayout label={'Комментарий'}>
          <Field
            name={'QuickComment'}
            component={ResizableAreaFieldWrapper}
            placeholder={'Введите комментарий'}
            rows={1}
            maxRows={4}
            disabled={!(userPermissions.agent.UPDATE || userPermissions.agent.CREATE)}
          />
        </FormFieldLayout>
        <Field
          name={'Cas'}
          component={BcdInputWrapper}
          useFormFieldLayout={true}
          label={'CAS-номер'}
          validate={[]}          
          hint={'Введите CAS-номера через пробел'}
          disabled={!(userPermissions.agent.UPDATE || userPermissions.agent.CREATE)}
        />
        <Field
          name={'MolarWeight'}
          component={BcdInputWrapper}
          useFormFieldLayout={true}
          label={'MW, г/моль'}
          wrapperStyle={{ '--FormFieldLayout-margin-b': 'var(--grid-spacer)' }}
          type={'number'}
          markPristineValidity={isComplexMolecule}
          validate={[FieldValidators.required]}
          disabled={!(userPermissions.agent.UPDATE || userPermissions.agent.CREATE)}
        />
        <FormFieldLayout>
          <Field
            name={'IsIgnoringSpending'}
            component={BcdCheckboxWrapper}
            label={'Неснижаемый остаток'}
            large={true}
            disabled={!(userPermissions.agent.UPDATE || userPermissions.agent.CREATE)}
          />
        </FormFieldLayout>
        <FormFieldLayout>
          <Field
            name={'IsPrecursor'}
            component={BcdCheckboxWrapper}
            label={'Прекурсор'}
            large={true}
            disabled={!(userPermissions.agent.UPDATE || userPermissions.agent.CREATE)}
          />
        </FormFieldLayout>
        <FormFieldLayout>
          <Field
            name={'IsCatalizator'}
            component={BcdCheckboxWrapper}
            label={'Катализатор'}
            validate={[]}
            onChange={catalizatorChanged}
            large={true}
            disabled={!(userPermissions.agent.UPDATE || userPermissions.agent.CREATE)}
          />
        </FormFieldLayout>
        <FormFieldLayout label={'Теги'}>
          <Field
            name={'Tags'}
            component={MultiValueInput}
            placeholder={'Введите тэг'}
            onItemsChanged={(items)=>{setTags(items)}}
            disabled={!(userPermissions.agentAlias.UPDATE || userPermissions.agentAlias.CREATE)}
          />
        </FormFieldLayout>
        {
          agent?.Compound?.IsAntibody &&
          <Field
            name={'AminoAcidSequence'}
            component={BcdInputWrapper}
            placeholder={'Введите аминокислотную последовательность'}
            label={'Аминокислотная последовательность'}
            useFormFieldLayout={true}
            disabled={!(userPermissions.agent.UPDATE || userPermissions.agent.CREATE)}
          />
        }
        
        <MSDFile />
        
        {
          agent.CreationDate &&
          <FormFieldLayout label={'Дата создания:'}
            style={{ '--FormFieldLayout-label-margin-b': 'var(--grid-spacer)' }}>
            {moment(agent.CreationDate).format('DD.M.YYYY HH:mm')}
          </FormFieldLayout>
        }
      </aside>
    </EditFormLayout>
  );
};

const validate = values => {
  //if (!values || Object.keys(values).length === 0) return {};
  const errors = {
    Aliases: '',
    ShortName: '',
    Cas: '',
  };

  if (values.ShortName && !RegRulesEnum.SHORT_NAME_RE.test(values.ShortName)) {
    errors.ShortName = 'Некорректное сокращенное обозначение';
  }
  if(values.IsCatalizator && (values.Cas == null || values.Cas.trim() == '')) {
    errors.Cas = 'Должен быть непустым для катализатора';
  }
  if (values.Cas) {
    for (let item of values.Cas.trim().split(' ')) {
      if (!RegRulesEnum.CAS_ITEM_RE.test(item.trim())) {
        errors.Cas = 'Формат номера: 9999999-99-9';
      }
    }
  }
  return errors;
};

EditForm = reduxForm({
  form: AGENT_EDIT_FORM_NAME,
  enableReinitialize: true,
  destroyOnUnmount: false, // Важно сохранять состояние формы, так как при сохранении компонент удаляется, но состояние требуется
  keepDirtyOnReinitialize: true,
  validate,
})(EditForm);

EditForm = connect(
  (state) => ({
    userPermissions: userCRUDPermissionsSelector(state),
  }),
  null
)(EditForm);

export {
  EditForm,
};
