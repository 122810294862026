// @flow

import React, { useState, useEffect } from 'react';
import Button from '@biocad/bcd-front-ui/controls/Button';
import Dialog from '@biocad/bcd-front-ui/layout/Dialog';
import {STAGE_INDICATORS_ARRAY_NAME, STAGE_INDICATORS_FORM_NAME, StageIndicatorForm} from './StageIndicatorsForm';
import { connect } from 'react-redux';
import type {IRootStore} from '../../../app/reducers';
import {Dispatch} from 'redux';
import {stageIndicatorResourceActions, stageIndicatorsModuleActions} from '../../actions';
import styled from 'styled-components';
import {destroy, hasSubmitSucceeded, initialize, isValid, submit, isSubmitting, arrayPush} from 'redux-form';
import {currentStageIndicatorsInitialFormValues} from '../../../stages/reducers';
import Loader from '@biocad/bcd-front-ui/controls/Loader';
import type {IStageIndicator} from '../../models';
import {stageIndicatorsEditAccessSelector} from '../../reducers/selectors'
import type {IObtainmentStage} from "../../../stages/models";


export type StageIndicatorsListProps = {
    stageId: number,
    stage: IObtainmentStage,
    className?: string,
    requestStageIndicatorsData?: (stageId: number)=>null,
    clearState?: ()=>null,
    formIsValid?: boolean,
    initialFormValues?: {},
    initializeForm?: (val: {}) => null,
    pending?: boolean,
    submitForm?: ()=>null,
    submitSucceeded?: boolean,
    currentCanBeModifiedOrDeleted?: boolean,
    isSubmitting?: boolean,
    addIndicator?: (value: IStageIndicator) => void,
    stageIndicatorsRequestSucceed?: boolean,
    sortByOrderPriority?: () => void,
    canSave?: boolean,
}

let StageIndicatorsList = (props: StageIndicatorsListProps) => {
    // State
    const
        [showDialog, setShowDialog] = useState(false)
    ;

    // Effects
    useEffect(() => {
        if (props.stageId && showDialog)
        {
            props.requestStageIndicatorsData(props.stageId);
        }
    }, [props.stageId, showDialog]);

    useEffect(() => () => {
        props.clearState();
    }, []);

    useEffect(() => {
        if (props.pending === false && showDialog === true && props.stageIndicatorsRequestSucceed)
        {
            props.initializeForm(props.initialFormValues);
            props.sortByOrderPriority();
        }
    }, [props.pending, showDialog, props.stageIndicatorsRequestSucceed]);

    useEffect(() => {
        if (props.isSubmitting)
        {
            return;
        }
        props.clearState();
        closeDialog();
    }, [props.submitSucceeded, props.isSubmitting]);

    // Methods
    const
        closeDialog = () =>
        {
            setShowDialog(false);
        }
    ;

    return (
        <div className={`stageIndicatorsList-wrap ${props.className}`}>
            <Button
                text={'Параметры стадии'}
                size={'L'}
                onAction={() => setShowDialog(f => !f)}
            />
            {
                showDialog &&
                <Dialog paranjaClose close={closeDialog}>
                    <div className={'dialog-layout dialog-layout-size-md'}>
                        <header className={'dialog-header'}>
                            <h3>Параметры стадии</h3>
                        </header>
                        <div className={'dialog-contents'}>
                            {
                                (props.pending || props.isSubmitting)
                                && <Loader />
                            }
                            {
                                (props.stageIndicatorsRequestSucceed === true && props.pending === false)
                                && <StageIndicatorForm
                                    stageId={props.stageId}
                                    requiredPurificationsCount={props.stage.RequiredPurificationsCount} />
                            }
                        </div>
                        <div className={'dialog-footer'}>
                            <Button
                                onAction={closeDialog}
                                text="Отмена"
                                size="L"
                            />
                            {
                                props.canSave &&
                                <Button
                                    className={'submitBtn'}
                                    onAction={(props.stageId) ? props.submitForm : closeDialog}
                                    text="Сохранить"
                                    view="primary"
                                    size="L"
                                    disabled={!props.formIsValid}
                                />
                            }
                        </div>
                    </div>
                </Dialog>
            }
        </div>
    );
};

StageIndicatorsList = connect(
    (state: IRootStore): StageIndicatorsListProps => ({
        formIsValid: isValid(STAGE_INDICATORS_FORM_NAME)(state),
        initialFormValues: currentStageIndicatorsInitialFormValues(state),
        pending: (
            state.resource.stageIndicators.pending
        ),
        submitSucceeded: hasSubmitSucceeded(STAGE_INDICATORS_FORM_NAME)(state),
        currentCanBeModifiedOrDeleted: state.modules.stage.editStage?.current?.CanBeModifiedOrDeleted,
        isSubmitting: isSubmitting(STAGE_INDICATORS_FORM_NAME)(state),
        stageIndicatorsRequestSucceed: (
            state.resource.stageIndicators.requestSucceed
        ),
        canSave: stageIndicatorsEditAccessSelector(state),
    }),
    (dispatch: Dispatch): StageIndicatorsListProps => ({
        requestStageIndicatorsData: (stageId: number) => dispatch(stageIndicatorsModuleActions.loadStageIndicatorData({ stageId })),
        clearState: () => [
            stageIndicatorResourceActions.destroy,
            ()=>destroy(STAGE_INDICATORS_FORM_NAME),
        ].forEach((fn) => dispatch(fn())),
        initializeForm: (data) => dispatch(initialize(STAGE_INDICATORS_FORM_NAME, data, true)),
        submitForm: () => dispatch(submit(STAGE_INDICATORS_FORM_NAME)),
        addIndicator: (value) => arrayPush(STAGE_INDICATORS_FORM_NAME, STAGE_INDICATORS_ARRAY_NAME, value),
        sortByOrderPriority: () => dispatch(stageIndicatorsModuleActions.sortByOrderPriority({}))
    })
)(StageIndicatorsList);

StageIndicatorsList = styled(StageIndicatorsList)`
  .stageIndicatorsList-wrap {
    display: contents;
  }
`;

export {
    StageIndicatorsList
};
