// @flow

import {
    put,
    takeEvery,
    fork,
    select,
} from 'redux-saga/effects';
import {getToken} from '../../account/reducers';
import type {IRequestResourceAction} from '../../shared/utils/action-creators';
import {reagentsResourceActions} from '../actions';
import {ReagentsResourceService} from '../services';
import {ReactionsResourceService} from '../../reactions/services';

function* getReagent({uriParams}: IRequestResourceAction) {
    const token = yield select(getToken);
    try {
        const result = yield ReagentsResourceService.get({uriParams}, token);
        yield put(reagentsResourceActions.get.success(result));
    } catch (error) {
        yield put(reagentsResourceActions.get.failure({error}));
    }
}

export function* listReagents({uriParams}: IRequestResourceAction) {
    const token = yield select(getToken);
    try {
        const result = yield ReactionsResourceService.getReagents({uriParams}, token);
        yield put(reagentsResourceActions.list.success(result));
    } catch (error) {
        yield put(reagentsResourceActions.list.failure({error}));
    }
}

function* updateReagent({uriParams, data}: IRequestResourceAction) {
    const token = yield select(getToken);
    try {
        const result = yield ReagentsResourceService.update({uriParams, data}, token);
        yield put(reagentsResourceActions.update.success(result));
    } catch (error) {
        yield put(reagentsResourceActions.update.failure({error}));
    }
}

function* createReagent({data}: IRequestResourceAction) {
    const token = yield select(getToken);
    try {
        const result = yield ReagentsResourceService.create({data}, token);
        yield put(reagentsResourceActions.create.success(result));
    } catch (error) {
        yield put(reagentsResourceActions.create.failure({error}));
    }
}

function* deleteReagent({uriParams}: IRequestResourceAction) {
    const token = yield select(getToken);
    try {
        const result = yield ReagentsResourceService.delete({uriParams}, token);
        yield put(reagentsResourceActions.delete.success(result));
    } catch (error) {
        yield put(reagentsResourceActions.delete.failure({error}));
    }
}

function* editReagentComment({uriParams, data}: IRequestResourceAction) {
  const token = yield select(getToken);
    try {
        const result = yield ReagentsResourceService.editReagentComment({uriParams, data}, token);
        yield put(reagentsResourceActions.editReagentComment.success(result));
    } catch (error) {
        yield put(reagentsResourceActions.editReagentComment.failure({error}));
    }
}

export const reagentsResourceWatchers = [
    function* () {
        yield takeEvery(reagentsResourceActions.get.request.type, function* (action) {
            yield fork(getReagent, action);
        });
    },

    function* () {
        yield takeEvery(reagentsResourceActions.list.request.type, function* (action) {
            yield fork(listReagents, action);
        });
    },

    function* () {
        yield takeEvery(reagentsResourceActions.update.request.type, function* (action) {
            yield fork(updateReagent, action);
        });
    },

    function* () {
        yield takeEvery(reagentsResourceActions.create.request.type, function* (action) {
            yield fork(createReagent, action);
        });
    },

    function* () {
        yield takeEvery(reagentsResourceActions.delete.request.type, function* (action) {
            yield fork(deleteReagent, action);
        });
    },

    function* () {
        yield takeEvery(reagentsResourceActions.editReagentComment.request.type, function* (action) {
            yield fork(editReagentComment, action);
        });
    },
];
