import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {itemsToArraySelector} from '../../../../../shared/utils/selectors/resource-selectors';
import {
    candidateModuleActions, descendantsResourceActions,
    folderModuleActions,
    folderSchemesResourceActions,
    productionSchemeResourceActions
} from '../../../../../projects/actions';
import {ConfirmDialog} from '../../../../../shared/components/ConfirmDialog/ConfirmDialog';
import {folderParentType} from '../../../../../projects/types/enum';
import queryString from 'query-string';
import {withRouter} from 'react-router';
import {getPathWithDescendants} from '../../../../../shared/utils/createPathWithDescendants';
import {userCRUDPermissionsSelector} from "../../../../../account/reducers/users-modules-reducer";

const initialProps = {
    foldersList: [],
    getFoldersList: () => null,
    setCurrentFolderId: () => null,
    foldersPath: [],
    deleteFolder: () => null,
    toggleFolderModal: () => null,
    currentCandidateId: null,
    currentFolderId: null,
    clearFoldersList: () => null,
    clearSchemesList: () => null,
    getSchemesList: () => null,
    addFolderPath: () => null,
    removeFoldersPathItem: () => null,
    location: {
        search: {}
    },
    candidates: [],
    getDescendantsList: () => null,
    descendantsList: [],
    userPermissions: {},
};

const Folders = (props = initialProps) => {
    // States
    const
        [isConfirmShown, setIsConfirmShown] = useState(false),
        [folderId, setFolderId] = useState(null),
        [candidateId, setCandidateId] = useState(null)
    ;

    // Effects
    const _init = () => {
        clearList();

        let urlParams = queryString.parse(props.location.search);
        let schemeFolderId = urlParams.schemeFolderId;
        let currentCandidateId = urlParams.selectedId;

        currentCandidateId && setCandidateId(currentCandidateId);
        schemeFolderId && setFolderId(schemeFolderId);
        if(!schemeFolderId && urlParams.folderId)
            setFolderId(undefined);

        schemeFolderId ? props.getDescendantsList({
            parentId: currentCandidateId,
            parentType: folderParentType.finalCandidate
        }) : setDefaultCandidate(currentCandidateId);
    }
    useEffect(_init, []);

    const _refreshOnSave = () => {
        if (props.schemeFolderSavedSuccessful === true ) {
            if (folderId) {
                openFolder(folderId);
            }
            else {
                _init();
            }
        }
      };
      useEffect(_refreshOnSave, [props.schemeFolderSavedSuccessful]);

    useEffect(() => {
        if (folderId) {
            openFolder(folderId);
        }
    }, [folderId]);

    useEffect(() => {
        if (itemsToArraySelector(props.descendantsList).length && !props.foldersPath.length && folderId) {
            let path = getPathWithDescendants(itemsToArraySelector(props.descendantsList), folderId, candidateId);

            if (path[0]) {
                path.map(item => {
                    props.addFolderPath({
                        id: item.Id,
                        name: item.Name
                    });
                });
            }
        }
    }, [props.descendantsList]);

    const setDefaultCandidate = (currentCandidateId) => {
        if (props.candidates[0]) {
            props.getFoldersList({
                parentId: currentCandidateId ?
                    currentCandidateId : props.candidates[0].Id,
                parentType: folderParentType.finalCandidate
            });

            props.getSchemesList({
                finalCandidateId: currentCandidateId ?
                    currentCandidateId : props.candidates[0].Id,
                showOnlyTopLevelSchemes: true
            });
        }
    };

    const openFolder = (folderId, folderName) => {
        clearList();
        props.getFoldersList({
            parentId: folderId,
            parentType: folderParentType.folder
        });

        props.getSchemesList({
            folderId: folderId,
            finalCandidateId: props.currentCandidateId,
            showOnlyTopLevelSchemes: true
        });

        if (folderName) {
            props.addFolderPath({
                id: folderId,
                name: folderName
            });

            let urlParams = queryString.parse(props.location.search);
            urlParams.schemeFolderId = folderId;
            setFolderId(folderId);
            props.history.push(props.location.pathname + '?' + queryString.stringify(urlParams));
        }
    };

    const editFolder = (e) => {
        e.stopPropagation();
        props.toggleFolderModal();
        props.setCurrentFolderId({
            id: e.target.dataset.id
        });
        props.setEditMode({
            isEdit: true
        });
    };

    const deleteFolder = () => {
        props.clearFoldersList();
        props.deleteFolder({
            id: props.currentFolderId,
            parentId: props.foldersPath.length > 0 ?
                props.foldersPath[props.foldersPath.length - 1].id : props.currentCandidateId,
            parentType: props.foldersPath.length > 0 ? folderParentType.folder : folderParentType.finalCandidate
        });
        toggleConfirmModal();
    };

    const confirmDeleteFolder = (e) => {
        e.stopPropagation();
        toggleConfirmModal();
        props.setCurrentFolderId({
            id: e.target.dataset.id
        });
    };

    const toggleConfirmModal = () => {
        setIsConfirmShown(!isConfirmShown);
    };

    const goBackFromFolder = () => {
        let urlParams = queryString.parse(props.location.search);
        
        if(props.foldersPath[props.foldersPath.length - 2]) {
            urlParams.schemeFolderId = props.foldersPath[props.foldersPath.length - 2].id;
        } else {
            delete urlParams.schemeFolderId;
            setFolderId(undefined);
        }

        props.history.push(props.location.pathname + '?' + queryString.stringify(urlParams));

        clearList();

        props.getFoldersList({
            parentId: props.foldersPath.length > 1 ?
                props.foldersPath[props.foldersPath.length - 2].id : props.currentCandidateId,
            parentType: props.foldersPath.length > 1 ?
                folderParentType.folder : folderParentType.finalCandidate
        });

        const fId = props.foldersPath.length > 1 ?
        props.foldersPath[props.foldersPath.length - 2].id : null;
        props.getSchemesList({
            folderId: fId,
            finalCandidateId: props.currentCandidateId,
            showOnlyTopLevelSchemes: true
        });
        setFolderId(fId);

        props.removeFoldersPathItem();
    };

    const clearList = () => {
        props.clearSchemesList();
        props.clearFoldersList();
    };

    return (
        <React.Fragment>
            {(isConfirmShown && props.userPermissions.folder.DELETE) &&
            <ConfirmDialog
                title={'Внимание'}
                message={'Вы уверены, что хотите удалить эту папку?'}
                confirmLabel={'Да'}
                declineLabel={'Отмена'}
                confirmAction={deleteFolder}
                declineAction={toggleConfirmModal}
            />}

            <ul className="folders-list">
                {props.foldersPath.length > 0 &&
                <li className={'folder-back-btn'} onClick={goBackFromFolder}>
                    <i className={'fo-folder-back'}/>
                    ../{props.foldersPath[props.foldersPath.length - 1].name}
                </li>
                }
                {props.foldersList.map((folder, index) => {
                    return (
                        <li
                            key={index}
                            onClick={() => openFolder(folder.Id, folder.Name)}>

                            <div className="title">
                                <i className={'fo-folder'}/>
                                {folder.Name}
                            </div>

                            <div className={'folder-control-panel'}>
                                {
                                    props.userPermissions.folder.UPDATE &&
                                    <i className={'fo-edit'}
                                       onClick={editFolder}
                                       data-id={folder.Id}
                                    />
                                }
                                {
                                    props.userPermissions.folder.DELETE &&
                                    <i className={'fo-trash'}
                                       onClick={confirmDeleteFolder}
                                       data-id={folder.Id}
                                    />
                                }
                            </div>
                        </li>
                    );
                })}
            </ul>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    const {resource, modules} = state;
    return {
        candidates: itemsToArraySelector(resource.candidates),
        foldersList: itemsToArraySelector(resource.foldersSchemes),
        foldersPath: modules.folder.schemesPath,
        currentCandidateId: modules.candidate.candidate.Id,
        currentFolderId: modules.folder.id,
        descendantsList: resource.descendants,
        schemeFolderSavedSuccessful: modules._folder_temp?.selectFolder?.savedSuccessful,
        userPermissions: userCRUDPermissionsSelector(state),
    };
};

export default withRouter(connect(
    mapStateToProps,
    (dispatch) => ({
        deleteFolder: (data) => dispatch(folderSchemesResourceActions.delete.request({data})),
        setCurrentFolderId: (id) => dispatch(folderModuleActions.setCurrentFolderId(id)),
        setEditMode: (isEditMode) => dispatch(folderModuleActions.setEditMode(isEditMode)),
        clearFoldersList: () => dispatch(folderSchemesResourceActions.destroy()),
        clearSchemesList: () => dispatch(productionSchemeResourceActions.destroy()),
        getFoldersList: (params) => dispatch(folderSchemesResourceActions.list.request({params})),
        getSchemesList: (params) => dispatch(productionSchemeResourceActions.list.request({params})),
        addFolderPath: ({id, name}) => dispatch(folderModuleActions.addFolderSchemesPathItem({id, name})),
        removeFoldersPathItem: () => dispatch(folderModuleActions.removeFolderSchemesPathItem()),
        getDescendantsList: (params) => dispatch(descendantsResourceActions.list.request({params}))
    })
)(Folders));
