// @flow
import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import Agents from '../agents/components';
import Login from '../account/components/Login';
import ProjectsRoutes from '../projects/routes';
import CandidatesRoutes from '../candidates/routes';
import Reactions from '../reactions/components';
import UserDashboard from '../user-dashboard/components';
import Stage from '../stages/components';
import Scheme from '../schemes/components';
import {NoMatch} from './components/NoMatch';
import {connect} from 'react-redux';
import {ProtectedRoute} from '../shared/components/ProtectedRoute';
import {isAuthorized} from '../account/reducers';
import Analytics from '../analytics/components';
import UserRoles from '../account/components/UserRoles/UserRoles';
import EquipmentList from "../equipment/components";
import {Reload} from './components/Reload';
import JChem from '../test/components/JChem';

export const RootRouter = ({isAuthorized}: { isAuthorized: Boolean }) => {
    return (
        <Switch>
            <ProtectedRoute path={'/agents'} redirect='/login' component={Agents} isAuth={isAuthorized}/>
            <ProtectedRoute path={'/projects'} redirect='/login' component={ProjectsRoutes} isAuth={isAuthorized}/>
            <ProtectedRoute path={'/candidates'} redirect='/login' component={CandidatesRoutes} isAuth={isAuthorized}/>
            <ProtectedRoute path={'/user-dashboard/:type'} redirect='/login' component={UserDashboard}
                            isAuth={isAuthorized}/>
            <ProtectedRoute path={'/user-dashboard'} redirect='/login' component={UserDashboard} isAuth={isAuthorized}/>
            <ProtectedRoute path={'/reactions'} redirect='/login' component={Reactions} isAuth={isAuthorized}/>
            <ProtectedRoute path={'/stages'} redirect='/login' component={Stage} isAuth={isAuthorized}/>
            <ProtectedRoute path={'/analytics'} redirect='/login' component={Analytics} isAuth={isAuthorized}/>
            <ProtectedRoute path={'/schemes'} redirect='/login' component={Scheme} isAuth={isAuthorized}/>
            <ProtectedRoute path={'/user-roles'} redirect='/login' component={UserRoles} isAuth={isAuthorized}/>
            <ProtectedRoute path={'/equipment'} redirect='/login' component={EquipmentList} isAuth={isAuthorized}/>
            <ProtectedRoute path={'/test'} redirect='/login' component={JChem} isAuth={isAuthorized}/>
            <Route path={'/login'} render={() => <Login/>}/>
            <Route path={'/reload'} render={() => <Reload />} />
            <Route component={NoMatch}/>
        </Switch>
    );
};

const mapStateToProps = (state) => ({
    isAuthorized: isAuthorized(state),
});

export default withRouter(connect(
    mapStateToProps,
    null
)(RootRouter));
