// @flow
import React from 'react';
import type { ChildrenArray } from 'react';
import Dropdown from '@biocad/bcd-front-ui/controls/Dropdown';
import {
    FormFieldLayout
} from '../FormFieldLayout';
import { OptionType } from '../../models/option-type';

export type BcdDropdownProps = {
    options                 : OptionType[] | string[],
    defaultValue?           : OptionType | string,
    placeholder?            : ChildrenArray,
    disabled?               : boolean,
    valid?                  : boolean,
    expanded?               : boolean,
    fit?                    : boolean,
    className?              : string,
    useFormFieldLayout      : boolean,
    wrapperStyle?           : {},
    wrapperClassName?       : string,
    label?                  : string,
    hint?                   : string,
    required?               : boolean,
    markPristineValidity?   : boolean,
}

const defaultBcdDropdownWrapperProps: BcdDropdownProps = {
    options: [],
    useFormFieldLayout: false,
};

// Wrapper for use BCD Dropdown component with Redux Forms.
export const BcdDropdownWrapper = (props: (BcdDropdownProps & any) = defaultBcdDropdownWrapperProps) => {
    let _valid: boolean;
    if (!props.markPristineValidity) {
        _valid = (props.meta?.valid === false || props.meta?.warning) && props.meta?.touched === true ? false : undefined;
    }
    else {
        _valid = (props.meta?.valid && !props.meta?.warning) ? undefined : false
    }
    const formFieldLayoutProps = {
        style: props.wrapperStyle,
        className: props.wrapperClassName,
        invalidMessage: props.meta?.error || props.meta?.warning,
        valid: _valid,
        hint: props.hint,
        label: props.label,
        required: props.required,
    };
    const renderBcdDropdown = () => (
      <Dropdown
        options      = {props.options}
        defaultValue = {props.defaultValue}
        value        = {props.value}
        placeholder  = {props.placeholder}
        disabled     = {props.disabled}
        valid        = {_valid}
        expanded     = {props.expanded}
        fit          = {props.fit}
        className    = {props.className}
        {...props.input}
      />
    );
    return (
      props.useFormFieldLayout ? (
        <FormFieldLayout {...formFieldLayoutProps}>
            { renderBcdDropdown() }
        </FormFieldLayout>
      ) : renderBcdDropdown()
    );
};
