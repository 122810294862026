// @flow

import { resourceReducer } from '../../shared/utils/reducer-creators';
import { reactionTypesResourceActions } from '../actions';
import { createSelector } from 'reselect';
import type { IRootStore } from '../../app/reducers';
import type { IReactionType } from '../model';
import type { OptionType } from '../../shared/models';

export const reactionTypesResourceReducer = resourceReducer(reactionTypesResourceActions);

export const
	reactionTypesSelector = createSelector(
		(state: IRootStore) => state.resource.reactionTypes.data,
		(data) => Object.values(data),
	),
	reactionTypesAsOptionsSelector = createSelector(
		(state: IRootStore) => state.resource.reactionTypes.data,
		(data) => Object.values(data).filter(i => !i.IsObsolete).map((item: IReactionType): OptionType => ({ value: item.Id, label: item.Name }))
	),
	reactionTypesObsoleteAsOptionsSelector = createSelector(
		(state: IRootStore) => state.resource.reactionTypes.data,
		(data) => Object.values(data).map((item: IReactionType): OptionType => ({ value: item.Id, label: item.Name }))
	)
;
