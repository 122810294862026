// @flow
import styled from 'styled-components';

export const EquipmentSearchFormLayout = styled.div`
    .searchFields {
        display: flex;
        flex-direction: row;
    }
    .b-input {
        border-radius: 4px 0 0 4px;
    }
    .b-button {
        border-radius: 0 4px 4px 0;
    }
`;
