import * as sanitizeHtml from 'sanitize-html';

const defaultOptions = {
  allowedTags: [ 'sub', 'sup', 'b', 'strong', 'i', 'u', 'p', 'span', 'div', 'table',
                            'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'em', 'br', 'a'],
  allowedAttributes: {
    table: [ 'style', 'border' ],
    span: ['style'],
    td: ['style', 'colspan', 'rowspan'],
    tr: ['style'],
    a: ['href', 'target']
  },
}; 

const sanitize = (dirty, options) => ({
  __html: sanitizeHtml(
    dirty,
    { ...defaultOptions, ...options }
  )
});

export const SanitizeHTML = ({ html, options, className}) => (
    html
      ? <span className={className} dangerouslySetInnerHTML={sanitize(html, options)} />
      : null
);