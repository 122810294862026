// @flow

import React, {
  useState,
  useEffect,
  useRef
} from 'react';
import { _noop } from '../../../shared/utils/common';
import type { IFolder } from '../../models';
import bnc from 'bnc/index';
import Button from '@biocad/bcd-front-ui/controls/Button';
import Dialog from '@biocad/bcd-front-ui/layout/Dialog';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import type { IRootStore } from '../../../app/reducers';
import { getFolderCreateOrUpdateSuccess, getLastEditedFolder } from '../../reducers/module';
import { _temp_folderResourceAction } from '../../actions';
import propTypes from 'prop-types';
import { EditFolderForm, FOLDER_EDIT_FORM } from './EditFolderForm';

import './index.less';
import { getFormValues } from 'redux-form';
import Loader from '@biocad/bcd-front-ui/controls/Loader';


interface IEditFolderProps {
  folder: IFolder,
  lastEditedFolder?: IFolder,
  createOrUpdateSuccess?: boolean,
  onFolderEdited: (IFolder)=>null,
  updateFolder?: (IFolder)=>void,
  createFolder?: (IFolder)=>void,
  formValue?: IFolder,
  className?: string,
  buttonClassName?: string,
}

const defaultProps: IEditFolderProps = {
  folder: null,
  onFolderEdited: _noop,
  className: '',
  buttonClassName: '',
};

const
  BNCBLock = new bnc('b-EditFolder'),
  BNCDialogLayout = BNCBLock.el('dialogLayout')
;

let EditFolder = (props: IEditFolderProps = defaultProps) => {
  // Ref
  const wrapperRef = useRef<HTMLDivElement>();

  // States
  const
    [dialogOpened, setDialogOpened] = useState<boolean>(false),
    [folderIsNew, setFolderIsNew]   = useState<boolean>(true)
  ;

  // Effects
  const _updateSelfStateOnPropsFolder = () => {
    if (!props.folder) return;
    if ('Id' in props.folder && Number.isInteger(props.folder.Id)) {
      setFolderIsNew(false);
    }
  };
  useEffect(_updateSelfStateOnPropsFolder, [props.folder]);

  const _closeOnSave = () => {
    if (props.createOrUpdateSuccess !== null) {
      setDialogOpened(false);
    }
  };
  useEffect(_closeOnSave, [props.createOrUpdateSuccess]);

  const _passLastEditedFolder = () => {
    if (props.lastEditedFolder && 'Id' in props.lastEditedFolder) {
      props.onFolderEdited(props.lastEditedFolder);
    }
  };
  useEffect(_passLastEditedFolder, [props.lastEditedFolder]);

  // Handlers
  const
    toggleDialog = () => {
      setDialogOpened(prev => !prev);
    },
    saveFolder = (event: SyntheticEvent) => {
      event.preventDefault();
      if (!props.formValue) return;
      if (folderIsNew) {
        props.createFolder(props.formValue);
        return;
      }
      props.updateFolder(props.formValue);
    },
    stopPropagation = (event: SyntheticEvent) => { // Нужно так как иначе из диалога будет прокликиваться родительский элемент
      if (wrapperRef?.current.contains(event.target)) event.stopPropagation();
    }
  ;

  return (
    <div ref={wrapperRef}
         className={`${BNCBLock} ${props.className}`}
         onClick={stopPropagation}
    >
      <Button
        className={`${folderIsNew ? 'fo-add-folder' : 'fo-edit'} ${props.buttonClassName}`}
        view={'icon'}
        size={'L'}
        onAction={toggleDialog}
      />
      {
        dialogOpened &&
        <Dialog paranjaClose={false} close={toggleDialog}>
          <EditFolderForm
            className={BNCDialogLayout + 'dialog-layout'}
            saveFolder={saveFolder}
            toggleDialog={toggleDialog}
            initialValues={props.folder}
            folderIsNew={folderIsNew}
          />
        </Dialog>
      }
    </div>
  )
};

EditFolder.propTypes = {
  folder: propTypes.shape({
    Id: propTypes.number,
    Name: propTypes.string,
    IsDeleted: propTypes.bool,
    ParentId: propTypes.number,
    ParentType: propTypes.number,
  }).isRequired,
  onFolderEdited: propTypes.func.isRequired
};

EditFolder = connect(
  (state: IRootStore): IEditFolderProps => ({
    createOrUpdateSuccess: getFolderCreateOrUpdateSuccess(state),
    lastEditedFolder: getLastEditedFolder(state),
    formValue: getFormValues(FOLDER_EDIT_FORM)(state),
  }),
  (dispatch: Dispatch): IEditFolderProps => ({
    updateFolder: (data: IFolder) => dispatch(_temp_folderResourceAction.update.request({ data })),
    createFolder: (data: IFolder) => dispatch(_temp_folderResourceAction.create.request({ data })),
  })
)(EditFolder);

export {
  EditFolder,
}
