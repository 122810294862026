// @flow
import { Model, BasicModel } from 'objectmodel';
import type {IIndicatorProcessPoint} from './i-indicator-process-point';

export const IndicatorProcessPointKeyNames =
{
    Id              : 'Id',
    Name            : 'Name',
    StageId         : 'StageId',
    IsCommon        : 'IsCommon',
    IsDeleted       : 'IsDeleted',
};

export const IndicatorProcessPoint: (d: IIndicatorProcessPoint) => IIndicatorProcessPoint = Model({
    [IndicatorProcessPointKeyNames.Id]         : [Number],
    [IndicatorProcessPointKeyNames.Name]       : [String],
    [IndicatorProcessPointKeyNames.IsCommon]   : [Boolean],
    [IndicatorProcessPointKeyNames.IsDeleted]  : [Boolean],
    [IndicatorProcessPointKeyNames.StageId]    : [Number],
})
.defaultTo({
    [IndicatorProcessPointKeyNames.Id]         : 0,
    [IndicatorProcessPointKeyNames.Name]       : '',
    [IndicatorProcessPointKeyNames.IsCommon]   : false,
    [IndicatorProcessPointKeyNames.IsDeleted]  : false,
    [IndicatorProcessPointKeyNames.StageId]    : null,
});