import { Component, Fragment }  from 'react';
import { fromEvent }            from 'rxjs';
import bnc                      from 'bnc';
import Button                   from '../../controls/Button';
import { faTh }                 from '@fortawesome/free-solid-svg-icons/faTh';
import                               './CrossNav.less';



import groups from '../../../bcd-front-services/index.json';

export default class CrossNav extends Component {

    static step  = .15;

    static env   = {
        'production':  '',
        'testing':     'test',
        'stand':       'stand',
        'dev':         'dev',
        'development': 'local'
    };

    static getServiceHost = (host, env = CrossNav.env[ENV.NODE_ENV] ) =>
        env in host
            ? host[env]
            : host['prod']
    ;

    static Service = ({emoji, label, host}) =>
        <a
            href      = {`https://${ CrossNav.getServiceHost(host) }`}
            className = {CrossNav.block.el('service-link')}
            target    = "_blank"
            rel       = "noopener noreferrer"
        >
            <span className={CrossNav.block.el('service-emoji')}>{emoji}</span>
            <span className={CrossNav.block.el('service-label')}>{label}</span>
        </a>
    ;

    static block = new bnc.default('b-crossnav')

    state = { opened: false, opacity: 0 }

    componentDidMount () {
        this.subscriptions = [
            fromEvent(document, 'keyup' ).subscribe(
                this.handleEscape
            )
        ];
    }

    componentWillUnmount() {
        this.subscriptions.forEach(
            sub => sub.unsubscribe()
        );
    }

    handleEscape = ({key})    => this.state.opened && (key === 'Escape') && this.toggle()

    toggle = () => this.setState({ opened: !this.state.opened })

    renderSidebar = ({opacity} ) =>
        <div
            className = {CrossNav.block.el('sidebar')}
            style     = {{ opacity, width: opacity * 280 }}
        >
            <div>
                { this.renderButton(this.buttonOpened) }
            </div>
            {
                groups.map(
                    ({group, services}, gi) =>
                        <ul className={CrossNav.block.el('group')} key={`gi-${gi}`}>
                            <span className={CrossNav.block.el('group-label')}>{group}</span>
                            {
                                services.map(
                                    (service, si) =>
                                        <li
                                            className = {CrossNav.block.el('service')}
                                            key       = {`gi-${gi}-${si}`}
                                        >
                                            <CrossNav.Service {...service}/>
                                        </li>
                                )
                            }
                        </ul>
                )
            }
        </div>
    ;

    buttonOpened = React.createRef()

    buttonClosed = React.createRef()

    componentDidUpdate (prevProps, prevState) {
        var {opacity, opened} = this.state;
        opened
            ?   opacity < 1 &&
                requestAnimationFrame(
                    ()=>this.setState({opacity:Math.min(1, opacity + CrossNav.step)})
                )
            :   opacity > 0 &&
                requestAnimationFrame(
                    ()=>this.setState({opacity:Math.max(0, opacity - CrossNav.step)})
                )
            ;

        setTimeout(
            () => {
                prevState.opened !== opened
                    ?   opened
                        ? this.buttonOpened.current && this.buttonOpened.current.button.current && this.buttonOpened.current.button.current.focus()
                        : this.buttonClosed.current && this.buttonClosed.current.button.current && this.buttonClosed.current.button.current.focus()
                    : void(0)
                ;
            },
            10
        );

    }

    renderFragment = opacity =>
        ReactDom.createPortal(
            <Fragment>
                <div style={{opacity}} className={CrossNav.block.el('overlay')} onClick={this.toggle}/>
                {this.renderSidebar(this.state)}
            </Fragment>,
            document.body
        )

    renderButton = ref =>
        <Button
            ref       = {ref}
            className = {CrossNav.block.el('btn')}
            icon      = {faTh}
            size      = "L"
            onAction  = {this.toggle}
        />

    renderStart = ({opacity}) =>
        <div className={CrossNav.block}>
            { this.renderButton( this.buttonClosed ) }
            { opacity > 0 && this.renderFragment(opacity) }
        </div>

    render = () => this.renderStart( this.state )

}

CrossNav.Service.displayName = 'CrossNav.Service';