// @flow

import { resourceActions } from '../../shared/utils/action-creators';
import {INDICATORS_MODULE_NAME, INDICATOR_UNITS_ROOT_NAME, INDICATOR_PROCESS_POINTS_MODULE_NAME} from './module';

export const indicatorsResourceActions = {
    ...resourceActions(INDICATORS_MODULE_NAME),
};

export const indicatorUnitsResourceActions = {
    ...resourceActions(INDICATOR_UNITS_ROOT_NAME),
};

export const indicatorProcessPointsResourceActions = {
    ...resourceActions(INDICATOR_PROCESS_POINTS_MODULE_NAME)
}