// @flow
import React from 'react';

import { IReaction } from '../../../user-dashboard/models';
import './reaction-type-icon.less';
import { reactionTitles } from './reactionTitlesMap';
  
export const ReactionTypeIcon = ({reaction, className}: {reaction: IReaction, className?: string}) => {
  const conditions = [
    { 'success'       : reaction.IsCompleted && !reaction.IsTrial && reaction.ExperimentalYield > 0 },
    { 'uncompleted'   : !reaction.IsCompleted && !reaction.IsTrial },
    { 'fail'          : reaction.IsCompleted && !reaction.IsTrial && !reaction.ExperimentalYield },
    { 'trial-success' : reaction.IsCompleted && reaction.IsTrial && reaction.IsSuccessful === true },
    { 'trial'         : (!reaction.IsCompleted && reaction.IsTrial)
                        ||  (reaction.IsTrial && reaction.IsSuccessful === null) },
    { 'trial-fail'    : reaction.IsCompleted && reaction.IsTrial && reaction.IsSuccessful === false },
    { 'default'       : true },
  ];
  const found = conditions.find(c => Object.values(c)[0]);
  
  const type = found && String(Object.keys(found)[0]);
  const title = reactionTitles[type];
  return(
    <i
      className={`typeIcon typeIcon__${type} ${className || ''}`}
      title={title} />
  );
};
