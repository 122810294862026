import bnc                 from 'bnc';
import { Component }       from 'react';
import propTypes           from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import                          './index.less';

export default class Button extends Component {

    static propTypes = {
        onHover:    propTypes.func,
        onAction:   propTypes.func,
        onFocus:    propTypes.func,
        onBlur:     propTypes.func,
        onKeyDown:  propTypes.func,
        disabled:   propTypes.bool,
        pressed:    propTypes.bool,
        mods:       propTypes.array,
        text:       propTypes.string,
        view:       propTypes.string,
        size:       propTypes.string,
        icon:       propTypes.object,
        type:       propTypes.oneOf(['submit', 'reset', 'button', 'menu']),
        className:  propTypes.oneOfType([propTypes.string, propTypes.instanceOf(bnc)]),
    };

    static defaultProps = {
        type: 'button',
        className:  '',
    };

    static IconTextGap = () =>
        <div className={Button.block.el('icontextgap')}/>
    ;

    static Gap = () =>
        <div className={Button.block.el('gap')}/>
    ;

    static View = (view, text, icon) => (
        (icon && !text)
            ? 'icon'
            : view
                ? view
                : 'secondary'
    );

    static block = new bnc.default('b-button')

    button = React.createRef()

    listeners = ({onHover, onAction, onFocus, onBlur, onKeyDown, onMouseEnter, onMouseLeave, disabled = false}) => {
        var listeners = {};

        if (onHover) {
            listeners.onMouseEnter = () => onHover(true);
            listeners.onMouseLeave = () => onHover(false);
        }

        if (onAction) {
            listeners.onClick = e => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                return disabled ? void(0) : onAction(e);
            };
        }

        if (onFocus)       { listeners.onFocus      = onFocus;      }
        if (onBlur)        { listeners.onBlur       = onBlur;       }
        if (onKeyDown)     { listeners.onKeyDown    = onKeyDown;    }
        if (onMouseEnter)  { listeners.onMouseEnter = onMouseEnter; }
        if (onMouseLeave)  { listeners.onMouseLeave = onMouseLeave; }

        return listeners;
    }

    classes = ({view, size, text, icon, disabled = false, className='', mods=[]}) => ([
        className,
        Button.block,
        Button.block.mod( Button.View(view, text, icon) ),
        Button.block.mod( 'size', size === 'L' ? 'L' : 'S'),
        (disabled && Button.block.mod( 'disabled' )),
     ...mods.map( mod => Button.block.mod( mod ) ),
    ]).filter( c => !!c ).join(' ')

    renderButton = ({text, icon, type, children, disabled = false}) => (
        (icon && disabled && !text)
            ? console.error('Icon button cannot be disabled')
            : <button
                disabled  = { disabled }
                ref       = { this.button }
                type      = { type }
                className = { this.classes(this.props) }
                { ...this.listeners(this.props) }
            >
                <span className={
                    Button.block.el('label') +
                    Button.block.el('label').boolmod('hastext', text )
                }>
                    { icon && <FontAwesomeIcon icon={icon} /> }
                    { (icon && text) && <Button.IconTextGap/> }
                    { text && <span className={Button.block.el('label-text')}>{text}</span> }
                </span>
                {children}
            </button>
    )

    render = () => this.renderButton(this.props)

}

Button.IconTextGap .displayName = 'Button.IconTextGap';
Button.Gap         .displayName = 'Button.Gap';
