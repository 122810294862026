import {getEnum} from '../../shared/utils';

export const physicalStatEnum = getEnum({
    substancePowder: 1,
    substanceSolution: 2,
    powder: 3
});

export const physicalStatTitlesEnum = getEnum({
    'Субстанция-порошок': 1,
    'Субстанция-раствор': 2,
    'Порошок': 3
});


