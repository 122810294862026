// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-m__reaction___EKDa4 {\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: flex-start;\n  align-items: center;\n}\n.styles-m__reaction__additionalName___qihMF {\n  font-size: smaller;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reaction": "styles-m__reaction___EKDa4",
	"reaction__additionalName": "styles-m__reaction__additionalName___qihMF"
};
export default ___CSS_LOADER_EXPORT___;
