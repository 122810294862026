// @flow
import styled from 'styled-components';
import Loader from '@biocad/bcd-front-ui/controls/Loader';

export const PageHeaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .b-dropdown {
    max-width: 300px;
  }
`;

export const LoaderWrap = styled.div`
  & .b-loader {
    position:absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
  }
`;
