// @flow

import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import type { IRootStore } from '../../../app/reducers';
import {
	getLastOpenedDialog,
	ReactionEditDialogsMap
} from '../../reducers';
import { ChooseReactantsTableModal } from './ChooseReactantsTableModal';
import { CopyFromTemplateModal } from './CopyFromTemplateModal';

import { Dispatch } from 'redux';
import { reactionModuleActions } from '../../actions';
import { _noop, uuidv4 } from '../../../shared/utils/common';
import type { TEditReactionDialogAction } from '../../actions';
import { ReactionSpendingsDialog } from '../ReactionSpendingsDialog';
import { JarSelectionDialog } from '../JarSelectionDialog';
import { RepkaTaskDialog } from '../RepkaTaskDialog';
import { CompleteReactionConfirm } from './CompleteReactionConfirm';
import {CreateArchivedReactionModal} from '../../components/CreateArchivedReactionModal';
import {ReactionAddProductDialog} from './ReactionAsideForm/ReactionAddProductDialog';
import { ReactionDeleteConfirm } from './ReactionDeleteConfirm';

export type TReactionEditDialogRefType = {
	close: ()=>void,
	open: ()=>void,
	id: string,
	name: string,
}

interface IReactionEditDialogWrapProps {
	dialogName: string,
	dialogProps?: {},
	lastOpenedDialog?: ?string,
	closeLastDialog?: ()=>void,
	dialogParanjaClose?: boolean,
	getDialogRef?: ()=>TReactionEditDialogRefType,
	openDialog?: (TEditReactionDialogAction)=>void
}

export interface IReactionEditDialogWrapComponentProps {
	closeDialog: ()=>void
}

const DialogsMap = {
	[ReactionEditDialogsMap.AddSubstance]: ChooseReactantsTableModal,
	[ReactionEditDialogsMap.ReactionSpendings]: ReactionSpendingsDialog,
	[ReactionEditDialogsMap.EditSubstance]: JarSelectionDialog,
	[ReactionEditDialogsMap.ChooseRepkaTask]: RepkaTaskDialog,
	[ReactionEditDialogsMap.CompleteReactionConfirmation]: CompleteReactionConfirm,
	[ReactionEditDialogsMap.CreateArchivedReaction]: CreateArchivedReactionModal,
	[ReactionEditDialogsMap.AddProduct]: ReactionAddProductDialog,
	[ReactionEditDialogsMap.DeleteConfirm]: ReactionDeleteConfirm,
	[ReactionEditDialogsMap.CopyFromTemplate]: CopyFromTemplateModal,
};

const defaultProps: IReactionEditDialogWrapProps = {
	dialogName: '',
	onDialogClose: _noop,
	dialogParanjaClose: false,
	dialogProps: {}
};

let ReactionEditDialogWrap = (props: IReactionEditDialogWrapProps = defaultProps) => {

	const {
		closeLastDialog, dialogParanjaClose, dialogName, dialogProps,
		lastOpenedDialog, openDialog, getDialogRef, registerDialog,
		...rest
	} = props;

	const dialogRef = useRef({});

	useEffect(() => {
		const id = uuidv4();
		dialogRef.current = {
			id,
			name: dialogName,
			close: () => closeLastDialog(),
			open: () => openDialog({ id, name: dialogName })
		};
		registerDialog({ name: dialogName, id });
		if (getDialogRef && typeof getDialogRef === 'function') {
			getDialogRef(dialogRef.current);
		}
	}, []);

	return (
		(lastOpenedDialog?.id && lastOpenedDialog?.name === dialogName && dialogName in DialogsMap && lastOpenedDialog?.id === dialogRef.current?.id)
			? React.createElement(DialogsMap[dialogName], { ...rest, ...(dialogProps || {}), closeDialog: dialogRef.current.close })
			: ''
	);
};

ReactionEditDialogWrap = connect(
	(state: IRootStore): IReactionEditDialogWrapProps => ({
		lastOpenedDialog: getLastOpenedDialog(state)
	}),
	(dispatch: Dispatch): IReactionEditDialogWrapProps => ({
		closeLastDialog: () => dispatch(reactionModuleActions.reactionEdit.closeLastDialog()),
		openDialog: ({ id, name }: TEditReactionDialogAction) => dispatch(reactionModuleActions.reactionEdit.openDialog({ id, name })),
		registerDialog: ({ id, name }) => dispatch(reactionModuleActions.reactionEdit.registerDialog({ name, id }))
	})
)(ReactionEditDialogWrap);

export {
	ReactionEditDialogWrap
}
