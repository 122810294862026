// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { ReactionCatalog } from './ReactionCatalog';
import { ReactionEdit } from './ReactionEdit';
import { CompleteReaction } from './CompleteReaction';
import { reactionModuleActions } from '../actions'

const Reactions = (props) => {

    const match = props.match;

    const _initSettings = () => {
        props.initRepkaRedirectTaskUrl();
    };
    useEffect(_initSettings, []);

    return (
        <>
            <Switch>
                <Route
                    path={`${match.path}/catalog`}
                    render={(props) => <ReactionCatalog {...props} />}
                />
                <Route
                    path={`${match.path}/redirect/:reactionId`}
                    render={(props) => <Redirect to={`${match.path}/${props.match.params.reactionId}`} />}
                />
                <Route
                    path={`${match.path}/:reactionId/complete`}
                    render={(props) => <CompleteReaction {...props} />}
                />
                <Route
                    path={`${match.path}/:reactionId/clone`}
                    render={(props) => <ReactionEdit {...props} isCloning={true} />}
                /> 
                <Route
                    path={`${match.path}/:reactionId`}
                    render={(props) => <ReactionEdit {...props} />}
                />                  
            </Switch>
        </>
    );
};

export default withRouter(connect(null, 
    (dispatch) => ({
        initRepkaRedirectTaskUrl: () => dispatch(reactionModuleActions.settings.initRepkaRedirectTaskUrl()),
    })
)(Reactions));