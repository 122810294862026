// @flow

import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import type { IReactionEditDialogWrapComponentProps } from '../ReactionEditDialogWrap';
import type { IRootStore } from '../../../../app/reducers';
import { Dispatch } from 'redux';
import { ConfirmDialog } from '../../../../shared/components';
import { _noop } from '../../../../shared/utils/common';
import type { IReaction } from '../../../models';
import type { TCurrentReactionStates, TReactionEditCurrent } from '../../../reducers';
import {ReactionAttachmentType} from '../../../models';
import { getReactionFromForm, isReactionFormIsPristine } from '../../../reducers/reaction-edit-form-plugin';
import { getCurrentReaction, getCurrentReactionStates, getReactionFilesByAttachmentType } from '../../../reducers';
import { withRouter } from 'react-router';
import { reactionModuleActions } from '../../../actions';
import { projectTypeEnum } from '../../../../projects/types/enum';
import {getCurrentStage} from '../../../../stages/reducers';
import { schemesResourceActions } from '../../../../schemes/actions';

interface ICompleteReactionConfirmProps extends IReactionEditDialogWrapComponentProps {
	reaction?: IReaction,
	reactionStates?: TCurrentReactionStates,
	current?: TReactionEditCurrent,
	className?: string,
	saveReaction?: ()=>void,
	reactionIsPristine?: boolean,
	files?: {},
	history?: {
		push?: (string)=>void
	},
	resetSaveStatus: () => void,
}

let CompleteReactionConfirm = (props: ICompleteReactionConfirmProps) => {
	const [isCompleteClicked, setIsCompleteClicked] = useState(false);

	const _initResetSaveStatus = () => {
		props.resetSaveStatus();
	};
	useEffect(_initResetSaveStatus, []);
	
	const _reloadWhenSaveReady = () => {
		if (props.current?.savedSuccess === true && isCompleteClicked) {
			gotoComplete();
		}
	};
	useEffect(_reloadWhenSaveReady, [props.current?.savedSuccess]);

	const hasNoFilesMS = (!Array.isArray(props.files[ReactionAttachmentType.MS]) || props.files[ReactionAttachmentType.MS].length == 0);
	const hasNoFilesNMR = (!Array.isArray(props.files[ReactionAttachmentType.NMR]) || props.files[ReactionAttachmentType.NMR].length == 0)
	const hasNoFilesTLC = (!Array.isArray(props.files[ReactionAttachmentType.TLC]) || props.files[ReactionAttachmentType.TLC].length == 0)
	const isFilesRequired = false;

	// Methods
	const
		gotoComplete = () => (
			props.history?.push(`${props.current?.reactionId}/complete`)
		),
		renderCompletionMessage = () => (
			<>
				{
					props.reactionStates?.hasLessThanMassConsumption &&
					<span>Расчетная масса реагента больше фактического расхода.<br /><br /></span>
				}
				{
					!props.reaction?.ReactionExtraConditions &&
					<span>Дополнительные условия не указаны.<br /><br /></span>
				}
				{					
					hasNoFilesMS && <span>Не были добавлены ВЭЖХ файлы.<br /><br /></span>
				}				
				{					
					hasNoFilesNMR && <span>Не были добавлены ЯМР файлы.<br /><br /></span>
				}				
				{					
					hasNoFilesTLC && <span>Не были добавлены ТСХ файлы.<br /><br /></span>
				}
				{					
					isFilesRequired && <strong>Пожалуйста добавьте файлы для завершения реакции.</strong>
				}
				{
					!isFilesRequired && <strong>Продолжить завершение реакции?</strong>
				}
			</>
		)
	;

	// Handlers
	const saveReactionAndPassToComplete = () => {
			props.saveReaction();
			setIsCompleteClicked(true);
	};

	return (
		!isCompleteClicked && <ConfirmDialog
			title={'Внимание'}
			message={renderCompletionMessage()}
			confirmLabel={isFilesRequired ? null : 'Да'}
			declineLabel={isFilesRequired ? 'Продолжить': 'Нет'}
			confirmAction={saveReactionAndPassToComplete}
			declineAction={() => props.closeDialog()}
		/>
	)
};

const mapStateToProps = (state) => {
    const {resource, modules} = state;
    return {
		stage: getCurrentStage(state),
		reaction: getReactionFromForm(state),
		reactionStates: getCurrentReactionStates(state),
		current: getCurrentReaction(state),
		files: getReactionFilesByAttachmentType(state),
		lastStageOfScheme: state.modules.stage.editStage?.lastStageOfScheme,
    };
};

CompleteReactionConfirm = connect(
	mapStateToProps,
	(dispatch: Dispatch): ICompleteReactionConfirmProps => ({
		saveReaction: () => dispatch(reactionModuleActions.reactionEdit.saveReaction()),
		resetSaveStatus: () => dispatch(reactionModuleActions.reactionEdit.setCurrentSaveStatus({status: null})),
		getLastStage: (id: number, shouldInitEntrySubstance: boolean) => dispatch(schemesResourceActions.getLastStage.request({ uriParams: { id }, params: { shouldInitEntrySubstance } })),
	}),
)(CompleteReactionConfirm);

CompleteReactionConfirm = withRouter(CompleteReactionConfirm);

export {
	CompleteReactionConfirm
}
