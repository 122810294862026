// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/folder.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".candidates-table-grid {\n  display: flex;\n  width: 72%;\n  height: 100%;\n  min-width: 200px;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n  align-items: flex-start;\n  padding: var(--LayoutBlock-margin-h) 24px;\n  overflow-y: auto;\n  align-content: flex-start;\n}\n.candidates-table-grid .cell {\n  width: 200px;\n  height: 200px;\n  margin: 10px;\n}\n.candidates-table-grid .cell:hover {\n  cursor: pointer;\n}\n.candidates-table-grid .cell:hover .img {\n  border: solid 1px var(--grey-600);\n}\n.candidates-table-grid .cell:hover .panel {\n  background-color: var(--grey-200);\n}\n.candidates-table-grid .cell .img {\n  width: 200px;\n  height: 153px;\n  border: solid 1px var(--grey-300);\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center center no-repeat;\n  background-size: 45%;\n}\n.candidates-table-grid .cell .panel {\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  height: 47px;\n}\n.candidates-table-grid .cell .panel h4 {\n  margin: 0 0 0 4px;\n  padding: 0;\n  font-size: 16px;\n  pointer-events: none;\n}\n.candidates-table-grid .cell .panel i {\n  color: var(--grey-400);\n  pointer-events: auto;\n}\n.candidates-table-grid .cell .panel i:hover {\n  color: #54575E;\n  cursor: pointer;\n}\n.candidates-table-grid .cell .panel i:hover + .dropdown-menu-list {\n  opacity: 1;\n  z-index: 10;\n}\n.candidates-table-grid .cell .panel .comment-container {\n  top: 70%;\n  right: -7px;\n  pointer-events: auto;\n}\n.candidates-table-grid .cell .panel .comment-container:hover {\n  cursor: auto;\n  opacity: 1;\n  z-index: 9;\n}\n.candidates-table-grid .cell.active .img {\n  border-color: var(--green);\n}\n.candidates-table-grid .cell.active .panel {\n  background: var(--green-o-10);\n}\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
