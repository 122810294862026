// @flow
import { Model } from 'objectmodel';

export const ReactionParameter = Model({
  Id: [Number, null, undefined],
  Duration: [Number, null, undefined],
  ReactionId: [Number, null, undefined],
  TemperatureInterval: [String, null, undefined],
  IsDeleted: [Boolean],
  IndexNumber: [Number, null, undefined],
})
.defaultTo({
  IsDeleted: false,
});
