// @flow

import {stageIndicatorsModuleActions} from '../actions';

export interface IStageIndicatorModuleStore
{
    stageIndicatorsList:
    {
        stageIndicatorRowIdxForCreatedPP: number
    }
}

const defaultStageIndicatorModuleStore: IStageIndicatorModuleStore = {
    stageIndicatorsList: {
        stageIndicatorRowIdxForCreatedPP: null,
    }
};

export const stageIndicatorModuleReducer = (state: IStageIndicatorModuleStore = defaultStageIndicatorModuleStore, action: any = {}) =>
{
    const reducedFn =
    {
        [stageIndicatorsModuleActions.setStageIndicatorRowIdxForCreatedPP]: (action: {rowIdx: number}): IStageIndicatorModuleStore =>
        ({
            ...state,
            stageIndicatorsList: {
                ...state.stageIndicatorsList,
                stageIndicatorRowIdxForCreatedPP: action.rowIdx
            }
        })
    }[action.type];
    return reducedFn ? reducedFn(action) : state;
}