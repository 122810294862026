// @flow

import Button from '@biocad/bcd-front-ui/controls/Button';
import Dialog from '@biocad/bcd-front-ui/layout/Dialog';
import classNames from 'classnames';
import React, {ReactElement, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { getFormValues, isDirty, isValid } from 'redux-form';
import { jarSpendingResourceActions } from '../../../agents/actions';
import css from './dialog.m.less';
import SpendingsForm, {
  JAR_SPENT_AMOUNT_FIELD_NAME,
  REAGENT_SPENDINGS_ARRAY_NAME,
  REAGENT_SPENDINGS_FORM_NAME,
} from './SpendingsForm';
import type { IReactionEditDialogWrapComponentProps } from '../ReactionEdit/ReactionEditDialogWrap';
import type { IJarStock } from '../../../agents/models';
import type { IRootStore } from '../../../app/reducers';
import { Dispatch } from 'redux';
import type { IReagent, IReagentJar } from '../../../reagents/model';
import { getReagentsFromForm } from '../../reducers/reaction-edit-form-plugin';
import ReactionSpendings from './ReactionSpendings';
import { ConfirmDialog } from '../../../shared/components';
import {reactionModuleActions} from '../../actions';

interface IReactionSpendingsDialogProps extends IReactionEditDialogWrapComponentProps {
  formValues?: any,
  formValid?: boolean,
  formDirty?: boolean,
  jars?: IJarStock[],
  spendJar?: (AgentAvailabilityId: number, Amount: number, Reason: string, ReactionId: number) => void,
  children?: ReactElement[],
  reagents?: IReagent[],
  reactionName?: string,
  onAfterSave?: (values: any[]) => void,
  clear?: () => void,
}

let ReactionSpendingsDialog = (props: IReactionSpendingsDialogProps) => {

  const [overdraftDialogOpen, setOverdraftDialogOpen] = useState(false);

  useEffect(() => () => {
    props.clear();
  }, [])

  const jarIds = props.reagents
    .map((reagent: IReagent) => reagent.Jars.map((jar) => jar.AgentAvailabilityId))
    .reduce((acc: number[], cur: number[]) => ([...acc, ...cur]), []);

  const checkOverdraft = (): Boolean => {
    const jars: (IJarStock & { Consumption?: number })[] = props.formValues?.[REAGENT_SPENDINGS_ARRAY_NAME];

    return jars?.some(j => j.Consumption > 0 && j.Consumption > j.Amount);
  };

  const saveSpendings = () => {
    const spendings = props.formValues?.[REAGENT_SPENDINGS_ARRAY_NAME]?.map((item: IJarStock) => item[JAR_SPENT_AMOUNT_FIELD_NAME]);
    const reagentConsumptions = props.formValues?.[REAGENT_SPENDINGS_ARRAY_NAME]?.reduce((acc, cur: IJarStock): Array<{ reagentId: number, consumption: number }> => {
      if (!('Consumption' in cur)) return acc;
      const foundReagent = props.reagents.find((reagent: IReagent) => reagent.Id == cur.reagentId 
                                              && reagent.Jars.find((rJar: IReagentJar) => rJar.AgentAvailabilityId === cur.Id));
      const reagentId = foundReagent?.Id;
      const addedItem = acc.find(item => item.reagentId === reagentId);
      if (addedItem) {
        return [...acc.filter(i => i !== addedItem), { ...addedItem, consumption: parseFloat((addedItem.consumption + cur.Consumption).toFixed(3)) }];
      }
      return [...acc, { reagentId, consumption: cur.Consumption }];
    }, []);
    if (spendings?.length > 0) {

      spendings.forEach((amount, index) => {
        if (!amount) return;

        const reagent = props.reagents.find(x => x.Agent?.Id === props.jars[index].AgentId);

        props.spendJar(
          props.jars[index].Id,
          amount,
          `Использовано в реакции ${(props.reactionName && typeof props.reactionName === 'string') ? props.reactionName : '[добавить название]'}`,
          reagent.ReactionId
        );
      });
      if (props.onAfterSave && typeof props.onAfterSave === 'function') props.onAfterSave(reagentConsumptions);
    }

    props.closeDialog();
  };

  // Handlers
  const
    onCancelClick = () => {
      props.closeDialog();
    },
    onSaveClick = (overdraftConfirmed: Boolean) => {      
      if (overdraftConfirmed || !checkOverdraft()) {
        setOverdraftDialogOpen(false);
        return saveSpendings();
      }
      return setOverdraftDialogOpen(true);
    }
    ;
  return (
    <>
      <Dialog paranjaClose={false} close={onCancelClick}>
        <div className={classNames('dialog-layout', css.dialogLayout)}>
          <header className={'dialog-header'}>
            <h3>Внести данные о расходе</h3>
          </header>
          <div className={'dialog-contents'} style={{ paddingTop: '0' }}>
            <ReactionSpendings
              reagents={props.reagents}
            />
          </div>
          <footer className={'dialog-footer'}>
            <Button
              onAction={() => onSaveClick(false)}
              text={'Выбрать'}
              size={'L'}
              view={'primary'}
              disabled={!props.formValid}
            />
            <Button
              onAction={onCancelClick}
              text={'Отменить'}
              size={'L'}
            />
          </footer>
        </div>
      </Dialog>
      {
        overdraftDialogOpen && <ConfirmDialog
          title={'Фактический расход больше остатка'}
          message={'Введённый фактический расход больше остатка в банке. Хотите продолжить?'}
          confirmLabel={'Да'}
          declineLabel={'Нет'}
          declineClass={'b-button-danger'}
          confirmAction={() => onSaveClick(true)}
          declineAction={() => setOverdraftDialogOpen(false)}
        />
      }
    </>
  );
};

ReactionSpendingsDialog = connect(
  (state: IRootStore): IReactionSpendingsDialogProps => ({
    formValues: getFormValues(REAGENT_SPENDINGS_FORM_NAME)(state),
    formValid: isValid(REAGENT_SPENDINGS_FORM_NAME)(state),
    formDirty: isDirty(REAGENT_SPENDINGS_FORM_NAME)(state),
    jars: state.modules.reaction.jarSpendings.jars,
    reagents: getReagentsFromForm(state),
  }),
  (dispatch: Dispatch): IReactionSpendingsDialogProps => ({
    spendJar: (AgentAvailabilityId: number, Amount: number, Reason: string, ReactionId: number) => (
      dispatch(
        jarSpendingResourceActions.create.request({
          data: {
            AgentAvailabilityId,
            Amount,
            Reason,
            ReactionId
          }
        })
      )
    ),
    clear: () => [
      jarSpendingResourceActions.destroy,
    ].forEach((fn) => dispatch(fn()))
  })
)(ReactionSpendingsDialog);

export {
  ReactionSpendingsDialog
};
