/* eslint-disable no-undef */
// @flow
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { ConfirmDialog } from '../../../shared/components/ConfirmDialog/ConfirmDialog';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { SchemeTree, SchemeImageExportType } from '../SchemeTree';
import { schemesResourceActions, schemeModuleActions } from '../../actions';
import {schemeViewSelector, schemeSelector, anyLoadedJarAmountShownSelector} from '../../reducers';
import { StyledPageLayout } from './style'
import Button from '@biocad/bcd-front-ui/controls/Button';
import { withRouter } from 'react-router-dom';
import StageDetails from '../StageDetails'

import {
    PageLayout,
    PageLayoutSlot,
    PageLayoutHeader,
    PageLayoutBody,
    pageLayoutAreas,
  } from '../../../shared/components/PageLayout';
import type {IObtainmentScheme, ISchemeView} from '../../models';
import { SchemeDetailsDialog } from '../SchemeDetailsDialog';
import { SchemeCopyDialog } from '../SchemeCopyDialog';
import { SelectCandidateDialog } from '../../../candidates/components/EditCandidate/SelectCandidateDialog';
import { setGlobalLoader } from '../../../shared/actions/loading';
import { projectActions } from '../../../projects/actions/projects';
import { ReactionPurificationModal } from '../../../reactions/components/ReactionPurification/ReactionPurificationModal';
import {candidatesResourceActions} from '../../../candidates/actions';
import type {IFinaleCandidate} from '../../../candidates/models/i-final-candidate';
import {userCRUDPermissionsSelector} from "../../../account/reducers/users-modules-reducer";
import type {IAgent, ICompound} from '../../../agents/models';
import type {IRootStore} from '../../../app/reducers';
import {labsLoaderHOC} from '../../../dictionary/components/labsLoaderHOC';
import {_noop} from '../../../shared/utils/common';
import {jarAmountsByLabsResourceActions} from '../../../agents/actions/jar-amounts-by-labs';
import {SchemeBreadcrumbs} from '../SchemeBreadcrumbs/index';

interface ISchemeEditProps {
  destroyResource: () => void,
  destroyModule: () => void,
  getScheme: () => void,
  getSchemeView: () => void,
  setSelectedStageId: () => void,
  setCurrentSchemeId: () => void,
  deleteScheme: () => void,
  clearSavedSchemeId: () => void,
  setLoader: (boolean) => void,
  match: any,
  history: {},
  scheme: IObtainmentScheme,
  schemeView: ISchemeView,
  schemeDeleted: boolean,
  project: {},
  getCandidate: () => void,
  candidate: IFinaleCandidate,
  userPermissions: {},
  getAgentBySmiles: (compound: ICompound) => void,
  currentSelectedCompoundAgent: IAgent,
  clearCurrentSelectedCompoundAgent: () => void,
  selectedCompoundAgentPending: boolean,
  loadAllAgentJarAmount: () => void,
  destroyJarsByLabsResource: () => void,
  anyLoadedJarAmountShown: boolean,
  setAllLoadedJarAmountsHidden: () => void,
}

const SchemeEdit = (props: ISchemeEditProps) => {
    const { match, getSchemeView, setCurrentSchemeId, getScheme, schemeView, scheme } = props;
    const { params } = match;
    const { id } = params;
    const [isConfirmShown, setIsConfirmShown] = useState(false);
    const [isSchemeDetailsShown, setIsSchemeDetailsShown] = useState(false);
    const [isSelectCandidateModalShown, setIsSelectCandidateModalShown] = useState(false);
    const [isSchemeCopyModalShown, setIsSchemeCopyModalShown] = useState(false);
    const [isPurificationModalShown, setIsPurificationModalShown] = useState(false);
    const [selectedCompound, setSelectedCompound] = useState(null);
    const [exportInvoker, setExportInvoker] = useState(null);
    const [selectedCandidateId, setSelectedCandidateId] = useState(null);
    const [exportedSchemeImage, setExportedSchemeImage] = useState(null);
    const [isPrecalcXlsxStarted, setIsPrecalcXlsxStarted] = useState(false);


    function initialSchemeLoad() {
      cleanUp();
      props.clearSavedSchemeId();
      setCurrentSchemeId(id);
      getScheme(id);
      getSchemeView(id);
    }
    useEffect(initialSchemeLoad, [id]);

    function onSchemeLoaded() {
      if(!props.scheme)
        return;
      props.getProjectByCandidateId(props.scheme.FinalCandidateId);
      props.getCandidate(props.scheme.FinalCandidateId);
    }
    useEffect(onSchemeLoaded, [props.scheme]);

    function loaderHandler() {
      props.setLoader(props.pending);
    }
    useEffect(loaderHandler, [props.pending]);


    function cleanUp() {
      props.destroyResource();
      props.destroyModule();
      props.destroyJarsByLabsResource();
    }

    function onUnmountCleanUp() {
      return () => cleanUp();
    }
    useEffect(onUnmountCleanUp, []);

    function schemeDeletedHandler() {
      if(props.schemeDeleted)
        goBack();
    }
    useEffect(schemeDeletedHandler, [props.schemeDeleted]);

    function createNewStage() {
      props.history.push(`/stages/add?schemeId=${schemeView.SchemeId}`);
    }


    function goBack() {
      const folderParam = scheme?.FolderId ? `&schemeFolderId=${scheme?.FolderId}` : '';
      const candidateUrl = `/projects/${props.project?.Id}?`
        + (props.candidate?.FolderId ? `folderId=${props.candidate?.FolderId}&` : ``) +
        `selectedId=${scheme?.FinalCandidateId}${folderParam}`;
      props.history.push(candidateUrl);
    }

    function selectStage(stageId: number) {
      props.setSelectedStageId(stageId);
    }

    function deleteSchemeHandler() {
      props.deleteScheme(id);
      toggleConfirmModal();
    }

    function toggleConfirmModal() {
      setIsConfirmShown(!isConfirmShown);
    }

    function toggleDetailsModal() {
      setIsSchemeDetailsShown(!isSchemeDetailsShown);
    }
    
    function showDeleteConfirm() {
      setIsConfirmShown(true);
    }

    function saveAsImage() {
      setExportInvoker({type: SchemeImageExportType.SaveSchemeToBrowser});
    }

    function canCreateStage() {
      return !schemeView?.Stages?.some(s => s.IsFinalCandidate) && props.userPermissions.scheme.CREATE;
    }

    function toggleSelectCandidateModal() {
      setIsSelectCandidateModalShown(!isSelectCandidateModalShown);
    }

    function candidateForCopySelected(candidateId) {
      toggleSelectCandidateModal();
      toggleSchemeCopyModal();
      setSelectedCandidateId(candidateId);
    }

    function toggleSchemeCopyModal() {
      setIsSchemeCopyModalShown(!isSchemeCopyModalShown);
    }

    function goToSchemeCompare() {
      props.history.push(`/schemes/${id}/compare`);
    }

    function togglePurificationModal() {
      setIsPurificationModalShown(!isPurificationModalShown);
      props.clearCurrentSelectedCompoundAgent();
    }

    function selectCompound(compound) {
      setSelectedCompound(compound);
      props.getAgentBySmiles(compound);
      togglePurificationModal();
    }

    function initPrecalcXlsx() {
      setIsPrecalcXlsxStarted(true);
      setExportInvoker({type: SchemeImageExportType.ExportSchemeImage});
    }
    function downloadPrecalcXlsx() {
      if(!exportedSchemeImage || !isPrecalcXlsxStarted) {
        return;
      }
      setExportedSchemeImage(null);
      setIsPrecalcXlsxStarted(false);
      props.getPrecalcXlsx(id, `${props.project.Name}-${scheme.Code}`, exportedSchemeImage);
    }
    useEffect(downloadPrecalcXlsx, [exportedSchemeImage, isPrecalcXlsxStarted]);

    return (
        <PageLayout as={StyledPageLayout}>
        <PageLayoutSlot gridAreaName={pageLayoutAreas.header}
                        className={pageLayoutAreas.header}
                        as={PageLayoutHeader}>
          <PageLayoutSlot gridAreaName={pageLayoutAreas.breadcrumbs} className={'no-overflow'}>
            <SchemeBreadcrumbs schemeId={props.scheme?.Id}/>
          </PageLayoutSlot>
          <PageLayoutSlot gridAreaName={pageLayoutAreas.headerBackBtn}
                          className={pageLayoutAreas.headerBackBtn}>
            <Button
              className={'fo-folder-back'}
              onAction={goBack}
              view={'icon'}
              size={'L'}
            />
          </PageLayoutSlot>
          <PageLayoutSlot gridAreaName={pageLayoutAreas.headerLeftSide}
                          className={pageLayoutAreas.headerLeftSide}>
            <div className={'h2'}>
              <span>{schemeView && schemeView.Code}</span>
            </div>
          </PageLayoutSlot>
          <PageLayoutSlot gridAreaName={pageLayoutAreas.headerRightSide}
                          className={pageLayoutAreas.headerRightSide}>
            {
              <>
                <div className='link-buttons'>
                    {
                      props.userPermissions.scheme.UPDATE &&
                      <>
                        <Button
                            className={'link-btn fo-edit'}
                            view={'icon'}
                            size={'L'}
                            onAction={toggleDetailsModal}
                        />
                        <div className={'separator'}/>
                      </>
                    }
                    {
                        (props.userPermissions.scheme.READ && props.schemeView?.Stages && schemeView?.Stages?.length > 0) &&
                        <>
                            <Button
                                className={'link-btn fo-calc'}
                                view={'icon'}
                                size={'L'}
                                onAction={initPrecalcXlsx}
                            />
                            <div className={'separator'}/>
                        </>
                    }
                    {
                        (props.userPermissions.scheme.UPDATE && props.userPermissions.scheme.CREATE) &&
                        <>
                            <Button
                                className={'link-btn fo-copy'}
                                view={'icon'}
                                size={'L'}
                                onAction={toggleSelectCandidateModal}
                            />
                            <div className={'separator'}/>
                        </>
                    }
                    <Button
                        className={'link-btn fo-compare'}
                        view={'icon'}
                        size={'L'}
                        onAction={goToSchemeCompare}
                    />
                    <div className={'separator'}/>
                    <Button
                        className={'link-btn fo-download'}
                        view={'icon'}
                        size={'L'}
                        onAction={saveAsImage}
                    />
                    <div className={'separator'}/>
                    {
                        props.userPermissions.scheme.DELETE &&
                        <>
                          <Button
                              className={'link-btn fo-trash'}
                              view={'icon'}
                              size={'L'}
                              onAction={showDeleteConfirm}
                          />
                         <div className={'separator'}/>
                        </>
                    }
                    <Button
                        className={'link-btn fo-chem-circle'}
                        view={'icon'}
                        size={'L'}
                        onAction={props.anyLoadedJarAmountShown ? props.setAllLoadedJarAmountsHidden : props.loadAllAgentJarAmount}
                    />
                </div>
                {canCreateStage() &&
                <Button
                    text={'Новая стадия'}
                    onAction={createNewStage}
                    size={'L'}
                    view="primary"
                    icon={faPlus}
                />
                }
              </>
            }
          </PageLayoutSlot>
        </PageLayoutSlot>
          <PageLayoutSlot gridAreaName={pageLayoutAreas.body}
                          className={pageLayoutAreas.body}
                          as={PageLayoutBody}>
          <PageLayoutSlot gridAreaName={pageLayoutAreas.bodyTopLeft}
                          className={pageLayoutAreas.bodyTopLeft}
                          schemeView={schemeView}
                          onSelectStage={selectStage}
                          exportInvoker={exportInvoker}
                          onSelectCompound={selectCompound}
                          setExportedData={setExportedSchemeImage}
                          as={SchemeTree}
          />
          <PageLayoutSlot gridAreaName={pageLayoutAreas.bodyRight}
                          className={pageLayoutAreas.bodyRight}
                          as={StageDetails}
          />
        </PageLayoutSlot>
        {/* Место под боковую панель навигации */}
        {/*<PageLayoutSlot gridAreaName={pageLayoutAreas.leftSide} />*/}
        {isConfirmShown &&
            <ConfirmDialog
                title={'Внимание'}
                message={'Вы уверены, что хотите удалить эту схему?'}
                confirmLabel={'Да'}
                declineLabel={'Отмена'}
                confirmAction={deleteSchemeHandler}
                declineAction={toggleConfirmModal}
            />
        }

        {isSchemeDetailsShown &&
          <SchemeDetailsDialog 
            onClose={toggleDetailsModal}
            onSave={initialSchemeLoad}
            projectType={props.project.Type}
          />
        }

        {isSelectCandidateModalShown &&
          <SelectCandidateDialog 
            initialCandidateId={scheme?.FinalCandidateId}
            onClose={toggleSelectCandidateModal}
            onSelect={candidateForCopySelected}
            project={props.project}
          />
        }

        {isSchemeCopyModalShown &&
          <SchemeCopyDialog 
            finalCandidateId={selectedCandidateId}
            scheme={props.scheme}
            onClose={toggleSchemeCopyModal}
          />
        }

        {isPurificationModalShown &&
          <ReactionPurificationModal
              onCancel={togglePurificationModal}
              compound={selectedCompound}
              agentId={props.currentSelectedCompoundAgent?.Id}
              pending={props.selectedCompoundAgentPending}
          />
        }
      </PageLayout>

    )
};

export default
labsLoaderHOC(
    withRouter(
        connect(
            (state: IRootStore) => ({
                schemeView: schemeViewSelector(state),
                scheme: schemeSelector(state),
                schemeDeleted: state?.modules?.scheme?.editScheme?.schemeDeleted,
                pending: state?.resource?.schemes?.pending,
                project: state?.modules?.scheme?.editScheme?.currentProject,
                candidate: state.modules.candidate?.candidate,
                userPermissions: userCRUDPermissionsSelector(state),
                currentSelectedCompoundAgent: state?.modules?.scheme?.editScheme?.selectedCompoundAgent,
                selectedCompoundAgentPending: state?.modules?.scheme?.editScheme?.selectedCompoundAgentPending,
                anyLoadedJarAmountShown: anyLoadedJarAmountShownSelector(state),
            }),
            (dispatch) => ({
                destroyResource: () => dispatch(schemesResourceActions.destroy()),
                destroyModule: () => dispatch(schemeModuleActions.destroyModule()),
                destroyJarsByLabsResource: () => dispatch(jarAmountsByLabsResourceActions.destroy()),
                clearSavedSchemeId: () => dispatch(schemeModuleActions.clearSchemeSavedId()),
                getSchemeView: (id: number) => dispatch(schemesResourceActions.getSchemeView.request({ uriParams: { id } })),
                getScheme: (id: number) => dispatch(schemesResourceActions.get.request({ uriParams: { id } })),
                deleteScheme: (id: number) => dispatch(schemesResourceActions.delete.request({ uriParams: { id } })),
                setSelectedStageId: (stageId: number) => dispatch(schemeModuleActions.setSelectedStageId({stageId: stageId})),
                setCurrentSchemeId: (id: number) => dispatch(schemeModuleActions.setCurrentSchemeId({schemeId: id})),
                setLoader: (isLoading) => dispatch(setGlobalLoader({ isLoading })),
                getProjectByCandidateId: (candidateId) => dispatch(projectActions.getProjectByCandidateId.request({ params: {candidateId: candidateId}})),
                getCandidate: (id: number) => dispatch(candidatesResourceActions.get.request({uriParams: {id}})),
                getAgentBySmiles: (compound: ICompound) => dispatch(schemeModuleActions.loadAgentBySmiles.request({ params: {compound} })),
                clearCurrentSelectedCompoundAgent: () => dispatch(schemeModuleActions.clearAgentForCurrentCompound({})),
                loadAllAgentJarAmount: () => dispatch(schemeModuleActions.loadAllAgentJarAmount({})),
                setAllLoadedJarAmountsHidden: () => dispatch(schemeModuleActions.setAllLoadedJarAmountsHidden()),
                getPrecalcXlsx: (schemeId: number, xlsxName: string, schemeImage: string) => dispatch(schemeModuleActions.getPrecalcXlsx({schemeId, xlsxName, schemeImage})),
            })
)(SchemeEdit)));