export const FieldValidators = {
    required: value => (value === '' || value === undefined || value === null)
      ? new Error('Обязательное поле')
      : undefined,
    number: value => (new RegExp(/^(0|[1-9]\d*)([.,]\d*[1-9])?$/)).test(value)
        ? undefined
        : new Error('Неверный формат числа'),
    decimalLength: (length) => {
        if (isNaN(length)) {
            return console.warn(`Expected param length to be number but got: ${length} of type ${typeof length}`);
        }
        return (value) => {
            if (value === null || value === undefined) return undefined;
            const regexp = new RegExp(`^\\d+([,.]\\d{0,${length}})?$`, 'g');
            return regexp.test(value) ? undefined : new Error(`Дробная часть не должна быть длиннее ${length} знаков`);
        };
    },
    maxStringLength: (max) => {
        if (isNaN(max)) {
            return console.warn(`Expected param max to be number but got: ${max} of type ${typeof max}`);
        }
        return (value) => {
            if (value === null || value === undefined) return undefined;
            const isValueString = (typeof value === 'string' || value instanceof String);
            if (!isValueString) {
                console.warn(`Expected string but got: ${value} of type ${typeof value}`);
                return;
            }
            return value.length <= max ? undefined : new Error(`Длина не может быть более ${max} символов`);
        };
    },
    minNum: (min) => {
        if (isNaN(min)) {
            return console.warn(`Expected param min to be number but got: ${min} of type ${typeof min}`);
        }
        return (value) => {
            if (value === null || value === undefined) return undefined;
            if (isNaN(value)) {
                console.warn(`Expected number but got: ${value} of type ${typeof value}`);
                return;
            }
            return Number(value, 10) < min ? new Error(`Число должно быть не менее ${min}`) : undefined;
        };
    },
    maxNum: (max) => {
        if (isNaN(max)) {
            return console.warn(`Expected param max to be number but got: ${max} of type ${typeof max}`);
        }
        return (value) => {
            if (value === null || value === undefined) return undefined;
            if (isNaN(value)) {
                return console.warn(`Expected number but got: ${value} of type ${typeof value}`);
            }
            return Number(value, 10) > max ? new Error(`Число должно быть не более ${max}`) : undefined;
        };
    },
    stepNum: (step) => {
        if (isNaN(step)) {
            return console.warn(`Expected param step to be number but got: ${step} of type ${typeof step}`);
        }
        return (value) => {
            if (!value) return undefined;
            if (isNaN(value) || typeof value !== 'number') {
                return console.warn(`Expected number but got: ${value} of type ${typeof value}`);
            }
            if (!isFinite(value)) {
                return console.warn(`The given value is not finite: ${value}`);
            }
            if (Number.isInteger(step)) {
                if (
                  !Number.isInteger(value) ||
                  (Number.isInteger(value) && (value / step < 0))
                ) {
                    return new Error(`Допустимы только целые числа с шагом ${step}`);
                }
            }
            else {
                const
                  strValueAfterDot = String(value).replace(/^\d+[.,](\d+)$/, '$1'),
                  strStepValueAfterDot = String(step).replace(/^\d+[.,](\d+)$/, '$1');
                const
                  valueLengthAfterDot = strValueAfterDot.length,
                  stepLengthAfterDot = strStepValueAfterDot.length;
                const
                  intValueAfterDot = valueLengthAfterDot < stepLengthAfterDot &&
                    Number.parseInt(`${strValueAfterDot.replace(/^[0]*(\d+)$]/, '$1')}
                    ${new Array(stepLengthAfterDot-valueLengthAfterDot).fill('0').join('')}`, 10),
                  intStepAfterDot = Number.parseInt(strStepValueAfterDot, 10);


                if (!Number.isInteger(value)) {
                    if (valueLengthAfterDot > stepLengthAfterDot) {
                        return new Error(`Допустимое кол-во символов после запятой - ${stepLengthAfterDot}`);
                    }
                }
                if (!Number.isInteger(intValueAfterDot / intStepAfterDot)) {
                    return new Error(`Допустимый шаг - ${step}`);
                }
            }
            return undefined;
        };
    },
    pattern: (regexp: string) => {
        if (!regexp || typeof regexp !== 'string') {
            return console.warn('Expecting pattern parameter to be string.');
        }
        return (value: string) => {
            if (!value || typeof value !== 'string') {
                return undefined;
            }
            const r = new RegExp(regexp, 'g');
            return r.test(value) ? undefined : new Error('Неверный формат значения поля.')
        }
    },
    maxDecimalLength3: value => FieldValidators.decimalLength(3)(value),
};
