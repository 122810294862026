import React from 'react';
import { ConfirmDialog } from './components/ConfirmDialog/ConfirmDialog';
import { Prompt } from 'react-router';

export class CustomPrompt extends React.Component {

    state = { open: false };

    constructor() {
        super();
        this.__trigger = Symbol.for('__PreventTransitionPrompt__');
    }
    
    componentDidMount() {
        window[this.__trigger] = this.show;
      }
    
    componentWillUnmount() {
        delete window[this.__trigger];
    }

    show = allowTransitionCallback => {
        this.setState({ open: true }, () => allowTransitionCallback(false));
    };

    handleTransition = () => {
        return Symbol.keyFor(this.__trigger);
    };

    handleCloseDecorated = () => {
        this.setState({ open: false }, () => this.props.declineAction());
    }

    render() {
        const { when } = this.props;
        const { open } = this.state;
        return (
            <React.Fragment>
                <Prompt when={when} message={this.handleTransition} />
                {open && <ConfirmDialog {...this.props} declineAction={this.handleCloseDecorated} />}
            </React.Fragment>
        );
    }
}
