// @flow

import React, {useEffect, useState, useRef} from 'react';
import {GridTable, GridTableCol, GridTableCellComponent} from '../../../shared/components/GridTable';
import type {IReactionIndicatorParameter} from '../../../reaction-indicator-parameters/models';
import {connect} from 'react-redux';
import type {IRootStore} from '../../../app/reducers';
import {Dispatch} from 'redux';
import {reactionIndicatorsResourceActions} from '../../../reaction-indicator-parameters/actions';
import {getIndicatorParametersFromForm} from '../../reducers/reaction-edit-form-plugin';
import {
    ReactionIndicatorParameter,
    ReactionIndicatorParameterFieldNamesMap
} from '../../../reaction-indicator-parameters/models';
import { IndicatorTypeEnum } from '../../../indicators/models/indicator-type-enum';
import {InlineEdit, inputTypesEnum} from '../../../shared/components/InlineEdit';
import {BcdCheckboxWrapper, BcdDropdownWrapper} from '../../../shared/components';
import CommentWrapper from '../../../shared/components/CommentWrapper';
import {FieldValidators} from '../../../shared/validators/field-validators';
import Button from '@biocad/bcd-front-ui/controls/Button';
import { change, Field } from 'redux-form';
// eslint-disable-next-line import/no-cycle
import {REACTION_EDIT_FORM_NAME} from './index';
import Collapsible from "react-collapsible";
import {StageIndicatorCPPMap} from "../../../stage-indicators/models";
import type {IIndicatorProcessPoint} from '../../../indicators/models';

interface IReactionIndicatorsProps {
    reactionId: number;
    reactionIndicators?: IReactionIndicatorParameter[];
    updateIsDeleted?: (index: Number, value: Boolean) => null;
    className?: string;
    arrayPush?: Function;
    arrayRemove?: Function;
    isDisabled?: Boolean
}

const defaultProps: IReactionIndicatorsProps = {
    reactionId: null,
    isDisabled: false
};

const boolOptions = [
    {value:null, label:'не выбрано'},
    {value:true, label:'да'},
    {value:false, label:'нет'},
];

export const
    REACTION_IND_PARAMETERS_ARRAY_FIELD_NAME = 'reactionIndicatorParameters'
;

const MaxNumVal = 9999999999999;

let ReactionIndicators = (props: IReactionIndicatorsProps = defaultProps) =>
{
    const [focusReceived, setFocusReceived] = useState(false);
    const [shouldUpdate, setShouldUpdate] = useState(false);

    const changeRangeMax = (event, params) => {
        if(focusReceived) {
            let emptyMax = (params.item.MaxRangeValue === null || params.item.MaxRangeValue === undefined || params.item.MaxRangeValue === '');
            setShouldUpdate(emptyMax);
            setFocusReceived(false);
            if(emptyMax && event !== '')
                params.item.MaxRangeValue = event;
        }
        if(shouldUpdate && event !== '')
            params.item.MaxRangeValue = event;

        if(event === '')
            params.item.MinRangeValue = null;
    };

    const changeRangeMin = (event, params) => {
        if(focusReceived) {
            let emptyMax = (params.item.MinRangeValue === null || params.item.MinRangeValue === undefined || params.item.MinRangeValue === '');
            setShouldUpdate(emptyMax);
            setFocusReceived(false);
            if(emptyMax && event !== '')
                params.item.MinRangeValue = event;
        }
        if(shouldUpdate && event !== '')
            params.item.MinRangeValue = event;

        if(event === '')
            params.item.MaxRangeValue = null;
    };

    const startEdit = () => {
        setFocusReceived(true);
    };

    const finishEdit = () => {
        setShouldUpdate(false);
    };

    const getBoolLabel = (value) => {
        if(value === null) return '';
        if(value) return 'да';
        return 'нет';
    };

    const renderMissedIndicator = (processPoint: IIndicatorProcessPoint) => {
        if(!props.reactionIndicators || !props.reactionIndicators.length)
            return '';
        const items = props.reactionIndicators?.filter((i) => i.StageIndicator?.ProcessPointId===processPoint?.Id);
        const missed = items.reduce((acc, cur) => acc + isIndicatorValueMissed(cur), 0);
        if (missed === 0)
        {
            return ( <div style={{marginLeft:'30px'}} /> );
        }
        return ( <div className="dot">{missed}</div> );
    };

    const isIndicatorValueMissed = (indicator) => {
        if((indicator.Value != null && indicator.Value !== '') ||
            (indicator.TextValue != null && indicator.TextValue !== '') ||
            indicator.BooleanValue !== null ||
            (indicator.MinRangeValue !== null && indicator.MaxRangeValue !== null)) return 0;
        return 1;
    };

    const renderByStageIndicator = (params) => {
        const {input, meta, item, rowidx} = params;
        const nameMap = ReactionIndicatorParameterFieldNamesMap;
        const getName = (field) => `${REACTION_IND_PARAMETERS_ARRAY_FIELD_NAME}[${rowidx}].${field}`;
        switch(item.StageIndicator.Indicator.IndicatorType) {
            case IndicatorTypeEnum.Number: {
                let value = item.Value;
                if (value === null) value = '';
                return (
                    <Field
                        validate={[FieldValidators.maxNum(MaxNumVal)]}
                        name={getName(nameMap.Value)}
                        component={GridTableCellComponent}
                        render={({input, meta, item}) => (
                            <InlineEdit inputType={inputTypesEnum.InputNum}
                                        markPristineValidity
                                        {...{input, meta, item}}
                                        borderClassName={`errorIfEmpty${value}`}
                                        isDisabled={props.isDisabled}
                            />
                        )}
                        useFormFieldLayout={true}
                    />
                );
            }
            case IndicatorTypeEnum.NumericRange: {
                let minValue = item.MinRangeValue;
                if (minValue === null) minValue = '';
                let maxValue = item.MaxRangeValue;
                if (maxValue === null) maxValue = '';
                return (
                    <>
                        <Field
                            name={getName(nameMap.MinRangeValue)}
                            validate={[FieldValidators.maxNum(MaxNumVal)]}
                            component={GridTableCellComponent}
                            render={({input, meta, item}) => (
                                <InlineEdit inputType={inputTypesEnum.InputNum}
                                            markPristineValidity
                                            {...{input, meta}}
                                            borderClassName={`errorIfEmpty${minValue}`}
                                            isDisabled={props.isDisabled}
                                />
                            )}
                            useFormFieldLayout={true}
                            onChange={(e) => {
                                changeRangeMax(e, params);
                            }}
                            onBlur={finishEdit}
                            onFocus={startEdit}
                        />
                        &nbsp;
                        <Field
                            name={getName(nameMap.MaxRangeValue)}
                            validate={[FieldValidators.maxNum(MaxNumVal)]}
                            component={GridTableCellComponent}
                            render={({input, meta, item}) => (
                                <InlineEdit inputType={inputTypesEnum.InputNum}
                                            markPristineValidity
                                            {...{input, meta}}
                                            borderClassName={`errorIfEmpty${maxValue}`}
                                            isDisabled={props.isDisabled}
                                />
                            )}
                            useFormFieldLayout={true}
                            onChange={(e) => {
                                changeRangeMin(e, params);
                            }}
                            onBlur={finishEdit}
                            onFocus={startEdit}
                        />
                    </>
                );
            }
            case IndicatorTypeEnum.Text: {
                let value = item.TextValue;
                if (value === null) value = '';
                return (
                    <Field
                        name={getName(nameMap.TextValue)}
                        component={GridTableCellComponent}
                        render={({input, meta, item}) => (
                            <InlineEdit inputType={inputTypesEnum.InputText}
                                        markPristineValidity
                                        {...{input, meta}}
                                        borderClassName={`errorIfEmpty${value}`}
                                        isDisabled={props.isDisabled}
                            />
                        )}
                        useFormFieldLayout={true}
                    />
                );
            }
            case IndicatorTypeEnum.Boolean: {
                let value = item.BooleanValue;
                if (value === null) value = '';
                return (
                    <Field
                        name={getName(nameMap.BooleanValue)}
                        component={GridTableCellComponent}
                        format={(value) => getBoolLabel(value)}
                        render={({input, meta, item}) => (
                            <InlineEdit inputType={inputTypesEnum.Select}
                                        markPristineValidity
                                        {...{input, meta}}
                                        options={boolOptions}
                                        borderClassName={`errorIfEmpty${value}`}
                                        isDisabled={props.isDisabled}
                            />
                        )}
                        useFormFieldLayout={true}
                    />
                );
            }
        }
        return null;
    };


    // State

    // Effects

    const _clearDataOnUnmount = () => () => {
        props.destroyData();
      };
      useEffect(_clearDataOnUnmount, []);

    const removeItem = (item, rowidx) => {
        props.updateIsDeleted(rowidx, true);
    };

    if(!props.reactionIndicators || !props.reactionIndicators.length)
        return null;

    let processPoints: IIndicatorProcessPoint[] = props.reactionIndicators
        .map(item => item.StageIndicator.ProcessPoint)
        .reduce((acc, cur: IIndicatorProcessPoint) => !!acc.find(i => i?.Id === cur?.Id) ? acc : [ ...acc, cur ], [])
    ;

    const tables = processPoints.filter(processPoint => !!processPoint).map((processPoint: IIndicatorProcessPoint, idx) =>
        <div key={`${idx}__${processPoint?.Id}`} className={'indicatorArea'}>
            <Collapsible transitionTime={200}
                         trigger={(
                             <div className={'tableTitle'}>
                                 { renderMissedIndicator(processPoint) }
                                 { processPoint.Name }
                                 <span className={'arrow'} />
                             </div>
                         )}>
                <GridTable className={props.className}
                   hover
                   stickyHeading
                   fieldArrayName={REACTION_IND_PARAMETERS_ARRAY_FIELD_NAME}
                   rowHeight={'minmax(54px, max-content)'}
                   colsTemplate={'16fr 4fr 8fr 16fr 16fr 64px'}
                   excludeRows={(item: IReactionIndicatorParameter) => item.IsDeleted || item.StageIndicator.ProcessPointId!==processPoint?.Id}
                >
                    <GridTableCol
                        title={'Параметр'}
                        render={({item}) => (
                            <span>
                                { item.StageIndicator.Indicator.Name }
                                { item.StageIndicator.Comment ? ',' : '' }
                                <br />
                                <span style={{ whiteSpace: 'pre-line' }}>{ item.StageIndicator.Comment || '' }</span>
                            </span>
                        )}
                    />
                    <GridTableCol
                        title={'Ед. изм.'}
                        render={({item}) => (
                            <span>{ item?.StageIndicator?.IndicatorUnit?.Name || '' }</span>
                        )}
                    />
                    <GridTableCol
                        title={'Норма'}
                        render={({item}) => (
                            <span>{ item.StageIndicator.Norm || '' }</span>
                        )}
                    />
                    <GridTableCol
                        title={'Значение'}
                        // warn={[FieldValidators.required]}
                        render={renderByStageIndicator}
                    />
                    <GridTableCol
                        title={'Комментарий'}
                        fieldName={ReactionIndicatorParameterFieldNamesMap.Comment}
                        render={({ meta, input }) => (
                            <InlineEdit
                                inputType={inputTypesEnum.InputText}
                                {...{ meta, input }}
                            />
                        )}
                    />
                    <GridTableCol
                        render={({ item, rowidx }) => (
                            item.StageIndicator.IsDeleted &&
                            <Button
                                className={'delete-btn fo-trash'}
                                onAction={() => removeItem(item, rowidx)}
                                view={'icon'}
                                size={'L'}
                            />
                        )}
                    />
                </GridTable>
            </Collapsible>
        </div>
    );

    return (
        <>
            {tables}
        </>
    );
};

ReactionIndicators = connect(
    (state: IRootStore): IReactionIndicatorsProps => ({
        reactionIndicators: getIndicatorParametersFromForm(state),
        stageId: state?.modules?.reaction?.editReaction?.current?.stageId,
    }),
    (dispatch: Dispatch): IReactionIndicatorsProps => ({
        destroyData: () => dispatch(reactionIndicatorsResourceActions.destroy()),
        updateIsDeleted: (index, value) => dispatch(change(REACTION_EDIT_FORM_NAME, `${REACTION_IND_PARAMETERS_ARRAY_FIELD_NAME}[${index}].${ReactionIndicatorParameterFieldNamesMap.IsDeleted}`, value)),
    })
)(ReactionIndicators);

export {
    ReactionIndicators
};
