// @flow
import React, {
  useEffect,
  useState
} from 'react';
import listStyles from './list.m.less';
import dashboardStyles from '../dashboard.m.less';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { workProjectsResourceActions } from '../../actions';
import { TableCol } from '../../../shared/models';
import { Row, Header, Table, Body, Cell } from '@biocad/bcd-front-ui/layout/Table';
import { LayoutBlock, TitleWithCounter, ReactionTypeIcon } from '../../../shared/components';
import { PageHeaderWrap, LoaderWrap } from '../../../agents/components/List/style';
import { PaginationStyled } from '../../../shared/components/Paginator/style';
import { ProjectReactionsTitlesEnum, ProjectReactionsKeysEnum } from '../../models/i-work-project';
import { itemsToArraySelector } from '../../../shared/utils/selectors/resource-selectors';
import Loader from '@biocad/bcd-front-ui/controls/Loader';
import { Link } from 'react-router-dom';
import { ProjectMoreInfoPopup } from './ProjectMoreInfoPopup';
import Button from '@biocad/bcd-front-ui/controls/Button';
import { ReactionNameWithType } from '../ReactionsList/ReactionNameWithType';
import { ProjectNameWithType } from './ProjectNameWithType';

const tableCols: TableCol[] = [
  {
    title: ProjectReactionsTitlesEnum.Name,
    mappingProp: ProjectReactionsKeysEnum.Name,
  },
  {
    title: ProjectReactionsTitlesEnum.Candidates,
    mappingProp: ProjectReactionsKeysEnum.Candidates,
  },
  {
    title: ProjectReactionsTitlesEnum.Reactions,
    mappingProp: ProjectReactionsKeysEnum.Reactions,
  },
];

const renderHeaderRow = () => (
  <Row className={dashboardStyles.table__headerRow}>
    {
      renderHeaderCells()
    }
  </Row>
);

const renderHeaderCells = () =>
  tableCols.map(col => (
    <Cell
      key={col.mappingProp}
      className={dashboardStyles.table__headerCell}
    >
        <nobr>{col.title}</nobr>
    </Cell>
    ) 
  );

const renderWorkProjectsListRow = (item, moreInfoHandler) => {

  const renderCandidates = (items, projectId) => {
    const limit = 5;
    const sliced = items.length > limit ? items.slice(0, limit) : items;
    return (
      <>
        {
          sliced.map((candidate, index) =>
            <Link
                key={index}
                to={`/projects/${projectId}`}
              >
              <img
                className={`${dashboardStyles.table__thumbnail} ${dashboardStyles.imgLink}`}
                onClick={() => {}}
                title={candidate.MolecularFormula}
                src={candidate.Thumbnail} />
            </Link>
          )
        }
        {
          items.length > limit &&
            <div>
              <Button
                  view="pseudo"
                  onAction={() => moreInfoHandler(item)}
                  className={listStyles.moreLinkBtn}
                >
                Ещё +{items.length - limit}
              </Button>
            </div>
        }  
      </>
    );
  };

  const renderReactions = (items: [], projectId) => {
    const limit = 4;
    const sliced = items.length > limit ? items.slice(0, limit) : items;
    return (
      <>
        {
          sliced.map(item =>
            <Link key={item.Id} to={`/reactions/${item.Id}`} className={`${dashboardStyles.textLink}`}>
              <ReactionNameWithType item={item}/>
            </Link>
          )
        }
        {
          items.length > limit &&
            <Button
              view="pseudo"
              onAction={() => moreInfoHandler(item)}
              className={listStyles.moreLinkBtn}
              text={`Ещё +${items.length - limit}`}
            />
        }
      </>
    );
  };

  const handleClickCandidate = (param) => {};
  const handleClickReaction = (param) => {};

  return (
    tableCols.map(col => {
      
      let result;

      switch (col.mappingProp) {
        case ProjectReactionsKeysEnum.Name:
          result =
            <Link to={`/projects/${item.Id}`} className={dashboardStyles.textLink}>
              <ProjectNameWithType item={item} />
            </Link>;
          break;

        case ProjectReactionsKeysEnum.Candidates:
          result = renderCandidates(item[col.mappingProp], item.Id);
          break;
        
        case ProjectReactionsKeysEnum.Reactions: {
          result = (
            <div className={listStyles.reactionList}>
              {renderReactions(item[col.mappingProp], item.Id)}
            </div>
          );
          break;
        }
        default:
          result = <span>{item[col.mappingProp]}</span>;
          break;
      }

      return (
        <Cell key={col.mappingProp} className={dashboardStyles.table__dataCell}>
          {result}
        </Cell>
      );

    })
  );
};

export const WorkProjectsList = ({
  workProjects,
  workProjectsListPagination,
  pendingState,
  getWorkProjectsList,
  destroyWorkProjectsList
}) => {

  const initialPagingParams = {skip: 0, limit: 5};

  const [moreInfoPopupVisible, setMoreInfoPopupVisible] = useState(false);
  const [currentProject, setCurrentProject] = useState(null);

  const [pagingParams, setPagingParams] = useState(
    workProjectsListPagination ?
    {
      skip: workProjectsListPagination.pageSize * (workProjectsListPagination.page - 1),
      limit: workProjectsListPagination.pageSize
    } :
    initialPagingParams
  );

  const _watchData = () => {
    if (!pendingState) {
      destroyWorkProjectsList();
      getWorkProjectsList({...pagingParams, order: 'Name'});
    }
  };
  useEffect(_watchData, [pagingParams]);

  const _clearDataOnUnmount = () => () => destroyWorkProjectsList();
  useEffect(_clearDataOnUnmount, []);
  
  const renderPageHeader = (count) => (
    <PageHeaderWrap>
        <TitleWithCounter title={'Проекты'} count={count}/>
    </PageHeaderWrap>
  );

  const handlePaginationClick = (listParams) => {
    setPagingParams(listParams);
  };

  const toggleMoreInfoPopup = (project) => {
    setMoreInfoPopupVisible(prev => !prev);
    setCurrentProject(project);
  };
  
  return (
    <LayoutBlock header={renderPageHeader(workProjectsListPagination.total)}>
    {
      (pendingState === true)
        ? <LoaderWrap>
            <Loader />
          </LoaderWrap>
        : <Table className={`${dashboardStyles.table} ${listStyles.table}`}>
          <Header>
            {
              renderHeaderRow()
            }
          </Header>
          <Body>
            {
              workProjects.map(x =>
                <Row key={x.Id} className={dashboardStyles.table__dataRow}>
                  {renderWorkProjectsListRow(x, toggleMoreInfoPopup)}
                </Row>
              )
            }
          </Body>
        </Table>
    }
      {
        moreInfoPopupVisible && <ProjectMoreInfoPopup project={currentProject} toggleModal={toggleMoreInfoPopup} />
      }
      <PaginationStyled {...workProjectsListPagination} handlePagingChange={handlePaginationClick} />
    </LayoutBlock>
  );
};

const mapStateToProps = ({ resource, modules }) => {
  return {
    workProjects: itemsToArraySelector(resource.dashboard.workProjects),
    pendingState: resource.dashboard.workProjects.pending,
    workProjectsListPagination: modules.dashboard.workProjectsList.pagination,
  };
};

const mapDispatchToProps = dispatch => ({
  getWorkProjectsList: params => dispatch(workProjectsResourceActions.list.request({ params })),
  destroyWorkProjectsList: () => dispatch(workProjectsResourceActions.destroy()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WorkProjectsList)
);
