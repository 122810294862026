// @flow
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const ProtectedRoute = ({ component: Component, isAuth, redirect = '/', ...rest }) => (
   <Route {...rest} render={(props) => (
      isAuth
          ? <Component {...props} />
          : <Redirect to={{ pathname: redirect, state: { from: props.location }}} />
   )} />
);