import React, {useState} from 'react';
import {connect} from 'react-redux';

import classNames from 'classnames';
import {descendantsModuleActions} from '../../../projects/actions';

const initialProps = {
    level: 1,
    descendants: [],
    setCurrentDescendant: () => null,
    currentDescendant: null,
    selectTrigger: () => null,
    parentId: null
};

const Tree = (props = initialProps) => {
    const [opened, setOpened] = useState([]);

    const hasChildren = (descendant) => {
        return !!descendant.Children.length;
    };

    const chooseValue = (e) => {
        e.stopPropagation();
        e.preventDefault();

        const id = e.target.dataset.id;
        const name = e.target.dataset.name;

        props.setCurrentDescendant({
            id: id,
            name: name
        });

        props.selectTrigger();
    };

    const accordionTrigger = (event, index) => {
        event.stopPropagation();
        setOpened((prev: number[]) => {
            if (Array.isArray(prev)) {
                if (prev.includes(index)) {
                    return prev.filter(i => i !== index);
                }
                return prev.concat([index]);
            }
            return prev;
        });
    };


    return (
        <React.Fragment>
            {
                props.descendants.map((descendant, index) => {

                    return (
                        <React.Fragment key={index}>
                            <li
                                data-id={descendant.Id}
                                data-name={descendant.Name}
                                onClick={chooseValue}
                                data-level={props.level}
                                style={{
                                    paddingLeft: ((props.level || 0) * 11) + (
                                        (
                                            !hasChildren(descendant) ||
                                            !descendant.ParentId ||
                                            (Number(descendant.ParentId) === Number(props.parentId))
                                        ) ? 11 : 0) + 'px'
                                }}
                                className={classNames({
                                    'opened': (opened && Array.isArray(opened) && opened.includes(index + 1)),
                                    'checked': props.currentDescendant && (
                                        Number(props.currentDescendant.id) === Number(descendant.Id)
                                    )
                                })}>

                                {hasChildren(descendant) &&
                                <i onClick={(event) => accordionTrigger(event, index + 1)}
                                   className={classNames('fo-dropdown-arrow', {
                                       'rotated': false
                                   })}/>}

                                <i className={'fo-folder'}/>

                                {descendant.Name}
                            </li>

                            {hasChildren(descendant) && (opened && Array.isArray(opened) && opened.includes(index + 1)) &&
                            <Tree {...props} descendants={descendant.Children} level={(props.level + 1 || 1) + 1}/>}
                        </React.Fragment>

                    );
                })
            }
        </React.Fragment>
    );

};

const mapStateToProps = (state) => {
    const {modules} = state;
    return {
        currentDescendant: modules.descendants.currentDescendant
    };
};

export default connect(
    mapStateToProps,
    (dispatch) => ({
        setCurrentDescendant: ({id, name}) => dispatch(descendantsModuleActions.setCurrentDescendant({id, name}))
    })
)(Tree);
