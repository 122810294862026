import { IPagination } from '../../shared/models';
import { currentUserReactionsResourceActions, workProjectsResourceActions, ownedProjectsResourceActions } from '../actions';
import { dashboardModuleActions } from '../actions/dashboard-modules';

const initialPagination: IPagination = {
    page: 1,
    pageCount: 1,
    pageSize: null,
    total: null,
};

const initialState = {
    reactionsList: {
        pagination: initialPagination
    },
    workProjectsList: {
        pagination: initialPagination
    },
    ownedProjectsList: {
        pagination: initialPagination
    },
};

export const dashboardModuleReducer = (state = initialState, action) => {
    const reduceFn = {
        [currentUserReactionsResourceActions.list.success]: () => ({
            ...state,
            reactionsList: {
                pagination: {
                    ...state.reactionsList.pagination,
                    ...action.pagination
                },
            }
        }),
        [workProjectsResourceActions.list.success]: () => ({
            ...state,
            workProjectsList: {
                pagination: {
                    ...state.workProjectsList.pagination,
                    ...action.pagination
                },
            }
        }),
        [ownedProjectsResourceActions.list.success]: () => ({
            ...state,
            ownedProjectsList: {
                pagination: {
                    ...state.ownedProjectsList.pagination,
                    ...action.pagination
                },
            }
        }),
        [dashboardModuleActions.destroy]: () => ({
            ...state,
            ...initialState,
        }),
        [currentUserReactionsResourceActions.destroy]: () => ({
            ...state,
            ...initialState,
        }),
    } [action.type];
    return reduceFn ? reduceFn() : state;
};
