// @flow

import React, { useEffect, useState } from 'react';
import { AgentsSubTableStyled } from './style';
import {getAmountRemainsIndicator} from '../../../utils';
import { jarStockResourceActions } from '../../../actions/jar-stock';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { itemsToArraySelector } from '../../../../shared/utils/selectors/resource-selectors';
import Button from '@biocad/bcd-front-ui/controls/Button';
import JarStockFileUploader from '../../Edit/JarStockForm/JarStockFileUploader';
import { attachmentsActions } from '../../../../attachments/actions';
import {AGENT_SEARCH_FORM_NAME, BcdTooltipWrapper} from '../../../../shared/components';
import { GridTable, GridTableCol } from '../../../../shared/components/GridTable';
import type { IJarStock, IJarUser } from '../../../models';
import type { IRootStore } from '../../../../app/reducers';
import {Dispatch} from "redux";
import {getFormValues} from "redux-form";


const
  renderJarUsersList = (props: { item: IJarStock }) =>
  (
    <div className={'table-cell-jar-users'}>
      {
        props.item ?.JarUsers ?.map((user: IJarUser, idx: number) => (
          <div key={`${user.UserName}__${idx}`}>
            <span className={`icon ${user.ReactionCompleted ? 'completed' : 'uncompleted'}`}>&#9679;</span>
            <Link to={`/reactions/${user.ReactionId}`}>
              <span>{user.Abbreviation}</span>
            </Link>
          </div>
        ))
      }
    </div>
  ),
  renderAttachmentsList = (props: { item: IJarStock, downloadAttachment: Function, setCurrentAvailability: Function }) =>
  {
    const
      [initTooltipShow, setInitTooltipShow] = useState(false)
    ;

    const { Attachments } = props.item;
    if (!Attachments || !Attachments.length) {
      return '';
    }

    const
      limit = 3,
      notDeletedItems = Attachments.filter(x => !x.IsDeleted),
      sliced = notDeletedItems.length > limit ? Attachments.slice(0, limit) : notDeletedItems
    ;

    return (
      <div className='attachments'>
        {
          sliced.map((attachment) =>
            <Button
              view='pseudo'
              key={attachment.Attachment.Id}
              className={'file-name'}
              title={attachment.Attachment.OriginalName}
              onAction={() => props.downloadAttachment(attachment.AttachmentId, attachment.Attachment.OriginalName)}
              text={attachment.Attachment.OriginalName}
              onMouseEnter={() => setInitTooltipShow(true)}
            >
              {
                initTooltipShow &&
                <BcdTooltipWrapper
                  align={'right'}
                  header='Нажмите, чтобы скачать файл:'
                  body={attachment.Attachment.OriginalName}
                  type='hover'
                />
              }
            </Button>
          )
        }
        {
          notDeletedItems.length > limit &&
          <Button
            view="pseudo"
            onAction={() => props.setCurrentAvailability(props.item)}
            className={'file-name'}
            text={`Ещё +${notDeletedItems.length - limit}`}
          />
        }
      </div>
    );
  }
;

interface IJarStockTableProps {
  jarStockData: IJarStock[],
  labsList: any[],
  agentId: number,
  getJarStockData: (number) => void,
  downloadFileRequest: (number, string) => void,
  searchValues?: {}
}

const defaultProps: IJarStockTableProps = {
  agentId: null,
  jarStockData: [],
  searchValues: {},
};

const setJarStockRowClassByAmount = (item: IJarStock) =>
{
  const indicator = getAmountRemainsIndicator(item);
  if (indicator)
  {
    return {
      [indicator.className]: true
    }
  }
  else {
    return {};
  }
};

const JarStockTable = (props: IJarStockTableProps = defaultProps) =>
{

  // State
  const
    [currentAvailability, setCurrentAvailability] = useState(null)
  ;
  // Effects
  useEffect(() => {
    props.getJarStockData(props.agentId);
  }, [props.agentId]);

  // Methods
  const
    clearCurrentAvailability = () =>
    (
        setCurrentAvailability(null)
    ),
    currentAgentJarStock = () =>
    (
        Array.isArray(props.jarStockData) &&
        props.jarStockData
            .filter((jar) => jar.AgentId === +props.agentId)
            .filter((jar) => props.searchValues?.isInStockOnly ? jar.Amount > 0 : true)
    ),
    downloadAttachment = (id, name) =>
    {
        if (!id || !name) return;
        props.downloadFileRequest(id, name);
    }
  ;

  return (
    <>
      <AgentsSubTableStyled
        as={GridTable}
        items={currentAgentJarStock()}
        setRowClassNames={setJarStockRowClassByAmount}
        hover={false}
        rowHeight={'auto'}
        onRowClicked={(_, __, event) => event.stopPropagation()}
        colsTemplate={'75px repeat(11, minmax(100px, 1fr))'}
      >
        <GridTableCol
          title={'Номер'}
          render={(args: { item: IJarStock }) => (
            <span>{args.item.Number}</span>
          )}
        />
        <GridTableCol
          title={'Производитель'}
          render={(args: { item: IJarStock }) => (
              args.item.ProviderReactionId
                  ? <Link to={`/reactions/${args.item.ProviderReactionId}`}>{ args.item.Provider }</Link>
                  : <span>{args.item.Provider}</span>
          )}
        />
        <GridTableCol
          title={'Квалификация'}
          render={(args: { item: IJarStock }) => (
            <span>{args.item.Qualification}</span>
          )}
        />
        <GridTableCol
          title={'Осн. вещ-во, %'}
          render={(args: { item: IJarStock }) => (
            <span>{args.item.MainAgentContent}</span>
          )}
        />
        <GridTableCol
          title={'М, моль/л'}
          render={(args: { item: IJarStock }) => (
            <span>{args.item.Concentration}</span>
          )}
        />
        <GridTableCol
          title={'Фасовка, г'}
          render={(args: { item: IJarStock }) => (
            <span>{args.item.PackageWeight}</span>
          )}
        />
        <GridTableCol
          title={'Доступно, г'}
          render={(args: { item: IJarStock }) => (
            <span>{args.item.Amount}</span>
          )}
        />
        <GridTableCol 
          title={'В заказе'}  
          render={(args: { item: IJarStock }) => (
            <span>{ args.item.IsOrdered ? 'Заказан' : '' }</span>
          )}
        />
        <GridTableCol
          title={'Расположение'}
          render={(args: { item: IJarStock }) => (
            <span>{props.labsList ? (props.labsList[args.item.LaboratoryId]?.Name ? props.labsList[args.item.LaboratoryId]?.Name : '') : ''}</span>
          )}
        />
        <GridTableCol
          title={'Место'}
          render={(args: { item: IJarStock }) => (
            <span>{args.item.Location}</span>
          )}
        />
        <GridTableCol
          title={'Склад / в работе'}
          render={(args: { item: IJarStock }) => (
            <span>{args.item ?.IsInWork ? 'В работе' : 'Склад'}</span>
          )}
        />
        <GridTableCol
          title={'Химик'}
          render={renderJarUsersList}
        />
        <GridTableCol
          title={'Файлы'}
          render={(args: { item: IJarStock }) => renderAttachmentsList({ item: args.item, downloadAttachment, setCurrentAvailability })}
        />
      </AgentsSubTableStyled>
      {
        currentAvailability !== null &&
        <JarStockFileUploader
          item={currentAvailability}
          name={currentAvailability ?.Number}
          handleClose={clearCurrentAvailability}
          viewMode={true}
        />
      }
    </>
  );
};

export default withRouter(
  connect(
      (state: IRootStore): IJarStockTableProps => ({
          labsList: state.resource.dictionary.labs.data,
          jarStockData: itemsToArraySelector(state.resource.jarStock),
          searchValues: getFormValues(AGENT_SEARCH_FORM_NAME)(state),
      }),
      (dispatch: Dispatch): IJarStockTableProps => ({
          getJarStockData: (Ids: number) => dispatch(jarStockResourceActions.list.request({ params: { Ids } })),
          downloadFileRequest: (id, name) => dispatch(attachmentsActions.download.request({ id, name })),
      })
  )(JarStockTable)
);
