// @flow

import {
	put,
	takeEvery,
	fork,
	select,
} from 'redux-saga/effects';
import {getToken} from '../../account/reducers';
import type { IRequestResourceAction } from '../../shared/utils/action-creators';
import { reactionTypesResourceActions } from '../actions';
import { ReactionTypesResourceService } from '../services';

function* getReactionType({ uriParams }: IRequestResourceAction) {
	const token = yield select(getToken);
	try {
		const result = ReactionTypesResourceService.get({ uriParams }, token);
		yield put(reactionTypesResourceActions.get.success(result));
	}
	catch (error) {
		yield put(reactionTypesResourceActions.get.failure({ error }))
	}
}

export function* listReactionTypes(action) {
	const token = yield select(getToken);
	try {
		const result = yield ReactionTypesResourceService.list(action, token);
		yield put(reactionTypesResourceActions.list.success(result));
	}
	catch (error) {
		yield put(reactionTypesResourceActions.list.failure({ error }))
	}
}

export function* getTemplateReactionTypes(action) {
	const token = yield select(getToken);
	try {
		const result = yield ReactionTypesResourceService.getTemplateReactionTypes(action, token);
		yield put(reactionTypesResourceActions.getTemplateReactionTypes.success(result));
	}
	catch (error) {
		yield put(reactionTypesResourceActions.getTemplateReactionTypes.failure({ error }))
	}
}

function* updateReactionType({ data }: IRequestResourceAction) {
	const token = yield select(getToken);
	try {
		const result = yield ReactionTypesResourceService.update({ data }, token);
		yield put(reactionTypesResourceActions.update.success(result));
	}
	catch (error) {
		yield put(reactionTypesResourceActions.update.failure({ error }))
	}
}

function* createReactionType({ data }: IRequestResourceAction) {
	const token = yield select(getToken);
	try {
		const result = yield ReactionTypesResourceService.create({ data }, token);
		yield put(reactionTypesResourceActions.create.success(result));
	}
	catch (error) {
		yield put(reactionTypesResourceActions.create.failure({ error }))
	}
}

function* deleteReactionType({ uriParams }: IRequestResourceAction) {
	const token = yield select(getToken);
	try {
		const result = yield ReactionTypesResourceService.delete({ uriParams }, token);
		yield put(reactionTypesResourceActions.delete.success(result));
	}
	catch (error) {
		yield put(reactionTypesResourceActions.delete.failure({ error }))
	}
}

export const reactionsTypeResourceWatchers = [
	function* () {
		yield takeEvery(reactionTypesResourceActions.get.request.type, function* (action) {
			yield fork(getReactionType, action);
		})
	},

	function* () {
		yield takeEvery(reactionTypesResourceActions.list.request.type, function* (action) {
			yield fork(listReactionTypes, action);
		})
	},

	function* () {
		yield takeEvery(reactionTypesResourceActions.update.request.type, function* (action) {
			yield fork(updateReactionType, action);
		})
	},

	function* () {
		yield takeEvery(reactionTypesResourceActions.create.request.type, function* (action) {
			yield fork(createReactionType, action);
		})
	},

	function* () {
		yield takeEvery(reactionTypesResourceActions.delete.request.type, function* (action) {
			yield fork(deleteReactionType, action);
		})
	},

	function* () {
		yield takeEvery(reactionTypesResourceActions.getTemplateReactionTypes.request.type, function* (action) {
			yield fork(getTemplateReactionTypes, action);
		})
	},
];
