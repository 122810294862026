import { Component }                 from 'react';
import propTypes                     from 'prop-types';
import { Subject, fromEvent }        from 'rxjs';
import Resize                        from '../../subjects/Resize';
import PageScroll                    from '../../subjects/PageScroll';
import TransitionEnd                 from '../../subjects/TransitionEnd';
import DocFullscreenChange           from '../../subjects/DocFullscreenChange';
import DocClick                      from '../../subjects/DocClick';
import constants                     from './constants.js';
import bnc                           from 'bnc';
import                                    './index.less';

var {placements} = constants,
    openSubject  = new Subject(),
    gcsCache     = new WeakMap(),
    TooltipID    = 0,
    closeTimeout,
    lastTooltip;

openSubject.subscribe( id => lastTooltip = id );

export default class Tooltip extends Component {

    state = { open: void(0) };

    layout      = React.createRef();
    holder      = React.createRef();
    container   = React.createRef();
    tail        = React.createRef();
    content     = React.createRef();


    static propTypes = {
        children:    propTypes.node.isRequired,
        listen:      propTypes.oneOf(['click', 'hover']),
        mousehold:   propTypes.bool,
        align:       propTypes.oneOf(['right', 'left']),
        place:       propTypes.oneOf(placements),
        fit:         propTypes.oneOfType([propTypes.bool, propTypes.number]),
        notail:      propTypes.bool,
        transparent: propTypes.bool,
        onOpen:      propTypes.func,
        onClose:     propTypes.func,
        className:   propTypes.oneOfType([
            propTypes.string,
            propTypes.instanceOf(bnc)
        ])
    };

    static placements = placements;

    static Click = ({children, ...rest}) => <Tooltip {...rest} open={false} listen="click">{children}</Tooltip>;

    static Hover = ({children, ...rest}) => <Tooltip {...rest} open={false} listen="hover">{children}</Tooltip>;

    static getComputedStyle = node => {
        !gcsCache.has(node) && gcsCache.set(node, window.getComputedStyle(node));
        return gcsCache.get(node);
    };

    static isOverflowed = node => {
        var cs = Tooltip.getComputedStyle(node),
            ox = cs.getPropertyValue('overflow-x'),
            oy = cs.getPropertyValue('overflow-y');
        return (ox !== 'visible' || oy !== 'visible');
    };

    static inside = (from, to, point) => ( point < from ? from : ( point > to ? to : point ) );

    static closeTimeoutMS = 200;

    static getDerivedStateFromProps = ({notail, open}, {id, open: prevOpen }) => ({
        open: (
                (prevOpen !== void(0))
                    ? prevOpen
                    : (
                        open !== void(0)
                            ? open
                            : true
                    )
        ),
        tail: notail ? 0 : parseInt(constants.tail, 10),
        id:   id || ++TooltipID
    });

    static block = new bnc.default('b-tooltip')

    cancelClose = () => closeTimeout && clearTimeout( closeTimeout )

    open  = () => {
        this.cancelClose();
        openSubject.next( this.state.id );
    }

    close = () => (
        closeTimeout = setTimeout(
            () => (lastTooltip === this.state.id) && openSubject.next( null ),
            Tooltip.closeTimeoutMS * (
                this.props.mousehold ? 2 : 1
            )
        )
    )

    toggle = () => ( this.state.open ? this.close() : this.open() )

    hold = flag => ({relatedTarget}) => {
        if (flag) {
            this.cancelClose();
        } else {
            const target = this.target();
            if ( target && relatedTarget && !target.contains(relatedTarget) ) {
                this.close();
            }
        }
    }

    stopMouseHold = () => {
        this.props.mousehold && this.mousehold && this.mousehold.length
            ? this.mousehold.forEach( sub => sub.unsubscribe() )
            : void(0);
        delete this.mousehold;
    }

    startMouseHold = () => {
        this.mousehold =
            !this.mousehold && this.props.mousehold
                ? [ fromEvent( this.layout.current, 'mouseenter' ).subscribe( this.hold(true)  ),
                    fromEvent( this.layout.current, 'mouseleave' ).subscribe( this.hold(false) ) ]
                : []
        ;
    }

    createHoverStream = () => ([
        fromEvent( this.target(), 'mouseenter'  ).subscribe( this.open ),
        fromEvent( this.target(), 'focus'       ).subscribe( this.open ),
        fromEvent( this.target(), 'mouseleave'  ).subscribe( this.close ),
        fromEvent( this.target(), 'blur'        ).subscribe( this.close )
    ])

    createClickStream = () =>
        fromEvent( this.target(), 'click'       ).subscribe( this.toggle )

    handleClickOutside = (e) => {

        let container = this.container.current;

        if (container && !container.contains(e.target) && !this.target().contains(e.target) ){
            openSubject.next( null );
        }
    }

    componentDidMount () {
        this.subscriptions = [
            DocClick.subscribe(
                this.handleClickOutside
            ),
            DocFullscreenChange.subscribe(
                () => !!this.state.open && this.forceUpdate()
            ),
            Resize.subscribe(
                () => !!this.state.open && this.forceUpdate()
            ),
            PageScroll.subscribe(
                () => !!this.state.open && this.forceUpdate()
            ),
            TransitionEnd.subscribe(
                () => !!this.state.open && this.forceUpdate()
            ),
            openSubject
                .subscribe(
                    id => {
                        const open = this.state.id === id;
                        (open !== this.state.open) && this.setState({open});
                    }
                )
        ];

        if (this.props.listen === 'click') {
            this.subscriptions.push(
                this.createClickStream()
            );
        }

        if (this.props.listen === 'hover') {
            this.subscriptions.push(
                ...this.createHoverStream()
            );
        }

        this.cancelClose();
        this.state.open && openSubject.next(this.state.id);
    }

    componentDidUpdate (prevProps, { open: prevStateOpen }) {

        //if (!prevStateOpen && !open) { return; }

        var {open}       = this.state,
            targetRect   = this.rectPosition( this.target() ),
            viewports    = this.viewports( this.target() ),
            gViewport    = this.gViewport(),
            intersection = this.intersect( targetRect, {...viewports, gViewport} ),
            fit          = this.props.fit && this.fitByTarget(targetRect, gViewport, this.props.fit, this.state.tail), // eslint-disable-line no-unused-vars
            layoutRect   = this.rectPosition( this.layout.current );

        if (open && intersection && layoutRect) {

            const places = Tooltip.placements
                                .map(this.getPlace({ intersection, layoutRect, gViewport }, this.state, this.props))
                                .filter(({exists}) => !!exists);

            const [ place ] = this.props.place
                                ? places.filter( ({place}) => (place === this.props.place) )
                                : places.sort( ({weight: a}, {weight: b}) => b - a )
            ;

            if (place){
                this.positionLayoutInPlace(place);
                this.startMouseHold();
            } else {
                this.hide();
                this.stopMouseHold();
            }
        } else {
            this.hide();
            this.stopMouseHold();
        }

        if (prevStateOpen !== open) {
            this[ open ? 'onOpen' : 'onClose' ](this.state.id);
        }

        //console.log('<T> CDU', this.state.id, open, prevStateOpen);
    }

    componentWillUnmount() {
        this.subscriptions.forEach(
            sub => sub.unsubscribe()
        );
    }

    target = () => this.holder.current.parentNode

    rectPosition = node => {
        if (node) {
            var cs                = Tooltip.getComputedStyle( node ),
                csProp            = prop => parseInt( cs.getPropertyValue( prop ), 10),
                rect              = node.getBoundingClientRect(),
                [ tbo,   lbo    ] = [ 'border-top-width', 'border-left-width' ].map(csProp),
                [ top,   left   ] = [ rect.top + tbo,   rect.left + lbo   ].map(Math.floor),
                [ width, height ] = [ node.clientWidth, node.clientHeight ].map(Math.floor);
            return { top, left, width, height };
        } else {
            return null;
        }
    }

    changePlaceClass = (layout, place) => {
        layout.className = Array
            .from   ( layout.classList                             )
            .filter ( classes => classes.indexOf( 'place' ) === -1 )
            .concat ( [`${ Tooltip.block.mod('place', place ) }`]  )
            .join   ( ' ' );
    }

    positionLayoutInPlace = ({left, top, place, tail}) => {
        this.changePlaceClass(this.container.current, place);
        this.layout.current.setAttribute( 'style',  `top: ${Math.round(top)}px; left: ${Math.round(left)}px;`);
        tail && this.tail.current && this.tail.current.setAttribute( 'style', `top: ${Math.round(tail.top)}px; left: ${Math.round(tail.left)}px;` );
    }

    hide = () => {
        this.layout.current && this.layout.current.setAttribute(
            'style',
            'visibility: hidden; z-index: -1;'
        );
        this.tail.current && this.tail.current.setAttribute(
            'style',
            'visibility: hidden; z-index: -1;'
        );
    }

    fitByTarget = ({width, height, top}, {width:gWidth, height:gHeight}, fit, tail) =>
        this.content.current && this.content.current.setAttribute(
            'style',
            `max-height: ${  Math.max( top - tail*3, gHeight - top - height - tail*3 ) - 1 }px; min-width: ${width}px; max-width: ${fit === 0 ? gWidth : Math.min(width, gWidth)}px; `//overflow: auto;
        )

    intersect = (rect, viewports) => {
        var [ top, bottom,
              left, right ] = [ 0, 0, 0, 0 ],
            exists          = true,
            weight          = { top, bottom, right, left },
            iRect           = { ...rect, exists, weight  };

        Object
            .values( viewports )
            .forEach(
                ({height,left,top,width}) => {
                    if ( !iRect.exists ) {
                        return;
                    } else if (
                        ( iRect.top  + iRect.height <= top  ) ||
                        ( iRect.left + iRect.width  <= left ) ||
                        ( iRect.top  >= (top  + height) ) ||
                        ( iRect.left >= (left + width ) )
                    ) {
                        iRect.exists = false;
                        return;
                    } else {
                        if (iRect.top < top) {
                            iRect.height         = iRect.height - (top - iRect.top);
                            iRect.top            = top;
                            iRect.weight.top    -= 2;
                            iRect.weight.left   -= 1;
                            iRect.weight.right  -= 1;
                        }
                        if ( (iRect.top + iRect.height) > (top + height) ) {
                            iRect.height        -= (iRect.top + iRect.height) - (top + height);
                            iRect.weight.bottom -= 2;
                            iRect.weight.left   -= 1;
                            iRect.weight.right  -= 1;
                        }
                        if (iRect.left < left) {
                            iRect.width          = iRect.width - (left - iRect.left);
                            iRect.left           = left;
                            iRect.weight.left   -= 2;
                            iRect.weight.top    -= 1;
                            iRect.weight.bottom -= 1;
                        }
                        if ( (iRect.left + iRect.width) > (left + width) ) {
                            iRect.width         -= (iRect.left + iRect.width) - (left + width);
                            iRect.weight.right  -= 2;
                            iRect.weight.top    -= 1;
                            iRect.weight.bottom -= 1;
                        }
                    }
                }
            );

        return iRect.exists ? iRect : null;
    }

    getPlace_right = ({intersection, layoutRect, gViewport, tail, align}) => {
        var place = {exists:false};
        if ( (intersection.left + intersection.width + tail + layoutRect.width) < (gViewport.left + gViewport.width ) ) {
            place.left  = intersection.left + intersection.width + tail;
            place.top   = intersection.top + (
                            align === 'left'
                                ? 0
                                : align === 'right'
                                    ? intersection.height - layoutRect.height
                                    : (intersection.height/2) - (layoutRect.height/2)
                        );

            place.top   = Tooltip.inside(
                gViewport.top,
                gViewport.top + gViewport.height - layoutRect.height,
                place.top
            );
            place.tail = {
                top:  ( intersection.top + (intersection.height/2) ) - (tail/2),
                left: ( intersection.left + intersection.width )
            };
            place.exists = true;
        }
        return place;
    }

    getPlace_left = ({intersection, layoutRect, gViewport, tail, align}) => {
        var place = {exists:false};
        if ( (intersection.left - tail - layoutRect.width) > gViewport.left ) {
            place.left   = intersection.left - tail - layoutRect.width;
            place.top    = intersection.top + (
                            align === 'left'
                                ? 0
                                : align === 'right'
                                    ? intersection.height - layoutRect.height
                                    : (intersection.height/2) - (layoutRect.height/2));

            place.top   = Tooltip.inside(
                gViewport.top,
                gViewport.top + gViewport.height - layoutRect.height,
                place.top
            );
            place.tail = {
                top:  ( intersection.top + (intersection.height/2) ) - (tail/2),
                left: ( intersection.left - tail )
            };
            place.exists = true;
        }
        return place;
    }

    getPlace_bottom = ({intersection, layoutRect, gViewport, tail, align}) => {
        var place = {exists:false};
        if ( (intersection.top + intersection.height + tail + layoutRect.height) < (gViewport.top + gViewport.height ) ) {
            place.top    = intersection.top  + intersection.height + tail;
            place.left   = intersection.left + (
                            align === 'left'
                                ? 0
                                : align === 'right'
                                    ? intersection.width - layoutRect.width
                                    : (intersection.width/2) - (layoutRect.width/2));
            place.left   = Tooltip.inside(
                gViewport.left,
                gViewport.left + gViewport.width - layoutRect.width,
                place.left
            );
            place.tail = {
                top:  place.top - tail,
                left: ( intersection.left + (intersection.width/2) ) - (tail/2)
            };
            place.exists = true;
        }
        return place;
    }

    getPlace_top = ({intersection, layoutRect, gViewport, tail, align}) => {
        var place = {exists:false};
        if ( intersection.top - tail - layoutRect.height < (gViewport.top + intersection.top)  ) {
            place.top    = intersection.top - tail - layoutRect.height;
            place.left   = intersection.left + (
                            align === 'left'
                                ? 0
                                : align === 'right'
                                    ? intersection.width - layoutRect.width
                                    : (intersection.width/2) - (layoutRect.width/2));

            place.left   = Tooltip.inside(
                gViewport.left,
                gViewport.left + gViewport.width - layoutRect.width,
                place.left
            );
            place.tail = {
                top:  intersection.top - tail,
                left: ( intersection.left + (intersection.width/2) ) - (tail/2)
            };
            place.exists = true;
        }
        return place;
    }

    getPlace = ({ intersection, layoutRect, gViewport }, {tail}, {align}) => place => ({
        place,
        weight: intersection.weight[place],
     ...this[`getPlace_${place}`]( {intersection, layoutRect, gViewport, tail, align} )
    })

    onOpen  = () => this.props.onOpen  && this.props.onOpen(this.state.id)

    onClose = () => this.props.onClose && this.props.onClose(this.state.id)

    gViewport = () => (
        document.fullscreenElement
            ? document.fullscreenElement.getBoundingClientRect()
            : ({
                top:     0,
                left:    0,
                width:   window.innerWidth,
                height:  window.innerHeight
            })
    )


    viewports = (node, acc = {} ) => {
        var over = 0;
        do {
            if (Tooltip.isOverflowed(node)){
                var {top, left, width, height} = this.rectPosition( node );
                acc[ `over${ over ++ } : ${ node.getAttribute('class') }` ] = {top, left, width, height};
            }
            node = node.parentNode;
        } while ( node !== document.fullscreenElement && node !== document.body );

        return acc;
    }


    renderTooltip = ({open}, {children, listen, notail, className, transparent, align}) => (
        open
            ? ReactDom.createPortal(
                <div
                    ref       = {this.container}
                    className = {
                        Tooltip.block +
                        ( align     ? Tooltip.block.mod(`align-${align}`) : '' ) +
                        ( listen    ? Tooltip.block.mod('listen', listen) : '' ) +
                        ( className ? className : '' )
                    }
                >
                    { !notail &&  <div ref={this.tail} className={Tooltip.block.el('tail')} /> }
                    <div
                        ref       = {this.layout}
                        className = {Tooltip.block.el('layout')}
                    >
                        <div
                            ref       = {this.content}
                            className = {
                                Tooltip.block.el('content') +
                                Tooltip.block.el('content').mod('transparent', transparent ? 'true' : 'false')
                            }>
                            {children}
                        </div>
                    </div>
                </div>,
                document.fullscreenElement || document.body
            )
            : null
    )

    render = () =>
        <div ref={this.holder} className={Tooltip.block.el('holder')}>
            { this.renderTooltip(this.state, this.props) }
        </div>
}

Tooltip.Click.displayName = 'Tooltip.Click';
Tooltip.Hover.displayName = 'Tooltip.Hover';
