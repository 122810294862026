// @flow
import { Model, ArrayModel } from 'objectmodel';
import { StageView, SchemeView, ReactionSummary, IStageView, ISchemeView, IReactionSummary } from './scheme-view';
import { atomSplit } from '../../shared/services/compound/molar-weight-calculator';

interface _ISchemeTreeNode<TChild> {
    TopLevelSchemeId?: number;

    SchemeId?: number;
    SchemeCode?: String;
    SchemeNumber: number;

    StageId?: number;
    TreeNumber: number;
    ExpectedReactionTypeId?: number;

    Formula: string;
    Thumbnail: string;
    Name: string;
    MolecularFormula: string;
    SmilesFormula: string;

    ReactionSummary?: IReactionSummary;

    TreeWidth: number;
    TreeHeight: number;

    Size: number;
    ArrowSize: number;

    Left: number;
    Top: number;

    Children: TChild[];

    RequiredPurificationsCount: number;
}

export type ISchemeTreeNode = _ISchemeTreeNode<ISchemeTreeNode>;

export const SchemeTreeNode = Model({
    TopLevelSchemeId: [Number],

    SchemeId: [Number],
    SchemeCode: [String],
    SchemeNumber: Number,

    StageId: [Number],
    TreeNumber: Number,
    ExpectedReactionTypeId: [Number],

    Formula: [String, null],
    Thumbnail: String,
    Name: [String],
    MolecularFormula: [String, null],
    SmilesFormula: [String, null],

    ReactionSummary: [ReactionSummary],

    CarbonCount: Number,

    TreeWidth: Number,
    TreeHeight: Number,

    Size: Number,
    ArrowSize: Number,

    Left: Number,
    Top: Number,

    Children: ArrayModel([Object]),

    RequiredPurificationsCount: [Number],
});

export function buildSchemeViewTree(scheme: ISchemeView): ISchemeTreeNode | null {
    const treeRoot = buildTree(scheme.Stages, scheme, scheme.SchemeId, 0);

    calcCoordinates(treeRoot, treeRoot.TreeWidth, 0);

    return treeRoot;
}

const smallTileSize = 128;
const mediumTileSize = smallTileSize * 1.5;
const largeTileSize = smallTileSize * 2;

const mediumTileThreshold = 10;
const largeTileThreshold = 21;

const simpleArrowLength = smallTileSize / 2;
const compoundArrowStartLength = simpleArrowLength / 2;
const compoundArrowEndLength = simpleArrowLength;

const textSize = smallTileSize / 8;

const arrowHeadHeight = smallTileSize / 16;
const arrowHeadWidth = smallTileSize / 8;

const subtreeDistance = smallTileSize / 2;
const externalBorder = smallTileSize / 4;

export const schemeGridSizes = {
    smallTileSize: smallTileSize,
    mediumTileSize: mediumTileSize,
    largeTileSize: largeTileSize,

    mediumTileThreshold: mediumTileThreshold,
    largeTileThreshold: largeTileThreshold,

    arrowHeadHeight: arrowHeadHeight,
    arrowHeadWidth: arrowHeadWidth,

    simpleArrowLength: simpleArrowLength,
    compoundArrowStartLength: compoundArrowStartLength,
    compoundArrowEndLength: compoundArrowEndLength,

    textSize: textSize,

    subtreeDistance: subtreeDistance,
    externalBorder: externalBorder,
};

function getCarbonCount(molFormulaParam): number {
    const molFormula = molFormulaParam || '';
    const carbonAtom = atomSplit(molFormula).find(({ atom }) => atom === 'C');
    return carbonAtom ? carbonAtom.count : 0;
}

function buildTree(stages: IStageView[], scheme: ISchemeView, topLevelSchemeId: number, d: number): ISchemeTreeNode | null {
    const stage = stages[0];

    const mergedChildren = stage.MergedSchemes?.length >= 1
        ? stage.MergedSchemes.map((s) => (buildTree(s.Stages, s, topLevelSchemeId, d + 1)))
        : [];

    const stagesLeft = stages.slice(1);

    const children = stagesLeft.length >= 1
        ? [buildTree(stagesLeft, scheme, topLevelSchemeId, d + 1), ...mergedChildren]
        : mergedChildren;

    const childrenCount = children.length;

    const chilrenWidths = children.map(({ TreeWidth }) => TreeWidth);
    const childrenMaxWidth = Math.max(0, ...chilrenWidths);

    const childrenHeigth = children.reduce((res, { TreeHeight }) => (res + TreeHeight), Math.max(0, (childrenCount - 1)) * subtreeDistance);

    const arrowSize =
        childrenCount <= 0
            ? 0
            : childrenCount <= 1 ? simpleArrowLength
                : compoundArrowStartLength + compoundArrowEndLength;

    const carbonCount = getCarbonCount(stage.MolecularFormula);

    const size =
        carbonCount >= largeTileThreshold
            ? largeTileSize
            : carbonCount >= mediumTileThreshold
                ? mediumTileSize
                : smallTileSize;

    const root = new SchemeTreeNode({
        TopLevelSchemeId: topLevelSchemeId,

        SchemeId: scheme.SchemeId,
        SchemeCode: scheme.Code,
        SchemeNumber: scheme.Number,

        StageId: stage.StageId,
        TreeNumber: stage.TreeNumber,
        ExpectedReactionTypeId: stage.ExpectedReactionTypeId,

        Formula: stage.Formula,
        Thumbnail: stage.Thumbnail,
        Name: stage.Name,
        MolecularFormula: stage.MolecularFormula,
        SmilesFormula: stage.SmilesFormula,

        ReactionSummary: stage.ReactionSummary,

        TreeWidth: childrenMaxWidth + arrowSize + size,
        TreeHeight: Math.max(size, childrenHeigth),

        CarbonCount: carbonCount,

        Size: size,
        ArrowSize: arrowSize,

        Left: 0,
        Top: 0,

        Children: children,

        RequiredPurificationsCount: stage.RequiredPurificationsCount,
    });

    return root;
}

function calcCoordinates(node: ISchemeTreeNode, rightBorder: number, topBorder: number): ISchemeTreeNode {
    const newRightBorder = rightBorder - node.Size - node.ArrowSize;
    let newTopBorder = topBorder;

    for (let childNode of node.Children) {
        calcCoordinates(childNode, newRightBorder, newTopBorder);

        newTopBorder += childNode.TreeHeight + subtreeDistance;
    }

    node.Left = rightBorder - node.Size;

    if (node.Children.length >= 1) {
        const firstChild = node.Children[0];
        const lastChild = node.Children[node.Children.length - 1];

        node.Top = (firstChild.Top + lastChild.Top + lastChild.Size) / 2 - (node.Size / 2);
    } else {
        node.Top = topBorder;
    }
}