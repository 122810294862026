// @flow
import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {schemeModuleActions, schemesResourceActions} from '../../actions';
import { SchemeView, IReactionSummary } from '../../models/scheme-view';
import { buildSchemeViewTree, schemeGridSizes, ISchemeTreeNode } from '../../models/scheme-tree';
import { getStyleColors } from '../../../shared/utils/colors'
import { withRouter } from 'react-router-dom';
import { ReactionSummaryWindow } from '../ReactionSummary'
import './scheme-tree.less';
import {uuidv4} from '../../../shared/utils/common';
import {JarAmountsByLabsRequest} from '../../../agents/models/jar-amounts-by-labs';
import { JarAmountsByLaboratoriesTable } from './JarAmountsByLaboratoriesTable';
import type {IRootStore} from '../../../app/reducers';
import { throttle } from 'lodash'
import { setJarStockRowClassByAmount } from '../../../reactions/components/ReactionSpendingsDialog/SpendingsForm';

const fabric = window.fabric;

const simpleArrowLength = schemeGridSizes.simpleArrowLength;
const compoundArrowStartLength = schemeGridSizes.compoundArrowStartLength;
const compoundArrowEndLength = schemeGridSizes.compoundArrowEndLength;

const arrowHeadHeight = schemeGridSizes.arrowHeadHeight;
const arrowHeadWidth = schemeGridSizes.arrowHeadWidth;

const textSize = schemeGridSizes.textSize;
const summaryWindowWidth = 384;

const strokeProp = 'stroke';
const fillProp = 'fill';
const cursorPointer = 'pointer';
const originCenter = 'center';

const chemIconSvg = `
<svg
   xmlns="http://www.w3.org/2000/svg"
   viewBox="0 0 20 20">
  <path
     d="M 10,0 C 4.4868355,0 0,4.4868351 0,10 0,15.513165 4.4868355,20 10,20 15.513164,20 20,15.513165 20,10 20,4.486835 15.513164,0 10,0 Z M 9.952641,1.1945741 c 4.62955,0 8.852851,4.1817845 8.852851,8.8113349 0,4.629551 -4.183347,8.73202 -8.8128977,8.73202 -4.6295506,0 -8.6944401,-4.158512 -8.6944401,-8.7880624 0,-4.6295506 4.0249368,-8.7552925 8.6544868,-8.7552925 z M 7.6985676,3.8867189 c -0.296878,0 -0.5338541,0.2402315 -0.5338541,0.5371092 v 0.358073 c 0,0.296878 0.2369761,0.5338543 0.5338541,0.5338543 H 7.8776041 V 8.6881509 L 5.2604166,12.89388 c -0.6540242,1.05135 0.1033188,2.421875 1.344401,2.421875 H 13.4375 c 1.243316,0 1.99517,-1.372757 1.341146,-2.421875 L 12.164713,8.6881509 V 5.3157551 h 0.179037 c 0.296877,0 0.533854,-0.2369761 0.533854,-0.5338541 V 4.4238281 c 0,-0.2968777 -0.236977,-0.5371092 -0.533854,-0.5371092 z"
  />
</svg>
`;

interface ISchemeViewProps {
    schemeView: SchemeView,
    history: {},
    onSelectStage: (stageId) => null,
    onSelectCompound: (compound) => null,
    setExportedData: (string) => null,
    exportInvoker: {},
}

const SchemeImageExportType = {
    ExportSchemeImage: 'ExportSchemeImage',
    SaveSchemeToBrowser:  'SchemeSaveSchemeToBrowserSaveToBrowser',
}


let SchemeTree = (props: ISchemeViewProps) => {
    const colors = getStyleColors();
    const { schemeView, history, onSelectStage } = props;

    const dispatch = useDispatch();
    const
        addJarAmountBtn = (uuid, SmilesFormula, CompoundName) => dispatch(schemeModuleActions.addJarAmountBtn({ uuid, SmilesFormula, CompoundName })),
        loadSingleAgentJarAmount = (uuid) => dispatch(schemeModuleActions.loadSingleAgentJarAmount({ uuid }))
    ;

    const canvasRef = useRef();
    const divRef = useRef();

    const [selected, setSelected] = useState(null);
    const [displayingSummary, setDisplayingSummary] = useState(null);
    const [canvas, setCanvas] = useState(null);
    const [initDone, setInitDone] = useState(false);
    const [jarAmountsBtnsToParamsMap, setJarAmountsBtnsToParamsMap] = useState(new Map);

    const jarAmountsFromStore = useSelector((state: IRootStore) => state.modules.scheme.schemeTree.jarAmounts);

    const btnIconColor = getComputedStyle(document.documentElement).getPropertyValue('--btn-icon-color');
    const btnIconColorHover = getComputedStyle(document.documentElement).getPropertyValue('--btn-icon-hover');

    const throttleJarAmountsRender = throttle(
        () => {
            setJarAmountsBtnsToParamsMap(prev => new Map(prev));
        }, 50
    );

    function selectNode(node) {
        setSelected(node);
        onSelectStage(node.StageId);
    }

    function clearSelection() {
        for (let { text, textCircle, textCircleGradient } of canvas.SelectableNodes) {
            textCircle.set(strokeProp, new fabric.Gradient({
                type: 'linear',
                gradientUnits: 'pixels',
                coords: { x1: textCircleGradient.x1,
                    y1: textCircleGradient.y1,
                    x2: textCircleGradient.x2,
                    y2: textCircleGradient.y2 },
                colorStops: textCircleGradient.colorStops
              }));


            textCircle.set(fillProp, 'transparent');            
            text.set({fill: '#000'});
        }
    }

    function getArrowColorStops(node: ISchemeTreeNode) {
        const emptyColor = '#aba8ad';
        const CreateColorStops = (startColor: string, endColor: string) => ([{color: startColor, opacity: 1, offset: 0}, {color: endColor, opacity: 1, offset: 0} ]);
        const summary = node?.ReactionSummary;

        if (summary?.WithExpYieldAmount > 0)
            return CreateColorStops(colors.hasYieldColor, colors.hasYieldColor);

        if (summary?.NonFinishedAmount > 0)
            return CreateColorStops(colors.uncompletedColor, colors.uncompletedColor);

        if (summary?.WithoutExpYieldAmount > 0)
            return CreateColorStops(colors.noYieldColor, colors.noYieldColor);

        if (summary?.TrialSuccessfulAmount > 0)
            return CreateColorStops(colors.trialColor, colors.hasYieldColor);

        if (summary?.TrialIncompleteAmount > 0)
            return CreateColorStops(colors.trialColor, colors.trialColor);

        if (summary?.TrialFailedAmount > 0)
            return CreateColorStops(colors.trialColor, colors.noYieldColor);

        return CreateColorStops(emptyColor, emptyColor);
    }

    function drawArrow(node: ISchemeTreeNode) {
        const strokeWidth = 2;

        const lineStartWidth = node.Children.length == 1 ? 0 : compoundArrowStartLength;
        const lineEndWidth = node.Children.length == 1 ? simpleArrowLength : compoundArrowEndLength;

        const lineLeft = node.Left - lineEndWidth;

        const arrowEndLeft = node.Left;
        const arrowEndTop = node.Top + node.Size / 2;

        const colorStops = getArrowColorStops(node);

        const arrowHead = new fabric.Polygon([
            { x: arrowEndLeft - arrowHeadWidth, y: arrowEndTop - arrowHeadHeight / 2 },
            { x: arrowEndLeft - arrowHeadWidth, y: arrowEndTop + arrowHeadHeight / 2 },
            { x: arrowEndLeft, y: arrowEndTop },
        ], {
            selectable: false,
            hoverCursor: cursorPointer,
            fill: 'blue',
        });

        
        arrowHead.set('fill', new fabric.Gradient({
            type: 'linear',
            gradientUnits: 'pixels',
            coords: { x1: -lineStartWidth - lineEndWidth + arrowHeadWidth,
                y1: 0,
                x2: arrowHeadWidth,
                y2: 0 },
            colorStops: colorStops
          }));



        const mouseUpArrow = (opt) => {
            opt.e.preventDefault();
            opt.e.stopPropagation();

            props.history.push(`/stages/${node.StageId}`);
        }

        canvas.add(arrowHead);

        const line = new fabric.Line([
            lineLeft, // first poit left
            arrowEndTop, // first poit top
            node.Left - arrowHeadWidth,
            arrowEndTop
        ], {
            selectable: false,
            hoverCursor: cursorPointer,
            stroke: 'blue',
            strokeWidth: strokeWidth,
            originY: originCenter,
        });

        
        line.set(strokeProp, new fabric.Gradient({
            type: 'linear',
            gradientUnits: 'pixels',
            coords: { x1: -lineStartWidth,
                y1: 0,
                x2: lineEndWidth,
                y2: 0 },
            colorStops: colorStops
          }));
          

        canvas.add(line);

        const textLeft = node.Left - compoundArrowEndLength / 2;
        const textTop = arrowEndTop - textSize - arrowHeadHeight;

        const textCircle = new fabric.Circle({
            selectable: false,
            hoverCursor: cursorPointer,
            originX: originCenter,
            originY: originCenter,
            left: textLeft,
            top: textTop,
            radius: textSize,
            fill: 'transparent',
            strokeWidth: strokeWidth,
        });
        const textCircleGradient = {
            x1: -lineStartWidth,
            y1: 0,
            x2: lineEndWidth,
            y2: 0,
            colorStops: colorStops
        };

        
        textCircle.set(strokeProp, new fabric.Gradient({
            type: 'linear',
            gradientUnits: 'pixels',
            coords: { x1: -lineStartWidth,
                y1: 0,
                x2: lineEndWidth,
                y2: 0 },
            colorStops: colorStops
          }));

        canvas.add(textCircle);

        const text = new fabric.Text('' + node.TreeNumber, {
            selectable: false,
            hoverCursor: cursorPointer,
            fontFamily: 'open-sans',
            fontSize: textSize,
            color: 'black',
            originX: originCenter,
            originY: originCenter,
            left: textLeft,
            top: textTop,
        });
        canvas.add(text);

        canvas.SelectableNodes.push({ text, textCircle, textCircleGradient });

        const mouseDown = (opt) => {
            canvas.isDragging = false;

            opt.e.preventDefault();
            opt.e.stopPropagation();
        }
        const mouseUp = (opt) => {
            opt.e.preventDefault();
            opt.e.stopPropagation();
            clearSelection();


            textCircle.set(fillProp, new fabric.Gradient({
                type: 'linear',
                gradientUnits: 'pixels',
                coords: { x1: -lineStartWidth,
                    y1: 0,
                    x2: lineEndWidth,
                    y2: 0 },
                colorStops: colorStops
              }));

            text.set({fill: '#fff'});

            selectNode(node);

            setTimeout(() => {
                canvas.calcOffset();
                canvas.requestRenderAll();
            }, 0);
        }

        const mouseOver = (opt) => {
            const rect = canvasRef.current.getBoundingClientRect();
            const left = textCircle.oCoords.br.x + rect.left;
            const top = textCircle.oCoords.br.y + rect.top;
            setDisplayingSummary({ node, left, top });
        }

        const mouseOut = (opt) => {
            setDisplayingSummary(null);
        }

        arrowHead.on('mousedown', mouseDown);
        arrowHead.on('mouseup', mouseUpArrow);
        text.on('mouseup', mouseUp);
        text.on('mousedown', mouseDown);
        text.on('mouseup', mouseUp);
        text.on('mouseover', mouseOver);
        text.on('mouseout', mouseOut);
        textCircle.on('mousedown', mouseDown);
        textCircle.on('mouseup', mouseUp);
        textCircle.on('mouseover', mouseOver);
        textCircle.on('mouseout', mouseOut);

        if (node.Children.length > 1) {
            const firstChild = node.Children[0];
            const lastChild = node.Children[node.Children.length - 1];

            const verticalLineStartTop = firstChild.Top + firstChild.Size / 2 - strokeWidth / 2;
            const verticalLineEndTop = lastChild.Top + lastChild.Size / 2 + strokeWidth / 2;
            const verticalLineLeft = node.Left - compoundArrowEndLength;

            const verticalLine = new fabric.Line([
                verticalLineLeft, // First point
                verticalLineStartTop, // First point
                verticalLineLeft, // Second point
                verticalLineEndTop, // Second point
            ], {
                selectable: false,
                hoverCursor: cursorPointer,
                stroke: 'blue',
                strokeWidth: strokeWidth,
                originX: originCenter,
            });

            
            verticalLine.set(strokeProp, new fabric.Gradient({
                type: 'linear',
                gradientUnits: 'pixels',
                coords: { x1: -lineStartWidth,
                    y1: 0,
                    x2: lineEndWidth,
                    y2: 0 },
                colorStops: colorStops
              }));

            canvas.add(verticalLine);

            for (let childNode of node.Children) {
                const childLineTop = childNode.Top + childNode.Size / 2;
                const childLineLeft = verticalLineLeft - compoundArrowStartLength;

                const childLine = new fabric.Line([
                    verticalLineLeft, // First point
                    childLineTop, // First point
                    childLineLeft, // Second point
                    childLineTop, // Second point
                ], {
                    selectable: false,
                    hoverCursor: cursorPointer,
                    stroke: 'blue',
                    strokeWidth: 2,
                    originY: originCenter,
                });

                
                childLine.set(strokeProp, new fabric.Gradient({
                    type: 'linear',
                    gradientUnits: 'pixels',
                    coords: { x1: 0,
                        y1: 0,
                        x2: lineStartWidth + lineEndWidth,
                        y2: 0 },
                    colorStops: colorStops
                  }));

                canvas.add(childLine);
            }
        }
    }

    function setUpObjectAsGraphics(obj, node) {        
        if(node.Size < 140)
            obj.scaleToWidth(140);
        else
            obj.scaleToWidth(node.Size);
        obj.set({
            selectable: false,
            left: node.Left,
            top: node.Top,
        });
    }

    function setUpImage(obj, node) {
        if (obj.width > obj.height) {
            obj.scaleToWidth(node.Size);
        } else { 
            obj.scaleToWidth(node.Size); 
        }
        obj.set({
            selectable: false,
            left: node.Left,
            top: node.Top,
        });
    }

    function setUpObjectAsText(obj, node) {
        const textScaleFactor = 6;
        const textOffsetFactor = 0.25;
        obj.scaleToHeight(textScaleFactor);
        obj.set({
            selectable: false,
            top: node.Top + node.Size * textOffsetFactor,
            left: node.Left + node.Size * textOffsetFactor,
        });
    }

    function addJarAmountIconButton(left: number, top: number, node: ISchemeTreeNode)
    {
        if (!node?.SmilesFormula && !node?.Name) return;
        fabric.loadSVGFromString(chemIconSvg, (objects, options) => {
            const btn = fabric.util.groupSVGElements(objects, { ...options, hoverCursor: cursorPointer });
            btn.set({
                selectable: false,
                hoverCursor: cursorPointer,
                originX: originCenter,
                originY: originCenter,
                fill: btnIconColor || 'black',
                left,
                top,
            });
            btn.on('mouseover', () => {
                btn.set({
                    fill: btnIconColorHover  || 'black'
                });
                canvas.renderAll();
            });
            btn.on('mouseout', () => {
                btn.set({
                    fill: btnIconColor  || 'black'
                });
                canvas.renderAll();
            });
            setJarAmountsBtnsToParamsMap(prev =>
                (new Map(prev)).set(btn, { params: JarAmountsByLabsRequest(node), uuid: null })
            );
            canvas.add(btn);
        });
    }

    function removeGradients(svg) {
        let ind1 = svg.indexOf("<linearGradient");
        while(ind1 > 0) {
            let ind2 = svg.indexOf("</linearGradient>");
            svg = svg.substring(0, ind1) + svg.substring(ind2+17);
            ind1 = svg.indexOf("<linearGradient");
        }
        return svg;
    }

    function addTiles(node: ISchemeTreeNode) {
        try {
            if (node.Thumbnail) {
                const mouseUpHandler = (opt) =>
                {
                    opt.e.preventDefault();
                    opt.e.stopPropagation();
                    props.onSelectCompound(node);
                };
                if (node.Thumbnail.startsWith('data:image/svg+xml')) {
                    const encodedSvg = node.Thumbnail.split(',')[1];
                    let svg = removeGradients(decodeURIComponent(escape(window.atob(encodedSvg))));
                    
                    fabric.loadSVGFromString(svg, (objects, options) => {                        
                        const obj = fabric.util.groupSVGElements(objects, { ...options, hoverCursor: cursorPointer });

                        //Фикс для веществ из одного атома, которые в svg представлены просто
                        //как текст и поэтому неправильно масштабируются через scaleToHeight
                        if ('text' in obj) {
                            setUpObjectAsText(obj, node);
                        } else {
                            setUpObjectAsGraphics(obj, node);
                        }

                        obj.on('mouseup', mouseUpHandler);

                        canvas.add(obj);
                        addJarAmountIconButton(obj.left, obj.top, node);
                    });
                }
                else {
                    fabric.Image.fromURL(node.Thumbnail, function (obj) {
                        setUpImage(obj, node);

                        obj.on('mouseup', mouseUpHandler);

                        canvas.add(obj);

                        addJarAmountIconButton(obj.left, obj.top, node);
                    });
                }
            }

            if (node.Children.length >= 1) {
                drawArrow(node);

                for (let childNode of node.Children) {
                    addTiles(childNode);
                }
            }
        } catch (error) {
            console.error('error:', error);
        }
    }

    function renderData() {
        if (schemeView) {

            if (!schemeView.Stages || schemeView.Stages.length === 0) {
                return;
            }

            canvas.SelectableNodes = [];
            const tree = buildSchemeViewTree(schemeView);

            addTiles(tree);
        }

        try {
            canvas.renderAll();
        } catch (error) {
            console.error('error:', error);
        }
    }

    function init() {
        canvas.selection = false;
        canvas.backgroundColor = "white";
        canvas.on('mouse:down', (opt) => {
            const evt = opt.e;
            canvas.isDragging = true;
            canvas.lastPosX = evt.clientX;
            canvas.lastPosY = evt.clientY;

        });
        canvas.on('mouse:up', (opt) => {
            canvas.isDragging = false;

            setDisplayingSummary(null);

            canvas.zoomToPoint({ x: 0, y: 0 }, canvas.getZoom());

            canvas.calcOffset();
            canvas.requestRenderAll();

            setTimeout(() => {
                canvas.calcOffset();
                canvas.requestRenderAll();
            }, 0);

        });
        canvas.on('mouse:move', (opt) => {
            if (canvas.isDragging) {
                const e = opt.e;
                canvas.viewportTransform[4] += e.clientX - canvas.lastPosX;
                canvas.viewportTransform[5] += e.clientY - canvas.lastPosY;
                canvas.lastPosX = e.clientX;
                canvas.lastPosY = e.clientY;

                setDisplayingSummary(null);

                canvas.zoomToPoint({ x: 0, y: 0 }, canvas.getZoom());

                canvas.calcOffset();
                canvas.requestRenderAll();

                // debounceJarAmountsRender();
                // setJarAmountsBtnsToParamsMap(prev => new Map(prev));
                throttleJarAmountsRender();
            }
        });
        canvas.on('mouse:wheel', (opt) => {
            const delta = opt.e.deltaY;
            let zoom = canvas.getZoom();
            zoom = zoom - delta / 2000;
            if (zoom > 2) zoom = 2;
            if (zoom < 0.3) zoom = 0.25;
            canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);

            setDisplayingSummary(null);

            opt.e.preventDefault();
            opt.e.stopPropagation();

            canvas.calcOffset();
            canvas.requestRenderAll();

            // debounceJarAmountsRender();
            throttleJarAmountsRender();
        });
        renderData();
    }

    function showAllScheme() {
        const edgeOffset = 20;
        const topLeftCornerOffset = 0;

        const maxX = Math.max(...(canvas._objects.map(i => i.aCoords?.br?.x || 0)));
        const maxY = Math.max(...(canvas._objects.map(i => i.aCoords?.br?.y || 0)));
        const minX = Math.min(...(canvas._objects.map(i => i.aCoords?.br?.x || 0)));
        const minY = Math.min(...(canvas._objects.map(i => i.aCoords?.br?.y || 0)));

        const yOffset = -1 * minY + edgeOffset;
        const xOffset = -1 * minX + edgeOffset;

        canvas.setViewportTransform([1, 0, 0, 1, xOffset, yOffset]);
        canvas.calcOffset();
        canvas.requestRenderAll();

        canvas.setWidth(maxX + edgeOffset + xOffset);
        canvas.setHeight(maxY + edgeOffset + yOffset);
    }

    function exportSchemeImage() {
        const multiplierFactor = 2;

        if (!props.exportInvoker || !props.exportInvoker.type) {
            return;
        }

        const prevTrans = [...canvas.viewportTransform];
        const prevWidth = canvas.width;
        const prevHeight = canvas.height;
        canvas.backgroundColor = "white";
        showAllScheme();
        const data = canvas.toDataURL({ multiplier: multiplierFactor });

        if(props.exportInvoker.type === SchemeImageExportType.SaveSchemeToBrowser) {
            const link = document.createElement('a');
            link.href = data;
            link.download = `${schemeView.Code}.png`;
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
        else if(props.exportInvoker.type === SchemeImageExportType.ExportSchemeImage) {
            props.setExportedData(data);
        }

        canvas.setViewportTransform(prevTrans);
        canvas.setWidth(prevWidth);
        canvas.setHeight(prevHeight);
    }
    useEffect(exportSchemeImage, [props.exportInvoker]);

    useEffect(() => {
        const canvasEl = canvasRef.current;
        const divEl = divRef.current;

        function updateSize() {
            canvas.setDimensions({
                width: divEl.clientWidth,
                height: divEl.clientHeight
            });
            canvas.calcOffset();
            canvas.requestRenderAll();
        }

        if (canvas) {
            if (!initDone) {
                init();
                setInitDone(true);
            }
        } else {

            const newCanvas = new fabric.Canvas(canvasEl, {
                width: divEl.clientWidth,
                height: divEl.clientHeight
            });

            setCanvas(newCanvas);
        }

        window.addEventListener('resize', updateSize);

        return () => window.removeEventListener('resize', updateSize);
    });

    useEffect(() => {
        if (!initDone) {
            return;
        }
        canvas.clear();
        //canvas.dispose();

        renderData();
    }, [schemeView]);

    useEffect(() => {
        if (jarAmountsBtnsToParamsMap.size === 0) return;
        Array.from(jarAmountsBtnsToParamsMap.entries())
            .filter(([btn, info]) => info.uuid === null)
            .forEach(([btn, info]) => {
                info.uuid = uuidv4();
                addJarAmountBtn(info.uuid, info?.params?.SmilesFormula, info?.params?.Name);
                btn.on('mouseup', (opt) => {
                    opt.e.preventDefault();
                    opt.e.stopPropagation();
                    loadSingleAgentJarAmount(info.uuid);
                });
            });
    }, [jarAmountsBtnsToParamsMap.size, Object.keys(jarAmountsFromStore).length]);

    const { node, left, top } = displayingSummary || {};

    function defineSummaryLeft(left: number): number {
        const canvasRect = canvas.upperCanvasEl.getBoundingClientRect();
        if (summaryWindowWidth + left + canvasRect.left >= canvasRect.right)
            return left - summaryWindowWidth;
        return left;
    }

    return (
        <div className="tree-container treeView">
            <div style={{ width: 100 + '%', height: 100 + '%' }}>
                <div style={{ width: 100 + '%', height: 100 + '%' }} ref={divRef}>
                    <canvas style={{ width: 100 + '%', height: 100 + '%' }} ref={canvasRef} />
                </div>
                {
                    [...jarAmountsBtnsToParamsMap.entries()]
                        .map(([btn, info]) =>
                        (
                            (info?.uuid && jarAmountsFromStore[info.uuid] && jarAmountsFromStore[info.uuid].show) &&
                            <JarAmountsByLaboratoriesTable key={info.uuid}
                                                           uuid={info.uuid}
                                                           left={btn.oCoords?.mt?.x}
                                                           top={btn.oCoords?.mt?.y}
                            />
                        ))
                }
                {
                    displayingSummary &&
                    <div className="summary" style={{ left: (defineSummaryLeft(left)) + 'px', top: top + 'px' }}>
                        <ReactionSummaryWindow summary={node.ReactionSummary} />
                    </div>
                }
            </div>
        </div>
    );
};

SchemeTree = withRouter(SchemeTree);

export {SchemeTree, SchemeImageExportType};

