export class Attachment {
  AttachmentId: number;
  AgentAvailabilityId: number;
  Id: number;
  IsDeleted: boolean;
  Attachment: {};

  constructor ({AttachmentId, AgentAvailabilityId, Id, Attachment}) {
    this.AttachmentId = AttachmentId || 0;
    this.AgentAvailabilityId = AgentAvailabilityId || 0;
    this.Id = Id || 0;
    this.IsDeleted = false;

    this.Attachment = Attachment || {
      Id: AttachmentId || 0,
      IsDeleted: this.IsDeleted,
      MimeType: '',
      OriginalName: '',
    };
  }
}