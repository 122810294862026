// @flow
import React, {
  useEffect,
  useState
} from 'react';
import listStyles from './list.m.less';
import dashboardStyles from '../dashboard.m.less';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ownedProjectsResourceActions } from '../../actions';
import { TableCol } from '../../../shared/models';
import { Row, Header, Table, Body, Cell } from '@biocad/bcd-front-ui/layout/Table';
import { LayoutBlock, TitleWithCounter, ReactionTypeIcon } from '../../../shared/components';
import { PageHeaderWrap, LoaderWrap } from '../../../agents/components/List/style';
import { PaginationStyled } from '../../../shared/components/Paginator/style';
import { ProjectReactionsTitlesEnum, ProjectReactionsKeysEnum } from '../../models';
import { projectTypeEnum, projectStatusTitlesEnum, folderParentType } from '../../../projects/types/enum';
import { projectTypesTranslate } from '../../../projects/localization';
import { itemsToArraySelector } from '../../../shared/utils/selectors/resource-selectors';
import Loader from '@biocad/bcd-front-ui/controls/Loader';
import { Link } from 'react-router-dom';
import { projectModuleActions, descendantsResourceActions } from '../../../projects/actions';
import ProjectModal from '../../../projects/components/ProjectsTable/Projects/modal';
import { ProjectNameWithType } from '../WorkProjectsList/ProjectNameWithType';
import {_noop} from '../../../shared/utils/common';
import {userCRUDPermissionsSelector} from '../../../account/reducers/users-modules-reducer';

const tableCols: TableCol[] = [
  {
    title: ProjectReactionsTitlesEnum.Name,
    mappingProp: ProjectReactionsKeysEnum.Name,
  },
  {
    title: ProjectReactionsTitlesEnum.Type,
    mappingProp: ProjectReactionsKeysEnum.Type,
  },
  {
    title: ProjectReactionsTitlesEnum.Status,
    mappingProp: ProjectReactionsKeysEnum.Status,
  },
  {
    title: '',
    mappingProp: 'actionEdit',
  },
];

const renderHeaderRow = () => (
  <Row className={dashboardStyles.table__headerRow}>
    {
      renderHeaderCells()
    }
  </Row>
);

const renderHeaderCells = () =>
  tableCols.map(col => (
    <Cell
      key={col.mappingProp}
      className={dashboardStyles.table__headerCell}
    >
        <nobr>{col.title}</nobr>
    </Cell>
    ) 
  );

const renderProjectsReactionsListRow = (item, editProject) => {

  return (
    tableCols.map(col => {
      
      let result;

      switch (col.mappingProp) {
        case ProjectReactionsKeysEnum.Name:
          result =
            <Link to={`/projects/${item.Id}`} className={dashboardStyles.textLink}>
              <ProjectNameWithType item={item} />
            </Link>;
          break;

        case ProjectReactionsKeysEnum.Type:
          result = <span>{projectTypesTranslate[projectTypeEnum[item.Type]]}</span>;
          break;
        
        case ProjectReactionsKeysEnum.Status: {
          result = <span>{projectStatusTitlesEnum[item.Status]}</span>;
          break;
        }
        case 'actionEdit':
          result = editProject === _noop
                    ? ''
                    : <span onClick={() => editProject(item.Id)} className={dashboardStyles.projectIcon}>
                        <i className={'fo-edit'} />
                      </span>;
          break;
        default:
          result = <span>{item[col.mappingProp]}</span>;
          break;
      }

      return (
        <Cell key={col.mappingProp} className={dashboardStyles.table__dataCell}>
          {result}
        </Cell>
      );

    })
  );
};

export const OwnedProjectsList = ({
  ownedProjects,
  ownedProjectsListPagination,
  pendingState,
  getOwnedProjectsList,
  destroyOwnedProjectsList,
  setEditProjectMode,
  setCurrentProjectId,
  getDescendantsList,
  userPermissions,
}) => {

  const initialPagingParams = {
    skip: 0,
    limit: 5
  };

  const [showProjectModal, setShowProjectModal] = useState(false);
  const [pagingParams, setPagingParams] = useState(
    ownedProjectsListPagination ?
    {
      skip: ownedProjectsListPagination.pageSize * (ownedProjectsListPagination.page - 1),
      limit: ownedProjectsListPagination.pageSize
    } :
    initialPagingParams
  );

  const _watchData = () => {
    if (!pendingState) {
      destroyOwnedProjectsList();
      getOwnedProjectsList({...pagingParams, order: 'Name'});
    }
  };
  useEffect(_watchData, [pagingParams]);

  const _clearDataOnUnmount = () => () => destroyOwnedProjectsList();
  useEffect(_clearDataOnUnmount, []);

  const toggleProjectModal = () => {
    setShowProjectModal(!showProjectModal);
    setEditProjectMode({
      isEdit: showProjectModal
    });
  };

  const editProject = (id) => {
    toggleProjectModal();
    setCurrentProjectId({ id });
    setEditProjectMode({
      isEdit: true
    });
  };
  
  const renderPageHeader = (count) => (
    <PageHeaderWrap>
        <TitleWithCounter title={'Курируемые проекты'} count={count}/>
    </PageHeaderWrap>
  );

  const handlePaginationClick = (listParams) => {
    setPagingParams(listParams);
  };
  
  return (
    <LayoutBlock header={renderPageHeader(ownedProjectsListPagination.total)}>
    {
      (pendingState === true)
        ? <LoaderWrap>
            <Loader />
          </LoaderWrap>
        : <Table className={`${dashboardStyles.table} ${listStyles.table}`}>
          <Header>
            {
              renderHeaderRow()
            }
          </Header>
          <Body>
            { ownedProjects.map(project =>
                <Row key={project.Id} className={dashboardStyles.table__dataRow}>
                  {renderProjectsReactionsListRow(
                      project,
                      userPermissions.project.UPDATE ? editProject : _noop
                  )}
                </Row>
              )
            }
          </Body>
        </Table>
    }
    {
      showProjectModal && 
      <ProjectModal toggleModal={toggleProjectModal} owned={true}/>
    }
      <PaginationStyled {...ownedProjectsListPagination} handlePagingChange={handlePaginationClick} />
    </LayoutBlock>
  );
};

const mapStateToProps = (state) => {
  const { resource, modules } = state;
  return {
    ownedProjects: itemsToArraySelector(resource.dashboard.ownedProjects),
    pendingState: resource.dashboard.ownedProjects.pending,
    ownedProjectsListPagination: modules.dashboard.ownedProjectsList.pagination,
    userPermissions: userCRUDPermissionsSelector(state),
  };
};

const mapDispatchToProps = dispatch => ({
  getOwnedProjectsList: params => dispatch(ownedProjectsResourceActions.list.request({ params })),
  destroyOwnedProjectsList: () => dispatch(ownedProjectsResourceActions.destroy()),
  
  setEditProjectMode: (isEditMode) => dispatch(projectModuleActions.setEditMode(isEditMode)),
  setCurrentProjectId: (id) => dispatch(projectModuleActions.setCurrentProjectId(id)),
  getDescendantsList: (params) => dispatch(descendantsResourceActions.list.request({params}))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OwnedProjectsList)
);
