import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import './index.less';
import {itemsToArraySelector} from '../../../shared/utils/selectors/resource-selectors';
import Folders from './Folders';
import {
    candidateModuleActions,
    candidatesResourceActions,
    folderModuleActions, folderResourceActions,
    projectActions, orderTypes
} from '../../../projects/actions';
import classNames from 'classnames';
import {
    productionSchemeResourceActions,
    folderSchemesResourceActions
} from '../../../projects/actions';
import {folderParentType} from '../../../projects/types/enum';
import CommentWrapper from '../../../shared/components/CommentWrapper';
import ViewCandidate from './ViewCandidate';
import queryString from 'query-string';
import {_temp_folderResourceAction} from '../../../folders/actions';
import {userCRUDPermissionsSelector} from "../../../account/reducers/users-modules-reducer";

const initialProps = {
    candidates: [],
    setCurrentCandidate: () => null,
    getProductSchemeList: () => null,
    clearProductSchemeList: () => null,
    getFoldersList: () => null,
    clearFoldersList: () => null,
    foldersPath: [],
    currentCandidate: null,
    clearFoldersPath: () => null,
    clearFolderSchemesPath: () => null,
    editCandidateComment: () => null,
    clearCandidatesList: () => null,
    currentProject: null,
    currentFolderId: null,
    setViewMode: () => null,
    isViewMode: false,
    location: {},
    getCandidatesList: () => null,
    getFolder: () => null,
    userPermissions: {},
};

const CandidatesTable = (props = initialProps) => {
    useEffect(() => {
        const urlParams = queryString.parse(props.location.search);
        urlParams.folderId && !props.foldersPath.length && props.getFolder(urlParams.folderId);

        if (!props.currentCandidate && props.candidates.length && !queryString.parse(props.location.search).schemeFolderId) {

            clearAll();

            props.getCandidatesList({
                projectId: props.location.pathname.split('/')[2],
                folderId: urlParams.folderId ? urlParams.folderId : null
            });

            props.setCurrentCandidate({
                candidate: props.candidates[0]
            });

            urlParams.selectedId = props.candidates[0].Id;
            props.history.push(props.location.pathname + '?' + queryString.stringify(urlParams));

        }

        return () => {
            props.setViewMode({
                isViewMode: false
            });

            props.setCurrentCandidate({
                candidate: null
            });

            clearAll();
            props.clearCandidatesList();
        };
    }, []);

    const orderedCandidates = () => {
        return props.candidates.sort((a, b) => {
            if(props.order === orderTypes.Code) 
                return ('' + a.Code).localeCompare(b.Code);
            else return b.Id - a.Id;
        });
    };

    const setCurrentCandidate = (e, currentId, isViewMode) => {
        e.stopPropagation();
        e.preventDefault();

        clearAll();

        const currentCandidate = props.candidates.find(item => Number(item.Id) === Number(currentId));

        if (currentCandidate) {
            props.setCurrentCandidate({
                candidate: currentCandidate
            });

            props.getProductSchemeList({
                finalCandidateId: currentCandidate.Id,
                showOnlyTopLevelSchemes: true
            });

            props.getFoldersList({
                parentId: currentCandidate.Id,
                parentType: folderParentType.finalCandidate
            });
        }

        let urlParams = queryString.parse(props.location.search);
        urlParams.selectedId = currentId;
        isViewMode ? urlParams.isViewMode = true : null;
        delete urlParams.schemeFolderId;
        props.history.push(props.location.pathname + '?' + queryString.stringify(urlParams));
    };

    const editComment = (data) => {
        props.editCandidateComment({
            candidateId: data.itemId,
            quickComment: data.comment || '',
            folderId: props.currentFolderId,
            projectId: props.currentProject.Id
        });
    };

    const viewCandidateTrigger = (e, candidateId) => {
        props.setViewMode({
            isViewMode: true
        });

        setCurrentCandidate(e, candidateId, true);
    };

    const clearAll = () => {
        props.clearProductSchemeList();
        props.clearFoldersList();
        props.clearFoldersPath();
        props.clearFolderSchemesPath();
    };

    return (
        <div className={'candidates-table-grid'}>

            {props.isViewMode &&
            <ViewCandidate/>}

            {!props.isViewMode &&
            <Folders/>}

            {!props.isViewMode && orderedCandidates().map((candidate, index) => {
                let imgStyle = {
                    background: 'url(' + candidate?.Compound?.Thumbnail + ') center center / contain no-repeat',
                    backgroundSize: 'contain'
                };

                return (
                    <div
                        key={index}
                        className={classNames('cell', {
                            'active': Number(candidate.Id) === Number(props.currentCandidate?.Id)
                        })}
                        data-id={candidate.Id}>

                        <div className={'img'}
                             style={imgStyle}
                             onClick={(event) => viewCandidateTrigger(event, candidate.Id)}
                        />

                        <div className={'panel'} onClick={(e) => setCurrentCandidate(e, candidate.Id)}>
                            <h4>{candidate.Code}</h4>

                            <CommentWrapper
                                item={candidate}
                                saveComment={editComment}
                                firstStaticField={{
                                    title: 'Наименование',
                                    value: candidate.Code
                                }}
                                readonly={!props.userPermissions.candidateMiniComment.UPDATE}
                            />
                        </div>

                    </div>
                );
            })}

        </div>
    );
};

const mapStateToProps = (state) => {
    const {resource, modules} = state;
    return {
        candidates: itemsToArraySelector(resource.candidates),
        foldersPath: modules.folder.candidatesPath,
        currentCandidate: modules.candidate.candidate,
        currentProject: modules.project.currentProject,
        currentFolderId: modules.folder.id,
        isViewMode: modules.candidate.isViewMode,
        order: modules.candidate.order,
        userPermissions: userCRUDPermissionsSelector(state)
    };
};

export default withRouter(connect(
    mapStateToProps,
    (dispatch) => ({
        setCurrentCandidate: (candidate) => dispatch(candidateModuleActions.setCurrentCandidate(candidate)),
        getProductSchemeList: (params) => dispatch(productionSchemeResourceActions.list.request({params})),
        clearProductSchemeList: () => dispatch(productionSchemeResourceActions.destroy()),
        getFoldersList: (params) => dispatch(folderSchemesResourceActions.list.request({params})),
        clearFoldersList: () => dispatch(folderSchemesResourceActions.destroy()),
        clearFoldersPath: () => dispatch(folderModuleActions.removeFolderSchemesPathItem()),
        clearFolderSchemesPath: () => dispatch(folderModuleActions.deleteFolderSchemesPath()),
        clearCandidatesList: () => dispatch(candidatesResourceActions.destroy()),
        editCandidateComment: (data) => dispatch(candidatesResourceActions.editCandidateComment.request({data})),
        setViewMode: (isViewMode) => dispatch(candidateModuleActions.setViewMode(isViewMode)),
        getCandidatesList: (params) => dispatch(candidatesResourceActions.list.request({params})),
        getFolder: (id: number) => dispatch(_temp_folderResourceAction.get.request({uriParams: {id}}))
    })
)(CandidatesTable));
