// @flow
import Loader from '@biocad/bcd-front-ui/controls/Loader';
import { Body, Cell, Header, Row, Table } from '@biocad/bcd-front-ui/layout/Table';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { getFormValues } from 'redux-form';
import { LoaderWrap, PageHeaderWrap } from '../../../agents/components/List/style';
import { LayoutBlock, TitleWithCounter } from '../../../shared/components';
import CommentWrapper from '../../../shared/components/CommentWrapper';
import { PaginationStyled } from '../../../shared/components/Paginator/style';
import { itemsToArraySelector } from '../../../shared/utils/selectors/resource-selectors';
import { currentUserReactionsResourceActions } from '../../actions';
import { ReactionsKeysEnum, ReactionsTitlesEnum } from '../../models';
import { ProductKeysEnum } from '../../models/i-product';
import dashboardStyles from '../dashboard.m.less';
import { FilterForm, REACTION_FILTER_FORM_NAME } from './FilterForm';
import listStyles from './list.m.less';
import { ReactionNameWithType } from './ReactionNameWithType';
import type {IReaction} from '../../models';
import type {TableCol} from '../../../shared/models';
import {reactionsResourceActions} from '../../../reactions/actions';
import {userCRUDPermissionsSelector} from '../../../account/reducers/users-modules-reducer';
import {RolesMap} from '../../../account/models/roles-map';
import dateTimeFormat from '@biocad/bcd-front-ui/utils/formatters/datetime';
import { ReactionPurificationModal } from '../../../reactions/components/ReactionPurification/ReactionPurificationModal';


const tableCols: TableCol[] = [
    {
        title: ReactionsTitlesEnum.Name,
        mappingProp: ReactionsKeysEnum.Name,
    },
    {
        title: ReactionsTitlesEnum.CreationDate,
        mappingProp: ReactionsKeysEnum.CreationDate,
    },
    {
        title: ReactionsTitlesEnum.Reagents,
        mappingProp: ReactionsKeysEnum.Reagents,
    },
    {
        title: ReactionsTitlesEnum.Products,
        mappingProp: ReactionsKeysEnum.Products,
    },
    {
        title: ReactionsTitlesEnum.TheoreticalYield,
        mappingProp: ReactionsKeysEnum.TheoreticalYield,
    },
    {
        title: ReactionsTitlesEnum.ExperimentalYield,
        mappingProp: ReactionsKeysEnum.ExperimentalYield,
    },
    {
        title: ReactionsTitlesEnum.PercentYield,
        mappingProp: ReactionsKeysEnum.PercentYield,
    },
    {
        title: '',
        mappingProp: 'actionComment',
    },
];

const alignRightCols = [
    ReactionsKeysEnum.TheoreticalYield,
    ReactionsKeysEnum.ExperimentalYield,
    ReactionsKeysEnum.PercentYield
];

const renderPageHeader = (count) => (
    <PageHeaderWrap>
        <TitleWithCounter title={'Реакции'} count={count}/>
    </PageHeaderWrap>
);

const renderHeaderRow = () => (
  <Row className={dashboardStyles.table__headerRow}>
    {
      renderHeaderCells()
    }
  </Row>
);

const renderHeaderCells = () =>
  tableCols.map(col => (
    <Cell
      key={col.mappingProp}
      className={[
        dashboardStyles.table__headerCell,
        alignRightCols.includes(col.mappingProp) ? dashboardStyles.table__alignRight : null
      ].join(' ')}
    >
        <nobr>{col.title}</nobr>
    </Cell>
  )
  );


const renderReactionsListRow = (item: IReaction, updateHandler, editReactionComment, destroyReactionsList, commentDisabled, handleClickCompound) => {

  const RenderCompounds = ({compounds, handleClick}) => {
    return compounds.map((cmp, index) =>
      <img
        className={`${dashboardStyles.table__thumbnail} ${dashboardStyles.imgLink}`}
        onClick={() => handleClick(cmp)}
        key={index}
        title={cmp.MolecularFormula}
        src={cmp.Thumbnail} />
    );
  };

  const editComment = (data) => {
    destroyReactionsList();

    editReactionComment(data.itemId, {
      ReactionId: data.itemId,
      QuickComment: data.comment || ''
    });

    updateHandler();
  };

  return (
    tableCols.map(col => {

      let result;

      switch (col.mappingProp) {
        case ReactionsKeysEnum.Name:
          result =
            <Link to={`/reactions/${item.Id}`} className={`${dashboardStyles.textLink}`}>
              <ReactionNameWithType item={item} />
            </Link>;
          break;

        case ReactionsKeysEnum.Reagents:
          result = <RenderCompounds compounds={item[col.mappingProp]} handleClick={handleClickCompound}/>;
          break;

        case 'actionComment':
          result = <CommentWrapper
              item={item}
              saveComment={editComment}
              firstStaticField={{
                  title: 'Название',
                  value: item.Name
              }}
              readonly={commentDisabled}
          />;
          break;

        case ReactionsKeysEnum.Products:
          result = <RenderCompounds compounds={item[col.mappingProp]} handleClick={handleClickCompound}/>;
          break;

        case ReactionsKeysEnum.CreationDate: {
          result = <span>{ dateTimeFormat(new Date(item[col.mappingProp]), {format: 'compact', time: true}) }</span>;
          break;
        }

        case ReactionsKeysEnum.PercentYield: {
          result = (<span>{ (item.PercentYield * 100).toFixed(2) }</span>);
          break;
        }

        default: {
          let value = item[col.mappingProp];
          value = (typeof value === 'number') ? Math.round((value)*1000)/1000 : value;
          result = <span>{value}</span>;
          break;
        }
      }

      return (
        <Cell
            className={classNames(
              dashboardStyles.table__dataCell,
              alignRightCols.includes(col.mappingProp) ? dashboardStyles.table__alignRight : null,
            )}
                    key={col.mappingProp}
                >
                    {result}
                </Cell>
            );

        })
    );
};

const ReactionsList = ({
  getReactionsList,
  pendingState,
  reactions,
  reactionsListPagination,
  filterFormValues,
  editReactionComment,
  destroyReactionsList,
  userPermissions,
  roles
}) => {

  const initialPagingParams = {
    skip: 0,
    limit: 20
  };

  const initialFilterParams = {
    includeCompleted: false,
    order: '-CreationDate',
  };

  const [pagingParams, setPagingParams] = useState(
    reactionsListPagination
    ? {
        skip: reactionsListPagination.pageSize*(reactionsListPagination.page-1),
        limit: reactionsListPagination.pageSize
      }
    : initialPagingParams
  );

  const [filterParams, setFilterParams] = useState(initialFilterParams);
  const [isPurificationModalShown, setIsPurificationModalShown] = useState(false);
  const [selectedCompound, setSelectedCompound] = useState(null);

  const _watchData = () => {
    if (!pendingState) {
      destroyReactionsList();
      setPagingParams(
        {
          skip: reactionsListPagination.pageSize*(reactionsListPagination.page-1),
          limit: reactionsListPagination.pageSize
        }
      );
      getReactionsList({...pagingParams, ...filterParams});
    }
  };

  useEffect(_watchData, [pagingParams, filterParams]);

  const _clearDataOnUnmount = () => () => destroyReactionsList();
  useEffect(_clearDataOnUnmount, []);

  const handlePaginationClick = (listParams) => {
    setPagingParams(listParams);
  };

  const applyFilters = (event: Event) => {
    event.preventDefault();
    setFilterParams({
      ...initialFilterParams,
      ...filterFormValues
    });
  };


  const togglePurificationModal = () => {
    setIsPurificationModalShown(!isPurificationModalShown);
  }

  const handleClickCompound = (compound) => {
    setSelectedCompound(compound);
    togglePurificationModal();
  }

  return (
      <LayoutBlock header={renderPageHeader(reactionsListPagination.total)}>
        <FilterForm submitHandler={applyFilters}/>
        {
          (pendingState === true)
            ? <LoaderWrap>
                <Loader />
              </LoaderWrap>

              : <Table className={`${dashboardStyles.table} ${listStyles.table}`}>
                <Header>
                  {
                    renderHeaderRow()
                  }
                </Header>
                <Body>
                  { reactions.map(reaction =>
                      <Row key={reaction.Id} className={dashboardStyles.table__dataRow}>
                        {renderReactionsListRow(
                            reaction,
                            _watchData,
                            editReactionComment,
                            destroyReactionsList,
                            (!userPermissions.reactionMiniComment.UPDATE) || !(roles.includes(RolesMap.User) && !reaction.IsCompleted),
                            handleClickCompound
                        )}
                      </Row>
                    )}
                </Body>
              </Table>
            }
            <PaginationStyled {...reactionsListPagination} handlePagingChange={handlePaginationClick}/>
            {isPurificationModalShown &&
              <ReactionPurificationModal onCancel={togglePurificationModal} compound={selectedCompound} agentId={selectedCompound.AgentId} />
            }
        </LayoutBlock>
    );
};

const mapStateToProps = (state) => {
    const {resource, modules} = state;
    return {
      reactions: itemsToArraySelector(resource.dashboard.reactions),
      pendingState: resource.dashboard.reactions.pending,
      reactionsListPagination: modules.dashboard.reactionsList.pagination,
      filterFormValues: getFormValues(REACTION_FILTER_FORM_NAME)(state),
      userPermissions: userCRUDPermissionsSelector(state),
      roles: state.global.account.roles,
    };
};

const mapDispatchToProps = dispatch => ({
  getReactionsList: params => dispatch(currentUserReactionsResourceActions.list.request({params})),
  destroyReactionsList: () => dispatch(currentUserReactionsResourceActions.destroy()),
  editReactionComment: (id, data) => dispatch(reactionsResourceActions.quickComment.request({ uriParams: {id}, data})),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ReactionsList)
);
