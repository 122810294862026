import type {IPagination} from '../../shared/models';
import type {ISuccessResourceAction} from "../../shared/utils/action-creators";
import type {IReactionModuleStore} from "../../reactions/reducers";
import {equipmentActions} from "../actions";

export interface IEquipmentModuleStore {
    list: {
        pagination: IPagination,
    },
}

const initialPagination: IPagination = {
    page: 1,
    pageCount: 1,
    pageSize: null,
    total: null,
};

const defaultEquipmentModuleStore: IEquipmentModuleStore = {
    list: {
        pagination: initialPagination,
    },
};


export const equipmentModuleReducer = (state: IEquipmentModuleStore = defaultEquipmentModuleStore, action: any = {}) => {
    const reducedFn = {
        [equipmentActions.list.success]: (action: ISuccessResourceAction): IReactionModuleStore => ({
            ...state,
            list: {
                ...state.list,
                pagination: {...state.list.pagination, ...action.pagination},
            },
        }),
    }[action.type];
    return reducedFn ? reducedFn(action) : state;
};
