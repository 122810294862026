// @flow

import React from 'react';
import Dialog from '@biocad/bcd-front-ui/layout/Dialog';
import Button from '@biocad/bcd-front-ui/controls/Button';
import styled from 'styled-components';
import classNames from 'classnames';
import './index.less';

export interface IConfirmDialog {
    title: string;
    message: string;

    confirmLabel: string;
    confirmClass?: string;

    declineLabel: string;
    declineClass?: string;

    otherLabel?: string;
    otherClass?: string;

    confirmAction: () => void;
    declineAction: () => void;
    otherAction?: () => void;
    type?: string;
}

export const StyledButton = styled(Button)`
    &.${Button.block} {
        margin-right: var(--grid-spacer);
        &:last-child {
            margin-right: 0;
        }
    }
`;

export const ConfirmDialog = ({
    message = 'На странице остались несохранённые изменения.',
    title = 'Внимание!',
    confirmLabel,
    confirmClass = null,
    declineLabel,
    declineClass = null,
    otherLabel = null,
    otherClass = null,
    confirmAction,
    declineAction,
    otherAction,
    type
}: IConfirmDialog) => {

    const handleConfirm = () => confirmAction();
    const handleDecline = () => declineAction();
    const handleOther = () => otherAction();

    return (
        <Dialog
            close={handleDecline}
            paranjaClose={false}
            modal={type === 'error'}
        >
            <h3>{title}</h3>
            <hr />
            {Array.isArray(message) ?
                message.map((message, index) => {
                    return <p key={index} className={classNames({ 'error-text': type === 'error' })}>{message.errorMessage}</p>;
                }) :
                <p>{message}</p>
            }
            {confirmLabel && confirmAction && (
                <StyledButton
                    size="L"
                    text={confirmLabel}
                    onAction={handleConfirm}
                    view="primary"
                    className={confirmClass || ''}
                />
            )}
            {declineLabel && declineAction && (
                <StyledButton
                    size="L"
                    text={declineLabel}
                    onAction={handleDecline}
                    className={declineClass || ''}
                />
            )}
            {otherLabel && otherAction && (
                <StyledButton
                    size="L"
                    text={otherLabel}
                    onAction={handleOther}
                    className={otherClass || ''}
                />
            )}
        </Dialog>
    );
};
