import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import dateTimeFormat from '@biocad/bcd-front-ui/utils/formatters/datetime';
import { GridTable, GridTableCol } from '../../../shared/components/GridTable';
import { ReactionEvent } from '../../models/reaction-event.model';
import css from './events.m.less';
import { BcdTooltipWrapper } from '../../../shared/components';
import { IRootStore } from '../../../app/root-store.interface';
import { reactionEventsHistoryResourceActions } from '../../actions';
import { PaginationStyled } from '../../../shared/components/Paginator/style';
import { itemsToArraySelector } from '../../../shared/utils/selectors/resource-selectors';
import {getReactionEventsHistoryItems} from '../../reducers';


const formatDate = item => (
  dateTimeFormat(new Date(item.CreationDate), {
    locale: 'ru',
    format: 'compact',
    time: true
  })
);

const EventsHistory = (props) => {
  const [events, setEvents] = useState([]);

  const {
    reactionId,
    items,
    reactionTypes,
    pagination,
    getEventsHistory,
    destroyEventsHistory,
    className,
    fullData,
    preventDataLoading,
  } = props;

  const _getEventsHistory = params => {
    destroyEventsHistory();
    getEventsHistory({
      params: {
        ...params,
        Order: '-CreationDate',
      },
      uriParams: { id: reactionId }
    });
  };

  useEffect(() => {
    return () => destroyEventsHistory();
  }, []);

  useEffect(() => {
    if (preventDataLoading) return;
    if(fullData === true)
      _getEventsHistory({ skip: 0, limit: 10000 });
    else
      _getEventsHistory({ skip: 0, limit: 20 });
  }, [reactionId]);

  useEffect(() => {
    items.length > 0
      ? setEvents(items.map(item => new ReactionEvent(item, reactionTypes)))
      : setEvents([]);
  }, [items]);

  const onPaginationChange = paginationParams => {
    _getEventsHistory(paginationParams);
  };

  const renderValues = (items: {}) => {
    const limit = 2;
    const sliced = items.length > limit ? items.slice(0, limit) : items;
    const rest = items.length > limit ? items.slice(limit, items.length) : null;

    const renderRestBody = () => (
      <div className={css.valuesList}>
        {rest.map((item, index) => (
          <span key={index}>{`${item.name}: ${item.value}`}</span>
        ))}
      </div>
    );

    return (
      <div className={css.valuesList}>
        {sliced.map((item, index) => (
          <span key={index}>{`${item.name}: ${item.value}`}</span>
        ))}
        {rest && (
          <span className={css.moreInfo}>
            ...
            <BcdTooltipWrapper
              align={'left'}
              body={renderRestBody()}
              type='hover'
              mousehold
            />
          </span>
        )}
      </div>
    );
  };

  return (
    <>
      <GridTable
        items={events}
        rowHeight={'54px'}
        colsTemplate={'3fr minmax(160px, 1fr) minmax(80px, .5fr) repeat(2, minmax(130px, 1fr))'}
        stickyHeading={true}
        hover={true}
        className={className}
      >
        <GridTableCol
          title={'Обновлены данные о реакции'}
          render={({ item }) => (
            <span>{ item?.LayoutParams?.Description }</span>
          )}
        />
        <GridTableCol
          title={'Дата изменения'}
          render={({ item }) => (
            <span>{formatDate(item)}</span>
          )}
        />
        <GridTableCol
          title={'Химик'}
          render={({ item }) => (
            <span>{item.Abbreviation}</span>
          )}
        />
        <GridTableCol
          title={'Было'}
          render={({ item }) => (
            renderValues(item?.LayoutParams?.oldValues)
          )}
        />
        <GridTableCol
          title={'Стало'}
          render={({ item }) => (
            renderValues(item?.LayoutParams?.newValues)
          )}
        />
      </GridTable>
      <PaginationStyled {...pagination} handlePagingChange={onPaginationChange} />
    </>
  );
};

const mapStateToProps = (state: IRootStore) => ({
  pagination: state.modules.reaction.eventsHistory.pagination,
  items: getReactionEventsHistoryItems(state),
  reactionTypes: state.resource.reactionTypes.data,
});

const mapDispatchToProps = dispatch => ({
  getEventsHistory: id => dispatch(reactionEventsHistoryResourceActions.list.request(id)),
  destroyEventsHistory: () => dispatch(reactionEventsHistoryResourceActions.destroy())
});

export default
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EventsHistory);
