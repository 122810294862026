// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory, createHashHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './app/sagas';
import App from './app/components/App';
import AppContainer from '@biocad/bcd-front-ui/layout/AppContainer';
import {BrowserRouter, HashRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import createRootReducer from './app/reducers';
import { ConnectedRouter } from 'connected-react-router';
import { accountActions } from './account/actions';
import './polyfills';

declare var VERSION: string;
declare var NODE_ENV: string;

VERSION && console.info('VERSION', VERSION, NODE_ENV); /* eslint-disable-line no-console */
//export const history = NODE_ENV === 'development' ? createHashHistory() : createBrowserHistory();
//const Router = NODE_ENV === 'development' ? HashRouter : BrowserRouter;

const Router = HashRouter;
export const history = createHashHistory();

const composeEnhancers = (NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const sagaMiddleware = createSagaMiddleware();
export const store = createStore(
    createRootReducer(history),
    composeEnhancers(
        applyMiddleware(
            routerMiddleware(history),
            sagaMiddleware
        )
    ),
);
sagaMiddleware.run(rootSaga);

// if (module.hot) {
//     module.hot.accept('./app/reducers', () => {
//         store.replaceReducer(createRootReducer(history));
//     });
// }

const getUserConfirmation = (dialogKey, callback) => {

    const dialogTrigger = window[Symbol.for(dialogKey)];

    if (dialogTrigger) {
        return dialogTrigger(callback);
    }
    callback(true);
};

function render() {
    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Router getUserConfirmation={getUserConfirmation}>
                    <App />
                </Router>
            </ConnectedRouter>
        </Provider>,

        AppContainer
    );
}

render();
store.subscribe(render);

store.dispatch(accountActions.loginOnStart.request({ data: window.location.href }));