import React from 'react';
import { Field} from 'redux-form';
import {BcdInputWrapper} from '../../../../shared/components/BcdInputWrapper';
import styled from 'styled-components';
import classNames from 'classnames';
import {BcdDropdownWrapper} from '../../../../shared/components/BcdDropdownWrapper';
import {aftAimTitlesEnum, aftAimEnum} from './enums/afs-aim.enum';
import {DatePickerInput} from '../../../../shared/components/DatePickerInput';
import {processScaleEnum, processScaleTitlesEnum} from './enums/process-scale.enum';
import {BcdTextAreaWrapper} from '../../../../shared/components/BcdTextareaWrapper';
import type {IReaction} from '../../../models';
import {ReactionKeyNames} from '../../../models/reaction-key-names';

interface IReactionSeriesProps {
    className?: string,
    reaction?: IReaction,
    isDisabled: Boolean
}

const defaultProps: IReactionSeriesProps = {
    className: 'seriesInfo',
    reaction: null,
    isDisabled: false
};

const processScaleOptions = [
    {
        label:processScaleTitlesEnum[processScaleEnum.laboratory],
        value:processScaleEnum.laboratory
    },
    {
        label:processScaleTitlesEnum[processScaleEnum.engineering],
        value:processScaleEnum.engineering
    },
    {
        label:processScaleTitlesEnum[processScaleEnum.pilotIndustrial],
        value:processScaleEnum.pilotIndustrial
    },
    {
        label:processScaleTitlesEnum[processScaleEnum.industrial],
        value:processScaleEnum.industrial
    },
];

const afsAimOptions = [
    {
        label:aftAimTitlesEnum[aftAimEnum.technologyDevelopment],
        value:aftAimEnum.technologyDevelopment
    },
    {
        label:aftAimTitlesEnum[aftAimEnum.dki],
        value:aftAimEnum.dki
    },
    {
        label:aftAimTitlesEnum[aftAimEnum.kiI],
        value:aftAimEnum.kiI
    },
    {
        label:aftAimTitlesEnum[aftAimEnum.kiII],
        value:aftAimEnum.kiII
    },
    {
        label:aftAimTitlesEnum[aftAimEnum.kiIII],
        value:aftAimEnum.kiIII
    },
    {
        label:aftAimTitlesEnum[aftAimEnum.commercialProduction],
        value:aftAimEnum.commercialProduction
    },
];

let SeriesInfoForm = (props:IReactionSeriesProps = defaultProps) => {
    return (
        <>
            <aside className={props.className}>
                <div className={'form-group'}>
                    <div className={'fields-row'}>
                        <label className={classNames('labelToTop')}>
                            Номер серии АФС
                            <Field
                                disabled={props.isDisabled}
                                type='text'
                                name={ReactionKeyNames.SeriesAfsNumber}
                                component={BcdInputWrapper}
                                placeholder={'Введите номер серии АФС'}/>
                        </label>
                        <label className={classNames('labelToTop')}>
                            Цель наработки АФС
                        <Field
                            disabled={props.isDisabled}
                            name={ReactionKeyNames.SeriesAfsAim}
                            component={BcdDropdownWrapper}
                            options={afsAimOptions}
                            format={(value) => aftAimTitlesEnum[value]}
                            placeholder={<span style={{color: 'var(--grey-300)'}}>Выберите цель наработки АФС</span>}
                            markPristineValidity
                        />
                        </label>
                    </div>
                    <div className={'fields-row'}>
                        <label className={classNames('labelToTop')}>
                            Состав
                            <Field
                                disabled={props.isDisabled}
                                type='text'
                                name={ReactionKeyNames.SeriesComposition}
                                component={BcdInputWrapper}
                                placeholder={'Введите состав'}/>
                        </label>
                        <label className={classNames('labelToTop')}>
                            Масштаб процесса производства
                            <Field
                                disabled={props.isDisabled}
                                name={ReactionKeyNames.SeriesProcessScale}
                                component={BcdDropdownWrapper}
                                options={processScaleOptions}
                                format={(value) => processScaleTitlesEnum[value]}
                                placeholder={<span style={{color: 'var(--grey-300)'}}>Выберите масштаб</span>}
                                markPristineValidity
                            />
                        </label>
                    </div>
                    <div className={'fields-row'}>
                        <label className={classNames('labelToTop')}>
                            Дата производства
                            <Field
                                disabled={props.isDisabled}
                                name={ReactionKeyNames.SeriesProductionDate}
                                component={DatePickerInput}
                                placeholder={'Выберите дату'}
                                markPristineValidity
                            />
                        </label>
                    </div>
                    <div className={'fields-row'}>
                        <label className={classNames('labelToTop')}>
                            Комментарий
                            <Field
                                large
                                name={ReactionKeyNames.SeriesComment}
                                component={BcdTextAreaWrapper}
                                placeholder={'Введите комментарий'}
                                disabled={props.isDisabled}
                            />
                        </label>
                    </div>
                </div>
            </aside>
        </>
    );
};

SeriesInfoForm = styled(SeriesInfoForm)`
	.form-group {
	    height: calc(100% - 32px);
		margin-bottom: var(--grid-spacer);		
		padding-top:16px;
		width:10px;
		display: flex;
		flex-direction: column;
      .fields-row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        line-height:19px;
        label {
            padding-left: 24px;
        }
        input, .b-datepicker {        
            width:250px;            
        }
        .b-dropdown {
            width:300px;
        }
        .b-textarea {
            width:574px;
            height:100%;
            border-color:#D3D7DE;
            &:hover {
                border-color:#B9BEC9;            
            }
        }        
      }      
    }   
    
`;


export {
    SeriesInfoForm,
};
