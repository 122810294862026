// @flow
import React from 'react';
import Screen from '@biocad/bcd-front-ui/layout/Screen';
import UserRolesButton from '../../account/components/UserRoles';

const MainHeader = ({items = []}: {items: any}) => (
    <Screen.Header themeButton={false} title={'CHEMSOFT'}>
        <Screen.TopMenu>
            {items.length > 0 && items.map((item, index) =>
                <Screen.TopNavLink key={index} to={item.path}>
                    {item.title}
                </Screen.TopNavLink>
            )}
        </Screen.TopMenu>
        <UserRolesButton/>
    </Screen.Header>
);

export default MainHeader;
