import bnc                 from 'bnc';
import { Component }       from 'react';
import propTypes           from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import                          './index.less';

export default class Input extends Component {

    static propTypes = {
        placeholder:    propTypes.string,
        disabled:       propTypes.bool,
        autoComplete:   propTypes.bool,
        valid:          propTypes.bool,
        onChange:       propTypes.func,
        onFocus:        propTypes.func,
        onBlur:         propTypes.func,
        className:      propTypes.oneOfType([propTypes.string, propTypes.instanceOf(bnc)]),
    };

    static Icon = React.forwardRef(
        ({icon, ...$}, ref) =>
            <span className={Input.block.el('icon-wrapper')}>
                <span className={Input.block.el('icon')}>
                    <FontAwesomeIcon icon={icon} />
                </span>
                <Input ref={ref} {...$} />
            </span>
    );

    static block = new bnc.default('b-input');

    input = React.createRef()

    clear = () => this.setValue('')

    setValue = value => {
        this.input.current.value = String(value);
        var event = new Event('input', { bubbles: true });
        this.input.current.dispatchEvent(event);
    }

    getClasses = ({mods, valid}) => (
        Input.block +
        (
            (mods && Array.isArray(mods))
                ? mods.reduce( (mc, mod) => mc += Input.block.mod(mod), '' )
                : ''
        ) + (
            Input.block.mod(
                'valid',
                valid !== void(0)
                    ? valid
                        ? 'true'
                        : 'false'
                    : 'undefined'
            )
        )
    )

    getAttrs = ({autocomplete, disabled, mods, valid, placeholder, className = '', ...rest}) => ({
        placeholder,
        className:    this.getClasses({mods, valid}) + className,
        disabled:     !!disabled,
        autoComplete: autocomplete ? 'on' : 'off',
     ...rest
    })

    render = () => <input ref={this.input} { ...this.getAttrs(this.props) } />

}