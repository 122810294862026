// @flow

import { PaginationHeadersMap } from './constants';
import type { IPagination } from '../../models';

export function getPagination(headers: {[string]: string}): IPagination {
  const _headers = Object.entries(headers) // Это нужно чтобы игнорировать регистр в названиях ключей в заголовках
    .map(([key, value]) => [key.toLowerCase(), value])
    .reduce((acc, [key,value]) => ({ ...acc, [key]: value }), {});
  const pagination = Object.entries(PaginationHeadersMap)
    .map(([headerName, headerString]) =>
      ({ ...( headerString in _headers && !isNaN(parseInt(_headers[headerString])) ?
          { [headerName]: parseInt(_headers[headerString], 10) }
          : null ) }))
    .filter(i => Object.keys(i).length > 0)
    .reduce((cur, acc) => ({ ...acc, ...cur }), {});
  if ('page' in pagination && 'pageCount' in pagination) {
    return pagination;
  }
  return null;
}
