// @flow

import React, {
	useEffect
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import type { IRootStore } from '../../../app/reducers';
import { Dispatch } from 'redux';
import { getFormValues, reduxForm, Field, Fields, FieldArray } from 'redux-form';
import { reactionModuleActions, reactionsResourceActions } from '../../actions';
import { productsResourceActions } from '../../../products/actions';
import { globalErrorsActions, setGlobalLoader } from '../../../shared/actions';
import type {IReaction, IReactionCompletion, IReactionQuickComment} from '../../models';
import {
	getCompletionReactionInitialFormFromReaction,
	getCurrentReactionMainProduct,
	getReactionByCurrentId,
} from '../../reducers';
import {
	ReactionCompletion,
	ReactionCompletionKeyNames,
	ReactionCompletionYield,
	ReactionKeyNames,
} from '../../models';
import {
	PageLayout,
	pageLayoutAreas,
	PageLayoutHeader,
	PageLayoutSlot,
} from '../../../shared/components/PageLayout';
import Button from '@biocad/bcd-front-ui/controls/Button';
import type {IFieldArrayComponentProps, IFormWrappedComponentProps} from '../../../shared/models';
import {
	BcdCheckboxWrapper,
	LayoutBlock,
	StyledButton,
	TitleWithCounter,
	WysiwygEditorFieldComponent
} from '../../../shared/components';
import { ResizableAreaFieldWrapper } from '../../../shared/components/ResizableArea/ResizableAreaFieldWrapper';
import styled from 'styled-components';
import {
	faPlus,
} from '@fortawesome/free-solid-svg-icons';
import type { IProduct } from '../../../products/model';
import { MAIN_PRODUCT_FIELDS, MainProductSubForm } from './MainProductSubForm';
import { labsLoaderHOC } from '../../../dictionary/components/labsLoaderHOC';
import {Product, ProductKeyNames} from '../../../products/model';
import { ProductFormFields } from '../../../products/components/ProductFormFields';
import { REACTION_TYPE_PURIFICATION_ID } from '../../../shared/globals/constants';
import {ProductFieldValidators} from '../../../products/components/ProductFormFields/field-validators';
import { PurificationBundleTypeEnum } from '../../../stages/models';

interface ICompleteReactionProps extends IFormWrappedComponentProps {
	className?: string,
	getReaction?: (number)=>void,
	getProducts?: (number)=>void,
	setCurrentId?: (number)=>void,
	setGlobalError?: (string)=>void,
	clearStore?: ()=>void,
	reaction?: IReaction,
	formValues?: {},
	mainProduct?: IProduct,
	pending?: boolean,
	setLoader?: (boolean)=>void,
	completeReactionRequest?: (IReactionCompletion)=>void,
	completionSuccess?: boolean,
	setQuickComment?: (number, IReactionQuickComment) => null
}

const
	COMPLETE_REACTION_FORM_NAME = '__completeReaction',
	INCLUDE_MAIN_PRODUCT_FIELD_NAME = '__includeMainProduct',
	CREATE_NESTED_PURIFICATION = '__createNestedPurification'
	;

const defaultProps: ICompleteReactionProps = {
	className: ''
};

const
	renderProductFieldsArray = (props: IFieldArrayComponentProps) => (
		props.fields.map((product, idx) => (
			<ProductFormFields
				key={idx}
				product={props.fields.get(idx)}
				onDelete={() => props.fields.remove(idx)}
				parentFieldName={product}
			/>
		))
	)
;

let CompleteReaction = (props: ICompleteReactionProps = defaultProps) => {
	// state
	const
		history = useHistory(),
		params = useParams<{reactionId: number}>()
	;

	// Effects
	const _loadData = () => {
		if (params?.reactionId) {
			const reactionId = parseInt(params.reactionId, 10);
			if (isNaN(reactionId)) {
				props.setGlobalError('Неверно указанный формат идентификатора реакции!');
				history.push('/no-match');
				return;
			}
			props.setCurrentId(reactionId);
			props.getReaction(reactionId);
			props.getProducts(reactionId);
		}
	};
	useEffect(_loadData, [params?.reactionId]);

	const _setLoader = () => {
		props.setLoader(props.pending)
	};
	useEffect(_setLoader, [props.pending]);

	const _onUnmount = () => () => {
		props.clearStore();
	};
	useEffect(_onUnmount, []);

	const _onReactionLoaded = () => {
		if (props.reaction?.IsCompleted) {
			props.setGlobalError('Невозможно завершить уже завершенную реакцию!');
			gotoReaction();
		}
	};
	useEffect(_onReactionLoaded, [props.reaction]);

	const _onReactionCompletionSuccess = () => {
		if (props.completionSuccess) {
				history.push(`/reactions/${props.reaction?.Id}`);
		}
	};
	useEffect(_onReactionCompletionSuccess, [props.completionSuccess]);

	// handlers
	const
		gotoReaction = () => {
			history.push(`/reactions/${props.reaction?.Id}`);
		},
		completeReaction = () => {
			try {
				const reactionCompletion: IReactionCompletion = ReactionCompletion({
					...props.formValues,
					[ReactionCompletionKeyNames.Products] : props.formValues[ReactionCompletionKeyNames.Products].map((p) => Product(p)),
					[ReactionCompletionKeyNames.Yields]   : props.formValues[ReactionCompletionKeyNames.Yields]?.map((y) => ReactionCompletionYield(y))
				});

				if (props.formValues[INCLUDE_MAIN_PRODUCT_FIELD_NAME]) {
					reactionCompletion.Products.push(Product({ ...props.mainProduct, ...props.formValues[MAIN_PRODUCT_FIELDS] }));
				}
				delete reactionCompletion[MAIN_PRODUCT_FIELDS];
				delete reactionCompletion[INCLUDE_MAIN_PRODUCT_FIELD_NAME];

				props.completeReactionRequest(props.reaction?.Id, reactionCompletion);
			} catch (error) {
				props.setGlobalError(error);
			}

			setTimeout(() => {
				const data: IReactionQuickComment = {
					QuickComment: props.formValues.QuickComment || '',
					ReactionId: props.reaction?.Id,
				};

				props.setQuickComment(props.reaction?.Id, data);
			}, 2000);
		},
		addByProduct = () => {
			props.array.push(
				ReactionCompletionKeyNames.Products,
				Product({
					NameAliases: [],
					IsMainProduct: false,
					ReactionId: props.reaction?.Id,
					MainAgentContent: 100,
				})
			)
		}
	;

	const purificationCount = props.reaction?.PurificationBundleType === PurificationBundleTypeEnum.Parallel ? props.reaction?.RequiredPurificationsRemain : 1;

	// templates
	const
		mainProductHeader = (
			<div className={'mainProductHeading'}>
				<span className={'b-LayoutBlock__title'}>Основной продукт</span>
				<Field
					name={INCLUDE_MAIN_PRODUCT_FIELD_NAME}
					component={BcdCheckboxWrapper}
					label={'Добавить основной продукт в базу'}
					large
				/>
				{
					props.formValues &&
					props.formValues[INCLUDE_MAIN_PRODUCT_FIELD_NAME] &&
					(props.reaction?.ReactionType !== REACTION_TYPE_PURIFICATION_ID
						 || props.reaction?.PurificationBundleType === PurificationBundleTypeEnum.Sequential) &&
					(props.reaction?.RequiredPurificationsRemain > 0
						&& <span className={'nested-purifications-message'}>Будут созданы вложенные реакции очистки: {purificationCount}
						</span>
						// : <Field
						// 	name={CREATE_NESTED_PURIFICATION}
						// 	component={BcdCheckboxWrapper}
						// 	label={'Добавить реакцию очистки'}
						// 	large
						// />
						)
				}
			</div>
		),
		byProductsHeader  = (
			<div className={'byProductsHeading'}>
				<TitleWithCounter
					title={'Побочные продукты'}
					count={
						(props.formValues && ReactionCompletionKeyNames.Products in props.formValues && Array.isArray(props.formValues[ReactionCompletionKeyNames.Products]))
							? props.formValues[ReactionCompletionKeyNames.Products].length
							: 0
					}
				/>
				<Button
					onAction={addByProduct}
					text={'Добавить побочный продукт'}
					icon={faPlus}
					size={'L'}
				/>
			</div>
		)
	;

	return (
		<PageLayout className={props.className}>
			<PageLayoutSlot gridAreaName={pageLayoutAreas.header}
											as={PageLayoutHeader}>
				<PageLayoutSlot gridAreaName={pageLayoutAreas.breadcrumbs} />
				<PageLayoutSlot gridAreaName={pageLayoutAreas.headerBackBtn}>
					<Button
						className={'fo-folder-back'}
						onAction={gotoReaction}
						view={'icon'}
						size={'L'}
					/>
				</PageLayoutSlot>
				<PageLayoutSlot gridAreaName={pageLayoutAreas.headerLeftSide}>
					<h2>Завершение реакции {props.reaction?.Name}</h2>
				</PageLayoutSlot>
				<PageLayoutSlot gridAreaName={pageLayoutAreas.headerRightSide} className={pageLayoutAreas.headerRightSide}>
					<StyledButton
						className={'cancel-btn'}
						onAction={gotoReaction}
						text={'Отмена'}
						size={'L'}
					/>
					<StyledButton
						className={'save-btn'}
						onAction={completeReaction}
						text={'Завершить'}
						size={'L'}
						view={'primary'}
						disabled={props.invalid}
					/>
				</PageLayoutSlot>
			</PageLayoutSlot>

			<PageLayoutSlot gridAreaName={pageLayoutAreas.body} as={'div'}>

				<LayoutBlock header={mainProductHeader}>
					{
						(props.formValues && props.formValues[INCLUDE_MAIN_PRODUCT_FIELD_NAME]) &&
						<Fields
							names={[
								`${MAIN_PRODUCT_FIELDS}.${ProductKeyNames.NameAliases}`,
								`${MAIN_PRODUCT_FIELDS}.${ProductKeyNames.LaboratoryId}`,
								`${MAIN_PRODUCT_FIELDS}.${ProductKeyNames.Location}`,
								`${MAIN_PRODUCT_FIELDS}.${ProductKeyNames.ShortName}`,
								`${ReactionKeyNames.Name}`,
								`${MAIN_PRODUCT_FIELDS}.${ProductKeyNames.Yield}`,
								`${MAIN_PRODUCT_FIELDS}.${ProductKeyNames.ExperimentalMainAgentContent}`,
								`${ReactionCompletionKeyNames.Yields}`
							]}
							component={MainProductSubForm}
							validate={props.reaction?.HasMultipleYields
								? null
								: {
									[`${MAIN_PRODUCT_FIELDS}.${ProductKeyNames.LaboratoryId}`]					: ProductFieldValidators[ProductKeyNames.LaboratoryId],
									[`${MAIN_PRODUCT_FIELDS}.${ProductKeyNames.ExperimentalMainAgentContent}`]	: ProductFieldValidators[ProductKeyNames.ExperimentalMainAgentContent],
									[`${MAIN_PRODUCT_FIELDS}.${ProductKeyNames.ShortName}`]						: ProductFieldValidators[ProductKeyNames.ShortName],
									[`${MAIN_PRODUCT_FIELDS}.${ProductKeyNames.Yield}`]							: ProductFieldValidators[ProductKeyNames.Yield],
									[`${MAIN_PRODUCT_FIELDS}.${ProductKeyNames.Location}`]						: ProductFieldValidators[ProductKeyNames.Location],
								}
						}
						/>
					}
				</LayoutBlock>

				<LayoutBlock header={'Мини комментарий'}>
					<Field
						name={ReactionKeyNames.QuickComment}
						component={ResizableAreaFieldWrapper}
						rows={3}
						maxRows={10}
						placeholder={'Введите комментарий'}
					/>
				</LayoutBlock>

				<LayoutBlock header={byProductsHeader} className={'byProducts'}>
					<FieldArray
						name={ReactionCompletionKeyNames.Products}
						component={renderProductFieldsArray}
					/>
				</LayoutBlock>

				<LayoutBlock header={'Комментарий к реакции'}>
					<Field
						name={ReactionCompletionKeyNames.Comment}
						component={WysiwygEditorFieldComponent}
					/>
				</LayoutBlock>

			</PageLayoutSlot>
		</PageLayout>
	)
};

CompleteReaction = reduxForm({
	form: COMPLETE_REACTION_FORM_NAME,
	destroyOnUnmount: true,
	enableReinitialize: true,
})(CompleteReaction);

CompleteReaction = connect(
	(state: IRootStore): ICompleteReactionProps => ({
		reaction: getReactionByCurrentId(state),
		initialValues: getCompletionReactionInitialFormFromReaction(state),
		formValues: getFormValues(COMPLETE_REACTION_FORM_NAME)(state),
		mainProduct: getCurrentReactionMainProduct(state),
		pending: [state.resource.dictionary.labs.pending, state.resource.reactions.pending, state.resource.products.pending].some(bool => bool === true),
		completionSuccess: state.modules.reaction.reactionComplete.isSuccess,
	}),
	(dispatch: Dispatch): ICompleteReactionProps => ({
		getReaction: (id: number) => dispatch(reactionsResourceActions.get.request({uriParams: {id}})),
		getProducts: (id: number) => dispatch(productsResourceActions.list.request({uriParams: {id}})),
		setCurrentId: (reactionId: number) => dispatch(reactionModuleActions.reactionEdit.setCurrentReactionId({reactionId})),
		setGlobalError: (error: string) => dispatch(globalErrorsActions.set({ error })),
		clearStore: () => [
			reactionsResourceActions.destroy(),
			productsResourceActions.destroy(),
			reactionModuleActions.clear(),
		].forEach(action => dispatch(action)),
		setLoader: (isLoading) => dispatch(setGlobalLoader({ isLoading })),
		completeReactionRequest: (id: number, data: IReactionCompletion) => dispatch(reactionsResourceActions.completeReaction.request({ uriParams: {id}, data })),
		setQuickComment: (id: number, data: IReactionQuickComment) => dispatch(reactionsResourceActions.quickComment.request({
			uriParams: {id},
			data
		}))
	})
)(CompleteReaction);

CompleteReaction = labsLoaderHOC(CompleteReaction);

CompleteReaction = styled(CompleteReaction)`
	--wysiwyg-general-width: auto;
	--wysiwyg-general-height: auto;
	height: auto;
  grid-template-areas:
    "${pageLayoutAreas.header}"
    "${pageLayoutAreas.body}";
	grid-template-columns: auto;
	grid-template-rows: 80px minmax(620px,1fr);
	${PageLayoutHeader} {
		padding-top: var(--grid-spacer);
		padding-left: calc(var(--grid-spacer) * 2.5);
		padding-right: calc(var(--grid-spacer) * 2);
	}
	.tox-tinymce {
		border: 0;
	}
	.b-LayoutBlock {
		padding-bottom: 0;
	} 
	.b-LayoutBlock__header {
		border-bottom: 1px solid var(--border-color);
	}
	.byProducts .b-LayoutBlock__header {
		border-bottom: 0;
	}
	.mainProductHeading {
		display: flex;
		align-items: center;
		> *:not(:last-child) {
			margin-right: calc(var(--grid-spacer) * 2);
		}
	}
	.byProductsHeading {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.resizable-area-component-wrapper {
		margin: var(--grid-spacer) calc(var(--grid-spacer) * 1.5);
		width: auto;
	}
	.nested-purifications-message {
		color: var(--grey-500);
	}
`;

export {
	CompleteReaction,
	COMPLETE_REACTION_FORM_NAME,
	INCLUDE_MAIN_PRODUCT_FIELD_NAME
};
