// @flow
import { Model } from 'objectmodel';
import { PaginationParams } from '../../shared/models';

export const ReactionEventRequest = Model({
	EventType: [Number, null, undefined]
})
.extend(PaginationParams)
.defaultTo({
	skip: 0,
	limit: 10,
	order: '-CreationDate'
})
;
