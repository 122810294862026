// @flow

import { Model } from 'objectmodel';
import { FolderParentTypeModel } from './folder-parent-type-model';

export const Folder = Model({
  Id: [Number],
  IsDeleted: Boolean,
  Name: String,
  ParentId: [Number, null],
  ParentType: FolderParentTypeModel,
})
.defaultTo({
  IsDeleted: false,
});
