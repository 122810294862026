import { Component, Fragment }  from 'react';
import bnc                      from 'bnc';
import Input                    from '../Input';
import Dropdown                 from '../Dropdown';
import Loader                   from '../Loader';
import Resize                   from '../../subjects/Resize';
import Tooltip                  from '../../layout/Tooltip';
import propTypes                from 'prop-types';
import                               './index.less';

export default class Suggest extends Component {

    static emptyHint = 'введите несколько символов';

    static notFoundHint = 'ничего не найдено';

    static propTypes = {
        defaultValue:  propTypes.array,
        options:       propTypes.array .isRequired,
        onChange:      propTypes.func  .isRequired,
        placeholder:   propTypes.string,
        onInput:       propTypes.func,
        SelectedItem:  propTypes.func,
        SuggestedItem: propTypes.func,
        FilterSuggest: propTypes.func,
        multiple:      propTypes.bool,
        disabled:      propTypes.bool,
        loading:       propTypes.bool,
        className:     propTypes.oneOfType([propTypes.string, propTypes.instanceOf(bnc)]),
    };

    static getDerivedStateFromProps = ({defaultValue}, {selected}) => (
        ( defaultValue !== void(0) && selected === void(0) )
            ? { selected: defaultValue }
            : null
    );

    static block = new bnc.default('b-suggest')

    block    = React.createRef()
    input    = React.createRef()
    pending  = React.createRef()
    selected = React.createRef()

    state = {
        inputValue:     '',
        selected:       void(0),
        selectedSizes:  void(0),
        filtered:       [],
        opened:         false,
        pending:        void(0)
    }

    onKeyDown = ({key}) => {
        var {filtered,
             pending,
             opened   } = this.state,
            hasFiltered = filtered.length > 0,
            hasPending  = pending !== void(0);
        switch (key) {
            case 'ArrowUp':     hasFiltered && this.setPendingPrev({filtered, pending}); break;
            case 'ArrowDown':   hasFiltered && this.setPendingNext({filtered, pending}); break;
            case 'Enter':       hasPending  && this.toggleOptionSelected(pending)();     break;
            case 'Escape':      opened      && this.toggleFocusInput(true)();            break;
            default:            return;
        }
    }

    setPendingNext  = ({filtered, pending}) => {
        var [first] = filtered,
            [last]  = filtered.slice(-1),
            newPending = pending === void(0)
                        ? first
                        : pending === last
                            ? first
                            : filtered[ filtered.indexOf(pending) + 1 ]
            ;
        this.setState({pending: newPending});
    }

    setPendingPrev  = ({filtered, pending}) => {
        var [first] = filtered,
            [last]  = filtered.slice(-1),
            newPending = pending === void(0)
                        ? last
                        : pending === first
                            ? last
                            : filtered[ filtered.indexOf(pending) - 1 ]
            ;
        this.setState({pending: newPending});
    }

    toggleFocusInput = toggle => e => {
        e && e.preventDefault();
        e && e.stopPropagation();
        clearTimeout(this.closeTimeout);
        var {opened} = this.state;
        if (toggle) {
            this.input.current.input.current[opened ? 'blur' : 'focus']();
        } else {
            this.input.current.input.current.focus();
        }
    }

    onInput = ({nativeEvent:{ target }}) => {
        var {options}     = this.props,
            inputValue    = `${target.value}`.trim(),
            FilterSuggest = this.props.FilterSuggest || this.FilterSuggest,
            filtered      = FilterSuggest(options, inputValue);

        this.setState(
            { inputValue, filtered, opened: true },
            () =>   this.props.onInput &&
                    this.props.onInput(
                        this.state.inputValue
                    )
        );
    }

    onFocus = () => this.setState({opened: true})

    onTooltipClose = () => this.setState({opened: false})

    onBlur  = () => {
        this.onTooltipClose();
        this.input.current && this.input.current.clear();

        this.closeTimeout = setTimeout(
            () =>   this.setState(
                        {
                            opened:        false,
                            pending:       void(0),
                            selectedSizes: this.selectedSizes,
                            inputValue:    '',
                            filtered:      [],
                        },
                        () => this.selectedSizes = []
                    )
            ,
            200
        );
    }

    toggleOptionMultipleSelectedInput = (option, oi) => e => {
        const {selectedSizes} = this.state;
        this.setState(
            { selectedSizes:
                selectedSizes !== void(0)
                    ? selectedSizes.filter( (op, oii) => oii !== oi )
                    : selectedSizes
            },
            () => this.toggleOptionSelected(option)(e)
        );
    }

    toggleOptionSelected = option => e => {
        if (this.props.disabled) {
            return;
        }
        var {multiple}              = this.props,
            {selected = [], opened} = this.state,
            contains                = this.isOptionSelected(selected, option);

        (multiple && opened) &&
        this.toggleFocusInput()(e);

        this.setSelected(
            multiple
                ? (
                    contains
                        ? selected.filter( o => o !== option && o.value !== option.value )
                        : [...selected, option]
                )
                : (
                    contains
                        ? []
                        : [option]
                )
            ,
            multiple
        );
    }

    setSelected = (selected, multiple) =>
        this.setState(
            multiple
                ? { selected }
                : { selected, inputValue: '', filtered: [] }
            ,
            () => {
                !multiple &&
                this.input.current &&
                this.input.current.clear();

                this.props.onChange &&
                this.props.onChange(this.state.selected);
            }
        )


    selectedCount () {
        const {current} = this.selected;
        if (current) {
            var selected      = [ ...current.querySelectorAll(`.${Suggest.block.el('selected-item')}`)],
                selectedSizes = selected.map( ({offsetWidth}) => offsetWidth + 4 /* margin-right */ );
            this.selectedSizes = selectedSizes;
        }
    }

    setBlockSize = () => {
        const {current} = this.block;
        current &&
        this.setState({
            blockWidth: current.getBoundingClientRect().width
        });
    }

    componentDidMount () {
        this.subscriptions = [
            Resize.subscribe(
                this.setBlockSize
            )
        ];
    }

    componentWillUnmount() {
        this.subscriptions.forEach(
            sub => sub.unsubscribe()
        );
    }

    componentDidUpdate () {
        this.pending.current &&
        this.pending.current.scrollIntoView(false);
        this.selectedCount();
    }

    renderSelected  = ({selected, filtered}, {SelectedItem = this.SelectedItem}) => (
        (selected && selected.length > 0)
            ? <ul
                ref       = {this.selected}
                className = {Suggest.block.el('selected')}
              >
                {
                    selected
                        .map(
                            (option, oi) =>
                                <li
                                    key         = {`oi-${oi}`}
                                    onMouseDown = {this.toggleOptionSelected(option)}
                                    className   = {Suggest.block.el('selected-item')}
                                >
                                    <SelectedItem
                                        key    = {`oi-${oi}`}
                                        option = {option}
                                    />
                                </li>
                        )
                }
            </ul>
            : filtered.length > 0
                ? <div className={
                    Suggest.block.el('selected') +
                    Suggest.block.el('selected').mod('empty')
                }>
                    <small>выберите варианты</small>
                </div>
                : null
    )

    renderSuggested = ({selected, filtered, pending, inputValue}, {SuggestedItem = this.SuggestedItem}) =>
        <ul className={Suggest.block.el('suggested')}>
            {
                filtered
                    .map(
                        (option, oi) => 
                            <li
                                key         = {`oi-${oi}`}
                                onMouseDown = {this.toggleOptionSelected(option)}
                                className   = {
                                    Suggest.block.el('suggested-item') +
                                    Suggest.block.el('suggested-item')
                                                    .mod(
                                                        'selected',
                                                        this.isOptionSelected(selected, option)
                                                            ? 'true'
                                                            : 'false'
                                                    ) +
                                   (
                                    pending === option
                                        ? Suggest.block.el('suggested-item').mod('pending')
                                        : ''
                                    )
                                }
                                {
                                    ...(
                                        pending === option
                                            ? {ref:this.pending}
                                            : null
                                    )
                                }
                            ><span></span>
                                <SuggestedItem
                                    option     = { option }
                                    inputValue = { inputValue }
                                    selected   = { this.isOptionSelected(selected, option) }
                                />
                            </li>
                    )
            }
        </ul>

    isOptionSelected(selected, option) {
        if (!selected)
            return false;            
        if (selected.indexOf(option) > -1)
            return true;
        let ex = selected.filter(v=>v.value == option.value);
        if (!ex)
            return false;
        return (Array.from(ex).length > 0);
    }

    renderEmptyHint = () =>
        <small className={Suggest.block.el('empty-hint')}>
            {Suggest.emptyHint}
        </small>

    renderNotFoundHint = () =>
        <small className={Suggest.block.el('not-found')}>
            {Suggest.notFoundHint}
        </small>

    renderTooltipContent = ({inputValue, filtered}, {multiple}) =>
        <div className={Suggest.block.el('content')}>
            {
                multiple &&
                this.renderSelected(this.state, this.props)
            }
            {
                (filtered.length > 0)
                    ? this.renderSuggested(this.state, this.props)
                    : (inputValue.length > 0)
                        ? this.renderNotFoundHint()
                        : null
            }
            {
                filtered  .length === 0 &&
                inputValue.length === 0 &&
                this.renderEmptyHint()
            }
        </div>

    renderMultipleSelectedInputLabel = ({selected, blockWidth, selectedSizes}, {SelectedItem = this.SelectedItem} ) => {

        var limit           = blockWidth - 32 /* arrow */ - 24 /* counter */  - 32 /* min input */,
            { show, rest }  = selectedSizes
                                ? selectedSizes.reduce(
                                    (acc, w) => {
                                        acc.sum += w;
                                        acc[ acc.sum > limit ? 'rest' : 'show' ] += 1;
                                        return acc;
                                    },
                                    { sum: 0, show: 0, rest: 0 }
                                )
                                : {
                                    show: selected && selected.length     ? 1 : 0,
                                    rest: selected && selected.length > 1 ? selected.length - 1 : 0
                                };

        return <Fragment>
                {
                    selected
                        .slice( 0, show )
                        .map(
                            (option, oi) =>
                                <div
                                    key       = { `oi-${oi}` }
                                    className = { Suggest.block.el('selected-item') }
                                    onClick   = { this.toggleOptionMultipleSelectedInput(option, oi) }
                                >
                                    <SelectedItem option={option}/>
                                </div>
                        )
                }{
                    (rest > 0) &&
                    <div className={Suggest.block.el('selected-multiple-count')}>
                        +{rest}
                    </div>
                }
            </Fragment>;
    }

    renderSingleSelectedInputLabel = ({selected}, {SelectedItem = this.SelectedItem} ) =>
        <div
            onClick   = {this.toggleOptionSelected(selected[0])}
            className = {Suggest.block.el('selected-item')}
        >
            <SelectedItem option={selected[0]}/>
        </div>

    renderSelectedInputLabel = ({selected}, {multiple}) => (
        (selected && selected.length)
            ? multiple
                ? this.renderMultipleSelectedInputLabel( this.state, this.props )
                : this.renderSingleSelectedInputLabel  ( this.state, this.props )
            : null
    )

    renderInput = ({ opened, selected }, { multiple, placeholder, loading, disabled }) =>
        <div
            className = {
                Suggest.block.el('input') +
                Suggest.block.el('input').mod( 'opened',   opened   ? 'true' : 'false' ) +
                Suggest.block.el('input').mod( 'multiple', multiple ? 'true' : 'false' ) +
                Suggest.block.el('input').mod( 'disabled', disabled ? 'true' : 'false' ) +
                Suggest.block.el('input').mod( 'loading',  loading  ? 'true' : 'false' ) +
                Suggest.block.el('input').mod( 'contains',(selected && selected.length && (!multiple || !opened)) ? 'true' : 'false' )
            }

        >
            {
                (!opened || !multiple) &&
                this.renderSelectedInputLabel( this.state, this.props )
            }
            <Input
                placeholder = {
                    (selected === void(0) || (Array.isArray(selected) && (selected.length === 0)))
                        ? placeholder
                        : void(0)
                }
                ref         = { this.input          }
                onChange    = { this.onInput        }
                onKeyDown   = { this.onKeyDown      }
                onFocus     = { this.onFocus        }
                onBlur      = { this.onBlur         }
                disabled    = { disabled || loading }
            />
            {
                loading
                    ? <span
                        className   = {
                            Suggest.block.el('input-loading')
                        }
                    >
                        <Loader />
                    </span>
                    : !disabled
                        ? <span
                            onMouseDown = { this.toggleFocusInput(true) }
                            className   = {
                                Suggest.block.el('input-arrow') +
                                Suggest.block.el('input-arrow').mod('opened', opened ? 'true' : 'false')
                            }
                        >
                            <Dropdown.Arrow />
                        </span>
                        : null
            }

        </div>

    renderSuggest = ({ opened }, { loading, disabled, className='' }) =>
        <div
            ref       = { this.block }
            className = { Suggest.block + className }
        >
            {
                this.renderInput( this.state, this.props )
            }
            {
                (!loading && !disabled && opened) &&
                <Tooltip
                    className = { Suggest.block.el('tooltip') }
                    onClose = { this.onTooltipClose }
                    fit transparent notail
                >
                    {
                        this.renderTooltipContent( this.state, this.props )
                    }
                </Tooltip>
            }
        </div>

    render  = () => this.renderSuggest( this.state, this.props )
}

export class SuggestText extends Suggest {

    static propTypes = {
        caseInsensitive: propTypes.bool
    };

    static searchRe = (inputValue, {caseInsensitive = true}) => {
        try {
            return inputValue ? new RegExp(inputValue,'g' + (caseInsensitive ? 'i' : '') ) : null;
        } catch (e) {
            console.error('Воу-воу, полегче', e);
        }
        return null;
    };

    static highlight = ({option, inputValue}, props) => ({
            dangerouslySetInnerHTML:{
                __html:
                (inputValue && option)
                    ? (`${option}`).replace(
                        SuggestText.searchRe(inputValue, props),
                        `<span class=${ Suggest.block.el('found') }>$&</span>`
                    )
                    : option
            }
        })

    FilterSuggest = (options, inputValue)  => {
        const searchRe = SuggestText.searchRe(inputValue, this.props);
        return searchRe
                ? options.filter(
                    option => option.match( searchRe ) !== null
                )
                : []
        ;
    }

    SelectedItem = ({option}) =>
        <div className={Suggest.block.el('selected-label') }>
            {option}
        </div>

    SuggestedItem = item =>
        <div
            className={Suggest.block.el('suggested-label')}
            {...SuggestText.highlight( item, this.props ) }
        />
}
