import React, { useEffect } from 'react';
import Button from '@biocad/bcd-front-ui/controls/Button';
import Dialog from '@biocad/bcd-front-ui/layout/Dialog';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { jarSpendingResourceActions } from '../../../actions';
import { Form, Field, reduxForm, reset, isValid, isDirty, touch, getFormValues } from 'redux-form';
import { BcdInputWrapper } from '../../../../shared/components/BcdInputWrapper';
import { faFlask } from '@fortawesome/free-solid-svg-icons/faFlask';
import { JarStockSpendingsEnum } from './jar-spendings-enum';
import { SpendingsTable } from './SpendingsDialogTable';
import {FieldValidators} from '../../../../shared/validators/field-validators';
import { JarStockSpendingsDialogLayout } from './style';
import { ChangesWatcher } from '../../../../shared/ChangesWatcher';
import { itemsToArraySelector } from '../../../../shared/utils/selectors/resource-selectors';

const SPEND_JAR_STOCK_FORM_NAME = 'spendJarForm';

const initialValues = {
    Amount: '',
    Reason: '',
};

const fieldValidators = {
    Amount: [ FieldValidators.required, FieldValidators.stepNum(0.0001), FieldValidators.minNum(0.0001) ],
    Reason: [ FieldValidators.required ],
};

let JarStockSpendingsDialog = ({
    Id = null,
    handleClose,
    jarStockSpendings,
    getJarStockSpendings,
    clearJarStockSpendings,
    spendJar,
    spendJarForm,
    resetForm,
    formValid,
    formDirty,
    touchForms,
    pending,
}) => {
  useEffect(() => {
    getJarStockSpendings(Id);
    return () => {
        clearJarStockSpendings();
    };
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    touchForms('Amount', 'Reason');
    if (formValid) {
      spendJar(Id, +spendJarForm.Amount, spendJarForm.Reason);
      resetForm();
    }
  };

  return (
    <Dialog
      close={handleClose}
      paranjaClose={false}>
        <JarStockSpendingsDialogLayout>
          <h3>Ввести данные о расходе</h3>
          <hr />
          <Form onSubmit={handleSubmit} className={'form'}>
            <Field
                name={'Amount'}
                component={BcdInputWrapper}
                useFormFieldLayout={true}
                placeholder={JarStockSpendingsEnum.Amount}
                className={'form-field'}
                type={'number'}
                validate={fieldValidators.Amount}
            />
            <Field
                name={'Reason'}
                component={BcdInputWrapper}
                useFormFieldLayout={true}
                placeholder={JarStockSpendingsEnum.Reason}
                className={'form-field'}
                type={'text'}
                validate={fieldValidators.Reason}
            />
            <Button
                size={'L'}
                text={'Списать'}
                type={'submit'}
                view={'primary'}
                disabled={pending || (!formValid && formDirty) || !formDirty}
                icon={faFlask}
                className={'form-button'}
            />
          </Form>
          <div className={'dialog-scrolling-body'}>
            {
              (jarStockSpendings && jarStockSpendings.length) ?
                <SpendingsTable data={jarStockSpendings} className={'form-table'}/> : ''
            }
          </div>
          <div className={'dialog-bottom'}>
            <Button size={'L'}
                    text={'Закрыть'}
                    onAction={handleClose}
            />
          </div>
        </JarStockSpendingsDialogLayout>
    </Dialog>
  );
};

const mapStateToProps = state => {
    return {
        jarStockSpendings: itemsToArraySelector(state?.resource?.jarSpending),
        pending: state?.resource?.jarSpending.pending,
        spendJarForm: getFormValues(SPEND_JAR_STOCK_FORM_NAME)(state),
        formValid: isValid(SPEND_JAR_STOCK_FORM_NAME)(state),
        formDirty: isDirty(SPEND_JAR_STOCK_FORM_NAME)(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getJarStockSpendings: (
            AgentAvailabilityId: number,
            includeSpentOnReactions?: boolean
        ) => {
            return dispatch(
              jarSpendingResourceActions.list.request({params: {
                    AgentAvailabilityId,
                    includeSpentOnReactions,
                }})
            );
        },

        clearJarStockSpendings: () => {
            return dispatch(jarSpendingResourceActions.destroy());
        },

        spendJar: (
            AgentAvailabilityId: number,
            Amount: number,
            Reason: string
        ) => {
            return dispatch(
              jarSpendingResourceActions.create.request({data: {
                    AgentAvailabilityId,
                    Amount,
                    Reason,
                }})
            );
        },

        resetForm: () => {
            return dispatch(reset(SPEND_JAR_STOCK_FORM_NAME));
        },

        touchForms: (...fields: string[]) => {
            return dispatch(touch(SPEND_JAR_STOCK_FORM_NAME, ...fields));
        },
    };
};

JarStockSpendingsDialog = reduxForm({
    form: SPEND_JAR_STOCK_FORM_NAME,
    destroyOnUnmount: true,
    touchOnChange: true,
    enableReinitialize: true,
    initialValues: initialValues,
})(JarStockSpendingsDialog);

JarStockSpendingsDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(JarStockSpendingsDialog);

JarStockSpendingsDialog = withRouter(JarStockSpendingsDialog);

JarStockSpendingsDialog = ChangesWatcher(
    { formName: SPEND_JAR_STOCK_FORM_NAME },
    JarStockSpendingsDialog,
);

export default JarStockSpendingsDialog;
