// @flow

import {
    put,
    takeEvery,
    fork,
    select,
} from 'redux-saga/effects';
import {getToken} from '../../account/reducers';
import type { IRequestResourceAction } from '../../shared/utils/action-creators';
import { reactionIndicatorsResourceActions } from '../actions/resource';
import { ReactionIndicatorParametersResourceService } from '../services/resource';
import type {IReactionIndicatorParameter} from "../models";

export function* listReactionIndicatorParameters({ params, uriParams }: IRequestResourceAction)
{
    const token = yield select(getToken);
    try
    {
        const response = yield ReactionIndicatorParametersResourceService.list({ params, uriParams }, token);
        yield put(reactionIndicatorsResourceActions.list.success(response));
    }
    catch (error)
    {
        yield put(reactionIndicatorsResourceActions.list.failure({ error }));
    }
}

export function* getParametersForNewReaction({ params, uriParams }: IRequestResourceAction)
{
    const token = yield select(getToken);
    try
    {
        const response = yield ReactionIndicatorParametersResourceService.getForNewReaction({ params, uriParams }, token);
        yield put(reactionIndicatorsResourceActions.list.success(response));
    }
    catch (error)
    {
        yield put(reactionIndicatorsResourceActions.list.failure({ error }));
    }
}

function* batchCreateReactionIndicatorParameters({ data, params }: IRequestResourceAction)
{
    const token = yield select(getToken);
    try
    {
        const response = yield ReactionIndicatorParametersResourceService.batch({ data, params }, token);
        yield put(reactionIndicatorsResourceActions.create.success(response));
    }
    catch (error)
    {
        yield put(reactionIndicatorsResourceActions.create.failure({ error }));
    }
}

function* batchDeleteReactionIndicatorParameters({ data, params }: IRequestResourceAction)
{
    const token = yield select(getToken);
    try
    {
        const response = yield ReactionIndicatorParametersResourceService.delete({ data, params }, token);
        yield put(reactionIndicatorsResourceActions.delete.success(response));
    }
    catch (error)
    {
        yield put(reactionIndicatorsResourceActions.delete.failure({ error }));
    }
}

function* batchAll({ parameters, stageId })
{
    const
        deletedIds = parameters.filter((i: IReactionIndicatorParameter) => i.Id && i.IsDeleted).map(i => i.Id),
        items = parameters.filter((i: IReactionIndicatorParameter) => !i.IsDeleted)
    ;
    if (deletedIds.length)
    {
        yield batchDeleteReactionIndicatorParameters({ data: deletedIds, params: { stageId } });
    }
    if (items.length)
    {
        yield batchCreateReactionIndicatorParameters({ data: items, params: { stageId } });
    }
}

export const reactionIndicatorParametersResourceWatchers = [
    function* ()
    {
        yield takeEvery(reactionIndicatorsResourceActions.list.request.type, function* (action)
        {
            yield fork(listReactionIndicatorParameters, action);
        });
    },

    function* ()
    {
        yield takeEvery(reactionIndicatorsResourceActions.create.request.type, function* (action)
        {
            yield fork(batchCreateReactionIndicatorParameters, action);
        });
    },

    function* ()
    {
        yield takeEvery(reactionIndicatorsResourceActions.delete.request.type, function* (action)
        {
            yield fork(batchDeleteReactionIndicatorParameters, action);
        });
    },

    function* ()
    {
        yield takeEvery(reactionIndicatorsResourceActions.batchAllActions, function* (action)
        {
            yield fork(batchAll, action);
        });
    },

    function* ()
    {
        yield takeEvery(reactionIndicatorsResourceActions.getForNewReaction, function* (action)
        {
            yield fork(getParametersForNewReaction, action);
        });
    },
];
