// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style-m__formula___mdb7G sub {\n  font-size: 50%;\n}\n.style-m__jarNumber___szonm {\n  margin-left: 16px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formula": "style-m__formula___mdb7G",
	"jarNumber": "style-m__jarNumber___szonm"
};
export default ___CSS_LOADER_EXPORT___;
