// @flow

import {BasicModel, Model, Any} from 'objectmodel';
import {ReagentTypeEnum} from '../../reagents/model/reagent-type-enum';
import {Agent, Compound} from '../../agents/models';

export const ArchivedReagent = Model({
  Agent: [Agent, null, undefined],
  AgentId: [Number, null, undefined],
  Amount: [Number, null, undefined],
  Compound: [Compound],
  CompoundId: [Number, null, undefined],
  Concentration: [Number, String, null],
  Density: [Number, String, null],
  Eq: Any,
  FactualConsumption: [Number, null, undefined],
  Id: [Number],
  IsDeleted: Boolean,
  IsRequired: Boolean,
  MainAgentContent: Any,
  Mass: Any,
  ReactionId: [Number, null, undefined],
  ReagentType: BasicModel(Number)
    .assert(t => Object.values(ReagentTypeEnum).filter(v => Number.isInteger(v) && (v >= 1 && v <= 5)).includes(t)),
  Volume: Any
})
  .defaultTo({
    Eq: 1,
    Amount: null,
    Mass: null,
    Volume: null,
    MainAgentContent: null,
    FactualConsumption: null,
    IsRefCompound: false,
    IsDeleted: false,
    IsRequired: false
  });
