import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import Button from '@biocad/bcd-front-ui/controls/Button';
import classNames from 'classnames';
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {ConfirmDialog} from '../../../../shared/components/ConfirmDialog/ConfirmDialog';
import {IconButtonBackwards} from '../../../../shared/components/IconButtonBackwards';
import {itemsToArraySelector} from '../../../../shared/utils/selectors/resource-selectors';
import {
    candidateModuleActions,
    candidatesResourceActions,
    folderModuleActions,
    folderResourceActions,
    orderTypes,
} from '../../../../projects/actions';
import {
    folderParentType,
    projectStatusEnum,
    projectTypeEnum,
    projectStatusTitlesEnum
} from '../../../../projects/types/enum';
import FolderModal from '../../../../projects/components/ProjectsTable/Folders/modal';
import './index.less';
import queryString from 'query-string';
import {_temp_folderResourceAction} from '../../../../folders/actions';
import {userCRUDPermissionsSelector} from "../../../../account/reducers/users-modules-reducer";
import {getClassNameByProjectType} from "../../../../projects/types/utils";
import type {OptionType} from '../../../../shared/models';
import Dropdown from '@biocad/bcd-front-ui/controls/Dropdown';
import ItemsSearchForm from "../../../../projects/components/Project/SearchForm";

const initialProps = {
    candidatesPath: [],
    getCandidatesList: () => null,
    getFoldersList: () => null,
    clearCandidatesList: () => null,
    clearFoldersList: () => null,
    currentFolderId: null,
    removeCandidatePath: () => null,
    deleteFolderPath: () => null,
    candidates: [],
    setCurrentCandidate: () => null,
    setEditMode: () => null,
    setEditFolderMode: () => null,
    deleteCandidate: () => null,
    currentCandidate: null,
    isViewMode: false,
    setViewMode: false,
    currentProject: null,
    location: {},
    tempFolder: null,
    destroyTempFolder: () => null,
    userPermissions: {},
    filterCodes: [],
    setFilterCodes: (arr: string[]) => {}
};

const createCandidateOptions: OptionType[] = [
    {
        label: 'Добавить молекулу',
        value: 'regular'
    },
    {
        value: 'antibody',
        label: 'Добавить антитело'
    },
    {
        value: 'polymer',
        label: 'Добавить полимер'
    },
]

const orderOptions: OptionType[] = [
    {
        label: 'По времени добавления',
        value: orderTypes.Id,
    },
    {
        label: 'По коду',
        value: orderTypes.Code,
    },
]


const CandidatesHeader = (props = initialProps) => {
    const
        [showFolderModal, setShowFolderModal] = useState(false),
        [isConfirmShown, setIsConfirmShown] = useState(false),
        [projectId, setCurrentProjectId] = useState(null)
    ;

    useEffect(() => {
        setCurrentProjectId(props.location.pathname.split('/')[2]);
        props.setFilterCodes([]);
    }, []);

    useEffect(() => {
        if (props.candidates[0] && !props.currentCandidate) {
            props.setCurrentCandidate({
                candidate: props.candidates[0]
            });
        }
    }, [props.candidates]);

    const createNewCandidate = (value) => {
        props.setEditMode({
            isEditMode: false
        });

        props.history.push(`/candidates/add?projectId=${projectId || props.location.pathname.split('/')[2]}&compoundType=${value}`);
    };

    const editItem = () => {
        props.setEditMode({
            isEditMode: true
        });

        props.history.push(`/candidates/${props.currentCandidate.Id}`);
    };

    const toggleFolderModal = () => {
        props.setEditFolderMode({
            isEditMode: false
        });
        setShowFolderModal(!showFolderModal);
    };

    const deleteItem = () => {
        let urlParams = queryString.parse(props.location.search);
        urlParams = {
            isViewMode: false,
            folderId: urlParams.folderId,
            selectedId: props.candidates[0].Id
        };

        props.candidates.length <= 1 && delete urlParams.selectedId;

        props.history.push(props.location.pathname + '?' + queryString.stringify(urlParams));

        props.setViewMode({
            isViewMode: false
        });

        props.clearCandidatesList();
        props.deleteCandidate({
            id: props.currentCandidate.Id,
            projectId: projectId,
            folderId: props.currentCandidate.FolderId
        });

        toggleConfirmModal();
    };

    const toggleConfirmModal = () => {
        setIsConfirmShown(!isConfirmShown);
    };

    const onFilterApplied = () => {
        const urlParams = queryString.parse(props.location.search);
        const urlParamFolderId = urlParams?.folderId;
        const pathnameFolderId = props.location.pathname.split('/')[2];
        props.clearCandidatesList();
        props.clearFoldersList();
        if (!props.filterCodes || props.filterCodes.length === 0) {
            props.getFoldersList({
                parentId: urlParamFolderId || pathnameFolderId,
                parentType: urlParamFolderId ? folderParentType.folder : folderParentType.project
            });
        }
        props.getCandidatesList({
            projectId: projectId,
            folderId: urlParamFolderId ? urlParamFolderId : null,
            filterCode: props.filterCodes
        });
    };

    const reloadList = () => {
        props.clearCandidatesList();
        props.clearFoldersList();
        if (!props.filterCodes || props.filterCodes.length === 0) {
            props.getFoldersList({
                parentId: props.candidatesPath.length > 1 ?
                  props.candidatesPath[props.candidatesPath.length - 2].id
                  : props.currentProject.Id,

                parentType: props.candidatesPath.length > 1 ?
                  folderParentType.folder : folderParentType.project
            });
        }
        props.getCandidatesList({
            projectId: projectId,
            folderId: props.candidatesPath.length > 1 ?
                props.candidatesPath[props.candidatesPath.length - 2].id
                : null,
            filterCode: props.filterCodes
        });
    };

    useEffect(() => {
            if (props.filterCodes && projectId && props.currentProject?.Id) {
                    onFilterApplied();
                }
        }, [props.filterCodes]);

    const onSearchFormUpdate = (valuesList: Array<string>) => {
        props.setFilterCodes(valuesList);
    }

    const goBack = () => {
        props.destroyTempFolder();

        if (props.isViewMode) {
            let urlParams = queryString.parse(props.location.search);
            urlParams.isViewMode = false;

            props.history.push(props.location.pathname + '?' + queryString.stringify(urlParams));

            props.setViewMode({
                isViewMode: false
            });

            !props.candidates.length && reloadList();

        } else {
            let urlParams = queryString.parse(props.location.search);

            if (!urlParams.folderId && !props.candidatesPath.length) {
                props.deleteFolderPath();

                props.history.push('/projects/list');

                props.clearCandidatesList();
                props.clearFoldersList();

            } else if (urlParams.folderId && !props.candidatesPath.length) {
                props.history.push(props.location.pathname);

                props.clearCandidatesList();
                props.clearFoldersList();

                props.getFoldersList({
                    parentId: props.currentProject.Id,
                    parentType: folderParentType.project
                });

                props.getCandidatesList({
                    projectId: projectId,
                    folderId: null
                });
            } else {
                props.removeCandidatePath();

                urlParams.folderId = props.candidatesPath[props.candidatesPath.length - 2] ?
                    props.candidatesPath[props.candidatesPath.length - 2].id :
                    null;

                urlParams.folderId ? props.history.push(props.location.pathname + '?' + queryString.stringify(urlParams)) :
                    props.history.push(props.location.pathname);

                reloadList();

                props.setCurrentCandidate({
                    candidate: null
                });
            }
        }

    };

    return (
        <div className={'candidates-header'}>
            <IconButtonBackwards onClick={goBack}/>

            <div className={'title-panel'}>

                {!props.isViewMode && props.currentProject &&
                <div className={'title'}>
                    {props.candidatesPath.length === 0 && !props.tempFolder ?
                        <i className={getClassNameByProjectType(props?.currentProject)}/> :
                        <span className={'title-separator'}>.../&nbsp;</span>
                    }
                    <h2>
                        {
                            props.candidatesPath.length === 0 && !props.tempFolder ?
                                props.currentProject?.Name + '(' +
                                projectStatusTitlesEnum[props.currentProject?.Status] + ')' :
                                props.tempFolder?.Name || props.candidatesPath[props.candidatesPath.length - 1]?.name
                        }
                    </h2>
                </div>}

                {!props.isViewMode &&
                <div className={'counter'}>
                    Целевых молекул: <span>{props.candidates.length}</span>
                </div>}

                {props.isViewMode &&
                <div className={'title'}>
                    <h2>{props.currentCandidate?.Code}</h2>
                </div>
                }
            </div>
            {!props.isViewMode && <ItemsSearchForm onUpdate={onSearchFormUpdate} itemsList={props.filterCodes}/>}
            <div className={'sorting-panel'}>
                <span>Сортировка: &nbsp;</span>
                <Dropdown
                    className={'header-dropdown'}
                    onChange={(val)=> props.setOrder(val)}
                    options={orderOptions}
                    defaultValue={orderOptions.find(i => i.value === props.order) || orderOptions[0]}
                />
            </div>
            <div className={classNames('buttons-panel', {'no-candidate': !props.currentCandidate || !props.candidates.length})}>
                {
                    props.userPermissions.candidate.UPDATE &&
                    <>
                        <i className={'fo-edit'} onClick={editItem}/>
                        <div className={'separator'}/>
                    </>
                }
                {
                    props.userPermissions.folder.CREATE &&
                    <>
                        <i className={'fo-add-folder'} onClick={toggleFolderModal}/>
                        <div className={'separator'}/>
                    </>
                }
                {
                    props.userPermissions.candidate.DELETE &&
                    <i className={'fo-trash'} onClick={toggleConfirmModal}/>
                }
                {
                    props.userPermissions.candidate.CREATE &&
                        <Dropdown
                            onChange={createNewCandidate}
                            options={createCandidateOptions}
                            value={'Новая молекула'}
                        />
                }
            </div>

            {(isConfirmShown && props.userPermissions.candidate.DELETE) &&
            <ConfirmDialog
                title={'Внимание'}
                message={'Вы уверены, что хотите удалить эту молекулу?'}
                confirmLabel={'Да'}
                declineLabel={'Отмена'}
                confirmAction={deleteItem}
                declineAction={toggleConfirmModal}
            />}

            {(showFolderModal && (props.userPermissions.folder.CREATE || props.userPermissions.folder.UPDATE))
            && <FolderModal toggleModal={toggleFolderModal} position={'candidate'}/>}
        </div>
    );

};

const mapStateToProps = (state) => {
    const {resource, modules} = state;
    return {
        candidatesPath: modules.folder.candidatesPath,
        currentProject: modules.project.currentProject,
        currentFolderId: modules.folder.id,
        projects: itemsToArraySelector(resource.projects),
        candidates: itemsToArraySelector(resource.candidates),
        currentCandidate: modules.candidate.candidate,
        order: modules.candidate.order,
        isViewMode: modules.candidate.isViewMode,
        tempFolder: modules._folder_temp.currentFolder,
        userPermissions: userCRUDPermissionsSelector(state),
        filterCodes: modules.candidate.filterCodes
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        (dispatch) => ({
            getCandidatesList: (params) => dispatch(candidatesResourceActions.list.request({params})),
            clearCandidatesList: () => dispatch(candidatesResourceActions.destroy()),
            setEditFolderMode: (isEditMode) => dispatch(folderModuleActions.setEditMode(isEditMode)),
            getFoldersList: (params) => dispatch(folderResourceActions.list.request({params})),
            deleteFolderPath: () => dispatch(folderModuleActions.deleteFolderPath()),
            clearFoldersList: () => dispatch(folderResourceActions.destroy()),
            removeCandidatePath: () => dispatch(folderModuleActions.removeFolderCandidatePathItem()),
            setCurrentCandidate: (candidate) => dispatch(candidateModuleActions.setCurrentCandidate(candidate)),
            setEditMode: (isEditMode) => dispatch(candidateModuleActions.setEditMode(isEditMode)),
            deleteCandidate: (data) => dispatch(candidatesResourceActions.delete.request({data})),
            setViewMode: (isViewMode) => dispatch(candidateModuleActions.setViewMode(isViewMode)),
            destroyTempFolder: () => dispatch(_temp_folderResourceAction.destroy()),
            setOrder: (order) => dispatch(candidateModuleActions.setOrder(order)),
            setFilterCodes: (filterCodes) => dispatch(candidateModuleActions.setFilterCodes(filterCodes))
        })
    )(CandidatesHeader)
);

