// @flow

import type { IReactionParameter } from './i-reaction-parameter';
import type { IReactionYield } from './i-reaction-yield';

export const ReactionKeyNames = {
	AttachmentsText: 'AttachmentsText',
	AuthorUserName: 'AuthorUserName',
	Comment: 'Comment',
	Equipment: 'Equipment',
	CreationDate: 'CreationDate',
	ExperimentalPercentYield: 'ExperimentalPercentYield',
	ExperimentalYield: 'ExperimentalYield',
	HPLCMSText: 'HPLCMSText',
	Id: 'Id',
	InitialStageId: 'InitialStageId',
	IsCompleted: 'IsCompleted',
	IsDeleted: 'IsDeleted',
	IsTrial: 'IsTrial',
	Methodology: 'Methodology',
	ModificationDate: 'ModificationDate',
	MSText: 'MSText',
	Name: 'Name',
	NMRText: 'NMRText',
	Number: 'Number',
	ReactionExtraConditions: 'ReactionExtraConditions',
	ReactionType: 'ReactionType',
	TheoreticalYield: 'TheoreticalYield',
	TLCText: 'TLCText',
	ArchiveProjectName: 'ArchiveProjectName',
	ChemistAbbreviation: 'ChemistAbbreviation',
	StartDate: 'StartDate',
	EndDate: 'EndDate',
	CompleteDate: 'CompleteDate',
	IsArchived: 'IsArchived',
	LaboratoryId: 'LaboratoryId',
	ProjectType: 'ProjectType',
	ReactionParameters: 'ReactionParameters',
	ReactionYields: 'ReactionYields',
	HasMultipleYields: 'HasMultipleYields',
	IsSuccessful: 'IsSuccessful',
	OriginalId: 'OriginalId',
	ParentRepkaTaskId: 'ParentRepkaTaskId',
	QuickComment: 'QuickComment',
	RefReagentName: 'RefReagentName',
	RepkaTaskId: 'RepkaTaskId',
	FolderId: 'FolderId',
	VolumeBasedSolvents: 'VolumeBasedSolvents',
	SeriesAfsAim: 'Series.SeriesAfsAim',
	SeriesAfsNumber: 'Series.SeriesAfsNumber',
	SeriesComposition: 'Series.SeriesComposition',
	SeriesProcessScale: 'Series.SeriesProcessScale',
	SeriesProductionDate: 'Series.SeriesProductionDate',
	SeriesComment: 'Series.SeriesComment',
	ReactionTemplateId: 'ReactionTemplateId',
	AdditionalName: 'AdditionalName',
	IsTemplate: 'IsTemplate',
};

export const
  REACTANTS_KEY_NAME = 'reactants'
;
