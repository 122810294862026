// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".popup-m__popup___RWpWo {\n  width: 1000px;\n}\n.popup-m__contetns____JWgm {\n  max-height: 548px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": "popup-m__popup___RWpWo",
	"contetns": "popup-m__contetns____JWgm"
};
export default ___CSS_LOADER_EXPORT___;
