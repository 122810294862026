// @flow

import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {GridTable, GridTableCol} from '../../../../../shared/components/GridTable';
import Checkbox from '@biocad/bcd-front-ui/controls/Checkbox';
import type {IJarStock, IJarUser} from '../../../../../agents/models';
import {jarStockResourceActions} from '../../../../../agents/actions';
import {_noop} from '../../../../../shared/utils/common';
import {AgentsSubTableStyled} from '../../../../../agents/components/List/JarStockTable/style';
import {itemsToArraySelector} from '../../../../../shared/utils/selectors/resource-selectors';
import type {IRootStore} from '../../../../../app/reducers';
import { getFormValues } from 'redux-form';
import { AGENT_SEARCH_FORM_NAME } from '../../../../../agents/components/List/SearchForm';
import {labsLoaderHOC} from '../../../../../dictionary/components/labsLoaderHOC';
import {getAmountRemainsIndicator} from '../../../../../agents/utils';

interface IChooseJarTable {
    agentId: Number,
    labsList: any[],
    jarStockData: IJarStock[],
    getJarStockData: Function,
    clearJarStockData: Function,
    onJarSelected: (jars: IJarStock[])=>void
}

const initialProps = {
    agentId: null,
    jarStockData: [],
    labsList: [],
    getJarStockData: _noop,
    clearJarStockData: _noop
};

const
    renderJarUsersList = (props: { item: IJarStock }) => (
        <div className={'table-cell-jar-users'}>
            {
                props.item?.JarUsers?.map((user: IJarUser, idx: number) => (
                    <div key={`${user.UserName}__${idx}`}>
                        <span className={`icon ${user.ReactionCompleted ? 'completed' : 'uncompleted'}`}>&#9679;</span>
                        <span>{user.Abbreviation}</span>
                    </div>
                ))
            }
        </div>
    )
;

let ChooseJarTable = (props: IChooseJarTable = initialProps) => {

    // State
    const [selectedJars, setSelectedJars] = useState([]);

    // Effects
    useEffect(() => {
        props.clearJarStockData();
        setSelectedJars([]);

        props.getJarStockData(props.agentId);
    }, [props.agentId]);

    useEffect(() => {
        if (props.onJarSelected && typeof props.onJarSelected === 'function') {
            props.onJarSelected(selectedJars);
        }
    }, [selectedJars]);

    // Methods

    const setJarStockRowClassByAmount = (item) =>
    {
        const indicator = getAmountRemainsIndicator(item);
        if (indicator)
        {
            return {
                [indicator.className]: true, 'disabled no-hover': item.IsOrdered
            }
        }
        else {
            return {'disabled no-hover': item.IsOrdered};
        }
    };

    const
        onJarStockClick = (reactant, rowidx, event) => {
            event.stopPropagation();
            if (reactant.IsOrdered) {
                return;
            }
            setSelectedJars(prev => {
                if (prev.find((r) => r.Id === reactant.Id)) {
                    return prev.filter(r => r.Id !== reactant.Id)
                }
                return [...prev, reactant];
            });
        },
        jarStock = () =>
        (
            Array.isArray(props.jarStockData) &&
            props.jarStockData
                .filter((jar) => !props.isInStockOnly || jar.Amount > 0)
        )
    ;
    return (
        <>
            <AgentsSubTableStyled
                as={GridTable}
                items={jarStock()}
                rowHeight={'auto'}
                colsTemplate={'50px repeat(8, minmax(100px, 1fr))'}
                onRowClicked={onJarStockClick}
                setRowClassNames={setJarStockRowClassByAmount}
                hover={true}>
                <GridTableCol
                    render={({item, rowidx}) => !item.IsOrdered && <Checkbox
                        checked={!!selectedJars.find((i) => i.Id === item.Id)}
                        large={true}
                        onChange={(event) => onJarStockClick(item, rowidx, event)}
                    />}
                />
                <GridTableCol
                    title={'Производитель'}
                    render={({item}: { item: IJarStock }) => <span>{item?.Provider}</span>}
                />
                <GridTableCol
                    title={'Квалификация'}
                    render={({item}: { item: IJarStock }) => <span>{item?.Qualification}</span>}
                />
                <GridTableCol
                    title={'Осн. вещество, %'}
                    render={({item}: { item: IJarStock }) => <span>{item?.MainAgentContent}</span>}
                />
                <GridTableCol
                    title={'М, моль/л'}
                    render={({item}: { item: IJarStock }) => <span>{item?.Concentration}</span>}
                />
                <GridTableCol
                    title={'Доступно, г'}
                    render={({item}: { item: IJarStock }) => <span>{item?.Amount}</span>}
                />
                <GridTableCol
                    title={'Расположение'}
                    render={({item}: { item: IJarStock }) =>
                        <span>{props.labsList && props.labsList[item.LaboratoryId] ? props.labsList[item.LaboratoryId].Name : ''}</span>}
                />
                <GridTableCol
                    title={'Место'}
                    render={({item}: { item: IJarStock }) => <span>{item?.Location}</span>}
                />
                <GridTableCol
                    title={'Химик'}
                    render={renderJarUsersList}
                />

            </AgentsSubTableStyled>
        </>
    );
};


export default labsLoaderHOC(connect(
    (state: IRootStore) => ({
        labsList: state.resource.dictionary.labs.data,
        jarStockData: itemsToArraySelector(state.resource.jarStock),
        isInStockOnly: getFormValues(AGENT_SEARCH_FORM_NAME)(state).isInStockOnly,
    }),
    (dispatch) => ({
        getJarStockData: (Ids: number) => dispatch(jarStockResourceActions.list.request({params: {Ids}})),
        clearJarStockData: () => dispatch(jarStockResourceActions.destroy())
    })
)(ChooseJarTable));
