// @flow

import React, { useEffect } from 'react';
import Button from '@biocad/bcd-front-ui/controls/Button';
import {
  StyledLayoutHeader
} from './style';
import { List } from './List';
import {
  CATALOG_REACTIONS_SEARCH_FORM_NAME,
  SearchForm,
} from './SearchForm';
import { connect } from 'react-redux';
import { projectActions } from '../../../projects/actions';
import { getFormValues, reset, isDirty } from 'redux-form';
import { reactionModuleActions } from '../../actions';
import {initialReactionSearchParams} from '../../reducers';

interface IReactionCatalogProps {
  getAllProjects: typeof Function,
  clearSearch: typeof Function,
  updateSearchParams: typeof Function,
  clearModule: typeof Function,
  searchFormValues: any,
  searchFormIsDirty: boolean,
}

const defaultReactionCatalogProps: IReactionCatalogProps = {
  getAllProjects: ()=>null,
  clearSearch: ()=>null,
  updateSearchParams: ()=>null,
  clearModule: ()=>null,
  searchFormValues: {},
  searchFormIsDirty: false,
};

let ReactionCatalog = (props: IReactionCatalogProps = defaultReactionCatalogProps) => {

  useEffect(() => {
    props.getAllProjects();
    return () => {
      props.clearModule();
    };
  }, []);

  const searchReactions = () => {
    const params = props.searchFormValues && typeof props.searchFormValues === 'object' ? props.searchFormValues : {};
      // Object.entries(props.searchFormValues)
      //   .filter(([_, fieldValue]) => fieldValue !== null)
      //   .reduce((acc, [fieldName, fieldValue])=>({ ...acc, [fieldName]: fieldValue }), {});
    props.updateSearchParams({ ...params, Skip: 0 });
  };

  const resetSearch = () => {
    props.clearSearch();
    props.updateSearchParams({ ...initialReactionSearchParams, preventSearchRequest: true });
  };

  return (
    <>
      <StyledLayoutHeader>
        <h2>Поиск реакций</h2>
        <div className={'buttons-holder'}>
          {
            props.searchFormIsDirty &&
            <Button
              text={'Очистить фильтр'}
              size={'L'}
              onAction={resetSearch}
            />
          }
          <Button
            text={'Поиск'}
            size={'L'}
            view={'primary'}
            onAction={searchReactions}
          />
        </div>
      </StyledLayoutHeader>

      <SearchForm />

      <List />

    </>
  );
};

ReactionCatalog = connect(
  (state): IReactionCatalogProps => {
    return {
      searchFormValues: getFormValues(CATALOG_REACTIONS_SEARCH_FORM_NAME)(state),
      searchFormIsDirty: isDirty(CATALOG_REACTIONS_SEARCH_FORM_NAME)(state),
    };
  },
  (dispatch): IReactionCatalogProps => ({
    getAllProjects: () => dispatch(projectActions.getAll.request()),
    clearSearch: () => dispatch(reset(CATALOG_REACTIONS_SEARCH_FORM_NAME)),
    updateSearchParams: (params) => dispatch(reactionModuleActions.catalogSearchParams.update({ params })),
    clearModule: () => dispatch(reactionModuleActions.clear()),
  })
)(ReactionCatalog);

export {
  ReactionCatalog
};
