// @flow
import React from 'react';
import {Field, reduxForm, Form} from 'redux-form';
import Button from '@biocad/bcd-front-ui/controls/Button';
import {faSearch} from '@fortawesome/free-solid-svg-icons/faSearch';
import {
  BcdInputWrapper
} from '../../../shared/components';
import styled from 'styled-components';

const StyledSearchFormLayout = styled.div`
  .searchFields {
        display: flex;
        flex-direction: row;
    }
    .b-input {
        border-radius: 4px 0 0 4px;
    }
    .b-button {
        border-radius: 0 4px 4px 0;
    }
`;

const initialValues: {
  search: string
} = {
  search: ''
};

let SearchForm = ({onSubmit}) => {
  return (
    <Form onSubmit={onSubmit}>
      <StyledSearchFormLayout>
        <div className={'searchFields'}>
          <Field
            name={'search'}
            component={BcdInputWrapper}
            props={{
              placeholder: 'Поиск пользователя',
              icon: faSearch,
              className: 'searchString',
            }}
            type="text"
          />
          <Button
            text="Найти"
            view="primary"
            size="L"
            type={'submit'}
          />
        </div>
      </StyledSearchFormLayout>
    </Form>
  );
};

export const USERS_SEARCH_FORM_NAME = 'usersSearchForm';

export default reduxForm({
  form: USERS_SEARCH_FORM_NAME,
  initialValues
})(SearchForm);
