import { BehaviorSubject, fromEvent } from 'rxjs';

var getSize = () => ({
        width:  window.innerWidth,
        height: window.innerHeight
    }),

    Resize  = new BehaviorSubject( getSize() );

fromEvent( window, 'resize' ).subscribe( () => Resize.next( getSize() ) );

export default Resize;
