import Tooltip from '@biocad/bcd-front-ui/layout/Tooltip';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reactionModuleActions, reactionRepkaTaskListActions } from '../../actions';
import { RepkaTaskTitles } from '../../models/index.js';
import css from './repka.m.less';
import './tooltip-custom.less';
import Loader from '@biocad/bcd-front-ui/controls/Loader';
import classNames from 'classnames';

let RepkaTaskSelector = ({ stageId, tasks, selectedTask, selectTask, loadList, clearList }) => {

  useEffect(() => {
    stageId && loadList(stageId);
    return () => clearList();
  }, []);

  const Task = ({ task, onClick }) => {
    return (
      <div className={classNames(css.task, task.RepkaTaskId === selectedTask?.RepkaTaskId ? css.task__selected : null)} onClick={onClick}>
        <span>
          <span className={css.task__header}>{RepkaTaskTitles.Name} </span>
          <span>{task?.Name}</span>
        </span>
        <span>
          <span className={css.task__header}>{RepkaTaskTitles.RepkaTaskId} </span>
          <span>{task?.RepkaTaskId}</span>
        </span>
        <span>
          <span className={css.task__header}>{RepkaTaskTitles.Deadline} </span>
          <span>{task?.Deadline}</span>
        </span>
      </div>
    );
  };

  const onTaskClick = (task) => {
    selectTask({task: {...task}});
  };
  

  const render = () => (
    <div className={css.dropdown}>
      {
        selectedTask 
          ? <span><strong>{RepkaTaskTitles.RepkaTaskId} </strong>{selectedTask.RepkaTaskId}</span>
          : <span className={css.dropdown__placeholder}>Выберите задачу</span>
      }
      <Tooltip.Click className='rtd-tooltip-custom' fit notail>
        {
          tasks.map((task, index) => <Task onClick={() => onTaskClick(task)} className={css.taskList} key={index} task={task}/>)
        }
      </Tooltip.Click>
    </div>
  );

  return (
    <>
      {
        tasks.length > 0
          ? render()
          : <Loader />
      }
    </>
  );
};

const mapStateToProps = ({ modules }) => ({
  tasks: modules.reaction.repkaTask.list,
  selectedTask: modules.reaction.repkaTask.selected,
});

const mapDispatchToProps = dispatch => ({
  loadList: (id) => dispatch(reactionRepkaTaskListActions.request({data: {id}})),
  selectTask: ({ task }) => dispatch(reactionModuleActions.repkaTask.select({ task })),
  clearList: () => dispatch(reactionModuleActions.repkaTask.clearList()),
  destroy: () => dispatch(reactionModuleActions.repkaTask.destroy())
});

RepkaTaskSelector = connect(
  mapStateToProps,
  mapDispatchToProps
)(RepkaTaskSelector);

export { RepkaTaskSelector };

