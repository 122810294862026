import styled from 'styled-components';
import {amountColorIndicators, AmountRemainsIndicatorEnum} from '../../../utils';


export const JarStockFormStyled = styled.form`
  display: grid;
  grid-template-columns: min-content minmax(170px, 1fr) repeat(6, 1fr) min-content minmax(150px, 1fr) minmax(150px, 1fr) min-content minmax(150px, 1fr) 1fr;
  grid-template-rows: [header-start] 42px [header-end row-start] auto [row-end];
  grid-auto-rows: auto;
  grid-auto-columns: auto;
  grid-gap: 0;
  overflow: auto;
  border-top: 1px solid var(--table-border-color);
  border-bottom: 1px solid var(--table-border-color);
  font-size: var(--table-font-size);  
  
  & .align-right {
    text-align: right;
  }
  
  & .table-row,
  & .table-rowHeading {
    &:first-child {
      padding-left: calc(var(--table-cell-padding-v) * 2);
    }
  }
  
  & .date-col {
        padding: var(--table-cell-padding-v) var(--table-cell-padding-h);
        .b-input {
            width:100px !important;
        }
    }
   
  
  & .table-row {
    padding: var(--table-cell-padding-v) var(--table-cell-padding-h);
    &:last-child {
      padding-right: calc(var(--table-cell-padding-v) * 1.5);
    }
  }
  
  & .table-rowHeading {
    color: var(--table-title-color);
    padding: var(--table-cell-padding-v) var(--table-cell-padding-h);
    grid-row: header;
    white-space: nowrap;
  }
  
  & .table-rowWrap {
    display: contents;    
    :hover > *:not(.table-rowHeading) {
      background-color: var(--hover-bg-color);
    }
    :not(:last-of-type) > * {
      border-bottom: 1px solid var(--table-border-color);
    }
  }
  
  & .buttons-wrap {
    display: flex;
    align-items: center;
    
    .action-btn  {
      width: 36px;
      height: 36px;
      font-size: 1.5em;
    }
  }

  & .file-name{
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .b-InlineEdit__valueHolder {
    margin-left: 0;
    margin-right: 0;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
    &::after {
      right: 0 !important;
      left: 0 !important;
    }
  }
  .b-InlineEdit__inputHolder {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  
  .b-InlineEdit__value  {
	  max-width: 100%;
	  overflow: hidden;
	  text-overflow: clip;
      white-space: nowrap;
    &.on-top {
      z-index: 10000;
    }
  }
  
  .b-InlineEdit-mod-Readonly .b-InlineEdit__valueHolder
  {
    padding-left: 0;
    padding-right: 0;
  }

  .b-InlineEdit-hasErr:not(.b-InlineEdit-mod-Edit)::before {
    left: 0;
    right: 0;
  }

  .${amountColorIndicators[AmountRemainsIndicatorEnum.Empty].className} > *
  {
    background-color: var(--red-o-15) !important;
  }
  .${amountColorIndicators[AmountRemainsIndicatorEnum.Low].className} > *
  {
    background-color: var(--orange-o-5) !important;
  }
  .${amountColorIndicators[AmountRemainsIndicatorEnum.Medium].className} > *
  {
    background-color: var(--yellow-o-5) !important;
  }
  .${amountColorIndicators[AmountRemainsIndicatorEnum.Full].className} > *
  {
    background-color: var(--green-o-20) !important;
  }
  .jar-amount-remains--ignore-spending > *
  {
    background-color: var(--green-o-40) !important;
  }
`;
