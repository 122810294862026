// @flow

export const CatalogReactionRequestParamsEnum = {
  Author: 'Author',
  CompleteDateFrom: 'CompleteDateFrom',
  CompleteDateTo: 'CompleteDateTo',
  CreationDateFrom: 'CreationDateFrom',
  CreationDateTo: 'CreationDateTo',
  IncludeArchived: 'IncludeArchived',
  IncludeCompleted: 'IncludeCompleted',
  IncludeExpYield: 'IncludeExpYield',
  IncludeIncomplete: 'IncludeIncomplete',
  IncludeRegular: 'IncludeRegular',
  IncludeTrialFailed: 'IncludeTrialFailed',
  IncludeTrialIncomplete: 'IncludeTrialIncomplete',
  IncludeTrialSuccessful: 'IncludeTrialSuccessful',
  IncludeWithoutExpYield: 'IncludeWithoutExpYield',
  ProjectId: 'ProjectId',
  SmilesFormula: 'SmilesFormula',
  UserName: 'UserName',
  ReactionName: 'ReactionName',
  FinalCandidateCode: 'FinalCandidateCode',
  Skip: 'Skip',
  Limit: 'Limit',
  Order: 'Order',  
  ReactionTypes: 'ReactionTypes',
  OnlyTemplateReactions: 'OnlyTemplateReactions',
};
