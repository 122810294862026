// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".repka-m__dialogLayout___NPqbi {\n  width: 320px;\n  height: 200px;\n}\n.repka-m__taskList___d_tXs {\n  display: flex;\n  flex-flow: column nowrap;\n}\n.repka-m__task___RnGnb {\n  display: flex;\n  flex-flow: column nowrap;\n  padding: 12px 16px;\n}\n.repka-m__task___RnGnb:not(:last-child) {\n  margin-bottom: 8px;\n}\n.repka-m__task___RnGnb:hover {\n  background-color: var(--hover-bg-color);\n  cursor: pointer;\n}\n.repka-m__task__selected___VFOES {\n  background-color: var(--active-bg-color);\n}\n.repka-m__task__selected___VFOES:hover {\n  background-color: var(--active-bg-color-hover);\n  cursor: pointer;\n}\n.repka-m__task__header___Mq2zC {\n  font-weight: 600;\n}\n.repka-m__dropdown___lxIwP {\n  width: 100%;\n  border-bottom: 1px solid var(--border-color);\n  position: relative;\n  padding-bottom: 4px;\n  font-size: 14px;\n  margin-top: var(--grid-spacer);\n  cursor: pointer;\n}\n.repka-m__dropdown___lxIwP:after {\n  content: '▾';\n  position: absolute;\n  right: 0;\n  bottom: 0;\n  height: 100%;\n}\n.repka-m__dropdown__placeholder___qkwqi {\n  color: var(--grey-600);\n  font-style: italic;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogLayout": "repka-m__dialogLayout___NPqbi",
	"taskList": "repka-m__taskList___d_tXs",
	"task": "repka-m__task___RnGnb",
	"task__selected": "repka-m__task__selected___VFOES",
	"task__header": "repka-m__task__header___Mq2zC",
	"dropdown": "repka-m__dropdown___lxIwP",
	"dropdown__placeholder": "repka-m__dropdown__placeholder___qkwqi"
};
export default ___CSS_LOADER_EXPORT___;
