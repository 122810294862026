// @flow

export type TReactionExtraCondition = {
	title: string,
	flag: number
};
export type TReactionExtraConditionMap = { [key: string]: TReactionExtraCondition };

export const
	ReactionExtraConditionMap: TReactionExtraConditionMap = Object.freeze({
		Autoclave             : {
			title: 'Автоклав',
			flag: 1
		},
		Darkness              : {
			title: 'Темнота',
			flag: 2
		},
		DeanStark             : {
			title: 'Насадка Дина-Старка',
			flag: 4
		},
		InertAtmosphere       : {
			title: 'Инертная атмосфера',
			flag: 8
		},
		MicrowaveIrradiation  : {
			title: 'Микроволновое облучение',
			flag: 16
		},
		MolecularSieve        : {
			title: 'Молекулярные сита',
			flag: 32
		},
		Reflux                : {
			title: 'Кипячение',
			flag: 64
		},
		SchlenkTechnique      : {
			title: 'Колба Шленка',
			flag: 128
		},
		SealedTube            : {
			title: 'Закрытый сосуд',
			flag: 256
		},
		Sonication            : {
			title: 'Ультразвуковое облучение',
			flag: 512
		},
		UVIrradiation         : {
			title: 'Ультрафиолетовое облучение',
			flag: 1024
		},
		FlowReactor           : {
			title: 'Проточный реактор',
			flag: 2048,
		},
		Photoreactor          : {
			title: 'Фотореактор',
			flag: 4096,
		}
	})
;
