// @flow

import {
    put,
    takeEvery,
    fork,
    select,
    all,
    call,
} from 'redux-saga/effects';
import {getToken} from '../../account/reducers';
import type { IRequestResourceAction } from '../../shared/utils/action-creators';
import {stageIndicatorResourceActions, stageIndicatorsModuleActions} from '../actions';
import { StageIndicatorsResourceService } from '../services';
import type {IStageIndicator} from "../models";
import {startSubmit, setSubmitSucceeded, stopSubmit, change} from 'redux-form';
import {
    STAGE_INDICATORS_ARRAY_NAME,
    STAGE_INDICATORS_FORM_NAME
} from '../components/StageIndicatorsList/StageIndicatorsForm';
import {
    createIndicatorProcessPoints, deleteIndicatorProcessPoints,
    listIndicatorProcessPoints,
    updateIndicatorProcessPoints
} from '../../indicators/sagas';
import {indicatorProcessPointsResourceActions} from '../../indicators/actions';
import {StageIndicator, StageIndicatorKeyNames} from '../models';
import type {IRootStore} from '../../app/reducers';
import type {IIndicatorProcessPoint} from '../../indicators/models';

export function* listStageIndicators({ params, uriParams }: IRequestResourceAction)
{
    const token = yield select(getToken);
    try
    {
        const response = yield StageIndicatorsResourceService.list({ params, uriParams }, token);
        yield put(stageIndicatorResourceActions.list.success(response));
    }
    catch (error)
    {
        yield put(stageIndicatorResourceActions.list.failure({ error }));
    }
}

export function* batchCreateStageIndicators({ data, params }: IRequestResourceAction)
{
    const token = yield select(getToken);
    try
    {
        const response = yield StageIndicatorsResourceService.batch({ data, params }, token);
        yield put(stageIndicatorResourceActions.create.success(response));
    }
    catch (error)
    {
        yield put(stageIndicatorResourceActions.create.failure({ error }));
    }
}

export function* batchDeleteStageIndicators({ data, params }: IRequestResourceAction)
{
    const token = yield select(getToken);
    try
    {
        const response = yield StageIndicatorsResourceService.delete({ data, params }, token);
        yield put(stageIndicatorResourceActions.delete.success(response));
    }
    catch (error)
    {
        yield put(stageIndicatorResourceActions.delete.failure({ error }));
    }
}

export function* batchAll({ indicators, stageId })
{
    yield put(startSubmit(STAGE_INDICATORS_FORM_NAME));
    const
        deletedIds = indicators
            .filter((i: IStageIndicator) => i.Id && i.IsDeleted)
            .map(i => i.Id),
        items = indicators
            .filter((i: IStageIndicator) => !i.IsDeleted)
            // надо на всякий случай убирать вложенные модели при сохранении, иначе сервер может попытаться пересоздать
            // уже созданные
            .map((i: IStageIndicator) => StageIndicator({
                ...i,
                ProcessPoint: null,
                Indicator: null,
                IndicatorUnit: null,
            }))
    ;
    if (deletedIds.length)
    {
        yield batchDeleteStageIndicators({ data: deletedIds, params: { stageId } });
    }
    if (items.length)
    {
        yield batchCreateStageIndicators({ data: items, params: { stageId } });
    }
    yield put(setSubmitSucceeded(STAGE_INDICATORS_FORM_NAME));
    yield put(stopSubmit(STAGE_INDICATORS_FORM_NAME));
}

export function* loadStageIndicatorsData({ stageId })
{
    const params = {stageId};
    // тут специально вызываются экшены, а не напрямую саги, потому что в компоненте используются флаги ресурсов,
    // которые проставляются автоматом при вызыве экшенов
    yield put(stageIndicatorResourceActions.list.request({params}));
    yield put(indicatorProcessPointsResourceActions.list.request({ params }));
}

export function* updateAndLoadProcessPoints({ data, stageId })
{
    const uriParams = { id: data.Id };
    const stageIndicatorRowIdxForCreatedPP: number = yield select(
        (state: IRootStore) => state.modules.stageIndicators.stageIndicatorsList.stageIndicatorRowIdxForCreatedPP
    );
    let createdPP: IIndicatorProcessPoint;
    yield put(stageIndicatorResourceActions.explicitlySetRequestState({ pending: true, requestSucceed: null }));
    if (uriParams.id)
    {
        yield updateIndicatorProcessPoints({ data });
    }
    else {
        createdPP = yield createIndicatorProcessPoints({ data });
    }
    yield put(indicatorProcessPointsResourceActions.destroy());
    yield listIndicatorProcessPoints({ params: { stageId }});
    if (stageIndicatorRowIdxForCreatedPP !== null && !!createdPP)
    {
        yield put(change(
            STAGE_INDICATORS_FORM_NAME,
            `${STAGE_INDICATORS_ARRAY_NAME}[${stageIndicatorRowIdxForCreatedPP}].${StageIndicatorKeyNames.ProcessPointId}`,
            createdPP.Id
        ));
        yield put(stageIndicatorsModuleActions.setStageIndicatorRowIdxForCreatedPP({
            rowIdx: null
        }));
    }
    yield put(stageIndicatorResourceActions.explicitlySetRequestState({ pending: false, requestSucceed: true }));
}

export function* deleteAndLoadProcessPoints({ id, stageId })
{
    yield put(stageIndicatorResourceActions.explicitlySetRequestState({ pending: true, requestSucceed: null }));
    yield deleteIndicatorProcessPoints({ uriParams: { id }});
    yield put(indicatorProcessPointsResourceActions.destroy());
    yield listIndicatorProcessPoints({ params: { stageId }});
    yield put(stageIndicatorResourceActions.explicitlySetRequestState({ pending: false, requestSucceed: true }));
}

export const stageIndicatorsWatchers = [
    function* ()
    {
        yield takeEvery(stageIndicatorResourceActions.list.request.type, function* (action)
        {
            yield fork(listStageIndicators, action);
        });
    },

    function* ()
    {
        yield takeEvery(stageIndicatorResourceActions.create.request.type, function* (action)
        {
            yield fork(batchCreateStageIndicators, action);
        });
    },

    function* ()
    {
        yield takeEvery(stageIndicatorResourceActions.delete.request.type, function* (action)
        {
            yield fork(batchDeleteStageIndicators, action);
        });
    },

    function* ()
    {
        yield takeEvery(stageIndicatorResourceActions.batchAllActions, function* (action)
        {
            yield fork(batchAll, action);
        });
    },

    function* ()
    {
        yield takeEvery(stageIndicatorsModuleActions.loadStageIndicatorData.type, function* (action)
        {
            yield fork(loadStageIndicatorsData, action);
        });
    },

    function* ()
    {
        yield takeEvery(stageIndicatorsModuleActions.updateOrCreateProcessPoint.type, function* (action)
        {
            yield fork(updateAndLoadProcessPoints, action);
        });
    },

    function* ()
    {
        yield takeEvery(stageIndicatorsModuleActions.deleteOrCreateProcessPoint.type, function* (action)
        {
            yield fork(deleteAndLoadProcessPoints, action);
        });
    }
];
