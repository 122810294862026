// @flow

import {
  put,
  takeEvery,
  fork,
  select,
} from 'redux-saga/effects';
import {getToken} from '../../account/reducers';
import type {IRequestResourceAction} from '../../shared/utils/action-creators';
import {ArchivedReagentsResourceService} from '../services';
import {archivedReagentsResourceActions} from '../actions';
import {ReactionsResourceService} from '../../reactions/services';

function* getArchivedReagent({uriParams}: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const result = yield ArchivedReagentsResourceService.get({uriParams}, token);
    yield put(archivedReagentsResourceActions.get.success(result));
  } catch (error) {
    yield put(archivedReagentsResourceActions.get.failure(result));
  }
}

function* createArchivedReagent({data}: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const result = yield ArchivedReagentsResourceService.create({data}, token);
    yield put(archivedReagentsResourceActions.create.success(result));
  } catch (error) {
    yield put(archivedReagentsResourceActions.create.failure(result));
  }
}

export function* listArchivedReagents({uriParams}: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const result = yield ReactionsResourceService.getArchiveReagents({uriParams}, token);
    yield put(archivedReagentsResourceActions.list.success(result));
  } catch (error) {
    yield put(archivedReagentsResourceActions.list.failure({error}));
  }
}

function* updateArchivedReagent({uriParams, data}: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const result = yield ArchivedReagentsResourceService.update({uriParams, data}, token);
    yield put(archivedReagentsResourceActions.update.success(result));
  } catch (error) {
    yield put(archivedReagentsResourceActions.update.failure({error}));
  }
}

function* deleteArchivedReagent({uriParams}: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const result = yield ArchivedReagentsResourceService.delete({uriParams}, token);
    yield put(archivedReagentsResourceActions.delete.success(result));
  } catch (error) {
    yield put(archivedReagentsResourceActions.delete.failure({error}));
  }
}

function* editReagentComment({uriParams, data}: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const result = yield ArchivedReagentsResourceService.editReagentComment({uriParams, data}, token);
    yield put(archivedReagentsResourceActions.editReagentComment.success(result));
  } catch (error) {
    yield put(archivedReagentsResourceActions.editReagentComment.failure({error}));
  }
}

export const archivedReagentsResourceWatchers = [
  function* () {
    yield takeEvery(archivedReagentsResourceActions.get.request.type, function* (action) {
      yield fork(getArchivedReagent, action);
    });
  },

  function* () {
    yield takeEvery(archivedReagentsResourceActions.create.request.type, function* (action) {
      yield fork(createArchivedReagent, action);
    });
  },

  function* () {
    yield takeEvery(archivedReagentsResourceActions.list.request.type, function* (action) {
      yield fork(listArchivedReagents, action);
    });
  },

  function* () {
    yield takeEvery(archivedReagentsResourceActions.update.request.type, function* (action) {
      yield fork(updateArchivedReagent, action);
    });
  },

  function* () {
    yield takeEvery(archivedReagentsResourceActions.delete.request.type, function* (action) {
      yield fork(deleteArchivedReagent, action);
    });
  },

  function* () {
    yield takeEvery(archivedReagentsResourceActions.editReagentComment.request.type, function* (action) {
      yield fork(editReagentComment, action);
    });
  }
];
