// @flow

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { GridTable, GridTableCol } from '../../../shared/components/GridTable';
import {
    BcdDropdownWrapper,
    InlineEdit,
    inputTypesEnum,
    EditableDropdown,
    BcdTextAreaWrapper
} from '../../../shared/components';
import Button from '@biocad/bcd-front-ui/controls/Button';
import {_noop} from '../../../shared/utils/common';
import {reduxForm, arrayPush, arrayRemove, getFormValues, Field} from 'redux-form';
import {StageIndicator, StageIndicatorCPPMap, StageIndicatorKeyNames} from '../../models';
import type {IFieldInputProps, IFormWrappedComponentProps, OptionType} from '../../../shared/models';
import type {IStageIndicator} from '../../models';
import {IndicatorsLoader} from '../../../indicators/hocs/IndicatorsLoader';
import type { IIndicatorsLoaderHocProps } from '../../../indicators/hocs/IndicatorsLoader';
import {FieldValidators} from '../../../shared/validators/field-validators';
import {stageIndicatorResourceActions, stageIndicatorsModuleActions} from '../../actions';
import {connect, useDispatch} from 'react-redux';
import type {IRootStore} from '../../../app/reducers';
import type {IIndicator, IIndicatorProcessPoint} from '../../../indicators/models';
import {getStageIndicatorsProcessPoints} from '../../reducers';
import {IndicatorProcessPoint, IndicatorProcessPointKeyNames} from '../../../indicators/models';
import {Dispatch} from 'redux';
import {stageIndicatorsEditAccessSelector} from '../../reducers/selectors'
import type {IProject} from '../../../projects/models';
import {stageProject} from '../../../stages/reducers';

export const STAGE_INDICATORS_FORM_NAME = 'stageIndicatorsForm';
export const STAGE_INDICATORS_ARRAY_NAME = 'stageIndicators';

export interface IStageIndicatorFormProps extends IFormWrappedComponentProps, IIndicatorsLoaderHocProps {
    className?: string,
    stageId: number,
    currentCanBeModifiedOrDeleted?: boolean,
    stageIndicatorProcessPoints?: IIndicatorProcessPoint[],
    updateOrCreateProcessPoint?: (data: IIndicatorProcessPoint, stageId: number) => void,
    deleteOrCreateProcessPoint?: (id: number, stageId: number) => void,
    processPointsPending?: boolean,
    requiredPurificationsCount:number,
    formValues?: {};
    stageIndicatorsRequestSucceed?: boolean;
    sortByOrderPriority?: () => void;
    setStageIndicatorRowIdxForCreatedPP?: (stageIndicatorId: number) => void;
    stageProject?: IProject;
}

const mapProcessPoint = (value: IIndicatorProcessPoint): OptionType =>
({
    label: value.Name,
    value: value.Id,
});

let nestedPurificationsOptions = [{ value: null, label: 'Реакции стадии' }, { value: 0, label: 'Вложенные очистки' }];

const renderCommentField = (props: {item: IStageIndicator, rowidx: number, renderIdx: number}) =>
(
    <Field
        name={`${STAGE_INDICATORS_ARRAY_NAME}[${props.rowidx}].${StageIndicatorKeyNames.Comment}`}
        component={BcdTextAreaWrapper}
        wrapperClassName={'stage-indicator-comment'}
        className={'stage-indicator-comment-textarea'}
        label={'Комментарий'}
        useFormFieldLayout
    />
);

let StageIndicatorForm = (props: IStageIndicatorFormProps) => {
    const dispatch = useDispatch();
    const processPointsOptions: OptionType[] = props.stageIndicatorProcessPoints?.map(mapProcessPoint) || [];

    nestedPurificationsOptions = [{ value: null, label: 'Реакции стадии' }, { value: 0, label: 'Вложенные очистки' }];

    for(let i=1;i<=props.requiredPurificationsCount;i++) {
        nestedPurificationsOptions.push({value: i, label: 'Очистка ' + i});
    }

    const indicatorOptions: OptionType[] = props.stageProject?.IsScaling === false
        ? props.indicators?.filter((i: IIndicator) => !i.IsScalingProjectsOnly)
                           .map((i: IIndicator): OptionType => ({ value: i.Id, label: i.Name }))
        : props.indicators?.map((i: IIndicator): OptionType => ({ value: i.Id, label: i.Name }));

    // Effects
    useEffect(() => {
        const stageIndicators: IStageIndicator[] = props.formValues && props.formValues[STAGE_INDICATORS_ARRAY_NAME];
        if (
            !Array.isArray(stageIndicators)
            || stageIndicators.length === 0
            || !Array.isArray(props.stageIndicatorProcessPoints)
            || props.stageIndicatorProcessPoints.length === 0
        ) return;
        stageIndicators.forEach((stageIndicator: IStageIndicator, idx) => {
            if (stageIndicator.ProcessPointId === 0) return;
            const processPointExists = !!props.stageIndicatorProcessPoints
                .find((processPoint: IIndicatorProcessPoint) => processPoint.Id === stageIndicator.ProcessPointId);
            if (!processPointExists)
            {
                props.change(
                    `${STAGE_INDICATORS_ARRAY_NAME}[${idx}].${StageIndicatorKeyNames.ProcessPointId}`,
                    null
                )
            }
        })
    }, [props.stageIndicatorProcessPoints, props.stageIndicatorsRequestSucceed])

    // Methods
    const
        formatProcessPoints = (value) =>
        (
            processPointsOptions.find(p => p.value === value)
        ),
        addItem = () =>
        {
            const stageIndicators: IStageIndicator[] = (props.formValues && props.formValues[STAGE_INDICATORS_ARRAY_NAME]) || [];
            const latestPriority = stageIndicators
                .map((p: IStageIndicator) => p.OrderPriority)
                .reduce((acc, cur) => cur >= acc ? cur : acc, 0);
            props.array.push(STAGE_INDICATORS_ARRAY_NAME, StageIndicator({
                StageId: props.stageId,
                OrderPriority: latestPriority+1,
                NestedPurification: false,
            }));
        },
        removeItem = (input: IFieldInputProps, item: IStageIndicator, rowidx: number) =>
        {
            if (item.Id && !item.IsDeleted) {
                input.onChange(true);
            }
            else {
                dispatch(
                    arrayRemove(
                        STAGE_INDICATORS_FORM_NAME,
                        STAGE_INDICATORS_ARRAY_NAME,
                        rowidx
                    )
                );
            }
            props.sortByOrderPriority();
        },
        changeUnit = (row_idx, unitId) =>
        {
            props.change(`${STAGE_INDICATORS_ARRAY_NAME}[${row_idx}].${StageIndicatorKeyNames.IndicatorUnitId}`, unitId);
        },
        setupUnit = (row_idx, indicatorId) =>
        {
            const properUnits = props.indicatorUnits.filter(u => u.IndicatorId === indicatorId);
            if (properUnits.length === 1) {
                changeUnit(row_idx, properUnits[0].Id);
            }
            else {
                changeUnit(row_idx, null);
            }
        },
        onChangeIndicator = (event, newValue, previousValue, name, item, row_idx) =>
        {
            setupUnit(row_idx, newValue);
        },
        onChangeProcessPoint = (event, newValue, prevValue, name, item, row_idx) =>
        {
            if (newValue !== prevValue)
            {
                const processPoint = props.stageIndicatorProcessPoints.find((p: IIndicatorProcessPoint) => p.Id === newValue);
                if (processPoint)
                {
                    props.change(
                        `${STAGE_INDICATORS_ARRAY_NAME}[${row_idx}].${StageIndicatorKeyNames.ProcessPoint}`,
                        IndicatorProcessPoint(processPoint)
                    )
                }
                else {
                    props.change(
                        `${STAGE_INDICATORS_ARRAY_NAME}[${row_idx}].${StageIndicatorKeyNames.ProcessPoint}`,
                        null
                    )
                }
            }
        },
        getUnitOptionsForIndicator = (item) =>
        {
            const unitOptions = props.indicatorUnitOptions || [];
            if(!item.IndicatorId || !props.indicatorUnits) {
                return [];
            }
            const properUnits = props.indicatorUnits.filter(u => u.IndicatorId === item.IndicatorId);
            return unitOptions.filter(op => properUnits.some(u => op.value === u.Id));
        },
        deleteProcessPoint = (option: OptionType) =>
        {
            const processPoint = props.stageIndicatorProcessPoints.find((p: IIndicatorProcessPoint) => p.Id === option.value);
            if (processPoint.IsCommon) return;
            if (processPoint)
            {
                props.deleteOrCreateProcessPoint(processPoint.Id, props.stageId);
            }
        },
        updateOrCreateProcessPoint = (option: OptionType, rowIdx: number) =>
        {
            let processPoint: IIndicatorProcessPoint;
            const processPointFromList = props.stageIndicatorProcessPoints.find((p: IIndicatorProcessPoint) => p.Id === option.value);
            if (!processPointFromList)
            {
                props.setStageIndicatorRowIdxForCreatedPP(rowIdx);
                processPoint = IndicatorProcessPoint({
                    Id: option.value,
                    Name: option.label,
                    IsCommon: false,
                    StageId: props.stageId
                });
            }
            else
            {
                processPoint = IndicatorProcessPoint({
                    ...processPointFromList,
                    Name: option.label
                });
            }
            if (processPoint.IsCommon) return;
            props.updateOrCreateProcessPoint(processPoint, props.stageId);
        },
        movePriority = (input: IFieldInputProps, item: IStageIndicator, rowidx: number, direction: 'up' | 'down') =>
        {
            const stageIndicators: IStageIndicator[] = props.formValues && props.formValues[STAGE_INDICATORS_ARRAY_NAME];
            if (!Array.isArray(stageIndicators) || !stageIndicators.length) return;
            const closestRowidx = {
                'up': rowidx-1,
                'down': rowidx+1
            }[direction];
            const replacingItem: IStageIndicator = stageIndicators
                .find((_, idx) => idx === closestRowidx);
            input.onChange(replacingItem.OrderPriority);
            props.change(
                `${STAGE_INDICATORS_ARRAY_NAME}[${closestRowidx}].${StageIndicatorKeyNames.OrderPriority}`,
                item.OrderPriority
            );
            props.sortByOrderPriority();
        },
        movePriorityUp = (input: IFieldInputProps, item: IStageIndicator, rowidx: number) =>
        {
            movePriority(input, item, rowidx, 'up');
        },
        movePriorityDown = (input: IFieldInputProps, item: IStageIndicator, rowidx: number) =>
        {
            movePriority(input, item, rowidx, 'down');
        }
    ;

    return (
        <div className={`stage-indicators ${props.className}`}>
            <div className={'stage-indicators-top'}>
                <h4>Параметры</h4>
                {
                    props.currentCanBeModifiedOrDeleted &&
                    <Button
                        text={'Добавить параметр'}
                        onAction={addItem}
                    />
                }
            </div>
            <GridTable
                fieldArrayName={STAGE_INDICATORS_ARRAY_NAME}
                colsTemplate={'3fr 1fr 1fr 3fr 2fr 64px 64px'}
                rowHeight={'minmax(54px, max-content)'}
                excludeRows={(item: IStageIndicator) => item.IsDeleted}
                rerenderOnEveryChange={true}
                renderRowAfter={renderCommentField}
            >
                <GridTableCol
                    title={'Параметр'}
                    fieldName={StageIndicatorKeyNames.IndicatorId}
                    format={(value) => indicatorOptions.find((opt) => opt.value === value)}
                    validate={[FieldValidators.required]}
                    onChange={onChangeIndicator}
                    render={({ meta, input }) => (
                        <BcdDropdownWrapper
                            markPristineValidity
                            options={indicatorOptions || []}
                            disabled={!props.currentCanBeModifiedOrDeleted}
                            {...{ meta, input }}
                        />
                    )}
                />
                <GridTableCol
                    title={'Ед. изм.'}
                    fieldName={StageIndicatorKeyNames.IndicatorUnitId}
                    format={(value) => props.indicatorUnitOptions.find((opt) => opt.value === value)}
                    validate={[FieldValidators.required]}
                    render={({ meta, input, item }) => (
                        <BcdDropdownWrapper
                            markPristineValidity
                            options={getUnitOptionsForIndicator({...item})}
                            disabled={!props.currentCanBeModifiedOrDeleted}
                            placeholder={'Выбрать...'}
                            {...{ meta, input }}
                        />
                    )}
                />
                <GridTableCol
                    title={'Норма'}
                    fieldName={StageIndicatorKeyNames.Norm}
                    render={({ meta, input }) => (
                        <InlineEdit
                            inputType={inputTypesEnum.InputText}
                            disabled={!props.currentCanBeModifiedOrDeleted}
                            {...{ meta, input }}
                        />
                    )}
                />
                <GridTableCol
                    title={'PP'}
                    fieldName={StageIndicatorKeyNames.ProcessPointId}
                    format={formatProcessPoints}
                    validate={[FieldValidators.required]}
                    onChange={onChangeProcessPoint}
                    render={({ meta, input, item, rowidx }) => (
                        <EditableDropdown
                            selectedEditable={!item.ProcessPoint?.IsCommon}
                            markPristineValidity
                            options={processPointsOptions}
                            disabled={!props.currentCanBeModifiedOrDeleted}
                            onSelectedChange={(option) => updateOrCreateProcessPoint(option, rowidx)}
                            onSelectedDelete={deleteProcessPoint}
                            pending={props.processPointsPending}
                            duplicatesNotAllowed
                            {...{ meta, input }}
                        />
                    )}
                />
                <GridTableCol
                    title={'Назначение'}
                    fieldName={StageIndicatorKeyNames.PurificationIndex}
                    format={(value) => nestedPurificationsOptions.find((opt) => opt.value === value)}
                    render={({ meta, input }) => (
                        <BcdDropdownWrapper
                            markPristineValidity
                            options={nestedPurificationsOptions}
                            defaultValue={nestedPurificationsOptions[0]}
                            disabled={!props.currentCanBeModifiedOrDeleted}
                            {...{ meta, input }}
                        />
                    )}
                />
                <GridTableCol
                    fieldName={StageIndicatorKeyNames.IsDeleted}
                    render={({ input, item, rowidx }) => (
                        props.currentCanBeModifiedOrDeleted &&
                        <Button
                            className={'delete-btn fo-trash'}
                            onAction={() => removeItem(input, item, rowidx)}
                            view={'icon'}
                            size={'L'}
                        />
                    )}
                />
                <GridTableCol
                    fieldName={StageIndicatorKeyNames.OrderPriority}
                    render={({ input, item, rowidx, renderIdx }) => (
                        props.currentCanBeModifiedOrDeleted &&
                        <div className={'order-priority-wrap'}>
                            {
                                (renderIdx > 1) &&
                                <Button
                                    className={'fo-up-open'}
                                    view={'icon'}
                                    size={'S'}
                                    onAction={() => movePriorityUp(input, item, rowidx)}
                                />
                            }
                            {
                                (props.formValues && renderIdx < props.formValues[STAGE_INDICATORS_ARRAY_NAME].filter(i => !i.IsDeleted).length) &&
                                <Button
                                    className={'fo-down-open'}
                                    view={'icon'}
                                    size={'S'}
                                    onAction={() => movePriorityDown(input, item, rowidx)}
                                />
                            }
                        </div>
                    )}
                />
            </GridTable>
        </div>
    );
};

StageIndicatorForm = IndicatorsLoader(StageIndicatorForm);

StageIndicatorForm = reduxForm({
    form: STAGE_INDICATORS_FORM_NAME,
    onSubmit: (values, dispatch, props) => {
        if (props.stageId)
        {
            dispatch(
                stageIndicatorResourceActions.batchAllActions({
                    indicators: values[STAGE_INDICATORS_ARRAY_NAME],
                    stageId: props.stageId
                })
            );
        }
    },
})(StageIndicatorForm);

StageIndicatorForm = connect(
    (state: IRootStore): IStageIndicatorFormProps => ({
        currentCanBeModifiedOrDeleted: stageIndicatorsEditAccessSelector(state),
        stageIndicatorProcessPoints: getStageIndicatorsProcessPoints(state),
        processPointsPending: state.resource.indicatorProcessPoints.pending,
        formValues: getFormValues(STAGE_INDICATORS_FORM_NAME)(state),
        stageIndicatorsRequestSucceed: (
            state.resource.stageIndicators.requestSucceed
        ),
        stageProject: stageProject(state)
    }),
    (dispatch: Dispatch): IStageIndicatorFormProps => ({
        updateOrCreateProcessPoint: (data: IIndicatorProcessPoint, stageId: number) => dispatch(stageIndicatorsModuleActions.updateOrCreateProcessPoint({ data, stageId })),
        deleteOrCreateProcessPoint: (id: number, stageId: number) => dispatch(stageIndicatorsModuleActions.deleteOrCreateProcessPoint({ id, stageId })),
        sortByOrderPriority: () => dispatch(stageIndicatorsModuleActions.sortByOrderPriority({})),
        setStageIndicatorRowIdxForCreatedPP: (rowIdx: number) => dispatch(stageIndicatorsModuleActions.setStageIndicatorRowIdxForCreatedPP({ rowIdx }))
    })
)(StageIndicatorForm);

StageIndicatorForm = styled(StageIndicatorForm)`
  & {
    min-width: 960px;
  }
  .grid-table-row {
    position: relative;
  }
  .grid-table-row-wrap > .grid-table-row
  {
    border-bottom: none !important;
  }
  .grid-table-row-after {
    border-top: none !important;
    display: grid;
    grid-template: inherit;
    padding-top: 4px;
  }
  .stage-indicators-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  .stage-indicator-comment {
    grid-column-start: 1;
    grid-column-end: 6;
    padding-bottom: 0;
  }
  .stage-indicator-comment-textarea {
    resize: none !important;
    height: 4em;
  }
  .order-priority-wrap {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: 0;
    right: 0;
    .fo-down-open {
      margin: auto auto 0 auto;
    }
    .fo-up-open {
      margin: 0 auto auto auto;
    }
  }
`;

export {
    StageIndicatorForm
};
