import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {itemsToArraySelector} from '../../../../shared/utils/selectors/resource-selectors';
import {
    folderModuleActions,
    folderResourceActions, folderSchemesResourceActions, productionSchemeResourceActions
} from '../../../../projects/actions';
import {
    candidateModuleActions,
    candidatesResourceActions
} from '../../../actions';
import {folderParentType} from '../../../../projects/types/enum';
import DropdownMenu from '../../../../shared/components/DropdownMenu';
import FolderModal from '../../../../projects/components/ProjectsTable/Folders/modal';
import {ConfirmDialog} from '../../../../shared/components/ConfirmDialog/ConfirmDialog';
import queryString from 'query-string';
import {_temp_folderResourceAction} from '../../../../folders/actions';
import {userCRUDPermissionsSelector} from "../../../../account/reducers/users-modules-reducer";

const initialProps = {
    folders: [],
    getFolders: null,
    setCurrentFolderId: null,
    addCandidatesFolderPath: null,
    clearCandidatesList: null,
    clearFoldersList: null,
    currentProjectId: null,
    currentFolderId: null,
    foldersPath: [],
    candidatesFoldersPath: [],
    getCandidatesList: null,
    setCurrentCandidate: () => null,
    candidates: [],
    clearFolderSchemesPath: () => null,
    clearFoldersSchemeList: () => null,
    clearProductSchemeList: () => null,
    setEditFolderMode: () => null,
    deleteFolder: () => null,
    userPermissions: {},
};

const Folders = (props = initialProps) => {
    const [folderOpened, setFolderOpened] = useState(false);
    const [showFolderModal, setShowFolderModal] = useState(false);
    const [isConfirmShown, setIsConfirmShown] = useState(false);

    useEffect(() => {
        if (props.candidates.length > 0 && folderOpened) {
            props.clearFolderSchemesPath();
            props.clearProductSchemeList();
            props.clearFoldersSchemeList();

            props.setCurrentCandidate({
                candidate: props.candidates[0]
            });
            setFolderOpened(false);
        }
    }, [props.candidates]);

    const openFolder = (folderId, folderName) => {
        props.clearFolderSchemesPath();
        props.clearProductSchemeList();
        props.clearFoldersSchemeList();
        props.destroyTempFolder();

        let urlParams = {
            folderId: folderId
        };
        props.history.push(props.location.pathname + '?' + queryString.stringify(urlParams));

        props.setCurrentFolderId({
            id: folderId
        });
        props.addCandidatesFolderPath({
            id: folderId,
            name: folderName
        });

        props.clearCandidatesList();
        props.clearFoldersList();

        props.getFolders({
            parentId: folderId,
            parentType: folderParentType.folder
        });

        props.getCandidatesList({
            projectId: props.currentProjectId,
            folderId: folderId
        });

        props.setCurrentCandidate({
            candidate: null
        });

        setFolderOpened(true);
    };

    const toggleFolderModal = () => {
        setShowFolderModal(!showFolderModal);
    };

    const toggleConfirmModal = () => {
        setIsConfirmShown(!isConfirmShown);
    };

    const dropdownMenuItems = [];
    if (props.userPermissions.folder.UPDATE) {
        dropdownMenuItems.push({
            title: 'Редактирование',
            action: (e) => {
                e.stopPropagation();
                props.setEditFolderMode({
                    isEdit: true
                });
                props.setCurrentFolderId({
                    id: e.target.parentNode.dataset.id
                });
                toggleFolderModal();
            }
        });
    }
    if (props.userPermissions.folder.DELETE) {
        dropdownMenuItems.push({
            title: 'Удаление',
            action: (e) => {
                e.stopPropagation();
                props.setCurrentFolderId({
                    id: e.target.parentNode.dataset.id
                });
                toggleConfirmModal();
            }
        });
    }

    const deleteFolder = () => {
        props.clearFoldersList();

        props.deleteFolder({
            id: props.currentFolderId,
            parentId: props.candidatesFoldersPath.length > 0 ?
                props.candidatesFoldersPath[props.candidatesFoldersPath.length - 1].id : props.currentProjectId,
            parentType: props.candidatesFoldersPath.length > 0 ? folderParentType.folder : folderParentType.project
        });
        toggleConfirmModal();
    };

    return (
        <React.Fragment>
            {(showFolderModal && props.userPermissions.folder.UPDATE) &&
            <FolderModal toggleModal={toggleFolderModal} position={'candidate'}/>}

            {(isConfirmShown && props.userPermissions.folder.DELETE) &&
            <ConfirmDialog
                title={'Внимание'}
                message={'Вы уверены, что хотите удалить эту папку?'}
                confirmLabel={'Да'}
                declineLabel={'Отмена'}
                confirmAction={deleteFolder}
                declineAction={toggleConfirmModal}
            />}

            {props.folders.map((folder, index) => {
                return (
                    <div key={index}
                         className={'cell'}
                         onClick={() => openFolder(folder.Id, folder.Name)}
                         data-id={folder.Id}
                         data-name={folder.Name}>

                        <div className={'img'} data-id={folder.Id}/>

                        <div className={'panel'} data-id={folder.Id}>
                            <h4>{folder.Name}</h4>
                            {
                                (dropdownMenuItems.length) ?
                                <DropdownMenu menuItems={dropdownMenuItems} currentId={folder.Id}/> : ''
                            }
                        </div>
                    </div>
                );
            })}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    const {resource, modules} = state;
    return {
        folders: itemsToArraySelector(resource.folders),
        currentProjectId: modules.project.id,
        currentFolderId: modules.folder.id,
        foldersPath: modules.folder.path,
        candidatesFoldersPath: modules.folder.candidatesPath,
        candidates: itemsToArraySelector(resource.candidates),
        userPermissions: userCRUDPermissionsSelector(state),
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        (dispatch) => ({
            getFolders: (params) => dispatch(folderResourceActions.list.request({params})),
            setCurrentFolderId: (id) => dispatch(folderModuleActions.setCurrentFolderId(id)),
            addCandidatesFolderPath: ({id, name}) => dispatch(folderModuleActions.addFolderCandidatePathItem({
                id,
                name
            })),
            clearFoldersList: () => dispatch(folderResourceActions.destroy()),
            clearCandidatesList: () => dispatch(candidatesResourceActions.destroy()),
            getCandidatesList: (params) => dispatch(candidatesResourceActions.list.request({params})),
            setCurrentCandidate: (candidate) => dispatch(candidateModuleActions.setCurrentCandidate(candidate)),
            clearFolderSchemesPath: () => dispatch(folderModuleActions.deleteFolderSchemesPath()),
            clearProductSchemeList: () => dispatch(productionSchemeResourceActions.destroy()),
            clearFoldersSchemeList: () => dispatch(folderSchemesResourceActions.destroy()),
            setEditFolderMode: (isEditMode) => dispatch(folderModuleActions.setEditMode(isEditMode)),
            deleteFolder: (data) => dispatch(folderResourceActions.delete.request({data})),
            destroyTempFolder: () => dispatch(_temp_folderResourceAction.destroy())
        })
    )(Folders)
);