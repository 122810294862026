// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import SchemeEdit from './SchemeEdit';
import {SchemeCompare} from './SchemeCompare';

const Scheme = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/:id/compare`} render={(props) => <SchemeCompare {...props} />} />
      <Route path={`${match.path}/:id`} render={(props) => <SchemeEdit {...props} />} />
    </Switch>
  )
};

export default withRouter(connect(null, null)(Scheme));
