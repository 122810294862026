// @flow

import { HTTPMethodEnum, ResourceService } from '../../shared/utils/service-creator';
import {
  IRepkaTask
} from '../models';

export const RepkaResourceService = ResourceService({
  baseURL: '/repka',
  defaultModel: IRepkaTask,

  requests: {
    getList: {
      url: '/taskInfoByStage/{id}',
      method: HTTPMethodEnum.GET,
    },
    getRedirectUrl: {
      url: '/redirectTaskUrl',
      method: HTTPMethodEnum.GET,
    }
  }
});
