// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-m__userList___qHSS2 {\n  padding: 0;\n  margin: 0;\n}\n.styles-m__userList___qHSS2 li {\n  padding: 0;\n  margin: 0;\n  list-style: none;\n}\n.styles-m__grid__cell___SibkO {\n  cursor: pointer;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userList": "styles-m__userList___qHSS2",
	"grid__cell": "styles-m__grid__cell___SibkO"
};
export default ___CSS_LOADER_EXPORT___;
