// @flow
import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { accountReducer } from '../account/reducers';
import {
  agentModuleReducer,
  agentResourceReducer,
  aliasesResourceReducer,
  jarSpendingResourceReducer,
  jarStockResourceReducer
} from '../agents/reducers';
import {
  analyticsModuleReducer,
  smallMoleculesAvailabilityResourceReducer,
  smallMoleculesReactionResourceReducer
} from '../analytics/reducers';
import { attachmentsReducer } from '../attachments/reducers';
import {
    bimsDictionaryModuleReducer,
    smTypesResourceReducer, TBimsDictionaryModuleStore,
    testClassesResourceReducer,
    testStatusesResourceReducer
} from '../dictionary/reducers';
import { labsResourceReducer } from '../dictionary/reducers/labs';
import { tagsResourceReducer } from '../dictionary/reducers/tags';
import { _temp_folderResourceReducer } from '../folders/reducers';
import { productsResourceReducer } from '../products/reducers';
import {
  candidatesListReducer,
  candidatesModuleReducer,
  descendantsListReducer,
  descendantsModuleReducer,
  folderListReducer,
  folderSchemesListReducer,
  foldersModuleReducer,
  productionSchemeReducer,
  projectListReducer,
  projectModuleReducer
} from '../projects/reducers';
import {
    reactionCatalogResourceReducer,
    reactionModuleReducer,
    reactionsResourceReducer,
    reactionEventsHistoryReducer,
    laboratoryCatalogResourceReducer,
    reactionAttachmentsReducer, purificationReactionCatalogResourceReducer,
} from '../reactions/reducers';
import { reagentsResourceReducer } from '../reagents/reducers';
import { schemeResourceReducer, schemeModuleReducer } from '../schemes/reducers';
import { applicationReducer } from '../shared/reducers';
import { stageModuleReducer, stageResourceReducer } from '../stages/reducers';
import {
  currentUserReactionsResourceReducer,
  dashboardModuleReducer,
  ownedProjectsResourceReducer,
  workProjectsResourceReducer
} from '../user-dashboard/reducers';
import type { IAgentModuleStore } from '../agents/reducers';
import type { IResourceState } from '../shared/utils/reducer-creators';
import type { IStageModuleState } from '../stages/reducers';
import type { AccountState } from '../account/reducers';
import type { ApplicationState } from '../shared/reducers';
import type { IReactionModuleStore } from '../reactions/reducers';
import type { IFolderModuleStore } from '../folders/reducers/module';
import { _temp_folderModuleReducer } from '../folders/reducers/module';
import { reactionEditFormPlugin } from '../reactions/reducers/reaction-edit-form-plugin';
import { reactionTypesResourceReducer } from '../reactionTypes/reducers';
import { REACTION_EDIT_FORM_NAME } from '../reactions/components/ReactionEdit';
import {usersResourceReducer} from '../account/reducers/users-reducer';
import {userModuleReducer} from '../account/reducers/users-modules-reducer';
import {archivedReagentsReducer} from '../archivedReagents/reducers';
import {
    indicatorProcessPointsResourceReducer,
    indicatorsResourceReducer,
    indicatorUnitsResourceReducer
} from '../indicators/reducers';
import { stageIndicatorResourceReducer } from '../stage-indicators/reducers';
import { reactionIndicatorsResourceReducer } from '../reaction-indicator-parameters/reducers';
import type {IEquipmentModuleStore} from "../equipment/reducers";
import {equipmentListResourceReducer} from "../equipment/reducers/equipment-list";
import {equipmentModuleReducer} from "../equipment/reducers";
import {STAGE_INDICATORS_FORM_NAME} from '../stage-indicators/components/StageIndicatorsList/StageIndicatorsForm';
import {stageIndicatorsFormPluginReducer} from '../stage-indicators/reducers/stage-indicators-form-plugin';
import type {IStageIndicatorModuleStore} from '../stage-indicators/reducers/module';
import {stageIndicatorModuleReducer} from '../stage-indicators/reducers/module';
import type {IUserModuleStore} from '../account/reducers/users-modules-reducer';
import {jarAmountsByLabsResourceActions} from '../agents/actions/jar-amounts-by-labs';
import {jarAmountsByLabsResourceReducer} from '../agents/reducers/jar-amounts-by-labs';
import type {ISchemeModuleStore} from '../schemes/reducers';

export interface IRootStore {
    router: any,
    form: any,
    resource: {
        agents: IResourceState,
        jarStock: IResourceState,
        jarSpending: IResourceState,
        labs: IResourceState,
        tags: IResourceState,
        aliases: IResourceState,
        projects: IResourceState,
        folders: IResourceState,
        _folders_temp: IResourceState, // @todo Временный узел пока переношу папки в отдельный модуль, потом надо будет переименовать
        candidates: IResourceState,
        productionScheme: IResourceState,
        foldersSchemes: IResourceState,
        reactionCatalog: IResourceState,
        purificationReactionCatalog: IResourceState,
        equipmentList: IResourceState,
        descendants: IResourceState,
        stages: IResourceState,
        schemes: IResourceState,
        reactions: IResourceState,
        reagents: IResourceState,
        archivedReagents: IResourceState,
        products: IResourceState,
        reactionTypes: IResourceState,
        eventsHistory: IResourceState,
        reactionAttachments: IResourceState,
        stageIndicators: IResourceState,
        indicatorProcessPoints: IResourceState,
        dashboard: {
            reactions: IResourceState,
            workProjects: IResourceState,
            ownedProjects: IResourceState,
        },
        analytics: {
            smallMoleculesAvailability: IResourceState,
            smallMoleculesReaction: IResourceState,
        },
        dictionary: {
            smTypes: IResourceState,
            testStatuses: IResourceState,
            testClasses: IResourceState,
            labs: IResourceState,
            tags: IResourceState,
            indicators: IResourceState,
        },
        reactionIndicators: IResourceState,
        users: IResourceState,
        jarAmountsByLabs: IResourceState,
    },
    modules: {
        agent: IAgentModuleStore,
        project: any,
        folder: any,
        _folder_temp: IFolderModuleStore,
        candidate: any,
        reaction: IReactionModuleStore,
        equipment: IEquipmentModuleStore,
        descendants: any,
        dashboard: any,
        stage: IStageModuleState,
        analytics: any,
        bimsDictionary: TBimsDictionaryModuleStore,
        stageIndicators: IStageIndicatorModuleStore,
        users: IUserModuleStore,
        scheme: ISchemeModuleStore,
    },
    global: {
        account: AccountState,
        application: ApplicationState,
        attachments: {
            uploadedFiles: []
        }
    }
}

const rootReducer = (history: any) => combineReducers({
    router: connectRouter(history),
    resource: combineReducers({
        agents: agentResourceReducer,
        jarStock: jarStockResourceReducer,
        jarSpending: jarSpendingResourceReducer,
        aliases: aliasesResourceReducer,
        projects: projectListReducer,
        folders: folderListReducer,
        candidates: candidatesListReducer,
        productionScheme: productionSchemeReducer,
        foldersSchemes: folderSchemesListReducer,
        reactionCatalog: reactionCatalogResourceReducer,
        purificationReactionCatalog: purificationReactionCatalogResourceReducer,
        equipmentList: equipmentListResourceReducer,
        reactions: reactionsResourceReducer,
        eventsHistory: reactionEventsHistoryReducer,
        stages: stageResourceReducer,
        schemes: schemeResourceReducer,
        descendants: descendantsListReducer,
        reagents: reagentsResourceReducer,
        archivedReagents: archivedReagentsReducer,
        products: productsResourceReducer,
        reactionTypes: reactionTypesResourceReducer,
        _folders_temp: _temp_folderResourceReducer,
        reactionAttachments: reactionAttachmentsReducer,
        stageIndicators: stageIndicatorResourceReducer,
        indicatorProcessPoints: indicatorProcessPointsResourceReducer,
        dashboard: combineReducers({
            reactions: currentUserReactionsResourceReducer,
            workProjects: workProjectsResourceReducer,
            ownedProjects: ownedProjectsResourceReducer,
        }),
        analytics: combineReducers({
            smallMoleculesAvailability: smallMoleculesAvailabilityResourceReducer,
            smallMoleculesReaction: smallMoleculesReactionResourceReducer,
        }),
        dictionary: combineReducers({
            smTypes: smTypesResourceReducer,
            testStatuses: testStatusesResourceReducer,
            testClasses: testClassesResourceReducer,
            labs: labsResourceReducer,
            tags: tagsResourceReducer,
            indicators: indicatorsResourceReducer,
            indicatorUnits: indicatorUnitsResourceReducer,

        }),
        users: usersResourceReducer,
        reactionIndicators: reactionIndicatorsResourceReducer,
        jarAmountsByLabs: jarAmountsByLabsResourceReducer,
    }),
    form: formReducer.plugin({
        [REACTION_EDIT_FORM_NAME]: reactionEditFormPlugin,
        [STAGE_INDICATORS_FORM_NAME]: stageIndicatorsFormPluginReducer,
    }),
    modules: combineReducers({
      agent: agentModuleReducer,
      project: projectModuleReducer,
      folder: foldersModuleReducer,
      candidate: candidatesModuleReducer,
      reaction: reactionModuleReducer,
      equipment: equipmentModuleReducer,
      users: userModuleReducer,
      descendants: descendantsModuleReducer,
      dashboard: dashboardModuleReducer,
      stage: stageModuleReducer,
      scheme: schemeModuleReducer,
      analytics: analyticsModuleReducer,
      _folder_temp: _temp_folderModuleReducer,
      bimsDictionary: bimsDictionaryModuleReducer,
      stageIndicators: stageIndicatorModuleReducer,
    }),
    global: combineReducers({
      account: accountReducer,
      application: applicationReducer,
      attachments: attachmentsReducer
    })
  });

export default rootReducer;
