// @flow

import React, {
  useEffect,
  useState,
} from 'react';
import {
  CatalogReactionRequestParamsEnum,
} from '../../../../models';
import type {
  IFieldInputProps
} from '../../../../../shared/models';

import styled from 'styled-components';


interface IReactionTypeCheckboxProps {
  input?: IFieldInputProps,
  reactionType:
    typeof CatalogReactionRequestParamsEnum.IncludeWithoutExpYield
    | typeof CatalogReactionRequestParamsEnum.IncludeIncomplete
    | typeof CatalogReactionRequestParamsEnum.IncludeTrialFailed
    | typeof CatalogReactionRequestParamsEnum.IncludeTrialIncomplete
    | typeof CatalogReactionRequestParamsEnum.IncludeTrialSuccessful
    | typeof CatalogReactionRequestParamsEnum.IncludeExpYield
    | 'default',
  title?: string,
}

const defaultReactionTypeCheckboxProps: IReactionTypeCheckboxProps = {
  reactionType: 'default',
};

export const reactionTypeMap: { [string]: { cssClass: string, title: string } } = {
  [CatalogReactionRequestParamsEnum.IncludeExpYield]: {
    cssClass: 'success',
    title: 'Экспериментальный выход больше нуля',
  },
  [CatalogReactionRequestParamsEnum.IncludeIncomplete]: {
    cssClass: 'uncompleted',
    title: 'Реакция не завершена',
  },
  [CatalogReactionRequestParamsEnum.IncludeWithoutExpYield]: {
    cssClass: 'fail',
    title: 'Экспериментальный выход равен нулю',
  },
  [CatalogReactionRequestParamsEnum.IncludeTrialSuccessful]: {
    cssClass: 'trialSuccess',
    title: 'Успешно завершенная пробная реакция',
  },
  [CatalogReactionRequestParamsEnum.IncludeTrialIncomplete]: {
    cssClass: 'trial',
    title: 'Пробная реакция',
  },
  [CatalogReactionRequestParamsEnum.IncludeTrialFailed]: {
    cssClass: 'trialFail',
    title: 'Неуспешно завершенная пробная реакция',
  },
  'default': {
    cssClass: 'default',
    title: '',
  },
};

export const StyledIcon = styled('i')`
  width: var(--typeIcon-size);
  height: var(--typeIcon-size);
  display: inline-block;
  background-size: cover;
  position: relative;
  cursor: pointer;
  
  &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(
          90deg,
          var(--first-color) 17%,
          var(--second-color) 84%
      );
      mask-size: contain;
      mask-repeat: no-repeat;
  }
  
  &.unchecked {
    &::after {
      mask-image: url(${require('../../../../../assets/images/reaction.svg')});
    }
  }
  
  &.checked {
    &::after {
      mask-image: url(${require('../../../../../assets/images/reaction-fill.svg')});
    }
  }
  
  &.status {
    &-${reactionTypeMap[CatalogReactionRequestParamsEnum.IncludeExpYield].cssClass} {
    --first-color: var(--hasYieldColor);
    --second-color: var(--hasYieldColor);
    }
    &-${reactionTypeMap[CatalogReactionRequestParamsEnum.IncludeIncomplete].cssClass} {
      --first-color: var(--uncompletedColor);
      --second-color: var(--uncompletedColor);
    }
    &-${reactionTypeMap[CatalogReactionRequestParamsEnum.IncludeWithoutExpYield].cssClass} {
      --first-color: var(--noYieldColor);
      --second-color: var(--noYieldColor);
    }
    &-${reactionTypeMap[CatalogReactionRequestParamsEnum.IncludeTrialSuccessful].cssClass} {
      --first-color: var(--trialColor);
      --second-color: var(--hasYieldColor);
    }
    &-${reactionTypeMap[CatalogReactionRequestParamsEnum.IncludeTrialIncomplete].cssClass} {
      --first-color: var(--trialColor);
      --second-color: var(--trialColor);
    }
    &-${reactionTypeMap[CatalogReactionRequestParamsEnum.IncludeTrialFailed].cssClass} {
      --first-color: var(--trialColor);
      --second-color: var(--noYieldColor);
    }
    &-${reactionTypeMap['default'].cssClass} {
      --first-color: var(--gray-600);
      --second-color: var(--gray-600);
    }
  }
`;

export const ReactionTypeCheckbox = (props: IReactionTypeCheckboxProps = defaultReactionTypeCheckboxProps) => {
  const [value, setValue] = useState(props.input.value);

  const _updateFieldValue = () => {
    if (typeof value === 'boolean'
      && props.input
      && props.input.onChange
      && typeof props.input.onChange === 'function') {
      props.input.onChange(value);
    }
  };
  useEffect(_updateFieldValue, [value]);

  const _updateValueFromInput = () => {
    if (props.input) {
      setValue(!!props.input.value);
    }
  };
  useEffect(_updateValueFromInput, [props.input.value]);

  const onClickHandler = () => {
    setValue(prev => !prev);
  };

  return (
    <StyledIcon
      onClick={onClickHandler}
      className={`${value === true ? 'checked' : 'unchecked'} status-${reactionTypeMap[props.reactionType].cssClass}`}
      title={reactionTypeMap[props.reactionType].title}
    />
  );
};
