// @flow

import React, {
  useEffect,
  useState,
  useRef,
} from 'react';
import Input from '@biocad/bcd-front-ui/controls/Input';
import Dropdown from '@biocad/bcd-front-ui/controls/Dropdown';
import PropTypes from 'prop-types';
import bnc from 'bnc';
import Tooltip from '@biocad/bcd-front-ui/layout/Tooltip';
import {
  parseInputValueToNumberOnBlur,
  parseInputValueToNumberOnChange,
  preventNonNumericOnKeyDown
} from '../../utils/common';
import type { IFieldInputProps, IFieldMetaProps } from '../../models';
import {BcdDatePickerWrapper} from "../BcdDatePickerWrapper";
import classNames from 'classnames';

import './index.less';


const inputTypesEnum = Object.freeze({
  InputText : 'InputText',
  InputNum  : 'InputNum',
  Select    : 'Select',
  DateTime  : 'DateTime'
});

const modsEnum = Object.freeze({
  Read: 'Read',
  Edit: 'Edit',
});

interface IInlineEditProps {
  inputType: $Keys<typeof inputTypesEnum>;
  input?: IFieldInputProps;
  meta?: IFieldMetaProps;
  options?: { label: string, value: any },
  inputClassName?: string;
  visibleValue?: string;
  readonly?: boolean;
  markPristineValidity?: boolean;
}

const
  block                     = new bnc('b-InlineEdit'),
  blockModRead              = block.mod('mod', 'Read').toString(),
  blockModEdit              = block.mod('mod', 'Edit').toString(),
  blockModReadonly          = block.mod('mod', 'Readonly').toString(),
  blockHasErr               = block.mod('hasErr').toString(),
  bValueHolder              = block.el('valueHolder').toString(),
  bValue                    = block.el('value').toString(),
  bInputHolder              = block.el('inputHolder').toString(),
  bInput                    = block.el('input').toString(),
  bErrMessage               = block.el('errMessage').toString();

const InlineEdit = ({
  inputType = inputTypesEnum.InputText,
  input = {},
  meta = {},
  options = [{ label: '', value: undefined }],
  inputClassName = '',
  visibleValue,
  readonly = false,
  markPristineValidity = false,
  borderClassName = '',
  ...rest
}: IInlineEditProps) => {
  const [mod, setMod] = useState(modsEnum.Read);
  const wrapperRef    = useRef(null);
  const inputRef      = useRef<Dropdown>(null);
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const { touched, error, valid, warning } = meta;
  let _valid: boolean;
  if (!markPristineValidity) {
    _valid = (meta !== undefined && valid === false && touched === true && !warning)
      ? false
      : undefined;
  }
  else {
    _valid = (valid && !warning) ? undefined : false;
  }

  const handleClick = () => {
    if (inputRef.current?.popoverListboxNode?.current?.contains(event.target)) {
      return;
    }
    if (mod !== modsEnum.Read) return;
    switchMod().then(() => {
        if (inputType !== inputTypesEnum.Select) {
          try {
            wrapperRef.current.querySelector('input').focus();
          } catch (e) {}
        } else if (inputRef && inputRef.current instanceof Dropdown) {
          inputRef.current.toggle();
        }
    });
  };

  const handleClickOutside = (event: Event) => {
    if (inputRef.current?.popoverListboxNode?.current?.contains(event.target)) {
      return;
    }
    if (mod !== modsEnum.Edit) return;
    if (!wrapperRef?.current?.contains(event.target)) {
      switchMod();
    }
  };

  const switchMod = () => new Promise((resolve, reject) => {
    if (readonly) {
      if (mod !== modsEnum.Read) setMod(modsEnum.Read);
      return resolve();
    }
    try {
      mod === modsEnum.Edit ?
        setMod(modsEnum.Read) :
        setMod(modsEnum.Edit);
      resolve();
    } catch (e) {
      reject(e);
    }
  });

  const handleFocus = () =>
  {
    if (mod === modsEnum.Read && !readonly)
    {
      setMod(modsEnum.Edit);
    }
  }

  const renderValue = (type) => {
    switch (type) {
      case inputTypesEnum.DateTime: {
        return (
          <span className={bValue}>
            { input && input.value ?  (new Date(input.value)).toLocaleDateString('ru', {dateStyle: 'short'}): input.value }
          </span>
        );
      }
      case inputTypesEnum.Select: {
        return (
          <span className={bValue}>
            { input && input.value && input.value.label ? (input.value.label).toString(): '' }
          </span>
        );
      }
      default : {
        return (
          <span className={bValue}>
            { input && input.value && (input.value.label || input.value).toString() }
          </span>
        );
      }
    }
  };

  const getExpirationDate = (input) => {
    return input.value
        ? (new Date (input.value))
        : undefined;
  }

  const renderInput = () =>
    [
      {
        type: inputTypesEnum.InputText,
        render: () =>
          <Input
            className={bInput.toString() + inputClassName}
            ref={inputRef}
            {...input}
            {...rest}
            valid={_valid}
            value={input.value || ''}
            onFocus={handleFocus}
          />,
      },
      {
        type: inputTypesEnum.InputNum,
        render: () =>
          <Input
            className={bInput.toString() + inputClassName}
            ref={inputRef}
            type={'text'}
            {...input}
            {...rest}
            value={(input.value !== null || input.value !== undefined) ? input.value : ''}
            valid={_valid}
            onWheel={(event) => event.target.blur()}
            onChange={(event) => parseInputValueToNumberOnChange(event, input.onChange)}
            onBlur={(event) => parseInputValueToNumberOnBlur(event, input.onBlur)}
            onKeyDown={preventNonNumericOnKeyDown}
            onFocus={handleFocus}
          />,
      },
      {
        type: inputTypesEnum.Select,
        render: () =>
          <Dropdown
            className={bInput + inputClassName}
            options={options}
            ref={inputRef}
            {...rest}
            {...input}
            valid={_valid}
            onChange={(event) => {
              input.onChange(event);
            }}
          />
      },
      {
        type: inputTypesEnum.DateTime,
        render: () =>
            <BcdDatePickerWrapper
                className={bInput.toString() + inputClassName}
                {...rest}
                markPristineValidity
                date={getExpirationDate(input)}
                value={(input.value !== null || input.value !== undefined) ? input.value : ''}
                input={input}
                meta={meta}
            />
      }
    ]
      .find(i => i.type === inputType)
      .render();

  return (
    <div className={
           classNames({
             [block.toString()]: true,
             [blockModRead]: mod === modsEnum.Read,
             [blockModEdit]: mod === modsEnum.Edit,
             [blockHasErr]: !!warning || !!error,
             [blockModReadonly]: readonly,
           })
         }
         onClick={(event) => handleClick(event)}
         ref={wrapperRef}>
      <div className={bValueHolder + borderClassName}>
        { renderValue(inputType) }
      </div>
      {
        !readonly &&
        <div className={bInputHolder}>
          { renderInput() }
          {
            !_valid && (error || warning) && mod === modsEnum.Edit &&
            <Tooltip align={'left'}
                     className={bErrMessage}>
              {
                error ?
                  error instanceof Error
                    ? error.message
                    : error
                : warning ?
                  warning instanceof Error
                    ? warning.message
                    : warning
                  : ''
              }
            </Tooltip>
          }
        </div>
      }
    </div>
  );
};
InlineEdit.propTypes = {
  inputType: PropTypes.oneOf(Object.values(inputTypesEnum)).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
  })),
  inputClassName: PropTypes.string,
  borderClassName: PropTypes.string,
  visibleValue: PropTypes.string,
  readonly: PropTypes.bool,
};

export {
  InlineEdit,
  inputTypesEnum,
};
