// @flow

import React from 'react';
import { StageSubstancePickerItem } from './StageSubstancePickerItem';
import bnc from 'bnc/index';

import './index.less';
import type { IStageSubstance } from '../../../models';
import { connect } from 'react-redux';
import { listStageSubstancesSelector } from '../../../reducers';

interface IStageSubstancePicker {
  className?: string;
  substances?: IStageSubstance[];
}

const defaultProps: IStageSubstancePicker = {
  className: '',
  substances: [],
};

export const
  BNCBlock = new bnc('b-StageSubstancePicker'),
  BNCWrapper = BNCBlock.el('wrapper'),
  BNCItem = BNCBlock.el('item'),
  BNCInterSign = BNCBlock.el('interSign')
;

let StageSubstancePicker = (props: IStageSubstancePicker = defaultProps) => {
  return (
    <div className={BNCBlock + props.className}>
      <div className={BNCWrapper}>
        {
          props.substances
            .filter((substance: IStageSubstance) => !substance.IsDeleted)
            .map((substance: IStageSubstance, idx: number, substances: IStageSubstance[]) => (
              <React.Fragment key={idx}>
                <StageSubstancePickerItem
                  className={BNCItem}
                  substance={substance}
                />
                {
                  (idx !== substances.length - 1) &&
                  (
                    (idx < substances.length - 2)
                      ? <i className={BNCInterSign + 'fo-plus'} />
                      : <i className={BNCInterSign + 'fo-arrow-right'} />
                  )
                }
              </React.Fragment>
            ))
        }
      </div>
    </div>
  );
};

StageSubstancePicker = connect(
  (state) => ({
    substances: listStageSubstancesSelector(state),
  }),
  null,
)(StageSubstancePicker);

export {
  StageSubstancePicker,
};
