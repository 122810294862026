// @flow

import {
  resourceActions,
} from '../../shared/utils';

export const REACTION_CATALOG_ROOT = 'REACTION_CATALOG';

export const reactionCatalogResourceActions = resourceActions(REACTION_CATALOG_ROOT);

export const PURIFICATION_REACTION_CATALOG_ROOT = 'PURIFICATION_REACTION_CATALOG';

export const purificationReactionCatalogResourceActions = resourceActions(PURIFICATION_REACTION_CATALOG_ROOT);
