import classNames from 'classnames';
import { Field } from 'redux-form';
import { BcdDatePickerWrapper, BcdDropdownWrapper, BcdSuggestWrapper } from '../../../shared/components';
import { IconButton } from '../../../shared/components/IconButton';
import { FieldValidators } from '../../../shared/validators/field-validators';
import { AnalyticsTestKeys } from '../../models';
import gridStyle from '../table.m.less';
import { ResizableAreaFieldWrapper } from '../../../shared/components/ResizableArea/ResizableAreaFieldWrapper';

export const renderTestArray = ({fields, currentMolecule, testClassDropdownObjects, deleteTestHandler}) => {
  const _fields = fields.getAll();
  return _fields && _fields.length > 0
    ? fields.getAll().map((test, index) => 
      !test.IsDeleted &&
        renderTestRow({
          index,
          fields,
          currentMolecule,
          testClassDropdownObjects,
          deleteTestHandler,
      }))
    : '';
};

const renderTestRow = ({index, fields, testClassDropdownObjects, deleteTestHandler}) => {

  const getDeadlineDate = () => {
    const DeadlineDate = fields.get(index).DeadlineDate;    
    return DeadlineDate
      ? (new Date (DeadlineDate))
      : undefined;
  };

  const formatTestClassBimsId = (value) => {
    return testClassDropdownObjects?.find(x => x.value === value);
  };

  return (
    <div key={index} className={classNames(gridStyle.grid__row)}>
      <div className={gridStyle.grid__cell}>
        <Field
          name={`AnalyticTests[${index}].${AnalyticsTestKeys.TestClassBimsId}`}
          component={BcdSuggestWrapper}
          validate={[FieldValidators.required]}
          format={value => formatTestClassBimsId(value)}
          options={testClassDropdownObjects}
          normalize={(value) => value === null ? 0 : value}
          props={{
              disabled: !!fields.get(index).Id,
              multiple: false,
            }}
          markPristineValidity
          />
      </div>
      <div className={gridStyle.grid__cell}>
        <Field
          name={`AnalyticTests[${index}].${AnalyticsTestKeys.DeadlineDate}`}
          component={BcdDatePickerWrapper}
          validate={[FieldValidators.required]}
          markPristineValidity
          props={{
            date: getDeadlineDate(),
          }}
        />
      </div>
      <div className={gridStyle.grid__cell}>
        <Field
          name={`AnalyticTests[${index}].${AnalyticsTestKeys.Comment}`}
          component={ResizableAreaFieldWrapper}
          rows={1}
          maxRows={5}
          placeholder={'Введите комментарий'}
        />
      </div>
      <div className={gridStyle.grid__cell}>
          <IconButton
            iconClassName={'fo-trash'}
            onClick={() => deleteTestHandler(index)}
          />
      </div>
    </div>
  );
};