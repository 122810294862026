import bnc                 from 'bnc';
import { Component }       from 'react';
import propTypes           from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck }         from '@fortawesome/free-solid-svg-icons/faCheck';
import                          './index.less';

const optionType = propTypes.oneOfType([
    propTypes.string,
    propTypes.shape({
        label:     propTypes.node,
        value:     propTypes.any
    })
]);

export default class Checkbox extends Component {

    static propTypes = {
        name:     propTypes.string,
        onChange: propTypes.func.isRequired,
        disabled: propTypes.bool,
        checked:  propTypes.bool,
        large:    propTypes.bool,
        option:   optionType,
        button:   propTypes.bool,
        className: propTypes.oneOfType([propTypes.string, propTypes.instanceOf(bnc)]),
    };

    static defaultProps = {
        disabled: false,
        checked:  false,
        large:    false,
        option:   '',
        button:   false,
        className: '',
    };

    static block = new bnc.default('b-checkbox');

    static getVisibleLabel = option =>
        (typeof option === 'object' && 'label' in option)
            ? option.label
            : option
    ;

    static getValue = option =>
        (typeof option === 'object' && 'value' in option)
            ? option.value
            : option
    ;

    static Group = ({children}) => {
        return (
            <div className={Checkbox.block.el('group')}>
                {
                    React.Children.map(
                        children,
                        ({props}) => (
                            <Checkbox
                                {...props}
                                button={true}
                            />
                        )
                    )
                }
            </div>
        );
    };

    input = React.createRef();

    componentDidUpdate() {
        this.input.current.checked = this.props.checked;
    }

    renderCheckbox = ({ onChange, checked, large, button, option, children, className='', disabled=false}) => (
        <label
            className={
                Checkbox.block.el('wrapper')
                + Checkbox.block.mod( large ? 'large' : 'small' )
                + className
            }
        >
            <input
                ref            = {this.input}
                className      = {Checkbox.block.el('checkbox')}
                type           = "checkbox"
                onChange       = {onChange}
                defaultChecked = {checked}
                value          = {Checkbox.getValue(option)}
                disabled       = {disabled}
            />
            <div
                className={
                    Checkbox.block +
                    Checkbox.block.el( button ? 'button' : 'simple' ) +
                  ( disabled ? Checkbox.block.mod( 'disabled' ) : '' )
                }
            >
                <span className={Checkbox.block.el('toggle')}>
                    <FontAwesomeIcon
                        icon      = {faCheck}
                        size      = 'xs'
                        className = {`${Checkbox.block.el('checkmark')}`}
                    />
                </span>
                {
                    option
                        ? <span className={Checkbox.block.el('label')}>{Checkbox.getVisibleLabel(option)}</span>
                        : children
                            ? <span className={Checkbox.block.el('label')}>{children}</span>
                            : null
                }
            </div>
        </label>
    );

    render() {
        return this.renderCheckbox(this.props);
    }
}

Checkbox.Group.displayName = 'Checkbox.Group';
