import { environment } from '../../../environment';
import React, {useCallback} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { itemsToArraySelector } from '../../../shared/utils/selectors/resource-selectors';
import css from '../table.m.less';
import style from './tests.m.less';
import { AnalyticsTestTitles, AnalyticsTestKeys } from '../../models/analytics-test.model';
import classNames from 'classnames';
import { loadBimsDictionaries } from '../../../dictionary/components/bimsLoaderHOC';

const tableCols = [
  {
    title: AnalyticsTestTitles.TestClassBimsId,
    mappingProp: AnalyticsTestKeys.TestClassBimsId,
  },
  {
    title: AnalyticsTestTitles.Comment,
    mappingProp: AnalyticsTestKeys.Comment,
  },
  {
    title: AnalyticsTestTitles.DeadlineDate,
    mappingProp: AnalyticsTestKeys.DeadlineDate,
  },
  {
    title: AnalyticsTestTitles.StatusCode,
    mappingProp: AnalyticsTestKeys.StatusCode,
  },
  {
    title: AnalyticsTestTitles.Result,
    mappingProp: AnalyticsTestKeys.Result,
  },
];

export let AnalyticTestsTable = ({
  tests,
  testStatuses,
  testClasses,
}) => {
  
  return (
    <>
      <div className={classNames(css.grid, style.template)}>
        <div className={css.grid__header}>
          <HeaderRow />
        </div>
        <div className={classNames(css.grid__body)}>
          {
            tests && tests.map(test => 
              {
                return !test.IsDeleted && (
                  <TestRow key={test.Id}>
                    <TestRowCells test={test} testStatuses={testStatuses} testClasses={testClasses}/>
                  </TestRow>
                );
              }
            )
          }
        </div>
      </div>
    </>
  );
};

const HeaderRow = () => (
  <div className={css.grid__row}>
    {
      tableCols.map((col, index) => 
        <div key={index} className={css.grid__cell}>
          {col.title}
        </div>
      )
    }
  </div>
);

const TestRow = ({children}) => {

  return(
    <div className={css.grid__row}>
      { children }
    </div>
  );
};

const TestRowCells = ({test, testClasses, testStatuses}) => {

  const cellContent = (field) => {
    switch (field) {

      case AnalyticsTestKeys.TestClassBimsId: {
        return getTestsClassTitle(test[field], test[AnalyticsTestKeys.Link], testClasses);
      }
      case AnalyticsTestKeys.Comment: {
        return test[field];
      }
      case AnalyticsTestKeys.DeadlineDate: {
        return getDeadlineDate(test[field]);
      }
      case AnalyticsTestKeys.StatusCode: {
        return getStatusCodeTitle(test[field], testStatuses);
      }
      case AnalyticsTestKeys.Result: {
        return getResult(test[field], test[AnalyticsTestKeys.Link]);
      }
      default: {
        return test[field];
      }
    }
  };

  const getTestsClassTitle = (id, href, classes) => {
    const title = classes?.find(x => x.id === id)?.title;
    return href ? getExternalLink(href, title) : title;
  };
  
  const getDeadlineDate = (date) => {
    return (new Date(date)).toLocaleDateString('ru', {dateStyle: 'short'});
  };
  
  const getStatusCodeTitle = useCallback((code, statuses) => {
    return statuses?.find(x => x.code === code)?.title;
  });
  
  const getResult = useCallback((result, href) => {
    return result ? getExternalLink(href, 'Перейдите по ссылке') : '\u2014';
  });
  
  const getExternalLink = useCallback((href, title) => {
    const link = environment.bimsUrl + '#page-data:' + href;
    return <a href={link} target='_blank' rel="noopener noreferrer">{title}</a>;
  });

  return tableCols.map((col, index) =>
    <div 
      key={`${test.Id} ${index}`}
      className={css.grid__cell}
    >
      {cellContent(col.mappingProp)}
    </div>
  );
};

const mapStateToProps = ({resource}) => {
  return {
    testStatuses: itemsToArraySelector(resource.dictionary.testStatuses),
    testClasses: itemsToArraySelector(resource.dictionary.testClasses),
  };
};

const mapDispatchToProps = dispatch => ({
});

AnalyticTestsTable = loadBimsDictionaries(AnalyticTestsTable);

AnalyticTestsTable = withRouter(
  connect(
    mapStateToProps, mapDispatchToProps
  )(AnalyticTestsTable)
);