// @flow

import { createSelector } from 'reselect';
import { resourceReducer } from '../../shared/utils/reducer-creators';
import {
    indicatorProcessPointsResourceActions,
    indicatorsResourceActions,
    indicatorUnitsResourceActions
} from '../actions';
import type { IRootStore } from '../../app/reducers';
import type { IIndicator, IIndicatorUnit } from '../models';
import type {OptionType} from '../../shared/models';
import {stageIndicatorResourceActions} from '../../stage-indicators/actions';

export const indicatorsResourceReducer = resourceReducer(indicatorsResourceActions);
export const indicatorUnitsResourceReducer = resourceReducer(indicatorUnitsResourceActions);
export const indicatorProcessPointsResourceReducer = resourceReducer(
    indicatorProcessPointsResourceActions,
    {
        [stageIndicatorResourceActions.explicitlySetRequestState]: (state, action) => ({
            ...state,
            pending: action.pending,
            requestSucceed: action.requestSucceed,
        })
    }
);

export const
    getIndicators = createSelector(
        (state: IRootStore) => state.resource.dictionary.indicators.data,
        (data) => Object.values(data)
    ),
    getIndicatorUnits = createSelector(
        (state: IRootStore) => state.resource.dictionary.indicatorUnits.data,
        (data) => Object.values(data)
    ),
    getIndicatorsAsOptions = createSelector(
        [getIndicators],
        (indicators: IIndicator[]): OptionType[] => indicators.map((i:IIndicator) => ({
            value: i?.Id,
            label: i?.Name,
        }))
    ),
    getIndicatorUnitsAsOptions = createSelector(
        [getIndicatorUnits],
        (indicatorUnits: IIndicatorUnit[]): OptionType[] => indicatorUnits.map((i:IIndicatorUnit) => ({
            value: i?.Id,
            label: i?.Name,
        }))
    ),
    getIndicatorProcessPoints = createSelector(
        (state: IRootStore) => state.resource.indicatorProcessPoints.data,
        (data) => Object.values(data)
    )
;
