// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".area-m__wrapper___Q2N3C {\n  width: 100%;\n  padding: 8px 12px 8px;\n  position: relative;\n  border: 1px solid var(--border-color);\n  border-radius: var(--border-radius);\n  background-color: #fff;\n  overflow: hidden;\n  outline: none;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.area-m__textarea___m2gbu {\n  width: 100%;\n  resize: none;\n  overflow: auto;\n  word-wrap: break-word;\n  box-sizing: border-box;\n  background-color: transparent;\n  border: none;\n  outline: none;\n  color: inherit;\n  padding: 0;\n  margin: 0;\n}\n.area-m__valid___mHWTN {\n  border: 1px solid green;\n}\n.area-m__invalid___Ue39G {\n  border: 1px solid red;\n}\n.area-m__disabled___D6Bdd {\n  border: 1px solid var(--grey-400) !important;\n  background-color: var(--grey-100);\n  color: var(--grey-500) !important;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "area-m__wrapper___Q2N3C",
	"textarea": "area-m__textarea___m2gbu",
	"valid": "area-m__valid___mHWTN",
	"invalid": "area-m__invalid___Ue39G",
	"disabled": "area-m__disabled___D6Bdd"
};
export default ___CSS_LOADER_EXPORT___;
