// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style-m__wrapper___Smg8y {\n  display: flex;\n  flex-flow: column nowrap;\n}\n.style-m__label___IuhNJ {\n  font-size: 12px;\n  margin-bottom: 4px;\n  color: var(--grey-400);\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "style-m__wrapper___Smg8y",
	"label": "style-m__label___IuhNJ"
};
export default ___CSS_LOADER_EXPORT___;
