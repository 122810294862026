import bnc                     from 'bnc';
import { Component, Fragment } from 'react';
import propTypes               from 'prop-types';
import { FontAwesomeIcon }     from '@fortawesome/react-fontawesome';
import                              './index.less';

export default class RadioGroup extends Component {

    state = {};

    static propTypes = {
        name:       propTypes.string.isRequired,
        options:    propTypes.array .isRequired,
        onChange:   propTypes.func  .isRequired,
        view:       propTypes.string,
        disabled:   propTypes.bool,
        className:  propTypes.oneOfType([propTypes.string, propTypes.instanceOf(bnc)]),
    };

    static getDerivedStateFromProps = ({value, defaultValue}, {selected, prevValue}) => (
        {
            prevValue: value,
            selected:  value !== prevValue
                        ? value
                        : selected !== void(0)
                            ? selected
                            : defaultValue
        }
    );

    static getID = (name, index) => `${name}-${index}`;

    static getOptionValue = option => (
        (typeof option === 'string')
            ? option
            : (typeof option === 'number')
                ? option
                : ('value' in option)
                    ? option.value
                    : null
    );

    static getOptionLabel = option => (
        (typeof option === 'string')
            ? option
            : (typeof option === 'number')
                ? option
                : ('label' in option)
                    ? option.label
                    : null
    );

    static block = new bnc.default('b-radiogroup')

    state = {
        selected: void(0)
    }

    onChange = selected =>
        this.setState(
            this.props.value ? {} : { selected },
            () =>   this.props.onChange(
                        RadioGroup.getOptionValue( selected )
                    )
        )

    renderRadioGroup = ({name, view, options, className='', disabled = false}, {selected} ) =>
        <fieldset className={RadioGroup.block + className}>
            {
                Boolean( options.length > 0 ) &&
                options.map(
                    (option, index) =>
                        <Fragment key = { `option-${name}-${index}` }>
                            <input
                                type     = 'radio'
                                id       = { RadioGroup.getID( name, index )  }
                                name     = { name                             }
                                checked  = { selected === option              }
                                onChange = { () => this.onChange(option)      }
                                disabled = { disabled                         }
                            />
                            <label
                                htmlFor   = { RadioGroup.getID( name, index ) }
                                className = { RadioGroup.block.el('option') +
                                              RadioGroup.block.el('option').mod( view || 'regular') +
                                            ( disabled ? RadioGroup.block.el('option').mod('disabled') : '') +
                                            ( selected === option
                                                ? RadioGroup.block.el('option').mod( 'selected' )
                                                : '' )
                                            }
                            >
                                { RadioGroup.getOptionLabel( option ) }
                            </label>
                        </Fragment>
                    )
            }
        </fieldset>

    render = () => this.renderRadioGroup( this.props, this.state )

}