import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { IconButton } from '../../../shared/components/IconButton';
import css from './uploader.m.less';
import Button from '@biocad/bcd-front-ui/controls/Button';
import { attachmentsActions } from '../../actions';
import classNames from 'classnames';
import { comparingSchemesSelector } from '../../../schemes/reducers';

let FileUploader = ({
  label = 'Добавить файл',
  multiple = true,
  accept = null,
  oneFile = false,
  files = [],
  viewMode = false,
  canDeleteFiles = true,
  canUploadFiles = true,
  tag = null,
  deleteHandler = () => { },
  uploadFiles = () => { },
  downloadFile = () => { },
  className=''
}) => {

  const inputElem = useRef(null);
  
  const onChangeHandler = (e) => {
    uploadFiles({ files: Object.values(e.target.files), tag: tag });
    inputElem.current.value = '';
  };

  const onTrashClickHandler = (index) => {
    deleteHandler(index);
  };

  const onFileClickHandler = (e, id, name) => {
    e.preventDefault();
    e.stopPropagation();
    downloadFile({ id, name });
  };

  const onButtonClickHandler = () => {
    inputElem?.current?.click();
  };

  return (
    <div className={css.main_wrap + ' ' + className}>
      {
        (typeof viewMode === 'boolean' ? !viewMode : canUploadFiles) &&
        (!oneFile || !files?.length) &&
        <>
          <Button
            text={label}
            icon={faPlus}
            onAction={onButtonClickHandler}
          />
          <input
            ref={inputElem}
            name='fileInput'
            type="file"
            className={css.inputBtn}
            multiple={multiple}
            onChange={onChangeHandler}
            accept={accept}
          />
          <hr />
        </>
      }
      <ul className={css.list}>
        {
          files.filter(x => !x.IsDeleted).length
            ? files.filter(x => !x.IsDeleted).map((x, index) =>
              <li className={css.list__row} key={index}>
                <i className={classNames(css.trashIcon, 'fo-doc-inv')} />
                <a
                  href={'#'}
                  onClick={(e) => onFileClickHandler(e, x.AttachmentId, x.Attachment.OriginalName)}
                  className={css.list__row__name}
                  title={x?.Attachment?.OriginalName}
                >
                  {x?.Attachment?.OriginalName}
                </a>
                <div className={css.list__row__expander} />
                {
                  (typeof viewMode === 'boolean' ? !viewMode : canDeleteFiles || !x.Id) &&
                  <IconButton iconClassName='fo-trash' onClick={() => onTrashClickHandler(index)} />
                }
              </li>
            )
            : <span>{oneFile ? 'Файл отсутствует' : 'Список файлов пуст'}</span>
        }

      </ul>
    </div>
  );
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = dispatch => ({
  uploadFiles: ({ files, index, tag }) => dispatch(attachmentsActions.upload.request({ files, index, tag })),
  downloadFile: ({ id, name }) => dispatch(attachmentsActions.download.request({ id, name })),
});

FileUploader = connect(mapStateToProps, mapDispatchToProps)(FileUploader);

export {
  FileUploader,
};
