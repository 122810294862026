// @flow
import { environment } from '../../environment';
import { UserManager, User, WebStorageStateStore } from 'oidc-client';

export { User };

function generateNonce(): string {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

const { oidcUrl, serviceId, baseUri } = environment;

const config = {
  authority: oidcUrl,
  client_id: serviceId,
  redirect_uri: baseUri,
  response_type: 'id_token token',
  scope: 'openid',
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  loadUserInfo: false,
  silent_redirect_uri: environment.baseUri + 'auth-silent.html',
};

const userManager: UserManager = new UserManager(config);

export class OidcAuthService {
  constructor() {
  }

  getUser(): Promise<User> {
    return userManager.getUser();
  }

  login(): Promise<User> {
    return userManager.signinRedirect();
  }

  endLogin(url: string): Promise<User> {
    return userManager.signinRedirectCallback(url || window.location.href);
  }

  renewToken(): Promise<User> {
    return userManager.signinSilent();
  }

  logout(): Promise<void> {
    return userManager.signoutRedirect();
  }
}
