// @flow

import { resourceReducer } from '../../shared/utils';
import {
  purificationReactionCatalogResourceActions,
  reactionCatalogResourceActions,
} from '../actions';
import {createSelector} from 'reselect';
import type {IRootStore} from '../../app/reducers';

export const reactionCatalogResourceReducer = resourceReducer(reactionCatalogResourceActions);
export const purificationReactionCatalogResourceReducer = resourceReducer(purificationReactionCatalogResourceActions);

export const getPurificationReactionCatalog = createSelector([
    (state: IRootStore) => state.resource.purificationReactionCatalog.data
], (data) => Object.values(data));
