import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import './index.less';
import classNames from 'classnames';
import Tree from './Tree';
import { descendantsModuleActions, descendantsResourceActions } from '../../../projects/actions';
import { itemsToArraySelector } from '../../utils/selectors/resource-selectors';
import { folderParentType } from '../../../projects/types/enum';

const initialProps = {
    title: '',
    isRequired: false,
    value: null, 
    descendants: [],
    folderId: null,
    setCurrentDescendant: () => null,
    currentDescendant: null,
    deleteCurrentDescendant: () => null,
    setDescendantsList: () => null,
    root: '',
    parentId: null,
    currentProject: null,
    getDescendantsList: () => null,
    disabled: false
};

const DescendantTree = (props = initialProps) => {
    const [opened, setOpened] = useState(false);
    const [descendantsTree, setDescendantsTree] = useState([]);

    useEffect(() => {
        if (opened)
            document.addEventListener('click', handleClickOutside, false);

        return document.removeEventListener('click', handleClickOutside, false);
    }, [opened]);

    useEffect(() => {
        let descendants = itemsToArraySelector(props.descendants);

        if(props.folderId) {
            let currentDescendant = descendants.find(item => item.Id === props.folderId);

            if(!currentDescendant) {
                return;
            }

            props.setCurrentDescendant({
                id: currentDescendant.Id,
                name: currentDescendant.Name
            });
        }


        if (descendants.length) {
            let tempArr = getTree(descendants);
            props.setDescendantsList({
                list: tempArr
            });

            setDescendantsTree(tempArr);            
        }

        getNameOfCurrentDescendant();
    }, [props.descendants]);

    const getNameOfCurrentDescendant = () => {
        if (props.currentDescendant && props.currentDescendant.id && !props.currentDescendant.name) {
            let descendants = itemsToArraySelector(props.descendants);
            let descendantName = descendants.find(item => Number(item.Id) === Number(props.currentDescendant.id)) ?.Name;
            props.setCurrentDescendant({
                id: props.currentDescendant.id,
                name: descendantName
            });
        }
    };

    useEffect(() => {
        getNameOfCurrentDescendant();
    }, [props.currentDescendant]);

    const getTree = (arr) => {
        let tree = [],
            mappedArr = {},
            mappedElem,
            arrElem;

        for (let i = 0; i < arr.length; i++) {
            arrElem = arr[i];
            mappedArr[arrElem.Id] = arrElem;
            mappedArr[arrElem.Id]['Children'] = [];
        }

        for (let id in mappedArr) {
            // eslint-disable-next-line no-prototype-builtins
            if (mappedArr.hasOwnProperty(id)) {
                mappedElem = mappedArr[id];

                if (mappedElem.ParentId && ((Number(mappedElem.ParentId) !== Number(props.parentId)) && mappedElem.ParentType === 1)) {
                    mappedArr[mappedElem['ParentId']]['Children'].push(mappedElem);
                } else {
                    tree.push(mappedElem);
                }
            }
        }
        return tree;
    };

    const handleClickOutside = (e) => {
        const container = document.getElementsByClassName('descendant-tree-container')[0];

        if (!e.path.includes(container)) {
            setOpened(false);
            document.removeEventListener('click', handleClickOutside, false);
        }
    };

    const openedTrigger = () => {
        if (props.disabled) return;
        setOpened(!opened);
        if (opened)
            document.removeEventListener('click', handleClickOutside, false);
    };

    const chooseRootValue = () => {
        if (props.disabled) return;
        props.deleteCurrentDescendant();
        setOpened(!opened);
    };

    return (
        <label className={'labelToTop'}>
            <span className={'labelToTop__title'}>{props.title}</span>

            {props.isRequired &&
                <i className='required-red' />}

            <div className={'descendant-tree-container'}>
                <div className={'descendant-tree-input'} onClick={openedTrigger}>
                    <span>{props.currentDescendant ? props.currentDescendant.name : props.root}</span>
                    <i className={classNames('fo-dropdown-arrow', {
                        'rotated': opened
                    })} />
                </div>

                <div className={classNames('tree', {
                    'opened': opened
                })}>
                    <ul>
                        <li
                            onClick={chooseRootValue}
                            data-level={props.level}
                            className={classNames({
                                'opened': opened,
                                'checked': props.currentDescendant === null
                            })}>

                            <i className={'fo-folder'} />
                            {props.root}
                        </li>
                        <Tree parentId={props.parentId} selectTrigger={openedTrigger} descendants={descendantsTree} />
                    </ul>
                </div>
            </div>
        </label>
    );

};

const mapStateToProps = (state) => {
    const { modules, resource } = state;
    return {
        currentDescendant: modules.descendants.currentDescendant,
        descendants: resource.descendants
    };
};

export default connect(
    mapStateToProps,
    (dispatch) => ({
        setCurrentDescendant: ({ id, name }) => dispatch(descendantsModuleActions.setCurrentDescendant({ id, name })),
        deleteCurrentDescendant: () => dispatch(descendantsModuleActions.deleteCurrentDescendant()),
        setDescendantsList: ({ list }) => dispatch(descendantsModuleActions.setDescendantsList({ list })),
        clearDescendantsList: () => dispatch(descendantsModuleActions.clearDescendantsList()),
        getDescendantsList: (params) => dispatch(descendantsResourceActions.list.request({ params }))
    })
)(DescendantTree);