// @flow

// import type { IRootStore } from '../../app/reducers';
import { _temp_folderResourceAction } from '../actions';
import type { ISuccessResourceAction } from '../../shared/utils/action-creators';
import type { IRootStore } from '../../app/reducers';
import type { IFolder } from '../models';
import {folderSchemesResourceActions} from '../../projects/actions';

export interface IFolderModuleStore {
  createOrUpdateSuccess: boolean,
  deleteFolderSuccess: boolean,
  lastEditedFolder: IFolder,
  currentFolder: IFolder,
  selectSuccess: Boolean,
  lastSelectedFolder: IFolder,
  selectFolder: {
    savedSuccessful: boolean,
  }
}

const defaultFolderModuleStore: IFolderModuleStore = {
  createOrUpdateSuccess: null,
  lastEditedFolder: null,
  currentFolder: null,
  selectSuccess: null,
  lastSelectedFolder: null,
  selectFolder: {
    savedSuccessful: null,
  },
};

export const _temp_folderModuleReducer = (state: IFolderModuleStore = defaultFolderModuleStore, action: any) => {
  const reducerFn = {
    [_temp_folderResourceAction.create.request]: (): IFolderModuleStore => ({
      ...state,
      createOrUpdateSuccess: null,
      lastEditedFolder: null,
    }),
    [_temp_folderResourceAction.create.success]: (action: ISuccessResourceAction): IFolderModuleStore => ({
      ...state,
      createOrUpdateSuccess: true,
      lastEditedFolder: action.result,
    }),
    [_temp_folderResourceAction.create.failure]: (): IFolderModuleStore => ({
      ...state,
      createOrUpdateSuccess: false,
      lastEditedFolder: null,
    }),
    [_temp_folderResourceAction.select.request]: (): IFolderModuleStore => ({
      ...state,
      selectSuccess: null,
      lastEditedFolder: null,
    }),
    [_temp_folderResourceAction.select.success]: (): IFolderModuleStore => ({
      ...state,
      selectSuccess: true,
      lastSelectedFolder: action.result,
    }),
    [_temp_folderResourceAction.select.failure]: (): IFolderModuleStore => ({
      ...state,
      selectSuccess: false,
      lastSelectedFolder: null,
    }),
    [_temp_folderResourceAction.update.request]: (): IFolderModuleStore => ({
      ...state,
      createOrUpdateSuccess: null,
      lastEditedFolder: null,
    }),
    [_temp_folderResourceAction.update.success]: (action: ISuccessResourceAction): IFolderModuleStore => ({
      ...state,
      createOrUpdateSuccess: true,
      lastEditedFolder: action.result,
    }),
    [_temp_folderResourceAction.update.failure]: (): IFolderModuleStore => ({
      ...state,
      createOrUpdateSuccess: false,
      lastEditedFolder: null,
    }),
    [_temp_folderResourceAction.delete.request]: (): IFolderModuleStore => ({
      ...state,
      deleteFolderSuccess: null,
    }),
    [_temp_folderResourceAction.delete.success]: (): IFolderModuleStore => ({
      ...state,
      deleteFolderSuccess: true,
    }),
    [_temp_folderResourceAction.delete.failure]: (): IFolderModuleStore => ({
      ...state,
      deleteFolderSuccess: false,
    }),
    [_temp_folderResourceAction.get.success]: (action: ISuccessResourceAction): IFolderModuleStore => ({
      ...state,
      currentFolder: action.result,
    }),
    [_temp_folderResourceAction.destroy]: () => ({
      ...state,
      currentFolder: null
    }),
    [_temp_folderResourceAction.selectFolder.request]: (): IFolderModuleStore => ({
      ...state,
      selectFolder: {
        savedSuccessful: null,
      }
    }),

    [_temp_folderResourceAction.selectFolder.success]: (): IFolderModuleStore => ({
      ...state,
      selectFolder: {
        savedSuccessful: true,
      }
    }),

    [_temp_folderResourceAction.selectFolder.failure]: (): IFolderModuleStore => ({
      ...state,
      selectFolder: {
        savedSuccessful: false,
      }
    }),
  }[action.type];
  return reducerFn ? reducerFn(action) : state;
};

// Selectors
export const
  getFolderCreateOrUpdateSuccess = (state: IRootStore) => state.modules._folder_temp.createOrUpdateSuccess,
  getFolderDeleteSuccess = (state: IRootStore) => state.modules._folder_temp.deleteFolderSuccess,
  getLastEditedFolder = (state: IRootStore) => state.modules._folder_temp.lastEditedFolder,
  
  getLastSelectedFolder = (state: IRootStore) => state.modules._folder_temp.lastSelectedFolder,
  getFolderSelectSuccess = (state: IRootStore) => state.modules._folder_temp.selectSuccess

;
