import {
    apiRequestList, apiRequestSingle
} from '../../shared/services/api/common';

export class EquipmentService {

    constructor(token) {
        const headers = token ? {Authorization: token} : {};

        this._getList = apiRequestList('GET', '/equipment', headers);
        this._getAll = apiRequestList('GET', '/equipment/all', headers);
    }

    getList(params) {
        return this._getList({ params });
    }

    getAll(params) {
        return this._getAll({ params });
    }
}
