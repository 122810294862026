import React, { useState, useEffect } from 'react';
import RadioGroup from '@biocad/bcd-front-ui/controls/RadioGroup';
import { getEnum } from '../../utils';
import PropTypes from 'prop-types';

export const BcdRadioGroupWithOptions = ({name, options, value, onChange, className, ...rest}) => {

  const [optionsEnum, setOptionsEnum] = useState(null);
  const [optionLabels, setOptionLabels] = useState([]);
  const [_value, set_value] = useState(value);

  useEffect(() => {
    typeof options[0] === 'object' &&
      setOptionsEnum(getOptionsEnum());

    setOptionLabels(getOptionLabels());
  }, [options]);

  useEffect(() => {
    set_value(getValue(value));
  }, [value]);

  const getValue = (label) => {
    if (typeof options[0] === 'object') {
      return optionsEnum ? optionsEnum[label] : getOptionsEnum()[label];
    } else {
      return label;
    }
  };

  const getOptionLabels = () => {
    if (typeof options[0] === 'object') {
      return options.map(x => x.label);
    } else {
      return options;
    }
  };

  const getOptionsEnum = () => {
    let result = {};
    options.map(x => result[x.value] = x.label);
    return getEnum(result);
  };

  const onChangeHandler = (label) => {
    set_value(getValue(label));
    onChange(getValue(label));
  };

  return (
    <RadioGroup
      name         = { name }
      options      = { optionLabels }
      value        = { _value }
      onChange     = { onChangeHandler }
      className    = { className }
      {...rest}
    />
  );
};

BcdRadioGroupWithOptions.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
  )]).isRequired,
};