import {
    apiRequestList,
} from '../../shared/services/api/common';

export const DASHBOARD_ROOT = '/current-user';

export class DashboardService {
    constructor(token) {
        const headers = token ? {
            Authorization: token
        } : {};

        this._getReactionsList = apiRequestList('GET', `${DASHBOARD_ROOT}/reactions`, headers);
        this._getWorkProjectsList = apiRequestList('GET', `${DASHBOARD_ROOT}/work-projects`, headers);
        this._getOwnedProjectsList = apiRequestList('GET', `${DASHBOARD_ROOT}/owned-projects`, headers);
    }

    
    getReactionsList({ params }) {
        return this._getReactionsList({
            params: {
                ...params,
                CreationDateFrom: params.CreationDateFrom && params.CreationDateFrom.toJSON(),
                CreationDateTo: params.CreationDateTo && params.CreationDateTo.toJSON(),
            }
        });
    }

    getWorkProjectsList({ params }) {
        return this._getWorkProjectsList({ params });
    }

    getOwnedProjectsList({ params }) {
        return this._getOwnedProjectsList({ params });
    }
}