// @flow

import {
  resourceActions, 
} from '../../shared/utils';


export const SMALL_MOLECULES_AVAILABILITY_ROOT_NAME = 'SMALL_MOLECULES_AVAILABILITY';
export const smallMoleculesAvailabilityResourceActions = {
  ...resourceActions(SMALL_MOLECULES_AVAILABILITY_ROOT_NAME),
};