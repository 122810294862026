import React, {useEffect} from 'react';
import Dialog from '@biocad/bcd-front-ui/layout/Dialog';
import {StyledButton} from '../../../../shared/components/ConfirmDialog/ConfirmDialog';
import {connect} from 'react-redux';
import {folderResourceActions, folderSchemesResourceActions} from '../../../actions';
import {itemsToArraySelector} from '../../../../shared/utils/selectors/resource-selectors';
import {BcdInputWrapper} from '../../../../shared/components';
import {Field, reduxForm, Form, getFormValues, isValid, isDirty, change} from 'redux-form';
import {FieldValidators} from '../../../../shared/validators/field-validators';
import {folderParentType} from '../../../types/enum';

export const FOLDER_EDIT_FORM_NAME = 'folderEditFormName';

const formInitialValues: {
    name: string
} = {
    name: ''
};

const initialProps = {
    toggleModal: null,
    createFolder: null,
    isEditMode: false,
    currentFolderId: null,
    folders: [],
    updateFolder: null,
    foldersPath: [],
    schemesFoldersPath: [],
    formValues: null,
    formDirty: null,
    formValid: null,
    updateForm: null,
    isSchemesFolder: false,
    position: null,
    currentCandidateId: null,
    createProductionSchemeFolder: () => null,
    updateProductionSchemeFolder: () => null,
    foldersSchemes: [],
    candidateFolderPath: [],
    currentProjectId: null
};

let FolderModal = (props = initialProps) => {
    useEffect(() => {
        if (props.isEditMode && props.currentFolderId) {
            const folders = props.isSchemesFolder ? props.foldersSchemes : props.folders;
            const value = folders.find(item => Number(item.Id) === Number(props.currentFolderId)).Name;
            if(value) props.updateForm(value);
        }
    }, [props.isEditMode]);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!props.isEditMode) {
            createFolder();
        } else {
            editFolder();
        }
    };

    const createFolder = () => {
        let foldersPath;

        switch(props.position) {
            case 'scheme':
                foldersPath = props.schemesFoldersPath;

                props.createProductionSchemeFolder({
                    name: props.formValues.name,
                    parentId: foldersPath.length ?
                        foldersPath[foldersPath.length - 1].id : props.currentCandidateId,
                    parentType: foldersPath.length ? folderParentType.folder : folderParentType.finalCandidate
                });

                break;
            case 'candidate':
                foldersPath = props.candidateFolderPath;

                props.createFolder({
                    name: props.formValues.name,
                    parentId: foldersPath.length ?
                        foldersPath[foldersPath.length - 1].id : props.currentProjectId,
                    parentType: foldersPath.length ? folderParentType.folder : folderParentType.project
                });

                break;
            default:
                foldersPath = props.foldersPath;

                props.createFolder({
                    name: props.formValues.name,
                    parentId: foldersPath.length ?
                        foldersPath[foldersPath.length - 1].id : null,
                    parentType: foldersPath.length ? folderParentType.folder : folderParentType.root
                });

                break;
        }

        props.toggleModal();
    };

    const editFolder = () => {
        let foldersPath;
        switch(props.position) {
            case 'scheme':
                foldersPath = props.schemesFoldersPath;

                props.updateProductionSchemeFolder({
                    id: props.currentFolderId,
                    name: props.formValues.name,
                    parentId: foldersPath.length ?
                        foldersPath[foldersPath.length - 1].id : props.currentCandidateId,
                    parentType: foldersPath.length ? folderParentType.folder : folderParentType.finalCandidate,
                });
                break;
            case 'candidate':
                foldersPath = props.candidateFolderPath;

                props.updateFolder({
                    id: props.currentFolderId,
                    parentId: foldersPath.length ?
                        foldersPath[foldersPath.length - 1].id : props.currentProjectId,
                    parentType: foldersPath.length ? folderParentType.folder : folderParentType.project,
                    name: props.formValues.name
                });
                break;
            default:
                foldersPath = props.foldersPath;

                props.updateFolder({
                    id: props.currentFolderId,
                    parentId: foldersPath.length ?
                        foldersPath[foldersPath.length - 1].id : null,
                    parentType: foldersPath.length ? folderParentType.folder : folderParentType.root,
                    name: props.formValues.name
                });
                break;
        }

        props.toggleModal();
    };

    return (
        <React.Fragment>
            <Dialog paranjaClose={false} close={props.toggleModal}>
                <h3>{(props.isEditMode ? 'Редактировать ' : 'Создать ') + 'папку'}</h3>
                <hr/>
                <Form onSubmit={handleSubmit}>
                    <label className={'labelToTop'}>Название<i className='required-red' />
                        <Field
                            name={'name'}
                            component={BcdInputWrapper}
                            placeholder={'Введите название'}
                            validate={[FieldValidators.required]}/>
                    </label>

                    <StyledButton
                        size="L"
                        text={props.isEditMode ? 'Изменить' : 'Создать'}
                        view="primary"
                        type={'submit'}
                        disabled={!props.formValid || !props.formDirty}
                    />

                    <StyledButton
                        size="L"
                        text='Отмена'
                        onAction={props.toggleModal}
                    />
                </Form>
            </Dialog>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    const {resource, modules} = state;
    return {
        folders: itemsToArraySelector(resource.folders),
        foldersSchemes: itemsToArraySelector(resource.foldersSchemes),
        foldersPath: modules.folder.path,
        schemesFoldersPath: modules.folder.schemesPath,
        candidateFolderPath: modules.folder.candidatesPath,
        currentFolderId: modules.folder.id,
        isEditMode: modules.folder.isEdit,
        formValues: getFormValues(FOLDER_EDIT_FORM_NAME)(state),
        formValid: isValid(FOLDER_EDIT_FORM_NAME)(state),
        formDirty: isDirty(FOLDER_EDIT_FORM_NAME)(state),
        currentProjectId: modules.project.id,
        currentCandidateId: modules.candidate.candidate ? modules.candidate.candidate.Id : null
    };
};

FolderModal = reduxForm({
    form: FOLDER_EDIT_FORM_NAME,
    formInitialValues,
    destroyOnUnmount: true
})(FolderModal);

FolderModal = connect(
    mapStateToProps,
    (dispatch) => ({
        getFolders: (params) => dispatch(folderResourceActions.list.request({params})),
        createFolder: (data) => dispatch(folderResourceActions.create.request({data})),
        createProductionSchemeFolder: (data) => dispatch(folderSchemesResourceActions.create.request({data})),
        updateProductionSchemeFolder: (data) => dispatch(folderSchemesResourceActions.update.request({data})),
        updateFolder: (data) => dispatch(folderResourceActions.update.request({data})),
        updateForm: (value) => dispatch(change(FOLDER_EDIT_FORM_NAME, 'name', value, false))
    })
)(FolderModal);

export default FolderModal;