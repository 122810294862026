// @flow
import Textarea from '@biocad/bcd-front-ui/controls/Textarea';
import {
  FormFieldLayout
} from '../FormFieldLayout';

interface IBcdTextAreaWrapperProps {
  className?: string;
  valid?: boolean,
  disabled?: boolean,
  input?: any,
  meta?: any,
  useFormFieldLayout: boolean,
  hint?: string,
  wrapperClassName?: string,
  wrapperStyle?: any,
  label?: string,
  required?: boolean,
}

const defaultBcdTextAreaWrapperProps: IBcdTextAreaWrapperProps = {
  useFormFieldLayout: false,
};

export const BcdTextAreaWrapper = (props: IBcdTextAreaWrapperProps = defaultBcdTextAreaWrapperProps) => {
  const formFieldLayoutProps = {
    style: props.wrapperStyle,
    className: props.wrapperClassName,
    invalidMessage: props.input.error,
    valid: props.input.valid,
    hint: props.hint,
    label: props.label,
    required: props.required,
  };
  const renderTextarea = () => (
    <Textarea
      className={props.className}
      disabled={props.input.disabled || props.disabled}
      valid={props.input.valid}
      {...props.input}
    />
  );
  return props.useFormFieldLayout ? (
    <FormFieldLayout {...formFieldLayoutProps}>
      { renderTextarea() }
    </FormFieldLayout>
  ) : renderTextarea();
};
