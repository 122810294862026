// @flow

import type {IStageIndicator} from './i-stage-indicator';
import type { IIndicator, IIndicatorProcessPoint,
    IIndicatorUnit } from '../../indicators/models';
import { IndicatorProcessPoint, IndicatorUnit } from '../../indicators/models';
import { Indicator } from '../../indicators/models';
import { Model, BasicModel } from 'objectmodel';

export const StageIndicatorKeyNames =
{
    Id                 : 'Id',
    IndicatorId        : 'IndicatorId',
    Indicator          : 'Indicator',
    IndicatorUnitId    : 'IndicatorUnitId',
    IndicatorUnit      : 'IndicatorUnit',
    Norm               : 'Norm',
    OrderPriority      : 'OrderPriority',
    ProcessPointId     : 'ProcessPointId',
    ProcessPoint       : 'ProcessPoint',
    Comment            : 'Comment',
    IsDeleted          : 'IsDeleted',
    IndicatorType      : 'IndicatorType',
    StageId            : 'StageId',
    PurificationIndex  : 'PurificationIndex',
};

export const StageIndicator: (IStageIndicator)=>IStageIndicator = Model({
    [StageIndicatorKeyNames.Id]                 : [Number],
    [StageIndicatorKeyNames.IndicatorId]        : [Number],
    [StageIndicatorKeyNames.Indicator]          : [Indicator],
    [StageIndicatorKeyNames.IndicatorUnitId]    : [Number],
    [StageIndicatorKeyNames.IndicatorUnit]      : [IndicatorUnit],
    [StageIndicatorKeyNames.Norm]               : [String],
    [StageIndicatorKeyNames.OrderPriority]      : [Number],
    [StageIndicatorKeyNames.Comment]            : [String],
    [StageIndicatorKeyNames.IsDeleted]          : Boolean,
    [StageIndicatorKeyNames.ProcessPointId]     : [Number],
    [StageIndicatorKeyNames.ProcessPoint]       : [IndicatorProcessPoint],
    [StageIndicatorKeyNames.StageId]            : [Number],
    [StageIndicatorKeyNames.PurificationIndex]  : [Number],
})
.defaultTo({
    [StageIndicatorKeyNames.IndicatorId]        : null,
    [StageIndicatorKeyNames.IndicatorUnitId]    : null,
    [StageIndicatorKeyNames.Norm]               : '',
    [StageIndicatorKeyNames.OrderPriority]      : null,
    [StageIndicatorKeyNames.Comment]            : '',
    [StageIndicatorKeyNames.IsDeleted]          : false,
    [StageIndicatorKeyNames.ProcessPointId]     : null,
    [StageIndicatorKeyNames.StageId]            : null,
    [StageIndicatorKeyNames.Indicator]          : null,
    [StageIndicatorKeyNames.IndicatorUnit]      : null,
    [StageIndicatorKeyNames.ProcessPoint]       : null,
    [StageIndicatorKeyNames.PurificationIndex]  : null,
});
