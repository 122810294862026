// @flow

import {
  CRUDStatusActions,
  resourceActions,
} from '../../shared/utils';
import { REACTIONS_MODULE_NAME } from './module';

export const reactionsResourceActions = {
  ...resourceActions(REACTIONS_MODULE_NAME),
  quickComment: CRUDStatusActions(REACTIONS_MODULE_NAME, ['QUICK_COMMENT']),
  selectFolder:CRUDStatusActions(REACTIONS_MODULE_NAME, ['SELECT_FOLDER']),
  getNewName: CRUDStatusActions(REACTIONS_MODULE_NAME, ['NAME']),
  completeReaction: CRUDStatusActions(REACTIONS_MODULE_NAME, ['COMPLETE']),
  getNewPurificationName: CRUDStatusActions(REACTIONS_MODULE_NAME, ['NAME_PURIFICATION']),
  getNewNestedPurificationName: CRUDStatusActions(REACTIONS_MODULE_NAME, ['NAME_NESTED_PURIFICATION']),
  createExtraNestedPurification: CRUDStatusActions(REACTIONS_MODULE_NAME, ['CREATE_EXTRA_PURIFICATION']),
  getTemplateReactions: CRUDStatusActions(REACTIONS_MODULE_NAME, ['GET_TEMPLATE']),
};

export const reactionRepkaTaskListActions = CRUDStatusActions(REACTIONS_MODULE_NAME, ['REPKA_TASK_LIST']);
export const reactionEventsHistoryResourceActions = resourceActions('EVENTS_HISTORY');
export const reactionAttachmentsActions = resourceActions('REACTION_ATTACHMENTS');
