
import { moduleAction, CRUDStatusActions } from '../../shared/utils';
import { AnalyticsSmallMolecule } from '../models';

const ANALYTICS_MODULE_ROOT_NAME = 'ANALYTICS_MODULE';

export const AnalyticsModuleActions = {
  updateMoleculeApi: CRUDStatusActions(ANALYTICS_MODULE_ROOT_NAME, 'UPDATE_SMALL_MOLECULES_API'),
  createMoleculeApi: CRUDStatusActions(ANALYTICS_MODULE_ROOT_NAME, 'CREATE_SMALL_MOLECULES_API'),
  deleteMoleculeApi: CRUDStatusActions(ANALYTICS_MODULE_ROOT_NAME, 'DELETE_SMALL_MOLECULES_API'),
  saveAnalyticsApi: CRUDStatusActions(ANALYTICS_MODULE_ROOT_NAME, 'SAVE_ANALYTICS_API'),

  setEntity: moduleAction(
    ANALYTICS_MODULE_ROOT_NAME,
    ['SET_ENTITY'],
    ({entity}) => ({entity})),

  setMolecules: moduleAction(
    ANALYTICS_MODULE_ROOT_NAME,
    ['SET_MOLECULES'],
    ({molecules}) => ({molecules})),

  createCurrentMolecule: moduleAction(
    ANALYTICS_MODULE_ROOT_NAME,
    ['CREATE_CURRENT_MOLECULE'],
    () => {}),

  setCurrentMolecule: moduleAction(
    ANALYTICS_MODULE_ROOT_NAME,
    ['SET_CURRENT_MOLECULE'],
    ({molecule, index}: {molecule: AnalyticsSmallMolecule}) => ({ molecule, index })),

  clearCurrentMolecule: moduleAction(
    ANALYTICS_MODULE_ROOT_NAME,
    ['CLEAR_CURRENT_MOLECULE'],
    () => {}),

  saveCurrentMolecule: moduleAction(
    ANALYTICS_MODULE_ROOT_NAME,
    ['SAVE_CURRENT_MOLECULE'],
    ({molecule}) => ({molecule})),
    
  deleteMolecule: moduleAction(
    ANALYTICS_MODULE_ROOT_NAME,
    ['DELETE_MOLECULE'],
    ({molecule, index}) => ({ molecule, index })),

  newTest: moduleAction(
    ANALYTICS_MODULE_ROOT_NAME,
    ['ADD_TEST'],
    ({ formValues }) => ({ formValues })),
  deleteTest: moduleAction(
    ANALYTICS_MODULE_ROOT_NAME,
    ['DELETE_TEST'],
    ({formValues, index}) => ({ formValues, index })),

  destroyAnalytics: moduleAction(
    ANALYTICS_MODULE_ROOT_NAME,
    ['DESTROY_ANALYTICS'],
    () => {}),
  completeChanges: moduleAction(
    ANALYTICS_MODULE_ROOT_NAME,
    ['COMPLETE_CHANGES'],
    () => {}),
    
  actualizeReactionInfo: moduleAction(
    ANALYTICS_MODULE_ROOT_NAME,
    ['REACTION_INFO', 'ACTUALIZE'],
    ({ reactionId, productId }) => ({ reactionId, productId })
  )

};