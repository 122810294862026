// @flow

import React, {
  useEffect,
  useState,
} from 'react';
import Button from '@biocad/bcd-front-ui/controls/Button';
import {
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import dateTimeFormat from '@biocad/bcd-front-ui/utils/formatters/datetime';
import {
  reactionCatalogResourceActions,
  reactionModuleActions,
  reactionsResourceActions,
} from '../../../actions';
import {connect} from 'react-redux';
import type {
  ICatalogReactionRequestParams,
  ICatalogReaction, IReactionQuickComment,
} from '../../../models';
import {
  CatalogReactionListStyled,
  ListHeaderStyled,
} from './style';
import {itemsToArraySelector} from '../../../../shared/utils/selectors/resource-selectors';
import {
  LayoutBlock,
} from '../../../../shared/components/LayoutBlock';
import {TitleWithCounter} from '../../../../shared/components/TitleWithCounter';
import type {IPagination} from '../../../../shared/models';
import {initialReactionSearchParams} from '../../../reducers';
import {PaginationStyled} from '../../../../shared/components/Paginator/style';
import {ReactionTypeIcon} from '../../../../shared/components';
import CommentWrapper from '../../../../shared/components/CommentWrapper';
import {GridTable, GridTableCol} from '../../../../shared/components/GridTable';
import type {IRootStore} from '../../../../app/reducers';
import {_noop} from '../../../../shared/utils/common';
import {setGlobalLoader} from '../../../../shared/actions/loading';
import {withRouter} from 'react-router-dom';
import {ReactionPurification} from '../../ReactionPurification';
import { Link } from 'react-router-dom';
import {userCRUDPermissionsSelector} from '../../../../account/reducers/users-modules-reducer';
import {RolesMap} from '../../../../account/models/roles-map';

interface IReactionCatalogProps {
  listReactions?: (params: ICatalogReactionRequestParams) => void,
  setQuickComment?: (number, IReactionQuickComment) => null,
  updateSearchParams?: typeof Function,
  clearReactions?: () => void,
  setLoader?: (boolean) => void,
  reactions?: ICatalogReaction[],
  pagination?: IPagination,
  searchParams?: ICatalogReactionRequestParams,
  pending?: boolean,
  quickCommentSavedSuccessful?: boolean,
  history?: {
    push: (string) => void,
  },
  userPermissions?: {},
  roles?: string[],
}

const reactionCatalogDefaultProps: IReactionCatalogProps = {
  listReactions: _noop,
  clearReactions: _noop,
  updateSearchParams: _noop,
  setQuickComment: _noop,
  setLoader: _noop,
  reactions: [],
  pagination: {
    page: 1,
    pageCount: 1,
    pageSize: null,
    total: null,
  },
  searchParams: initialReactionSearchParams,
  pending: false,
  quickCommentSavedSuccessful: null,
};

const renderReactionCatalogHeader = ({count = 0, gotoReaction}) => (
  <ListHeaderStyled>
    <TitleWithCounter
      title={'Реакции'}
      count={count}
    />
    {
        gotoReaction === _noop ? '' :
        <Button
            text={'Добавить архивную реакцию'}
            onAction={() => typeof gotoReaction === 'function' && gotoReaction()}
            size={'L'}
            icon={faPlus}
        />
    }
  </ListHeaderStyled>
);

let List = (props: IReactionCatalogProps = reactionCatalogDefaultProps) => {
  // States
  const
    [closeCommentDialogFn, setCloseCommentDialogFn] = useState(null)
  ;

  // Effects
  const _loadListOnSearchParamsUpdate = () => {
    if (props.searchParams.preventSearchRequest !== true) {
      props.clearReactions();
      const params = {...props.searchParams};
      if ('preventSearchRequest' in params) {
        delete params.preventSearchRequest;
      }
      props.listReactions(params);
    }
  };
  useEffect(_loadListOnSearchParamsUpdate, [props.searchParams]);

  const _loadListOnCommentSaved = () => {
    if (props.quickCommentSavedSuccessful) {
      props.listReactions(props.searchParams);
    }
    typeof closeCommentDialogFn === 'function' && closeCommentDialogFn();
    setCloseCommentDialogFn(null);
  };
  useEffect(_loadListOnCommentSaved, [props.quickCommentSavedSuccessful]);

  const _onUnmount = () => () => {
    props.clearReactions();
  };
  useEffect(_onUnmount, []);

  const _setLoader = () => {
    props.setLoader(props.pending);
  };
  useEffect(_setLoader, [props.pending]);

  // Handlers
  const
    paginate = (params) => {
      props.clearReactions();
      props.updateSearchParams({Skip: params.skip, Limit: params.limit});
    },
    saveComment = ({itemId, comment, close}) => {
      const data: IReactionQuickComment = {
        QuickComment: comment,
        ReactionId: itemId,
      };
      props.setQuickComment(itemId, data);
      setCloseCommentDialogFn(close);
    },
    gotoReaction = (reactionId: number) => {
      if (reactionId) {
          props.history.push(`/reactions/${reactionId}`);
          return;
      }
        props.history.push('/reactions/add?isArchived=true');
    }
  ;
  return (
    <LayoutBlock
      header={renderReactionCatalogHeader({
        count: props.pagination.total,
        gotoReaction: props.userPermissions.archivedReactions.CREATE ? gotoReaction : _noop
      })}
    >

      <CatalogReactionListStyled
        items={props.reactions.sort((r1, r2) => new Date(r2.CreationDate) - new Date(r1.CreationDate))}
        hover={true}
        rowHeight={'115px'}
        colsTemplate={'1.25fr 1fr repeat(2, minmax(120px, auto)) repeat(3, 1fr) 80px'}
        as={GridTable}
      >
        <GridTableCol
          title={'Название'}
          render={(args: { item: ICatalogReaction }) => (
            <span className={'title-col'}>
              <ReactionTypeIcon reaction={args.item}/>
              <Link to={`/reactions/${args.item.Id}`}>
                {args.item.Name}
                {args.item.AdditionalName && <><br /><span className={'additional-name'}>{args.item.AdditionalName}</span></>}
              </Link>
            </span>
          )}
        />
        <GridTableCol
          title={'Дата создания'}
          render={(args: { item: ICatalogReaction }) => (
            <span>{dateTimeFormat(new Date(args.item.CreationDate), {format: 'compact', time: true})}</span>
          )}
        />
        <GridTableCol
          title={'Реактанты'}
          render={(args: { item: ICatalogReaction }) => (
            <span className={'reagent-thumbnail-holder'}>{
              args.item.Reagents.map((reagent, idx: number) => (
                <ReactionPurification
                  key={idx}
                  compound={reagent}
                  className={'reagent-thumbnail'}
                />
              ))
            }</span>
          )}
        />
        <GridTableCol
          title={'Продукты'}
          render={(args: { item: ICatalogReaction }) => (
            <span className={'reagent-thumbnail-holder'}>{
              args.item.Products.sort((a,b)=> {return a.FirstOrderItem?-1:1}).map((reagent, idx: number) => (
                <ReactionPurification
                  key={idx}
                  compound={reagent}
                  className={'reagent-thumbnail'}
                />
              ))
            }</span>
          )}
        />
        <GridTableCol
          title={'Теор. выход, г'}
          align={'right'}
          render={(args: { item: ICatalogReaction }) => (
            <span>{args.item.TheoreticalYield ? (+args.item.TheoreticalYield).toFixed(3) : <>&ndash;</>}</span>
          )}
        />
        <GridTableCol
          title={'Эксп. выход, г'}
          align={'right'}
          render={(args: { item: ICatalogReaction }) => (
            <span>{args.item.ExperimentalYield ? (+args.item.ExperimentalYield).toFixed(3) : <>&ndash;</>}</span>
          )}
        />
        <GridTableCol
          title={'Эксп. выход, %'}
          align={'right'}
          render={(args: { item: ICatalogReaction }) => (
            <span>{args.item.PercentYield ? (args.item.PercentYield * 100).toFixed(3) : <>&ndash;</>}</span>
          )}
        />
        <GridTableCol
          render={(args: { item: ICatalogReaction }) => (
            <CommentWrapper
              item={args.item}
              saveComment={saveComment}
              firstStaticField={{
                title: 'Наименование',
                value: args.item.Name
              }}
              preventCloseOnSave={true}
              readonly={(!props.userPermissions.reactionMiniComment.UPDATE) || !(props.roles.includes(RolesMap.User) && !args.item.IsCompleted)}
            />
          )}
        />
      </CatalogReactionListStyled>
      <PaginationStyled
        {...props.pagination}
        handlePagingChange={paginate}
      />
    </LayoutBlock>
  );
};

List = connect(
    (state: IRootStore): IReactionCatalogProps => {
        const {resource, modules} = state;
        return {
            reactions: itemsToArraySelector(resource.reactionCatalog),
            pagination: modules.reaction.list.pagination,
            searchParams: modules.reaction.list.searchParams,
            pending: resource.reactionCatalog.pending,
            quickCommentSavedSuccessful: state.modules.reaction?.quickComment?.savedSuccessful,
            userPermissions: userCRUDPermissionsSelector(state),
            roles: state.global.account.roles
        };
    },
    (dispatch): IReactionCatalogProps => ({
        listReactions: (params: ICatalogReactionRequestParams) => dispatch(reactionCatalogResourceActions.list.request({params})),
        clearReactions: () => dispatch(reactionCatalogResourceActions.destroy()),
        updateSearchParams: (params) => dispatch(reactionModuleActions.catalogSearchParams.update({params})),
        setQuickComment: (id: number, data: IReactionQuickComment) => dispatch(reactionsResourceActions.quickComment.request({
            uriParams: {id},
            data
        })),
        setLoader: (isLoading) => dispatch(setGlobalLoader({isLoading})),
    })
)(List);

List = withRouter(List);

export {
  List,
};
