// @flow

import React, {
  useState,
  useEffect,
} from 'react';
import Checkbox from '@biocad/bcd-front-ui/controls/Checkbox';
import bnc from 'bnc';
import { _noop } from '../../../../shared/utils/common';
import propTypes from 'prop-types';
import { CustomizedTooltip } from '../../../../shared/components';

import './index.less';


type ReactionYields = {
  IncludeExpYield: boolean,
  IncludeIncomplete: boolean,
  IncludeTrial: boolean,
  IncludeWithoutExpYield: boolean,
}

type ParamItem = {
  color: string,
  text: string,
}

const paramsMap: {[key: string]: ParamItem} = {
  IncludeExpYield: {
    color: '--hasYieldColor',
    text: 'Экспериментальный выход  > 0 '
  },
  IncludeIncomplete: {
    color: '--uncompletedColor',
    text: 'Незавершенная реакция'
  },
  IncludeTrial: {
    color: '--trialColor',
    text: 'Пробная реакция'
  },
  IncludeWithoutExpYield: {
    color: '--noYieldColor',
    text: 'Экспериментальный выход = 0'
  },
};

const defaultParams: ReactionYields = {
  IncludeExpYield: true,
  IncludeIncomplete: true,
  IncludeTrial: true,
  IncludeWithoutExpYield: true,
};

const
  BNCBlock = new bnc('b-ReactionsFilter'),
  BNCTooltip = BNCBlock.el('tooltip'),
  BNCItem = BNCBlock.el('item'),
  BNCTitle = BNCBlock.el('title'),
  BNCItemShowAll = BNCItem.mod('showAll'),
  BNCIndicator = BNCBlock.el('indicator')
;

interface IFilterTooltipProps {
  className?: string,
  onItemClick: (ReactionYields)=>void,
  params: ReactionYields,
}

const defaultProps: IFilterTooltipProps = {
  className: '',
  onItemClick: _noop,
};

let ReactionsFilter = (props: IFilterTooltipProps = defaultProps) => {
  // State
  const
    [params, setParams] = useState(props.params)
  ;

  // Effects
  const _emitParamsOnChange = () => {
    if (params !== props.params) props.onItemClick(params);
  };
  useEffect(_emitParamsOnChange, [params]);

  // Methods
  const
    allShown = () => !Object.values(props.params).filter(value => !value).length
  ;

  // Handlers
  const
    updateParams = (name: string) => {
      setParams(prev => ({
        ...prev,
        [name]: !prev[name]
      }));
    },
    showAll = () => {
      if (allShown()) {
        setParams(prev => Object.entries(prev)
          .map(([key, value]) => [key, !value])
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}));
        return;
      }
      setParams(defaultParams);
    }
  ;

  return (
    <CustomizedTooltip
      className={BNCBlock.toString()}
      buttonClassName={'fo-filter'}
      tooltipClassName={BNCTooltip.toString()}
      title={'Фильтр'}
    >
      <Checkbox className={BNCItem + BNCItemShowAll}
                onChange={showAll}
                checked={allShown()}
      >
        <span className={BNCTitle}>Показать все</span>
      </Checkbox>
      {
        Object.entries(paramsMap).map(([key, value]: [string, ParamItem]) => (
          <Checkbox className={BNCItem}
                    onChange={() => updateParams(key)}
                    checked={props.params[key]}
                    key={key}
          >
            <span className={BNCTitle}>{ value.text }</span>
            <i className={BNCIndicator} style={{ backgroundColor: `var(${value.color})` }} />
          </Checkbox>
        ))
      }
    </CustomizedTooltip>
  );
};

ReactionsFilter.propTypes = {
  params: propTypes.shape({
    IncludeExpYield: propTypes.bool.isRequired,
    IncludeIncomplete: propTypes.bool.isRequired,
    IncludeTrial: propTypes.bool.isRequired,
    IncludeWithoutExpYield: propTypes.bool.isRequired,
  }),
  onItemClick: propTypes.func.isRequired,
};

export {
  ReactionsFilter,
};
