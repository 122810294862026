// @flow

import { ArrayModel, Model } from 'objectmodel';
import { Compound } from './compound';

export const Agent = Model({
  Aliases: [ArrayModel(String), null, undefined, String],
  Cas: [String],
  Compound: [Compound],
  CompoundId: [Number],
  CreationDate: [Date, String, null],
  HasJarsWithAmount: Boolean,
  Id: [Number],
  IsIgnoringSpending: Boolean,
  MolarWeight: Number,
  Name: [String],
  ShortName: [String],
  OriginalSmilesFormula: [String, null, undefined],
  IsPrecursor: [Boolean],
  IsCatalizator: [Boolean],
  AminoAcidSequence: [String, null, undefined],
  MSDAttachmentId: [Number, null],
  MSDAttachment: [{}, null],
  CanBeDeleted: [Boolean, null, undefined],
});
