// @flow
import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import Projects from './components';
import Project from './components/Project';

const ProjectsRoutes = ({match}) => {
    return (
        <Switch>
            <Route path={`${match.path}/list`} render={() => <Projects/>}/>
            <Route path={`${match.path}/:projectId`} render={(props) => <Project {...props} />}/>
        </Switch>
    );
};

export default withRouter(ProjectsRoutes);
