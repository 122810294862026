// @flow

import { ArrayModel, Model } from 'objectmodel';
import type { IReactionCompletion, IReactionCompletionYield } from './i-reaction-completion';
import { Product } from '../../products/model';

export const ReactionCompletionKeyNames = {
	Comment             : 'Comment',
	Products            : 'Products',
	ReactionId          : 'ReactionId',
	StageId             : 'StageId',
	Yields              : 'Yields'
};

export const ReactionCompletionYieldKeyNames = {
	Amount              : 'Amount',
	Comment             : 'Comment',
	LaboratoryId        : 'LaboratoryId',
	Location            : 'Location',
	MainAgentContent    : 'MainAgentContent',
	IsInUse             : 'IsInUse',
	OriginalYieldAmount : 'OriginalYieldAmount',
};

export const ReactionCompletionYield: (IReactionCompletionYield)=>IReactionCompletionYield = Model({
	[ReactionCompletionYieldKeyNames.Amount]              : [Number, null, undefined],
	[ReactionCompletionYieldKeyNames.Comment]             : [String, null, undefined],
	[ReactionCompletionYieldKeyNames.LaboratoryId]        : [Number, null, undefined],
	[ReactionCompletionYieldKeyNames.Location]            : [String, null, undefined],
	[ReactionCompletionYieldKeyNames.MainAgentContent]    : [Number, null, undefined],
	[ReactionCompletionYieldKeyNames.IsInUse]             : [Boolean, null, undefined],
	[ReactionCompletionYieldKeyNames.OriginalYieldAmount] : [Number, null, undefined]
})
.defaultTo({
	[ReactionCompletionYieldKeyNames.Amount]              : 0,
	[ReactionCompletionYieldKeyNames.MainAgentContent]    : 0,
	[ReactionCompletionYieldKeyNames.IsInUse]             : true,
});

export const ReactionCompletion: (IReactionCompletion)=>IReactionCompletion = Model({
	[ReactionCompletionKeyNames.Comment]                  : [String, null, undefined],
	[ReactionCompletionKeyNames.Products]                 : [ArrayModel([Product, null, undefined]), null, undefined],
	[ReactionCompletionKeyNames.ReactionId]               : [Number, null, undefined],
	[ReactionCompletionKeyNames.StageId]                  : [Number, null, undefined],
	[ReactionCompletionKeyNames.Yields]                   : [ArrayModel([ReactionCompletionYield, null, undefined]), null, undefined]
});
