// @flow

import type {IPagination} from '../../shared/models';
import { RolesMap } from '../models/roles-map';
import {usersResourceActions} from '../actions/users';
import { createSelector } from 'reselect';
import type {IRootStore} from '../../app/reducers';

export interface IUserModuleStore {
  selfUserId: number,
  list: {
    pagination: IPagination
  }
}

const initialPagination: IPagination = {
  page: 1,
  pageCount: 1,
  pageSize: null,
  total: null,
};

const defaultUserModuleStore = {
  selfUserId: null,
  list: {
    pagination: initialPagination
  }
};

export const userModuleReducer = (state: IUserModuleStore = defaultUserModuleStore, action: any = {}) => {
  const reducedFn = {
    [usersResourceActions.list.success]: (action) => ({
      ...state,
      list: {
        ...state.list,
        pagination: {...state.list.pagination, ...action.pagination}
      }
    }),
    [usersResourceActions.getSelf.success]: (action) => ({
      ...state,
      selfUserId: action?.result?.Id
    })
  }[action.type];
  return reducedFn ? reducedFn(action) : state;
};

type CRUDActions = {
  CREATE: ?string[],
  READ: ?string[],
  UPDATE: ?string[],
  DELETE: ?string[],
};

type TUserPermissionsByEntity = {
  [key: string]: CRUDActions,
}

type CRUDUserPermissions = {
  [key: string]: {
    CREATE: boolean,
    READ: boolean,
    UPDATE: boolean,
    DELETE: boolean,
  },
}

// Список сущностей и действий, если у действия значение null - значит его могут выполнять все, если список то только
// те роли которые есть в списке, если список пустой то вообще никто.
const USER_PERMISSIONS_MAP: TUserPermissionsByEntity = {
  'agent': {
    CREATE: [RolesMap.SuperUser, RolesMap.AgentsAdmin],
    READ: null,
    UPDATE: [RolesMap.SuperUser, RolesMap.AgentsAdmin],
    DELETE: [RolesMap.SuperUser, RolesMap.AgentsAdmin],
  },
  'agentAlias': {
    CREATE: [RolesMap.SuperUser, RolesMap.AgentsAdmin],
    READ: null,
    UPDATE: [RolesMap.SuperUser, RolesMap.AgentsAdmin],
    DELETE: [RolesMap.SuperUser, RolesMap.AgentsAdmin],
  },
  'agentMiniComment': {
    CREATE: [],
    READ: null,
    UPDATE: [RolesMap.SuperUser, RolesMap.AgentsAdmin],
    DELETE: [],
  },
  'agentAnalytics': {
    CREATE: [RolesMap.SuperUser, RolesMap.User, RolesMap.ArchiveViewer, RolesMap.AgentsAdmin, RolesMap.ProjectLead],
    READ: [RolesMap.SuperUser, RolesMap.User, RolesMap.ArchiveViewer, RolesMap.AgentsAdmin, RolesMap.ProjectLead],
    UPDATE: [RolesMap.SuperUser, RolesMap.User, RolesMap.ArchiveViewer, RolesMap.AgentsAdmin, RolesMap.ProjectLead],
    DELETE: [RolesMap.SuperUser, RolesMap.User, RolesMap.ArchiveViewer, RolesMap.AgentsAdmin, RolesMap.ProjectLead],
  },
  'agentFileList': {
    CREATE: [RolesMap.SuperUser, RolesMap.User, RolesMap.AgentsAdmin, RolesMap.ProjectLead],
    READ: null,
    UPDATE: [],
    DELETE: [RolesMap.SuperUser, RolesMap.AgentsAdmin],
  },
  'jarStock': {
    CREATE: [RolesMap.SuperUser, RolesMap.AgentsAdmin],
    READ: null,
    UPDATE: [RolesMap.SuperUser, RolesMap.AgentsAdmin],
    DELETE: [RolesMap.SuperUser, RolesMap.AgentsAdmin],
  },
  'jarStockSpendings': {
    CREATE: [RolesMap.SuperUser, RolesMap.User, RolesMap.ArchiveViewer, RolesMap.AgentsAdmin, RolesMap.ProjectLead],
    READ: null,
    UPDATE: [],
    DELETE: [],
  },
  'jarStockMiniComment': {
    CREATE: [],
    READ: null,
    UPDATE: [RolesMap.SuperUser, RolesMap.AgentsAdmin],
    DELETE: [],
  },
  'project': {
    CREATE: [RolesMap.SuperUser, RolesMap.ProjectLead],
    READ: null,
    UPDATE: [RolesMap.SuperUser, RolesMap.ProjectLead],
    DELETE: [RolesMap.SuperUser, RolesMap.ProjectLead],
  },
  'folder': {
    CREATE: [RolesMap.SuperUser, RolesMap.ProjectLead],
    READ: null,
    UPDATE: [RolesMap.SuperUser, RolesMap.ProjectLead],
    DELETE: [RolesMap.SuperUser, RolesMap.ProjectLead],
  },
  'candidate': {
    CREATE: [RolesMap.SuperUser, RolesMap.ProjectLead],
    READ: null,
    UPDATE: [RolesMap.SuperUser, RolesMap.ProjectLead],
    DELETE: [RolesMap.SuperUser, RolesMap.ProjectLead],
  },
  'candidateMiniComment': {
    CREATE: [],
    READ: null,
    UPDATE: [RolesMap.SuperUser, RolesMap.ProjectLead],
    DELETE: [],
  },
  'scheme': {
    CREATE: [RolesMap.SuperUser, RolesMap.ProjectLead],
    READ: null,
    UPDATE: [RolesMap.SuperUser, RolesMap.ProjectLead],
    DELETE: [RolesMap.SuperUser, RolesMap.ProjectLead],
  },
  'schemeMiniComment': {
    CREATE: [],
    READ: null,
    UPDATE: [RolesMap.SuperUser, RolesMap.ProjectLead],
    DELETE: [],
  },
  'stage': {
    CREATE: [RolesMap.SuperUser, RolesMap.ProjectLead],
    READ: null,
    UPDATE: [RolesMap.SuperUser, RolesMap.ProjectLead],
    DELETE: [RolesMap.SuperUser, RolesMap.ProjectLead],
  },
  'reaction': {
    CREATE: [RolesMap.SuperUser, RolesMap.User, RolesMap.AgentsAdmin, RolesMap.ProjectLead],
    READ: null,
    UPDATE: [RolesMap.SuperUser, RolesMap.User, RolesMap.AgentsAdmin, RolesMap.ProjectLead],
    DELETE: [RolesMap.SuperUser, RolesMap.User, RolesMap.AgentsAdmin, RolesMap.ProjectLead],
  },
  'reactionFolder': {
    CREATE: [RolesMap.SuperUser, RolesMap.User, RolesMap.AgentsAdmin, RolesMap.ProjectLead],
    READ: null,
    UPDATE: [RolesMap.SuperUser, RolesMap.User, RolesMap.AgentsAdmin, RolesMap.ProjectLead],
    DELETE: [RolesMap.SuperUser, RolesMap.User, RolesMap.AgentsAdmin, RolesMap.ProjectLead],
  },
  'reactionMiniComment': {
    CREATE: [],
    READ: null,
    UPDATE: [RolesMap.SuperUser, RolesMap.AgentsAdmin, RolesMap.ProjectLead],
    DELETE: [],
  },
  'reactionAnalytics': {
    CREATE: [RolesMap.SuperUser, RolesMap.User, RolesMap.AgentsAdmin, RolesMap.ProjectLead],
    READ: null,
    UPDATE: [RolesMap.SuperUser, RolesMap.User, RolesMap.AgentsAdmin, RolesMap.ProjectLead],
    DELETE: [RolesMap.SuperUser, RolesMap.User, RolesMap.AgentsAdmin, RolesMap.ProjectLead],
  },
  'archivedReactions': {
    CREATE: [RolesMap.SuperUser, RolesMap.ArchiveViewer, RolesMap.ProjectLead],
    READ: null,
    UPDATE: [RolesMap.SuperUser, RolesMap.ArchiveViewer, RolesMap.ProjectLead],
    DELETE: [RolesMap.SuperUser, RolesMap.ArchiveViewer, RolesMap.ProjectLead],
  },
  'stageIndicators': {
    CREATE: [RolesMap.SuperUser, RolesMap.Technologist],
    READ: null,
    UPDATE: [RolesMap.SuperUser, RolesMap.Technologist],
    DELETE: [RolesMap.SuperUser, RolesMap.Technologist],
  }
};

export const
    userCRUDPermissionsSelector = createSelector(
      [
          (state: IRootStore) => state.global.account.roles,
      ],
      (userRoles: string[]): CRUDUserPermissions => (
          Object.entries(USER_PERMISSIONS_MAP)
              .reduce((acc: CRUDUserPermissions, [entity, crudActions]: [string, CRUDActions]) => ({
                  ...acc,
                  [entity]: Object.entries(crudActions)
                      .reduce((permissions: {}, [actionName, rolesList]: [string, ?string[]]) => ({
                          ...permissions,
                          [actionName]: !Array.isArray(rolesList)
                                        ? true
                                        : userRoles?.some((role: string) => rolesList?.includes(role))
                      }), {})
              }), {})
      )
  ),
  getSelfSelector = createSelector(
      [
          (state: IRootStore) => state.resource.users.data,
          (state: IRootStore) => state.modules.users.selfUserId,
      ],
      (data, id) => data[id] || null,
  )
