import * as React from 'react';

interface IState
{
  error: Error;
  errorInfo: React.ErrorInfo;
}

export class ErrorBoundary extends React.Component<any, IState>
{
  constructor(props)
  {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo)
  {
    this.setState({ error, errorInfo });
  }

  render()
  {
    return (
        this.state.errorInfo
            ? <div>
              <h2>Ошибка при рендере компонента.</h2>
              <details style={{ whiteSpace: 'pre-wrap' }}>
                { this.state.error?.toString() }
                <br />
                { this.state.errorInfo.componentStack }
              </details>
            </div>
            : this.props.children
    )
  }
}
