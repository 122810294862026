// @flow
import React, {
    useState,
    useEffect,
} from 'react';
import bnc from 'bnc';
import { MarvinJSUtil } from '../../services/compound/marvinjs-util';
import type { MarvinJsSketch, MarvinJsPackage, MarvinJsImageExporter } from '../../services/compound/marvinjs-util';
import { JChemService, marvinJsEndpoints } from '../../services/compound/jChem-service';
import { calculateMolarWeight } from '../../services/compound/molar-weight-calculator';
import { ICompound } from '../../../agents/models/i-agent';
import Loader from '@biocad/bcd-front-ui/controls/Loader';
import { environment } from '../../../environment';
import { useSelector, useDispatch } from 'react-redux';
import { getToken } from '../../../account/reducers';
import type { ICanonicalizerResponse } from '../../services/compound/canonicalizer-service';
import {
  CanonicalizerResponse,
  CanonicalizerService,
} from '../../services/compound/canonicalizer-service';
import { globalErrorsActions, setGlobalLoader } from '../../actions';
import { Compound } from '../../../agents/models';
import classNames from 'classnames';

import './index.less';

interface SketchProps {
  className?: string,
  handleChange: (?ICompound, { pending: boolean, fail: boolean })=>void,
  initialMrv?: string | null,
  onSketchInit?: (sketch: MarvinJsSketch, canonicalize: ()=>void)=>void,
  style?: {[string]: string},
  useCanonicalizer?: typeof Function, // имеется ввиду колбек на успешный ответ каноникалайзера
  token?: string,
  message?: string,
  radicalMode?: boolean,
}

const
  block = new bnc('b-MarvinSketch'),
  blockHasMessage = block.mod('hasMessage'),
  isLoading = block.mod('isLoading'),
  bIframe = block.el('iframe').toString(),
  message = block.el('message')
;

const cyclobutaneTemplateJSO =
{
    structure: "<?xml version=\"1.0\" encoding=\"windows-1252\"?><cml xmlns=\"http://www.chemaxon.com\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"http://www.chemaxon.com/marvin/schema/mrvSchema_16_02_15.xsd\" version=\"ChemAxon file format v16.02.15, generated by v17.13.0\">\n" +
               "<MDocument><MChemicalStruct><molecule molID=\"m1\"><atomArray atomID=\"a1 a2 a3 a4\" elementType=\"C C C C\" x2=\"0.7699999999999998 1.858944443027283 0.7699999999999998 -0.3189444430272834\" y2=\"1.8589444430272832 0.77 -0.3189444430272832 0.77\"></atomArray><bondArray><bond id=\"b1\" atomRefs2=\"a1 a2\" order=\"1\"></bond><bond id=\"b2\" atomRefs2=\"a2 a3\" order=\"1\"></bond><bond id=\"b3\" atomRefs2=\"a3 a4\" order=\"1\"></bond><bond id=\"b4\" atomRefs2=\"a1 a4\" order=\"1\"></bond></bondArray></molecule></MChemicalStruct></MDocument>\n" +
               "</cml>",
    name: "Cyclobutane",
    icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADcAAAA3CAMAAACfBSJ0AAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAAlwSFlzAAAQ6gAAEOoBgtMKmAAAAFpQTFRFR3BMKysrKioqKioqAAAAKioqJiYmKioqKioqKSkpKioqFBQUKioqKioqKSkpKioqKysrKioqKioqKioqKioqKysrLCwsKSkpKioqKCgoKSkpKysrKysrKioqMeLGZQAAAB10Uk5TABVA9QGPFPhH05QCjZlL0Pah1oeoGFJp8RMsVDCNGuAFAAABCUlEQVRIx9XW2xKCIBAGYDRKyjykdm7f/zVDx8rDAv9yFzeyut+MLouDUn8/8jaJYh1VSRSjCGjZrdyLYX6iW6qSipqzkG1Se032IvhhQvhjA6x2ciaAcwZDXcwZCNcMghwDIM+CUB94FoBu5oU+5oF+5oSW1am31izsmQmsLQMRxkDLshLo3QVE2QL2zIB789xQdR/nj4w6+F9wJbp+5mVGW5A9M2p/kUHhZcZguGQgXDMI2jIc13eDkGdB6GIBaJzMC33MA0Mf73geLjabgSwuk4M10yoLbd5FXh9qBcLjNCg0uMkmUMImUMa+0NQyNkI5G9q/q+kgZUpbGMGGVy3yqAPoS6u/H28KThXoYLL02wAAAABJRU5ErkJggg==",
};

export const MarvinSketch = (props: SketchProps) => {

    // State
    const
      [marvinPackage: MarvinJsPackage, setMarvinPackage]                        = useState(null),
      [packageLoaded: boolean, setPackageLoaded]                                = useState(false),
      [canonicalizerResponse: ICanonicalizerResponse, setCanonicalizerResponse] = useState(CanonicalizerResponse()),
      token                                                                     = useSelector(getToken)
    ;

    // Methods
    const
      dispatch     = useDispatch(),
      getThumbnail = (imageExporter: MarvinJsImageExporter, formula: string): string => {
        const image = imageExporter.mrvToDataUrl(formula, 'image/svg');

        if (!image) {
          return null;
        }

        const base64 = btoa(unescape(encodeURIComponent(image)));
        return `data:image/svg+xml;base64,${base64}`;
      },
      canonicalize = (smiles) => {
        if (
          !props.useCanonicalizer
          || typeof props.useCanonicalizer !== 'function'
          || !smiles
        ) return;
        dispatch(setGlobalLoader({ isLoading: true }));
        CanonicalizerService.canonicalize({ params: {smiles} }, token)
          .then((response: { result: ICanonicalizerResponse }) => {
            setCanonicalizerResponse(response.result);
            if (response.result.IsValid) {
              props.useCanonicalizer();
            }
            else {
              marvinPackage?.sketcherInstance.setDisplaySettings({ atomIndicesVisible: true });
            }
          })
          .catch((error) => {
            dispatch(globalErrorsActions.set({ error }));
          })
          .finally(() => {
            dispatch(setGlobalLoader({ isLoading: false }));
          });
      }
    ;


    // Effects
    const _onMarvinInited = () => {
        if (!marvinPackage) return;
        marvinPackage.sketcherInstance.setServices(marvinJsEndpoints);
        marvinPackage.sketcherInstance.on('molchange', molChangeHandler);
        marvinPackage.sketcherInstance.addTemplate(cyclobutaneTemplateJSO);
        if (props.initialMrv) {
            marvinPackage.sketcherInstance.importStructure('mrv', props.initialMrv);
        }
        if (props.onSketchInit && typeof props.onSketchInit === 'function') {
            if (props.useCanonicalizer) {
              props.onSketchInit(marvinPackage.sketcherInstance, canonicalize);
            }
            else {
              props.onSketchInit(marvinPackage.sketcherInstance, null);
            }
        }
        setTimeout(() => setPackageLoaded(!!marvinPackage), 500);
    };
    useEffect(_onMarvinInited, [marvinPackage]);

    // это очень важно, иначе в родительском компоненте может закешироваться функция с неверной областью видимости
    const _updateCanonicalizeFnOnUseCanonicalizerFnChange = () => {
      if (props.useCanonicalizer) {
        props.onSketchInit(marvinPackage?.sketcherInstance, canonicalize);
      }
    };
    useEffect(_updateCanonicalizeFnOnUseCanonicalizerFnChange, [props.useCanonicalizer]);

    // Handlers
    const
      molChangeHandler = () => {
        props.handleChange(null, { pending: true, fail: false });
        marvinPackage.sketcherInstance.exportStructure('mrv')
          .then((mrv) => {
              const imageExporter = marvinPackage.ImageExporter;
              const thumbnail = getThumbnail(imageExporter, mrv);
              const service = new JChemService();
              return Promise.all([
                  props.radicalMode 
                    ? service.tryConvert(mrv, 'smiles')
                    : service.convert(mrv, 'smiles'),

                  service.convert(mrv, 'cxsmiles'),

                  props.radicalMode 
                    ? service.tryConvert(mrv, 'name')
                    : service.convert(mrv, 'name'),

                  service.getMolecularFormula(mrv),
                  new Promise((resolve, _) => resolve(thumbnail)),
                  new Promise((resolve, _) => resolve(mrv)),
              ]);
          })
          .then((results) => {
              const [smiles, cxsmiles, name, mf, thumbnail, mrv] = results;
              const mw = calculateMolarWeight(mf);
              const _compound = {
                  Name: name,
                  MolarWeight: mw,
                  Formula: mrv,
                  Thumbnail: thumbnail,
                  SmilesFormula: smiles,
                  CxSmilesFormula: cxsmiles,
                  MolecularFormula: mf
              };
              props.handleChange(_compound, { pending: false, fail: false });
          })
          .catch((error) => {
              props.handleChange(null, { pending: false, fail: true });
              console.error(error);
              throw error;
          });
      },
      iFrameLoadedHandler = (event) => {
        const iframe = event.target;
        if (iframe) {
            MarvinJSUtil.getPackage(iframe).then((pckg: MarvinJsPackage) => setMarvinPackage(pckg));
        }
      }
    ;

    return (
        <div className={classNames(block.toString(), props.className, {
          [isLoading]: !packageLoaded,
          [blockHasMessage]: ((!canonicalizerResponse.IsValid && canonicalizerResponse.Message) || props.message)
        })}
             style={props.style}>
          {
            !packageLoaded && <Loader />
          }
          <iframe src={environment.baseUri + 'marvinjs/editor.html'}
                  onLoad={(event) => iFrameLoadedHandler(event)}
                  className={bIframe}
                  data-toolbars="reporting"
          />
          {
            ((!canonicalizerResponse.IsValid && canonicalizerResponse.Message) || props.message) &&
            <div className={message}>{ canonicalizerResponse.Message || props.message }</div>
          }
        </div>
    );
};


