import {getEnum} from '../../../shared/utils';

export const projectStatusEnum = getEnum({
    configuring: 1,
    inWork: 2,
    finished: 3,
    closed: 4,
    paused: 5,
});

export const projectStatusTitlesEnum = getEnum({
    'Подготовка': 1,
    'Активный': 2,
    'Завершен': 3,
    'Закрыт': 4,
    'Приостановлен': 5,
});



