// @flow

import type {IReagent} from '../../reagents/model';
import {Product} from '../model';
import type {IProduct} from '../model';
import {Compound} from '../../agents/models';

export const reactantConversionToProduct = (reagent: IReagent, newProduct: IProduct) => {
  return new Product({
    Amount: reagent.Amount,
    Compound: new Compound({
      Id: 0,
      Formula: reagent.Agent?.Compound?.Formula,
      MolarWeight: reagent.Agent?.Compound?.MolarWeight,
      MolecularFormula: reagent.Agent?.Compound?.MolecularFormula,
      Name: reagent.Agent?.Compound?.Name,
      SmilesFormula: reagent.Agent?.Compound?.SmilesFormula,
      Thumbnail: reagent.Agent?.Compound?.Thumbnail,
      IsAntibody: reagent.Agent?.Compound?.IsAntibody,
      IsPolymer: reagent.Agent?.Compound?.IsPolymer,
    }),
    CompoundId: 0,
    Concentration: reagent.AgentAvailability?.Concentration,
    Density: reagent.AgentAvailability?.Density,
    Eq: reagent.Eq,
    MainAgentContent: reagent.MainAgentContent,
    Mass: reagent.Mass,
    MolarWeight: reagent.Agent?.MolarWeight,
    Name: reagent.Agent?.Name,
    ReactionId: reagent.ReactionId,
    ShortName: reagent.Agent?.ShortName,
    Volume: reagent.Volume,
    ...newProduct
  });
};
