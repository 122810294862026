/* eslint-disable no-undef */
export const environment = {
    apiUri: API_URL,
    jChemUrl: JCHEM_URL,
    oidcUrl: OIDC_URL,
    serviceId: SERVICE_ID,
    baseUri: BASE_URI,
    bimsUrl: BIMS_URL,
    useLocalLogin: USE_LOCAL_LOGIN === 'true',

};