import styled from 'styled-components';
import { bHint } from '../../../../shared/components/FormFieldLayout';

export const JarStockSpendingsDialogLayout = styled.div`
  --FormFieldLayout-margin-t: 0px;
  --FormFieldLayout-margin-b: 24px;
    
  & .form {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: baseline;
    margin-top: var(--FormFieldLayout-margin-t);

    &-table {
      margin-bottom: -1px;
      position: absolute;
      left: 0; right: 0;
      top: 0; bottom: 0;
    }

    &-field {
      flex: 2;
      margin-right: 16px;
    }
    &-button {
        flex: 1;
    }
    
    .${bHint} {
      bottom: 0;
    }
  }
  
  & .dialog-scrolling-body {
    position: relative;
    overflow: auto;
    width: 100%;
    min-height: 330px;
  }
  
  & .dialog-bottom {
    margin-top: var(--grid-spacer);
  }
`;
