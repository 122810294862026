import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import './index.less';
import {Field, reduxForm, Form, getFormValues, isValid, isDirty, change} from 'redux-form';
import {FieldValidators} from '../../../../shared/validators/field-validators';
import {BcdInputWrapper} from '../../../../shared/components/BcdInputWrapper';
// eslint-disable-next-line import/no-cycle
import ParentsTasks from '../../../../shared/components/ParentsTasks';
import DescendantTree from '../../../../shared/components/DescendantTree';
import {
    descendantsModuleActions,
    descendantsResourceActions, projectActions
} from '../../../../projects/actions';
import {
    candidateModuleActions,
    candidatesResourceActions
} from '../../../actions';
import {folderParentType, projectTypeEnum} from '../../../../projects/types/enum';
import classNames from 'classnames';
import {itemsToArraySelector} from '../../../../shared/utils/selectors/resource-selectors';
import {MolecularFormulaFormatter} from '../../../../shared/components/MolecularFormulaFormatter';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

export const CANDIDATE_EDIT_FORM_NAME = 'candidateEditFormName';

const formInitialValues: {
    code: string,
    mw: string,
    afs: string,
    mnn: string,
    folderId: string,
    parentsTasks: Array<string>,
    marvinCompound: Object
} = {
    code: '',
    mw: '',
    afs: '',
    mnn: '',
    folderId: '',
    parentsTasks: [],
    marvinCompound: null
};

const initialProps = {
    checkTask: () => null,
    formValues: null,
    candidatePath: [],
    setCurrentDescendant: () => null,
    deleteCurrentDescendant: () => null,
    currentDescendant: null,
    checkParentsTask: null,
    getDescendantsList: () => null,
    clearDescendantsResourceList: () => null,
    currentCandidate: null,
    updateCode: () => null,
    updateMw: () => null,
    updateRepkaTasks: () => null,
    updateMarvinCompound: () => null,
    updateFolderId: () => null,
    updateName: () => null,
    currentProjectType: null,
    updateParentsTasksList: () => null,
    location: {
        pathname: ''
    },
    clearDescendantsList: () => null,
    descendants: null,
    getProject: () => null,
    className: '',
};

const codeFieldPatternValidation = FieldValidators.pattern('^[A-Za-zА-Яа-я0-9.,+-/\\\\_:\'[\\])(\\s]+$');

let RightPanel = (props = initialProps) => {

    // State
    const
        [isEditMode, setIsEditMode] = useState(false)
    ;

    const candidateId = props.match?.params?.candidateId;

    // Effects
    useEffect(() => {
        props.clearDescendantsResourceList();
        const urlParams = queryString.parse(props.location.search);
        setIsEditMode(candidateId !== 'add');
        const projectId = urlParams.projectId || props.currentCandidate?.ProjectId;
        if(!props.currentProject && projectId)
        {
            props.getProject(projectId);
        }

    }, []);

    useEffect(() => {
        if (candidateId !== 'add' && props.currentCandidate)
        {
            props.updateCode(props.currentCandidate?.Code);
            props.updateMw(props.currentCandidate?.Compound?.MolarWeight);
            props.updateMnn(props.currentCandidate?.Mnn);
            props.updateAfs(props.currentCandidate?.Afs);
            props.updateFolderId(props.currentCandidate?.FolderId);
            props.updateName(props.currentCandidate?.Compound?.Name || props.currentCandidate?.Name);
        }
    }, [props.currentCandidate]);

    useEffect(() => {
        if (props.currentDescendant) {
            props.updateFolderId(props.currentDescendant.id);
        } else {
            props.updateFolderId(null);
        }
    }, [props.currentDescendant]);

    useEffect(() => {
        if (props.candidatePath.length) {
            props.setCurrentDescendant({
                id: props.candidatePath[props.candidatePath.length - 1]?.id,
                name: props.candidatePath[props.candidatePath.length - 1]?.name
            });
        } else {
            props.deleteCurrentDescendant();
        }
    }, [props.candidatePath]);

    const getDescendantsList = () => {
        props.getDescendantsList({
            parentId: props.currentCandidate?.ProjectId,
            parentType: folderParentType.project
        });
    };

    useEffect(() => {
        props.currentCandidate && props.clearDescendantsList() && getDescendantsList();
    },[props.currentCandidate]);

    const handleNameChange = (event) =>
    {
        const value = event?.target?.value;
        if (value !== null)
        {
            props.change('marvinCompound.Name', value);
        }
    };

    return (
        <div className={`candidate-right-panel ${props.className}`}>

            {
                (props.formValues?.marvinCompound?.IsAntibody || props.formValues?.marvinCompound?.IsPolymer) ?
                    <Field
                        type='text'
                        name={'name'}
                        component={BcdInputWrapper}
                        validate={[FieldValidators.required]}
                        placeholder={'Введите название'}
                        onChange={handleNameChange}
                        label={'Название'}
                        useFormFieldLayout
                        required
                    /> :
                    props.formValues && props.formValues?.marvinCompound ?
                        <div className={'title'}>
                            <MolecularFormulaFormatter
                                className={'h2'}
                                formulaStr={props.formValues?.marvinCompound?.MolecularFormula || ''}
                            />
                        </div> :
                        <div className={'title'}>-</div>
            }

            {
                (props.formValues && props.formValues.marvinCompound && !props.formValues.marvinCompound?.IsComplexCompound) &&
                <div className={'sub-title'}>
                    { props.formValues ? props.formValues.marvinCompound.Name : '' }
                </div>
            }

            <Field
                type='text'
                name={'code'}
                component={BcdInputWrapper}
                validate={[FieldValidators.required, codeFieldPatternValidation]}
                placeholder={'Введите код'}
                label={'Код'}
                useFormFieldLayout
                required
            />

            <Field
                type='text'
                name={'mw'}
                component={BcdInputWrapper}
                validate={[FieldValidators.required]}
                placeholder={'Введите молярную массу'}
                label={'MW, г/моль'}
                useFormFieldLayout
                required
            />

            <Field
                type='text'
                name={'afs'}
                component={BcdInputWrapper}
                placeholder={'Введите наименование АФС'}
                label={'Наименование АФС'}
                useFormFieldLayout
            />

            <Field
                type='text'
                name={'mnn'}
                component={BcdInputWrapper}
                placeholder={'Введите МНН'}
                label={'МНН'}
                useFormFieldLayout
            />

            {isEditMode && props.descendants && props.currentCandidate &&
            <DescendantTree
                title={'Папка'}
                isRequired={false}
                root={'Целевые молекулы'}
                value={props.currentDescendant ? props.currentDescendant.name : 'Целевые молекулы'}
                parentId={props.currentCandidate?.ProjectId}
                folderId={props.currentCandidate?.FolderId}/>
            }

            {props.currentProjectType === projectTypeEnum.innovative &&
            <ParentsTasks
                item={props.currentCandidate}
                isNewItem={!isEditMode}
                updateParentsTasksList={props.updateParentsTasksList}
                checkTask={props.checkTask}
                checkParentTaskStatus={props.checkParentsTask}
                checkPending={props.checkParentsTaskPending}
            />}

        </div>
    );

};

RightPanel = reduxForm({
    form: CANDIDATE_EDIT_FORM_NAME,
    initialValues: formInitialValues,
    destroyOnUnmount: true
})(RightPanel);

const mapStateToProps = (state) => {
    const {modules, resource} = state;
    return {
        formValues: getFormValues(CANDIDATE_EDIT_FORM_NAME)(state),
        candidatePath: modules.folder.candidatesPath,
        currentDescendant: modules.descendants.currentDescendant,
        checkParentsTask: modules.candidate.checkParentsTask,
        checkParentsTaskPending: !modules.candidate.checkParentsTask.pending && modules.candidate.checkParentsTask.response,
        currentCandidate: modules.candidate.candidate,
        currentProjectType: modules.project?.currentProject?.Type,
        descendants: resource.descendants
    };
};

RightPanel = withRouter(connect(
    mapStateToProps,
    (dispatch) => ({
        checkTask: (repkaTaskId) => dispatch(candidatesResourceActions.checkRepkaTaskExists.request({params: repkaTaskId})),
        updateParentsTasksList: (value) => dispatch(change(CANDIDATE_EDIT_FORM_NAME, 'parentsTasks', value, false)),
        setCurrentDescendant: ({id, name}) => dispatch(descendantsModuleActions.setCurrentDescendant({id, name})),
        deleteCurrentDescendant: () => dispatch(descendantsModuleActions.deleteCurrentDescendant()),
        getDescendantsList: (params) => dispatch(descendantsResourceActions.list.request({params})),
        clearDescendantsResourceList: () => dispatch(descendantsResourceActions.destroy()),
        updateCode: (value) => dispatch(change(CANDIDATE_EDIT_FORM_NAME, 'code', value, false)),
        updateMw: (value) => dispatch(change(CANDIDATE_EDIT_FORM_NAME, 'mw', value, false)),
        updateMnn: (value) => dispatch(change(CANDIDATE_EDIT_FORM_NAME, 'mnn', value, false)),
        updateAfs: (value) => dispatch(change(CANDIDATE_EDIT_FORM_NAME, 'afs', value, false)),
        updateRepkaTasks: (value) => dispatch(change(CANDIDATE_EDIT_FORM_NAME, 'parentsTasks', value, false)),
        updateFolderId: (value) => dispatch(change(CANDIDATE_EDIT_FORM_NAME, 'folderId', value, false)),
        updateName: (value) => dispatch(change(CANDIDATE_EDIT_FORM_NAME, 'name', value, false)),
        clearDescendantsList: () => dispatch(descendantsModuleActions.clearDescendantsList()),
        getProject: (id: number) => dispatch(projectActions.get.request({uriParams: {id}}))
    })
)(RightPanel));

export default RightPanel;
