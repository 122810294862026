import { fromEvent, BehaviorSubject }   from 'rxjs';
import mixBehavior                      from '../utils/mixBehavior';

const STORE_KEY      = 'LocalSettings';
const ReadWorkspace  = (saved = globalThis.localStorage.getItem( STORE_KEY )) => saved ? JSON.parse( saved ) : {};
const LocalSettings  = new BehaviorSubject( ReadWorkspace() );
const UpdateSettings = ({key}) => (key === STORE_KEY) && LocalSettings.next( ReadWorkspace() );

LocalSettings.mix    = $ => mixBehavior(LocalSettings, $);

LocalSettings.subscribe(data => globalThis.localStorage.setItem(STORE_KEY, JSON.stringify(data)));

fromEvent( window, 'storage' ).subscribe( UpdateSettings );

export default LocalSettings;
