// @flow

import React, {
  useState,
  useRef,
  useEffect,
} from 'react';
import { faCalendar }  from '@fortawesome/free-solid-svg-icons/faCalendar';
import propTypes       from 'prop-types';
import DayPicker       from 'react-day-picker';
import type {
  DayPickerProps,
  DayModifiers,
}    from 'react-day-picker';
import type {
  IFieldInputProps,
  IFieldMetaProps,
} from '../../models';
import 'react-day-picker/lib/style.css';
import '@biocad/bcd-front-ui/controls/Datepicker/index.less';
import Datepicker from '@biocad/bcd-front-ui/controls/Datepicker';
import localeRu from '@biocad/bcd-front-ui/controls/Datepicker/ru';
import Tooltip from '@biocad/bcd-front-ui/layout/Tooltip';
import Input from '@biocad/bcd-front-ui/controls/Input';
import classNames from 'classnames';


interface IDatePickerProps {
  date: Date,
  input?: IFieldInputProps,
  meta?: IFieldMetaProps,
  dayPickerProps?: DayPickerProps,
  className?: string,
  placeholder?: string,
  disabled?: boolean,
}

const defaultDatePickerProps: IDatePickerProps = {
  date: null,
  placeholder: localeRu.placeholder,
  disabled: false,
};

export const DatePickerInput = (props: IDatePickerProps = defaultDatePickerProps) => {

  const currentDate = new Date();

  // States
  const
    [date, setDate]: [Date, typeof Function] = useState(null),
    [opened, setOpened]: [boolean, typeof Function] = useState(false)
  ;

  // Refs
  const
    bcdInputRef: { current: ?Input } = useRef(),
    wrapperRef: { current: ?HTMLDivElement } = useRef();

  // Effects
  const _watchDateState = () => {
    if (date === '') {
      setDate(null);
      props.input.onChange(null);
    } else if (date && props.input && props.input.onChange) {
      props.input.onChange(date);
    }
  };
  useEffect(_watchDateState, [date]);

  const _updateInputElValue = () => {
    if (bcdInputRef && bcdInputRef.current) {
      bcdInputRef.current.setValue(formatInputValue(props.input.value));
      if(props.input.value && !(props.input.value instanceof Date)) {
        const _date = new Date(props.input.value);
        setDate(prev => prev !== _date ? _date : prev);
      }
      else {
        setDate(prev => prev !== props.input.value ? props.input.value : prev);
      }
    }
  };
  useEffect(_updateInputElValue, [props.input.value]);

  // Methods
  const
    getNativeInput = (): HTMLInputElement =>
      bcdInputRef?.current?.input?.current
        ? bcdInputRef.current.input.current
        : null,

    formatInputValue = (val: Date): string =>
      val ? (new Date(val)).toLocaleDateString(localeRu.locale) : '',

    getDayPickerProps = () => ({
      fixedWeeks: true,
      modifiers: { weekend: { daysOfWeek: [0,6] } },
      ...localeRu,
      ...props.dayPickerProps,
    });

  // Handlers
  const
    onDayClick = (value: Date, modifiers: DayModifiers): void => {
      if ('disabled' in modifiers && modifiers['disabled'] === true) {
        return;
      }
      setOpened(false);
      const _inputNative = getNativeInput();
      _inputNative && _inputNative.blur();
      setDate(value);
      if (bcdInputRef.current) {
        bcdInputRef.current.setValue(formatInputValue(value));
      }
    },

    onInputBlur = (): void => {      
      if (props.input && props.input.onBlur) {
        props.input.onBlur(date);
      }
    },

    onInputFocus = (): void => {
      if (props.input && props.input.onFocus) {
        props.input.onFocus(date);
      }
      setOpened(true);
    },

    onInputChange = () => {
      const
        _inputNative = getNativeInput(),
        value = _inputNative.value.trim();
      if (value) {
        const values = value.match( localeRu.regexp );
        if (values) {
          const
            [,d,m,y] = values,
            _date    = new Date( y, m-1, d );
          if (props.date) {
            setDate(props.date);
          }
          else {
            setDate(_date);
          }
        }
      } else {
        setDate(null);
      }
    };

    const onInputCut = () => {
      document.execCommand('copy');
      setDate('');
      bcdInputRef?.current.setValue('');
      const _inputNative = getNativeInput();
      _inputNative && _inputNative.blur();
      setOpened(false);
    };


  const renderTooltip = (): React.Children => (
    <Tooltip
      className={ Datepicker.block.el('tooltip') }
      onClose={() => setOpened(false)}
      align={ 'left' }
      transparent
      notail
    >
      <div ref={wrapperRef}>
        <DayPicker
          month={ date || currentDate }
          selectedDays={[ date ]}
          onDayClick={onDayClick}
          { ...getDayPickerProps() }
        />
      </div>
    </Tooltip>
  );

  return (
    <div
      className={classNames(Datepicker.block.toString(), props.className)}
    >
      <Input.Icon
        type          = {'text'}
        ref           = {bcdInputRef}
        onChange      = {onInputChange}
        onKeyPress    = {onInputChange}
        onCut         = {onInputCut}
        onFocus       = {onInputFocus}
        onBlur        = {onInputBlur}
        placeholder   = {props.placeholder}
        icon          = {faCalendar}
        defaultValue  = {props.date ? formatInputValue(props.date) : ''}
        disabled      = {props.disabled}
        { ...(props.meta && props.meta.valid === false ? { valid: props.meta.valid } : null) }
      />
      {
        !!opened &&
        renderTooltip()
      }
    </div>
  );
};
