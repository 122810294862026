import React from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import Button from '@biocad/bcd-front-ui/controls/Button';
import classNames from 'classnames';
import { Object } from 'core-js';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Field, FieldArray, Form, getFormValues, reduxForm, } from 'redux-form';
import { loadBimsDictionaries } from '../../../dictionary/components/bimsLoaderHOC';
import { ISmType, ITestClasses } from '../../../dictionary/models';
import { ButtonsContainer, PageHeaderWrap } from '../../../projects/components/ProjectsTable/style';
import {
  BcdInputWrapper,
  FormFieldLayout,
  TitleWithCounter,
  BcdCheckboxWrapper,
  BcdSuggestWrapper,
} from '../../../shared/components';
import { itemsToArraySelector } from '../../../shared/utils/selectors/resource-selectors';
import { FieldValidators } from '../../../shared/validators/field-validators';
import { AnalyticsModuleActions } from '../../actions';
import { AnalyticsSmallMoleculeKeys, AnalyticsSmallMoleculeTitles, AnalyticsTestTitles, smEntityTypes } from '../../models';
import gridStyle from '../table.m.less';
import css from './edit.m.less';
import { renderTestArray } from './renderTestArray';
import { ResizableAreaFieldWrapper } from '../../../shared/components/ResizableArea/ResizableAreaFieldWrapper';
import {smTypesSelector, testClassesSelector} from '../../../dictionary/reducers';



const smTypesToDropdownOptions = (types: ISmType[]) => {
  return types.map(x => ({
    label: x?.title,
    value: x?.id,
  }));
};

const testClassToDropdownObjects = (classes: ITestClasses[]) => {
  return classes.map(x => ({
    label: x?.title,
    value: x?.id,
  }));
};

let SmallMoleculeEdit = ({
  isReaction,
  sampleTypeFieldValue,
  currentMolecule,
  smTypes,
  testClasses,
  addNewTestToMolecule,
  deleteTestFromMolecule,
  formValues
}) => {

  const addNewTest = () => addNewTestToMolecule(formValues);
  
  const smTypesDropdownOptions = smTypes ? smTypesToDropdownOptions(smTypes) : [];
  const testClassDropdownObjects = testClasses ? testClassToDropdownObjects(testClasses) : [];

  const submitForm = (event) => {
    event.preventDefault();
  };

  
  const deleteTestHandler = (index) => {
    deleteTestFromMolecule(formValues, index);
  };

  const renderTestHeader = ({count}) => (
    <PageHeaderWrap>
        <TitleWithCounter title={'Анализы'} count={count}/>
        <ButtonsContainer>
            <Button
              text={'Добавить анализ'}
              onAction={addNewTest}
              size={'L'}
              icon={faPlus}
            />
        </ButtonsContainer>
    </PageHeaderWrap>
  );

  const renderAnalyticTestsSection = () => {
    const testValues = currentMolecule && currentMolecule.AnalyticTests && Object.values(currentMolecule.AnalyticTests);
    return (
      <>
        { renderTestHeader({count: testValues?.filter(x => !x.IsDeleted).length}) }

        <div className={classNames(gridStyle.grid, css.testTemplate)}>
          
          <div className={gridStyle.grid__header}>
            <div className={classNames(gridStyle.grid__row, css.headerRow, !testValues ? css.borderBottom : null )}>
              <div className={gridStyle.grid__cell}>{AnalyticsTestTitles.TestClassBimsId}<i className='required-red' /></div>
              <div className={gridStyle.grid__cell}>{AnalyticsTestTitles.DeadlineDate}<i className='required-red' /></div>
              <div className={gridStyle.grid__cell}>{AnalyticsTestTitles.Comment}</div>
              <div className={gridStyle.grid__cell} />
            </div>
          </div>

          <div className={gridStyle.grid__body}>
            <FieldArray
              name={'AnalyticTests'}
              component={renderTestArray}
              props={{
                currentMolecule,
                testClassDropdownObjects,
                deleteTestHandler,
              }}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
        <Form onSubmit={event => submitForm(event)} className={classNames('horizontal', css.form)}>
          <div className={classNames(
            css.formRow,
            isReaction ? css.formRow__reaction : css.formRow__agent,
            )}
          >
            <Field
              name={AnalyticsSmallMoleculeKeys.SampleName}
              component={BcdInputWrapper}
              useFormFieldLayout={true}
              required={true}
              validate={[FieldValidators.required]}
              markPristineValidity
              placeholder={'Введите название образца'}
              label={AnalyticsSmallMoleculeTitles.SampleName}
              disabled={isReaction}
            />
            <Field
              name={AnalyticsSmallMoleculeKeys.SampleType}
              validate={[FieldValidators.required]}
              normalize={(value) => value === null ? 0 : value}
              markPristineValidity
              component={BcdSuggestWrapper}
              multiple={false}
              options={smTypesDropdownOptions}
              format={value => smTypesDropdownOptions.find(x => x.value === value)}
              placeholder={'Выберите тип образца'}
              label={AnalyticsSmallMoleculeTitles.SampleType}
              required={true}
              useFormFieldLayout
            />
            <Field
              name={AnalyticsSmallMoleculeKeys.Amount}
              component={BcdInputWrapper}
              validate={[FieldValidators.required, FieldValidators.number, FieldValidators.maxDecimalLength3]}
              markPristineValidity
              useFormFieldLayout={true}
              required={true}
              placeholder={'Введите количество'}
              label={AnalyticsSmallMoleculeTitles.Amount}
            />
            <FormFieldLayout className={css.formRow__comment} placeholder={'Введите комментарий'} label={AnalyticsSmallMoleculeTitles.Comment}>
              <Field
                name={AnalyticsSmallMoleculeKeys.Comment}
                component={ResizableAreaFieldWrapper}
                rows={1}
                maxRows={3}
              />
            </FormFieldLayout>
            {
              isReaction
                ? <Field
                    name={AnalyticsSmallMoleculeKeys.SendAsCandidate}
                    component={BcdCheckboxWrapper}
                    label='Отправить как кандидата'
                    large={true}
                  />
                : null
            }
          </div>
          <div className={css.testsSection}>
            { renderAnalyticTestsSection() }
          </div>
          {/* <Button
            className={'submitBtn'}
            text="Добавить"
            disabled={formIsInvalid || formSyncErrorsCheck()}
            view="primary"
            size="L"
            type={'submit'}
          /> */}
        </Form>
    </>
  );
};

export const SMALL_MOLECULE_EDIT_FORM_NAME = 'smallMoleculeEditForm';

const mapStateToProps = (state) => {
  const {resource, modules} = state;
  return {
    isReaction: modules?.analytics?.currentEntity?.type === smEntityTypes.Reaction,
    smTypes: smTypesSelector(state),
    testClasses: testClassesSelector(state),
    initialValues: modules.analytics.currentMolecule,
    currentMolecule: modules.analytics.currentMolecule,
    formValues: getFormValues(SMALL_MOLECULE_EDIT_FORM_NAME)(state),
  };
};

const mapDispatchToProps = dispatch => ({
  saveCurrentMolecule: (molecule) => dispatch(AnalyticsModuleActions.saveCurrentMolecule({molecule})),
  addNewTestToMolecule: (formValues) => dispatch(AnalyticsModuleActions.newTest({formValues})),
  deleteTestFromMolecule: (formValues, index) => dispatch(AnalyticsModuleActions.deleteTest({formValues, index})),
});
  
SmallMoleculeEdit = loadBimsDictionaries(SmallMoleculeEdit);
SmallMoleculeEdit = reduxForm({
  form: SMALL_MOLECULE_EDIT_FORM_NAME,
  enableReinitialize: true,
  destroyOnUnmount: true,
})(SmallMoleculeEdit);
SmallMoleculeEdit = connect(mapStateToProps, mapDispatchToProps)(SmallMoleculeEdit);
SmallMoleculeEdit = withRouter(SmallMoleculeEdit);

export { SmallMoleculeEdit };
