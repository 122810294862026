// @flow

import {
    put,
    takeEvery,
    fork,
    select, delay,
} from 'redux-saga/effects';
import {ReactionsResourceService} from '../services';
import {getToken} from '../../account/reducers';
import {
    reactionCatalogResourceActions,
    reactionsResourceActions,
    reactionEventsHistoryResourceActions,
    reactionModuleActions,
    reactionAttachmentsActions, purificationReactionCatalogResourceActions,
} from '../actions';
import type { ICatalogReaction } from '../models';
import type { IRequestResourceAction } from '../../shared/utils/action-creators';
import type { IRootStore } from '../../app/reducers';
import { globalErrorsActions, setGlobalLoader } from '../../shared/actions';
import {getAccount} from '../../account/sagas';

function* getUserName()
{
    let authorUserName = yield select((state: IRootStore) => state.global.account.data.UserName);
    let delayTime = 100;
    while (!authorUserName && delayTime <= 1000)
    {
        yield delay(delayTime);
        authorUserName = yield select((state: IRootStore) => state.global.account.data.UserName);
        delayTime += 50;
    }
    return authorUserName || null;
}

export function* getReaction({ uriParams }: IRequestResourceAction) {
    const token = yield select(getToken);
    try {
        const result: ICatalogReaction[] = yield ReactionsResourceService.get({ uriParams }, token);
        yield put(reactionsResourceActions.get.success(result));
    } catch (error) {
        yield put(reactionsResourceActions.get.failure({error}));
    }
}

export function* listReactions({ params }: IRequestResourceAction) {
    const token = yield select(getToken);
    try {
        const result = yield ReactionsResourceService.list({ params }, token);
        yield put(reactionsResourceActions.list.success(result));
    } catch (error) {
        yield put(reactionsResourceActions.list.failure({ error }));
    }
}

export function* listPurification({ params }: IRequestResourceAction) {
    params.SmilesFormula = params.SmilesFormula && encodeURIComponent(params.SmilesFormula);
    params.Name = params.Name && encodeURIComponent(params.Name);

    const token = yield select(getToken);
    try {
        const result = yield ReactionsResourceService.listPurification({ params }, token);
        yield put(purificationReactionCatalogResourceActions.list.success(result));
    } catch (error) {
        yield put(purificationReactionCatalogResourceActions.list.failure({ error }));
    }
}

export function* getNewPurificationName({ params }) {
    const token = yield select(getToken);
    const authorUserName = yield getUserName();
    if (!authorUserName) return;
    try {
        const result = yield ReactionsResourceService.getNewPurificationName({ params: { ...params, authorUserName } }, token);
        yield put(reactionModuleActions.reactionEdit.setCurrentReactionName(result));
        yield put(reactionsResourceActions.getNewPurificationName.success({}));
    } catch (error) {
        yield put(reactionsResourceActions.getNewPurificationName.failure({ error }));
    }
}

export function* getNewNestedPurificationName({ params, reactionName }) {
    const token = yield select(getToken);
    const authorUserName = yield getUserName();
    if (!authorUserName) return;
    try {
        const { result } = yield ReactionsResourceService.getNewPurificationName({ params: { ...params, authorUserName } }, token);
        yield put(reactionModuleActions.reactionEdit.setCurrentReactionName({ result: { Name: `PUR-${result.Number}-${reactionName}`, Number: result.Number } }));
        yield put(reactionsResourceActions.getNewNestedPurificationName.success({}));
    } catch (error) {
        yield put(reactionsResourceActions.getNewNestedPurificationName.failure({ error }));
    }
}

export function* createExtraNestedPurification({ uriParams }) {
    const token = yield select(getToken);
    try {
        const { result } = yield ReactionsResourceService.createExtraNestedPurification({ uriParams }, token);
        yield put(reactionsResourceActions.createExtraNestedPurification.success({ result }));
    } catch (error) {
        yield put(reactionsResourceActions.createExtraNestedPurification.failure({ error }));
    }
}

export function* updateReaction({ data }: IRequestResourceAction) {
    const token = yield select(getToken);
    try {
        const result = yield ReactionsResourceService.update({ data }, token);
        yield put(reactionsResourceActions.update.success(result));
    } catch (error) {
        yield put(reactionsResourceActions.update.failure({ error }));
    }
}

export function* createReaction({ data }: IRequestResourceAction) {
    const token = yield select(getToken);
    try {
        const result = yield ReactionsResourceService.create({ data }, token);
        yield put(reactionsResourceActions.create.success(result));
    } catch (error) {
        yield put(reactionsResourceActions.create.failure({ error }));
    }
}

export function* deleteReaction({ uriParams }: IRequestResourceAction) {
    const token = yield select(getToken);
    try {
        yield ReactionsResourceService.delete({ uriParams }, token);
    } catch (error) {
        yield put(reactionsResourceActions.delete.failure({ error }));
    }
}

export function* listCatalogReactions({params}: IRequestResourceAction) {
    const token = yield select(getToken);
    try {
        params = {...params, Order: '-CreationDate' };
        const result: ICatalogReaction[] = yield ReactionsResourceService.listCatalog({ params }, token);
        yield put(reactionCatalogResourceActions.list.success(result));
    } catch (error) {
        yield put(reactionCatalogResourceActions.list.failure({error}));
    }
}

export function* quickComment({data, uriParams}: IRequestResourceAction) {
    const token = yield select(getToken);
    try {
        const result = yield ReactionsResourceService.quickComment({ data, uriParams }, token);
        yield put(reactionsResourceActions.quickComment.success(result));
    } catch (error) {
        yield put(reactionsResourceActions.quickComment.failure({error}));
    }
}

export function* selectFolder({data, uriParams}: IRequestResourceAction) {
    const token = yield select(getToken);
    try {
        const result = yield ReactionsResourceService.selectFolder({ data, uriParams }, token);
        console.log('finish', result);
        yield put(reactionsResourceActions.selectFolder.success(result));
    } catch (error) {
        yield put(reactionsResourceActions.selectFolder.failure({error}));
    }
}

export function* getEventsHistory({params, uriParams}: IRequestResourceAction) {
    const token = yield select(getToken);
    try {
        const result = yield ReactionsResourceService.getEventsHistory({ params, uriParams }, token);
        yield put(reactionEventsHistoryResourceActions.list.success(result));
    } catch (error) {
        yield put(reactionEventsHistoryResourceActions.list.failure({error}));
    }
}

export function* getNewName({ params }: IRequestResourceAction) {
    const token = yield select(getToken);
    const authorUserName = yield getUserName();
    if (!authorUserName) return;
    try {
        const result = yield ReactionsResourceService.getNewName({ params: { ...params, authorUserName } }, token);
        yield put(reactionModuleActions.reactionEdit.setCurrentReactionName(result));
        yield put(reactionsResourceActions.getNewName.success({}));
    } catch (error) {
        yield put(reactionsResourceActions.getNewName.failure({ error }));
    }
}

export function* completeReaction({ uriParams, data }: IRequestResourceAction) {
    const token = yield select(getToken);
    yield put(reactionModuleActions.reactionComplete.success({ isSuccess: null }));
    yield put(setGlobalLoader({ isLoading: true }));
    try {
        yield ReactionsResourceService.completeReaction({ uriParams, data }, token);
        yield put(reactionsResourceActions.completeReaction.success({}));
        yield put(reactionModuleActions.reactionComplete.success({ isSuccess: true }));
    } catch (error) {
        yield put(reactionsResourceActions.completeReaction.failure({ error }));
        yield put(reactionModuleActions.reactionComplete.success({ isSuccess: false }));
    } finally {
        yield put(reactionModuleActions.reactionComplete.success({ isSuccess: null }));
        yield put(setGlobalLoader({ isLoading: false }));
    }
}

export function* getReactionAttachments({ uriParams }: IRequestResourceAction) {
    const token = yield select(getToken);

    try {
        const result = yield ReactionsResourceService.getAttachedFiles({ uriParams }, token);
        yield put(reactionAttachmentsActions.list.success(result));
    } catch (error) {
        yield put(reactionAttachmentsActions.list.failure({error}));
    }
}

function* getTemplateReactions(action) {
    const token = yield select(getToken);
    try {
        const result = yield ReactionsResourceService.getTemplateReactions({ params: action.params }, token);
        yield put(reactionsResourceActions.getTemplateReactions.success(result));
    } catch (error) {
        yield put(reactionsResourceActions.getTemplateReactions.failure({error}));
    }
  }

  function* getTechInstruction(action: IRequestResourceAction) {
    const token = yield select(getToken);
    try {
      const  { result } = yield ReactionsResourceService.getTechInstruction({
        uriParams: { id: action.reactionId },
        data: {Image: action.reactionImage}
      }, token);
      const url = URL.createObjectURL(new Blob([result]));
  
      let a = document.createElement('a');
      a.href = url;
      a.download = `Техническая-инструкция-${action.reactionId}.docx`;
      a.click();
      URL.revokeObjectURL(url.href);
    }
    catch(err) {
      yield put(globalErrorsActions.set({error: err.message}));
    }
  }

// Watchers
export const reactionsResourceWatchers = [

    function* () {
        yield takeEvery(reactionCatalogResourceActions.list.request.type, function* (action) {
            yield fork(listCatalogReactions, action);
        });
    },

    function* () {
        yield takeEvery(reactionsResourceActions.get.request.type, function* (action) {
            yield fork(getReaction, action);
        });
    },

    function* () {
      yield takeEvery(reactionsResourceActions.quickComment.request.type, function* (action) {
        yield fork(quickComment, action);
      });
    },

    function* () {
        yield takeEvery(reactionsResourceActions.selectFolder.request.type, function* (action) {
          yield fork(selectFolder, action);
        });
      },

    function* () {
      yield takeEvery(reactionsResourceActions.list.request.type, function* (action) {
        yield fork(listReactions, action);
      });
    },
    
    function* () {
      yield takeEvery(reactionEventsHistoryResourceActions.list.request.type, function* (action) {
        yield fork(getEventsHistory, action);
      });
    },

    function* () {
        yield takeEvery(reactionsResourceActions.getNewName.request.type, function* (action) {
            yield fork(getNewName, action);
        });
    },

    function* () {
        yield takeEvery(reactionsResourceActions.delete.request.type, function* (action) {
            yield fork(deleteReaction, action);
        });
    },

    function* () {
        yield takeEvery(reactionsResourceActions.update.request.type, function* (action) {
            yield fork(updateReaction, action);
        });
    },

    function* () {
        yield takeEvery(reactionsResourceActions.create.request.type, function* (action) {
            yield fork(createReaction, action);
        });
    },

    function* () {
        yield takeEvery(reactionsResourceActions.completeReaction.request.type, function* (action) {
            yield fork(completeReaction, action);
        });
    },

    function* () {
        yield takeEvery(purificationReactionCatalogResourceActions.list.request.type, function* (action) {
            yield fork(listPurification, action);
        });
    },

    function* () {
        yield takeEvery(reactionsResourceActions.getNewPurificationName.request.type, function* (action) {
            yield fork(getNewPurificationName, action);
        });
    },

    function* () {
        yield takeEvery(reactionsResourceActions.getNewNestedPurificationName.request.type, function* (action) {
            yield fork(getNewNestedPurificationName, action);
        });
    },

    function* () {
        yield takeEvery(reactionAttachmentsActions.list.request.type, function* (action) {
            yield fork(getReactionAttachments, action);
        });
    },

    function* () {
        yield takeEvery(reactionsResourceActions.createExtraNestedPurification.request.type, function* (action) {
            yield fork(createExtraNestedPurification, action);
        });
    },

    function* () {
        yield takeEvery(reactionsResourceActions.getTemplateReactions.request.type, function* (action) {
            yield fork(getTemplateReactions, action);
        });
    },

    function* () {
        yield takeEvery(reactionModuleActions.reactionEdit.getTechInstruction.type, function* (action) {
            yield fork(getTechInstruction, action);
        });
    },
];
