// @flow
import { Model, ArrayModel } from 'objectmodel';
import { Compound } from '../../agents/models';
import { RepkaTaskLink } from '../../shared/models';

export const ObtainmentScheme = Model({
  CanBeDeleted: Boolean,
  Code: String,
  CreatedAt: [String],
  CustomStageCompound: [Compound],
  CustomStageCompoundId: [Number],
  FinalCandidateId: Number,
  FolderId: [Number],
  Id: Number,
  InitialAgentId: [Number],
  InitialAgentSubstanceId: [Number],
  InitialAgentSubstanceSmiles: [String],
  IsDeleted: Boolean,
  RepkaTaskId: [Number],
  RepkaTaskLinks: [ArrayModel([RepkaTaskLink, null])],
  ProjectType: [Number],
});
