import {
    apiRequestList,
    apiRequestSingle
} from '../../shared/services/api/common';

export class DescendantsService {

    constructor(token) {
        this._token = token;
        const headers = token ? {Authorization: token} : {};
        this._getList = apiRequestList('GET', '/folders/descendants', headers);
    }

    getList(params) {
        return this._getList({params});
    }

}