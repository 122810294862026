// @flow

import { createSelector } from 'reselect';
import { resourceReducer } from '../../shared/utils/reducer-creators';
import { productsResourceActions } from '../actions';
import type { IRootStore } from '../../app/reducers';

export const productsResourceReducer = resourceReducer(productsResourceActions);

export const
  getProducts = createSelector(
    (state: IRootStore) => state.resource.products.data,
    (data) => Object.values(data)
  )
;
