// @flow

import {
  resourceActions, CRUDStatusActions,
} from '../../shared/utils';


export const JAR_STOCK_ROOT_NAME = 'JAR_STOCK';
export const jarStockResourceActions = {
  ...resourceActions(JAR_STOCK_ROOT_NAME),
  getJar: CRUDStatusActions(JAR_STOCK_ROOT_NAME, 'GET_JAR'),
  getUsedJarsByReaction: CRUDStatusActions(JAR_STOCK_ROOT_NAME, 'GET_USED_JARS'),
  getProvidedJarsByReaction: CRUDStatusActions(JAR_STOCK_ROOT_NAME, 'GET_PROVIDED_JARS'),
};
