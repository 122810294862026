import React, {useEffect} from 'react';
import Dialog from '@biocad/bcd-front-ui/layout/Dialog';
import {StyledButton} from '../../../../shared/components/ConfirmDialog/ConfirmDialog';
import {connect} from 'react-redux';
import {BcdInputWrapper, BcdDropdownWrapper, BcdCheckboxWrapper, FormFieldLayout} from '../../../../shared/components';
import {Field, reduxForm, Form, getFormValues, isValid, isDirty, change} from 'redux-form';
import {FieldValidators} from '../../../../shared/validators/field-validators';
import {folderParentType, projectStatusEnum, projectTypeEnum} from '../../../types/enum';
import {projectStatusesTranslate, projectTypesTranslate} from '../../../localization';
import {itemsToArraySelector} from '../../../../shared/utils/selectors/resource-selectors';
import {descendantsModuleActions, projectActions} from '../../../actions';
import DescendantTree from '../../../../shared/components/DescendantTree';
import { ownedProjectsResourceActions } from '../../../../user-dashboard/actions';

export const PROJECTS_EDIT_FORM_NAME = 'projectsEditFormName';

const formInitialValues: {
    name: string,
    leader: string,
    type: string,
    status: string,
    folderId: number,
    OrderPriority: number,
    isScaling: false,
} = {
    name: '',
    leader: '',
    type: '',
    status: '',
    folderId: 0,
    OrderPriority: 0,
    isScaling: false,
};

const dropdownStatusesOptions = [
    projectStatusesTranslate.configuring,
    projectStatusesTranslate.inWork,
    projectStatusesTranslate.finished,
    projectStatusesTranslate.closed,
    projectStatusesTranslate.paused,
];

const dropdownTypesOptions = [
    projectTypesTranslate.innovative,
    projectTypesTranslate.generic
];

const initialProps = {
    toggleModal: null,
    isEditMode: false,
    formValues: null,
    formDirty: null,
    formValid: null,
    createProject: null,
    updateProject: null,
    foldersPath: [],
    updateName: null,
    updateLeader: null,
    updateType: null,
    updateStatus: null,
    projects: [],
    currentProjectId: null,
    descendants: [],
    currentDescendant: null,
    owned: false,
    setCurrentDescendant: () => null,
    deleteCurrentDescendant: () => null,
    clearProjectsList: () => null,
    getMaxOrder: () => null,
};

let ProjectModal = (props = initialProps) => {
    useEffect(() => {
        
        if (props.isEditMode && props.currentProjectId) {
            const project = props.owned
                ? props.ownedProjects.find(item => +item.Id === +props.currentProjectId)
                : props.projects.find(item => +item.Id === +props.currentProjectId);

            props.updateName(project.Name);
            props.updateLeader(project.Leader);
            props.updateType(projectTypesTranslate[projectTypeEnum[project.Type]]);
            props.updateStatus(projectStatusesTranslate[projectStatusEnum[project.Status]]);
            props.updateOrderPriority(project.OrderPriority);
            props.updateIsScaling(project.IsScaling);

            if (project.FolderId) {
                props.updateFolderId(project.FolderId);

                props.setCurrentDescendant({
                    id: project.FolderId,
                    name: props.foldersPath.length ?
                        props.foldersPath[props.foldersPath.length - 1].name : null
                });
            } else {
                props.deleteCurrentDescendant();
            }
        } else {
            props.getMaxOrder({FolderId: props.currentFolder});
        }
    }, [props.isEditMode]);

    useEffect(() => {
        !props.isEditMode && props.updateOrderPriority(props.currentMaxOrder);
    }, [props.currentMaxOrder]);

    useEffect(() => {
        if (props.currentDescendant) {
            props.updateFolderId(props.currentDescendant.id);
        } else {
            props.updateFolderId(null);
        }
    }, [props.currentDescendant]);

    //Не допускать проставления масштабирования для не generic-проектов
    useEffect(() => {
        let currentProjectType = getCurrentProjectType();
        if(currentProjectType && currentProjectType !== projectTypeEnum.generic) {
            props.updateIsScaling(false);
        }
    }, [props?.formValues?.type]);

    const getCurrentProjectType = () => projectTypeEnum[projectTypesTranslate[props?.formValues?.type]];

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!props.isEditMode) {
            createProject();
        } else {
            editProject();
        }
    };

    const createProject = () => {
        props.createProject({
            name: props.formValues.name,
            leader: props.formValues.leader,
            type: projectTypeEnum[projectTypesTranslate[props.formValues.type]],
            status: projectStatusEnum[projectStatusesTranslate[props.formValues.status]],
            folderId: props.foldersPath.length
                ? props.foldersPath[props.foldersPath.length - 1].id
                : null,
            OrderPriority: props.formValues.OrderPriority,
            isScaling: props.formValues.isScaling,
        });
        props.toggleModal();
    };

    const editProject = () => {

        const dataToUpdate = {
            id: props.currentProjectId,
            name: props.formValues.name,
            leader: props.formValues.leader,
            type: projectTypeEnum[projectTypesTranslate[props.formValues.type]],
            status: projectStatusEnum[projectStatusesTranslate[props.formValues.status]],
            folderId: props.formValues.folderId,
            parentId: props.foldersPath.length
                ? props.foldersPath[props.foldersPath.length - 1].id
                : null,
            OrderPriority: props.formValues.OrderPriority,
            isScaling: props.formValues.isScaling,
        };
        if (props.owned) {
            props.destroyOwnedProjectsList();
            props.updateProject(dataToUpdate);
        } else {
            props.clearProjectsList();
            props.updateProject(dataToUpdate);
        }

        props.toggleModal();
    };

    return (
        <React.Fragment>
            <Dialog paranjaClose={false} close={props.toggleModal}>
                <h3>{(props.isEditMode ? 'Редактировать ' : 'Новый ') + 'проект'}</h3>
                <hr/>
                <Form onSubmit={handleSubmit} className={'projects-edit-form-modal'}>
                    <FormFieldLayout label='Название' required>
                        <Field
                            type='text'
                            name={'name'}
                            component={BcdInputWrapper}
                            validate={[FieldValidators.required]}
                            placeholder={'Введите название'}/>
                    </FormFieldLayout>

                    <FormFieldLayout label='Руководитель' required>
                        <Field
                            type='text'
                            name={'leader'}
                            component={BcdInputWrapper}
                            validate={[FieldValidators.required]}
                            placeholder={'Введите ФИО'}/>
                    </FormFieldLayout>

                    {props.isEditMode &&
                    <DescendantTree
                        title={'Папка'}
                        isRequired={false}
                        root={'Проекты'}
                        value={props.currentDescendant ? props.currentDescendant.name : 'Проекты'}/>
                    }

                    <FormFieldLayout label='Тип' required>
                        <Field
                            name={'type'}
                            component={BcdDropdownWrapper}
                            validate={[FieldValidators.required]}
                            options={dropdownTypesOptions}
                            placeholder={'Выберите тип разработки'}
                        />
                        <Field
                            name={'isScaling'}
                            component={BcdCheckboxWrapper}
                            label={'Масштабирование'}
                            disabled={getCurrentProjectType() !== projectTypeEnum.generic}
                            large
                            useFormFieldLayout
                        />
                    </FormFieldLayout>

                    <FormFieldLayout label='Статус' required>
                        <Field
                            name="status"
                            component={BcdDropdownWrapper}
                            validate={[FieldValidators.required]}
                            options={dropdownStatusesOptions}
                            placeholder={'Выберите статус проекта'}
                        />
                    </FormFieldLayout>

                    
                    <FormFieldLayout label='Порядок' required>
                        <Field
                            name="OrderPriority"
                            component={BcdInputWrapper}
                            validate={[FieldValidators.number]}
                            placeholder={'Введите числовое значение'}
                        />
                    </FormFieldLayout>

                    <StyledButton
                        size="L"
                        text={props.isEditMode ? 'Изменить' : 'Создать'}
                        type={'submit'}
                        view="primary"
                        disabled={!props.formValid || !props.formDirty}
                    />

                    <StyledButton
                        size="L"
                        text='Отмена'
                        onAction={props.toggleModal}
                    />
                </Form>
            </Dialog>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    const {resource, modules} = state;
    return {
        isEditMode: modules.project.isEdit,
        currentProjectId: modules.project.id,
        formValues: getFormValues(PROJECTS_EDIT_FORM_NAME)(state),
        formValid: isValid(PROJECTS_EDIT_FORM_NAME)(state),
        formDirty: isDirty(PROJECTS_EDIT_FORM_NAME)(state),
        foldersPath: modules.folder.path,
        projects: itemsToArraySelector(resource.projects),
        ownedProjects: itemsToArraySelector(resource.dashboard.ownedProjects),
        descendants: itemsToArraySelector(resource.descendants),
        currentDescendant: modules.descendants.currentDescendant,

        currentFolder: modules.folder.path.length
            ? modules.folder.path[modules.folder.path.length - 1].id
            : null,
        currentMaxOrder: modules.project.currentMaxOrder,
    };
};

ProjectModal = reduxForm({
    form: PROJECTS_EDIT_FORM_NAME,
    formInitialValues,
    destroyOnUnmount: true,
})(ProjectModal);

ProjectModal = connect(
    mapStateToProps,
    (dispatch) => ({
        createProject: (data) => dispatch(projectActions.create.request({data})),
        updateProject: (data) => dispatch(projectActions.update.request({data})),
        updateName: (value) => dispatch(change(PROJECTS_EDIT_FORM_NAME, 'name', value, false)),
        updateLeader: (value) => dispatch(change(PROJECTS_EDIT_FORM_NAME, 'leader', value, false)),
        updateType: (value) => dispatch(change(PROJECTS_EDIT_FORM_NAME, 'type', value, false)),
        updateStatus: (value) => dispatch(change(PROJECTS_EDIT_FORM_NAME, 'status', value, false)),
        updateFolderId: (value) => dispatch(change(PROJECTS_EDIT_FORM_NAME, 'folderId', value, false)),
        updateOrderPriority: (value) => dispatch(change(PROJECTS_EDIT_FORM_NAME, 'OrderPriority', value, false)),
        updateIsScaling: (value) => dispatch(change(PROJECTS_EDIT_FORM_NAME, 'isScaling', value, false)),
        setCurrentDescendant: ({id, name}) => dispatch(descendantsModuleActions.setCurrentDescendant({id, name})),
        deleteCurrentDescendant: () => dispatch(descendantsModuleActions.deleteCurrentDescendant()),
        clearProjectsList: () => dispatch(projectActions.destroy()),
        getMaxOrder: (data) => dispatch(projectActions.getMaxOrder.request({data})),
        destroyOwnedProjectsList: () => dispatch(ownedProjectsResourceActions.destroy()),
    })
)(ProjectModal);

export default ProjectModal;
