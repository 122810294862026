import {descendantsModuleActions} from '../actions/descendants';

const initialState = {
    currentDescendant: null,
    list: []
};

export const descendantsModuleReducer = (state = initialState, action) => {
    const reducedFn = {
        [descendantsModuleActions.setCurrentDescendant]: () => ({
            ...state,
            currentDescendant: {
                id: action.id,
                name: action.name
            }
        }),
        [descendantsModuleActions.deleteCurrentDescendant]: () => ({
            ...state,
            currentDescendant: null
        }),
        [descendantsModuleActions.setDescendantsList]: () => ({
            ...state,
            list: action.list
        }),
        [descendantsModuleActions.clearDescendantsList]: () => ({
            ...state,
            list: []
        })
    }[action.type];
    return reducedFn ? reducedFn() : state;
};