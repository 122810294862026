export default {
    'white':    '#FFFFFF',
    'black':    '#000000',
    'green':    '#22AA48',
    'orange':   '#F4AD46',
    'red':      '#D93B36',
    'purple':   '#9D77EE',
    'blue-300': '#74C4FA',
    'blue-500': '#048FEC',
    'blue-700': '#0377C4',
    'blue-800': '#005E99',
    'grey-100': '#F5F7FA',
    'grey-200': '#E9ECF2',
    'grey-300': '#D3D7DE',
    'grey-400': '#B9BEC9',
    'grey-500': '#9CA2AD',
    'grey-600': '#737780',
    'grey-700': '#54575E',
    'grey-800': '#37393E',
    'grey-900': '#202226'
};
