// @flow

import type { ResourceRequestMethodType } from '../../shared/utils/service-creator';
import { CRUDRequestsMap, HTTPMethodEnum, ResourceService } from '../../shared/utils/service-creator';
import {
  CatalogReaction,
  CatalogReactionRequestParams, PurificationListRequest,
  Reaction, ReactionCompletion, ReactionEventRequest,
  ReactionListRequest, ReactionName, ReactionNameRequest,
  ReactionAttachment,
} from '../models';
import { Model } from 'objectmodel';
import { SingleRequestURIParams } from '../../shared/models';
import { Reagent } from '../../reagents/model';
import { Product } from '../../products/model';
import { AxiosInstance } from 'axios';
import { ReactionEventModel } from '../models/reaction-event';
import {ArchivedReagent} from '../../archivedReagents/model';


type ReactionsResourceServiceType = {
  get: ResourceRequestMethodType,
  list: ResourceRequestMethodType,
  create: ResourceRequestMethodType,
  update: ResourceRequestMethodType,
  delete: ResourceRequestMethodType,
  listCatalog: ResourceRequestMethodType,
  quickComment: ResourceRequestMethodType,
  selectFolder: ResourceRequestMethodType,
  listPurification: ResourceRequestMethodType,
  getNewName: ResourceRequestMethodType,
  getNewPurificationName: ResourceRequestMethodType,
  // getNewNumber: ResourceRequestMethodType,
  completeReaction: ResourceRequestMethodType,
  // listEvents: ResourceRequestMethodType,
  // getFullPath: ResourceRequestMethodType,
  getAttachedFiles: ResourceRequestMethodType,
  getProducts: ResourceRequestMethodType,
  getReagents: ResourceRequestMethodType,
  getArchiveReagents: ResourceRequestMethodType,
  // getAnalyticsSmallMolecules: ResourceRequestMethodType,
}

export const ReactionsResourceService: ReactionsResourceServiceType = ResourceService({
  baseURL: '/reactions',
  defaultModel: Reaction,
  defaultQuery: ReactionListRequest,
  useDefaultCRUDRequests: [
    CRUDRequestsMap.get,
    CRUDRequestsMap.list,
    CRUDRequestsMap.create,
    CRUDRequestsMap.delete,
    CRUDRequestsMap.update,
  ],
  requests: {
    [CRUDRequestsMap.list]: {
      url: '',
      method: HTTPMethodEnum.GET,
      paramsModel: ReactionListRequest,
      model: Reaction,
    },
    [CRUDRequestsMap.create]: {
      url: '/',
      method: HTTPMethodEnum.POST,
      model: Reaction,
      dataModel: Reaction,
    },
    [CRUDRequestsMap.update]: {
      url: '/',
      method: HTTPMethodEnum.PUT,
      model: Reaction,
      dataModel: Reaction,
    },
    listCatalog: {
      url: '/catalog',
      method: HTTPMethodEnum.GET,
      model: CatalogReaction,
      paramsModel: CatalogReactionRequestParams,
    },
    quickComment: {
      url: '/{id}/quick_comment',
      method: HTTPMethodEnum.PUT,
      uriParamsModel: SingleRequestURIParams,
      dataModel: Model({ QuickComment: String, ReactionId: Number }),
    },
    selectFolder: {
      url: '/{id}/select_folder',
      method: HTTPMethodEnum.PUT,
      uriParamsModel: SingleRequestURIParams,
      dataModel: Model({ FolderId: Number, ReactionId: Number }),
    },
    getReagents: {
      url: '/{id}/reagents',
      method: HTTPMethodEnum.GET,
      uriParamsModel: SingleRequestURIParams,
      model: Reagent,
    },
    getArchiveReagents: {
      url: '/{id}/archive_reagents',
      method: HTTPMethodEnum.GET,
      uriParamsModel: SingleRequestURIParams,
      model: ArchivedReagent,
    },
    getProducts: {
      url: '/{id}/products',
      method: HTTPMethodEnum.GET,
      uriParamsModel: SingleRequestURIParams,
      model: Product,
      paramsModel: Model({
        ShowOnlyMainProduct: Boolean,
      }).defaultTo({ ShowOnlyMainProduct: false })
    },
    getEventsHistory: {
      url: '/{id}/events',
      method: HTTPMethodEnum.GET,
      uriParamsModel: SingleRequestURIParams,
      model: ReactionEventModel,
      paramsModel: ReactionEventRequest,
    },
    getNewName: {
      url: '/name',
      method: HTTPMethodEnum.GET,
      paramsModel: ReactionNameRequest,
      model: ReactionName
    },
    completeReaction: {
      url: '/{id}/complete',
      method: HTTPMethodEnum.POST,
      uriParamsModel: SingleRequestURIParams,
      dataModel: ReactionCompletion,
    },
    listPurification: {
      url: '/purification',
      method: HTTPMethodEnum.GET,
      paramsModel: PurificationListRequest
    },
    getNewPurificationName: {
      url: '/purification_name',
      method: HTTPMethodEnum.GET,
      paramsModel: ReactionNameRequest,
      model: ReactionName
    },
    getAttachedFiles: {
      url: '/{id}/attachments',
      method: HTTPMethodEnum.GET,
      uriParamsModel: SingleRequestURIParams,
      model: ReactionAttachment,
      dataModel: ReactionAttachment,
    },
    countHistorical: {
      url: '/count_historical/{stageId}',
      method: HTTPMethodEnum.GET,
      uriParamsModel: Model({ stageId: [Number] }),
    },
    createExtraNestedPurification: {
      url: '{id}/extra_purification',
      method: HTTPMethodEnum.POST,
      uriParamsModel: SingleRequestURIParams,
      model: Reaction,
    },
    getTemplateReactions: {
      url: '/templates',
      method: HTTPMethodEnum.GET,
      paramsModel: Model({ reactionType: [Number] }),
    },
    getTechInstruction: {
      url: '{id}/tech_instruction',
      method: HTTPMethodEnum.POST,
      uriParamsModel: SingleRequestURIParams,
      responseType: 'arraybuffer',
      dataModel: Model({ Image: String }),
    },
    setNowToModificationDate: {
      url: '/{id}/set_now_mod_date',
      method: HTTPMethodEnum.PUT,
      uriParamsModel: SingleRequestURIParams,
    },
  }
});

export const ReactionsAttachmentService = ResourceService({
  baseURL: '/reaction_attachments',
  defaultModel: ReactionAttachment,
  useDefaultCRUDRequests: [
    CRUDRequestsMap.get,
    CRUDRequestsMap.create,
    CRUDRequestsMap.delete,
  ],
});