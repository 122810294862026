// Требуется чтобы в redux-devtools отображались реакции

if (!Map.prototype.toJSON) {
  Map.prototype.toJSON = function() {
    return Array.from(this.entries()).map(([key, value]) => ({
      mapKey: key,
      mapValue: value,
    }));
  };
}
