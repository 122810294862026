// @flow

import { ObjectModel } from 'objectmodel';

export const CompoundFormula = ObjectModel({
  Formula: [String],
  Id: [Number],
  MolecularFormula: [String],
  SmilesFormula: [String],
  Thumbnail: [String],
  MolarWeight: [Number, null, undefined],
  Name: [String],
  IsAntibody: [Boolean],
  IsPolymer: [Boolean],
});
