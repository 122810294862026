import {folderModuleActions} from '../actions/folders';

const initialState = {
    path: [],
    id: null,
    isEdit: false,
    candidatesPath: [],
    schemesPath: []
};

export const foldersModuleReducer = (state = initialState, action) => {
    const reducedFn = {
        [folderModuleActions.addFolderPathItem]: () => ({
            ...state,
            path: [
                ...state.path,
                {
                    id: action.id,
                    name: action.name
                }
            ]
        }),
        [folderModuleActions.removeFolderPathItem]: () => ({
            ...state,
            path: state.path.slice(0, -1)
        }),
        [folderModuleActions.deleteFolderPath]: () => ({
            ...state,
            path: []
        }),
        [folderModuleActions.setCurrentFolderId]: () => ({
            ...state,
            id: action.id
        }),
        [folderModuleActions.setEditMode]: () => ({
            ...state,
            isEdit: action.isEdit
        }),
        [folderModuleActions.addFolderCandidatePathItem]: () => ({
            ...state,
            candidatesPath: [
                ...state.candidatesPath,
                {
                    id: action.id,
                    name: action.name
                }
            ]
        }),
        [folderModuleActions.setFolderCandidatePath]: () => ({
           ...state,
            candidatesPath: action.path
        }),
        [folderModuleActions.removeFolderCandidatePathItem]: () => ({
            ...state,
            candidatesPath: state.candidatesPath.slice(0, -1)
        }),
        [folderModuleActions.deleteFolderCandidatePath]: () => ({
            ...state,
            candidatesPath: []
        }),
        [folderModuleActions.addFolderSchemesPathItem]: () => ({
            ...state,
            schemesPath: [
                ...state.schemesPath,
                {
                    id: action.id,
                    name: action.name
                }
            ]
        }),
        [folderModuleActions.removeFolderSchemesPathItem]: () => ({
            ...state,
            schemesPath: state.schemesPath.slice(0, -1)
        }),
        [folderModuleActions.deleteFolderSchemesPath]: () => ({
            ...state,
            schemesPath: []
        })
    }[action.type];
    return reducedFn ? reducedFn() : state;
};