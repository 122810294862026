// @flow
import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import { getToken } from '../../account/reducers';
import { attachmentsActions } from '../actions';
import { AttachmentsService } from '../services/attachments';
import { globalErrorsActions } from '../../shared/actions';

export function* AttachmentUpload({ files, tag }): Generator<*, *, *> {
  const token = yield select(getToken);
  const service = new AttachmentsService(token);

  try {

    let result = yield all(files.map(file => service.uploadFile(file)));
    result = files.map((x, index) => {
      x.Id = result[index].result.Id;
      x.Attachment = {
        ...result[index].result,
      };
      return x;
    });

    yield put(attachmentsActions.upload.success({files: result, tag: tag }));

  } catch (e) {
    if (e.response && e.response.data) {
      let message = e.response.data['ErrorMessage'] || e.response.data['ExceptionMessage'];
      if (message) {
        yield put(attachmentsActions.upload.failure({error: message}));
        yield put(globalErrorsActions.set({error: message}));
      }
    }
  }
}

export function* AttachmentDownload({ id, name }): Generator<*, *, *> {
  const token = yield select(getToken);
  const service = new AttachmentsService(token);

  try {
    const { result } = yield service.downloadFile(id);
    const url = URL.createObjectURL(result);
    let a = document.createElement('a');
    a.href = url;
    a.download = name;
    a.click();
    URL.revokeObjectURL(url.href);
  } catch (e) {
    let message = e?.response?.data['ErrorMessage'] || e?.response?.data['ExceptionMessage'];
      if (message) {
        yield put(attachmentsActions.download.failure({error: message}));
        yield put(globalErrorsActions.set({error: message}));
      }
  }
}

export const attachmentWatchers = [

  function* watchAttachmentUpload(): Generator<*, *, *> {
    yield takeEvery(attachmentsActions.upload.request.type, function* (action) {
      const { files, tag } = action;
      yield fork(AttachmentUpload, { files, tag });
    });
  },

  function* watchAttachmentDownload(): Generator<*, *, *> {
    yield takeEvery(attachmentsActions.download.request.type, function* (action) {
      const { id, name } = action;
      yield fork(AttachmentDownload, { id, name });
    });
  },
];
