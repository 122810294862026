// @flow
import type { IPagination, OptionType } from '../../shared/models';
import type { ICatalogReactionRequestParams, IReaction } from '../models';
import type { IJarStock } from '../../agents/models';
import type { IRepkaTask } from '../models';
import cloneDeep from 'lodash/cloneDeep';
import { projectActions } from '../../projects/actions';
import { IProject } from '../../projects/models';
import type { IFolder } from '../../projects/models';
import type { ISuccessResourceAction } from '../../shared/utils/action-creators';
import { _temp_folderResourceAction } from '../../folders/actions';
import { reactionModuleActions, reactionRepkaTaskListActions, reactionsResourceActions, reactionEventsHistoryResourceActions } from '../actions';
import { reactionCatalogResourceActions } from '../actions/reaction-catalog-resource';
import {jarSpendingResourceActions, jarStockResourceActions} from '../../agents/actions';
import type { TEditReactionDialogAction } from '../actions';
import { JarStock_Model } from '../../agents/models';
import { reactionTypesResourceActions } from '../../reactionTypes/actions/resource'


export type TReactionEditCurrent = {
  reactionId: number,
  stageId: number,
  savedSuccess: boolean,
  isPurification: boolean,
  smilesFormula: string,
  isArchived: boolean,
  compoundName: string,
}

export interface IReactionModuleStore {
  list: {
    pagination: IPagination,
    searchParams: ICatalogReactionRequestParams,
    allProjects: OptionType[]
  },
  quickComment: {
    savedSuccessful: boolean,
  },
  selectFolder: {
    savedSuccessful: boolean,
  },
  reactionsSideBar: {
    reactions: IReaction[],
    folders: IFolder[],
    reactionsPagination: IPagination,
    historicalCount: ?number,
  },
  jarStock: {
    selectedJars: IJarStock[],
    invalidAgentsIndexList: number[],
    savedJars: IJarStock[],
  },
  repkaTask: {
    list: IRepkaTask[],
    selected: IRepkaTask,
    saved: IRepkaTask,
  },
  editReaction: {
    current: TReactionEditCurrent,
    highlightedSubstance: ?number,
    dialogsQueue: Array<{ name: string, id: string }>,
    registeredDialogs: Array<{name: string,  id: string}>,
    redirectToReaction: ?Number,
    substanceJars: [],
  },
  reactionComplete: {
    isSuccess: boolean,
  },
  jarSpendings: {
    jars: [],
  },
  eventsHistory: {
    pagination: IPagination,
  },
  settings: {
    repkaRedirectTaskUrl: string,
  },
  template: {
    isPending: ?boolean,
    searchedReaction: IReaction,
    reagents: [],
    reactantsInfo: [],
    reactionTypes: [],
    templateReactions: [],
    emptyJarsInfo: [],
    hasEmptyJars: ?boolean,
  },
}

export const ReactionEditDialogsMap = {
  ChooseRepkaTask: 'ChooseRepkaTask',
  AddSubstance: 'AddSubstance',
  EditSubstance: 'EditSubstance',
  AddArchivedSubstance: 'AddArchivedSubstance',
  EditArchivedSubstance: 'EditArchivedSubstance',
  ReactionSpendings: 'ReactionSpendings',
  PrintOptions: 'PrintOptions',
  DeleteConfirm: 'DeleteConfirm',
  CompleteReactionConfirmation: 'CompleteReactionConfirmation',
  CreateArchivedReaction: 'CreateArchivedReaction',
  AddProduct: 'AddProduct',
  ReactionPurification: 'ReactionPurification',
  CopyFromTemplate: 'CopyFromTemplate',
};

const initialPagination: IPagination = {
  page: 1,
  pageCount: 1,
  pageSize: null,
  total: null,
};

export const initialReactionSearchParams: ICatalogReactionRequestParams = {
  IncludeArchived: true,
  IncludeCompleted: true,
  IncludeExpYield: true,
  IncludeIncomplete: true,
  IncludeRegular: true,
  IncludeTrialFailed: true,
  IncludeTrialIncomplete: true,
  IncludeTrialSuccessful: true,
  IncludeWithoutExpYield: true,
  Author: null,
  CompleteDateFrom: null,
  CompleteDateTo: null,
  CreationDateFrom: null,
  CreationDateTo: null,
  ProjectId: null,
  SmilesFormula: null,
  ReactionName: null,
  FinalCandidateCode: null,
  ReactionTypes: null,
  OnlyTemplateReactions: false,
};

const defaultReactionModuleStore: IReactionModuleStore = {
  list: {
    pagination: initialPagination,
    searchParams: initialReactionSearchParams,
    allProjects: [],
  },
  quickComment: {
    savedSuccessful: null,
  },
  reactionsSideBar: {
    folders: [],
    reactions: [],
    reactionsPagination: null,
    historicalCount: null,
  },
  jarStock: {
    selectedJars: [],
    invalidAgentsIndexList: [],
    savedJars: [],
    agentsData: [],
  },
  repkaTask: {
    list: [],
    selected: null,
    saved: null,
  },
  editReaction: {
    current: {
      reactionId: null,
      stageId: null,
      savedSuccess: null,
      isPurification: false,
      smilesFormula: null,
      isArchived: false,
    },
    cloning: {
      cloneSourceReactionId: null,
    },
    highlightedSubstance: null,
    redirectToReaction: null,

    dialogsQueue: [],
    registeredDialogs: [],
    substanceJars: [],
  },
  reactionComplete: {
    isSuccess: null
  },
  jarSpendings: {
    jars: []
  },
  eventsHistory: {
    pagination: initialPagination,
  },
  attachments: {
    added: [],
    deleted: [],
  },
  settings: {
    repkaRedirectTaskUrl: '',
  },
  template: {
    isPending: false,
    specTemplateApplied: false,
    searchedReaction: null,
    reagents: [],
    reactantsInfo: [],
    reactionTypes: [],
    templateReactions: [],
    emptyJarsInfo: [],
    hasEmptyJars: null,
  }
};

export const reactionModuleReducer = (state: IReactionModuleStore = defaultReactionModuleStore, action: any = {}) => {
  const reducedFn = {
    [reactionCatalogResourceActions.list.success]: (action: ISuccessResourceAction): IReactionModuleStore => ({
      ...state,
      list: {
        ...state.list,
        pagination: { ...state.list.pagination, ...action.pagination },
      }
    }),

    [reactionModuleActions.sidebar.setHistoricalCount]: (action): IReactionModuleStore => ({
      ...state,
      reactionsSideBar: {
        ...state.reactionsSideBar,
        historicalCount: action?.count,
      }
    }),

    [reactionsResourceActions.list.request]: (): IReactionModuleStore => ({
      ...state,
      reactionsSideBar: {
        ...state.reactionsSideBar,
        reactions: [],
        reactionsPagination: null,
      }
    }),

    [reactionsResourceActions.list.success]: (action: ISuccessResourceAction): IReactionModuleStore => ({
      ...state,
      reactionsSideBar: {
        ...state.reactionsSideBar,
        reactions: action?.result,
        reactionsPagination: action?.pagination,
      }
    }),

    [_temp_folderResourceAction.list.success]: (action: ISuccessResourceAction): IReactionModuleStore => ({
      ...state,
      reactionsSideBar: {
        ...state.reactionsSideBar,
        folders: action.result,
      }
    }),

    [reactionModuleActions.catalogSearchParams.update]: (action): IReactionModuleStore => ({
      ...state,
      list: {
        ...state.list,
        searchParams: {
          ...state.list.searchParams,
          ...action.params,
          preventSearchRequest: action.params.preventSearchRequest === true ? action.params.preventSearchRequest : false
        }
      }
    }),

    [projectActions.getAll.success]: (action: ISuccessResourceAction): IReactionModuleStore => ({
      ...state,
      list: {
        ...state.list,
        allProjects: action.result.map((project: IProject): OptionType => ({ value: project.Id, label: project.Name })),
      }
    }),

    [reactionsResourceActions.quickComment.request]: (): IReactionModuleStore => ({
      ...state,
      quickComment: {
        savedSuccessful: null,
      }
    }),

    [reactionsResourceActions.quickComment.success]: (): IReactionModuleStore => ({
      ...state,
      quickComment: {
        savedSuccessful: true,
      }
    }),

    [reactionsResourceActions.quickComment.failure]: (): IReactionModuleStore => ({
      ...state,
      quickComment: {
        savedSuccessful: false,
      }
    }),

    [reactionsResourceActions.selectFolder.request]: (): IReactionModuleStore => ({
      ...state,
      selectFolder: {
        savedSuccessful: null,
      }
    }),

    [reactionsResourceActions.selectFolder.success]: (): IReactionModuleStore => ({
      ...state,
      selectFolder: {
        savedSuccessful: true,
      }
    }),

    [reactionsResourceActions.selectFolder.failure]: (): IReactionModuleStore => ({
      ...state,
      selectFolder: {
        savedSuccessful: false,
      }
    }),

    [reactionsResourceActions.createExtraNestedPurification.success]: (action): IReactionModuleStore => ({
      ...state,
      editReaction: {
        ...state.editReaction,
        redirectToReaction: action.result.Id,
      }
    }),

    [reactionModuleActions.clear]: (): IReactionModuleStore => ({
      ...defaultReactionModuleStore
    }),

    [reactionModuleActions.reactionEdit.setCurrentReactionId]: (action: { reactionId: number }): IReactionModuleStore => ({
      ...state,
      editReaction: {
        ...state.editReaction,
        current: {
          ...state.editReaction.current,
          reactionId: action.reactionId,
        }
      }
    }),

    [reactionModuleActions.reactionEdit.setCloneSourceReactionId]: (action: { sourceReactionId: number }): IReactionModuleStore => ({
      ...state,
      editReaction: {
        ...state.editReaction,
        cloning: {
          ...state.editReaction.cloning,
          cloneSourceReactionId: action.sourceReactionId,
        }
      }
    }),

    [reactionModuleActions.reactionEdit.setCurrentStageId]: (action: { stageId: number }): IReactionModuleStore => ({
      ...state,
      editReaction: {
        ...state.editReaction,
        current: {
          ...state.editReaction.current,
          stageId: action.stageId,
        }
      }
    }),

    [reactionModuleActions.reactionEdit.setCurrentFolderId]: (action: { folderId: number }): IReactionModuleStore => ({
      ...state,
      editReaction: {
        ...state.editReaction,
        current: {
          ...state.editReaction.current,
          folderId: action.folderId,
        }
      }
    }),

    [reactionModuleActions.reactionEdit.setCurrentSaveStatus]: (action: { status: boolean }): IReactionModuleStore => ({
      ...state,
      editReaction: {
        ...state.editReaction,
        current: {
          ...state.editReaction.current,
          savedSuccess: typeof action.status === 'boolean' ? action.status : null
        }
      }
    }),

    [reactionModuleActions.reactionEdit.setIsPurification]: (action: { isPurification: boolean }): IReactionModuleStore => ({
      ...state,
      editReaction: {
        ...state.editReaction,
        current: {
          ...state.editReaction.current,
          isPurification: action.isPurification
        }
      }
    }),

    [reactionModuleActions.reactionEdit.setIsArchived]: (action: { isArchived: boolean }): IReactionModuleStore => ({
      ...state,
      editReaction: {
        ...state.editReaction,
        current: {
          ...state.editReaction.current,
          isArchived: action.isArchived
        }
      }
    }),

    [reactionModuleActions.reactionEdit.setSmilesFormula]: (action: { smilesFormula: String }): IReactionModuleStore => ({
      ...state,
      editReaction: {
        ...state.editReaction,
        current: {
          ...state.editReaction.current,
          smilesFormula: action.smilesFormula
        }
      }
    }),

    [reactionModuleActions.reactionEdit.setCompoundName]: (action: { compoundName: String }): IReactionModuleStore => ({
      ...state,
      editReaction: {
        ...state.editReaction,
        current: {
          ...state.editReaction.current,
          compoundName: action.compoundName,
        }
      }
    }),

    [reactionModuleActions.reactionEdit.setNestedPurification]: (action: { data: any }): IReactionModuleStore => ({
      ...state,
      editReaction: {
        ...state.editReaction,
        current: {
          ...state.editReaction.current,
          ...{
            isPurification: true,
            isNestedPurification: true,
            primaryReactionId: action.data.primaryReactionId,
            primaryReactionName: action.data.primaryReactionName,
          },
          ...(action.data.compoundName ? { compoundName: action.data.compoundName } : null),
          ...(action.data.smilesFormula ? { smilesFormula: action.data.smilesFormula } : null),
        }
      }
    }),

    [reactionModuleActions.reactionEdit.setHoveredImage]: (action: { idx: ?number }): IReactionModuleStore => ({
      ...state,
      editReaction: {
        ...state.editReaction,
        highlightedSubstance: action.idx
      }
    }),

    [reactionModuleActions.jarStock.selectedJars.add]: (action: { jar: IJarStock }): IReactionModuleStore => {
      return {
      ...state,
      jarStock: {
        ...state.jarStock,
        selectedJars: [
          ...state.jarStock.selectedJars,
          action.jar
        ],
      }
    }},

    [reactionRepkaTaskListActions.success]: (action): IReactionModuleStore => ({
      ...state,
      repkaTask: {
        ...state.repkaTask,
        list: action.result,
      }
    }),

    [reactionModuleActions.repkaTask.clearList]: (): IReactionModuleStore => ({
      ...state,
      repkaTask: {
        ...state.repkaTask,
        list: [],
      }
    }),

    [reactionModuleActions.repkaTask.load]: (): IReactionModuleStore => ({
      ...state,
      repkaTask: {
        ...state.repkaTask,
        selected: cloneDeep(state.repkaTask.saved),
      }
    }),

    [reactionModuleActions.repkaTask.save]: (): IReactionModuleStore => ({
      ...state,
      repkaTask: {
        ...state.repkaTask,
        saved: cloneDeep(state.repkaTask.selected),
      }
    }),

    [reactionModuleActions.repkaTask.select]: (action): IReactionModuleStore => ({
      ...state,
      repkaTask: {
        ...state.repkaTask,
        selected: action.task,
      }
    }),

    [reactionModuleActions.repkaTask.destroy]: (): IReactionModuleStore => ({
      ...state,
      repkaTask: {
        list: [],
        selected: null,
      }
    }),

    [reactionModuleActions.jarStock.selectedJars.delete]: (action: {id: number, reagentId: any}): IReactionModuleStore => {

      return {
      ...state,
      jarStock: {
        ...state.jarStock,
        selectedJars: [
          ...state.jarStock.selectedJars.filter(x => x.Id !== action.id || x.reagentId != action.reagentId)
        ]
      }
    }},

    [reactionModuleActions.jarStock.validateAgents]: (action): IReactionModuleStore => {
      const idList = new Set(state.jarStock.selectedJars.map(x => x?.AgentId));

      let invalidIndexList =
        action.agentsId.map((x, index) => !idList.has(+x) ? index : null)
          .filter(x => typeof x === 'number');

      console.log('validate1', invalidIndexList, action);
      if(invalidIndexList.length == 0) {
        const idReagentsList = new Set(state.jarStock.selectedJars.map(x => x?.reagentId));
        invalidIndexList =
        action.reagents.map((x, index) => !idReagentsList.has(x.localUuid) ? index : null)
          .filter(x => typeof x === 'number');
          console.log('validate2', invalidIndexList);
      }

      return {
        ...state,
        jarStock: {
          ...state.jarStock,
          invalidAgentsIndexList: [
            ...invalidIndexList
          ],
        }
      };
    },

    [reactionModuleActions.jarStock.setAgentsData]: (action): IReactionModuleStore => ({
      ...state,
      jarStock: {
        ...state.jarStock,
        agentsData: action.agents,
      }
    }),

    [reactionModuleActions.jarStock.loadSaved]: (): IReactionModuleStore => ({
      ...state,
      jarStock: {
        ...state.jarStock,
        selectedJars: [
          ...state.jarStock.savedJars.map((j) => JarStock_Model(j)),
        ],
      }
    }),

    [reactionModuleActions.jarStock.save]: (): IReactionModuleStore => ({
      ...state,
      jarStock: {
        ...state.jarStock,
        selectedJars: [],
        invalidAgentsIndexList: [],
        savedJars: [
          ...state.jarStock.selectedJars.filter((j) => !j.IsOrdered).map((j) => JarStock_Model(j)),
        ]
      }
    }),

    [reactionModuleActions.jarStock.destroy]: (): IReactionModuleStore => ({
      ...state,
      jarStock: {
        ...state.jarStock,
        selectedJars: [],
        invalidAgentsIndexList: [],
        savedJars: [],
        agentsData: [],
      }
    }),

    [jarStockResourceActions.getJar.success]: (action): IReactionModuleStore => ({
      ...state,
      jarSpendings: {
        ...state.jarSpendings,
        jars: [
          ...action.result
        ],
      }
    }),

    [jarSpendingResourceActions.destroy]: (): IReactionModuleStore => ({
      ...state,
      jarSpendings: {
        ...defaultReactionModuleStore.jarSpendings,
      }
    }),

    [jarStockResourceActions.getProvidedJarsByReaction.success]: (action): IReactionModuleStore => ({
      ...state,
      editReaction: {
        ...state.editReaction,
        substanceJars: [
          ...action.result
        ],
      }
    }),

    [jarStockResourceActions.getUsedJarsByReaction.success]: (action): IReactionModuleStore => ({
      ...state,
      editReaction: {
        ...state.editReaction,
        substanceJars: [
          ...action.result
        ],
      }
    }),

    [reactionEventsHistoryResourceActions.list.success]: (action): IReactionModuleStore => ({
      ...state,
      eventsHistory: {
        ...state.eventsHistory,
        pagination: { ...state.eventsHistory.pagination, ...action.pagination },
      }
    }),

    [reactionModuleActions.reactionEdit.openDialog]: (action: { name: string, id: string }): IReactionModuleStore => {
      if (!(action.name in ReactionEditDialogsMap) || !action.id) return state;
      const last = state.editReaction.dialogsQueue.slice(-1)[0];
      if (last && last.id === action.id) {
        return state;
      }
      return {
        ...state,
        editReaction: {
          ...state.editReaction,
          dialogsQueue: [...state.editReaction.dialogsQueue, { id: action.id, name: action.name }]
        }
      };
    },

    [reactionModuleActions.reactionEdit.closeLastDialog]: (): IReactionModuleStore => {
      if (!state.editReaction.dialogsQueue.length) return state;
      return {
        ...state,
        editReaction: {
          ...state.editReaction,
          dialogsQueue: state.editReaction.dialogsQueue.slice(0, -1)
        }
      };
    },

    [reactionModuleActions.reactionEdit.setDialogsQueue]: (action: { dialogs: TEditReactionDialogAction[] }): IReactionModuleStore => ({
      ...state,
      editReaction: {
        ...state.editReaction,
        dialogsQueue: action.dialogs
      }
    }),

    [reactionModuleActions.reactionEdit.registerDialog]: (action: { name: string, id: string }): IReactionModuleStore => ({
      ...state,
      editReaction: {
        ...state.editReaction,
        registeredDialogs: [
            ...state.editReaction.registeredDialogs.filter((d) => d.id !== action.id),
          { name: action.name, id: action.id }
        ]
      }
    }),

    [reactionModuleActions.reactionEdit.removeDialogFromQueue]: (action: TEditReactionDialogAction): IReactionModuleStore => ({
      ...state,
      editReaction: {
        ...state.editReaction,
        dialogsQueue: state.editReaction.dialogsQueue.filter((dialog: TEditReactionDialogAction) => dialog.id !== action.id)
      }
    }),

    [reactionModuleActions.reactionEdit.insertDialogToQueue]: (action: TEditReactionDialogAction & { position: number }): IReactionModuleStore => ({
      ...state,
      editReaction: {
        ...state.editReaction,
        dialogsQueue: [
          ...state.editReaction.dialogsQueue.slice(0, action.position),
          {
            id: action.id,
            name: action.name
          },
          ...state.editReaction.dialogsQueue.slice(action.position)
        ]
      }
    }),

    [reactionModuleActions.reactionEdit.clearDialogsQueue]: (): IReactionModuleStore => ({
      ...state,
      editReaction: {
        ...state.editReaction,
        dialogsQueue: [...defaultReactionModuleStore.editReaction.dialogsQueue]
      }
    }),

    [reactionModuleActions.reactionComplete.success]: (action: { isSuccess: boolean }): IReactionModuleStore => ({
      ...state,
      reactionComplete: {
        ...state.reactionComplete,
        isSuccess: action.isSuccess
      }
    }),

    [reactionModuleActions.reactionEdit.addReactionAttachments]: (action): IReactionModuleStore => ({
      ...state,
      attachments: {
        ...state.attachments,
        added: [...state.attachments.added, ...action.attachments],
      },
    }),

    [reactionModuleActions.reactionEdit.deleteReactionAttachments]: (action): IReactionModuleStore => ({
      ...state,
      attachments: {
        ...state.attachments,
        deleted: [...state.attachments.deleted, ...action.attachments],
      },
    }),

    [reactionModuleActions.reactionEdit.clearReactionAttachments]: (): IReactionModuleStore => ({
      ...state,
      attachments: {
        ...state.attachments,
        added: [],
        deleted: [],
      },
    }),

    [reactionModuleActions.settings.setRepkaRedirectTaskUrl]: (): IReactionModuleStore => ({
      ...state,
      settings: {
        ...state.settings,
        repkaRedirectTaskUrl: action.url,
      },
    }),

    [reactionModuleActions.template.setSearchedReaction]: (action) => ({
      ...state,
      template: {
        ...state.template,
        searchedReaction: action.result,
      }
    }),

    [reactionModuleActions.template.setIsPending]: (action) => ({
      ...state,
      template: {
        ...state.template,
        isPending: action.value,
      }
    }),

    [reactionModuleActions.template.setReagents]: (action) => ({
      ...state,
      template: {
        ...state.template,
        reagents: action.reagents,
      }
    }),

    [reactionModuleActions.template.setReactantsInfo]: (action) => ({
      ...state,
      template: {
        ...state.template,
        reactantsInfo: action.reactants,
      }
    }),

    [reactionTypesResourceActions.getTemplateReactionTypes.success]: (action) => ({
      ...state,
      template: {
        ...state.template,
        reactionTypes: action.result,
      }
    }),

    [reactionsResourceActions.getTemplateReactions.success]: (action) => ({
      ...state,
      template: {
        ...state.template,
        templateReactions: action.result,
      }
    }),

    [reactionModuleActions.template.clear]: () => ({
      ...state,
      template: {
        ...defaultReactionModuleStore.template,
      }
    }),

    [reactionModuleActions.template.specTemplateApplied]: () => ({
      ...state,
      template: {
        ...state.template,
        specTemplateApplied: action.value
      }
    }),
    [reactionModuleActions.template.setEmptyJarsInfo]: (action) => ({
      ...state,
      template: {
        ...state.template,
        emptyJarsInfo: action.emptyJarsInfo,
      }
    }),
    [reactionModuleActions.template.setHasEmptyJars]: (action) => ({
      ...state,
      template: {
        ...state.template,
        hasEmptyJars: action.hasEmptyJars,
      }
    })
  }[action.type];
  return reducedFn ? reducedFn(action) : state;
};
