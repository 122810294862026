// @flow

import React, { useEffect } from 'react';
import { TabList, Tab } from '../../../shared/components/TabList';
import { ReagentsTable } from './ReagentsTable';
import { Field } from 'redux-form';
import { ReactionKeyNames, ReactionAttachmentType, ReactionAttachment } from '../../models';
import { WysiwygEditorFieldComponent } from '../../../shared/components/WysiwygEditor';
import styled from 'styled-components';
import EventsHistory from '../EventsHistory';
import { connect } from 'react-redux';
import type { IRootStore } from '../../../app/reducers';
import {
	getCurrentReaction,
	getCurrentReactionStates,
	getReactionAttachments,
	getReactionFilesByAttachmentType,
	getReactionFileCountsByAttachmentType,
} from '../../reducers';
import type { TCurrentReactionStates, TReactionEditCurrent } from '../../reducers';
import type { IReaction } from '../../models';
import { getReactionFromForm } from '../../reducers/reaction-edit-form-plugin';
import { ArchivedReagentsTable } from './ArchivedReagentsTable';
import { smEntityTypes } from '../../../analytics/models';
import Analytics from '../../../analytics/components';
import { FileUploader } from '../../../attachments/components/FileUploader';
import { reactionModuleActions } from '../../actions';
import { attachmentsActions } from '../../../attachments/actions';
import { userCRUDPermissionsSelector } from '../../../account/reducers/users-modules-reducer';
import { ReactionIndicators } from './ReactionIndicators';
import { SeriesInfoForm } from './SeriesInfoForm';
import EquipmentTable from './EquipmentTable';

interface IReactionEditTabsProps {
	className?: string,
	currentReaction?: TReactionEditCurrent,
	reaction?: IReaction,
	reactionStates?: TCurrentReactionStates,
	attachments?: [],
	newFiles?: [],
	addAttachments: () => {},
	deleteAttachments: () => {},
	clearNewFiles: () => {},
	userPermissions?: {},
	files?: {},
	fileCounts?: {},
	onTabSelected: (tabNum:number) => null,
	selectedTab?: number,
	fullData?: boolean,
	printMode: boolean,
}

const defaultProps: IReactionEditTabsProps = {
	className: '',
	selectedTab: 0,
	fullData: false,
	printMode: false,
};

let ReactionEditTabs = (props: IReactionEditTabsProps = defaultProps) => {

	useEffect(() => {
		if (!props.newFiles || !props.newFiles.length || props.printMode) {
			return;
		}

		const attachments = props.newFiles
			.map(a => ReactionAttachment({
				ReactionAttachmentType: a.Tag,
				AttachmentId: a.Id,
				Attachment: a.Attachment,
				IsDeleted: false,
			}));

		props.addAttachments(attachments);
		props.clearNewFiles();
	}, [props.newFiles]);

	window.testFiles = props.files;

	const deleteAttachment = (attachment) => {
		props.deleteAttachments([attachment]);
	};

	const onTabSelected = (event, idx) => {
		if (props.onTabSelected && typeof props.onTabSelected === 'function')
			props.onTabSelected(idx);
	};

	const AttachmentTabTitle = (name, type) => (<>{name}&nbsp;<sup className={'attachment-count'}>{props.fileCounts[type]}</sup></>);

	return (
		<TabList className={props.className} onTabTitleClick={onTabSelected} selectedTab={props.selectedTab}>
			<Tab
				title={'Свойства вещ-ва'}
			>
				{
					props.reaction?.IsArchived
						? <ArchivedReagentsTable />
						: <ReagentsTable className={'reagentsTable'} />
				}
			</Tab>
			<Tab
				title={'Методика'}
				componentClassName={'wysiwyg-holder full-height'}
			>
				<div className='toShow'>
					<Field
						name={ReactionKeyNames.Methodology}
						component={WysiwygEditorFieldComponent}
						isDisabled={!props.reactionStates?.isTabsEditable}
					/>
				</div>
				<div className='toPrint' dangerouslySetInnerHTML={{
					__html: props.reaction?.Methodology
				}}>
				</div>
			</Tab>
			{
				props.currentReaction?.reactionId &&
				<Tab title={'PP'}>
					<ReactionIndicators
						reactionId={props.currentReaction?.reactionId}	
						isDisabled={!props.reactionStates?.isTabsEditable}					
					/>
				</Tab>
			}
			<Tab
				title={'Оборудование'}>
				<EquipmentTable reaction={props.reaction} />
			</Tab>
			<Tab
				title={'Комментарий'}
				componentClassName={'wysiwyg-holder full-height'}
			>
				<Field
					name={ReactionKeyNames.Comment}
					component={WysiwygEditorFieldComponent}
					isDisabled={!props.reactionStates?.isTabsEditable}
				/>
			</Tab>
			<Tab
				title={AttachmentTabTitle('ВЭЖХ', ReactionAttachmentType.MS)}
				componentClassName={'wysiwyg-holder'}
			>
				<FileUploader
					files={props.files[ReactionAttachmentType.MS]}
					multiple={true}
					deleteHandler={(index) => deleteAttachment(props.files[ReactionAttachmentType.MS][index])}
					viewMode={null}
					canDeleteFiles={props.reactionStates?.isTabsEditable}
					canUploadFiles={'true'}
					tag={ReactionAttachmentType.MS}
					className={'fileuploader'}
				/>

				<Field
					name={ReactionKeyNames.MSText}
					component={WysiwygEditorFieldComponent}
					isDisabled={!props.reactionStates?.isTabsEditable}
				/>
			</Tab>
			<Tab
				title={AttachmentTabTitle('ЯМР', ReactionAttachmentType.NMR)}
				componentClassName={'wysiwyg-holder'}
			>
				<FileUploader
					files={props.files[ReactionAttachmentType.NMR]}
					multiple={true}
					deleteHandler={(index) => deleteAttachment(props.files[ReactionAttachmentType.NMR][index])}
					viewMode={null}
					canDeleteFiles={props.reactionStates?.isTabsEditable}
					canUploadFiles={'true'}
					tag={ReactionAttachmentType.NMR}
					className={'fileuploader'}
				/>

				<Field
					name={ReactionKeyNames.NMRText}
					component={WysiwygEditorFieldComponent}
					isDisabled={!props.reactionStates?.isTabsEditable}
				/>
			</Tab>
			<Tab
				title={AttachmentTabTitle('ТСХ', ReactionAttachmentType.TLC)}
				componentClassName={'wysiwyg-holder'}
			>
				<FileUploader
					files={props.files[ReactionAttachmentType.TLC]}
					multiple={true}
					deleteHandler={(index) => deleteAttachment(props.files[ReactionAttachmentType.TLC][index])}
					viewMode={null}
					canDeleteFiles={props.reactionStates?.isTabsEditable}
					canUploadFiles={'true'}
					tag={ReactionAttachmentType.TLC}
					className={'fileuploader'}
				/>

				<Field
					name={ReactionKeyNames.TLCText}
					component={WysiwygEditorFieldComponent}
					isDisabled={!props.reactionStates?.isTabsEditable}
				/>
			</Tab>
			<Tab
				title={AttachmentTabTitle('ВЭЖХ-МС', ReactionAttachmentType.HPLCMS)}
				componentClassName={'wysiwyg-holder'}
			>
				<FileUploader
					files={props.files[ReactionAttachmentType.HPLCMS]}
					multiple={true}
					deleteHandler={(index) => deleteAttachment(props.files[ReactionAttachmentType.HPLCMS][index])}
					viewMode={null}
					canDeleteFiles={props.reactionStates?.isTabsEditable}
					canUploadFiles={'true'}
					tag={ReactionAttachmentType.HPLCMS}
					className={'fileuploader'}
				/>

				<Field
					name={ReactionKeyNames.HPLCMSText}
					component={WysiwygEditorFieldComponent}
					isDisabled={!props.reactionStates?.isTabsEditable}
				/>
			</Tab>
			<Tab title={'Аналитика'}>
				{props.reaction && props.reaction.Name &&
					<Analytics entity={{
						id: props.currentReaction?.reactionId,
						type: smEntityTypes.Reaction,
						reaction: props.reaction
					}}
						preventDataLoading
						noHeader={true}
						readonly={!props.userPermissions.reactionAnalytics.UPDATE || !props.reactionStates?.isTabsEditable}
					/>}
			</Tab>
			<Tab
				title={AttachmentTabTitle('Вложения', ReactionAttachmentType.Other)}
				componentClassName={'wysiwyg-holder'}
			>
				<FileUploader
					files={props.files[ReactionAttachmentType.Other]}
					multiple={true}
					deleteHandler={(index) => deleteAttachment(props.files[ReactionAttachmentType.Other][index])}
					viewMode={null}
					canDeleteFiles={props.reactionStates?.isTabsEditable}
					canUploadFiles={'true'}
					tag={ReactionAttachmentType.Other}
					className={'fileuploader'}
				/>

				<Field
					name={ReactionKeyNames.AttachmentsText}
					component={WysiwygEditorFieldComponent}
					isDisabled={!props.reactionStates?.isTabsEditable}
				/>
			</Tab>
			<Tab
				title={'История'}
			>
				{
					props.currentReaction?.reactionId &&
					<div className={'eventsHistoryDiv'}>
						<EventsHistory className={'eventsHistory'}
							reactionId={props.currentReaction?.reactionId}
							fullData={false}
							preventDataLoading
						/>
					</div>
				}
			</Tab>
			<Tab title={'Информация о серии'} >
				<SeriesInfoForm 
					className={'seriesInfo'} 
					reaction={props.reaction} 
					isDisabled={!props.reactionStates?.isTabsEditable}
					/>
			</Tab>
		</TabList>
	);
};

ReactionEditTabs = connect(
	(state: IRootStore): IReactionEditTabsProps => ({
		currentReaction: getCurrentReaction(state),
		reaction: getReactionFromForm(state),
		attachments: getReactionAttachments(state),
		newFiles: state.global.attachments.uploadedFiles,
		reactionStates: getCurrentReactionStates(state),
		userPermissions: userCRUDPermissionsSelector(state),
		files: getReactionFilesByAttachmentType(state),
		fileCounts: getReactionFileCountsByAttachmentType(state),
	}),
	(dispatch) => ({
		addAttachments: (attachments) => dispatch(reactionModuleActions.reactionEdit.addReactionAttachments({ attachments })),
		deleteAttachments: (attachments) => dispatch(reactionModuleActions.reactionEdit.deleteReactionAttachments({ attachments })),
		clearNewFiles: () => dispatch(attachmentsActions.upload.destroy({})),
	})
)(ReactionEditTabs);

ReactionEditTabs = styled(ReactionEditTabs)`
	--file-uploader-height: 140px;
	.wysiwyg-holder {
		--wysiwyg-general-width: 100%;
		--wysiwyg-general-height: calc(100% - var(--file-uploader-height));
		&.full-height {
			--wysiwyg-general-height: 100%;
		}
	}
	.tox-tinymce {
		border: 0;
		min-height: 100%;
	}
	.b-WysiwygEditor {
		overflow: auto;
		border-top: 1px solid var(--border-color);
	}
	.tox-toolbar {
		position: absolute;
		top: 0;
		left: 0; right: 0;
	}
	.tox-sidebar-wrap {
		padding-top: 38px;
	}
	.fileuploader {
		display: block;
		padding: 16px;
	    overflow: auto;
    	height: var(--file-uploader-height);
		.b-button:first-child {
			margin-bottom: 12px;
		}
		hr {
			display: none;
		}
		ul {
			overflow-y: initial;
			li:hover {
				background-color: var(--hover-bg-color);
			} 
		}
	}
	.eventsHistory {
		height: calc(100% - 74px);
	}
	.tableTitle {
		display: flex;
		flex-orientation:row;
		align-items:center;
		padding-left:12px;
		flex-grow:1;
	}
	
	.dot {
		font-size:12px;
		color: white;
  		height: 20px;
  		width: 20px;
  		background-color: #F00;
  		margin:5px;
  		justify-content: center;
  		align-items: center;
  		border-radius: 100%;
  		text-align: center;
  		display: flex;
  	}
  	
  	.indicatorArea {
  		border-bottom:1px solid #ddd;
  	}
  	
  	.Collapsible__trigger {
  		display:flex;
  		flex-orientation:row;
  		align-items: center;
  		height:36px;
  		cursor: pointer;
  		&:after {
  			color:#B9BEC9;
  			font-family: "fo";
    		content: '\\e80f';
    		display: block;
    		transition: transform 300ms;
    		margin-right:12px;
  		}
  		&.is-open {
  			&:after {
  			transform: rotateZ(180deg);
  			margin-right:12px;
  			}
  		}
  	}
  	
  	.errorIfEmpty::after {
  		border-color:red;
  	}
  	
  	.equipmentArea {
  	    border-bottom:1px solid #ddd;
  	}

	.attachment-count {
		color: var(--grey-900);
	}
`;

export {
	ReactionEditTabs
};
