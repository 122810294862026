import React, {
	useEffect,
	useRef,
	useState
} from 'react';
import {
	REACTION_REACTANTS_ARRAY_FIELD_NAME,
} from './ReagentsTable';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import queryString from 'query-string';
import { reactionEventsHistoryResourceActions, reactionsResourceActions, reactionAttachmentsActions } from '../../actions/resource';
import {
	PageLayout,
	PageLayoutSlot,
	pageLayoutAreas,
	PageLayoutHeader,
	PageLayoutBody,
} from '../../../shared/components/PageLayout';
import type { IReaction, IReactionNameRequest, IRepkaTask } from '../../models';
import type { IRootStore } from '../../../app/reducers';
import Button from '@biocad/bcd-front-ui/controls/Button';
import { reduxForm } from 'redux-form';
import { ReactionImage } from '../ReactionImage';
import type { IProduct } from '../../../products/model';
import { Dispatch } from 'redux';
import { reactionModuleActions } from '../../actions';
import { globalErrorsActions } from '../../../shared/actions';
import { reagentsResourceActions } from '../../../reagents/actions';
import { productsResourceActions } from '../../../products/actions';
import { setGlobalLoader } from '../../../shared/actions/loading';
import { ReactionAsideForm } from './ReactionAsideForm';
import {
	getCloneSourceReactionId,
	getCurrentReaction,
	getCurrentReactionStates,
	getProductFromStage,
	getReactionEditFormInitValues, getReactionEditValues,
	getReactionPendingState,
	getSmilesFormulasFromParentStage, isReactionEditIsDirty, preventReactionFormSubmit,
	getComplexCompoundNamesFromParentStage,
	ReactionEditDialogsMap,
} from '../../reducers';
import type { IReagent } from '../../../reagents/model';
import { getReactantsFromForm, getReagentsFromForm, getReactionFromForm } from '../../reducers/reaction-edit-form-plugin';
import type { RecalcFunctionArgs } from '../../helpers/equation.helper';
import { ReactionEditTabs } from './ReactionEditTabs';
import { stageResourceActions } from '../../../stages/actions';
import { ReactionEditDialogWrap } from './ReactionEditDialogWrap';
import type { TEditReactionDialogAction } from '../../actions';
import type { IFormWrappedComponentProps } from '../../../shared/models';
import styled from 'styled-components';
import type { TCurrentReactionStates, TReactionEditCurrent } from '../../reducers';
import { accountActions } from '../../../account/actions';
import { reactantConversionToProduct } from '../../../products/utils/reactantConversionToProduct.js';
import { isProduct, isReagent, isCatalyst, isSolvent, isAuxiliary, isReactant } from '../../helpers/common';
import { ReduxFormAutosave } from '../../../shared/hocs/ReduxFormAutosave';
import dateTimeFormat from '@biocad/bcd-front-ui/utils/formatters/datetime';
import Tooltip from '@biocad/bcd-front-ui/layout/Tooltip';
import {userCRUDPermissionsSelector} from '../../../account/reducers/users-modules-reducer';
import {REACTION_TYPE_PURIFICATION_ID} from '../../../shared/globals/constants';
import {ReagentKeyNames} from '../../../reagents/model';
import { ReactionTypeIcon } from '../../../shared/components';
import { PurificationBundleTypeEnum } from '../../../stages/models';
import html2canvas from 'html2canvas';
import {getCurrentStage} from '../../../stages/reducers';
import { schemesResourceActions } from '../../../schemes/actions';

interface INestedPurificationData {
	primaryReactionId: Number;
	primaryReactionName: String;
	agentId: Number;
	smilesFormula: String;
	compoundName: String;
}

interface IReactionEditProps extends IFormWrappedComponentProps {
	match?: {
		params: {
			reactionId: String
		},
	},
	location: {
		search: String
	},
	history?: {
		push?: (string) => void,
	},
	className: string,
	getReaction: Function,
	reaction?: IReaction,
	reactants?: (IProduct | IReagent)[],
	reagents?: IReagent[],
	setGlobalError?: (string)=>void,
	clearStore?: ()=>void,
	setLoader?: (boolean) => void,
	pending?: boolean,
	setCurrentReactionId?: (number) => void,
	setCurrentStageId?: (number) => void,
	setCurrentFolderId?: (number) => void,
	current?: TReactionEditCurrent,
	recalcAll?: (RecalcFunctionArgs) => void,
	loadStage?: (id: number) => void,
	setDialogsQueue?: (TEditReactionDialogAction[])=> void,
	smilesFormulasFromStage?: string[],
	productFromStage?: IProduct,
	addProduct?: (IProduct) => void,
	repkaTaskFromDialog?: number,
	setRepkaTask?: (IRepkaTask) => void,
	getNewName?: (IReactionNameRequest) => void,
	saveReaction?: () => void,
	deleteReaction?: (id: number) => void,
	reactionStates?: TCurrentReactionStates,
	requestAccount?: () => void,
	setIsPurification?: (boolean) => void,
	setIsArchived?: (boolean) => void,
	setSmilesFormula?: (String) => void,
	setCompoundName?: (String) => void,
	setNestedPurification?: (INestedPurificationData) => void,
	reactionSavedSuccess?: boolean,
	isCloning?: boolean,
	cloneSourceReactionId?: number,
	preventSubmit?: boolean,
	userPermissions?: {},
	loadInitialData?: (isNew: boolean, isCloning: boolean) => void,
	checkSave?: (check: boolean) => void,
	hasEmptyJars?: boolean,
	emptyJarsInfo?: [],
	isPending?: boolean,
	specTemplateApplied?: boolean,
}

const REACTION_EDIT_FORM_NAME = 'reactionEditForm';

const defaultProps: IReactionEditProps = {
	className: '',
	reaction: null,
};

let ReactionEdit = (props: IReactionEditProps = defaultProps) => {
	// State
	const { reactionId } = props.match?.params;
	const showSubmitButton = props.reaction?.IsArchived
		? (props.userPermissions.archivedReactions.CREATE)
		: (props.userPermissions.reaction.CREATE);
	const [selectedTab, setSelectedTab] = useState(0);
	const [isClickedGetTechInstruction, setIsClickedGetTechInstruction] = useState(false);
	const [reactionImageSnapshot, setReactionImageSnapshot] = useState(false);


	const showTemplateButton = !props.reaction?.IsArchived
	  && !props.reaction?.IsCompleted
		&& props.reagents.every(r => !isCatalyst(r)
			&& !isSolvent(r)
			&& !isAuxiliary(r)
			&& (!isReactant(r) || r.IsRequired));

	// Refs
	const
		editSubstanceDialogRef = useRef(null),
		templateEditSubstanceDialogRef = useRef(null),
		repkaTaskDialogRef = useRef(null),
		createArchiveSubstances = useRef(null),
		deleteReactionConfirmRef = useRef(null),
		copyFromTemplateRef = useRef(null),
		emptyJarsInfoCached = useRef([])
	;

	const childReagents = props.reagents.filter(item=>item.ParentReagentId || item.ParentReagentLocalId);  
	let parentReagents = props.reagents.filter(item=> (!item.ParentReagentId && !item.ParentReagentLocalId));  
	parentReagents = parentReagents.filter(item => !childReagents.some(i => i.ParentReagentId && item.Id == i.ParentReagentId));  
	parentReagents = parentReagents.filter(item => !childReagents.some(i => i.ParentReagentLocalId && item.localUuid == i.ParentReagentLocalId));
	const resultReagents = [...childReagents,...parentReagents];
	const reagents2show = props.reagents.filter(i=> (i.Id!=0 && resultReagents.some(r=>r.Id == i.Id)) || i.localUuid && resultReagents.some(r=>r.localUuid == i.localUuid));
  
	// Effects
	const _initialReactionLoading = () =>
	{
		const isNew: boolean = reactionId === 'add' || isNaN(parseInt(reactionId, 10));
		if (!isNew)
		{
			props.setCurrentReactionId(parseInt(reactionId, 10));
		}
		else
		{
			const { 
				stageId, isPurification, smilesFormulaEncoded, 
				isArchived, folderId, compoundName, 
				isNestedPurification, primaryReactionId, primaryReactionNameEncoded, agentId,
			} = queryString.parse(props.location.search);
			const smilesFormula = smilesFormulaEncoded ? decodeURIComponent(smilesFormulaEncoded) : null;
			const primaryReactionName = primaryReactionNameEncoded ? decodeURIComponent(primaryReactionNameEncoded) : null;

			if (stageId && !isNaN(parseInt(stageId, 10)))
			{
				props.setCurrentStageId(parseInt(stageId, 10));
				if (folderId)
				{
					props.setCurrentFolderId(parseInt(folderId, 10));
				}
			} else if (isArchived) {
				const _isArchived =
					{
						'true' : true,
						'false': false
					}[isArchived] || null
				;
				props.setIsArchived(_isArchived);

				props.setDialogsQueue([
					{
						id: createArchiveSubstances.current.id,
						name: createArchiveSubstances.current.name
					}
				]);
			} else if (isPurification) {
				props.setIsPurification(isPurification);
				if (smilesFormula) {
					props.setSmilesFormula(smilesFormula);
				}

				if (compoundName) {
					props.setCompoundName(compoundName);
				}
			} else {
				props.setGlobalError('Не указана стадия!');
				props.history.push('/no-match');
			}
		}
		props.loadInitialData(isNew, props.isCloning)

	};
	useEffect(_initialReactionLoading, []);

	useEffect(() => {
		if(props.stage)
		props.getLastStage(props.stage?.ObtainmentSchemeId, false);
	}, [props.stage]);

	const _onReactionLoaded = () => {
		if (!props.reaction)
		{
			return;
		}

		if (props.isCloning && !props.current?.stageId)
		{
			const
				{ stageId } = queryString.parse(props.location.search),
				stageIdNum = parseInt(stageId, 10)
			;
			if (stageId && !isNaN(stageIdNum))
			{
				props.setCurrentStageId(stageIdNum);

				if (props.reaction.InitialStageId === stageIdNum)
				{
					props.setCurrentFolderId(props.reaction.FolderId);
				} 
				else
				{
					props.setCurrentFolderId(null);
				}

				props.loadStage(stageIdNum);
			} 
			else
			{
				props.setCurrentStageId(props.reaction.InitialStageId);
				props.setCurrentFolderId(props.reaction.FolderId);
				if(props.reaction.InitialStageId) {
					props.loadStage(props.reaction.InitialStageId);
				}
			}
		}
	};
	useEffect(_onReactionLoaded, [props.reaction]);

	const setUpDialogForNewReaction = () => {
		if (
			!props.current?.isPurification &&
			props.smilesFormulasFromStage &&
			Array.isArray(props.smilesFormulasFromStage)
		){
			props.setDialogsQueue([
				{
					id: repkaTaskDialogRef.current?.id,
					name: repkaTaskDialogRef.current?.name
				},
				{
					id: editSubstanceDialogRef.current?.id,
					name: editSubstanceDialogRef.current?.name
				},
			]);
		}
		else if (props.current?.isPurification
			    && (props.current?.smilesFormula
				|| props.reagents?.filter(i => i.Agent?.Compound?.SmilesFormula).length
				|| props.current?.compoundName
				|| props.current?.agentId))
		{
			props.setDialogsQueue([
				{
					id: editSubstanceDialogRef.current?.id,
					name: editSubstanceDialogRef.current?.name
				}
			]);
		}
	};

	const _setDialogsForNewReaction = () => {
		if (reactionId !== 'add' || props.reaction?.Id || props.reactionSavedSuccess) return;
		setUpDialogForNewReaction();
	};
	useEffect(_setDialogsForNewReaction, [
		props.smilesFormulasFromStage?.length, // такое нужно чтоб не вызывался эффект лишние разы
		props.current?.smilesFormula,
		props.current?.isPurification,
		props.reactionSavedSuccess,
		reactionId
	]);

	const _updateRepkaTaskOnSelect = () => {
		if (props.repkaTaskFromDialog) props.setRepkaTask(props.repkaTaskFromDialog);
	};
	useEffect(_updateRepkaTaskOnSelect, [props.repkaTaskFromDialog]);

	const _updateFormOnProductAdded = () => {
		if (props.productFromStage && !props.reactants?.find((r: IProduct) => r.IsMainProduct) && !props.current?.reactionId)
			props.addProduct(props.productFromStage);
	};
	useEffect(_updateFormOnProductAdded, [props.productFromStage]);

	const _onUnmount = () => () => {
		props.clearStore();
		props.checkSave(false);
	};
	useEffect(_onUnmount, []);

	const _clone = () => {
		if(props.isCloning 
			&& !props.pending 
			&& props.reactants?.some(i => isReagent(i) && i.Id)
			&& props.reactants?.some(i => isProduct(i) && i.Id))
		{
			setUpDialogForNewReaction();
			props.setCloneSourceReactionId(props.current.reactionId);
			props.cloneReaction();
		}
	};
	useEffect(_clone, [props.isCloning, props.pending, props.reactants]);

	const _recalcOnSubstancesUpload = () => {
		if (props.initialValues[REACTION_REACTANTS_ARRAY_FIELD_NAME]?.length) {
			props.recalcAll();
		}
	};
	useEffect(_recalcOnSubstancesUpload, [props.initialValues[REACTION_REACTANTS_ARRAY_FIELD_NAME]?.length]);

	const _saveBeforeGetTechInstructionHandle = () => {
		if (props.current?.savedSuccess === true && isClickedGetTechInstruction) {
			props.getTechInstruction(props.current?.reactionId, reactionImageSnapshot);
			setIsClickedGetTechInstruction(false);
		}
	};
	useEffect(_saveBeforeGetTechInstructionHandle, [props.current?.savedSuccess]);

	useEffect(() => {
		if (props.reactants.length === 1 && props.current.isPurification) {
			let product = reactantConversionToProduct(
				props.reactants[0],
				{ MainAgentContent: 100 }
			);
			props.addProduct(product);
		}
		// Workaround, чтобы в реакциях отчистки заставить провалидировать поле массы реагента, чтоб не давать сохранить с пустым полем.
		if (props.reactants.length === 2 && props.current.isPurification && !props.reaction.Id && !props.anyTouched) {
			props.touch(`${REACTION_REACTANTS_ARRAY_FIELD_NAME}[0].${ReagentKeyNames.Mass}`);
			props.blur(`${REACTION_REACTANTS_ARRAY_FIELD_NAME}[0].${ReagentKeyNames.Mass}`);
		}
	}, [props.reactants]);

	useEffect(() => {
		if (Array.isArray(props.emptyJarsInfo) && props.emptyJarsInfo.length && props.specTemplateApplied) {
			emptyJarsInfoCached.current = [...props.emptyJarsInfo.map((i) => ({...i, jars: [...i.jars]}))];
		
			setTimeout(() => {
				templateEditSubstanceDialogRef.current.open();
			}, 500);
		}
	}, [props.hasEmptyJars, props.emptyJarsInfo, props.specTemplateApplied]);

	// Handlers
	const
		gotoStage = () => {
			props.reaction?.PrimaryReactionId
				? goToPurificationReaction(props.reaction.PrimaryReactionId)
				: props.reaction?.InitialStageId ?
					props.history.push(`/stages/${props.current?.stageId}`) :
					props.history.push('/reactions/catalog');
		},
		cloneReaction = () => {
			if(props.reagents.some(r=>r.Id == 0))
				props.saveReaction();
			props.history.push(`/reactions/${reactionId}/clone`);
		},
		printReaction = () => {
			window.print();
		},
		getTechInstructionClick = () => {
			let rImage = document.querySelector('.pageLayout .b-ReactionImage');
			rImage.style.position = 'fixed';
			rImage.style.left = '10000px';
			rImage.style.width = rImage.scrollWidth + 'px';
			html2canvas(rImage, {scrollY: -window.scrollY, scrollX: -window.scrollX}).then(canvas => {
				setReactionImageSnapshot(canvas.toDataURL());
				props.saveReaction();
				setIsClickedGetTechInstruction(true);
				rImage.style.position = '';
				rImage.style.left = '';
				rImage.style.width = '';
			});

		},
		goToCatalog = () => {
			props.history.push('/reactions/catalog');
		},
		saveReaction = () => {
			props.saveReaction();
		},
		deleteReaction = () => {
			deleteReactionConfirmRef.current?.close();
			props.deleteReaction(props.current?.reactionId);
			gotoStage();
		},
		goToPurificationReaction = (id) => {
			if (props.reaction?.Id === id) {
				return;
			}
			props.history.push({
				pathname: '/reload',
				state: { url: `/reactions/${id}` }
			});
		};

	const selectTab = (tabNum) => {
		setSelectedTab(tabNum);
	};

	const getPurificationCode = (index) => props.reaction.PurificationBundleType === PurificationBundleTypeEnum.Parallel ? `1-${index + 1}` : `${index + 1}`;

	return (
		<div className={props.className}>
			<PageLayout className={'printLayout'}>
				<PageLayoutSlot gridAreaName={pageLayoutAreas.header} as={PageLayoutHeader}>
					<PageLayoutSlot gridAreaName={pageLayoutAreas.headerLeftSide}>
						<h2>
							{props.reaction?.Name}
							{
								props.reaction?.IsCompleted &&
								<span className="complete-sign">(реакция завершена)</span>
							}
						</h2>
					</PageLayoutSlot>
				</PageLayoutSlot>
				<PageLayoutSlot
					gridAreaName={pageLayoutAreas.body}
					as={PageLayoutBody}
					style={{
						'--LayoutBlock-padding-v': '0', 'display':'flex', 'flexDirection':'column'
					}}
				>
					<PageLayoutSlot
						gridAreaName={pageLayoutAreas.bodyTopLeft}
						as={ReactionImage}
						stageId={props.current?.stageId}
						reagents={props.reactants}
					/>
					<PageLayoutSlot
						gridAreaName={pageLayoutAreas.bodyBottomLeft}
						as={ReactionEditTabs}
						selectedTab={selectedTab}
						fullData={true}
						printMode={true}
					/>
				</PageLayoutSlot>
			</PageLayout>
			<PageLayout className={'pageLayout'}>
				<PageLayoutSlot
					gridAreaName={pageLayoutAreas.header}
					as={PageLayoutHeader}>
					{/* Место под хлебные крошки */}
					{/*<PageLayoutSlot gridAreaName={pageLayoutAreas.breadcrumbs} />*/}
					<PageLayoutSlot gridAreaName={pageLayoutAreas.headerBackBtn}>
						<Button
							className={'fo-folder-back'}
							onAction={gotoStage}
							view={'icon'}
							size={'L'}
						/>
					</PageLayoutSlot>
					<PageLayoutSlot gridAreaName={pageLayoutAreas.headerLeftSide}>
						<h2 className="reaction-name">
							<span>{props.reaction?.Name}</span>
							{
								!!props.reaction?.RepkaTaskId && 
								<a href={props.repkaRedirectTaskUrl + props.reaction.RepkaTaskId}
									className="fo-link-ext repka-link"
								/>
							}
							{
								props.reaction?.IsCompleted && 
								<span className="complete-sign">(завершена)</span>
							}

						</h2>
					</PageLayoutSlot>
					<PageLayoutSlot gridAreaName={pageLayoutAreas.headerCenter}>
						{
							props.reaction?.Id &&
							props.reaction.NestedPurifications &&
							props.reaction.NestedPurifications.length > 0 &&
							<div className="purification-reactions">
								<div className="purification-reactions-link active">
									<ReactionTypeIcon reaction={props.reaction}/>&nbsp;Синтез
								</div>
								{
									props.reaction.NestedPurifications.map((pr, index) => {
											return <div className="purification-reactions-link" key={pr.Id}>
												<Link onClick={(e) => (e.preventDefault(), goToPurificationReaction(pr.Id))}
													  to={`/reactions/${pr.Id}`}>
													<ReactionTypeIcon reaction={pr}/>&nbsp;Очистка&nbsp;{getPurificationCode(index)}
												</Link>
											</div>
										}
									)
								}
							</div>
						}
						{
							props.reaction?.Id &&
							props.reaction?.PrimaryReactionId &&
							props.reaction.PrimaryNestedPurifications &&
							props.reaction.PrimaryNestedPurifications.length > 0 &&
							<div className="purification-reactions">
								<div className={`purification-reactions-link`}>
									<Link onClick={(e) => (e.preventDefault(), goToPurificationReaction(props.reaction.PrimaryReactionId))}
										to={`/reactions/${props.reaction?.PrimaryReactionId}`}>
										<ReactionTypeIcon reaction={{ IsCompleted: true, IsTrial: false, ExperimentalYield: 1 }} />&nbsp;Синтез
									</Link>
								</div>
								{
									props.reaction.PrimaryNestedPurifications
										.sort((a,b)=>a.Id > b.Id?1:-1)
										.map((pr, index) => {
										const active = props.reaction?.Id === pr.Id;
										return <div className={`purification-reactions-link ${active ? 'active' : ''}`} key={pr.Id}>
											{
												active
													? <>
														<ReactionTypeIcon reaction={props.reaction} />&nbsp;Очистка&nbsp;{getPurificationCode(index)}
													  </>
													: <Link onClick={(e) => (e.preventDefault(), goToPurificationReaction(pr.Id))} to={`/reactions/${pr.Id}`}>
														<ReactionTypeIcon reaction={pr} />&nbsp;Очистка&nbsp;{getPurificationCode(index)}
													  </Link>
											}
										</div>
									})
								}
							</div>
						}
					</PageLayoutSlot>
					<PageLayoutSlot gridAreaName={pageLayoutAreas.headerRightSide} className={pageLayoutAreas.headerRightSide}>
						{
							(props.reactionStates?.isDeleteAvailable && props.userPermissions[props.reaction?.IsArchived ? 'archivedReactions' : 'reaction'].DELETE) &&
							<Button
								className={'fo-trash'}
								onAction={() => deleteReactionConfirmRef.current?.open()}
								size={'L'}
								view={'icon'}
							/>
						}
						{
							(
								props.reaction?.Id
							) ?
								<Button
									className={'fo-print'}
									onAction={printReaction}
									view={'icon'}
									size={'L'}
								/> : ''
						}
						{
							(
								props.reaction?.Id && showSubmitButton
							) ?
								<Button
									className={'fo-file-word'}
									onAction={getTechInstructionClick}
									view={'icon'}
									size={'L'}
								/> : ''
						}
						{
							(
								((props.reaction?.ReactionType === REACTION_TYPE_PURIFICATION_ID) || props.reaction?.InitialStageId)
								&& props.reaction?.Id
								&& props.userPermissions[props.reaction?.IsArchived ? 'archivedReactions' : 'reaction'].CREATE
								&& !props.reaction?.PrimaryReactionId
							) ?
							<Button
								className={'fo-copy'}
								onAction={cloneReaction}
								view={'icon'}
								size={'L'}
							/> : ''
						}

						{
							showTemplateButton &&
							<Button
								className={'cancel-btn'}
								onAction={() => copyFromTemplateRef.current?.open()}
								text={'Шаблон'}
								size={'L'}
							/>
						}		

						<Button
							className={'cancel-btn'}
							onAction={gotoStage}
							text={'Отмена'}
							size={'L'}
						/>
						
						{
							showSubmitButton &&
							<Button
								className={'save-btn'}
								onAction={saveReaction}
								text={'Сохранить'}
								size={'L'}
								view={'primary'}
								disabled={props.preventSubmit}
							>
								{
									(props.reaction?.Id && props.reaction?.ModificationDate)
										? <Tooltip.Hover place={'bottom'}>
											<small>
												Дата последнего сохранения:
												{ dateTimeFormat(new Date(props.reaction.ModificationDate), {
													format:'compact',
													time:true,
													locale: 'ru'
												}) }
											</small>
										</Tooltip.Hover>
										: ''
								}
							</Button>
						}
					</PageLayoutSlot>
				</PageLayoutSlot>
				<PageLayoutSlot
					gridAreaName={pageLayoutAreas.body}
					as={PageLayoutBody}
					style={{
						'--LayoutBlock-padding-v': '0'
					}}
				>
					<PageLayoutSlot
						gridAreaName={pageLayoutAreas.bodyTopLeft}
						as={ReactionImage}
						stageId={props.current?.stageId}
						reagents={props.reactants}
					/>
					<PageLayoutSlot
						gridAreaName={pageLayoutAreas.bodyBottomLeft}
						as={ReactionEditTabs}
						onTabSelected={selectTab}
						preventDadaLoading
					/>
					<PageLayoutSlot
						as={ReactionAsideForm}
						gridAreaName={pageLayoutAreas.bodyRight}
					/>
				</PageLayoutSlot>
				{/* Место под боковую панель навигации */}
				{/*<PageLayoutSlot gridAreaName={pageLayoutAreas.leftSide} />*/}
			</PageLayout>
			{/* Dialogs */}
			<ReactionEditDialogWrap
				dialogName={ReactionEditDialogsMap.EditSubstance}

				smilesFormulas={(props.isCloning && reagents2show.map(i => i.Agent.Compound.SmilesFormula))
					|| props.smilesFormulasFromStage || (props.current?.smilesFormula ? [props.current.smilesFormula] : [])}

				complexCompoundNames={(props.isCloning && reagents2show.filter(i => !i.Agent.Compound.SmilesFormula).map(i => i.Agent.Compound.Name)) 
					|| props.complexNamesFromStage || [props.current?.compoundName]}
				reagents={props.isCloning ? props.reagents : null}
				primaryReactionId={props.current?.primaryReactionId}
				getDialogRef={(dialog) => {
					if (dialog) editSubstanceDialogRef.current = dialog;
				}}
				onCancel={gotoStage}
			/>
			<ReactionEditDialogWrap
				dialogName={ReactionEditDialogsMap.EditSubstance}
				smilesFormulas={emptyJarsInfoCached.current?.filter((i) => i.complexCompoundName === null).map(i => i.smiles)}
				complexCompoundNames={emptyJarsInfoCached.current?.filter((i) => i.complexCompoundName !== null).map(i => i.complexCompoundName)}
				reagents={props.reagents.filter((r) => r.Id === 0)}
				primaryReactionId={props.current?.primaryReactionId}
				getDialogRef={(dialog) => {
					if (dialog) templateEditSubstanceDialogRef.current = dialog;
				}}
			/>
			<ReactionEditDialogWrap
				dialogName={ReactionEditDialogsMap.ChooseRepkaTask}
				stageId={props.current?.stageId}
				getDialogRef={(dialog) => {
					if (dialog) repkaTaskDialogRef.current = dialog;
				}}
				onCancel={gotoStage}
			/>
			<ReactionEditDialogWrap
				dialogName={ReactionEditDialogsMap.CreateArchivedReaction}
				getDialogRef={(dialog) => {
					if (dialog) createArchiveSubstances.current = dialog;
				}}
				onCancel={goToCatalog}
			/>
			<ReactionEditDialogWrap
				dialogName={ReactionEditDialogsMap.DeleteConfirm}
				getDialogRef={(dialog) => {
					if (dialog) deleteReactionConfirmRef.current = dialog;
				}}
				deleteReaction={deleteReaction}
			/>
			<ReactionEditDialogWrap
				dialogName={ReactionEditDialogsMap.CopyFromTemplate}
				getDialogRef={(dialog) => {
					if (dialog) copyFromTemplateRef.current = dialog;
				}}
			/>
		</div>

	);
};

ReactionEdit = reduxForm({
	form: REACTION_EDIT_FORM_NAME,
	destroyOnUnmount: true,
	enableReinitialize: true,
	shouldError: () => true
})(ReactionEdit);

// ReactionEdit = ReduxFormAutosave({
// 	form: REACTION_EDIT_FORM_NAME,
// 	submitForm: reactionModuleActions.reactionEdit.saveReaction,
// 	preventSubmitSelector: (state: IRootStore) => preventReactionFormSubmit(state),
// 	valueSelector: (state: IRootStore) => getReactionEditValues(state),
// 	isDirtySelector: (state: IRootStore) => isReactionEditIsDirty(state),
// })(ReactionEdit);

// ReactionEdit = ChangesWatcher({
// 	formName: REACTION_EDIT_FORM_NAME,
// 	hasChangesSelector: _noop
// }, ReactionEdit);

ReactionEdit = connect(
	(state: IRootStore): IReactionEditProps => ({
		stage: getCurrentStage(state),
		reaction: getReactionFromForm(state),
		reactants: getReactantsFromForm(state),
		reagents: getReagentsFromForm(state),
		pending: getReactionPendingState(state),
		current: getCurrentReaction(state),
		initialValues: getReactionEditFormInitValues(state),
		smilesFormulasFromStage: getSmilesFormulasFromParentStage(state),
		complexNamesFromStage: getComplexCompoundNamesFromParentStage(state),
		productFromStage: getProductFromStage(state),
		repkaTaskFromDialog: state.modules.reaction.repkaTask.saved,
		reactionStates: getCurrentReactionStates(state),
		cloneSourceReactionId: getCloneSourceReactionId(state),
		reactionSavedSuccess: state.modules.reaction.editReaction.current.savedSuccess,
		repkaRedirectTaskUrl: state.modules.reaction.settings.repkaRedirectTaskUrl,
		preventSubmit: preventReactionFormSubmit(state),
		userPermissions: userCRUDPermissionsSelector(state),
		hasEmptyJars: state.modules.reaction.template.hasEmptyJars,
		emptyJarsInfo: state.modules.reaction.template.emptyJarsInfo,
		isPending: state.modules.reaction.template.isPending,
		specTemplateApplied: state.modules.reaction.template.specTemplateApplied,
	}),
	(dispatch: Dispatch): IReactionEditProps => ({
		getReaction: (id: number) => dispatch(reactionsResourceActions.get.request({ uriParams: { id } })),
		setGlobalError: (error: string) => dispatch(globalErrorsActions.set({ error })),
		clearStore: () => [
			reactionsResourceActions.destroy(),
			reagentsResourceActions.destroy(),
			productsResourceActions.destroy(),
			stageResourceActions.destroy(),
			reactionEventsHistoryResourceActions.destroy(),
			reactionModuleActions.clear(),
			reactionAttachmentsActions.destroy(),
		].forEach(action => dispatch(action)),
		setLoader: (isLoading: boolean) => dispatch(setGlobalLoader({ isLoading })),
		setCurrentReactionId: (reactionId: number) => dispatch(reactionModuleActions.reactionEdit.setCurrentReactionId({ reactionId })),
		setCloneSourceReactionId: (sourceReactionId: number) => dispatch(reactionModuleActions.reactionEdit.setCloneSourceReactionId({ sourceReactionId })),
		setCurrentStageId: (stageId: number) => dispatch(reactionModuleActions.reactionEdit.setCurrentStageId({ stageId })),
		setCurrentFolderId: (folderId: number) => dispatch(reactionModuleActions.reactionEdit.setCurrentFolderId({ folderId })),
		recalcAll: () => dispatch(reactionModuleActions.recalc.all({})),
		loadStage: (id: number) => dispatch(stageResourceActions.get.request({ uriParams: { id } })),
		setDialogsQueue: (dialogs: TEditReactionDialogAction[]) => dispatch(reactionModuleActions.reactionEdit.setDialogsQueue({ dialogs })),
		addProduct: (product: IProduct) => dispatch(reactionModuleActions.reactionEdit.addProduct({ product })),
		setRepkaTask: (task: IRepkaTask) => dispatch(reactionModuleActions.reactionEdit.setReactionParentRepkaTaskId({ task })),
		getNewName: (params: IReactionNameRequest) => dispatch(reactionsResourceActions.getNewName.request({ params })),
		saveReaction: () => dispatch(reactionModuleActions.reactionEdit.saveReaction()),
		getTechInstruction: (reactionId, reactionImage) => dispatch(reactionModuleActions.reactionEdit.getTechInstruction({reactionId, reactionImage})),
		cloneReaction: () => dispatch(reactionModuleActions.reactionEdit.cloneReaction()),
		deleteReaction: (id: number) => dispatch(reactionsResourceActions.delete.request({ uriParams: { id } })),
		requestAccount: () => dispatch(accountActions.get.request()),
		setIsPurification: (isPurification: boolean) => dispatch(reactionModuleActions.reactionEdit.setIsPurification({ isPurification })),
		setIsArchived: (isArchived: boolean) => dispatch(reactionModuleActions.reactionEdit.setIsArchived({ isArchived })),
		setSmilesFormula: (smilesFormula: String) => dispatch(reactionModuleActions.reactionEdit.setSmilesFormula({ smilesFormula })),
		setCompoundName: (compoundName: String) => dispatch(reactionModuleActions.reactionEdit.setCompoundName({ compoundName })),
		setNestedPurification: (data: INestedPurificationData) => dispatch(reactionModuleActions.reactionEdit.setNestedPurification({data})),
		loadInitialData: (isNew: boolean, isCloning: boolean) => dispatch(reactionModuleActions.reactionEdit.loadInitialData({ isNew, isCloning })),
		checkSave: (check: boolean) => dispatch(reactionModuleActions.reactionEdit.checkSave({ check })),
		getLastStage: (id: number, shouldInitEntrySubstance: boolean) => dispatch(schemesResourceActions.getLastStage.request({ uriParams: { id }, params: { shouldInitEntrySubstance } })),
	})
)(ReactionEdit);

ReactionEdit = withRouter(ReactionEdit);

ReactionEdit = styled(ReactionEdit)`
  --LayoutHeader-margin-v: 0;
  --LayoutHeader-margin-h: 0;
  --LayoutBlock-margin-h: 0;
  --LayoutBlock-margin-v: 0;
  --LayoutBlock-padding-h: 0;
  --LayoutBlock-padding-v: 0;
  
  display: contents;

  .repka-link {
    text-decoration: none;
  }

  .reaction-name {
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;
  }

  .complete-sign {
    margin-left: 2px;
	color: gray;
	white-space: nowrap;
  }

  @media screen {
    .pageLayout {
      grid-template-rows: 80px minmax(620px,1fr) var(--grid-spacer)
    }
    .$ {
      pageLayoutAreas .header
    }
  	{
      padding: var(--grid-spacer) 0;
  	}

  	.printLayout {
      display: none;
  	}
  	
  	.toShow {
  	  display: block;
  	  height:100%;
  	} 	
  	
  	.toPrint {
  	  display: none;
  	} 
  	
  }

  @media print {
  	@page {
      size: A4 landscape;
      max-height:100%;
      max-width:100%;
  	}

  	.pageLayout {
      display: none;
  	}
  	
  	.printLayout {
      display: block;
      height: auto;
      overflow: visible !important;
  	}
  
	.b-TabList {
	  height: auto;
      overflow: visible !important;
	}
    .b-InlineEdit {
      &__valueHolder
      {
        &::after
        {
          border: 0px;
        }
      }
    }
    
    .fo-edit:before {
      content: '';
    }
    .fo-eye:before {
      content: '';
    }
    .fo-link-solid:before {
      content: '';
    }
    
    .b-ReactionImage {
      overflow: visible !important;
      flex-wrap: wrap;
      height: auto;
    }
    
    .b-Paginator {
  	  display: none;
  	}
  	
  	.grid-table-row * {
  	  page-break-inside: avoid;
  	}
  	
  	.grid-table-row-heading {
      padding-left:12px;
      padding-right:12px;
    }
  	
  	.grid-table-row {
  	  page-break-inside: avoid;
  	  padding-left:12px;
      padding-right:12px;
  	}
  	
  	.tox-toolbar {
      display: none !important;
  	}
  	
  	.tox-sidebar-wrap {
      padding-top: 0px;
  	}
  	
  	.b-TabList__titleList {
      display: none;
  	}
  	
  	.reagentsTable {
      height: auto;
      overflow-x: visible !important;
      overflow-y: visible !important;
      width:100%;
  	}
  	
  	.eventsHistory {
      height: auto;
      overflow: visible !important;
  	}
  	
  	.Collapsible__contentOuter {
  		height:auto !important;
  	}
  	
  	.toShow {
  	  display: none;
  	} 	
  	
  	.toPrint {
  	  display: block;
  	  padding-left:12px;
  	  overflow: visible !important;
  	} 
  }  
  
	.${pageLayoutAreas.headerRightSide} {
		margin-right: calc(var(--grid-spacer) * 2.25);
		.${Button.block} {
			&:not(:last-of-type) {
				margin-right: var(--grid-spacer);
			}
		}	
	}

	.purification-reactions {
		display: flex;
		overflow-x: auto;
		overflow-y: hidden;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		padding: 0 0.5em;

		.purification-reactions-link {
			flex-grow: 0;
			flex-shrink: 0;
			padding: 0 0.5em;
			white-space: nowrap;
			
			a {
				cursor: pointer;
				color: var(--grey-800);			
			}

			.typeIcon {
				width: 14px;
				height: 14px;
				margin: 0;
				margin-top: 4px;
				vertical-align: text-top;
			}

			&.active {
				font-weight: bold;
			}
		}
	}
`;

export {
	ReactionEdit,
	REACTION_EDIT_FORM_NAME,
};
