import {
  apiRequestSingle,
} from '../../shared/services/api/common';

export const ANALYTICS_LINK = 'analytic_small_molecules';

export class AnalyticsService {
  constructor(token) {
      const headers = token ? {
          Authorization: token
      } : {};

      this._getSmallMoleculesAvailability = id => apiRequestSingle('GET', `/availabilities/${id}/${ANALYTICS_LINK}`, headers);
      this._getSmallMoleculesReaction = id => apiRequestSingle('GET', `/reactions/${id}/${ANALYTICS_LINK}`, headers);
      this._updateSmallMolecule = apiRequestSingle('PUT', `/${ANALYTICS_LINK}`, headers);
      this._createSmallMolecule = apiRequestSingle('POST', `/${ANALYTICS_LINK}`, headers);
      this._deleteSmallMolecule = (id) => apiRequestSingle('DELETE', `/${ANALYTICS_LINK}/${id}`, headers);
  }

  
  getSmallMoleculesAvailability({ id }) {
      return this._getSmallMoleculesAvailability(id)();
  }

  getSmallMoleculesReaction({ id }) {
      return this._getSmallMoleculesReaction(id)();
  }

  updateSmallMolecule(molecule) {
      return this._updateSmallMolecule({}, molecule);
  }
  createSmallMolecule(molecule) {
      return this._createSmallMolecule({}, molecule);
  }
  deleteSmallMolecule(molecule) {
      return this._deleteSmallMolecule(molecule.Id)({}, molecule);
  }
}