import React from 'react';
import {connect} from 'react-redux';
import {IFinaleCandidate} from '../../../models/i-final-candidate';

interface IViewCandidateProps {
    currentCandidate: IFinaleCandidate
}

const initialProps = {
    currentCandidate: null
};


const ViewCandidate = (props: IViewCandidateProps = initialProps) => {

    const viewCandidateContainerStyles = {
        position: 'relative',
        width: '100%',
        height: '100%',
        backgroundImage: 'url("' + props.currentCandidate?.Compound?.Thumbnail + '")',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        zIndex: '9'
    };

    return (
        <div style={viewCandidateContainerStyles}/>
    );
};

export default connect(
    (state): IViewCandidateProps => ({
        currentCandidate: state.modules.candidate.candidate
    }),
    null
)(ViewCandidate);
