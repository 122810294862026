// @flow

export const STAGE_MERGE_OPTION_ENUM = Object.freeze({
  DEFAULT         : '-default',
  AGENTS          : '-agents',
  SCHEME          : '-scheme',
  UNITE_SCHEME    : '-uniteScheme',
  MARVIN          : '-marvin',
  FINAL_CANDIDATE : '-finalCandidate',
});
