import Button from '@biocad/bcd-front-ui/controls/Button';
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { Field, Form, getFormValues, reduxForm, reset } from 'redux-form';
import { BcdCheckboxWrapper, BcdInputWrapper, DatePickerInput, FormFieldLayout } from '../../../../shared/components';
import styles from './form.m.less';


export const REACTION_FILTER_FORM_NAME = 'reactionsListFilterForm';
const FilterFormFieldNames = {
  ReactionName: 'ReactionName',
  CreationDateFrom: 'CreationDateFrom',
  CreationDateTo: 'CreationDateTo',
  includeCompleted: 'includeCompleted',
};

let FilterForm = ({ resetForm, submitHandler, formValues }) => {

  const resetFormHandler = () => {
    resetForm();
  };

  const formHasValues = () => {
    return !!(formValues && Object.values(formValues).filter(x => x !== null).length);
  };

  return (
    <>
      <Form className={[styles.filter, styles.filter__form].join(' ')} onSubmit={submitHandler}>
        <FormFieldLayout 
            className={styles.filter__form__field}
            label={'Название'}>
          <Field
            name={FilterFormFieldNames.ReactionName}
            placeholder={'Введите название'}
            component={BcdInputWrapper}
          />
        </FormFieldLayout>
        <FormFieldLayout className={classNames(styles.filter__form__field)} label={'Дата создания реакции'}>
          <Field
            name={FilterFormFieldNames.CreationDateFrom}
            component={DatePickerInput}
            placeholder={'От'}
            dayPickerProps={
              formValues 
                ? {
                    selectedDays: [formValues.CreationDateFrom, { from: formValues.CreationDateFrom, to: formValues.CreationDateTo }],
                    disabledDays: { after: formValues.CreationDateTo || new Date() },
                    toMonth: formValues.CreationDateTo,
                    modifiers: { start: formValues.CreationDateFrom, end: formValues.CreationDateTo },
                  } 
                : {}
            }
          />
        </FormFieldLayout>
        <div className={styles.filter__form__dash}>&ndash;</div>
        <FormFieldLayout className={classNames(styles.filter__form__field)}>
          <Field
            name={FilterFormFieldNames.CreationDateTo}
            component={DatePickerInput}
            placeholder={'До'}
            dayPickerProps={
              formValues
                ? {
                    selectedDays: [formValues.CreationDateFrom, { from: formValues.CreationDateFrom, to: formValues.CreationDateTo }],
                    disabledDays: { before: formValues.CreationDateFrom, after: new Date() },
                    fromMonth: formValues.CreationDateFrom,
                    modifiers: { start: formValues.CreationDateFrom, end: formValues.CreationDateTo },
                  }
                : {}
            }
          />
        </FormFieldLayout>
        <FormFieldLayout className={styles.filter__form__checkbox}>
          <Field
            name={FilterFormFieldNames.includeCompleted}
            component={BcdCheckboxWrapper}
            label={'Показывать завершённые реакции'}
            large={true}
          />
        </FormFieldLayout>
        <div className={styles.filter__form__expander} />
        <div className={styles.filter__form__buttonWrapper}>
          {
            formHasValues() &&
              <Button
                text="Очистить фильтр"
                type={'reset'}
                className={styles.filter__form__button}
                disabled={!formHasValues()}
                onAction={resetFormHandler}
              />
          }
          <Button
            text="Найти"
            view="primary"
            type={'submit'}
            className={styles.filter__form__button}
          />
        </div>
      </Form>
    </>
  );
};

FilterForm = reduxForm({
  form: REACTION_FILTER_FORM_NAME,
  destroyOnUnmount: true,
})(FilterForm);

const mapStateToProps = (state) => ({
  formValues: getFormValues(REACTION_FILTER_FORM_NAME)(state)
});

const mapDispatchToProps = dispatch => ({
  resetForm: () => dispatch(reset(REACTION_FILTER_FORM_NAME)),
});

FilterForm = connect(
    mapStateToProps,
    mapDispatchToProps
  )(FilterForm);

export { FilterForm, };

