// @flow
import React, {useState} from 'react';
import {connect} from 'react-redux';
import {projectStatusesTranslate, projectTypesTranslate} from '../../../localization';
import {projectStatusEnum, projectTypeEnum} from '../../../types/enum';
import Button from '@biocad/bcd-front-ui/controls/Button/index';
import classNames from 'classnames';
import {
    StyledLink
} from '../style';
import {ConfirmDialog} from '../../../../shared/components/ConfirmDialog/ConfirmDialog';
import {
    candidatesResourceActions,
    folderResourceActions,
    projectActions,
    projectModuleActions,
    folderSchemesResourceActions, descendantsResourceActions
} from '../../../actions';
import {getFormValues} from 'redux-form';
import {PROJECT_SEARCH_FORM_NAME} from '../../SearchField';
import {withRouter} from 'react-router-dom';
import {folderParentType} from '../../../types/enum';
import {sortedProjectsSelector} from '../../../reducers/projects-list';
import './index.less';
import {userCRUDPermissionsSelector} from "../../../../account/reducers/users-modules-reducer";
import {getClassNameByProjectType} from "../../../types/utils";

const initialProps = {
    toggleProjectModal: null,
    projects: [],
    setCurrentProjectId: null,
    setEditMode: null,
    deleteProject: null,
    clearFoldersList: null,
    clearCandidatesList: null,
    currentProjectId: null,
    searchFormValues: null,
    getList: null,
    history: null,
    getCandidatesList: null,
    clearSchemeFoldersList: () => null,
    getDescendantsList: () => null,
    setCurrentProjectType: () => null
};

const ProjectsList = (props = initialProps) => {
    const [isConfirmShown, setIsConfirmShown] = useState(false);

    const toggleConfirmModal = () => {
        setIsConfirmShown(!isConfirmShown);
    };

    const openProject = (e) => {
        let projectId = e.target.parentNode.dataset.id;
        let projectType = e.target.parentNode.dataset.ptype;

        props.setCurrentProjectId({
            id: projectId
        });
        props.setCurrentProjectType({
            ptype: projectType
        });

        props.clearCandidatesList();
        props.clearFoldersList();

        props.getFoldersList({
            parentId: projectId,
            parentType: folderParentType.project
        });
        props.getCandidatesList({
            projectId: projectId,
            folderId: props.foldersPath.length ?
                props.foldersPath[props.foldersPath.length - 1].id : null
        });
        props.history.push('/projects/' + projectId);
    };

    const deleteProject = () => {
        props.deleteProject({
            projectId: props.currentProjectId,
            folderId: props.foldersPath.length > 0 ?
                props.foldersPath[props.foldersPath.length - 1].id : null
        });
        toggleConfirmModal();
    };

    const confirmDeleteProject = (e) => {
        e.stopPropagation();
        toggleConfirmModal();
        props.setCurrentProjectId({
            id: e.target.dataset.id
        });
    };

    const editProject = (e, projectId) => {
        e.stopPropagation();

        props.getDescendantsList({
            parentId: null,
            parentType: folderParentType.root
        });

        props.toggleProjectModal();
        props.setCurrentProjectId({
            id: projectId
        });
        props.setEditMode({
            isEdit: true
        });
    };

    return (
        <React.Fragment>
            {isConfirmShown &&
            <ConfirmDialog
                title={'Внимание'}
                message={'Вы уверены, что хотите удалить этот проект?'}
                confirmLabel={'Да'}
                declineLabel={'Отмена'}
                confirmAction={deleteProject}
                declineAction={toggleConfirmModal}
            />}

            {props.projects.map((project, index) => {
                return (
                    <tr key={project.Id || index} data-id={project.Id} data-ptype={project.Type} onClick={openProject}>
                        <td>
                            <i className={getClassNameByProjectType(project)}/>
                            {project.Name || ''}
                        </td>
                        <td>
                            {project.Leader || ''}
                        </td>
                        <td>
                            {project.IsScaling 
                                ? 'Масштабирование'
                                : projectTypesTranslate[projectTypeEnum[project.Type]] || ''}
                        </td>
                        <td>
                            {projectStatusesTranslate[projectStatusEnum[project.Status]] || ''}
                        </td>
                        {
                            props.userPermissions.project.UPDATE ?
                            <td className={'cell-with-only-icon'} onClick={(e) => editProject(e, project.Id)}>
                                <div className={'button-holder'}>
                                    <StyledLink
                                        to={'#'}
                                        className={Button.block.toString() + Button.block.mod('icon') + Button.block.mod('size', 'L')}
                                    >
                                        <i className={'fo-edit'} />
                                    </StyledLink>
                                </div>
                            </td> : <td className={'cell-with-only-icon'} />
                        }
                        {
                            props.userPermissions.project.DELETE ?
                            <td className={'cell-with-only-icon'} onClick={confirmDeleteProject}>
                                <StyledLink
                                    to={'#'}
                                    className={Button.block.toString() + Button.block.mod('icon') + Button.block.mod('size', 'L')}
                                >
                                    <i className={'fo-trash'} data-id={project.Id}/>
                                </StyledLink>
                            </td> : <td className={'cell-with-only-icon'} />
                        }
                    </tr>
                );
            })}
        </React.Fragment>
    );

};

const mapStateToProps = (state) => {
    const {resource, modules} = state;
    return {
        projects: sortedProjectsSelector(resource.projects),
        currentProjectId: modules.project.id,
        foldersPath: modules.folder.path,
        searchFormValues: getFormValues(PROJECT_SEARCH_FORM_NAME)(state),
        userPermissions: userCRUDPermissionsSelector(state)
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        (dispatch) => ({
            setEditMode: (isEditMode) => dispatch(projectModuleActions.setEditMode(isEditMode)),
            setCurrentProjectId: (id) => dispatch(projectModuleActions.setCurrentProjectId(id)),
            setCurrentProjectType: (type) => dispatch(projectModuleActions.setCurrentProjectType(type)),
            deleteProject: (data) => dispatch(projectActions.delete.request({data})),
            clearCandidatesList: () => dispatch(candidatesResourceActions.destroy()),
            clearFoldersList: () => dispatch(folderResourceActions.destroy()),
            getList: (params) => dispatch(projectActions.list.request({params})),
            getCandidatesList: (params) => dispatch(candidatesResourceActions.list.request({params})),
            getFoldersList: (params) => dispatch(folderResourceActions.list.request({params})),
            getDescendantsList: (params) => dispatch(descendantsResourceActions.list.request({params}))
        })
    )(ProjectsList));