// @flow

import React, {
  useState,
  useEffect,
} from 'react';
import {
  Field,
  reduxForm,
} from 'redux-form';
import {
  BcdInputWrapper,
  BcdDropdownWrapper,
  BcdCheckboxWrapper,
  MarvinSketch,
  FormFieldLayout,
  LayoutBlock,
  DatePickerInput,
  BcdSuggestWrapper,
} from '../../../../shared/components';
import { connect } from 'react-redux';
import {
  CatalogReactionRequestParamsEnum,
} from '../../../models';
import {
  StyledForm,
} from './style';
import type { IFieldMetaProps, OptionType } from '../../../../shared/models';
import { initialReactionSearchParams } from '../../../reducers/module';
import getFormValues from 'redux-form/lib/getFormValues';
import type { ICatalogReactionRequestParams } from '../../../models';
import { ReactionTypeCheckbox } from './ReactionTypeCheckbox';
import { getSortedProjects } from '../../../reducers';
import type { MarvinJsSketch } from '../../../../shared/services/compound/marvinjs-util';
import type { IFieldInputProps } from '../../../../shared/models/redux-form-interfaces';
import type { ICompound } from '../../../../agents/models/i-agent';
import { reactionTypesResourceActions } from '../../../../reactionTypes/actions/resource';
import { reactionTypesObsoleteAsOptionsSelector } from '../../../../reactionTypes/reducers';


interface ISearchFormProps {
  allProjects?: OptionType[],
  formValues?: ICatalogReactionRequestParams,
  reactionTypes?: OptionType[],
  loadReactionTypes?: () => void,
}

const searchFormInitProps: ISearchFormProps = {
  allProjects: [],
  reactionTypes: [],
};

const CATALOG_REACTIONS_SEARCH_FORM_NAME = 'catalogReactionSearch';

const renderMarvinSketchField = (props: { meta: IFieldMetaProps, input: IFieldInputProps, className: string }) => {
  const
    [sketcher: MarvinJsSketch, setSketcher] = useState(null),
    [pristine: boolean, setPristine] = useState(null);

  useEffect(() => {
    if (!sketcher || !('clear' in sketcher)) return;
    if (props.meta.pristine === true && pristine === false) {
      sketcher.clear();
    }
    if (props.meta.pristine !== pristine) setPristine(props.meta.pristine);
  }, [props.meta.pristine]);

  return (
    <MarvinSketch
      handleChange={(compound: ICompound, {pending}) => !pending && props.input.onChange(compound.SmilesFormula)}
      className={props.className}
      onSketchInit={(sketch) => setSketcher(sketch)}
    />
  );
};

let SearchForm = (props: ISearchFormProps = searchFormInitProps) => {
  useEffect(() => {
    props.loadReactionTypes();
  }, []);

  return (
  <LayoutBlock header={null}
               style={{
                 '--LayoutBlock-padding-h': '0',
                 '--LayoutBlock-padding-v': '0',
               }}>
    <StyledForm onSubmit={()=>null}>
      <Field
        name={CatalogReactionRequestParamsEnum.Author}
        component={BcdInputWrapper}
        label={'Автор'}
        wrapperClassName={`field-${CatalogReactionRequestParamsEnum.Author}`}
        useFormFieldLayout={true}
        placeholder={'Введите ФИО автора'}
      />
      <Field
        name={CatalogReactionRequestParamsEnum.ProjectId}
        component={BcdDropdownWrapper}
        format={(value) => props.allProjects.find((i:OptionType) => i.value === value) || null}
        options={(props.formValues && props.formValues[CatalogReactionRequestParamsEnum.ProjectId])
          ? [{ value: null, label: '--- Выберите проект ---' }, ...props.allProjects]
          : props.allProjects}
        label={'Проект'}
        placeholder={<span style={{color: 'var(--grey-300)'}}>Выберите проект</span>}
        wrapperClassName={`field-${CatalogReactionRequestParamsEnum.ProjectId}`}
        useFormFieldLayout={true}
      />
      <Field
        name={CatalogReactionRequestParamsEnum.ReactionName}
        component={BcdInputWrapper}
        label={'Наименование реакции'}
        useFormFieldLayout={true}
        wrapperClassName={`field-${CatalogReactionRequestParamsEnum.ReactionName}`}
        placeholder={'Введите наименование'}
      />
      <Field
        name={CatalogReactionRequestParamsEnum.FinalCandidateCode}
        component={BcdInputWrapper}
        label={'Целевая молекула'}
        useFormFieldLayout={true}
        wrapperClassName={`field-${CatalogReactionRequestParamsEnum.FinalCandidateCode}`}
        placeholder={'Введите код ЦМ'}
      />
      <FormFieldLayout className={'field-CreationDateRange'}
                       label={'Дата создания реакции'}>
        <Field
          name={CatalogReactionRequestParamsEnum.CreationDateFrom}
          component={DatePickerInput}
          dayPickerProps={props.formValues ? {
            selectedDays: [props.formValues.CreationDateFrom, { from: props.formValues.CreationDateFrom, to: props.formValues.CreationDateTo }],
            disabledDays: { after: props.formValues.CreationDateTo || new Date() },
            toMonth: props.formValues.CreationDateTo,
            modifiers: { start: props.formValues.CreationDateFrom, end: props.formValues.CreationDateTo },
          } : {}}
          placeholder={'От'}
        />
        <Field
          name={CatalogReactionRequestParamsEnum.CreationDateTo}
          component={DatePickerInput}
          dayPickerProps={props.formValues ? {
            selectedDays: [props.formValues.CreationDateFrom, { from: props.formValues.CreationDateFrom, to: props.formValues.CreationDateTo }],
            disabledDays: { before: props.formValues.CreationDateFrom, after: new Date() },
            fromMonth: props.formValues.CreationDateFrom,
            modifiers: { start: props.formValues.CreationDateFrom, end: props.formValues.CreationDateTo },
          } : {}}
          placeholder={'До'}
        />
      </FormFieldLayout>
      <FormFieldLayout className={'field-CompleteDateRange'}
                       label={'Дата завершения реакции'}>
        <Field
          name={CatalogReactionRequestParamsEnum.CompleteDateFrom}
          component={DatePickerInput}
          dayPickerProps={props.formValues ? {
            selectedDays: [props.formValues.CompleteDateFrom, { from: props.formValues.CompleteDateFrom, to: props.formValues.CompleteDateTo }],
            disabledDays: { after: props.formValues.CompleteDateTo || new Date() },
            toMonth: props.formValues.CompleteDateTo,
            modifiers: { start: props.formValues.CompleteDateFrom, end: props.formValues.CompleteDateTo },
          } : {}}
          placeholder={'От'}
        />
        <Field
          name={CatalogReactionRequestParamsEnum.CompleteDateTo}
          component={DatePickerInput}
          dayPickerProps={props.formValues ? {
            selectedDays: [props.formValues.CompleteDateFrom, { from: props.formValues.CompleteDateFrom, to: props.formValues.CompleteDateTo }],
            disabledDays: { before: props.formValues.CompleteDateFrom, after: new Date() },
            fromMonth: props.formValues.CompleteDateFrom,
            modifiers: { start: props.formValues.CompleteDateFrom, end: props.formValues.CompleteDateTo },
          } : {}}
          placeholder={'До'}
        />
      </FormFieldLayout>
      <FormFieldLayout className={'field-reactionStatus'} label={'Статус реакции'}>
        <Field
          name={CatalogReactionRequestParamsEnum.IncludeExpYield}
          component={ReactionTypeCheckbox}
          reactionType={CatalogReactionRequestParamsEnum.IncludeExpYield}
        />
        <Field
          name={CatalogReactionRequestParamsEnum.IncludeIncomplete}
          component={ReactionTypeCheckbox}
          reactionType={CatalogReactionRequestParamsEnum.IncludeIncomplete}
        />
        <Field
          name={CatalogReactionRequestParamsEnum.IncludeWithoutExpYield}
          component={ReactionTypeCheckbox}
          reactionType={CatalogReactionRequestParamsEnum.IncludeWithoutExpYield}
        />
        <Field
          name={CatalogReactionRequestParamsEnum.IncludeTrialSuccessful}
          component={ReactionTypeCheckbox}
          reactionType={CatalogReactionRequestParamsEnum.IncludeTrialSuccessful}
        />
        <Field
          name={CatalogReactionRequestParamsEnum.IncludeTrialIncomplete}
          component={ReactionTypeCheckbox}
          reactionType={CatalogReactionRequestParamsEnum.IncludeTrialIncomplete}
        />
        <Field
          name={CatalogReactionRequestParamsEnum.IncludeTrialFailed}
          component={ReactionTypeCheckbox}
          reactionType={CatalogReactionRequestParamsEnum.IncludeTrialFailed}
        />
      </FormFieldLayout>
      <FormFieldLayout  className={'field-reactionType'} label={'Тип реакции'}>
        <Field
            name={CatalogReactionRequestParamsEnum.ReactionTypes}
            markPristineValidity
            component={BcdSuggestWrapper}
            multiple={true}
            options={props.reactionTypes}
            format={(value) => value === null ? [] : value}
            placeholder={'Выберите тип реакции'}
            useFormFieldLayout
          />
      </FormFieldLayout>

      <Field
        name={CatalogReactionRequestParamsEnum.IncludeRegular}
        component={BcdCheckboxWrapper}
        label={'Отображать реакции Chemsoft'}
        wrapperClassName={`field-${CatalogReactionRequestParamsEnum.IncludeRegular}`}
        large={true}
        useFormFieldLayout={true}
        wrapperStyle={{
          '--FormFieldLayout-label-line-height': '0'
        }}
      />
      <Field
        name={CatalogReactionRequestParamsEnum.IncludeArchived}
        component={BcdCheckboxWrapper}
        label={'Отображать архивные реакции'}
        wrapperClassName={`field-${CatalogReactionRequestParamsEnum.IncludeArchived}`}
        large={true}
        useFormFieldLayout={true}
        wrapperStyle={{
          '--FormFieldLayout-label-line-height': '0'
        }}
      />
      <Field
        name={CatalogReactionRequestParamsEnum.OnlyTemplateReactions}
        component={BcdCheckboxWrapper}
        label={'Только шаблонные реакции'}
        wrapperClassName={`field-${CatalogReactionRequestParamsEnum.OnlyTemplateReactions}`}
        large={true}
        useFormFieldLayout={true}
        wrapperStyle={{
          '--FormFieldLayout-label-line-height': '0'
        }}
      />
      <Field
        name={CatalogReactionRequestParamsEnum.SmilesFormula}
        component={renderMarvinSketchField}
        format={(value) => value ? { SmilesFormula: value } : ''}
        className={`field-${CatalogReactionRequestParamsEnum.SmilesFormula}`}
      />
    </StyledForm>
  </LayoutBlock>
)};

SearchForm = reduxForm({
  form: CATALOG_REACTIONS_SEARCH_FORM_NAME,
  initialValues: initialReactionSearchParams,
  destroyOnUnmount: true,
})(SearchForm);

SearchForm = connect(
  (state): ISearchFormProps => ({
    reactionTypes: reactionTypesObsoleteAsOptionsSelector(state),
    allProjects: getSortedProjects(state),
    formValues: getFormValues(CATALOG_REACTIONS_SEARCH_FORM_NAME)(state),
  }),
  (dispatch) => ({
    loadReactionTypes: () => dispatch(reactionTypesResourceActions.list.request()),
  })
)(SearchForm);

export {
  SearchForm,
  CATALOG_REACTIONS_SEARCH_FORM_NAME,
};
