// @flow

import React, {
	useEffect,
	useRef,
} from 'react';
import {
	Field,
	change,
} from 'redux-form';
import {
	BcdInputWrapper,
	BcdDropdownWrapper,
	BcdCheckboxWrapper,
	FormFieldLayout,
	block as formFieldBNCBlock
} from '../../../../shared/components';
import { connect } from 'react-redux';
import type { IRootStore } from '../../../../app/reducers';
import type { IReaction, IReactionEvent } from '../../../models';
import { Dispatch } from 'redux';
import dateTimeFormat from '@biocad/bcd-front-ui/utils/formatters/datetime';
import type { IReactionType } from '../../../../reactionTypes/model';
import { reactionTypesResourceActions } from '../../../../reactionTypes/actions';
import { reactionTypesAsOptionsSelector } from '../../../../reactionTypes/reducers';
import { ReactionKeyNames } from '../../../models';
import { REACTION_EDIT_FORM_NAME } from '../index';
import type { OptionType } from '../../../../shared/models';
import {
	getAddedJarsByCurrentReaction,
	getCurrentReactionStates, 
	ReactionEditDialogsMap,
	getCurrentReactionMainProduct,
} from '../../../reducers';
import type { TCurrentReactionStates } from '../../../reducers';
import { ReactionParametersInput } from './ReactionParametersInput';
import { ReactionExtraConditionSelect } from './ReactionExtraConditionSelect';
import { reactionModuleActions, reactionsResourceActions } from '../../../actions';
import { getReactionFromForm } from '../../../reducers/reaction-edit-form-plugin';
import { ReactionMultipleYields, fieldValidators } from './ReactionMultipleYields';
import { ReactionCreatedJars } from './ReactionCreatedJars';
import Button from '@biocad/bcd-front-ui/controls/Button';
import { ArchivedReactionInfo } from './ArchivedReactionInfo';
import styled from 'styled-components';
import Input from '@biocad/bcd-front-ui/controls/Input';
import { globalErrorsActions } from '../../../../shared/actions';
import { ReactionEditDialogWrap } from '../ReactionEditDialogWrap';
import type { IProduct } from '../../../../products/model';
import { folderParentType } from '../../../../projects/types/enum';
import { descendantsModuleActions, descendantsResourceActions } from '../../../../projects/actions';
import DescendantTree from '../../../../shared/components/DescendantTree';
import {userCRUDPermissionsSelector} from '../../../../account/reducers/users-modules-reducer';
import { BcdSuggestWrapper } from '../../../../shared/components';
import {withRouter} from 'react-router-dom';

interface IReactionEditFormProps {
	className?: string,
	reaction?: IReaction,
	reactionTypes?: IReactionType[],
	listReactionTypes?: () => void,
	initialValues?: IReaction,
	reactionStates?: TCurrentReactionStates,
	recalcReactionYields?: () => void,
	jars?: IReactionEvent[],
	setGlobalError?: (error: string) => void,
	addProduct?: (product: IProduct) => void,
	currentDescendant: null,
	setCurrentDescendant: () => void,
	deleteCurrentDescendant: () => void,
	getDescendantsList: () => void,
	clearDescendantsResourceList: () => void,
	updateExperimentalPercentYield: () => void,
	userPermissions?: {},
	history?: { push?: Function },
	mainProduct?: IProduct,
}

const
	reactionIsSuccessfulOptions: OptionType[] = [
		{ label: 'Не указано', value: null },
		{ label: 'Успешно', value: true },
		{ label: 'Неуспешно', value: false },
	],
	asideFieldsValidators: { [key: string]: Array<(value: any, allValues: any, props: IReactionEditFormProps, name: string) =>?Error> } = {
		ReactionType: [
			(value) => value > 0 ? undefined : new Error('Обязательное поле.'),
		],
		ReactionParameters: [
			(value) => (Array.isArray(value) && value.filter(i => !i.IsDeleted).length > 0) ? undefined : new Error('Обязательное поле.'),
		],
	}
	;

const
	renderDate = (date: string) => (
		date ? dateTimeFormat(new Date(date), { format: 'full' }) : <>&ndash;</>
	),
	formatReactionIsSuccessfulField = (value, _) => (
		reactionIsSuccessfulOptions.find((item: OptionType) => item.value === value)
	)
	;

const defaultProps: IReactionEditFormProps = {
	className: '',
	reactionStates: {},
	history: { push: () => { } },
};

let ReactionAsideForm = (props: IReactionEditFormProps = defaultProps) => {

	// Refs
	const completeConfirmDialog = useRef(null);
	const addProductDialogRef = useRef(null);

	// Effects
	useEffect(() => {
		const descendants = props.descendants || [];

		const current = descendants.find(d => d.Id === props.reaction?.FolderId);

		const result = current
			? { id: current.Id, name: null }
			: { id: props.reaction?.FolderId, name: null };
		props.setCurrentDescendant(result);
	}, [props.descendants]);

	useEffect(() => {
		if (props.currentDescendant && props.currentDescendant.id) {
			props.updateFolderId(parseInt(props.currentDescendant.id, 10));
		} else {
			props.updateFolderId(null);
		}
	}, [props.currentDescendant]);

	const _redirectToCreatedNestedReaction = () => {
		if(!props.redirectToReaction)
			return;

		const url = '/reactions/' + props.redirectToReaction;

		props.history.push({
			pathname: '/reload',
			state: { url }
		});
	}
	useEffect(_redirectToCreatedNestedReaction, [props.redirectToReaction])

	// Methods
	const formatReactionTypeOptions = (value, _) => (
			props.reactionTypes.find((item: OptionType) => item.value === value)
		);
	const getReactionTypesComponentProps = () => {
		if(props.reactionStates?.isPurification) {
			return {
				component: BcdDropdownWrapper,
				options: [],
				format: () => 'Purification',
				disabled: true,
			};
		}

		const rType = props.reaction?.ReactionType;
		if(props.reaction?.IsCompleted && rType
			&& props.allReactionTypes[rType]
			&& props.allReactionTypes[rType].IsObsolete)
		{
			return {
				component: BcdDropdownWrapper,
				options: [],
				format: () => props.allReactionTypes[rType].Name,
				disabled: true,
			};
		}

		return {
			component: BcdSuggestWrapper,
			options: props.reactionTypes,
			format: formatReactionTypeOptions,
			normalize: (value) => value === null ? 0 : value,
			placeholder: 'Выберите тип реакции',
			multiple: false
		};
	};

	// Handlers
	const
		recalcYields = (event) => {
			if (props.reaction?.IsArchived)
			{
				let ExperimentalPercentYield = (props.reaction.ExperimentalYield && props.reaction.TheoreticalYield)
					? (props.reaction.ExperimentalYield / props.reaction.TheoreticalYield)
					: 0;
				props.updateExperimentalPercentYield(ExperimentalPercentYield);
			}
			else {
				props.recalcReactionYields();
			}
		},
		completeReaction = () => {
			if (props.reactionStates.hasZeroConsumption) {
				return props.setGlobalError('У каждой банки должен быть указан фактический расход');
			}
			if (typeof completeConfirmDialog?.current?.open === 'function') {
				completeConfirmDialog.current.open();
			}
		},
		createNestedPurification = () => {
			props.createNestedPurificationReaction(props.reaction?.Id);
		};

	return (
		<>
			<aside className={props.className}>
				<div className={'form-group'}>
					{
						!props.reaction?.IsArchived &&
							<>
								<div className={'form-group'}>
									<Field
										name={ReactionKeyNames.AdditionalName}
										component={BcdInputWrapper}
										label={'Дополнительное наименование'}
										disabled={props.reactionStates?.isReadonly}
										large
										useFormFieldLayout
									/>
								</div>
								<FormFieldLayout label={'Дата создания:'}>
									{renderDate(props.reaction?.CreationDate)}
								</FormFieldLayout>
								{
									props.reaction?.IsCompleted && props.reaction?.CompleteDate &&
										<FormFieldLayout label={'Дата завершения:'}>
											{renderDate(props.reaction.CompleteDate)}
										</FormFieldLayout>
								}
							</>
					}

					{
						props.reaction?.IsArchived &&
							<ArchivedReactionInfo />
					}

				</div>

				<div className={'form-group'}>
					<h4>Условия реакции</h4>
					<Field
						name={ReactionKeyNames.IsTrial}
						component={BcdCheckboxWrapper}
						label={'Пробная реакция'}
						disabled={props.reactionStates?.isReadonly || props.reaction?.HasMultipleYields}
						large
						useFormFieldLayout
					/>
					<Field
						name={ReactionKeyNames.ReactionType}
						label={'Тип реакции'}
						warn={props.reaction?.Id ? asideFieldsValidators.ReactionType: null}
						disabled={props.reactionStates?.isReadonly}
						useFormFieldLayout
						markPristineValidity
						{...getReactionTypesComponentProps()}
					/>
					<Field
						name={ReactionKeyNames.IsTemplate}
						component={BcdCheckboxWrapper}
						label={'Шаблонная реакция'}
						disabled={props.reactionStates?.isReadonly}
						large
						useFormFieldLayout
					/>
					<Field
						name={ReactionKeyNames.ReactionParameters}
						warn={props.reaction?.Id ? asideFieldsValidators.ReactionParameters : null}
						component={ReactionParametersInput}
						reactionId={props.reaction?.Id}
						disabled={props.reactionStates?.isReadonly}
					/>
					<Field
						name={ReactionKeyNames.ReactionExtraConditions}
						component={ReactionExtraConditionSelect}
						disabled={props.reactionStates?.isReadonly}
					/>
				</div>
				<div className={'form-group reaction-yields-fields'}>
					<h4>Выход продукта</h4>
					{
						props.reactionStates?.canHaveMultipleYields &&
							<Field
								name={ReactionKeyNames.HasMultipleYields}
								component={BcdCheckboxWrapper}
								label={'Множественные выходы'}
								wrapperClassName={'hasMultipleYields-checkbox'}
								disabled={!props.reactionStates?.canHaveMultipleYields || props.reactionStates?.isReadonly}
								large
								useFormFieldLayout
							/>
					}
					{
						(props.reactionStates?.canHaveMultipleYields && props.reaction?.HasMultipleYields)
							? <ReactionMultipleYields disabled={props.reactionStates?.isReadonly} />
							: <>
								<Field
									name={ReactionKeyNames.TheoreticalYield}
									component={BcdInputWrapper}
									label={'Теор., г'}
									type={'number'}
									disabled={props.reactionStates?.isReadonly}
									onChange={recalcYields}
									validate={props.reaction?.IsArchived ? null : fieldValidators.TheoreticalYield}
									useFormFieldLayout
									markPristineValidity
								/>
								{
									!props.reaction?.IsTrial
										? <>
											<Field
												name={ReactionKeyNames.ExperimentalYield}
												component={BcdInputWrapper}
												label={'Эксп.,г'}
												type={'number'}
												disabled={props.reactionStates?.isReadonly}
												onChange={recalcYields}
												warn={(props.reaction?.IsArchived || !props.reaction?.Id) ? null : fieldValidators.ExperimentalYield}
												useFormFieldLayout
												markPristineValidity
											/>

											<div className={'b-FormFieldLayout'}>
												<span className={'b-FormFieldLayout__label'}>Эксп.,%</span>
												<div className={'input-simulator'}>
													{(props.reaction?.ExperimentalPercentYield*100).toFixed(2)}
												</div>
											</div>

										</>
										: <Field
											name={ReactionKeyNames.IsSuccessful}
											component={BcdDropdownWrapper}
											wrapperClassName={'reaction-isSuccessful-dropdown'}
											label={'Результат'}
											options={reactionIsSuccessfulOptions}
											format={formatReactionIsSuccessfulField}
											warn={(!props.reaction.IsTrial || props.reaction?.IsArchived || !props.reaction?.Id) ? null : fieldValidators.IsSuccessful}
											disabled={props.reactionStates?.isReadonly}
											useFormFieldLayout
											markPristineValidity
										/>
								}
							</>
					}
				</div>

				{!props.reaction?.IsArchived &&
					<div>
						<DescendantTree
							title={'Папка'}
							isRequired={false}
							root={'Стадия'}
							disabled={props.reactionStates?.isReadonly}
							parentId={props.reaction?.FolderId} />
					</div>
				}

				{
						(!props.reaction?.IsArchived && props.reactionStates?.isCompleteAvailable && props.userPermissions.reaction.UPDATE) &&
						<div className={'form-group'}>
							<Button
								view={'primary'}
								size={'L'}
								text={'Завершить реакцию'}
								onAction={completeReaction}
							/>
						</div>
				}

				{
					(props.reaction?.IsArchived) &&
					<Field
						name={ReactionKeyNames.IsCompleted}
						component={BcdCheckboxWrapper}
						label={'Реакция завершена'}
						large
						useFormFieldLayout
					/>
				}
				
				{ 
					props.reaction?.IsCompleted &&
					props.reaction?.CanCreateNestedPurification &&
					!props.reaction?.IsArchived &&
					!props.reaction?.IsTrial &&
					<div className={'form-group'}>
						<Button
							onAction={createNestedPurification}
							text={'Добавить реакцию очистки'}
							size={'L'}
							disabled={!props.reactionStates?.isPurificationEditable}
							view={'primary'} />
					</div>
				} 
				
				{
					(!props.reaction?.IsArchived && !props.reaction?.IsTrial && props.reaction?.IsCompleted) &&
					<div className={'form-group'}>
						<h4 className={'heading-with-btn'}>
							<span>Побочные продукты</span>
							<Button
								className={'fo-plus'}
								view={'icon'}
								size={'L'}
								disabled={props.reactionStates?.isReadonly}
								onAction={() => 'open' in addProductDialogRef.current && addProductDialogRef.current.open()}
							/>
						</h4>
						{
							(props.reaction || props.jars?.length)
								? <FormFieldLayout>
									<ReactionCreatedJars />
								</FormFieldLayout>
								: ''
						}
					</div>
				}
			</aside>

			{/*	Dialogs*/}
			<ReactionEditDialogWrap
				dialogName={ReactionEditDialogsMap.CompleteReactionConfirmation}
				getDialogRef={(dialog) => {
					if (dialog) completeConfirmDialog.current = dialog;
				}}
			/>
			<ReactionEditDialogWrap
				dialogName={ReactionEditDialogsMap.AddProduct}
				getDialogRef={(dialog) => {
					if (dialog) addProductDialogRef.current = dialog;
				}}
				onProductSave={(product: IProduct) => props.addProduct(product)}
			/>
		</>
	);
};

ReactionAsideForm = connect(
	(state: IRootStore): IReactionEditFormProps => ({
		reaction: getReactionFromForm(state),
		reactionTypes: reactionTypesAsOptionsSelector(state),
		allReactionTypes: state?.resource?.reactionTypes?.data,
		reactionStates: getCurrentReactionStates(state),
		jars: getAddedJarsByCurrentReaction(state),
		currentDescendant: state.modules.descendants.currentDescendant,
		descendants: state.modules.descendants.list,
		userPermissions: userCRUDPermissionsSelector(state),
		mainProduct: getCurrentReactionMainProduct(state),
		redirectToReaction: state.modules.reaction.editReaction.redirectToReaction,
	}),
	(dispatch: Dispatch): IReactionEditFormProps => ({
		listReactionTypes: () => dispatch(reactionTypesResourceActions.list.request()),
		recalcReactionYields: () => dispatch(reactionModuleActions.recalc.reaction({ debounce: true })),
		createNestedPurificationReaction: (id) => dispatch(reactionsResourceActions.createExtraNestedPurification.request({ uriParams: { id } })),
		setGlobalError: (error: string) => dispatch(globalErrorsActions.set({ error })),
		addProduct: (product: IProduct) => dispatch(reactionModuleActions.reactionEdit.addProduct({ product })),
		setCurrentDescendant: ({ id, name }) => dispatch(descendantsModuleActions.setCurrentDescendant({ id, name })),
		deleteCurrentDescendant: () => dispatch(descendantsModuleActions.deleteCurrentDescendant()),
		getDescendantsList: (params) => dispatch(descendantsResourceActions.list.request({ params })),
		clearDescendantsResourceList: () => dispatch(descendantsResourceActions.destroy()),
		updateFolderId: (value) => dispatch(change(REACTION_EDIT_FORM_NAME, ReactionKeyNames.FolderId, value)),
		updateExperimentalPercentYield: (value) => dispatch(change(REACTION_EDIT_FORM_NAME, ReactionKeyNames.ExperimentalPercentYield, value))
	}),
)(ReactionAsideForm);

ReactionAsideForm = styled(ReactionAsideForm)`
  --FormFieldLayout-margin-b: var(--grid-spacer);
	border-left: 1px solid var(--grey-300);
	padding: calc(var(--grid-spacer) * 1.5);
	.input-simulator{
			background-color: var(--grey-100);
			color: var(--grey-500);
			display: flex;
			align-items: center;
			padding: 0 12px;
			min-height: 36px;
			border: 1px solid var(--grey-100);
	}
	.form-group {
		margin-bottom: var(--grid-spacer);
		&.archive-info {
      margin-bottom: 32px;
      h4 + .b-FormFieldLayout {
        margin-top: calc(var(--FormFieldLayout-label-line-height) * 1.25);
      }
      .fields-row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        &.large {
          .b-FormFieldLayout{
            width: 100%;
            margin-top: 32px;
          }
        }
        label {
          color: var(--FormFieldLayout-color-default);
        }        
        label,
        .b-FormFieldLayout{
          width: 45%;
          .b-datepicker {
            margin-top: 8px;
            .b-input {
              width: 100%;
            }
          }
        }
      }
    }
		.heading-with-btn {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
	.reaction-yields-fields {
		--FormFieldLayout-margin-b: 8px;
		--FormFieldLayout-hint-line-height: 0;
		display: grid;
		grid-template-rows: calc(2em + var(--FormFieldLayout-label-line-height)) auto;
		grid-template-columns: repeat(3, minmax(0, 1fr));
		grid-column-gap: var(--grid-spacer);
		h4 {
			grid-row: 1;
			grid-column: 1 / 4;		
		}
		.${formFieldBNCBlock} {
			grid-row: auto;
		}
		.${Input.block} {
			max-width: 100%;
		}
		.reaction-isSuccessful-dropdown {
			grid-column: 2 / 4;
		}
	}
	.b-FormFieldLayout.hasMultipleYields-checkbox {
		grid-column: 1 / 4;
		padding-top: 0;
		margin-bottom: calc(var(--grid-spacer) * 1.5);
	}
`;

ReactionAsideForm = withRouter(ReactionAsideForm);

export {
	ReactionAsideForm,
};
