// @flow

import React from 'react';
import { reduxForm, getFormValues } from 'redux-form';
import styled from 'styled-components';
import Dialog from '@biocad/bcd-front-ui/layout/Dialog';
import {Product} from '../../../../products/model';
import { ProductFormFields } from '../../../../products/components/ProductFormFields';
import type {IReactionEditDialogWrapComponentProps} from '../ReactionEditDialogWrap';
import Button from '@biocad/bcd-front-ui/controls/Button';
import classNames from 'classnames';
import type { IFormWrappedComponentProps } from '../../../../shared/models';
import { connect } from 'react-redux';
import type {IRootStore} from '../../../../app/reducers';
import type {IProduct} from '../../../../products/model';

interface IReactionAddProductDialogProps extends IReactionEditDialogWrapComponentProps, IFormWrappedComponentProps {
  className?: string,
  formValues?: IProduct,
  onProductSave?: (product: IProduct)=>void
}

const defaultProps: IReactionAddProductDialogProps = {
  className: ''
};

const REACTION_ADD_PRODUCT_FORM_NAME = 'reactionAddProduct';

let ReactionAddProductDialog = (props: IReactionAddProductDialogProps = defaultProps) => (
  <Dialog paranjaClose={false} close={() => props.closeDialog()}>
    <div className={classNames('dialog-layout', props.className)}>
      <header className={'dialog-header'}>
        <h3>Добавление побочного продукта</h3>
      </header>
      <div className={'dialog-contents'}>
        <ProductFormFields
          product={props.formValues}
        />
      </div>
      <footer className={'dialog-footer'}>
        <Button
          onAction={()=> {
            if (props.onProductSave && typeof props.onProductSave === 'function') {
              props.onProductSave({...props.formValues, MainAgentContent: 100});
            }
            props.closeDialog();
          }}
          text={'Выбрать'}
          size={'L'}
          view={'primary'}
          disabled={props.invalid}
        />
        <Button
          onAction={()=>props.closeDialog()}
          text={'Отменить'}
          size={'L'}
        />
      </footer>
    </div>
  </Dialog>
);

ReactionAddProductDialog = reduxForm({
  form: REACTION_ADD_PRODUCT_FORM_NAME,
  destroyOnUnmount: true,
  initialValues: Product({
    ExperimentalMainAgentContent: 100,
    IsMainProduct: false
  })
})(ReactionAddProductDialog);

ReactionAddProductDialog = connect(
  (state: IRootStore): IReactionAddProductDialogProps => ({
    formValues: getFormValues(REACTION_ADD_PRODUCT_FORM_NAME)(state)
  }),
  null
)(ReactionAddProductDialog);

ReactionAddProductDialog = styled(ReactionAddProductDialog)`
  width: 1200px;
  .b-FormFieldLayout:not(:last-child) {
    margin-bottom: var(--FormFieldLayout-margin-b) !important;
  }
`;

export {
  ReactionAddProductDialog
};
