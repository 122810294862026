import bnc                 from 'bnc';
import { Component }       from 'react';
import propTypes           from 'prop-types';
import Tooltip             from '../../layout/Tooltip';
import palette             from '../../colors/palette.js';
import                          './index.less';

const optionType = propTypes.oneOfType([
    propTypes.string,
    propTypes.shape({
        label:     propTypes.node,
        value:     propTypes.any
    })
]);

let instanceCounter = 0;



/**
## Dropdown

Controls:
- mouse/touch: click label to toggle, click option to select
- keyboard: tab to get focus, enter/space to toggle, up/down to preselect, enter/space to confirm selection
 */

export default class Dropdown extends Component {

    static Arrow = () =>
        <svg width="13" height="9" viewBox="0 0 13 9" fill={palette['grey-400']}>
            <path d="M5.58594 8.25L0.273438 2.9375C0.0911458 2.75521 0 2.53385 0 2.27344C0 2.01302 0.0911458 1.79167 0.273438 1.60938L1.17188 0.75C1.35417 0.567708 1.57552 0.476562 1.83594 0.476562C2.09635 0.476562 2.31771 0.567708 2.5 0.75L6.25 4.5L10 0.75C10.1823 0.567708 10.4036 0.476562 10.6641 0.476562C10.9245 0.476562 11.1458 0.567708 11.3281 0.75L12.2266 1.60938C12.4089 1.79167 12.5 2.01302 12.5 2.27344C12.5 2.53385 12.4089 2.75521 12.2266 2.9375L6.91406 8.25C6.73177 8.43229 6.51042 8.52344 6.25 8.52344C5.98958 8.52344 5.76823 8.43229 5.58594 8.25Z" fill="@{grey-400}"/>
        </svg>
    ;

    static propTypes = {
        options:      propTypes.arrayOf(optionType).isRequired,
        defaultValue: optionType,
        placeholder:  propTypes.node,
        disabled:     propTypes.bool,
        valid:        propTypes.bool, // strictly optional, default value is undefined
        expanded:     propTypes.bool,
        fit:          propTypes.bool,
        onChange:     propTypes.func.isRequired,
        className:    propTypes.oneOfType([propTypes.string, propTypes.instanceOf(bnc)]),

    };

    static defaultProps = {
        placeholder: 'Выберите из списка',
        disabled:     false,
        expanded:     false,
        fit:          true,
        className:    '',
    };

    static block = new bnc.default('b-dropdown');

    static getVisibleLabel = option =>
        (option && (typeof option === 'object') && ('label' in option))
            ? option.label
            : option
    ;

    static getValue = option =>
        (option && (typeof option === 'object') && ('label' in option))
            ? option.value
            : option
    ;

    static getDerivedStateFromProps = ({value, defaultValue, options: propsOptions}, {selectedOption, options: stateOptions, prevValue}) => (
        {
            prevValue:      value,
            selectedOption: (value !== void(0) || value !== prevValue)
                                    ? value
                                    : selectedOption !== void(0)
                                        ? selectedOption
                                        : defaultValue
        }
    );

    // required for assistive technology
    rootId = `dropdown-${instanceCounter++}`

    state = {
        byToggle:       false, // TODO derivative of reason that caused opened to change, turn into explicit VisibilityState type?
        opened:         false,
        pendingOption:  null,
        selectedOption: void(0)
    }

    componentDidMount() {
        // register handlers for clickOutside and Escape behaviouts
        document.addEventListener('touchend', this.handleClickOutside, true);
        document.addEventListener('click',    this.handleClickOutside, true);
        document.addEventListener('keyup',    this.handleEscapePress,  true);
    }

    componentDidUpdate(prevProps, prevState) {

        // transfer focus from label to listbox and back when appropriate
        if (prevState.opened !== this.state.opened && this.state.byToggle) {
            if (this.state.opened) {
                if (!this.state.pendingOption) {
                    this.setState({
                        pendingOption: this.state.selectedOption
                    });
                }
            } else {
                this.labelNode.current && this.labelNode.current.focus();
            }
        }
    }

    componentWillUnmount() {
        // clean up what we did in cDM
        document.removeEventListener('touchend', this.handleClickOutside, true);
        document.removeEventListener('click',    this.handleClickOutside, true);
        document.removeEventListener('keyup',    this.handleEscapePress,  true);
    }

    handleClickOutside = e => {
        // some touch devices fire both touchend and click,
        // some legacy ones only do the latter
        // FIXME react-click-outside does it, but do *we* need it?
        if (e.type === 'touchend') {this.isTouch = true;}
        if (e.type === 'click' && this.isTouch) {return;}

        if (
            [this.labelNode, this.popoverListboxNode].some(
                ({current}) => current && (current === e.target || current.contains(e.target))
            )
        ) {
            return;
        }

        this.closeByFocusOut(e);
    }

    /**
     * @param e {KeyboardEvent}
     */
    handleEscapePress = e => {
        if (e.key === 'Escape' && this.state.opened) {
            // FIXME select(selectedOption) closes implicitly, make it explicitly clear pendingOption and close?
            this.select(this.state.selectedOption, 'selected');
        }
    }

    handleFocusIn = ({currentTarget: focusedElement}) => this.setState({ focusedElement })

    handleFocusOut = () => {
        // waiting for focus to be transferred in componentDidUpdate or by user tabbing
        // FIXME rAF hack used won't work with AsyncMode
        requestAnimationFrame(() => {
            if (
                this.state.opened
                && document.activeElement !== document.body // because we could be handling clickOutside twice
                && document.activeElement !== this.labelNode.current
            ) {
                this.closeByFocusOut();
            }
        });
    }

    handleKeyDown = event => {
        var { opened,
              pendingOption,
              selectedOption }  = this.state,
            { options }         = this.props,
            { key }             = event,
            selectedIndex       = selectedOption ? options.indexOf( selectedOption ) : -1,
            pendingIndex        = pendingOption  ? options.indexOf( pendingOption  ) : selectedIndex,
            { length }          = options;

        switch (true) {
            // Keyboard navigation for options
            case key === 'ArrowDown':
                this.select(
                    pendingIndex === -1
                        ? options[0]
                        : options[(length + pendingIndex + 1) % length]
                    ,
                    'pending'
                );
                break;

            case key === 'ArrowUp':
                this.select(
                    pendingIndex === -1
                        ? options[length-1]
                        : options[(length + pendingIndex - 1) % length]
                    ,
                    'pending'
                );
                break;

            case key === 'Home':
                this.select(
                    options[0],
                    'pending'
                );
                break;

            case key === 'End':
                this.select(
                    options[length-1],
                    'pending'
                );
                break;

            // Confirms selection
            case (key === 'Enter' || key === ' ') && !!pendingOption:
                this.select(
                    pendingOption,
                    'selected'
                );
                break;

            // Toggles listbox visibility
            case (key === 'Enter' || key === ' ') && !pendingOption:
                this.toggle();
                break;

            // Cancels pending selection
            case (key === 'Escape' && opened):
                this.select(
                    selectedOption || null,
                    'selected'
                );
                break;

            default: return;
        }

        event.preventDefault();
    }

    handleMouseDetected = () =>
        this.setState({
            pendingOption: null
        })

    handleLabelClick = (event) => {
        this.toggle();
        event.preventDefault();
    }

    renderLabel() {
        return Dropdown.getVisibleLabel(this.state.selectedOption) || this.props.placeholder;
    }

    renderOptionVisible(option, index) {
        var { expanded } = this.props,
            selected     = option === this.state.selectedOption,
            pending      = option === this.state.pendingOption,
            label        = Dropdown.getVisibleLabel(option);

        return (
            <li
                key       = { `option-${index}`                     }
                onClick   = { () => this.select(option, 'selected') }
                className = {
                    Dropdown.block.el('option') +
                    (selected ? Dropdown.block.el('option').mod('selected') : '') +
                    (pending  ? Dropdown.block.el('option').mod('pending')  : '') +
                    Dropdown.block.el('option').mod( expanded ? 'expanded' : 'oneline' )
                }
            >{label}</li>
        );
    }

    toggle = () =>
        this.setState({
            opened:   !this.state.opened,
            byToggle: true
        })

    closeByFocusOut() {
        this.setState({
            opened:         false,
            byToggle:       false,
            pendingOption:  null,
            focusedElement: null
        });
    }

    /**
     *
     * @param {Option} option
     * @param {'pending' | 'selected'} eventType How the option was selected
     */
    select = (option, eventType) => {
        var state = {
                [
                    eventType === 'pending'
                        ? 'pendingOption'
                        : 'selectedOption'
                ] : option,

                ...(eventType === 'selected'
                        ? { byToggle: true, opened: false, pendingOption: null }
                        : eventType === 'pending'
                            ? { byToggle: true, opened: true }
                            : {}
                )
            },

            changed = ('selectedOption' in state) &&
                      (state.selectedOption !== this.state.selectedOption);

        this.setState(
            state,
            () => changed &&
                    this.props.onChange(
                        Dropdown.getValue(
                            state.selectedOption
                        )
                    )
        );
    }

    labelNode          = React.createRef();
    popoverListboxNode = React.createRef();

    renderDropdown = ({ disabled, expanded, valid, options, fit, className = '' }, { opened, selectedOption, focusedElement }) =>
        <div className={Dropdown.block + className}>
            <button
                ref                   = {this.labelNode}
                tabIndex              = {focusedElement ? -1 : null}
                disabled              = {disabled}
                onClick               = {this.handleLabelClick}
                onKeyDown             = {this.handleKeyDown}
                className             = {
                    Dropdown.block.el('label') +
                    (opened                     ? Dropdown.block.el('label').mod('opened') : ''                   ) +
                    (selectedOption             ? Dropdown.block.el('label').mod('has-selection') : ''            ) +
                    (typeof valid === 'boolean' ? Dropdown.block.el('label').mod(valid ? 'valid' : 'invalid') : '')
                }
            >
                <span className={`${Dropdown.block.el('label-text')}`}>
                    {this.renderLabel()}
                </span>
                <span
                    role      = 'presentation'
                    className = {
                        Dropdown.block.el('label-icon') +
                        (opened ? Dropdown.block.el('label-icon').mod('opened') : '')
                    }
                ><Dropdown.Arrow /></span>
            </button>
            {
                !!opened &&
                <Tooltip
                    fit     = {fit}
                    onClose = {this.toggle}
                    transparent
                    notail
                >
                    <ul
                        ref          = {this.popoverListboxNode}
                        onMouseEnter = {this.handleMouseDetected}
                        className    = {
                            Dropdown.block.el('options') +
                            (className ? `${(className+'').trim()}-options` : '' ) +
                            (expanded ? Dropdown.block.el('options').mod('expanded') : '')
                        }
                    >
                        {
                            // render here for mouse / touch
                            options.map(this.renderOptionVisible, this)
                        }
                    </ul>
                </Tooltip>
            }
        </div>

    render = () => this.renderDropdown(this.props, this.state)

}

Dropdown.Arrow.displayName = 'Dropdown.Arrow';