// @flow

import React, {
	useState,
	useRef,
} from 'react';
import bnc from 'bnc';
import Button from '@biocad/bcd-front-ui/controls/Button';
import Input from '@biocad/bcd-front-ui/controls/Input';
import { bncInstances } from '../../../../../shared/components/MultiValueInput';
import type { IReactionParameter } from '../../../../models';
import type { IFieldInputProps, IFieldMetaProps } from '../../../../../shared/models';
import { FormFieldLayout } from '../../../../../shared/components/FormFieldLayout';
import { ReactionParameter } from '../../../../models';

import './index.less';

const
	BNCBlock = new bnc('b-ReactionParametersInput'),
	BNCLabel = BNCBlock.el('label'),
	BNCLabelTemperature = BNCLabel.mod('temperature'),
	BNCLabelDuration = BNCLabel.mod('duration'),
	BNCInputWrap = BNCBlock.el('inputWrap'),
	BNCInput = BNCBlock.el('input'),
	BNCItemText = BNCBlock.el('itemText'),
	BNCItemTextHours = BNCItemText.mod('hours'),
	BNCItemTextMinutes = BNCItemText.mod('minutes'),
	BNCItemTextTemperature = BNCItemText.mod('temperature'),
	BNCBtn = BNCBlock.el('btn'),
	BNCBtnAdd = BNCBtn.mod('add'),
	BNCBtnRemove = BNCBtn.mod('remove')
;

interface IReactionParametersInputProps {
	reactionId: number,
	input?: IFieldInputProps,
	meta?: IFieldMetaProps,
	className?: string,
}

const defaultProps: IReactionParametersInputProps = {
	className: '',
	reactionId: null,
};

const
	renderItemText = (item: IReactionParameter) => {
		const
			minutes = item.Duration % 60,
			hours = ((item.Duration - minutes) / 60)
		;
		return (
			<>
				<span className={bncInstances.bValueItemText + BNCItemText + BNCItemTextHours}>{
					(hours !== null) ? `${hours.toFixed(0)} ч` : ''
				}</span>
				<span className={bncInstances.bValueItemText + BNCItemText + BNCItemTextMinutes}>{
					(minutes !== null) ? `${minutes.toFixed(0)} мин` : ''
				}</span>
				<span className={bncInstances.bValueItemText + BNCItemText + BNCItemTextTemperature}>{
					(item.TemperatureInterval !== null) ? `${item.TemperatureInterval} ºC` : ''
				}</span>
			</>
		)
	},
	sortParameters = (a: IReactionParameter, b: IReactionParameter) => (
		a.IndexNumber - b.IndexNumber
	)
;

export const ReactionParametersInput = (props: IReactionParametersInputProps = defaultProps) => {
	// State
	const
		[hoursValue, setHoursValue]             = useState<string>(''),
		[minutesValue, setMinutesValue]         = useState<string>(''),
		[temperatureValue, setTemperatureValue] = useState<string>('')
	;
	const valid = (props.meta?.valid === false || props.meta?.warning) ? false : undefined;

	// Refs
	const
		inputDurationHoursRef = useRef<HTMLInputElement>(),
		inputDurationMinutesRef = useRef<HTMLInputElement>(),
		inputTemperatureRef = useRef<HTMLInputElement>()
	;

	// Methods
	const
		updateInputValue = (event: SyntheticEvent, elem: React$Ref) => {
			if (!('value' in event.target)) return;
			switch (elem?.current) {
				case inputDurationHoursRef?.current: {
					setHoursValue(event.target.value);
					break;
				}
				case inputDurationMinutesRef?.current: {
					setMinutesValue(event.target.value);
					break;
				}
				case inputTemperatureRef?.current: {
					setTemperatureValue(event.target.value.replace(/[,]/gi, '.'));
					break;
				}
				default: return;
			}
		}
	;

	// Handlers
	const
		passOnFocus = () => {
			props.input?.onFocus()
		},
		passOnBlur = () => {
			props.input?.onBlur()
		},
		filterInputValue = (event: SyntheticEvent, elem: React$Ref, cb: (event: SyntheticEvent, elem: React$Ref)=>void) => {
			if (!('value' in event.target)) return;
			if (event.target?.value === '') {
				updateInputValue(event, elem);
				return;
			}
			cb(event, elem);
		},
		preventNonIntegerInput = (event: SyntheticEvent, elem: React$Ref) => {
			const nonIntegerValue = event.target.value?.replace(/[\d]/gi, '');
			if (nonIntegerValue) {
				event.preventDefault();
				return;
			}
			updateInputValue(event, elem);
		},
		preventNonNumericInput = (event: SyntheticEvent, elem: React$Ref) => {
			const nonNumericValue = event.target.value?.replace(/[\d.,-]/gi, '');
			if (nonNumericValue) {
				event.preventDefault();
				return;
			}
			updateInputValue(event, elem);
		},
		addItemToList = () => {
			const
				hours = parseInt(hoursValue, 10),
				minutes = parseInt(minutesValue, 10)
			;
			if (isNaN(hours) || isNaN(minutes) || !temperatureValue) return;
			let item: IReactionParameter;
			try {
				item = ReactionParameter({
					Duration: (hours * 60) + minutes,
					ReactionId: props.reactionId,
					TemperatureInterval: temperatureValue,
					IndexNumber: Array.isArray(props.input?.value) ? props.input?.value?.length : 0,
				});
			} catch (e) {
				throw e;
			}
			if (item) props.input?.onChange([...props.input?.value, item]);
			setHoursValue('');
			setMinutesValue('');
			setTemperatureValue('');
		},
		removeItemFromList = (item: IReactionParameter) => {
			if (item.Id) {
				let deletedItem: IReactionParameter;
				try {
					deletedItem = new ReactionParameter({ ...item, IsDeleted: true });
				} catch (e) {
					throw e;
				}
				if (deletedItem) {
					props.input?.onChange([...props.input?.value.map((i: IReactionParameter) => i.Id === item.Id ? deletedItem : i)])
				}
			}
			else {
				props.input?.onChange([...props.input?.value.filter((i: IReactionParameter) => i !== item)])
			}
		}
	;

	return (
		<FormFieldLayout
			valid={valid}
			invalidMessage={props.meta?.warning instanceof Error ? props.meta?.warning?.message : null}
			className={`${props.className} ${BNCBlock}`}
		>
			<label className={BNCLabel + BNCLabelDuration + bncInstances.bLabelText}>
				Прод. (ч:мин)
			</label>
			<label className={BNCLabel + BNCLabelTemperature + bncInstances.bLabelText}>
				Темп. ºC
			</label>
			<div className={BNCInputWrap} data-control-after={':'}>
				<Input
					type={'text'}
					ref={inputDurationHoursRef}
					onChange={(event) => filterInputValue(event, inputDurationHoursRef, preventNonIntegerInput)}
					onBlur={passOnBlur}
					onFocus={passOnFocus}
					value={hoursValue}
					className={BNCInput}
					valid={valid}
				/>
			</div>
			<div className={BNCInputWrap} data-control-after={'-'}>
				<Input
					type={'text'}
					ref={inputDurationMinutesRef}
					onChange={(event) => filterInputValue(event, inputDurationMinutesRef, preventNonIntegerInput)}
					onBlur={passOnBlur}
					onFocus={passOnFocus}
					value={minutesValue}
					className={BNCInput}
					valid={valid}
				/>
			</div>
			<div className={BNCInputWrap}>
				<Input
					type={'text'}
					ref={inputTemperatureRef}
					onChange={(event) => filterInputValue(event, inputTemperatureRef, preventNonNumericInput)}
					onBlur={passOnBlur}
					onFocus={passOnFocus}
					value={temperatureValue}
					className={BNCInput}
					valid={valid}
				/>
			</div>
			<Button
				className={`${bncInstances.bBtn} ${bncInstances.bBtnAdd} ${BNCBtn} ${BNCBtnAdd} fo-plus`}
				onAction={addItemToList}
				view={'icon'}
				size={'L'}
				disabled={!hoursValue || !minutesValue || !temperatureValue || props.disabled}
			/>
			{
				(props.input?.value && Array.isArray(props.input?.value)) &&
				props.input?.value
					.sort(sortParameters)
					.filter((parameter: IReactionParameter) => !parameter.IsDeleted)
					.map((parameter: IReactionParameter, idx: number) => (
						<React.Fragment key={(parameter.Id && parameter.IndexNumber) ? `${parameter.Id}__${parameter.IndexNumber}` : `${idx}`}>
							{ renderItemText(parameter) }
							<Button
								className={`${bncInstances.bBtn} ${bncInstances.bBtnRemove} ${BNCBtn} ${BNCBtnRemove} fo-cross-thick`}
								onAction={() => removeItemFromList(parameter)}
								view={'icon'}
								size={'L'}
								disabled = {props.disabled}
							/>
						</React.Fragment>
				))
			}
		</FormFieldLayout>
	)
};
