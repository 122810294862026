import bnc from 'bnc';
import          './index.less';

const block = new bnc.default('b-textarea');

const Textarea = ({className = '', valid, disabled = false, children, onChange = ()=>true, ...rest}) =>
    <textarea
        className = {
            ( className ? `${className} ` : '') +
            block +
            block.mod('valid', valid === true ? 'true' : valid === false ? 'false' : 'undefined')
        }
        onChange = {onChange}
        disabled = {disabled}
        {...rest}
    />
;

export default Textarea;