// @flow

import React, {
  useEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';
import type { IObtainmentStage, IStageSubstance } from '../../models';
import { PurificationBundleTypeEnum } from '../../models';
import { stageModuleActions, stageResourceActions } from '../../actions';
import { getCurrentStage, getStageEntrySelector, getStageProductSelector } from '../../reducers';
import { _noop } from '../../../shared/utils/common';
import { setGlobalLoader } from '../../../shared/actions/loading';
import { StyledPageLayout } from './style';
import Input from '@biocad/bcd-front-ui/controls/Input';
import Button from '@biocad/bcd-front-ui/controls/Button';
import { LayoutBlock } from '../../../shared/components/LayoutBlock';
import { StageReactionList } from './StageReactionList';
import { StageSubstancePicker } from './StageSubstancePicker';
import { StageSubstanceEdit } from './StageSubstanceEdit';
import queryString from 'query-string';
import { globalErrorsActions } from '../../../shared/actions';
import { schemesResourceActions } from '../../../schemes/actions';
import type { IObtainmentScheme } from '../../../schemes/models';
import {
  PageLayout,
  PageLayoutSlot,
  PageLayoutHeader,
  PageLayoutBody,
  pageLayoutAreas,
} from '../../../shared/components/PageLayout';
import Dialog from '@biocad/bcd-front-ui/layout/Dialog';
import { ConfirmDialog } from '../../../shared/components';
import styled from 'styled-components';
import { userCRUDPermissionsSelector } from '../../../account/reducers/users-modules-reducer';
import { StageIndicatorsList } from '../../../stage-indicators/components/StageIndicatorsList';
import { submit } from "redux-form";
import { STAGE_INDICATORS_FORM_NAME } from "../../../stage-indicators/components/StageIndicatorsList/StageIndicatorsForm";
import Dropdown from '@biocad/bcd-front-ui/controls/Dropdown';
import {SchemeBreadcrumbs} from '../../../schemes/components/SchemeBreadcrumbs';

interface IStageEditProps {
  stage?: IObtainmentStage,
  currentStage?: IObtainmentStage,
  currentSchemeId?: number,
  currentScheme?: IObtainmentScheme,
  currentCanBeModifiedOrDeleted?: boolean,
  isFirstStageInScheme?: boolean,
  preSaveReady?: boolean,
  preSaveSchemeReady?: boolean,
  saveOrDeleteSuccess?: boolean,
  schemeUpdateSuccess?: boolean,
  marvinPending?: boolean,
  entrySubstance?: IStageSubstance,
  productSubstance?: IStageSubstance,
  uniteSchemeId?: number;
  match?: {
    params: {
      id: string,
    }
  },
  history?: {
    push?: typeof Function,
  },
  location?: {
    search?: string,
  },
  getStage?: (id: number) => null,
  updateStage?: (number, IObtainmentStage) => null,
  createStage?: (IObtainmentStage) => null,
  deleteStage?: (number) => null,
  getScheme?: (number) => null,
  getLastStage?: (number) => null,
  destroyStage?: () => null,
  setLoader?: (boolean) => null,
  setCurrentId?: (number) => null,
  setCurrentSchemeId?: (number) => null,
  preSaveStage?: (IObtainmentStage) => null,
  preSaveScheme?: () => null,
  updateScheme?: (IObtainmentScheme) => null,
  setError?: (string) => null,
  setCurrentCanBeModifiedOrDeleted?: (boolean) => null,
  setCurrentNumber?: (number) => null,
  destroyModule?: () => null,
  canonicalizeSubstances?: () => null,
  userPermissions: {},
  submitStageIndicators?: () => null,
  setPurificationsCount?: () => null,
  getStageRelatedData?: (id: number) => null,
}

export const ADD_STAGE_URI_SUBSTR = 'add';

const defaultProps: IStageEditProps = {
  stage: null,
  currentCanBeModifiedOrDeleted: false,
  isFirstStageInScheme: null,
  preSaveReady: null,
  preSaveSchemeReady: null,
  saveOrDeleteSuccess: null,
  currentScheme: null,
  entrySubstance: null,
  productSubstance: null,
  schemeUpdateSuccess: null,
  marvinPending: false,
  uniteSchemeId: null,
  match: {
    params: {}
  },
  history: {
    push: _noop,
  },
  location: {
    search: null,
  },
  getStage: _noop,
  updateStage: _noop,
  createStage: _noop,
  deleteStage: _noop,
  getScheme: _noop,
  getLastStage: _noop,
  destroyStage: _noop,
  setLoader: _noop,
  setCurrentId: _noop,
  setCurrentSchemeId: _noop,
  preSaveScheme: _noop,
  updateScheme: _noop,
  setError: _noop,
  setCurrentCanBeModifiedOrDeleted: _noop,
  setCurrentNumber: _noop,
  destroyModule: _noop,
  setPurificationsCount: _noop,
};

let StageEdit = (props: IStageEditProps = defaultProps) => {
  // States
  const [stageIsNew, setStageIsNew] = useState(false);
  const [stageNumber, setStageNumber] = useState('');
  const [disableSaveBtn, setDisableSaveBtn] = useState(true);
  const [deleteStageConfirmShow, setDeleteStageConfirmShow] = useState(false);
  const [purificationsCount, setPurificationsCount] = useState(0);

  // Effects
  const _initialStageLoading = () => {
    props.destroyStage();
    props.destroyModule();
    if ('id' in props.match.params) {
      if (!isNaN(Number.parseInt(props.match?.params?.id, 10))) {
        props.getStageRelatedData(props.match.params.id);
        props.setCurrentId(Number(props.match?.params?.id, 10));
        props.setLoader(true);
      }
      else if (props.match.params.id === ADD_STAGE_URI_SUBSTR) {
        setStageIsNew(true);
      }
      else {
        props.history.push('/no-match');
      }
    }
  };
  useEffect(_initialStageLoading, []);

  const _onUnmountCleanUp = () => () => {
    props.destroyStage();
    props.destroyModule();
  };
  useEffect(_onUnmountCleanUp, []);

  const _onStageLoaded = () => {
    if (stageIsNew) return;
    props.stage?.Id && props.setLoader(false);
    props.setCurrentSchemeId(props.stage?.ObtainmentSchemeId);
    props.setCurrentNumber(props.stage?.TreeNumber);
    props.setCurrentCanBeModifiedOrDeleted(props.stage?.CanBeModifiedOrDeleted);
    props.stage?.TreeNumber && setStageNumber(props.stage.TreeNumber);
    props.stage?.RequiredPurificationsCount && setPurificationsCount(props.stage.RequiredPurificationsCount);
    props.setPurificationBundleType(props.stage?.PurificationBundleType);
    if (props.stage?.ObtainmentSchemeId) {
      props.getLastStage(props.stage?.ObtainmentSchemeId, false);
    }
  };
  useEffect(_onStageLoaded, [props.stage]);

  const _watchStageIsNew = () => {
    if (stageIsNew) {
      const { schemeId } = queryString.parse(props.location.search);
      if (!schemeId || isNaN(Number.parseInt(schemeId, 10))) {
        props.setError('Не указана схема к которой пренадлежит новая стадия!');
        props.history.push('/no-match');
        return;
      }
      props.setCurrentSchemeId(Number.parseInt(schemeId, 10));
      props.setCurrentCanBeModifiedOrDeleted(true);
      props.setPurificationsCount(0);
      props.setPurificationBundleType(PurificationBundleTypeEnum.Parallel);
      //shouldInitEntrySubstance = true, после подгрузки последней стадии
      //схемы "доинициализируем" входящее вещество на уровне редьюсера
      props.getLastStage(schemeId, true);
    }
  };
  useEffect(_watchStageIsNew, [stageIsNew]);

  const _loadParentScheme = () => {
    if (props.isFirstStageInScheme !== null && props.currentSchemeId) {
      props.getScheme(props.currentSchemeId);
    }
  };
  useEffect(_loadParentScheme, [props.isFirstStageInScheme, props.currentSchemeId]);

  const _requestStageSaveOnPreSave = () => {
    if (props.preSaveReady !== null && props.preSaveReady) {
      if (stageIsNew) {
        props.createStage(props.currentStage);
        return;
      }
      props.updateStage(props.currentStage.Id, props.currentStage);
    }
  };
  useEffect(_requestStageSaveOnPreSave, [props.preSaveReady]);

  const _redirectOnSuccessSaveOrDelete = () => {
    if (props.saveOrDeleteSuccess !== null && props.saveOrDeleteSuccess) {
      props.history.push(`/schemes/${props.currentSchemeId}`);
    }
  };
  useEffect(_redirectOnSuccessSaveOrDelete, [props.saveOrDeleteSuccess]);

  const purificationBundleOptions = [{ value: PurificationBundleTypeEnum.Parallel, label: 'Параллельные' }, { value: PurificationBundleTypeEnum.Sequential, label: 'Последовательные' }];

  const _preventSaveOnDataChange = () => {
    setDisableSaveBtn(
      (stageIsNew && !props.currentStage?.TreeNumber)
      || !props.entrySubstance?.Thumbnail
      || !props.productSubstance?.Thumbnail
      || props.marvinPending
      || !props.currentStage?.TreeNumber
    );
  };
  useEffect(_preventSaveOnDataChange, [
    stageIsNew,
    props.currentStage?.TreeNumber,
    props.entrySubstance?.Thumbnail,
    props.productSubstance?.Thumbnail,
    props.marvinPending,
  ]);

  const _updateSchemeWhenReady = () => {
    if (props.preSaveSchemeReady !== null && props.preSaveSchemeReady) {
      props.updateScheme(props.currentScheme);
    }
  };
  useEffect(_updateSchemeWhenReady, [props.preSaveSchemeReady]);

  const _saveStageWhenSchemeUpdated = () => {
    if (props.schemeUpdateSuccess !== null && props.schemeUpdateSuccess) {
      props.preSaveStage(props.stage);
      props.submitStageIndicators();
    }
  };
  useEffect(_saveStageWhenSchemeUpdated, [props.schemeUpdateSuccess]);

  useEffect(() => {
    if (!stageIsNew && !props.stage?.CanBeModifiedOrDeleted)
    {
      const disable = purificationsCount === props.stage?.RequiredPurificationsCount
          && props.currentPurBundleType === props.stage?.PurificationBundleType;
      if (disable !== disableSaveBtn)
      {
        setDisableSaveBtn(disable);
      }
    }
  }, [purificationsCount, props.currentPurBundleType])

  // Handlers
  const
    onDeleteStageClick = () => {
      setDeleteStageConfirmShow(false);
      props.deleteStage(props.currentStage.Id);
    },
    gotoScheme = () => {
      props.history.push(`/schemes/${props.currentSchemeId}`);
    },
    onSaveSubmit = () => {
      // if (props.isFirstStageInScheme) {
      //   props.preSaveScheme();
      //   return;
      // }
      // props.preSaveStage(props.stage);
      props.canonicalizeSubstances();
    },
    onInputChange = (event) => {
      if (!('value' in event.target)) return;
      if (event.target.value === '') {
        props.setCurrentNumber('');
        setStageNumber('');
        return;
      }
      const value = Number.parseInt(event.target.value?.replace(/[\D]/gi, ''), 10);
      if (isNaN(value) && !Number.isInteger(value)) {
        return;
      }
      props.setCurrentNumber(value);
      setStageNumber(value.toString());
    },
    onPurificationsCountChange = (event) => {
      const value = Number.parseInt(event.target.value?.replace(/[\D]/gi, ''), 10) | 0;
      props.setPurificationsCount(value);
      setPurificationsCount(value);
    },
    onPurificationBundleTypeChange = (event) => {
      props.setPurificationBundleType(event);
    };

  return (
    <>
      <PageLayout as={StyledPageLayout}>
        <PageLayoutSlot gridAreaName={pageLayoutAreas.header}
          className={pageLayoutAreas.header}
          as={PageLayoutHeader}>
          <PageLayoutSlot gridAreaName={pageLayoutAreas.breadcrumbs} className={'no-overflow'} >
            <SchemeBreadcrumbs schemeId={props.stage?.ObtainmentSchemeId || props.currentSchemeId} showScheme={true} />
          </PageLayoutSlot>
          <PageLayoutSlot gridAreaName={pageLayoutAreas.headerBackBtn}
            className={pageLayoutAreas.headerBackBtn}>
            <Button
              className={'fo-folder-back'}
              onAction={gotoScheme}
              view={'icon'}
              size={'L'}
            />
          </PageLayoutSlot>
          <PageLayoutSlot gridAreaName={pageLayoutAreas.headerLeftSide}
            className={pageLayoutAreas.headerLeftSide}>
            <div className={'h2'}>
              <span>Стадия</span>
              {
                props.currentCanBeModifiedOrDeleted
                  ? <Input
                    onChange={onInputChange}
                    placeholder={'Номер'}
                    value={stageNumber}
                    className={'inputStageNumber'}
                    disabled={!(props.userPermissions.stage.UPDATE || props.userPermissions.stage.CREATE)}
                  />
                  : <span className={'inputStageNumber'}>{props.stage?.TreeNumber}</span>
              }
            </div>
          </PageLayoutSlot>
          <PageLayoutSlot gridAreaName={pageLayoutAreas.headerRightSide}
            className={pageLayoutAreas.headerRightSide}>
            {
              (!stageIsNew && props.stage?.Id) &&
              <StageIndicatorsList
                className={'stageIndicatorsBtn'}
                stageId={props.stage.Id}
                stage={props.stage}
              />
            }
            {
              props.currentCanBeModifiedOrDeleted &&
                (!stageIsNew && props.userPermissions.stage.DELETE) &&
                <Button
                  className={'delete-btn fo-trash'}
                  onAction={() => setDeleteStageConfirmShow(true)}
                  view={'icon'}
                  size={'L'}
                />
            }
            {
              (props.userPermissions.stage.UPDATE || props.userPermissions.stage.CREATE) &&
              <Button
                  className={'save-btn'}
                  onAction={onSaveSubmit}
                  text={'Сохранить'}
                  size={'L'}
                  view={'primary'}
                  disabled={disableSaveBtn}
              />
            }
            <Button
              className={'cancel-btn'}
              onAction={gotoScheme}
              text={'Отмена'}
              size={'L'}
            />
          </PageLayoutSlot>
        </PageLayoutSlot>
        <PageLayoutSlot gridAreaName={pageLayoutAreas.body}
          className={pageLayoutAreas.body}
          as={PageLayoutBody}>
          <PageLayoutSlot gridAreaName={pageLayoutAreas.bodyTopLeft}
            className={pageLayoutAreas.bodyTopLeft}>
            <div className='stage-properties'>
              <label>
                <span>Обязательные реакции очистки:</span>
                {
                  (props.userPermissions.stage.UPDATE || props.userPermissions.stage.CREATE)
                    ? <>
                        <Input
                          onChange={onPurificationsCountChange}
                          placeholder={''}
                          value={purificationsCount}
                          className={'purification-count'}
                        />
                        <Dropdown
                          options={purificationBundleOptions}
                          value={purificationBundleOptions[props.currentPurBundleType]}
                          onChange={onPurificationBundleTypeChange}
                        />
                      </>
                    : <span>
                        &nbsp;{purificationsCount}
                        &nbsp;({purificationBundleOptions.find(i => i.value === props.currentPurBundleType)?.label})
                      </span>
                }
              </label>
            </div>
            <StageSubstancePicker />
          </PageLayoutSlot>
          <PageLayoutSlot gridAreaName={pageLayoutAreas.bodyBottomLeft}
            as={StageSubstanceEdit}
          />
          {
            !stageIsNew &&
            <PageLayoutSlot gridAreaName={pageLayoutAreas.bodyRight}
              className={pageLayoutAreas.bodyRight}
              as={StageReactionList}
            />
          }
        </PageLayoutSlot>
        {/* Место под боковую панель навигации */}
        {/*<PageLayoutSlot gridAreaName={pageLayoutAreas.leftSide} />*/}
      </PageLayout>
      {/* Dialogs */}
      {
        deleteStageConfirmShow &&
        <ConfirmDialog
          title={'Удалить стадию?'}
          message={''}
          confirmLabel={'Ок'}
          declineLabel={'Отмена'}
          confirmAction={onDeleteStageClick}
          declineAction={() => setDeleteStageConfirmShow(false)}
        />
      }
    </>
  );
};

StageEdit = connect(
  (state): IStageEditProps => ({
    stage: getCurrentStage(state),
    currentScheme: state.modules.stage.editStage?.currentScheme,
    currentStage: state.modules.stage.editStage?.current,
    currentCanBeModifiedOrDeleted: state.modules.stage.editStage?.current?.CanBeModifiedOrDeleted,
    currentSchemeId: state.modules.stage.editStage?.current?.ObtainmentSchemeId,
    currentPurBundleType: state.modules.stage.editStage?.current?.PurificationBundleType,
    isFirstStageInScheme: state.modules.stage.editStage?.isFirstStageInScheme,
    preSaveReady: state.modules.stage.editStage?.preSaveReady,
    saveOrDeleteSuccess: state.modules.stage.editStage?.saveOrDeleteSuccess,
    entrySubstance: getStageEntrySelector(state),
    productSubstance: getStageProductSelector(state),
    preSaveSchemeReady: state.modules.stage.editStage?.preSaveSchemeReady,
    schemeUpdateSuccess: state.modules.stage.editStage?.schemeUpdateSuccess,
    marvinPending: state.modules.stage.editStage?.marvinPending,
    userPermissions: userCRUDPermissionsSelector(state),
  }),
  (dispatch): IStageEditProps => ({
    getStage: (id: number) => dispatch(stageResourceActions.get.request({ uriParams: { id } })),
    updateStage: (id: number, data: IObtainmentStage) => dispatch(stageResourceActions.update.request({ uriParams: { id }, data })),
    createStage: (data: IObtainmentStage) => dispatch(stageResourceActions.create.request({ data })),
    deleteStage: (id: number) => dispatch(stageResourceActions.delete.request({ uriParams: { id } })),
    getScheme: (id: number) => dispatch(schemesResourceActions.get.request({ uriParams: { id } })),
    getLastStage: (id: number, shouldInitEntrySubstance: boolean) => dispatch(schemesResourceActions.getLastStage.request({ uriParams: { id }, params: { shouldInitEntrySubstance } })),
    destroyStage: () => dispatch(stageResourceActions.destroy()),
    setLoader: (isLoading) => dispatch(setGlobalLoader({ isLoading })),
    setCurrentId: (id) => dispatch(stageModuleActions.setCurrentStageId({ id })),
    setCurrentSchemeId: (schemeId) => dispatch(stageModuleActions.setCurrentSchemeId({ schemeId })),
    setCurrentCanBeModifiedOrDeleted: (flag: boolean) => dispatch(stageModuleActions.setCurrentCanBeModifiedOrDeleted({ flag })),
    preSaveStage: (stage: IObtainmentStage) => dispatch(stageModuleActions.preSaveStage({ stage })),
    setError: (error: string) => dispatch(globalErrorsActions.set({ error })),
    setCurrentNumber: (number: number) => dispatch(stageModuleActions.setCurrentNumber({ number })),
    setPurificationsCount: (count: number) => dispatch(stageModuleActions.setCurrentReqiredPurificationsCount({ count })),
    setPurificationBundleType: (value: number) => dispatch(stageModuleActions.setPurificationBundleType({ value })),
    destroyModule: () => dispatch(stageModuleActions.destroyModule()),
    preSaveScheme: () => dispatch(stageModuleActions.preSaveScheme()),
    updateScheme: (data: IObtainmentScheme) => dispatch(schemesResourceActions.update.request({ data })),
    canonicalizeSubstances: () => dispatch(stageModuleActions.canonicalizeSubstances()),
    submitStageIndicators: () => dispatch(submit(STAGE_INDICATORS_FORM_NAME)),
    getStageRelatedData: (id: number) => dispatch(stageModuleActions.loadStageRelatedData({ uriParams: { id } })),
  }),
)(StageEdit);

export {
  StageEdit,
};
