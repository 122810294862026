// @flow
import { moduleAction } from '../../shared/utils/action-creators';

export const STAGE_INDICATORS_MODULE = 'STAGE_INDICATORS';

export const stageIndicatorsModuleActions =
{
    loadStageIndicatorData: moduleAction(
        STAGE_INDICATORS_MODULE,
        ['LOAD_DATA'],
        ({ stageId }) => ({ stageId })
    ),
    updateOrCreateProcessPoint: moduleAction(
        STAGE_INDICATORS_MODULE,
        ['PROCESS_POINTS', 'UPDATE_OR_CREATE'],
        ({ data, stageId }) => ({ data, stageId })
    ),
    deleteOrCreateProcessPoint: moduleAction(
        STAGE_INDICATORS_MODULE,
        ['PROCESS_POINTS', 'DELETE'],
        ({ id, stageId }) => ({ id, stageId })
    ),
    sortByOrderPriority: moduleAction(
        STAGE_INDICATORS_MODULE,
        ['SORT_BY_ORDER_PRIORITY'],
        ({}) => ({})
    ),
    setStageIndicatorRowIdxForCreatedPP: moduleAction(
        STAGE_INDICATORS_MODULE,
        ['SET_STAGE_INDICATOR_ID_FOR_CREATE_PP'],
        ({ rowIdx }) => ({ rowIdx })
    )
}