// @flow
import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import {itemsToArraySelector} from '../../../../../shared/utils/selectors/resource-selectors';
import CommentWrapper from '../../../../../shared/components/CommentWrapper';
import FavoritesWrapper from '../../../../../shared/components/FavoritesWrapper';
import {productionSchemeResourceActions, folderSchemesResourceActions} from '../../../../../projects/actions';
import { withRouter } from 'react-router-dom';
import {userCRUDPermissionsSelector} from "../../../../../account/reducers/users-modules-reducer";
import {SelectFolder} from '../../../../../folders/components/SelectFolder' 
import {_temp_folderResourceAction} from '../../../../../folders/actions' 
import queryString from 'query-string';

const initialProps = {
    schemeList: [],
    currentCandidate: null,
    editSchemeComment: () => null,
    clearSchemesList: () => null,
    history: () => null,
    userPermissions: {},
    foldersList: [],
    getFoldersList: () => null,
    folderSavedSuccessful: false
};

const Schemes = (props = initialProps) => {

    useEffect(() => {
        return () => {
            props.clearProductSchemeList();
        }
    }, []);

    const getStageStatusClass = (stage) => {
        const conditions = [
            {'success': stage.HasCompleted && stage.HasPositiveYield},
            {'uncompleted': stage.HasIncomplete},
            {'fail': stage.HasCompleted && !stage.HasPositiveYield},
            {'trial-success': stage.HasSuccessfulTrial},
            {'trial': stage.HasIncompleteTrial},
            {'trial-fail': stage.HasFailedTrial}
        ];

        const found = conditions.find(c => Object.values(c)[0]);
        if (found) {
            return String(Object.keys(found)[0]);
        } else {
            return '';
        }
    };

    const getCorrectDate = (date) => {
        return date.slice(0, -3);
    };

    const editSchemeFavorite = (data) => {
        props.editSchemeIsFavorite({
            schemeId: data.itemId,
            isFavorite: data.isFavorite
        });
    }

    const editSchemeComment = (data) => {
        props.clearProductSchemeList();

        let urlParams = queryString.parse(props.location.search);
        let schemeFolderId = urlParams.schemeFolderId;
        

        props.editSchemeComment({
            schemeId: data.itemId,
            quickComment: data.comment || '',
            finalCandidateId: props.currentCandidate.Id,
            folderId: schemeFolderId
        });
    };

    const getFolders = () => {
        var folders = [...props.folders];
        if(props.foldersPath.length > 0) {
            var folder = props.foldersPath[props.foldersPath.length - 1];
            folders = [{Id: folder.id, Name: '../'+folder.name}, ...folders]
        }
        
        return folders;
    }

    const hasFolders =() => {
        return (props.foldersPath.length > 0 || (props.folders != null && Array.isArray(props.folders) && props.folders.length > 0))
    }

    const goToScheme = (id: number, e) => {
        if(e.target.dataset.id)
            props.history.push(`/schemes/${id}`);
    };
    return (
        <React.Fragment>
            {props.schemeList.length > 0 &&
            <ul className="progress-list">

                {props.schemeList.map((scheme, index) => {
                    return (
                        <li key={index} data-id={scheme.Id} onClick={(e) => goToScheme(scheme.Id, e)} >
                            <div className="title" data-id={scheme.Id}>
                                {scheme.Code}
                                <div className={'date'} data-id={scheme.Id}>{
                                    getCorrectDate(scheme?.CreatedAt)
                                }</div>
                            </div>
                            <div className='scheme-buttons'>
                            <FavoritesWrapper
                                item={scheme}
                                saveIsFavorite={editSchemeFavorite}
                            />
                            {
                                hasFolders() ?
                                <SelectFolder
                                    className={`fo-folder`}
                                    folders={getFolders()}
                                    selectFolder={(id) => {                                         
                                        if(props.foldersPath.length > 0 && props.foldersPath[props.foldersPath.length - 1].id == id) {
                                            if(props.foldersPath.length > 1)
                                                props.selectFolder(scheme.Id, {SchemeId:scheme.Id, FolderId:props.foldersPath[props.foldersPath.length - 2].id})
                                            else
                                                props.selectFolder(scheme.Id, {SchemeId:scheme.Id, FolderId:null})
                                        } else {                                        
                                            props.selectFolder(scheme.Id, {SchemeId:scheme.Id, FolderId:id})
                                        }
                                    }}
                                /> : <></>
                            }
                            <CommentWrapper
                                item={scheme}
                                saveComment={editSchemeComment}
                                firstStaticField={{
                                    title: 'Схема получения',
                                    value: scheme.Code
                                }}
                                readonly={!props.userPermissions.schemeMiniComment.UPDATE}
                            />
                            </div>
                            <div className={'progress-bars-container'}>
                                {scheme.Stages.map((stage, index) => {
                                    return (
                                        <div
                                            key={index}
                                            data-id={stage.Id}
                                            className={getStageStatusClass(stage)}/>
                                    );
                                })}
                            </div>
                        </li>
                    );
                })}

            </ul>
            }
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    const {resource, modules} = state;
    return {
        schemeList: itemsToArraySelector(resource.productionScheme),
        currentCandidate: modules.candidate.candidate,
        userPermissions: userCRUDPermissionsSelector(state),
        folders: itemsToArraySelector(resource.foldersSchemes),
        foldersPath: modules.folder.schemesPath,
        folderSavedSuccessful: modules.folder.selectFolder?.savedSuccessful,
    };
};


export default withRouter(connect(
    mapStateToProps,
    (dispatch) => ({
        editSchemeComment: (data) => dispatch(productionSchemeResourceActions.editSchemeComment.request({data})),
        editSchemeIsFavorite: (data) => dispatch(productionSchemeResourceActions.editSchemeIsFavorite.request({data})),
        clearProductSchemeList: () => dispatch(productionSchemeResourceActions.destroy()),
        getFoldersList: (params) => dispatch(folderSchemesResourceActions.list.request({params})),
        selectFolder: (id, data) => dispatch(_temp_folderResourceAction.selectFolder.request({ uriParams: { id }, data })),
    })
)(Schemes));
