// @flow
import React from 'react';
import type { Children } from 'react';
import type { IFieldProps } from '../../models';
import { _noop } from '../../utils/common';

type ColRenderProps = {
  item: any,
  colidx: number,
  rowidx: number,
  field: string,
  renderIdx: number,
}

interface IGridTableColProps extends IFieldProps {
  title?: string | Children, // Заголовок колонки
  render: (ColRenderProps: ColRenderProps) => Children, // что отрендарить в каждой ячейке таблицы
  width?: string, // ширина колонки
  fieldName?: string | (item: any, row_idx: number, col_idx: number, renderIdx: number) => string, // задает использование как
                                                                                                  // компонент Field из redux-form, значение должно быть тем же что и для поля name у Field,
                                                                                                  // либо функцией которая возвращает строку для поля name Field
  align?: string,
}

const defaultProps: IGridTableColProps = {
  title: ' ',
  render: null,
};

export const GridTableCol = (props: IGridTableColProps = defaultProps) => null;
