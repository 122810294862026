// @flow
import {
  put,
  takeEvery,
  fork,
  select,
} from 'redux-saga/effects';
import type { IRequestResourceAction } from '../../shared/utils/action-creators';
import { getToken } from '../../account/reducers';
import { FolderResourceService } from '../services/resource';
import { _temp_folderResourceAction } from '../actions';
import {CandidatesService} from '../../candidates/services/candidates';
import {folderSchemesResourceActions} from '../../projects/actions';

function* getFolder({ uriParams }: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const result = yield FolderResourceService.get({ uriParams }, token);
    yield put(_temp_folderResourceAction.get.success(result));
  } catch (error) {
    yield put(_temp_folderResourceAction.get.failure({ error }));
  }
}

function* createFolder({ data }: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const result = yield FolderResourceService.create({ data }, token);
    yield put(_temp_folderResourceAction.create.success(result));
  } catch (error) {
    yield put(_temp_folderResourceAction.create.failure({ error }));
  }
}

function* updateFolder({ data }: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const result = yield FolderResourceService.update({ data }, token);
    yield put(_temp_folderResourceAction.update.success(result));
  } catch (error) {
    yield put(_temp_folderResourceAction.update.failure({ error }));
  }
}

function* listFolders({ params }: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const result = yield FolderResourceService.list({ params }, token);
    yield put(_temp_folderResourceAction.list.success(result));
  } catch (error) {
    yield put(_temp_folderResourceAction.list.failure({ error }));
  }
}

function* deleteFolder({ uriParams }: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const result = yield FolderResourceService.delete({ uriParams }, token);
    yield put(_temp_folderResourceAction.delete.success(result));
  } catch (error) {
    yield put(_temp_folderResourceAction.delete.failure({ error }));
  }
}

function* getDescendants({ uriParams }: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const result = yield FolderResourceService.getDescendants({ uriParams }, token);
    yield put(_temp_folderResourceAction.getDescendants.success(result));
  } catch (error) {
    yield put(_temp_folderResourceAction.getDescendants.failure({ error }));
  }
}

function* getFolderByCandidateId(action: IRequestResourceAction) {
  const token = yield select(getToken);
  const candidateService = new CandidatesService(token);

  try {
    const candidateId = action.params.candidateId;
    const candidate = yield candidateService.get(candidateId);
    const folderId = candidate.result.FolderId;
    yield put(_temp_folderResourceAction.getFolderByCandidateId.success(folderId));
  } catch (error) {
    yield put(_temp_folderResourceAction.getFolderByCandidateId.failure({ error }));
  }
}

export function* selectFolder({data, uriParams}: IRequestResourceAction) {
  const token = yield select(getToken);
  try {    
      const result = yield FolderResourceService.selectFolder({ data, uriParams }, token);
      yield put(_temp_folderResourceAction.selectFolder.success(result));
  } catch (error) {
      yield put(_temp_folderResourceAction.selectFolder.failure({error}));
  }
}

export const _temp_folderResourceWatchers = [
  function* () {
    yield takeEvery(_temp_folderResourceAction.get.request.type, function*(action) {
      yield fork(getFolder, action);
    });
  },

  function* () {
    yield takeEvery(_temp_folderResourceAction.create.request.type, function*(action) {
      yield fork(createFolder, action);
    });
  },

  function* () {
    yield takeEvery(_temp_folderResourceAction.update.request.type, function*(action) {
      yield fork(updateFolder, action);
    });
  },

  function* () {
    yield takeEvery(_temp_folderResourceAction.list.request.type, function*(action) {
      yield fork(listFolders, action);
    });
  },

  function* () {
    yield takeEvery(_temp_folderResourceAction.delete.request.type, function*(action) {
      yield fork(deleteFolder, action);
    });
  },

  function* () {
    yield takeEvery(_temp_folderResourceAction.getDescendants.request.type, function*(action) {
      yield fork(getDescendants, action);
    });
  },

  function* () {
    yield takeEvery(_temp_folderResourceAction.getFolderByCandidateId.request.type, function*(action) {
      yield fork(getFolderByCandidateId, action);
    });
  },

  function* () {
    yield takeEvery(_temp_folderResourceAction.selectFolder.request.type, function*(action) {
      yield fork(selectFolder, action);
    });
  }
];
