// @flow
import React, { useEffect } from 'react';
import Button from '@biocad/bcd-front-ui/controls/Button';
import Dialog from '@biocad/bcd-front-ui/layout/Dialog';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { reactionModuleActions } from '../../actions';
import css from './dialog.m.less';
import { globalErrorsActions } from '../../../shared/actions/error';
import { JarSelection } from './JarStockSelection';
import type { IReactionEditDialogWrapComponentProps } from '../ReactionEdit/ReactionEditDialogWrap';
import type { IJarStock } from '../../../agents/models';
import type { IRootStore } from '../../../app/reducers';
import { Dispatch } from 'redux';
import { createSelector } from 'reselect';
import type { TReagentAddFromDialogAction, TReagentEditFromDialogAction } from '../../actions';
import { getSelectedJarsWithAgents } from '../../reducers';
import { _noop } from '../../../shared/utils/common';
import { ReagentTypeEnum } from '../../../reagents/model';
import type { IReagent } from '../../../reagents/model';

interface IJarSelectionDialogProps extends IReactionEditDialogWrapComponentProps {
  agentIds?: number[],
  invalidAgents?: number[],
  validateAgents?: (number[]) => void,
  setGlobalError?: (string) => void,
  selectedJars?: IJarStock[],
  loadSelectedJars?: () => void,
  saveSelectedJars?: () => void,
  destroySelectedJars?: () => void,
  smilesFormulas?: string[],
  selectionResult?: TReagentAddFromDialogAction[],
  onCancel?: () => void,
  updateReactionReagents?: (TReagentAddFromDialogAction[])=> void,
  updateSingleReagent?: (TReagentEditFromDialogAction) => void,
  recalcAll?: () => void,
  reagents?: IReagent[],
  primaryReactionId?: number,
  complexCompoundNames?: string[],
}

const defaultProps: IJarSelectionDialogProps = {
  smilesFormulas: [],
  complexCompoundNames: [],
  agentIds: [],
  onCancel: _noop
};

let JarSelectionDialog = (props: IJarSelectionDialogProps = defaultProps) => {

  // Methods
  const
    validate = () => {
      if (props.agentIds.length && props.reagents) props.validateAgents(props.agentIds, calcReagents2Show());
    },
    throwError = () => {
      let msg = 'У каждого вещества должна быть выбрана хотя бы одна банка.';
      msg += props.invalidAgents.length > 0 && props.agentIds.length > 1
        ? ` Банки не выбраны на страницах: ${props.invalidAgents.map(x => x + 1).join(', ')}`
        : '';

      props.setGlobalError(msg);
    },
    onSaveClick = () => {
      if (props.invalidAgents.length > 0 || props.selectedJars.length === 0) {
        validate();
        throwError();
        return;
      }
      props.saveSelectedJars();
    },
    onCancelClick = () => {
      props.closeDialog();
      if (props.onCancel && typeof props.onCancel === 'function') props.onCancel();
    }
    ;

  // Effects
  useEffect(() => {
    props.loadSelectedJars();
    return () => props.destroySelectedJars();
  }, []);

  useEffect(validate, [props.selectedJars]);

  const getReagentForSelection = (orderedReagents: IReagent[], index: number) => {
    const currentSelection = props.selectionResult[index];
    const reagents = orderedReagents.filter(r => r.Agent.Id == currentSelection.agent.Id);
    
    //ошибочный случай - не смогли сопоставить выбранные банки с реагентом
    if (!reagents || !reagents.length) {
      return null;
    }

    //самый простой (и распространенный) случай - подошел единственный реагент, его и возвращаем
    if (reagents.length === 1) {
      return reagents[0];
    }

    //если же есть несколько реагентов с одним и тем же агентом - ситуация усложняется

    //нужно исключить реагенты, которые являются родительскими для других реагентов. Им банки присваивать не следует
    const nonParentReagents = reagents.filter(i => !reagents.some(j => (j.Id && j.ParentReagentId == i.Id) || j.ParentReagentLocalId == i.localUuid));
    
    //нужно найти все 'выборы банок' (Selection), которые относятся к тому же агенту, что и текущий
    const sameAgentSelections = props.selectionResult.filter(i => i.agent.Id == currentSelection.agent.Id);
    
    //и определить, номер по порядку текущего 'выбора' относительно других (по тому же агенту)
    const offset = sameAgentSelections.indexOf(currentSelection);

    //т. к. количество реагентов с одинаковыми банками должно быть == количеству 'выборов', просто сопоставляем их
    //по порядковому номеру текущего 'выбора'
    return nonParentReagents[offset];
  }

  const _updateReactionReagents = () => {

    if (props.selectionResult && props.selectionResult.length) {
      const noOriginalReagent: boolean = !props.selectionResult.find((res: TReagentAddFromDialogAction) => res.reagentType === ReagentTypeEnum.Original);
      if (noOriginalReagent && !props.reagents) {
        props.closeDialog();
        if (props.onCancel && typeof props.onCancel === 'function') props.onCancel();
        props.setGlobalError('Входное вещество не найдено');
        return;
      }
      if (props.reagents) {
        // Чтобы не разрушилась вся логика выбора банок при клонировании реакции
        // производится упорядочивание: сначала идут банки со SMILES, потом идут антитела/полимеры
        // у которых SMILES нет.
        const orderedReagents = [...props.reagents.filter(r => r.Agent.Compound.SmilesFormula),
        ...props.reagents.filter(r => !r.Agent.Compound.SmilesFormula)];
        for (let i = 0; i < props.selectionResult.length; i++) {
          props.selectionResult[i].jars = props.selectionResult[i].jars.filter(j => j.Amount > 0);
          const reagent = getReagentForSelection(orderedReagents, i);
          props.updateSingleReagent({
            ...props.selectionResult[i],
            reagentId: reagent?.Id ?? 0,
            reagentLocalUuid: reagent?.localUuid
          });
        }
      }
      else {
        props.updateReactionReagents(props.selectionResult);
      }
      props.recalcAll();
      props.closeDialog();
    }
  };
  useEffect(_updateReactionReagents, [props.selectionResult]);

  const calcReagents2Show =() => {
    let reagents2show = [];
    if (props.reagents) {
      const childReagents = props.reagents.filter(item => item.ParentReagentId || item.ParentReagentLocalId);
      let parentReagents = props.reagents.filter(item => (!item.ParentReagentId && !item.ParentReagentLocalId));
      parentReagents = parentReagents.filter(item => !childReagents.some(i => i.ParentReagentId && item.Id == i.ParentReagentId));
      parentReagents = parentReagents.filter(item => !childReagents.some(i => i.ParentReagentLocalId && item.localUuid == i.ParentReagentLocalId));
      const resultReagents = [...childReagents, ...parentReagents];
      reagents2show = props.reagents.filter(i => (i.Id != 0 && resultReagents.some(r => r.Id == i.Id)) || i.localUuid && resultReagents.some(r => r.localUuid == i.localUuid));
    }
    return reagents2show;
  }

  const reagents2show = calcReagents2Show();

  return (
    <>
      <Dialog paranjaClose={false} close={onCancelClick}>
        <div className={classNames('dialog-layout', css.dialogLayout)}>
          <header className={'dialog-header'}>
            <h3>Выберите производителей исходных веществ</h3>
          </header>
          <div className={'dialog-contents'} style={{ paddingTop: '0' }}>
            <JarSelection smilesFormulas={props.smilesFormulas}
              reagents={reagents2show}
              primaryReactionId={props.primaryReactionId}
              complexCompoundNames={props.complexCompoundNames} />
          </div>
          <footer className={'dialog-footer'}>
            <Button
              onAction={onSaveClick}
              text={'Выбрать'}
              size={'L'}
              view={'primary'}
            />
            <Button
              onAction={onCancelClick}
              text={'Отменить'}
              size={'L'}
            />
          </footer>
        </div>
      </Dialog>
    </>
  );
};

const getAgentIdsSelector = createSelector(
  (state: IRootStore) => state.resource.agents.data,
  (data: {}) => Object.keys(data)
);

JarSelectionDialog = connect(
  (state: IRootStore): IJarSelectionDialogProps => ({
    agentIds: getAgentIdsSelector(state),
    invalidAgents: state.modules.reaction.jarStock.invalidAgentsIndexList,
    selectedJars: state.modules.reaction.jarStock.selectedJars,
    selectionResult: getSelectedJarsWithAgents(state)
  }),
  (dispatch: Dispatch): IJarSelectionDialogProps => ({
    saveSelectedJars: () => dispatch(reactionModuleActions.jarStock.save()),
    loadSelectedJars: () => dispatch(reactionModuleActions.jarStock.loadSaved()),
    destroySelectedJars: () => dispatch(reactionModuleActions.jarStock.destroy()),
    validateAgents: (agentsId: number[], reagents) => dispatch(reactionModuleActions.jarStock.validateAgents({ agentsId, reagents })),
    setGlobalError: (error: string) => dispatch(globalErrorsActions.set({ error })),
    updateReactionReagents: (results: TReagentAddFromDialogAction[]) => dispatch(reactionModuleActions.reactionEdit.addMultipleReagents({ results })),
    updateSingleReagent: (results: TReagentEditFromDialogAction) => dispatch(reactionModuleActions.reactionEdit.editSingleReagentJars({ results })),
    recalcAll: () => dispatch(reactionModuleActions.recalc.all({ debounce: true })),
  })
)(JarSelectionDialog);

export { JarSelectionDialog };

