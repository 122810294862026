// @flow

import { ResourceService, CRUDRequestsMap, HTTPMethodEnum } from '../../shared/utils/service-creator';
import { Folder, FolderListParams, SchemeFolder } from '../models';
import { ArrayModel } from 'objectmodel';
import type { ResourceRequestMethodType } from '../../shared/utils/service-creator';
import { SingleRequestURIParams } from '../../shared/models';


interface IFolderResourceService {
  get: ResourceRequestMethodType,
  list: ResourceRequestMethodType,
  update: ResourceRequestMethodType,
  create: ResourceRequestMethodType,
  delete: ResourceRequestMethodType,
  getDescendants: ResourceRequestMethodType,
  selectFolder: ResourceRequestMethodType,
}

export const FolderResourceService: IFolderResourceService = ResourceService({
  baseURL: '/folders',
  defaultModel: Folder,
  defaultQuery: FolderListParams,
  useDefaultCRUDRequests: [
    CRUDRequestsMap.list,
    CRUDRequestsMap.get,
    CRUDRequestsMap.update,
    CRUDRequestsMap.create,
    CRUDRequestsMap.delete
  ],
  requests: {
    [CRUDRequestsMap.update]: {
      url: '/',
      method: HTTPMethodEnum.PUT,
      model: Folder,
    },
    getDescendants: {
      url: '/descendants',
      method: HTTPMethodEnum.GET,
      model: ArrayModel([Folder]),
      paramsModel: FolderListParams,
    },
    selectFolder: {
      url: '/{id}/select_folder',
      method: HTTPMethodEnum.PUT,
      uriParamsModel: SingleRequestURIParams,
      dataModel: SchemeFolder,
    },
  }
});
