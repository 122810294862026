// @flow

import React from 'react';
import {_noop} from '../../utils/common';
import bnc from 'bnc';

import './index.less';

export type TBreadcrumbItem = {
  label: string,
}

interface IBreadcrumbsNavProps {
  items: TBreadcrumbItem & any,
  onItemClick: (TBreadcrumbItem & any, number)=>void,
  className?: string;
}

const defaultProps: IBreadcrumbsNavProps = {
  items: [],
  onItemClick: _noop,
  className: '',
};

const
  BNCBlock = new bnc('b-BreadcrumbsNav'),
  BNCItem = BNCBlock.el('item')
;

export const BreadcrumbsNav = (props: IBreadcrumbsNavProps = defaultProps) => (
  <div className={BNCBlock + props.className}>
    {
      props.items.map((item, idx, arr) => (
        <span
          className={BNCItem}
          key={idx}
          onClick={idx+1 === arr.length ? _noop : () => props.onItemClick(item, idx)}>
            { item.label }
          </span>
      ))
    }
  </div>
);
