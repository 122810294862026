/**
* Converts `value` to a string key if it's not a string or symbol.
*
* @private
* @param {*} value The value to inspect.
* @returns {string|symbol} Returns the key.
*/
const toKey = (value) => {
    if (typeof value === 'string' || typeof value === 'symbol') {
        return value;
    }

    const result = String(value);
    return (result === '0' && (1 / value) === Number.NEGATIVE_INFINITY) ? '-0' : result;
};


/**
 * Get object property by `path` from `object`
 *
 * @param {(string|number|symbol)[]} propsPath The path of the property to get.
 * @param {Object} object The object to query.
 * @returns {*} Returns the resolved value.
 */
export const path = (propsPath, object) => {
    let index = 0;
    const length = propsPath.length;

    while (object !== undefined && index < length) {
        object = object[toKey(propsPath[index++])];
    }
    return (index && index === length) ? object : undefined;
};


/**
 * Get object property by `path` from `object` or return `defaultValue`
 *
 * @param {Any} defaultValue Default value
 * @param {(string|number|symbol)[]} propsPath The path of the property to get.
 * @param {Object} object The object to query.
 * @returns {*} Returns the resolved value or default value.
 */
export const pathOr = (defaultValue, propsPath, object) => {
    const value = path(propsPath, object);
    return (value === undefined) ? defaultValue : value;
};

