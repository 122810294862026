// @flow

import {
  ResourceService,
  ResourceRequestMethodType,
  CRUDRequestsMap,
  HTTPMethodEnum,
} from '../../shared/utils/service-creator';
import { Product } from '../model';

interface IProductsResourceService {
  get: ResourceRequestMethodType,
  update: ResourceRequestMethodType,
  create: ResourceRequestMethodType,
  delete: ResourceRequestMethodType,
}

export const ProductsResourceService: IProductsResourceService = ResourceService({
  baseURL: '/products',
  defaultModel: Product,
  useDefaultCRUDRequests: [
    CRUDRequestsMap.get,
    CRUDRequestsMap.update,
    CRUDRequestsMap.create,
    CRUDRequestsMap.delete,
  ],
  requests: {
    [CRUDRequestsMap.create]: {
      url: '/',
      method: HTTPMethodEnum.POST,
      model: Product,
      dataModel: Product,
    },
    [CRUDRequestsMap.update]: {
      url: '/',
      method: HTTPMethodEnum.PUT,
      model: Product,
      dataModel: Product,
    },
  }
});
