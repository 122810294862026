// @flow

import { Model } from 'objectmodel';
import { PaginationParams } from '../../shared/models';

export const ReactionListRequest = Model({
  IncludeExpYield: Boolean,
  IncludeIncomplete: Boolean,
  IncludeTrial: Boolean,
  IncludeWithoutExpYield: Boolean,
  InitialSmiles: [String],
  MergedSmiles: [String],
  ResultSmiles: [String],
  InitialStageId: [Number],
  FolderId: [Number],
  IgnoreFolders: Boolean,
  HistoricalMode: Boolean,
})
.extend(PaginationParams)
.defaultTo({
  IncludeExpYield: true,
  IncludeIncomplete: true,
  IncludeTrial: true,
  IncludeWithoutExpYield: true,
  IgnoreFolders: false,
  FolderId: null,
  HistoricalMode: false,
});
