// @flow

import React, { useState, useEffect, } from 'react';
import type { StatelessFunctionalComponent } from 'react';
import type {IFieldComponentProps, OptionType} from '../../models';
import Input from '@biocad/bcd-front-ui/controls/Input';
import Button from '@biocad/bcd-front-ui/controls/Button/index';
import './index.less';
import {FormFieldLayout} from '../FormFieldLayout';
import classNames from 'classnames';
import {BcdDropdownWrapper} from '../BcdDropdownWrapper';
import Loader from '@biocad/bcd-front-ui/controls/Loader';

interface IProps extends IFieldComponentProps
{
    className?              : string;
    options?                : OptionType[];
    useFormFieldLayout      : boolean;
    wrapperStyle?           : {};
    wrapperClassName?       : string;
    label?                  : string;
    hint?                   : string;
    required?               : boolean;
    disabled?               : boolean;
    onSelectedChange?       : (option: OptionType) => void;
    onSelectedDelete?       : (option: OptionType) => void;
    selectedEditable?       : boolean;
    pending?                : boolean;
    markPristineValidity?   : boolean;
    duplicatesNotAllowed?   : boolean;
}

export const EditableDropdown: StatelessFunctionalComponent<IProps> = (props: IProps) =>
{
    // Refs

    // State
    let _valid: boolean;
    if (!props.markPristineValidity) {
        _valid = (props.meta?.valid === false || props.meta?.warning) && props.meta?.touched === true
            ? false
            : undefined;
    }
    else {
        _valid = (props.meta?.valid && !props.meta?.warning) ? undefined : false
    }
    const formFieldLayoutProps = {
        style: props.wrapperStyle,
        className: props.wrapperClassName,
        invalidMessage: props.meta?.error || props.meta?.warning,
        valid: _valid,
        hint: props.hint,
        label: props.label,
        required: props.required,
    };
    const
        [selected, setSelected] = useState<OptionType>(null),
        [selectedChanged, setSelectedChanged] = useState<boolean>(null),
        [open, setOpen] = useState<boolean>(false),
        [edit, setEdit] = useState<boolean>(false)
    ;

    // Effects
    useEffect(() => {
        if (props.input.value && typeof props.input.value === 'object')
        {
            setSelected({
                value: props.input.value.value,
                label: props.input.value.label
            });
        }
    }, [props.input.value?.value, props.input.value?.label]);

    // Handlers
    const
        setEditMod = () =>
        {
            if (props.selectedEditable)
            {
                setEdit(true);
                setOpen(false);
            }
        },
        setAddNewMod = () =>
        {
            setEdit(true);
            setOpen(false);
            setSelected({
                value: 0,
                label: ''
            });
        },
        setViewMod = () =>
        {
            setEdit(false);
            setOpen(false);
            setSelectedChanged(false);
            setSelected(props.input.value);
        },
        editSelected = (event: Event) =>
        {
            const label = event.target?.value;
            if (!selectedChanged) setSelectedChanged(true);
            setSelected(prev => ({ ...prev, label }));
        },
        saveSelectedChanges = () =>
        {
            const labelTrimmed = selected.label.trim();
            if (props.duplicatesNotAllowed)
            {
                const duplicate = props.options.find((opt: OptionType) => opt.label === labelTrimmed);
                if (duplicate)
                {
                    props.input.onChange(duplicate.value);
                    setViewMod();
                    return;
                }
            }
            if (selectedChanged && labelTrimmed && props.onSelectedChange && typeof props.onSelectedChange === 'function')
            {
                props.onSelectedChange({
                    ...selected,
                    label: labelTrimmed
                });
            }
            setViewMod();
        },
        deleteSelected = () =>
        {
            if (selected.value)
            {
                setViewMod();
                if (props.onSelectedDelete && typeof props.onSelectedDelete === 'function')
                {
                    props.onSelectedDelete(selected);
                }
            }
            setSelected(prev => ({
                ...prev,
                label: ''
            }));
        },
        handleInputKeyPress = (event: KeyboardEvent) =>
        {
            if (['Enter', 'Escape'].includes(event.key))
            {
                event.preventDefault();
                event.nativeEvent?.stopImmediatePropagation();
            }
            switch (event.key)
            {
                case 'Enter':
                    saveSelectedChanges();
                    break;
                case 'Escape':
                    setViewMod();
                    break
                default:
                    return;
            }
        }
    ;

    // Methods
    const
        renderDropdown = () =>
        (
            <div className={classNames('b-EditableDropdown', props.className)}>
                { props.pending && <Loader/> }
                <div className={'b-EditableDropdown__selectedValue'}>
                    {
                        (edit)
                        ? <Input value={selected?.label}
                                 onChange={editSelected}
                                 onKeyUp={handleInputKeyPress}
                                 disabled={props.disabled}
                          />
                        : <BcdDropdownWrapper meta={props.meta}
                                              input={props.input}
                                              expanded={open}
                                              useFormFieldLayout={false}
                                              disabled={props.disabled}
                                              options={props.options}
                                              markPristineValidity={props.markPristineValidity}
                          />
                    }
                    {
                        (props.selectedEditable && !edit && !!selected?.value) &&
                        <Button view={'icon'}
                                size={'L'}
                                onAction={setEditMod}
                                disabled={props.disabled || open}
                                className={classNames({
                                    ['fo-edit']: true,
                                })}
                        />
                    }
                    {
                        !edit &&
                        <Button view={'icon'}
                                size={'L'}
                                onAction={setAddNewMod}
                                disabled={props.disabled || open}
                                className={classNames({
                                    ['fo-plus']: true,
                                })}
                        />
                    }
                    {
                        edit &&
                        <Button view={'icon'}
                                size={'L'}
                                onAction={saveSelectedChanges}
                                disabled={props.disabled}
                                className={classNames({
                                    ['fo-ok']: true,
                                })}
                        />
                    }
                    {
                        (props.selectedEditable && edit && selected?.value) ?
                        <Button view={'icon'}
                                size={'L'}
                                onAction={deleteSelected}
                                disabled={props.disabled}
                                className={classNames({
                                    ['fo-trash']: true,
                                })}
                        /> : ''
                    }
                </div>
            </div>
        )
    ;

    return (
        props.useFormFieldLayout
        ? ( <FormFieldLayout {...formFieldLayoutProps}>{ renderDropdown() }</FormFieldLayout> )
        : renderDropdown()
    )
}