// @flow
import {
  put,
  takeEvery,
  fork,
  select, call, all,
} from 'redux-saga/effects';
import { SchemeResourceService } from '../services';
import { schemesResourceActions, schemeModuleActions } from '../actions';
import { getToken } from '../../account/reducers';
import type { IRequestResourceAction } from '../../shared/utils/action-creators';
import { stageModuleActions } from '../../stages/actions';
import type {ICompound} from '../../agents/models';
import {AgentsService} from '../../agents/services/agents';
import {CandidatesService} from '../../candidates/services/candidates';
import {ProjectService} from '../../projects/services/projects';

import {SearchOptionsEnum} from '../../agents/components/List/SearchForm';
import type {IRootStore} from '../../app/reducers';
import {getAmountsByLabs} from '../../agents/sagas';
import {jarStockResourceActions} from '../../agents/actions';
import {jarAmountsByLabsResourceActions} from '../../agents/actions/jar-amounts-by-labs';
import {globalErrorsActions} from '../../shared/actions';

function* listReactions({ params }: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
      const result = yield SchemeResourceService.list({ params }, token);
      yield put(schemesResourceActions.list.success(result));
  } catch (error) {
      yield put(schemesResourceActions.list.failure({ error }));
  }
}

function* getScheme({ uriParams }: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const result = yield SchemeResourceService.get({ uriParams, params: {} }, token);
    yield put(schemesResourceActions.get.success(result));
  } catch (error) {
    yield put(schemesResourceActions.get.failure({ error }));
  }
}

function* updateScheme({ data }) {
  const token = yield select(getToken);
  try {
    const result = yield SchemeResourceService.update({ data }, token);
    yield put(schemesResourceActions.update.success(result));
  } catch (error) {
    yield put(schemesResourceActions.update.failure({error}));
  }
}

function* createScheme({ data }) {
  const token = yield select(getToken);
  try {
    const result = yield SchemeResourceService.create({ data }, token);
    yield put(schemesResourceActions.create.success(result));
  } catch (error) {
    yield put(schemesResourceActions.create.failure({error}));
  }
}

function* getMergeableSchemes(action: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const result = yield SchemeResourceService.getMergeableSchemes({
      uriParams: action.uriParams
    }, token);
    yield put(schemesResourceActions.getMergeableSchemes.success(result));
  } catch (error) {
    yield put(schemesResourceActions.getMergeableSchemes.failure({ error }));
  }
}

function* getMergedSubschemes(action: IRequestResourceAction) {
  const token = yield select(getToken);
   try {
    const result = yield SchemeResourceService.getMergedSubschemes({
      uriParams: action.uriParams
    }, token);
    yield put(schemesResourceActions.getMergedSubschemes.success(result));
   } catch (error) {
     yield put(schemesResourceActions.getMergedSubschemes.failure({ error }));
   }
}

function* getLastStage(action: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const result = yield SchemeResourceService.getLastStage({
      uriParams: action.uriParams
    }, token);
    yield put(schemesResourceActions.getLastStage.success(result));
    if(action.params?.shouldInitEntrySubstance) {
      yield put(stageModuleActions.initEntrySubstance(result));
    }
  } catch (error) {
    yield put(schemesResourceActions.getLastStage.failure({ error }));
  }
}

function* getSchemeView(action: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const result = yield SchemeResourceService.getSchemeView({
      uriParams: action.uriParams
    }, token);
    yield put(schemesResourceActions.getSchemeView.success(result));
  } catch (error) {
    yield put(schemesResourceActions.getSchemeView.failure({ error }));
  }
}

function* getComparingSchemeViews(action: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const result = [];
    const { schemeIds } = action.params;
    for(let id of schemeIds) {
      let schemeView = yield SchemeResourceService.getSchemeView({
        uriParams: { id }
      }, token);
      result.push(schemeView.result);
    }
    yield put(schemesResourceActions.getComparingSchemeViews.success());
    yield put(schemeModuleActions.setComparingSchemeViews({ schemeViews: result }));
  } catch (error) {
    yield put(schemesResourceActions.getComparingSchemeViews.failure({ error }));
  }
}

function* deleteScheme({ uriParams }: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const result = yield SchemeResourceService.delete({ uriParams }, token);
    if (!result.result.IsSuccess) {
      yield put(schemesResourceActions.delete.failure({
        error: 'Невозможно удалить схему, так как она содержит стадии'
      }));
    } else {
      yield put(schemesResourceActions.delete.success(result));
    }
  } catch (error) {
    yield put(schemesResourceActions.delete.failure({error}));
  }
}

function* cloneScheme({data, uriParams}) {
  const token = yield select(getToken);
  try {
    const result = yield SchemeResourceService.clone({data, uriParams}, token);
    yield put(schemesResourceActions.cloneScheme.success(result));
  } catch (error) {
    yield put(schemesResourceActions.cloneScheme.failure({error}));
  }
}

function* loadLastStagesByIds({ ids }: { ids: number[] }) {
  const token = yield select(getToken);
  const stages = [];
  for(const id of ids) {
    stages.push((yield SchemeResourceService.getLastStage({ uriParams: { id }}, token)).result);
  }
  yield put(stageModuleActions.setMergeableSchemesLastStages({stages}));
}

function* loadAgentForCurrentCompound({ params: { compound }})
{
  const { SmilesFormula, Name } = compound;
  const search = SmilesFormula || Name;
  if (search)
  {
    const token = yield select(getToken);
    const service = new AgentsService(token);
    try {
      const result = yield service.search({ params: {
          search,
          searchType: (!SmilesFormula && !!Name) ? SearchOptionsEnum.ByName : SearchOptionsEnum.ByStructure,
          isStrictSearch: true,
          isInStockOnly: false,
          isPrecursor: false,
          isAntibody: !SmilesFormula && !!Name,
          isPolymer: !SmilesFormula && !!Name,
          isIgnoringSpending :false,
          isReagentOrdered: false,
          laboratoryId: null,
          isSmallAmount: true,
          isSufficientAmount: true,
          isLargeAmount: true,
          skip: 0,
          limit: 1,
          order: null,
      }});
      yield put(schemeModuleActions.loadAgentBySmiles.success(result));
    }
    catch (error) {
      yield put(schemeModuleActions.loadAgentBySmiles.failure({error}))
    }
  }
}

function* loadSingleAgentJarAmount({ uuid })
{
  yield put(schemeModuleActions.setShowResultForLoadedJarAmount({ uuid, show: true }));
  const pending = yield select((state: IRootStore) => (
      state.modules.scheme.schemeTree.jarAmounts[uuid]?.pending
  ));
  if (pending) return;
  const params = yield select((state: IRootStore) => (
      state.modules.scheme.schemeTree.jarAmounts[uuid]?.params
  ));
  yield put(schemeModuleActions.setPendingForLoadedJarAmount({ uuid, pending: true }));
  if (!params) return;
  yield put(jarAmountsByLabsResourceActions.get.request({}));
  const result = yield getAmountsByLabs({ params });
  if (result?.AgentId)
  {
    yield put(schemeModuleActions.setAgentIdForLoadedJarAmounts({ uuid, agentId: result.AgentId }));
  }
  yield put(schemeModuleActions.setPendingForLoadedJarAmount({ uuid, pending: false }));
}

function* loadAllAgentJarAmount()
{
  const uuids = yield select((state: IRootStore) => (
      Object.keys(state.modules.scheme.schemeTree.jarAmounts)
  ));
  const NUMBER_OF_REQUESTS_AT_ONCE = 5; // Чтобы не грузить сервер разом большим кол-вом запросов
  const uuidsGrouped = uuids.reduce((acc, uuid) => (
      acc[acc.length-1].length >= NUMBER_OF_REQUESTS_AT_ONCE
          ? [...acc, [uuid]]
          : acc.map((group, idx) => (
              (idx === acc.length-1)
                  ? [ ...group, uuid ]
                  : group
          ))
      ), [[]]
  );
  for (const group of uuidsGrouped)
  {
    yield all(
        group.map((uuid) => call(loadSingleAgentJarAmount, { uuid }))
    );
  }
}

function* getPrecalcXlsx(action: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const  { result } = yield SchemeResourceService.getPrecalcXlsx({
      uriParams: { id: action.schemeId },
      data: {Image: action.schemeImage}
    }, token);
    const url = URL.createObjectURL(new Blob([result]));

    let a = document.createElement('a');
    a.href = url;
    a.download = `${action.xlsxName}.xlsx`;
    a.click();
    URL.revokeObjectURL(url.href);
  }
  catch(err) {
    yield put(globalErrorsActions.set({error: err.message}));
  }
}

function* loadBreadcrumbs(action: IRequestResourceAction) {
  const token = yield select(getToken);
  const candidateService = new CandidatesService(token);
  const projectService = new ProjectService(token);
  
  const scheme = (yield SchemeResourceService.get({ uriParams: {id: action.schemeId}, params: {} }, token)).result;
  const candidate = (yield candidateService.get(scheme.FinalCandidateId)).result;
  const project = (yield projectService.get(candidate.ProjectId)).result;
  const breadcrumbs = {
    scheme: {
      id: scheme.Id,
      title: scheme.Code,
      folderId: scheme.FolderId,
    },
    candidate: {
      id: candidate.Id,
      title: candidate.Code,
      folderId: candidate.FolderId,
    },
    project: {
      id: project.Id,
      title: project.Name,
    }
  };
  yield put(schemeModuleActions.setBreadcrumbs({breadcrumbs}));
  
}

export const schemeResourceWatchers = [
  function* () {
    yield takeEvery(schemesResourceActions.list.request.type, function* (action) {
      yield fork(listReactions, action);
    });
  },

  function* () {
    yield takeEvery(schemesResourceActions.get.request.type, function* (action) {
      yield fork(getScheme, action);
    });
  },

  function* () {
    yield takeEvery(schemesResourceActions.update.request.type, function* (action) {
      yield fork(updateScheme, action);
    });
  },

  function* () {
    yield takeEvery(schemesResourceActions.getMergeableSchemes.request.type, function* (action) {
      yield fork(getMergeableSchemes, action);
    });
  },

  function* () {
    yield takeEvery(schemesResourceActions.getMergedSubschemes.request.type, function* (action) {
      yield fork(getMergedSubschemes, action);
    });
  },

  function* () {
    yield takeEvery(stageModuleActions.loadLastStagesByIds.type, function* (action) {
      yield fork(loadLastStagesByIds, action);
    });
  },

  function* () {
    yield takeEvery(schemesResourceActions.getLastStage.request.type, function* (action) {
      yield fork(getLastStage, action);
    });
  },

  function* () {
    yield takeEvery(schemesResourceActions.getSchemeView.request.type, function* (action) {
      yield fork(getSchemeView, action);
    });
  },

  function* () {
    yield takeEvery(schemesResourceActions.getComparingSchemeViews.request.type, function* (action) {
      yield fork(getComparingSchemeViews, action);
    });
  },

  function* () {
    yield takeEvery(schemesResourceActions.delete.request.type, function* (action) {
      yield fork(deleteScheme, action);
    });
  },
  function* () {
    yield takeEvery(schemesResourceActions.create.request.type, function* (action) {
      yield fork(createScheme, action);
    });
  },
  function* () {
    yield takeEvery(schemesResourceActions.cloneScheme.request.type, function* (action) {
      yield fork(cloneScheme, action);
    });
  },

  function* () {
    yield takeEvery(schemeModuleActions.loadAgentBySmiles.request.type, function* (action) {
      yield fork(loadAgentForCurrentCompound, action);
    });
  },

  function* ()
  {
    yield takeEvery(schemeModuleActions.loadSingleAgentJarAmount.type, function* (action) {
      yield fork(loadSingleAgentJarAmount, action);
    })
  },

  function* ()
  {
    yield takeEvery(schemeModuleActions.loadAllAgentJarAmount.type, function* (action) {
      yield fork(loadAllAgentJarAmount, action);
    })
  },

  function* ()
  {
    yield takeEvery(schemeModuleActions.getPrecalcXlsx.type, function* (action) {
      yield fork(getPrecalcXlsx, action);
    })
  },

  function* ()
  {
    yield takeEvery(schemeModuleActions.loadBreadcrumbs.type, function* (action) {
      yield fork(loadBreadcrumbs, action);
    })
  },
];
