// @flow

import type {ResourceRequestMethodType} from '../../shared/utils/service-creator';
import {CRUDRequestsMap, HTTPMethodEnum, ResourceService} from '../../shared/utils/service-creator';
import {ArchivedReagent} from '../model';
import {SingleRequestURIParams} from '../../shared/models';

interface IArchivedReagentsResourceService {
  get: ResourceRequestMethodType,
  update: ResourceRequestMethodType,
  create: ResourceRequestMethodType,
  delete: ResourceRequestMethodType,
  editReagentComment: ResourceRequestMethodType
}

export const ArchivedReagentsResourceService: IArchivedReagentsResourceService = ResourceService({
  baseURL: '/archive_reagents',
  defaultModel: ArchivedReagent,
  useDefaultCRUDRequests: [
    CRUDRequestsMap.get,
    CRUDRequestsMap.update,
    CRUDRequestsMap.create,
    CRUDRequestsMap.delete
  ],
  requests: {
    [CRUDRequestsMap.create]: {
      url: '/',
      method: HTTPMethodEnum.POST,
      model: ArchivedReagent,
      dataModel: ArchivedReagent
    },
    [CRUDRequestsMap.update]: {
      url: '/',
      method: HTTPMethodEnum.PUT,
      model: ArchivedReagent,
      dataModel: ArchivedReagent
    },
    editReagentComment: {
      url: '{id}/quick_comment',
      method: HTTPMethodEnum.PUT,
      uriParamsModel: SingleRequestURIParams
    }
  }
});
