// @flow
import classNames from 'classnames';
import React from 'react';
import css from './pages.m.less';
import './pages.less';

export const ItemPages = ({
  label = '',
  items = [],
  itemIndex = 0,
  itemClickHandler = () => null,
  invalidItems = [],
}) => {
  return (
    <div className={classNames(css.pages, 'pages-component')}>
      <span>{label}</span>
      {
        !!items.length && items.map((x, index) => (
          <span
            key={'page'+index}
            className={classNames(

              css.pages__item,
              'pages-component-item',

              itemIndex === index 
                ? [css.pages__item_active, 'pages-component-item-active']
                : null,

              !!invalidItems.length && invalidItems.includes(index) 
                ? [css.pages__item_invalid, 'pages-component-item-invalid']
                : null
            )}
            onClick={() => itemClickHandler(index)}
          >
            {index + 1}
          </span>
        ))
      }
    </div>
  );
};
