// @flow

import { Model } from 'objectmodel';
import { CanonicalizerResponse } from '../../shared/services/compound/canonicalizer-service';
import { uuidv4 } from '../../shared/utils/common';

const StageSubstance = Model({
  Id: [Number],
  IsDeleted: Boolean,
  isSelected: Boolean,
  isDisabled: Boolean,
  position: [Number],
  title: [String],
  Formula: [String],
  formulaId: [Number],
  MolecularFormula: [String],
  SmilesFormula: [String],
  Thumbnail: [String],
  AgentId: [Number],
  CustomStageCompoundId: [Number],
  SourceSchemeId: [Number],
  MolarWeight: [Number],
  Name: [String],
  TargetStageId: [Number],
  uniteSchemeId: [Number],
  IsAntibody: [Boolean], 
  IsPolymer: [Boolean],
  canonicalized: CanonicalizerResponse,
}).defaultTo({
  Id: 0,
  formulaId: null,
  IsDeleted: false,
  isSelected: false,
  isDisabled: false,
  AgentId: null,
  CustomStageCompoundId: null,
  SourceSchemeId: null,
  uniteSchemeId: null,
  canonicalized: CanonicalizerResponse(),
});

export {
  StageSubstance
}
