// @flow

import {Model, MapModel} from 'objectmodel';

export interface IJarAmountInfo
{
    Amount: number;
    Location: string;
}


export interface IJarAmountsByLabsModel
{
    AgentId: number;
    UserLaboratoryId?: number;
    AvailabilitiesByLaboratories?: Map<number, IJarAmountInfo>;
}

export interface IJarAmountsByLabsRequest
{
    SmilesFormula?: string;
    CompoundName?: string;
    LaboratoryId?: number;
}

export const JarAmountsByLabsModel: (d: IJarAmountsByLabsModel) => IJarAmountsByLabsModel = Model({
    AgentId: Number,
    UserLaboratoryId: [Number],
    AvailabilitiesByLaboratories: [MapModel(Number, Model({Amount: Number, Location: String}))]
})
.defaultTo({
    AgentId: 0,
    UserLaboratoryId: null,
    AvailabilitiesByLaboratories: new Map(),
});

export const JarAmountsByLabsRequest: (d: IJarAmountsByLabsRequest) => IJarAmountsByLabsRequest = Model({
    SmilesFormula: [String],
    CompoundName: [String],
    LaboratoryId: [Number],
})
.defaultTo({
    SmilesFormula: null,
    CompoundName: null,
    LaboratoryId: null,
});