// @flow
import React from 'react';
import './reaction-summary.less';
import { IReactionSummary, ReactionSummary } from '../../models';  

export interface IReactionSummaryProps{
  summary : IReactionSummary
}

export const ReactionSummaryWindow = ({summary}: IReactionSummaryProps) => {

  summary = summary || {};
  const totalAmount = summary.TotalAmount || 0;
  const withExpYieldAmount = summary.WithExpYieldAmount || 0;
  const nonFinishedAmount = summary.NonFinishedAmount || 0;
  const withoutExpYieldAmount = summary.WithoutExpYieldAmount || 0;
  const trialAmount = (summary.TrialIncompleteAmount + summary.TrialSuccessfulAmount + summary.TrialFailedAmount) || 0;

  return(
    <div className="summary-window">
      <div className="total-container">
        <div>Реакции стадии</div>
        <div>{totalAmount}</div>
      </div>
      <div className="details-container">
        <div className="mark has-yield" />
        <div>экспериментальный выход</div>
        <div>{withExpYieldAmount}</div>
        <div className="mark uncompleted" />
        <div>незавершенных реакций</div>
        <div>{nonFinishedAmount}</div>
        <div className="mark no-yield" />
        <div>экспериментальный выход=0</div>
        <div>{withoutExpYieldAmount}</div>
        <div className="mark trial" />
        <div>пробных реакций</div>
        <div>{trialAmount}</div>
      </div>
    </div>
  );
};
