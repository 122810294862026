import _ from 'lodash';
import {
  ExtraConditionSystemName,
  genReactionsEventDescriptionEnum,
  ReactionsEventPropNamesEnum
} from '../enums/reaction-events.enum';

export const ReactionEventNameValueSeparator = '=';
export const ReactionEventValueSeparator = '|';
export const ReactionEventPropertySeparator = '&';

export class ReactionEvent {
  ReactionId: number;
  CreationDate: Date;
  EventType: number;
  Block: number;
  UserName: string;
  AdditionalInfo: string;
  Abbreviation: string;
  
  EventParams: any = {};
  LayoutParams: any = {};

  booleanParams = [
    'IsRefCompound',
    'IsCompleted',
    'IsTrial',
    'IsSuccessful',
    'IsMainProduct',
  ];

  constructor(event, reactionTypes) {
    //PP: если появится еще одна зависимость от стейта - переделать в модель
    this.reactionTypes = reactionTypes;

    if (event) {

      this.ReactionId = event.ReactionId;
      this.CreationDate = event.CreationDate;
      this.EventType = event.EventType;
      this.Block = event.Block;
      this.UserName = event.UserName;
      this.AdditionalInfo = event.AdditionalInfo;
      this.Abbreviation = event.Abbreviation;
    }

    this.EventParams = this.parseInfo(this.AdditionalInfo);
    
    this.descriptionEnum = genReactionsEventDescriptionEnum({
      Name: this.EventParams?.Name,
      AgentName: this.EventParams?.AgentName,
      Amount: this.EventParams?.Amount,
      AgentAvailabilityNumber: this.EventParams?.AgentAvailabilityNumber,
      AgentAvailabilityId: this.EventParams?.AgentAvailabilityId,
      IsMainProduct: this.EventParams?.IsMainProduct,
      Block: this.Block,
      OriginalId: this.EventParams?.OriginalId,
      TemplateId: this.EventParams?.TemplateId,
    });
    this.LayoutParams.Description = this.descriptionEnum[this.EventType];
    this.LayoutParams.oldValues = this.getOldValues();
    this.LayoutParams.newValues = this.getNewValues();
  }

  parseInfo(info: string): any {
    if (!info) { return; }
    const result = {};
    info.split(ReactionEventPropertySeparator).forEach(part => {

      const nameValues = part.split(ReactionEventNameValueSeparator);
      const name = nameValues[0];

      if(nameValues.length > 1) {
        const values = nameValues[1].split(ReactionEventValueSeparator);
        
        values.length > 1
         ? result[name] = {old: values[0], new: values[1]}
         : result[name] = values[0];
      }
      else {
        result[name] = '';
      }
    });

    return result;
  }

  getOldValues() {
    const result = [];
    _.forOwn(this.EventParams, (value, name) => {
      if (value.old) {
        const val = this.handleSpecialValues(value.old, name);
        result.push({name: ReactionsEventPropNamesEnum[name], value: val});
      }
    });
    return result;
  }

  getNewValues() {
    const result = [];
    _.forOwn(this.EventParams, (value, name) => {
      if (value.new) {
        const val = this.handleSpecialValues(value.new, name);
        result.push({name: ReactionsEventPropNamesEnum[name], value: val});
      }
    });
    return result;
  }

  handleSpecialValues(val, name) {
    if (name === 'ReactionExtraConditions') {
      return this.getExtraConditionValues(val);
    }

    if (name === 'Duration') {
      return this.convertDuration(val);
    }

    if (name === 'StartDate' || name === 'EndDate') {
      return this.formatDate(val);
    }

    if (name === 'ReactionType') {
      return !val ? 'Не установлен' : (this.reactionTypes[val] ? this.reactionTypes[val].Name : val);
    }

    if (this.booleanParams.includes(name)) {
      return val === 'True' ? 'Да' : 'Нет';
    }

    return val;
  }

  formatDate(dateStr: string): string {
    const date = new Date(dateStr);
    return date.toLocaleDateString('ru-RU');
  }

  convertDuration(duration: string): string {
    const durationNum = +duration;
    const hours = Math.floor( durationNum / 60);          
    const minutes = durationNum % 60;
    return `${hours} ч. ${minutes} мин.`;
  }

  getExtraConditionValues(valuesInput: string): string {
    const separator = ', ';
    return valuesInput
    .split(separator)
    .map(i => ExtraConditionSystemName[i] ? ExtraConditionSystemName[i] : i)
    .join(separator);
  }
  
}
