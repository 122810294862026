// @flow

import {
    put,
    takeEvery,
    fork,
    select,
} from 'redux-saga/effects';
import {getToken} from '../../account/reducers';
import {
    indicatorProcessPointsResourceActions,
    indicatorsResourceActions,
    indicatorUnitsResourceActions
} from '../actions';
import {
    IndicatorProcessPointsResourceService,
    IndicatorsResourceService,
    IndicatorUnitsResourceService
} from '../services';
import type {IRequestResourceAction} from '../../shared/utils';
import {IndicatorProcessPoint} from '../models';

export function* listIndicators()
{
    const token = yield select(getToken);
    try {
        const response = yield IndicatorsResourceService.list({}, token);
        yield put(indicatorsResourceActions.list.success(response));
    } catch (error) {
        yield put(indicatorsResourceActions.list.failure({ error }));
    }
}

export function* listIndicatorUnits()
{
    const token = yield select(getToken);
    try {
        const response = yield IndicatorUnitsResourceService.list({}, token);
        yield put(indicatorUnitsResourceActions.list.success(response));
    } catch (error) {
        yield put(indicatorUnitsResourceActions.list.failure({ error }));
    }
}

export function* listIndicatorProcessPoints(action: { params: { stageId: number } })
{
    const token = yield select(getToken);
    try {
        const response = yield IndicatorProcessPointsResourceService.list({ params: action.params }, token);
        yield put(indicatorProcessPointsResourceActions.list.success(response));
    }
    catch (error) {
        yield put(indicatorProcessPointsResourceActions.list.failure({ error }));
    }
}

export function* getIndicatorProcessPoints({ uriParams }: IRequestResourceAction)
{
    const token = yield select(getToken);
    try {
        const response = yield IndicatorProcessPointsResourceService.get({ uriParams }, token);
        yield put(indicatorProcessPointsResourceActions.get.success(response));
    }
    catch (error) {
        yield put(indicatorProcessPointsResourceActions.get.failure({ error }));
    }
}

export function* updateIndicatorProcessPoints({ data }: IRequestResourceAction)
{
    const token = yield select(getToken);
    try {
        const response = yield IndicatorProcessPointsResourceService.update({ data }, token);
        yield put(indicatorProcessPointsResourceActions.update.success(response));
    }
    catch (error) {
        yield put(indicatorProcessPointsResourceActions.update.failure({ error }));
    }
}

export function* createIndicatorProcessPoints({ data }: IRequestResourceAction)
{
    const token = yield select(getToken);
    try {
        const response = yield IndicatorProcessPointsResourceService.create({ data }, token);
        yield put(indicatorProcessPointsResourceActions.create.success(response));
        return IndicatorProcessPoint(response.result);
    }
    catch (error) {
        yield put(indicatorProcessPointsResourceActions.create.failure({ error }));
    }
}

export function* deleteIndicatorProcessPoints({ uriParams }: IRequestResourceAction)
{
    const token = yield select(getToken);
    try {
        const response = yield IndicatorProcessPointsResourceService.delete({ uriParams }, token);
        yield put(indicatorProcessPointsResourceActions.delete.success(response));
    }
    catch (error) {
        yield put(indicatorProcessPointsResourceActions.delete.failure({ error }));
    }
}

export const indicatorsResourceWatchers = [
    function* ()
    {
        yield takeEvery(indicatorsResourceActions.list.request.toString(), function* ()
        {
            yield fork(listIndicators);
        });

        yield takeEvery(indicatorUnitsResourceActions.list.request.toString(), function* ()
        {
            yield fork(listIndicatorUnits);
        });

        yield takeEvery(indicatorProcessPointsResourceActions.list.request.type, function* (action)
        {
            yield fork(listIndicatorProcessPoints, action);
        });

        yield takeEvery(indicatorProcessPointsResourceActions.get.request.type, function* (action)
        {
            yield fork(getIndicatorProcessPoints, action);
        });

        yield takeEvery(indicatorProcessPointsResourceActions.update.request.type, function* (action)
        {
            yield fork(updateIndicatorProcessPoints, action);
        });

        yield takeEvery(indicatorProcessPointsResourceActions.create.request.type, function* (action)
        {
            yield fork(createIndicatorProcessPoints, action);
        });

        yield takeEvery(indicatorProcessPointsResourceActions.delete.request.type, function* (action)
        {
            yield fork(deleteIndicatorProcessPoints, action);
        });
    }
];
