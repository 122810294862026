import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IJarStock, JarStockKeysEnum } from '../../../../agents/models';
import { itemsToArraySelector } from '../../../../shared/utils/selectors/resource-selectors';
import css from './styles.m.less';
import gridCss from './table.m.less';
import { tableCols } from './tableCols';
import { reactionModuleActions } from '../../../actions';
import type { IReagentJar } from '../../../../reagents/model';
import { getJarStock } from '../../../../agents/reducers';
import { labsResourceActions } from '../../../../dictionary/actions';
import { getAmountRemainsIndicator } from '../../../../agents/utils';
import { _noop } from '../../../../shared/utils/common';
import Checkbox from '@biocad/bcd-front-ui/controls/Checkbox';


const JarStockSelectTable = ({ agentId, primaryReactionId, labId, reagent, ...state }) => {

  const {
    jars,
    selectedJars,
    addJarToSelected,
    deleteJarFromSelected,
    reagentJars,
    getLabs,
    labs = [],
  } = state;

  useEffect(() => {
    getLabs();
  }, []);

  const addJar = ({ jar }) => {
    console.log(jar);
    addJarToSelected({ jar });
  }

  useEffect(() => {
    if ((!reagentJars && !Array.isArray(reagentJars)) || (!jars && !Array.isArray(jars))) return;
    const shouldBeSelectedJars: IJarStock[] = jars.filter((jar: IJarStock) => !!reagentJars.find((rJar: IReagentJar) => rJar.AgentAvailabilityId === jar.Id));
    shouldBeSelectedJars.forEach((jar: IJarStock) => {
      jar.reagentId = reagent?.localUuid ?? null;
      addJar({ jar });
    });
  }, [jars, reagentJars]);

  const dataRowClickHandler = (jar: IJarStock) => {

    if (jar.IsOrdered) return;


    if(selectedJars.some(x => x?.Id == jar.Id && x.reagentId == jar.reagentId)) {
      deleteJarFromSelected({ id: jar.Id, reagentId: jar.reagentId })
    }
    else
      addJar({ jar });
  };

  const JarStockRowCells = ({ jar }) => {
    const getCellContent = (field) => {
      switch (field) {
        case JarStockKeysEnum.JarUsers: {
          return (
            <ul className={css.userList}>
              {jar[field].map((x, index) => <li key={index}>{x.UserName}</li>)}
            </ul>
          );
        }
        case JarStockKeysEnum.LaboratoryId: {
          return itemsToArraySelector(labs)?.find(lab => lab.Id === jar[field])?.Name
        }
        default: {
          return jar[field];
        }
      }
    };

    return tableCols.map(col => (
      <div key={`${jar.Id} ${col.mappingProp}`} className={classNames(gridCss.grid__cell, css.grid__cell)}>
        {getCellContent(col.mappingProp)}
      </div>
    ));
  };

  const shownJars = (primaryReactionId ? jars.filter(j => j.ProviderReactionId === primaryReactionId) : jars)
    .filter((jar) => (labId == null || labId == jar.LaboratoryId));


  const TableBody = () => {
    return (
      <div className={gridCss.grid__body}>
        {
          //отображаем только банки с остатком, либо если эта банка уже была выбрана
          shownJars.filter(x => x.AgentId === agentId && (x.Amount > 0 /*|| selectedJars.find(s => s?.Id === x?.Id)*/))
            .map((jar: IJarStock) => {
              const rJar = {reagentId: reagent?.localUuid ?? null, ...jar};
              return (<DataRow key={jar.Id} jar={rJar}>
                <div className={classNames(gridCss.grid__cell, css.grid__cell)}>
                  <Checkbox
                    checked={!!selectedJars.find(i => i.Id === jar.Id && i.reagentId == (reagent?.localUuid ?? null))}
                    onChange={_noop}
                  />
                </div>
                <JarStockRowCells jar={rJar} />
              </DataRow>)
          })
        }
      </div>
    );
  };



  const TableHeader = () =>
    <div className={gridCss.grid__header}>
      <div className={gridCss.grid__row}>
        <div className={classNames(gridCss.grid__cell, css.grid__cell)} />
        {
          tableCols.map((col, idx: number) => (
            <div className={gridCss.grid__cell} key={idx}>
              {col.title}
            </div>
          ))
        }
      </div>
    </div>;



  const DataRow = ({ children, jar }) => {
    const amoutRemains = getAmountRemainsIndicator(jar);
    return (
      <div
        onClick={jar.IsOrdered ? _noop : () => dataRowClickHandler(jar)}
        className={classNames({
          [gridCss.grid__row]: true,
          [gridCss[`grid__row_${amoutRemains?.className}`]]: !!amoutRemains,
          [gridCss.grid__row_disabled]: jar.IsOrdered
        })}
      >
        {children}
      </div>
    );
  };



  return (
    <>
      <div className={classNames(gridCss.grid)}>
        <TableHeader />
        <TableBody />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  jars: getJarStock(state),
  selectedJars: state.modules.reaction.jarStock.selectedJars,
  labs: state.resource.dictionary.labs
});

const mapDispatchToProps = (dispatch) => ({
  addJarToSelected: ({ jar }) => dispatch(reactionModuleActions.jarStock.selectedJars.add({ jar })),
  deleteJarFromSelected: ({ id, reagentId }) => dispatch(reactionModuleActions.jarStock.selectedJars.delete({ id, reagentId })),
  getLabs: () => dispatch(labsResourceActions.list.request({}))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(JarStockSelectTable)
);
