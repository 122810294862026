import React, {useState} from 'react';
import {connect} from 'react-redux';
import './index.less';
import Schemes from './schemes';
import {itemsToArraySelector} from '../../../../shared/utils/selectors/resource-selectors';
import Folders from './folders';
import FolderModal from '../../../../projects/components/ProjectsTable/Folders/modal';
import {folderModuleActions} from '../../../../projects/actions';
import {MolecularFormulaFormatter} from '../../../../shared/components';
import {SchemeDetailsDialog} from '../../../../schemes/components/SchemeDetailsDialog';
import {withRouter} from 'react-router-dom';
import {userCRUDPermissionsSelector} from "../../../../account/reducers/users-modules-reducer";

const initialProps = {
    currentCandidate: null,
    schemeList: [],
    setEditFolderMode: () => null,
    schemeSavedId: null,
    projectType: null,
    currentProjectType: null,
    userPermissions: {},
    folderSavedSuccessful: false
};

const ProductionSchemePanel = (props = initialProps) => {
    const [showFolderModal, setShowFolderModal] = useState(false);
    const [showSchemeDetailsModal, setShowSchemeDetailsModal] = useState(false);

    const toggleFolderModal = () => {
        setShowFolderModal(!showFolderModal);
        props.setEditFolderMode({
            isEdit: false
        });
    };

    const createScheme = () => {
        setShowSchemeDetailsModal(true);
    };

    const toggleDetailsModal = () => {
        setShowSchemeDetailsModal(!showSchemeDetailsModal);
    };

    const goToCreatedScheme = () => {
        props.history.push(`/schemes/${props.schemeSavedId}`);
    };

    const getProjectType = () => {
        if(props.projectType && props.projectType != "")
            return props.projectType;
        return props.currentProjectType;
    }

    return (
        <div className={'production-scheme-panel'}>

            {showFolderModal &&
            <FolderModal toggleModal={toggleFolderModal} position={'scheme'} isSchemesFolder={true}/>}

            <div className={'main-title'}>
                <MolecularFormulaFormatter
                    className={'h2'}
                    formulaStr={props.currentCandidate?.Compound.MolecularFormula || ''}
                />
            </div>

            {props.currentCandidate &&
            <div className={'panel-content'}>
                <div className={'control-panel'}>
                    <h4>
                        Схемы получения
                        <div className={'counter'}>{props.schemeList.length}</div>
                    </h4>

                    <div className={'btns-panel'}>
                        {
                            props.userPermissions.folder.CREATE &&
                            <i className={'fo-add-folder'} onClick={toggleFolderModal}/>
                        }
                        {
                            props.userPermissions.scheme.CREATE &&
                            <i className={'fo-plus'} onClick={createScheme}/>
                        }
                    </div>
                </div>

                <Folders toggleFolderModal={toggleFolderModal}/>

                <Schemes/>

            </div>
            }

            {showSchemeDetailsModal &&
            <SchemeDetailsDialog
                onClose={toggleDetailsModal}
                onSave={goToCreatedScheme}
                projectType={getProjectType()}
            />
            }

        </div>
    );

};

const mapStateToProps = (state) => {
    const {modules, resource} = state;
    return {
        currentCandidate: modules.candidate.candidate,
        schemeList: itemsToArraySelector(resource.productionScheme),
        schemeSavedId: state?.modules?.scheme?.editScheme?.schemeSavedId,
        projectType: state?.modules?.project?.type,
        currentProjectType: state?.modules?.project?.currentProject?.Type,
        userPermissions: userCRUDPermissionsSelector(state),
    };
};

export default withRouter(connect(
    mapStateToProps,
    (dispatch) => ({
        setEditFolderMode: (isEditMode) => dispatch(folderModuleActions.setEditMode(isEditMode))
    })
)(ProductionSchemePanel));
