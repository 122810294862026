// @flow
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import bnc from 'bnc';
import Button from '@biocad/bcd-front-ui/controls/Button';
import Input from '@biocad/bcd-front-ui/controls/Input';
import { OptionType } from '../../models';

import './index.less';

type EmitedMultiValueInputPayload = {
  values: any,
  inputElemValue: string;
}

interface IMultiValueInputProps {
  label?: string;
  defaultValue?: OptionType[];
  placeholder?: string;
  onItemAdded?: (payload?: EmitedMultiValueInputPayload) => null,
  onItemRemoved?: (payload?: EmitedMultiValueInputPayload) => null,
}

const
  block           = new bnc('b-MultiValueInput'),
  bLabel          = block.el('label'),
  bLabelText      = block.el('labelText'),
  bValueItem      = block.el('valueItem'),
  bValueItemText  = block.el('valueItemText'),
  bValueItemsList = block.el('valueItemsList'),
  bField          = block.el('field'),
  bBtn            = block.el('btn'),
  bBtnAdd         = bBtn.mod('add'),
  bBtnRemove      = bBtn.mod('remove')
;

const MultiValueInput = ({
                           label = '',
                           defaultValue = [],
                           placeholder = '',
                           onItemAdded = ()=>{},
                           onItemRemoved = ()=>{},
                           onItemsChanged = ()=>{},
                           input = {},
                           meta = {},
                           disabled = false,
}: IMultiValueInputProps) => {
  const [values, setValues] = useState(defaultValue);
  const [deletedValues, setDeletedValues] = useState([]);
  const [newValues, setNewValues] = useState([]);
  const [inputElemValue, setInputElemValue] = useState('');

  const { onChange, onBlur, onFocus, value } = input;

  useEffect(() => {
    if (value && value.length && Array.isArray(value)) setValues(value);
  }, [value]);

  useEffect(() => {
    onChange({ ...values, deletedValues, newValues });
    onItemsChanged({values});
  }, [deletedValues, newValues]);

  const inputRef = React.createRef();

  const clearInput = () => {
    inputRef.current.clear();
    setInputElemValue('');
  };

  const addItemToList = () => {
    if (!inputElemValue) return;
    if (values.find(i => i.label === inputElemValue.trim())) {
      clearInput();
      return;
    }
    setValues(values.concat([{label: inputElemValue}]));
    setNewValues(prevValues => [...prevValues, {label: inputElemValue}]);
    clearInput();
    onItemAdded({ inputElemValue, values });
  };

  const removeItemFromList = (item: OptionType) => {
    setValues(prevValue => prevValue.filter((val: OptionType) => val !== item));
    if (item.value) setDeletedValues(prevValue => [...prevValue, item.value]);
    if (values.find((val: OptionType) => val.label === item.label)) {
      setNewValues(prevValues => prevValues.filter((val: OptionType) => val.label !== item.label));
    }
    onItemRemoved({ inputElemValue, values });
  };

  const onInputChange = (event) => {
    const value = event && event.target && event.target.value.toString();
    if (value) {
      setInputElemValue(value.trim());
    }
  };

  const onInputKeyDown = (event) => {
    if (event.target.value.length && event.key === 'Enter') addItemToList(inputElemValue);
  };

  const onAddBtnClick = () => {
    addItemToList(inputElemValue);
  };

  return (
    <div className={block.toString()}>
      <label className={bLabel.toString()}>
        { label ? <span className={bLabelText.toString()}>{ label }</span> : null }
        <Input
          type={'text'}
          className={bField.toString()}
          placeholder={placeholder}
          ref={inputRef}
          onChange={onInputChange}
          onKeyDown={onInputKeyDown}
          onBlur={() => onBlur()}
          onFocus={() => onFocus()}
          disabled={disabled}
        />
        <Button
          className={bBtn.toString() + bBtnAdd.toString() + 'fo-plus'}
          onAction={onAddBtnClick}
          view={'icon'}
          size={'L'}
          disabled={disabled}
        />
      </label>
      <div className={bValueItemsList.toString()}>
        {
          values.map((item, index) => (
            <div className={bValueItem.toString()}
                 key={`${index}_${item.value || item}`}>
              <span
                className={bValueItemText.toString()}
                title={item.label || item}
              >{item.label || item}</span>
              <Button
                className={bBtn.toString() + bBtnRemove.toString() + 'fo-cross-thick'}
                onAction={() => removeItemFromList(item)}
                view={'icon'}
                size={'L'}
                disabled={disabled}
              />
            </div>
          ))
        }
      </div>
    </div>
  );
};

MultiValueInput.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
  onItemAdded: PropTypes.func,
  onItemRemoved: PropTypes.func,
};

const bncInstances = {
  block,
  bField,
  bBtn, bBtnAdd, bBtnRemove,
  bLabel, bLabelText,
  bValueItem, bValueItemsList, bValueItemText,

};

export {
  MultiValueInput,
  bncInstances
};
