import {getEnum} from '../../shared/utils';

export const projectTypesTranslate = getEnum({
    innovative: 'Инновационный',
    generic: 'Generic'
});

export const projectStatusesTranslate = getEnum({
    configuring: 'Подготовка',
    inWork: 'Активный',
    finished: 'Завершен',
    closed: 'Закрыт',
    paused: 'Приостановлен',
});
