// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tooltip-m__tooltip__wrapper___NqaSl {\n  max-width: 300px;\n  padding: var(--minor-margin);\n}\n.tooltip-m__tooltip__header___jBdoL {\n  font-size: 12px;\n  color: var(--grey-500);\n  margin-bottom: var(--minor-margin);\n}\n.tooltip-m__tooltip__body___Hk2YJ {\n  word-wrap: break-word;\n  overflow-x: hidden;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip__wrapper": "tooltip-m__tooltip__wrapper___NqaSl",
	"tooltip__header": "tooltip-m__tooltip__header___jBdoL",
	"tooltip__body": "tooltip-m__tooltip__body___Hk2YJ"
};
export default ___CSS_LOADER_EXPORT___;
