// @flow

import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import type {IAgent} from '../../../../agents/models/i-agent';
import {itemsToArraySelector} from '../../../../shared/utils/selectors/resource-selectors';
import Dialog from '@biocad/bcd-front-ui/layout/Dialog';
import {DialogLayout} from '../../../../stages/components/StageEdit/StageSubstanceEdit/AgentsListDialog/style';
import Loader from '@biocad/bcd-front-ui/controls/Loader';
import {AGENT_SEARCH_FORM_NAME, SearchForm, agentSearchInitialValues} from '../../../../agents/components/List/SearchForm';
import {GridTable, GridTableCol} from '../../../../shared/components/GridTable';
import {agentResourceActions} from '../../../../agents/actions';
import {_noop} from '../../../../shared/utils/common';
import {getFormValues} from 'redux-form';
import ChooseJarTable from './ChooseJarTable/index';
import {AgentsTableStyled} from '../../../../agents/components/List/AgentTable/style';
import { IconButtonExpand } from '../../../../shared/components/IconButtonExpand';
import Button from '@biocad/bcd-front-ui/controls/Button';
import type { IJarStock } from '../../../../agents/models';
import type { IPagination } from '../../../../shared/models';
import { PaginationStyled } from '../../../../shared/components/Paginator/style';
import type { IReactionEditDialogWrapComponentProps } from '../ReactionEditDialogWrap';

interface IChooseReactantsTableModal extends IReactionEditDialogWrapComponentProps {
    agents?: IAgent[],
    pending?: boolean,
    onCancel: Function,
    loadAgents?: Function,
    destroyAgents?: Function,
    searchFormValues?: any,
    onJarsSelected?: (IAgent, IJarStock[], IJarStock)=>void,
    pagination?: IPagination,
    excludedAgentIds?: number[]
}

const initialProps: IChooseReactantsTableModal = {
    agents: [],
    pending: true,
    onCancel: _noop,
    loadAgents: _noop,
    destroyAgents: _noop,
    onJarsSelected: _noop,
    excludedAgentIds: [],
};

const localAgentSearchInitialValues: ISearchFormValues = {
    ...agentSearchInitialValues,
    isInStockOnly: true,
    isInitialyLoaded: false
};
  

let ChooseReactantsTableModal = (props: IChooseReactantsTableModal = initialProps) => {
    if (props.isInStockOnly == true)
        localAgentSearchInitialValues.isInStockOnly = props.isInStockOnly;
    // State
    const
      [selectedJars, setSelectedJars] = useState([]),
      [openedAgentId, setOpenedAgentId] = useState(null)
    ;

    const searchQuery = {
        ...props.searchFormValues,
        ...((props.excludedAgentIds && props.excludedAgentIds.length)
          ? { excludedAgentIds: props.excludedAgentIds }
          : null)
    };

    // Effects
    const _loadAgents = () => {
        if(localAgentSearchInitialValues.isInitialyLoaded == true)
            return;
        if (!props.searchFormValues || props.pending || !props.pagination || props.isInStockOnly != props.searchFormValues.isInStockOnly || props.agents.length > 0)
            return;
        var searchParams = {
            ...searchQuery,
            skip: (props.pagination.page-1)*props.pagination.pageSize,
            limit: props.pagination.pageSize
        };        
        localAgentSearchInitialValues.isInitialyLoaded = true;
        props.loadAgents(searchParams);
        return () => {
            props.destroyAgents();
        };
    };
    useEffect(_loadAgents, [props.searchFormValues]);

    // Methods
    const
        onCancel = () => {            
            localAgentSearchInitialValues.isInitialyLoaded = false;
            props.destroyAgents();
            props.closeDialog();
        },

        onSearchFormSubmit = (event) => {
            event.preventDefault();
            if (!props.pending) {
                props.destroyAgents();
                setSelectedJars(null);
                props.loadAgents({
                    ...searchQuery,
                    skip: (props.pagination.page-1)*props.pagination.pageSize,
                    limit: props.pagination.pageSize
                });
            }
        },

        onAgentClick = (agent: IAgent) => {
            openedAgentId !== agent.Id
              ? setOpenedAgentId(agent.Id)
              : setOpenedAgentId(null);
        },

        onPaginationChange = (paginationParams) => {            
          if (!props.pending) {
              props.destroyAgents();
              setSelectedJars([]);
              setOpenedAgentId(null);
              props.loadAgents({ ...searchQuery, ...paginationParams });
          }
        },

        selectJars = () => {
            if (props.onJarsSelected !== _noop) {
                const agent = props.agents.find(a => a.Id === openedAgentId);
                const availability = selectedJars[0];
                props.onJarsSelected(agent, selectedJars, availability);
            }
            localAgentSearchInitialValues.isInitialyLoaded = false;
            props.destroyAgents();
            props.closeDialog();
        }
    ;

    return (
      <Dialog close={onCancel} paranjaClose={false}>
        <DialogLayout className={'dialog-layout'}>
            <header className={'dialog-header'}>
                <h3>Поиск компонента</h3>
            </header>

            <div className={'dialog-contents'}>
                {
                    <>
                        <SearchForm handleSubmit={onSearchFormSubmit}
                                    className={'searchForm'}
                                    isInStockOnly={props.isInStockOnly}
                                    initialValues={localAgentSearchInitialValues}
                        />

                        {
                            props.agents &&
                            <AgentsTableStyled
                                as={GridTable}
                                items={props.agents}
                                rowHeight={'auto'}
                                colsTemplate={'50px 200px 300px repeat(3, minmax(120px, 1fr))'}
                                onRowClicked={onAgentClick}
                                hover={true}
                                renderRowAfter={({item}: { item: IAgent }) => (
                                  openedAgentId === item.Id &&
                                  <ChooseJarTable
                                    agentId={item.Id}
                                    onJarSelected={(jars: IJarStock[]) => setSelectedJars(jars)}
                                  />
                                )}
                                setRowClassNames={(item: IAgent, _) => ({
                                    'highlight-error': !item.HasJarsWithAmount,
                                    'is-selected': item.Id === openedAgentId,
                                })}
                            >
                                <GridTableCol
                                    title={''}
                                    render={({item}: { item: IAgent }) => (
                                      <IconButtonExpand
                                        expanded={item.Id === openedAgentId}
                                        expandHandler={(event) => onAgentClick(item, event)}
                                      />
                                    )}
                                />
                                <GridTableCol
                                    title={'Структурная формула'}
                                    render={({item}: { item: IAgent }) => <img src={item?.Compound?.Thumbnail}
                                                                               alt={''} className={'thumbnail'}/>}
                                />
                                <GridTableCol
                                    title={'Наименование'}
                                    render={({item}: { item: IAgent }) => <span>{item?.Name}</span>}
                                />
                                <GridTableCol
                                    title={'Сокращение'}
                                    render={({item}: { item: IAgent }) => <span>{item?.ShortName}</span>}
                                />
                                <GridTableCol
                                    title={'CAS-номер'}
                                    render={({item}: { item: IAgent }) => <span>{item?.Cas}</span>}
                                />
                                <GridTableCol
                                    title={'MW, г/моль'}
                                    render={({item}: { item: IAgent }) => <span>{item?.MolarWeight}</span>}
                                />
                            </AgentsTableStyled>
                        }
                    </>
                }
            </div>
            <footer className={'dialog-footer'}>
                <PaginationStyled
                  {...props.pagination}
                  handlePagingChange={onPaginationChange}
                />
                <Button
                  text={'Выбрать'}
                  size={'L'}
                  disabled={!selectedJars?.length}
                  onAction={selectJars}
                  view={'primary'}
                />
                <Button
                  text={'Отменить'}
                  size={'L'}
                  onAction={onCancel}
                />
            </footer>
        </DialogLayout>
      </Dialog>
    );
};

ChooseReactantsTableModal = connect(
    (state) => ({
        agents: itemsToArraySelector(state.resource.agents),
        pending: state.resource.agents?.pending,
        searchFormValues: getFormValues(AGENT_SEARCH_FORM_NAME)(state),
        pagination: state.modules.agent?.list?.pagination,
    }),
    (dispatch) => ({
        loadAgents: (params) => dispatch(agentResourceActions.list.request({params})),
        destroyAgents: () => dispatch(agentResourceActions.destroy())
    })
)(ChooseReactantsTableModal);

export {
    ChooseReactantsTableModal
};


