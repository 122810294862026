import { ExportFormat } from './marvinjs-util';
import { apiRequestSingle } from '../api/common';
import { environment } from '../../../environment';

const endpoint = environment.jChemUrl;

export const marvinJsEndpoints = {
    'clean2dws': endpoint + 'rest-v0/util/convert/clean',
    'clean3dws': endpoint + 'rest-v0/util/convert/clean',
    'molconvertws': endpoint + 'rest-v0/util/calculate/molExport',
    'stereoinfows': endpoint + 'rest-v0/util/calculate/cipStereoInfo',
    'reactionconvertws': endpoint + 'rest-v0/util/calculate/reactionExport',
    'hydrogenizews': endpoint + 'rest-v0/util/convert/hydrogenizer',
    'automapperws': endpoint + 'rest-v0/util/convert/reactionConverter'
};

const headers = { 'Content-Type': 'application/json' };

const parser = new DOMParser();

const requestHydrogenize = apiRequestSingle('POST', marvinJsEndpoints.hydrogenizews, headers);

const requestConvert = apiRequestSingle('POST', marvinJsEndpoints.molconvertws, headers);

export class JChemService {
    async getMolecularFormula(formula: string): Promise<string> {
        const body = { 'structure': formula, 'parameters': { 'method': 'HYDROGENIZE' } };

        const response = await requestHydrogenize({}, body);

        const root = parser.parseFromString(response.result, 'application/xml');
        const atomArrayTags = Array.from(root.getElementsByTagName('atomArray'));
        const atomCounts = {};
        for (const atomArrayTag of atomArrayTags) {
            const allAtoms = atomArrayTag.getAttribute('elementType');
            if (allAtoms) {
                for (const atom of allAtoms.split(' ')) {
                    atomCounts[atom] = (atomCounts[atom] || 0) + 1;
                }
            }
            else {
                const atomTags = Array.from(atomArrayTag.getElementsByTagName('atom'));
                for (const atomTag of atomTags) {
                    const atom = atomTag.getAttribute('elementType');
                    if (atom) {
                        atomCounts[atom] = (atomCounts[atom] || 0) + 1;
                    }
                }
            }
        }
        let res = '';
        for (const atomKey in atomCounts) {
            if (Object.hasOwnProperty.call(atomCounts, atomKey)) {
                res = res + atomKey + atomCounts[atomKey];
            }
        }
        return res;
    }

    async convert(formula: string, format: ExportFormat): Promise<string> {
        const body = { 'structure': formula, 'parameters': format };

        const res = await requestConvert({}, body);

        return res.result['structure'];
    }

    async tryConvert(formula: string, format: ExportFormat): Promise<string> {
        const body = { 'structure': formula, 'parameters': format };

        let res;
        try {
            res = await requestConvert({}, body);
        }
        catch {
            return null;
        }

        return res.result['structure'];
    }
}
