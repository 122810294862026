// @flow

import { Model, BasicModel } from 'objectmodel';
import type { IIndicator } from './i-indicator';
import { IndicatorTypeEnum } from './indicator-type-enum';

export const IndicatorKeyNames = {
    Id                      : 'Id',
    Name                    : 'Name',
    IsDeleted               : 'IsDeleted',
    IndicatorType           : 'IndicatorType',
    OrderPriority           : 'OrderPriority',
    IsScalingProjectsOnly   : 'IsScalingProjectsOnly',
}

export const Indicator: (IIndicator)=>IIndicator = Model({
    [IndicatorKeyNames.Id]                      : [Number],
    [IndicatorKeyNames.Name]                    : [String],
    [IndicatorKeyNames.IsDeleted]               : [Boolean],
    [IndicatorKeyNames.OrderPriority]           : [Number],
    [IndicatorKeyNames.IndicatorType]           : [BasicModel([Number]).assert(t => t in IndicatorTypeEnum)],
    [IndicatorKeyNames.IsScalingProjectsOnly]   : [Boolean],
})
.defaultTo({
    [IndicatorKeyNames.Id]                      : null,
    [IndicatorKeyNames.Name]                    : '',
    [IndicatorKeyNames.IsDeleted]               : false,
    [IndicatorKeyNames.IndicatorType]           : null,
    [IndicatorKeyNames.IsScalingProjectsOnly]   : false,
});
