// @flow
import { apiRequestSingle } from '../../shared/services/api/common';

export class LabsService {
    
    _getLabsList: () => {};

    constructor(token) {
        const headers = token ? {Authorization: token} : {};

        this._getLabsList = apiRequestSingle('GET', '/laboratories', headers);
    }

    getLabsList() {
        return this._getLabsList({}, {});
    }
}
