// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".list-m__table___W0Ca1 {\n  --grid-template: 2fr 1fr 150px 50px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "list-m__table___W0Ca1"
};
export default ___CSS_LOADER_EXPORT___;
