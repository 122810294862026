import React, {useState} from 'react';
import bnc from 'bnc';
import {CSSTransition} from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons/faAngleDoubleRight';


import './Sidebar.less';
const b = new bnc.default('b-sidebar');

const SidebarCollapsible = ({children, className='', opened=true}) => {
    const [isOpen, setIsOpen] = useState(opened);

    return (
        <aside className={b + b.mod('open', isOpen ? 'yes': 'no') + className}>
            <CSSTransition
                in={isOpen}
                unmountOnExit
                mountOnEnter
                timeout={400}
                classNames={b.el('content').mod('transition').toString().trim()}
                >
                <div className={b.el('content')}>
                    <div className={b.el('content_wrapper')}>
                        {children}
                    </div>
                </div>
            </CSSTransition>
            <CSSTransition
                in={isOpen}
                timeout={400}
                classNames={b.el('controls').mod('transition').toString().trim()}
                >
                <div className={b.el('controls')}>
                    <button className={b.el('collapse-button')} onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon className={b.el('collapse-icon').toString()} icon={faAngleDoubleRight} />
                    </button>
                </div>
            </CSSTransition>
        </aside>
    );
};

const SidebarSimple = ({children, className=''}) => (
    <aside className={b + className}>
        <div className={b.el('content') + b.el('content_wrapper')}>
            {children}
        </div>
    </aside>
);

const Sidebar = ({children, className='', collapsible=false, opened}) => (
    collapsible
        ? <SidebarCollapsible className={className} opened={opened}>{children}</SidebarCollapsible>
        : <SidebarSimple className={className}>{children}</SidebarSimple>
);

export default Sidebar;