// @flow

import { resourceReducer } from '../../shared/utils/reducer-creators';
import { labsResourceActions } from '../actions';
import { createSelector } from 'reselect';
import type { IRootStore } from '../../app/reducers';
import type { ILab } from '../models';
import type { OptionType } from '../../shared/models';

export const labsResourceReducer = resourceReducer(labsResourceActions);

export const
	getLabsSelector = createSelector(
		(state: IRootStore) => state.resource.dictionary.labs.data,
		(labs: {}) => Object.values(labs),
	),
	getLabsPendingSelector = (
		(state: IRootStore) => state.resource.dictionary.labs.pending
	),
	getLabsAsOptions = createSelector(
		[getLabsSelector],
		(labs: ILab[]): OptionType[] => labs.map((lab: ILab): OptionType => ({ label: lab.Name, value: lab.Id }))
	)
;
