// @flow

import {
    resourceActions,
} from '../../shared/utils';


export const OWNED_PROJECTS_ROOT_NAME = 'OWNED_PROJECTS';
export const ownedProjectsResourceActions = {
    ...resourceActions(OWNED_PROJECTS_ROOT_NAME),
};