// @flow

import React from 'react';
import { connect } from 'react-redux';
import {Redirect, useLocation} from 'react-router-dom';
import {useLastVisitedPage} from '../../shared/react-hooks/use-last-visited-page';
import type {IRootStore} from '../reducers';

interface IProps
{
    connectedRouterState?: { url: string },
    connectedRouterQuery?: { url: string }
}

let Reload = (props: IProps) =>
{
    const location = useLocation();
    const routerState = location.state;
    const state = { ...routerState, ...props.connectedRouterState, ...props.connectedRouterQuery };
    const previous = useLastVisitedPage();
    const url = state?.url
        ? (state.url.startsWith('/')
                ? state.url
                : `/${state.url}`)
        : previous;
    return ( <Redirect to={url || '/user-dashboard'} /> );
}

Reload = connect(
    (state: IRootStore): IProps => ({
        connectedRouterState: state.router?.location?.state,
        connectedRouterQuery: state.router?.location?.query
    })
)(Reload);

export {Reload}