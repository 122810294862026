import React from 'react';
import css from './area.m.less';
import classNames from 'classnames';

export class ResizableArea extends React.Component {
  
  constructor (props) {
    super(props);

    this.state = {
      value: this.props.value || '',
      lineHeight: null,
    };

    this.node = React.createRef();
  }

  init() {
    const lineHeight = +getComputedStyle(this.node.current).lineHeight.slice(0, -2);
    
    this.setState({
      ...this.state,
      lineHeight,
    });
  }

  resize() {   
    if (this.node && this.node.current) {
      this.node.current.style.height = 'auto';
      this.node.current.style.height = Math.min(this.state.lineHeight*this.props.maxRows, this.node.current.scrollHeight) + 1 +'px';
    }
  }
  
  delayedResize () {
    window.setTimeout(this.resize.bind(this), 0);
  }

  componentDidMount() {
    if (this.node) {
      this.init();
      this.delayedResize();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        ...this.state,
        value: this.props.value,
      });
    }

    if (this.node  && prevState.value !== this.state.value) {
      this.resize();
    }
  }

  changeHandler(event) {
    this.setState({
      ...this.state,
      value: event.target.value
    });

    this.props.onChange && this.props.onChange(event.target.value);
  }

  render() {
    return (
      <div className={classNames(
        'resizable-area-component-wrapper',
        css.wrapper,
        this.props.valid
          ? css.valid
          : this.props.valid !== undefined
            ? css.invalid
            : null,
        
        this.props.valid
          ? 'valid'
          : this.props.valid !== undefined
            ? 'invalid'
            : null,

        this.props.disabled ? css.disabled : null,
        this.props.className ? css.className : null,
      )}>
        <textarea
          className={classNames(
            'resizable-area-component-textarea',
            css.textarea
          )}
          rows={this.props.rows}
          ref={this.node}
          value={this.state.value}
          disabled={this.props.disabled}
          placeholder={this.props.placeholder}
          onChange={event => this.changeHandler(event)}/>
      </div>
    );
  }
}

ResizableArea.defaultProps = {
  rows: 1,
  maxRows: 2,
};