// @flow

export type ImageType = 'image/png' | 'image/jpeg' | 'image/svg';

export type ExportFormat = 'mrv' | 'mol' | 'smiles' | 'smarts' | 'inchi' | 'name' | 'cxsmiles';

export type EventType = 'molchange' | 'selectionchange' | 'undo' | 'redo';

export interface MarvinJsSketch {
    exportStructure(format: ExportFormat): Promise<string>;

    importStructure(format: ExportFormat, value: string): void;

    exportAsMrv(): string;

    exportAsMol(): string;

    setServices(services: { [key: string]: string }): void;

    on(eventType: EventType, callback: () => void): void;

    off(eventType: EventType, callback: () => void): void;

    setDisplaySettings({ atomIndicesVisible: boolean }): void;

    isEmpty(): boolean;

    clear(): void;

    pasteStructure(format: ExportFormat, source: string): void;

    getSupportedFormats(): {
        importFormats: any,
        exportFormats: any
    };

    addButton(jso: any, EventListener): void;

    addTemplate(jso: any): void;

    getSelection(): {
        atoms: string,
        bonds: string,
    };

    setSelection(jso: any): void;

    setHighlight(jso: any[]): void;

    setAutoChirality(boolean): void;
}

export interface MarvinJsImageExporter {
    molToDataUrl(source: string, imageType: ImageType, optionalSettings?: { [key: string]: any }): string;

    mrvToDataUrl(source: string, imageType: ImageType, optionalSettings?: { [key: string]: any }): string;
}

export interface MarvinJsPackage {
    sketcherInstance: MarvinJsSketch;
    onReady: (() => void) => void;

    ImageExporter: MarvinJsImageExporter;
}

export class MarvinJSUtil {
    static getEditor(element: HTMLIFrameElement | null): Promise<MarvinJsSketch> {
        return MarvinJSUtil.getPackage(element).then(result => result.sketcherInstance);
    }

    static getPackage(element: HTMLIFrameElement | null): Promise<MarvinJsPackage> {
        return new Promise((resolve: (value: MarvinJsPackage) => void, reject: (reason: any) => void): void => {
            MarvinJSUtil._createPackage(element, resolve, reject);
        });
    }

    static _getPackage(wrapperElement: HTMLIFrameElement): MarvinJsPackage | null {
        if (typeof wrapperElement.contentWindow.marvin !== 'undefined') {
            return wrapperElement.contentWindow.marvin;
        }
        return null;
    }

    static _createPackage(element: HTMLIFrameElement | null, resolve: (value: MarvinJsPackage) => void, reject: (reason: any) => void) {
        if (!(element instanceof HTMLIFrameElement)) {
            reject('Element should be instance of HTMLIFrameElement.');
            return;
        }

        const marvinPackage = MarvinJSUtil._getPackage(element);

        if (marvinPackage !== null) {
            marvinPackage.onReady(function () {
                resolve(marvinPackage);
            });
        } else { // use listener
            element.addEventListener('load', () => {
                const marvin = MarvinJSUtil._getPackage(element);
                if (marvin) {
                    marvin.onReady(function () {
                        resolve(marvin);
                    });
                } else {
                    reject('Unable to find marvin package');
                }
            });
        }
    }
}
