import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {change} from 'redux-form';
// eslint-disable-next-line import/no-cycle
import {CANDIDATE_EDIT_FORM_NAME} from '../../../candidates/components/EditCandidate/RightPanel';
import classNames from 'classnames';
import {candidatesResourceActions} from '../../../projects/actions';
import './index.less';

const initialProps = {
    updateParentsTasksList: () => null,
    checkTask: () => null,
    checkParentsTaskStatus: null,
    isNewItem: false,
    item: null,
    checkPending: false
};

const ParentsTasks = (props = initialProps) => {
    const [parentsTasksList, setParentsTasksList] = useState([]);
    const [parentTaskValue, setParentTaskValue] = useState('');

    useEffect(() => {
        setParentsTasksList([]);

        if (!props.isNewItem) {
            const repkaTasks = getRepkaTasksList() || [];
            setParentsTasksList(repkaTasks);
        }
    }, [props.item]);

    const getRepkaTasksList = () => {
        if (props.item?.RepkaTaskLinks)
        {
            return Array.from(
                props.item?.RepkaTaskLinks
                    .filter(i => !i.IsDeleted)
                    .reduce((acc, cur) => new Set(acc.add(cur.RepkaTaskId)), new Set)
            );
        }
    };

    useEffect(() => {
        props.updateParentsTasksList(parentsTasksList);
        if(JSON.stringify(parentsTasksList) === '[""]') {
            setParentsTasksList(
                !props.isNewItem ? (getRepkaTasksList() || []) : []
            );
        }
    }, [parentsTasksList]);

    useEffect(() => {
        if (props.checkPending) {
            addParentsTask();
        }
    }, [props.checkPending]);

    const checkParentsTask = () => {
        props.checkTask({
            repkaTaskId: parentTaskValue
        });
    };

    const addParentsTask = () => {
        if(parentsTasksList.indexOf(parentTaskValue) === -1) setParentsTasksList([...parentsTasksList, parentTaskValue]);
        setParentTaskValue('');
    };

    const onParentTaskInput = (e) => {
        setParentTaskValue(e.target.value);
    };

    const deleteParentsTask = (e) => {
        let tempArr = [...parentsTasksList];
        let index = e.target.dataset.index;

        tempArr.splice(index, 1);

        setParentsTasksList(tempArr);
        props.updateParentsTasksList(tempArr);
    };

    return (
        <label className={'labelToTop'}>
            Родительские задачи
            <i className='required-red' />

            <div className={'parents-tasks-list'}>
                <input
                    onChange={onParentTaskInput}
                    value={parentTaskValue}
                    placeholder={'Введите задачу'}/>

                <div onClick={checkParentsTask} className={classNames('add-task-btn',
                    {'error': parentTaskValue === ''})}>
                    <i className={'fo-plus'}/>
                </div>

                <ul>
                    {parentsTasksList?.map((task, index) => {
                        return (
                            <li key={index}>
                                <div className={'task-title'}>
                                    {task}
                                </div>

                                <i className={'fo-plus'} data-index={index} onClick={deleteParentsTask}/>
                            </li>
                        );
                    })}
                </ul>

            </div>

        </label>

    );

};

export default connect(
    null,
    null
)(ParentsTasks);