// @flow
import { Model, ArrayModel } from 'objectmodel';

export const CatalogReactionRequestParams = Model({
  Author: [String],
  CompleteDateFrom: [String, Date, null],
  CompleteDateTo: [String, Date, null],
  CreationDateFrom: [String, Date, null],
  CreationDateTo: [String, Date, null],
  IncludeArchived: Boolean,
  IncludeCompleted: Boolean,
  IncludeExpYield: Boolean,
  IncludeIncomplete: Boolean,
  IncludeRegular: Boolean,
  IncludeTrialFailed: Boolean,
  IncludeTrialIncomplete: Boolean,
  IncludeTrialSuccessful: Boolean,
  IncludeWithoutExpYield: Boolean,
  ProjectId: [Number],
  SmilesFormula: [String],
  UserName: [String],
  Skip: [Number],
  Limit: [Number],
  Order: [String],
  ReactionName: [String],
  FinalCandidateCode: [String],
  preventSearchRequest: [Boolean],
  ReactionTypes: [ArrayModel([Number]), 0, null, undefined],
  OnlyTemplateReactions: [Boolean],
});
