// @flow

import { Model, ObjectModel, ArrayModel } from 'objectmodel';
import { Compound } from '../../agents/models';
import { MergedStage } from './merged-stage';

export const ObtainmentStage = Model({
  BestReactionId: [Number],
  CanBeModifiedOrDeleted: [Boolean],
  Compound: [Compound],
  CompoundId: [Number],
  Id: [Number],
  IsDeleted: [Boolean],
  IsFinalCandidate: [Boolean],
  MergedStages: [ArrayModel(MergedStage)],
  NextStageInfo: [{
    Id: [Number],
    ObtainmentSchemeId: [Number],
  }],
  Number: [Number],
  ObtainmentSchemeId: [Number],
  PreviousStageCompound: [Compound],
  PreviousStageId: [Number],
  TreeNumber: [Number],
  ExpectedReactionTypeId: [Number],
  RequiredPurificationsCount: [Number],
  PurificationBundleType: [Number],
});
