// @flow

import { resourceReducer, setResourceRequestState, setResourceFailState } from '../../shared/utils';
import { schemesResourceActions } from '../actions';

export const schemeResourceReducer = resourceReducer(schemesResourceActions, {
  [schemesResourceActions.getMergeableSchemes.request]: setResourceRequestState,
  [schemesResourceActions.getMergeableSchemes.success]: (state, action) => ({
    pending: action.pending,
    fail: action.fail,
    data: {
      ...state.data,
      ...(action.result && Array.isArray(action.result) && action.result.reduce((acc, cur) => ({ ...acc, [cur.Id]: cur }), {}))
    },
    params: action.params,
    error: null,
  }),
  [schemesResourceActions.getMergeableSchemes.failure]: setResourceFailState,
  [schemesResourceActions.getSchemeView.request]: setResourceRequestState,
  [schemesResourceActions.getSchemeView.success]: (state, action) => ({
    pending: action.pending,
    fail: action.fail,
    data: state.data,
    custom: {
      ...state.custom,
      schemeView: action.result,
    },
    params: action.params,
    error: null,
  }),
  [schemesResourceActions.getSchemeView.failure]: setResourceFailState,
  [schemesResourceActions.getMergedSubschemes.request]: setResourceRequestState,
  [schemesResourceActions.getMergedSubschemes.success]: (state, action) => ({
    pending: action.pending,
    fail: action.fail,
    data: state.data,
    custom: {
      ...state.custom,
      mergedSubschemes: action.result,
    },
    params: action.params,
    error: null,
  }),
  [schemesResourceActions.getMergedSubschemes.failure]: setResourceFailState,
  [schemesResourceActions.cloneScheme.request]: setResourceRequestState,
  [schemesResourceActions.cloneScheme.success]: (state, action) => ({
    pending: action.pending,
    fail: action.fail,
    data: state.data,
    custom: {
      ...state.custom,
      clonedScheme: action.result,
    },
    params: action.params,
    error: null,
  }),
  [schemesResourceActions.cloneScheme.failure]: setResourceFailState,
  [schemesResourceActions.getComparingSchemeViews.request]: setResourceRequestState,
  [schemesResourceActions.getComparingSchemeViews.success]: (state, action) => ({
    pending: action.pending,
    fail: action.fail,
    data: state.data,
    custom: state.custom,
    params: action.params,
    error: null,
  }),
  [schemesResourceActions.getComparingSchemeViews.failure]: setResourceFailState,
});