// @flow

import Button from '@biocad/bcd-front-ui/controls/Button';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import CommentWrapper from '../../../../shared/components/CommentWrapper';
import { IconButtonExpand } from '../../../../shared/components/IconButtonExpand';
import { agentResourceActions } from '../../../actions';
import JarStockTable from '../JarStockTable';
import { AgentsTableStyled, StyledLink } from './style';
import type {IAgent} from '../../../models/i-agent';
import { GridTable, GridTableCol } from '../../../../shared/components/GridTable';
import { _noop } from '../../../../shared/utils/common';
import {ReactionPurification} from '../../../../reactions/components/ReactionPurification';
import { Dispatch } from 'redux';
import type { IRootStore } from '../../../../app/reducers';
import {userCRUDPermissionsSelector} from '../../../../account/reducers/users-modules-reducer';
import {attachmentsActions} from '../../../../attachments/actions/attachments';

interface IAgentTableProps {
  handleHeaderClick: (string)=>void,
  listData: IAgent[],
  clearAgentsList: ()=>void,
  editAgentComment: (data: {}) => void,
  reloadAgentList: ()=>void,
  commentUpdateRequestStatus?: boolean,
  userPermissions?: {}
}

const defaultProps: IAgentTableProps = {
    handleHeaderClick: _noop,
    listData: [],
    clearAgentsList: _noop,
    editAgentComment: _noop,
};

let AgentTable = (props: IAgentTableProps = defaultProps) => {

    // State
    const
      [expandedRows, setExpandedRows] = useState([]),
      [commentDialogClose, setCommentDialogClose] = useState(null)
    ;

    // Effects
    const _updateListOnCommentSaved = () => {
      if (props.commentUpdateRequestStatus === null) return;
      if (props.commentUpdateRequestStatus) {
        props.reloadAgentList();
      }
      if (commentDialogClose && typeof commentDialogClose === 'function') {
        commentDialogClose();
        setCommentDialogClose(null);
      }
    };
    useEffect(_updateListOnCommentSaved, [props.commentUpdateRequestStatus]);

    // Methods
    const
      toggleSubTable = (event: Event, rowidx: number) => {
        event.preventDefault();
        event.stopPropagation();
        setExpandedRows((prev: number[]) =>
          prev.includes(rowidx)
            ? prev.filter(idx => idx !== rowidx)
            : prev.concat([rowidx])
        );
      },
      editComment = ({ itemId, comment, close }) => {
        setCommentDialogClose(close);
        props.editAgentComment({
            agentId: itemId,
            quickComment: comment || ''
        });
      },
      downloadMSD = (attachment) => {
        props.downloadAttachment(attachment.Id, attachment.OriginalName);
      }
    ;

    return (
      <AgentsTableStyled
        as={GridTable}
        items={props.listData}
        hover={true}
        rowHeight={'auto'}
        colsTemplate={'75px 215px 1fr 56px repeat(3, minmax(140px, auto)) auto'}
        renderRowAfter={(args: {item: IAgent, rowidx: number}) => (
          expandedRows.includes(args.rowidx) ? <JarStockTable agentId={args.item.Id} /> : ''
        )}
        onRowClicked={(_, rowidx: number, event) => toggleSubTable(event, rowidx)}
        setRowClassNames={(item: IAgent, rowidx: number) => ({
          'is-expanded': expandedRows.includes(rowidx),
          'highlight-error': 'HasJarsWithAmount' in item && !item.HasJarsWithAmount
        })}
      >
          <GridTableCol
            render={(args: {rowidx: number}) => (
              <IconButtonExpand expanded={expandedRows.includes(args.rowidx)}
                                expandHandler={event => toggleSubTable(event, args.rowidx)}
              />
            )}
          />
          <GridTableCol
            title={'Структурная формула'}
            render={(args: {item: IAgent}) => (
              <ReactionPurification
                compound={args.item?.Compound}
                className={'Thumbnail'}
                agentId={args.item?.Id}
              />
            )}
          />
          <GridTableCol
            title={'Наименование'}
            render={(args: {item: IAgent}) => <span>{args.item.Name}</span>}
          />
          <GridTableCol
            title={'MSD'}
            render={(args: {item: IAgent}) => <span>
                {
                  args.item.MSDAttachment &&
                  <Button
                      className={'link-btn fo-download'}
                      view={'icon'}
                      size={'L'}
                      onAction={() => downloadMSD(args.item.MSDAttachment)} />
                }
              </span>}
          />
          <GridTableCol
            title={'Сокращение'}
            render={(args: {item: IAgent}) => <span>{args.item.ShortName}</span>}
          />
          <GridTableCol
            title={'CAS-номер'}
            render={(args: {item: IAgent}) => <span>{args.item.Cas}</span>}
          />
          <GridTableCol
            title={'MW, г/моль'}
            render={(args: {item: IAgent}) => <span>{args.item.MolarWeight}</span>}
          />
          <GridTableCol
            render={(args: {item: IAgent}) => (
              <div className={'button-holder'}>
                  <StyledLink
                    to={`/agents/${args.item.Id}`}
                    className={Button.block.toString() + Button.block.mod('icon') + Button.block.mod('size', 'L')}
                  >
                      <i className={'fo-edit'}/>
                  </StyledLink>
                  {
                      (props.userPermissions.agentMiniComment.READ) &&
                      <CommentWrapper
                          item={args.item}
                          saveComment={editComment}
                          firstStaticField={{
                              title: 'Наименование',
                              value: args.item.Name,
                          }}
                          readonly={!props.userPermissions.agentMiniComment.UPDATE}
                      />
                  }
              </div>
            )}
          />
      </AgentsTableStyled>
    );
};

AgentTable = connect(
  (state: IRootStore): IAgentTableProps => ({
    commentUpdateRequestStatus: state.modules.agent.list.commentUpdateRequestStatus,
    userPermissions : userCRUDPermissionsSelector(state),
  }),
  (dispatch: Dispatch): IAgentTableProps => ({
    clearAgentsList: () => dispatch(agentResourceActions.destroy()),
    editAgentComment: (data) => dispatch(agentResourceActions.editAgentComment.request({data})),
    downloadAttachment: (id, name) => dispatch(attachmentsActions.download.request({ id, name })),
  })
)(AgentTable);

export default AgentTable;
