import React, {useState, useEffect} from 'react';
import {GridTable, GridTableCol} from '../../../shared/components/GridTable';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {getFormValues} from 'redux-form';
import styled from 'styled-components';

import type {IRootStore} from '../../../app/reducers';
import {itemsToArraySelector} from '../../../shared/utils/selectors/resource-selectors';
import {userCRUDPermissionsSelector} from '../../../account/reducers/users-modules-reducer';
import {setGlobalLoader} from '../../../shared/actions';
import {equipmentActions} from '../../actions';
import {EQUIPMENT_SEARCH_FORM_NAME} from '../SearchForm';
import {LayoutBlock} from '../../../shared/components/LayoutBlock';
import {TitleWithCounter} from '../../../shared/components/TitleWithCounter';
import {PaginationStyled} from '../../../shared/components/Paginator/style';
import type {IEquipment} from '../../models';

const defaultProps = {
    equipmentList: [],
    getEquipmentList: () => null,
    clearEquipment:() => null,
    searchFormValue: null,
    startSearch: false,
    pending: false
};

const defaultPagingParams = {
    limit: 20,
    skip: 0
};

const EquipmentTable = (props = defaultProps) => {
    const [pagingParams, setPagingParams] = useState(defaultPagingParams);

    useEffect(() => {
        if(props.listPagination.pageCount === 1) {
            pagingParams.skip = 0;
            setPagingParams({
                ...pagingParams,
                ...(props.currentFolder ? { folderId: props.currentFolder } : null)
            });
        }
    }, [props.listPagination.pageCount]);

    useEffect(() => {
        props.setLoader(props.pending);
    }, [props.pending]);

    useEffect(() => {
        pagingParams.skip = 0;
    }, [props.startSearch]);

    useEffect(() => {
        props.clearEquipment();
        props.getEquipmentList({ ...props.searchFormValue, ...pagingParams});
    }, [props.startSearch, pagingParams]);

    const handlePaginationClick = listParams => {
        setPagingParams({
            ...listParams,
            ...(props.currentFolder ? { folderId: props.currentFolder } : null)
        });
    };

    const renderHeader = ({count = 0}) => (
        <ListHeaderStyled>
            <TitleWithCounter
                title={'Оборудование'}
                count={count}
            />
        </ListHeaderStyled>
    );

    return(
        <LayoutBlock
            header={renderHeader({
                count: props.listPagination.total
            })}
        >
            <EquipmentTableStyled
                items={props.equipmentList}
                hover={true}
                rowHeight={'40px'}
                colsTemplate={'minmax(300px, auto) minmax(200px, auto) minmax(200px, auto) minmax(200px, auto) minmax(200px, auto)'}
                as={GridTable}
            >
                <GridTableCol
                    title={'Название'}
                    render={(args: { item: IEquipment }) => (
                        <span>{args.item.Name}</span>
                    )}
                />
                <GridTableCol
                    title={'Инвентарный номер'}
                    name={'InventoryNumber'}
                    render={(args: { item: IEquipment }) => (
                        <span>{args.item.InventoryNumber}</span>
                    )}
                />
                <GridTableCol
                title={'Серийный номер'}
                name={'SerialNumber'}
                render={(args: { item: IEquipment }) => (
                    <span>{args.item.SerialNumber}</span>
                )}
                />
                <GridTableCol
                    title={'Модель'}
                    name={'Model'}
                    render={(args: { item: IEquipment }) => (
                        <span>{args.item.Model}</span>
                    )}
                />
                <GridTableCol
                    title={'Тип'}
                    name={'EquipmentType.Name'}
                    render={(args: { item: IEquipment }) => (
                        <span>{args.item.EquipmentType.Name}</span>
                    )}
                />
            </EquipmentTableStyled>
            <PaginationStyled
                {...props.listPagination}
                handlePagingChange={handlePaginationClick}
            />
        </LayoutBlock>
    );
};


export const ListHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const EquipmentTableStyled = styled.div`
.title-col {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: inherit;
    &:hover {
      text-decoration: underline;
      color: var(--link-color);
    }
    .typeIcon {
      margin-right: calc(var(--grid-spacer) / 2);
    }
    .additional-name {
      font-size: smaller;
    }
  }  
`;


export default withRouter(
    connect(
        (state: IRootStore) => {
            const {resource, modules} = state;
            return {
                equipmentList: itemsToArraySelector(resource.equipmentList),
                listPagination: modules.equipment.list.pagination,
                searchFormValue: getFormValues(EQUIPMENT_SEARCH_FORM_NAME)(state),
                userPermissions: userCRUDPermissionsSelector(state),
                pending: resource.equipmentList.pending,
                roles: state.global.account.roles
            };
        },
        (dispatch) => ({
            setLoader: (isLoading) => dispatch(setGlobalLoader({isLoading})),
            getEquipmentList: (params) => dispatch(equipmentActions.list.request({params})),
            clearEquipment: () => dispatch(equipmentActions.destroy()),
        })
    )(EquipmentTable)
);
