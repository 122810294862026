// @flow

import {
  ResourceRequestMethod,
  CRUDRequestsMap,
  ResourceService,
  HTTPMethodEnum,
} from '../../shared/utils/service-creator';
import { ObtainmentScheme, ObtainmentSchemeQuery } from '../models';
import { SingleRequestURIParams } from '../../shared/models';
import { ObtainmentStage } from '../../stages/models';
import { Model } from 'objectmodel';

const uniteUriParamsModel = Model({ sourceSchemeId: [Number, String], targetSchemeId: [Number, String] });

export const SchemeResourceService = ResourceService({
  baseURL: '/schemes',
  defaultModel: ObtainmentScheme,
  defaultQuery: ObtainmentSchemeQuery,
  useDefaultCRUDRequests: [
    CRUDRequestsMap.get,
    CRUDRequestsMap.list,
    CRUDRequestsMap.update,
    CRUDRequestsMap.delete,
    CRUDRequestsMap.create,
  ],
  requests: {
    getMergeableSchemes: {
      url: '{id}/mergeable_schemes',
      method: HTTPMethodEnum.GET,
      model: ObtainmentScheme,
      uriParamsModel: SingleRequestURIParams,
    },
    getMergedSubschemes: {
      url: '{id}/merged_subschemes',
      method: HTTPMethodEnum.GET,
      model: ObtainmentScheme,
      uriParamsModel: SingleRequestURIParams,
    },
    getLastStage: {
      url: '{id}/last_stage',
      method: HTTPMethodEnum.GET,
      model: ObtainmentStage,
      uriParamsModel: SingleRequestURIParams,
    },
    update: {
      url: '/',
      method: HTTPMethodEnum.PUT,
      model: ObtainmentStage,
    },
    getSchemeView: {
      url: '{id}/view',
      method: HTTPMethodEnum.GET,
      model: ObtainmentStage,
      uriParamsModel: SingleRequestURIParams,
    },
    unite: {
      url: 'unite/source/{sourceSchemeId}/target/{targetSchemeId}',
      method: HTTPMethodEnum.POST,
      model: ObtainmentStage,
      uriParamsModel: uniteUriParamsModel,
    },
    clone: {
      url: '{schemeId}/final_candidates/{finalCandidateId}/clone',
      method: HTTPMethodEnum.POST,
      model: ObtainmentStage,
    },
    getPrecalcXlsx: {
      url: '{id}/precalc_xlsx',
      method: HTTPMethodEnum.POST,
      uriParamsModel: SingleRequestURIParams,
      responseType: 'arraybuffer',
      dataModel: Model({ Image: String }),
    },
  }
});
