import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import css from './ckeck-group.m.less';

export const CheckGroup = ({ value = [], options = {}, onChange, className}) => {
  
  const [selectedItems, setSelectedItems] = useState(value);
  const [_options, set_options] = useState([]);

  useEffect(() => {
    typeof options?.[0] === 'object'
     ? set_options(options)
     : set_options(options.map(x => ( {label: x, value: x} )));
    

  }, [options]);

  const onItemClick = value => {
    const selectedList = selectedItems.includes(value)
      ? selectedItems.filter(y => y !== value)
      : [...selectedItems, value];

    setSelectedItems(selectedList);
    onChange(selectedList);
  };

  return (
    <div className={classNames(css.group, className)}>
      {_options.map((item, index) => (
        <CheckGroupItem
          key={index}
          label={item?.label}
          selected={selectedItems.includes(item.value)}
          onClick={() => onItemClick(item.value)}
        />
      ))}
    </div>
  );
};

const CheckGroupItem = ({ label, selected, onClick }) => {
  const stopPropagation = e => {
    e.stopPropagation();
    onClick();
  };
  return (
    <>
      <span
        onClick={e => stopPropagation(e)}
        className={classNames(
          css.item,
          selected ? css.item__selected : null
        )}
      >
        {label}
      </span>
    </>
  );
};