import {
  apiRequestList,
  apiRequestSingle
} from '../../shared/services/api/common';

/**
 * @typedef {Object} AgentSearchRequest
 * @extends {PaginatedSearchModel}
 * @property {string} search
 * @property {'ByName' | 'ByStructure' | 'ByCas'} searchType
 * @property {boolean} isStrictSearch
 * @property {boolean} isInStockOnly
 * @property {number[]} excludedAgentIds
 */
export const AGENTS_ROOT = '/agents';
export class AgentsService {
  constructor(token) {
    const headers = token ? { Authorization: token } : {};

    this._search = apiRequestList('GET', `${AGENTS_ROOT}`, headers);
    this._getAgentsBySmiles = apiRequestList('GET', `${AGENTS_ROOT}/bySmiles`, headers);
    this._getSingle = agentId => apiRequestSingle('GET', `${AGENTS_ROOT}/${agentId}`, headers);
    this._getAgentAliases = agentId => apiRequestSingle('GET', `${AGENTS_ROOT}/${agentId}/aliases`, headers);
    this._create = apiRequestSingle('POST', `${AGENTS_ROOT}`, headers);
    this._update = apiRequestSingle('PUT', `${AGENTS_ROOT}`, headers);
    this._delete = agentId => apiRequestSingle('DELETE', `${AGENTS_ROOT}/${agentId}`, headers);
    this._addAliases = apiRequestSingle('POST', '/aliases/batch', headers);
    this._deleteAliases = apiRequestSingle('DELETE', '/aliases/batch', headers);
    this._editComment = agentId => apiRequestList('PUT', `${AGENTS_ROOT}/${agentId}/quick_comment`, headers);
    this._getMWLimits = apiRequestSingle('GET', `${AGENTS_ROOT}/mw_limits`, headers);
  }

  /**
   * @param {AgentSearchRequest} searchParams
   */
  search({ data, params }) {
    if(params.search) {
      params.search = encodeURIComponent(params.search);
    }
    if(params.tags) {
      params.tags = params.tags.join(';');
    }
    return this._search({ data, params });
  }
  
  getAgentsBySmiles({ params }) {
    params.smilesFormulas = params.smilesFormulas.map(i => i ? encodeURIComponent(i) : null);
    return this._getAgentsBySmiles({ params });
  }

  getSingle(agentId, params) {
    return this._getSingle(agentId)(params);
  }

  getAgentAliases(agentId, params) {
    return this._getAgentAliases(agentId)(params);
  }

  create(data) {
    return this._create({}, data);
  }

  update(data) {
    return this._update({}, data);
  }

  addAliases(data) {
    return data && this._addAliases({}, data);
  }

  deleteAliases(data) {
    return data && this._deleteAliases({}, data);
  }

  delete(agentId, params) {
    return this._delete(agentId)(params);
  }

  editComment(agentId, data) {
    return this._editComment(agentId)({ data });
  }

  getMWLimits() {
    return this._getMWLimits();
  }
}
