// @flow

import React, {useState, useEffect, useRef} from 'react';
import {BcdTooltipWrapper} from '../BcdTooltipWrapper';
import {IconButton} from '../IconButton';
import './style.less';
import Dialog from '@biocad/bcd-front-ui/layout/Dialog';
import {StyledButton} from '../ConfirmDialog/ConfirmDialog';

export type CommentWrapperOnSaveReturnObject = {
    itemId: number,
    comment: string,
    close: ()=>void,
};

interface ICommentWrapperProps {
    item: Object, // должен содержать поле QuickComment
    saveComment: (CommentWrapperOnSaveReturnObject)=>void,
    readonly?: boolean,
    firstStaticField?: {
        title: String,
        value: String
    },
    preventCloseOnSave?: boolean,
    iconClassName?: string,
}

const initialProps: ICommentWrapperProps = {
    item: null,
    saveComment: () => null,
    readonly: false,
    firstStaticField: null,
    preventCloseOnSave: false,
    iconClassName: '',
};

const CommentWrapper = (props: ICommentWrapperProps = initialProps) => {
    // refs
    const
        iconButtonRef = useRef(null),
        tooltipRef    = useRef(null)
    ;

    const
        [isModalShow, setIsModalShow] = useState(false),
        [isTooltipShow, setIsTooltipShow] = useState(false),
        [commentValue, setCommentValue] = useState('')
    ;

    useEffect(() => {
        if(props.item?.QuickComment && isModalShow) {
            setCommentValue(props.item?.QuickComment);
        } else {
            setCommentValue('');
        }

    }, [isModalShow]);

    useEffect(() => {
        setCommentValue(props.item?.QuickComment);
    }, []);

    const toggleModal = (event) => {
        setIsModalShow(!isModalShow);
    };

    const onChangeCommentValue = (e) => {
        setCommentValue(e.target.value);
    };

    const onSaveComment = () => {
        props.saveComment({
            itemId: props.item?.Id,
            comment: commentValue,
            close: toggleModal,
        });
        if (!props.preventCloseOnSave) {
            toggleModal();
        }
    };

    const toggleTooltip = (event) => {
        event.stopPropagation();
        setIsTooltipShow(prev => !prev);
    };

    return (
        <span style={{ display: 'contents' }} onClick={(event) => event.stopPropagation()}>
            <IconButton iconClassName={`fo-comments ${props.iconClassName}`}
                        active={!!props.item.QuickComment}
                        disabled={false}
                        onClick={toggleTooltip}
                        ref={iconButtonRef}
            >
                {
                    !isModalShow &&
                    <BcdTooltipWrapper
                        ref={tooltipRef}
                        align={'right'}
                        header='Комментарий'
                        open={isTooltipShow}
                        body={props.item?.QuickComment}
                        type='click'
                        saveComment={props.saveComment}
                        className={props.readonly && 'readonly'}
                        onEditClickAction={toggleModal}
                    />
                }
            </IconButton>

            {isModalShow &&
            <Dialog paranjaClose={false} close={toggleModal}>
                <h3>Комментарий</h3>
                <hr/>

                {props.firstStaticField &&
                    <label className={'labelToTop'}>
                        {props.firstStaticField.title}
                        <div className={'info'}>
                            {props.firstStaticField.value}
                        </div>
                    </label>
                }

                <label className={'labelToTop'}>Комментарий
                    <textarea
                        className={'commentArea'}
                        placeholder={'Комментарий'}
                        value={commentValue || ''}
                        onChange={onChangeCommentValue}/>
                </label>

                <StyledButton
                    size="L"
                    text={'Сохранить'}
                    onAction={onSaveComment}
                    view="primary"
                />

                <StyledButton
                    size="L"
                    text='Отмена'
                    onAction={toggleModal}
                />
            </Dialog>
            }
        </span>
    );
};

export default CommentWrapper;
