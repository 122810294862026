// @flow

import {
  resourceActions,
  CRUDStatusActions
} from '../../shared/utils/action-creators';

export const ARCHIVED_REAGENTS_MODULE_NAME = 'ARCHIVED_REAGENTS';

export const archivedReagentsResourceActions = {
  ...resourceActions(ARCHIVED_REAGENTS_MODULE_NAME),
  editReagentComment: CRUDStatusActions(ARCHIVED_REAGENTS_MODULE_NAME, 'EDIT_ARCHIVED_REAGENT_COMMENT')
};
