// @flow

export interface ICompound {
  Id: number;
  Formula?: string;
  MolarWeight?: number,
  MolecularFormula?: string,
  Name?: string,
  SmilesFormula?: string,
  Thumbnail?: string,
  FirstOrderItem?: boolean,
  IsPolymer?: boolean,
  IsAntibody?: boolean,
}

export interface IAgent {
  Id: number;
  ShortName: string;
  PhysicalState: number;
  Cas: string;
  Comment: string;
  MolarWeight: number;
  Name: string;
  Aliases: string[];
  HasJarsWithAmount: boolean;
  CreationDate: string;
  IsIgnoringSpending: boolean;
  Formula: string;
  Thumbnail: string;
  SmilesFormula: string;
  MolecularFormula: string;
  Compound?: ICompound;
  CompoundId?: number;
  OriginalSmilesFormula?: string;
  AminoAcidSequence?: string;
};

export const AgentKeysEnum = Object.freeze({
  Id: 'Id',
  ShortName: 'ShortName',
  Cas: 'Cas',
  Comment: 'Comment',
  MolarWeight: 'MolarWeight',
  Name: 'Name',
  Aliases: 'Aliases',
  HasJarsWithAmount: 'HasJarsWithAmount',
  CreationDate: 'CreationDate',
  IsIgnoringSpending: 'IsIgnoringSpending',
  Formula: 'Formula',
  Thumbnail: 'Thumbnail',
  SmilesFormula: 'SmilesFormula',
  MolecularFormula: 'MolecularFormula',
  Compound: 'Compound',
  AminoAcidSequence: 'AminoAcidSequence',
});

export const AgentTitlesEnum = Object.freeze({
  Id: 'Id',
  ShortName: 'Сокращенное обозначение',
  Cas: 'CAS-номер',
  Comment: 'Комментарий',
  MolarWeight: 'MW, г/моль',
  Name: 'Название',
  Aliases: 'Доп. наименование',
  HasJarsWithAmount: 'HasJarsWithAmount',
  CreationDate: 'Дата создания',
  IsIgnoringSpending: 'Неснижаемый остаток',
  Formula: 'Формула',
  Thumbnail: 'Изображение',
  SmilesFormula: 'Смайлс-формула',
  MolecularFormula: 'Молекулярная формула',
  Compound: 'Компаунд',
  AminoAcidSequence: 'Аминокислотная последовательность',
});
