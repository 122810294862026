// @flow

import { Model } from 'objectmodel';
import { PaginationParams } from '../../shared/models';
import { FolderParentTypeModel } from './folder-parent-type-model';

export const FolderListParams = Model({
  parentId: [Number, null],
  parentType: FolderParentTypeModel,
});
// .extend(PaginationParams);
