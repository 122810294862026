// @flow

import { createSelector } from 'reselect';
import { resourceReducer } from '../../shared/utils/reducer-creators';
import { stageIndicatorResourceActions } from '../actions';
import type { IRootStore } from '../../app/reducers';
import {getIndicatorProcessPoints} from '../../indicators/reducers';
import type {IIndicatorProcessPoint} from '../../indicators/models';

export const stageIndicatorResourceReducer = resourceReducer(
    stageIndicatorResourceActions,
);

export const
    getAllStageIndicators = createSelector(
        (state: IRootStore) => state.resource.stageIndicators.data,
        (data) => Object.values(data)
    ),
    getStageIndicatorsProcessPoints = createSelector(
        [getIndicatorProcessPoints, (state: IRootStore) => state.modules.stage.editStage?.current?.Id],
        (processPoints: IIndicatorProcessPoint[] = [], stageId: number) =>
        (
            processPoints
                .filter((p: IIndicatorProcessPoint) => p.IsCommon || p.StageId === stageId)
                .sort((a: IIndicatorProcessPoint, b: IIndicatorProcessPoint) => {
                    const
                        nameA = a.Name.toUpperCase(),
                        nameB = b.Name.toUpperCase()
                    ;
                    if (nameA < nameB) return -1;
                    if (nameB < nameA) return 1;
                    return 0;
                })
        )
    )
;
