// @flow

import React, {useEffect, useState, useRef} from 'react';
import type { Children, Element, ChildrenArray } from 'react';
import { GridTableCol } from './GridTableCol';
import { Field, FieldArray } from 'redux-form';
import styled from 'styled-components';
import { _noop } from '../../utils/common';
import type {
  IFieldArrayFieldsProps,
  IFieldArrayMetaProps,
  IFieldArrayProps,
  IFieldProps,
} from '../../models';
import classNames from 'classnames';

// Interfaces
interface IGridTableProps extends IFieldArrayProps {
  children?: Children,
  hover?: boolean,
  items?: any[],
  colsTemplate?: string,
  rowHeight?: string,
  headingHeight?: string,
  className?: string,
  style?: {},
  fieldArrayName?: string,
  excludeRows?: (item: {}, rowidx: number) => boolean,
  onRowClicked?: (item: {}, rowidx: number, event: SyntheticEvent, renderIdx: number) => void,
  onRowMouseEnter?: (item: {}, rowidx: number, event: SyntheticEvent, renderIdx: number)=>void,
  onRowMouseLeave?: (item: {}, rowidx: number, event: SyntheticEvent, renderIdx: number)=>void,
  setRowClassNames?: (item: {}, rowidx: number, renderIdx: number) => {},
  renderRowAfter?: (props: {item: {}, rowidx: number, renderIdx: number, allItems: Array<{}>}) => React$ComponentType,
  renderRowBefore?: (props: {item: {}, rowidx: number, renderIdx: number, allItems: Array<{}>}) => React$ComponentType,
  stickyHeading?: boolean,
}

type renderColProps = {
  child: React$ElementType,
  item: {},
  col_idx: number,
  row_idx: number,
  renderIdx: number,
  field: string,
  allItems: Array<{}>
}

type renderRowProps = {
  item: {},
  row_idx: number,
  renderIdx: number,
  field: string,
  cols: React$ElementType[],
  hover: boolean,
  excludeRows: (item: {}, rowidx: number)=>void,
  onRowClicked: (item: {}, rowidx: number, event: SyntheticEvent)=>void, selectedRow: number,
  setSelectedRow: (number)=>void,
  setRowClassNames: (item: {}, rowidx: number) => {},
  renderRowAfter?: (props: {item: {}, rowidx: number}) => React$ComponentType,
  renderRowBefore?: (props: {item: {}, rowidx: number}) => React$ComponentType,
  items: Array<{}>,
}

type renderTableProps = {
  fields: IFieldArrayFieldsProps,
  meta: IFieldArrayMetaProps,
  cols: React$ElementType[],
  items: any,
}


const StyledGridTable = styled('div')`
  grid-template-columns: [cols-start] ${props => props.colsTemplate || `repeat(${props.colsLength}, 1fr)`} [cols-end];
  grid-template-rows: [header-start] var(--table-row-head-height) [header-end];
  grid-auto-rows: var(--table-row-height);
  --table-row-height: ${props => props.rowHeight || null};
  --table-row-head-height: ${props => props.headingHeight || null};
`;

const filterCols = (child: Element) => child.type === GridTableCol;
const filterSubTables = (child: Element) => child.type === GridSubTable;

export const GridTableCellComponent = ({ item, rowidx, colidx, field, renderIdx, render, ...rest }) => (
  render({ item, rowidx, colidx, field, renderIdx, ...rest })
);

const GridTableRowAfter = ({ item, rowidx, renderIdx, render, allItems }) => (
  <div className={'grid-table-row-after'}>
    { render({ item, rowidx, renderIdx, allItems }) }
  </div>
);

const GridTableRowBefore = ({ item, rowidx, renderIdx, render, allItems }) => (
  <div className={'grid-table-row-before'}>
    { render({ item, rowidx, renderIdx, allItems }) }
  </div>
);

function GridTableCell ({child, item, col_idx, row_idx, renderIdx, field, allItems}: renderColProps) {

  // Field props from child
  const
    { format, normalize, onDragStart, onDrop, parse, validate, warn, immutableProps }: IFieldProps = child.props,
    childPropsPassedToField = Object.entries({
        format, normalize,
        onDragStart, onDrop, parse, validate,
        warn, immutableProps
      })
      .filter(([_, value]) => value !== undefined)
      .reduce((acc, [key, value]) => ({...acc, [key]: value}), {})
  ;

  if (childPropsPassedToField.validate && typeof childPropsPassedToField.validate === 'function') {
    childPropsPassedToField.validate = childPropsPassedToField.validate(item, row_idx, col_idx, renderIdx, allItems);
  }

  if (childPropsPassedToField.warn && typeof childPropsPassedToField.warn === 'function') {
    childPropsPassedToField.warn = childPropsPassedToField.warn(item, row_idx, col_idx, renderIdx, allItems)
  }

  let fieldName: string;
  if (child.props.fieldName) {
    if (typeof child.props.fieldName === 'function') {
      fieldName = child.props.fieldName(item, row_idx, col_idx, renderIdx, allItems);
    }
    else {
      fieldName = child.props.fieldName;
    }
  }

  return (
    (child.props.fieldName && field)
      ? <div className={`grid-table-row align-${child.props.align || 'left'}`}
             key={`${col_idx}_${field ? field : 'Id' in item ? item.Id : row_idx}`}>
          <Field
            name={`${field}.${fieldName}`}
            component={GridTableCellComponent}
            props={{ item, renderIdx, colidx: col_idx, rowidx: row_idx, render: child.props.render, allItems }}
            { ...childPropsPassedToField }
            onChange={(event, newValue, previousValue, name) => {
              child.props.onChange && child.props.onChange(event, newValue, previousValue, name, item, row_idx, col_idx);
            }}
            onBlur={(event, newValue, previousValue, name) => {
              child.props.onBlur && child.props.onBlur(event, newValue, previousValue, name, item, row_idx, col_idx);
            }}
            onFocus={(event, name) => {
              child.props.onFocus && child.props.onFocus(event, name, item, row_idx, col_idx);
            }}
          />
        </div>
      : <div className={`grid-table-row align-${child.props.align || 'left'}`}
             key={`${col_idx}_${row_idx}`}>
          <GridTableCellComponent render={child.props.render} {...{item, renderIdx, colidx: col_idx, rowidx: row_idx, allItems}} />
        </div>
  )
}

function GridTableRow ({
                      item,
                      row_idx,
                      field,
                      cols,
                      hover,
                      onRowClicked=_noop,
                      onRowMouseEnter=_noop,
                      onRowMouseLeave=_noop,
                      setRowClassNames,
                      renderRowAfter,
                      renderRowBefore,
                      renderIdx,
                      items,
}: renderRowProps) {
  const rowClassNames = classNames('grid-table-row-wrap', {
    ...(setRowClassNames && typeof setRowClassNames === 'function' ? setRowClassNames(item, row_idx, renderIdx) : null),
    'no-hover': !hover,
  });

  // Handlers
  const
    onRowClick = (event) => {
      if (!onRowClicked || onRowClicked === _noop) return;
      onRowClicked(item, row_idx, event, renderIdx);
    }
  ;

  return (
    <div className={rowClassNames}
         key={`${field || item['Id'] || item['id'] || row_idx}`}
         onClick={onRowClick}
         onMouseEnter={(event) =>
           (!onRowMouseEnter || onRowMouseEnter === _noop)
              ? null
              : onRowMouseEnter(item, row_idx, event, renderIdx)
         }
         onMouseLeave={(event) =>
           (!onRowMouseLeave || onRowMouseLeave === _noop)
             ? null
             : onRowMouseLeave(item, row_idx, event, renderIdx)
         }
    >
      {
        (renderRowBefore && typeof renderRowBefore === 'function')
          ? <GridTableRowBefore render={renderRowBefore} {...{item, renderIdx, rowidx: row_idx, allItems: items}} />
          : ''
      }
      {
        cols.map((child: Element, col_idx) =>
          <GridTableCell key={`${'Id' in item && item.Id}__${row_idx}__${col_idx}`}
                         {...{child, item, col_idx, row_idx, field, renderIdx, allItems: items}}
          />
        )
      }
      {
        (renderRowAfter && typeof renderRowAfter === 'function')
          ? <GridTableRowAfter render={renderRowAfter} {...{item, renderIdx, rowidx: row_idx, allItems: items}} />
          : ''
      }
    </div>
  );
}

function renderTable ({ fields, meta, cols = [], items, ...rest }: renderTableProps) {
  // row props
  const
    { hover, excludeRows, onRowClicked, setRowClassNames, renderRowAfter,
      renderRowBefore, onRowMouseEnter, onRowMouseLeave }: renderRowProps = rest,
    rowProps: renderRowProps = { hover, excludeRows, onRowClicked, setRowClassNames,
      renderRowAfter, renderRowBefore, onRowMouseEnter, onRowMouseLeave, items }
  ;

  let renderIdx = 0; // иногда необходимо оставлять ряды в том же списке, но при этом не рендерить их, это индекс реальной отрисовки

  return(
    <StyledGridTable className={classNames(`grid-table ${rest.className || ''}`, { 'sticky-heading': rest.stickyHeading })}
                     colsLength={cols.length}
                     colsTemplate={rest.colsTemplate}
                     rowHeight={rest.rowHeight}
                     headingHeight={rest.headingHeight}
                     style={rest.style}
    >
      {
        cols.map((child: Element, idx) => (
          <div className={`grid-table-row-heading bordered align-${child.props.align || 'left'}`} key={idx}>{
            (typeof child.props.title === 'string')
              ? child.props.title
              : (typeof child.props.title === 'function')
                ? <GridTableCellComponent render={child.props.title} />
                : ''
          }</div>
        ))
      }
      {
        fields
          ? fields.map((field, row_idx, fields) => {
              const item = fields.get(row_idx);
              if (excludeRows && typeof excludeRows === 'function' && excludeRows(item, row_idx)) {
                return;
              }
              renderIdx += 1;
              return (
                <GridTableRow key={`${field}__${row_idx}`} {...{ row_idx, field, item, cols, renderIdx, ...rowProps }} />
              );
          })
          : items.map((item, row_idx) => {
              if (excludeRows && typeof excludeRows === 'function' && excludeRows(item, row_idx)) {
                return;
              }
              renderIdx += 1;
              return (
                <GridTableRow key={`${'Id' in item && item.Id}__${row_idx}`} {...{ row_idx, item, cols, ...rowProps }} />
              );
          })
      }
    </StyledGridTable>
  );
}

function GridSubTable (props)
{
    const { render, items, ...rest } = props;
    return (
        <>
            <div className={'grid-table-row-heading bordered'}>
                <div className={'grid-table-row grid-sub-table-row'}>
                    <GridTableCellComponent render={props.title} />
                </div>
            </div>
            { render({items, ...rest}) }
        </>
    );
}

// Exported component
const defaultProps: IGridTableProps = {
  children: <GridTableCol title={''} render={_noop} />,
  hover: false,
  items: [],
  className: '',
  stickyHeading: false,
};

function GridTable (props: IGridTableProps = defaultProps) {

  // States
  const
    [cols, setCols] = useState([]),
    [subTables, setSubTables] = useState([])
  ;

  // Field array props
  const
    { validate, warn, forwardRef, rerenderOnEveryChange }: IFieldArrayProps = props,
    fieldArrayProps = { validate, warn, forwardRef, rerenderOnEveryChange }
  ;

  // Table props
  const
    { className, style, hover, items, colsTemplate, rowHeight,
      onRowClicked, setRowClassNames, excludeRows, renderRowAfter, renderRowBefore,
      onRowMouseEnter, onRowMouseLeave, stickyHeading } = props,
    tableProps = { className, style, hover, items,
                   colsTemplate, rowHeight, onRowClicked,
                   onRowMouseEnter, onRowMouseLeave,
                   setRowClassNames, excludeRows,
                   renderRowAfter, renderRowBefore, stickyHeading }
  ;

  // Effects
  const _onChildrenUpdate = () => {
    setCols(
        React.Children
            .toArray(props.children)
            .filter(filterCols)
    );
    setSubTables(
        React.Children
            .toArray(props.children)
            .filter(filterSubTables)
    );
  };
  useEffect(_onChildrenUpdate, [props.children]);

  return props.fieldArrayName
    ? <FieldArray
        name={props.fieldArrayName}
        component={renderTable}
        cols={cols}
        subTables={subTables}
        {...tableProps}
        {...fieldArrayProps}
      />
    : renderTable({ cols, subTables, ...tableProps });
}

GridTable = React.memo(GridTable);

export {
  GridTable,
  GridTableCol,
  GridSubTable,
};
