import {
  put,
  takeEvery,
  fork,
  select,
  delay,
  spawn,
} from 'redux-saga/effects';
import { ADAccountService, OidcAuthService } from '../services';
import { accountActions } from '../actions';
import { getToken, getAuthType } from '../reducers';
import { setGlobalLoader } from '../../shared/actions';
import { authTypes } from '../models';
import { readRoles } from '../utils';
import { environment } from '../../environment';
import { push } from 'connected-react-router';

function* loginUser({ username, password }) {
  const service = new ADAccountService();

  try {
    const { result } = yield service.login(username, password);
    const { Token } = result;
    if (Token) {
      window.sessionStorage.setItem('auth-token', Token);
    }

    yield put(accountActions.login.success({ result: { ...result, AuthType: authTypes.AD } }));
  } catch (error) {
    yield put(accountActions.login.failure({ error }));
  }
}

function* loginOnStart({ url }) {
  yield put(setGlobalLoader({ isLoading: true }));

  window.sessionStorage.clear();

  const service = new OidcAuthService();

  try {
    const user = yield service.endLogin(url);

    const profile = user.profile;
    const account = {
      Name: profile.name,
      FirstName: profile.given_name,
      Surname: profile.family_name,
      Email: profile.email,
      DisplayName: profile.name,
      UserName: profile.preferred_username,
    };

    const roles = readRoles(user.access_token);

    yield put(accountActions.login.success({ result: { Account: account, Roles: roles, Token: user.access_token, AuthType: authTypes.OIDC } }));
  } catch (error) {
    if (error.toString().includes('network error')) {
      return;
    }
  }

  yield put(setGlobalLoader({ isLoading: false }));

  yield spawn(renewToken, { onStart: true });
}

function* renewToken({ onStart }) {
  if (onStart)
    yield put(setGlobalLoader({ isLoading: true }));

  const service = new OidcAuthService();
  try {
    const user = yield service.renewToken();

    const profile = user.profile;

    const account = {
      Name: profile.name,
      FirstName: profile.given_name,
      Surname: profile.family_name,
      Email: profile.email,
      DisplayName: profile.name,
      UserName: profile.preferred_username,
    };

    const roles = readRoles(user.access_token);

    yield put(accountActions.login.success({ result: { Account: account, Roles: roles, Token: user.access_token, AuthType: authTypes.OIDC } }));
  } catch (error) {
    yield service.login();
  }

  if (onStart)
    yield put(setGlobalLoader({ isLoading: false }));

  yield delay(5 * 1000 * 60); // 5 minutes

  yield spawn(renewToken, { onStart: false });
}

function* logoutUser() {
  const type = yield select(getAuthType);

  if (type === authTypes.OIDC) {
    const service = new OidcAuthService();
    yield service.logout();
  } else {

    const token = yield select(getToken);
    const service = new ADAccountService(token);

    yield put(setGlobalLoader({ isLoading: true }));

    window.sessionStorage.removeItem('auth-token');

    try {
      const { result } = yield service.logout();
      yield put(accountActions.logout.success({ result }));
    } catch (error) {
      yield put(accountActions.logout.failure({ error }));
    }

    yield put(setGlobalLoader({ isLoading: false }));
  }
}

export function* getAccount() {
  const token = yield select(getToken);
  const service = new ADAccountService(token);

  try {
    const data = yield service.getData();
    const roles = yield service.getRoles();

    yield put(accountActions.get.success({ result: { data: data ?.result, roles: roles ?.result } }));
  } catch (error) {
    yield put(accountActions.get.failure({ error: error }));
    if (error?.response?.status === 401) {
      localStorage.removeItem('localLoginToken');
      yield put(accountActions.setToken({ token: null }));
      yield put(push('/login'));
    }
  }
}

export const accountWatchers = [
  function* watchLoginUser() {
    yield takeEvery(accountActions.login.request.type, function* (action) {
      const { data } = action;
      yield fork(loginUser, data);
    });
  },
  function* watchLogoutUser() {
    yield takeEvery(accountActions.logout.request.type, function* () {
      yield fork(logoutUser);
    });
  },
  function* watchGetAccount() {
    yield takeEvery(accountActions.get.request.type, function* () {
      yield fork(getAccount, {});
    });
  },
  function* watchLoginOnStart() {
    if(environment.useLocalLogin) {
      return;
    }

    yield takeEvery(accountActions.loginOnStart.request.type, function* ({ data }) {
      yield fork(loginOnStart, { url: data });
    });
  },
];
