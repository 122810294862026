// @flow

export const JarStockKeysEnum = Object.freeze({
  Id: 'Id',
  Attachments: 'Attachments',
  Provider: 'Provider',
  Qualification: 'Qualification',
  MainAgentContent: 'MainAgentContent',
  AgentId: 'AgentId',
  Amount: 'Amount',
  Concentration: 'Concentration',
  Cost: 'Cost',
  Density: 'Density',
  IsInWork: 'IsInWork',
  LaboratoryId: 'LaboratoryId',
  Location: 'Location',
  IsDeleted: 'IsDeleted',
  ProviderReactionId: 'ProviderReactionId',
  ProviderStageId: 'ProviderStageId',
  Number: 'Number',
  PackageWeight: 'PackageWeight',
  JarUsers: 'JarUsers',
  CreationDate: 'CreationDate',
  CanBeDeleted: 'CanBeDeleted',
  IsIgnoringSpending: 'IsIgnoringSpending',
  ExpirationDate: 'ExpirationDate',
  Series: 'Series',
  IsOrdered: 'IsOrdered',
});
