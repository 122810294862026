// @flow
import React, {
    useState,
    useEffect
} from 'react';
import {change, Field} from 'redux-form';
import styled from 'styled-components';
import type {IReaction, IReactionCompletionYield} from '../../models';
import {connect} from 'react-redux';
import type {IRootStore} from '../../../app/reducers';
import {Dispatch} from 'redux';
import type {IProduct} from '../../../products/model';
import {getCurrentReactionMainProduct, getReactionByCurrentId} from '../../reducers';
import {ProductNameAliasesInput} from '../../../products/components/ProductNameAliasesInput';
import {ProductKeyNames} from '../../../products/model';
import {
    ReactionCompletionKeyNames,
    ReactionCompletionYieldKeyNames,
    ReactionKeyNames,
} from '../../models';
import classNames from 'classnames';
import {labsLoaderHOC} from '../../../dictionary/components/labsLoaderHOC';
import type {ILabsLoaderWrappedComponentProps} from '../../../dictionary/components/labsLoaderHOC';
import {GridTable, GridTableCol} from '../../../shared/components/GridTable';
import {BcdCheckboxWrapper, InlineEdit, inputTypesEnum, FormFieldLayout, BcdDropdownWrapper, BcdInputWrapper} from '../../../shared/components';
import CommentWrapper from '../../../shared/components/CommentWrapper';
import {uuidv4} from '../../../shared/utils/common';
import {FieldValidators} from '../../../shared/validators/field-validators';
import {COMPLETE_REACTION_FORM_NAME} from './index';
import {ProductFieldValidators} from '../../../products/components/ProductFormFields/field-validators';

import type {OptionType} from '../../../shared/models';


interface IMainProductSubFormProps extends ILabsLoaderWrappedComponentProps {
    reaction?: IReaction,
    className?: string,
    mainProduct?: IProduct,
    recalcMainProductYield?: (value: number)=>void
}

const defaultProps: IMainProductSubFormProps = {
    className: ''
};

const reactionYieldValidators = {
    [ReactionCompletionYieldKeyNames.Amount]: [
        FieldValidators.required,
        FieldValidators.minNum(0),
        FieldValidators.stepNum(0.001)
    ],
    [ReactionCompletionYieldKeyNames.MainAgentContent]: [
        FieldValidators.required,
        FieldValidators.minNum(0),
        FieldValidators.stepNum(0.001)
    ],
    [ReactionCompletionYieldKeyNames.LaboratoryId]: [
        FieldValidators.required,
    ],
    [ReactionCompletionYieldKeyNames.Location]: [
        FieldValidators.required,
    ],
};

const MAIN_PRODUCT_FIELDS = 'mainProductFields';

let MainProductSubForm = (props: IMainProductSubFormProps = defaultProps) => {

    // state
    const
        [amountValidators, setAmountValidators] = useState([]),
        [mainAgentContentValidators, setMainAgentContentValidators] = useState([])
    ;

    // effects
    const _setAmountValidators = () => {
        if (props.reaction?.HasMultipleYields) {
            setAmountValidators(
                props[ReactionCompletionKeyNames.Yields].input.value.map((item: IReactionCompletionYield) =>
                    item.OriginalYieldAmount !== null
                        ? [...reactionYieldValidators[ReactionCompletionYieldKeyNames.Amount], FieldValidators.maxNum(item.OriginalYieldAmount)]
                        : reactionYieldValidators[ReactionCompletionYieldKeyNames.Amount]
                )
            )
        }
    };
    useEffect(_setAmountValidators, [props[ReactionCompletionKeyNames.Yields]?.input?.value, props.reaction?.HasMultipleYields]);

    const _setMainAgentContentValidators = () => {
        if (props.reaction?.HasMultipleYields) {
            setMainAgentContentValidators(
                props.mainProduct?.ExperimentalMainAgentContent !== null
                    ? [...reactionYieldValidators[ReactionCompletionYieldKeyNames.MainAgentContent], FieldValidators.maxNum(props.mainProduct?.ExperimentalMainAgentContent)]
                    : reactionYieldValidators[ReactionCompletionYieldKeyNames.MainAgentContent]
            )
        }
    };
    useEffect(_setMainAgentContentValidators, [props.reaction?.HasMultipleYields, props.mainProduct?.ExperimentalMainAgentContent]);

    // handlers
    const
        recalcYield = () => {
            const amount: ?number = props[ReactionCompletionKeyNames.Yields]?.input?.value.reduce((acc: number, cur: IReactionCompletionYield) => (acc + parseFloat(cur.Amount)), 0);
            if (isNaN(parseFloat(amount))) return;
            props.recalcMainProductYield(amount);
        }
    ;

    return (
        <div className={props.className}>
            <div className={'thumbnail-wrap'}>
                <img className={'thumbnail'} src={props.mainProduct?.Compound?.Thumbnail} alt=""/>
            </div>
            <div className={'fields-wrap'}>
                <FormFieldLayout className={'product-name'}>
                    {props.mainProduct?.Name}
                </FormFieldLayout>
                <div
                    className={classNames('product-fields-layout', {'reaction-has-multiple-yields': props.reaction?.HasMultipleYields})}>
                    <Field
                        name={`${MAIN_PRODUCT_FIELDS}.${ProductKeyNames.NameAliases}`}
                        component={ProductNameAliasesInput}
                        className={'product-name-aliases'}
                    />
                    <Field
                        label={'Сокращенное обозначение'}
                        name={`${MAIN_PRODUCT_FIELDS}.${ProductKeyNames.ShortName}`}
                        component={BcdInputWrapper}
                        wrapperClassName={'product-short-name'}
                        useFormFieldLayout
                        markPristineValidity
                    />
                    <Field
                        label={'Производитель'}
                        name={ReactionKeyNames.Name}
                        component={BcdInputWrapper}
                        wrapperClassName={'reaction-name'}
                        useFormFieldLayout
                        disabled
                    />
                    <Field
                        label={'Количество вещества, г'}
                        name={`${MAIN_PRODUCT_FIELDS}.${ProductKeyNames.Yield}`}
                        component={BcdInputWrapper}
                        wrapperClassName={'product-yield'}
                        disabled={props.reaction?.HasMultipleYields}
                        type={'number'}
                        useFormFieldLayout
                        markPristineValidity
                        required
                    />
                    {!props.reaction?.HasMultipleYields &&
                    <>
                        <Field
                            label={'Основное вещество, %'}
                            name={`${MAIN_PRODUCT_FIELDS}.${ProductKeyNames.ExperimentalMainAgentContent}`}
                            component={BcdInputWrapper}
                            wrapperClassName={'product-main-agent-content'}
                            type={'number'}
                            useFormFieldLayout
                            markPristineValidity
                        />
                        <Field
                            label={'Расположение'}
                            name={`${MAIN_PRODUCT_FIELDS}.${ProductKeyNames.LaboratoryId}`}
                            component={BcdDropdownWrapper}
                            options={props.labsOptions}
                            format={(value) => props.labsOptions?.find((opt: OptionType) => opt.value === value)}
                            wrapperClassName={'product-lab'}
                            useFormFieldLayout
                            required
                            markPristineValidity
                        />
                        <Field
                            label={'Место'}
                            name={`${MAIN_PRODUCT_FIELDS}.${ProductKeyNames.Location}`}
                            component={BcdInputWrapper}
                            wrapperClassName={'product-location'}
                            useFormFieldLayout
                            required
                            markPristineValidity
                        />
                    </>
                    }
                </div>
                {
                    props.reaction?.HasMultipleYields &&
                    <GridTable
                        className={'reaction-yields'}
                        fieldArrayName={ReactionCompletionKeyNames.Yields}
                        colsTemplate={'repeat(3, min-content) minmax(30ch, 1fr) minmax(30ch, 1fr) min-content'}
                        rowHeight={'56px'}
                    >
                        <GridTableCol
                            fieldName={ReactionCompletionYieldKeyNames.IsInUse}
                            render={({input, meta}) => (
                                <BcdCheckboxWrapper large {...{meta, input}} />
                            )}
                        />
                        <GridTableCol
                            title={'Кол-во вещества, г'}
                            fieldName={ReactionCompletionYieldKeyNames.Amount}
                            onBlur={recalcYield}
                            validate={(_, rowidx) => (amountValidators && amountValidators.length && amountValidators[rowidx]) || reactionYieldValidators[ReactionCompletionYieldKeyNames.Amount]}
                            render={({input, meta}) => (
                                <InlineEdit inputType={inputTypesEnum.InputNum}
                                            markPristineValidity
                                            {...{meta, input}}
                                />
                            )}
                        />
                        <GridTableCol
                            title={'Содер-ие, %'}
                            fieldName={ReactionCompletionYieldKeyNames.MainAgentContent}
                            validate={mainAgentContentValidators}
                            render={({input, meta}) => (
                                <InlineEdit inputType={inputTypesEnum.InputNum}
                                            markPristineValidity
                                            {...{meta, input}}
                                />
                            )}
                        />
                        <GridTableCol
                            title={'Расположение'}
                            fieldName={ReactionCompletionYieldKeyNames.LaboratoryId}
                            validate={reactionYieldValidators[ReactionCompletionYieldKeyNames.LaboratoryId]}
                            format={(value) => props.labsOptions?.find((opt: OptionType) => opt.value === value)}
                            render={({input, meta}) => (
                                <InlineEdit inputType={inputTypesEnum.Select}
                                            options={props.labsOptions}
                                            markPristineValidity
                                            {...{meta, input}}
                                />
                            )}
                        />
                        <GridTableCol
                            title={'Место'}
                            fieldName={ReactionCompletionYieldKeyNames.Location}
                            validate={reactionYieldValidators[ReactionCompletionYieldKeyNames.Location]}
                            render={({input, meta}) => (
                                <InlineEdit inputType={inputTypesEnum.InputText}
                                            {...{meta, input}}
                                            markPristineValidity
                                />
                            )}
                        />
                        <GridTableCol
                            fieldName={ReactionCompletionYieldKeyNames.Comment}
                            render={({input, item}) => (
                                <CommentWrapper
                                    item={{Id: uuidv4(), QuickComment: item[ReactionCompletionYieldKeyNames.Comment]}}
                                    saveComment={({comment}) => input?.onChange(comment)}
                                    iconClassName={'comment-btn'}
                                />
                            )}
                        />
                    </GridTable>
                }
            </div>
        </div>
    )
};

MainProductSubForm = connect(
    (state: IRootStore): IMainProductSubFormProps => ({
        reaction: getReactionByCurrentId(state),
        mainProduct: getCurrentReactionMainProduct(state)
    }),
    (dispatch: Dispatch): IMainProductSubFormProps => ({
        recalcMainProductYield: (value) => dispatch(change(COMPLETE_REACTION_FORM_NAME, `${MAIN_PRODUCT_FIELDS}.${ProductKeyNames.Yield}`, value))
    })
)(MainProductSubForm);

MainProductSubForm = labsLoaderHOC(MainProductSubForm);

MainProductSubForm = styled(MainProductSubForm)`
	display: grid;
	grid-template-columns: 50% 50%;
	.thumbnail-wrap {
		display: flex;
		border-right: 1px solid var(--border-color);
	}
	.thumbnail {
        margin: auto;
        max-width: 100%;
	}
	.product-fields-layout,
	.product-name {
		padding-left: calc(var(--grid-spacer) * 1.5);
		padding-right: calc(var(--grid-spacer) * 1.5);
	}
	
	.product-name-aliases 			{ grid-area: product-name-aliases 		}
	.product-lab 					{ grid-area: product-lab 				}
	.product-location 				{ grid-area: product-location 			}
	.product-short-name 			{ grid-area: product-short-name 		}
	.reaction-name 					{ grid-area: reaction-name 				}
	.product-yield 					{ grid-area: product-yield 				}
	.product-main-agent-content     { grid-area: product-main-agent-content }
	
	.product-fields-layout {
		display: grid;
		grid-column-gap: var(--grid-spacer);
		grid-template:   "product-name-aliases product-name-aliases      "
                         "product-lab          product-location          "
                         "product-short-name   reaction-name             "
                         "product-yield        product-main-agent-content";

		&.reaction-has-multiple-yields {
			grid-template: "product-name-aliases product-name-aliases"
                           "product-short-name   reaction-name       "
                           "product-yield        product-yield       ";
		}
	}
	
	.reaction-yields {
		.grid-table-row:first-child, 
		.grid-table-row-heading:first-child {
			padding-left: var(--grid-spacer);
			padding-right: var(--grid-spacer);
		}
		.b-checkbox__toggle  {
			margin: 0;
		}
	}
  
    .b-FormFieldLayout
    {
      margin-bottom: var(--FormFieldLayout-margin-b) !important;
    }

    .b-InlineEdit__valueHolder::after
    {
      left: 0;
      right: 0;
    }
    .b-InlineEdit__value {
      padding: 0 12px;
    }
    .b-InlineEdit__inputHolder {
      margin-left: 0;
      margin-right: 0;
    }
    .b-InlineEdit-hasErr::before {
      left: 0 !important;
      right: 0 !important;
    }
`;

export {
    MainProductSubForm,
    MAIN_PRODUCT_FIELDS
}
