// @flow

import { BasicModel, Model, ArrayModel, Any } from 'objectmodel';
import { Agent, JarStock_Model } from '../../agents/models';
import { ReagentTypeEnum } from './reagent-type-enum';

export const ReagentJar = Model({
  AgentAvailabilityId: Number,
  Id: [Number, null, undefined],
  IsDeleted: Boolean,
  IsReturned: Boolean,
  ReagentId: [Number, null, undefined],
  ProviderName: [String, null, undefined]
})
.defaultTo({
  IsDeleted: false,
  IsReturned: false,
});

export const Reagent = Model({
  Agent: [Agent],
  AgentAvailability: [JarStock_Model],
  AgentAvailabilityId: [Number],
  Amount: Any,
  Eq: Any,
  FactualConsumption: [Number],
  Id: [Number, null, undefined],
  ParentReagentId: [Number, null, undefined],
  ParentReagentLocalId: [String, null, undefined],
  IsDeleted: Boolean,
  IsRequired: Boolean,
  Jars: [ArrayModel([ReagentJar, null]), null, undefined],
  Mass: Any,
  ReactionId: [Number, null, undefined],
  ReagentType: BasicModel(Number)
    .assert(t => Object.values(ReagentTypeEnum).filter(v => Number.isInteger(v) && (v>=1 && v<=5)).includes(t)),
  Volume: Any,
  IsRefCompound: Boolean, // Данный реагент используется как базовый при расчете реакции
  MainAgentContent: Any,
  localUuid: [String]
})
.defaultTo({
  Eq: 1,
  Amount: null,
  Mass: null,
  Volume: null,
  MainAgentContent: null,
  FactualConsumption: null,
  Jars: [],
  IsRefCompound: false,
  IsDeleted: false,
  IsRequired: false,
});
