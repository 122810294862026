// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".popup-m__candidatesList___TXkXy {\n  --rowHeight: calc(144 + var(--grid-spacer) * 2);\n  --rowSpacer: calc(var(--grid-spacer) * 1.5);\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  grid-column-gap: var(--grid-spacer);\n  grid-row-gap: var(--rowSpacer);\n  align-items: flex-start;\n  height: 480px;\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n.popup-m__hr___Vprrz {\n  margin-bottom: 0;\n}\n.popup-m__candidate___cky1R {\n  display: flex;\n  flex-flow: row nowrap;\n  align-items: flex-start;\n  position: relative;\n  padding-top: var(--rowSpacer);\n}\n.popup-m__candidate___cky1R:nth-child(2n+1):not(:nth-child(1)):after {\n  content: '';\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 1px;\n  background: var(--grey-300);\n}\n.popup-m__reactions___alLO_ {\n  display: flex;\n  flex-flow: column nowrap;\n}\n.popup-m__typeIcon___f7RqW {\n  margin-right: var(--minor-margin);\n}\n.popup-m__thumbnail___cZU3x {\n  width: 144px;\n  height: 144px;\n  border: 1px solid var(--grey-300);\n  padding: var(--minor-margin);\n  margin-right: var(--grid-spacer);\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"candidatesList": "popup-m__candidatesList___TXkXy",
	"hr": "popup-m__hr___Vprrz",
	"candidate": "popup-m__candidate___cky1R",
	"reactions": "popup-m__reactions___alLO_",
	"typeIcon": "popup-m__typeIcon___f7RqW",
	"thumbnail": "popup-m__thumbnail___cZU3x"
};
export default ___CSS_LOADER_EXPORT___;
