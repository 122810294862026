import {
    put,
    takeEvery,
    fork,
    select,
} from 'redux-saga/effects';
import {AgentsService} from '../services/agents';
import { agentModuleActions, agentResourceActions, aliasesResourceActions } from '../actions';
import {getToken} from '../../account/reducers';
import { destroy } from 'redux-form';

export function* searchAgents({params} = {}) {
    const token = yield select(getToken);
    const service = new AgentsService(token);
    let processedParams;
    if (Array.isArray(params.agentMWLimits) && params.agentMWLimits.length === 2) {
        const [MinMWValue, MaxMWValue] = params.agentMWLimits;
        delete params.agentMWLimits;
        processedParams = { ...params, MinMWValue, MaxMWValue };
    }
    try {
        const result = yield service.search({params: processedParams || params});
        yield put(agentResourceActions.list.success(result));
    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(agentResourceActions.list.failure({error: e.response.data['ErrorMessage']}));
        }
    }
}

export function* destroyAgents({params} = {}) {
    const data = { pagination: {
        page: 1,
        pageSize: 20
      },
      result: []
    };
    yield put(agentResourceActions.list.success(data));
    yield destroy();
}

export function* getAgentDetails({agentId}) {
    const token = yield select(getToken);
    const service = new AgentsService(token);

    try {
        const result = yield service.getSingle(agentId);
        yield put(agentResourceActions.get.success(result));
    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(agentResourceActions.get.failure({error: e.response.data['ErrorMessage']}));
        }
    }
}

export function* getAgentsBySmiles({ params }) {
    const token = yield select(getToken);
    const service = new AgentsService(token);

    try {
        const result = yield service.getAgentsBySmiles({ params });
        yield put(agentResourceActions.destroy());
        yield put(agentResourceActions.list.success(result));
    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(agentResourceActions.get.failure({error: e.response.data['ErrorMessage']}));
        }
    }
}

export function* deleteAgent({agentId}) {
    const token = yield select(getToken);
    const service = new AgentsService(token);

    try {
        const result = yield service.delete(agentId);
        yield put(agentResourceActions.delete.success(result));
    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(agentResourceActions.delete.failure({error: e.response.data['ErrorMessage']}));
        }
    }
}

export function* getAgentAliases({agentId, params}) {
    const token = yield select(getToken);
    const service = new AgentsService(token);

    try {
        const result = yield service.getAgentAliases(agentId, params);
        yield put(aliasesResourceActions.get.success(result));
    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(aliasesResourceActions.get.failure({error: e.response.data['ErrorMessage']}));
        }
    }
}

export function* addAgentAliases(data) {
    const token = yield select(getToken);
    const service = new AgentsService(token);

    try {
        const result = yield service.addAliases(data);
        yield put(aliasesResourceActions.create.success(result));
    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(aliasesResourceActions.create.failure({error: e.response.data['ErrorMessage']}));
        }
    }
}

export function* deleteAgentAliases(data) {
    const token = yield select(getToken);
    const service = new AgentsService(token);

    try {
        const result = yield service.deleteAliases(data);
        yield put(aliasesResourceActions.delete.success(result));
    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(aliasesResourceActions.delete.failure({error: e.response.data['ErrorMessage']}));
        }
    }
}

export function* createAgent(data) {
    const token = yield select(getToken);
    const service = new AgentsService(token);

    try {
        const result = yield service.create(data);
        yield put(agentResourceActions.create.success(result));
    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(agentResourceActions.create.failure({error: e.response.data['ErrorMessage']}));
        }
    }
}

export function* updateAgent(data) {
    const token = yield select(getToken);
    const service = new AgentsService(token);

    try {
        const result = yield service.update(data);
        yield put(agentResourceActions.update.success(result));
    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(agentResourceActions.update.failure({error: e.response.data['ErrorMessage']}));
        }
    }
}

export function* editAgentComment(data) {
    const {agentId} = data;
    const token = yield select(getToken);
    const service = new AgentsService(token);
    try {
        const result = yield service.editComment(agentId, data);
        yield put(agentResourceActions.editAgentComment.success(result));
        yield put(agentModuleActions.setCommentUpdateRequestStatus({ status: true }));
    } catch (e) {
        yield put(agentModuleActions.setCommentUpdateRequestStatus({ status: false }));
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(agentResourceActions.editAgentComment.failure({error: e.response.data['ErrorMessage']}));
        }
    } finally {
        yield put(agentModuleActions.setCommentUpdateRequestStatus({ status: null }));
    }
}

export function* getMWLimits() {
    const token = yield select(getToken);
    const service = new AgentsService(token);
    try {
        yield put(agentModuleActions.setMWLimits({ limits: [0, 200] }));
        const result = yield service.getMWLimits();
        yield put(agentResourceActions.getMWLimits.success({}));
        yield put(agentModuleActions.setMWLimits({ limits: result.result }));
    } catch (error) {
        yield put(agentResourceActions.getMWLimits.failure({ error }));
    }
}


// Watchers
export const agentWatchers = [

    function* watchSearchAgents() {
        yield takeEvery(agentResourceActions.list.request.type, function* (action) {
            const {params} = action;
            yield fork(searchAgents, {params});
        });
    },
    function* watchDestroy() {
        yield takeEvery(agentResourceActions.destroy, function* (action) {
            const {params} = action;
            yield fork(destroyAgents, {params});
        });
    },
    function* watchGetAgentsBySmiles() {
        yield takeEvery(agentResourceActions.getBySmiles.request, function* (action) {
            const {params} = action;
            yield fork(getAgentsBySmiles, {params});
        });
    },

    function* watchDetailsAgent() {
        yield  takeEvery(agentResourceActions.get.request.type, function* (action) {
            const {params} = action;
            yield fork(getAgentDetails, params);
        });
    },

    function* watchCreateAgent() {
        yield takeEvery(agentResourceActions.create.request.type, function* (action) {
            const {data} = action;
            yield fork(createAgent, data);
        });
    },

    function* watchUpdateAgent() {
        yield takeEvery(agentResourceActions.update.request.type, function* (action) {
            const {data} = action;
            yield fork(updateAgent, data);
        });
    },

    function* watchDeleteAgent() {
        yield takeEvery(agentResourceActions.delete.request.type, function* (action) {
            const {params} = action;
            yield fork(deleteAgent, params);
        });
    },

    function* watchAgentAliases() {
        yield  takeEvery(aliasesResourceActions.get.request.type, function* (action) {
            const {params} = action;
            yield fork(getAgentAliases, params);
        });
    },

    function* watchAddAgentAliases() {
        yield  takeEvery(aliasesResourceActions.create.request.type, function* (action) {
            const {data} = action;
            yield fork(addAgentAliases, data);
        });
    },

    function* watchDeleteAgentAliases() {
        yield  takeEvery(aliasesResourceActions.delete.request.type, function* (action) {
            const {data} = action;
            yield fork(deleteAgentAliases, data);
        });
    },

    function* watchEditComment() {
        yield  takeEvery(agentResourceActions.editAgentComment.request.type, function* (action) {
            const {data} = action;
            yield fork(editAgentComment, data);
        });
    },

    function* () {
        yield takeEvery(agentResourceActions.getMWLimits.request.type, function* () {
            yield fork(getMWLimits)
        })
    }
];
