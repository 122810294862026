// @flow

import React from 'react';
import styled from 'styled-components';
import Button from '@biocad/bcd-front-ui/controls/Button';

export const DialogLayout = styled('div')`
  width: 400px;
  height: 600px;
  .dialog-contents {
    display: grid;
    grid-auto-rows: calc(200px - (var(--grid-spacer) / 2));
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--grid-spacer);
  }
  .dialog-footer {
    .${Button.block} {
      margin-left: var(--grid-spacer);
    }
    *:first-child {
      margin-left: auto;
    }
  }
`;

export const MergeableStageItemStyled = styled('div')`
  border: 1px solid var(--grey-400);
  padding: var(--grid-spacer);
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  &.is-selected {
    background-color: var(--green-o-10);
    border-color: var(--green);
  }
  .stage-thumbnail {
    display: block;
    width: 100px; height: 100px;
    margin: auto 0 0 auto;
  }
  .stage-number {
    flex: 100% 1 0;
  }
  .fo-arrow-right {
      display: flex;
      font-size: 28px;
      &::before {
        margin: auto;
      }
  }
`;
