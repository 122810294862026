// @flow
import React, { useState } from 'react';
import {connect} from 'react-redux';
import Button from '@biocad/bcd-front-ui/controls/Button/index';
import {
    StyledLink
} from '../style';
import {projectActions} from '../../../actions/projects';
import './style.less';
import {folderModuleActions, folderResourceActions} from '../../../actions/folders';
import {itemsToArraySelector} from '../../../../shared/utils/selectors/resource-selectors';
import {ConfirmDialog} from '../../../../shared/components/ConfirmDialog/ConfirmDialog';
import {folderParentType} from '../../../types/enum';
import {userCRUDPermissionsSelector} from "../../../../account/reducers/users-modules-reducer";

const initialProps = {
    folders: [],
    getList: null,
    getFolders: null,
    deleteFolder: null,
    clearProjectsList: null,
    clearFoldersList: null,
    addFolderPath: null,
    removeFolderPath: null,
    foldersPath: [],
    toggleFolderModal: null,
    currentFolderId: null,
    setCurrentFolderId: null,
    setEditMode: null
};

const FoldersList = (props = initialProps) => {
    const [isConfirmShown, setIsConfirmShown] = useState(false);

    const openFolder = (e) => {
        let folderId = e.target.parentNode.dataset.id;
        let folderName = e.target.parentNode.dataset.name;

        props.clearProjectsList();
        props.clearFoldersList();
        props.getFolders({
            parentId: folderId,
            parentType: folderParentType.folder
        });
        props.getList({
            folderId: folderId
        });

        props.addFolderPath({
            id: folderId,
            name: folderName
        });
    };

    const returnFromFolder = () => {
        props.clearProjectsList();
        props.clearFoldersList();
        props.getFolders({
            parentId: props.foldersPath.length > 1 ?
                props.foldersPath[props.foldersPath.length - 2].id : null,
            parentType: props.foldersPath.length > 1 ? folderParentType.folder : folderParentType.root
        });
        props.getList({
            folderId: props.foldersPath.length > 1 ?
                props.foldersPath[props.foldersPath.length - 2].id : null
        });

        props.removeFolderPath();
    };

    const confirmDeleteFolder = (e) => {
        e.stopPropagation();
        toggleConfirmModal();
        props.setCurrentFolderId({
            id: e.target.dataset.id
        });
    };

    const deleteFolder = () => {
        props.clearFoldersList();
        props.deleteFolder({
            id: props.currentFolderId,
            parentId: props.foldersPath.length > 0 ?
                props.foldersPath[props.foldersPath.length - 1].id : null,
            parentType: props.foldersPath.length > 0 ? folderParentType.folder : folderParentType.root
        });
        toggleConfirmModal();
    };

    const editFolder = (e) => {
        e.stopPropagation();
        props.toggleFolderModal();
        props.setCurrentFolderId({
            id: e.target.dataset.id
        });
        props.setEditMode({
            isEdit: true
        });
    };

    const toggleConfirmModal = () => {
        setIsConfirmShown(!isConfirmShown);
    };

    return (
        <React.Fragment>
            {isConfirmShown &&
            <ConfirmDialog
                title={'Внимание'}
                message={'Вы уверены, что хотите удалить эту папку?'}
                confirmLabel={'Да'}
                declineLabel={'Отмена'}
                confirmAction={deleteFolder}
                declineAction={toggleConfirmModal}
            />}

            {props.foldersPath.length > 0 &&
            <tr onClick={returnFromFolder}>
                <td>
                    <i className={'fo-folder-back'}/>
                    .../{props.foldersPath[props.foldersPath.length - 1].name}
                </td>
                <td/>
                <td/>
                <td/>
                <td/>
                <td/>
            </tr>
            }
            {props.folders.map((folder, index) => {
                return (
                    <tr
                        key={folder.Id || index}
                        data-id={folder.Id}
                        data-name={folder.Name}
                        onClick={openFolder}
                        className={'folders-list-line'}
                    >
                        <td>
                            <i className={'fo-folder'}/>
                            {folder.Name || ''}
                        </td>
                        <td/>
                        <td/>
                        <td/>
                        {
                            props.userPermissions.folder.UPDATE ?
                            <td className={'cell-with-only-icon'} onClick={editFolder}>
                                <div className={'button-holder'}>
                                    <StyledLink
                                        to={'#'}
                                        className={Button.block.toString() + Button.block.mod('icon') + Button.block.mod('size', 'L')}
                                    >
                                        <i className={'fo-edit'} data-id={folder.Id}/>
                                    </StyledLink>
                                </div>
                            </td> : <td className={'cell-with-only-icon'} />
                        }
                        {
                            props.userPermissions.folder.DELETE ?
                            <td className={'cell-with-only-icon'} onClick={confirmDeleteFolder}>
                                <StyledLink
                                    to={'#'}
                                    className={Button.block.toString() + Button.block.mod('icon') + Button.block.mod('size', 'L')}
                                >
                                    <i className={'fo-trash'} data-id={folder.Id}/>
                                </StyledLink>
                            </td> : <td className={'cell-with-only-icon'} />
                        }
                    </tr>
                );
            })}
        </React.Fragment>
    );

};

const mapStateToProps = (state) => {
    const {resource, modules} = state;
    return {
        folders: itemsToArraySelector(resource.folders),
        foldersPath: modules.folder.path,
        currentFolderId: modules.folder.id,
        editMode: modules.folder.isEdit,
        userPermissions: userCRUDPermissionsSelector(state)
    };
};

export default connect(
    mapStateToProps,
    (dispatch) => ({
        getList: (params) => dispatch(projectActions.list.request({params})),
        getFolders: (params) => dispatch(folderResourceActions.list.request({params})),
        deleteFolder: (data) => dispatch(folderResourceActions.delete.request({data})),
        clearProjectsList: () => dispatch(projectActions.destroy()),
        clearFoldersList: () => dispatch(folderResourceActions.destroy()),
        addFolderPath: ({id, name}) => dispatch(folderModuleActions.addFolderPathItem({id, name})),
        removeFolderPath: () => dispatch(folderModuleActions.removeFolderPathItem()),
        setCurrentFolderId: (id) => dispatch(folderModuleActions.setCurrentFolderId(id)),
        setEditMode: (isEditMode) => dispatch(folderModuleActions.setEditMode(isEditMode))
    })
)(FoldersList);
