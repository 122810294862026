// @flow

import { CRUD_ACTION_TYPES } from '../action-creators';

let HTTPMethodEnum = {
  POST    : 'POST',
  GET     : 'GET',
  PUT     : 'PUT',
  DELETE  : 'DELETE',
  OPTIONS : 'OPTIONS',
  PATCH   : 'PATCH',
  HEAD    : 'HEAD',
};
HTTPMethodEnum = Object.freeze(HTTPMethodEnum);

let PaginationHeadersMap: {[key: string]: string} = {
  page: 'x-page',
  pageCount: 'x-pagecount',
  pageSize: 'x-pagesize',
  total: 'x-total',
};
PaginationHeadersMap = Object.freeze(PaginationHeadersMap);

let CRUDRequestsMap = {
  get: Symbol(CRUD_ACTION_TYPES.get),
  list: Symbol(CRUD_ACTION_TYPES.list),
  create: Symbol(CRUD_ACTION_TYPES.create),
  update: Symbol(CRUD_ACTION_TYPES.update),
  delete: Symbol(CRUD_ACTION_TYPES.delete),
};
CRUDRequestsMap = Object.freeze(CRUDRequestsMap);

export {
  HTTPMethodEnum,
  PaginationHeadersMap,
  CRUDRequestsMap,
};
