// @flow
import { apiRequestSingle } from '../../shared/services/api/common';
import { IJarStock } from '../models';

export class JarStockService {

    _getJarStockByAgent: (...params: any) => void;
    _getJarStockSpendings: (...params: any) => void;
    _spendJar: (...params: any) => void;
    _update: (...params: any) => void;
    _getUsedJarsByReaction: (...params: any) => void;
    _getProvidedJarsByReaction: (...params: any) => void;



    constructor(token) {
        const headers = token ? {Authorization: token} : {};

        this._getJarStockByAgent = apiRequestSingle('GET', '/availabilities/byAgents', headers);
        this._update = apiRequestSingle('POST', '/availabilities/batch', headers);
        this._getJar = apiRequestSingle('GET', '/availabilities/batch', headers);
        this._getJarStockSpendings = apiRequestSingle('GET', '/jar_spendings', headers);
        this._spendJar = apiRequestSingle('POST', '/jar_spendings', headers);
        this._getAmountsByLaboratories = apiRequestSingle('GET', '/availabilities/get-amounts-by-laboratories', headers);
        this._getUsedJarsByReaction = apiRequestSingle('GET', '/availabilities/used', headers);
        this._getProvidedJarsByReaction = apiRequestSingle('GET', '/availabilities/provided', headers);

    }

    getUsedJarsByReaction(data) {
        return this._getUsedJarsByReaction(data.params, {});
    }
    getProvidedJarsByReaction(data) {
        return this._getProvidedJarsByReaction(data.params, {});
    }

    getJarStockByAgent({ Ids }: { Ids: number }) {
        return Ids && this._getJarStockByAgent({ Ids }, {});
    }
    
    getJar(data) {
        return this._getJar(data, {});
    }

    update(data: IJarStock) {
        return data && this._update({}, data);
    }

    getJarStockSpendings({ AgentAvailabilityId, includeSpentOnReactions = true }: { AgentAvailabilityId: number, includeSpentOnReactions: boolean }) {
        return AgentAvailabilityId && this._getJarStockSpendings({ AgentAvailabilityId, includeSpentOnReactions }, {});
    }

    spendJar(data: { AgentAvailabilityId: number, Amount: number, Reason: string, ReactionId?: number}) {
        return data.AgentAvailabilityId && this._spendJar({}, data);
    }

    getAmountsByLaboratories(params)
    {
        return this._getAmountsByLaboratories(params);
    }
}
