// @flow

import React from 'react';
import bnc from 'bnc';
import {
	CustomizedTooltip, FormFieldLayout,
} from '../../../../../shared/components';
import type { IFieldInputProps, IFieldMetaProps } from '../../../../../shared/models';
import { ReactionExtraConditionMap } from '../../../../models';
import type { TReactionExtraCondition } from '../../../../models';
import Checkbox from '@biocad/bcd-front-ui/controls/Checkbox';
import Button from '@biocad/bcd-front-ui/controls/Button';

import './index.less';

interface IReactionExtraConditionSelectProps {
	input?: IFieldInputProps,
	meta?: IFieldMetaProps,
	className?: string,
	disabled?: boolean
}

const
	BNCBlock = new bnc('b-ReactionExtraConditionsSelect'),
	BNCValueItem = BNCBlock.el('valueItem'),
	BNCTooltip = BNCBlock.el('tooltip'),
	BNCTrigger = BNCBlock.el('trigger'),
	BNCSelectItem = BNCBlock.el('selectItem'),
	BNCTitle = BNCBlock.el('title'),
	BNCSelectList = BNCBlock.el('selectList'),
	BNCSelectGroup = BNCBlock.el('selectGroup'),
	BNCBtnRemove = BNCBlock.el('btnRemove')
;

const
	parameterGroups = [
		[
			ReactionExtraConditionMap.Autoclave,
			ReactionExtraConditionMap.SealedTube,
			ReactionExtraConditionMap.DeanStark,
			ReactionExtraConditionMap.MolecularSieve,
			ReactionExtraConditionMap.SchlenkTechnique,
		],
		[
			ReactionExtraConditionMap.Darkness,
			ReactionExtraConditionMap.InertAtmosphere,
			ReactionExtraConditionMap.Reflux,
		],
		[
			ReactionExtraConditionMap.MicrowaveIrradiation,
			ReactionExtraConditionMap.Sonication,
			ReactionExtraConditionMap.UVIrradiation,
			ReactionExtraConditionMap.FlowReactor,
			ReactionExtraConditionMap.Photoreactor,
		],
	],
	parameters = Object.entries(ReactionExtraConditionMap)
;

const defaultProps: IReactionExtraConditionSelectProps = {
	className: '',
	disabled: false
};

export const ReactionExtraConditionSelect = (props: IReactionExtraConditionSelectProps = defaultProps) => {
	const
		updateValue = (event: SyntheticEvent, condition: TReactionExtraCondition) => {
			let { value } = props.input;
			if ((value & condition.flag) !== condition.flag) {
				value += condition.flag;
			}
			else {
				value -= condition.flag;
			}
			props.input?.onChange(value);
		},
		passOnFocus = () => {
			props.input?.onFocus();
		},
		passOnBlur = () => {
			props.input?.onBlur();
		}
	;

	return (
		<FormFieldLayout
			label={'Дополнительные условия'}
			className={props.className}
			childrenWrapClassName={BNCBlock}
		>
			{
				parameters
					.filter(([_, condition: TReactionExtraCondition]) => (
						(props.input.value & condition.flag) === condition.flag
					))
					.map(([_, condition: TReactionExtraCondition]) => (
						<span className={BNCValueItem} key={condition.flag}>
							<span>{condition.title}</span>
							<Button
								className={BNCBtnRemove + 'fo-close'}
								view={'icon'}
								size={'S'}
								onAction={(event) => updateValue(event, condition)}
								disabled = {props.disabled}
							/>
						</span>
					))
			}
			<CustomizedTooltip
				buttonClassName={BNCTrigger + 'fo-plus'}
				tooltipClassName={BNCTooltip.toString()}
				contentsClassName={BNCSelectList.toString()}
				onTooltipOpen={passOnFocus}
				onTooltipClose={passOnBlur}
				disabled = {props.disabled}
			>
				{
					parameterGroups.map((group: TReactionExtraCondition[], idx: number) => (
						<div className={BNCSelectGroup} key={idx}>
							{
								group.map((condition: TReactionExtraCondition) => (
									<Checkbox
										key={condition.flag}
										onChange={(event) => updateValue(event, condition)}
										className={BNCSelectItem}
										checked={(props.input.value & condition.flag) === condition.flag}
										large
									>
										<span className={BNCTitle}>{condition.title}</span>
									</Checkbox>
								))
							}
						</div>
					))
				}
			</CustomizedTooltip>
		</FormFieldLayout>
	)
};
