// @flow

import { ArrayModel, Model } from 'objectmodel';
import { Compound } from '../../agents/models';
import {formInputValueType} from '../../shared/utils/common';

export const AgentAlias = Model({
  AgentId: [Number, null, undefined],
  Id: [Number, null, undefined],
  Name: [String]
});

export const Product = Model({
  Amount: formInputValueType(Number),
  Compound: [Compound, null, undefined],
  CompoundId: [Number, null, undefined],
  Concentration: [Number],
  Density: formInputValueType(Number),
  Eq: formInputValueType(Number),
  FactualConsumption: [Number],
  Id: [Number, null, undefined],
  IsDeleted: Boolean,
  IsInWork: Boolean,
  IsMainProduct: Boolean,
  LaboratoryId: [Number, null, undefined],
  Location: [String, null, undefined],
  MainAgentContent: [Number],
  Mass: formInputValueType(Number),
  MolarWeight: [Number, null, undefined],
  Name: [String],
  NameAliases: [ArrayModel([AgentAlias]), null, undefined],
  ReactionId: [Number, null, undefined],
  ShortName: [String, null, undefined],
  Volume: formInputValueType(Number),
  ExperimentalMainAgentContent: formInputValueType(Number),
  Yield: formInputValueType(Number),
  IgnoreJarCreation: Boolean,
  AgentId: [Number, null, undefined],
})
.defaultTo({
  IsDeleted: false,
  IsInWork: false,
  IsMainProduct: true,
  IgnoreJarCreation: false,
  Amount: null,
  Concentration: null,
  Density: null,
  Eq: 1,
  FactualConsumption: null,
  MainAgentContent: null,
  Mass: null,
  Volume: null,
  ExperimentalMainAgentContent: null,
  Yield: null,
  MolarWeight: null,
  AgentId: null,
});
