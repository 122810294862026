// @flow

import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import type {IAgent} from '../../../../agents/models/i-agent';
import {itemsToArraySelector} from '../../../../shared/utils/selectors/resource-selectors';
import Dialog from '@biocad/bcd-front-ui/layout/Dialog';
import {DialogLayout} from '../../../../stages/components/StageEdit/StageSubstanceEdit/AgentsListDialog/style';
import Loader from '@biocad/bcd-front-ui/controls/Loader';
import {GridTable, GridTableCol} from '../../../../shared/components/GridTable';
import {_noop} from '../../../../shared/utils/common';
import {reduxForm, getFormValues, Field} from 'redux-form';
import Button from '@biocad/bcd-front-ui/controls/Button';
import type { IReactionEditDialogWrapComponentProps } from '../ReactionEditDialogWrap';
import {
    BcdInputWrapper, BcdCheckboxWrapper, BcdDropdownWrapper
  } from '../../../../shared/components';
import {FieldValidators} from '../../../../shared/validators/field-validators';
import styled from 'styled-components';
import {reactionModuleActions} from '../../../actions'
import { ReagentTypeEnum } from '../../../../reagents/model';
import { reactionTypesResourceActions } from '../../../../reactionTypes/actions';
import { reactionsResourceActions } from '../../../actions';
import { TemplateReaction } from './template-reaction'
import { PurificationBundleTypeEnum } from '../../../../stages/models';

const OffsetSpan = styled.span`
  margin-left: 8px;
  .template-checkbox {
    display: contents;
   }
  .reaction-type-combobox {
    width: 320px;
  }
`;

const InnerCheckboxSpan = styled.span`
  font-size: 16px;
`;

interface ICopyFromTemplateModal extends IReactionEditDialogWrapComponentProps {
    pending: Boolean,
}

const initialProps: ICopyFromTemplateModal = {
    pending: false,
};

export const COPY_FROM_TEMPLATE_FORM_NAME = "copyFromTemplateForm";
const reactionTemplateIdKey = "reactionTemplateId";
const onlyTemplateKey = "isOnlyTemplateReaction";
const reactionTypeKey = "reactionType";

const filterByType = (reagents, rType) => reagents?.filter(r => r.ReagentType === rType);

let CopyFromTemplateModal = (props: ICopyFromTemplateModal = initialProps) => {

    const _onUnmount = () => () => {
		props.clearStore();
	};
	useEffect(_onUnmount, []);

    const _loadTemplateReactionTypes = () => {
        props.getTemplateReactionTypes();
	};
	useEffect(_loadTemplateReactionTypes, []);

    const [selectedTemplateId, setSelectedTemplateId] = useState(null);    
    const [isDefferedClosing, setIsDefferedClosing] = useState(false);
    const _defferedCloseHandle = () => {
        if(props.specTemplateApplied && isDefferedClosing) {
            setIsDefferedClosing(false);
            props.closeDialog();
        }
	};
	useEffect(_defferedCloseHandle, [props.specTemplateApplied, isDefferedClosing]);

    const
        onCancel = () => {
            props.closeDialog();
        },
        onConfirm = () => {
                props.searchReaction(selectedTemplateId, true);
                props.useSpecificTemplateReaction(selectedTemplateId);
                setIsDefferedClosing(true);
            if (props.emptyJarsInfo?.length > 0) {
                props.setHasEmptyJars(true);
            }
        },
        canSearch = () => (
            props.formValues &&
            !(
                props.isOnlyTemplate
                && props.formValues[reactionTypeKey]
                || !props.isOnlyTemplate
                && props.formValues[reactionTemplateIdKey]
            )
        ),
        onSearch = () => {
            if(!props.isOnlyTemplate) {
                const reactionTemplateId = props.formValues[reactionTemplateIdKey];
                setSelectedTemplateId(reactionTemplateId);
                props.searchReaction(reactionTemplateId, false);
            } else
            {
                props.getTemplateReactions(props.formValues[reactionTypeKey]);
                setSelectedTemplateId(null);
            }
        },
        getReactionTypeOptions = () => {
            return props.reactionTypes.map(rt => ({
                    label: rt.Name,
                    value: rt.Id,
                }));
        },
        formatReactionTypeOptions = (value, _) => getReactionTypeOptions().find(i => i.value === value),
        renderReagent = (r, index) => (<li key={index}>{r.Agent.ShortName || r.Agent.Name}</li>),
        renderReagentsByType = () => (
            <>
                {props.catalysts.length > 0 &&
                    <>
                        <span>Катализаторы</span>
                        <ul>
                            {props.catalysts?.map((r, index) => renderReagent(r, index))}
                        </ul>
                    </>
                }
                {props.solvents.length > 0 &&
                    <>
                        <span>Растворители</span>
                        <ul>
                            {props.solvents?.map((r, index) => renderReagent(r, index))}
                        </ul>
                    </>
                }
                {props.auxs.length > 0 &&
                    <>
                        <span>Вспомогательные реагенты</span>
                        <ul>
                            {props.auxs?.map((r, index) => renderReagent(r, index))}
                        </ul>
                    </>
                }
            </>
        ),
        renderTemplateReactions = () => {
            return (
                <>
                    {props.templateReactions.map((r, index) => (
                        <TemplateReaction key={index} templateReaction={r} onSelect={(id) => setSelectedTemplateId(id)} isSelected={selectedTemplateId === r.Id} />
                    ))}
                </>
            );
        };

    return (
      <Dialog close={onCancel} paranjaClose={false}>
        <DialogLayout className={'dialog-layout'}>
            <header className={'dialog-header'}>
                <h3>Копирование из шаблона</h3>
            </header>

            <div className={'dialog-contents'}>
                {props.pending || isDefferedClosing ?
                    <Loader/> :
                    <>
                        {!props.formValues || !props.formValues[onlyTemplateKey] ?
                        <>
                            <span>
                                Введите идентификатор реакции-шаблона:
                            </span>
                            <OffsetSpan>
                                <Field
                                    name={reactionTemplateIdKey}
                                    component={BcdInputWrapper}
                                    type={'number'}
                                    validate={[FieldValidators.required]}
                                />
                            </OffsetSpan>
                        </> :
                        <>
                            <span>
                                Тип реакции:
                            </span>
                            <OffsetSpan>
                                <Field
                                    name={reactionTypeKey}
                                    large={true}
                                    className={'reaction-type-combobox'}
                                    component={BcdDropdownWrapper}
                                    options={getReactionTypeOptions()}
                                    format={formatReactionTypeOptions}
                                    placeholder={'Выберите тип реакции'} 
                                />
                            </OffsetSpan>
                        </>
                        }
                        <OffsetSpan>
                            <Button
                                text={'Найти'}
                                size={'L'}
                                disabled={canSearch()}
                                onAction={onSearch}
                                view={'primary'}

                            />
                        </OffsetSpan>
                        <OffsetSpan>
                            <Field
                                name={onlyTemplateKey}
                                className={'template-checkbox'}
                                component={BcdCheckboxWrapper}
                                label={'Шаблонная реакция'}
                                large
                            />
                        </OffsetSpan>
                        {
                            !props.isOnlyTemplate && props.reaction && 
                            <>
                                <h3>Реакция найдена: {props.reaction.Name} {props.reaction.AdditionalName && `(${props.reaction.AdditionalName})`}</h3>
                                { props.reagents.length > 0 
                                    ? renderReagentsByType()
                                    : <span>В реакции отсутсвуют реагенты, доступные для копирования</span>
                                }
                            </>
                        }
                        {
                            props.isOnlyTemplate && props.templateReactions.length ? renderTemplateReactions() : ''
                        }
                    </>
                }
            </div>
            <footer className={'dialog-footer'}>
                <Button
                  text={'Подтвердить'}
                  size={'L'}
                  disabled={!props.isOnlyTemplate && (!props.reagents || props.reagents.length === 0) 
                          || props.isOnlyTemplate && !selectedTemplateId
                          || isDefferedClosing}
                  onAction={onConfirm}
                  view={'primary'}
                />
                <Button
                  text={'Отменить'}
                  size={'L'}
                  onAction={onCancel}
                  disabled={isDefferedClosing}
                />
            </footer>
        </DialogLayout>
      </Dialog>
    );
};

CopyFromTemplateModal = reduxForm({
    form: COPY_FROM_TEMPLATE_FORM_NAME,
    destroyOnUnmount: true,
    enableReinitialize: true,
    touchOnChange: true,
  })(CopyFromTemplateModal);

const _getFormValues = (state) => getFormValues(COPY_FROM_TEMPLATE_FORM_NAME)(state);

CopyFromTemplateModal = connect(
    (state) => ({
        formValues: _getFormValues(state),
        isOnlyTemplate: _getFormValues(state) && _getFormValues(state)[onlyTemplateKey],
        pending: state.modules.reaction.template.isPending,
        specTemplateApplied: state.modules.reaction.template.specTemplateApplied,
        reactionTypes: state.modules.reaction.template.reactionTypes,
        reaction: state.modules.reaction.template.searchedReaction,
        catalysts: filterByType(state.modules.reaction.template.reagents, ReagentTypeEnum.Catalyst),
        solvents: filterByType(state.modules.reaction.template.reagents, ReagentTypeEnum.Solvent),
        auxs: filterByType(state.modules.reaction.template.reagents, ReagentTypeEnum.Auxiliary),
        reagents: state.modules.reaction.template.reagents,
        templateReactions: state.modules.reaction.template.templateReactions,
        emptyJarsInfo: state.modules.reaction.template.emptyJarsInfo,
    }),
    (dispatch) => ({
        searchReaction: (id, checkEmpty) => dispatch(reactionModuleActions.template.requestSearchById({reactionId: id, checkEmpty: checkEmpty})),
        useTemplate: () => dispatch(reactionModuleActions.template.useTemplate()),
        clearStore: () => dispatch(reactionModuleActions.template.clear()),
        getTemplateReactionTypes: () => dispatch(reactionTypesResourceActions.getTemplateReactionTypes.request({})),
        getTemplateReactions: (reactionType) => dispatch(reactionsResourceActions.getTemplateReactions.request({ params: { reactionType }})),
        useSpecificTemplateReaction: (id) => dispatch(reactionModuleActions.template.useSpecificTemplateReaction({reactionId: id})),
        setHasEmptyJars: (hasEmptyJars) => dispatch(reactionModuleActions.template.setHasEmptyJars({ hasEmptyJars })),
    })
)(CopyFromTemplateModal);

export {
    CopyFromTemplateModal
};

