import React, {
  forwardRef,
} from 'react';
import styles from './icon-button.m.less';
import './icon-button.less';
import classNames from 'classnames';
import Button from '@biocad/bcd-front-ui/controls/Button';

let IconButton = ({iconClassName, className, active, disabled, onClick, ...rest}, ref) => {
  
  const classes = classNames(
    'icon-button-component',
    styles.iconButton,
    iconClassName ? iconClassName : null,
    className ? className : null,
    disabled ? styles.disabled : null,
    active ? styles.active : null,
  );

  return (
    <Button
      ref={ref}
      className={classes}
      view={'icon'}
      size={'L'}
      onAction={onClick}
      disabled={disabled}
      {...rest}
    />
  );
};

IconButton = forwardRef(IconButton);

export {
  IconButton
};
