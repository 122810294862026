// @flow
import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import Candidate from './components/EditCandidate';

const CandidatesRoutes = ({match}) => {

    return (
        <Switch>
            <Route path={`${match.path}/:candidateId`} render={(props) => <Candidate {...props}/>}/>
        </Switch>
    );

};

export default withRouter(CandidatesRoutes);
