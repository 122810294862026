import {all} from 'redux-saga/effects';
import {accountWatchers} from '../account/sagas';
import {agentWatchers, jarStockWatchers} from '../agents/sagas';
import {analyticsWatchers} from '../analytics/sagas';
import {attachmentWatchers} from '../attachments/sagas';
import {bimsWatchers} from '../dictionary/sagas';
import {dictionaryWatchers} from '../dictionary/sagas/dictionaryWatchers';
import {_temp_folderResourceWatchers} from '../folders/sagas';
import {productsResourceWatchers} from '../products/sagas';
import {
  candidatesWatchers,
  descendantsWatchers,
  folderSchemesWatchers,
  folderWatchers,
  productionSchemeWatchers,
  projectWatchers
} from '../projects/sagas';
import {reactionsResourceWatchers, repkaTaskListWatchers} from '../reactions/sagas';
import {reagentsResourceWatchers} from '../reagents/sagas/resource';
import {reactionsTypeResourceWatchers} from '../reactionTypes/sagas';
import {reactionModuleWatchers} from '../reactions/sagas/module';
import {schemeResourceWatchers} from '../schemes/sagas';
import {stageWatchers} from '../stages/sagas';
import {dashboardWatchers} from '../user-dashboard/sagas';
import {usersResourceWatchers} from '../account/sagas/users';
import {archivedReagentsResourceWatchers} from '../archivedReagents/sagas';
import {reduxFormAutosaveWatchers} from '../shared/sagas';
import { indicatorsResourceWatchers } from '../indicators/sagas';
import { stageIndicatorsWatchers } from '../stage-indicators/sagas';
import { reactionIndicatorParametersResourceWatchers } from '../reaction-indicator-parameters/sagas/resource';
import {equipmentWatchers} from '../equipment/sagas';

// Root saga
export default function* rootSaga() {
  yield all([
    ...accountWatchers.map(fn => fn()),
    ...attachmentWatchers.map(fn => fn()),
    ...agentWatchers.map(fn => fn()),
    ...dictionaryWatchers.map(fn => fn()),
    ...jarStockWatchers.map(fn => fn()),
    ...projectWatchers.map(fn => fn()),
    ...equipmentWatchers.map(fn=> fn()),
    ...candidatesWatchers.map(fn => fn()),
    ...folderWatchers.map(fn => fn()),
    ...productionSchemeWatchers.map(fn => fn()),
    ...folderSchemesWatchers.map(fn => fn()),
    ...descendantsWatchers.map(fn => fn()),
    ...dashboardWatchers.map(fn => fn()),
    ...reactionsResourceWatchers.map(fn => fn()),
    ...analyticsWatchers.map(fn => fn()),
    ...stageWatchers.map(fn => fn()),
    ...schemeResourceWatchers.map(fn => fn()),
    ..._temp_folderResourceWatchers.map(fn => fn()),
    ...reagentsResourceWatchers.map(fn => fn()),
    ...productsResourceWatchers.map(fn => fn()),
    ...reactionsTypeResourceWatchers.map(fn => fn()),
    ...reactionModuleWatchers.map(fn => fn()),
    ...repkaTaskListWatchers.map(fn => fn()),
    ...usersResourceWatchers.map(fn => fn()),
    ...archivedReagentsResourceWatchers.map(fn => fn()),
    ...reduxFormAutosaveWatchers.map(fn => fn()),
    ...indicatorsResourceWatchers.map(fn => fn()),
    ...stageIndicatorsWatchers.map(fn => fn()),
    ...reactionIndicatorParametersResourceWatchers.map(fn => fn()),
  ]);
}
