// @flow

import React from 'react';
import styled from 'styled-components';
import { LayoutHeader } from '../../../shared/components/LayoutHeader';
import { StageReactionList } from './StageReactionList';
import { StageSubstancePicker } from './StageSubstancePicker';
import { StageSubstanceEdit } from './StageSubstanceEdit';
import { pageLayoutAreas } from '../../../shared/components/PageLayout';

export const StyledPageLayout = styled('div')`
  --LayoutHeader-margin-v: 0;
  --LayoutHeader-margin-h: 0;
  --LayoutBlock-margin-h: 0;
  --LayoutBlock-margin-v: 0;
  --LayoutBlock-padding-h: 0;
  --LayoutBlock-padding-v: 0;
  
  grid-template-rows: 80px minmax(620px,1fr) var(--grid-spacer);
  
  .${pageLayoutAreas.header} {
    padding: var(--grid-spacer) 0;
  }

  .no-overflow {
    overflow: initial;
  }
  
  .${pageLayoutAreas.headerRightSide} {
    display: grid;
    grid-template: "deleteBtn stageIndicatorsBtn cancelBtn saveBtn";
    grid-column-gap: var(--grid-spacer);
    align-items: center;
    justify-self: end;
    .delete-btn {
      grid-area: deleteBtn;
      font-size: 22px;
    }
    .save-btn {
      grid-area: saveBtn;
    }
    .cancel-btn {
      grid-area: cancelBtn;
    }
  }
  
  .${pageLayoutAreas.headerLeftSide} {
    display: flex;
    align-items: center;
    .h2 { 
      margin-bottom: 0;
      display: flex;
      align-items: center;
      > span { margin-bottom: 4px }
    }
  }
  
  .${pageLayoutAreas.headerBackBtn} {
    display: flex;
    .fo-folder-back {
      margin: auto 0 auto auto;
    }
  }
  
  .${pageLayoutAreas.body} {
    grid-template-rows: calc((var(--StageSubstancePicker-padding-v) * 2) + var(--StageSubstancePickerItem-thumbnail-min-size) + var(--StageSubstancePickerItem-title-line-height) + 2 * var(--grid-spacer) + 2em) 1fr;
  }
  
  .${pageLayoutAreas.bodyRight} {
    border-left: 1px solid var(--grey-300);
  }
  
  .${pageLayoutAreas.bodyTopLeft} {
    border-bottom: 1px solid var(--grey-300);
  }
  
  .inputStageNumber {
    width: 80px;
    margin-left: var(--grid-spacer);
    margin-top: 6px;
  }
  
  .purification-count {
    margin-right: 1ch;
    margin-left: 1ch;
    width: 80px;
  }

  .stage-properties {
    border-bottom: 1px solid var(--grey-300);
    padding: calc(var(--grid-spacer) * 0.5) var(--grid-spacer);

    label {
      margin: 0;
      padding: 0;
    }
  }
`;
