import classNames from 'classnames';
import {IProject} from './ProjectType.js';
import {projectTypeEnum} from './enum';

export function getClassNameByProjectType(project: IProject) {
  return classNames({
    'fo-innovative-project': project?.Type === projectTypeEnum.innovative,
    'fo-generic-project': !project?.IsScaling && project?.Type === projectTypeEnum.generic,
    'fo-scaling-project': project?.IsScaling && project?.Type === projectTypeEnum.generic,
  });
}