// @flow

import type { ICompound } from '../../agents/models/i-agent';
import type { IAgentAlias } from './i-product';

export const ProductKeyNames = {
	Amount: 'Amount',
	Compound: 'Compound',
	CompoundId: 'CompoundId',
	Concentration: 'Concentration',
	Density: 'Density',
	Eq: 'Eq',
	FactualConsumption: 'FactualConsumption',
	Id: 'Id',
	IsDeleted: 'IsDeleted',
	IsInWork: 'IsInWork',
	IsMainProduct: 'IsMainProduct',
	LaboratoryId: 'LaboratoryId',
	Location: 'Location',
	MainAgentContent: 'MainAgentContent',
	Mass: 'Mass',
	MolarWeight: 'MolarWeight',
	Name: 'Name',
	NameAliases: 'NameAliases',
	ReactionId: 'ReactionId',
	ShortName: 'ShortName',
	Volume: 'Volume',
	ExperimentalMainAgentContent: 'ExperimentalMainAgentContent',
	Yield: 'Yield',
	IgnoreJarCreation: 'IgnoreJarCreation',
};
