// @flow

import {ResourceService, CRUDRequestsMap, HTTPMethodEnum} from '../../shared/utils/service-creator';
import type {ResourceRequestMethodType} from '../../shared/utils/service-creator';
import {User} from '../models/user';

interface IUsersResourceService {
  list: ResourceRequestMethodType,
  update: ResourceRequestMethodType,
  get: ResourceRequestMethodType,
  getSelf: ResourceRequestMethodType,
}

export const UsersResourceService: IUsersResourceService = ResourceService({
  baseURL: '/userRoles',
  defaultModel: User,
  useDefaultCRUDRequests: [
    CRUDRequestsMap.list,
    CRUDRequestsMap.update,
    CRUDRequestsMap.get
  ],
  requests: {
    [CRUDRequestsMap.update]: {
      method: HTTPMethodEnum.PUT,
      model: User,
      dataModel: User
    },
    getSelf: {
      url: '/self',
      method: HTTPMethodEnum.GET,
      model: User,
    }
  }
});
