// @flow

import React, { useRef, useEffect, useState } from 'react';
import bnc from 'bnc';
import Button from '@biocad/bcd-front-ui/controls/Button';
import Input from '@biocad/bcd-front-ui/controls/Input';
import { bncInstances } from '../../../shared/components/MultiValueInput';
import type { IFieldComponentProps } from '../../../shared/models';
import './index.less';
import { FormFieldLayout } from '../../../shared/components/FormFieldLayout';
import type { IAgentAlias } from '../../model';
import { Agent } from '../../../agents/models';
import { AgentAlias } from '../../model';
import { uuidv4 } from '../../../shared/utils/common';


interface IProductNameAliasesInputProps extends IFieldComponentProps {
	className?: string;
	label?: string;
}

const defaultProps: IProductNameAliasesInputProps = {
	className: '',
};

const
	BNCBlock = new bnc('b-ProductNameAliasesInput'),
	BNCGrid = BNCBlock.el('grid'),
	BNCBtn = BNCBlock.el('btn'),
	BNCName = BNCBlock.el('name'),
	BNCInput = BNCBlock.el('input')
;

let ProductNameAliasesInput = (props: IProductNameAliasesInputProps) => {
	// refs
	const
		inputRef = useRef<HTMLInputElement>(null)
	;

	// state
	const
		[inputValue, setInputValue] = useState('')
	;

	// Effects
	useEffect(() => {
		if (!props.input?.value) {
			props.input?.onChange([]);
		}
	}, [props.input?.value]);

	// Handlers
	const
		addItem = () => {
			if (inputValue && !props.input?.value?.find((item: IAgentAlias) => item.Name === inputValue)) {
				props.input?.onChange([
					...props.input?.value,
					AgentAlias({ Name: inputValue })
				]);
			}
			setInputValue('');
		},
		removeItem = (item: IAgentAlias) => {
			if (item) {
				props.input?.onChange(props.input?.value?.filter((i: IAgentAlias) => i !== item));
			}
		}
	;

	return (
		<FormFieldLayout label={props.label || 'Дополнительные наименования'}
		                 className={BNCBlock + props.className}
		                 childrenWrapClassName={BNCGrid.toString()}
		>
			<Input
				type={'text'}
				ref={inputRef}
				value={inputValue}
				onBlur={() => props.input?.onBlur()}
				onFocus={() => props.input?.onFocus()}
				onChange={(event) => setInputValue(event.target?.value)}
				placeholder={'Введите наименование'}
				className={BNCInput.toString()}
			/>
			<Button
				className={`${bncInstances.bBtn} ${bncInstances.bBtnAdd} ${BNCBtn} fo-plus`}
				view={'icon'}
				size={'L'}
				onAction={() => addItem()}
			/>
			{
				Array.isArray(props.input?.value) && props.input.value.map((item: IAgentAlias) => (
					<React.Fragment key={uuidv4()}>
						<div className={BNCName.toString()}>{ item.Name }</div>
						<Button
							className={`${bncInstances.bBtn} ${bncInstances.bBtnRemove} ${BNCBtn} fo-cross-thick`}
							view={'icon'}
							size={'L'}
							onAction={() => removeItem(item)}
						/>
					</React.Fragment>
				))
			}
		</FormFieldLayout>
	)
};

export {
	ProductNameAliasesInput
}
