import React from 'react';
import { ReactionTypeIcon } from '../../../../shared/components';
import styles from './styles.m.less';



export const ReactionNameWithType = React.memo(({ item }) => (
  <div className={`${styles.reaction}`}>
    <ReactionTypeIcon reaction={item} />
    <div>
      {item['Name']}
      {item['AdditionalName'] && <><br /><span className={`${styles.reaction__additionalName}`}>{item['AdditionalName']}</span></>}
    </div>
  </div>
));