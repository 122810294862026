// @flow

export interface IResourceError extends Error {
  toJSON(): { error: string }
}

export class ResourceError extends Error {
  toJSON() {
    return { error: this.message };
  }
}
