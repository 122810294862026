import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {LayoutBlock} from '../../../shared/components/LayoutBlock';
import CandidatesTable from '../../../candidates/components/CandidatesTable';
import {withRouter} from 'react-router-dom';
import CandidatesHeader from '../../../candidates/components/CandidatesTable/Header';
import ProductionSchemePanel from '../../../candidates/components/CandidatesTable/ProductionSchemePanel';
import Loader from '@biocad/bcd-front-ui/controls/Loader';
import queryString from 'query-string';
import {
    candidateModuleActions,
    candidatesResourceActions, descendantsResourceActions, folderModuleActions,
    folderResourceActions,
    projectActions,
    projectModuleActions
} from '../../actions';
import type {IFinaleCandidate, IProject} from '../../models';
import {folderParentType} from '../../types/enum';
import {
    getPathWithDescendants
} from '../../../shared/utils';
import {itemsToArraySelector} from '../../../shared/utils/selectors/resource-selectors';

const layoutBlockStyles = {
    position: 'relative',
    height: '84%',
    padding: 0,
    background: '#ffffff'
};

interface IProjectProps {
    candidatesPending: boolean,
    schemesPending: boolean,
    foldersPending: boolean,
    match?: {
        params: {
            reactionId: String
        },
    },
    location: {
        pathname: String,
        search: String
    },
    getCandidate: Function,
    setViewMode: Function,
    setCurrentProjectId: Function,
    getCurrentProject: Function,
    getCandidatesList: Function,
    clearCandidatesList: Function,
    clearFoldersList: Function,
    currentProject: IProject,
    getFoldersList: Function,
    getDescendantsList: Function,
    descendantsList: Object[],
    foldersPath: Object[],
    setCandidatesFolderPath: Function,
    currentProjectId: String,
    setCurrentCandidate: Function,
    clearProjectsList: Function,
}

const initialProps: IProjectProps = {
    candidatesPending: false,
    schemesPending: false,
    foldersPending: false,
    match: {},
    location: {},
    getCandidate: () => null,
    setViewMode: () => null,
    setCurrentProjectId: () => null,
    getCurrentProject: () => null,
    getCandidatesList: () => null,
    currentProject: null,
    getFoldersList: () => null,
    clearCandidatesList: () => null,
    clearFoldersList: () => null,
    getDescendantsList: () => null,
    descendantsList: [],
    foldersPath: [],
    setCandidatesFolderPath: () => null,
    currentProjectId: null,
    setCurrentCandidate: () => null
};

const Project = (props: IProjectProps = initialProps) => {
    const [folderId, setFolderId] = useState(null);

    useEffect(() => {
        clearLists();
        pageInit();

        return () => {
            clearLists();
        }
    }, []);

    useEffect(() => {        
        const urlParams = queryString.parse(props.location.search);
        const urlFolderId = urlParams.folderId;

        clearLists();
        props.getCandidatesList({
            projectId: props.location.pathname.split('/')[2],
            folderId: urlFolderId ? urlFolderId : folderId
        });

        props.getFoldersList({
            parentId: urlFolderId ? urlFolderId : (folderId || props.location.pathname.split('/')[2]),
            parentType: (folderId || urlFolderId) ?
              folderParentType.folder : folderParentType.project
        });
    }, [props.currentProjectId]);

    useEffect(() => {
        if (itemsToArraySelector(props.descendantsList).length && !props.foldersPath.length && folderId) {
            let path;
            let tempPath = [];

            path = getPathWithDescendants(itemsToArraySelector(props.descendantsList), folderId, props.currentProjectId);

            if (path[0]) {
                path.map(item => {
                    tempPath.push({
                        id: item.Id,
                        name: item.Name
                    });
                });

                props.setCandidatesFolderPath({
                    path: tempPath
                });
            }
        }
    }, [props.descendantsList]);

    const pageInit = () => {
        if (queryString.parse(props.location.search)) {
            let selectedId = queryString.parse(props.location.search)?.selectedId;  // Проверка на наличии в ссылке выделенной молекулы
            let isViewMode = queryString.parse(props.location.search)?.isViewMode;  // Проверка, показывать ли выделенную молекулу в полном размере
            let folderId = queryString.parse(props.location.search)?.folderId;  // Проверка, находимся ли мы в папке
            let projectId = props.location.pathname.split('/')[2];

            clearLists();

            projectId && props.setCurrentProjectId({
                id: projectId
            });
            folderId ? setFolderId(folderId) : setFolderId(null);

            projectId && props.getCurrentProject(projectId);
            if (selectedId && selectedId!='undefined') {
                props.getCandidate(selectedId);
            }
            isViewMode === 'true' && props.setViewMode({
                isViewMode: true
            });

            props.getDescendantsList({
                parentId: projectId,
                parentType: folderParentType.project
            });
        }
    };

    const clearLists = () => {
        props.clearCandidatesList();
        props.clearFoldersList();
        props.setCurrentCandidate({
            candidate: null
        });
    };

    return (
        <React.Fragment>

            <CandidatesHeader/>

            {
                props.candidatesPending ?
                    <Loader/> :

                    <LayoutBlock style={layoutBlockStyles}>

                        <CandidatesTable/>

                        <ProductionSchemePanel/>

                    </LayoutBlock>
            }

        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    const {resource, modules} = state;
    return {
        candidatesPending: resource.candidates && resource.candidates.pending,
        schemesPending: resource.productionScheme && resource.productionScheme.pending,
        descendantsList: resource.descendants,
        foldersPath: modules.folder.path,
        currentProjectId: modules.project.id,
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        (dispatch) => ({
            getCandidate: (id: number) => dispatch(candidatesResourceActions.get.request({uriParams: {id}})),
            setViewMode: (isViewMode: boolean) => dispatch(candidateModuleActions.setViewMode(isViewMode)),
            setCurrentProjectId: (id: number) => dispatch(projectModuleActions.setCurrentProjectId(id)),
            getCurrentProject: (id: number) => dispatch(projectActions.get.request({uriParams: {id}})),
            getCandidatesList: (params) => dispatch(candidatesResourceActions.list.request({params})),
            getFoldersList: (params) => dispatch(folderResourceActions.list.request({params})),
            clearCandidatesList: () => dispatch(candidatesResourceActions.destroy()),
            clearFoldersList: () => dispatch(folderResourceActions.destroy()),
            getDescendantsList: (params) => dispatch(descendantsResourceActions.list.request({params})),
            setCandidatesFolderPath: (path) => dispatch(folderModuleActions.setFolderCandidatePath(path)),
            setCurrentCandidate: (candidate) => dispatch(candidateModuleActions.setCurrentCandidate(candidate))
        })
    )(Project)
);

