// @flow

import {
  resourceActions,
} from '../../shared/utils';


export const TEST_STATUSES_ROOT_NAME = 'TEST_STATUSES';
export const testStatusesResourceActions = {
  ...resourceActions(TEST_STATUSES_ROOT_NAME),
};

export const SM_TYPES_ROOT_NAME = 'SM_TYPES';
export const smTypesResourceActions = {
  ...resourceActions(SM_TYPES_ROOT_NAME),
};

export const TEST_CLASSES_ROOT_NAME = 'TEST_CLASSES';
export const testClassesResourceActions = {
  ...resourceActions(TEST_CLASSES_ROOT_NAME),
};