// @flow
import { accountActions } from '../actions';
import type { IRootStore } from '../../app/reducers';

export interface AccountState {
  pending: boolean;
  data: ?AccountData;
  roles: ?string[];
  token: ?string;
  type: string;
}

interface AccountData {
  Name: string;
  FirstName: string;
  Surname: string;
  Email: string;
  DisplayName: string;
  UserName: string;
}

const defaultState: AccountState = {
  pending: false,
  data: {
    Name: '',
    FirstName: '',
    Surname: '',
    Email: '',
    DisplayName: '',
    UserName: '',
  },
  roles: null,
  token: null,
  type: '',
};

export const accountReducer = (state: AccountState = defaultState, action: any): AccountState => {
  return {
    [accountActions.login.success]: {
      ...state,
      pending: action.pending,
      data: action.result?.Account,
      roles: action.result?.Roles,
      token: action.result?.Token,
      type: action.result?.AuthType,
    },
    [accountActions.logout.success]: {
      ...state,
      pending: true,
      data: null,
      roles: [],
      token: null,
    },
    [accountActions.get.failure]: {
      ...state,
      pending: false,
      data: null,
      roles: [],
    },
    [accountActions.get.success]: {
      ...state,
      pending: false,
      data: action.result?.data,
      roles: action.result?.roles,
    },
    [accountActions.setToken]: {
      ...state,
      token: action.token,
    },
  }[action.type] || state;
};

// Selectors
export const isAuthorized = (state: any) => state.global.account?.token?.length > 0;
export const getToken = (state: any) => state.global.account?.type + ' ' + state.global.account?.token;
export const getAuthType = (state: any) => state.global.account?.type;
export const getUserName = (state: IRootStore) => state.global.account.data?.UserName;
export const getUserRoles = (state: IRootStore) => state.global.account.roles;
