import {
    put,
    takeEvery,
    fork,
    select,
} from 'redux-saga/effects';
import {FolderService} from '../services';
import {folderSchemesResourceActions} from '../actions';
import {getToken} from '../../account/reducers';

export function* getSchemeFoldersList(params) {
    const token = yield select(getToken);
    const service = new FolderService(token);
    try {
        const result = yield service.getList(params);

        yield put(folderSchemesResourceActions.list.success(result));
    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(folderSchemesResourceActions.list.failure({error: e.response.data['ErrorMessage']}));
        }
    }
}

export function* createSchemeFolder(data) {

    const token = yield select(getToken);

    const service = new FolderService(token);
    try {
        const result = yield service.create(data);

        yield put(folderSchemesResourceActions.create.success(result));
    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(folderSchemesResourceActions.create.failure({error: e.response.data['ErrorMessage']}));
        }
    }
}

export function* updateSchemeFolder(data) {

    const token = yield select(getToken);

    const service = new FolderService(token);
    try {
        const result = yield service.update(data);

        yield put(folderSchemesResourceActions.update.success(result));
    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(folderSchemesResourceActions.update.failure({error: e.response.data['ErrorMessage']}));
        }
    }
}

export function* deleteSchemeFolder(params) {

    const token = yield select(getToken);

    const {id, parentId, parentType} = params;

    const service = new FolderService(token);
    try {
        const {result} = yield service.delete(id);

        if(!result.IsSuccess) {
            yield put(folderSchemesResourceActions.create.failure({
                error: 'Невозможно удалить папку. Папка не является пустой'
            }));
        }

        yield fork(getSchemeFoldersList, {parentId, parentType});

    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(folderSchemesResourceActions.delete.failure({error: e.response.data['ErrorMessage']}));
        }
    }
}

export const folderSchemesWatchers = [

    function* watchFolderList() {
        yield takeEvery(folderSchemesResourceActions.list.request.type, function* (action) {
            const {params} = action;

            yield fork(getSchemeFoldersList, params);
        });
    },

    function* watchCreateFolder() {
        yield takeEvery(folderSchemesResourceActions.create.request.type, function* (action) {
            const {data} = action;

            yield fork(createSchemeFolder, data);
        });
    },

    function* watchUpdateFolder() {
        yield takeEvery(folderSchemesResourceActions.update.request.type, function* (action) {
            const {data} = action;

            yield fork(updateSchemeFolder, data);
        });
    },

    function* watchDeleteFolder() {
        yield takeEvery(folderSchemesResourceActions.delete.request.type, function* (action) {
            const {data} = action;

            yield fork(deleteSchemeFolder, data);
        });
    }

];
