import getLocale from '../getLocale';

const DEFAULT_LOCALE = getLocale('ru');

const translates = {
    'ru': 'Некоректная дата',
    'en': 'Invalid date',
};

/**
 * @param {value} Any
 * @return {boolean} if value is valid Date object
 */
export const isValidDate = (value) => (value instanceof Date && !Number.isNaN(value.valueOf()));

/**
 * @param {value} Any
 * @return {boolean} if value is Number
 */
export const isNumber = (value) => (!Number.isNaN(value) && Number.isFinite(value));

const formatMap = {
    full: { year: 'numeric', month: 'long', day: 'numeric',  },
    short: { year: 'numeric', month: 'short', day: 'numeric' },
    daymonth: { month: 'long', day: 'numeric' },
    monthyear: { year: 'numeric', month: 'long' },
    compact: { year: 'numeric', month: 'numeric', day: 'numeric' },
    weekday: { weekday: 'long' },
    time: { hour: '2-digit', minute: '2-digit' },
};

/**
 * @typedef {('full' | 'short' | 'daymonth' | 'monthyear' | 'compact' | 'weekday' | 'time')} FormatEnum
 */
/**
 * @param {Object} [options] - DateTimeFormat options
 * @param {FormatEnum} [options.format = 'compact'] - format
 * @param {boolean} [options.time = false] - show time
 * @param {boolean} [options.second = false] - show second (only if time is true or format is 'time')
 * @param {boolean | 'short' | 'long'} [options.weekday = false] - show weekday
 * @return {Object} options for DateTimeFormat
 * see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
 * extended with option.locale
 */

const formatToIntlDateTimeFormat = ({format, time, second, weekday}) => {
    let opt = formatMap[format] || formatMap.compact;
    if (format === 'time' || time) {
        opt = {...opt, ...formatMap.time};
        if (second) {
            opt = {...opt, second: '2-digit' };
        }
    }
    if (weekday === 'short') {
        opt = {...opt, weekday: 'short'};
    } else if (format === 'weekday' || weekday === true || weekday === 'long') {
        opt = {...opt, weekday: 'long'};
    }

    return opt;
};

/**
 * @param {Date | number} value - Date or unix timestamp
 * @param {string} [options.locale] - locale
 * @param {FormatEnum} [options.format = 'compact'] - format see FormatEnum
 * @param {boolean} [options.time = false] - show time
 * @param {boolean} [options.second = false] - show second (only if time is true or format is 'time')
 * @param {boolean | 'short' | 'long'} [options.weekday = false] - show weekday
 * @return {string} formatted date-time
 */
const formatDateTime = (value, options = {}) => {
    const {locale=DEFAULT_LOCALE, format, time, second, weekday} = options;
    const formatOptions = formatToIntlDateTimeFormat({format, time, second, weekday});

    if (isValidDate(value)) {
        return value.toLocaleString(locale, formatOptions);
    } else if (isNumber(value)) { // unix timestamp
        return (new Date(value * 1e3)).toLocaleString(locale, formatOptions);
    }

    return translates[locale] || translates['en'];
};


export default formatDateTime;