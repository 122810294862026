// @flow

const parseInputValueToNumber = (value: string, cb: (event: any)=>null) => {
  if (Array.from(value).filter(i => i === '.').length > 1) {
    return cb(value.replace(/^([\de+-.]+)[.]([\de+-]+)/gi, '$1$2'));
  }
  if (Array.from(value).includes(',')) {
    return cb(value.replace(/[,]/gi, '.'));
  }
  if (value.length > 1 && value.endsWith('0')) {
    return cb(value);
  }
  if(value === '-') {
    return cb('-');
  }
  const passedValue = Number(value);
  if (isNaN(passedValue) || (value !== '0' && passedValue === 0)) {
    return cb('');
  }
  return cb(passedValue);
};

export const parseInputValueToNumberOnBlur = (event: Event, cb: (event: any)=>null) => {
  const value: string = event?.target?.value;
  if (value.endsWith('.')) {
    return cb(value.replace(/^(.*)[.]$/gi, '$1'));
  }
  if (value.length > 1 && value.endsWith('0')) {
    return cb(Number(value));
  }
  parseInputValueToNumber(value, cb);
};

export const parseInputValueToNumberOnChange = (event: Event, cb: (event: any)=>null) => {
  const value: string = event?.target?.value;
  event.preventDefault();
  if (value.endsWith('.') || (value.length > 1 && value.endsWith('0')) || value.endsWith('-')) {
    return cb(value);
  }
  parseInputValueToNumber(value, cb);
};

const _allowedControlKeys = [
    'Esc', 'Escape', 'Backspace', 'Left', 'ArrowLeft', 'Right',
    'ArrowRight', 'Enter', 'Home', 'End', 'Tab', 'CapsLock',
    'PrintScreen', 'Insert', 'Delete',
]
export const preventNonNumericOnKeyDown = (event: KeyboardEvent) =>
{
  if (event.defaultPrevented) return;
  const value = event.target?.value;
  const caretPosition = event.target?.selectionStart;
  const
      isNumber        = !isNaN(event.key),
      isNegativeSign  = caretPosition === 0 && event.key === '-',
      isPeriodSign    = value?.indexOf('.') === -1 && ['.', ','].includes(event.key),
      isControlKey    = _allowedControlKeys.includes(event.key),
      isCopyPasting   = event.ctrlKey && ['c', 'v'].includes(event.key),
      isSelectingAll  = event.ctrlKey && event.key === 'a',
      isUndoing       = event.ctrlKey && event.key === 'z'
  ;
  if (isNumber || isNegativeSign || isPeriodSign || isControlKey || isCopyPasting || isSelectingAll || isUndoing)
  {
    return;
  }
  event.preventDefault();
}

export const _noop = ()=>null;

export function uuidv4()
{
  if ('crypto' in window && 'getRandomValues' in window.crypto) {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ window.crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }
  else {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}

export const formInputValueType = (...types) => (
    [ ...types, String, null, undefined ]
);
