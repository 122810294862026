import styled from 'styled-components';
import {amountColorIndicators, AmountRemainsIndicatorEnum} from '../../../utils';

export const AgentsSubTableStyled = styled.table`
  margin: calc(var(--grid-spacer) * 0.75) 0;
  border-left: 1px solid var(--table-border-color);
  border-right: 1px solid var(--table-border-color);

  .icon{
    font-size: 16px;
    line-height: 16px;
    margin-right: 4px;
  }

  .completed{
    color: var(--hasYieldColor);
  }

  .uncompleted{
    color: var(--uncompletedColor);
  }
  
  .grid-table-row-wrap.disabled {
    .grid-table-row {
      &:first-child::before {
        width: 70px;
        content: 'В заказе';
        position: absolute;
        color: #fff;
        top: 0;
        left: 0;
        z-index: 2;
        background: var(--grey-800);
        padding: 4px 6px;
        border-radius: 0 0 4px 0;
      }
      background-color: var(--grey-500);
      position: relative;
      cursor: not-allowed;
      user-select: none;
      border-left: 1px solid var(--grey-500);
      border-right: 1px solid var(--grey-500);
      color: var(--grey-700);
    }
  }

  .file-name{
    width: 100%;
    min-height: 0;
    text-overflow: ellipsis;
    justify-content: flex-start;

    &:last-child{
      margin-top: 2px;
    }

    span {
      width: 100%;
      text-align-last: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .attachments{
    width: 100px;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
  }
  
  .table-cell-jar-users {
    flex-direction: column;
  }

  .table-cell-jar-users a {
    text-decoration: none;
    color: inherit;
  }

  .table-cell-jar-users a:hover {
    text-decoration: underline;
  }
  
  .${amountColorIndicators[AmountRemainsIndicatorEnum.Empty].className} > *
  {
    background-color: var(--red-o-15);
  }
  .${amountColorIndicators[AmountRemainsIndicatorEnum.Low].className} > *
  {
    background-color: var(--orange-o-5);
  }
  .${amountColorIndicators[AmountRemainsIndicatorEnum.Medium].className} > *
  {
    background-color: var(--yellow-o-5);
  }
  .${amountColorIndicators[AmountRemainsIndicatorEnum.Full].className} > *
  {
    background-color: var(--green-o-20);
  }
  .jar-amount-remains--ignore-spending > * 
  {
    background-color: var(--green-o-40);
  }
`;
