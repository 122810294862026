import React, { useRef } from 'react';
import type { IFieldComponentProps } from '../../models';
import { Editor } from '@tinymce/tinymce-react';
import {_noop} from "../../utils/common";
import './index.less';

interface IWysiwygEditorFieldComponentProps extends IFieldComponentProps {
  isDisabled?: boolean,
  initOptions?: {}
}

const defaultTinyMCEOptions = {
  plugins: [
    'table charmap lists lineheight link autolink fullscreen'
  ],
  min_height: 350,
  resize: false,
  toolbar: `undo redo | 
              subscript superscript bold italic underline lineheightselect | 
              alignleft aligncenter alignright alignjustify | 
              forecolor backcolor | 
              numlist bullist | 
              fontsizeselect | 
              table | 
              charmap link |
              fullscreen`,
  extended_valid_elements: 'a[target=_self|target=_blank|rel|rev|charset|hreflang|tabindex|accesskey|type|name|href|title|class|onfocus|onblur]',
  default_link_target: '_blank',
  target_list:[
    { text: 'В новой вкладке', value: '_blank' },
    { text: 'В текущей вкладке', value: '_self' }
  ],
  language: 'ru',
  menubar: false,
  statusbar: false,
  branding: false,
  charmap: [
    ['945', ''],
    ['946', ''],
    ['947', ''],
    ['948', ''],
    ['956', ''],
    ['176', ''],
    ['8212', ''],
    ['8804', ''],
    ['8805', ''],
    ['8594', ''],
    ['8592', ''],
    ['177', ''],
    ['8800', ''],
    ['8776', ''],
    ['916', ''],
    ['951', ''],
    ['931', ''],
    ['961', ''],
    ['963', ''],
    ['960', ''],
    ['197', ''],
    ['937', ''],
    ['969', ''],
    ['1014', ''],
    ['1069', ''],
    ['215', ''],
  ],
  fontsize_formats: '8pt 10pt 11pt 12pt 14pt 18pt 24pt 36pt'
};

export const WysiwygEditorFieldComponent = (props: IWysiwygEditorFieldComponentProps) => {

  // Consts
  const debounceTimeout = 10;

  // Refs
  const
      timerId = useRef(null)
  ;

  // Methods
  const
    onChange = (event: any) => {
      const content = event?.target?.getContent() || null;
      if (props.input?.onChange) {
        props.input.onChange(content);
      }
    },
    debounceChange = (value: string) => {
      clearTimeout(timerId.current);

      timerId.current = setTimeout(() => {
        props.input?.onChange(value);
      }, debounceTimeout);
    }
  ;

  const initOptions = {
    ...defaultTinyMCEOptions,
    ...props.initOptions,
  }

  return (
      <div className={'b-WysiwygEditor'}>
        <Editor
            disabled={typeof props.isDisabled === 'boolean' ? props.isDisabled : false}
            init={initOptions}
            value={props.input?.value || ''}
            onChange={onChange}
            onBlur={props.input?.onBlur || _noop}
            onFocus={props.input?.onFocus || _noop}
            onEditorChange={props.input?.onChange ? debounceChange : _noop}
            tinymceScriptSrc={'./tinymce/tinymce.js'}
        />
      </div>
  )
};
