// @flow
import React from 'react';

import Datepicker from '@biocad/bcd-front-ui/controls/Datepicker';

export const BcdDatePickerWrapper = ({input, meta: {touched, error}, ...props}) => {
  return(
    <>
      <Datepicker
        {...input}
        {...props}
        onChange={(event) => {
          input.onChange(event);
        }}
      />
      {touched && error && <span>{error}</span>}
    </>
  );
};
