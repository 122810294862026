import { useState, useEffect } from 'react';
import queryString from 'query-string';

export const useQueryParams = (string) => {
  const [queryParams, setQueryParams] = useState(null);
    
  useEffect(() => {
    setQueryParams(queryString.parse(string));
  }, [string]);

  return queryParams;
};
