// @flow
import { globalAction } from '../../shared/utils/action-creators';

export const accountActions = {
  setToken: globalAction('ACCOUNT', ['TOKEN', 'SET'], ({ token }) => ({ token })),
  login: {
    request: globalAction('ACCOUNT', ['LOGIN', 'REQUEST'], ({data}) => ({
      data,
      pending: true,
      fail: false,
    })),
    success: globalAction('ACCOUNT', ['LOGIN', 'SUCCESS'], ({result}) => ({
      result,
      pending: false,
      fail: false,
    })),
    failure: globalAction('ACCOUNT', ['LOGIN', 'FAILURE'], ({error}) => ({
      error,
      pending: false,
      fail: true,
    })),
  },
  loginOnStart: {
    request: globalAction('ACCOUNT', ['LOGIN_ON_START', 'REQUEST'], ({data}) => ({
      data: data,
      pending: true,
      fail: false,
    })),
    success: globalAction('ACCOUNT', ['LOGIN_ON_START', 'SUCCESS'], ({result}) => ({
      result,
      pending: false,
      fail: false,
    })),
    failure: globalAction('ACCOUNT', ['LOGIN_ON_START', 'FAILURE'], ({error}) => ({
      error,
      pending: false,
      fail: true,
    })),
  },
  logout: {
    request: globalAction('ACCOUNT', ['LOGOUT', 'REQUEST'], () => ({
      pending: true,
      fail: false,
    })),
    success: globalAction('ACCOUNT', ['LOGOUT', 'SUCCESS'], ({result}) => ({
      result,
      pending: false,
      fail: false,
    })),
    failure: globalAction('ACCOUNT', ['LOGOUT', 'FAILURE'], ({error}) => ({
      error,
      pending: false,
      fail: true,
    })),
  },
  get: {
    request: globalAction('ACCOUNT', ['GET', 'REQUEST'], () => ({
      pending: true,
      fail: false,
    })),
    success: globalAction('ACCOUNT', ['GET', 'SUCCESS'], ({result}) => ({
      result,
      pending: false,
      fail: false,
    })),
    failure: globalAction('ACCOUNT', ['GET', 'FAILURE'], ({error}) => ({
      error,
      pending: false,
      fail: false,
    })),
  },
};
