// @flow

import React, { useState } from 'react';
import bnc from 'bnc';
import type { IReaction } from '../../../models';
import { ReactionTypeIcon } from '../../../../shared/components/ReactionTypeIcon';
import { _noop } from '../../../../shared/utils/common';
import CommentWrapper from '../../../../shared/components/CommentWrapper';
import type { CommentWrapperOnSaveReturnObject } from '../../../../shared/components/CommentWrapper';
import Button from '@biocad/bcd-front-ui/controls/Button';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { userCRUDPermissionsSelector } from '../../../../account/reducers/users-modules-reducer';
import type { IRootStore } from '../../../../app/reducers';
import { RolesMap } from '../../../../account/models/roles-map';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import {SelectFolder} from '../../../../folders/components/SelectFolder' 

import './index.less';


interface IReactionItem {
  reaction: IReaction,
  isBestReaction?: ?boolean,
  bestReactionAvailable: boolean,
  highlightReaction: boolean,
  onBestReactionIconClick: (number) => void,
  selectFolder: (number, number) => void,
  onCommentSave: (CommentWrapperOnSaveReturnObject) => void,  
  userPermissions?: {},
  roles?: string[],
  userName?: string,
  folders?: IFolder[]
}

const defaultProps: IReactionItem = {
  reaction: null,
  isBestReaction: null,
  bestReactionAvailable: true,
  onBestReactionIconClick: _noop,
  selectFolder: _noop,
  onCommentSave: _noop,
};

const
  BNCBLock = new bnc('b-ReactionItem'),
  BNCReactionTitle = BNCBLock.el('title'),
  BNCReactionTitleHighlighted = BNCReactionTitle.mod('highlighted'),
  BNCControlIcon = BNCBLock.el('controlIcon'),
  BNCControlIconStar = BNCControlIcon.mod('star'),
  BNCControlIconComment = BNCControlIcon.mod('comment'),
  BNCControlIconCopy = BNCControlIcon.mod('copy'),
  BNCYieldData = BNCBLock.el('yieldData'),
  BNCAdditionalName = BNCBLock.el('additionalName');

const
  BNCNestedPurifications = new bnc('b-NestedPurifications'),
  BNCNestedPurificationsTitle = BNCNestedPurifications.el('title'),
  BNCNestedPurificationsIcon = BNCNestedPurifications.el('icon'),
  BNCNestedPurificationsItem = BNCNestedPurifications.el('item'),
  BNCNestedPurificationsIconOpen = BNCNestedPurificationsIcon.mod('open');

const NestedPurificationList = ({ purifications }: { purifications: [] } = { purifications: [] }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={BNCNestedPurifications}>
      <span className={BNCNestedPurificationsTitle}>
        <Button className={isOpen ? 'fo-up-open' : 'fo-down-open'} view={'icon'} size={'S'} onAction={() => setIsOpen(!isOpen)} />
        Реакции очистки:
      </span>
      {
        isOpen
          ? <div>
            {
              purifications.map((pr, index) =>
                <div className={BNCNestedPurificationsItem} key={pr.Id}>
                  <Link to={`/reactions/${pr.Id}`} key={pr.Id}>
                    <ReactionTypeIcon reaction={pr} className={BNCNestedPurificationsIconOpen} />
                    <span>{pr.Name}</span>
                  </Link>
                </div>
              )
            }
          </div>
          : <span>
            {
              purifications.map(pr =>
                <Link to={`/reactions/${pr.Id}`} key={pr.Id}>
                  <ReactionTypeIcon reaction={pr} className={BNCNestedPurificationsIcon} />
                </Link>
              )
            }
          </span>
      }
    </div>
  );
};

let ReactionItem = (props: IReactionItem = defaultProps) => (
  <div className={BNCBLock}>
    <Link
      className={classNames(BNCReactionTitle.toString(), {
        [BNCReactionTitleHighlighted]: props.highlightReaction
      })}
      to={`/reactions/${props.reaction.Id}`}
    >
      <ReactionTypeIcon reaction={props.reaction} />
      <span>{props.reaction?.Name} {props.reaction?.AdditionalName && <span className={BNCAdditionalName}>({props.reaction?.AdditionalName})</span>}</span>
    </Link>
    <Button className={`${BNCControlIcon + BNCControlIconCopy} fo-copy`}
      view={'icon'}
      size={'L'}
      disabled={!props.userPermissions.reaction.CREATE}
      onAction={() => props.history.push(`/reactions/${props.reaction.Id}/clone?stageId=${props.stageId}`)}
    />    
    {
      props.bestReactionAvailable &&
      <Button className={`${BNCControlIcon + BNCControlIconStar} ${props.isBestReaction ? 'fo-star-fill' : 'fo-star'}`}
        view={'icon'}
        size={'L'}
        disabled={!props.userPermissions.reaction.UPDATE}
        onAction={() => props.onBestReactionIconClick(props.reaction?.Id)}
      />
    }
    
    <CommentWrapper item={props.reaction}
      saveComment={props.onCommentSave}
      iconClassName={BNCControlIcon + BNCControlIconComment}
      preventCloseOnSave={true}
      readonly={
        !props.roles?.includes(RolesMap.ProjectLead) && // может редактировать руководитель проекта
        props.reaction.AuthorUserName !== props.userName && // может редактировать автор реакции
        ((!props.userPermissions.reactionMiniComment.UPDATE) ||
          !(props.roles?.includes(RolesMap.User) && !props.reaction.IsCompleted))
      }
    />
    <div className={BNCYieldData}>
      <span>{
        ('TheoreticalYield' in props.reaction && typeof props.reaction.TheoreticalYield === 'number')
          ? props.reaction.TheoreticalYield.toFixed(2)
          : '-'
      }</span>
      <span>{
        ('ExperimentalYield' in props.reaction && typeof props.reaction.ExperimentalYield === 'number')
          ? props.reaction.ExperimentalYield.toFixed(2)
          : '-'
      }</span>
      <span>{
        ('ExperimentalPercentYield' in props.reaction && typeof props.reaction.ExperimentalPercentYield === 'number')
          ? (props.reaction.ExperimentalPercentYield * 100).toFixed(2)
          : '-'
      }%</span>
    </div>
    {
      props.reaction.NestedPurifications &&
      props.reaction.NestedPurifications.length > 0 &&
      <NestedPurificationList purifications={props.reaction.NestedPurifications} />
    }
  </div>
);

ReactionItem = connect(
  (state: IRootStore) => ({
    userPermissions: userCRUDPermissionsSelector(state),
    roles: state.global.account.roles,
    stageId: state.modules?.scheme?.editScheme?.selectedStageId || state.modules?.stage?.editStage?.current?.Id,
  }),
  null
)(ReactionItem);

ReactionItem = withRouter(ReactionItem);

export {
  ReactionItem
};
