// @flow

import { resourceReducer, setResourceRequestState, setResourceSuccess, setResourceFailState } from '../../shared/utils/reducer-creators';
import { _temp_folderResourceAction } from '../actions';

export const _temp_folderResourceReducer = resourceReducer(_temp_folderResourceAction, {
  [_temp_folderResourceAction.getDescendants.request]: setResourceRequestState,
  [_temp_folderResourceAction.getDescendants.success]: setResourceSuccess,
  [_temp_folderResourceAction.getDescendants.failure]: setResourceFailState,
});
