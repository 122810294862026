// @flow
import styled from 'styled-components';

export const AgentSearchFormLayout = styled.div`
  display: grid;
  grid-template-areas: "searchOption     searchField      isInStockOnly    submitBtn       "
                       "labDropdown      checkboxes       checkboxes       checkboxes      "
                       "jarAmountRemains jarAmountRemains jarAmountRemains jarAmountRemains"
                       "rangeSlider      rangeSlider      rangeSlider      rangeSlider     "
  ;
  grid-template-columns: 200px 1fr 200px 150px;
  grid-column-gap: var(--grid-spacer);
  grid-row-gap: calc(var(--grid-spacer) * 0.5);
  align-items: center;

  & .searchOption {
    grid-area: searchOption; 
  }
  & .searchField { 
    grid-area: searchField;    
  }
  & .isInStockOnly {
    grid-area: isInStockOnly;
  }
  & .isStrictSearch {
    grid-area: isStrictSearch;
  }
  & .submitBtn {
    grid-area: submitBtn;
  }
  & .labDropdown {
    grid-area: labDropdown;
  }
  & .checkboxes {
    grid-area: checkboxes;
    display: flex;
    align-items: center;
  }
  & .isPrecursor {
    grid-area: isPrecursor;
  }
  & .isIgnoringSpending {
    grid-area: isIgnoringSpending;
  }
  & .jar-amount-remains {
    grid-area: jarAmountRemains;
    margin: 8px 0;
    display: flex;
    align-items: center;
    &-label {
      margin-bottom: 0;
      margin-right: 8px;
    }
    &-tags-label {
      margin-bottom: 0;
      margin-right: 8px;
      margin-left: 8px;
    }
    &-inner {
      white-space: nowrap;
      padding: 0 10px 0 0;
      display: flex;
      align-items: center;
    }
    & .b-FormFieldLayout {
      width:250px;
      padding: 0;
    }
  }
  & .range-slider {
    grid-area: rangeSlider;
  }
  &.searchType-ByStructure {
    grid-template-areas: "searchOption     isStrictSearch   isInStockOnly    .                submitBtn       "
                         "labDropdown      checkboxes       checkboxes       checkboxes       checkboxes      "
                         "jarAmountRemains jarAmountRemains jarAmountRemains jarAmountRemains jarAmountRemains"
                         "searchField      searchField      searchField      searchField      searchField     "
                         "rangeSlider      rangeSlider      rangeSlider      rangeSlider      rangeSlider     "
    ;
    grid-template-columns: 200px 200px 200px 1fr 150px;
    grid-template-rows: repeat(3, auto) 400px;

    & .isAntibody,
    & .isPolymer {
      display: none;
    }
  }
`;
