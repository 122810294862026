// @flow

import React, { useEffect, useRef, useState } from 'react';
import Suggest, { SuggestText } from '@biocad/bcd-front-ui/controls/Suggest';
import type { OptionType } from '../../models/option-type';
import type {IFieldComponentProps} from '../../models';
import {
    FormFieldLayout
} from '../FormFieldLayout';
import type { IFormFieldLayoutProps } from '../FormFieldLayout';


import './index.less';


export interface BcdSuggestProps extends IFormFieldLayoutProps, IFieldComponentProps {
    defaultValue?: OptionType | string,
    options: OptionType[] | string[],
    placeholder?: string,
    onInput?: typeof Function,
    SelectedItem?: typeof Function,
    SuggestedItem?: typeof Function,
    FilterSuggest?: typeof Function,
    multiple?: boolean,
    disabled?: boolean,
    loading?: boolean,
    className?: string,
    markPristineValidity?: boolean,
    wrapperStyle?: {},
    wrapperClassName?: string,
    useFormFieldLayout?: boolean,
    disabled?: boolean
}

const
    FilterSuggestProp = (options: OptionType[], inputValue: string) => {
        const re = new RegExp(inputValue,'gi');
        return re
            ? options.filter((option) => option.label.match(re) !== null)
            : []
        ;
    },
    SelectedItem = ({option}) => (
        <div className={Suggest.block.el('selected-label') }>
            { option?.label || '' }
        </div>
    ),
    SuggestedItem = (item, {caseInsensitive = true}) => (
        <div
            className={Suggest.block.el('suggested-label')}
            {
                ...SuggestText.highlight(
                    {
                        option: item?.option?.label || item?.option,
                        selected: item.selected,
                        inputValue: item.inputValue,
                    },
                    {
                        caseInsensitive
                    }
                )
            }
        />
    )
;

export const BcdSuggestWrapper = (props: BcdSuggestProps) =>
{

    const suggestRef = useRef();
    // Так вот приходится передавать начальное значение контрола, defaultValue работает только при инициализации
    // компонента, то есть если значение получено после отрисовки контрола, то оно проваливается в никуда.
    const _explicitlySetInitialSelectedValue = () => {
        if (!!props.input.value && !Array.isArray(props.input.value))
        {
            suggestRef.current?.setSelected([props.input.value], props.multiple);
        }
        if (Array.isArray(props.input.value) && props.input.value.length === 0)
        {
            if (suggestRef.current?.selected.current == null || suggestRef.current?.selected == []) return;
            suggestRef.current?.setSelected([], props.multiple);
        }
        
    };
    useEffect(
        _explicitlySetInitialSelectedValue,
        [
            (typeof props.input.value === 'object' && !Array.isArray(props.input.value))
                ? props.input.value?.value
                : props.input.value
        ]
    );

    let _valid: boolean;
    if (!props.markPristineValidity) {
        _valid = (props.meta?.valid === false || props.meta?.warning) && props.meta?.touched === true ? false : undefined;
    }
    else {
        _valid = (props.meta?.valid && !props.meta?.warning) ? undefined : false;
    }

    const
        bcdProps: BcdSuggestProps = {
            defaultValue: props.defaultValue,
            options: props.options,
            placeholder: props.placeholder,
            onInput: props.onInput,
            multiple: props.multiple,
            disabled: props.disabled,
            loading: props.loading,
            className: _valid === undefined ? props.className : `${props.className} b-suggestWrap-invalid`,
        },
        formFieldLayoutProps = {
            style: props.wrapperStyle,
            className: props.wrapperClassName,
            invalidMessage: props.meta?.error || props.meta?.warning,
            valid: _valid,
            hint: props.hint,
            label: props.label,
            required: props.required,
            disabled: props.disabled,
        }
    ;

    const renderBcdSuggest = () => (
        (props.input) ?
        <Suggest {...bcdProps}
                 {...props.input}
                 valid={_valid}
                 onChange={
                     (value: OptionType[]) => 
                     {
                        Array.isArray(value) && value.length > 0
                        ? (props.multiple 
                            ? props.input.onChange(value.map(i => i.value)) 
                            : props.input.onChange(value[0].value))
                        : props.input.onChange(null)
                     }
                 }
                 FilterSuggest={FilterSuggestProp}
                 SelectedItem={SelectedItem}
                 SuggestedItem={SuggestedItem}
                 ref={suggestRef}
                 disabled={props.disabled}
        /> : ''
    );
    return (
        props.useFormFieldLayout
            ? <FormFieldLayout {...formFieldLayoutProps}>{ renderBcdSuggest() }</FormFieldLayout>
            : renderBcdSuggest()
    );
};
