// @flow

import { IReagent } from '../../reagents/model/i-reagent';
import { IReaction } from '../models/i-reaction';
import { IProduct } from '../../products/model/i-product';
import { ReagentTypeEnum } from '../../reagents/model/reagent-type-enum';
import { projectTypeEnum } from '../../projects/types/enum/ProjectTypeEnum';
import { ReactionTypeEnum } from '../models/reaction-type-enum';
import { REACTION_TYPE_PURIFICATION_ID } from '../../shared/globals/constants';
import { formatFloat } from '../../shared/utils/value-formatters';
import type { IReactionYield } from '../models';
import { isSolvent } from "./common";

export type RecalcFunctionArgs = {
    reagentList: IReagent[],
    productList: IProduct[],
    reaction: IReaction,
    selectedReagent?: IReagent,
    selectedProduct?: IProduct,
    skipParent: Boolean
}

export type RecalcFunction = (entry: RecalcFunctionArgs, next: RecalcFunction) => RecalcFunctionArgs;

// Helper functions
function _recalcVolume(concentration?: number, amount?: number, mass?: number, density?: number) {
    if (concentration) {
        return parseFloat(((amount / concentration) * 1000).toFixed(3));
    } else {
        return parseFloat((mass / density).toFixed(3));
    }
}

function _getReferencedReagent(reagentList: IReagent[]): IReagent {
    let referenced = reagentList.find((reagent: IReagent) => reagent.IsRefCompound === true);
    if (referenced) {
        return referenced;
    }

    let required = reagentList.find((reagent: IReagent): RecalcFunctionArgs => reagent.IsRequired === true);
    if (required) {
        required.IsRefCompound = true;
        return required;
    }

    return null;
}

function _computeExperimentalPercentYield(experimentalYield: number, theoreticalYield: number): number {
    return experimentalYield ? (experimentalYield / theoreticalYield) : 0;
}

// Formatters

export const
    formatAmount = (amount: number): number => (
        formatFloat(amount, 8)
    ),
    formatEq = (eq: number): number => (
        formatFloat(eq, 3)
    ),
    formatMass = (mass: number): number => (
        formatFloat(mass, 5)
    ),
    formatVolume = (volume: number): number => (
        formatFloat(volume, 4)
    ),
    formatTheoreticalYield = (theoreticalYield: number) => (
        formatFloat(theoreticalYield, 3)
    ),
    formatExperimentalPercentYield = (experimentalPercentYield: number) => (
        formatFloat(experimentalPercentYield, 3)
    )
    ;

export function pipeRecalcFunctions(entry: RecalcFunctionArgs, ...functions: Array<RecalcFunction>): RecalcFunctionArgs {
    const nonFunctions = functions.filter(f => typeof f !== 'function' && f.length < 1); // Обязательно должен быть один параметр
    if (nonFunctions.length) {
        throw new Error('Все аргументы функции pipeRecalcFunctions должны быть функциями!')
    }
    if (
        (!entry.reagentList && !Array.isArray(entry.reagentList))
        || (!entry.productList && !Array.isArray(entry.productList))
        || !entry.productList
    ) {
        throw new Error('Первый аргумент entry должен быть объектом с непустыми полями reagentList, productList и reaction')
    }
    return functions.reduce((acc: RecalcFunctionArgs, cur: RecalcFunction): RecalcFunctionArgs => {
        const
            temp: RecalcFunctionArgs = cur(acc)
            ;
        if (!temp) return acc;
        return { ...acc, ...temp };
    }, entry);
}

// Pipe functions

export function recalcReagent(entry: RecalcFunctionArgs): RecalcFunctionArgs {
    const referencedReagent = _getReferencedReagent(entry.reagentList);
    if (!referencedReagent || !entry.selectedReagent) return;
    if (entry.selectedReagent.Id === referencedReagent.Id
        && entry.selectedReagent.localUuid === referencedReagent.localUuid) {
        return entry;
    }

    const
        referencedEq: number = referencedReagent.Eq || 0,
        referencedMass: number = referencedReagent.Mass || 0,
        referencedAmount: number = referencedReagent.Amount || 0
        ;
    if ([referencedMass, referencedEq, referencedAmount, entry.selectedReagent.Mass, entry.selectedReagent.Eq, entry.selectedReagent.Volume].some(isNaN)) {
        return entry;
    }
    const
        MainAgentContent: number = entry.selectedReagent.MainAgentContent || 0,
        MolarWeight: number = entry.selectedReagent.Agent?.Compound?.MolarWeight || 0,
        Density: number = entry.selectedReagent.AgentAvailability?.Density || 0,
        Concentration: number = entry.selectedReagent.AgentAvailability?.Concentration || 0
        ;
    let
        Amount: number,
        Mass: number,
        Volume: number,
        Eq: number
        ;

    if (isSolvent(entry.selectedReagent)) {
        if (entry.reaction?.VolumeBasedSolvents) {
            Volume = entry.selectedReagent.Volume || 0;
            Eq = Volume / referencedMass;
        }
        else {
            Volume = referencedMass * entry.selectedReagent.Eq;
            Eq = entry.selectedReagent.Eq || 0;
        }
        Mass = Volume * Density;
        Amount = (Mass * MainAgentContent * 0.01) / MolarWeight;
    }
    else {
        Eq = entry.selectedReagent.Eq || 0;
        Amount = referencedAmount * Eq / referencedEq;
        Mass = MolarWeight * Amount / (MainAgentContent * 0.01);
        Volume = _recalcVolume(Concentration, Amount, Mass, Density);
    }

    entry.selectedReagent.Amount = formatAmount(Amount);
    entry.selectedReagent.Mass = formatMass(Mass);
    if (isSolvent(entry.selectedReagent) && entry.reaction?.VolumeBasedSolvents) {
        entry.selectedReagent.Eq = formatEq(Eq);
    }
    // Всегда кроме случая когда реагент растворитель и включена галочка ведется расчет растворителей от объема, а не от eq.
    if (!isSolvent(entry.selectedReagent) || (isSolvent(entry.selectedReagent) && !entry.reaction?.VolumeBasedSolvents)) {
        entry.selectedReagent.Volume = formatVolume(Volume);
    }

    if(!entry.skipParent && (entry.selectedReagent.ParentReagentId || entry.selectedReagent.ParentReagentLocalId)) {
        let subReagents = entry.reagentList.filter(r => 
            ((r.ParentReagentId && r.ParentReagentId == entry.selectedReagent.ParentReagentId) ||
                (r.ParentReagentLocalId && r.ParentReagentLocalId == entry.selectedReagent.ParentReagentLocalId))
             && !r.IsDeleted);
        if(subReagents.some(r => (r.Id && r.Id == entry.selectedReagent.Id) || (r.localUuid && r.localUuid == entry.selectedReagent.localUuid)))
            subReagents = subReagents.filter(r => !((r.Id && r.Id == entry.selectedReagent.Id) || (r.localUuid && r.localUuid == entry.selectedReagent.localUuid)));
        subReagents = [entry.selectedReagent, ...subReagents];
        const parentReagent = entry.reagentList.find(r => ((r.Id && r.Id == entry.selectedReagent.ParentReagentId) 
            || (r.localUuid && r.localUuid == entry.selectedReagent.ParentReagentLocalId)));
        if(parentReagent && subReagents) {
            parentReagent.Amount = formatAmount(subReagents.reduce((n, {Amount}) => n + Amount, 0));
            parentReagent.Mass = formatMass(subReagents.reduce((n, {Mass}) => n + Mass, 0));
            parentReagent.Eq = formatEq(subReagents.reduce((n, {Eq}) => n + Eq, 0));
            parentReagent.Volume = formatVolume(subReagents.reduce((n, {Volume}) => n + Volume, 0));
        }
    }

    return entry;
}

export function recalcProduct(entry: RecalcFunctionArgs): RecalcFunctionArgs {
    const referencedReagent = _getReferencedReagent(entry.reagentList);
    if (!referencedReagent || !entry.selectedProduct) return;
    const
        referencedEq: number = referencedReagent.Eq || 0,
        referencedAmount: number = referencedReagent.Amount || 0,
        referencedMass: number = referencedReagent.Mass || 0
        ;
    if (isNaN(referencedEq) || isNaN(referencedAmount) || isNaN(referencedMass)) {
        return entry;
    }
    const
        Eq: number = entry.selectedProduct.Eq || 0,
        MainAgentContent: number = entry.selectedProduct.MainAgentContent || 0,
        MolarWeight: number = entry.selectedProduct.MolarWeight || 0,
        Density: number = entry.selectedProduct.Density || 0,
        Concentration: number = entry.selectedProduct.Concentration || 0,
        Amount: number = referencedAmount * Eq / referencedEq,
        Mass: number = MolarWeight * Amount / (MainAgentContent * 0.01),
        Volume: number = _recalcVolume(Concentration, Amount, Mass, Density)
        ;

    entry.selectedProduct.Mass = formatMass(Mass);
    entry.selectedProduct.Amount = formatAmount(Amount);
    entry.selectedProduct.Volume = formatVolume(Volume);

    return entry;
}

function recalcParent(entry: RecalcFunctionArgs): RecalcFunctionArgs {
    if(!entry.selectedReagent.ParentReagentId && !entry.selectedReagent.ParentReagentLocalId) {
        let subReagents = entry.reagentList.filter(r => 
            ((r.ParentReagentId && r.ParentReagentId == entry.selectedReagent.Id) ||
                (r.ParentReagentLocalId && r.ParentReagentLocalId == entry.selectedReagent.localUuid))
             && !r.IsDeleted);
        if(subReagents.length > 0) {             
            entry.selectedReagent.Amount = formatAmount(subReagents.reduce((n, {Amount}) => n + Amount, 0));
            entry.selectedReagent.Mass = formatMass(subReagents.reduce((n, {Mass}) => n + Mass, 0));
            entry.selectedReagent.Eq = formatEq(subReagents.reduce((n, {Eq}) => n + Eq, 0));
            entry.selectedReagent.Volume = formatVolume(subReagents.reduce((n, {Volume}) => n + Volume, 0));
            entry.selectedReagent.FactualConsumption = formatVolume(subReagents.reduce((n, {FactualConsumption}) => n + FactualConsumption, 0));
        }
    }

    return entry;
}

export function recalcReactionYield(entry: RecalcFunctionArgs): RecalcFunctionArgs {
    const referencedReagent = _getReferencedReagent(entry.reagentList);
    if (
        !referencedReagent
        || (entry.reaction.ProjectType === projectTypeEnum.generic
            && !entry.reaction.IsTrial
            && entry.reaction.ReactionType === REACTION_TYPE_PURIFICATION_ID
            && entry.reaction.HasMultipleYields)
    ) {
        return;
    }
    if (!entry.reaction.HasMultipleYields) {
        const mainProduct: IProduct = entry.productList.find((product: IProduct) => product.IsMainProduct);
        if (!mainProduct) return;
        let ExperimentalPercentYield: number;

        entry.reaction.TheoreticalYield = formatTheoreticalYield(mainProduct.Mass || 0);

        if (entry.reaction.IsArchived) {
            ExperimentalPercentYield = (entry.reaction.ExperimentalYield / entry.reaction.TheoreticalYield);
        }
        else {

            ExperimentalPercentYield = entry.reaction.ExperimentalYield
                ? (entry.reaction.ExperimentalYield / entry.reaction.TheoreticalYield)
                : 0;
        }

        entry.reaction.ExperimentalPercentYield = formatExperimentalPercentYield(ExperimentalPercentYield);

        let expYieldStr = entry.reaction?.ExperimentalYield?.toString()?.split(',')?.join('.');
        //избегать форматирования, если введенное число закончилось на разделитель дробной части или 0
        if (isNaN(expYieldStr) || (expYieldStr && (!expYieldStr.endsWith('.') && !expYieldStr.endsWith('0')))) {
            entry.reaction.ExperimentalYield = formatFloat(entry.reaction.ExperimentalYield, 3);
        }
    }
    else {
        const validYields = entry.reaction?.ReactionYields?.filter((item: IReactionYield) => !item.IsDeleted);
        validYields.forEach((item: IReactionYield) => {
            item.ExperimentalPercentYield = formatExperimentalPercentYield(_computeExperimentalPercentYield(item.ExperimentalYield, item.TheoreticalYield));
        });
        const
            theoreticalYield = validYields.map(i => i.TheoreticalYield).reduce((i, j) => i + j, 0),
            experimentalYield = validYields.map(i => i.ExperimentalYield).reduce((i, j) => i + j, 0),
            experimentalPercent = _computeExperimentalPercentYield(experimentalYield, theoreticalYield)
            ;
        entry.reaction.TheoreticalYield = formatTheoreticalYield(theoreticalYield);
        entry.reaction.ExperimentalPercentYield = formatExperimentalPercentYield(experimentalPercent);
        entry.reaction.ExperimentalYield = formatFloat(experimentalYield, 3);
    }

    return entry;
}

export function recalcReferencedReagent(entry: RecalcFunctionArgs): RecalcFunctionArgs {
    const referencedReagent: IReagent = _getReferencedReagent(entry.reagentList);
    if (!referencedReagent) return;
    const
        Mass: number = referencedReagent.Mass || 0,
        MolarWeight: number = referencedReagent.Agent?.Compound?.MolarWeight || 0,
        MainAgentContent: number = referencedReagent.MainAgentContent || 0,
        Density: number = referencedReagent.AgentAvailability?.Density || 0,
        Concentration: number = referencedReagent.AgentAvailability?.Concentration || 0,
        Amount: number = (Mass * MainAgentContent * 0.01) / MolarWeight,
        Volume: number = _recalcVolume(Concentration, Amount, Mass, Density)
        ;

    referencedReagent.Volume = formatVolume(Volume);
    referencedReagent.Amount = formatAmount(Amount);

    return entry;
}

export function recalcReagentList(entry: RecalcFunctionArgs): RecalcFunctionArgs {
    const referencedReagent: IReagent = _getReferencedReagent(entry.reagentList);
    if (!referencedReagent) return;
    entry.reagentList.forEach((reagent: IReagent) => {
        if (reagent !== referencedReagent && !reagent.IsDeleted) {
            recalcReagent({ ...entry, selectedReagent: reagent, skipParent: true });
        }
    });
    entry.reagentList.forEach((reagent: IReagent) => {
        recalcParent({ ...entry, selectedReagent: reagent, skipParent: true });
    }); 
    return entry;
}

export function recalcProductList(entry: RecalcFunctionArgs): RecalcFunctionArgs {
    const referencedReagent: IReagent = _getReferencedReagent(entry.reagentList);
    if (!referencedReagent) return;
    entry.productList.forEach((product: IProduct) => {
        recalcProduct({ ...entry, selectedProduct: product });
    });
       
    return entry;
}
