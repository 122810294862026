// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".table-m__grid___ritTh {\n  --header-height: 48px;\n  --body-row-height: 56px;\n  --grid-spacer: 0;\n  --row-border-color: var(--border-color);\n  --table-border-color: var(--border-color);\n  background-color: #fff;\n  border: 1px solid var(--table-border-color);\n}\n.table-m__grid__header___ycgpW {\n  font-weight: 500;\n  font-size: 14px;\n  color: var(--grey-600);\n}\n.table-m__grid__header___ycgpW .table-m__grid__row___aCVI3 {\n  min-height: var(--header-height);\n  border-top: none;\n}\n.table-m__grid__header___ycgpW .table-m__grid__cell___o9uaO {\n  min-height: var(--header-height);\n  border-top: none;\n}\n.table-m__grid__body___c92zu {\n  font-weight: 300;\n  font-size: 14px;\n}\n.table-m__grid__body___c92zu .table-m__grid__row___aCVI3:hover {\n  background-color: var(--hover-bg-color);\n}\n.table-m__grid__body___c92zu .table-m__grid__cell___o9uaO {\n  min-height: var(--body-row-height);\n}\n.table-m__grid__body___c92zu .table-m__grid__cell___o9uaO:first-child {\n  padding-left: var(--LayoutBlock__header-padding-h);\n}\n.table-m__grid__body___c92zu .table-m__grid__cell___o9uaO:last-child {\n  padding-right: var(--LayoutBlock__header-padding-h);\n}\n.table-m__grid__container___eXICH {\n  width: 100%;\n}\n.table-m__grid__row___aCVI3 {\n  display: grid;\n  grid-template-columns: var(--template, repeat(auto-fit, minmax(100px, 1fr)));\n  grid-column-gap: var(--grid-spacer);\n  align-items: start;\n  border-top: 1px solid var(--row-border-color);\n}\n.table-m__grid__cell___o9uaO {\n  padding: var(--minor-margin);\n  overflow: hidden;\n  display: flex;\n  align-items: center;\n}\n.table-m__alignRight___ByKz9 {\n  justify-content: flex-end;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": "table-m__grid___ritTh",
	"grid__header": "table-m__grid__header___ycgpW",
	"grid__row": "table-m__grid__row___aCVI3",
	"grid__cell": "table-m__grid__cell___o9uaO",
	"grid__body": "table-m__grid__body___c92zu",
	"grid__container": "table-m__grid__container___eXICH",
	"alignRight": "table-m__alignRight___ByKz9"
};
export default ___CSS_LOADER_EXPORT___;
