// @flow
import { Model, ArrayModel, BasicModel, Any } from 'objectmodel';
import { ReactionParameter } from './reaction-parameter';
import { ReactionYield } from './reaction-yield';
import type {IReaction, ISeries} from './i-reaction';
import {Compound} from '../../agents/models';
import {formInputValueType} from '../../shared/utils/common';

const defaultSeries: ISeries = {
  SeriesAfsAim: null,
  SeriesAfsNumber: '',
  SeriesComposition: null,
  SeriesProcessScale: null,
  SeriesProductionDate: null,
  SeriesComment: '',
};

export const Series = Model({
  Id: [Number, null, undefined],
  SeriesAfsAim: [Number, null, undefined],
  SeriesAfsNumber: [String],
  SeriesComposition: [String, null, undefined],
  SeriesProcessScale: [Number, null, undefined],
  SeriesProductionDate: Any,
  SeriesComment: [String, null, undefined],
}).defaultTo(defaultSeries);

const defaultReaction: IReaction = {
  InitialStageId: null,
  IsCompleted: false,
  IsDeleted: false,
  IsTrial: false,
  Number: 0,
  IsArchived: false,
  HasMultipleYields: false,
  IsPurification: false,
  ReactionType: 0,
  AttachmentsText: '',
  Comment: '',
  ExperimentalPercentYield: null,
  ExperimentalYield: null,
  HPLCMSText: '',
  Methodology: '',
  MSText: '',
  Name: '',
  NMRText: '',
  ReactionExtraConditions: 0,
  TheoreticalYield: 0,
  TLCText: '',
  ArchiveProjectName: '',
  ChemistAbbreviation: '',
  ReactionParameters: [],
  VolumeBasedSolvents: false,
  QuickComment: '',
  Series: defaultSeries,
  ReactionTemplateId: null,
  AdditionalName: '',
  IsTemplate: false,
};

export const Reaction = Model({
  AttachmentsText: [String],
  AuthorUserName: [String],
  Comment: [String],
  CreationDate: Any,
  ExperimentalPercentYield: formInputValueType(Number),
  ExperimentalYield: formInputValueType(Number),
  HPLCMSText: [String],
  Id: [Number, null, undefined],
  InitialStageId: [Number, null, undefined],
  IsCompleted: Boolean,
  IsDeleted: Boolean,
  IsTrial: Boolean,
  Methodology: [String],
  ModificationDate: Any,
  MSText: [String],
  Name: [String],
  NMRText: [String],
  Number: [Number],
  ReactionExtraConditions: Any,
  ReactionType: [BasicModel(Number).assert(Number.isInteger).assert(n => n >= 0 || n <= 30), null, undefined],
  TheoreticalYield: [Number],
  TLCText: [String],
  ArchiveProjectName: [String],
  ChemistAbbreviation: [String],
  StartDate: Any,
  EndDate: Any,
  CompleteDate: Any,
  IsArchived: Boolean,
  LaboratoryId: [Number, null, undefined],
  ProjectType: [BasicModel(Number).assert(n => n === 1 || n === 2), null, undefined],
  ReactionParameters: [ArrayModel([ReactionParameter, null, undefined])],
  ReactionYields: [ArrayModel([ReactionYield, null, undefined]), null, undefined],
  VolumeBasedSolvents: [Boolean],
  HasMultipleYields: [Boolean],
  IsSuccessful: [Boolean, null, undefined],
  OriginalId: [Number, null, undefined],
  ParentRepkaTaskId: [Number, null, undefined],
  QuickComment: [String, null, undefined],
  RefReagentName: [String, null, undefined],
  RepkaTaskId: [Number, null, undefined],
  FolderId: [Number, null, undefined],
  IsPurification: [Boolean, null, undefined],
  Compound: [Compound, null, undefined],
  Series: [Series, null, undefined],
  ReactionTemplateId: [Number, null, undefined],
  AdditionalName: [String],
  PrimaryReactionId: [Number, null, undefined],
  NestedPurifications: [ArrayModel([Any]), null, undefined],
  PrimaryNestedPurifications: [ArrayModel([Any]), null, undefined],
  RequiredPurificationsRemain: [Number, null, undefined],
  PurificationBundleType: [Number, null, undefined],
  NestedIndex: [Number, null, undefined],
  CanCreateNestedPurification: [Boolean],
  IsTemplate: [Boolean],
})
.defaultTo(defaultReaction);



