// @flow
import {getEnum} from '../../shared/utils';

export const ReagentTypeEnum = getEnum({
  Reactant  : 1, // реактант
  Solvent   : 2, // Растворитель
  Catalyst  : 3, // Катализатор
  Original  : 4, // Исходное вещество
  Auxiliary : 5, // Побочный реактив
});
