import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {getFormValues} from 'redux-form';
import {LayoutHeader} from '../../shared/components';
import ProjectsTable from './ProjectsTable';
import {projectActions} from '../actions/projects';
import {folderModuleActions, folderResourceActions} from '../actions/folders';
import SearchForm from './SearchField';
import {PROJECT_SEARCH_FORM_NAME} from './SearchField';
import {folderParentType} from '../types/enum';
import Loader from '@biocad/bcd-front-ui/controls/Loader';
import styled from 'styled-components';
import {candidateModuleActions, candidatesResourceActions} from '../actions';

export const LoaderWrap = styled.div`
  & .b-loader {
    position:absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
  }
`;



const Projects = ({
                      getList,
                      getFolders,
                      clearProjectsList,
                      clearFoldersList,
                      searchFormValues,
                      foldersPath,
                      clearFoldersPath,
                      clearFolderCandidatePath,
                      clearFolderSchemesPath,
                      setCandidateEditMode,
                      foldersPending,
                      projectsPending,
                      clearCandidatesList
                  }) => {

    useEffect(() => {
        clearCandidatesList();
        clearFoldersList();
        clearProjectsList();
        setCandidateEditMode({
            isEditMode: false
        });
        getList({});
    }, []);

    useEffect(() => {
        clearFoldersList();
        getFolders({
            parentType: folderParentType.root
        });
        return () => {
            clearFoldersList();
            clearFoldersPath();
            clearFolderSchemesPath();
            clearFolderCandidatePath();
        };
    }, []);

    const handleSearchFormSubmit = (event: any) => {
        event.preventDefault();
        searchFormValues.search.length === 0 && getFolders({
            parentType: folderParentType.root
        });
        getProjectsList({
            searchData: searchFormValues,
        });
    };

    const getProjectsList = ({listParams: params, searchData: search}: { listParams: { page: number, pageSize: number }, searchData: {} }) => {
        clearProjectsList();
        clearFoldersList();
        return getList({...search});
    };

    return (
        <React.Fragment>
            {(projectsPending || foldersPending) &&
            <LoaderWrap>
                <Loader/>
            </LoaderWrap>
            }
            {foldersPath.length === 0 &&
            <LayoutHeader>
                <SearchForm onSubmit={handleSearchFormSubmit}/>
            </LayoutHeader>}
            <ProjectsTable/>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    const {modules, resource} = state;
    return {
        searchFormValues: getFormValues(PROJECT_SEARCH_FORM_NAME)(state),
        foldersPath: modules.folder.path,
        foldersPending: resource.folders && resource.folders.pending,
        projectsPending: resource.projects && resource.projects.pending
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        (dispatch) => ({
            getList: (params) => dispatch(projectActions.list.request({params})),
            getFolders: (params) => dispatch(folderResourceActions.list.request({params})),
            clearProjectsList: () => dispatch(projectActions.destroy()),
            clearFoldersList: () => dispatch(folderResourceActions.destroy()),
            clearFoldersPath: () => dispatch(folderModuleActions.deleteFolderPath()),
            clearFolderCandidatePath: () => dispatch(folderModuleActions.deleteFolderCandidatePath()),
            clearFolderSchemesPath: () => dispatch(folderModuleActions.deleteFolderSchemesPath()),
            setCandidateEditMode: (isEditMode) => dispatch(candidateModuleActions.setEditMode(isEditMode)),
            clearCandidatesList: () => dispatch(candidatesResourceActions.destroy()),
        })
    )(Projects)
);