import base64url from 'base64url';
import { environment } from '../../environment';

export function readRoles(token) {
    try {
        const data = JSON.parse(base64url.decode(token.split('.')[1]));
        return data['resource_access'][environment.serviceId]['roles'];
    } catch (error) {
        console.error(error);
        return [];
    }
}
