// @flow

import Button from '@biocad/bcd-front-ui/controls/Button';
import Loader from '@biocad/bcd-front-ui/controls/Loader';
import bnc from 'bnc';
import propTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { _temp_folderResourceAction } from '../../../folders/actions';
import { EditFolder } from '../../../folders/components/EditFolder';
import { getFolderDeleteSuccess } from '../../../folders/reducers/module';
import { folderParentType } from '../../../projects/types/enum';
import { BreadcrumbsNav } from "../../../shared/components/BreadcrumbsNav";
import { Paginator } from '../../../shared/components/Paginator';
import { TitleWithCounter } from '../../../shared/components/TitleWithCounter';
import { stageResourceActions } from '../../../stages/actions';
import { reactionModuleActions, reactionsResourceActions } from '../../actions';
import { getSidebarFolders, getSidebarPagination, getSidebarPending, getSidebarReactions } from '../../reducers';
import './index.less';
import { ReactionItem } from './ReactionItem';
import { ReactionsFilter } from './ReactionsFilterTooltip';
import type { IReaction, IReactionListRequest, IReactionQuickComment } from '../../models';
import type { CommentWrapperOnSaveReturnObject } from '../../../shared/components/CommentWrapper';
import type { IFolder, IFoldersListParams } from '../../../projects/models';
import type { IPagination, IPaginationParams } from '../../../shared/models';
import type { IStageBestReactionURIParams } from '../../../stages/models';
import type {TBreadcrumbItem} from "../../../shared/components/BreadcrumbsNav";
import {userCRUDPermissionsSelector} from "../../../account/reducers/users-modules-reducer";



type BreadcrumbType = {
  parentId: number,
  parentType: number,
  ...TBreadcrumbItem,
}

interface IReactionSideBarProps {
  stageId: ?number,
  bestReactionId?: ?number,
  className?: string,
  reactions?: IReaction[],
  pagination?: IPagination,
  folders?: IFolder[],
  pending?: boolean,
  quickCommentSavedSuccessful?: boolean,
  folderSavedSuccessful?: boolean,
  requestReactions?: (IReactionListRequest)=>void,
  requestFolders?: (IFoldersListParams)=>void,
  updateStageBestReaction?: (IStageBestReactionURIParams)=>void,
  selectReactionFolder?: (any)=>void,
  deleteStageBestReaction?: (id: number)=>void,
  updateQuickComment?: (number, IReactionQuickComment)=>void,
  deleteFolder?: (number)=>void,
  clearStore?: ()=>void,
  deleteFolderSuccess?: boolean,
  history?: {
    push?: typeof Function,
  },
  userPermissions?: {},
}

const defaultProps: IReactionSideBarProps = {
  stageId: null,
  bestReactionId: null,
};

const
  BNCBlock = new bnc('b-ReactionSideBar'),
  BNCHeader = BNCBlock.el('header'),
  BNCFolderItem = BNCBlock.el('folderItem'),
  BNCFolderItemAll = BNCFolderItem.mod('all'),
  BNCFolderItemBest = BNCFolderItem.mod('best'),
  BNCFolderItemHistorical = BNCFolderItem.mod('historical'),
  BNCTitleWithCounter = BNCBlock.el('titleWithCounter'),
  BNCPaginator = BNCBlock.el('paginator'),
  BNCControl = BNCBlock.el('control'),
  BNCBreadcrumb = BNCBlock.el('breadcrumb')
;

const
  defaultListReactionsParams: IReactionListRequest = {
    skip: 0,
    limit: 10,
    IncludeExpYield: true,
    IncludeIncomplete: true,
    IncludeTrial: true,
    IncludeWithoutExpYield: true,
  },
  defaultListFoldersParams: IFoldersListParams = {
    parentType: folderParentType.stage,
  },
  defaultPagingParams: IPaginationParams = {
    limit: 10,
    skip: 0,
  }
;

let ReactionSideBar = (props: IReactionSideBarProps = defaultProps) => {
  // Local state
  const
    [listReactionsParams, setListReactionsParams]   = useState<IReactionListRequest>(defaultListReactionsParams),
    [listFoldersParams, setListFoldersParams]       = useState<IFoldersListParams>(defaultListFoldersParams),
    [bestReactionId, setBestReactionId]             = useState(null),
    [onlyBestReactions, setOnlyBestReactions]       = useState<boolean>(false),
    [closeCommentDialogFn, setCloseCommentDialogFn] = useState<typeof Function>(null),
    [breadcrumb, setBreadcrumb]                     = useState<BreadcrumbType[]>([])
  ;

  // Variables
  const
    {
      IncludeExpYield, IncludeIncomplete, IncludeTrial, IncludeWithoutExpYield
    } = listReactionsParams,
    firstBreadcrumbItem: BreadcrumbType = {
      parentId: props.stageId,
      parentType: folderParentType.stage,
      label: '..'
    },
    allReactionsLabel = 'На стадии',
    allBestReactionsLabel = 'Избранные',
    historicalReactionsLabel = 'Исторические'
  ;

  // Effects
  const _updateLocalStateOnStageIdChange = () => {
    if (props.stageId && !isNaN(Number.parseInt(props.stageId))) {
      setListReactionsParams(prev => ({ ...prev, InitialStageId: props.stageId }));
      setListFoldersParams(prev => ({ ...prev, parentId: props.stageId }));
      setBreadcrumb([firstBreadcrumbItem])
    }
  };
  useEffect(_updateLocalStateOnStageIdChange, [props.stageId]);

  const _requestReactions = () => {
    if ('InitialStageId' in listReactionsParams && listReactionsParams.InitialStageId) {
      props.requestReactions({ ...listReactionsParams, order: '-CreationDate' });
      props.requestHistoricalReactionsCount(props.stageId);
    }
  };
  useEffect(_requestReactions, [listReactionsParams]);

  const _requestFolders = () => {
    if ('parentId' in listFoldersParams && listFoldersParams.parentId) {
      props.requestFolders(listFoldersParams);
    }
  };
  useEffect(_requestFolders, [listFoldersParams]);

  const _updateBestReactionId = () => {
    if (props.bestReactionId) setBestReactionId(props.bestReactionId);
  };
  useEffect(_updateBestReactionId, [props.bestReactionId]);

  const _updateReactionsOnCommentSave = () => {
    if (props.quickCommentSavedSuccessful === null) return;
    if (props.quickCommentSavedSuccessful) {
      setListReactionsParams(prev => ({ ...prev }));
    }
    typeof closeCommentDialogFn === 'function' && closeCommentDialogFn();
    setCloseCommentDialogFn(null);
  };
  useEffect(_updateReactionsOnCommentSave, [props.quickCommentSavedSuccessful]);

  const _updateFolderSavedSuccessful = () => {
    if(props.folderSavedSuccessful === true) {
      props.requestReactions({ ...listReactionsParams, order: '-CreationDate' });
      reloadFolders();
    }
  }
  useEffect(_updateFolderSavedSuccessful, [props.folderSavedSuccessful]);  

  const _reloadFolderOnDelete = () => {
    if (props.deleteFolderSuccess === true) {
      reloadFolders();
    }
  };
  useEffect(_reloadFolderOnDelete, [props.deleteFolderSuccess]);

  const _onUnMount = () => () => {
    props.clearStore();
  };
  useEffect(_onUnMount, []);

  // Handlers
  const
    updateReactionComment = ({ itemId, comment, close }: CommentWrapperOnSaveReturnObject) => {
      const data: IReactionQuickComment = {
        ReactionId: itemId,
        QuickComment: comment,
      };
      props.updateQuickComment(itemId, data);
      setCloseCommentDialogFn(close);
    },
    updateBestReaction = (reactionId: number) => {
      if (reactionId === bestReactionId) {
        setBestReactionId(null);
        props.deleteStageBestReaction(props.stageId);
        return;
      }
      setBestReactionId(reactionId);
      props.updateStageBestReaction({
        ReactionId: reactionId,
        StageId: props.stageId,
      });
    },
    selectReactionFolder = (reactionId: number, folderId: number) => {
      console.log('select', reactionId, folderId);
      props.selectReactionFolder(reactionId, {
        ReactionId: reactionId,
        FolderId: folderId,
      });
    },
    updateReactionParams = (params) => {
      setListReactionsParams(prev => ({
        ...prev,
        ...params,
        ...defaultPagingParams,
      }))
    },
    reloadFolders = () => {
      setListFoldersParams(prev => ({ ...prev }));
    },
    addReaction = () => {
      const folderParam = (listReactionsParams?.FolderId) ? `&folderId=${listReactionsParams.FolderId}` : '';
      props.history.push(`/reactions/add?stageId=${props.stageId}${folderParam}`); // @todo не забыть потом подставить переменную вместо 'add'
    },
    getAllFoldersReactions = () => {
      setListReactionsParams(prev => ({
        ...prev,
        IgnoreFolders: true,
        FolderId: folderParentType.root,
        HistoricalMode: false,
      }));
      setOnlyBestReactions(false);
      setBreadcrumb(prev => [...prev, { label: allReactionsLabel } ]);
    },
    getAllBestReactions = () => {
      setListReactionsParams(prev => ({
        ...prev,
        IgnoreFolders: true,
        FolderId: folderParentType.root,
        HistoricalMode: false,
      }));
      setOnlyBestReactions(true);
      setBreadcrumb(prev => [...prev, { label: allBestReactionsLabel } ]);
    },
    getHistoricalReactions = () => {
      setListReactionsParams(prev => ({
        ...prev,
        IgnoreFolders: true,
        FolderId: folderParentType.root,
        HistoricalMode: true,
      }));
      setOnlyBestReactions(false);
      setBreadcrumb(prev => [...prev, { label: historicalReactionsLabel } ]);
    },
    paginateReactions = ({ skip, limit }: IPaginationParams) => {
      setListReactionsParams(prev => ({ ...prev, skip, limit }));
    },
    gotoFolder = (folder: IFolder) => {
      setListFoldersParams(prev => ({
        ...prev,
        parentId: folder.Id,
        parentType: folderParentType.folder
      }));
      setListReactionsParams(prev => ({
        ...prev,
        ...defaultPagingParams,
        IgnoreFolders: false,
        FolderId: folder.Id,
        HistoricalMode: false,
      }));
      setBreadcrumb((prev: BreadcrumbType[]) => prev.concat<BreadcrumbType[]>([{
        parentId: folder.Id,
        parentType: folderParentType.folder,
        label: folder.Name
      }]))
    },
    deleteFolder = (id: number) => {
      props.deleteFolder(id);
    },
    navigateBreadcrumbs = (item: BreadcrumbType, idx: number) => {
      if (idx === 0) {
        setListFoldersParams({ ...defaultListFoldersParams, parentId: props.stageId });
        setListReactionsParams(prev => ({
          ...prev,
          ...defaultPagingParams,
          FolderId: null,
          IgnoreFolders: false,
          HistoricalMode: false,
        }));
        setBreadcrumb([firstBreadcrumbItem]);
        setOnlyBestReactions(false);
        return;
      }
      setListFoldersParams(prev => ({
        ...prev,
        parentId: item.parentId,
        parentType: folderParentType.folder,
      }));
      setListReactionsParams(prev => ({
        ...prev,
        ...defaultPagingParams,
        FolderId: item.parentId,
      }));
      setBreadcrumb((prev: BreadcrumbType[]) => prev.slice(0, idx+1));
    }
  ;

  return (
    <aside className={BNCBlock + props.className}>
      {
        props.pending
          ? <Loader />
          : <>
            <header className={BNCHeader}>
              <TitleWithCounter
                className={BNCTitleWithCounter.toString()}
                title={'Реакции'}
                count={props.pagination?.total}
              />
              <ReactionsFilter onItemClick={updateReactionParams}
                               params={{IncludeExpYield, IncludeIncomplete, IncludeTrial, IncludeWithoutExpYield}}
              />
              {
                props.userPermissions.reactionFolder.CREATE &&
                <EditFolder
                    folder={{
                      ParentType: listFoldersParams.parentType,
                      ParentId: listFoldersParams.parentId,
                    }}
                    onFolderEdited={reloadFolders}
                />
              }
              {
                props.userPermissions.reaction.CREATE &&
                <Button
                    className={'fo-plus'}
                    view={'icon'}
                    size={'L'}
                    onAction={addReaction}
                />
              }
            </header>
            {
              props.pagination?.page === 1 &&
              <>
                {
                  (!listReactionsParams.FolderId && breadcrumb.length === 1) &&
                    <>
                      <div className={`${BNCFolderItem} ${BNCFolderItemAll}`}
                           onClick={getAllFoldersReactions}>
                        <span>{allReactionsLabel}</span>
                      </div>
                      <div className={`${BNCFolderItem} ${BNCFolderItemBest}`}
                           onClick={getAllBestReactions}>
                        <span>{allBestReactionsLabel}</span>
                      </div>
                      <div className={`${BNCFolderItem} ${BNCFolderItemHistorical}`}
                           onClick={getHistoricalReactions}>
                        <span>{historicalReactionsLabel} ({Number.isInteger(props.historicalCount) ? props.historicalCount : "?"})</span>
                      </div>
                    </>
                }
                {
                  breadcrumb.length > 1 &&
                  <BreadcrumbsNav items={breadcrumb}
                                  onItemClick={navigateBreadcrumbs}
                                  className={BNCBreadcrumb}
                  />
                }
                {
                  !listReactionsParams.IgnoreFolders && props.folders?.map((folder: IFolder, idx: number) => (
                    <div className={`${BNCFolderItem}`}
                         key={idx}
                         onClick={() => !props.folderEditDialog && gotoFolder(folder)}> 
                      <span>{ folder.Name }</span>
                      {
                        props.userPermissions.reactionFolder.UPDATE &&
                        <EditFolder folder={folder}
                                    onFolderEdited={reloadFolders}
                                    buttonClassName={BNCControl}
                        />
                      }
                      {
                        props.userPermissions.reactionFolder.DELETE &&
                        <Button
                            className={BNCControl + 'fo-trash'}
                            view={'icon'}
                            size={'L'}
                            onAction={() => deleteFolder(folder.Id)}
                        />
                      }
                    </div>
                  ))
                }
              </>
            }
            {
              props.reactions && props.reactions
                .filter((reaction: IReaction) => onlyBestReactions ? reaction.Id === bestReactionId : reaction)
                .map((reaction: IReaction, idx: number) => (
                  <ReactionItem reaction={reaction}
                                isBestReaction={reaction.Id === bestReactionId}
                                onBestReactionIconClick={updateBestReaction}
                                selectFolder={selectReactionFolder}
                                onCommentSave={updateReactionComment}
                                bestReactionAvailable={reaction.InitialStageId === props.stageId}
                                highlightReaction={listReactionsParams.IgnoreFolders && !onlyBestReactions && reaction.InitialStageId === props.stageId}
                                key={reaction.Id}
                                userName={props.userName}
                                folders={props.folders}
                  />
                ))
            }
            {
              props.pagination?.pageCount > 1 &&
              <Paginator {...props.pagination}
                         className={BNCPaginator.toString()}
                         hidePageInput={true}
                         hideLimit={true}
                         handlePagingChange={paginateReactions} />
            }
            </>

      }
    </aside>
  )
};

ReactionSideBar.propTypes = {
  stageId: propTypes.number.isRequired,
  stageBestReactionId: propTypes.number,
};

ReactionSideBar = connect(
  (state): IReactionSideBarProps => ({
    reactions: getSidebarReactions(state),
    pagination: getSidebarPagination(state),
    folders: getSidebarFolders(state),
    pending: getSidebarPending(state),
    quickCommentSavedSuccessful: state.modules.reaction?.quickComment?.savedSuccessful,
    folderSavedSuccessful: state.modules.reaction?.selectFolder?.savedSuccessful,
    deleteFolderSuccess: getFolderDeleteSuccess(state),
    historicalCount: state.modules.reaction?.reactionsSideBar?.historicalCount,
    userPermissions: userCRUDPermissionsSelector(state),
    folderEditDialog: state.form?.folderEditDialog,
    userName:state.global.account.data.UserName,
  }),
  (dispatch: Dispatch): IReactionSideBarProps => ({
    requestReactions: (params: IReactionListRequest) => dispatch(reactionsResourceActions.list.request({ params })),
    requestFolders: (params: IFoldersListParams) => dispatch(_temp_folderResourceAction.list.request({ params })),
    updateStageBestReaction: (uriParams: IStageBestReactionURIParams) => dispatch(stageResourceActions.updateBestReaction.request({ uriParams })),
    selectReactionFolder: (id, data: any) => dispatch(reactionsResourceActions.selectFolder.request({ uriParams: { id }, data })),
    deleteStageBestReaction: (id: number) => dispatch(stageResourceActions.deleteBestReaction.request({ uriParams: {id}})),
    updateQuickComment: (id, data: IReactionQuickComment) => dispatch(reactionsResourceActions.quickComment.request({ uriParams: { id }, data })),
    deleteFolder: (id: number) => dispatch(_temp_folderResourceAction.delete.request({ uriParams: {id} })),
    clearStore: () => [
      reactionModuleActions.clear,
      _temp_folderResourceAction.destroy,
      reactionsResourceActions.destroy
    ].forEach(action => dispatch(action())),
    requestHistoricalReactionsCount: (stageId) => dispatch(reactionModuleActions.sidebar.requestHistoricalCount({stageId})),
  })
)(ReactionSideBar);

ReactionSideBar = withRouter(ReactionSideBar);

export { ReactionSideBar, };

