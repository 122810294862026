// @flow

import {resourceReducer} from '../../shared/utils/reducer-creators';
import {archivedReagentsResourceActions} from '../actions';
import {createSelector} from 'reselect';
import type {IRootStore} from '../../app/reducers';

export const archivedReagentsReducer = resourceReducer(archivedReagentsResourceActions);

// Selectors
export const
	getArchivedReagents = createSelector(
		(state: IRootStore) => state.resource.archivedReagents.data,
		(data) => Object.values(data)
	)
;