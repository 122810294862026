import React, { useState, useEffect } from 'react';
import css from './tabs.m.less';
import { BcdRadioGroupWithOptions } from '../../../shared/components/BcdRadioGroupOptioned';
import { IDashboardTab, DashboardTabsLabels, DashboardTabsValues } from '../../models';

export const dashboardTabsOptions: IDashboardTab = [
  {
    value: DashboardTabsValues.reactions,
    label: DashboardTabsLabels.reactions,
  },
  {
    value: DashboardTabsValues.workProjects,
    label: DashboardTabsLabels.workProjects,
  },
  {
    value: DashboardTabsValues.ownedProjects,
    label: DashboardTabsLabels.ownedProjects,
  },
];

export const DashboardTabs = ({value, changeHandler}) => {
  const [tabValue, seTabValue] = useState(dashboardTabsOptions[0]?.value);

  useEffect(() => {
    const currentTab = dashboardTabsOptions.find(x => x.value === value);
    currentTab
      ? seTabValue(currentTab.value)
      : changeHandler(tabValue);
  }, [value]);

  return (
    <BcdRadioGroupWithOptions
      name         = "dashboardTabs"
      options      = { dashboardTabsOptions }
      value        = { tabValue }
      onChange     = { val => changeHandler(val) }
      className    = { css.tabs }
    />
  );
};
