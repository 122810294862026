// @flow
import { createSelector } from 'reselect';
import type { OptionType } from '../../shared/models';
import type { IReaction, IReactionEvent, IReactionYield } from '../models';
import type { IRootStore } from '../../app/reducers';
import {
  Reaction,
  ReactionCompletionKeyNames,
  ReactionCompletionYield,
  ReactionCompletionYieldKeyNames,
  ReactionKeyNames,
} from '../models';
import { RolesMap } from '../../account/models/roles-map';
import { REACTION_TYPE_PURIFICATION_ID } from '../../shared/globals/constants';
import { projectTypeEnum } from '../../projects/types/enum';
import type { TReactionEditCurrent } from './module';
import type { IReagent } from '../../reagents/model';
import type { IProduct } from '../../products/model';
import { getReagents } from '../../reagents/reducers';
import { getProducts } from '../../products/reducers';
import { getArchivedReagents } from '../../archivedReagents/reducers';
import {
  getReactionFromForm,
  getReagentsFromForm,
  isReactionFormIsValid,
  sortReactantsByType,
} from './reaction-edit-form-plugin';
import { ReactionEventTypesEnum } from '../enums/reaction-events.enum';
import { ReactionEvent } from '../models/reaction-event.model';
import type { IMergedStage, IObtainmentStage } from '../../stages/models';
import { Product, ProductKeyNames } from '../../products/model';
import type { IAgent, IJarStock } from '../../agents/models';
import type { TReagentAddFromDialogAction } from '../actions';
import { ReagentTypeEnum } from '../../reagents/model';
import { Agent, JarStock_Model } from '../../agents/models';
import { calculateMolarWeight } from '../../shared/services/compound/molar-weight-calculator';
import { CompoundFormula } from '../../shared/models';
import {getFormValues, isDirty} from 'redux-form';
import { REACTION_EDIT_FORM_NAME } from '../components/ReactionEdit';
import { getUserName, getUserRoles } from '../../account/reducers';
import { MAIN_PRODUCT_FIELDS } from '../components/CompleteReaction/MainProductSubForm';
import { INCLUDE_MAIN_PRODUCT_FIELD_NAME } from '../components/CompleteReaction';
import { REACTION_REACTANTS_ARRAY_FIELD_NAME } from '../components/ReactionEdit/ReagentsTable';
import type {IArchivedReagent} from '../../archivedReagents/model';
import {userCRUDPermissionsSelector} from '../../account/reducers/users-modules-reducer';
import { ReactionAttachmentType } from '../models';
import {getReactionIndicators} from '../../reaction-indicator-parameters/reducers';
import {REACTION_IND_PARAMETERS_ARRAY_FIELD_NAME} from '../components/ReactionEdit/ReactionIndicators';
import type {IReactionIndicatorParameter} from '../../reaction-indicator-parameters/models';
import {areReactionIndicatorParametersFilled} from '../../reaction-indicator-parameters/models';


export type TCurrentReactionStates = {
  isEditable: boolean,
  isReadonly: boolean,
  isCommentEditable: boolean,
  isPartlyEditable: boolean,
  isPurification: boolean,
  isPurificationEditable: boolean,
  isCompleteAvailable: boolean,
  isCompleteNotValid: boolean,
  isDeleteAvailable: boolean,
  hasZeroConsumption: boolean,
  hasEmptyValues: boolean,
  hasReagentWithoutAgent: boolean,
  hasYieldOrSuccessStatus: boolean,
  hasLessThanMassConsumption: boolean,
  hasPositiveFactualConsumption: boolean,
  canHaveMultipleYields: boolean,
  canAddProductAfterCompletion: boolean,
}

export const getSortedProjects = createSelector(
    (state) => state.modules.reaction.list?.allProjects,
    (data: OptionType[]) => data.sort((a, b) =>
    (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
);

export const getSidebarReactions = (state: IRootStore) => (
  state.modules.reaction.reactionsSideBar.reactions
);

export const getSidebarFolders = (state: IRootStore) => (
    state.modules.reaction.reactionsSideBar.folders
);

export const getSidebarPagination = (state: IRootStore) => (
    state.modules.reaction.reactionsSideBar.reactionsPagination
);

export const getSidebarPending = createSelector(
    (state: IRootStore) => ({
      pendingReactions: state.resource.reactions.pending,
      pendingFolders: state.resource._folders_temp.pending,
    }),
    ({ pendingReactions, pendingFolders }) => pendingReactions && pendingFolders,
);

export const getReactionByCurrentId = createSelector(
  [
    (state: IRootStore) => state.resource.reactions.data,
    (state: IRootStore) => state.modules.reaction.editReaction.current.reactionId,
    (state: IRootStore) => state.modules.reaction.editReaction.current.stageId,
    (state: IRootStore) => state.modules.reaction.editReaction.current.isPurification,
    (state: IRootStore) => state.modules.reaction.editReaction.current.smilesFormula,
    (state: IRootStore) => state.modules.reaction.editReaction.current.folderId,
    (state: IRootStore) => state.modules.reaction.editReaction.current.isArchived,
    (state: IRootStore) => state.modules.reaction.editReaction.current.compoundName,
    (state: IRootStore) => state.modules.reaction.editReaction.current.primaryReactionId,
		getUserName
  ],
  (data, currentId, stageId, isPurification, smilesFormula, folderId, isArchived, compoundName, primaryReactionId, userName): IReaction => {
    if (currentId) {
      return data[currentId];
      } else if (stageId) {
        return Reaction({ AuthorUserName: userName, InitialStageId: stageId, FolderId: folderId });
      } else if (isPurification) {
        return Reaction({
          AuthorUserName: userName,
          IsPurification: true,
          ReactionType: REACTION_TYPE_PURIFICATION_ID,
          PrimaryReactionId: primaryReactionId,
          Compound: {
            SmilesFormula: smilesFormula,
            CompoundName: compoundName,
          }
        });
      } else {
        return null;
      }
    }
);

export const getCurrentReaction = (state: IRootStore): TReactionEditCurrent => (
    state.modules.reaction.editReaction.current
);

export const getReactionPendingState = createSelector(
    [
      (state: IRootStore) => state.resource.reagents.pending,
      (state: IRootStore) => state.resource.products.pending,
      (state: IRootStore) => state.resource.reactions.pending,
      (state: IRootStore) => state.resource.stages.pending,
      (state: IRootStore) => state.resource.analytics.smallMoleculesReaction.pending,
    ],
    (...args) => args.some((pending: boolean) => pending === true)
);

export const getCurrentReactionStates: (IRootStore) => TCurrentReactionStates =
    createSelector(
      [
        getReactionFromForm,
        getReagentsFromForm,
        getUserRoles,
        getUserName,
        isReactionFormIsValid
      ],
      (
        reaction: IReaction,
        reagents: IReagent[],
        roles: string[],
        userName: string,
        valid: boolean,
      ): TCurrentReactionStates => {
        if (!reaction || !roles || !userName || !reagents) return;
        const
          isAuthor      = reaction.AuthorUserName === userName,
          isSuperUser   = roles.includes(RolesMap.SuperUser),
          isProjectLead = roles.includes(RolesMap.ProjectLead),
          isUser        = roles.includes(RolesMap.User),
          isEditor      = isAuthor || isProjectLead || isSuperUser || isUser,
          isCompleted   = reaction.IsCompleted && !reaction.IsArchived
        ;
        const
          isEditable                    = (!isCompleted && isEditor)
                                        || (isCompleted && (isSuperUser || isProjectLead))
                                        || (reaction?.IsArchived && !isCompleted && roles.includes(RolesMap.ArchiveViewer)),
          isReadonly                    = !isEditable,

          isTabsEditable                = (isSuperUser || isProjectLead) || (reaction?.IsArchived && !isCompleted && roles.includes(RolesMap.ArchiveViewer)),

          isPurificationEditable        = (!isCompleted && isEditor)
                                          || (isCompleted && (isSuperUser || isProjectLead || isUser))
                                          || (reaction?.IsArchived && !isCompleted && roles.includes(RolesMap.ArchiveViewer)),
          isCommentEditable             = isEditor || isUser,
          isPartlyEditable              = !!isEditor,
          hasPositiveFactualConsumption = reagents.some((reagent: IReagent) => !reagent.IsDeleted && (reagent.FactualConsumption && reagent.FactualConsumption >= 0)),
          isPurification                = reaction?.ReactionType === REACTION_TYPE_PURIFICATION_ID,
          canHaveMultipleYields         = (reaction?.ProjectType === projectTypeEnum.generic || reaction?.ProjectType === projectTypeEnum.innovative || isPurification)
                                            && !reaction.IsTrial,
          hasZeroConsumption            = reagents.some((reagent: IReagent) => !reagent.IsDeleted && !reagent.FactualConsumption || reagent.FactualConsumption <= 0),
          hasEmptyValues                = reagents.some((reagent: IReagent) => (
                                            !reagent.IsDeleted
                                              && ((!reagent.Mass || reagent.Mass <= 0) && reagent.IsRefCompound)
                                              || (!reagent.Eq || reagent.Eq <= 0)
                                              || (!reagent.MainAgentContent || reagent.MainAgentContent <= 0)
                                            )),
          hasReagentWithoutAgent        = reagents.some((reagent: IReagent) => !reagent.Agent),
          hasYieldOrSuccessStatus       = (reaction.IsTrial && reaction.IsSuccessful !== null) || reaction.ExperimentalYield > 0,
          hasLessThanMassConsumption    = reagents.some((reagent: IReagent) => !reagent.IsDeleted && reagent.FactualConsumption < reagent.Mass),
          isCompleteAvailable           = (
                                            valid
                                            && !isReadonly
                                            && !reaction.IsArchived
                                            && !reaction.IsCompleted
                                            && !reaction.IsDeleted
                                            && !hasZeroConsumption
                                            && !hasEmptyValues
                                            && !hasReagentWithoutAgent
                                            && reaction.ReactionType > 0
                                            && ((typeof reaction.ExperimentalYield === 'number' && reaction.ExperimentalYield >= 0) || reaction.IsTrial)
                                            && Array.isArray(reaction?.ReactionParameters) && reaction.ReactionParameters.filter(i => !i.IsDeleted).length > 0
                                            && areReactionIndicatorParametersFilled(reaction[REACTION_IND_PARAMETERS_ARRAY_FIELD_NAME])
                                            && (!reaction.IsTrial || reaction.IsSuccessful !== null)
                                          ),
          isCompleteNotValid            = !reaction.IsArchived && !reaction.IsCompleted && !isCompleteAvailable,
          isDeleteAvailable             = !!reaction.Id && !reaction.IsCompleted && !reaction.IsDeleted && !isReadonly && !hasPositiveFactualConsumption,
          canAddProductAfterCompletion  = reaction.IsCompleted && !reaction.IsTrial && !reaction.IsArchived && isPartlyEditable;
        return {
          isEditable,
          isReadonly,
          isCommentEditable,
          isPartlyEditable,
          isPurification,
          isTabsEditable,
          canHaveMultipleYields,
          isCompleteAvailable,
          isPurificationEditable,
          hasPositiveFactualConsumption,
          hasZeroConsumption,
          hasReagentWithoutAgent,
          hasEmptyValues,
          hasYieldOrSuccessStatus,
          hasLessThanMassConsumption,
          isCompleteNotValid,
          isDeleteAvailable,
          canAddProductAfterCompletion
        };
    }
  );

export const getProductsByCurrentReactionId: (state: IRootStore) => IProduct[] = createSelector(
    [getProducts, getCurrentReaction],
    (products: IProduct[], current: TReactionEditCurrent) => products.filter((product: IProduct) => product.ReactionId === current?.reactionId)
);

export const getReagentsByCurrentReactionId: (state: IRootStore) => IReagent[] = createSelector(
    [getReagents, getCurrentReaction],
    (reagents: IReagent[], current: TReactionEditCurrent) => reagents.filter((reagent: IReagent) => reagent.ReactionId === current?.reactionId)
);

export const getArchivedReagentsByCurrentReactionId: (state: IRootStore) => IArchivedReagent[] = createSelector(
	[getArchivedReagents, getCurrentReaction],
	(archivedReagents: IArchivedReagent[], current: TReactionEditCurrent) => archivedReagents.filter((archivedReagents: IArchivedReagent) => archivedReagents.ReactionId === current?.reactionId)
);

export const getReactantsByReactionId: (state: IRootStore) => (IReagent | IProduct | IArchivedReagent)[] =
  createSelector(
    [
      getProductsByCurrentReactionId, getReagentsByCurrentReactionId,
			getReactionByCurrentId, getArchivedReagentsByCurrentReactionId
    ],
		(products: IProduct[], reagents: IReagent[], reaction: IReaction, archivedReagents: IArchivedReagent[]) => (reaction && reaction.IsArchived) ?
			[...products, ...archivedReagents].sort(sortReactantsByType) :
			[...products, ...reagents].sort(sortReactantsByType)
  );

export const getEventsByCurrentReactionId: (state: IRootStore) => IReactionEvent[] = createSelector(
    [
      getCurrentReaction,
      (state: IRootStore) => Object.values(state.resource.eventsHistory.data),
      (state: IRootStore) => state.resource.reactionTypes.data,
    ],
    (current: TReactionEditCurrent, data: IReactionEvent[], reactionTypes) =>
      current?.reactionId
      ? data
        .filter((item: IReactionEvent) => item.ReactionId === current.reactionId)
        .map((item: IReactionEvent) => {
          const ev = new ReactionEvent(item, reactionTypes);
          const AdditionalInfo = ev.parseInfo(item.AdditionalInfo);
          return { ...ev, AdditionalInfo };
        })
      : []
);

export const getAddedJarsByCurrentReaction: (state: IRootStore) => IReactionEvent[] = createSelector(
    [
      getEventsByCurrentReactionId,
    ],
    (data: IReactionEvent[]) =>
      data
        ? data.filter((item: IReactionEvent) => item.EventType === ReactionEventTypesEnum.JarAdded)
        : []
);

export const getHighlightedSubstance = (state: IRootStore) => (
    state.modules.reaction.editReaction.highlightedSubstance
);

export const getLastOpenedDialog = (state: IRootStore) => (
    state.modules.reaction.editReaction.dialogsQueue.slice(-1)[0]
);

export const dialogsOpened = createSelector(
    [
        (state: IRootStore) => state.modules.reaction.editReaction.dialogsQueue
    ],
    (dialogs) => dialogs.length > 0
);

export const getCurrentReactionParentStage = createSelector(
    [
      getCurrentReaction,
      (state: IRootStore) => state.resource.stages.data
    ],
    (current: TReactionEditCurrent, data: {}) => data[current?.stageId] || null
);

export const getSmilesFormulasFromParentStage = createSelector(
    [getCurrentReactionParentStage],
    (stage: IObtainmentStage) => {
      if (!stage) return null;
      return [
        stage.PreviousStageCompound?.SmilesFormula,
        ...stage.MergedStages.map((stage: IMergedStage) => stage.Formula?.SmilesFormula),
    ];
    }
);

export const getComplexCompoundNamesFromParentStage = createSelector(
  [getCurrentReactionParentStage],
  (stage: IObtainmentStage) => {
    if (!stage) return null;
    const result = [];
    if(stage.PreviousStageCompound.IsPolymer || stage.PreviousStageCompound.IsAntibody)
    {
      result.push(stage.PreviousStageCompound?.Name);
    }
    result.push(
        ...stage.MergedStages
            .filter(stage => stage.Formula.IsPolymer || stage.Formula.IsAntibody)
            .map((stage: IMergedStage) => stage.Formula?.Name)
    );
    return result;
  }
);

export const getProductFromStage = createSelector(
    [getCurrentReactionParentStage, getCurrentReaction],
    (stage: IObtainmentStage, current: TReactionEditCurrent) => {
      if (!stage || !current) return null;
      const stageCompound = new CompoundFormula(stage?.Compound);
      if (!stageCompound.MolarWeight) {
        stageCompound.MolarWeight = calculateMolarWeight(stageCompound.MolecularFormula);
      }
      return Product({
        MolarWeight: stageCompound.MolarWeight,
        CompoundId: stageCompound.Id,
        ReactionId: current.reactionId || 0,
        Compound: stageCompound,
        Name: stageCompound.Name,
        IsMainProduct: true,
        MainAgentContent: 100
    });
    }
);

export const getSelectedJarsWithAgents = createSelector(
    [
      (state) => state,
      (state: IRootStore) => state.modules.reaction.jarStock.savedJars,
      (state: IRootStore) => state.modules.reaction.jarStock.agentsData,
      getCurrentReactionParentStage,
      (state: IRootStore) => state.modules.reaction.editReaction.current.isPurification,
      (state: IRootStore) => state.modules.reaction.editReaction.current.smilesFormula,
    ],
    (state,
      jars: IJarStock[],
     agentsData: IAgent[],
     stage: IObtainmentStage,
     isPurification: boolean,
     smilesFormula: string): TReagentAddFromDialogAction[] => {
      if (!jars || !agentsData) return;
      const agentsIdsFromJars = jars
        .map((jar: IJarStock) => jar.AgentId)
      .reduce((acc: number[], cur: number) => (acc.includes(cur) ? acc : [...acc, cur]), []);
      return agentsData
        .filter((agent: IAgent) => agentsIdsFromJars.includes(agent.Id))
        .map((agent: IAgent) => {
          const agentJars: IJarStock[] = jars
            .filter((jar: IJarStock) => jar.AgentId === agent.Id && jar.Amount > 0)
            .map((jar: IJarStock) => JarStock_Model(jar));
          
          //=== Поддержка одинаковых реагентов ===
          // Этот код необходим для копирования реакций с подстроками.
          // находим одинаковые агенты
          const sameAgents = agentsData.filter(i => i.Id == agent.Id);
          // находим индекс текущего агента среди одинаковых агентов
          const index = sameAgents.indexOf(agent);
          // группируем выбранные банки по реагентам
          const reagentGroups = groupJarsByReagents(agentJars);
          const reagentJars = reagentGroups[index].jars;
          //////////////////////////////

          let reagentType: number;
          if (isPurification) {
            reagentType = agent.OriginalSmilesFormula === smilesFormula
              ? ReagentTypeEnum.Original
              : ReagentTypeEnum.Reactant;
          } else {
            reagentType = agent.OriginalSmilesFormula === stage.PreviousStageCompound?.SmilesFormula
              ? ReagentTypeEnum.Original
              : ReagentTypeEnum.Reactant;
          }
          return {
            agent: Agent(agent),
            availability: reagentJars.sort((jar: IJarStock) => jar.Id)[0],
            jars: reagentJars,
            reagentType
          };
        });
    }
);

function groupJarsByReagents(agentJars) {
  const reagentGroups = [];
  for (const jar of agentJars) {
    let group = reagentGroups.find(g => g.key == jar.reagentId);
    if (!group) {
      group = { key: jar.reagentId, jars: [] };
      reagentGroups.push(group);
    }
    group.jars.push(jar);
  }
  return reagentGroups;
}

export const getCurrentReactionMainProduct = createSelector(
    [
      (state: IRootStore) => state.modules.reaction.editReaction.current.reactionId,
      (state: IRootStore) => Object.values(state.resource.products.data)
    ],
    (reactionId: number, data: IProduct[]) => {
      const mainProduct: IProduct = Array.isArray(data)
          && data.find((product: IProduct) => product.IsMainProduct && product.ReactionId === reactionId)
      ;
      if (!mainProduct) return null;
      return mainProduct;
    }
);

export const getCompletionReactionInitialFormFromReaction = createSelector(
    [getReactionByCurrentId, getCurrentReactionMainProduct],
    (reaction: IReaction, product: IProduct) => ({
    [ReactionCompletionKeyNames.ReactionId]: reaction?.Id,
    [ReactionCompletionKeyNames.StageId]: reaction?.InitialStageId,
    [ReactionCompletionKeyNames.Comment]: '',
    [ReactionCompletionKeyNames.Products]: [],
    [ReactionCompletionKeyNames.Yields]: (reaction?.HasMultipleYields && Array.isArray(reaction?.ReactionYields))
                                                ? reaction?.ReactionYields
                                                    .filter((item: IReactionYield) => item.ShouldCreateJar && !item.IsDeleted)
                                                    .map((item: IReactionYield) => ReactionCompletionYield({
                                                      ...item,
                                                      [ReactionCompletionYieldKeyNames.OriginalYieldAmount]: item.ExperimentalYield,
          [ReactionCompletionYieldKeyNames.Amount]: item.ExperimentalYield,
          [ReactionCompletionYieldKeyNames.MainAgentContent]: product?.MainAgentContent
                                                    }))
                                                : null,
    [ReactionKeyNames.QuickComment]: reaction?.QuickComment,
    [ReactionKeyNames.Name]: reaction?.Name,
    [MAIN_PRODUCT_FIELDS]: {
          [ProductKeyNames.NameAliases]: [],
          [ProductKeyNames.ExperimentalMainAgentContent]: product?.MainAgentContent,
          [ProductKeyNames.Yield]: (reaction?.HasMultipleYields && reaction?.ReactionYields && Array.isArray(reaction?.ReactionYields))
                                              ? reaction.ReactionYields
                                                .filter((item: IReactionYield) => item.ShouldCreateJar && !item.IsDeleted)
                                                .reduce((acc: number, cur: IReactionYield) => (acc + cur.ExperimentalYield), 0)
                                              : reaction?.ExperimentalYield,
          [ProductKeyNames.LaboratoryId]: null,
          [ProductKeyNames.Location]: null,
          [ProductKeyNames.ExperimentalMainAgentContent]: null,
      },
      [INCLUDE_MAIN_PRODUCT_FIELD_NAME]: false,
    })
);

export const getReactionEditFormInitValues = createSelector(
  [
      getReactionByCurrentId,
      getReactantsByReactionId,
      (state: IRootStore) => state.modules.reaction.editReaction.current.isArchived,
      getReactionIndicators,
  ],
  (reaction: IReaction = Reaction(), substances: (IProduct | IReagent | IArchivedReagent)[] = [], isArchived: boolean, indicators: IReactionIndicatorParameter[]) => ({
    ...reaction,
    IsArchived: reaction?.IsArchived || isArchived,
    [REACTION_REACTANTS_ARRAY_FIELD_NAME]: substances,
    [REACTION_IND_PARAMETERS_ARRAY_FIELD_NAME]: indicators?.sort((a, b) => a.StageIndicator?.OrderPriority - b.StageIndicator?.OrderPriority),
  })
);

export const getAddedAttachments = (state: IRootStore) => Object.values(state.modules.reaction.attachments.added);

export const getDeletedAttachments = (state: IRootStore) => Object.values(state.modules.reaction.attachments.deleted);

export const getReactionAttachments = createSelector(
    [
        (state: IRootStore) => Object.values(state.resource.reactionAttachments.data),
        getAddedAttachments,
        getDeletedAttachments,
    ],
    (loaded, added, deleted) => (
        [...loaded, ...added].filter((item) => deleted.indexOf(item) < 0)
    )
);

export const getReactionFilesByAttachmentType = createSelector(
    [getReactionAttachments],
    (attachments) => ({
        [ReactionAttachmentType.MS]: attachments.filter(a => a.ReactionAttachmentType === ReactionAttachmentType.MS),
        [ReactionAttachmentType.NMR]: attachments.filter(a => a.ReactionAttachmentType === ReactionAttachmentType.NMR),
        [ReactionAttachmentType.TLC]: attachments.filter(a => a.ReactionAttachmentType === ReactionAttachmentType.TLC),
        [ReactionAttachmentType.HPLCMS]: attachments.filter(a => a.ReactionAttachmentType === ReactionAttachmentType.HPLCMS),
        [ReactionAttachmentType.Other]: attachments.filter(a => a.ReactionAttachmentType === ReactionAttachmentType.Other),
    })
);

export const getReactionFileCountsByAttachmentType = createSelector(
  [getReactionFilesByAttachmentType],
  (attachments) => Object.fromEntries(Object.keys(attachments).map(k => ([k, attachments[k].length])))
);

export const getCloneSourceReactionId = (state: IRootStore) => (
  state.modules.reaction.editReaction.cloning.cloneSourceReactionId
);

export const preventReactionFormSubmit = createSelector(
    [getReactionPendingState, isReactionFormIsValid, userCRUDPermissionsSelector, getReactionFromForm, dialogsOpened],
    (pending, isValid, userPermissions, reaction, anyDialogOpened) =>
        pending
        || !isValid
        || !(reaction?.IsArchived ? userPermissions.archivedReactions.CREATE : userPermissions.reaction.CREATE)
        || anyDialogOpened
);

export const getReactionEditValues = createSelector(
    [
        getFormValues(REACTION_EDIT_FORM_NAME),
        (state: IRootStore) => state.modules.reaction.attachments.added,
        (state: IRootStore) => state.modules.reaction.attachments.deleted,
    ],
    (values, addedFiles, deletedFiles) => ({ values, addedFiles, deletedFiles })
);

export const isReactionEditIsDirty = createSelector(
    [
        isDirty(REACTION_EDIT_FORM_NAME),
        (state: IRootStore) => state.modules.reaction.attachments.added,
        (state: IRootStore) => state.modules.reaction.attachments.deleted,
    ],
    (isDirty, addedFiles, deletedFiles) => (
        isDirty
        || (Array.isArray(addedFiles) && addedFiles.length>0)
        || (Array.isArray(deletedFiles) && deletedFiles.length>0)
    )
);

