// @flow

import React, {
  useState,
  useEffect,
  useRef
} from 'react';
import { _noop } from '../../../shared/utils/common';
import type { IFolder } from '../../models';
import bnc from 'bnc/index';
import Button from '@biocad/bcd-front-ui/controls/Button';
import Dialog from '@biocad/bcd-front-ui/layout/Dialog';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import type { IRootStore } from '../../../app/reducers';
import { _temp_folderResourceAction } from '../../actions';
import { SelectFolderForm, FOLDER_SELECT_FORM } from './SelectFolderForm';

import './index.less';
import { getFormValues } from 'redux-form';
import { getFolderSelectSuccess, getLastSelectedReaction } from '../../reducers/module';


interface ISelectFolderProps {
  folders: IFolder[],
}

let SelectFolder = (props: ISelectFolderProps) => {
  // Ref
  const wrapperRef = useRef<HTMLDivElement>();

  // States
  const
    [dialogOpened, setDialogOpened] = useState<boolean>(false);
  const foldersOptions = props.folders.map((f) => ({value:f.Id, label:f.Name}));

  const _closeOnSave = () => {
    if (props.folderSavedSuccessful !== null || props.schemeFolderSavedSuccessful !== null ) {
      setDialogOpened(false);
    }
  };
  useEffect(_closeOnSave, [props.folderSavedSuccessful, props.schemeFolderSavedSuccessful]);

  // Handlers
  const
    toggleDialog = () => {
      setDialogOpened(prev => !prev);
    },
    saveFolder = (event: SyntheticEvent) => {
      event.preventDefault();
      props.selectFolder(props.formValue.Id);
    },
    stopPropagation = (event: SyntheticEvent) => { // Нужно так как иначе из диалога будет прокликиваться родительский элемент
      if (wrapperRef?.current.contains(event.target)) event.stopPropagation();
    }
  ;

  return (
    <div ref={wrapperRef}
         onClick={stopPropagation}
    >
      <Button
        className='fo-folder'
        view={'icon'}
        size={'L'}
        onAction={toggleDialog}
      />
      {
        dialogOpened &&
        <Dialog paranjaClose={false} close={toggleDialog}>
          <SelectFolderForm
            selectFolder={saveFolder}
            toggleDialog={toggleDialog}
            folders={foldersOptions}
          />
        </Dialog>
      }
    </div>
  )
};

SelectFolder = connect(
  (state: IRootStore): ISelectFolderProps => ({
    formValue: getFormValues(FOLDER_SELECT_FORM)(state),
    folderSavedSuccessful: state.modules.reaction?.selectFolder?.savedSuccessful,
    schemeFolderSavedSuccessful: state.modules._folder_temp?.selectFolder?.savedSuccessful,
  }),
  (dispatch: Dispatch): ISelectFolderProps => ({
    updateFolder: (data: IFolder) => dispatch(_temp_folderResourceAction.update.request({ data })),
  })
)(SelectFolder);

export {
  SelectFolder,
}
