// @flow

import { Model, BasicModel } from 'objectmodel';
import type { IIndicatorUnit } from './i-indicator-unit';

export const IndicatorUnit: (IIndicatorUnit)=>IIndicatorUnit = Model({
    Id              : [Number],
    Name            : [String],
    IsDeleted       : [Boolean],
    IndicatorInd    : [Number],
});
