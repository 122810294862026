import { Component }    from 'react';
import propTypes        from 'prop-types';
import Button           from '../../controls/Button';
import bnc              from 'bnc';
import                       './index.less';

export default class Dialog extends Component {

    static propTypes = {
        close:        propTypes.func,
        paranjaClose: propTypes.bool
    };

    static block = new bnc.default('b-dialog')

    state = {paranjaPressed:false}

    keyClose = ({key, code}) => (code === 'Escape' || key === 'Escape') && this.props.close()

    stopEvent = e => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }

    componentDidMount(){
        this.props.close && document.addEventListener('keyup', this.keyClose);
    }

    componentWillUnmount(){
        this.props.close && document.removeEventListener('keyup', this.keyClose);
    }

    paranjaMouseUp = () =>
        this.props.paranjaClose &&
        this.props.close &&
        this.state.paranjaPressed &&
        this.props.close()

    paranjaPressed = paranjaPressed => () =>
        this.setState({paranjaPressed})

    paranja = React.createRef()

    renderPortal = ({children, close, paranjaClose}) =>
        ReactDom.createPortal(
            <div
                ref         = { this.paranja               }
                className   = { Dialog.block.el('paranja') }
                onMouseUp   = { this.paranjaMouseUp        }
                onMouseDown = { this.paranjaPressed(true)  }
            >
                <div
                    className   = { Dialog.block }
                    onMouseUp   = { this.stopEvent }
                    onMouseDown = { this.stopEvent }
                    onMouseMove = { this.paranjaPressed(false) }
                >
                    <div className={Dialog.block.el('wrapper-content')}>
                        {children}
                    </div>
                    {
                        close &&
                        <div className={Dialog.block.el('wrapper-close')}>
                            <Button
                                className   = { Dialog.block.el('close') }
                                onAction    = { close }
                                text        = "✕"
                                view        = "pseudo"
                            />
                        </div>
                    }
                </div>
            </div>,
            document.body
        )

    render = () => this.renderPortal(this.props)
}