// @flow

import React from 'react';
import bnc from 'bnc';
import { Field, Fields } from 'redux-form';
import {
  BcdDropdownWrapper,
  BcdInputWrapper,
  MarvinSketch,
  FormFieldLayout
} from '../../../shared/components';
import { ProductNameAliasesInput } from '../ProductNameAliasesInput';
import { ProductKeyNames } from '../../model';
import type {IFieldComponentProps, OptionType} from '../../../shared/models';
import type {ICompound} from '../../../agents/models';
import Button from '@biocad/bcd-front-ui/controls/Button';
import type {IProduct} from '../../model';
import { labsLoaderHOC } from '../../../dictionary/components/labsLoaderHOC';
import type { ILabsLoaderWrappedComponentProps } from '../../../dictionary/components/labsLoaderHOC';
import { ReactionCompletionKeyNames } from '../../../reactions/models';
import './index.less';
import { ProductFieldValidators } from './field-validators';


interface IProductFormFieldsProps extends ILabsLoaderWrappedComponentProps {
  className?: string,
  onDelete?: ()=>void,
  product?: IProduct,
  parentFieldName?: string,
}

const defaultProps: IProductFormFieldsProps = {
  className: '',
};

const MARVIN_FIELDS = [ProductKeyNames.Name, ProductKeyNames.MolarWeight, ProductKeyNames.Compound];

const
  BNCBlock                              = new bnc('b-ProductFormFields'),
  BNCField                              = BNCBlock.el('field'),
  BNCFieldName                          = BNCField.mod('name'),
  BNCFieldNameAliases                   = BNCField.mod('nameAliases'),
  BNCFieldLaboratoryId                  = BNCField.mod('laboratoryId'),
  BNCFieldLocation                      = BNCField.mod('location'),
  BNCFieldShortName                     = BNCField.mod('shortName'),
  BNCFieldYield                         = BNCField.mod('yield'),
  BNCFieldExperimentalMainAgentContent  = BNCField.mod('experimentalMainAgentContent'),
  BNCFieldDensity                       = BNCField.mod('density'),
  BNCFieldConcentration                 = BNCField.mod('concentration'),
  BNCMarvin                             = BNCBlock.el('marvin'),
  BNCHint                               = BNCBlock.el('hint'),
  BNCRequiredSign                       = BNCBlock.el('requiredSign'),
  BNCDeleteButton                       = BNCBlock.el('deleteButton')
;

const
  prependParentFieldName = (parentField: string, childField: string): string => (
    (parentField && typeof parentField === 'string' && parentField.length && childField && typeof childField === 'string' && childField.length)
      ? `${parentField}.${childField}`
      : childField
  ),
  marvinValidator = (value: string | {}, allValues: any, props: IProductFormFieldsProps, name: string) => {
    for (let fName of MARVIN_FIELDS)
      if (name.endsWith(fName) && fName in ProductFieldValidators) {
        for (let fn of ProductFieldValidators[fName]) {
          const validity = fn(value);
          if (validity) return validity;
        }
      }
    }
;

const
  renderMarvinComponent = (props) => {
    const handleMarvinChange = (compound: ICompound) => {
      if (!compound) return;
      let fields: { [key: string]: IFieldComponentProps };
      if (ReactionCompletionKeyNames.Products in props && Array.isArray(props[ReactionCompletionKeyNames.Products])) {
        fields = props[ReactionCompletionKeyNames.Products][props[ReactionCompletionKeyNames.Products].length-1];
      }
      else if (ProductKeyNames.Compound in props && ProductKeyNames.Name in props && ProductKeyNames.MolarWeight in props) {
        fields = {
          [ProductKeyNames.Compound]    : props[ProductKeyNames.Compound],
          [ProductKeyNames.Name]        : props[ProductKeyNames.Name],
          [ProductKeyNames.MolarWeight] : props[ProductKeyNames.MolarWeight]
        };
      }
      else {
        return;
      }
      if (fields) {
        fields[ProductKeyNames.Compound]?.input?.onChange(() => compound);
        fields[ProductKeyNames.Name]?.input?.onChange(() => compound?.Name);
        fields[ProductKeyNames.MolarWeight]?.input?.onChange(() => compound?.MolarWeight);
      }
    };
    return (
      <MarvinSketch
        className={BNCMarvin.toString()}
        handleChange={handleMarvinChange}
      />
    );
  },

  renderProductName      = (props: IFieldComponentProps) => (
    <FormFieldLayout className={BNCField + BNCFieldName}>
      {
        (props?.input?.value && typeof props?.input?.value === 'string') ? props?.input?.value : 'Необходимо ввести структурную формулу побочного продукта'
      }
    </FormFieldLayout>
  )
;

let ProductFormFields = (props: IProductFormFieldsProps = defaultProps) => (
  <div className={BNCBlock + props.className}>
    {
      (props.onDelete && typeof props.onDelete === 'function') &&
      <Button
        onAction={(event) => props.onDelete(props.product, event)}
        size={'S'}
        text={'Удалить'}
        className={BNCDeleteButton.toString()}
      />
    }
    <Fields
      names={MARVIN_FIELDS.map((fName) => prependParentFieldName(props.parentFieldName, fName))}
      component={renderMarvinComponent}
      validate={marvinValidator}
    />
    <Field
      name={prependParentFieldName(props.parentFieldName, ProductKeyNames.Name)}
      component={renderProductName}
    />
    { !props.product?.IgnoreJarCreation &&
      <>
      <Field
        name={prependParentFieldName(props.parentFieldName, ProductKeyNames.NameAliases)}
        component={ProductNameAliasesInput}
        className={BNCField + BNCFieldNameAliases}
      />
      <Field
        label={'Расположение'}
        name={prependParentFieldName(props.parentFieldName, ProductKeyNames.LaboratoryId)}
        component={BcdDropdownWrapper}
        validate={ProductFieldValidators[ProductKeyNames.LaboratoryId]}
        format={(value) => props.labsOptions?.find((opt: OptionType) => opt.value === value)}
        options={props.labsOptions}
        wrapperClassName={BNCField + BNCFieldLaboratoryId}
        markPristineValidity
        useFormFieldLayout
        required
      />
      <Field
        label={'Место'}
        name={prependParentFieldName(props.parentFieldName, ProductKeyNames.Location)}
        validate={ProductFieldValidators[ProductKeyNames.Location]}
        component={BcdInputWrapper}
        wrapperClassName={BNCField + BNCFieldLocation}
        markPristineValidity
        useFormFieldLayout
        required
      />
      <Field
        label={'Сокращенное обозначение'}
        name={prependParentFieldName(props.parentFieldName, ProductKeyNames.ShortName)}
        component={BcdInputWrapper}
        wrapperClassName={BNCField + BNCFieldShortName}
        validate={ProductFieldValidators[ProductKeyNames.ShortName]}
        useFormFieldLayout
        markPristineValidity
      />
      <Field
        label={'Количество вещества, г'}
        name={prependParentFieldName(props.parentFieldName, ProductKeyNames.Yield)}
        component={BcdInputWrapper}
        wrapperClassName={BNCField + BNCFieldYield}
        validate={ProductFieldValidators[ProductKeyNames.Yield]}
        type={'number'}
        useFormFieldLayout
        markPristineValidity
      />
      <Field
        label={'Основное вещество, %'}
        name={prependParentFieldName(props.parentFieldName, ProductKeyNames.ExperimentalMainAgentContent)}
        component={BcdInputWrapper}
        wrapperClassName={BNCField + BNCFieldExperimentalMainAgentContent}
        validate={ProductFieldValidators[ProductKeyNames.ExperimentalMainAgentContent]}
        type={'number'}
        useFormFieldLayout
        markPristineValidity
      />
      <Field
        label={'Плотность, г/мл'}
        name={prependParentFieldName(props.parentFieldName, ProductKeyNames.Density)}
        component={BcdInputWrapper}
        wrapperClassName={BNCField + BNCFieldDensity}
        type={'number'}
        useFormFieldLayout
      />
      <Field
        label={'М, моль/л'}
        name={prependParentFieldName(props.parentFieldName, ProductKeyNames.Concentration)}
        component={BcdInputWrapper}
        wrapperClassName={BNCField + BNCFieldConcentration}
        validate={ProductFieldValidators[ProductKeyNames.Concentration]}
        type={'number'}
        useFormFieldLayout
        markPristineValidity
      />
      <div className={BNCHint.toString()}>
        <i className={BNCRequiredSign.toString()}>*</i> - поля, обязательные для заполнения
      </div>
      </>
    }
  </div>
);

ProductFormFields = labsLoaderHOC(ProductFormFields);

export {
  ProductFormFields
};
