import {
  put,
  takeLeading,
  takeEvery,
  fork,
  select,
  all,
} from 'redux-saga/effects';
import { smallMoleculesAvailabilityResourceActions, smallMoleculesReactionResourceActions, AnalyticsModuleActions } from '../actions';
import { getToken } from '../../account/reducers';
import { AnalyticsService } from '../services';


export function* getSmallMoleculesAvailability(id) {
  const token = yield select(getToken);
  const service = new AnalyticsService(token);
  try {
      const result = yield service.getSmallMoleculesAvailability(id);
      yield put(smallMoleculesAvailabilityResourceActions.list.success(result));
  } catch (e) {
      if (e.response && e.response.data) {
        let message = e.response.data['ErrorMessage'] || e.response.data['ExceptionMessage'];
        if (message) {
          yield put(smallMoleculesAvailabilityResourceActions.list.failure({error: message}));
        }
      }
  }
}


export function* getSmallMoleculesReaction(id) {
  const token = yield select(getToken);
  const service = new AnalyticsService(token);
  try {
      const result = yield service.getSmallMoleculesReaction(id);
      yield put(smallMoleculesReactionResourceActions.list.success(result));
  } catch (e) {
      if (e.response && e.response.data) {
        let message = e.response.data['ErrorMessage'] || e.response.data['ExceptionMessage'];
        if (message) {
          yield put(smallMoleculesReactionResourceActions.list.failure({error: message}));
        }
      }
  }
}

export function* updateSmallMolecule({molecule, index}) {
  const token = yield select(getToken);
  const service = new AnalyticsService(token);

  try {
      const {result} = yield service.updateSmallMolecule(molecule);
      
      yield put(AnalyticsModuleActions.updateMoleculeApi.success({result: {molecule: result, index}}));
  } catch (e) {
      if (e.response && e.response.data) {
        let message = e.response.data['ErrorMessage'] || e.response.data['ExceptionMessage'];
        if (message) {
          yield put(AnalyticsModuleActions.updateMoleculeApi.failure({error: message}));
        }
      }
  }
}

export function* createSmallMolecule({ molecule, index }) {
  const token = yield select(getToken);
  const service = new AnalyticsService(token);
  try {
      const {result} = yield service.createSmallMolecule(molecule);
      yield put(AnalyticsModuleActions.createMoleculeApi.success({result: {molecule: result, index}}));
  } catch (e) {
      if (e.response && e.response.data) {
        let message = e.response.data['ErrorMessage'] || e.response.data['ExceptionMessage'];
        if (message) {
          yield put(AnalyticsModuleActions.createMoleculeApi.failure({error: message}));
        }
      }
  }
}

export function* deleteSmallMolecule({molecule, index}) {
  const token = yield select(getToken);
  const service = new AnalyticsService(token);

  try {
      if (molecule.Id) {
        const {result} = yield service.deleteSmallMolecule(molecule);
        yield put(AnalyticsModuleActions.deleteMoleculeApi.success({result: {molecule: result, index}}));
      } else {
        yield put(AnalyticsModuleActions.deleteMoleculeApi.success({result: {molecule, index}}));
      }
  } catch (e) {
      if (e.response && e.response.data) {
          let message = e.response.data['ErrorMessage'] || e.response.data['ExceptionMessage'];
          if (message) {
            yield put(AnalyticsModuleActions.deleteMoleculeApi.failure({error: message}));
          }
      }
  }
}

export function* saveAnalyticsApi() {
  let molecules: [] = yield select(state => state?.modules?.analytics?.molecules);

  molecules = molecules.map(x => {
    if (typeof x.Amount === 'string') {
      x.Amount = +x.Amount.replace(/,/g, '.');
    }
    return x;
  });
  const listToDelete: number[] = yield select(state => state?.modules?.analytics?.listToDelete);
  const listToUpdate: number[] = yield select(state => state?.modules?.analytics?.listToUpdate);
  
  const findMolecule = (id: number) => ({
    molecule: molecules.find(x => x.Id === id),
    index: molecules.findIndex(x => x.Id === id)          
  });

  try {
    if (listToDelete.length > 0) {
      for(const id of listToDelete) {
        yield deleteSmallMolecule(findMolecule(id));
      }  
    }

    if (listToUpdate.length > 0) {
      for(const id of listToUpdate) {
        yield updateSmallMolecule(findMolecule(id)); 
      }
    }

    let index = 0;
    for(const molecule of molecules) {
      if (molecule.Id === 0 && !molecule.IsDeleted) {
        yield createSmallMolecule ({ molecule, index });
      }
      ++index;
    }

    yield put(AnalyticsModuleActions.completeChanges());

  } catch (e) {
      if (e.response && e.response.data) {
          let message = e.response.data['ErrorMessage'] || e.response.data['ExceptionMessage'];
          if (message) {
            yield put(AnalyticsModuleActions.saveAnalyticsApi.failure({error: message}));
          }
      }
  }
}


export function* destroyAnalyticsModule() {
  yield put(smallMoleculesAvailabilityResourceActions.destroy());
  yield put(smallMoleculesReactionResourceActions.destroy());
}


export const analyticsWatchers = [
  function* watchSmallMoleculesAvailability() {
    yield takeLeading(smallMoleculesAvailabilityResourceActions.list.request.type, function* (action) {
        const {id} = action.params;
        yield fork(getSmallMoleculesAvailability, {id});
    });
  },
  function* watchSmallMoleculesReaction() {
    yield takeLeading(smallMoleculesReactionResourceActions.list.request.type, function* (action) {
        const {id} = action.params;
        yield fork(getSmallMoleculesReaction, {id});
    });
  },
  function* watchUpdateSmallMolecule() {
    yield takeEvery(AnalyticsModuleActions.updateMoleculeApi.request.type, function* (action) {
        const {molecule, index} = action.data;
        yield fork(updateSmallMolecule, {molecule, index});
    });
  },
  function* watchCreateSmallMolecule() {
    yield takeEvery(AnalyticsModuleActions.createMoleculeApi.request.type, function* (action) {
        const {data} = action;
        yield fork(createSmallMolecule, data);
    });
  },

  function* watchDeleteSmallMolecule() {
    yield takeEvery(AnalyticsModuleActions.deleteMoleculeApi.request.type, function* (action) {
        const {molecule, index} = action.data;
        yield fork(deleteSmallMolecule, {molecule, index});
    });
  },

  function* watchSaveAnalyticsApi() {
    yield takeLeading(AnalyticsModuleActions.saveAnalyticsApi.request.type, function* () {
        yield fork(saveAnalyticsApi);
    });
  },
  function* watchDestroyAnalyticsModule() {
    yield takeLeading(AnalyticsModuleActions.destroyAnalytics, function* () {
        yield fork(destroyAnalyticsModule);
    });
  },
];