import React from 'react';
import Dialog from '@biocad/bcd-front-ui/layout/Dialog';
import css from './uploader.m.less';

export const FileUploaderDialog = ({handleClose, header = 'Управление файлами', children}) => {
  return (
    <>
      <Dialog
        close={handleClose}
        paranjaClose={false}
      >
        <div className={css.popup}>
          <h3>{header}</h3>
          <hr />
          {children}
        </div>
      </Dialog>
    </>
  );
};