import {candidatesResourceActions} from '../actions/candidates';
import {resourceReducer, setResourceRequestState, setResourceFailState} from '../../shared/utils';

export const candidatesListReducer = resourceReducer(candidatesResourceActions, {
  [candidatesResourceActions.getFromAllFolders.request]: setResourceRequestState,
  [candidatesResourceActions.getFromAllFolders.success]: (state, action) => ({
    pending: action.pending,
    fail: action.fail,
    data: {
      ...state.data,
      fromAllFolders: action.result,
    },
    params: action.params,
    error: null,
  }),
  [candidatesResourceActions.getFromAllFolders.failure]: setResourceFailState,
});
