export const STORE_ROOT_PREFIXES = Object.freeze({
  resource: '@resource',
  modules:  '@modules',
  global:   '@global',
});

export const actionCreate = (storeRoot: string, root: string, actionTypes: string | [], actionCreator: ({}) => any): any => {
  const fn = (payload: {}) => Object.assign({}, actionCreator(payload), { type: fn.toString() });
  const actionType = `${storeRoot}/${root.toUpperCase()}/${Array.isArray(actionTypes) ? actionTypes.join('/').toUpperCase() : actionTypes.toUpperCase()}`;
  return Object.defineProperties(fn, {
    [Symbol.toPrimitive]: {
      value: (hint) => hint === 'string' ? actionType : NaN,
      writable: false,
      enumerable: false,
    },
    toString: {
      value: () => actionType,
      writable: false,
      enumerable: false,
    },
    type: {
      get: () => actionType,
    }
  });
};
