// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".molecule-m__template___iQRZk {\n  --template: 50px 2fr 1fr 0.5fr 1fr 1fr;\n  --table-border-color: none;\n  border-bottom: 1px solid var(--border-color);\n}\n.molecule-m__reaction-analytics-button-container___DI7WB {\n  margin-top: 8px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"template": "molecule-m__template___iQRZk",
	"reaction-analytics-button-container": "molecule-m__reaction-analytics-button-container___DI7WB"
};
export default ___CSS_LOADER_EXPORT___;
