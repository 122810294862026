import { moduleAction } from '../../shared/utils';

export const DASHBOARD_ROOT_NAME = 'DASHBOARD_ROOT';

export const dashboardModuleActions = {
  destroy: moduleAction(
      DASHBOARD_ROOT_NAME,
      ['DASHBOARD', 'DESTROY'],
      () => null
  ),
};