import React from 'react';
import classNames from 'classnames';
import css from './style.m.less';

export const LabeledText = ({ text, label, className, children, ...rest }) => {
  return (
    <div className={classNames(css.wrapper, 'labeled-text-component-wrapper', className)} { ...rest }>
      {label && <span className={classNames(css.label, 'labeled-text-component-label')}>{label}</span> }
      {text
          ? <span className={classNames(css.text, 'labeled-text-component-text')}>{text}</span>
          : text !== undefined && '–'
      }
      {children}
    </div>
  );
};