//@flow
import {getEnum} from '../../shared/utils';

export const ReactionTypeEnum = getEnum({
    Unknown: 0,
    AmideFormation: 1,
    EsterFormation: 2,
    Hydrolysis: 3,
    Dehydration: 4,
    Hydrogenation: 5,
    Reduction: 6,
    Oxidation: 7,
    Alkylation: 8,
    Halogenation: 9,
    Dehalogenation: 10,
    SuzukiCoupling: 11,
    Borylation: 12,
    MitsunobuReaction: 13,
    BuchwaldUllmannReaction: 14,
    AromaticElectrophilicSubstitution: 15,
    AromaticNucleophilicSubstitution: 16,
    AliphaticNucleophilicSubstitution: 17,
    GrignardReaction: 18,
    Acylation: 19,
    AminoGroupProtection: 20,
    AminoGroupDeprotection: 21,
    HydroxyGroupProtection: 22,
    HydroxyGroupDeprotection: 23,
    Rearrangement: 24,
    Cyclization: 25,
    StilleCoupling: 26,
    SonogashiraCoupling: 27,
    Other: 28,
    Purification: 29,
    HeckCoupling: 30
});
