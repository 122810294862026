// @flow

import {
  ResourceRequestMethod,
  CRUDRequestsMap,
  ResourceService, HTTPMethodEnum,
} from '../../shared/utils/service-creator';
import { Model } from 'objectmodel';
import { ObtainmentStage, StageRelatedEntity } from '../models';
import { SingleRequestURIParams } from '../../shared/models';
import type { ResourceRequestFunction } from '../../shared/utils/service-creator';

type StageResourceServiceType = {
  get: ResourceRequestFunction,
  list: ResourceRequestFunction,
  create: ResourceRequestFunction,
  delete: ResourceRequestFunction,
  update: ResourceRequestFunction,
  getRelatedEntities: ResourceRequestFunction,
  deleteBestReaction: ResourceRequestFunction,
  updateBestReaction: ResourceRequestFunction,
  updateStageWithIgnoreReactions: ResourceRequestFunction,
}

export const StageResourceService: StageResourceServiceType = ResourceService({
  baseURL: '/stages',
  defaultModel: ObtainmentStage,
  useDefaultCRUDRequests: [
    CRUDRequestsMap.get,
    CRUDRequestsMap.list,
    CRUDRequestsMap.create,
    CRUDRequestsMap.delete,
    CRUDRequestsMap.update,
  ],
  requests: {
    [CRUDRequestsMap.update]: {
      method: HTTPMethodEnum.PUT,
      model: ObtainmentStage,
      dataModel: ObtainmentStage,
    },
    getRelatedEntities: {
      url: '/{id}/related_entities',
      method: HTTPMethodEnum.GET,
      model: StageRelatedEntity,
      uriParamsModel: SingleRequestURIParams,
    },
    deleteBestReaction: {
      url: '/{id}/best_reaction',
      method: HTTPMethodEnum.DELETE,
      uriParamsModel: SingleRequestURIParams,
    },
    updateBestReaction: {
      url: '/{StageId}/best_reaction/{ReactionId}',
      method: HTTPMethodEnum.POST,
      uriParamsModel: Model({ StageId: Number, ReactionId: Number }),
    },
    updateStageWithIgnoreReactions: {
      url: '/ignore-reactions',
      method: HTTPMethodEnum.PUT,
      model: ObtainmentStage,
      dataModel: ObtainmentStage,
    }
  }
});
