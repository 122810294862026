import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { smTypesResourceActions, testStatusesResourceActions, testClassesResourceActions } from '../actions';
import type {IRootStore} from '../../app/reducers';
import {bimsDictionaryModuleActions, smTypesSelector, testClassesSelector, testStatusesSelector} from '../reducers';

export function loadBimsDictionaries(WrappedComponent) {
    
    const BimsLoaderComponent = ({raw, ntlTypes, ntlStatuses, ntlClasses, getSmTypes, getTestStatuses, getTestClasses,
                                     smTypesRequested, testClassesRequested, testStatusesRequested, ...props}) => {
          
          useEffect(() => {
            if (smTypesRequested) return;
            if ((!Array.isArray(ntlTypes) || ntlTypes.length === 0)) {
                getSmTypes();
            }
          }, []);
        
          useEffect(() => {
            if (testStatusesRequested) return;
            if (!Array.isArray(ntlStatuses) || ntlStatuses.length === 0) {
                getTestStatuses();
            }
          }, []);

          useEffect(() => {
            if (testClassesRequested) return;
            if (!Array.isArray(ntlClasses) || ntlClasses.length === 0) {
                getTestClasses();
            }
          }, []);

        return (
            <>
                <WrappedComponent
                    {...props}
                />
            </>
        );
    };

    const mapStateToProps = (state: IRootStore) => ({
        raw: state.resource.dictionary.smTypes,
        ntlTypes: smTypesSelector(state),
        ntlStatuses: testStatusesSelector(state),
        ntlClasses: testClassesSelector(state),
        smTypesRequested: state.modules.bimsDictionary.smTypesRequested,
        testClassesRequested: state.modules.bimsDictionary.testClassesRequested,
        testStatusesRequested: state.modules.bimsDictionary.testStatusesRequested,
      });
      
      const mapDispatchToProps = dispatch => ({
        getSmTypes: () => dispatch(smTypesResourceActions.list.request()),
        getTestStatuses: () => dispatch(testStatusesResourceActions.list.request()),
        getTestClasses: () => dispatch(testClassesResourceActions.list.request()),
        setSmTypesRequested: () => dispatch(bimsDictionaryModuleActions.setSmTypesRequested()),
        setTestClassesRequested: () => dispatch(bimsDictionaryModuleActions.setTestClassesRequested()),
        setTestStatusesRequested: () => dispatch(bimsDictionaryModuleActions.setTestStatusesRequested()),
      });

    return withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(BimsLoaderComponent)
    );
}
