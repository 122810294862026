// @flow

import {
  resourceActions,
  CRUDActionCreate,
  CRUDStatusActions,
} from '../../shared/utils';
import { STAGES_MODULE_NAME } from './module';

export const stageResourceActions = {
  ...resourceActions(STAGES_MODULE_NAME),
  clearAllNotCurrent: CRUDActionCreate(
    STAGES_MODULE_NAME,
    ['CLEAR_ALL_NOT_CURRENT'],
    ({ id }: { id: number }) => ({ id })
  ),
  updateBestReaction: CRUDStatusActions(
    STAGES_MODULE_NAME,
    'UPDATE_BEST_REACTION',
  ),
  deleteBestReaction: CRUDStatusActions(
    STAGES_MODULE_NAME,
    'DELETE_BEST_REACTION'
  )
};
