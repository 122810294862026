// @flow

import React from 'react';
import { connect } from 'react-redux';
import { _noop } from '../../../../../shared/utils/common';
import bnc from 'bnc/index';
import type { IStageSubstance } from '../../../../models';
import { stageModuleActions } from '../../../../actions';
import { StageSubstance } from '../../../../models';
import { getProductPosition, getRemovableReactantPosition } from '../../../../reducers';
import { MolecularFormulaFormatter } from '../../../../../shared/components/MolecularFormulaFormatter';
import classNames from 'classnames';

import './index.less';

interface IStageSubstancePickerItemProps {
  substance?: IStageSubstance,
  deleteSubstance?: (position: number)=>void,
  selectSubstance?: (position: number)=>void,
  className?: string,
  productPosition?: number,
  removableReactantPosition?: number,
}

const defaultProps: IStageSubstancePickerItemProps = {
  substance: StageSubstance(),
  deleteSubstance: _noop,
  selectSubstance: _noop,
  className: '',
};

export const
  BNCBlock = new bnc('b-StageSubstancePickerItem'),
  BNCIsDisable = BNCBlock.mod('isDisabled'),
  BNCIsHighlighted = BNCBlock.mod('isHighlighted'),
  BNCDeleteBtn = BNCBlock.el('deleteBtn'),
  BNCThumbnailHolder = BNCBlock.el('thumbnailHolder'),
  BNCThumbnailHolderIsEmpty = BNCThumbnailHolder.mod('isEmpty'),
  BNCThumbnail = BNCBlock.el('thumbnail'),
  BNCTitle = BNCBlock.el('title'),
  BNCTitleInvalid = BNCTitle.mod('invalid'),
  BNCFormula = BNCBlock.el('formula')
;

let StageSubstancePickerItem = (props: IStageSubstancePickerItemProps = defaultProps) => (
  <div
    onClick={() => (!props.substance?.isDisabled && !props.substance?.isSelected) && props.selectSubstance(props.substance?.position)}
    className={`
      ${BNCBlock} 
      ${props.className} 
      ${props.substance?.isSelected ? BNCIsHighlighted : ''} 
      ${props.substance?.isDisabled ? BNCIsDisable : ''}
    `}
  >
    {
      props.substance?.position === props.removableReactantPosition &&
      <i className={BNCDeleteBtn + 'fo-close'}
         onClick={(event) => {
           event.stopPropagation();
           props.deleteSubstance(props.substance?.position);
         }}
      />
    }
    <span className={classNames(BNCTitle.toString(), { [BNCTitleInvalid]: !props.substance?.canonicalized?.IsValid })}>
      <span>{props.substance?.title || '\u00A0'}</span>
      {
        props.substance.MolecularFormula &&
        <MolecularFormulaFormatter formulaStr={props.substance.MolecularFormula} className={BNCFormula} />
      }
    </span>
    <div className={BNCThumbnailHolder + (!props.substance?.Thumbnail ? BNCThumbnailHolderIsEmpty : '')}>
      {
        props.substance?.Thumbnail &&
        <img
          className={BNCThumbnail}
          alt={''}
          src={props.substance?.Thumbnail}
        />
      }
    </div>
  </div>
);

StageSubstancePickerItem = connect(
  (state): IStageSubstancePickerItemProps => ({
    productPosition: getProductPosition(state),
    removableReactantPosition: getRemovableReactantPosition(state),
  }),
  (dispatch): IStageSubstancePickerItemProps => ({
    selectSubstance: (position) => dispatch(stageModuleActions.selectSubstance({ position })),
    deleteSubstance: (position) => dispatch(stageModuleActions.deleteSubstance({ position })),
  })
)(StageSubstancePickerItem);

export {
  StageSubstancePickerItem,
};
