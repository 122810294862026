// @flow
import {resourceActions, moduleAction, CRUDStatusActions} from '../../shared/utils';

export const FOLDERS_MODULE_NAME = 'FOLDERS';
export const FOLDERS_SCHEMES_MODULE_NAME = 'FOLDERS_SCHEMES';

export const folderResourceActions = {
    ...resourceActions(FOLDERS_MODULE_NAME)
};

export const folderSchemesResourceActions = {
    ...resourceActions(FOLDERS_SCHEMES_MODULE_NAME),
    selectFolder:CRUDStatusActions(FOLDERS_SCHEMES_MODULE_NAME, ['SELECT_FOLDER']),
};

const FOLDER_SCHEME_PATH_ITEM = 'FOLDER_SCHEME_PATH_ITEM';
const CANDIDATE_PATH_ITEM = 'CANDIDATE_PATH_ITEM';
const PATH_ITEM = 'PATH_ITEM';
const FOLDER_ID = 'FOLDER_ID';
const EDIT_MODE = 'EDIT_MODE';

const ADD = 'ADD';
const SET = 'SET';
const REMOVE = 'REMOVE';
const DELETE = 'DELETE';
const PUT = 'PUT';

export const folderModuleActions = {
    addFolderPathItem: moduleAction(
        FOLDERS_MODULE_NAME,
        [PATH_ITEM, ADD],
        ({id, name}) => ({id, name})
    ),
    removeFolderPathItem: moduleAction(
        FOLDERS_MODULE_NAME,
        [PATH_ITEM, REMOVE],
        () => null
    ),
    deleteFolderPath: moduleAction(
        FOLDERS_MODULE_NAME,
        [PATH_ITEM, DELETE],
        () => null
    ),
    setCurrentFolderId: moduleAction(
        FOLDERS_MODULE_NAME,
        [FOLDER_ID, SET],
        (id) => (id)
    ),
    setEditMode: moduleAction(
        FOLDERS_MODULE_NAME,
        [EDIT_MODE, SET],
        (isEditMode) => (isEditMode)
    ),
    addFolderCandidatePathItem: moduleAction(
        FOLDERS_MODULE_NAME,
        [CANDIDATE_PATH_ITEM, ADD],
        ({id, name}) => ({id, name})
    ),
    setFolderCandidatePath: moduleAction(
        FOLDERS_MODULE_NAME,
        [CANDIDATE_PATH_ITEM, SET],
        (path) => (path)
    ),
    removeFolderCandidatePathItem: moduleAction(
        FOLDERS_MODULE_NAME,
        [CANDIDATE_PATH_ITEM, REMOVE],
        () => null
    ),
    deleteFolderCandidatePath: moduleAction(
        FOLDERS_MODULE_NAME,
        [CANDIDATE_PATH_ITEM, DELETE],
        () => null
    ),
    addFolderSchemesPathItem:  moduleAction(
        FOLDERS_MODULE_NAME,
        [FOLDER_SCHEME_PATH_ITEM, ADD],
        ({id, name}) => ({id, name})
    ),
    removeFolderSchemesPathItem: moduleAction(
        FOLDERS_MODULE_NAME,
        [FOLDER_SCHEME_PATH_ITEM, REMOVE],
        () => null
    ),
    deleteFolderSchemesPath: moduleAction(
        FOLDERS_MODULE_NAME,
        [FOLDER_SCHEME_PATH_ITEM, DELETE],
        () => null
    )
};
