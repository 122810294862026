// @flow
import React from 'react';

import type { IJarStockSpendings } from '../../../models';
import { JarStockSpendingsEnum } from './jar-spendings-enum';
import { Table, Header, Body, Row, Cell } from '@biocad/bcd-front-ui/layout/Table';
import dateTimeFormat from '@biocad/bcd-front-ui/utils/formatters/datetime';

export const SpendingsTable = ({
    data = [],
    ...props
}: {
    data: IJarStockSpendings[],
}) => {
    return (
        <Table {...props}>
            <Header>
                <Row>
                    {Object.values(JarStockSpendingsEnum).map(col => (
                        <Cell key={col}>
                            <span>{col}</span>
                        </Cell>
                    ))}
                </Row>
            </Header>
            <Body>
                {
                 renderSpendingsTableBody(data)
                }
            </Body>
        </Table>
    );
};

const renderSpendingsTableBody = (data) =>
    data && data.length
        ? data.map((item, index) => {
              return <Row key={index}>{item && renderSpendingsTableRow(item)}</Row>;
          })
        : null;

const renderSpendingsTableRow = (item: IJarStockSpendings) =>
  Object.keys(JarStockSpendingsEnum).map(key => (
      key === 'CreationDate'
        ? <Cell key={key}>{dateTimeFormat(new Date(item[key]), { format:'compact', time:true })}</Cell>
        : <Cell key={key}>{item[key]}</Cell>
  ));
