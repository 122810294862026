// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pages-m__pages___kiCKq {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  flex-flow: row nowrap;\n  padding: var(--grid-spacer) 0;\n  border-bottom: 1px solid var(--border-color);\n  position: sticky;\n  top: 0;\n  z-index: 1000;\n  background: #fff;\n}\n.pages-m__pages__item___ggzVa {\n  width: 24px;\n  height: 24px;\n  border-radius: 50%;\n  font-size: 14px;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-left: var(--minor-margin);\n}\n.pages-m__pages__item_active___KD2qb {\n  color: var(--green);\n  background-color: rgba(34, 170, 72, 0.1);\n}\n.pages-m__pages__item_invalid___VlIU5 {\n  color: var(--red);\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pages": "pages-m__pages___kiCKq",
	"pages__item": "pages-m__pages__item___ggzVa",
	"pages__item_active": "pages-m__pages__item_active___KD2qb",
	"pages__item_invalid": "pages-m__pages__item_invalid___VlIU5"
};
export default ___CSS_LOADER_EXPORT___;
