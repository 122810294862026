import React from 'react';
import styled from 'styled-components';

export const StyledLayoutHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  margin: 0 !important;
  padding: var(--LayoutHeader-margin-v) var(--LayoutHeader-margin-h);
  z-index: 2;
  background: var(--grey-100);
  .buttons-holder {
    *:not(:last-of-type) {
      margin-right: var(--grid-spacer);
    }
  }
`;
