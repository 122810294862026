// @flow

import React from 'react';
import { reduxForm, Field } from 'redux-form';
import Button from '@biocad/bcd-front-ui/controls/Button';
import { BcdDropdownWrapper } from '../../../shared/components';
import {IFolder } from '../../models';

interface ISelectFolderFormProps {
  selectFolder: typeof Function,
  toggleDialog: typeof Function,
  folders: IFolder[]
}

export const FOLDER_SELECT_FORM = 'folderSelectDialog';

let SelectFolderForm = (props: ISelectFolderFormProps) => (
  <form className={props.className} onSubmit={props.selectFolder}>
    <header className={'dialog-header'}>
      <h3>Веберите папку</h3>
    </header>
    <div className={'dialog-contents'}>
      <Field
        name={'Id'}
        component={BcdDropdownWrapper}
        label={'Название'}
        useFormFieldLayout={true}
        options={ props.folders }
        format={(value) => props.folders?.find((opt) => opt.value === value)}
      />
    </div>
    <footer className={'dialog-footer'}>
      <Button
        text='Выбрать'
        view='primary'
        size={'L'}
        type={'submit'}
      />
      <Button
        text='Отмена'
        size={'L'}
        onAction={props.toggleDialog}
      />
    </footer>
  </form>
);

SelectFolderForm = reduxForm({
  form: FOLDER_SELECT_FORM,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(SelectFolderForm);

export {
  SelectFolderForm
};
