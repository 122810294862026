import React from 'react';
import styled from 'styled-components';

const
  asideWidth = '25%',
  asideMinWidth = '340px';

export const EditFormLayout = styled.form`
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  .agent-marvin {
    flex: 1 0 auto;
    max-width: calc(100% - ${asideWidth});
  }
  .agent-image-upload {
    flex: 1 0 auto;
    max-width: calc(100% - ${asideWidth});

    input[type=file] {
      display: none;
    }

    div.button {
      padding: var(--grid-spacer);
    }

    div.image {
      padding: var(--grid-spacer);
      max-width: 640px;

      img {
        max-width: 100%;
      }
    }
  }
  .agent-aside {
    flex: 1 0 ${asideMinWidth};
    max-width: ${asideWidth};
    min-width: ${asideMinWidth};
    padding: var(--grid-spacer) calc(var(--grid-spacer) * 1.5);
    border-left: 1px solid var(--border-color);

    .compound-type {
      margin-bottom: calc(var(--grid-spacer) * 0.5);
    }
  }
  button.invalid {
    box-shadow: inset 0px 0px 0px 1px var(--FormFieldLayout-color-invalid);
  }
`;
