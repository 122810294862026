// @flow
import React, {
  useState,
  useEffect
} from 'react';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import type { IRootStore } from '../../../../app/reducers';
import { GridTable } from '../../../../shared/components/GridTable';
import { GridTableCol } from '../../../../shared/components/GridTable/GridTableCol';
import { InlineEdit, inputTypesEnum } from '../../../../shared/components/InlineEdit';
import { FieldValidators } from '../../../../shared/validators/field-validators';
import type { IJarStock } from '../../../../agents/models';
import { _noop } from '../../../../shared/utils/common';
import { getAmountRemainsIndicator, amountColorIndicators, AmountRemainsIndicatorEnum } from '../../../../agents/utils';
import styled from 'styled-components';

export const REAGENT_SPENDINGS_FORM_NAME = 'reagentSpendingsForm';
export const REAGENT_SPENDINGS_ARRAY_NAME = 'jars';
export const JAR_SPENT_AMOUNT_FIELD_NAME = 'Consumption';

const
  minNumAmount = FieldValidators.minNum(0.001)
  ;

export const setJarStockRowClassByAmount = (item: IJarStock) => {
  const indicator = getAmountRemainsIndicator(item);
  if (indicator) {
    return {
      [indicator.className]: true
    }
  }
  else {
    return {};
  }
};

type TProps = {
  agentId: number,
  reagent: any,
  labs: {},
  jars: IJarStock[],
  formValues: {},
  className?: string,
}

let SpendingsForm = (props: TProps) => {

  // state
  const [maxValueValidators, setMaxValueValidators] = useState(null);

  useEffect(() => {
    if (props.jars?.length && !maxValueValidators) {
      setMaxValueValidators(props.jars.reduce((acc: {}, cur: IJarStock) => ({ ...acc, [cur?.Id]: FieldValidators.maxNum(cur?.Amount) }), {}));
    }
  }, [props.jars]);

  const getNumber = (item) => {
    console.log('spent', item, props.jars, props.reagent);
    return item.Number;
  }
  console.log('spent1', props.jars);
  return (
    <GridTable
      className={props.className}
      hover={false}
      rowHeight={'54px'}
      excludeRows={item => (item.AgentId !== props.agentId || (item.reagentId && item.reagentId !== props.reagent.Id) || item.Amount <= 0)}
      fieldArrayName={REAGENT_SPENDINGS_ARRAY_NAME}
      colsTemplate={'100px max-content 100px minmax(100px, 150px) repeat(2, minmax(1fr, max-content))'}
      setRowClassNames={setJarStockRowClassByAmount}
    >
      <GridTableCol
        title={'№ Банки'}
        render={({ item }) => <span>{getNumber(item)}</span>}
      />
      <GridTableCol
        title={'Производитель'}
        render={({ item }) => <span>{item.Provider}</span>}
      />
      <GridTableCol
        title={'Кол-во, г'}
        align='right'
        render={({ item }) => <span>{item.Amount}</span>}
      />
      {
        maxValueValidators &&
        <GridTableCol
          title={'Расход, г'}
          align='right'
          fieldName={JAR_SPENT_AMOUNT_FIELD_NAME}
          validate={[minNumAmount]}
          warn={(jar: IJarStock) => [maxValueValidators[jar.Id] || _noop]}
          render={({ meta, input }) => (
            <InlineEdit inputType={inputTypesEnum.InputNum} markPristineValidity {...{ input, meta }} />
          )}
        />
      }
      <GridTableCol
        title={'Расположение'}
        render={({ item }) => <span>{props?.labs?.[item.LaboratoryId]?.Name}</span>}
      />
      <GridTableCol
        title={'Место'}
        render={({ item }) => <span>{item.Location}</span>}
      />
    </GridTable>
  );
};

SpendingsForm = reduxForm({
  form: REAGENT_SPENDINGS_FORM_NAME,
  destroyOnUnmount: true,
  enableReinitialize: true,
  touchOnChange: true,
})(SpendingsForm);

SpendingsForm = styled(SpendingsForm)`
  .${amountColorIndicators[AmountRemainsIndicatorEnum.Empty].className} > *
  {
    background-color: var(--red-o-15);
  }
  .${amountColorIndicators[AmountRemainsIndicatorEnum.Low].className} > *
  {
    background-color: var(--orange-o-5);
  }
  .${amountColorIndicators[AmountRemainsIndicatorEnum.Medium].className} > *
  {
    background-color: var(--yellow-o-5);
  }
  .${amountColorIndicators[AmountRemainsIndicatorEnum.Full].className} > *
  {
    background-color: var(--green-o-20);
  }
  .jar-amount-remains--ignore-spending > *
  {
    background-color: var(--green-o-40);
  }
`;

const mapStateToProps = (state: IRootStore) => ({
  formValues: getFormValues(REAGENT_SPENDINGS_FORM_NAME)(state),
  labs: state.resource.dictionary.labs.data,
  jars: state.modules.reaction.jarSpendings.jars,
  initialValues: { [REAGENT_SPENDINGS_ARRAY_NAME]: state.modules.reaction.jarSpendings.jars },
});

const mapDispatchToProps = dispatch => ({
});
export default
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SpendingsForm);
