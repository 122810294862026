// @flow
import React, {
  useEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';
import {AGENT_SEARCH_FORM_NAME, SearchForm, SearchOptionsEnum, agentSearchInitialValues} from '../../../../../agents/components/List/SearchForm';
import Dialog from '@biocad/bcd-front-ui/layout/Dialog';
import Button from '@biocad/bcd-front-ui/controls/Button';
import Loader from '@biocad/bcd-front-ui/controls/Loader';
import type { IAgent, ICompound } from '../../../../../agents/models/i-agent';
import { _noop } from '../../../../../shared/utils/common';
import { itemsToArraySelector } from '../../../../../shared/utils/selectors/resource-selectors';
import type { IPagination } from '../../../../../shared/models';
import { DialogLayout, AgentsList } from './style';
import { agentResourceActions } from '../../../../../agents/actions';
import {getFormValues, initialize} from 'redux-form';
import { AgentKeysEnum } from '../../../../../agents/models/i-agent';
import { PaginationStyled } from '../../../../../shared/components/Paginator/style';
import { stageModuleActions } from '../../../../actions';
import { STAGE_MERGE_OPTION_ENUM } from '../../../../constants';
import type { IStageSubstance } from '../../../../models';
import { getSelectedSubstanceSelector } from '../../../../reducers';
import { GridTable } from '../../../../../shared/components/GridTable';
import { GridTableCol } from '../../../../../shared/components/GridTable/GridTableCol';

type agentListCols = {
  title: string,
  objPropName: string,
  render: typeof Function,
  cellClassName: string,
}

interface IAgentListDialogProps {
  agents?: IAgent[],
  pagination?: IPagination,
  pending?: boolean,
  searchFormValues?: any,
  selectedSubstance?: IStageSubstance,
  isInStockOnly?: boolean,
  loadAgents?: (params: any)=>void,
  destroyAgents?: ()=>void,
  setMergeOption?: (string)=>void;
  setSubstance?: (ICompound, number)=>void,
  setDefaultValues?: (IAgentListDialogProps) => void,
}

const defaultProps: IAgentListDialogProps = {
  agents: [],
  pagination: {},
  searchFormValues: {},
  selectedSubstance: {},
  loadAgents: _noop,
  destroyAgents: _noop,
  setMergeOption: _noop,
  setSubstance: _noop,
  setDefaultValues: _noop,
  isInStockOnly: false
};

const defaultSearchValues = {
  ...agentSearchInitialValues,
  searchType: SearchOptionsEnum.ByStructure
};

let AgentListDialog = (props: IAgentListDialogProps = defaultProps) => {
  // State
  const
      [selectedAgent: IAgent, setSelectedAgent] = useState(null),
      [defaultValuesInited, setDefaultValuesInited] = useState(false),
      [lastSearchValues, setLastSearchValues] = useState(null)
  ;
  if (props.isInStockOnly == true)
    agentSearchInitialValues.isInStockOnly = props.isInStockOnly;

  // Effects
  const _loadAgents = () => {
    if (!props.searchFormValues || !props.pagination || props.pending || !props.pagination)
      return;
    let changed = searchValuesChanged();
    if(!changed)
      return;

    props.loadAgents({ ...props.searchFormValues, skip: 0, limit: 20 });
    return () => {
      props.destroyAgents();
    };
  };
  useEffect(_loadAgents, [props.searchFormValues]);

  const _setDefaultSearchValues = () => {
    if (!defaultValuesInited && !props.pending) {
      props.setDefaultValues(defaultSearchValues);
      setDefaultValuesInited(true);
    }
  };
  useEffect(_setDefaultSearchValues, [props.pending, props.searchFormValues]);

  function searchValuesChanged() {
    if(!props.searchFormValues) {
      return false;
    }
    if(!lastSearchValues && props.searchFormValues) {
      setLastSearchValues(props.searchFormValues);
      return true;
    }
    if(!compareObject(lastSearchValues, props.searchFormValues))
    {
      setLastSearchValues(props.searchFormValues);
      return true;
    }
    return false;
  }

  function compareObject(obj1, obj2){
    const isObject = (obj) => {
      return (Object.prototype.toString.call(obj) === '[object Object]');
    }
    if(!isObject(obj1) || !isObject(obj2)){
      return false;
    }
    let len = null;
    if(Object.keys(obj1).length != Object.keys(obj2).length){
      return false;
    }else{
      len = JSON.stringify(obj1).length;
    }
    let match = 0;
    let stringified = JSON.stringify(obj1);
  
    let ii = 0;
    while( ii < stringified.length){
      if(JSON.stringify(obj2).includes(stringified[ii])){
        match++;
      }
      ii++;
    }
    if(match === len){
      return true;
    }
    return false;
  }

  // Handlers
  const
    onAgentClick = (agent: IAgent) => {
      if (agent && agent.Id) {
        setSelectedAgent(agent);
      }
    },
    onPaginationChange = (paginationParams) => {
      if (!props.pending) {
        props.destroyAgents();
        setSelectedAgent(null);
        props.loadAgents({ ...props.searchFormValues, ...paginationParams });
      }
    },
    onSearchFormSubmit = (event) => {
      event.preventDefault();
      props.destroyAgents();
      setSelectedAgent(null);      
      props.loadAgents({ ...props.searchFormValues, skip: 0, limit: 20 });
    },
    onAgentSelected = () => {
      if (!selectedAgent) {
        return;
      }
      props.setSubstance(selectedAgent, props.selectedSubstance?.position);
      props.setMergeOption(STAGE_MERGE_OPTION_ENUM.DEFAULT);
    },
    onCancel = () => {
      props.setMergeOption(STAGE_MERGE_OPTION_ENUM.DEFAULT);
    };

  return (
    <Dialog paranjaClose={false} close={onCancel}>
      <DialogLayout className={'dialog-layout'}>
        <header  className={'dialog-header'}>
          <h3>Поиск вещества</h3>
        </header>
        <div className={'dialog-contents'}>
          <SearchForm handleSubmit={onSearchFormSubmit}
                      className={'searchForm'}
                      isInStockOnly={props.isInStockOnly}
                      initiallySubmit
          />
          {
            (props.agents && !props.pending) ?
            <GridTable items={props.agents}
                       rowHeight={'240px'}
                       onRowClicked={onAgentClick}
                       hover={true}
                       setRowClassNames={(item: IAgent, _) => ({
                         'highlight-error': !item.HasJarsWithAmount,
                         'is-selected': item.Id === selectedAgent?.Id,
                       })}
            >
              <GridTableCol
                title={'Структурная формула'}
                render={({ item }: { item: IAgent }) => <img src={item?.Compound?.Thumbnail} alt={''} className={'thumbnail'} />}
              />
              <GridTableCol
                title={'Наименование'}
                render={({ item }: { item: IAgent }) => <span>{ item?.Name }</span>}
              />
              <GridTableCol
                title={'Сокращение'}
                render={({ item }: { item: IAgent }) => <span>{ item?.ShortName }</span>}
              />
              <GridTableCol
                title={'CAS-номер'}
                render={({ item }: { item: IAgent }) => <span>{ item?.Cas }</span>}
              />
              <GridTableCol
                title={'MW, г/моль'}
                render={({ item }: { item: IAgent }) => <span>{ item?.MolarWeight }</span>}
              />
            </GridTable>
            :
            <Loader />
          }
        </div>
        <footer className={'dialog-footer'}>
          <PaginationStyled
            {...props.pagination}
            handlePagingChange={onPaginationChange}
          />
          <Button
            text={'Выбрать'}
            size={'L'}
            disabled={!selectedAgent}
            onAction={onAgentSelected}
            view={'primary'}
          />
          <Button
            text={'Отменить'}
            size={'L'}
            onAction={onCancel}
          />
        </footer>
      </DialogLayout>
    </Dialog>
  );
};

AgentListDialog = connect(
  (state): IAgentListDialogProps => ({
    agents: itemsToArraySelector(state.resource.agents),
    pagination: state.modules.agent?.list?.pagination,
    pending: state.resource.agents?.pending,
    searchFormValues: getFormValues(AGENT_SEARCH_FORM_NAME)(state),
    selectedSubstance: getSelectedSubstanceSelector(state),
  }),
  (dispatch): IAgentListDialogProps => ({
    loadAgents: (params) => dispatch(agentResourceActions.list.request({ params })),
    destroyAgents: () => dispatch(agentResourceActions.destroy()),
    setMergeOption: (option: string) => dispatch(stageModuleActions.setMergeOption({ option })),
    setSubstance: (payload: IAgent, substancePosition: number) => dispatch(stageModuleActions.setSubstance({ payload, substancePosition })),
    setDefaultValues: (values: IAgentListDialogProps) => dispatch(initialize(AGENT_SEARCH_FORM_NAME, values)),
  }),
)(AgentListDialog);

export {
  AgentListDialog,
};
