// @flow

import { Model } from 'objectmodel';

export const ReactionType =
	Model({
		Id: [Number, null, undefined],
		Complexity: [Number, null, undefined],
		IsDeleted: [Boolean, null, undefined],
		IsObsolete: [Boolean, null, undefined],
		Name: [String, null, undefined],
	})
	.defaultTo({
		IsDeleted: false,
		IsObsolete: false,
	});
