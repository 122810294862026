import {projectActions, projectModuleActions} from '../actions/projects';
import {resourceReducer} from '../../shared/utils';
import { createSelector } from 'reselect';
import {itemsToArraySelector} from '../../shared/utils/selectors/resource-selectors';
import type {IPagination} from '../../shared/models';
import type {IRootStore} from '../../app/reducers';

export const projectListReducer = resourceReducer(projectActions);

const initialPagination: IPagination = {
    page: 1,
    pageCount: 1,
    pageSize: null,
    total: null,
};

const initialState = {
    list: {
        pagination: initialPagination
    },
    id: null,
    type: '',
    isEdit: false,
    projectsTotal: 0,
    currentMaxOrder: null,
    isChanged: false,
    currentProject: null
};

export const projectModuleReducer = (state = initialState, action) => {
    const reducedFn = {
        [projectActions.list.success]: () => ({
            ...state,
            list: {
                ...state.list,
                pagination: {...state.list.pagination, ...action.pagination},
            },
            projectsTotal: action.pagination.total,
            isChanged: false,
        }),
        [projectActions.get.success]: () => ({
           ...state,
           currentProject: action.result
        }),
        [projectModuleActions.setCurrentProjectId]: () => ({
            ...state,
            id: action.id
        }),
        [projectModuleActions.setEditMode]: () => ({
            ...state,
            isEdit: action.isEdit
        }),
        [projectModuleActions.setCurrentProjectType]: () => ({
            ...state,
            type: action.ptype
        }),
        [projectModuleActions.destroyModule]: () => ({
            ...initialState,
        }),
        [projectModuleActions.destroyPagination]: () => ({
            ...state,
            list: {
                ...state.list,
                pagination: { ...initialState.list.pagination }
            }
        }),
        [projectActions.create.success]: () => ({
            ...state,
            projectsTotal: state.projectsTotal + 1,
            isChanged: true,
        }),
        [projectActions.update.success]: () => ({
            ...state,
            isChanged: true,
        }),
        [projectActions.delete.success]: () => ({
            ...state,
            projectsTotal: state.projectsTotal - 1,
            isChanged: true,
        }),
        [projectActions.getMaxOrder.success]: () => ({
            ...state,
            currentMaxOrder: (action?.result?.OrderPriority | 0) + 10,
        }),
        [projectActions.getMaxOrder.failure]: () => ({
            ...state,
            currentMaxOrder: 0,
        }),
        [projectActions.clearMaxOrder]: () => ({
            ...state,
            currentMaxOrder: null,
        }),
    }[action.type];
    return reducedFn ? reducedFn() : state;
};

export const getProjectsSelector = createSelector(
    [(state: IRootStore) => state?.resource?.projects?.data],
    (data) => data ? Object.values(data) : [],
);

export const sortedProjectsSelector = createSelector(
    [itemsToArraySelector],
    (data) => data.sort((cur, next) => cur.OrderPriority - next.OrderPriority)
);
