// @flow

import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import type {IRootStore} from '../../../app/reducers';
import {JarAmountsByLabsModel} from '../../../agents/models/jar-amounts-by-labs';
import type {IJarAmountsByLabsModel, IJarAmountsByLabsRequest} from '../../../agents/models/jar-amounts-by-labs';
import { GridTable, GridTableCol } from '../../../shared/components/GridTable';
import styled from 'styled-components';
import classNames from 'classnames';
import {getLabsSelector} from '../../../dictionary/reducers';
import {schemeModuleActions} from '../../actions';
import Button from '@biocad/bcd-front-ui/controls/Button';
import Loader from '@biocad/bcd-front-ui/controls/Loader';

interface IProps
{
    uuid: string;
    className?: string;
    left?: number;
    top?: number;
}

export const JarAmountsByLaboratoriesTable = styled(
    (props: IProps) =>
    {
        // State
        const agentId = useSelector((state: IRootStore) => (
            state.modules.scheme.schemeTree.jarAmounts[props.uuid]?.agentId
        ));
        const jarAmountsByLabs: IJarAmountsByLabsModel = useSelector((state: IRootStore) => (
            state.resource.jarAmountsByLabs.data[agentId]
                ? state.resource.jarAmountsByLabs.data[agentId]
                : null
        ));
        const params: IJarAmountsByLabsRequest = useSelector((state: IRootStore) => (
            state.modules.scheme.schemeTree.jarAmounts[props.uuid]?.params
        ));
        const labsList = useSelector(getLabsSelector);
        const labsListRaw = useSelector((state: IRootStore) => (
            state.resource.dictionary.labs.data
        ));
        const pending = useSelector((state: IRootStore) => (
            state.modules.scheme.schemeTree.jarAmounts[props.uuid]?.pending
        ));
        const show = useSelector((state: IRootStore) => (
            state.modules.scheme.schemeTree.jarAmounts[props.uuid]?.show
        ));
        const [items, setItems] = useState(null);
        const userLabId = jarAmountsByLabs?.UserLaboratoryId;
        const jarsByLabs = jarAmountsByLabs?.AvailabilitiesByLaboratories;

        // Methods
        const dispatch = useDispatch();
        const hide = () =>
        {
            dispatch(schemeModuleActions.setShowResultForLoadedJarAmount({ show: false, uuid: props.uuid }));
        }

        // Effects
        useEffect(() => {
            if (!jarsByLabs?.size || !labsList.length) return;
            if (userLabId)
            {
                const userLabAmount = jarsByLabs.get(userLabId) || 0;
                const otherLabAmount = Array.from(jarsByLabs.entries())
                    .filter(([labId]) => labId !== userLabId)
                    .reduce((acc, [_, amount]) => acc + amount, 0);
                setItems([
                    { title: 'Лаборатория пользователя', amount: `${userLabAmount.Amount?.toFixed(3) ?? 0} г`, location: `${userLabAmount.Location}`, quantity: userLabAmount.Amount ?? 0 },
                    { title: 'Остальные лаборатории', amount: `${otherLabAmount.Amount?.toFixed(3) ?? 0} г`, location: `${otherLabAmount.Location}`, quantity: otherLabAmount.Amount ?? 0 }
                ]);
            }
            else {
                setItems(
                    Array.from(jarsByLabs.entries())
                        .map(([labId, amount]) => ({
                            title: labsListRaw[labId]?.Name || '-',
                            amount: amount.Amount?.toFixed(3) ?? 0,
                            location: `${amount.Location}`,
                            quantity: amount.Amount ?? 0
                        }))
                )
            }
        }, [jarsByLabs?.size, userLabId, labsList?.length]);

        if (!show) return '';
        return (
            <div className={props.className} style={{ top: `${props.top}px`, left: `${props.left}px` }}>
                <div className={'holder'}>
                    {
                        pending && <Loader />
                    }
                    <Button className={'fo-close close-btn'}
                            onAction={hide}
                            view={'icon'}
                    />
                    <div className={'header'}>
                        <div className={'header-line'}>
                            <span>SMILES-формула:</span>
                            <pre title={ params?.SmilesFormula?.length > 50 ? params?.SmilesFormula : null }>
                                { params?.SmilesFormula || '-' }
                            </pre>
                        </div>
                        <div className={'header-line'}>
                            <span>Наименование: </span>
                            <pre title={ params?.CompoundName?.length > 50 ? params?.CompoundName : null }>
                                { params?.CompoundName || '-' }
                            </pre>
                        </div>
                    </div>
                    {
                        items?.length ?
                        <GridTable items={items.filter(i => i.quantity > 0)}
                                   className={classNames({
                                       ['table']: true,
                                       ['table-hide-heading']: !!userLabId,
                                   })}
                        >
                            <GridTableCol
                                title={'Лаборатория'}
                                render={({item}) => (
                                    <>
                                        <span className={'title-text'}>{item.title}</span>
                                        <span className={'delimeter'}>:</span>
                                    </>
                                )}
                            />
                            <GridTableCol
                                title={'Место хранения'}
                                render={({item}) => (
                                    <>
                                        <span className={'title-text'}>{item.location}</span>
                                        <span className={'delimeter'}>:</span>
                                    </>
                                )}
                            />
                            <GridTableCol
                                title={'В наличии, г'}
                                render={({item}) => (
                                    <span>{item.amount}</span>
                                )}
                            />
                        </GridTable>
                        :
                        <div className={'message'}>Данные не найдены</div>
                    }
                </div>
            </div>
        );
    })
    `
        & {
          width: 0; height: 0;
          position: absolute;
          transition: all 50ms linear;
        }
        .holder {
          position: absolute;
          bottom: 6px;
          left: -175px;
          width: 500px;
          min-height: 20px;
          background-color: var(--grey-100);
          border: 1px solid var(--border-color);
          border-radius: var(--border-radius);
          box-shadow: 0 0 4px var(--box-shadow-color);
          transition: all 300ms linear;
          cursor: default;
          &::after {
            content: '';
            position: absolute;
            bottom: -8px;
            left: 170px;
            width: 0; height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 8px solid var(--box-shadow-color);
            opacity: 0.8;
          }
          &:hover {
            z-index: 1;
            background-color: var(--white);
            box-shadow: 4px 4px 2px var(--box-shadow-color);
          }
        }
        .table {
          margin-bottom: 0;
          &-hide-heading {
            .grid-table-row-heading { display: none }
          }
        }
        .delimeter {
          flex: 1 0 0;
        }
        .title-text {
          white-space: nowrap;
          padding-right: calc(var(--table-cell-padding-v) * 2);
        }
        .close-btn {
          float: right;
        }
        .b-loader {
          position: absolute;
          top: 1px; left: 1px; right: 1px; bottom: 1px;
          background-color: var(--white);
          z-index: 1;
          &__icon { font-size: 16px !important; }
        }
        .message {
          padding: 4px 12px;
          font-size: 12px;
          color: var(--table-title-color);
        }
        .header {
          padding: 24px 6px 2px 12px;
        }
        .header-line {
          font-size: 12px;
          color: var(--table-title-color);
          span, pre {
            display: block;
          }
          span {
            text-decoration: underline;
          }
          pre {
            text-overflow: ellipsis;
            overflow: hidden;
            margin-bottom: 6px;
          }
        }
    `;