// @flow

export const ReactionYieldKeyNames = {
	Id: '	Id',
	Comment: 'Comment',
	ExperimentalPercentYield: 'ExperimentalPercentYield',
	ExperimentalYield: 'ExperimentalYield',
	IsDeleted: 'IsDeleted',
	ReactionId: 'ReactionId',
	TheoreticalYield: 'TheoreticalYield',
	IndexNumber: 'IndexNumber',
	ShouldCreateJar: 'ShouldCreateJar',
};
