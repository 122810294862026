// @flow
import {moduleAction, resourceActions, CRUDStatusActions} from '../../shared/utils';
import type {ICompound} from '../../agents/models/i-agent';

export const CANDIDATE_MODULE_NAME = 'CANDIDATES';

export const candidatesResourceActions = {
    ...resourceActions(CANDIDATE_MODULE_NAME),
    checkRepkaTaskExists: CRUDStatusActions(CANDIDATE_MODULE_NAME, 'REPKA_TASK_EXIST'),
    editCandidateComment: CRUDStatusActions(CANDIDATE_MODULE_NAME, 'EDIT_CANDIDATE_COMMENT'),
    getFromAllFolders: CRUDStatusActions(CANDIDATE_MODULE_NAME, 'GET_FROM_ALL_FOLDERS'),
};

const SET = 'SET';
const EDIT = 'EDIT';
const VIEW = 'VIEW';
const REMOVE = 'REMOVE';
const COMPOUND_FROM_MARVIN = 'COMPOUND_FROM_MARVIN';
const CANDIDATE = 'CANDIDATE';
const ORDER = 'ORDER';
const FILTER = "FILTER";
export const CHECK_PARENTS_TASK = 'CHECK_PARENTS_TASK';

export const orderTypes = {
    Id: 'id',
    Code: 'code'
};

export const candidateModuleActions = {
    setCurrentCandidate: moduleAction(
        CANDIDATE_MODULE_NAME,
        [CANDIDATE, SET],
        (candidate) => (candidate)
    ),
    removeCurrentCandidate: moduleAction(
        CANDIDATE_MODULE_NAME,
        [CANDIDATE, REMOVE],
        () => null
    ),
    compoundFromMarvin: moduleAction(
        CANDIDATE_MODULE_NAME,
        [COMPOUND_FROM_MARVIN],
        ({result}: { result: ICompound }) => ({result})
    ),
    setEditMode: moduleAction(
        CANDIDATE_MODULE_NAME,
        [EDIT, SET],
        (isEditMode) => (isEditMode)
    ),
    setViewMode: moduleAction(
        CANDIDATE_MODULE_NAME,
        [VIEW, SET],
        (isViewMode) => (isViewMode)
    ),
    setOrder: moduleAction(
        CANDIDATE_MODULE_NAME,
        [ORDER, SET],
        (order) => ({order})
    ),
    setFilterCodes: moduleAction(
      CANDIDATE_MODULE_NAME,
      [FILTER, SET],
      (filterCodes: ({ filterCodes: Array<string> })) => ({filterCodes})
    )
};
