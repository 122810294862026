// @flow

import React from 'react';
import styled from 'styled-components';
import Button from '@biocad/bcd-front-ui/controls/Button';
import { PaginationStyled } from '../../../../../shared/components/Paginator/style';
import { SearchForm } from '../../../../../agents/components/List/SearchForm';

export const DialogLayout = styled('div')`
  width: 1200px;
  height: 800px;
  .searchForm {
    margin-bottom: var(--grid-spacer);
  }
  .dialog-footer {
    .${Button.block} {
      margin-left: var(--grid-spacer);
    }
    ${PaginationStyled} {
      margin-right: auto;
      margin-left: 0;
    }
  }
`;

export const AgentsList = styled('div')`
  display: grid;
  margin-top: var(--grid-spacer);
  --table-row-height: auto;
  .selected-row {
    .grid-table-row {
      background-color: var(--green-o-10);
    }
  }
  .no-jars-with-amount:not(.selected-row) {
    .grid-table-row {
      background-color: var(--red-o-15);
    }
  }
`;
