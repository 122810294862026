// @flow

import type { IProduct } from '../../products/model';
import type { IReagent } from '../../reagents/model';
import { ReagentKeyNames, ReagentTypeEnum } from '../../reagents/model';
import { ProductKeyNames } from '../../products/model';

export const
	isReagent = (item: (IProduct | IReagent)) => (
		typeof item === 'object' && ReagentKeyNames.ReagentType in item
	),
	isProduct = (item: (IProduct | IReagent)) => (
		typeof item === 'object' && ProductKeyNames.IsMainProduct in item
	),
	isSolvent = (item: IReagent) => (
		isReagent(item) && item.ReagentType === ReagentTypeEnum.Solvent
	),
	isCatalyst = (item: IReagent) => (
		isReagent(item) && item.ReagentType === ReagentTypeEnum.Catalyst
	),
	isOriginal = (item: IReagent) => (
		isReagent(item) && item.ReagentType === ReagentTypeEnum.Original
	),
	isReactant = (item: IReagent) => (
		isReagent(item) && item.ReagentType === ReagentTypeEnum.Reactant
	),
	isAuxiliary = (item: IReagent) => (
		isReagent(item) && item.ReagentType === ReagentTypeEnum.Auxiliary
	),
	isRefCompound = (item: IReagent) => (
		(isOriginal(item) || isReactant(item)) && item.IsRefCompound === true
	)
;
