// @flow

import React, {useEffect, useState, useRef} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Header from './Header';
import RightPanel, {CANDIDATE_EDIT_FORM_NAME} from './RightPanel';
import { change, getFormValues } from 'redux-form';
import {
    MarvinSketch,
} from '../../../shared/components';
import {candidateModuleActions, candidatesResourceActions} from '../../actions';
import {projectActions} from '../../../projects/actions';
import Button from '@biocad/bcd-front-ui/controls/Button';
import {
    faFileUpload,
} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import {PageLayout, PageLayoutSlot, pageLayoutAreas, PageLayoutBody} from '../../../shared/components/PageLayout';
import {_noop} from '../../../shared/utils/common';

const initialProps = {
    location: {},
    className: '',
    currentCandidate: null,
    updateMarvinCompound: _noop,
    updateMw: _noop,
    getCandidate: _noop,
    getProjectByCandidateId: _noop,
    removeCurrentCandidate: _noop,
};

let Candidate = (props = initialProps) => {

    // State
    const
        [isEditMode, setIsEditMode] = useState(false),
        [saveAvailable, setSaveAvailable] = useState(false),
        [isNew, setIsNew] = useState(null),
        [isAntibody, setIsAntibody] = useState(null),
        [isPolymer, setIsPolymer] = useState(null),
        [isComplex, setIsComplex] = useState(true),
        [marvinSketch, setMarvinSketch] = useState(null)
    ;

    const candidateId = props.match?.params?.candidateId;

    // Refs
    const
      marvinCanonicalize = useRef(null),
      hiddenFileInput = useRef(null)
    ;

    // Effects
    useEffect(() => {
        if ((typeof candidateId === 'string' && candidateId !== 'add') && isNaN(candidateId))
        {
            return;
        }
        const _isNew = candidateId === 'add';
        setIsNew(_isNew);

        if (_isNew)
        {
            const compoundType = new URLSearchParams(props.location?.search).get('compoundType');
            setIsAntibody(compoundType === 'antibody');
            setIsPolymer(compoundType === 'polymer');
            props.removeCurrentCandidate();
        }
        else {
            setIsEditMode(true);
            const id = Number.parseInt(candidateId, 10);
            props.getCandidate(id);
            props.getProjectByCandidateId(id);
        }
    }, []);

    useEffect(() => {
        setIsComplex(isAntibody || isPolymer);
    }, [isAntibody, isPolymer]);

    useEffect(() => {
        if (props.currentCandidate && (typeof isNew === 'boolean' && isNew === false))
        {
            setIsAntibody(props.currentCandidate?.Compound?.IsAntibody);
            setIsPolymer(props.currentCandidate?.Compound?.IsPolymer);
            setIsComplex(props.currentCandidate?.Compound?.IsComplexCompound);
            props.updateMarvinCompound(props.currentCandidate.Compound);
        }
    }, [props.currentCandidate, isNew]);

    useEffect(() => {
        if (marvinSketch && props.currentCandidate?.Compound?.SmilesFormula)
        {
            marvinSketch.importStructure('smiles', props.currentCandidate.Compound.SmilesFormula);
        }
    }, [marvinSketch, props.currentCandidate]);

    useEffect(() => {
        if (saveAvailable) {
            setSaveAvailable(false);
        }
    }, [saveAvailable]);

    // Methods
    const
      onMarvinChange = (result) => {
        props.updateMarvinCompound(result.result);
        props.updateMw(result.result?.MolarWeight);
      },
      onMarvinSketchInit = (sketch, canonicalize) => {
        marvinCanonicalize.current = canonicalize;
        setMarvinSketch(sketch);
      },
      onBeforeSave = () => {
        if (isComplex)
        {
            setSaveAvailable(true);
        }
        else if (marvinCanonicalize?.current && typeof marvinCanonicalize?.current === 'function')
        {
            marvinCanonicalize.current(props.formValues?.marvinCompound?.SmilesFormula);
        }
      },
      signalHeaderToProceedSave = () => {
        setSaveAvailable(true);
      },
      handleUpload = (event) =>
      {
          event.preventDefault();

          const {target} = event;

          if (target.files.length > 0)
          {
              const reader = new FileReader();
              reader.readAsDataURL(target.files[0]);
              reader.onload = () => {
                  props.updateMarvinCompound({
                      Thumbnail: reader.result,
                      IsAntibody: isAntibody,
                      IsPolymer: isPolymer,
                  });
              };
          }
      },
      renderMarvin = () =>
      (
          <div className={'candidate-sketch-container'}>
              <MarvinSketch
                  handleChange={(result) => onMarvinChange({result})}
                  onSketchInit={onMarvinSketchInit}
                  useCanonicalizer={signalHeaderToProceedSave}
              />
          </div>
      ),
      renderImageUploader = () =>
      (
          <div className={'candidate-image-upload'}>
              <div className={'button'}>
                  <Button
                      text={'Загрузить изображение молекулы'}
                      onAction={() => hiddenFileInput.current.click()}
                      size={'L'}
                      icon={faFileUpload}
                  />
                  <input type='file'
                         onChange={(event) => handleUpload(event)}
                         accept="image/png,image/jpeg,image/svg+xml,image/gif"
                         ref={hiddenFileInput}
                  />
              </div>
              <div className={'image'} />
          </div>
      )
    ;

    return (
        <PageLayout className={props.className}>
            <PageLayoutSlot gridAreaName={pageLayoutAreas.header}
                            as={Header}
                            onBeforeChange={onBeforeSave}
                            saveAvailable={saveAvailable}
            />
            <PageLayoutSlot gridAreaName={pageLayoutAreas.body}
                            as={PageLayoutBody}>
                <PageLayoutSlot gridAreaName={pageLayoutAreas.bodyTopLeft}
                                className={pageLayoutAreas.bodyTopLeft}
                >
                    {
                        isComplex
                            ? renderImageUploader()
                            : renderMarvin()

                    }
                </PageLayoutSlot>
                <PageLayoutSlot gridAreaName={pageLayoutAreas.bodyRight}
                                as={RightPanel}
                />
            </PageLayoutSlot>
        </PageLayout>
    );
};

Candidate = styled(Candidate)`
    --LayoutHeader-margin-v: 0;
    --LayoutHeader-margin-h: 0;
    --LayoutBlock-margin-h: 0;
    --LayoutBlock-margin-v: 0;
    --LayoutBlock-padding-h: 0;
    --LayoutBlock-padding-v: 0;
  
    .${pageLayoutAreas.bodyTopLeft}
    {
      grid-row-start: ${pageLayoutAreas.bodyTopLeft};
      grid-row-end: ${pageLayoutAreas.bodyBottomLeft};
    }
  
    .candidate-image-upload 
    {
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 12px;
      input[type="file"] { display: none; }
      .image {
        flex: auto;
        background-image: ${props => props?.formValues?.marvinCompound?.Thumbnail ? `url(${props?.formValues?.marvinCompound?.Thumbnail})` : ''};
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-origin: content-box;
        padding-top: 12px;
      }
    }
  
  .candidate-sketch-container {
    height: 100%;
  }
`;

Candidate = withRouter(Candidate);

Candidate = connect(
    (state) =>
    {
        const {modules} = state;
        return {
            currentCandidate: modules.candidate.candidate,
            formValues: getFormValues(CANDIDATE_EDIT_FORM_NAME)(state)
        };
    },
    (dispatch) =>
    ({
        updateMarvinCompound: (value) => dispatch(change(CANDIDATE_EDIT_FORM_NAME, 'marvinCompound', value, false)),
        updateMw: (value) => dispatch(change(CANDIDATE_EDIT_FORM_NAME, 'mw', value, false)),
        updateMnn: (value) => dispatch(change(CANDIDATE_EDIT_FORM_NAME, 'mnn', value, false)),
        updateAfs: (value) => dispatch(change(CANDIDATE_EDIT_FORM_NAME, 'afs', value, false)),
        getCandidate: (id: number) => dispatch(candidatesResourceActions.get.request({uriParams: {id}})),
        getProjectByCandidateId: (candidateId) => dispatch(projectActions.getProjectByCandidateId.request({ params: {candidateId: candidateId}})),
        removeCurrentCandidate: () => dispatch(candidateModuleActions.removeCurrentCandidate()),
    })
)(Candidate);

export default Candidate;
