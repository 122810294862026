import styled from 'styled-components';
import { LayoutHeader } from '../../../shared/components/LayoutHeader';

export const StyledLayoutHeader = styled(LayoutHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .buttons-holder {
    *:not(:last-of-type) {
      margin-right: var(--grid-spacer);
    }
  }
`;
