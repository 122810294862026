// @flow

import {
  moduleAction,
} from '../../shared/utils';
import type { IObtainmentStage, IStageSubstance } from '../models';
import type { IAgent, ICompound } from '../../agents/models/i-agent';
import type { IFinaleCandidate } from '../../projects/models';
import type { IObtainmentScheme } from '../../schemes/models';

export const STAGES_MODULE_NAME = 'STAGES';

export const stageModuleActions = {
  setProduct: moduleAction(
    STAGES_MODULE_NAME,
    ['SUBSTANCES', 'PRODUCT', 'SET'],
    ({ substance }: {substance: IStageSubstance}) => ({ substance })
  ),
  setSubstance: moduleAction(
    STAGES_MODULE_NAME,
    ['SUBSTANCES', 'REACTANT' ,'SET'],
    ({ payload, substancePosition }: { payload: IAgent | ICompound | IObtainmentStage | IFinaleCandidate, substancePosition: number }) => ({ payload, substancePosition })
  ),
  deleteSubstance: moduleAction(
    STAGES_MODULE_NAME,
    ['SUBSTANCES','DELETE'],
    ({ position }: { position: number }) => ({ position })
  ),
  selectSubstance: moduleAction(
    STAGES_MODULE_NAME,
    ['SUBSTANCES', 'SELECT'],
    ({ position }: { position: number }) => ({ position })
  ),
  setMergeOption: moduleAction(
    STAGES_MODULE_NAME,
    ['SUBSTANCES', 'MERGE_OPTION', 'SET'],
    ({ option }: { option: string }) => ({ option }),
  ),
  setMarvinPending: moduleAction(
    STAGES_MODULE_NAME,
    ['SUBSTANCES', 'MARVIN_PENDING'],
    ({ pending }: { pending: boolean }) => ({ pending }),
  ),
  setCurrentStageId: moduleAction(
    STAGES_MODULE_NAME,
    ['CURRENT', 'SET_ID'],
    ({ id }: { id: number }) => ({ id }),
  ),
  setCurrentSchemeId: moduleAction(
    STAGES_MODULE_NAME,
    ['CURRENT', 'SCHEME', 'SET_ID'],
    ({ schemeId }: { schemeId: number }) => ({ schemeId }),
  ),
  setCurrentCanBeModifiedOrDeleted: moduleAction(
    STAGES_MODULE_NAME,
    ['CURRENT', 'STAGE', 'SET_CAN_BE_MODIFIED_OR_DELETED'],
    ({ flag }: { flag: boolean }) => ({ flag })
  ),
  setCurrentNumber: moduleAction(
    STAGES_MODULE_NAME,
    ['CURRENT', 'STAGE', 'SET_NUMBER'],
    ({ number }: { number: number }) => ({ number })
  ),
  setCurrentReqiredPurificationsCount: moduleAction(
    STAGES_MODULE_NAME,
    ['CURRENT', 'STAGE', 'SET_REQIRED_PURIFICATIONS_COUNT'],
    ({ count }: { count: number }) => ({ count })
  ),
  setPurificationBundleType: moduleAction(
    STAGES_MODULE_NAME,
    ['CURRENT', 'STAGE', 'SET_PURIFICATION_BUNDLE_TYPE'],
    ({ value }: { value: number }) => ({ value })
  ),
  preSaveStage: moduleAction(
    STAGES_MODULE_NAME,
    ['CURRENT', 'STAGE', 'PRE_SAVE'],
    ({ stage }: { stage: IObtainmentStage }) => ({ stage }),
  ),
  preSaveScheme: moduleAction(
    STAGES_MODULE_NAME,
    ['CURRENT', 'SCHEME', 'PRE_SAVE'],
    () => null,
  ),
  initEntrySubstance: moduleAction(
    STAGES_MODULE_NAME,
    ['CURRENT', 'STAGE', 'INIT_ENTRY_STAGE'],
    (action: {result: IObtainmentStage}) => action,
  ),
  loadLastStagesByIds: moduleAction(
    STAGES_MODULE_NAME,
    ['MERGEABLE_SCHEMES', 'LAST_STAGE', 'LOAD'],
    ({ ids }: { ids: number[] }) => ({ ids }),
  ),
  setMergeableSchemesLastStages: moduleAction(
    STAGES_MODULE_NAME,
    ['MERGEABLE_SCHEMES', 'LAST_STAGE', 'SET'],
    ({ stages }) => ({ stages }),
  ),
  canonicalizeSubstances: moduleAction(
    STAGES_MODULE_NAME,
    ['SUBSTANCES', 'CANONICALIZE'],
    ()=>null,
  ),
  updateCanonicalizeStatus: moduleAction(
    STAGES_MODULE_NAME,
    ['SUBSTANCES', 'UPDATE_CANONICALIZE_STATUS'],
    ({ position, status }) => ({ position, status })
  ),
  destroyModule: moduleAction(
    STAGES_MODULE_NAME,
    ['DESTROY'],
    ()=>null,
  ),
  setProjectId: moduleAction(
      STAGES_MODULE_NAME,
      ['SET_PROJECT_ID'],
      ({ projectId }) => ({ projectId }),
  ),
  loadStageRelatedData: moduleAction(
      STAGES_MODULE_NAME,
      ['LOAD_RELATED_DATA'],
      ({ uriParams }) => ({ uriParams }),
  )
};
