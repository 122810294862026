import React, {useState} from 'react';
import type {ICompound} from '../../../agents/models/i-agent';
import {ReactionPurificationModal} from './ReactionPurificationModal';

interface IReactionPurification {
  compound: ICompound,
  agentId: number,
}

const initialProps = {
  compound: null,
  agentId: null,
};

export let ReactionPurification = (props: IReactionPurification = initialProps) => {

  // States
  const [isModalShown, setIsModalShown] = useState(false);

  // Methods
  const
    modalTrigger = (event: Event) => {
      event.preventDefault();
      event.stopPropagation();

      setIsModalShown(prev => !prev);
    }
  ;

  const { agentId, ...rest } = props;

  return (
    <>

      <img
        src={`${props.compound?.Thumbnail}`}
        onClick={event => modalTrigger(event)}
        alt={''}
        {...rest}
      />

      {isModalShown &&
        <ReactionPurificationModal
            onCancel={modalTrigger}
            compound={props.compound}
            agentId={agentId}
        />
      }

    </>
  );
};