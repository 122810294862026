// @flow
import React from 'react';
import {
  useState,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import Button from '@biocad/bcd-front-ui/controls/Button';
import {
    faPlus,
} from '@fortawesome/free-solid-svg-icons';
import {
    TitleWithCounter,
    LayoutBlock,
    LayoutHeader,
} from '../../../shared/components';
import { SearchForm, AGENT_SEARCH_FORM_NAME } from './SearchForm';
import {
    PageHeaderWrap,
    LoaderWrap,
} from './style';
import { withRouter, Redirect } from 'react-router-dom';
import {agentResourceActions, jarStockResourceActions} from '../../actions';
import AgentTable from './AgentTable';
import { itemsToArraySelector } from '../../../shared/utils/selectors/resource-selectors';
import Loader from '@biocad/bcd-front-ui/controls/Loader';
import { PaginationStyled } from '../../../shared/components/Paginator/style';
import type { IAgent } from '../../models';
import type { IPagination } from '../../../shared/models';
import type { IRootStore } from '../../../app/reducers';
import { Dispatch } from 'redux';
import { setGlobalLoader } from '../../../shared/actions';
import {userCRUDPermissionsSelector} from '../../../account/reducers/users-modules-reducer';
import Dropdown from '@biocad/bcd-front-ui/controls/Dropdown';
import { amountColorIndicators, AmountRemainsIndicatorEnum } from '../../utils';

interface IListProps {
  listData?: IAgent[],
  pendingState?: boolean,
  listPagination?: IPagination,
  searchFormValue?: {},
  getAgentList?: ({})=>void,
  destroyAgentList?: ()=>void,
  destroyJarStock?: ()=>void,
  setGlobalLoader?: (boolean)=>void,
  history?: {
    push?: typeof Function
  },
  userPermissions: {}
}

const
  sortAvailable: string[] = ['Name', 'Cas', 'MolarWeight'],
  initialPagingParams = {
    limit: 20,
    skip: 0,
  }
;

const createAgentOptions = [
  {
    value: 'add',
    label: 'Добавить вещество'
  },
  {
    value: 'addAntibody',
    label: 'Добавить антитело'
  },
  {
    value: 'addPolymer',
    label: 'Добавить полимер'
  },
];

const renderPageHeader = ({ count, goToAddAgent, showButton }) => (
  <PageHeaderWrap>
      <TitleWithCounter title={'Вещества'} count={count} />
      {
          showButton &&
          <Dropdown
            onChange={(value)=>goToAddAgent(value)}
            value={'Новое вещество'}
            options={Object.values(createAgentOptions)}
          />
      }
  </PageHeaderWrap>
);

let List = (props: IListProps) => {
  // State
  const
    [order, setOrder]                             = useState(null),
    [pagingParams, setPagingParams]               = useState(
      props.listPagination
        ? {
            skip: props.listPagination.pageSize*(props.listPagination.page-1),
            limit: props.listPagination.pageSize
          }
        : initialPagingParams
    ),
    [searchFormSubmitted, setSearchFormSubmitted] = useState(false),
    [initialAgentRequested, setInitialAgentRequested] = useState(false)
  ;

  // Effects
  const _watchData = () => {
    if (!props.pendingState) {
      if (!props.searchFormValue || !pagingParams) return;
      props.destroyAgentList();
      props.getAgentList({ ...props.searchFormValue, ...pagingParams, ...{ order } });
      setSearchFormSubmitted(false);
      if (!initialAgentRequested) setInitialAgentRequested(true);
    }
  };
  useEffect(_watchData, [order, searchFormSubmitted, pagingParams, initialAgentRequested ? null : props.searchFormValue]);

  const _clearDataOnUnmount = () => () => {
    props.destroyAgentList();
    props.destroyJarStock();
  };
  useEffect(_clearDataOnUnmount, []);

  const _setGlobalLoader = () => {
    props.setGlobalLoader(props.pendingState);
  };
  useEffect(_setGlobalLoader, [props.pendingState]);

  // Methods
  const
    handlePaginationClick = (listParams) => {
      setPagingParams(listParams);
    },
    handleHeaderClick = (colName: string) => {
      colName !== order && sortAvailable.includes(colName)
          ? setOrder(`-${colName}`)
          : setOrder(colName);
    },
    handleSearchFormSubmit = (event: Event) => {
      event.preventDefault();      
      setSearchFormSubmitted(true);
    },
    goToAddAgent = (value) => {
      props.history.push(`/agents/${value}`);
    },
    reloadAgentList = () => {
      props.destroyAgentList();
      props.getAgentList({ ...props.searchFormValue, ...pagingParams, ...{ order } });
    }
  ;

  return (
    <>
      <LayoutHeader>
        <SearchForm handleSubmit={handleSearchFormSubmit} />
      </LayoutHeader>
      <LayoutBlock header={renderPageHeader({count: props.listPagination.total, showButton: props.userPermissions.agent.CREATE, goToAddAgent})}>
        <AgentTable
          listData={props.listData}
          handleHeaderClick={handleHeaderClick}
          reloadAgentList={reloadAgentList}
        />
        <PaginationStyled
          {...props.listPagination}
          handlePagingChange={handlePaginationClick}
        />
      </LayoutBlock>
    </>
  );
};

List = connect(
  (state: IRootStore): IListProps => ({
    listData        : itemsToArraySelector(state.resource.agents),
    pendingState    : state.resource.agents?.pending,
    listPagination  : state.modules.agent?.list?.pagination,
    searchFormValue : getFormValues(AGENT_SEARCH_FORM_NAME)(state),
    userPermissions : userCRUDPermissionsSelector(state),
  }),
  (dispatch: Dispatch) => ({
    getAgentList    : (params) => dispatch(agentResourceActions.list.request({params})),
    destroyAgentList: () => dispatch(agentResourceActions.destroy()),
    destroyJarStock : () => dispatch(jarStockResourceActions.destroy()),
    setGlobalLoader : (isLoading: boolean) => dispatch(setGlobalLoader({ isLoading }))
  })
)(List);

List = withRouter(List);

export default List;
