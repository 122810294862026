import axios from 'axios';
import queryString from 'query-string';
import { environment } from '../../../environment';

const urlIsBase = (url) => {
    return /^http[s]?[/:]{3}.*$/i.test(url);
};
// eslint-disable-next-line no-undef
export const baseApiURL = environment.apiUri;

function getPagination(headers) {
    function getHeader(name) {
        return headers[name] || headers[name.toLowerCase()];
    }

    const page = parseInt(getHeader('X-Page'), 10);
    const pageCount = parseInt(getHeader('X-PageCount'), 10);
    const pageSize = parseInt(getHeader('X-PageSize'), 10);
    const total = parseInt(getHeader('X-Total'), 10);

    return isNaN(page) || isNaN(pageCount) ? null : { page, pageCount, pageSize, total };
}

function paramsSerializer(params) {
    return queryString.stringify(params);
}

/**
 * @typedef {Object} PaginatedSearchModel
 * @property {number} order
 * @property {number} limit
 * @property {number} skip
 */

/**
 * @param {'GET'|'POST'|'PUT'|'DELETE'} method
 * @param {string} url
 * @param {any} headers
 * @returns {function(PaginatedSearchModel, Object):Object[]}
 */
export function apiRequestList(method, url, headers) {
    return ({ data, params }) => axios({
        method,
        url: `${urlIsBase(url) ? url : `${baseApiURL}${url}`}`,
        params,
        data,
        headers,
        paramsSerializer: paramsSerializer,
    }).then((result) => {
        if (result.status >= 200 && result.status < 300) {
            return { pagination: getPagination(result.headers), result: result.data };
        }
        throw result;
    });
}

/**
 * @param {'GET'|'POST'|'PUT'|'DELETE'} method
 * @param {string} url
 * @param {any} headers
 * @param {any} baseApiUrl
 * @returns {function(Object, Object):Object}
 */
export function apiRequestSingle(method, url, headers) {
    return (params, data) => axios({
            method,
            url: `${urlIsBase(url) ? url : `${baseApiURL}${url}`}`,
            params,
            data,
            headers,
            paramsSerializer: paramsSerializer,
        }).then((result) => {
            if (result.status >= 200 && result.status < 300) {
                return { result: result.data };
            }
            throw result;
        });
}

export function apiRequestSingleAppendFile(method, _url, _headers) {
    return (params, file) => {

        const formData = new FormData();
        formData.append('file', file);

        const url = `${urlIsBase(_url) ? _url : `${baseApiURL}${_url}`}`;
        const headers = {
            ..._headers,
            'content-type': 'multipart/form-data',
        };

        return axios.post(
            url,
            formData,
            { headers },
        ).then((result) => {
            if (result.status >= 200 && result.status < 300) {
                return { result: result.data };
            }
            throw result;
        });
    };
}

export function apiRequestSingleBlob(method, url, headers) {
    return (params, data) => axios({
        method,
        url: `${urlIsBase(url) ? url : `${baseApiURL}${url}`}`,
        params,
        data,
        headers,
        responseType: 'blob',
    }).then((result) => {
        if (result.status >= 200 && result.status < 300) {
            return { result: result.data };
        }
        throw result;
    });
}

export interface IRequestSingle {
    params: {};
    data: {};
}

