// @flow

import {
    ResourceService,
    ResourceRequestMethodType,
    CRUDRequestsMap, HTTPMethodEnum,
} from '../../shared/utils/service-creator';

import { ArrayModel, Model } from 'objectmodel';

import {Indicator, IndicatorProcessPoint, IndicatorUnit} from '../models';

interface IIndicatorsResourceService {
    list: ResourceRequestMethodType
}

export const IndicatorsResourceService: IIndicatorsResourceService = ResourceService({
    baseURL: '/indicators',
    defaultModel: Indicator,
    useDefaultCRUDRequests: [
        CRUDRequestsMap.list,
    ],
});

export const IndicatorUnitsResourceService = ResourceService({
    baseURL: '/indicator_units',
    defaultModel: IndicatorUnit,
    useDefaultCRUDRequests: [
        CRUDRequestsMap.list,
    ],
});

interface IIndicatorProcessPointsResourceService
{
    get: ResourceRequestMethodType;
    create: ResourceRequestMethodType;
    update: ResourceRequestMethodType;
    delete: ResourceRequestMethodType;
    list: ResourceRequestMethodType;
}

export const IndicatorProcessPointsResourceService: IIndicatorProcessPointsResourceService = ResourceService({
    baseURL: '/ind_process_points',
    defaultModel: IndicatorProcessPoint,
    useDefaultCRUDRequests: [
        CRUDRequestsMap.get,
        CRUDRequestsMap.create,
        CRUDRequestsMap.update,
        CRUDRequestsMap.delete,
        CRUDRequestsMap.list,
    ],
    requests: {
        [CRUDRequestsMap.list]: {
            url: '',
            model: ArrayModel([IndicatorProcessPoint]),
            paramsModel: Model({ stageId: Number })
        },
        [CRUDRequestsMap.update]: {
            url: '',
            method: HTTPMethodEnum.PUT,
            model: IndicatorProcessPoint,
            dataModel: IndicatorProcessPoint,
        }
    }
})