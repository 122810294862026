// @flow

import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { globalAction } from '../utils/action-creators';
import type { ComponentType } from 'react';
import type {IRootStore} from '../../app/reducers';
import {getFormValues, isDirty} from 'redux-form';
import {_noop} from '../utils/common';
import '../../reactions/reducers/selectors';
import {Dispatch} from 'redux';

type ReduxFormAutosaveOptions = {
    form: string,
    preventSubmitSelector: typeof Function,
    isDirtySelector?: typeof Function,
    submitForm?: typeof Function,
    valueSelector?: typeof Function,
}

const defaultReduxFormAutosaveOptions: ReduxFormAutosaveOptions = {
    form: null,
    isDirtySelector: null,
    preventSubmitSelector: null,
    submitForm: null,
    valueSelector: null,
}

type ReduxFormAutosaveHOCProps = {
    isDirty?: boolean,
    preventSubmit?: boolean,
    values?: {},
    submitForm?: typeof Function,
    debounce?: (submitActionType: ?string, formName: string) => null,
}

export const REDUX_FORM_AUTOSAVE_DELAY = 2 * 60 * 1000; // 2 минуты, самое простое хранить константой, если надо будет определять динамически, то надо придумать как это передавать саге.

export const reduxFormAutosaveDebounceAction = globalAction(
    'REDUX_FORM_AUTOSAVE',
    ['DEBOUNCE'],
    ({ submitActionType, formName })=>({ submitActionType, formName })
);

export const ReduxFormAutosave = (options: ReduxFormAutosaveOptions = defaultReduxFormAutosaveOptions) => {
    if (!options.form && (!options.valueSelector && !options.isDirtySelector)) {
        throw new Error('Skipped required options.');
    }

    const
        isDirtySelector         = options.isDirtySelector || isDirty(options.form),
        preventSubmitSelector   = options.preventSubmitSelector || _noop,
        valueSelector           = options.valueSelector || getFormValues(options.form)
    ;

    return (WrappedComponent: ComponentType) => {

        let ReduxFormAutosaveHOC = (props: ReduxFormAutosaveHOCProps) => {
            // Effects
            const _debounceValues = () => {
                if (props.isDirty && !props.preventSubmit) {
                    // DISPATCH DEBOUNCE
                    props.debounce(
                        options.submitForm.toString(),
                        options.form,
                    );
                }
                else {
                    // CANCEL SUBMIT
                    props.debounce(
                        null,
                        options.form,
                    );
                }
                return () => {
                    props.debounce(null, options.form);
                }
            };
            useEffect(_debounceValues, [props.values, props.isDirty, props.preventSubmit]);

            // render
             return (
                <WrappedComponent {...props} />
             )
        };

        ReduxFormAutosaveHOC = connect(
            (state: IRootStore): ReduxFormAutosaveHOCProps => ({
                isDirty: isDirtySelector(state),
                values: valueSelector(state),
                preventSubmit: preventSubmitSelector(state),
            }),
            (dispatch: Dispatch): ReduxFormAutosaveHOCProps => ({
                debounce: (submitActionType, formName) => dispatch(reduxFormAutosaveDebounceAction({ submitActionType, formName })),
            })
        )(ReduxFormAutosaveHOC);

        return ReduxFormAutosaveHOC;
    }
}