// @flow

import { Model, ArrayModel } from 'objectmodel';
import { Compound } from '../../agents/models';

export const CatalogReaction = Model({
  Id: Number,
  CandidateId: Number,
  CreationDate: Date,
  ExperimentalYield: Number,
  IsCompleted: Boolean,
  IsTrial: Boolean,
  Name: String,
  PercentYield: Number,
  Products: ArrayModel([Compound]),
  ProjectId: Number,
  Reagents: ArrayModel([Compound]),
  SchemeId: Number,
  StageId: Number,
  TheoreticalYield: Number,
  Author: String,
  IsArchive: Boolean,
  ReactionType: Number,
  CompleteDate: Date,
  IsSuccessful: Boolean,
  QuickComment: String,
  AdditionalName: String,
});
