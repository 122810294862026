import React  from 'react';
import bnc from 'bnc';
import propTypes from 'prop-types';
import './index.less';

const b = new bnc.default('b-table');

export const Table = ({className = '', children}) => (
    <table className={b + className}>
        {children}
    </table>
);

export const Header = ({className = '', children}) => (
    <thead className={b.el('header') + className}>
        {children}
    </thead>
);

export const Footer = ({className = '', children}) => (
    <tfoot className={b.el('footer') + className}>
        {children}
    </tfoot>
);

export const Body = ({className = '', children}) => (
    <tbody className={b.el('body') + className}>
        {children}
    </tbody>
);

export const Row = ({className = '', children}) => (
    <tr className={b.el('row') + className}>
        {children}
    </tr>
);

export const Cell = ({className = '', children, colSpan, rowSpan}) => (
    <td className={b.el('cell') + className} colSpan={colSpan} rowSpan={rowSpan}>
        {children}
    </td>
);

/* PropTypes */
const classNameType = propTypes.oneOfType([
    propTypes.string,
    propTypes.instanceOf(bnc)
]);

const childrenOf = (...types) => {
    let fieldType = propTypes.shape({
        type: propTypes.oneOf(types).isRequired,
    });

    return propTypes.oneOfType([
        fieldType,
        propTypes.arrayOf(fieldType),
    ]);
};

Table.propTypes = {
    className: classNameType,
    children: childrenOf(Header, Footer, Body),
};

Header.propTypes = {
    className: classNameType,
    children: childrenOf(Row),
};

Footer.propTypes = Header.propTypes;
Body.propTypes = Header.propTypes;

Row.propTypes = {
    className: classNameType,
    children: childrenOf(Cell),
};

Cell.propTypes = {
    className: propTypes.oneOfType([propTypes.string, propTypes.object]),
    children: propTypes.node,
    colSpan: propTypes.number,
    rowSpan: propTypes.number,
};
