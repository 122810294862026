// @flow

import React, { useRef } from 'react';
import type { TReactionEditDialogRefType } from '../ReactionEditDialogWrap';
import Button from '@biocad/bcd-front-ui/controls/Button';
import { ReactionEditDialogsMap } from '../../../reducers';
import { ReactionEditDialogWrap } from '../ReactionEditDialogWrap';
import type { IReagent } from '../../../../reagents/model';


interface IReagentConsumptionBtnProps {
	smilesFormulas: string[],
	complexCompoundNames: string[],
	reagent: IReagent
}

let ReagentConsumptionBtn = (props: IReagentConsumptionBtnProps) => {
	// refs
	const
		dialogRef = useRef<TReactionEditDialogRefType>(null)
	;

	// handlers
	const
		setDialogRef = (dialog: TReactionEditDialogRefType) => {
			if (dialog) dialogRef.current = dialog;
		},
		showDialog = () => {
			if (dialogRef.current) {
				dialogRef.current.open();
			}
		}
	;

	return (
		<span style={{ display: 'contents' }} onClick={(event) => event.stopPropagation()}>
		<Button
			className={'fo-edit'}
			onAction={showDialog}
			view={'icon'}
			size={'L'}
		/>
		<ReactionEditDialogWrap
			dialogName={ReactionEditDialogsMap.EditSubstance}
			smilesFormulas={props.smilesFormulas}
			complexCompoundNames={props.complexCompoundNames}
			getDialogRef={setDialogRef}
			reagents={[props.reagent]}
		/>
		</span>
	)
};

export {
	ReagentConsumptionBtn
}
