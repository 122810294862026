import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Button from '@biocad/bcd-front-ui/controls/Button';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faFolderPlus } from '@fortawesome/free-solid-svg-icons/faFolderPlus';
import {
  PageHeaderWrap,
  ProjectsTableStyled,
  ButtonsContainer,
  LayoutBlockStyled,
  PageHeaderWrapExpander
} from './style';
import './index.less';
import { TitleWithCounter } from '../../../shared/components/TitleWithCounter';
import type { TableCol } from '../../../shared/models';
import ProjectsList from './Projects';
import FoldersList from './Folders';
import FolderModal from './Folders/modal';
import ProjectModal from './Projects/modal';
import {
  candidateModuleActions,
  folderModuleActions,
  folderResourceActions,
  projectActions,
  projectModuleActions
} from '../../actions';
import { getFormValues } from 'redux-form';
import { PROJECT_SEARCH_FORM_NAME } from '../SearchField';
import { PaginationStyled } from '../../../shared/components/Paginator/style';
import { CheckGroup } from '../../../shared/components/CheckGroup';
import { projectStatusesTranslate } from '../../localization';
import { projectStatusEnum } from '../../types/enum';
import {userCRUDPermissionsSelector} from "../../../account/reducers/users-modules-reducer";

const tableCols: TableCol[] = [
  {
    title: 'Наименование проекта',
    mappingProp: 'Name'
  },
  {
    title: 'Руководитель',
    mappingProp: 'Manager'
  },
  {
    title: 'Тип',
    mappingProp: 'Type'
  },
  {
    title: 'Статус',
    mappingProp: 'Status'
  }
];

const defaultPagingParams = {
  limit: 20,
  skip: 0
};

const initialProps = {
  setEditFolderMode: () => null,
  setEditProjectMode: () => null,
  destroyPagination: () => null,
  projectsListProps: {},
  listPagination: null,
  getProjectsList: null,
  clearProjectsList: null,
  clearFoldersList: null,
  pendingState: null,
  searchFormValue: null,
  removeCurrentCandidate: () => null,
  candidate: null,
  currentFolder: null,
  userPermissions: {}
};

const ProjectsTable = (props = initialProps) => {
  const [showFolderModal, setShowFolderModal] = useState(false);
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [pagingParams, setPagingParams] = useState(defaultPagingParams);
  const [searchFormSubmitted, setSearchFormSubmitted] = useState(false);
  const [checkGroupVals, setCheckGroupVals] = useState([projectStatusEnum.configuring, projectStatusEnum.inWork]);

  useEffect(() => {
    props.candidate && props.removeCurrentCandidate();
  }, []);

  useEffect(() => {
    if(props.listPagination.pageCount === 1) {
      pagingParams.skip = 0;
      setPagingParams({
        ...pagingParams,
        ...(props.currentFolder ? { folderId: props.currentFolder } : null)
      });
    }
  }, [props.listPagination.pageCount]);

  const _watchData = () => {
    
    if (!props.pendingState) {
      props.clearProjectsList();
      props.getProjectsList({ ...props.searchFormValue, ...pagingParams, statuses: checkGroupVals, FolderId: props.currentFolder});
      setSearchFormSubmitted(false);
    }
  };
  useEffect(_watchData, [searchFormSubmitted, pagingParams, checkGroupVals, props.isChanged]);

  const _onUnmount = () => () => {
    props.destroyPagination();
  };
  useEffect(_onUnmount, []);

  const toggleFolderModal = () => {
    setShowFolderModal(!showFolderModal);
    props.setEditFolderMode({
      isEdit: false
    });
  };

  const toggleProjectModal = () => {
    setShowProjectModal(!showProjectModal);
    props.setEditProjectMode({
      isEdit: false
    });
  };

  const handlePaginationClick = listParams => {
    setPagingParams({
      ...listParams,
      ...(props.currentFolder ? { folderId: props.currentFolder } : null)
    });
  };

  const statusesOptions = [
    {
      label: projectStatusesTranslate['configuring'],
      value: projectStatusEnum.configuring,
    },
    {
      label: projectStatusesTranslate['inWork'],
      value: projectStatusEnum.inWork,
    },
    {
      label: projectStatusesTranslate['finished'],
      value: projectStatusEnum.finished,
    },
    {
      label: projectStatusesTranslate['closed'],
      value: projectStatusEnum.closed,
    },
    {
      label: projectStatusesTranslate['paused'],
      value: projectStatusEnum.paused,
    }
  ];

  

  const renderPageHeader = ({ count }) => (
    <PageHeaderWrap>
      <TitleWithCounter
        title={'Проекты'}
        count={count}
        className={'project-counter'}
      />
      <CheckGroup
        className={'projects-list-check-group'}
        options={statusesOptions}
        value={checkGroupVals}
        onChange={val => setCheckGroupVals(val)}
      />
      <PageHeaderWrapExpander />
      <ButtonsContainer>
        {
          props.userPermissions.folder.CREATE &&
          <Button
              text={'Создать папку'}
              onAction={toggleFolderModal}
              size={'L'}
              icon={faFolderPlus}
          />
        }
        {
          props.userPermissions.project.CREATE &&
          <Button
              text={'Новый проект'}
              onAction={toggleProjectModal}
              size={'L'}
              icon={faPlus}
          />
        }
      </ButtonsContainer>
    </PageHeaderWrap>
  );

  return (
    <LayoutBlockStyled
      header={renderPageHeader({ count: props.projectsTotal })}
    >
      {showFolderModal && <FolderModal toggleModal={toggleFolderModal} />}

      {showProjectModal && <ProjectModal toggleModal={toggleProjectModal} />}

      <ProjectsTableStyled>
        <thead>
          <tr>
            {tableCols.map(col => (
              <th key={col.mappingProp}>
                <span>{col.title}</span>
              </th>
            ))}
            <td />
            <td />
          </tr>
        </thead>
        <tbody>
          {props.listPagination.page === 1 && (
            <FoldersList toggleFolderModal={toggleFolderModal} />
          )}

          <ProjectsList toggleProjectModal={toggleProjectModal} />
        </tbody>
      </ProjectsTableStyled>

      <PaginationStyled
        {...props.listPagination}
        handlePagingChange={handlePaginationClick}
      />
    </LayoutBlockStyled>
  );
};

const mapStateToProps = state => {
  const { resource, modules } = state;
  return {
    projectsTotal: modules.project.projectsTotal,
    pendingState: resource.projects && resource.projects.pending,
    listPagination:
      modules.project &&
      modules.project.list &&
      modules.project.list.pagination,
    searchFormValue: getFormValues(PROJECT_SEARCH_FORM_NAME)(state),
    candidate: modules.candidate.candidate,
    currentFolder: modules.folder.path.length
      ? modules.folder.path[modules.folder.path.length - 1].id
      : null,
    isChanged: modules.project.isChanged,
    userPermissions: userCRUDPermissionsSelector(state)
  };
};

export default connect(
  mapStateToProps,
  dispatch => ({
    setEditFolderMode: isEditMode => dispatch(folderModuleActions.setEditMode(isEditMode)),
    setEditProjectMode: isEditMode => dispatch(projectModuleActions.setEditMode(isEditMode)),
    getProjectsList: params => dispatch(projectActions.list.request({ params })),
    clearProjectsList: () => dispatch(projectActions.destroy()),
    clearFoldersList: () => dispatch(folderResourceActions.destroy()),
    destroyPagination: () => dispatch(projectModuleActions.destroyPagination()),
    removeCurrentCandidate: () => dispatch(candidateModuleActions.removeCurrentCandidate())
  })
)(ProjectsTable);
