// @flow

import axios, { AxiosError, AxiosPromise, AxiosResponse, CancelToken } from 'axios';
import type {
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';
import { ModelError, ObjectModel } from 'objectmodel';
import { parseTemplate } from 'url-template';
import { HTTPMethodEnum } from './constants';
import { getPagination } from './helpers';
import { ResourceError } from './resource-error';


export interface IServiceRequestConfig extends AxiosRequestConfig {
  model: typeof ObjectModel,
  uriParamsModel: typeof ObjectModel,
  paramsModel: typeof ObjectModel,
  dataModel: typeof ObjectModel,
}

export type ServiceRequestArgs = {
  uriParams: any,
  params: any,
  data: any,
};

export type ResourceRequestMethodType = {|
  (args: ServiceRequestArgs, token: ?string): AxiosPromise,
  cancel: (cancelRequest: typeof Function)=>void,
  cancelToken: CancelToken,
|}

const defaultConfig: IServiceRequestConfig = {
  url: '',
  method: HTTPMethodEnum.GET,
  model: null,
  headers: {},
  uriParamsModel: null,
  paramsModel: null,
  dataModel: null,
};

function extractErrorFromArrayBuffer(arrayBuffer) {
  let utf8decoder = new TextDecoder(); 
  let string = utf8decoder.decode(arrayBuffer);
  let obj = JSON.parse(string);
  return obj.ErrorMessage || 'Произошла ошибка!';
}

// Функция которая по конфигу создает Axios-запрос, кроме стандартных полей из AxiosRequestConfig также
// принимает три дополнительных поля: uriParamsModel, paramsModel и dataModel, которые должны быть функциями конструкторами.
// Вторым аргуметом принимает базовый сервис, который и использует для запроса.
export function ResourceRequestMethod (
  config: IServiceRequestConfig = defaultConfig,
  baseService: AxiosInstance = null,
): ResourceRequestMethodType
{
  if (!baseService) return;
  let cancelRequest = () => {
      source.cancel();
    };
  const source = axios.CancelToken.source();

  const serviceRequest = async (args: ServiceRequestArgs, token: ?string = null ): AxiosPromise => {
    let { uriParams, params, data } = args;
    let url, urlParsed;

    // если был указан config.dataModel то попробовать инстанциировать, иначе передать data как пришло.
    if (data && typeof data === 'object' && config.dataModel && typeof config.dataModel === 'function') {
      try {
        data = new config.dataModel(data);
      }
      catch (error) {
        console.error(`Error while instantiating dataModel ${config.dataModel.name} - ${error.message}`);
        throw new ResourceError(error.message);
      }
    }

    // если был указан config.paramsModel то попробовать инстанциировать, иначе передать params как пришло.
    if (params && typeof params === 'object' && config.paramsModel && typeof config.paramsModel === 'function') {
      try {
        params = new config.paramsModel(params);
      }
      catch (error) {
        console.error(`Error while instantiating paramsModel ${config.paramsModel.name} - ${error.message}`);
        throw new ResourceError(error.message);
      }
    }

    // если был указан config.uriParams то попробовать инстанциировать
    if (uriParams && typeof uriParams === 'object') {
      if (config.uriParamsModel && typeof config.uriParamsModel === 'function') {
        try {
          uriParams = new config.uriParamsModel(uriParams);
        }
        catch (error) {
          console.error(`Error while instantiating uriParamsModel ${config.uriParamsModel.name} - ${error.message}`);
          throw new ResourceError(error.message);
        }
      }
    }

    // если config.url то попробовать разобрать его
    if ('url' in config && typeof config.url === 'string' && config.url.length) {
      urlParsed = parseTemplate(config.url);
      url = urlParsed.expand(uriParams);
    }

    try {
      const requestArgs = {
        ...( data ? { data } : null ),
        ...( params ? { params } : null ),
        ...( url ? { url } : null ),
      };
      const response: AxiosResponse = await baseService.request({
        ...config,
        ...requestArgs,
        headers: { ...(token ? {Authorization: token} : null), ...config.headers },
        cancelToken: source.token,
      });
      const pagination = getPagination(response.headers);
      let result;
      if (response.data) {
        if (Array.isArray(response.data) && config.dataModel ) {
          result = response.data
            .map( item => (config.dataModel && typeof config.dataModel === 'function')
              ? new config.model(item)
              : item );
        } else {
          result = (config.dataModel && typeof config.dataModel === 'function' && config.model)
            ? new config.model(response.data)
            : response.data;
        }
      }
      return { result, ...(pagination ? { pagination } : null) };
    } catch (error) {
      if (error.response) {
        if ('ErrorMessage' in error.response?.data) {
          throw new ResourceError(error.response.data.ErrorMessage);
        }
        else if ('ExceptionMessage' in error.response?.data) {
          throw new ResourceError(error.response.data.ExceptionMessage);
        }
        else if (error.response?.data instanceof ArrayBuffer) {
          throw new ResourceError(extractErrorFromArrayBuffer(error.response?.data));
        }
        else {
          throw new ResourceError('Произошла ошибка!');
        }
      }
      else if (error.request) {
        console.error(error.request);
        throw new ResourceError(error.message);
      }
      else {
        console.error('Error: ', error.message);
        throw new ResourceError(error.message);
      }
    }
  };

  return Object.defineProperties(serviceRequest, {
    cancel: {
      value: () => {
        if (cancelRequest) {
          console.log('Request canceled');
          cancelRequest();
        }
      },
      writable: false,
      enumerable: false,
    },
    cancelToken: {
      value: source.token,
      writable: false,
      enumerable: false,
    }
  });
}
