// @flow

import {
    resourceActions, moduleAction, CRUDStatusActions,
} from '../../shared/utils';

export const REACTIONS_ROOT_NAME = 'CURRENT_REACTIONS';
export const currentUserReactionsResourceActions = {
    ...resourceActions(REACTIONS_ROOT_NAME),
};
