// @flow
import { IProduct } from './i-product';

export interface IReaction {
  Author: string;
  CandidateId: number;
  CompleteDate: any;
  CreationDate: string;
  ExperimentalYield: any;
  Id: number;
  IsArchive: boolean;
  IsCompleted: boolean;
  IsSuccessful: any;
  IsTrial: boolean;
  Name: string;
  PercentYield: number;
  Products: IProduct[];
  ProjectId: number;
  QuickComment: any;
  ReactionType: number;
  Reagents: IProduct[];
  SchemeId: number;
  StageId: number;
  TheoreticalYield: number;
}

export const ReactionsKeysEnum = Object.freeze({
      Name: 'Name',
      CreationDate: 'CreationDate',
      Reagents: 'Reagents',
      Products: 'Products',
      TheoreticalYield: 'TheoreticalYield',
      ExperimentalYield: 'ExperimentalYield',
      PercentYield: 'PercentYield',
});

export const ReactionsTitlesEnum = Object.freeze({
      Name: 'Название',
      CreationDate: 'Дата создания',
      Reagents: 'Реактанты',
      Products: 'Продукты',
      TheoreticalYield: 'Теор. выход, г',
      ExperimentalYield: 'Эксп. выход, г',
      PercentYield: 'Эксп. выход, %',
});
