import React, { useState, useEffect, useCallback } from 'react';
import Button from '@biocad/bcd-front-ui/controls/Button';
import Dialog from '@biocad/bcd-front-ui/layout/Dialog';
import classNames from 'classnames';
import { SmallMoleculeEdit, SMALL_MOLECULE_EDIT_FORM_NAME } from '../SmallMoleculeEdit';
import { connect } from 'react-redux';
import { getFormValues, isInvalid, getFormSyncErrors } from 'redux-form';
import { AnalyticsModuleActions } from '../../actions';
import css from './popup.m.less';

export const SmallMoleculeEditPopup = ({ closeModal, formValues, saveCurrentMolecule, formIsInvalid, formSyncErrors, }) => {
  const [disabled, setDisabled] = useState(false);

  const submitHandler = () => {
    saveCurrentMolecule(formValues);
  };

  const formSyncErrorsCheck = useCallback(() => {
    const values = !!Object.values(formSyncErrors);

    return formSyncErrors && !!values.length && !!Object.values(values)?.length;
  }, [formSyncErrors]);
  
  useEffect(() => {
    setDisabled(formIsInvalid || formSyncErrorsCheck());
  }, [formIsInvalid, formSyncErrorsCheck()]);

  return (
    <Dialog paranjaClose={false} close={closeModal}>
      <div className={classNames('dialog-layout', css.popup)}>
        <header className={'dialog-header'}>
          <h3>Образец</h3>
        </header>
        <div className={classNames('dialog-contents', css.contetns)}>
          <SmallMoleculeEdit />
        </div>
        <footer className={'dialog-footer'}>
          <Button
            className={'submitBtn'}
            onAction={submitHandler}
            text="Добавить"
            disabled={disabled}
            view="primary"
            size="L"
          />
        </footer>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  formValues: getFormValues(SMALL_MOLECULE_EDIT_FORM_NAME)(state),
  formIsInvalid: isInvalid(SMALL_MOLECULE_EDIT_FORM_NAME)(state),
  formSyncErrors: getFormSyncErrors(SMALL_MOLECULE_EDIT_FORM_NAME)(state),
});

const mapDispatchToProps = dispatch => ({
  saveCurrentMolecule: (molecule) => dispatch(AnalyticsModuleActions.saveCurrentMolecule({molecule})),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(SmallMoleculeEditPopup);
