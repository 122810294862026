import {
  put,
  takeEvery,
  fork,
  select,
} from 'redux-saga/effects';
import { workProjectsResourceActions, currentUserReactionsResourceActions, ownedProjectsResourceActions } from '../actions';
import { getToken } from '../../account/reducers';
import { DashboardService } from '../services';
import { projectActions } from '../../projects/actions';

const defaultWorkProjectPagingParams = {
  skip: 0,
  limit: 20,
  order: 'Name'
};

export function* getWorkProjectsList(data) {
  const token = yield select(getToken);
  const service = new DashboardService(token);
  try {
      const result = yield service.getWorkProjectsList(data);
      yield put(workProjectsResourceActions.list.success(result));
  } catch (e) {
      if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
          yield put(workProjectsResourceActions.list.failure({error: e.response.data['ErrorMessage']}));
      }
  }
}

export function* getOwnedProjectsList(data) {
  const token = yield select(getToken);
  const service = new DashboardService(token);
  try {
      const result = yield service.getOwnedProjectsList(data);
      yield put(ownedProjectsResourceActions.list.success(result));
  } catch (e) {
      if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
          yield put(ownedProjectsResourceActions.list.failure({error: e.response.data['ErrorMessage']}));
      }
  }
}

export function* getReactionsList(data) {
  const token = yield select(getToken);
  const service = new DashboardService(token);
  try {
      const result = yield service.getReactionsList(data);
      yield put(currentUserReactionsResourceActions.list.success(result));
  } catch (e) {
      if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
          yield put(currentUserReactionsResourceActions.list.failure({error: e.response.data['ErrorMessage']}));
      }
  }
}

export const dashboardWatchers = [
  function* watchReactionsList() {
    yield takeEvery(currentUserReactionsResourceActions.list.request.type, function* (action) {
        const {params} = action;
        yield fork(getReactionsList, {params});
    });
  },
  function* watchWorkProjectsList() {
    yield takeEvery(workProjectsResourceActions.list.request.type, function* (action) {
        const {params} = action;
        yield fork(getWorkProjectsList, {params});
    });
  },
  function* watchOwnedProjectsList() {
    yield takeEvery(ownedProjectsResourceActions.list.request.type, function* (action) {
        const {params} = action;
        yield fork(getOwnedProjectsList, {params});
    });
  },
  function* watchProjectUpdate() {
    yield takeEvery(projectActions.update.success.type, function* () {
        yield fork(getOwnedProjectsList, defaultWorkProjectPagingParams);
        yield fork(getWorkProjectsList, defaultWorkProjectPagingParams);
    });
},
];
