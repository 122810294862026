import './index.less';

var AppContainer = document.getElementById('app');

if (!AppContainer){
    AppContainer = document.createElement('section');
    AppContainer.id = 'app';
    document.body.appendChild(AppContainer);
}

export default AppContainer;