// @flow
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactionsList from './ReactionsList';
import WorkProjectsList from './WorkProjectsList';
import OwnedProjectsList from './OwnedProjectsList';
import { StyledLayoutHeader } from '../../shared/components';
import { DashboardTabsValues } from '../models';
import { DashboardTabs } from './DashboardTabs';
import { dashboardModuleActions } from '../actions/dashboard-modules';

const UserDashboard = ({match, history, destroyDashboard}) => {

  const renderTabContent = () => {
    switch(match?.params?.type) {
      case DashboardTabsValues.reactions:
        return <ReactionsList />;
      case DashboardTabsValues.workProjects:
        return <WorkProjectsList />;
      case DashboardTabsValues.ownedProjects:
        return <OwnedProjectsList />;
      default:
        return null;
    }
  };

  useEffect(() => {
    return () => {
      destroyDashboard();
    };
  }, []);

  useEffect(() => {
    !match?.params?.type
      && navigate(`${match.path}/${DashboardTabsValues.reactions}`);
  }, [match.params.type]);
  
  const navigate = (val) => {
    history.push(`${val}`);
  };

  return (
    <>
      <StyledLayoutHeader>
        <h2>Кабинет химика</h2>
        {
          <DashboardTabs value={match.params.type} changeHandler={navigate}/>
        }
        <div className={'flex-expander'}></div>
      </StyledLayoutHeader>
        {
         renderTabContent() 
        }
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  destroyDashboard: () => dispatch(dashboardModuleActions.destroy()),
});


export default withRouter(connect(null, mapDispatchToProps)(UserDashboard));

