export const getPathWithDescendants = (descendantsList, folderId, rootId) => {
    let path = [];
    let currentFolder;

    path.push(descendantsList.find(item => Number(item.Id) === Number(folderId)));

    let tempPath = createPathCycle(descendantsList, path[0]);
    currentFolder && tempPath && path.push(tempPath);

    while (path[0] && (Number(path[path.length - 1].ParentId) !== Number(rootId))) {
        tempPath = createPathCycle(descendantsList, path[path.length - 1]);
        tempPath && path.push(tempPath);
    }

    return path.reverse();
};

const createPathCycle = (descendantsList, currentFolder) => {
    if (currentFolder) {
        for (let i = 0; i < descendantsList.length; i++) {
            if (Number(currentFolder.ParentId) === Number(descendantsList[i].Id) && (Number(currentFolder.Id) !== Number(descendantsList[i].Id))) {
                return descendantsList[i];
            }
        }
    }
    return null
};
