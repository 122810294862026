// @flow
import { actionCreate, STORE_ROOT_PREFIXES } from './basic-action';
import { IPagination } from '../../models';


export interface IResourceAction {
    type: string;
    pending: boolean;
    fail: boolean;
    requestSucceed: boolean;
    pagination?: IPagination;
}
export interface IRequestResourceAction extends IResourceAction {
    data: any;
    params: any;
    uriParams: any;
}
export interface ISuccessResourceAction extends IResourceAction {
    result: { Id: number, ...any } | { Id: number, ...any }[];
}
export interface IFailureResourceAction extends IResourceAction {
    error: any;
}

export type resourceAction = IResourceAction & IRequestResourceAction & ISuccessResourceAction & IFailureResourceAction;

export interface IFetchActions {
    request: (type: string, payload: any) => IRequestResourceAction;
    success: (type: string, payload: any) => ISuccessResourceAction;
    failure: (type: string, payload: any) => IFailureResourceAction;
}
export interface IResourceActions {
    create: IFetchActions;
    get: IFetchActions;
    list: IFetchActions;
    update: IFetchActions;
    delete: IFetchActions;
    destroy: IFetchActions;
}

export const FETCH_RESOURCE_STATUS_TYPES = Object.freeze({
  request: 'REQUEST',
  success: 'SUCCESS',
  failure: 'FAILURE',
});

export const CRUD_ACTION_TYPES = Object.freeze({
  create:   'CREATE',
  get:      'GET',
  list:     'LIST',
  update:   'UPDATE',
  delete:   'DELETE',
  destroy:  'DESTROY',
});

export const CRUDActionCreate = (root: string, actionTypes: string[], actionCreator: ({}) => {}) =>
  actionCreate(STORE_ROOT_PREFIXES.resource, root, actionTypes, actionCreator);

export const CRUDStatusActions = (root: string, CRUDName: string): IFetchActions => ({
  request: CRUDActionCreate(root, [CRUDName, FETCH_RESOURCE_STATUS_TYPES.request], ({ params=null, data=null, uriParams=null } = {}) => ({
    data,
    params,
    uriParams,
    pending: true,
    fail: false,
    requestSucceed: null,
  })),
  success: CRUDActionCreate(root, [CRUDName, FETCH_RESOURCE_STATUS_TYPES.success], ({ result=null, pagination=null } = {}) => ({
    result,
    pagination,
    pending: false,
    fail: false,
    requestSucceed: true,
  })),
  failure: CRUDActionCreate(root, [CRUDName, FETCH_RESOURCE_STATUS_TYPES.failure], ({ error=null } = {}) => ({
    error,
    pending: false,
    fail: true,
    requestSucceed: false,
  }))
});

export const resourceActions = (root: string): IResourceActions => ({
    create  : CRUDStatusActions(root, CRUD_ACTION_TYPES.create),
    get     : CRUDStatusActions(root, CRUD_ACTION_TYPES.get),
    list    : CRUDStatusActions(root, CRUD_ACTION_TYPES.list),
    update  : CRUDStatusActions(root, CRUD_ACTION_TYPES.update),
    delete  : CRUDStatusActions(root, CRUD_ACTION_TYPES.delete),
    destroy : CRUDActionCreate(root, CRUD_ACTION_TYPES.destroy, () => null),
});
