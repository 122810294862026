import React, { useEffect } from 'react';
import {GridTable, GridTableCol} from "../../../../shared/components/GridTable";
import { connect } from 'react-redux';
import {jarStockResourceActions} from '../../../../agents/actions/jar-stock'
import {labsLoaderHOC} from '../../../../dictionary/components/labsLoaderHOC';
import {setJarStockRowClassByAmount} from '../../ReactionSpendingsDialog/SpendingsForm'
import { amountColorIndicators, AmountRemainsIndicatorEnum } from '../../../../agents/utils';
import styled from 'styled-components';


let ReactionUsedJars = (props) => {
  const _loadJars = () => {
    if (props.isProduct) {
      props.getProvidedJarsByReaction(props.agentId, props.reactionId);
    }
    else {
      props.getUsedJarsByReaction(props.agentId, props.reactionId);
    }
  };
  useEffect(_loadJars, [props.reactionId, props.isProduct]);

  return (<div>
    <GridTable
      className={props.className}
      hover={false}
      rowHeight={'54px'}
      items={props.substanceJars}
      excludeRows={item => item.Amount <= 0}
      colsTemplate={'100px max-content 100px minmax(100px, 150px) repeat(2, minmax(1fr, max-content))'}
      setRowClassNames={setJarStockRowClassByAmount}
    >
      <GridTableCol
        title={'№ Банки'}
        render={({ item }) => <span>{item.Number}</span>}
      />
      <GridTableCol
        title={'Производитель'}
        render={({ item }) => <span>{item.Provider}</span>}
      />
      <GridTableCol
        title={'Кол-во, г'}
        align='right'
        render={({ item }) => <span>{item.Amount}</span>}
      />
      <GridTableCol
        title={'Расположение'}
        render={({ item }) => <span>{props?.labs?.[item.LaboratoryId]?.Name}</span>}
      />
      <GridTableCol
        title={'Место'}
        render={({ item }) => <span>{item.Location}</span>}
      />
    </GridTable>
  </div>);
};

ReactionUsedJars = styled(ReactionUsedJars)`
  .${amountColorIndicators[AmountRemainsIndicatorEnum.Empty].className} > *
  {
    background-color: var(--red-o-15);
  }
  .${amountColorIndicators[AmountRemainsIndicatorEnum.Low].className} > *
  {
    background-color: var(--orange-o-5);
  }
  .${amountColorIndicators[AmountRemainsIndicatorEnum.Medium].className} > *
  {
    background-color: var(--yellow-o-5);
  }
  .${amountColorIndicators[AmountRemainsIndicatorEnum.Full].className} > *
  {
    background-color: var(--green-o-20);
  }
  .jar-amount-remains--ignore-spending > *
  {
    background-color: var(--green-o-40);
  }
`;

const mapStateToProps = (state) => ({
  substanceJars: state.modules.reaction.editReaction.substanceJars,
  reactionId: state.modules.reaction.editReaction.current.reactionId,
  labs: state.resource.dictionary.labs.data,
});

const mapDispatchToProps = dispatch => ({
  getUsedJarsByReaction: (agentId, reactionId) => dispatch(jarStockResourceActions.getUsedJarsByReaction.request({ params: { agentId, reactionId }})),
  getProvidedJarsByReaction: (agentId, reactionId) => dispatch(jarStockResourceActions.getProvidedJarsByReaction.request({ params: { agentId, reactionId }})),
});

ReactionUsedJars = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReactionUsedJars);

ReactionUsedJars = labsLoaderHOC(ReactionUsedJars);

export { ReactionUsedJars };
