/* tslint:disable */
import * as React from 'react';
import bnc from 'bnc';
import * as PropTypes from 'prop-types';

import './index.less';

type TitleWithCounterProps = {
    title      : string;
    count      : number;
    className? : string;
}

const
    block       = new bnc('b-TitleWithCounter'),
    blockName   = block.toString(),
    titleName   = block.el('title').toString(),
    counterName = block.el('counter').toString();

const TitleWithCounter = ({ title, count, className }: TitleWithCounterProps) => (
    <div className={`${blockName} ${className}`}>
        <h2 className={titleName}>{title}</h2>
        <div className={counterName}>{count}</div>
    </div>
);

TitleWithCounter.propTypes = {
    title: PropTypes.string.isRequired,
    count: PropTypes.number,
    className: PropTypes.string,
};

export {
    TitleWithCounter,
};
