// @flow

import React from 'react';
import type { IReactionEditDialogWrapComponentProps } from './ReactionEditDialogWrap';
import { ConfirmDialog } from '../../../shared/components';

interface IReactionDeleteConfirmProps extends IReactionEditDialogWrapComponentProps {
	deleteReaction: ()=>void
}

export const ReactionDeleteConfirm = (props: IReactionDeleteConfirmProps) => (
	<ConfirmDialog
		title={'Удалить реакцию?'}
		message={''}
		confirmLabel={'Ок'}
		declineLabel={'Отмена'}
		confirmAction={() => props.deleteReaction()}
		declineAction={() => props.closeDialog()}
	/>
);
