// @flow

import React, { useEffect } from 'react';
import styled from 'styled-components';
import type { IFieldComponentProps } from '../../../../../shared/models';
import { connect } from 'react-redux';
import type { IRootStore } from '../../../../../app/reducers';
import { Dispatch } from 'redux';
import type { TCurrentReactionStates } from '../../../../reducers';
import { getCurrentReactionStates } from '../../../../reducers';
import { GridTable, GridTableCol } from '../../../../../shared/components/GridTable';
import { ReactionKeyNames, ReactionYield } from '../../../../models';
import { ReactionYieldKeyNames } from '../../../../models/reaction-yield-key-names';
import { InlineEdit, inputTypesEnum } from '../../../../../shared/components/InlineEdit';
import { BcdCheckboxWrapper } from '../../../../../shared/components/BcdCheckboxWrapper';
import type { IReaction, IReactionYield } from '../../../../models';
import { getReactionFromForm } from '../../../../reducers/reaction-edit-form-plugin';
import Checkbox from '@biocad/bcd-front-ui/controls/Checkbox';
import { bncInstances } from '../../../../../shared/components/MultiValueInput';
import Button from '@biocad/bcd-front-ui/controls/Button';
import CommentWrapper from '../../../../../shared/components/CommentWrapper';
import { arrayPush, arrayRemove } from 'redux-form';
import { REACTION_EDIT_FORM_NAME } from '../../index';
import { reactionModuleActions } from '../../../../actions';
import { FieldValidators } from '../../../../../shared/validators/field-validators';


interface IReactionMultipleYieldsProps extends IFieldComponentProps {
	reactionState?: TCurrentReactionStates,
	reaction?: IReaction,
	className?: string,
	addReactionYield?: (IReactionYield)=>void,
	removeReactionYield?: (number)=>void,
	recalcReactionYields?: ()=>void
}

const fieldValidators = {
	TheoreticalYield        : [FieldValidators.required, FieldValidators.minNum(0), FieldValidators.stepNum(0.001)],
	ExperimentalYield       : [FieldValidators.required, FieldValidators.minNum(0), FieldValidators.stepNum(0.001)],
	IsSuccessful            : [FieldValidators.required],
};

const defaultProps: IReactionMultipleYieldsProps = {
	className: ''
};

// Helpful functions
const
	getLastIndexNumber = (yields: IReactionYield[] = []) => {
		const nums = yields.map((y: IReactionYield) => y.IndexNumber);
		const lastIdx = Math.max(...nums);
		return isFinite(lastIdx) ? lastIdx : 0;
	},
	filterNonDeletedYields = (item: IReactionYield) => (
		!item.IsDeleted
	)
;

let ReactionMultipleYields = (props: IReactionMultipleYieldsProps = defaultProps) => {

	// Vars
	const
		reactionYieldsLength = props.reaction?.ReactionYields.filter(filterNonDeletedYields).length,
		lastIndex = getLastIndexNumber(props.reaction?.ReactionYields.filter(filterNonDeletedYields))
	;

	// Effects
	const _addFirstReactionRowIfNon = () => {
		if (!reactionYieldsLength && props.reaction) {
			props.addReactionYield(ReactionYield({
				ExperimentalYield: props.reaction?.ExperimentalYield,
				ExperimentalPercentYield: props.reaction?.ExperimentalPercentYield,
				TheoreticalYield: props.reaction?.TheoreticalYield,
				ReactionId: props.reaction?.Id,
				IndexNumber: lastIndex+1
			}))
		}
	};
	useEffect(_addFirstReactionRowIfNon, [reactionYieldsLength, props.reaction]);

	return (
		<GridTable
			className={`${props.className}`}
			fieldArrayName={ReactionKeyNames.ReactionYields}
			colsTemplate={'40px repeat(3, 1fr) 36px 36px'}
			excludeRows={(item: IReactionYield) => item.IsDeleted}
			renderRowAfter={({ item }) => (
				item.IndexNumber === lastIndex
				? <>
						<span className={'reaction-yields-item'}>{ props.reaction?.TheoreticalYield }</span>
						<span className={'reaction-yields-item'}>{ props.reaction?.ExperimentalYield }</span>
						<span className={'reaction-yields-item'}>{ (props.reaction?.ExperimentalPercentYield*100).toFixed(2) }</span>
				  </>
				: ''
			)}
		>
			<GridTableCol
				fieldName={ReactionYieldKeyNames.ShouldCreateJar}
				render={({ meta, input }) => (
					<BcdCheckboxWrapper
						{...{ meta, input }}
						large={true}
						disabled={props.reactionState?.isReadonly || props.reaction?.IsCompleted}
					/>
				)}
			/>
			<GridTableCol
				title={'Теор.\nвыход, г'}
				fieldName={ReactionYieldKeyNames.TheoreticalYield}
				onChange={() => props.recalcReactionYields()}
				validate={fieldValidators.TheoreticalYield}
				render={({ meta, input }) => (
					<InlineEdit
						{...{ input, meta }}
						inputType={inputTypesEnum.InputNum}
						readonly={props.reactionState?.isReadonly}
					/>
				)}
			/>
			<GridTableCol
				title={'Эксп.\nвыход, г'}
				fieldName={ReactionYieldKeyNames.ExperimentalYield}
				validate={fieldValidators.ExperimentalYield}
				onChange={() => props.recalcReactionYields()}
				render={({ meta, input }) => (
					<InlineEdit
						{...{ input, meta }}
						inputType={inputTypesEnum.InputNum}
						readonly={props.reactionState?.isReadonly}
					/>
				)}
			/>
			<GridTableCol
				title={'Эксп.\nвыход, %'}
				render={({ item }) => (
					<span>{ (item.ExperimentalPercentYield*100).toFixed(2) }</span>
				)}
			/>
			<GridTableCol
				fieldName={ReactionYieldKeyNames.Comment}
				render={({ input, item }) => (
					<CommentWrapper
						item={{ Id: item.IndexNumber, QuickComment: item.Comment }}
						saveComment={({ comment }) => input?.onChange(comment)}
						iconClassName={'comment-btn'}
						readonly={props.reactionState?.isReadonly}
					/>
				)}
			/>
			<GridTableCol
				fieldName={ReactionYieldKeyNames.IsDeleted}
				title={() => (
					<Button
						className={`fo-plus`}
						view={'icon'}
						size={'L'}
						disabled={props.reactionState?.isReadonly}
						onAction={() => props.addReactionYield(ReactionYield({
							ReactionId: props.reaction?.Id,
							IndexNumber: getLastIndexNumber(props.reaction?.ReactionYields) + 1
						}))}
					/>
				)}
				render={({ input, item, rowidx }) => (
					reactionYieldsLength > 1
					? <Button
							className={`${bncInstances.bBtn} ${bncInstances.bBtnRemove} fo-cross-thick`}
							view={'icon'}
							size={'L'}
							disabled={props.reactionState?.isReadonly}
							onAction={() => {
								if ('Id' in item && item.Id) {
									input?.onChange(!item.IsDeleted)
								}
								else {
									props.removeReactionYield(rowidx)
								}
								props.recalcReactionYields();
							}}
						/>
					: ''
				)}
			/>
		</GridTable>
	)
};

ReactionMultipleYields = connect(
	(state: IRootStore): IReactionMultipleYieldsProps => ({
		reactionState: getCurrentReactionStates(state),
		reaction: getReactionFromForm(state)
	}),
	(dispatch: Dispatch): IReactionMultipleYieldsProps => ({
		addReactionYield: (value: IReactionYield) => dispatch(arrayPush(REACTION_EDIT_FORM_NAME, ReactionKeyNames.ReactionYields, value)),
		removeReactionYield: (index: number) => dispatch(arrayRemove(REACTION_EDIT_FORM_NAME, ReactionKeyNames.ReactionYields, index)),
		recalcReactionYields: () => dispatch(reactionModuleActions.recalc.reaction({ debounce: true }))
	})
)(ReactionMultipleYields);

ReactionMultipleYields = styled(ReactionMultipleYields)`
	grid-column: 1 / 4;
	height: max-content;
	overflow: initial !important;
	border-top: 0;
	border-bottom: 0;
	--table-row-head-height: auto;
	--table-cell-padding-h: 8px;
	.grid-table-row {
		&:first-child {
			padding-left: 0;
		}
		&:nth-last-child(3),
		&:nth-last-child(2) {
			padding: 0;
		}
	}
	.grid-table-row-heading:nth-child(6) {
		padding: 0;
		display: flex;
		align-items: center;
	}
	.${Checkbox.block} {
		margin: auto auto auto 0;
		width: 16px;
	}
	.${Button.block} {
		margin: 0;
		&:active {
			background-color: inherit;
			color: inherit;
		}
	}
	.grid-table-row-heading {
		white-space: pre-line;
		font-size: 12px;
	}
	.grid-table-row-after:not(:empty) {
		padding: 0;
		display: grid;
		grid-template-columns: inherit;
	}
	.reaction-yields-item {
		padding: var(--table-cell-padding-v) var(--table-cell-padding-h);
		font-weight: bold;
		&:first-child {
			grid-column: 2;
		}
	}
	.comment-btn {
		display: flex;
		height: 100%;
		&::before {
			margin: auto;
		}
	}
`;

export {
	ReactionMultipleYields,
	fieldValidators,
}
