// @flow
import { resourceActions, CRUDActionCreate } from '../../shared/utils/action-creators';
import { REACTION_INDICATORS_MODULE_NAME } from './module';

export const reactionIndicatorsResourceActions = {
    ...resourceActions(REACTION_INDICATORS_MODULE_NAME),
    batchAllActions: CRUDActionCreate(
        REACTION_INDICATORS_MODULE_NAME,
        ['BATCH_ALL_ACTIONS'],
        ({ parameters = [], reactionId }) => ({ parameters, reactionId })
    ),
    getForNewReaction: CRUDActionCreate(
        REACTION_INDICATORS_MODULE_NAME,
        ['GET_FOR_NEW_REACTION'],
        ({ params: { stageId }}) => ({ params: { stageId }})
    ),
};
