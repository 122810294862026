import React, {useState, useEffect} from 'react';
import styles from './icon-button-expand.m.less';
import './icon-button-expand.less';
import { IconButton } from '../IconButton';

export const IconButtonExpand = React.memo(({iconClassName, className, expanded, expandHandler, disabled, ...rest}) => {
  
  const [_expanded, _setExpanded] = useState(false);

  useEffect(() => {
    _setExpanded(expanded);
  }, [expanded]);
  
  const classNames = [
    'icon-button-expand-component',                     //Глобальный класс для доступа снаружи
    iconClassName ? iconClassName : 'fo-angle-right',    // По умолчанию иконка - галочка, но можно поменять на свою

    styles.iconButtonExpand,
    _expanded && `${styles.expanded} expanded`,
    className && className,
  ].join(' ');

  const toggleExpand = (event) => {
    event.stopPropagation();
    
    _setExpanded(prev => !prev);
    expandHandler && expandHandler(event);
  };

  return (
    <IconButton
      className={classNames}
      onClick={event => !disabled && toggleExpand(event)}
      disabled={disabled}
      {...rest}
    />
  );
});