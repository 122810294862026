// @flow

import {
  put,
  takeEvery,
  fork,
  select,
} from 'redux-saga/effects';
import { StageResourceService } from '../services/stages';
import { getToken } from '../../account/reducers';
import { stageModuleActions, stageResourceActions } from '../actions';
import type { IRequestResourceAction } from '../../shared/utils';
import type { IRootStore } from '../../app/reducers';
import { getCurrentStage, getInValidSubstances, listStageSubstancesSelector } from '../reducers';
import type { IObtainmentStage, IStageSubstance } from '../models';
import { CanonicalizerService } from '../../shared/services/compound/canonicalizer-service';
import type { ICanonicalizerResponse } from '../../shared/services/compound/canonicalizer-service';
import { globalErrorsActions, setGlobalLoader } from '../../shared/actions';
import { SchemeResourceService } from '../../schemes/services'
import {getProject} from '../../projects/sagas';

export function* getStage(action: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const result = yield StageResourceService.get({
      uriParams: action.uriParams,
    }, token);
    yield put(stageResourceActions.get.success(result));
  } catch (error) {
    yield put(stageResourceActions.get.failure({error}));
  }
}

export function* loadStageRelatedData(action: IRequestResourceAction)
{
  const token = yield select(getToken);
  try {
    yield getStage(action);
    const relatedEntities = yield StageResourceService.getRelatedEntities({
      uriParams: action.uriParams,
    }, token);
    yield getProject({ id: relatedEntities.result.ProjectId });
    yield put(stageModuleActions.setProjectId({ projectId: relatedEntities.result.ProjectId }));
  } catch (e) {

  }
}

function* handleSchemeUniting(schemeId: number) {
  const token = yield select(getToken);
  const uniteSchemeId = yield select(state => state.modules?.stage?.editStage?.uniteSchemeId);
  if(!uniteSchemeId) {
    return;
  }
  yield SchemeResourceService.unite({ uriParams: {sourceSchemeId: uniteSchemeId, targetSchemeId: schemeId}}, token);
}

function* updateStage({ uriParams, data }: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const stage: IObtainmentStage = yield select((store: IRootStore) => store.resource.stages.data[data.Id]);
    let result;
    if ((
            stage?.RequiredPurificationsCount !== data?.RequiredPurificationsCount
            || stage?.PurificationBundleType !== data?.PurificationBundleType)
        && stage.CanBeModifiedOrDeleted === false)
    {
      result = yield StageResourceService.updateStageWithIgnoreReactions({ uriParams, data }, token);
    }
    else {
      result = yield StageResourceService.update({ uriParams, data }, token);
    }
    yield handleSchemeUniting(data.ObtainmentSchemeId);
    yield put(stageResourceActions.update.success(result));
  } catch (error) {
    yield put(stageResourceActions.update.failure({error}));
  }
}

function* createStage({ data }: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const result = yield StageResourceService.create({ data }, token);
    yield handleSchemeUniting(data.ObtainmentSchemeId);
    yield put(stageResourceActions.create.success(result));
  } catch (error) {
    yield put(stageResourceActions.create.failure({error}));
  }
}

function* deleteStage({ uriParams }: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const result = yield StageResourceService.delete({ uriParams }, token);
    yield put(stageResourceActions.delete.success(result));
  } catch (error) {
    yield put(stageResourceActions.delete.failure({error}));
  }
}

function* updateBestReaction({ uriParams }: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const result = yield StageResourceService.updateBestReaction({ uriParams }, token);
    yield put(stageResourceActions.updateBestReaction.success(result));
  } catch (error) {
    yield put(stageResourceActions.updateBestReaction.failure({ error }));
  }
}

function* deleteBestReaction({ uriParams }: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const result = yield StageResourceService.deleteBestReaction({ uriParams }, token);
    yield put(stageResourceActions.updateBestReaction.success(result));
  } catch (error) {
    yield put(stageResourceActions.deleteBestReaction.failure({ error }));
  }
}

function* canonicalizeSubstancesAndProceed() {
  const
    token: string                 = yield select(getToken),
    substances: IStageSubstance[] = yield select(listStageSubstancesSelector),
    stage: IObtainmentStage       = yield select(getCurrentStage),
    isFirstStageInScheme: boolean = yield select((state: IRootStore) => state.modules.stage.editStage?.isFirstStageInScheme)
  ;
  if (!substances || !Array.isArray(substances) || !substances.length) return;
  yield put(setGlobalLoader({ isLoading: true }));
  const filteredSubstances: IStageSubstance[] = substances
    .filter((substance: IStageSubstance) => 'SmilesFormula' in substance && typeof substance.SmilesFormula === 'string' && substance.SmilesFormula?.length);
  try {
    for (let substance of filteredSubstances) {
      const res: { result: ICanonicalizerResponse } = yield CanonicalizerService.canonicalize({ params: { smiles: substance.SmilesFormula }}, token);
      yield put(stageModuleActions.updateCanonicalizeStatus({ position: substance.position, status: res.result }));
    }
    const invalidSubstances: IStageSubstance[] = yield select(getInValidSubstances);
    if (invalidSubstances && invalidSubstances.length) {
      yield put(globalErrorsActions.set({
        error: `Обнаружены ошибки в следующих веществах, участвующих в стадии:\n ${invalidSubstances.map((s: IStageSubstance) => s.position === substances.length ? 'Продукт' : `Реактант ${s.position}`).join(', ')}`
      }));
    }
    else {
      if (isFirstStageInScheme) {
        yield put(stageModuleActions.preSaveScheme());
        return;
      }
      yield put(stageModuleActions.preSaveStage({ stage }));
    }
  }
  catch (error) {
    yield put(globalErrorsActions.set({ error }));
  }
  finally {
    yield put(setGlobalLoader({ isLoading: false }));
  }
}

export const stageWatchers = [
  function* () {
    yield takeEvery(stageResourceActions.get.request.type, function*(action) {
      yield fork(getStage, action);
    });
  },

  function* () {
    yield takeEvery(stageResourceActions.update.request.type, function*(action) {
      yield fork(updateStage, action);
    });
  },

  function* () {
    yield takeEvery(stageResourceActions.create.request.type, function*(action) {
      yield fork(createStage, action);
    });
  },

  function* () {
    yield takeEvery(stageResourceActions.delete.request.type, function*(action) {
      yield fork(deleteStage, action);
    });
  },

  function* () {
    yield takeEvery(stageResourceActions.updateBestReaction.request.type, function* (action) {
      yield fork(updateBestReaction, action);
    });
  },

  function* () {
    yield takeEvery(stageResourceActions.deleteBestReaction.request.type, function* (action) {
      yield fork(deleteBestReaction, action);
    })
  },

  function* () {
    yield takeEvery(stageModuleActions.canonicalizeSubstances.type, function* () {
      yield fork(canonicalizeSubstancesAndProceed);
    })
  },

  function* () {
    yield takeEvery(stageModuleActions.loadStageRelatedData.type, function* (action) {
      yield fork(loadStageRelatedData, action);
    })
  },
];
