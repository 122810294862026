import {
    apiRequestSingle,
} from '../../shared/services/api/common';

export const BIMS_ROOT = '/bims';

export class BimsService {
  constructor(token) {
      const headers = token ? {
          Authorization: token
      } : {};

      this._getTestStatuses = apiRequestSingle('GET', `${BIMS_ROOT}/testStatuses`, headers);
      this._getSmTypes = apiRequestSingle('GET', `${BIMS_ROOT}/smTypes`, headers);
      this._getTestClasses = apiRequestSingle('GET', `${BIMS_ROOT}/testClasses`, headers);
  }

  
  getTestStatuses() {
      return this._getTestStatuses({});
  }

  getSmTypes() {
      return this._getSmTypes({});
  }

  getTestClasses() {
      return this._getTestClasses({});
  }
}