// @flow

export const CatalogReactionKeysEnum = Object.freeze({
  Id: 'Id',
  CandidateId: 'CandidateId',
  CreationDate: 'CreationDate',
  ExperimentalYield: 'ExperimentalYield',
  IsCompleted: 'IsCompleted',
  IsTrial: 'IsTrial',
  Name: 'Name',
  PercentYield: 'PercentYield',
  Products: 'Products',
  ProjectId: 'ProjectId',
  Reagents: 'Reagents',
  SchemeId: 'SchemeId',
  StageId: 'StageId',
  TheoreticalYield: 'TheoreticalYield',
  Author: 'Author',
  IsArchive: 'IsArchive',
  ReactionType: 'ReactionType',
  CompleteDate: 'CompleteDate',
  IsSuccessful: 'IsSuccessful',
  QuickComment: 'QuickComment',
  AdditionalName: 'AdditionalName',
});
