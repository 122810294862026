// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".icon-button-expand-m__iconButtonExpand___nEr1c {\n  transition: transform 0.1s linear;\n}\n.icon-button-expand-m__iconButtonExpand___nEr1c.icon-button-expand-m__expanded___HMMgO {\n  transform: rotate(90deg);\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconButtonExpand": "icon-button-expand-m__iconButtonExpand___nEr1c",
	"expanded": "icon-button-expand-m__expanded___HMMgO"
};
export default ___CSS_LOADER_EXPORT___;
