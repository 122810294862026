// @flow

import {resourceReducer, setResourceFailState, setResourceRequestState, setResourceSuccess} from '../../shared/utils';
import {
  agentResourceActions,
  agentModuleActions,
  aliasesResourceActions,
  tagsResourceActions,
  jarStockResourceActions,
  jarSpendingResourceActions,
} from '../actions';
import { IAgent } from '../models/i-agent';
import type { IPagination } from '../../shared/models';
import type { IJarStock, IJarStockSpendings } from '../models';
import actions from "redux-form/lib/actions";


export interface IAgentModuleStore {
  currentAgent: {
    details: IAgent;
    jarStock: IJarStock[];
    jarSpent: number[];
    newAgentResponse: {
      newAgentId: number;
      agentCreated: boolean;
      jarStockCreated: boolean;
      aliasesCreated: boolean;
    }
  },
  list: {
    pagination: IPagination,
    commentUpdateRequestStatus: ?boolean,
    agentSearchMWLimits: { min: number, max: number }
  },
  analytics: {
    agentFormula: string | null,
    jarName: string | null,
  },
}

const initialPagination: IPagination = {
  page: 1,
  pageCount: 1,
  pageSize: null,
  total: null,
};

export const initialAgentModuleState: IAgentModuleStore = {
  currentAgent: {
    details: {
      Compound: {}
    },
    jarStock: [],
    jarSpent: [],
    newAgentResponse: {
      newAgentId: null,
      agentCreated: null,
      aliasesCreated: null,
      jarStockCreated: null,
    }
  },
  list: {
    pagination: initialPagination,
    commentUpdateRequestStatus: null,
    agentSearchMWLimits: { min: 0, max: 1000 }
  },
  analytics: {
    agentFormula: null,
    jarName: null,
  },
};

export const agentResourceReducer = resourceReducer(agentResourceActions, {
  [agentResourceActions.getMWLimits.success]: (state, action) => setResourceSuccess(state, action),
  [agentResourceActions.getMWLimits.request]: (state, action) => setResourceFailState(state, action),
  [agentResourceActions.getMWLimits.failure]: (state, action) => setResourceFailState(state, action),
});

export const agentModuleReducer = (state: IAgentModuleStore = initialAgentModuleState, action = {}): IAgentModuleStore => {
  const reducedFn = {
    [agentModuleActions.compoundFromMarvin]: () => ({
      ...state,
      currentAgent: {
        ...state.currentAgent,
        details: {
          ...state.currentAgent.details,
          ...action.result,
          Compound: {
            ...state.currentAgent.details.Compound,
            ...action.result,
          }
        }
      }
    }),
    [agentModuleActions.destroyCurrent]: () => ({
      ...state,
      currentAgent: initialAgentModuleState.currentAgent,
    }),
    [agentResourceActions.get.success]: () => ({
      ...state,
      currentAgent: {
        ...state.currentAgent,
        details: {
          ...state.currentAgent.details,
          ...action.result,
          Aliases: state.currentAgent.details.Aliases,
          Tags: action.result.Tags.map(tag => ({ value: tag.Id, label: tag.Name })),
        },
      },
    }),
    [agentResourceActions.create.success]: () => ({
      ...state,
      currentAgent: {
        ...state.currentAgent,
        newAgentResponse: {
          newAgentId: action.result.Id,
          agentCreated: true,
        }
      }
    }),
    [agentResourceActions.update.success]: () => ({
      ...state,
      currentAgent: {
        ...state.currentAgent,
        details: {
          ...state.currentAgent.details,
          ...action.result,
          Tags: action.result.Tags.map(tag => ({ value: tag.Id, label: tag.Name })),
        }
      }
    }),    
    [aliasesResourceActions.get.success]: () => ({
      ...state,
      currentAgent: {
        ...state.currentAgent,
        details: {
          ...state.currentAgent.details,
          Aliases: action.result.map(alias => ({ value: alias.Id, label: alias.Name })),
        }
      },
    }),
    [aliasesResourceActions.create.success]: () => ({
      ...state,
      currentAgent: {
        ...state.currentAgent,
        newAgentResponse: {
          ...state.currentAgent.newAgentResponse,
          aliasesCreated: true,
        }
      },
    }),
    [agentResourceActions.list.success]: () => ({
      ...state,
      list: {
        ...state.list,
        pagination: { ...state.list.pagination, ...action.pagination },
      }
    }),
    [jarStockResourceActions.list.success]: () => ({
      ...state,
      currentAgent: {
        ...state.currentAgent,
        jarStock: action.result,
      }
    }),
    [jarStockResourceActions.update.success]: () => ({
      ...state,
    }),
    [jarSpendingResourceActions.create.success]: (action) => ({
      ...state,
      currentAgent: {
        ...state.currentAgent,
        jarSpent: [ ...state.currentAgent.jarSpent, action.result.AgentAvailabilityId ],
        jarStock: state.currentAgent.jarStock.map((jar: IJarStock) => ({
          ...jar,
          Amount:
            //- Изменяем только ту банку, которая пришла из action'а, остальные не изменяем
            //- Изменяем "Доступно", только в случае, если у нас отключен "Неснижаемый остаток" 
            action?.result?.AgentAvailabilityId === jar.Id && !state?.currentAgent?.details?.IsIgnoringSpending 
              ? (jar.Amount >= action.result.Amount ? jar.Amount - action.result.Amount : 0) //Записываем в "Доступно" разницу со списанным количеством, но не допускаем отрицательных величин          
              : (jar.Amount),
          SpendingsCount:
            action?.result?.AgentAvailabilityId === jar.Id 
            ? (jar.SpendingsCount + 1)          
            : (jar.SpendingsCount),
        }))
      }
    }),
    [agentModuleActions.setCommentUpdateRequestStatus]: (action: { status: boolean }): IAgentModuleStore => ({
      ...state,
      list: {
        ...state.list,
        commentUpdateRequestStatus: action?.status
      }
    }),
    [agentModuleActions.setMWLimits]: ({ limits }) => ({
      ...state,
      list: {
        ...state.list,
        agentSearchMWLimits: {
          min: limits[0] > 1 ? limits[0] - 1 : 0,
          max: limits[1] + 1
        }
      }
    })
    
  }[action.type];
  return reducedFn ? reducedFn(action) : state;
};
