// @flow
import React, { useEffect } from 'react';
import './scheme-details.less';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Dialog from '@biocad/bcd-front-ui/layout/Dialog';
import type { IObtainmentScheme } from '../../models';
import Button from '@biocad/bcd-front-ui/controls/Button';
import {
  Field,
  reduxForm,
  isValid,
  getFormValues,
  change
} from 'redux-form';
import {
  BcdInputWrapper,
} from '../../../shared/components';
import { SchemeView } from '../../models';
import { FieldValidators } from '../../../shared/validators/field-validators'
import { schemeViewSelector, schemeSelector } from '../../reducers';
import { schemesResourceActions } from '../../actions';
import { setGlobalLoader } from '../../../shared/actions/loading';
import ParentsTasks from '../../../shared/components/ParentsTasks';
import {projectTypeEnum} from '../../../projects/types/enum/ProjectTypeEnum';
import { candidatesResourceActions } from '../../../candidates/actions';
import {getRepkaTaskLinks} from '../../../shared/components/ParentsTasks/utils';

export const SCHEME_DETAILS_FORM_NAME = "SchemeDetails";

interface ISchemeDetailsProps{
  onClose: () => void,
  onSave: () => void,
  updateScheme: () => void,
  createScheme: () => void,
  change: () => void,
  updateParentsTasksList: (value) => void,
  checkTask: (repkaTaskId) => void,
  projectType: Boolean;
  formValid: Boolean,
  scheme: IObtainmentScheme,
  formValues: IObtainmentScheme,
  history: {},
  schemeSavedId: Number,
  candidateId: Number,
  folderPath: {},
}

const FinalCandidateIdKey = 'FinalCandidateId';
const FolderIdKey = 'FolderId';
const RepkaTaskLinksKey = 'RepkaTaskLinks';

const CodeKeyName = 'Code';
const SchemeDetailsValidators: { [key: string]: Array<(value: any)=>?Error> } = {
	[CodeKeyName]                : [FieldValidators.required, FieldValidators.maxStringLength(40)]
};

let SchemeDetailsDialog = (props: ISchemeDetailsProps) => {
  const initScheme = () => {
    if(isNewScheme()) {
      props.change(FinalCandidateIdKey, Number(props.candidateId));
      
      const folderPathlength = props.folderPath?.length;
      if(folderPathlength) {
        props.change(FolderIdKey, Number(props.folderPath[folderPathlength - 1].id));
      }
    }
  };
  useEffect(initScheme, [props.scheme]);

  const schemeSavedHandler = () => {
    if(props.schemeSavedId) {
      props.setLoader(false);
      props.onSave();
      props.onClose();
    }
  };
  useEffect(schemeSavedHandler, [props.schemeSavedId]);

  function isNewScheme() {
    return !(props.scheme?.Id);
  }

  function saveScheme() {
    props.setLoader(true);
    if(isNewScheme()) {
      props.createScheme(props.formValues);
    } else {
      props.updateScheme(props.formValues);
    }
  }

  function prepareTaskListUpdate(value) {
    const links = getRepkaTaskLinks(value, props.scheme);
    props.updateParentsTasksList(links);
  }

  function isValid() {
    return props.formValid &&
      (Number(props.projectType) !== projectTypeEnum.generic 
        || props?.formValues?.RepkaTaskLinks?.length);
  }

  return(
    <>
      <Dialog paranjaClose={false} close={props.onClose}>
        <header className={'dialog-header'}>
          <h3>{isNewScheme() ? 'Создать' : 'Редактировать'} схему</h3>
        </header>
        <div className={'dialog-contents'}>
          <Field
            name={CodeKeyName}
            component={BcdInputWrapper}
            label={'Код'}
            useFormFieldLayout={true}
            placeholder={'Введите код схемы'}
            validate={SchemeDetailsValidators[CodeKeyName]}
          />
          {Number(props.projectType) === projectTypeEnum.generic &&
          <ParentsTasks
              item={props.scheme}
              isNewItem={isNewScheme()}
              updateParentsTasksList={prepareTaskListUpdate}
              checkTask={props.checkTask}
              checkParentTaskStatus={props.checkParentsTask}
              checkPending={props.checkParentsTaskPending}
          />
          }
        </div>
        <footer className={'dialog-footer'}>
          <Button
            text={isNewScheme() ? 'Создать' : 'Изменить'}
            view='primary'
            size={'L'}
            onAction={saveScheme}
            disabled={!isValid()}
          />
          <Button
            text='Отмена'
            size={'L'}
            onAction={props.onClose}
          />
        </footer>
      </Dialog>
    </>
  );
};

SchemeDetailsDialog = reduxForm({
  form: SCHEME_DETAILS_FORM_NAME,
  destroyOnUnmount: true,
})(SchemeDetailsDialog);
SchemeDetailsDialog = withRouter(SchemeDetailsDialog);
SchemeDetailsDialog = connect(
  (state) => ({ 
    formValid: isValid(SCHEME_DETAILS_FORM_NAME)(state),
    scheme: schemeSelector(state),
    formValues: getFormValues(SCHEME_DETAILS_FORM_NAME)(state),
    initialValues: schemeSelector(state),
    schemeSavedId: state?.modules?.scheme?.editScheme?.schemeSavedId,
    candidateId: state?.modules?.candidate?.candidate?.Id,
    folderPath: state?.modules?.folder?.schemesPath,
    checkParentsTask: state.modules.candidate.checkParentsTask,
    checkParentsTaskPending: !state.modules.candidate.checkParentsTask.pending && state.modules.candidate.checkParentsTask.response,
  }),
  (dispatch) => ({
    updateScheme: (data: IObtainmentScheme) => dispatch(schemesResourceActions.update.request({ data })),
    createScheme: (data: IObtainmentScheme) => dispatch(schemesResourceActions.create.request({ data })),
    setLoader: (isLoading) => dispatch(setGlobalLoader({ isLoading })),
    updateParentsTasksList: (value) => dispatch(change(SCHEME_DETAILS_FORM_NAME, RepkaTaskLinksKey, value)),
    checkTask: (repkaTaskId) => dispatch(candidatesResourceActions.checkRepkaTaskExists.request({params: repkaTaskId})),
  })
)(SchemeDetailsDialog);

export {
  SchemeDetailsDialog
};
