import ActionList from '@biocad/bcd-front-ui/controls/ActionList';
import Button from '@biocad/bcd-front-ui/controls/Button';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm } from 'redux-form';
import CommentWrapper from '../../../../shared/components/CommentWrapper';
import { JarStockKeysEnum } from '../../../models';
import JarStockFileUploader from './JarStockFileUploader';
import { jarStockTableCols } from './jarStockTableCols';
import { JarStockFormStyled } from './style';
import type { IJarStock } from '../../../models';
import {userCRUDPermissionsSelector} from '../../../../account/reducers/users-modules-reducer';
import classNames from 'classnames';
import { getAmountRemainsIndicator } from '../../../utils';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import {AGENT_EDIT_FORM_NAME} from '../EditForm';


export const AGENT_JAR_STOCK_EDIT_FORM_NAME = 'agentJarStock';
export const AGENT_JAR_STOCK_ARRAY_FIELD_NAME = 'stock';


const MoreActionsList = ({ copyHandler, deleteHandler, uploaderHandler, analyticsHandler, itemHasId, CanBeDeleted, canHandleFiles, canHandleAnalytics}) => {
  return(
      (CanBeDeleted || canHandleFiles || canHandleAnalytics) &&
      <ActionList.Click align='right'>
          <ActionList.Button view={'icon'} className={'fo-more-h'} size="L"/>
          { (itemHasId && canHandleFiles) && <ActionList.Action action={uploaderHandler}>Управление файлами</ActionList.Action> }
          { (itemHasId && canHandleAnalytics) && <ActionList.Action action={analyticsHandler}>Аналитика</ActionList.Action> }
          { <ActionList.Action action={copyHandler}>Копировать</ActionList.Action> }
          { (CanBeDeleted || !itemHasId) && <ActionList.Action action={deleteHandler}>Удалить</ActionList.Action> }
      </ActionList.Click>
  );
};

const renderJarStockFormRows = ({
    labsList = [],
    fields = [],
    openSpendingsDialog = () => null,
    openRemoveJarStockRowDialog = () => null,
    onAnalyticsClick = () => null,
    onCopyClick = () => null,
    hideEmptyJars = true,
    userPermissions = {},
    jarSpent = [],
  }) => {

    // Вся часть изменения данных должна быть перенесена в редьюсеры при переработке компонента

    const [currentAvailabilityIdx, setCurrentAvailabilityIdx] = useState(null);
    const dispatch = useDispatch();
    const clearCurrentAvailabilityIdx = () => setCurrentAvailabilityIdx(null);
    const saveCommentHandler = ({itemId, comment, close}) => {
      let idx;
      const jars = fields.getAll();

      const jarStock = jars.find((item, index) => {
        if (item.Id === itemId) {
          idx = index;
          return item
        }
      });

      fields.splice(idx, 1, { ...jarStock, QuickComment: comment });
      close();
    };

    const handleFieldBlur = (value: number, fieldName: string, field: string, item: IJarStock) =>
    {
        if (fieldName.endsWith(`.${JarStockKeysEnum.Amount}`) && (item.PackageWeight === "" || item.PackageWeight === null))
        {
            dispatch(
                change(
                    AGENT_JAR_STOCK_EDIT_FORM_NAME,
                    `${field}.${JarStockKeysEnum.PackageWeight}`,
                    value
            ));
        }
    };

    const saveFilesHandler = ({ Attachments }) => {
      const value = fields.get(currentAvailabilityIdx);
      fields.splice(currentAvailabilityIdx, 1, {
        ...value,
        Attachments: [...value.Attachments, ...Attachments]
      });
    };

    const deleteFileHandler = (fileIndex) => {
      const value = fields.get(currentAvailabilityIdx);
      const notDeleted = [ ...value.Attachments.filter(x => !x.IsDeleted)];
      const deleted = [ ...value.Attachments.filter(x => x.IsDeleted)];
      fields.splice(currentAvailabilityIdx, 1, {
        ...value, 
        Attachments: [
          ...notDeleted.map((x, index) => {
            if (index === fileIndex){
              x.IsDeleted = true;
            }
            return x;
          }),
          ...deleted
        ]
      });
    };

    const isFieldReadonly = (column, item) => {
      return (column.mappingProp === JarStockKeysEnum.Amount && !!item.Id && item.SpendingsCount);
    };

    return (
      <>
      {
        fields.map((field, idx, _fields) => {
          const
            currentItem: IJarStock = _fields.get(idx),
            showSpendingsBtn       = !!currentItem.Id && userPermissions?.jarStockSpendings.CREATE,
            showCommentBtn         = !!currentItem.Id && (userPermissions?.jarStockMiniComment.READ),
            itemHasId              = !!currentItem.Id,
            CanBeDeleted           = currentItem.CanBeDeleted && userPermissions?.jarStock.DELETE && !jarSpent.includes(currentItem.Id);

          if (currentItem?.Id && currentItem?.Amount <= 0 && hideEmptyJars) {
            return undefined;
          }

          return (
            <div className={classNames('table-rowWrap', getAmountRemainsIndicator(currentItem)?.className)}
                 key={field}
            >
                {
                    jarStockTableCols.map((col, colIdx) => {
                        const mappingPropKeyValue = Object.entries(currentItem).find(entry => entry[0] === col.mappingProp);
                        const mappingValue = (mappingPropKeyValue && mappingPropKeyValue[1]) || '';
                        const formatValue = (value) =>
                            value && col.getLabelById
                              ? { value, label:  col.getLabelById(value, labsList, 'Name') }
                              : value;
                        return (
                          col.mappingProp &&
                            <div className={`table-row ${(col.className || '')}`}
                                 key={`${field}.${col.mappingProp}.${colIdx}`}>
                                <Field
                                  name={`${field}.${col.mappingProp}`}
                                  component={col.renderChild}
                                  item={currentItem}
                                  format={formatValue}
                                  onBlur={(_, value, __, fieldName) => handleFieldBlur(value, fieldName, field, currentItem)}
                                  validate={!isFieldReadonly(col, currentItem) ? col.validate : null}
                                  visibleValue={col.getLabelById && col.getLabelById(mappingValue, labsList, 'Name')}
                                  options={col.getLabelById && labsList.map(l => ({ value: l.Id, label: l.Name }))}
                                  readonly={isFieldReadonly(col, currentItem) || !(userPermissions?.jarStock.CREATE || userPermissions?.jarStock.UPDATE)}
                                />
                            </div>
                        );
                    })
                }
                <div className={'buttons-wrap'}>
                  {
                    showSpendingsBtn &&
                    <Button
                      className={'action-btn fo-chem'}
                      onAction={() => openSpendingsDialog(currentItem.Id)}
                      view={'icon'}
                      size={'L'}
                    />
                  }
                  {
                    showCommentBtn &&
                    <CommentWrapper
                      item={fields.get(idx)}
                      itemId={idx}
                      saveComment={saveCommentHandler}
                      readonly={!userPermissions?.jarStockMiniComment.UPDATE}
                    />
                  }
                  <MoreActionsList 
                    deleteHandler={() => openRemoveJarStockRowDialog(idx)}
                    analyticsHandler={e => onAnalyticsClick(e, currentItem.Id)}
                    copyHandler={() => onCopyClick(currentItem)}
                    uploaderHandler={() => setCurrentAvailabilityIdx(idx)}
                    itemHasId={itemHasId}
                    CanBeDeleted={CanBeDeleted}
                    canHandleAnalytics={(userPermissions.agentAnalytics.CREATE || userPermissions.agentAnalytics.UPDATE || userPermissions.agentAnalytics.DELETE)}
                    canHandleFiles={(userPermissions.agentFileList.READ)}
                  />
                </div>
            </div>
          );
        })
      }
      {
        currentAvailabilityIdx !== null &&
          <JarStockFileUploader
            item={fields.get(currentAvailabilityIdx)}
            index={currentAvailabilityIdx}
            saveFilesHandler={saveFilesHandler}
            deleteHandler={deleteFileHandler}
            handleClose={clearCurrentAvailabilityIdx}
            canDeleteFiles={userPermissions.agentFileList.DELETE}
            canUploadFiles={userPermissions.agentFileList.CREATE}
            viewMode={null}
          />
      }
      </>
    );
    
};

let JarStockForm = ({
                          labsList = [],
                          openSpendingsDialog = () => null,
                          openRemoveJarStockRowDialog = () => null,
                          onAnalyticsClick = () => null,
                          onCopyClick = () => null,
                          hideEmptyJars = true,
                          userPermissions = {},
                          jarSpent = [],
}) => {
  return (
      <div>
          <JarStockFormStyled onSubmit={event => event.preventDefault()}>
              <div className={'table-rowWrap'}>
                  {
                      jarStockTableCols.map(col => (
                          <div className={`table-rowHeading ${(col.className || '')}`} key={col.title}>
                              <span>{col.title}</span>
                          </div>
                      ))
                  }
                  <div className='table-rowHeading' />
              </div>
              <FieldArray name={AGENT_JAR_STOCK_ARRAY_FIELD_NAME}
                          component={renderJarStockFormRows}
                          labsList={labsList}
                          openSpendingsDialog={openSpendingsDialog}
                          openRemoveJarStockRowDialog={openRemoveJarStockRowDialog}
                          onAnalyticsClick={onAnalyticsClick}
                          onCopyClick={onCopyClick}
                          hideEmptyJars={hideEmptyJars}
                          userPermissions={userPermissions}
                          jarSpent={jarSpent}
              />
          </JarStockFormStyled>
      </div>
  );
};

const validate = (values) => {
    if (!values || !values[AGENT_JAR_STOCK_ARRAY_FIELD_NAME] || !values[AGENT_JAR_STOCK_ARRAY_FIELD_NAME].length) return;
    const errors = {[AGENT_JAR_STOCK_ARRAY_FIELD_NAME]: []};
    errors[AGENT_JAR_STOCK_ARRAY_FIELD_NAME] = values[AGENT_JAR_STOCK_ARRAY_FIELD_NAME].map((row) => {
        const { PackageWeight, Amount } = row;
        const rowErrors = Object.keys(row).reduce((acc, cur) => {
            return Object.assign(acc, { [cur]: undefined });
        }, {});
        if (PackageWeight && Amount) {
            rowErrors.Amount = Amount > PackageWeight ? 'Значение поля Доступно не может превышать значение поля Фасовка' : undefined;
        }
        return rowErrors;
    });
    return errors;
};


const mapStateToProps = (state) => ({
    userPermissions: userCRUDPermissionsSelector(state),
    jarSpent: state?.modules?.agent?.currentAgent?.jarSpent || [],
});

const mapDispatchToProps = {
  
};


JarStockForm = reduxForm({
    form: AGENT_JAR_STOCK_EDIT_FORM_NAME,
    destroyOnUnmount: true, // Важно сохранять состояние формы, так как при сохранении компонент удаляется, но состояние требуется
    enableReinitialize: true,
    touchOnChange: true,
    validate,
})(JarStockForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JarStockForm);
