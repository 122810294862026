import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Dialog from '@biocad/bcd-front-ui/layout/Dialog';
import Button from '@biocad/bcd-front-ui/controls/Button';
import {DialogLayout} from '../../../../stages/components/StageEdit/StageSubstanceEdit/MergeableSchemesDialog/style';
import {_noop} from '../../../../shared/utils/common';
import type {ICompound} from '../../../../agents/models';
import styled from 'styled-components';
import {
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import {
  reactionsResourceActions,
  reactionModuleActions,
  reactionCatalogResourceActions,
  purificationReactionCatalogResourceActions
} from '../../../actions';
import {copyToClipboard} from '../../../../shared/utils/copyToClipboard';
import type {IRootStore} from '../../../../app/reducers';
import type {IReaction} from '../../../models';
import {ReactionTypeIcon} from '../../../../shared/components/ReactionTypeIcon';
import Loader from '@biocad/bcd-front-ui/controls/Loader';
import {userCRUDPermissionsSelector} from '../../../../account/reducers/users-modules-reducer';
import { Link } from 'react-router-dom';
import {getPurificationReactionCatalog} from '../../../reducers';

import {ReactionUsedJars} from './reaction-used-jars'

interface IReactionPurificationModalProps {
  onCancel: Function,
  compound: ICompound,
  getListPurification?: Function,
  history?: {
    push?: Function
  },
  listPurification?: IReaction[],
  purificationPendingStatus?: boolean,
  userPermissions?: {},
  agentId?: number,
  clearPurificationList?: Function,
  skipReactionResourceClear?: boolean,
  pending?: boolean,
  showReactionJars?: boolean,
  isProduct?: boolean,
}

const initialProps = {
  onCancel: _noop,
  compound: null,
  getListPurification: _noop,
  history: {
    push: _noop
  },
  listPurification: [],
  purificationPendingStatus: false,
  skipReactionResourceClear: false,
  isProduct: false,
  showReactionJars: false,
};

const StyledDialogLayout = styled(DialogLayout)`
  width: 800px;
  .go-to-agent-btn {
    text-decoration: none !important;
  }
  .agent-area.short {
    max-height: 64%;
  }
  .agent-area {
    grid-auto-rows: auto;
    
    .sub-img {
      position: relative;
    }
    
    .copy-smiles-btn {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-100%);
    }
  
    .reactions-list-container {
      border-left: 1px solid var(--table-border-color);
      overflow: auto;
      
      ul {
        list-style-type: none;
        padding: 0 16px;
        
        .reaction-item,
        li {
          height: 48px;
          line-height: 48px;
          border-bottom: 1px solid var(--table-border-color);
          font-size: 14px;
          color: inherit !important;
          text-decoration: none;
          
          &:hover {
            cursor: pointer;
            background: var(--hover-bg-color);
          }
          
          &:first-child {
            font-size: 16px;
            
            &:hover {
              cursor: auto;
              background: inherit;
            }
          }
          
          .b-button {
            float: right;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            font-size: 18px;
          }
          
          .typeIcon {
            top: 50%;
            transform: translateY(-90%);
            line-height: normal;
          }
        }
      }
      .reaction-item {
        display: list-item;
      }
    }
  
  }
  
`;
let ReactionPurificationModal = (props: IReactionPurificationModalProps = initialProps) => {

  // Effects
  useEffect(() => {
    props.getListPurification({
      SmilesFormula: props.compound?.SmilesFormula,
      CompoundName: props.compound?.Name,
      limit: 9999, // Вывести оч много
    });

    return () => {
      props.clearPurificationList();
    };
  }, []);

  // Methods
  const
    clickBlock = (e) => {
      e.stopPropagation();
      e.preventDefault();
    },
    copySmiles = () => {
      copyToClipboard(props.compound?.SmilesFormula);
    },
    goToCreateReaction = () => {
      props.history.push({
        pathname: '/reload',
        state: { 
          url: props.compound?.SmilesFormula
                ? `/reactions/add?isPurification=true&smilesFormulaEncoded=${encodeURIComponent(props.compound?.SmilesFormula)}` 
                : `/reactions/add?isPurification=true&compoundName=${encodeURIComponent(props.compound?.Name)}`
          }
      })
    },
    goToEditAgent = () => {
      if (props.agentId)
      {
        props.history.push(`/agents/${props.agentId}`);
      }
    },
    openNewTab = (event: MouseEvent, reactionId: number) =>
    {
      event.preventDefault();
      switch (event.button)
      {
        case 0:
          props.history.push({ pathname: '/reload', state: { url: `/reactions/${reactionId}` } });
          break;
        case 1:
          window.open(`#/reload?url=/reactions/${reactionId}`, '_blank');
          break;
        default:
          return;
      }
    }
  ;

  // Styles
  const subImgStyle = {
    background: 'url(' + props.compound?.Thumbnail + ') center 20% no-repeat',
    backgroundSize: 'contain'
  };

  return (
    <Dialog
      paranjaClose={false}
      close={props.onCancel}
    >
      <StyledDialogLayout className={'dialog-layout'} onClick={clickBlock}>

        <header className={'dialog-header'}>
          <h3>{props.compound?.MolecularFormula}</h3>
        </header>

        <div className={`dialog-contents agent-area ${props.showReactionJars && 'short'}`}>
          <div className={'sub-img'} style={subImgStyle}/>

          <div className={'reactions-list-container'}>
            <ul>
              <li>
                <span>Очистка субстанции</span>
                {
                  props.userPermissions.reaction.CREATE &&
                  <Button
                      icon={faPlus}
                      onAction={goToCreateReaction}
                  />
                }
              </li>
              {
                (props.purificationPendingStatus || props.pending) ?
                  <Loader/> :
                  <>
                    {
                      props.listPurification.map((purification: IReaction, index: number) => {
                        return (
                          <a key={index}
                             onMouseUp={(event) => openNewTab(event, purification.Id)}
                             className={'reaction-item'}
                          >
                            <ReactionTypeIcon reaction={purification}/>
                            <span>{purification.Name}</span>
                          </a>
                        );
                      })
                    }
                  </>
              }
            </ul>
          </div>
        </div>
        { props.showReactionJars && <ReactionUsedJars agentId={props.agentId} isProduct={props.isProduct} /> }
        <footer className={'dialog-footer'}>
          {
            props.compound?.SmilesFormula &&
            <Button
              text={'Копировать SMILES'}
              onAction={copySmiles}
              size={'L'}
            />
          }
          {
            props.agentId &&
            <Link
                to={`/agents/${props.agentId}`}
                className={'b-button b-button-secondary b-button-size-L go-to-agent-btn'}
            >
              Редактировать агента
            </Link>
          }
        </footer>

      </StyledDialogLayout>
    </Dialog>
  );
};

ReactionPurificationModal = connect(
  (state: IRootStore): IReactionPurificationModalProps => ({
    listPurification: getPurificationReactionCatalog(state),
    purificationPendingStatus: state.resource.purificationReactionCatalog.pending,
    userPermissions: userCRUDPermissionsSelector(state),
  }),
  (dispatch) => ({
    getListPurification: (params) => dispatch(purificationReactionCatalogResourceActions.list.request({params})),
    clearPurificationList: () => dispatch(purificationReactionCatalogResourceActions.destroy()),
  })
)(ReactionPurificationModal);

ReactionPurificationModal = withRouter(ReactionPurificationModal);

export {
  ReactionPurificationModal
};
