// @flow
import { attachmentsActions } from '../actions';

export const initialAttachmentModuleState = {
  uploadedFiles: []
};

export const attachmentsReducer = (state = initialAttachmentModuleState, action = {}) => {
  const reducedFn = {
    [attachmentsActions.upload.success]: () => {
      return {
        ...state,
        uploadedFiles: action.files.map(f => ({...f, Tag: action.tag}))
      };
    },
    [attachmentsActions.upload.failure]: () => {
      return {
        ...state,
        ...action.error,
      };
    },
    [attachmentsActions.upload.destroy]: () => {
      return {
        ...state,
        uploadedFiles: []
      };
    },
    
    [attachmentsActions.download.request]: () => {
      return {
        ...state,
      };
    },
    [attachmentsActions.download.failure]: () => {
      return {
        ...state,
        ...action.error,
      };
    },
  }[action.type];
  return reducedFn ? reducedFn() : state;
};
