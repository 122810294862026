import {getEnum} from '../../../shared/utils';

export const folderParentType = getEnum({
    root: 0,
    folder: 1,
    project: 2,
    finalCandidate: 3,
    scheme: 4,
    stage: 5
});
