// @flow
import React, { useEffect } from 'react';
import { Field, reduxForm, Form, getFormValues, reset, change } from 'redux-form';
import Button from '@biocad/bcd-front-ui/controls/Button';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import {
  BcdInputWrapper,
  BcdDropdownWrapper,
  BcdCheckboxWrapper,
  MarvinSketch,
} from '../../../../shared/components';
import type { OptionType } from '../../../../shared/models/option-type';
import { AgentSearchFormLayout } from './style';
import { connect } from 'react-redux';
import { amountColorIndicators, AmountRemainsIndicatorEnum } from '../../../utils';
import { itemsToArraySelector } from '../../../../shared/utils/selectors/resource-selectors';
import { RangeSlider } from "../../../../shared/components/RangeSlider";
import { BcdSuggestWrapper } from '../../../../shared/components';
import {agentResourceActions} from "../../../actions";
import type {IRootStore} from "../../../../app/reducers";
import { tagsResourceActions } from '../../../actions/tags';
import { labsResourceActions } from '../../../../dictionary/actions'

export const SearchOptionsEnum = Object.freeze({
  ByName: 'ByName',
  ByStructure: 'ByStructure',
  ByCas: 'ByCas',
  ByComment: 'ByComment',
});

interface ISearchFormValues {
  search: string,
  searchType: string,
  isStrictSearch: boolean,
  isInStockOnly: boolean,
  isPrecursor: boolean,
  isAntibody: boolean,
  isPolymer: boolean,
  isIgnoringSpending: boolean,
  isReagentOrdered: boolean,
  laboratoryId: number,
  isInitialyLoaded: boolean
}

export const agentSearchInitialValues: ISearchFormValues = {
  search: '',
  searchType: SearchOptionsEnum.ByName,
  isStrictSearch: false,
  isInStockOnly: false,
  isPrecursor: false,
  isAntibody: false,
  isPolymer: false,
  isIgnoringSpending: false,
  isReagentOrdered: false,
  laboratoryId: null,
  [amountColorIndicators[AmountRemainsIndicatorEnum.Low].searchValue]: true,
  [amountColorIndicators[AmountRemainsIndicatorEnum.Medium].searchValue]: true,
  [amountColorIndicators[AmountRemainsIndicatorEnum.Full].searchValue]: true,
  agentMWLimits: [0, 200]
};

const dropdownOptions: OptionType[] = [
  { value: SearchOptionsEnum.ByName, label: 'По наименованию' },
  { value: SearchOptionsEnum.ByStructure, label: 'По формуле' },
  { value: SearchOptionsEnum.ByCas, label: 'По CAS-номеру' },
  { value: SearchOptionsEnum.ByComment, label: 'По комментарию' },
];

const AGENT_SEARCH_FORM_NAME = 'agentSearchForm';

const formatSearchTypeOptions = (value, name) => ({
  ...dropdownOptions.find(i => i.value === value)
});

const formatMrv = (value, name) => ({
  SmilesFormula: value,
});

const renderMarvinSketchField = ({ input, className, radicalMode }) => (
  <React.Fragment>
    <MarvinSketch
      handleChange={event => input.onChange(event ? event.CxSmilesFormula : null)}
      className={className}
      radicalMode={radicalMode}
    />
  </React.Fragment>
);

let SearchForm = ({
                    handleSubmit, searchType, labsList,
                    resetForm, changeSearchType, className,
                    getMWLimits, mwLimits, isInStockOnly, tagsList, loadTags, getLabs, changeSearch
}) => {
  if (isInStockOnly == true)
    agentSearchInitialValues.isInStockOnly = isInStockOnly;

  const labOptions = [{ value: null, label: 'Все лаборатории' }].concat(labsList);
  const formatLabOptions = (value) => labOptions.find(i => i.value === value);

  useEffect(() => {
    getLabs();
    getMWLimits();
    loadTags();
  }, []);

  const _resetFormExceptSearchType = () => {
    resetForm();
    changeSearchType(searchType);
  };
  useEffect(_resetFormExceptSearchType, [searchType]);

  const typeChanged = (e) => {
    changeSearch(null);
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); 
      handleSubmit(new Event("submit"));
    }
  }

  return (
    <Form onSubmit={handleSubmit} onKeyDown={handleKeyDown} className={className}>
      <AgentSearchFormLayout className={`searchType-${searchType}`}>
        <Field
          name="searchType"
          component={BcdDropdownWrapper}
          options={dropdownOptions}
          className={'searchOption'}
          format={formatSearchTypeOptions}
          onChange={typeChanged}
        />
        {
          searchType && searchType === SearchOptionsEnum.ByStructure ?
            <>
              <Field
                name={'isStrictSearch'}
                component={BcdCheckboxWrapper}
                label={'Точное совпадение'}
                className={'isStrictSearch'}
                large={true}
              />
              <Field name={'search'}
                component={renderMarvinSketchField}
                format={formatMrv}
                className={'searchField'}
                radicalMode={true}
              />
            </>
            :
            <Field name={'search'}
              component={BcdInputWrapper}
              placeholder={'Поиск вещества'}
              icon={faSearch}
              className={'searchField'}
            />
        }
        <Field
          name={'isInStockOnly'}
          component={BcdCheckboxWrapper}
          label={'Есть в наличии'}
          className={'isInStockOnly'}
          large={true}
        />
        <Button
          className={'submitBtn'}
          text="Найти"
          view="primary"
          size="L"
          type={'submit'}
        />
        <Field
          name="laboratoryId"
          component={BcdDropdownWrapper}
          options={labOptions}
          className={'labDropdown'}
          format={formatLabOptions}
        /> 
        <div className={'checkboxes'}>
          <Field
            name={'isIgnoringSpending'}
            component={BcdCheckboxWrapper}
            label={'Неснижаемый остаток'}
            large={true}
          />
          <Field
            name={'isReagentOrdered'}
            component={BcdCheckboxWrapper}
            label={'Реактив заказан'}
            large={true}
          />
          <Field
            name={'isPrecursor'}
            component={BcdCheckboxWrapper}
            label={'Прекурсор'}
            large={true}
          />
          <Field
              name={'isFinalCandidate'}
              component={BcdCheckboxWrapper}
              label={'Целевая молекула'}
              large={true}
          />
          <Field
            name={'isCasSpecified'}
            component={BcdCheckboxWrapper}
            label={'CAS указан'}
            large={true}
          />
          {
            searchType !== SearchOptionsEnum.ByStructure &&
            <>
              <Field
                name={'isAntibody'}
                component={BcdCheckboxWrapper}
                label={'Антитело'}
                large={true}
              />
              <Field
                name={'isPolymer'}
                component={BcdCheckboxWrapper}
                label={'Полимер'}
                large={true}
              />
            </>
          }
        </div>
        <div className={'jar-amount-remains'}>
          <div className={'jar-amount-remains-inner'}>
            <label className={'jar-amount-remains-label'}>Остаток: </label>
            <Field
              name={amountColorIndicators[AmountRemainsIndicatorEnum.Low].searchValue}
              component={BcdCheckboxWrapper}
              label={'Вещ-ва мало'}
              large
            />
            <Field
              name={amountColorIndicators[AmountRemainsIndicatorEnum.Medium].searchValue}
              component={BcdCheckboxWrapper}
              label={'Вещ-ва достаточно'}
              large
            />
            <Field
              name={amountColorIndicators[AmountRemainsIndicatorEnum.Full].searchValue}
              component={BcdCheckboxWrapper}
              label={'Вещ-ва много'}
              large
            />
          </div>
          <label className={'jar-amount-remains-tags-label'}>Теги: </label>
          <Field
            name={'tags'}
            markPristineValidity
            component={BcdSuggestWrapper}
            multiple={true}
            options={tagsList}
            format={(value) => value === null ? [] : value}
            placeholder={'Выберите теги'}
            useFormFieldLayout
          />
        </div>
        <div className={"range-slider"}>
          <Field
            label={"MW min/max"}
            name={'agentMWLimits'}
            component={RangeSlider}
            min={mwLimits?.min || 0}
            max={mwLimits?.max || 0}
            step={1}
          />
        </div>        
      </AgentSearchFormLayout>
    </Form>
  );
};


SearchForm = reduxForm({
  form: AGENT_SEARCH_FORM_NAME,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(SearchForm);

SearchForm = connect(
  (state: IRootStore) => ({
    searchType: ((state) => {
      const values = getFormValues(AGENT_SEARCH_FORM_NAME)(state);
      if (values) {
        return values.searchType || '';
      }
    })(state),
    labsList: itemsToArraySelector(state.resource.dictionary.labs).map(lab => ({ value: lab.Id, label: lab.Name })),
    tagsList: itemsToArraySelector(state.resource.dictionary.tags).map(tag => ({ value: tag.Id, label: tag.Name })),
    mwLimits: state.modules.agent.list.agentSearchMWLimits,    
    initialValues: {
      ...agentSearchInitialValues,
      agentMWLimits: [state.modules.agent.list.agentSearchMWLimits.min, state.modules.agent.list.agentSearchMWLimits.max]
    }
  }),
  (dispatch) => ({
    resetForm: () => dispatch(reset(AGENT_SEARCH_FORM_NAME)),
    changeSearch: (value) => dispatch(change(AGENT_SEARCH_FORM_NAME, 'search', value)),
    changeSearchType: (value) => dispatch(change(AGENT_SEARCH_FORM_NAME, 'searchType', value)),
    getMWLimits: () => dispatch(agentResourceActions.getMWLimits.request({})),
    loadTags: () => dispatch(tagsResourceActions.list.request({})),
    getLabs: () => dispatch(labsResourceActions.list.request({}))
  })
)(SearchForm);

export {
  SearchForm,
  AGENT_SEARCH_FORM_NAME,
};
