// @flow
import React from 'react';
import styled from 'styled-components';
import Dropdown from '@biocad/bcd-front-ui/controls/Dropdown';

export const StageSubstanceEditLayout = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  .header {
     padding: calc(var(--grid-spacer) * 1.5);
     flex-basis: 64px;
     display: flex;
     justify-content: space-between;
     align-items: center;
     min-height: 84px;
     .${Dropdown.block} {
        max-width: 300px;
        flex-basis: 300px;
     }
  }
  .edit-content {
    display: flex;
    flex: 1 0 auto;
  }
  .thumbnail {
    display: block;
    margin: auto;
    width: 300px;
    &-empty {
      width: auto;
    }
  }
  .selected-substance-formula {
    color: var(--grey-600);
    display: inline-block;
    margin-left: 4px;
    font-size: 18px;
    &::before { content: '(' }
    &::after  { content: ')' }
  }
  .marvin-is-hidden {
    opacity: 0;
    z-index: -1;
    width: 0; height: 0;
  }
`;
