import React from 'react';
import {ConfirmDialog} from '../ConfirmDialog/ConfirmDialog';
import {connect} from 'react-redux';
import {clearErrors, ClearErrorsAction} from '../../actions';
import {copyToClipboard} from '../../utils';
import { globalErrorsActions } from '../../actions';

interface AlertMessageProps {
    error: Array<Object>,
    clearErrors: () => ClearErrorsAction
}

const initialProps = {
    error: [],
    clearErrors
};

const AlertMessage = (props: AlertMessageProps = initialProps) => {

    const copyErrorsText = () => {
        let textForCopy = props.error.map(error => error.errorMessage).join('; ');
        copyToClipboard(textForCopy);
    };
    return (
        <React.Fragment>
            {props.error.length ?
            <ConfirmDialog
                title={'Сообщение об ' + (props.error.length === 1 ? 'ошибке' : 'ошибках')}
                message={props.error}
                confirmLabel={'Закрыть'}
                otherLabel={'Копировать текст ошибки'}
                otherAction={copyErrorsText}
                confirmAction={props.clearErrors}
                declineAction={props.clearErrors}
                type={'error'}
            /> : ''}
        </React.Fragment>
    );
};

const mapStateToProps = ({ global }) => {
    return {
        error: global.application.error
    };
};

export default connect(
    mapStateToProps,
    {
        clearErrors: globalErrorsActions.destroy
    }
)(AlertMessage);


