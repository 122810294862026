// @flow

import { Model } from 'objectmodel';
import type { ResourceRequestMethodType } from '../../utils/service-creator';
import { HTTPMethodEnum, ResourceService } from '../../utils/service-creator';

export interface ICanonicalizerResponse {
	IsValid: boolean,
	Message: string,
}

export interface ICanonicalizerRequest {
	smiles: string,
}

export const CanonicalizerResponse: (ICanonicalizerResponse)=>ICanonicalizerResponse = Model({
	IsValid: Boolean,
	Message: [String]
})
.defaultTo({
	IsValid: true,
	Message: ''
});

export const CanonicalizerRequest: (ICanonicalizerRequest)=>ICanonicalizerRequest = Model({
	smiles: String
})
.defaultTo({
	smiles: ''
});

interface ICanonicalizerService {
	canonicalize: ResourceRequestMethodType,
}

export const CanonicalizerService: ICanonicalizerService = ResourceService({
	baseURL: '/canonicalizer',
	requests: {
		canonicalize: {
			url: '',
			paramsModel: CanonicalizerRequest,
			model: CanonicalizerResponse,
			method: HTTPMethodEnum.GET
		}
	}
});
