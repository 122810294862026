// @flow

import {
  resourceActions,
} from '../../shared/utils';


export const JAR_SPENDING_ROOT_NAME = 'JAR_SPENDINGS';
export const jarSpendingResourceActions = {
  ...resourceActions(JAR_SPENDING_ROOT_NAME),
};