// @flow

import {
    resourceActions,
} from '../../shared/utils';


export const SMALL_MOLECULES_REACTION_ROOT_NAME = 'SMALL_MOLECULES_REACTION';
export const smallMoleculesReactionResourceActions = {
    ...resourceActions(SMALL_MOLECULES_REACTION_ROOT_NAME),
};
