// @flow
import {
  moduleAction,
  resourceActions,
  CRUDStatusActions
} from '../../shared/utils';

export const PROJECT_MODULE_NAME = 'PROJECTS';

export const projectActions = {
  ...resourceActions(PROJECT_MODULE_NAME),
  getAll: CRUDStatusActions(PROJECT_MODULE_NAME, 'GET_ALL'),
  getMaxOrder: CRUDStatusActions(PROJECT_MODULE_NAME, 'GET_MAX_ORDER'),
  getProjectByCandidateId: CRUDStatusActions(PROJECT_MODULE_NAME, 'GET_PROJECT_OF_SCHEME'),
};

const SET = 'SET';

export const projectModuleActions = {
  setCurrentProjectId: moduleAction(
    PROJECT_MODULE_NAME,
    ['PROJECT_ID', SET],
    id => id
  ),
  setEditMode: moduleAction(
    PROJECT_MODULE_NAME,
    ['EDIT_MODE', SET],
    isEditMode => isEditMode
  ),
  setCurrentProjectType: moduleAction(
    PROJECT_MODULE_NAME,
    ['PROJECT_TYPE', SET],
    ptype => ptype
  ),
  destroyPagination: moduleAction(
    PROJECT_MODULE_NAME,
    ['PAGINATION', 'DESTROY'],
    () => ({})
  ),
  destroyModule: moduleAction(PROJECT_MODULE_NAME, ['DESTROY'], () => ({})),
  clearMaxOrder: moduleAction(
    PROJECT_MODULE_NAME,
    ['CLEAR_MAX_ORDER'],
    () => null
  )
};
