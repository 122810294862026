// @flow
import React, {useEffect} from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import List from './List';
import View from './Edit';
import { connect } from 'react-redux';
import AvailabilityAnalytics from './Edit/AvailabilityAnalytics';
import { labsResourceActions } from '../../dictionary/actions';

const Agents = ({ match, getLabs}) => {
    useEffect(() => {
        getLabs();
    }, []);

    return (
        <Switch>
            <Route path={`${match.path}/list`} render={() => <List />} />
            <Route path={`${match.path}/analytics/:id`} render={(props) => <AvailabilityAnalytics {...props} />} />
            <Route path={`${match.path}/:agentId`} render={(props) => <View {...props} />} />
        </Switch>
    );
};

const mapStateToProps = (state) => ({
    
  });

const mapDispatchToProps = dispatch => ({
  getLabs: () => dispatch(labsResourceActions.list.request({})),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Agents)
);
