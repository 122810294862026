// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  smallMoleculesAvailabilityResourceActions,
  smallMoleculesReactionResourceActions,
  AnalyticsModuleActions
} from '../actions';
import { SmallMoleculeTable } from './SmallMoleculeTable';
import Loader from '@biocad/bcd-front-ui/controls/Loader';
import { smEntityTypes } from '../models/analytics-entity.interface';
import { IAnalyticsEntity } from '../models';
import { globalErrorsActions } from '../../shared/actions';
import { useQueryParams } from '../../shared/react-hooks/useQueryParams';

const Analytics = ({entity, noHeader = false, match, location, readonly = false, preventDataLoading = false, ...state}: {
  entity: IAnalyticsEntity,
  noHeader: boolean,
  match: Object,
  location: Object,
  state: any,
  readonly: boolean,
}) => {
  
  const {
    molecules,
    getMoleculesAvailability,
    getMoleculesReaction,
    analyticsReaction,
    analyticsAvailability,
    setMolecules,
    setEntity,
    destroyAnalyticsModule,
    throwError,
    pending,
  } = state;


  const [_entity, set_entity] = useState(undefined);
  const queryParams = useQueryParams(location.search);

  const setEntityFromPropsAndLocation = () => {
    if (entity === undefined && (queryParams?.type === null || queryParams?.id === null)) {
      set_entity(null);
      throwError('Аналитика: cущность не установлена');
    } 

    if (entity || (queryParams?.type && queryParams?.id)) {
      set_entity(entity || {type: queryParams?.type, id: queryParams?.id});
    }
  };

  const loadMolecules = () => {
    if (_entity) {
      setEntity({entity: {..._entity}});
      if (preventDataLoading) return;
      if(_entity.id) {
        _entity.type === smEntityTypes.Reaction
          ? getMoleculesReaction(_entity.id)
          : getMoleculesAvailability(_entity.id);
      }
    }
  };

  const setMoleculesFromState = () => {
    if (!_entity) {
      return;
    }
    if (_entity?.type === smEntityTypes.Reaction && analyticsReaction) {
      setMolecules(Object.values(analyticsReaction));
    } else 
    if (_entity?.type === smEntityTypes.AgentAvailability && analyticsAvailability) {
      setMolecules(Object.values(analyticsAvailability));
    }
  };

  const _destroy = () => {
    destroyAnalyticsModule();
  };

  useEffect(() => {
    return () => _destroy();
  }, []);
  
  useEffect(setEntityFromPropsAndLocation, [queryParams?.type, queryParams?.id, entity?.id]);
  useEffect(loadMolecules, [_entity]);
  useEffect(setMoleculesFromState, [_entity, analyticsReaction, analyticsAvailability]);


  return (
    <>
      {
        pending && <Loader/>
      }
      { 
        _entity
          ? molecules && <SmallMoleculeTable readonly={readonly} noHeader={noHeader} />
          : null
      }
    </>
  );
};

const mapStateToProps = ({ resource, modules }) => {
  return {
    analyticsAvailability: resource?.analytics?.smallMoleculesAvailability?.data,
    analyticsReaction: resource?.analytics?.smallMoleculesReaction?.data,
    molecules: modules.analytics.molecules,
    pending: modules.analytics.pending
  };
};

const mapDispatchToProps = dispatch => ({
  getMoleculesAvailability: id => dispatch(smallMoleculesAvailabilityResourceActions.list.request({ params: { id } })),
  getMoleculesReaction: id => dispatch(smallMoleculesReactionResourceActions.list.request({ params: { id } })),

  setEntity: ({entity}) => dispatch(AnalyticsModuleActions.setEntity({entity})),
  setMolecules: (molecules) => dispatch(AnalyticsModuleActions.setMolecules({molecules})),
  
  destroyAnalyticsModule: () => dispatch(AnalyticsModuleActions.destroyAnalytics()),
  throwError: (error) => dispatch(globalErrorsActions.set({error})),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Analytics)
);
