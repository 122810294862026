// @flow

import {
  resourceActions,
  moduleAction, CRUDStatusActions,
} from '../../shared/utils';
import type {ICompound} from '../models/i-agent';

export const AGENT_ACTIONS_ROOT = 'AGENT';

export const agentResourceActions = {
  ...resourceActions(AGENT_ACTIONS_ROOT),
  editAgentComment: CRUDStatusActions(AGENT_ACTIONS_ROOT, 'EDIT_AGENT_COMMENT'),
  getBySmiles: CRUDStatusActions(AGENT_ACTIONS_ROOT, 'AGENT_BY_SMILES'),
  getMWLimits: CRUDStatusActions(AGENT_ACTIONS_ROOT, 'GET_MW_LIMITS'),
};

export const agentModuleActions = {
  compoundFromMarvin: moduleAction(
    AGENT_ACTIONS_ROOT,
    ['COMPOUND_FROM_MARVIN'],
    ({ result }: {result: ICompound}) => ({ result })),
  destroyCurrent: moduleAction(
    AGENT_ACTIONS_ROOT,
    ['DESTROY_CURRENT'],
    () => null,
  ),
  setCommentUpdateRequestStatus: moduleAction(
    AGENT_ACTIONS_ROOT,
    ['SET_COMMENT_UPDATE_REQUEST_STATUS'],
    ({ status }: { status: boolean }) => ({ status })
  ),
  setMWLimits: moduleAction(
      AGENT_ACTIONS_ROOT,
      ['SET_MW_LIMITS'],
      ({ limits }): ({ limits: Array<number> }) => ({ limits })
  )
};
