// @flow

import { ObjectModel } from 'objectmodel';
import { CompoundFormula } from '../../shared/models';

export const MergedStage = ObjectModel({
  Id: [Number],
  AgentId: [Number],
  CustomStageCompoundId: [Number],
  Formula: [CompoundFormula],
  IsDeleted: Boolean,
  Name: [String],
  SourceSchemeId: [Number],
  TargetStageId: [Number],
});
