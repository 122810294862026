import { Component }   from 'react';
import bnc             from 'bnc';
import Input           from '../Input';
import Tooltip         from '../../layout/Tooltip';
import { faCalendar }  from '@fortawesome/free-solid-svg-icons/faCalendar';
import propTypes       from 'prop-types';
import DayPicker, {ModifiersUtils} from 'react-day-picker';
import                      'react-day-picker/lib/style.css';
import localeRu        from './ru.js';
import                      './index.less';

const isValidDate = date => date === void(0) || !Number.isNaN(date.valueOf());

export const defaultModifiers = {weekend: { daysOfWeek: [0,6] }};

export default class Datepicker extends Component {

    static propTypes = {
        onChange:  propTypes.func.isRequired,
        initDate:  propTypes.instanceOf(Date),
        date:      propTypes.instanceOf(Date),
        disabled:  propTypes.bool,
        className: propTypes.oneOfType([propTypes.string, propTypes.instanceOf(bnc)]),
        modifiers: propTypes.objectOf(
            /* see http://react-day-picker.js.org/docs/matching-days */
            propTypes.oneOfType([
                propTypes.func,
                propTypes.instanceOf(Date),
                propTypes.shape({
                    daysOfWeek: propTypes.arrayOf(propTypes.number),
                }),
                propTypes.shape({ after: propTypes.instanceOf(Date) }),
                propTypes.shape({ before: propTypes.instanceOf(Date) }),
                propTypes.shape({
                    after: propTypes.instanceOf(Date),
                    before: propTypes.instanceOf(Date),
                }),
                propTypes.shape({
                    from: propTypes.instanceOf(Date),
                    to: propTypes.instanceOf(Date),
                }),
            ])),
    };

    static getDerivedStateFromProps = ({date:propsDate, initDate}, {date:stateDate, prevDate}) => {
        if ( isValidDate(propsDate) || isValidDate(initDate) ) {
            let date;
            if ( propsDate !== void(0) || propsDate !== prevDate ) {
                if ( isValidDate(propsDate) ) {
                    date = propsDate;
                } else {
                    throw Error('Datepicker: prop date is invalid');
                }
            } else if ( stateDate !== void(0) ) {
                date = stateDate;
            } else if ( initDate !== void(0) ) {
                if ( isValidDate(initDate) ) {
                    date = initDate;
                } else {
                    throw Error('Datepicker: prop initDate is invalid');
                }
            }
            return { date, prevDate:date };
        } else {
            throw Error('Datepicker: invalid props');
        }
    };

    static block = new bnc.default('b-datepicker')

    state = {
        today:  new Date(),
        date:   void(0),
        opened: false,
        valid:  void(0)
    }

    callOnChange = (date) => {
        const mods = {};
        ModifiersUtils
            .getModifiersForDay(date, this.props.modifiers)
            .forEach(
                (mod) => {mods[mod] = true;}
            );
        this.props.onChange(date, mods);
    }

    setOpened = opened => () => this.setState({opened})

    inputFormat = val => val ? (new Date(val)).toLocaleDateString(localeRu.locale) : ''

    getInput = () => (
        this.input               && ('current' in this.input) &&
        this.input.current       && ('input'   in this.input.current) &&
        this.input.current.input && ('current' in this.input.current.input)
            ? this.input.current.input.current
            : null
    )

    onDayClick = (value) => {
        this.setState(
            {opened: false},
            () => {
                this.input.current.input.current.blur();
                if (this.props.date) {
                    this.callOnChange(value); // same as this.props.onChange(value, modifiers);
                } else if (this.input.current) {
                    this.setState(
                        {date: value, valid:void(0)},
                        () => {
                            this.input.current.setValue( this.inputFormat(value) );
                        }
                    );
                }
            }
        );
    }

    DayPickerProps = () => ({
        fixedWeeks: true,
        ...localeRu,
    })

    wrapper = React.createRef()

    renderTooltip = ({date, today}, {align = 'left', modifiers = defaultModifiers}) =>
        <Tooltip
            className = { Datepicker.block.el('tooltip') }
            onClose   = { this.setOpened(false) }
            align     = { align }
            transparent
            notail
        >
            <div ref={ this.wrapper } onBlur={this.onBlurWrapper}>
                <DayPicker
                    month        = { date || today }
                    selectedDays = {[date]}
                    onDayClick   = {this.onDayClick}
                    modifiers    = {modifiers}
                    { ...this.DayPickerProps() }
                />
            </div>
        </Tooltip>

    onClick = () => {
        const {input:{current:{input:{current: input}}}} = this;
        input && (input.blur(), input.focus());
    }

    onChange = () => {
        const input = this.getInput();
        const value = input.value.trim();

        if (value) {
            const values = value.match( localeRu.regexp );
            if (values) {
                const [,d,m,y] = values;
                const date     = new Date( y, m-1, d );
                if (this.props.date) {
                    this.setState(
                        { valid:true },
                        () => {
                            this.callOnChange(date);
                        }
                    );
                } else {
                    this.setState({
                        valid:true,
                        date
                    });
                }
            } else {
                this.setState({
                    valid:false
                });
            }
        } else {
            this.setState({
                valid: void(0),
                date:  void(0)
            });
        }
    }

    onBlurInput = ({relatedTarget}) => {
        const {wrapper:{current:wrapper}} = this;
        const close = !wrapper || !wrapper.contains(relatedTarget);
        close && this.setOpened(false)();
        close && this.inputRevalidate();
    }

    onBlurWrapper = ({relatedTarget}) => {
        const {
            wrapper:{current:wrapper},
            input:  {current:{input:{current: input}}}
        } = this;

        const close = !((wrapper && wrapper.contains(relatedTarget)) || (relatedTarget === input));
        close && this.setOpened(false)();
        close && this.inputRevalidate();
    }

    inputRevalidate = () =>
        setTimeout(
            () => {
                const input = this.getInput();
                const value = input.value.trim();
                this.setState({
                    valid: value
                        ? localeRu.regexp.test(value)
                            ? void(0)
                            : false
                        : void(0)
                });
            }
        )
    ;

    componentDidUpdate (prevProps, {date: prevDate}) {
        const {date, opened} = this.state;

        if (prevDate === void(0) && date === void(0)) {
            void(0);
        } else if (prevDate === void(0) && date !== void(0)) {
            this.callOnChange(date);
        } else if (prevDate !== void(0) && date === void(0)) {
            this.callOnChange(date);
        } else if (prevDate.valueOf() !== date.valueOf()) {
            this.callOnChange(date);
        }

        if (!opened) {
            this.input.current.setValue( this.inputFormat( date ) );
        }
    }

    input = React.createRef()

    renderDatepicker = ({opened, date, valid}, {disabled, className = ''}) =>
        <div
            className = {Datepicker.block + className}
            onClick   = {this.onClick}
        >
            <Input.Icon
                type         = 'text'
                ref          = {this.input}
                onChange     = {this.onChange}
                onFocus      = {this.setOpened(true)}
                onBlur       = {this.onBlurInput}
                placeholder  = {localeRu.placeholder}
                icon         = {faCalendar}
                valid        = {valid}
                defaultValue = {date ? this.inputFormat(date) : ''}
                disabled     = {disabled}
            />
            {
                !!opened &&
                this.renderTooltip(this.state, this.props)
            }
        </div>

    render  = () => this.renderDatepicker( this.state, this.props )
}
