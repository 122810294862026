import {
    apiRequestList,
    apiRequestSingle
} from '../../shared/services/api/common';
const SchemeLimit = 1024;

export class ProductionSchemeService {

    constructor(token) {
        this._token = token;
        const headers = token ? {Authorization: token} : {};
        this._getList = apiRequestList('GET', '/schemes', headers);
        this._editComment = (schemeId) => apiRequestList('PUT', `/schemes/${schemeId}/quick_comment`, headers);
        this._editIsFavorite = (schemeId) => apiRequestList('PUT', `/schemes/${schemeId}/is_favorite`, headers);
    }

    getList(params) {
        return this._getList({params: {limit: SchemeLimit, ...params,}});
    }

    editComment(schemeId, data) {
        return this._editComment(schemeId)({data});
    }

    editIsFavorite(schemeId, data) {
        return this._editIsFavorite(schemeId)({data});
    }

}