// @flow
import { Model, ArrayModel } from 'objectmodel';

export interface IReactionSummary {
    StageId: number;
    TotalAmount: number;
    NonFinishedAmount: number;
    WithExpYieldAmount: number;
    WithoutExpYieldAmount: number;
    TrialIncompleteAmount: number;
    TrialSuccessfulAmount: number;
    TrialFailedAmount: number;
};

export interface ISchemeView {
    SchemeId?: number;
    Code?: string;
    Number: number;

    Stages: IStageView[];
}

export interface IStageView {
    StageId?: number;
    TreeNumber?: number;
    ExpectedReactionTypeId?: number;

    Formula: string;
    Thumbnail: string;
    MolecularFormula: string;
    SmilesFormula: string;

    ReactionSummary: IReactionSummary;

    MergedSchemes: ISchemeView;

    RequiredPurificationsCount?: number;
}

export const ReactionSummary = Model({
    StageId: Number,
    TotalAmount: Number,
    NonFinishedAmount: Number,
    WithExpYieldAmount: Number,
    WithoutExpYieldAmount: Number,
    TrialIncompleteAmount: Number,
    TrialSuccessfulAmount: Number,
    TrialFailedAmount: Number,
});

export const SchemeView = Model({
    SchemeId: [Number],
    Code: [String],
    Number: Number,

    Stages: ArrayModel([Object]),
});

export const StageView = Model({
    StageId: [Number],
    TreeNumber: [Number],
    ExpectedReactionTypeId: [Number],

    Formula: [String, null],
    Thumbnail: String,
    MolecularFormula: [String, null],
    SmilesFormula: [String, null],

    ReactionSummary: [ReactionSummary],

    MergedSchemes: ArrayModel([SchemeView]),

    RequiredPurificationsCount: [Number],
});
