// @flow

import {ResourceService, CRUDRequestsMap, HTTPMethodEnum} from '../../shared/utils/service-creator';
import {Reagent} from '../model';
import type {ResourceRequestMethodType} from '../../shared/utils/service-creator';
import {ObtainmentScheme} from '../../schemes/models';
import {SingleRequestURIParams} from '../../shared/models';

interface IReagentsService {
    get: ResourceRequestMethodType,
    update: ResourceRequestMethodType,
    create: ResourceRequestMethodType,
    delete: ResourceRequestMethodType,
    editReagentComment: ResourceRequestMethodType
}

export const ReagentsResourceService: IReagentsService = ResourceService({
    baseURL: '/reagents',
    defaultModel: Reagent,
    useDefaultCRUDRequests: [
        CRUDRequestsMap.get,
        CRUDRequestsMap.update,
        CRUDRequestsMap.create,
        CRUDRequestsMap.delete
    ],
    requests: {
        [CRUDRequestsMap.create]: {
            url: '/',
            method: HTTPMethodEnum.POST,
            model: Reagent,
            dataModel: Reagent,
        },
        [CRUDRequestsMap.update]: {
            url: '/',
            method: HTTPMethodEnum.PUT,
            model: Reagent,
            dataModel: Reagent,
        },
        editReagentComment: {
            url: '{id}/quick_comment',
            method: HTTPMethodEnum.PUT,
            uriParamsModel: SingleRequestURIParams
        },
    }
});
