// @flow

import { aliasesResourceActions } from '../actions';
import { resourceReducer } from '../../shared/utils/reducer-creators';

export const aliasesResourceReducer = resourceReducer(aliasesResourceActions, {
    [aliasesResourceActions.get.success]: (state, action) => ({
      pending: action.pending,
      fail: action.fail,
      data: action.result.reduce((acc, cur) => ({ ...acc, ...{ [cur.Id]: cur } }), {}),
      params: action.params,
      error: null,
    })
  }
);
