// @flow

import React, {
	useEffect
} from 'react';
import type { TReactionEditCurrent } from '../../../../reducers';
import type { IProduct } from '../../../../../products/model';
import { GridTable, GridTableCol } from '../../../../../shared/components/GridTable';
import type { IReactionEvent, IReactionEventRequest } from '../../../../models';
import { ReactionEventRequest } from '../../../../models';
import { ReactionEventTypesEnum } from '../../../../enums/reaction-events.enum';
import { connect } from 'react-redux';
import type { IRootStore } from '../../../../../app/reducers';
import { Dispatch } from 'redux';
import { getAddedJarsByCurrentReaction, getCurrentReaction } from '../../../../reducers';
import { getProductsFromForm } from '../../../../reducers/reaction-edit-form-plugin';
import { reactionEventsHistoryResourceActions } from '../../../../actions';
import styled from 'styled-components';

interface IReactionCreatedJarsProps {
	currentReaction?: TReactionEditCurrent,
	products?: IProduct[],
	jars?: IReactionEvent[],
	loadReactionEvents?: (params: IReactionEventRequest, id: number)=>void,
	className?: string
}

const defaultProps: IReactionCreatedJarsProps = {
	className: ''
};

const defaultReactionEventRequest: IReactionEventRequest = ReactionEventRequest({
	EventType: ReactionEventTypesEnum.JarAdded
});

const
	renderMainProductInfo = (mainProd: 'True' | 'False') => (
		mainProd && `${(mainProd === 'True') ? 'основной' : 'побочный'} продукт`
	)
;

let ReactionCreatedJars = (props: IReactionCreatedJarsProps = defaultProps) => {

	// Effects
	const _loadEvents = () => {
		if (props.currentReaction?.reactionId) {
			props.loadReactionEvents(defaultReactionEventRequest, props.currentReaction.reactionId)
		}
	};
	useEffect(_loadEvents, [props.currentReaction?.reactionId]);

	// Methods
	const
		getProductName = (productId: string): string => {
			const id = parseInt(productId, 10);
			if (isNaN(id) || !isFinite(id)) return;
			const product: IProduct = props.products.find((p: IProduct) => p.Id === id);
			return product?.Name;
		}
	;

	return (
		<GridTable
			items={props.jars}
			rowHeight={'auto'}
			colsTemplate={'1fr auto'}
			className={props.className}
		>
			<GridTableCol
				title={'Продукт'}
				render={({ item }) => (
					<span>
						<i>{ getProductName(item?.AdditionalInfo['ProductId']) }</i><br/>(<b>{ renderMainProductInfo(item?.AdditionalInfo['IsMainProduct']) }</b>)
					</span>
				)}
			/>
			<GridTableCol
				title={'Масса, г'}
				align={'right'}
				render={({ item }) => (
					<span>
						{ item.AdditionalInfo['Amount'] }
					</span>
				)}
			/>
		</GridTable>
	);
};

ReactionCreatedJars = connect(
	(state: IRootStore): IReactionCreatedJarsProps => ({
		currentReaction: getCurrentReaction(state),
		products: getProductsFromForm(state),
		jars: getAddedJarsByCurrentReaction(state)
	}),
	(dispatch: Dispatch): IReactionCreatedJarsProps => ({
		loadReactionEvents: (params: IReactionEventRequest, id: number) => dispatch(reactionEventsHistoryResourceActions.list.request({ params, uriParams: {id} }))
	})
)(ReactionCreatedJars);

ReactionCreatedJars = styled(ReactionCreatedJars)`
	.grid-table-row-heading:first-child,
	.grid-table-row:first-child {
		padding-left: 0;
	}
`;

export {
	ReactionCreatedJars
}
