// @flow

import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import type { ComponentType } from 'react';
import type {IRootStore} from '../../app/reducers';
import { Dispatch } from 'redux';
import { indicatorsResourceActions, indicatorUnitsResourceActions } from '../actions';
import { getIndicators, getIndicatorsAsOptions, getIndicatorUnits, getIndicatorUnitsAsOptions } from '../reducers';
import type {IIndicator, IIndicatorUnit} from '../models';
import type {OptionType} from '../../shared/models';

export interface IIndicatorsLoaderHocProps {
    indicators?: IIndicator[],
    indicatorUnits?: IIndicatorUnit[],
    indicatorOptions?: OptionType[],
    indicatorUnitOptions?: OptionType[],
    requestIndicators?: ()=>null,
    requestIndicatorUnits?: ()=>null,

}

export const IndicatorsLoader = (WrappedComponent: ComponentType) => (
    connect(
        (state: IRootStore): IIndicatorsLoaderHocProps => ({
            indicators: getIndicators(state),
            indicatorOptions: getIndicatorsAsOptions(state),
            indicatorUnits: getIndicatorUnits(state),
            indicatorUnitOptions: getIndicatorUnitsAsOptions(state),
        }),
        (dispatch: Dispatch): IIndicatorsLoaderHocProps => ({
            requestIndicators: () => dispatch(indicatorsResourceActions.list.request({})),
            requestIndicatorUnits: () => dispatch(indicatorUnitsResourceActions.list.request({})),
        })
    )(
        (props: IIndicatorsLoaderHocProps) => {
            const _loadIndicatorsIfListEmpty = () => {
                if (props.indicators.length === 0)
                {
                    props.requestIndicators();
                }
            };
            useEffect(_loadIndicatorsIfListEmpty, []);

            const _loadIndicatorUnitsIfListEmpty = () => {
                if (props.indicatorUnits.length === 0)
                {
                    props.requestIndicatorUnits();
                }
            };
            useEffect(_loadIndicatorUnitsIfListEmpty, []);

            return (
                <WrappedComponent {...props} />
            );
        }
    )
);
