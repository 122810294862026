// @flow
import React from 'react';
import { ResizableArea } from '.';

export const ResizableAreaFieldWrapper = ({input, meta: {touched, error}, ...props}) => {
  return(
    <>
      <ResizableArea 
        {...input}
        {...props}
      />
      {touched && error && <span>{error}</span>}
    </>
  );
};
