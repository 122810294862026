// @flow
import {
  put,
  takeEvery,
  fork,
  select,
} from 'redux-saga/effects';
import { JarStockService } from '../services/jar-stock';
import { jarStockResourceActions, jarSpendingResourceActions } from '../actions';
import {getToken} from '../../account/reducers';
import type {IJarAmountsByLabsRequest} from '../models/jar-amounts-by-labs';
import {jarAmountsByLabsResourceActions} from '../actions/jar-amounts-by-labs';
import {JarAmountsByLabsModel} from '../models/jar-amounts-by-labs';
import type {IRootStore} from '../../app/reducers';

export function* searchJarStockByAgents({ params }: any): Generator<*, *, *> {
  const token = yield select(getToken);
  const service = new JarStockService(token);

  try {
    const result = yield service.getJarStockByAgent(params);
    yield put(jarStockResourceActions.list.success(result));
  } catch (e) {
    if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
      yield put(jarStockResourceActions.list.failure({ error: e.response.data['ErrorMessage'] }));
    }
  }
}

export function* updateJarStock(data: any): Generator<*, *, *> {
  const token = yield select(getToken);
  const service = new JarStockService(token);

  try {
    const result = yield service.update(data);
    yield put(jarStockResourceActions.update.success(result));
  } catch (e) {
    if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
      yield put(jarStockResourceActions.update.failure({ error: e.response.data['ErrorMessage'] }));
    }
  }
}

export function* getJarStockSpendings(params: { AgentAvailabilityId: number, includeSpentOnReactions: boolean }): Generator<*, *, *> {
  const token = yield select(getToken);
  const service = new JarStockService(token);

  try {
    const result = yield service.getJarStockSpendings(params);
    yield put(jarSpendingResourceActions.list.success(result));
  } catch (e) {
    if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
      yield put(jarSpendingResourceActions.list.failure({ error: e.response.data['ErrorMessage'] }));
    }
  }
}

export function* spendJar(data: { AgentAvailabilityId: number, Amount: number, Reason: string }): Generator<*, *, *> {
  const token = yield select(getToken);
  const service = new JarStockService(token);

  try {
    const result = yield service.spendJar(data);
    yield put(jarSpendingResourceActions.create.success(result));
  } catch (e) {
    if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
      yield put(jarSpendingResourceActions.create.failure({ error: e.response.data['ErrorMessage'] }));
    }
  }
}

export function* getJar(reagents): Generator<*, *, *> {
  const token = yield select(getToken);
  const service = new JarStockService(token);

  try {
    const jarIds = reagents.flatMap(r => r.Jars).map(j => j.AgentAvailabilityId);
    const foundJars = (yield service.getJar({Ids: jarIds})).result;
    const result = reagents.flatMap(r => r.Jars)
                           .map(j => ({
                              reagentId: j.ReagentId, ...foundJars.find(i => i.Id == j.AgentAvailabilityId) 
                            }));
    yield put(jarStockResourceActions.getJar.success({result}));
  } catch (e) {
    if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
      yield put(jarStockResourceActions.getJar.failure({ error: e.response.data['ErrorMessage'] }));
    }
  }
}

export function* getAmountsByLabs({ params }: { params: IJarAmountsByLabsRequest })
{
  if (!params) return;
  const token = yield select(getToken);
  const service = new JarStockService(token);
  const labIdFromStore = yield select((state: IRootStore) => {
    const userId = state.modules.users.selfUserId;
    const user = state.resource.users.data[userId];
    return user?.LaboratoryId;
  });

  try {
    const response = yield service.getAmountsByLaboratories({
      ...params,
      LaboratoryId: params.LaboratoryId || labIdFromStore
    });
    yield put(jarAmountsByLabsResourceActions.get.success({ ...response, result: JarAmountsByLabsModel({
        ...response.result,
        AvailabilitiesByLaboratories: (response.result?.AvailabilitiesByLaboratories)
            ? Object.entries(response.result?.AvailabilitiesByLaboratories)
                .reduce((acc, [labId, amount]) => acc.set(Number.parseInt(labId), amount), new Map())
            : new Map()
    }) }));
    return response.result;
  }
  catch (error)
  {
    yield put(jarAmountsByLabsResourceActions.get.failure({ error }));
  }
}

function* getUsedJarsByReaction(params) {
  const token = yield select(getToken);
  const service = new JarStockService(token);

  try {
    const result = yield service.getUsedJarsByReaction(params);
    yield put(jarStockResourceActions.getUsedJarsByReaction.success(result));
  } catch (e) {
    if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
      yield put(jarStockResourceActions.getUsedJarsByReaction.failure({ error: e.response.data['ErrorMessage'] }));
    }
  }
}

function* getProvidedJarsByReaction(params) {
  const token = yield select(getToken);
  const service = new JarStockService(token);

  try {
    const result = yield service.getProvidedJarsByReaction(params);
    yield put(jarStockResourceActions.getProvidedJarsByReaction.success(result));
  } catch (e) {
    if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
      yield put(jarStockResourceActions.getProvidedJarsByReaction.failure({ error: e.response.data['ErrorMessage'] }));
    }
  }
}

export const jarStockWatchers = [

  function* watchGetJarStockSpendings(): Generator<*, *, *> {
    yield takeEvery(jarSpendingResourceActions.list.request.type, function* (action) {
      const { params } = action;
      yield fork(getJarStockSpendings, params);
    });
  },

  function* watchSearchJarStockByAgents(): Generator<*, *, *> {
    yield takeEvery(jarStockResourceActions.list.request.type, function* (action) {
      const { params } = action;
      yield fork(searchJarStockByAgents, { params });
    });
  },

  function* watchSpendJar(): Generator<*, *, *> {
    yield takeEvery(jarSpendingResourceActions.create.request.type, function* (action) {
      const { data } = action;
      yield fork(spendJar, data);
    });
  },

  function* watchGetJar(): Generator<*, *, *> {
    yield takeEvery(jarStockResourceActions.getJar.request.type, function* (action) {
      const { data } = action;
      yield fork(getJar, data);
    });
  },

  function* watchUpdateJarStock(): Generator<*, *, *> {
    yield takeEvery(jarStockResourceActions.update.request.type, function* (action) {
      const { data } = action;
      yield fork(updateJarStock, data);
    });
  },

  function* ()
  {
    yield takeEvery(jarAmountsByLabsResourceActions.get.request.type, function* (action) {
      yield fork(getAmountsByLabs, action);
    })
  },

  function* watchGetUsedJarsByReaction(): Generator<*, *, *> {
    yield takeEvery(jarStockResourceActions.getUsedJarsByReaction.request.type, function* (params) {
      yield fork(getUsedJarsByReaction, params);
    });
  },

  function* watchGetUsedJarsByReaction(): Generator<*, *, *> {
    yield takeEvery(jarStockResourceActions.getProvidedJarsByReaction.request.type, function* (params) {
      yield fork(getProvidedJarsByReaction, params);
    });
  },
];
