// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { accountActions } from '../actions';
import { withRouter } from 'react-router-dom';
import { isAuthorized } from '../reducers';
import {
  getFormValues,
  Field,
  reduxForm,
} from 'redux-form';
import {
  BcdInputWrapper,
  LayoutBlock,
  LayoutHeader,
} from '../../shared/components';
import Button from '@biocad/bcd-front-ui/controls/Button';

const LOGIN_FORM_NAME = 'loginForm';

const Login = ({ processLogin, formValues, isAuthorized, history }) => {
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (isAuthorized) {
      setRedirect(true);
    }
  }, [isAuthorized]);

  useEffect(() => {
    if (redirect) {
      const lastVisitedPage = JSON.parse(localStorage.getItem('lastVisitedPage'));
      if (lastVisitedPage && lastVisitedPage[window.name]?.pathname && !['/login', '/'].includes(lastVisitedPage[window.name].pathname)) {
        history.push(lastVisitedPage[window.name]);
      }
      else {
        history.push('/user-dashboard/reactions');
      }
    }
  }, [redirect]);

  function performLogin() {
    processLogin(formValues.UserName, formValues.Password);
  }

  return (
    <div>
      <LayoutHeader>
        <h2>Логин</h2>
      </LayoutHeader>
      <LayoutBlock>
        <Field
          name={'UserName'}
          component={BcdInputWrapper}
          useFormFieldLayout={true}
          label={'Логин'}
        />
        <Field
          name={'Password'}
          type={'password'}
          component={BcdInputWrapper}
          useFormFieldLayout={true}
          label={'Пароль'}
        />
        <Button text={'Вход'}
          size={'L'}
          onAction={performLogin}
        />
      </LayoutBlock>
    </div>
  );
};

const validate = (values) => {
  if (!values || Object.keys(values).length === 0) return {};
  const errors = {
    UserName: null,
    Password: null,
  };
  return errors;
};

const mapStateToProps = (state) => ({
  isAuthorized: isAuthorized(state),
  formValues: getFormValues(LOGIN_FORM_NAME)(state),
});

const mapDispatchToProps = dispatch => ({
  processLogin: (username, password) => dispatch(accountActions.login.request({ data: { username, password } })),
});

export default reduxForm({
  form: LOGIN_FORM_NAME,
  enableReinitialize: true,
  destroyOnUnmount: true,
  keepDirtyOnReinitialize: false,
  validate,
})(withRouter(connect(mapStateToProps, mapDispatchToProps)(Login)));
