// @flow
import styled from 'styled-components';
import { Form } from 'redux-form';
import { CatalogReactionRequestParamsEnum } from '../../../models';
import { bChildrenWrap } from '../../../../shared/components/FormFieldLayout';
import Checkbox from '@biocad/bcd-front-ui/controls/Checkbox';
import { StyledIcon } from './ReactionTypeCheckbox';

export const StyledForm = styled(Form)`
  --gap: calc(var(--grid-spacer) * 1.5);
  --FormFieldLayout-margin-b: 4px;
  display: grid;
  grid-template: ". .                   .                   SmilesFormula"
                 ". Author              ProjectId           SmilesFormula"
                 ". ReactionName        FinalCandidateCode  SmilesFormula"
                 ". CreationDateRange   CreationDateRange   SmilesFormula"
                 ". CompleteDateRange   CompleteDateRange   SmilesFormula"
                 ". reactionStatus      ReactionType        SmilesFormula"
                 ". IncludeRegular      TemplateReaction    SmilesFormula"
                 ". IncludeArchived     .                   SmilesFormula"
                 ". .                   .                   SmilesFormula";
  grid-template-columns: 0 0.45fr 0.45fr 1.1fr;
  grid-template-rows: var(--gap) repeat(7, auto) var(--gap);
  column-gap: var(--gap);
  
  .${`field-${CatalogReactionRequestParamsEnum.Author}`} {
    grid-area: ${CatalogReactionRequestParamsEnum.Author};
  }
  .${`field-${CatalogReactionRequestParamsEnum.ProjectId}`} {
    grid-area: ${CatalogReactionRequestParamsEnum.ProjectId};
  }
  .field-CompleteDateRange {
    grid-area: CompleteDateRange;
  }
  .field-CreationDateRange {
    grid-area: CreationDateRange;
  }
  .${`field-${CatalogReactionRequestParamsEnum.ReactionName}`} {
    grid-area: ReactionName;
  } 
  .${`field-${CatalogReactionRequestParamsEnum.FinalCandidateCode}`} {
    grid-area: FinalCandidateCode; 
  }
  .field-reactionStatus {
    grid-area: reactionStatus;
    .${bChildrenWrap} {
      flex-direction: row;
      ${StyledIcon} {
        &:not(:last-child) {
          margin-right: calc(var(--grid-spacer) * 1.25);
        }
      }
    }
  }
  .field-reactionType {
    grid-area: ReactionType;
    .${bChildrenWrap} {
      div {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
  .${`field-${CatalogReactionRequestParamsEnum.IncludeArchived}`} {
    grid-area: ${CatalogReactionRequestParamsEnum.IncludeArchived};
  }
  .${`field-${CatalogReactionRequestParamsEnum.IncludeRegular}`} {
    grid-area: ${CatalogReactionRequestParamsEnum.IncludeRegular};
  }
  .${`field-${CatalogReactionRequestParamsEnum.OnlyTemplateReactions}`} {
    grid-area: TemplateReaction;
  }
  .${`field-${CatalogReactionRequestParamsEnum.SmilesFormula}`} {
    grid-area: ${CatalogReactionRequestParamsEnum.SmilesFormula};
    border-left: 1px solid var(--grey-300);
  } 
  .field-CompleteDateRange,
  .field-CreationDateRange {
    .${bChildrenWrap} {
      display: grid;
      grid-auto-columns: 1fr var(--gap) 1fr;
      grid-template-areas: ". intersign .";
      &::after {
        content: '–';
        text-align: center;
        grid-area: intersign;
        align-self: center;
      }
    }
  }
  .${Checkbox.block.el('wrapper')} {
    margin-left: 0;
  }
`;
