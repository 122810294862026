import React, {useEffect, useState, useRef} from 'react';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';
import { Field, isValid } from 'redux-form';
import {productsResourceActions} from '../../../../products/actions';
import {reagentsResourceActions} from '../../../../reagents/actions';
import type {IRootStore} from '../../../../app/reducers';
import type {IReaction} from '../../../models';
import {GridTable} from '../../../../shared/components/GridTable';
import {GridTableCol} from '../../../../shared/components/GridTable/GridTableCol';
import type { IReagent, IReagentJar } from '../../../../reagents/model';
import {InlineEdit, inputTypesEnum} from '../../../../shared/components/InlineEdit';
import {FieldValidators} from '../../../../shared/validators/field-validators';
import { IconButtonExpand } from '../../../../shared/components/IconButtonExpand';
import type {IProduct} from '../../../../products/model';
import {
  getProductsFromForm,
  getReactantsFromForm,
  getReactionFromForm,
  getReagentsErroredFieldNames,
  getReagentsFromForm,
} from '../../../reducers/reaction-edit-form-plugin';
import Button from '@biocad/bcd-front-ui/controls/Button';
import {
    StyledReagentsTableBtnsContainer, ReagentsTableStyled
} from './styles';
import classNames from 'classnames';
import {reactionModuleActions} from '../../../actions';
import { BcdCheckboxWrapper } from '../../../../shared/components/BcdCheckboxWrapper';
import styled from 'styled-components';
import { ReactionKeyNames, REACTANTS_KEY_NAME } from '../../../models';
import type { TCurrentReactionStates } from '../../../reducers';
import { getCurrentReactionStates, getHighlightedSubstance } from '../../../reducers';
import { ReagentKeyNames, ReagentTypeEnum } from '../../../../reagents/model';
import { ProductKeyNames } from '../../../../products/model';
import {
    isAuxiliary,
    isCatalyst,
    isOriginal,
    isProduct,
    isReactant,
    isReagent,
    isRefCompound,
    isSolvent
} from '../../../helpers/common';
import Dropdown from '@biocad/bcd-front-ui/controls/Dropdown';
import { ReactionEditDialogsMap } from '../../../reducers';
import { ReactionEditDialogWrap } from '../ReactionEditDialogWrap';
import type { OptionType } from '../../../../shared/models';
import type { TReactionEditDialogRefType } from '../ReactionEditDialogWrap';
import type { IAgent, IJarStock } from '../../../../agents/models';
import { REACTION_EDIT_FORM_NAME } from '../index';
import { ReagentConsumptionBtn } from './ReagentConsumptionBtn';
import Tooltip from '@biocad/bcd-front-ui/layout/Tooltip';
import {userCRUDPermissionsSelector} from "../../../../account/reducers/users-modules-reducer";
import {ReactionPurificationModal} from '../../ReactionPurification/ReactionPurificationModal';
import {REACTION_TYPE_PURIFICATION_ID} from '../../../../shared/globals/constants';
import './index.less';
// Constants
const
  REACTION_REACTANTS_ARRAY_FIELD_NAME = REACTANTS_KEY_NAME
;

interface IReagentsTableProps {
  getReagentsList?: Function,
  getProductsList?: Function,
  reactantsList?: (IReagent | IProduct)[],
  reaction?: IReaction,
  reagentsList?: IReagent[],
  productsList?: IProduct[],
  className?: string,
  editReagentComment?: Function,
  recalcReagent?: (number)=>void,
  recalcProduct?: (number)=>void,
  recalcAll?: ()=>void,
  setReferencedReagent?: (IReagent)=>void,
  addSubItem?: (IReagent)=>void,
  reactionStates?: TCurrentReactionStates,
  formErrors?: string[],
  highlightedSubstance?: ?number,
  setHighlightedSubstance?: (idx: number) => void,
  addReagent?: (IAgent, IJarStock[], number)=>void,
  removeReagent?: (number)=>void,
  formIsValid?: boolean,
  openSpendingsDialog?: (id: string, name: string)=>void,
  closeSpendingsDialog?: (any[])=>void,
  userPermissions?: {},
}

const fieldValidators = {
  mass: [
    FieldValidators.stepNum(0.00001),
    FieldValidators.minNum(0)
  ],
  refCompMass: [
    FieldValidators.stepNum(0.00001),
    FieldValidators.minNum(0),
    FieldValidators.required
  ],
  eq: [
    FieldValidators.stepNum(0.0001),
    FieldValidators.minNum(0),
  ],
  mainAgent: [
    FieldValidators.maxNum(100),
    FieldValidators.minNum(0.01),
    FieldValidators.stepNum(0.01)
  ],
  volume: [
    FieldValidators.stepNum(0.001),
    FieldValidators.minNum(0),
  ],
  density: [
    FieldValidators.stepNum(0.001),
    FieldValidators.minNum(0),
  ],
  concentration: [
    FieldValidators.stepNum(0.01),
    FieldValidators.minNum(0),
  ]
};

const defaultProps: IReagentsTableProps = {
  className: '',
};

const chooseAddedReactantDropdownOptions: OptionType[] = [
  {
    value: ReagentTypeEnum.Catalyst,
    label: 'Реагент/катализатор'
  },
  {
    value: ReagentTypeEnum.Solvent,
    label: 'Растворитель'
  },
  {
    value: ReagentTypeEnum.Auxiliary,
    label: 'Вспом. реагент'
  },
];

const renderJarProviderName = (jar: IReagentJar, providerReactionId: number) => {
    if (!jar) {
      return;
    }
    return providerReactionId
    ? <Link key={jar.Id}
            to={`/reactions/${providerReactionId}`}
            target={'_blank'}>{ jar.ProviderName || '-' }</Link>
    : <div key={jar.Id}>{ jar.ProviderName || '-' }</div>;
};

const renderJarProviders = (reagent: IReagent = []) => {
    const
        startingJar = reagent.Jars.find((jar: IReagentJar) => jar.AgentAvailabilityId === reagent.AgentAvailabilityId),
        otherJars = reagent.Jars.filter((jar: IReagentJar) => jar.Id !== startingJar.Id || jar.ProviderName !== startingJar.ProviderName)
    ;
    return (
        <>
            { renderJarProviderName(startingJar, reagent?.AgentAvailability?.ProviderReactionId) }
            {
                otherJars.length
                ? <span className={'jar-providers-more'}>
                    Еще +{otherJars.length}
                    <Tooltip.Hover place={'bottom'}>
                        { otherJars.map((jar: IJarStock) => renderJarProviderName(jar)) }
                    </Tooltip.Hover>
                  </span>
                : ''
            }
        </>
    );
};

const _mapSubtitlesToCheckFns =
{
    ['Реагенты/Катализаторы'] : (item: IReagent) => isReactant(item) || isCatalyst(item),
    ['Растворители']          : isSolvent,
    ['Вспом. реагенты']       : isAuxiliary,
    ['Продукты']              : isProduct,
}

const renderSubTable = (props, reactants, parentId, parentLocalId, viewProducer) => {

  const onFieldChange = (event, newValue, previousValue, name, item: (IReagent | IProduct), row_idx: number) => {
    if (isRefCompound(item)) {
      props.recalcAll();
    }
    else
      props.recalcReagent(row_idx);
  };

  const renderButtons = ({item, rowidx}: { item: (IReagent | IProduct), rowidx: number}) => (
    <div className={'buttons-wrap'}>
        {                
            <>                    
                {
                    isReagent(item) &&
                    <ReagentConsumptionBtn
                        smilesFormulas={[item?.Agent?.Compound?.SmilesFormula]}
                        complexCompoundNames={
                          !item?.Agent?.Compound?.SmilesFormula && [item?.Agent?.Compound?.Name] || null
                        }
                        reagent={item}
                    />
                }                    
                {                        
                    <Button
                        className={'fo-trash'}
                        view={'icon'}
                        size={'L'}
                        onAction={() => removeReagentAndRecalc(rowidx)}
                    />
                }
                {
                  (isReagent(item) && item.Agent?.Compound) &&
                  <Button
                    className={'fo-eye'}
                    view={'icon'}
                    size={'L'}
                    onAction={() => {
                      viewProducer(item, false);
                    }}
                  />
                }
            </>
        }
        
    </div>
  );

  const onActivateRow = (event, row, rowidx, renderIdx) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const removeReagentAndRecalc = (rowidx) => {
    props.removeReagent(rowidx);
    props.recalcAll();
  };
  return (<div style={{border: '1px solid #D3D7DE', backgroundColor: '#FCFCFC', marginRight: '10px'}}>
    <GridTable items={reactants}
                 hover={true}
                 stickyHeading={true}
                 fieldArrayName={'reactants'}
                 colsTemplate={'repeat(9, minmax(min-content, 1fr)) 3fr min-content'}
                 rowHeight={'53px'}
                 excludeRows={(item: IProduct | IReaction) => 
                    ((!item.ParentReagentId && !item.ParentReagentLocalId) || 
                    ((item.ParentReagentId && item.ParentReagentId != parentId) ||  (item.ParentReagentLocalId && item.ParentReagentLocalId != parentLocalId) )
                    || item.IsDeleted)}
                 onRowClicked={(row, rowidx, event, renderIdx) => onActivateRow(event, row, rowidx, renderIdx)}
      >
        
        <GridTableCol
            title={'Масса, г'}
            fieldName={ReagentKeyNames.Mass}
            render={({input, meta, item}) => (
              <InlineEdit
                readonly={true}
                inputType={inputTypesEnum.InputNum}
                markPristineValidity
                {...{ input, meta }}
              />
            )}
            onChange={onFieldChange}
            validate={(item) => item.IsRefCompound ? fieldValidators.refCompMass : fieldValidators.mass}
        />
        <GridTableCol
            title={'Vol, мл'}
            fieldName={ReagentKeyNames.Volume}
            render={({input, meta, item}) => (
              <InlineEdit
                readonly={props.reactionStates?.isReadonly || (isSolvent(item) ? !props.reaction?.VolumeBasedSolvents : true)}
                inputType={inputTypesEnum.InputNum}
                markPristineValidity
                {...{ input, meta }}
              />
            )}
            validate={(item) => (isSolvent(item) && props.reaction?.VolumeBasedSolvents) ? fieldValidators.volume : null}
            warn={(item) => (isSolvent(item) && props.reaction?.VolumeBasedSolvents) ? [FieldValidators.required] : null}
            onChange={onFieldChange}
        />
        <GridTableCol
            title={'Кол-во, млМ'}
            render={({item}) => (
              <span>{ item.Amount ? (item.Amount*1000).toFixed(5) : '-'}</span>
            )}
        />
        <GridTableCol
            title={'Eq'}
            fieldName={ReagentKeyNames.Eq}
            render={({input, meta, item}) => (
              <InlineEdit
                inputType={inputTypesEnum.InputNum}
                readonly={props.reactionStates?.isReadonly || (props.reaction?.VolumeBasedSolvents && isSolvent(item))}
                markPristineValidity
                {...{input, meta}}
              />
            )}
            align={'right'}
            onChange={onFieldChange}
            validate={fieldValidators.eq}
        />
        <GridTableCol
            title={'MW'}
            render={({item}) => (
              <span>{isReagent(item) ? item.Agent?.MolarWeight?.toFixed(2) || '-' : item.MolarWeight?.toFixed(2)}</span>
            )}
            align={'right'}
        />
        <GridTableCol
            title={'Плот, г/мл'}
            fieldName={(item: (IProduct | IReagent)) => isProduct(item) ? ProductKeyNames.Density : `${ReagentKeyNames.AgentAvailability}.Density`}
            render={({item, input, meta}) => (
              <InlineEdit
                inputType={inputTypesEnum.InputNum}
                readonly={props.reactionStates?.isReadonly || isReagent(item)}
                markPristineValidity
                {...{input, meta}}
              />
            )}
            onChange={onFieldChange}
            align={'right'}
            validate={(item) => isProduct(item) ? fieldValidators.density : null}
        />
        <GridTableCol
            title={'M, моль/л'}
            fieldName={(item: (IProduct | IReagent)) => isProduct(item) ? ProductKeyNames.Concentration : `${ReagentKeyNames.AgentAvailability}.Concentration`}
            render={({input, meta, item}) => (
              <InlineEdit
                inputType={inputTypesEnum.InputNum}
                readonly={props.reactionStates?.isReadonly || isReagent(item)}
                markPristineValidity
                {...{input, meta}}
              />
            )}
            onChange={onFieldChange}
            align={'right'}
            validate={(item) => isProduct(item) ? fieldValidators.concentration : null}
        />
        <GridTableCol
            title={'Осн. вещ-во, %'}
            fieldName={ReagentKeyNames.MainAgentContent}
            render={({input, meta, item}) => (
              <InlineEdit
                inputType={inputTypesEnum.InputNum}
                readonly={
                    props.reactionStates?.isReadonly
                    || ((!props.reaction?.IsArchived && props.reaction?.ReactionType !== REACTION_TYPE_PURIFICATION_ID) && isProduct(item))
                }
                markPristineValidity
                {...{input, meta}}
              />
            )}
            onChange={onFieldChange}
            align={'right'}
            validate={fieldValidators.mainAgent}
        />
        <GridTableCol
            title={'Факт. расход, г'}
            render={({item}) => (
              isReagent(item) ? <span>{(item.FactualConsumption ?? 0).toFixed(3)}</span> : ''
            )}
            align={'right'}
        />
        <GridTableCol
            title={'Производитель'}
            render={({item}) => (
              isReagent(item) ? renderJarProviders(item) : ''
            )}
            align={'left'}
        />
        <GridTableCol
            render={renderButtons}
        />
      </GridTable></div>);
}

const renderTableSubTitle = (props: {item: IReagent | IProduct, rowidx: number, renderIdx: number, allItems: Array<IReagent | IProduct>}) =>
{
    if (!Array.isArray(props.allItems)) return;
    const { item, renderIdx, allItems } = props;
    const renderedAllItems = allItems.filter((i) => !i.IsDeleted);
    const prevItem = renderedAllItems[renderIdx-2];
    let title;
    if (!isProduct(item) && isReagent(item) && !prevItem)
    {
        title = 'Реагенты/Катализаторы';
    }
    else if (isProduct(item) && !isProduct(prevItem))
    {
        title = 'Продукты';
    }
    else
    {
        const prevIsOfDifferentType = isReagent(item) && isReagent(prevItem) && item.ReagentType !== prevItem.ReagentType;
        if (!prevIsOfDifferentType) return;
        if (isSolvent(item))
        {
            title = 'Растворители';
        }
        else if (isAuxiliary(item))
        {
            title = 'Вспом. реагенты';
        }
    }
    return title ? (<strong>{ title }</strong>) : '';
};

let ReagentsTable = (props: IReagentsTableProps = defaultProps) => {
  // State
  const
    [selectedSubstanceType, setSelectedSubstanceType] = useState(null),
    [reagentDialogCompound, setReagentDialogCompound] = useState(null),
    [reagentDialogAgentId, setReagentDialogAgentId]   = useState(null),
    [expandedRows, setExpandedRows] = useState([]),
    [expandedLocalRows, setExpandedLocalRows] = useState([]),    
    [reagentDialogIsProduct, setReagentDialogIsProduct]   = useState(null)

  ;
  // Refs
  const
    addSubstanceDialogRef = useRef(null),
    reactionSpendingsRef = useRef(null)
  ;

  // Handlers
  const
  toggleSubTable = (event: Event, item) => {
    event.preventDefault();
    event.stopPropagation();
    if(event.target.nodeName == "INPUT")
      return;
    if(event.target.nodeName == "DIV" && event.target.innerHTML.indexOf('b-InlineEdit__value') > 0)
      return;
    if(item.Id) {
      if(!expandedRows.includes(item.Id) && expandedLocalRows.includes(item.localUuid)) {}
      else
      setExpandedRows((prev: number[]) => 
        prev.includes(item.Id)
          ? prev.filter(it => it !== item.Id)
          : prev.concat([item.Id])
      );
    }
    if(item.localUuid) {
      setExpandedLocalRows((prev: String[]) =>
        prev.includes(item.localUuid)
          ? prev.filter(it => it !== item.localUuid)
          : prev.concat([item.localUuid])
      );
    }
  },
    onFieldChange = (event, newValue, previousValue, name, item: (IReagent | IProduct), row_idx: number) => {
      if (isProduct(item)) {
        props.recalcProduct(row_idx);
        return;
      }
      if (isRefCompound(item)) {
        props.recalcAll();
        return;
      }
      props.recalcReagent(row_idx);
    },
    // editComment = (data) => {
    //   props.editReagentComment(data.itemId, { QuickComment: data.comment || '' });
    // },
    setRefCompound = (item: IReagent) => {
      props.setReferencedReagent(item);
    },
    addSubItem = (item: IReagent) => {
      props.addSubItem(item);
      props.recalcAll();
    },
    isRefCompoundHaveFactualConsumption = () => {
        return !props.reactantsList?.find(item => item.IsRefCompound)?.FactualConsumption;
    },
    chooseAddedReactant = (value) => {
      setSelectedSubstanceType(value);
      if (addSubstanceDialogRef?.current && 'open' in addSubstanceDialogRef?.current) {
        addSubstanceDialogRef?.current.open();
      }
    },
    addReagentAndRecalc = (agent, jars, availability) => {
      props.addReagent(agent, jars, availability, selectedSubstanceType);
      setSelectedSubstanceType(null);
      props.recalcAll();
    },
    removeReagentAndRecalc = (rowidx) => {
      props.removeReagent(rowidx);
      props.recalcAll();
    },
    openSpendingsDialog = () => {
      if ('id' in reactionSpendingsRef.current && 'name' in reactionSpendingsRef.current) {
        const { id, name } = reactionSpendingsRef.current;
        props.openSpendingsDialog(id, name);
      }
    }
  ;

  // Methods
  const
    onActivateRow = (event, row, rowidx, renderIdx) => {
      const hasSubItems = props.reactantsList.some(r => ((row.localUuid && r.ParentReagentLocalId == row.localUuid) ||
      (row.Id && r.ParentReagentId == row.Id))
       && !r.IsDeleted);
      if(!row.ParentReagentId && !row.ParentReagentLocalId && hasSubItems) {
        toggleSubTable(event, row);
      }
      event.preventDefault();
      event.stopPropagation();
      if (renderIdx !== props.highlightedSubstance) {
        props.setHighlightedSubstance(renderIdx);
      } else {
        props.setHighlightedSubstance(null);
      }      
    },
    isParentReagent = (item) => {
      return props.reactantsList.some(r => 
        ((item.localUuid && r.ParentReagentLocalId == item.localUuid) ||
        (item.Id && r.ParentReagentId == item.Id))
      && !r.IsDeleted );
    },
    viewProducer = (item, isProduct) => {
      if(item.Agent)
      {
        setReagentDialogCompound(item.Agent.Compound);
        setReagentDialogAgentId(item.Agent.Id);
        }
      else
      {
        setReagentDialogCompound(item.Compound);
        setReagentDialogAgentId(item.AgentId);
      }
      setReagentDialogIsProduct(isProduct);
    },
    renderButtons = ({item, rowidx}: { item: (IReagent | IProduct), rowidx: number}) => {
      return (<div className={'buttons-wrap'}>
          {
              (!props.reactionStates?.isReadonly && props.userPermissions.reaction.UPDATE) &&
              <> 
                {
                  (isReagent(item) && (!item.IsRefCompound || (item.IsRefCompound && item.Mass))) &&
                  <Button
                          className={'fo-plus'}
                          onAction={() => addSubItem(item)}
                          view={'icon'}
                          size={'L'}
                  />
                }
                  {
                      !isParentReagent(item) && isReagent(item) &&
                      <ReagentConsumptionBtn
                          smilesFormulas={[item?.Agent?.Compound?.SmilesFormula]}
                          complexCompoundNames={
                            !item?.Agent?.Compound?.SmilesFormula && [item?.Agent?.Compound?.Name] || null
                          }
                          reagent={item}
                      />
                  }
                  {
                      (isReagent(item) && item.IsRequired && !item.IsRefCompound) &&
                      <Button
                          className={'fo-link-solid'}
                          onAction={() => setRefCompound(item)}
                          view={'icon'}
                          size={'L'}
                      />
                  }
                  {
                      (isReagent(item) && !item.FactualConsumption && !item.IsRequired) &&
                      <Button
                          className={'fo-trash'}
                          view={'icon'}
                          size={'L'}
                          onAction={() => removeReagentAndRecalc(rowidx)}
                      />
                  }
              </>
          }
          {
              (isReagent(item) && item.Agent?.Compound) &&
              <Button
                  className={'fo-eye'}
                  view={'icon'}
                  size={'L'}
                  onAction={() => {
                    viewProducer(item, false);
                }}
              />
          }
          {
              (isProduct(item) && item.Compound) &&
              <Button
                  className={'fo-eye'}
                  view={'icon'}
                  size={'L'}
                  onAction={() => {
                    viewProducer(item, true);
                }}
              />
          }
      </div>
    )
  };
  return (
    <div className={props.className}>
      {
        !props.reactionStates?.isReadonly &&
        <StyledReagentsTableBtnsContainer>
            {
                props.userPermissions.reaction.UPDATE &&
                <Field
                    name={ReactionKeyNames.VolumeBasedSolvents}
                    component={BcdCheckboxWrapper}
                    label={'Вести расчет растворителей относительно объема'}
                    large={true}
                />
            }
          {
            (!!props.reaction?.Id && props.userPermissions.reaction.UPDATE) &&
            <Button
              text={'Внести расход'}
              onAction={openSpendingsDialog}
              size={'L'}
              className={classNames('fo-chem', {
                  'FactualConsumption-check': isRefCompoundHaveFactualConsumption()
              })}
              disabled={!props.formIsValid}
            />
          }
          {
              props.userPermissions.reaction.UPDATE &&
              <Dropdown
                  onChange={chooseAddedReactant}
                  value={'Добавить компонент'}
                  options={Object.values(chooseAddedReactantDropdownOptions)}
              />
          }
        </StyledReagentsTableBtnsContainer>
      }
      <ReagentsTableStyled 
        as={GridTable}
        items={props.reactantsList}
                 hover={true}
                 stickyHeading={true}
                 fieldArrayName={REACTION_REACTANTS_ARRAY_FIELD_NAME}
                 colsTemplate={'70px repeat(9, minmax(min-content, 1fr)) 3fr min-content'}
                 rowHeight={'auto'}
                 excludeRows={(item: IProduct | IReaction) => 
                  isProduct(item) ? 
                  (!item?.IsMainProduct || item?.IsDeleted || item?.ParentReagentId != null || item?.ParentReagentLocalId != null ) : 
                  (item?.IsDeleted|| item?.ParentReagentId != null || item?.ParentReagentLocalId != null)}
                 onRowClicked={(row, rowidx, event, renderIdx) => onActivateRow(event, row, rowidx, renderIdx)}
                 setRowClassNames={(item, rowidx, renderIdx) => ({
                   'is-selected': renderIdx === props.highlightedSubstance,
                   'is-expanded': expandedRows.includes(item.Id) || expandedLocalRows.includes(item.localUuid),
                   'limited-height': true
                 })}
                 renderRowBefore={renderTableSubTitle}
                 renderRowAfter={(args: {item: IProduct | IReaction, rowidx: number}) => {
                  if((expandedRows.includes(args.item.Id) || expandedLocalRows.includes(args.item.localUuid))
                  && isParentReagent(args.item)) {
                    return renderSubTable(props, 
                      props.reactantsList.filter((item) => ((item.ParentReagentId && args.item.Id == item.ParentReagentId) 
                      || (args.item.localUuid == item.ParentReagentLocalId && item.ParentReagentLocalId)) ), 
                      args.item.Id, args.item.localUuid,
                      viewProducer);
                  }
                  else return '';
                 }}
      >
        
        <GridTableCol
            title={'№'}            
            render={({item, renderIdx}) => (
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                {
                  isParentReagent(item) && 
                  <IconButtonExpand className={'no-outline'}
                    expanded={expandedRows.includes(item.Id) || expandedLocalRows.includes(item.localUuid)}
                    expandHandler={event => toggleSubTable(event, item)} />
                }
                {
                  (isReagent(item) && !item?.Agent?.IsIgnoringSpending && item?.Mass > item?.FactualConsumption) &&
                  <div style={{minWidth:'15px'}}><i className={'fo-attention reagent-consumption-warning'}>
                    <Tooltip.Hover place={'bottom'} align={'left'}>
                      Возможно некорректно внесены данные<br/>о расходе химического вещества.
                    </Tooltip.Hover>
                  </i></div>
                }
                <div>{ renderIdx }</div>
              </div>
            )}
        />
        <GridTableCol
            title={'Масса, г'}
            fieldName={ReagentKeyNames.Mass}
            render={({input, meta, item}) => (
              <InlineEdit
                readonly={!item.IsRefCompound || props.reactionStates?.isReadonly}
                inputType={inputTypesEnum.InputNum}
                markPristineValidity
                {...{ input, meta }}
              />
            )}
            onChange={onFieldChange}
            validate={(item) => item.IsRefCompound ? fieldValidators.refCompMass : fieldValidators.mass}
        />
        <GridTableCol
            title={'Vol, мл'}
            fieldName={ReagentKeyNames.Volume}
            render={({input, meta, item}) => (
              <InlineEdit
                readonly={props.reactionStates?.isReadonly || (isSolvent(item) ? !props.reaction?.VolumeBasedSolvents : true)}
                inputType={inputTypesEnum.InputNum}
                markPristineValidity
                {...{ input, meta }}
              />
            )}
            validate={(item) => (isSolvent(item) && props.reaction?.VolumeBasedSolvents) ? fieldValidators.volume : null}
            warn={(item) => (isSolvent(item) && props.reaction?.VolumeBasedSolvents) ? [FieldValidators.required] : null}
            onChange={onFieldChange}
        />
        <GridTableCol
            title={'Кол-во, мМоль'}
            render={({item}) => (
              <span>{ item.Amount ? (item.Amount*1000).toFixed(5) : '-'}</span>
            )}
        />
        <GridTableCol
            title={'Eq'}
            fieldName={ReagentKeyNames.Eq}
            render={({input, meta, item}) => (
              <InlineEdit
                inputType={inputTypesEnum.InputNum}
                readonly={props.reactionStates?.isReadonly || (props.reaction?.VolumeBasedSolvents && isSolvent(item)) || isParentReagent(item)}
                markPristineValidity
                {...{input, meta}}
              />
            )}
            align={'right'}
            onChange={onFieldChange}
            validate={fieldValidators.eq}
        />
        <GridTableCol
            title={'MW'}
            render={({item}) => (
              <span>{isReagent(item) ? item.Agent?.MolarWeight?.toFixed(2) || '-' : item.MolarWeight?.toFixed(2)}</span>
            )}
            align={'right'}
        />
        <GridTableCol
            title={'Плот, г/мл'}
            fieldName={(item: (IProduct | IReagent)) => isProduct(item) ? ProductKeyNames.Density : `${ReagentKeyNames.AgentAvailability}.Density`}
            render={({item, input, meta}) => (
              <InlineEdit
                inputType={inputTypesEnum.InputNum}
                readonly={props.reactionStates?.isReadonly || isReagent(item)}
                markPristineValidity
                {...{input, meta}}
              />
            )}
            onChange={onFieldChange}
            align={'right'}
            validate={(item) => isProduct(item) ? fieldValidators.density : null}
        />
        <GridTableCol
            title={'M, моль/л'}
            fieldName={(item: (IProduct | IReagent)) => isProduct(item) ? ProductKeyNames.Concentration : `${ReagentKeyNames.AgentAvailability}.Concentration`}
            render={({input, meta, item}) => (
              <InlineEdit
                inputType={inputTypesEnum.InputNum}
                readonly={props.reactionStates?.isReadonly || isReagent(item)}
                markPristineValidity
                {...{input, meta}}
              />
            )}
            onChange={onFieldChange}
            align={'right'}
            validate={(item) => isProduct(item) ? fieldValidators.concentration : null}
        />
        <GridTableCol
            title={'Осн. вещ-во, %'}
            fieldName={ReagentKeyNames.MainAgentContent}
            render={({input, meta, item}) => (
              <InlineEdit
                inputType={inputTypesEnum.InputNum}
                readonly={
                    props.reactionStates?.isReadonly
                }
                markPristineValidity
                {...{input, meta}}
              />
            )}
            onChange={onFieldChange}
            align={'right'}
            validate={fieldValidators.mainAgent}
        />
        <GridTableCol
            title={'Факт. расход, г'}
            render={({item}) => (
              isReagent(item) ? <span>{item.FactualConsumption?.toFixed(3) || '-'}</span> : ''
            )}
            align={'right'}
        />
        <GridTableCol
            title={'Производитель'}
            render={({item}) => (
              isReagent(item) ? renderJarProviders(item) : ''
            )}
            align={'left'}
        />
        <GridTableCol
            render={renderButtons}
        />
      </ReagentsTableStyled>
      {/*Dialogs*/}
      <ReactionEditDialogWrap
        dialogName={ReactionEditDialogsMap.AddSubstance}
        onJarsSelected={addReagentAndRecalc}
        dialogProps={{isInStockOnly: true}}
        excludedAgentIds={props.reagentsList.map((reagent: IReagent) => reagent?.AgentAvailability?.AgentId)}
        getDialogRef={(dialog: TReactionEditDialogRefType) => {
          if (dialog) {
            addSubstanceDialogRef.current = dialog;
          }
        }}
      />
      <ReactionEditDialogWrap
        dialogName={ReactionEditDialogsMap.ReactionSpendings}
        getDialogRef={(dialog) => {
          if (dialog) {
            reactionSpendingsRef.current = dialog;
          }
        }}
        reagents={props.reagentsList}
        reactionName={props.reaction?.Name}
        onAfterSave={(reagentConsumptions: any[]) => props.closeSpendingsDialog(reagentConsumptions)}
      />
        {
            (reagentDialogCompound && typeof reagentDialogCompound === 'object') &&
                <ReactionPurificationModal
                    onCancel={() => {
                        setReagentDialogCompound(null);
                        setReagentDialogAgentId(null);
                        setReagentDialogIsProduct(null);
                    }}
                    compound={reagentDialogCompound}
                    agentId={reagentDialogAgentId}
                    showReactionJars={!reagentDialogIsProduct || props.reaction.IsCompleted}
                    isProduct={reagentDialogIsProduct}
                />
        }
    </div>
  );
};

ReagentsTable = connect(
  (state: IRootStore): IReagentsTableProps => ({
    reaction: getReactionFromForm(state),
    reactantsList: getReactantsFromForm(state),
    reagentsList: getReagentsFromForm(state),
    productsList: getProductsFromForm(state),
    reactionStates: getCurrentReactionStates(state),
    formErrors: getReagentsErroredFieldNames(state),
    highlightedSubstance: getHighlightedSubstance(state),
    formIsValid: isValid(REACTION_EDIT_FORM_NAME),
    userPermissions: userCRUDPermissionsSelector(state),
  }),
  (dispatch): IReagentsTableProps => ({
    getReagentsList: (id) => dispatch(reagentsResourceActions.list.request({uriParams: {id}})),
    getProductsList: (id) => dispatch(productsResourceActions.list.request({uriParams: {id}})),
    editReagentComment: (id, data) => dispatch(reagentsResourceActions.editReagentComment.request({ uriParams: {id}, data })),
    recalcReagent: (reagentIdx: number) => dispatch(reactionModuleActions.recalc.reagent({ reagentIdx, debounce: true })),
    recalcProduct: (productIdx: number) => dispatch(reactionModuleActions.recalc.product({ productIdx, debounce: true })),
    recalcAll: () => dispatch(reactionModuleActions.recalc.all({ debounce: true })),
    setReferencedReagent: (reagent: IReagent) => dispatch(reactionModuleActions.reactionEdit.setReferencedReagent({ reagent })),
    addSubItem: (reagent: IReagent) => dispatch(reactionModuleActions.reactionEdit.addSubItem({ reagent })),
    setHighlightedSubstance: (idx: number) => dispatch(reactionModuleActions.reactionEdit.setHoveredImage({ idx })),
    addReagent: (agent: IAgent, jars: IJarStock[], availability: IJarStock, reagentType: number) => dispatch(reactionModuleActions.reactionEdit.addReagent({ agent, jars, availability, reagentType })),
    removeReagent: (reagentIdx: number) => dispatch(reactionModuleActions.reactionEdit.removeReagent({ reagentIdx })),
    openSpendingsDialog: (id: string, name: string) => dispatch(reactionModuleActions.reactionEdit.openSpendingsDialog({ id, name })),
    closeSpendingsDialog: (reagentConsumptions) => dispatch(reactionModuleActions.reactionEdit.closeSpendingsDialog({ reagentConsumptions })),
  })
)(ReagentsTable);

ReagentsTable = styled(ReagentsTable)`
  height: 100%;
  display: grid;
  grid-template-rows: min-content minmax(0, 1fr);
  .b-InlineEdit-mod-Readonly.b-InlineEdit:not(.b-InlineEdit-mod-Edit)::before {
    content: none;
  }
  .buttons-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .grid-table {
    border-bottom: none;
    margin-bottom: 0;
  }
  .grid-table-row-wrap {
    &.is-expanded > .grid-table-row {
      border-bottom: none;
    }
    &.limited-height> .grid-table-row {
      padding: 8px 16px;
    }
  }
  .grid-table-row-wrap:last-of-type {
    .grid-table-row {
      border-bottom: 1px solid var(--border-color);
    }
  }
  .grid-table-row-before {
    background-color: inherit !important;
  }
  .jar-providers-more {
    font-size: 12px;
    color: var(--grey-600);
    margin-left: auto;
    cursor: default;
    width: min-content;
    white-space: nowrap;
  }
  .reagent-consumption-warning {
    color: var(--orange);
    transform: translate(-12px, 0);
    font-size: 12px;
    margin-left: -4px;
    margin-right: -12px;
  }
`;

export {
  ReagentsTable,
  REACTION_REACTANTS_ARRAY_FIELD_NAME,
};
