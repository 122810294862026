// @flow

import {
  put,
  takeEvery,
  fork,
  select,
} from 'redux-saga/effects';
import {getToken} from '../../account/reducers';
import type { IRequestResourceAction } from '../../shared/utils/action-creators';
import { productsResourceActions } from '../actions';
import { ProductsResourceService } from '../services';
import { ReactionsResourceService } from '../../reactions/services';

function* getProduct({uriParams}: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const result = yield ProductsResourceService.get({uriParams}, token);
    yield put(productsResourceActions.get.success(result));
  } catch (error) {
    yield put(productsResourceActions.get.failure({error}));
  }
}

export function* listProducts({params, uriParams}: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const result = yield ReactionsResourceService.getProducts({params, uriParams}, token);
    yield put(productsResourceActions.list.success(result));
  } catch (error) {
    yield put(productsResourceActions.list.failure({error}));
  }
}

function* updateProduct({uriParams, data}: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const result = yield ProductsResourceService.update({uriParams, data}, token);
    yield put(productsResourceActions.update.success(result));
  } catch (error) {
    yield put(productsResourceActions.update.failure({error}));
  }
}

function* createProduct({data}: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const result = yield ProductsResourceService.create({data}, token);
    yield put(productsResourceActions.create.success(result));
  } catch (error) {
    yield put(productsResourceActions.create.failure({error}));
  }
}

function* deleteProduct({uriParams}: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const result = yield ProductsResourceService.delete({uriParams}, token);
    yield put(productsResourceActions.delete.success(result));
  } catch (error) {
    yield put(productsResourceActions.delete.failure({error}));
  }
}

export const productsResourceWatchers = [
  function* () {
    yield takeEvery(productsResourceActions.get.request.type, function* (action) {
      yield fork(getProduct, action);
    })
  },

  function* () {
    yield takeEvery(productsResourceActions.list.request.type, function* (action) {
      yield fork(listProducts, action);
    })
  },

  function* () {
    yield takeEvery(productsResourceActions.update.request.type, function* (action) {
      yield fork(updateProduct, action);
    })
  },

  function* () {
    yield takeEvery(productsResourceActions.create.request.type, function* (action) {
      yield fork(createProduct, action);
    })
  },

  function* () {
    yield takeEvery(productsResourceActions.delete.request.type, function* (action) {
      yield fork(deleteProduct, action);
    })
  },
];
