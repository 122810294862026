// @flow

import {
	moduleAction,
} from '../../shared/utils';
import type { ICatalogReaction, ICatalogReactionRequestParams, IReaction, IRepkaTask } from '../models';
import { _noop } from '../../shared/utils/common';
import type { RecalcFunctionArgs } from '../helpers/equation.helper';
import type { IReagent } from '../../reagents/model';
import type { IProduct } from '../../products/model';
import type { IAgent, IJarStock } from '../../agents/models';
import type { IRequestResourceAction, ISuccessResourceAction } from '../../shared/utils';

export const REACTIONS_MODULE_NAME = 'REACTIONS';

export type TEditReactionDialogAction = {
	id: string,
	name: string,
}

export type TReagentAddFromDialogAction = {
	agent: IAgent,
	jars: IJarStock[],
	availability: IJarStock,
	reagentType: number
}

export type TReagentEditFromDialogAction = {
	...TReagentAddFromDialogAction,
	reagentId: number,
	reagentLocalUuid: string,
}

export const reactionModuleActions = {
	catalogSearchParams: {
		update: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_CATALOG', 'UPDATE'],
			({ params }: { params: ICatalogReactionRequestParams }) => ({ params })
		)
	},
	clear: moduleAction(
		REACTIONS_MODULE_NAME,
		['CLEAR'],
		() => ({})
	),
	reactionEdit: {
		loadInitialData: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'LOAD_INITIAL_DATA'],
			({ isNew, isCloning }) => ({ isNew, isCloning })
		),
		setCurrentReactionId: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'REACTION_ID', 'SET'],
			({ reactionId }) => ({ reactionId })
		),
		setIsPurification: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'IS_PURIFICATION', 'SET'],
			({ isPurification }) => ({ isPurification })
		),
		setIsArchived: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'IS_ARCHIVED', 'SET'],
			({ isArchived }) => ({ isArchived })
		),
		setSmilesFormula: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'SMILES_FORMULA', 'SET'],
			({ smilesFormula }) => ({ smilesFormula })
		),
		setCompoundName: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'COMPOUND_NAME', 'SET'],
			({ compoundName }) => ({ compoundName })
		),
		setNestedPurification: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'NESTED_PURIFICATION', 'SET'],
			({ data }) => ({ data })
		),
		setCurrentStageId: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'STAGE_ID', 'SET'],
			({ stageId }) => ({ stageId })
		),
		setCurrentFolderId: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'FOLDER_ID', 'SET'],
			({ folderId }) => ({ folderId })
		),
		setCurrentSaveStatus: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'SAVE_STATUS', 'SET'],
			({ status }) => ({ status })
		),
		setNewReactionId: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'NEW_REACTION_ID', 'SET'],
			({ reactionId }) => ({ reactionId })
		),
		setCloneSourceReactionId: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'CLONE_SOURCE_ID', 'SET'],
			({ sourceReactionId }) => ({ sourceReactionId })
		),
		resetClone: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'CLONE', 'RESET'],
			({ originalId, authorUserName, stageId }) => ({ originalId, authorUserName, stageId })
		),
		setCurrentReactionName: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'NAME', 'SET'],
			({ result }: ISuccessResourceAction) => ({ result })
		),
		setReferencedReagent: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'REFERENCED_REAGENT', 'SET'],
			({ reagent }) => ({ reagent })
		),
		addSubItem: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'SUB_ITEM', 'ADD'],
			({ reagent }) => ({ reagent })
		),
		addReagent: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'REAGENT', 'ADD'],
			({ agent, jars, availability, reagentType }: TReagentAddFromDialogAction) => ({ agent, jars, availability, reagentType })
		),
		addMultipleReagents: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'REAGENT', 'ADD_MULTIPLE'],
			({ results }: { results: TReagentAddFromDialogAction[] }) => ({ results })
		),
		addArchivedReagent: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'ARCHIVED_REAGENT', 'ADD'],
			({ compound }) => ({ compound })
		),
		editSingleReagentJars: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'REAGENT', 'EDIT_JARS'],
			({ results }: { results: TReagentEditFromDialogAction }) => ({ results })
		),
		removeReagent: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'REAGENT', 'REMOVE'],
			({ reagentIdx }: { reagentIdx: number }) => ({ reagentIdx })
		),
		addProduct: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'PRODUCT', 'ADD'],
			({ product }: { product: IProduct }) => ({ product })
		),
		updateReagentsConsumption: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'REAGENT', 'CONSUMPTION'],
			({ reagentConsumptions }) => ({ reagentConsumptions })
		),
		setReactionParentRepkaTaskId: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'REPKA_TASK', 'SET'],
			({ task }: { task: IRepkaTask }) => ({ task })
		),
		setHoveredImage: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'HOVERED_IMAGE', 'SET'],
			({ idx }) => ({ idx })
		),
		openDialog: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'DIALOGS', 'OPEN'],
			({ name, id }: TEditReactionDialogAction) => ({ name, id })
		),
		closeLastDialog: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'DIALOGS', 'CLOSE_LAST'],
			_noop
		),
		setDialogsQueue: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'DIALOGS', 'SET'],
			({ dialogs }: { dialogs: TEditReactionDialogAction[] }) => ({ dialogs })
		),
		registerDialog: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'DIALOGS', 'REGISTER'],
			({ name, id }) => ({ name, id })
		),
		removeDialogFromQueue: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'DIALOGS', 'REMOVE'],
			({ id, name }: TEditReactionDialogAction) => ({ id, name })
		),
		insertDialogToQueue: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'DIALOGS', 'INSERT'],
			({ id, name, position }: { ...TEditReactionDialogAction, position: number }) => ({ id, name, position })
		),
		clearDialogsQueue: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'DIALOGS', 'CLEAR'],
			_noop
		),
		saveReaction: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'SAVE'],
			_noop
		),
		checkSave: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'SAVE', 'CHECK'],
			({ check }) => ({ check })
		),
		openSpendingsDialog: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'OPEN_SPENDINGS'],
			({ id, name }) => ({ id, name })
		),
		closeSpendingsDialog: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'CLOSE_SPENDINGS'],
			({ reagentConsumptions }) => ({ reagentConsumptions })
		),
		cloneReaction: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'CLONE'],
			_noop
		),
		addReactionAttachments: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_ATTACHMENTS', 'ADD'],
			({ attachments }) => ({ attachments }),
		),
		deleteReactionAttachments: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_ATTACHMENTS', 'DELETE'],
			({ attachments }) => ({ attachments }),
		),
		clearReactionAttachments: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_ATTACHMENTS', 'CLEAR'],
			_noop
		),
		getTechInstruction: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_EDIT', 'GET_TECH_INSTRUCTION'],
			({ reactionId, reactionImage }: {reactionId: number, reactionImage: string}) => ({ reactionId, reactionImage })
		),
  },

reactionComplete: {
	success: moduleAction(
		REACTIONS_MODULE_NAME,
		['REACTION_COMPLETE', 'SUCCESS'],
		({ isSuccess }) => ({ isSuccess })
	)
},

repkaTask: {
	select: moduleAction(
		REACTIONS_MODULE_NAME,
		['REPKA_TASK_SELECT'],
		({ task }) => ({ task })
	),
	save: moduleAction(
		REACTIONS_MODULE_NAME,
		['REPKA_TASK_SAVE'],
		() => null
	),
	load: moduleAction(
		REACTIONS_MODULE_NAME,
		['REPKA_TASK_LOAD'],
		() => null
	),
	clearList: moduleAction(
		REACTIONS_MODULE_NAME,
		['REPKA_TASK_LIST_CLEAR'],
		() => null
	),
	destroy: moduleAction(
		REACTIONS_MODULE_NAME,
		['REPKA_TASK_DESTROY'],
		() => null
	),
},

jarStock: {
	setAgentsData: moduleAction(
		REACTIONS_MODULE_NAME,
		['JAR_STOCK_SET_AGENTS_DATA'],
		(agents) => ({agents})
	),
	loadSaved: moduleAction(
		REACTIONS_MODULE_NAME,
		['JAR_STOCK_LOAD'],
		() => ({})
	),
	save: moduleAction(
		REACTIONS_MODULE_NAME,
		['JAR_STOCK_SAVE'],
		() => ({})
	),
	destroy: moduleAction(
		REACTIONS_MODULE_NAME,
		['JAR_STOCK_DESTROY'],
		() => ({})
	),
	selectedJars: {
		add: moduleAction(
			REACTIONS_MODULE_NAME,
			['JAR_STOCK', 'SELECTED_JARS_ADD'],
			({ jar }) => ({ jar })
		),
		delete: moduleAction(
			REACTIONS_MODULE_NAME,
			['JAR_STOCK', 'SELECTED_JARS_DELETE'],
			({ id, reagentId }) => ({ id, reagentId })
		),
	},
	validateAgents: moduleAction(
		REACTIONS_MODULE_NAME,
		['JAR_STOCK', 'VALIDATE_AGENTS'],
		({ agentsId, reagents }) => ({ agentsId, reagents })
	),
},

recalc: {
	reagent: moduleAction(
		REACTIONS_MODULE_NAME,
		['RECALC', 'REAGENT'],
		({ reagentIdx, debounce = false }) => ({ reagentIdx, debounce })
	),
	product: moduleAction(
		REACTIONS_MODULE_NAME,
		['RECALC', 'PRODUCT'],
		({ productIdx, debounce = false }) => ({ productIdx, debounce })
	),
	reaction: moduleAction(
		REACTIONS_MODULE_NAME,
		['RECALC', 'REACTION'],
		({ debounce = false }) => ({ debounce })
	),
	all: moduleAction(
		REACTIONS_MODULE_NAME,
		['RECALC', 'ALL'],
		({ debounce = false }) => ({ debounce })
	),
	},
	
	settings: {
		setRepkaRedirectTaskUrl: moduleAction(
			REACTIONS_MODULE_NAME,
			['SETTINGS', 'SET_REPKA_REDIRECT_TASK_URL'],
			({ url }) => ({ url })
		),
		initRepkaRedirectTaskUrl: moduleAction(
			REACTIONS_MODULE_NAME,
			['SETTINGS', 'INIT_REPKA_REDIRECT_TASK_URL'],
			() => ({})
		),
	},

	sidebar: {
		setHistoricalCount: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_SIDEBAR', 'SET_HISTORICAL_COUNT'],
			({ count }) => ({ count })
		),
		requestHistoricalCount: moduleAction(
			REACTIONS_MODULE_NAME,
			['REACTION_SIDEBAR', 'REQUEST_HISTORICAL_COUNT'],
			({ stageId }) => ({ stageId }),
		),	
	},

	template: {
		requestSearchById: moduleAction(
			REACTIONS_MODULE_NAME,
			['TEMPLATE', 'REQUEST_SEARCH_BY_ID'],
			({ reactionId, checkEmpty }) => ({ reactionId, checkEmpty }),
		),
		setSearchedReaction: moduleAction(
			REACTIONS_MODULE_NAME,
			['TEMPLATE', 'SET_SEARCHED_REACTION'],
			({ result }) => ({ result }),
		),
		setIsPending: moduleAction(
			REACTIONS_MODULE_NAME,
			['TEMPLATE', 'SET_IS_PENDING'],
			({ value }) => ({ value }),
		),	
		specTemplateApplied: moduleAction(
			REACTIONS_MODULE_NAME,
			['TEMPLATE', 'SPEC_TEMPLATE_APPLIED'],
			({ value }) => ({value}),
		),
		setReagents: moduleAction(
			REACTIONS_MODULE_NAME,
			['TEMPLATE', 'SET_TEMPLATE_REAGENTS'],
			({ reagents }) => ({ reagents }),
		),	
		setReactantsInfo: moduleAction(
			REACTIONS_MODULE_NAME,
			['TEMPLATE', 'SET_TEMPLATE_REACTANTS_INFO'],
			({ reactants }) => ({ reactants }),
		),	
		useTemplate: moduleAction(
			REACTIONS_MODULE_NAME,
			['TEMPLATE', 'USE_TEMPLATE'],
			() => ({}),
		),	
		useSpecificTemplateReaction: moduleAction(
			REACTIONS_MODULE_NAME,
			['TEMPLATE', 'USE_SPEC_TEMPLATE_REACTION'],
			(reactionId) => ({reactionId}),
		),	
		clear: moduleAction(
			REACTIONS_MODULE_NAME,
			['TEMPLATE', 'CLEAR'],
			() => ({}),
		),
		setEmptyJarsInfo: moduleAction(
			REACTIONS_MODULE_NAME,
			['TEMPLATE', 'SET_EMPTY_JARS_INFO'],
			({emptyJarsInfo}) => ({ emptyJarsInfo })
		),
		setHasEmptyJars: moduleAction(
			REACTIONS_MODULE_NAME,
			['TEMPLATE', 'SET_HAS_EMPTY_JARS'],
			({hasEmptyJars}) => ({ hasEmptyJars })
		)
	},
};
