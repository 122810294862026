import './index.less';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Dialog, { DialogLayout } from '@biocad/bcd-front-ui/layout/Dialog';
import Button from '@biocad/bcd-front-ui/controls/Button';
import { schemesResourceActions, schemeModuleActions } from '../../actions';
import { comparingSchemesSelector, selectedStageIdSelector } from '../../reducers/selectors';

const SchemesLimit = 4096;

interface ISchemeCompareSelectDialogProps {
  scheme: {},
  onClose: () => void,
  onSelect: () => void,
  setComparingSchemeIds: (schemeIds: []) => void,
  storedSelectedSchemeIds: [],
}

let SchemeCompareSelectDialog = (props : ISchemeCompareSelectDialogProps) => {
  const [selectedIds, setSelectedIds] = useState([]);

  function initDialog() {
    if(!props.scheme)
      return;
    props.getSchemes(props.scheme.FinalCandidateId);
    if(props.storedSelectedSchemeIds && props.storedSelectedSchemeIds.length) {
      setSelectedIds([...props.storedSelectedSchemeIds]);
    }
  }
  useEffect(initDialog, [props?.scheme?.Id]);

  function toggleScheme(id) {
    if(selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter(i => i !== id));
    }
    else {
      setSelectedIds([...selectedIds, id]);
    }
  }

  function select() {
    props.setComparingSchemeIds(selectedIds);
    props.onSelect();
  }

  return(
    <>
      <Dialog paranjaClose={false} close={props.onClose}>
        <div className={'scheme-compare-dialog'}>
          <header className={'dialog-header'}>
            <h3>Выбрать схемы для сравнения</h3>
          </header>
          <div className={'dialog-contents'}>
            {props.comparingSchemes && props.comparingSchemes.map(s => (
            <div key={s.Id}
                 onClick={() => toggleScheme(s.Id)}
                 className={`scheme-item ${selectedIds.includes(s.Id) && 'selected'}`}>
              {s.Code}
            </div>)
            )}
          </div>
          <footer className={'dialog-footer'}>
            <Button
              text={'Выбрать'}
              view='primary'
              size={'L'}
              onAction={select}
              disabled={!selectedIds.length}
            />
            <Button
              text='Отмена'
              size={'L'}
              onAction={props.onClose}
            />
          </footer>
        </div>
      </Dialog>
    </>
  );

};

SchemeCompareSelectDialog = withRouter(SchemeCompareSelectDialog);
SchemeCompareSelectDialog = connect(
  (state) => ({
    comparingSchemes: comparingSchemesSelector(state),
    storedSelectedSchemeIds: state?.modules?.scheme?.compareScheme?.comparingSchemeIds,
  }),
  (dispatch) => ({
    getSchemes: (candidateId) => dispatch(schemesResourceActions.list.request({ params: { 
      FinalCandidateId: candidateId,
      ShowOnlyTopLevelSchemes: true,
      IgnoreFolders: true,
      Order: '-CreatedAt',
      Skip: 0,
      Limit: SchemesLimit
     } })),
     setComparingSchemeIds: (schemeIds) => dispatch(schemeModuleActions.setComparingSchemeIds({schemeIds})),
  })
)(SchemeCompareSelectDialog);

export {
  SchemeCompareSelectDialog
};