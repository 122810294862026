// @flow

import {resourceReducer} from '../../shared/utils';
import {jarAmountsByLabsResourceActions} from '../actions/jar-amounts-by-labs';
import {createSelector} from 'reselect';
import type {IRootStore} from '../../app/reducers';

export const jarAmountsByLabsResourceReducer = resourceReducer(
    jarAmountsByLabsResourceActions,
    {
        [jarAmountsByLabsResourceActions.get.success]: (state, action) =>
        ({
            ...state,
            pending: action.pending,
            fail: action.fail,
            data: { ...state.data, [action.result ? action.result.AgentId : 0]: action.result },
            params: action.params,
            error: null,
            requestSucceed: true,
        })
    }
);

export const
    jarAmountsByLabsListSelector = createSelector(
        [
            (state: IRootStore) => state.resource.jarAmountsByLabs,
        ],
        (data) => Object.values(data)
    )
;
