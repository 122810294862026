// @flow

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Button from '@biocad/bcd-front-ui/controls/Button';
import Dialog from '@biocad/bcd-front-ui/layout/Dialog';
import classNames from 'classnames';
import css from './repka.m.less';
import './tooltip-custom.less';
import { RepkaTaskSelector } from './RepkaTaskSelector';
import { reactionModuleActions } from '../../actions';
import type { IReactionEditDialogWrapComponentProps } from '../ReactionEdit/ReactionEditDialogWrap';
import type { IRepkaTask } from '../../models';
import type { IRootStore } from '../../../app/reducers';
import { Dispatch } from 'redux';
import { _noop } from '../../../shared/utils/common';

interface IRepkaTaskDialogProps extends IReactionEditDialogWrapComponentProps {
  stageId: number,
  selectedTask?: IRepkaTask,
  saveTask?: ()=>void,
  loadTask?: ()=>void,
  onCancel?: ()=>void,
}

const defaultProps: IRepkaTaskDialogProps = {
  onCancel: _noop,
};

let RepkaTaskDialog = (props: IRepkaTaskDialogProps = defaultProps) => {
  useEffect(() => {
    props.loadTask();
  }, []);

  const onCancelClick = () => {
    props.closeDialog();
    props.onCancel();
  };

  const onSaveClick = () => {
    props.saveTask();
    props.closeDialog();
  };
  
  return (
    <>
      <Dialog paranjaClose={false} close={onCancelClick}>
        <div className={classNames('dialog-layout', css.dialogLayout)}>
          <header className={'dialog-header'}>
            <h3>Выберите задачу</h3>
          </header>
          <div className={'dialog-contents'}>
            <RepkaTaskSelector stageId={props.stageId} />
          </div>
          <footer className={'dialog-footer'}>
            <Button
              onAction={onSaveClick}
              text={'Выбрать'}
              size={'L'}
              view={'primary'}
              disabled={!props.selectedTask}
            />
            <Button
              onAction={onCancelClick}
              text={'Отменить'}
              size={'L'}
            />
          </footer>
        </div>
      </Dialog>
    </>
  );
};

RepkaTaskDialog = connect(
  (state: IRootStore): IRepkaTaskDialogProps => ({
    selectedTask: state.modules.reaction.repkaTask.selected,
  }),
  (dispatch: Dispatch): IRepkaTaskDialogProps => ({
    saveTask: () => dispatch(reactionModuleActions.repkaTask.save()),
    loadTask: () => dispatch(reactionModuleActions.repkaTask.load()),
  })
)(RepkaTaskDialog);

export { RepkaTaskDialog };

