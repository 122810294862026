// @flow
import { createSelector } from 'reselect';
import { itemsToArraySelector } from '../../shared/utils/selectors/resource-selectors';
import type { OptionType } from '../../shared/models';
import type { IReaction } from '../models';
import type { IRootStore } from '../../app/reducers';

export const schemeViewSelector = (state: IRootStore) => state?.resource?.schemes?.custom?.schemeView;
export const selectedStageIdSelector = (state: IRootStore) => state?.modules?.scheme?.editScheme?.selectedStageId;
export const selectedStageResourceSelector = (state: IRootStore) => state?.resource?.stages?.data[selectedStageIdSelector(state)];
export const currentSchemeIdSelector = (state: IRootStore) => state?.modules?.scheme?.editScheme?.currentSchemeId;
export const schemeSelector = (state: IRootStore) => state?.resource?.schemes?.data[currentSchemeIdSelector(state)];

export const comparingSchemesSelector = createSelector(
  (state) => itemsToArraySelector(state?.resource?.schemes),
  (state) => currentSchemeIdSelector(state),
  (data, currentSchemeId) => data.filter(s => s.Id !== currentSchemeId)
);

export const anyLoadedJarAmountShownSelector = createSelector(
    [
        (state: IRootStore) => state.modules.scheme.schemeTree.jarAmounts
    ],
    (amounts) =>
    (
        Object.values(amounts).some(a => a.show === true)
    )
)