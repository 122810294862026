// @flow

import { resourceReducer } from '../../shared/utils/reducer-creators';
import { tagsResourceActions } from '../../agents/actions';
import { createSelector } from 'reselect';
import type { IRootStore } from '../../app/reducers';
import type { ITag } from '../models';
import type { OptionType } from '../../shared/models';

export const tagsResourceReducer = resourceReducer(tagsResourceActions);

export const
	getTagsSelector = createSelector(
		(state: IRootStore) => state.resource.dictionary.tags.data,
		(tags: {}) => Object.values(labs),
	),
	getTagsPendingSelector = (
		(state: IRootStore) => state.resource.dictionary.tags.pending
	),
	getTagsAsOptions = createSelector(
		[getTagsSelector],
		(tags: ITag[]): OptionType[] => tags.map((tag: ITag): OptionType => ({ label: tag.Name, value: tag.Id }))
	)
;
