import cloneDeep from 'lodash/cloneDeep';
import { AnalyticsModuleActions } from '../actions/analytics-module';
import { AnalyticsSmallMolecule, IAnalyticsEntity, smEntityTypes} from '../models';

interface initialStateTypes {
  currentEntity: IAnalyticsEntity,
  molecules: AnalyticsSmallMolecule[],
  currentMolecule: AnalyticsSmallMolecule,
  currentIndex: number,
  pending: boolean,
}

const initialState: initialStateTypes = {
  currentEntity: null,
  molecules: [],
  currentMolecule: null,
  currentIndex: null,
  pending: false,
  hasChanges: false,
  listToUpdate: [],
  listToDelete: [],
};

const requestState = {
  pending: true,
  currentMolecule: null,
};

const failureState = {
  pending: false,
  currentMolecule: null,
  currentIndex: null,
};

export const analyticsModuleReducer = (state: initialStateTypes = initialState, action) => {

  const reduceFn = {

      [AnalyticsModuleActions.updateMoleculeApi.request]:() => ({
        ...state,
        ...requestState
      }),
      [AnalyticsModuleActions.updateMoleculeApi.failure]:() => ({
        ...state,
        ...failureState
      }),
      [AnalyticsModuleActions.updateMoleculeApi.success]:() => {
        
        const {result} = action;
        const molecules = cloneDeep(state.molecules);
        molecules[result.index] = new AnalyticsSmallMolecule({molecule: result.molecule});

        return { 
          ...state,
          pending: false,
          molecules: molecules,
          currentMolecule: null,
          currentIndex: null,
          listToUpdate: []
        };
      },

      
      [AnalyticsModuleActions.createMoleculeApi.request]:() => ({...state, ...requestState}),
      [AnalyticsModuleActions.createMoleculeApi.failure]:() => ({...state, ...failureState}),
      [AnalyticsModuleActions.createMoleculeApi.success]:() => {
        const {result} = action;
        const molecules = cloneDeep(state.molecules);
        molecules[result.index] = new AnalyticsSmallMolecule({molecule: result.molecule});
        
        return { 
          ...state,
          pending: false,
          molecules: molecules,
          currentMolecule: null,
          currentIndex: null,
        };
      },


      [AnalyticsModuleActions.deleteMoleculeApi.request]:() => ({
        ...state,
        ...requestState,
      }),
      [AnalyticsModuleActions.deleteMoleculeApi.failure]:() => ({...state, ...failureState}),
      [AnalyticsModuleActions.deleteMoleculeApi.success]:() => {
        
        const {result} = action;
        const molecules = cloneDeep(state.molecules);
        if (result.molecule.IsSuccess) {
          molecules[result.index].IsDeleted = true;
        }

        return {
          ...state,
          pending: false,
          molecules,
          listToDelete: []
        };
      },

      [AnalyticsModuleActions.setEntity]: () => {
        return {
          ...state,
          currentEntity: {
            ...action.entity
          }
        };
      },

      [AnalyticsModuleActions.setMolecules]: () => {
        return {
          ...state,
          molecules: [
            ...action.molecules.map(molecule => new AnalyticsSmallMolecule({ molecule }))
          ]
        };
      },

      [AnalyticsModuleActions.actualizeReactionInfo]: () => {
        return {
          ...state,
          molecules: [
            ...state.molecules.map(mol => ({...mol, ReactionId: action.reactionId, ProductId: action.productId}))
          ]
        };
      },

      [AnalyticsModuleActions.createCurrentMolecule]: () => {
        const params = state.currentEntity.type === smEntityTypes.Reaction
          ? {
              ReactionId: state.currentEntity.id,
              SampleName: `${state.currentEntity.reaction.Name}-${state.molecules.length + 1}`
            }
          : {AgentAvailabilityId: state.currentEntity.id};

        return {
          ...state,
          currentMolecule: new AnalyticsSmallMolecule({...params}),
          currentIndex: state.molecules.length
        };
      },


      [AnalyticsModuleActions.setCurrentMolecule]: () => ({
        ...state,
        currentMolecule: new AnalyticsSmallMolecule({molecule: action.molecule}),
        currentIndex: action.index
      }),

      [AnalyticsModuleActions.clearCurrentMolecule]: () => ({
        ...state,
        currentMolecule: null,
      }),

      [AnalyticsModuleActions.saveCurrentMolecule]: () => {
        
        const molecules = cloneDeep(state.molecules);
        molecules[state.currentIndex] = new AnalyticsSmallMolecule({molecule: action.molecule});
        
        const listToUpdate = [...state.listToUpdate];
        action.molecule.Id && listToUpdate.push(action.molecule.Id);

        return { 
          ...state,
          hasChanges: true,
          molecules: molecules,
          currentMolecule: null,
          currentIndex: null,
          listToUpdate: [
            ...(new Set(listToUpdate))
          ]
        };
      },

      [AnalyticsModuleActions.deleteMolecule]:() => {
        
        let molecules = cloneDeep(state.molecules);
        const listToDelete = [...state.listToDelete];        

        if (action.molecule.Id !== 0) {
          molecules[action.index].IsDeleted = true;
          listToDelete.push(action.molecule.Id);
        } else {
          molecules = [...molecules.filter((x, index) => index !== action.index)];
        }
        
        return {
          ...state,
          hasChanges: true,
          pending: false,
          molecules: [
            ...molecules
          ],
          listToDelete: [
            ...(new Set(listToDelete))
          ],
          listToUpdate: [
            ...state.listToUpdate.filter(x => !listToDelete.includes(x))
          ]
        };
      },


      [AnalyticsModuleActions.newTest]: () => {
        const molecule = new AnalyticsSmallMolecule({molecule: action.formValues});
        molecule.newTest({});
        return {
          ...state,
          hasChanges: true,
          currentMolecule: molecule
        };
      },


      [AnalyticsModuleActions.deleteTest]: () => {
        const molecule = new AnalyticsSmallMolecule({molecule: action.formValues});
        molecule.deleteTest(action.index);
        return {
          ...state,
          hasChanges: true,
          currentMolecule: molecule
        };
      },

      [AnalyticsModuleActions.saveAnalyticsApi.request]:() => ({...state, pending: false, }),
      [AnalyticsModuleActions.saveAnalyticsApi.failure]:() => ({...state, pending: false, }),
      [AnalyticsModuleActions.saveAnalyticsApi.success]:() => {

        return { 
          ...initialState
        };
      },


      [AnalyticsModuleActions.completeChanges]: () => {
        return {
          ...state,
          hasChanges: false,
        };
      },

      [AnalyticsModuleActions.destroyAnalytics]: () => {
        return {
          ...initialState
        };
      },

      
  }[action.type];
  return reduceFn ? reduceFn() : state;
};

// Selectors
export const analyticsHasChangesSelector = (state: any) => state?.modules.analytics.hasChanges;