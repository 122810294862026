import React from 'react';
import styles from './icon-button-backwards.m.less';
import './icon-button-backwards.less';
import { IconButton } from '../IconButton';
import { withRouter } from 'react-router-dom';

let IconButtonBackwards = ({iconClassName, className, onClick, history, staticContext, disabled, ...rest}) => {
    
  const classNames = [
    'icon-button-backwards-component',                     //Глобальный класс для доступа снаружи
    iconClassName ? iconClassName : 'fo-folder-back',    // По умолчанию иконка - стрелка, но можно поменять на свою

    styles.iconButtonBackwards,
    className && className,
  ].join(' ');

  const goBack = () => history.goBack();

  const onClickHandler = (event) => {
    event.stopPropagation();
    onClick ? onClick() : goBack();
  };

  return (
    <IconButton
      className={classNames}
      onClick={event => !disabled && onClickHandler(event)}
      disabled={disabled}
      {...rest}
    />
  );
};

IconButtonBackwards = withRouter(IconButtonBackwards);

export {
  IconButtonBackwards
};
