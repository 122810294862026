import React from 'react';
import {connect} from 'react-redux';
import Loader from '@biocad/bcd-front-ui/controls/Loader';
import './index.less';

interface LoaderControlProps {
    isLoading: boolean
}

const initialProps = {
    isLoading: false
};

const LoaderControl = (props: LoaderControlProps = initialProps) => {
    return (
        <React.Fragment>
            {props.isLoading &&
                <div className='globalLoaderParanja'>
                    <Loader text={'Загрузка'} />
                </div>
            }
        </React.Fragment>
    );
};

const mapStateToProps = ({global}) => {
    return {
        isLoading: global.application?.isLoading
    };
};

export default connect(
    mapStateToProps,
    null
)(LoaderControl);
