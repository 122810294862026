// @flow

import React, {useState, useEffect, useRef} from 'react';
import {IconButton} from '../IconButton';

export type FavoritesWrapperOnSaveReturnObject = {
    itemId: number,
};

interface IFavoritesWrapperProps {
    item: Object, // должен содержать поле QuickComment
    saveIsFavorite: (CommentWrapperOnSaveReturnObject)=>void,
    iconClassName?: string,
}

const initialProps: ICommentWrapperProps = {
    item: null,
    saveIsFavorite: (value) => null,
};

const FavoritesWrapper = (props: IFavoritesWrapperProps = initialProps) => {
    // refs
    const
        iconButtonRef = useRef(null)
    ;

    const [isFavoriteValue, setIsFavoriteValue] = useState(false)


    useEffect(() => {
        setIsFavoriteValue(props.item.IsFavorite);
    }, []);

    const toggleIsFavorite = () => {
        props.saveIsFavorite({itemId: props.item?.Id, isFavorite: !isFavoriteValue});
        setIsFavoriteValue(value => !value);
    }


    return (
        <span style={{ display: 'contents' }} onClick={(event) => event.stopPropagation()}>
            <IconButton iconClassName={isFavoriteValue?`fo-star-fill`:`fo-star`}
                        disabled={false}
                        onClick={toggleIsFavorite}
                        ref={iconButtonRef}
            >                
            </IconButton>

        </span>
    );
};

export default FavoritesWrapper;
