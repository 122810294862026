// @flow
import { Model } from 'objectmodel';
import { JarUser } from './jar-user';
import { ArrayModel } from 'objectmodel';
import { IJarStock } from './i-jar-stock';

export class JarStock implements IJarStock { // @todo нфдо будет переделать на objectmoddel и модель ниже переименовать в JarStock
  constructor(data: IJarStock) {
      this.Number           = '';
      this.Provider         = '';
      this.Qualification    = '';
      this.Cost             = '';
      this.Comment          = '';
      this.MainAgentContent = '';
      this.Concentration    = '';
      this.Density          = '';
      this.PackageWeight    = '';
      this.Amount           = '';
      this.LaboratoryId     = '';
      this.Location         = '';
      this.Series           = '';
      this.Attachments      = [];
      this.IsOrdered        = null;
      
      if (data && typeof data === 'object') {
        const _data = Object.entries(data).reduce((acc, cur) => ({ ...acc, [cur[0]]: cur[1] === null ? '' : cur[1] }), {});
        Object.assign(this, _data);
      }
  }
}

export const JarStock_Model = Model({
  Id: [Number, null],
  AgentId: [Number, null],
  Amount: [Number, String, null],
  CanBeDeleted: [Boolean, null],
  Concentration: [Number, String, null],
  Cost: [Number, String, null],
  CreationDate: [Date, String, null],
  Density: [Number, String, null],
  IsDeleted: [Boolean, null],
  IsInWork: [Boolean, null],
  JarUsers: [ArrayModel([JarUser]), null],
  LaboratoryId: [String, Number, null],
  Location: [String, null],
  MainAgentContent: [String, Number, null],
  Number: [Number, String, null],
  PackageWeight: [Number, String, null],
  Provider: [String, null],
  ProviderReactionId: [Number, null],
  ProviderStageId: [Number, null],
  Qualification: [String, null],
  IsIgnoringSpending: [Boolean, null],
  Series: [String, null],
  ExpirationDate: [Date, String, null],
  IsOrdered: [Boolean],
})
.defaultTo({
  Number           : '',
  Provider         : '',
  Qualification    : '',
  Cost             : '',
  Comment          : '',
  MainAgentContent : '',
  Concentration    : '',
  Density          : '',
  PackageWeight    : '',
  Amount           : '',
  LaboratoryId     : '',
  Location         : '',
  Series           : '',
  IsOrdered        : null,
});

export const copyJar = (jar: JarStock) => ({
    ...jar, 
    Id: 0,
    JarUsers: [],
    CreationDate: null,
    CanBeDeleted: true, 
    SpendingsCount: 0, 
    ProviderReactionId: null, 
    ProviderStageId: null,
    Provider: jar.ProviderReactionId ? '' : jar.Provider,
    Attachments: [],
  });