// @flow

import { Model } from 'objectmodel';
import { IPurificationListRequest } from './i-purification-list-request';
import { PaginationParams } from '../../shared/models';

export const PurificationListRequest: (IPurificationListRequest) => IPurificationListRequest = Model({
  SmilesFormula: [String],
  CompoundName: [String],
}).extend(PaginationParams);
