import {apiRequestSingle} from '../../shared/services/api/common';

export class ADAccountService {
    constructor(token) {
        const headers = token ? {Authorization: token} : {};

        this._login = apiRequestSingle('POST', '/account/login', headers);
        this._logout = apiRequestSingle('GET', '/account/logout', headers);
        
        this._getData = apiRequestSingle('GET', '/account', headers);
        this._getRoles = apiRequestSingle('GET', '/account/roles', headers);
    }

    login(username, password) {
        return this._login({}, {username, password});
    }

    logout() {
        return this._logout({}, {});
    }

    getData() {
        return this._getData({}, {});
    }

    getRoles() {
        return this._getRoles({}, {});
    }
}