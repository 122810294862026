// @flow

import {
  put,
  takeEvery,
  fork,
  select,
} from 'redux-saga/effects';
import {getToken} from '../../account/reducers';
import type {IRequestResourceAction} from '../../shared/utils/action-creators';
import {UsersResourceService} from '../services/users-service';
import {usersResourceActions} from '../actions/users';

function* getUsersLIst({params}: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const result = yield UsersResourceService.list({params}, token);
    yield put(usersResourceActions.list.success(result));
  } catch (error) {
    yield put(usersResourceActions.list.failure({error}));
  }
}

function* updateUser({data}) {
  const token = yield select(getToken);
  try {
    const result = yield UsersResourceService.update({data}, token);
    yield put(usersResourceActions.update.success(result));
  } catch (error) {
    yield put(usersResourceActions.update.failure({error}));
  }
}

function* getUser({uriParams}: IRequestResourceAction) {
  const token = yield select(getToken);
  try {
    const result = yield UsersResourceService.get({uriParams}, token);
    yield put(usersResourceActions.get.success(result));
  } catch (error) {
    yield put(usersResourceActions.get.failure({error}));
  }
}

function* getSelf()
{
  const token = yield select(getToken);
  try {
    const result = yield UsersResourceService.getSelf({}, token);
    yield put(usersResourceActions.getSelf.success(result));
  } catch (error) {
    yield put(usersResourceActions.getSelf.failure({error}));
  }
}

export const usersResourceWatchers = [
  function* () {
    yield takeEvery(usersResourceActions.list.request.type, function* (action) {
      yield fork(getUsersLIst, action);
    });
  },

  function* () {
    yield takeEvery(usersResourceActions.update.request.type, function* (action) {
      yield fork(updateUser, action);
    });
  },

  function* () {
    yield takeEvery(usersResourceActions.get.request.type, function* (action) {
      yield fork(getUser, action);
    });
  },

  function* () {
    yield takeEvery(usersResourceActions.getSelf.request.type, function* (action) {
      yield fork(getSelf, action);
    })
  }
];
