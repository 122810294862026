// @flow
import * as React from 'react';
import type { Children } from 'react';
import './index.less';
import bnc from 'bnc';
import classNames from 'classnames';

export type LayoutBlockProps = {
    style?: any;
    header?: string | Children;
    children?: Children;
    className?: string;
}

const
    block           = new bnc('b-LayoutBlock'),
    blockName       = block.toString(),
    noShadow        = block.mod('noShadow').toString(),
    headerName      = block.el('header').toString(),
    titleName       = block.el('title').toString(),
    separatorName   = block.el('separator').toString();

export const LayoutBlock = (props: LayoutBlockProps) => {
    return (
        <div
            className={classNames(blockName, props.className, props.noShadow ? noShadow : null)}
            style={props.style}
        >
            {
                props.header ?
                    (
                        <div className={headerName}>
                            <div className={typeof props.header === 'string' ? titleName : null}>
                                {props.header}
                            </div>
                        </div>
                    ) : null
            }
            {props.children}
        </div>
    );
};
