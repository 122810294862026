// @flow

import {
  moduleAction,
} from '../../shared/utils';
import { schemesResourceActions } from '../actions/scheme-resource-actions'
import { schemeModuleActions } from '../actions/module';
import { projectActions } from '../../projects/actions';
import type { IAgent } from '../../agents/models';

export const SCHEMES_MODULE_NAME = 'SCHEMES';

export interface ISchemeModuleStore
{
  breadcrumbs: {},
  editScheme: {
    selectedStageId: number,
    selectedCompoundAgent: {},
    selectedCompoundAgentPending: boolean,
  },
  compareScheme: {
    comparingSchemeIds: Array<number>,
    comparingSchemeViews: Array<any>,
  },
  schemeTree: {
    jarAmounts: {
      [key: string]: {
        params: { SmilesFormula: string, CompoundName: string },
        agentId: number,
        pending: boolean,
        show: boolean,
      }
    }
  }
}

export const defaultState = {
  breadcrumbs: null,
  editScheme: {
    selectedStageId: 0,
    selectedCompoundAgent: null,
    selectedCompoundAgentPending: false,
  },
  compareScheme: {
    comparingSchemeIds: [],
    comparingSchemeViews: [],
  },
  schemeTree: {
    jarAmounts: {}
  }
};

export const schemeModuleReducer = (state = defaultState, action = {}) => {
  const reducedFn = {
    [schemeModuleActions.destroyModule]: () => {
      return {
        ...defaultState
      };
    },
    [schemeModuleActions.setSelectedStageId]: () => {
      return {
        ...state,
        editScheme: {
          ...state.editScheme,
          selectedStageId: action.stageId
        }
      };
    },
    [schemeModuleActions.setCurrentSchemeId]: () => {
      return {
        ...state,
        editScheme: {
          ...state.editScheme,
          currentSchemeId: Number.parseInt(action.schemeId)
        }
      };
    },
    [schemesResourceActions.delete.success]: () => {
      return {
        ...state,
        editScheme: {
          ...state.editScheme,
          schemeDeleted: true
        }
      };
    },
    [schemesResourceActions.create.success]: () => {
      return {
        ...state,
        editScheme: {
          ...state.editScheme,
          schemeSavedId: action.result.Id
        }
      };
    },
    [schemesResourceActions.update.success]: () => {

      return {
        ...state,
        editScheme: {
          ...state.editScheme,
          schemeSavedId: action.result.Id
        }
      };
    },
    [schemeModuleActions.clearSchemeSavedId]: () => {
      return {
        ...state,
        editScheme: {
          ...state.editScheme,
          schemeSavedId: null
        }
      };
    },
    [projectActions.getProjectByCandidateId.success]: () => {
      return {
        ...state,
        editScheme: {
          ...state.editScheme,
          currentProject: action.result
        }
      };
    },
    [schemeModuleActions.setComparingSchemeIds]: () => {
      return {
        ...state,
        compareScheme: {
          ...state.compareScheme,
          comparingSchemeIds: action.schemeIds
        }
      };
    },
    [schemeModuleActions.setComparingSchemeViews]: () => {
      return {
        ...state,
        compareScheme: {
          ...state.compareScheme,
          comparingSchemeViews: action.schemeViews
        }
      };
    },
    [schemeModuleActions.loadAgentBySmiles.success]: (action) => ({
      ...state,
      editScheme: {
        ...state.editScheme,
        selectedCompoundAgent: Array.isArray(action.result) && action.result.length ? action.result[0] : null,
        selectedCompoundAgentPending: false,
      }
    }),
    [schemeModuleActions.loadAgentBySmiles.request]: (action) => ({
      ...state,
      editScheme: {
        ...state.editScheme,
        selectedCompoundAgentPending: true,
      }
    }),
    [schemeModuleActions.loadAgentBySmiles.failure]: (action) => ({
      ...state,
      editScheme: {
        ...state.editScheme,
        selectedCompoundAgentPending: false,
      }
    }),
    [schemeModuleActions.clearAgentForCurrentCompound]: () => ({
      ...state,
      editScheme: {
        ...state.editScheme,
        selectedCompoundAgent: defaultState.editScheme.selectedCompoundAgent
      }
    }),
    [schemeModuleActions.setBreadcrumbs]: () => ({
      ...state,
        breadcrumbs: {...action.breadcrumbs},
    }),
    [schemeModuleActions.addJarAmountBtn]: ({uuid, SmilesFormula, CompoundName}) => ({
      ...state,
      schemeTree: {
        ...state.schemeTree,
        jarAmounts: {
          ...state.schemeTree.jarAmounts,
          [uuid]: {
            params: { SmilesFormula, CompoundName },
            agentId: null,
          }
        }
      }
    }),
    [schemeModuleActions.setAgentIdForLoadedJarAmounts]: ({uuid, agentId}) => ({
      ...state,
      schemeTree: {
        ...state.schemeTree,
        jarAmounts: {
          ...state.schemeTree.jarAmounts,
          [uuid]: {
            ...state.schemeTree.jarAmounts[uuid],
            agentId,
          }
        }
      }
    }),
    [schemeModuleActions.setPendingForLoadedJarAmount]: ({ uuid, pending }) => ({
      ...state,
      schemeTree: {
        ...state.schemeTree,
        jarAmounts: {
          ...state.schemeTree.jarAmounts,
          [uuid]: {
            ...state.schemeTree.jarAmounts[uuid],
            pending,
          }
        }
      }
    }),
    [schemeModuleActions.setShowResultForLoadedJarAmount]: ({ uuid, show }) => ({
      ...state,
      schemeTree: {
        ...state.schemeTree,
        jarAmounts: {
          ...state.schemeTree.jarAmounts,
          [uuid]: {
            ...state.schemeTree.jarAmounts[uuid],
            show,
          }
        }
      }
    }),
    [schemeModuleActions.setAllLoadedJarAmountsHidden]: () => ({
      ...state,
      schemeTree: {
        ...state.schemeTree,
        jarAmounts: {
          ...Object.entries(state.schemeTree.jarAmounts)
              .map(([uuid, amount]) => ([ uuid, {...amount, show: false} ]))
              .reduce((acc, [uuid, amount]) => ({...acc, [uuid]: amount}), {})
        }
      }
    })
  }[action.type];
  return reducedFn ? reducedFn(action) : state;
};