// @flow
import React, { useEffect } from 'react';
import './stage-details.less';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { selectedStageIdSelector, selectedStageResourceSelector } from '../../reducers/selectors';
import { ReactionSideBar } from '../../../reactions/components/ReactionsSidebar';
import { stageResourceActions } from '../../../stages/actions';
import type { IObtainmentStage } from '../../../stages/models';

interface IStageDetailsProps{
  stage: IObtainmentStage;
  className: string;
  selectedStageId: number;
  history: {};
  getStage: (id: number) => void;
}

const StageDetails = (props: IStageDetailsProps) => {

  const loadData = () => {
    if(!props.selectedStageId)
      return;

    props.getStage(props.selectedStageId);
  };
  useEffect(loadData, [props.selectedStageId]);

  function editStage(event) {
    event.preventDefault();
    props.history.push(`/stages/${props.selectedStageId}`);
  }

  return(
    <div className={`stage-details-container ${props.className}`}>

      {
        props.stage &&
        <>
          <div className="details-header">
            <span>Стадия {props.stage && props.stage.TreeNumber}</span>
            <i className={'fo-edit'} onClick={editStage}/>
          </div>
          {
            props.stage && props.stage.RequiredPurificationsCount > 0 
              ? <div className="required-purification">Количество обязательных реакций очистки: {props.stage.RequiredPurificationsCount}</div>
              : ''
          }
          <ReactionSideBar
            stageId={props.stage.Id}
            className={props.className}
            bestReactionId={props.stage.BestReactionId}
          />
        </>
      }
     
    </div>
  );
};


export default withRouter(connect(
  (state) => ({ 
      selectedStageId: selectedStageIdSelector(state),
      stage: selectedStageResourceSelector(state),
  }),
  (dispatch) => ({
      getStage: (id: number) => dispatch(stageResourceActions.get.request({ uriParams: { id } })),
  })
)(StageDetails));