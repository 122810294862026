// @flow
import React from 'react';
import { Redirect } from 'react-router-dom';
import {useLastVisitedPage} from '../../shared/react-hooks/use-last-visited-page';

export const NoMatch = () => {
    const lastVisitedPage = useLastVisitedPage();

    return (
        <Redirect to={lastVisitedPage} />
    );
};
