import { moduleAction } from '../../shared/utils';

export const ATTACHMENTS_ROOT_NAME = 'ATTACHMENTS_ROOT';

export const attachmentsActions = {
    upload: {
      request: moduleAction(
        ATTACHMENTS_ROOT_NAME,
        ['UPLOAD_REQUEST'],
        ({ files, index, tag }) => ({ files, index, tag })
      ),
      success: moduleAction(
        ATTACHMENTS_ROOT_NAME,
        ['UPLOAD_SUCCESS'],
        ({ files, tag }) => ({ files, tag })
      ),
      failure: moduleAction(
        ATTACHMENTS_ROOT_NAME,
        ['UPLOAD_FAILURE'],
        ({ error }) => ({ error })
      ),
      destroy: moduleAction(
        ATTACHMENTS_ROOT_NAME,
        ['UPLOAD_DESTROY'],
        () => null
      ),
    },

    download: {
      request: moduleAction(
      ATTACHMENTS_ROOT_NAME,
      ['DOWNLOAD_REQUEST'],
      ({ id, name }) => ({ id, name })
    ),
      failure: moduleAction(
      ATTACHMENTS_ROOT_NAME,
      ['DOWNLOAD_FAILURE'],
      ({ error }) => ({ error })
    ),
    }
};