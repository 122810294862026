import React from 'react';
import {GridTable, GridTableCol} from "../../../../shared/components/GridTable";
import Collapsible from "react-collapsible";
import dateTimeFormat from "@biocad/bcd-front-ui/utils/formatters/datetime";
import type {IReactionMeasurement} from "../../../models";

const EquipmentTable = (props) => {
    const getEquipmentName = (eq) => {
        return props.reaction.ReactionMeasurements.filter((value) => value.EquipmentId === eq)[0].Equipment.Name;
    };

    if(props.reaction?.ReactionMeasurements) {
        let equipments = props.reaction.ReactionMeasurements.map(item => item.EquipmentId)
            .filter((value, index, self) => self.indexOf(value) === index).sort(function (a, b) {
                return a - b;
            });
        const tables = equipments.map((eq) =>
            <div key={eq} className={'equipmentArea'}>
                <Collapsible transitionTime={200}
                             trigger={<div className={'tableTitle'}>{getEquipmentName(eq)}
                                 <span className={'arrow'}/></div>}>
                <GridTable items={props.reaction.ReactionMeasurements}
                           hover={true}
                           stickyHeading={true}
                           colsTemplate={'repeat(3, minmax(150, auto))'}
                           rowHeight={'54px'}
                           excludeRows={(item: IReactionMeasurement) => item.EquipmentId!==eq}
                >
                    <GridTableCol
                        title={'Значение'}
                        render={({item}) => (
                            <>
                                <span>{item.Value?item.Value:item.TextValue}</span>
                            </>
                        )}
                    />
                    <GridTableCol
                        title={'Время замера'}
                        render={({item}) => (
                            <>
                                <span>{item.DateTime?
                                    dateTimeFormat(new Date(item.DateTime), {
                                        locale: 'ru',
                                        format: 'compact',
                                        time: true
                                    }):''}</span>
                            </>
                        )}
                    />
                    <GridTableCol
                        title={'Единица измерения'}
                        render={({item}) => (
                            <>
                                <span>{item.Unit}</span>
                            </>
                        )}
                    />
                </GridTable>
                </Collapsible>
            </div>
        );
        return (<div className={props.className}>
            {tables}
        </div>);
    }
    else
        return <></>;
};

export default EquipmentTable;
