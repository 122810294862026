import * as React from "react";
import {useEffect, useState, useRef, useCallback} from "react";
import PropTypes from "prop-types";
import BNC from "bnc";
import { fieldInputPropTypes, fieldMetaPropTypes } from "redux-form";
import classNames from "classnames";
import {debounce} from "lodash";
import { FormFieldLayout } from "../FormFieldLayout";

import "./index.less";

const 
    bncBlock = new BNC("b-RangeSlider"),
    bncThumb = bncBlock.el("thumb"),
    bncSlider = bncBlock.el("slider"),
    bncTrack = bncBlock.el("track"),
    bncRange = bncBlock.el("range"),
    bncTextInputHolder = bncBlock.el("textInputHolder"),
    bncTextInput = bncBlock.el("textInput"),
    bncTextLabel = bncBlock.el("textLabel"),
    bncChildren = bncBlock.el("children")
;

const defaultProps = {
    input: {},
    meta: {},
    className: "",
    step: 0.1,
    min: 0,
    max: 100,

}

let RangeSlider = (props) => 
{
    const
        [startValue, setStartValue] = useState(0),
        [endValue, setEndValue] = useState(0),
        [isDebounced, setIsDebounced] = useState(true)
    ;

    const formFieldLayoutProps = {
        label: props.label,
        hint: props.hint,
        invalidMessage: props.input.error || props.invalidMessage,
        valid: props.input.valid,
        required: props.required,
        className: classNames(bncBlock.toString(), props.className),
        childrenWrapClassName: bncChildren.toString()
    };

    const
        rangeRef = useRef(null),
        startInputRef = useRef(null),
        endInputRef = useRef(null),
        startInputTextRef = useRef(null),
        endInputTextRef = useRef(null),
        debouncedChange = useRef(debounce((start, end) => {
            props.input.onChange([start, end]);
        }, 250))
    ;

    const getPercent = useCallback((value) => (
        ((value - props.min) / (props.max - props.min)) * 100
    ), [props.min, props.max]);

    useEffect(() => {
        setStartValue(props.min);
        setEndValue(props.max);
    }, [props.min, props.max]);

    useEffect(() => {
        if (Array.isArray(props.input.value) && props.input.value.length === 2) {
            const [_start, _end] = props.input.value;
            setStartValue(_start);
            setEndValue(_end);
        }
    }, [props.input.value]);

    useEffect(() => {
        if (startInputTextRef.current && endInputTextRef.current) {
            startInputTextRef.current.value = startValue.toString();
            endInputTextRef.current.value = endValue.toString();
        }
        if (isDebounced) {
            debouncedChange.current(startValue, endValue);
        } else {
            setIsDebounced(true);
            props.input.onChange([startValue, endValue]);
        }
    }, [startValue, endValue]);

    useEffect(() => {
        if (!startInputRef.current || !rangeRef.current) {
            return;
        }
        const
            startPercent = getPercent(Number.parseFloat(startInputRef.current?.value)),
            endPercent = getPercent(Number.parseFloat(endInputRef.current?.value))
        ;
        rangeRef.current.style.width = `${(endPercent - startPercent).toFixed(2)}%`;
        rangeRef.current.style.left = `${startPercent.toFixed(2)}%`;

    }, [startValue, endValue, getPercent]);

    // Methods
    const
        extractValue = (event) =>
        {
            let value = Number.parseFloat(event?.target?.value, 10);
            if (isNaN(value)) {
                throw new TypeError();
            }
            return value;
        }

    // Handlers
    const
        setStartValueFromEvent = (event) =>
        {
            const value = extractValue(event);
            setStartValue(
                Math.min(
                    Math.max(value, props.min), endValue - props.step));
        },
        setEndValueFromEvent = (event) =>
        {
            const value = extractValue(event);
            setEndValue(
                Math.max(
                    Math.min(value, props.max), startValue + props.step));
        },
        onStartValueBlur = (event) =>
        {
            const value = extractValue(event);
            if (value < props.min) {
                event.target.value = props.min;
            }
            setStartValueFromEvent(event);
            setIsDebounced(false);
        },
        onEndValueBlur = (event) =>
        {
            const value = extractValue(event);
            if (value >= props.max) {
                event.target.value = props.max;
            }
            setEndValueFromEvent(event);
            setIsDebounced(false);
        }
    ;

    return (
        <FormFieldLayout { ...formFieldLayoutProps }>
            <div className={bncTextInputHolder}>
                <label className={bncTextLabel}>
                    от:
                    <input onBlur={onStartValueBlur}
                           className={bncTextInput}
                           ref={startInputTextRef}
                           type={'number'}
                           step={'0.0001'}
                    />
                </label>
                <label className={bncTextLabel}>
                    до:
                    <input onBlur={onEndValueBlur}
                           className={bncTextInput}
                           ref={endInputTextRef}
                           type={'number'}
                           step={'0.0001'}
                    />
                </label>
            </div>
            <input 
                type="range"
                className={bncThumb.toString()}
                min={props.min}
                max={props.max}
                value={startValue}
                step={props.step}
                onChange={setStartValueFromEvent}
                ref={startInputRef}
            />
            <input 
                type="range"
                className={bncThumb.toString()}
                min={props.min}
                max={props.max}
                value={endValue}
                step={props.step}
                onChange={setEndValueFromEvent}
                ref={endInputRef}
            />
            <div className={classNames(bncSlider.toString())}>
                <div className={classNames(bncTrack.toString())} />
                <div className={classNames(bncRange.toString())} ref={rangeRef} />
            </div>
        </FormFieldLayout>
    )
};

RangeSlider.defaultProps = defaultProps;

RangeSlider.propTypes = {
    input: PropTypes.shape(fieldInputPropTypes),
    meta: PropTypes.shape(fieldMetaPropTypes),
    className: PropTypes.string,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    step: PropTypes.number,
    hint: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    invalidMessage: PropTypes.string,
};

export {
    RangeSlider,
}