// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".icon-button-m__iconButton___RgFDM {\n  color: var(--icon-button-color);\n  font-size: var(--icon-button-size);\n  display: inline-block;\n}\n.icon-button-m__iconButton___RgFDM:not(.icon-button-m__disabled___SCNAg) {\n  cursor: pointer;\n}\n.icon-button-m__iconButton___RgFDM:hover:not(.icon-button-m__disabled___SCNAg) {\n  color: var(--icon-button-hover-color);\n}\n.icon-button-m__iconButton___RgFDM.icon-button-m__active___DjLK2,\n.icon-button-m__iconButton___RgFDM.icon-button-m__active___DjLK2:hover {\n  color: var(--icon-button-active-color) !important;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconButton": "icon-button-m__iconButton___RgFDM",
	"disabled": "icon-button-m__disabled___SCNAg",
	"active": "icon-button-m__active___DjLK2"
};
export default ___CSS_LOADER_EXPORT___;
