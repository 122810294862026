// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".spendings-m__grid___oBTtY {\n  display: grid;\n  grid-template-columns: 5fr 4fr;\n  grid-template-rows: 160px;\n  grid-gap: var(--grid-spacer);\n  margin-bottom: 16px;\n}\n.spendings-m__thumbnail___vOORh {\n  justify-self: center;\n  align-self: center;\n  padding-top: var(--grid-spacer);\n  height: 100%;\n}\n.spendings-m__thumbnail___vOORh img {\n  height: 100%;\n}\n.spendings-m__info___sgsmu {\n  padding-top: var(--grid-spacer);\n  display: flex;\n  flex-flow: column nowrap;\n  align-self: center;\n}\n.spendings-m__info__field___af1Dd {\n  margin-bottom: 20px;\n}\n.spendings-m__jarStock___dtdS5 {\n  grid-column-start: 1;\n  grid-column-end: 3;\n}\n.spendings-m__hidden___rjVoN {\n  display: none;\n}\n.spendings-m__visible___CwKkz {\n  display: block;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": "spendings-m__grid___oBTtY",
	"thumbnail": "spendings-m__thumbnail___vOORh",
	"info": "spendings-m__info___sgsmu",
	"info__field": "spendings-m__info__field___af1Dd",
	"jarStock": "spendings-m__jarStock___dtdS5",
	"hidden": "spendings-m__hidden___rjVoN",
	"visible": "spendings-m__visible___CwKkz"
};
export default ___CSS_LOADER_EXPORT___;
