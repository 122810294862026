import React, { forwardRef } from 'react';
import Tooltip from '@biocad/bcd-front-ui/layout/Tooltip';
import './tooltip.less';
import styles from './tooltip.m.less';

let BcdTooltipWrapper = ({ header, body, type, className, open = false, onEditClickAction, ...params }, ref) => {
  let content = (
    <div className={`${styles.tooltip__wrapper} tooltip__wrapper`}>
      {header &&
        <div className={`${styles.tooltip__header} tooltip__header`}>
          {header}
          {onEditClickAction && (
            <i
              className={`${styles.tooltip__edit_btn} tooltip__edit_btn fo-edit`}
              onClick={onEditClickAction}
            />
          )}
        </div>
      }
      <div className={`${styles.tooltip__body} tooltip__body`}>
        {body || 'Нет комментария'}
      </div>
    </div>
  );

  return (
    <>
      {
        <Tooltip
          ref={ref}
          className={`${styles.tooltip} ${className ? className : 'tooltip-custom'}`}
          open={open}
          listen={type}
          {...params}
        >
          {content}
        </Tooltip>
      }
    </>
  );
};

BcdTooltipWrapper = forwardRef(BcdTooltipWrapper);

export { BcdTooltipWrapper };
