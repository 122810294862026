// @flow

import React, { useState, useEffect } from 'react';
import bnc from 'bnc/index';
import { MolecularFormulaFormatter } from '../../../shared/components/MolecularFormulaFormatter';
import type { IProduct } from '../../../products/model';
import type { IReaction } from '../../models';
import type { IReagent } from '../../../reagents/model';
import { ReagentTypeEnum } from '../../../reagents/model';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { reactionModuleActions } from '../../actions';
import classNames from 'classnames';
import type { IRootStore } from '../../../app/reducers';
import { getHighlightedSubstance } from '../../reducers';

import './index.less';
import {stageModuleActions} from "../../../stages/actions";
import type {IObtainmentStage} from "../../../stages/models";
import {schemesResourceActions} from "../../../schemes/actions";
import type {IObtainmentScheme} from "../../../schemes/models";
import {getCurrentFinalCandidate, getCurrentScheme, getCurrentStage} from "../../../stages/reducers";
import {candidatesResourceActions} from "../../../candidates/actions";
import {IFinaleCandidate} from "../../../candidates/models/i-final-candidate";

interface IReactionImageProps {
  reagents: (IProduct | IReaction)[],
  className?: string,
  setHighlightedSubstance?: (id: number) => void,
  highlightedSubstance?: number,
  stageId?: number,
  getScheme?: (id: number) => void,
  getFinalCandidate?: (id: number) => void,
  setCurrentStageId: (id: number) => void,
  setCurrentSchemeId: (id: number) => void,
  stage?: IObtainmentStage,
  currentScheme?: IObtainmentScheme,
  currentCandidate?: IFinaleCandidate
}

type ReactionImageItem = {
  id: number,
  thumbnail: string,
  name?: string,
  formula: string,
  reagentType: number,
  itemIdx: number,
  isRequired: boolean,
}

const defaultProps: IReactionImageProps = {
  reagents: [],
  className: '',
  stage: null,
  currentScheme: null,
  currentCandidate: null
};

const
  BNCBlock            = new bnc('b-ReactionImage'),
  BNCItem             = BNCBlock.el('item'),
  BNCItemHighlighted  = BNCItem.mod('highlighted'),
  BNCThumb            = BNCBlock.el('thumb'),
  BNCImg              = BNCBlock.el('img'),
  BNCTitle            = BNCBlock.el('title'),
  BNCPLus             = BNCBlock.el('plus'),
  BNCCell             = BNCBlock.el('cell'),
  BNCArrow            = BNCBlock.el('arrow'),
  BNCList             = BNCBlock.el('list'),
  BNCListTop          = BNCList.mod('top'),
  BNCListBottom       = BNCList.mod('bottom')
;

// Functions
const
  isReactant = (item: ReactionImageItem) => (
    item.reagentType === ReagentTypeEnum.Original || (item.reagentType === ReagentTypeEnum.Reactant && item.isRequired)
  ),
  isCatalyst = (item: ReactionImageItem) => (
    item.reagentType === ReagentTypeEnum.Catalyst || (item.reagentType === ReagentTypeEnum.Reactant && !item.isRequired)
  ),
  isSolvent  = (item: ReactionImageItem) => (
    item.reagentType === ReagentTypeEnum.Solvent
  ),
  isProduct  = (item: ReactionImageItem) => (
    !item.reagentType
  )
;

let ReactionImage = (props: IReactionImageProps = defaultProps) => {

  // states
  const
    [items, setItems] = useState<ReactionImageItem[]>([])
  ;

  const updateStageId = () => {
      if (props.stageId && !props?.stage?.Id) {
        props.setCurrentStageId(props.stageId);
      }
  }
  useEffect(updateStageId, [props.stageId]);

  const updateSchemeId = () => {
      if (props.stage && props.stage.IsFinalCandidate && !props.currentScheme) {
          props.setCurrentSchemeId(props.stage.ObtainmentSchemeId);
          props.getScheme(props.stage.ObtainmentSchemeId);
      }
  }

  useEffect(updateSchemeId, [props.stage?.ObtainmentSchemeId]);

  const watchCurrentScheme = () => {
    if (props?.currentScheme?.FinalCandidateId) {
      props.getFinalCandidate(props.currentScheme.FinalCandidateId);
    }
  };

  useEffect(watchCurrentScheme, [props?.currentScheme?.FinalCandidateId]);

  // Effects
  const updateItems = () => {
    if (!props.reagents || !Array.isArray(props.reagents)) return;
    const _items = props.reagents
      .filter((item: (IProduct | IReagent)) => !item.IsDeleted && !item.ParentReagentId && !item.ParentReagentLocalId)
      .map((item: (IProduct | IReagent), idx: number): ReactionImageItem => ({
        id: item.Id,
        thumbnail: 'IsMainProduct' in item ? item.Compound?.Thumbnail : (item.Agent?.Compound?.Thumbnail || item.Compound?.Thumbnail),
        formula: 'IsMainProduct' in item ? item.Compound?.MolecularFormula : (item.Agent?.Compound?.MolecularFormula || item.Compound?.MolecularFormula),
        name: 'IsMainProduct' in item ? (item.ShortName || item.Compound?.MolecularFormula) : (item.Agent?.ShortName || item.Compound?.MolecularFormula),
        reagentType: 'ReagentType' in item ? item.ReagentType : null,
        isRequired: 'IsRequired' in item ? item.IsRequired : null,
        itemIdx: idx+1
      }));
    setItems(_items);
  };
  useEffect(updateItems, [props.reagents]);

   const getCode = (fc: IFinaleCandidate, isFinal: boolean) => {
     if (!fc || !isFinal || !fc.Code) {
       return "";
     }
     return " (" + fc.Code + ")";
  }

  // Methods
  const
    renderItemWithImage = (item: ReactionImageItem, idx, arr: ReactionImageItem[]) => (
      <React.Fragment key={`${item.itemIdx}__${idx}`}>
        <div className={BNCCell + BNCItem + classNames({ [BNCItemHighlighted.toString()]: item.itemIdx === props.highlightedSubstance })}
             onClick={() => (item.itemIdx === props.highlightedSubstance)
               ? props.setHighlightedSubstance(null)
               : props.setHighlightedSubstance(item.itemIdx)}
        >
          <span className={BNCTitle}>
              {item.itemIdx}.
              { isProduct(item)
                  ? 'Продукт' + getCode(props.currentCandidate, props.stage?.IsFinalCandidate)
                  : item.name
                      ? item.name
                      : 'Реактант'
              }
          </span>
          <div className={BNCThumb}>
            <img
              alt={''}
              className={BNCImg}
              src={item.thumbnail || item.Compound?.Thumbnail}
            />
          </div>
        </div>
        {
          (arr.length && idx+1 < arr.length)
            ? <i className={BNCPLus + 'fo-plus'} />
            : ''
        }
      </React.Fragment>
    ),
    renderItemWithoutImage = (item: ReactionImageItem, idx: number) => (
      <span className={BNCTitle + BNCItem + classNames({ [BNCItemHighlighted.toString()]: item.itemIdx === props.highlightedSubstance })} key={`${item.itemIdx}__${idx}`}
            onClick={() => (item.itemIdx === props.highlightedSubstance)
              ? props.setHighlightedSubstance(null)
              : props.setHighlightedSubstance(item.itemIdx)}
      >
      { item.itemIdx }. { item.name ? <span>{item.name}</span> : (<MolecularFormulaFormatter formulaStr={item.formula || ''} />) }
    </span>
    )
  ;

  return (
    items.length
    ? <div className={BNCBlock + props.className}>
        { items.filter(isReactant).map(renderItemWithImage) }
        <div className={BNCCell}>
          <div className={BNCList + BNCListTop}>
            { items.filter(isCatalyst).map(renderItemWithoutImage) }
          </div>
          {
            items.length
            ? <span className={BNCArrow} />
            : ''
          }
          <div className={BNCList + BNCListBottom}>
            { items.filter(isSolvent).map(renderItemWithoutImage) }
          </div>
        </div>
        { items.filter(isProduct).map(renderItemWithImage) }
      </div>
    : ''
  )
};

ReactionImage = connect(
  (state: IRootStore): IReactionImageProps => ({
    highlightedSubstance: getHighlightedSubstance(state),
    stage: getCurrentStage(state),
    currentScheme: getCurrentScheme(state),
    currentCandidate: getCurrentFinalCandidate(state)
  }),
  (dispatch: Dispatch): IReactionImageProps => ({
    setHighlightedSubstance: (idx: number) => dispatch(reactionModuleActions.reactionEdit.setHoveredImage({ idx })),
    getScheme: (id: number) => dispatch(schemesResourceActions.get.request({ uriParams: { id } })),
    setCurrentStageId: (id: number) => dispatch(stageModuleActions.setCurrentStageId({ id })),
    setCurrentSchemeId: (schemeId: number) => dispatch(stageModuleActions.setCurrentSchemeId({ schemeId })),
    getFinalCandidate: (id: number) => dispatch(candidatesResourceActions.get.request({ uriParams: { id } })),
  })
)(ReactionImage);

export {
  ReactionImage
}
