import {
    put,
    select,
    takeEvery,
    takeLeading,
    fork,
} from 'redux-saga/effects';
import {EquipmentService} from "../services";
import {getToken} from "../../account/reducers";
import {equipmentActions} from "../actions";

export function* getEquipmentList(data) {
    const token = yield select(getToken);
    const service = new EquipmentService(token);

    try {
        const result = yield service.getList(data);
        yield put(equipmentActions.list.success(result));
    } catch (e) {
        if (e.response && e.response.data && 'ErrorMessage' in e.response.data) {
            yield put(equipmentActions.list.failure({error: e.response.data['ErrorMessage']}));
        }
    }
}

export const equipmentWatchers = [
    function* watchEquipmentList() {
        yield takeLeading(equipmentActions.list.request.type, function* (action) {
            const {params} = action;
            yield fork(getEquipmentList, params);
        });
    },


];
