// @flow

import React from 'react';
import { reduxForm, Field } from 'redux-form';
import Button from '@biocad/bcd-front-ui/controls/Button';
import { BcdInputWrapper } from '../../../shared/components/BcdInputWrapper';


interface IEditFolderFormProps {
  className?: string,
  saveFolder: typeof Function,
  toggleDialog: typeof Function,
  folderIsNew: boolean,
}

const defaultProps: IEditFolderFormProps = {
  className: '',
};

export const FOLDER_EDIT_FORM = 'folderEditDialog';

let EditFolderForm = (props: IEditFolderFormProps = defaultProps) => (
  <form className={props.className} onSubmit={props.saveFolder}>
    <header className={'dialog-header'}>
      <h3>{props.folderIsNew ? 'Создать' : 'Редактировать'} папку</h3>
    </header>
    <div className={'dialog-contents'}>
      <Field
        name={'Name'}
        component={BcdInputWrapper}
        label={'Название'}
        useFormFieldLayout={true}
        placeholder={'Введите название'}
      />
    </div>
    <footer className={'dialog-footer'}>
      <Button
        text={props.folderIsNew ? 'Создать' : 'Изменить'}
        view='primary'
        size={'L'}
        type={'submit'}
      />
      <Button
        text='Отмена'
        size={'L'}
        onAction={props.toggleDialog}
      />
    </footer>
  </form>
);

EditFolderForm = reduxForm({
  form: FOLDER_EDIT_FORM,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(EditFolderForm);

export {
  EditFolderForm
};
