import React,  {useState} from 'react';
import {LayoutHeader} from '../../shared/components/LayoutHeader';

import EquipmentTable from './EquipmentTable';
import EquipmentSearchForm from './SearchForm';

const EquipmentList = () => {
    const [searchSubmitted, setSearchSubmitted] = useState(false);
    const handleSearchFormSubmit = (event: any) => {
        event.preventDefault();
        setSearchSubmitted(!searchSubmitted);
    };

    return (
        <>
            <LayoutHeader>
                <EquipmentSearchForm onSubmit={handleSearchFormSubmit}/>
            </LayoutHeader>
            <EquipmentTable startSearch={searchSubmitted} />
        </>
    );
};

export default EquipmentList;
