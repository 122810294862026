// @flow
import { Model, Any } from 'objectmodel';

export const ReactionEventModel = Model({
	Abbreviation: [String, null, undefined],
	AdditionalInfo: Any,
	Block: Number,
	CreationDate: [Date, String],
	EventType: Number,
	Id: Number,
	ReactionId: Number,
	UserName: [String, null, undefined],
});
