const atomWeightsAverage = {
  'H': 1.0079,
  'He': 4.0026,
  'Li': 6.941,
  'Be': 9.0122,
  'B': 10.811,
  'C': 12.0107,
  'N': 14.0067,
  'O': 15.9994,
  'F': 18.9984,
  'Ne': 20.1797,
  'Na': 22.9897,
  'Mg': 24.305,
  'Al': 26.9815,
  'Si': 28.0855,
  'P': 30.9738,
  'S': 32.065,
  'Cl': 35.453,
  'Ar': 39.948,
  'K': 39.0983,
  'Ca': 40.078,
  'Sc': 44.9559,
  'Ti': 47.867,
  'V': 50.9415,
  'Cr': 51.9961,
  'Mn': 54.938,
  'Fe': 55.845,
  'Co': 58.9332,
  'Ni': 58.6934,
  'Cu': 63.546,
  'Zn': 65.39,
  'Ga': 69.723,
  'Ge': 72.64,
  'As': 74.9216,
  'Se': 78.96,
  'Br': 79.904,
  'Kr': 83.8,
  'Rb': 85.4678,
  'Sr': 87.62,
  'Y': 88.9059,
  'Zr': 91.224,
  'Nb': 92.9064,
  'Mo': 95.94,
  'Tc': 98,
  'Ru': 101.07,
  'Rh': 102.9055,
  'Pd': 106.42,
  'Ag': 107.8682,
  'Cd': 112.411,
  'In': 114.818,
  'Sn': 118.71,
  'Sb': 121.76,
  'Te': 127.6,
  'I': 126.9045,
  'Xe': 131.293,
  'Cs': 132.9055,
  'Ba': 137.327,
  'La': 138.9055,
  'Ce': 140.116,
  'Pr': 140.9077,
  'Nd': 144.24,
  'Pm': 145,
  'Sm': 150.36,
  'Eu': 151.964,
  'Gd': 157.25,
  'Tb': 158.9253,
  'Dy': 162.5,
  'Ho': 164.9303,
  'Er': 167.259,
  'Tm': 168.9342,
  'Yb': 173.04,
  'Lu': 174.967,
  'Hf': 178.49,
  'Ta': 180.9479,
  'W': 183.84,
  'Re': 186.207,
  'Os': 190.23,
  'Ir': 192.217,
  'Pt': 195.078,
  'Au': 196.9665,
  'Hg': 200.59,
  'Tl': 204.3833,
  'Pb': 207.2,
  'Bi': 208.9804,
  'Po': 209,
  'At': 210,
  'Rn': 222,
  'Fr': 223,
  'Ra': 226,
  'Ac': 227,
  'Th': 232.0381,
  'Pa': 231.0359,
  'U': 238.0289,
  'Np': 237,
  'Pu': 244,
  'Am': 243,
  'Cm': 247,
  'Bk': 247,
  'Cf': 251,
  'Es': 252,
  'Fm': 257,
  'Md': 258,
  'No': 259,
  'Lr': 262,
  'Rf': 261,
  'Db': 262,
  'Sg': 266,
  'Bh': 264,
  'Hs': 277,
  'Mt': 268,
};

const atomWeightsExact = {
  'H': 1.007825,
  'He': 4.002603,
  'Li': 7.016005,
  'Be': 9.012183,
  'B': 11.009305,
  'C': 12.000000,
  'N': 14.003074,
  'O': 15.994915,
  'F': 18.998403,
  'Ne': 19.992439,
  'Na': 22.989770,
  'Mg': 23.985045,
  'Al': 26.981541,
  'Si': 27.976928,
  'P': 30.973763,
  'S': 31.972072,
  'Cl': 34.968853,
  'Ar': 39.962383,
  'K': 38.963708,
  'Ca': 39.962591,
  'Sc': 44.955914,
  'Ti': 47.947947,
  'V': 50.943963,
  'Cr': 51.940510,
  'Mn': 54.938046,
  'Fe': 55.934939,
  'Co': 58.933198,
  'Ni': 57.935347,
  'Cu': 62.929599,
  'Zn': 63.929145,
  'Ga': 68.925581,
  'Ge': 73.921179,
  'As': 74.921596,
  'Se': 79.916521,
  'Br': 78.918336,
  'Kr': 83.911506,
  'Rb': 84.911800,
  'Sr': 87.905625,
  'Y': 88.905856,
  'Zr': 89.904708,
  'Nb': 92.906378,
  'Mo': 97.905405,
  'Ru': 101.90434,
  'Rh': 102.905503,
  'Pd': 105.903475,
  'Ag': 106.905095,
  'Cd': 113.903361,
  'In': 114.903875,
  'Sn': 119.902199,
  'Sb': 120.903824,
  'Te': 129.906229,
  'I': 126.904477,
  'Xe': 131.904148,
  'Cs': 132.905433,
  'Ba': 137.905236,
  'La': 138.906355,
  'Ce': 139.905442,
  'Pr': 140.907657,
  'Nd': 141.907731,
  'Sm': 151.919741,
  'Eu': 152.921243,
  'Gd': 157.924111,
  'Tb': 158.925350,
  'Dy': 163.929183,
  'Ho': 164.930332,
  'Er': 165.930305,
  'Tm': 168.934225,
  'Yb': 173.938873,
  'Lu': 174.940785,
  'Hf': 179.946561,
  'Ta': 180.948014,
  'W': 183.950953,
  'Re': 186.955765,
  'Os': 191.961487,
  'Ir': 192.962942,
  'Pt': 194.964785,
  'Au': 196.966560,
  'Hg': 201.970632,
  'Tl': 204.974410,
  'Pb': 207.976641,
  'Bi': 208.980388,
  'Th': 232.038054,
  'U': 238.050786,

  // Элементы, взятые из таблицы средних весов
  'Tc': 98,
  'Pm': 145,
  'Po': 209,
  'At': 210,
  'Rn': 222,
  'Fr': 223,
  'Ra': 226,
  'Ac': 227,
  'Pa': 231.0359,
  'Np': 237,
  'Pu': 244,
  'Am': 243,
  'Cm': 247,
  'Bk': 247,
  'Cf': 251,
  'Es': 252,
  'Fm': 257,
  'Md': 258,
  'No': 259,
  'Lr': 262,
  'Rf': 261,
  'Db': 262,
  'Sg': 266,
  'Bh': 264,
  'Hs': 277,
  'Mt': 268,
};

const digit = /^[0-9]$/;
const uppercase = /^[A-Z]$/;
const lowercase = /^[a-z]$/;

export function atomSplit(formula: string): { atom: string, count: number }[] {
  const res: { atom: string, count: number }[] = [];
  let index = 0;

  while (index < formula.length) {
    const current = { atom: '', count: 1 };

    if (uppercase.test(formula.charAt(index))) {
      current.atom = formula.charAt(index);

      if (lowercase.test(formula.charAt(index + 1))) {
        index = index + 1;
        current.atom = current.atom + formula.charAt(index);
      }

      let count = '';

      while (digit.test(formula.charAt(index + 1))) {
        index = index + 1;
        count = count + formula.charAt(index);
      }

      current.count = parseInt(count, 10) || 1;

      res.push(current);
    }

    index = index + 1;
  }

  return res;
}

function calculate(molecularFormula: string, weights: any): number {
  const formula = molecularFormula || '';

  let result = 0;

  for (const { atom, count } of atomSplit(formula)) {
    result = result + (weights[atom] || 0) * (count || 1);
  }

  return Math.round(result * 100) / 100;
}

export function calculateMolarWeight(molecularFormula: string): number {
  return calculate(molecularFormula, atomWeightsAverage);
}

export function calculateMolarWeightExact(molecularFormula: string): number {
  return calculate(molecularFormula, atomWeightsExact);
}