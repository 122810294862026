// @flow

import {createSelector} from 'reselect';
import {
    resourceReducer,
    setResourceFailState,
    setResourceRequestState,
    setResourceSuccess
} from '../../shared/utils/reducer-creators';
import {usersResourceActions} from '../actions/users';

export const usersResourceReducer = resourceReducer(usersResourceActions, {
    [usersResourceActions.getSelf.request]: (state, action) => setResourceRequestState(state, action),
    [usersResourceActions.getSelf.failure]: (state, action) => setResourceFailState(state, action),
    [usersResourceActions.getSelf.success]: (state, action) => setResourceSuccess(state, action)
});
