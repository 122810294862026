// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ckeck-group-m__group___GEvx1 {\n  display: flex;\n  flex-flow: row;\n  justify-content: flex-start;\n  align-items: center;\n}\n.ckeck-group-m__item___xMeVj {\n  font-size: 12px;\n  padding: 4px 8px;\n  border-radius: calc(var(--border-radius) * 2);\n  border: 1px solid var(--border-color);\n  cursor: pointer;\n}\n.ckeck-group-m__item___xMeVj:not(:last-child) {\n  margin-right: 8px;\n}\n.ckeck-group-m__item__selected___m1hWc {\n  color: #fff;\n  background-color: var(--green);\n  border-color: var(--green);\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group": "ckeck-group-m__group___GEvx1",
	"item": "ckeck-group-m__item___xMeVj",
	"item__selected": "ckeck-group-m__item__selected___m1hWc"
};
export default ___CSS_LOADER_EXPORT___;
